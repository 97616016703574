<portal-modal (clickedOutside)="onExitClickNotify.emit()">
	<div modalHeader class="pd-left30 pd-right30 pd-top15 pd-bottom15 title relative white-bg">
		<div class="row mgn-bottom30">
			<div class="col-sm-12 text-center">
				<a class="hidden-xs ft-size26 dark-text absolute right15 top0" (click)="onExitClickNotify.emit()"><i class="fa fa-times" aria-hidden="true"></i></a>
				<a class="hidden-sm hidden-md hidden-lg ft-size26 dark-text absolute rightneg4 top0" (click)="onExitClickNotify.emit()">
					<i class="fa fa-times" aria-hidden="true"></i>
				</a>
				<h2 class="in-block">
					Playlist Preview
					<br />
				</h2>
				<h1 class="semi-bold">
					<em>{{ playlist?.Name }}</em>
				</h1>
			</div>
		</div>
	</div>

	<div modalContent>
		<div class="video-container col-centered relative">
			<video
				*ngIf="previewModel?.currentClip.contentType === 'video/mp4'"
				(playing)="onPlay(previewModel?.currentClip)"
				[ngStyle]="{ 'max-height.px': maxVideoHeight, 'width.%': appStateService.product.Route === 'poster' ? '50' : null }"
				src="{{ previewModel?.currentClip.previewUrl }}"
				id="fullPreviewVideo"
				#fullPreviewVideo
				class="white-bg"
				(ended)="playNextItemOnEnd()"
				(error)="onVideoError($event)"
				type="video/mp4"
				autoplay
				controlsList="nodownload"
				controls
			>
				Oops! Your browser does not support this preview.
			</video>

			<img
				*ngIf="previewModel?.currentClip.contentType === 'image/png'"
				(load)="onPlay(previewModel?.currentClip)"
				[ngStyle]="{ 'max-height.px': appStateService.product.Route === 'poster' ? '525' : null }"
				class="{{ heightWidth() }} relative shadow border-radius mgn-bottom15"
				src="{{ previewModel?.currentClip.previewUrl }}"
			/>

			<ng-container *ngIf="contentViewService.showC24HTML(previewModel?.currentClip, contentViewService.c24SVG)">
				<span [innerHTML]="contentViewService.c24SVG" class="animated fadeIn"></span>
			</ng-container>
		</div>

		<!--*******************
        ERROR
    *********************-->
		<div *ngIf="!loading && (!previewModel || !previewModel?.currentClip)" id="fullPreviewNoPlaylist" class="relative z">
			ERROR: The playlist was empty or missing. :(
		</div>

		<div *ngIf="loading" id="fullPreviewNoPlaylist" class="relative z">Loading playlist...</div>

		<div class="pd30 white-bg video-controls relative z">
			<!--************************
            CLIP NAME & DURATION
        *************************-->
			<div class="row">
				<div class="col-xs-8 col-sm-10">
					<h3 class="semi-bold ellipsis">{{ previewModel?.nameLabel }}</h3>
				</div>
				<div class="col-xs-4 col-sm-2 text-right">
					<h3>{{ previewModel?.timeLabel ? previewModel?.timeLabel : '0:00/0:00' }}</h3>
				</div>
			</div>

			<!--*******************
            VIDEO CONTROLS
        *********************-->
			<div class="row mgn-top30">
				<div class="col-xs-8">
					<div class="row">
						<div class="col-xs-3">
							<button id="backBtn" (click)="backClicked()" class="setWidth100 white-bg">
								<span class="fa fa-step-backward"></span>
							</button>
						</div>
						<div class="col-xs-3">
							<button (click)="pauseClicked()" class="setWidth100 white-bg">
								<span class="fa fa-pause" class="fa" [ngClass]="{ 'fas fa-play': previewModel?.isPaused, 'fa-pause': !previewModel?.isPaused }"></span>
							</button>
						</div>
						<div class="col-xs-3">
							<button (click)="skipClicked()" class="setWidth100 white-bg">
								<span class="fa fa-step-forward"></span>
							</button>
						</div>
					</div>
				</div>
				<div class="col-xs-4">
					<div class="row">
						<div class="col-xs-6 col-xs-offset-6 col-sm-4 col-sm-offset-8">
							<button (click)="muteClicked()" class="setWidth100 white-bg">
								<span
									class="fa"
									[ngClass]="{ 'fa-volume-off': previewModel?.isMuted, 'fa-volume-up': !previewModel?.isMuted, 'red-text': previewModel?.isMuted }"
								></span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<span *ngIf="previewModel?.currentClip?.isDeleted" class="red-text absolute bottom5 left30 z"><em>This content has been deleted.</em></span>
		</div>
	</div>
</portal-modal>
