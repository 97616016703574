export class Contract {
	onHold: boolean;
	radio: boolean;
	video: boolean;
	poster: boolean;
	customTouchScreen: boolean;
	twoByOneVideoArray: boolean;
	twoByOnePosterArray: boolean;
	other: boolean;
	webPlayer: boolean;
}