<div class="row mgn-top15">
	<div class="col-xs-10 col-xs-offset-1 text-left">
		<span>
			Please enter a new password for
			<em class="semi-bold">{{ loginService.userName }}</em>
			.
		</span>
	</div>
</div>

<!--****************
	NEW PASSWORD
*****************-->
<div class="row mgn-top15">
	<div class="col-xs-10 col-xs-offset-1" (mouseover)="loginService.onPasswordInputMouseover(true)" (mouseleave)="loginService.onPasswordInputMouseover(false)">
		<input
			class="pd-left36 setWidth100 ht55 lt-gray-bg"
			type="{{ loginService.passwordInputType }}"
			[pattern]="loginService.passwordPattern"
			placeholder="New Password"
			(keyup)="onPasswordKeyup()"
			[(ngModel)]="password"
		/>
		<i
			*ngIf="loginService.showRevealPasswordIcon"
			[ngClass]="loginService.passwordInputType === 'password' ? 'fa-eye' : 'fa-eye-slash'"
			class="far absolute top-1.5 right-6 text-xl text-gray-400 p-2 cursor-pointer hidden md:!block"
			ngxTippy
			tippyName="showPasswordReset"
			[attr.data-tippy-content]="loginService.eyeIconTooltip"
			[tippyProps]="{ offset: [0, -5] }"
			(click)="loginService.onShowPasswordClick('showPasswordReset')"
		></i>
		<i
			[ngClass]="loginService.passwordInputType === 'password' ? 'fa-eye' : 'fa-eye-slash'"
			class="far absolute top-1.5 right-6 text-xl text-gray-400 p-2 cursor-pointer md:hidden"
			ngxTippy
			tippyName="showPasswordResetMobile"
			[attr.data-tippy-content]="loginService.eyeIconTooltip"
			[tippyProps]="{ offset: [0, -5] }"
			(click)="loginService.onShowPasswordClickMobile('showPasswordResetMobile')"
		></i>
	</div>
</div>

<!--*******************
	RE-ENTER PASSWORD
********************-->
<div class="row mgn-top20">
	<div class="col-xs-10 col-xs-offset-1" (mouseover)="loginService.onPasswordInputMouseover(true)" (mouseleave)="loginService.onPasswordInputMouseover(false)">
		<input
			class="pd-left36 setWidth100 ht55 lt-gray-bg"
			type="{{ loginService.passwordInputType }}"
			[pattern]="loginService.passwordPattern"
			placeholder="Re-enter Password"
			(keyup)="onPasswordKeyup()"
			(keyup.enter)="onUpdatePasswordClick()"
			[(ngModel)]="repeatPassword"
		/>
		<i
			*ngIf="loginService.showRevealPasswordIcon"
			[ngClass]="loginService.passwordInputType === 'password' ? 'fa-eye' : 'fa-eye-slash'"
			class="far absolute top-1.5 right-6 text-xl text-gray-400 p-2 cursor-pointer hidden md:!block"
			ngxTippy
			tippyName="showPasswordResetReenter"
			[attr.data-tippy-content]="loginService.eyeIconTooltip"
			[tippyProps]="{ offset: [0, -5] }"
			(click)="loginService.onShowPasswordClick('showPasswordResetReenter')"
		></i>
		<i
			[ngClass]="loginService.passwordInputType === 'password' ? 'fa-eye' : 'fa-eye-slash'"
			class="far absolute top-1.5 right-6 text-xl text-gray-400 p-2 cursor-pointer md:hidden"
			ngxTippy
			tippyName="showPasswordResetMobileReenter"
			[attr.data-tippy-content]="loginService.eyeIconTooltip"
			[tippyProps]="{ offset: [0, -5] }"
			(click)="loginService.onShowPasswordClickMobile('showPasswordResetMobileReenter')"
		></i>
	</div>
</div>

<!--******************
	UPDATE BUTTON
*******************-->
<div class="row mgn-top20">
	<div class="col-xs-10 col-xs-offset-1">
		<button
			[disabled]="updateButtonDisabled"
			class="{{ updateButtonDisabled ? 'setOpacityLow' : null }} border-radius red-bg white-text setWidth100 shadow ht38"
			(click)="onUpdatePasswordClick()"
		>
			<span>Update password</span>
		</button>
	</div>
</div>
