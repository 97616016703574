import { Component, Input, OnInit } from '@angular/core';

import { ClipService } from '../../_services';
import { ClipsView } from '../../../../_models';
import { CreateEditRequestService } from '../../../../_services';
import { FileUploadStateService } from 'src/app/shared/services';
import { FileUploadModalService } from 'src/app/shared/components/file-upload-modal/file-upload-modal.service';

@Component({
    selector: 'hv-upload-modal',
    templateUrl: './upload-modal.component.html',
    styleUrls: ['./upload-modal.component.scss']
})
export class UploadModalComponent implements OnInit {

    @Input() bypassModal: boolean; //if wanting to initiate upload immediately upon selecting file
    @Input() clip: ClipsView;
    @Input() isVideoLogo: boolean;

    constructor(
        public clipService: ClipService,
        public createEditRequestService: CreateEditRequestService,
        public fileUploadStateService: FileUploadStateService,
        public fileUploadModalService: FileUploadModalService
    ) { }

    ngOnInit() {
        this.uploadDataInit();
        if(this.bypassModal) {
            //Error without a slight delay
            setTimeout( () => {
                this.onUploadClick(); 
            }, 200)
            return;
        }
        this.fileUploadModalService.modalInit();
        this.fileUploadModalService.getVoiceTalentList();
    }

    public onCancelClick(): void {
        this.fileUploadStateService.resetSteps();
    }

    public onUploadClick(): void {
        this.clip.completedFileUploadInProgress = true;
        this.fileUploadModalService.onUploadClick(this.clip.Id);
    }

    private uploadDataInit(): void {
        this.fileUploadStateService.uploadData.Title = this.clip.Title;
        this.fileUploadStateService.uploadData.Text = this.clip.ScriptText;
        this.fileUploadStateService.uploadData.TargetLibraryId = this.targetLibraryId();
    }

    private targetLibraryId(): number {
        //Video logo hard-wired to Logo Library
        return this.isVideoLogo ? 1000395 : this.createEditRequestService.request.TargetLibraryId;
    }
}
