<div class="row mgn-bottom30">
	<div class="col-xs-7">
		<span class="ft-size16">Font</span>
		<portal-dropdown-menu
			[backgroundColor]="'white-bg'"
			[height]="'ht40'"
			[key]="'name'"
			[options]="textService.fontList"
			[setFontFamily]="true"
			[selectedOption]="layersService.activeLayer.canvasObj.fontFamily"
			(onOptionSelect)="onFontSelect($event)"
		></portal-dropdown-menu>
	</div>
	<div class="col-xs-5">
		<span class="ft-size16">Font Size</span>
		<portal-dropdown-menu
			[backgroundColor]="'white-bg'"
			[height]="'ht40'"
			[key]="'fontSize'"
			[options]="fontSizeList"
			[selectedOption]="layersService.activeLayer.canvasObj.fontSize"
			(onOptionSelect)="onFontSizeSelect($event)"
		></portal-dropdown-menu>
	</div>
</div>

<hr class="mgn0 mgn-top30" />

<div class="row mgn-top30">
	<div class="col-xs-4">
		<button
			[disabled]="layersService.activeLayer.type === 'Feed Text'"
			ngxTippy
			[tippyProps]="{ appendTo: 'parent' }"
			tippyName="{{ utilService.guid | wrapFn }}"
			data-tippy-content="Bold"
			class="{{ layersService.activeLayer.canvasObj.fontWeight === 'bold' ? 'gray-bg-darkest white-text' : 'white-bg dark-text' }} {{
				layersService.activeLayer.type === 'Feed Text' ? 'gray-bg-dark' : null
			}}"
			(click)="onBoldClick()"
		>
			<i class="fas fa-bold {{ layersService.activeLayer.type === 'Feed Text' ? 'gray-text-darkest' : null }}"></i>
		</button>
	</div>
	<div class="col-xs-4">
		<button
			[disabled]="layersService.activeLayer.type === 'Feed Text'"
			ngxTippy
			[tippyProps]="{ appendTo: 'parent' }"
			tippyName="{{ utilService.guid | wrapFn }}"
			data-tippy-content="Italic"
			class="{{ layersService.activeLayer.canvasObj.fontStyle === 'italic' ? 'gray-bg-darkest white-text' : 'white-bg dark-text' }} {{
				layersService.activeLayer.type === 'Feed Text' ? 'gray-bg-dark' : null
			}}"
			(click)="onItalicClick()"
		>
			<i class="fas fa-italic {{ layersService.activeLayer.type === 'Feed Text' ? 'gray-text-darkest' : null }}"></i>
		</button>
	</div>
	<div class="col-xs-4">
		<button
			(click)="showAlignmentOptions = !showAlignmentOptions"
			[disabled]="layersService.activeLayer.type === 'Feed Text'"
			ngxTippy
			[tippyProps]="{ appendTo: 'parent' }"
			tippyName="{{ utilService.guid | wrapFn }}"
			data-tippy-content="Align"
			class="{{ layersService.activeLayer.type === 'Feed Text' ? 'gray-bg-dark' : 'white-bg' }} relative ht40"
		>
			<i class="fas {{ alignBtn() }} absolute left20 top12 {{ layersService.activeLayer.type === 'Feed Text' ? 'gray-text-darkest' : null }}"></i>
			<i class="fas fa-sort-down absolute right20 top12 ft-size12 {{ layersService.activeLayer.type === 'Feed Text' ? 'gray-text-darkest' : null }}"></i>
		</button>
		<!--Alignment Options-->
		<div *ngIf="showAlignmentOptions" class="white-bg border-radius absolute pd7 shadow right15 z ft-size16">
			<div style="display: flex; justify-content: space-between">
				<i
					(mouseup)="onAlignClick('left')"
					ngxTippy
					[tippyProps]="{ appendTo: 'parent', placement: 'bottom' }"
					tippyName="{{ utilService.guid | wrapFn }}"
					data-tippy-content="Left"
					class="fas fa-align-left mgn-right12 align-icon pointer pd8 border-radius {{
						layersService.activeLayer.canvasObj.textAlign === 'left' ? 'gray-bg-darker' : null
					}}"
				></i>
				<i
					(mouseup)="onAlignClick('center')"
					ngxTippy
					[tippyProps]="{ appendTo: 'parent', placement: 'bottom' }"
					tippyName="{{ utilService.guid | wrapFn }}"
					data-tippy-content="Center"
					class="fas fa-align-center mgn-right12 align-icon pointer pd8 border-radius {{
						layersService.activeLayer.canvasObj.textAlign === 'center' ? 'gray-bg-darker' : null
					}}"
				></i>
				<i
					(mouseup)="onAlignClick('right')"
					ngxTippy
					[tippyProps]="{ appendTo: 'parent', placement: 'bottom' }"
					tippyName="{{ utilService.guid | wrapFn }}"
					data-tippy-content="Right"
					class="fas fa-align-right mgn-right12 align-icon pointer pd8 border-radius {{
						layersService.activeLayer.canvasObj.textAlign === 'right' ? 'gray-bg-darker' : null
					}}"
				></i>
				<i
					(mouseup)="onAlignClick('justify')"
					ngxTippy
					[tippyProps]="{ appendTo: 'parent', placement: 'bottom' }"
					tippyName="{{ utilService.guid | wrapFn }}"
					data-tippy-content="Justify"
					class="fas fa-align-justify align-icon pointer pd8 border-radius {{
						layersService.activeLayer.canvasObj.textAlign === 'justify' ? 'gray-bg-darker' : null
					}}"
				></i>
			</div>
		</div>
	</div>
</div>

<hr class="mgn0 mgn-top30" />

<ng-container *ngIf="layerDetailService.showLineHeightLetterSpacing()">
	<div class="row mgn-top30">
		<div class="col-xs-6">
			<span class="ft-size12">Line height:</span>
		</div>
		<div class="col-xs-6">
			<span class="ft-size12">Letter spacing:</span>
		</div>
	</div>

	<div class="row">
		<div class="col-xs-6">
			<input
				type="range"
				min="0.5"
				max="2.5"
				step="0.1"
				[(ngModel)]="layersService.activeLayer.canvasObj.lineHeight"
				class="slider"
				#lineHeightId
				(mouseup)="recordState('lineHeight')"
				(input)="onSliderChange('lineHeight', +lineHeightId.value)"
			/>
			<span class="absolute top15 right15 ft-size9">{{ layersService.activeLayer.canvasObj.lineHeight }}</span>
		</div>
		<div class="col-xs-6">
			<input
				type="range"
				min="-120"
				max="800"
				[(ngModel)]="layersService.activeLayer.canvasObj.charSpacing"
				class="slider"
				#charSpacingId
				(mouseup)="recordState('charSpacing')"
				(input)="onSliderChange('charSpacing', +charSpacingId.value)"
			/>
			<span class="absolute top15 right15 ft-size9">{{ layersService.activeLayer.canvasObj.charSpacing }}</span>
		</div>
	</div>

	<hr class="mgn0 mgn-top30" />
</ng-container>

<ld-shared-controls></ld-shared-controls>
