<!-- noExit class added so the menu doesn't disappear when clicking a menu item -->
<div style="min-height: 500px" class="container-fluid border-radius white-bg shadow-wide ln-height20 animated fadeInDown noExit pd30">
	<div class="row noExit">
		<div class="col-sm-4 border-right noExit">
			<div class="row noExit">
				<div class="col-sm-6 noExit">
					<button class="gray-bg noExit" (click)="onButtonClick('historyNote')">
						<i class="fa fa-history mgn-right12 noExit" aria-hidden="true"></i>
						New history note
					</button>
				</div>
				<div class="col-sm-6 noExit">
					<button class="white-text noExit green-bg" (click)="onButtonClick('clientDetails')">
						<i class="fas fa-user-circle noExit mgn-right12" aria-hidden="true"></i>
						View/edit client
					</button>
				</div>
			</div>
			<div class="row mgn-top30 noExit">
				<div class="col-sm-6 noExit">
					<h3 class="semi-bold mgn-bottom5 noExit">Industry Type:</h3>
					<div class="noExit">
						<span class="noExit">{{ appStateService.currentClient.IndustryType.Name }},</span>
						<span *ngIf="locationsService.locationsCount > 1 || locationsService.locationsCount < 1" class="noExit">
							{{ locationsService.locationsCount }} Locations
						</span>
						<span *ngIf="locationsService.locationsCount === 1" class="noExit">{{ locationsService.locationsCount }} Location</span>
						<br />
					</div>
				</div>
				<div class="col-sm-6 noExit">
					<h3 class="semi-bold mgn-bottom5 noExit">Active Contracts:</h3>
					<div *ngFor="let product of contractedProducts$ | async" class="row mgn-bottom5 noExit">
						<div class="col-xs-10">
							<span [ngSwitch]="true" class="noExit">
								<span *ngSwitchCase="product.ProductName === 'On Hold'" class="noExit">
									<i class="fas fa-phone-volume mgn-right8 noExit" aria-hidden="true"></i>
								</span>
								<span *ngSwitchCase="product.ProductName === 'Works24 Radio'" class="noExit">
									<i class="fas fa-volume-up mgn-right8 noExit" aria-hidden="true"></i>
								</span>
								<span *ngSwitchCase="product.ProductName === 'Poster'" class="noExit">
									<i class="far fa-square mgn-right8 noExit" aria-hidden="true"></i>
								</span>
								<span *ngSwitchDefault class="noExit">
									<i class="fas fa-tv mgn-right8 noExit" aria-hidden="true"></i>
								</span>
							</span>
							<span class="noExit">{{ product.ProductName }}</span>
						</div>
						<div class="col-xs-2">
							<span
								*ngIf="product.textColor"
								ngxTippy
								tippyName="{{ utilService.guid | wrapFn }}"
								[tippyProps]="{ appendTo: 'parent', arrow: false }"
								attr.data-tippy-content="{{ product.lastSuccessfulDownloadDateTime }}"
							>
								<i [ngClass]="product.textColor" class="fa fa-download noExit" aria-hidden="true"></i>
							</span>
						</div>
					</div>
				</div>
			</div>

			<div class="row noExit mgn-top30">
				<div class="col-sm-6">
					<h3 class="semi-bold noExit mgn-bottom5">Billing Address:</h3>
					<span class="noExit">{{ appStateService.currentClient.BillingAddress1 }}</span>
					<br />
					<span class="noExit">
						{{ appStateService.currentClient.BillingCity }}, {{ appStateService.currentClient.BillingState }} {{ appStateService.currentClient.BillingZip }}
					</span>
					<br />
					<em class="noExit">Phone: {{ appStateService.currentClient.MainPhoneNumber }}</em>
					<br />
					<em class="noExit">Fax: {{ appStateService.currentClient.MainFaxNumber }}</em>
					<br />
					<a
						*ngIf="appStateService.currentClient.Website"
						class="noExit"
						href="{{ utilService.addHttp(appStateService.currentClient.Website) }}{{ appStateService.currentClient.Website }}"
						target="_blank"
					>
						{{ appStateService.currentClient.Website }}
					</a>
					<br *ngIf="appStateService.currentClient.Website" />
					<span *ngIf="appStateService.currentClient.RssdId" class="noExit">Fed ID (RSSD): {{ appStateService.currentClient.RssdId }}</span>
					<br />
				</div>
				<div class="col-sm-6">
					<h3 class="semi-bold mgn-bottom5">Last Login:</h3>
					<div *ngIf="lastLoginUser$ | async as lastLoginUser">
						<span>
							{{ lastLoginUser.UserName }} on
							<em>{{ dateTimeService.dateAndTimeUTCtoLocal(lastLoginUser.LastLogin) }}</em>
						</span>
					</div>
				</div>
			</div>
		</div>

		<div class="col-sm-4 border-right ht375 noExit">
			<div class="row noExit">
				<div class="col-sm-12 ht375 noExit">
					<div class="row noExit">
						<div class="col-sm-12 noExit">
							<h3 class="semi-bold mgn-bottom15 noExit">Important Notes:</h3>
							<portal-client-notes [rowCount]="16"></portal-client-notes>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-sm-4 ht375 noExit">
			<div class="row noExit">
				<div class="col-sm-6 noExit">
					<h3 class="semi-bold mgn-bottom18 noExit">System Tools:</h3>
				</div>
				<div *ngIf="securityService.userRoleAdminOrCsr()" class="col-sm-6 noExit">
					<button class="white-text green-bg ft-size16 relative noExit" (click)="onButtonClick('createNewClient')">
						<i class="fas fa-user-circle mgn-right12 relative noExit">
							<i class="fa fa-plus absolute topneg3 left16 ft-size10 noExit"></i>
						</i>
						Create new client
					</button>
				</div>
			</div>
			<div class="row mgn-top30 noExit">
				<div class="col-sm-6 noExit">
					<div class="noExit" [routerLink]="['/client-browser']">
						<a class="dark-text ft-size16 noExit">
							<i class="fas fa-user-circle mgn-right6 noExit" aria-hidden="true"></i>
							Client Browser
						</a>
					</div>
					<br />

					<ng-container *ngIf="!securityService.userRoleSalesperson()">
						<div class="noExit" [routerLink]="['/client-health-dashboard/user-id/' + clientHealthDashboardService.clientHealthDashUserId()]">
							<a class="dark-text ft-size16 noExit">
								<i class="fas fa-chart-pie mgn-right6 noExit" aria-hidden="true"></i>
								Client Health Dashboard
							</a>
						</div>
						<br />
					</ng-container>

					<div class="noExit" [routerLink]="['/client-merge-tool']">
						<a class="dark-text ft-size16 noExit">
							<i class="fas fa-people-arrows mgn-right6 noExit" aria-hidden="true"></i>
							Client Merge Tool
						</a>
					</div>
					<br />

					<div [routerLink]="['/newsletter']" class="noExit">
						<a class="dark-text ft-size16 noExit">
							<i class="far fa-file-alt mgn-right6 noExit" aria-hidden="true"></i>
							Client Newsletter
						</a>
					</div>
					<br />

					<div [routerLink]="['/system-tools/content-requests']" class="noExit">
						<a class="dark-text ft-size16 noExit">
							<i class="far fa-paper-plane mgn-right6 noExit" aria-hidden="true"></i>
							Content Requests
						</a>
					</div>
					<br />

					<div [routerLink]="['/contract-renewals-report']" class="noExit">
						<a class="dark-text ft-size16 noExit">
							<i class="fas fa-file-contract mgn-right6 noExit" aria-hidden="true"></i>
							Contract Renewals
						</a>
					</div>
					<br />

					<div [routerLink]="['/csr-home/user-id/' + appStateService.currentUser.UserId]" class="noExit">
						<a class="dark-text ft-size16 noExit">
							<i class="fas fa-home mgn-right6 noExit" aria-hidden="true"></i>
							CSR Home
						</a>
					</div>
					<br />

					<div class="noExit" [routerLink]="['/system-tools/csr-tasks']">
						<a class="dark-text ft-size16 noExit">
							<i class="fas fa-tasks mgn-right6 noExit" aria-hidden="true"></i>
							CSR Tasks
						</a>
					</div>
					<br />

					<div [routerLink]="['/email-blast-tool/my-mailings']" class="noExit">
						<a class="dark-text ft-size16 noExit">
							<i class="fas fa-bullhorn mgn-right6 noExit" aria-hidden="true"></i>
							Email Blast Tool
						</a>
					</div>
					<br />
				</div>

				<div class="col-sm-6 noExit">
					<div [routerLink]="['/featured-content']" class="noExit">
						<a class="dark-text ft-size16 noExit">
							<i class="far fa-lightbulb mgn-right6 noExit" aria-hidden="true"></i>
							Featured Content
						</a>
					</div>
					<br />

					<div class="noExit" [routerLink]="['/library-editor']">
						<a class="dark-text ft-size16 noExit">
							<i class="fa fa-book mgn-right6 noExit" aria-hidden="true"></i>
							Library Editor
						</a>
					</div>
					<br />

					<div *ngIf="securityService.userRoleAdminOrCsr()" class="noExit" [routerLink]="['/news-manager']">
						<a class="dark-text ft-size16 noExit">
							<i class="far fa-newspaper mgn-right6 noExit" aria-hidden="true"></i>
							News Manager
						</a>
					</div>
					<br *ngIf="securityService.userRoleAdminOrCsr()" />

					<div [routerLink]="['/on-hold-delivery-queue']" class="noExit">
						<a class="dark-text ft-size16 noExit">
							<i class="fa fa-phone-volume mgn-right6 noExit" aria-hidden="true"></i>
							On Hold Delivery Queue
						</a>
					</div>
					<br />

					<div class="noExit" [routerLink]="['/player-status-report']">
						<a class="dark-text ft-size16 noExit">
							<i class="fas fa-hdd mgn-right6 noExit" aria-hidden="true"></i>
							Player Status
						</a>
					</div>
					<br />

					<div class="noExit" [routerLink]="['/record-24']">
						<a class="dark-text ft-size16 noExit">
							<i class="fa fa-microphone mgn-right6 noExit" aria-hidden="true"></i>
							Record24
						</a>
					</div>
					<br />

					<ng-container *ngIf="securityService.userIsAdminOrNatalie()">
						<div class="noExit" [routerLink]="['/reminder-emails']">
							<a class="dark-text ft-size16 noExit">
								<i class="far fa-bell mgn-right6 noExit" aria-hidden="true"></i>
								Reminder Emails
							</a>
						</div>
						<br />
					</ng-container>

					<div class="noExit" [routerLink]="['/ticket-system']">
						<a class="dark-text ft-size16 noExit">
							<i class="fas fa-ticket-alt mgn-right6 noExit" aria-hidden="true"></i>
							Ticket System
						</a>
					</div>
					<br />

					<div class="noExit" [routerLink]="['/usage-reports/library-search']">
						<a class="dark-text ft-size16 noExit">
							<i class="fas fa-chart-bar mgn-right6 noExit" aria-hidden="true"></i>
							Usage Reports
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
