import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { AppStateService, ContentSerializerService, DateTimeService, Events, MessageService } from '../../core/services';
import { ContentAdvancedSchedules, Playlists } from '../api-models/content';
import { GetContentDataService } from '../../shared/services/get-content-data.service';
import { PlayersDragDrop } from '../view-models/admin';
import { ContentItem, PbPlaylist, PlaylistItem } from '../view-models/content';
import { ContentVM } from '../components/content-container/content/_models/content-view';

type PlayersPlaylist = {
	playlist: PbPlaylist;
	players?: PlayersDragDrop[];
};

@Injectable()
export class PrintPlaylistService {
	private channelMembersCounter: number = 0;
	private contentChannelArr: any[] = [];
	private currentDate: any;
	private playlist: PbPlaylist;
	private playlistItemsArr: any[] = [];
	private subs: Subscription[] = [];

	constructor(
		public appStateService: AppStateService,
		private contentSerializerService: ContentSerializerService,
		private dateTimeService: DateTimeService,
		private getContentDataService: GetContentDataService,
		private messageService: MessageService
	) {
		this.getScheduledPlayersSubscribe();
	}

	getScheduledPlayersSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.getScheduledPlayersComplete, ({ playlist, players }: PlayersPlaylist) => {
				this.playlist = playlist;
				this.playlist.scheduledPlayers = players || [];
				this.assignMusicName();
				this.playlistItemsArr.length = 0;

				this.playlist.contentItems.forEach((contentItem) => {
					if (contentItem.isChannel) {
						//  counter equal to the total number of
						// playlist items that ARE channels
						this.channelMembersCounter++;
						this.getContentForChannel(contentItem);
					}
					this.beginPlaylistItemsArrAssembly(contentItem);
				});
				this.asyncFinish();
			})
		);
	}

	private assignMusicName(): void {
		if (this.playlist.contentItems[0].isMusicTrack) {
			this.playlist.musicContentItem = this.playlist.contentItems[0];
		}
	}

	private getContentForChannel(contentItem: ContentItem): void {
		this.getContentDataService.getContentForChannel(contentItem.contentId).subscribe((content: ContentVM[]) => {
			this.channelMembersCounter--;

			const contentItems: ContentItem[] = content.map((content) => {
				return this.contentSerializerService.contentToContentItem(content);
			});
			this.contentChannelArr.push(this.assignPlaylistItemsWithChannelMembers(contentItem, contentItems));
			this.asyncFinish();
		});
	}

	private beginPlaylistItemsArrAssembly(contentItem: ContentItem): void {
		let contentObj: any = {};
		contentObj.name = contentItem.contentName;
		contentObj.contentId = contentItem.contentId;
		contentObj.duration = contentItem.duration;
		contentObj.isMusicTrack = contentItem.isMusicTrack;
		this.playlistItemsArr.push(contentObj);
	}

	private assignChannelMembers(): void {
		this.playlistItemsArr.forEach((obj) => {
			this.contentChannelArr.forEach((contentChannel) => {
				if (contentChannel.id === obj.contentId) {
					obj.name = contentChannel.name;
					obj.channelMembers = contentChannel.channelMembers;
					obj.isChannel = true;
				}
			});
		});
	}

	private assignNonChannelPlaylistItemScript(): void {
		this.playlistItemsArr.forEach((item) => {
			this.playlist.contentItems.forEach((content) => {
				if (content.contentId === item.contentId) {
					item.script = content.script;
				}
			});
		});
	}

	private assemblePlaylistObj(): void {
		this.currentDate = moment().local().format('MM/DD/YYYY');
		this.playlist['playlistItems'] = this.playlistItemsArr;
		this.writePlaylistToPrintWindow(this.playlist, this.buildContentString(this.playlist));
	}

	private assignPlaylistItemsWithChannelMembers(contentItem: ContentItem, contentItems: ContentItem[]): Object {
		let obj: any = new Object();
		obj.name = contentItem.name;
		obj.id = contentItem.contentId;
		obj.channelMembers = contentItems;
		return obj;
	}

	private asyncFinish(): void {
		if (this.channelMembersCounter === 0) {
			this.assignNonChannelPlaylistItemScript();
			this.assignChannelMembers();
			this.assemblePlaylistObj();
			//This playlist can show up in playlist builder, regardless of product unless it is removed here
			this.appStateService.removeSessionItem('playlist');
		}
	}

	private buildContentString(playlist: PbPlaylist): string {
		let contentString: string = '';
		playlist['playlistItems'].forEach((item, i) => {
			if (!item.isMusicTrack) {
				contentString = this.buildNonChannelContentString(contentString, item, playlist.contentItems[i]);
			} else if (item.isMusicTrack) {
				contentString = '';
			}
		});
		return contentString;
	}

	private buildNonChannelContentString(contentString: string, playlistItem: PlaylistItem, contentItem: ContentItem): string {
		const startDate = this.dateTimeService.dateAndTimeUTCtoLocal(contentItem.startDate);
		const stopDate = this.dateTimeService.dateAndTimeUTCtoLocal(contentItem.stopDate);
		contentString = contentString.concat(`<h3>
                                          ${playlistItem.name} - (${this.durationFriendlyFormat(playlistItem.duration)}) ${
			this.showStartStopDates(contentItem)
				? `<span style="font-size: 18px; font-weight: 200;"> Start: <em>${startDate}</em> - Stop: <em>${stopDate}</em></span>`
				: ''
		}  ${
			contentItem.advancedSchedule
				? '<br><br> <span style="font-size: 18px;">Repeating Schedule:</span> <span style="font-size: 18px; font-weight: 300;"> <br>' +
				  this.buildAdvancedScheduleFriendlyString(contentItem.advancedSchedule)
				: ''
		}</span>
                                        </h3>
                                        <span><b>Script: </b>${playlistItem.script}</span>
                                        <hr style="border: 0.5px solid #d6d9db; margin-top: 25px; margin-bottom: 0px">
                                        <br>`);
		return contentString;
	}

	private showStartStopDates(contentItem: ContentItem): boolean {
		const today = moment().startOf('hour');
		const date = moment(new Date(contentItem.startDate));
		const difference = today.diff(date, 'days', true);

		if ((difference < 0 && !contentItem.isStopDateSelected) || contentItem.isStopDateSelected) {
			return true;
		}
		return false;
	}

	private buildScheduledPlayerNameString(): string {
		let scheduledPlayersString: string = '';
		if (this.playlist.scheduledPlayers) {
			this.playlist.scheduledPlayers.forEach((player) => {
				scheduledPlayersString = scheduledPlayersString.concat(`
                    <span>${player.PlayerName}</span><br>
                  `);
			});
		}
		return scheduledPlayersString;
	}

	private buildScheduledPlayerStartDateString(): string {
		let scheduledPlayerStartDateString: string = '';
		if (this.playlist.scheduledPlayers) {
			this.playlist.scheduledPlayers.forEach((player) => {
				scheduledPlayerStartDateString = scheduledPlayerStartDateString.concat(`
                    <span>${moment(player.deliveryQueue.StartDate).local().format('MM/DD/YYYY h:mm a')}</span><br>
                  `);
			});
		}
		return scheduledPlayerStartDateString;
	}

	private durationFriendlyFormat(milli: number): string {
		let seconds: number = Math.floor(milli / 1000) % 60;
		let minutes: number = Math.floor((milli / (60 * 1000)) % 60);
		if (seconds < 10) {
			return minutes + ':' + 0 + seconds;
		} else {
			return minutes + ':' + seconds;
		}
	}

	private htmlString(playlist: any, contentString: string): string {
		let htmlString: string;
		if (this.appStateService.product.Route === 'hold') {
			htmlString = `
                    <html>
                        <head>
                            <title>Print it!</title>
                        </head>
                       <body>
                           <span><strong>Playlist Name: </strong></span><span>${playlist.Name}</span><br>                       
                           <span><strong>Music Name: </strong></span><span>${
															playlist.musicContentItem ? playlist.musicContentItem.contentName : 'No music selected'
														}</span><br>
                           <span><strong>Created Date: </strong></span><span>${
															playlist.CreateDate ? moment(playlist.CreateDate).local().format('MM/DD/YYYY') : this.currentDate
														}</span><br>
                           <h4 style="margin-top: 0;"><strong>Scheduled Players and Dates:</strong></h4>
                           <table style="margin-bottom: 50px;">
                             <tr>
                               <th style="text-align: left; width: 48%; border-bottom: 0.5px solid #d6d9db;">Player Name</th>
                               <th style="text-align: left; width: 36%; border-bottom: 0.5px solid #d6d9db;">Start Date</th>
                             </tr>
                             <tr>
                               <td>
                                 ${this.buildScheduledPlayerNameString()}
                               </td>
                               <td>
                                 ${this.buildScheduledPlayerStartDateString()}
                               </td>
                             </tr>
                           </table>

                           ${contentString}
                       </body>
                    </html>
          `;
		} else {
			htmlString = `
                    <html>
                        <head>
                            <title>Print it!</title>
                        </head>
                       <body>
                           <span><strong>Playlist Name: </strong></span><span>${playlist.Name}</span><br>
                           <span><strong>Created Date: </strong></span><span>${
															playlist.CreateDate ? moment(playlist.CreateDate).local().format('MM/DD/YYYY') : this.currentDate
														}</span><br>
                           <h4 style="margin-top: 0;"><strong>Scheduled Players and Dates:</strong></h4>
                           <table style="margin-bottom: 50px;">
                             <tr>
                               <th style="text-align: left; width: 48%; border-bottom: 0.5px solid #d6d9db;">Player Name</th>
                               <th style="text-align: left; width: 36%; border-bottom: 0.5px solid #d6d9db;">Start Date</th>
                             </tr>
                             <tr>
                               <td>
                                 ${this.buildScheduledPlayerNameString()}
                               </td>
                               <td>
                                 ${this.buildScheduledPlayerStartDateString()}
                               </td>
                             </tr>
                           </table>

                           ${contentString}
                       </body>
                    </html>
          `;
		}
		return htmlString;
	}

	private writePlaylistToPrintWindow(playlist: any, contentString: any): void {
		let newWindow = window.open('', '', 'directories=no,status=no,width=700,height=400,top=250,left=250,scrollbars=1');
		newWindow.document.write(`${this.htmlString(playlist, contentString)}`);
		newWindow.print();
	}

	private buildAdvancedScheduleFriendlyString(model: ContentAdvancedSchedules): string {
		return (
			`${model.MonthsOfYear ? `Months: ${model.MonthsOfYear.replace(/,/g, ', ')}<br>` : ''}` +
			`${model.DaysOfMonth ? `Dates: ${model.DaysOfMonth.replace(/,/g, ', ')}<br>` : ''}` +
			`${model.DaysOfWeek ? `Days: ${model.DaysOfWeek.replace(/,/g, ', ')}<br>` : ''}` +
			`${
				model.YearlyStartDate
					? `Yearly: Start: ${this.dateTimeService.dateUTCtoLocal(model.YearlyStartDate)} End: ${this.dateTimeService.dateUTCtoLocal(model.YearlyEndDate)}<br>`
					: ''
			}` +
			`Start: ${this.dateTimeService.convertUtcTimeToView(model.RecurrenceStartTime)}, ` +
			`Stop: ${this.dateTimeService.convertUtcTimeToView(model.RecurrenceStopTime)}`
		);
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
