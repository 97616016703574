<div style="width: 330px; margin-right: 14px; height: 47px" class="relative">
	<ng-container *ngIf="create24Service.contentType() === 'video'">
		<div (click)="videoControlsService.onClick('play')" style="width: 96px; height: 52px" class="{{ playBtnClassList() }}">
			<i style="right: 38px; top: 17px" class="{{ videoControlsService.playIcon }} white-text absolute pointer"></i>
		</div>
		<div class="in-block mgn-left15 relative topneg5">
			<div class="row">
				<div class="col-xs-2 text-right ft-size18">
					<i
						(mouseup)="videoControlsService.onClick('toStart')"
						class="fa fa-step-backward {{ videoControlsService.isPlaying ? 'gray-text-darker' : 'dark-text pointer' }}"
						aria-hidden="true"
					></i>
				</div>
				<div class="col-xs-8 ft-size26 text-center">
					<span class="relative topneg6 dark-text">{{ videoControlsService.playClock | hoursMinutesSeconds }}</span>
				</div>
				<div class="col-xs-2 text-center ft-size18">
					<i
						(mouseup)="videoControlsService.onClick('toEnd')"
						class="fa fa-step-forward {{ videoControlsService.isPlaying ? 'gray-text-darker' : 'dark-text pointer' }}"
						aria-hidden="true"
					></i>
				</div>
			</div>
			<div *ngIf="showClickPlayText" class="pd10 yellow-bg-darker border-radius absolute bottom4 left2 ft-size16 shadow" id="playHelp">
				<i class="fas fa-angle-left mgn-right6"></i>
				Click Play to begin
			</div>
		</div>
	</ng-container>
</div>
