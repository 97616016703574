<section *ngIf="layersService.atLeastOneFeedLayer()" class="white-bg container-fluid feed">
	<div class="row mgn-top15">
		<div class="col-xs-7">
			<span class="ft-size16">Feed</span>
		</div>
		<div class="col-xs-5">
			<span class="ft-size16">Feed Item</span>
		</div>
	</div>

	<div class="row mgn-top15 mgn-bottom15">
		<div class="col-xs-7">
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[key]="'Name'"
				[options]="canvasService.feedsList ? canvasService.feedsList : null"
				[selectedOption]="canvasService.feed?.Name"
				(onOptionSelect)="onFeedSelect($event)"
			></portal-dropdown-menu>
		</div>
		<div class="col-xs-5">
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[key]="'order'"
				[options]="canvasService.feed?.Items?.length > 0 ? canvasService.feed.Items : null"
				[selectedOption]="canvasService.feedItem?.order"
				(onOptionSelect)="onFeedItemSelect($event)"
			></portal-dropdown-menu>
		</div>
	</div>
</section>

<section
	style="vertical-align: top"
	[ngStyle]="{ 'height.px': ltwService.layerDetailHeight }"
	(scroll)="onScroll()"
	#section
	class="gray-bg scrollable container-fluid setWidth100 in-block layer"
>
	<ng-container *ngIf="!cEventsService.isGroupSelected">
		<div class="row mgn-top15">
			<div class="col-xs-12">
				<h1 class="semi-bold">{{ layersService.activeLayer?.name }}</h1>
			</div>
		</div>
		<hr />

		<ng-container *ngIf="create24Service.contentType() === 'video'">
			<div class="row mgn-top15">
				<div class="col-xs-6">
					<div class="ft-size16 mgn-bottom8">Start time</div>
					<span>{{ startTimeView }}s</span>
					<!-- <input type="number" class="setWidth75 white-bg" [(ngModel)]="startTimeView" (input)="onStartTimeChange()"> -->
				</div>
				<div class="col-xs-6">
					<div class="ft-size16 mgn-bottom8">End time</div>
					<span>{{ endTimeView }}s</span>
				</div>
			</div>
			<hr *ngIf="layersService.activeLayer?.type !== 'Feed Image'" />
		</ng-container>

		<ld-shared-controls *ngIf="layerIsShape()"></ld-shared-controls>
		<ld-text
			*ngIf="
				layersService.activeLayer?.type === 'Text' ||
				layersService.activeLayer?.type === 'Countdown' ||
				layersService.activeLayer?.type === 'Current Date' ||
				layersService.activeLayer?.type === 'Current Time'
			"
		></ld-text>
		<ld-feed *ngIf="layersService.activeLayer?.type === 'Feed Text' || layersService.activeLayer?.type === 'Feed Image'"></ld-feed>
		<ld-image *ngIf="layersService.activeLayer?.type === 'Image' || layersService.activeLayer?.type === 'Feed Image'"></ld-image>

		<div *ngIf="layersService.activeLayer?.type === 'background-image'" class="row mgn-top30">
			<div class="col-xs-12">
				<button (click)="layersService.onChangeContentClick(contentBrowserFeatureArea())" class="white-bg">Change background image</button>
			</div>
		</div>
		<hr *ngIf="showHr()" class="mgn-top30" />

		<div *ngIf="layersService.activeLayer?.type === 'background-image' && !canvasService.bgContent" class="row mgn-top30">
			<div class="col-xs-12 relative">
				<button class="white-bg" (click)="colorPickerOpen = !colorPickerOpen">
					<i class="fas fa-fill-drip mgn-right10"></i>
					Change background color
				</button>
				<div class="absolute top46 left15 white-bg border-radius shadow z">
					<color-chrome
						*ngIf="colorPickerOpen"
						color="{{ layersService.activeLayer.canvasObj.blankBgColor }}"
						[disableAlpha]="true"
						(onChange)="onBlankBgColorChange($event)"
					></color-chrome>
					<div *ngIf="colorPickerOpen" class="white-bg pd-top15 pd-bottom15 mgn0 relative topneg2 buttons row">
						<div class="col-xs-5 col-xs-offset-2">
							<button (click)="colorPickerOpen = false" class="gray-bg">Cancel</button>
						</div>
						<div class="col-xs-5">
							<button (click)="onSaveClick()" class="blue-bg white-text">Save</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="layersService.activeLayer?.type === 'background-video'" class="row mgn-top30">
			<div class="col-xs-12">
				<button (click)="layersService.onChangeContentClick(create24Area.NEW_VIDEO_REPLACE)" class="white-bg">Change video</button>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="cEventsService.isGroupSelected">
		<div class="row mgn-top15">
			<div class="col-xs-12">
				<h1 class="semi-bold">Group</h1>
			</div>
		</div>
		<hr />

		<div class="row mgn-top30">
			<div class="col-xs-12">
				<em>Aligns all selected layers with first layer</em>
			</div>
		</div>

		<div class="row mgn-top15">
			<div class="col-xs-6">
				<button class="white-bg" (click)="onAlignClick('left')">Align left</button>
			</div>
			<div class="col-xs-6">
				<button class="white-bg" (click)="onAlignClick('right')">Align right</button>
			</div>
		</div>
		<div class="row mgn-top30">
			<div class="col-xs-6">
				<button class="white-bg" (click)="onAlignClick('top')">Align top</button>
			</div>
			<div class="col-xs-6">
				<button class="white-bg" (click)="onAlignClick('bottom')">Align bottom</button>
			</div>
		</div>
		<div class="row mgn-top30">
			<div class="col-xs-12">
				<button class="white-bg" (click)="onAlignClick('center')">Align center</button>
			</div>
		</div>
	</ng-container>
</section>

<section class="in-block setWidth100 mgn-bottom60 gray-bg export-btn-wrapper">
	<div class="mgn-left15 mgn-right15 mgn-top15 text-center">
		<button
			(click)="onExportClick()"
			[disabled]="!layerDetailService.feedLayersValid()"
			class="{{ !layerDetailService.feedLayersValid() ? 'setOpacityLow' : null }} limeGreen-bg white-text ft-size18"
		>
			<i class="fas fa-share-square mgn-right12"></i>
			Save to Library
		</button>
	</div>
</section>
