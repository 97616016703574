<div *ngIf="cvStateService.viewState.contentListVisible && cvStateService.contentList; else cvStateService.viewState.contentListVisible && loading">
	<ng-container *ngIf="cvStateService.contentList.length; else noItems">
		<perfect-scrollbar
			class="relative"
			#directiveRef="ngxPerfectScrollbar"
			[ngClass]="{ 'border-bottom': !scrollYReachEnd && viewportWidthService.isLg, 'border-top': !scrollYReachStart && viewportWidthService.isLg }"
			style="max-height: 56vh"
			(psScrollUp)="onScrollUp()"
			(psScrollDown)="onScrollDown()"
			(psYReachEnd)="onScrollYReachEnd()"
			(psYReachStart)="onScrollYReachStart()"
		>
			<div style="min-height: 52vh" class="col-md-12">
				<div dragula="item-bag" [(dragulaModel)]="cvStateService.contentList">
					<div
						*ngFor="
							let content of cvStateService.contentList | paginate : cvStateService.paginationConfig();
							let isEvenIndex = even;
							let isFirstIndex = first;
							let isLastIndex = last
						"
					>
						<c-content
							[content]="content"
							[contentList]="cvStateService.contentList"
							[contentViewState]="{
								layout: viewportWidthService.isLg ? 'list-view' : 'card-view'
							}"
							[isEvenIndex]="isEvenIndex"
							[isFirstIndex]="isFirstIndex"
							[isLastIndex]="isLastIndex"
						></c-content>
					</div>
				</div>
			</div>
			<div *ngIf="showScrollForMore" style="opacity: 0.9" class="absolute bottom0 w-full gray-bg-dark border-radius ht30 text-center pd-top5 z-[99]">
				<span class="ft-size14 semi-bold dark-text">SCROLL FOR MORE</span>
			</div>
		</perfect-scrollbar>
	</ng-container>
	<ng-template #noItems>
		<h3><em>No results found</em></h3>
	</ng-template>
	<div *ngIf="cvStateService.contentList && cvStateService.contentList.length > 0" class="row mgn-bottom60">
		<div class="col-sm-8">
			<pagination-controls (pageChange)="contentService.getPage($event)" class="pagination"></pagination-controls>
		</div>
		<div class="col-sm-4 pd-top16 text-right">
			<span>{{ cvStateService.totalItemsContentList }} Items</span>
		</div>
	</div>
</div>
<ng-template #loading>
	<div class="relative top200 z"><portal-spinner [height]="'htpx76'" [width]="'wdthpx76'"></portal-spinner></div>
</ng-template>
