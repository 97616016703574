import { Component, OnInit, DoCheck, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { CanvasService, CEventsService } from '../canvas/_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { Create24Service } from '../../create-24.service';
import { Feed, FeedItem } from '../_models';
import { FeedService } from './feed/feed.service';
import { InitService } from '../timeline/layers-timeline-wrapper/_services';
import { LayersService, UndoRedoService } from '../_services';
import { Layer, LayerType } from '../_models';
import { LayerDetailService } from './_services/layer-detail.service';
import { LayersTimelineWrapperService } from '../timeline/layers-timeline-wrapper/_services';
import { Create24Area } from '../../_models/create-24-state.enum';

@Component({
	selector: 'me-layer-detail',
	templateUrl: './layer-detail.component.html',
	styleUrls: ['./layer-detail.component.scss']
})
export class LayerDetailComponent implements OnInit, OnDestroy, DoCheck {
	@ViewChild('section') section: ElementRef;

	public colorPickerOpen: boolean;
	public contentDurationView: number;

	public startTimeView: number;
	public endTimeView: number;

	private tempBgColor: string;
	private subs: Subscription[] = [];
	readonly create24Area = Create24Area;

	constructor(
		public canvasService: CanvasService,
		public cEventsService: CEventsService,
		public create24Service: Create24Service,
		public cvStateService: ContentViewStateService,
		private feedService: FeedService,
		private initService: InitService,
		public layerDetailService: LayerDetailService,
		public layersService: LayersService,
		public ltwService: LayersTimelineWrapperService,
		private undoRedoService: UndoRedoService
	) {
		this.onTimelineLayerSelect();
	}

	ngOnInit(): void {
		this.feedService.getFeeds();
	}

	ngDoCheck() {
		if (this.ltwService.contentDuration) {
			this.contentDurationView = this.ltwService.contentDuration / 1000;
		}
		this.startTimeView = +(this.ltwService.pxToMs(this.layersService.activeLayer?.start) / 1000).toFixed(2);
		this.endTimeView = +(this.ltwService.pxToMs(this.layersService.activeLayer?.end) / 1000).toFixed(2);
	}

	onTimelineLayerSelect(): void {
		this.subs.push(
			this.layersService.activeLayerSelected$.subscribe((payload: [number, string]) => {
				if (payload) {
					let layerId: number = payload[0];
					//If layer is not playhead
					if (layerId >= 0) {
						this.layersService.activeLayer = this.layersService.layers.find((layer) => layer.id === layerId);
					}
				}
			})
		);
	}

	public onAlignClick(align: string): void {
		const group = this.canvasService.canvas.getObjects().find((obj) => !!obj.group).group;
		const masterObj = group._objects[0];
		const distanceFromGroupLeft = group?.getScaledWidth() / 2 + masterObj.left;
		const distanceFromGroupTop = group.height / 2 + masterObj.top;
		const masterObjLeftEqualsGroupLeft: boolean = Math.abs(masterObj.left) === masterObj.group.getScaledWidth() / 2;
		let objs: any[];
		//Grouped objects with layerId greater than 1
		if (align !== 'center') {
			objs = this.canvasService.canvas.getObjects().filter((obj) => obj.layerId > 1 && !!obj.group);
		}
		//Include the first layer if centering
		if (align === 'center') {
			objs = this.canvasService.canvas.getObjects().filter((obj) => obj.layerId > 0 && !!obj.group);
		}

		objs.forEach((obj) => {
			let layer = this.layersService.layers.find((layer) => layer.id === obj.layerId);
			switch (align) {
				case 'left':
					if (masterObjLeftEqualsGroupLeft) {
						layer.canvasObj.left = group.left;
						obj.left = masterObj.left;
					} else {
						layer.canvasObj.left = group.left + group.getScaledWidth() - masterObj.getScaledWidth();
						obj.left = masterObj.left;
					}
					break;

				case 'right':
					layer.canvasObj.left = group.left + masterObj.getScaledWidth() + distanceFromGroupLeft - obj.getScaledWidth();
					obj.left = masterObj.left + masterObj.getScaledWidth() - obj.getScaledWidth();
					break;

				case 'top':
					if (obj.name === 'text') {
						layer.canvasObj.top = group.top + distanceFromGroupTop;
					} else {
						layer.canvasObj.top = group.top + distanceFromGroupTop;
					}
					obj.top = masterObj.top;
					break;

				case 'bottom':
					if (obj.name === 'text') {
						layer.canvasObj.top = group.top + distanceFromGroupTop + masterObj.height * masterObj.scaleY - obj.height * obj.scaleY;
						obj.top = masterObj.top + masterObj.height * masterObj.scaleY - obj.height * obj.scaleY;
					} else {
						layer.canvasObj.top = group.top + distanceFromGroupTop + masterObj.height * masterObj.scaleY - obj.height * obj.scaleY;
						obj.top = masterObj.top + masterObj.height * masterObj.scaleY - obj.height * obj.scaleY;
					}
					break;
				case 'center':
					layer.canvasObj.left = (group.getScaledWidth() - obj.getScaledWidth()) / 2 + group.left;
					obj.left = (group.getScaledWidth() - obj.getScaledWidth()) / 2 - group.getScaledWidth() / 2;
					break;
			}
		});
		this.undoRedoService.recordState('Align objects');
		this.canvasService.canvas.renderAll();
		this.layerDetailService.updateFeedTextImage();
	}

	public contentBrowserFeatureArea(): Create24Area {
		const { c24ActiveArea } = this.create24Service;
		switch (c24ActiveArea) {
			case Create24Area.NEW_IMAGE:
			case Create24Area.EDIT_PROJECT:
			case Create24Area.STILL_TEMPLATE:
				return Create24Area.NEW_IMAGE_REPLACE;
			case Create24Area.NEW_SIDEBAR:
				return Create24Area.NEW_SIDEBAR_REPLACE;
			case Create24Area.EDIT_PROJECT_SIDEBAR:
				return Create24Area.SIDEBAR_REPLACE;
		}
	}

	public onFeedSelect(feed: Feed): void {
		this.canvasService.feed = feed;
		this.feedService.updateFeedItem();
	}

	public onFeedItemSelect(feedItem: FeedItem): void {
		this.canvasService.feedItem = feedItem;
		this.canvasService.feedItem.parsedValues = JSON.parse(feedItem.Values);
		//Update values for all feed objects
		this.layerDetailService.updateValuesAllFeedObjs();
	}

	public layerIsShape(): boolean {
		let layerType: LayerType = this.layersService.activeLayer?.type;
		return layerType === 'Circle' || layerType === 'Rectangle' || layerType === 'Line' || layerType === 'Triangle';
	}

	public onBlankBgColorChange(e): void {
		this.tempBgColor = e.color.hex;
	}

	public onSaveClick(): void {
		let bgLayer: Layer = this.layersService.layers.find((layer) => layer.id === 0);
		bgLayer.canvasObj.blankBgColor = this.tempBgColor;
		this.colorPickerOpen = false;
		this.undoRedoService.recordState('Change background color');
	}

	public onDurationChange(e): void {
		let durationMs: number = e * 1000;
		this.ltwService.contentDuration = durationMs;
		this.initService.timelineInit();
	}

	public onExportClick(): void {
		this.create24Service.popup = 'export-to-library';
	}

	public onScroll(): void {
		this.layerDetailService.onLayerDetailScroll(this.section.nativeElement.scrollTop);
	}

	//Horizontal line below change bg image btn
	public showHr(): boolean {
		switch (true) {
			case this.layersService.activeLayer?.type === 'Image':
				return true;
			case this.layersService.activeLayer?.type === 'background-image':
				return this.canvasService.bgContent === null || this.canvasService.bgContent === undefined;
		}
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
		this.canvasService.feed = null;
	}
}
