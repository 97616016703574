import { Injectable } from '@angular/core';

import { AppStateService } from './app-state.service';
import { ContentFilesVM } from '../../shared/components/content-container/content/_models';
import { DateTimeService } from './date-time.service';
import { ContentItem } from '../../shared/view-models/content';
import { TypeGuardService } from './type-guard.service';
import { ContentVM } from 'src/app/shared/components/content-container/content/_models/content-view';

@Injectable({
	providedIn: 'root'
})
export class ContentSerializerService {
	constructor(private appStateService: AppStateService, private dateTimeService: DateTimeService, private typeGuardService: TypeGuardService) {}

	public contentToContentItem(content: Partial<ContentVM>): ContentItem {
		let contentItem: ContentItem = new ContentItem();

		if (this.typeGuardService.contentIsVideo(content) && content.contentFiles) {
			contentItem.thumbIcon = content.thumbIcon;
			contentItem.masterFileName = content.contentFiles[0].MasterFileName;
			contentItem.directory = content.contentFiles[0].Directory;

			contentItem.startDate = this.dateTimeService.todayAtMidnightUTC();
			contentItem.contentFileId = content.contentFiles[0].Id;
			contentItem.previewFileName = content.contentFiles[0].PreviewFileName;
		}
		this.sharedProps(contentItem, content);

		contentItem.contentFiles = content.contentFiles;
		return contentItem;
	}

	public contentFileToContentItem(content: ContentVM, contentFile: ContentFilesVM): ContentItem {
		let contentItem: ContentItem = new ContentItem();
		this.sharedProps(contentItem, content);

		// contentItem.duration = contentFile.DurationMs;
		contentItem.directory = contentFile.Directory;
		contentItem.contentFileId = contentFile.Id;
		contentItem.voiceTalentId = contentFile.VoiceTalentId;
		contentItem.masterFileName = contentFile.MasterFileName;

		if (contentFile.previewUrl) {
			contentItem.previewUrl = contentFile.previewUrl;
		}
		if (contentFile.startDate) {
			contentItem.startDate = contentFile.startDate;
		} else {
			contentItem.startDate = this.dateTimeService.todayAtMidnightUTC();
		}

		if (this.typeGuardService.contentFileIsHold(contentFile) && contentFile.VoiceTalent) {
			contentItem.voiceTalentGender = contentFile.VoiceTalent.TalentGender;
			contentItem.voiceTalentName = contentFile.VoiceTalent.TalentName;
			contentItem.isMusicTrack = contentFile.isMusicTrack;
		}
		if (this.typeGuardService.contentFileIsHold(contentFile)) {
			contentItem.isMusicTrack = contentFile.isMusicTrack;
		}
		contentItem.contentFiles = content.contentFiles;
		return contentItem;
	}

	public contentItemToContent(contentItem: ContentItem): ContentVM {
		let content: any = {};
		content.Title = contentItem.contentName;
		content.Text = contentItem.script;
		content.previewUrl = contentItem.previewUrl;
		content.thumbnailUrl = contentItem.thumbnailUrl;
		content.CategoryId = contentItem.categoryId;
		content.IsChannel = contentItem.isChannel;
		content.CreateDate = contentItem.createDate;
		content.IsDeleted = contentItem.isDeleted;
		content.Id = contentItem.contentId;
		content.isFavorite = contentItem.isFavorite;
		content.favoriteBtnTooltip = contentItem.favoriteBtnTooltip;
		content.LibraryId = contentItem.libraryId;
		content.ContentTypeId = contentItem.contentTypeId;
		content.contentFiles = contentItem.contentFiles;
		content.order = contentItem.order;
		content.contentType = contentItem.contentType ? contentItem.contentType : null;
		if (this.typeGuardService.contentIsVideo(content)) {
			content.thumbIcon = this.icon(content);
		}
		return content;
	}

	public icon(content: ContentVM | ContentItem): string {
		switch (true) {
			case content.contentType === 'video/mp4':
				return 'fas fa-play';
			case content.contentType === 'image/png':
				return 'far fa-image';
			default:
				return '';
		}
	}

	public contentItemToContentFile(contentItem: ContentItem): ContentFilesVM {
		let contentFile: any = {};

		contentFile.PreviewFileName = contentItem.previewFileName;
		contentFile.previewUrl = contentItem.previewUrl;
		contentFile.Id = contentItem.contentFileId;
		contentFile.isMusicTrack = contentItem.isMusicTrack;
		contentFile.VoiceTalent = contentItem.VoiceTalent;
		contentFile.Create24OutputHtmlV4 = contentItem.create24OutputHtmlV4;
		contentFile.Create24ProjectJsonV4 = contentItem.create24ProjectJsonV4;
		return contentFile;
	}

	public contentTypeIdToContentType(contentTypeId: number): string | null {
		switch (true) {
			case [4, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 43, 44, 1000002, 1000005, 1000006, 1000009].some((id) => contentTypeId === id):
				return 'image/png';
			case [6, 8, 9, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 1000007].some((id) => contentTypeId === id):
				return 'video/mp4';
			default:
				return null;
		}
	}

	private sharedProps(contentItem: ContentItem, content: ContentVM | any): void {
		contentItem.contentName = content.Title ? content.Title : content.contentName;
		contentItem.script = content.Text;
		contentItem.previewUrl = content.previewUrl;
		contentItem.categoryId = content.CategoryId;
		contentItem.isChannel = content.IsChannel;
		contentItem.createDate = content.CreateDate;
		contentItem.isDeleted = content.IsDeleted;
		contentItem.contentId = content.contentId ? content.contentId : content.Id;
		contentItem.isFavorite = content.isFavorite;
		contentItem.favoriteBtnTooltip = content.favoriteBtnTooltip;
		contentItem.libraryId = content.LibraryId;
		contentItem.contentTypeId = content.ContentTypeId;
		contentItem.contentType = content.contentType;
		contentItem.order = content.order;
		contentItem.contentTypeId = content.ContentTypeId;

		if (this.typeGuardService.contentIsVideo(content)) {
			contentItem.thumbnailUrl = content.thumbnailUrl;
		}
		if (content.contentFiles && content.contentFiles.length > 0) {
			this.setStillImageDuration(content, content.contentFiles[0], contentItem);
		}

		//Will be true only for Featured Playlists
		if (content.startDate) {
			contentItem.startDate = content.startDate;
		}
		//Will be true only for Featured Playlists
		if (content.stopDate) {
			contentItem.stopDate = content.stopDate;
			contentItem.isStopDateSelected = true;
		}
	}

	private setStillImageDuration(content: ContentVM, contentFile: ContentFilesVM, contentItem: ContentItem): void {
		if (content.ContentTypeId === 4 || content.ContentTypeId === 43 || content.ContentTypeId === 1000006) {
			//still image
			contentItem.duration = this.appStateService.currentClient.DefaultStillDurationMs;
		} else {
			contentItem.duration = contentFile.DurationMs;
		}
	}
}
