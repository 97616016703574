<div *ngIf="!isFeedImageLayer()" class="row relative mgn-top30 mgn-bottom30">
	<div class="col-xs-12">
		<button
			(click)="layersService.onChangeContentClick(create24Area.IMAGE_LAYER_REPLACE)"
			[disabled]="layerDetailService.isCropping"
			class="{{ layerDetailService.isCropping ? 'setOpacityLow' : null }} white-bg"
		>
			Change image
		</button>
	</div>
</div>
<hr />

<ng-container *ngIf="!isFeedImageLayer()">
	<!--IMAGE HEIGHT/WIDTH TITLES-->
	<div class="row mgn-top15">
		<div class="col-xs-5">
			<div class="ft-size16 mgn-bottom8">Width</div>
		</div>

		<div class="col-xs-5 col-xs-offset-2">
			<div class="ft-size16 mgn-bottom8">Height</div>
		</div>
	</div>
	<!--IMAGE HEIGHT/WIDTH VALUES-->
	<div class="row mgn-top8 mgn-bottom30">
		<div class="col-xs-5">
			<input
				type="number"
				[(ngModel)]="widthPx"
				(input)="onWidthChange()"
				class="{{ layerDetailService.isCropping ? 'setOpacityLow' : null }} setWidth100 white-bg ht40"
			/>
		</div>
		<div class="col-xs-2 pd-left0 pd-right0 center">
			<button (click)="dimensionsLinked = !dimensionsLinked" class="white-bg {{ dimensionsLinked ? 'gray-bg-darker' : 'white-bg' }}">
				<i class="fas fa-link"></i>
			</button>
		</div>
		<div class="col-xs-5">
			<input
				type="number"
				[(ngModel)]="heightPx"
				(input)="onHeightChange()"
				class="{{ layerDetailService.isCropping ? 'setOpacityLow' : null }} setWidth100 white-bg ht40"
			/>
		</div>
	</div>
	<hr />
</ng-container>

<ld-shared-controls></ld-shared-controls>
