import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { UsageReportsStore } from './usage-reports.store';

@Component({
    selector: 'app-usage-reports',
    templateUrl: './usage-reports.component.html',
    styleUrls: ['./usage-reports.component.scss'],
    providers: [UsageReportsStore]
})
export class UsageReportsComponent implements OnInit {



    constructor(
       private location: Location,
       public usageReportsStore: UsageReportsStore
    ) {}

    ngOnInit(): void {}

    public onBackClick(): void {
        this.usageReportsStore.setShowBackBtn(false);
        this.location.back();
    }
}
