import { Injectable } from '@angular/core';

import { ContentAdvancedSchedules } from '../../shared/api-models/content';


@Injectable({
	providedIn: 'root'
})
export class AdvancedScheduleSummaryService {

	constructor() { }

	public friendlyStringOutput(advancedSchedule: ContentAdvancedSchedules): string {
		switch (advancedSchedule.RecurrenceTypeId) {
			case 1:
				return 'Repeats every day';

			case 2:
				return 'Repeats weekly';

			case 3:
				return 'Repeats monthly';

			case 4:
				return 'Repeats yearly';
		}
	}
}
