import { AfterViewInit, Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ClientHealthDashboardService } from '../client-health-dashboard.service';
import { ClientHealthViewService } from '../client-health-view.service';
import { DateTimeService } from 'src/app/core/services';
import { GumballCircle } from '../gumball-circle.model';

@Component({
	selector: 'app-client-details',
	templateUrl: './client-details.component.html',
	styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('myDoughnut') myDoughnut: ElementRef<HTMLCanvasElement>;

	constructor(
		private activatedRoute: ActivatedRoute,
		public clientHealthDashboardService: ClientHealthDashboardService,
		public clientHealthViewService: ClientHealthViewService,
		public dateTimeService: DateTimeService
	) {}

	ngOnInit(): void {
		this.clientHealthDashboardService.showClientDetails = true;
	}

	ngAfterViewInit(): void {
		const clientId: number = +this.activatedRoute.snapshot.paramMap.get('clientId');
		this.clientHealthDashboardService.getSingleClient(clientId).subscribe((client: GumballCircle[]) => {
			this.clientHealthDashboardService.selectedClient = client[0];
			this.clientHealthDashboardService.getPlayersByClient(
				this.clientHealthDashboardService.selectedClient.ClientId,
				this.myDoughnut.nativeElement.getContext('2d')
			);
		});
	}

	public onPlayerCircleHover(player: GumballCircle): void {
		if (!this.clientHealthViewService.persistInfoBox) {
			this.clientHealthViewService.hoveredElement = document.getElementById(JSON.stringify(player.PlayerId));
			this.clientHealthViewService.onCircleHover(player);
		}
	}

	ngOnDestroy() {
		this.clientHealthViewService.hoveredClientOrPlayer = null;
	}
}
