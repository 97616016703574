import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppStateService, Events, MessageService, SecurityService, UtilitiesService } from '../../../../../../core/services';
import { BatchChangeCategoryService } from '../../../../content-browser/_services';
import { ContentViewService } from '../../_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { Create24Service } from 'src/app/user-tools/create-24/create-24.service';
import { Create24Area } from 'src/app/user-tools/create-24/_models/create-24-state.enum';
import { ContentVM } from '../../_models/content-view';

@Component({
	selector: 'vc-c24-view',
	templateUrl: './c24-view.component.html',
	styleUrls: ['./c24-view.component.scss']
})
export class C24ViewComponent implements OnInit {
	@Input() content: ContentVM;
	@Input() contentList: ContentVM[];
	@Input() isFirstIndex: boolean;
	@Input() isLastIndex: boolean;
	@Input() isEvenIndex: boolean;

	public contentDeletable: boolean;
	public fileNameTooltipVisible: boolean;

	constructor(
		public appStateService: AppStateService,
		public batchChangeCategoryService: BatchChangeCategoryService,
		public contentViewService: ContentViewService,
		private create24Service: Create24Service,
		private cvStateService: ContentViewStateService,
		private messageService: MessageService,
		private router: Router,
		public securityService: SecurityService,
		public utilService: UtilitiesService
	) {}

	ngOnInit(): void {
		this.contentDeletable = this.cvStateService.contentDeletable(this.content);
	}

	public onSelectClick(content: ContentVM): void {
		const { activeUrl } = this.appStateService;
		let replaceContent: boolean = this.utilService.includes(activeUrl, 'replace');
		if (!replaceContent) {
			switch (this.create24Service.c24ActiveArea) {
				case Create24Area.NEW_VIDEO:
				case Create24Area.NEW_IMAGE:
				case Create24Area.NEW_SIDEBAR:
					this.routeAndSelectBgContent([content, replaceContent]);
					break;
				case Create24Area.IMAGE_LAYER_CREATE:
					this.routeAndSelectLayerContent(content, activeUrl);
					break;
				case Create24Area.VIDEO_TEMPLATE:
				case Create24Area.STILL_TEMPLATE:
				case Create24Area.SIDEBAR_TEMPLATE:
					this.routeAndSelectExistingProject(content);
					break;
				case Create24Area.EDIT_PROJECT:
					this.routeAndSelectExistingProject(content);
					break;
				case Create24Area.FEED_ITEM:
					this.selectForFeedItem(content);
					break;
			}
		} else {
			switch (this.create24Service.c24ActiveArea) {
				case Create24Area.NEW_IMAGE_REPLACE:
				case Create24Area.NEW_VIDEO_REPLACE:
				case Create24Area.NEW_SIDEBAR_REPLACE:
				case Create24Area.SIDEBAR_REPLACE:
					this.contentViewService.onSelectBgContent([content, replaceContent]);
					break;
				case Create24Area.IMAGE_LAYER_REPLACE:
					this.contentViewService.onSelectLayerContent(content);
					break;
			}
			this.router.navigate([`${this.utilService.removeLastParam(activeUrl)}`]);
		}
	}

	private selectForFeedItem(content: ContentVM): void {
		this.messageService.publish(Events.getCreate24FeedItem, content);
	}

	private routeAndSelectBgContent(payload: [ContentVM, boolean]): void {
		const { c24ActiveArea } = this.create24Service;
		const { Route } = this.appStateService.product;
		const content = payload[0];
		this.router.navigate([`create-24/editor/${Route}/${c24ActiveArea}/${this.contentViewService.c24ContentType(content)}`]);
		setTimeout(() => {
			this.contentViewService.onSelectBgContent(payload);
		}, 10);
	}

	private routeAndSelectExistingProject(content: ContentVM): void {
		const { c24ActiveArea } = this.create24Service;
		const { Route } = this.appStateService.product;
		if (this.create24Service.c24ActiveArea === Create24Area.EDIT_PROJECT && content.ContentTypeId === 1000009) {
			//sidebar
			this.router.navigate([`create-24/editor/${Route}/edit-project-sidebar/${this.contentViewService.c24ContentType(content)}`]);
		} else {
			this.router.navigate([`create-24/editor/${Route}/${c24ActiveArea}/${this.contentViewService.c24ContentType(content)}`]);
		}

		setTimeout(() => {
			this.contentViewService.onSelectExistingProject(content);
		}, 10);
	}

	private routeAndSelectLayerContent(content: ContentVM, activeUrl: string): void {
		this.router.navigate([`${this.utilService.removeLastParam(activeUrl)}`]);
		setTimeout(() => {
			this.contentViewService.onSelectLayerContent(content);
		}, 10);
	}
}
