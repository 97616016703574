import { Component, HostListener, Input, OnInit } from '@angular/core';

import { AppStateService, UtilitiesService } from '../../core/services';
import { environment } from '../../../environments/environment';
import { GroupManagerService } from '../group-manager.service';
import { Groups, PlayersView } from '../_models';

@Component({
	selector: 'gm-group',
	templateUrl: './group.component.html',
	styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {
	@HostListener('window:mousedown', ['$event'])
	mouseDown() {
		this.mouseIsDown = true;
	}

	@HostListener('window:mouseup', ['$event'])
	mouseUp() {
		this.onDrop();
		this.mouseIsDown = false;
	}

	@Input() group: Groups;
	@Input() searchActive: boolean;

	public dragContainerId: string;
	public nameEditable: boolean;

	private draggedPlayer: PlayersView;
	public dropAreaVisible: boolean;
	private mouseIsDown: boolean;
	private playerExists: boolean;

	constructor(public appStateService: AppStateService, public groupManagerService: GroupManagerService, public utilService: UtilitiesService) {}

	ngOnInit(): void {
		this.dragContainerId = 'dragContainerId';
	}

	public onDeleteGroupClick(): void {
		const confirmDelete = confirm(`Delete group "${this.group.Name}?"`);
		if (confirmDelete) {
			this.groupManagerService.delete(`${environment.adminUrl}CorePlayerGroups/${this.group.Id}`);
		}
	}

	public onGroupNameSaveClick(): void {
		this.nameEditable = false;
		this.groupManagerService.updateGroupName(this.group);
	}

	public onDropAreaHover(): any {
		if (this.mouseIsDown) {
			this.draggedPlayer = JSON.parse(this.appStateService.getSessionItem('draggedPlayer'));
			this.playerExists = false;
			this.dropAreaVisible = true;

			//See if player already exists in group
			const matchingPlayer: PlayersView = this.group.players.find((player) => player.Id === this.draggedPlayer.Id);
			if (matchingPlayer) {
				this.playerExists = true;

				//prevents players in groups from triggering the alert below when
				//drag is disabled but alert would still execute without this check
				if (this.draggedPlayer.isDraggable) {
					this.dragContainerId = '';
					alert('Player is already in this group!');
				}
			} else {
				this.dragContainerId = 'dragContainerId';
			}
		}
	}

	private onDrop(): void {
		if (this.mouseIsDown && this.dropAreaVisible && this.draggedPlayer.isDraggable) {
			if (!this.playerExists) {
				this.groupManagerService.postPlayerGroupsBridge(this.group.Id, this.draggedPlayer.Id);
			}
		}
		this.dropAreaVisible = false;
	}

	public onNameClick(): void {
		this.nameEditable = true;
		if (this.group.Name === 'Click to enter group name') {
			this.group.Name = '';
		}
	}
}
