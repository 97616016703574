import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ClientHealthDashboardService } from '../client-health-dashboard/client-health-dashboard.service';
import { ClientHealthViewService } from '../client-health-dashboard/client-health-view.service';
import { CsrHomeService } from './csr-home.service';
import { CsrTasks } from 'src/app/shared/api-models/admin';
import { CsrTasksService } from '../csr-tasks/_services';
import { AppStateService, EncryptService, Events, MessageService } from 'src/app/core/services';
import { CsrTasksView } from '../csr-tasks/_models';
import { CsrHomeContentRequestTable } from 'src/app/content-requests/_models';
import { GumballCircle } from '../client-health-dashboard/gumball-circle.model';

type NameAndId = { Name: string; Id: number };

@Component({
	selector: 'app-csr-home',
	templateUrl: './csr-home.component.html',
	styleUrls: ['./csr-home.component.scss']
})
export class CsrHomeComponent implements OnInit, AfterViewInit, OnDestroy {
	public showCompletedOnly: boolean = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		private appStateService: AppStateService,
		public clientHealthDashboardService: ClientHealthDashboardService,
		public clientHealthViewService: ClientHealthViewService,
		public csrHomeService: CsrHomeService,
		private csrTasksService: CsrTasksService,
		private encryptService: EncryptService,
		private messageService: MessageService,
		private router: Router
	) {}

	ngOnInit(): void {}

	ngAfterViewInit() {
		const userId: number = +this.activatedRoute.snapshot.paramMap.get('userId');
		const isCsrHomeView: boolean = true;
		this.clientHealthDashboardService.pageInit(userId, null, isCsrHomeView);
		this.csrHomeService.getMyTasks(this.showCompletedOnly, userId);
		this.csrHomeService.getMyContentRequests(userId);
	}

	public onClientClick(client: GumballCircle): void {
		this.router.navigate([`/client-health-dashboard/client-details/${client.ClientId}`]);
		this.clientHealthViewService.hoveredClientOrPlayer = null;
	}

	public onCsrSelect(user: NameAndId): void {
		this.clientHealthDashboardService.selectedUser = user;
		this.router.navigate([`/csr-home/user-id/${this.clientHealthDashboardService.selectedUser.Id}`]);
		this.clientHealthDashboardService.getUserAndClients(this.clientHealthDashboardService.selectedUser.Id);
		this.csrHomeService.getMyTasks(this.showCompletedOnly, this.clientHealthDashboardService.selectedUser.Id);
		this.csrHomeService.getMyContentRequests(this.clientHealthDashboardService.selectedUser.Id);
	}

	public onClientCircleHover(client: GumballCircle): void {
		this.clientHealthViewService.hoveredElement = document.getElementById(JSON.stringify(client.ClientId));
		this.clientHealthViewService.onCircleHover(client);
	}

	public onTaskClientNameClick(task: CsrTasks): void {
		localStorage.setItem('changeClientUrl', `/crm/client-details/locations/`);
		window.open(`/my-products/${task.ClientId}`);
	}

	public onRequestClientNameClick(request: CsrHomeContentRequestTable): void {
		localStorage.setItem('changeClientUrl', `/crm/client-details/locations/`);
		window.open(`/my-products/${request.ContentRequest.ClientId}`);
	}

	public onEditTaskClick(task: CsrTasks): void {
		this.router.navigate([`system-tools/csr-tasks/edit-task/${task.Id}`]);
	}

	public onCompleteTaskClick(task: CsrTasksView): void {
		this.messageService.publish(Events.savingPreloader, 1);
		if (task.completeBtn === 'fas fa-check') {
			task.CompleteDate = new Date().toISOString();
			task.Completed = true;
			this.updateTask(task);
			this.csrTasksService.postHistoryNote(task).subscribe();
		} else if (task.completeBtn === 'fas fa-undo-alt') {
			task.CompleteDate = null;
			task.Completed = false;
			this.updateTask(task);
		}
	}

	public onContentRequestClick(request: CsrHomeContentRequestTable): void {
		this.router.navigate([`/system-tools/content-requests/${this.encryptService.encryptData(JSON.stringify(request.ContentRequest.Id))}`]);
	}

	public onCrmClick(): void {
		localStorage.setItem('changeClientUrl', `/crm/client-details/locations/`);
		window.open(`/my-products/${this.appStateService.currentClient.Id}`);
	}

	public onShowCompletedTasksClick(): void {
		this.showCompletedOnly = !this.showCompletedOnly;
		this.csrHomeService.getMyTasks(this.showCompletedOnly, this.clientHealthDashboardService.selectedUser.Id);
	}

	private updateTask(task: CsrTasksView): void {
		this.csrTasksService.updateTask(task).subscribe(() => {
			this.csrHomeService.getMyTasks(this.showCompletedOnly, this.clientHealthDashboardService.selectedUser.Id);
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}

	ngOnDestroy() {
		clearInterval(this.clientHealthDashboardService.timer);
	}
}
