import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ContentRequestType } from '../../../shared/api-models/admin';
import { CreateEditRequestService, CreateEditViewStateService } from '../_services';
import { DetailsService } from './_services';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'cer-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

    constructor(
        private detailsService: DetailsService,
        private httpClient: HttpClient,
        public viewStateService: CreateEditViewStateService
    ) { }

    ngOnInit() {
        this.detailsService.getLibraries().subscribe();
        this.detailsService.producers$ = this.detailsService.getAllProducers();
        this.detailsService.requestTypes$ = this.httpClient.get<ContentRequestType[]>(`${environment.adminUrl}ContentRequestRequestTypes`);
        this.detailsService.usersByClient$ = this.detailsService.getUsersByClient();
    }
}
