import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { authGuard, contractGuard, userGuard } from '../core/services/route-guards';
import { GroupManagerComponent } from './group-manager.component';

const routes: Routes = [
	{
		path: '',
		canActivate: [authGuard, contractGuard, userGuard],
		component: GroupManagerComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class GroupManagerRoutingModule {}
