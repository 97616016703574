import { Component, OnInit, OnDestroy } from '@angular/core';

import { AppStateService, Events, MessageService } from 'src/app/core/services';
import { ClipsService } from './clips.service';
import { ClipsView, ContentFilesViewHold } from '../_models';
import { CreateEditRequestService, CreateEditViewStateService, EmailService, HeightService } from '../_services';
import { PlaylistService } from 'src/app/playlist-builder/_services';
import { Subscription } from 'rxjs';

@Component({
	selector: 'cer-clips',
	templateUrl: './clips.component.html',
	styleUrls: ['./clips.component.scss']
})
export class ClipsComponent implements OnInit, OnDestroy {
	public angleIcon: string;
	public newClipVideo: ClipsView;
	public newClipVideoDetailsVisible: boolean;

	private subs: Subscription[] = [];

	constructor(
		private appStateService: AppStateService,
		public clipsService: ClipsService,
		public createEditRequestService: CreateEditRequestService,
		private emailService: EmailService,
		public heightService: HeightService,
		private messageService: MessageService,
		private playlistService: PlaylistService,
		public viewStateService: CreateEditViewStateService
	) {
		this.showClipPreviewModal();
	}

	ngOnInit() {
		this.angleIcon = 'chevron-right';
		if (this.viewStateService.mode === 'edit') {
			this.clipsService.getClips().subscribe();
		} else if (this.viewStateService.mode === 'new') {
			if (!this.appStateService.getSessionItem('requestsForMerge')) {
				this.createEditRequestService.request.clips = [];
			}
			//Initialize the request with one message
			if (this.createEditRequestService.request.RequestType === 'On-Hold Message') {
				this.onAddMessageClick();
			}

			//For video logos, insert two clips by default
			if (this.createEditRequestService.request.RequestType === 'Video-Logo') {
				this.createEditRequestService.request.clips.push(new ClipsView('Logo Overlay'), new ClipsView('Screen Layouts Logo'), new ClipsView('Create24 Logo'));
			}
		}

		if (this.clipsService.pendingScriptApprovalClientView()) {
			this.clipsService.showApprovalInstructionsCheck();
		}
	}

	showClipPreviewModal(): void {
		this.subs.push(
			this.messageService.subscribe(Events.showPbModal, (payload: [string, number]) => {
				const [stringId, clipId] = [...payload];
				const clip: ClipsView = this.createEditRequestService.request.clips.find((clip) => clip.completedVideoFile?.Id === clipId);

				//Set these for the preview modal
				this.playlistService.selectedContentItem.contentName = clip.Title;
				this.playlistService.selectedContentItem.script = clip.ScriptText;
				this.playlistService.selectedContentItem.description = clip.Description;
				this.createEditRequestService.showPreviewModal = true;
			})
		);
	}

	public onAddMessageClick(): void {
		this.clipsService.newClip = new ClipsView();
		this.clipsService.newClipInit();
		this.createEditRequestService.request.clips.unshift(this.clipsService.newClip);
	}

	public onAddNewClipClick(): void {
		this.createEditRequestService.request.clips.push(this.newClipVideo);
		this.newClipVideoDetailsVisible = false;
		this.angleIcon = 'chevron-right';
		if (this.viewStateService.mode === 'edit') {
			this.clipsService.saveNewClip(this.newClipVideo);
			this.emailService.sendEmail(this.emailService.addVideoClipEmail()).subscribe();
		}
		this.newClipVideo = null;
	}

	//Remove popupBox on content files when scrolling
	public onScroll(): void {
		this.createEditRequestService.request.clips.forEach((clip) => {
			if (clip.contentFiles) {
				clip.contentFiles.forEach((contentFile: ContentFilesViewHold) => (contentFile.showActionsBox = false));
			}
		});
	}

	public onPrintScriptsClick(): void {
		const newWindow = window.open('', '', 'directories=no,status=no,width=1224,height=1584,top=250,left=250,scrollbars=1');
		const contentString: string = '';
		newWindow.document.write(`${this.clipsService.htmlString(this.clipsService.buildContentString(contentString))}`);
	}

	//Toggle new video clip accordion
	public onToggleClick(): void {
		if (this.createEditRequestService.request.RequestType !== 'Video-Logo') {
			!this.newClipVideo ? (this.newClipVideo = new ClipsView()) : null;
			this.newClipVideoDetailsVisible = !this.newClipVideoDetailsVisible;
			if (this.newClipVideoDetailsVisible) {
				this.angleIcon = 'chevron-down';
			} else {
				this.angleIcon = 'chevron-right';
			}
		}
	}

	public showHideInstructionsClick(instructionsVisible: boolean): void {
		this.createEditRequestService.request.approvalInstructionsVisible = instructionsVisible;
		this.appStateService.setUserItem('approvalInstructionsVisible', JSON.stringify(this.createEditRequestService.request.approvalInstructionsVisible));
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
