import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as uuid from 'uuid/v4';

import { ContentFilesViewHold, ContentFilesViewVideo, ClipsView } from '../../../_models';
import { ContentRequestClipAttachments } from 'src/app/shared/api-models/admin';
import { CreateEditRequestService, CreateEditViewStateService } from '../../../_services';
import { environment } from 'src/environments/environment';
import { Events, MessageService } from 'src/app/core/services';

@Injectable()
export class ClipService {

    public clip: ClipsView;
    public key: string;
    public percentCompleteCompletedFile: number;
    public percentCompleteAttachment: number;

    private formChanged: Subject<string> = new Subject<string>();

    constructor(
        private createEditRequestService: CreateEditRequestService,
        private messageService: MessageService,
        private httpClient: HttpClient,
        private viewStateService: CreateEditViewStateService
    ) {
        this.formChanged.pipe(
            debounceTime(500),
        ).subscribe(() => {
            this.saveClip();
        })
    }

    public onKeyup(key: string, clip: ClipsView): void {
        if (this.viewStateService.mode === 'edit') {
            this.key = key;
            this.clip = clip;
            this.formChanged.next();
        }
    }


    public deleteAttachment(attachment: ContentRequestClipAttachments, clip: ClipsView): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.httpClient.delete(`${environment.adminUrl}ContentRequestClipAttachments/${attachment.Id}`)
            .subscribe(() => {
                this.messageService.publish(Events.savingPreloader, 0);
                clip.attachments = clip.attachments.filter(a => a.Id !== attachment.Id);
            })
    }

    public deleteClip(clip: ClipsView): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.httpClient.delete(`${environment.adminUrl}ContentRequestClips/${clip.Id}`)
            .subscribe( () => {
                this.messageService.publish(Events.savingPreloader, 0);
                location.reload();
            })
    }

    public deleteCompletedFile(clip: ClipsView): void {
        this.messageService.publish(Events.savingPreloader, 1);
        clip.CompletedContentId = null;
        clip.IsApproved = false;
        
        const clipPut$ = this.httpClient.put(`${environment.adminUrl}ContentRequestClips/${clip.Id}`, clip);
        const deleteContentFile$ = this.httpClient.delete(`${environment.contentUrl}ContentFiles/${clip.completedVideoFile.Id}`);

        forkJoin([clipPut$, deleteContentFile$])
            .subscribe( () => {
                this.messageService.publish(Events.savingPreloader, 0);
                clip.completedVideoFile = null;
            })
    }


    public saveClip(): void {
        this.messageService.publish(Events.savingPreloader, 1);

        const contentPatch$ = this.httpClient.patch(`${environment.contentUrl}Content/${this.clip?.CompletedContentId}`, {Text: this.clip?.ScriptText});
        const clipPut$ = this.httpClient.put(`${environment.adminUrl}ContentRequestClips/${this.clip.Id}`, this.clip);

        //Make sure CompletedContentId exists, otherwise just update the CR clip
        const arr$ = !!this.clip.CompletedContentId ? [contentPatch$, clipPut$] : [clipPut$];

        forkJoin(arr$)
            .subscribe( () => {
                this.messageService.publish(Events.savingPreloader, 0);
            })
    }

    public updateCoreClients(clipIndex: number, clip: ClipsView): void {
        //For Video Logos, bug logo will always be index 1, content logo index 2
        let key: string;
        if(clipIndex === 1) {
            key = 'BugLogoContentId';
        }
        if(clipIndex === 2) {
            key = 'ContentLogoContentId';
        }
        const clients = { [key]: clip.CompletedContentId };
        this.httpClient.patch(`${environment.adminUrl}CoreClients/${this.createEditRequestService.request.ClientId}`, clients)
            .subscribe(() => {
                this.messageService.publish(Events.savingPreloader, 0);
            })
    }

    public getAttachments(clip: ClipsView): Observable<ContentRequestClipAttachments[]> {
        return this.httpClient.get<ContentRequestClipAttachments[]>(`${environment.adminUrl}ContentRequestClips/${clip.Id}/Attachments`);
    }

    public getCompletedContentFiles(clip: any): Observable<any> {
        return this.httpClient.get(environment.contentUrl + `Content/${clip.CompletedContentId}/contentFiles`).pipe(
            mergeMap((contentFiles: (ContentFilesViewHold | ContentFilesViewVideo)[]) => {

                const tempContentFiles = contentFiles.map((contentFile) => {
                    contentFile.previewUrl = `${environment.contentUrl}File/${this.previewUrlString()}${contentFile.Id}/Preview`;
                    contentFile.inRevision = contentFile.ContentFileStateId === 7;
                    return contentFile;
                }).filter(contentFile => !contentFile.IsDeleted);

                if (this.createEditRequestService.request.RequestType !== 'On-Hold Message') {
                    const contentTypeArr$ = tempContentFiles.map((contentFile) => {
                        return this.httpClient.get(`${environment.contentUrl}File/${this.createEditRequestService.thumbnailString()}${contentFile.Id}/Thumbnail` + `?${uuid()}`, { observe: 'response' });
                    })

                    return forkJoin(contentTypeArr$).pipe(
                        map((res: any) => {

                            clip.contentFiles = tempContentFiles.map((contentFile: ContentFilesViewVideo, index) => {
                                const contentType = res[index].headers.get('x-original-content-type');
                                contentFile.contentType = contentType;
                                contentFile.thumbIcon = this.icon(contentType);
                                contentFile.thumbnailUrl = `${environment.contentUrl}File/${this.createEditRequestService.thumbnailString()}${contentFile.Id}/Thumbnail`;
                                return contentFile;
                            })
                            clip.completedVideoFile = clip.contentFiles[0] as ContentFilesViewVideo;
                        })
                    )
                }
                clip.contentFiles = tempContentFiles;
                return of([]);
            })
        )
    }

    public icon(contentType: string): string {
        switch (true) {
            case contentType === 'video/mp4':
                return 'fas fa-play';
            case contentType === 'image/png':
                return 'fa-picture-o';
        }
    }

    public previewUrlString(): string {
        if (this.createEditRequestService.request.RequestType === 'On-Hold Message') {
            return 'Hold/Message/';
        } else {
            return 'Video/';
        }
    }
}
