import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { authGuard, canDeactivateGuard, contractGuard, userGuard } from 'src/app/core/services/route-guards';
import { ContentBrowserComponent } from './content-browser.component';

const routes: Routes = [
	{
		path: '',
		canActivate: [authGuard, contractGuard, userGuard],
		canDeactivate: [canDeactivateGuard],
		component: ContentBrowserComponent
	},
	{
		path: 'playlist-in-progress',
		canActivate: [authGuard, contractGuard],
		canDeactivate: [canDeactivateGuard],
		component: ContentBrowserComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ContentBrowserRoutingModule {}
