<div style="border-radius: 16px" class="row pd-top10 pd-bottom10 mgn-top15 mgn-left15 mgn-right15 white-bg box-shadow hidden md:!block">
	<ng-container *ngIf="promoBarService.promoBarState === 'read'">
		<div [innerHTML]="newsletter.ProductsPagePromoBarHtml | safeHtml"></div>
	</ng-container>
	<ng-container *ngIf="promoBarService.promoBarState === 'edit'">
		<textarea
			class="w-full"
			rows="5"
			#promoBar
			(input)="promoBarService.onPromoBarChange(promoBar.value, newsletter)"
			[innerHTML]="newsletter.ProductsPagePromoBarHtml | safeHtml"
		></textarea>
	</ng-container>
</div>
