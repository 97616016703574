export class ContentAdvancedSchedules {
	Id?: number;
	ClientId: number;
	ScheduleName: string;
	RecurrenceStartTime: string;
	RecurrenceStopTime: string;
	RecurrenceTypeId: number;
	RecurrenceSubtypeId: number;
	DaysOfWeek: string;
	DaysOfMonth: string;
	MonthsOfYear: string;
	YearlyStartDate: string;
	YearlyEndDate: string;

	isValid: boolean;
}
