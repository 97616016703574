import { Pipe, PipeTransform } from '@angular/core';

import { AppStateService } from '../../../../core/services';
import { LibrariesTree } from '../_models';

@Pipe({
    name: 'productTypeFilter',
    pure: false
})

export class ProductTypeFilterPipe implements PipeTransform {

    constructor(private appStateService: AppStateService) {}


    transform(libraries: LibrariesTree[]): any {
    	return libraries.filter( (library) => {
            //show libraries by product type and hide global libraries from non-works24 employees
    		if(library.ProductTypeId === this.appStateService.product.Id && (this.appStateService.currentUser.IsEmployee || !library.IsGlobal) && library.WriteAccess) {
    			return true;
            }
            if(library.AllowCreate24) {
                return true;
            }
    		return false;
    	});
    }

}