import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EntryTypesService {

  constructor() { }

  public entryTypes: any[] = [
  	{
  		name: 'Appointment',
  	},
  	{
  		name: 'Completed Task',
  	},
  	{
  		name: 'Content Request',
  	},
  	{
  		name: 'CSR',
  	},
  	{
  		name: 'Incoming Call',
  	},
  	{
  		name: 'Incoming Email',
  	},
  	{
  		name: 'Install Ticket',
  	},
  	{
  		name: 'Other',
  	},
  	{
  		name: 'Outgoing Call',
  	},
  	{
  		name: 'Outgoing Email',
  	},
  	{
  		name: 'Repair Ticket',
  	},
  	{
  		name: 'Trouble Ticket',
  	},
  	{
  		name: 'Video Content Request',
  	},
  	{
  		name: 'Website Issue',
  	},
  ]
}
