import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { AppStateService, UtilitiesService } from '../../../core/services';
import { CreateEditPlayerService, MacValidationService, PlayerOptionsService, SaveService, ViewService } from '../_services';
import { GlobalSettingsService } from '../../../global-settings/global-settings.service';
import { PlayersView } from '../_models';
import { VoipFormats } from '../../../shared/api-models/admin';
import { EPlayerModels } from 'src/app/shared/api-models/admin/player-models.enum';

@Component({
	selector: 'cep-hold-view',
	templateUrl: './hold-view.component.html',
	styleUrls: ['./hold-view.component.scss']
})
export class HoldViewComponent implements OnInit {
	@Output() onCancelClickNotify = new EventEmitter<any>();
	@ViewChild('form', { static: true }) form;

	private voipEmailListValid: boolean;
	readonly playerModel = EPlayerModels;

	get showMacField() {
		const { player } = this.createEditPlayerService;
		return player.PlayerModel.PlayerModelTypeId === 3;
	}

	get showAsterisk() {
		const { PlayerModelId } = this.createEditPlayerService.player;
		return (
			PlayerModelId !== this.playerModel.LINUX_HOLD &&
			PlayerModelId !== this.playerModel.HALO_NETWORK_HOLD &&
			PlayerModelId !== this.playerModel.SEVENTY_ONE_HUNDRED
		);
	}

	constructor(
		public appStateService: AppStateService,
		public createEditPlayerService: CreateEditPlayerService,
		private globalSettingsService: GlobalSettingsService,
		public location: Location,
		public macValidationService: MacValidationService,
		public playerOptionsService: PlayerOptionsService,
		public saveService: SaveService,
		public utilService: UtilitiesService,
		public viewService: ViewService
	) {}

	ngOnInit() {
		this.voipEmailListValid = true;
		this.getHoldPlayerModels();
		if (this.createEditPlayerService.viewState === 'edit') {
			if (this.createEditPlayerService.playerIsTypeVOIP()) {
				this.getVoipFormats();
			}
		} else if (this.createEditPlayerService.viewState === 'new') {
			this.getVoipFormats();
		}
	}

	public onVoipEmailChange(value: string): void {
		if (value.length > 0) {
			this.voipEmailListValid = this.globalSettingsService.validateEmailList(value);
			return;
		}
		this.voipEmailListValid = true;
	}

	public saveBtnDisabled(): boolean {
		const playerModelTypeId: number = this.createEditPlayerService.player.PlayerModel.PlayerModelTypeId;
		const macValidationServicePlayer: PlayersView = this.macValidationService.player;
		const player: PlayersView = this.createEditPlayerService.player;

		if (this.createEditPlayerService.viewState === 'edit') {
			return (
				!this.form.valid ||
				!this.voipEmailListValid ||
				(playerModelTypeId === 3 &&
					player.PlayerModelId !== this.playerModel.HALO_NETWORK_HOLD &&
					player.PlayerModelId !== this.playerModel.LINUX_HOLD &&
					macValidationServicePlayer &&
					macValidationServicePlayer.Id !== player.Id) ||
				this.saveService.savingInProgress
			);
		} else if (this.createEditPlayerService.viewState === 'new') {
			return !this.form.valid || !this.voipEmailListValid || this.saveService.savingInProgress;
		}
	}

	private getHoldPlayerModels(): void {
		this.playerOptionsService.getHoldPlayerModels().subscribe((holdPlayerTypes) => {
			this.playerOptionsService.holdPlayerModels = holdPlayerTypes;
		});
	}

	private getVoipFormats(): void {
		this.playerOptionsService.getVoipFormats().subscribe((voipFormats: VoipFormats[]) => {
			this.playerOptionsService.voipFormats = this.utilService.sortItems(voipFormats, 'FriendlyName');
		});
	}
}
