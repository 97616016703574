import { Injectable } from '@angular/core';

import { Column, Table, TableConfig } from '../../shared/components/table/view-models';

@Injectable()
export class TableConfigService {
	//VIDEO VIEW
	public videoColumns: Column[] = [
		new Column('ClientName', 'Client Name', 'string').setIsSortable(true).setElementClassList('semi-bold'),
		new Column('Title', 'Title', 'string').setIsSortable(true),
		new Column('ClipCount', 'Clips', 'string').setIsSortable(true),
		new Column('CreateDateTime', 'Created', 'date').setIsSortable(true),
		new Column('DueDate', 'Due', 'date').setIsSortable(true),
		new Column('ApprovedDateTime', 'Approved', 'date').setSortKey('ApprovedDateTime').setIsSortable(true),
		new Column('CsrName', 'Csr', 'string').setIsSortable(true),
		new Column('ProducerName', 'Producer', 'string').setIsSortable(true),
		new Column('RequestedByName', 'Requested By', 'string').setIsSortable(true),
		new Column('StateName', 'Status', 'string').setIsSortable(true)
	];

	public videoTable: Table = new Table(this.videoColumns, 'id');

	public videoConfig: TableConfig = {
		itemsPerPage: 50,
		rowSelect: true,
		headerColor: 'white-bg',
		headerFontSize: 'ft-size12'
	};

	//HOLD VIEW
	public holdColumns: Column[] = [
		new Column('Id', 'ID', 'string').setIsSortable(true),
		new Column('DueDate', 'Due', 'date').setIsSortable(true),
		new Column('ClientName', 'Client Name', 'string').setIsSortable(true).setElementClassList('semi-bold'),
		new Column('ClipCount', 'Messages', 'string').setIsSortable(true),
		new Column('Title', 'Title', 'string').setIsSortable(true),
		new Column('StateName', 'Status', 'string').setIsSortable(true),
		new Column('VoiceTalent', 'Voice', 'string').setIsSortable(true),
		new Column('CsrName', 'Csr', 'string').setIsSortable(true)
	];

	public holdColumnsAudioContentCreator: Column[] = [
		new Column('selectBox', '', 'checkbox', false, 'relative z'),
		new Column('Id', 'ID', 'string').setIsSortable(true),
		new Column('DueDate', 'Due', 'date').setIsSortable(true),
		new Column('ClientName', 'Client Name', 'string').setIsSortable(true),
		new Column('ClipCount', 'Messages', 'string').setIsSortable(true),
		new Column('Title', 'Title', 'string').setIsSortable(true),
		new Column('StateName', 'Status', 'string').setIsSortable(true),
		new Column('VoiceTalent', 'Voice', 'string').setIsSortable(true),
		new Column('CsrName', 'Csr', 'string').setIsSortable(true)
	];

	public holdTableAudioContentCreator: Table = new Table(this.holdColumnsAudioContentCreator, 'id');
	public holdTable: Table = new Table(this.holdColumns, 'id');

	public holdConfig: TableConfig = {
		itemsPerPage: 50,
		rowSelect: true,
		headerColor: 'white-bg',
		headerFontSize: 'ft-size12'
	};

	//ALL PRODUCTS INTERNAL VIEW
	public allProductsColumns: Column[] = [
		new Column('ClientName', 'Client Name', 'string').setIsSortable(true).setElementClassList('semi-bold'),
		new Column('Title', 'Title', 'string').setIsSortable(true),
		new Column('ClipCount', 'Clips', 'string').setIsSortable(true),
		new Column('RequestType', 'Type', 'string').setIsSortable(true),
		new Column('CreateDateTime', 'Created', 'date').setIsSortable(true),
		new Column('DueDate', 'Due', 'date').setIsSortable(true),
		new Column('ApprovedDateTime', 'Approved', 'date').setIsSortable(true),
		new Column('CsrName', 'Csr', 'string').setIsSortable(true),
		new Column('ProducerName', 'Producer', 'string').setIsSortable(true),
		new Column('RequestedByName', 'Requested By', 'string').setIsSortable(true),
		new Column('StateName', 'Status', 'string').setIsSortable(true)
	];

	public allProductsTable: Table = new Table(this.allProductsColumns, 'id');

	public allProductsConfig: TableConfig = {
		itemsPerPage: 50,
		rowSelect: true,
		headerColor: 'white-bg',
		headerFontSize: 'ft-size12'
	};

	//CLIENT VIEW
	public clientColumns: Column[] = [
		new Column('viewBtn', 'View', 'button', false, 'gray-bg'),
		new Column('Title', 'Title', 'string').setIsSortable(true),
		new Column('ClipCount', 'Messages', 'string').setIsSortable(true),
		new Column('CreateDateTime', 'Created', 'date').setIsSortable(true),
		new Column('RequestedByName', 'Requested By', 'string').setIsSortable(true),
		new Column('VoiceTalent', 'Voice', 'string').setIsSortable(true),
		new Column('clientStatusView', 'Status', 'string')
	];

	public clientTable: Table = new Table(this.clientColumns, 'id');

	public clientConfig: TableConfig = {
		itemsPerPage: 50,
		headerColor: 'blue-bg',
		rowFontSize: 'ft-size15'
	};
}
