<div
	style="width: 96px; float: left; height: 600px"
	(window:mouseup)="undoRedoService.autoCloseDropdown($event)"
	class="relative top0 gray-bg sidebar in-block"
>
	<div class="container-fluid mgn-top15">
		<!--IMAGE-->
		<div class="row center ft-size30">
			<div class="col-xs-12">
				<i (click)="onAddImageClick()" class="far fa-image {{ layersService.layers.length === 30 ? 'setOpacityLow' : 'pointer' }}"></i>
			</div>
		</div>
		<div class="row center ft-size30 mgn-top15 relative">
			<div class="col-xs-12">
				<span class="noExit {{ layersService.layers.length === 30 ? 'setOpacityLow' : 'pointer' }}" (click)="onShapePickerClick()">
					<i class="far fa-square noExit"></i>
					<i
						style="bottom: 4px"
						class="absolute right22 fa fa-caret-right ft-size14 rotated noExit {{ layersService.layers.length === 30 ? null : 'pointer' }}"
						aria-hidden="true"
					></i>
				</span>
			</div>

			<!--SHAPE PICKER-->
			<div
				*ngIf="showShapePicker"
				(window:mouseup)="autoClose($event)"
				class="absolute top30 text-left left85 wdth188 ft-size18 white-bg shadow z border-radius"
			>
				<div style="border-bottom: 1px solid #bbc0c3" (click)="onShapeClick('Circle', 'far fa-circle', 'green')" class="pd15 pointer noExit shape-container">
					<i class="far fa-circle mgn-right15 noExit"></i>
					<span class="noExit">Circle</span>
				</div>
				<div style="border-bottom: 1px solid #bbc0c3" (click)="onShapeClick('Rectangle', 'fas fa-square', 'red')" class="pd15 pointer noExit shape-container">
					<i class="fas fa-square mgn-right15 noExit"></i>
					<span class="noExit">Rectangle</span>
				</div>
				<div
					style="border-bottom: 1px solid #bbc0c3"
					(click)="onShapeClick('Line', 'fas fa-arrows-alt-h', 'purple')"
					class="pd15 pointer noExit shape-container"
				>
					<i class="fas fa-arrows-alt-h mgn-right15 noExit"></i>
					<span class="noExit">Line</span>
				</div>
			</div>
		</div>

		<!--TEXT-->
		<div class="row center ft-size30 mgn-top15 relative">
			<div class="col-xs-12">
				<i class="fas fa-font {{ layersService.layers.length === 30 ? 'setOpacityLow' : 'pointer' }}" (click)="onAddTextClick()"></i>
			</div>
		</div>

		<!--FEED-->
		<div
			*ngIf="contentType !== 'sidebar' && create24Service.c24ActiveArea !== create24Area.NEW_SIDEBAR && showDynamicOptionsIcon"
			class="row center ft-size30 mgn-top15 relative"
		>
			<div class="col-xs-12">
				<i class="fas fa-sync-alt noExit {{ layersService.layers.length === 30 ? 'setOpacityLow' : 'pointer' }}" (click)="onShowFeedPickerClick()"></i>
			</div>

			<div
				*ngIf="showFeedPicker"
				(window:mouseup)="autoClose($event)"
				class="absolute top30 text-left left85 wdth188 ft-size18 white-bg shadow z border-radius"
			>
				<div style="border-bottom: 1px solid #bbc0c3" (click)="onAddFeedTextClick()" class="pd15 pointer noExit shape-container">
					<i class="fas fa-font mgn-right15 noExit"></i>
					<span class="noExit">Feed Text</span>
				</div>
				<div style="border-bottom: 1px solid #bbc0c3" (click)="onAddFeedImageClick()" class="pd15 pointer noExit shape-container">
					<i class="far fa-image mgn-right15 noExit"></i>
					<span class="noExit">Feed Image</span>
				</div>
			</div>
		</div>

		<!--COUNTDOWN-->
		<div *ngIf="showDynamicOptionsIcon" class="row center ft-size30 mgn-top15 relative">
			<div class="col-xs-12">
				<i
					class="far fa-calendar-alt noExit {{
						layersService.layers.length === 30 ||
						(createLayerSidebarService.countdownExists() && createLayerSidebarService.currentDateExists() && createLayerSidebarService.currentTimeExists())
							? 'setOpacityLow'
							: 'pointer'
					}}"
					(click)="onCountdownClick()"
				></i>
				<i
					*ngIf="appStateService.product.Route === 'video'"
					class="fas fa-question-circle red-text pointer absolute right16 topneg6 ft-size12"
					[ngxTippy]="htmlCountdown"
					tippyName="{{ utilService.guid | wrapFn }}"
					[tippyProps]="{ allowHTML: true, interactive: true, placement: 'top', appendTo: 'parent', arrow: true }"
				></i>
				<ng-template #htmlCountdown>
					<div [ngStyle]="{ 'width.px': 210 }" class="text-left">
						<p>Tutorial:</p>
						<span>
							<ng-container *ngFor="let link of c24CountdownLinks">
								<i class="fas fa-video red-text mgn-right10"></i>
								<a class="white-text underline tutorial" href="{{ link.url }}" target="_blank">
									{{ link.title }}
								</a>
								<br />
							</ng-container>
						</span>
					</div>
				</ng-template>
			</div>
		</div>

		<!--COUNTDOWN OPTIONS-->
		<cls-countdown-and-date
			*ngIf="createLayerSidebarService.showCountdownOptionsPopup"
			(hideCountdownOptions)="createLayerSidebarService.showCountdownOptionsPopup = false"
		></cls-countdown-and-date>

		<!--UNDO-->
		<div class="row center ft-size30 absolute setWidth100 mgn-bottom30 bottom60">
			<div class="col-xs-12 pointer" (click)="undoRedoService.onUndoRedoClick($event, 'undo')">
				<i class="fas fa-undo-alt {{ !undoRedoService.canUndo() ? 'gray-text-darker' : null }}" aria-hidden="true"></i>
				<span class="ft-size20 relative topneg8 {{ !undoRedoService.canUndo() ? 'gray-text-darker' : null }}">Undo</span>
			</div>

			<!--HISTORY PANEL-->
			<div
				*ngIf="undoRedoService.showHistoryPanel"
				style="z-index: 10000"
				class="noExit wdth300 max-height370 scrollable shadow white-bg border-radius pd15 absolute left88 top0 text-left"
			>
				<div class="row noExit">
					<div class="col-xs-6 noExit">
						<h4 class="semi-bold noExit">History</h4>
					</div>
					<div class="col-xs-6 text-right ft-size18 noExit">
						<i (click)="undoRedoService.showHistoryPanel = false" class="noExit fas fa-times pd5 border-radius pointer"></i>
					</div>
				</div>
				<div class="noExit red-gradient border-radius ht1 mgn-top15 mgn-bottom15"></div>
				<div
					*ngFor="let past of undoRedoService.past; let i = index"
					(click)="undoRedoService.onUndoRedoClick($event, 'historyPanel', i)"
					(mouseover)="undoRedoService.historyHoverIndex = i"
					(mouseleave)="undoRedoService.historyHoverIndex = null"
					[ngClass]="i === undoRedoService.historyHoverIndex ? 'active' : null"
					class="row noExit pointer pd5 ft-size14 mgn-bottom10"
				>
					<div class="col-xs-8 noExit">
						<span class="noExit">{{ past.tooltip }}</span>
					</div>
					<div *ngIf="undoRedoService.index === i" class="col-xs-4 text-right">
						<i class="fas fa-check"></i>
					</div>
				</div>
			</div>
		</div>

		<!--REDO-->
		<div class="row center ft-size30 absolute setWidth100 bottom0">
			<div (click)="undoRedoService.onUndoRedoClick($event, 'redo')" class="col-xs-12 pointer">
				<i class="fas fa-redo-alt {{ !undoRedoService.canRedo() ? 'gray-text-darker' : null }}" aria-hidden="true"></i>
				<span class="ft-size20 relative topneg8 {{ !undoRedoService.canRedo() ? 'gray-text-darker' : null }}">Redo</span>
			</div>
		</div>
	</div>
</div>
