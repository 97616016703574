<!--****************
	USERNAME
*****************-->
<div class="row mgn-top15">
	<div class="col-xs-10 col-xs-offset-1">
		<input class="pd-left36 setWidth100 ht55 lt-gray-bg" type="text" placeholder="Username" [(ngModel)]="username" />
		<i class="fa fa-user gray-text-dark absolute wdth10 left24 top15 ft-size22" id="user-icon" aria-hidden="true"></i>
	</div>
</div>

<!--****************
	PASSWORD
*****************-->
<div class="row mgn-top20">
	<div class="col-xs-10 col-xs-offset-1" (mouseover)="loginService.onPasswordInputMouseover(true)" (mouseleave)="loginService.onPasswordInputMouseover(false)">
		<input
			class="pd-left36 setWidth100 ht55 lt-gray-bg"
			type="{{ loginService.passwordInputType }}"
			placeholder="Password"
			autofocus="{{ autoFocus }}"
			(keyup.enter)="onSignInClick()"
			[(ngModel)]="password"
		/>
		<i class="fa fa-lock gray-text-dark absolute wdth10 left24 top15 ft-size22" id="user-icon" aria-hidden="true"></i>
		<i
			*ngIf="loginService.showRevealPasswordIcon"
			[ngClass]="loginService.passwordInputType === 'password' ? 'fa-eye' : 'fa-eye-slash'"
			class="far absolute top-1.5 right-6 text-xl text-gray-400 p-2 cursor-pointer hidden md:!block"
			ngxTippy
			tippyName="showPassword"
			[attr.data-tippy-content]="loginService.eyeIconTooltip"
			[tippyProps]="{ offset: [0, -5] }"
			(click)="loginService.onShowPasswordClick('showPassword')"
		></i>
		<i
			[ngClass]="loginService.passwordInputType === 'password' ? 'fa-eye' : 'fa-eye-slash'"
			class="far absolute top-1.5 right-6 text-xl text-gray-400 p-2 cursor-pointer md:hidden"
			ngxTippy
			tippyName="showPasswordMobile"
			[attr.data-tippy-content]="loginService.eyeIconTooltip"
			[tippyProps]="{ offset: [0, -5] }"
			(click)="loginService.onShowPasswordClickMobile('showPasswordMobile')"
		></i>
	</div>
</div>

<!--******************
	SIGN IN BUTTON
*******************-->
<div class="row mgn-top20">
	<div class="col-xs-10 col-xs-offset-1">
		<button class="border-radius red-bg white-text setWidth100 shadow ht38" (click)="onSignInClick()">
			<span *ngIf="!signInClicked">Sign in</span>
			<portal-spinner *ngIf="signInClicked" [height]="'htpx18'" [width]="'wdthpx18'"></portal-spinner>
		</button>
	</div>
</div>

<!--***********************************
	REMEMBER ME/FORGOT PASSWORD LINK
************************************-->
<div class="row mgn-top30">
	<div class="col-xs-10 col-xs-offset-1">
		<div class="row">
			<div class="col-sm-6 col-sm-offset-3 text-center center-xs margin">
				<a class="relative top3" (click)="onForgotPasswordClick()">Forgot password?</a>
			</div>
		</div>
	</div>
</div>
