<portal-header></portal-header>
<!--*********************
	HEADER BAR
**********************-->
<div class="container-fluid">
	<!-- <div class="row">
		<div class="col-xs-3 setHeight100">
			<a class="white-text" [routerLink]="['/my-players/' + appStateService.product.Route]">
				<i class="fa fas fa-chevron-left mgn-right10 fontSize" aria-hidden="true"></i>
				<span class="hidden-xs hidden-sm {{browserDetectorService.osIsWindows() ? 'semi-bold' : null}}">My Players</span>
			</a>
		</div>

		<div class="col-xs-3 col-xs-offset-6 ft-size16 text-right white-text">
			<i class="{{appStateService.product.DashIcon}} mgn-right10 border-radius relative top5 ft-size26"
			 aria-hidden="true"></i>
			<span class="hidden-xs hidden-sm hidden-md {{browserDetectorService.osIsWindows() ? 'semi-bold' : null}}">{{appStateService.product.ProductName}}</span>
		</div>
	</div> -->

	<div class="row mgn-bottom15 mgn-top15">
		<div class="col-xs-4 col-md-2">
			<div class="row">
				<div class="col-xs-12 col-lg-9">
					<button [routerLink]="['/my-players/' + appStateService.product.Route]" class="white-bg"><i class="fas fa-chevron-left mgn-right12"></i>Dashboard</button>
				</div>
			</div>
			
		</div>
		<div class="col-xs-6 col-xs-offset-2 col-md-offset-4 text-right">
			<i class="fas fa-tags mgn-right12 ft-size28 {{appStateService.product.color}}-text"></i><h1 class="in-block"><span class="semi-bold">Group Manager</span> | <em>{{appStateService.product.ProductName}}</em></h1>
		</div>
	</div>

	<div class="{{appStateService.product.gradient}} mgn-bottom45 ht2 border-radius"></div>

	<gm-drag-drop></gm-drag-drop>
</div>

