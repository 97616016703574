import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { EncryptService, Events, MessageService } from '../../core/services';
import { Feeds, FeedSources, FeedProperties, Stock, FeedItems, FeedStockSymbol } from '../../shared/api-models/content';

@Injectable({
	providedIn: 'root'
})
export class CreateEditFeedService {
	public feed: Feeds;
	public feedItems: FeedItems[];
	public viewMode: 'edit' | 'new';

	constructor(private encryptService: EncryptService, private messageService: MessageService, private httpClient: HttpClient, private router: Router) {}

	public getFeed(feedId: number): Observable<Feeds> {
		return this.httpClient.get<Feeds>(`${environment.contentUrl}Feeds/${feedId}`).pipe(
			mergeMap((feed: Feeds) => {
				const feedProperties$ = this.httpClient.get<FeedProperties>(`${environment.contentUrl}feeds/${feedId}/Properties`);

				return forkJoin([this.getFeedSource(feed), feedProperties$]).pipe(
					map((res) => {
						const feedSource: any = res[0];
						const feedProperties: FeedProperties = res[1][0];

						feed.feedSource = feedSource;
						feed.properties = feedProperties;
						if (feed.FeedSourceId === 8) {
							feed.stockSymbols = JSON.parse(feedProperties.Value);
						} else {
							feed.activeItemsCount = feedProperties?.Value;
						}
						return feed;
					})
				);
			})
		);
	}

	public getFeedSource(feed: Feeds): Observable<FeedSources> {
		return this.httpClient.get<FeedSources>(`${environment.contentUrl}FeedSources/${feed.FeedSourceId}`);
	}

	public getStock(stockSymbol: string): Observable<Stock> {
		return this.httpClient.get<Stock>(`${environment.contentUrl}Stocks/${stockSymbol}`);
	}

	public activeItemsDropdownVisible(): boolean {
		return (
			this.feed.FeedSourceId !== 8 && //stocks
			this.feed.FeedSourceId !== 10 && //day in history
			this.feed.FeedSourceId !== 12
		); //celebrity birthdays
	}

	public activeFeedItemsList(): { name: number }[] {
		let num: number = 1;
		const activeItems: any[] = [];
		let maxNum: number;

		maxNum = this.feed.FeedSourceId === 1000005 ? 6 : 11; //1000005 is ticker feed

		while (num < maxNum) {
			activeItems.push({ name: num });
			num++;
		}
		return activeItems;
	}

	public feedValid(): boolean {
		return this.feed.Name.length > 0;
	}

	public saveFeed(): void {
		this.messageService.publish(Events.savingPreloader, 1);

		const feedCopy: Feeds = JSON.parse(JSON.stringify(this.feed));
		delete feedCopy.feedSource;
		feedCopy.CreateDate = new Date().toISOString();

		const postFeed$ = this.httpClient.post<Feeds>(`${environment.contentUrl}feeds`, feedCopy);
		const patchFeedName$ = this.httpClient.patch<Feeds>(`${environment.contentUrl}feeds/${this.feed.Id}`, { Name: this.feed.Name });
		const updateFeedProperties$ = this.httpClient.put(`${environment.contentUrl}feedProperties/${this.feed.properties?.Id}`, this.feedProperties());

		if (this.viewMode === 'new') {
			postFeed$.subscribe((feed: Feeds) => {
				this.feed.Id = feed.Id;
				const postFeedProperties$ = this.httpClient.post(`${environment.contentUrl}feedProperties/`, this.feedProperties());
				postFeedProperties$.subscribe(() => {
					this.messageService.publish(Events.savingPreloader, 0);
					this.router.navigate([`/custom-data-feeds/feed-items/${this.encryptService.encryptData(JSON.stringify(feed.Id))}`]);
				});
			});
		}
		if (this.viewMode === 'edit') {
			forkJoin([patchFeedName$, updateFeedProperties$]).subscribe(() => {
				this.messageService.publish(Events.savingPreloader, 0);
			});
		}
	}

	public feedProperties(): FeedProperties {
		const feedProperties: FeedProperties = {
			FeedId: this.feed.Id,
			IsEditable: false,
			DisplayOrdinal: 0,
			Key: 'Count',
			Value: this.feed.activeItemsCount
		};
		if (this.viewMode === 'edit') {
			feedProperties.Id = this.feed.properties.Id;
		}
		return feedProperties;
	}
}
