import { ContentAdvancedSchedules } from '../../api-models/content';
import { ContentItem } from '../../view-models/content';
import { Playlists } from '../../api-models/content';

export class SavedPlaylists extends Playlists {
	startDate: string;
	stopDate: string;
	status: string; //'New' | 'Inactive' | 'Active' | 'Scheduled' | 'Draft'
	statusView: string;
	preview: any;
	previewBtnIcon: string;
	previewActive: boolean;
	advancedScheduleActive: boolean;
	advancedSchedule: ContentAdvancedSchedules;
	deleteBtn: string;
	isDisabled: boolean;
	isSelected: boolean;
	createdByUserFriendlyName: string;
	contentItems: ContentItem[];
	detailsBtn: string;
	editBtn: string;
	border: string;
	deliverComplete: string;
	createDateTimeView: string;
}