<div class="row">
	<div class="col-xs-3">
		<span class="ft-size10 {{ !layersService.activeLayer.canvasObj.includeDropShadow ? 'controls-opacity-low' : null }}">X:</span>
	</div>
	<div class="col-xs-9">
		<input
			[disabled]="!layersService.activeLayer.canvasObj.includeDropShadow || layerDetailService.isCropping"
			type="range"
			min="-100"
			max="100"
			value="{{ layersService.activeLayer.canvasObj.shadow?.offsetX }}"
			class="slider {{
				!layersService.activeLayer.canvasObj.includeDropShadow || layerDetailService.isCropping ? 'cursor-auto controls-opacity-low' : 'pointer'
			}}"
			#xId
			(mouseup)="undoRedoService.recordState('Change drop shadow')"
			(input)="onSliderChange('offsetX', +xId.value)"
		/>
		<span class="absolute top16 right15 ft-size10 {{ !layersService.activeLayer.canvasObj.includeDropShadow ? 'controls-opacity-low' : null }}">
			{{ layersService.activeLayer.canvasObj.shadow.offsetX }}
		</span>
	</div>
</div>
<div class="row">
	<div class="col-xs-3">
		<span class="ft-size10 {{ !layersService.activeLayer.canvasObj.includeDropShadow ? 'controls-opacity-low' : null }}">Y:</span>
	</div>
	<div class="col-xs-9">
		<input
			[disabled]="!layersService.activeLayer.canvasObj.includeDropShadow || layerDetailService.isCropping"
			type="range"
			min="-100"
			max="100"
			value="{{ layersService.activeLayer.canvasObj.shadow.offsetY }}"
			class="slider {{
				!layersService.activeLayer.canvasObj.includeDropShadow || layerDetailService.isCropping ? 'cursor-auto controls-opacity-low' : 'pointer'
			}}"
			#yId
			(mouseup)="undoRedoService.recordState('Change drop shadow')"
			(input)="onSliderChange('offsetY', +yId.value)"
		/>
		<span class="absolute top16 right15 ft-size10 {{ !layersService.activeLayer.canvasObj.includeDropShadow ? 'controls-opacity-low' : null }}">
			{{ layersService.activeLayer.canvasObj.shadow.offsetY }}
		</span>
	</div>
</div>
<div class="row">
	<div class="col-xs-3">
		<span class="ft-size10 {{ !layersService.activeLayer.canvasObj.includeDropShadow ? 'controls-opacity-low' : null }}">Blur:</span>
	</div>
	<div class="col-xs-9">
		<input
			[disabled]="!layersService.activeLayer.canvasObj.includeDropShadow || layerDetailService.isCropping"
			type="range"
			min="1"
			max="100"
			value="{{ layersService.activeLayer.canvasObj.shadow.blur }}"
			class="slider {{
				!layersService.activeLayer.canvasObj.includeDropShadow || layerDetailService.isCropping ? 'cursor-auto controls-opacity-low' : 'pointer'
			}}"
			#blurId
			(mouseup)="undoRedoService.recordState('Change drop shadow')"
			(input)="onSliderChange('blur', +blurId.value)"
		/>
		<span class="absolute top16 right15 ft-size10 {{ !layersService.activeLayer.canvasObj.includeDropShadow ? 'controls-opacity-low' : null }}">
			{{ layersService.activeLayer.canvasObj.shadow.blur }}
		</span>
	</div>
</div>
