<!--FILL-->
<div class="row mgn-top30 relative">
	<div class="col-xs-6">
		<span class="ft-size16 {{ isDisabled ? 'gray-text-darkest' : null }} ">{{ title }}</span>
	</div>
	<div class="col-xs-4 col-xs-offset-2 relative">
		<button
			class="white-bg ft-size10 {{ layerDetailService.isCropping || isDisabled ? 'setOpacityLow' : null }}"
			[disabled]="layerDetailService.isCropping || isDisabled"
			(click)="onSaveAsPresetClick()"
		>
			Save as preset
		</button>
		<div *ngIf="showSavePresets" style="width: 220px" class="absolute top26 right15 shadow border-radius white-bg pd15 z">
			<ld-user-colors [mode]="'set'" [objectProp]="objectProp" (saveClickNotify)="showSavePresets = false"></ld-user-colors>
		</div>
	</div>
</div>
<div class="row mgn-top15">
	<div class="col-xs-12">
		<div
			(click)="colorPickerOpen = !isDisabled"
			[ngStyle]="{ 'background-color': color() }"
			class="{{ isDisabled ? 'setOpacityLow' : 'pointer' }}  border-radius ht40 setWidth100"
		></div>
	</div>
</div>
<div class="row mgn-top30">
	<!--COLOR PICKER-->
	<div class="col-xs-4 relative">
		<button
			class="white-bg {{ layerDetailService.isCropping || isDisabled ? 'setOpacityLow' : null }}"
			[disabled]="layerDetailService.isCropping || isDisabled"
			ngxTippy
			[tippyProps]="{ appendTo: 'parent', delay: [0, 300], theme: 'light' }"
			tippyName="{{ utilService.guid | wrapFn }}"
			data-tippy-content="Color picker"
			(click)="onColorPickerClick()"
		>
			<i class="fas fa-fill-drip"></i>
		</button>
		<div class="absolute top46 left15 white-bg border-radius shadow z">
			<color-chrome *ngIf="colorPickerOpen" color="{{ color() }}" [disableAlpha]="objectProp === 'stroke'" (onChange)="onColorChange($event)"></color-chrome>
			<div *ngIf="colorPickerOpen" class="white-bg pd-top15 pd-bottom15 mgn0 relative topneg2 buttons row">
				<div class="col-xs-5 col-xs-offset-2">
					<button (click)="onCancelClick()" class="gray-bg">Cancel</button>
				</div>
				<div class="col-xs-5">
					<button (click)="onSaveClick()" class="blue-bg white-text">Save</button>
				</div>
			</div>
		</div>
	</div>

	<!--EYEDROPPER-->
	<div #eyedropperBtn class="col-xs-4">
		<button
			class="white-bg {{ layerDetailService.isCropping || isDisabled ? 'setOpacityLow' : null }}"
			[disabled]="layerDetailService.isCropping || isDisabled"
			ngxTippy
			[tippyProps]="{ appendTo: 'parent', delay: [0, 300], theme: 'light' }"
			tippyName="{{ utilService.guid | wrapFn }}"
			data-tippy-content="Eyedropper"
			(click)="onEyedropperBtnClick()"
		>
			<i class="fas fa-eye-dropper"></i>
		</button>
	</div>

	<!--PRESETS-->
	<div class="col-xs-4 relative">
		<button
			[disabled]="layerDetailService.isCropping || isDisabled"
			class="white-bg {{ layerDetailService.isCropping || isDisabled ? 'setOpacityLow' : null }}"
			ngxTippy
			[tippyProps]="{ appendTo: 'parent', delay: [0, 300], theme: 'light' }"
			tippyName="{{ utilService.guid | wrapFn }}"
			data-tippy-content="Presets"
			(click)="onPresetsClick()"
		>
			<i class="fas fa-palette"></i>
		</button>

		<div *ngIf="showPresets" style="width: 225px" class="absolute top40 right15 shadow border-radius white-bg pd15 z">
			<ld-user-colors [mode]="'get'" [objectProp]="objectProp" (saveClickNotify)="showPresets = false"></ld-user-colors>
		</div>
	</div>
</div>

<div
	style="right: 7%"
	[ngStyle]="{ 'top.px': eyedropperPositionTop }"
	#eyedropperContainer
	class="{{ !eyedropperOpen ? 'hidden' : null }} animated fadeInUp absolute white-bg pd15 shadow z border-radius"
>
	<div class="mgn-bottom15">
		<em>Click anywhere on the image to select a color</em>
	</div>

	<video #videoPlayer crossOrigin="anonymous" height="197" width="350" preload="auto" style="visibility: hidden" class="border-radius video-player">
		<source [src]="canvasService.bgContent?.previewUrl" type="video/mp4" />
	</video>
	<!-- *ngIf="canvasService.bgContent?.contentType === 'video/mp4'" -->
	<!-- <img #videoPlayer crossorigin="anonymous" height="197" width="350" style="visibility: hidden;" class="border-radius" [src]="canvasService.bgContent.previewUrl"> -->

	<canvas #eyedropper height="197" width="350" (mouseup)="onEyedropperImageClick($event)" style="right: 4%" class="absolute top50 eyedropper"></canvas>

	<div class="row mgn-top30">
		<div class="col-xs-5 col-xs-offset-2">
			<button (click)="onCancelClick()" class="gray-bg">Cancel</button>
		</div>
		<div class="col-xs-5">
			<button (click)="onSaveClick()" class="blue-bg white-text">Save</button>
		</div>
	</div>
</div>
