import { Installers, InstallersAttachments } from '../../../shared/api-models/admin';

export class InstallersView extends Installers {
	border: string;
	shadow: string;
	rowBgColor: string;
	isActive: string;
	emailList: string[];
	isSearchResult: boolean;
	attachments: InstallersAttachments[];
	classList: string;
	icon: string;
}
