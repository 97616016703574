<portal-header></portal-header>
<div *ngIf="cvStateService.viewState.modalVisible" class="absolute left0 right0">
	<div class="modal-overlay" (click)="modalService.onModalExit()"></div>
	<div class="row">
		<div
			style="top: -16px; margin-bottom: 60px; z-index: 200"
			class="col-xs-12 col-md-8 col-lg-6 col-sm-offset-3 min-height600 margin mgn-bottom30 absolute left0 right0 slideUp"
		>
			<c-preview-modal (exitClickNotify)="modalService.onModalExit()"></c-preview-modal>
		</div>
	</div>
</div>

<div class="container-fluid">
	<div class="row mgn-bottom15 mgn-top15">
		<div class="col-xs-4 col-md-2">
			<div class="row">
				<div class="col-xs-12 col-lg-9">
					<button (click)="location.back()" class="white-bg">
						<i class="fas fa-chevron-left mgn-right12"></i>
						Back
					</button>
				</div>
			</div>
		</div>
		<div class="col-xs-6 col-xs-offset-2 col-md-offset-4 text-right">
			<i
				class="mgn-right12 ft-size28 {{ appStateService.product.Route === 'hold' ? 'fas fa-compact-disc' : 'fas fa-photo-video' }} {{
					appStateService.product.color
				}}-text"
			></i>
			<h1 class="in-block">
				<span class="semi-bold">Playlist Details</span>
				|
				<em>{{ appStateService.product.ProductName }}</em>
			</h1>
		</div>
	</div>

	<div class="{{ appStateService.product.color }}-gradient mgn-bottom45 ht2 border-radius"></div>
</div>

<!--********************
	MODAL
*********************-->
<div *ngIf="modalVisible" class="absolute left0 right0">
	<div class="modal-overlay" (click)="modalVisible = false"></div>
	<div class="row">
		<div
			style="top: -84px; margin-bottom: 60px; z-index: 200"
			class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 margin mgn-bottom30 absolute left0 right0 slideUp"
		>
			<pd-scheduled-players *ngIf="activeModal === 'scheduled-players'" [playlist]="playlist" (exitClickNotify)="modalVisible = false"></pd-scheduled-players>
			<portal-video-playlist-previewer
				*ngIf="activeModal === 'video-playlist-previewer'"
				[playlistBuilderVisible]="true"
				(onExitClickNotify)="modalVisible = false"
			></portal-video-playlist-previewer>
		</div>
	</div>
</div>

<!--********************
	PAGE TITLE
*********************-->
<div class="container-fluid">
	<div class="row pd-bottom30">
		<div class="col-xs-12">
			<h2 class="in-block semi-bold">
				<i class="{{ appStateService.product.DashIcon }} {{ appStateService.product.color }}-text mgn-right10" aria-hidden="true"></i>
				{{ playlist?.Name }}
			</h2>
		</div>
	</div>
</div>

<div class="container-fluid mgn-bottom60 mgn-left15 mgn-right15">
	<div class="row white-bg border-radius shadow pd30">
		<div class="col-sm-6">
			<div class="row">
				<div class="col-sm-6">
					<h4 class="gray-text-darkest mgn-bottom2">CREATED DATE/TIME</h4>
					<span class="regular ft-size18" [innerHTML]="dateTimeService.dateAndTimeUTCtoLocal(playlist?.CreateDate)"></span>
				</div>
				<div class="col-sm-6">
					<h4 class="gray-text-darkest mgn-bottom2">CREATED BY</h4>
					<span class="regular ft-size18">{{ playlist?.createdByUserFriendlyName }}</span>
				</div>
			</div>
		</div>
		<div class="col-sm-6">
			<div class="row">
				<div class="col-xs-6 col-md-3">
					<button (click)="onPreviewClick()" class="gray-bg">
						<i class="fa {{ playlist?.previewBtnIcon }} mgn-right10" aria-hidden="true"></i>
						{{ playlist?.previewBtnIcon === 'fa-pause' ? 'Stop' : 'Preview' }}
					</button>
				</div>
				<div class="col-xs-6 col-md-3">
					<button (click)="onEditClick()" class="gray-bg">
						<i class="fas fa-pencil-alt mgn-right10" aria-hidden="true"></i>
						Edit
					</button>
				</div>
				<div class="col-xs-6 col-md-3 margin-top">
					<button (click)="onPlayersClick()" class="gray-bg">
						<i class="{{ appStateService.product.DashIcon }} mgn-right10" aria-hidden="true"></i>
						Players
					</button>
				</div>
				<div class="col-xs-6 col-md-3 margin-top">
					<button (click)="onPrintClick()" class="gray-bg">
						<i class="fas fa-print mgn-right10" aria-hidden="true"></i>
						Print
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<!--********************
	CONTENT LIST
*********************-->
<div *ngIf="contentList?.length" class="container-fluid mgn-left15 mgn-right15 mgn-top30 mgn-bottom100">
	<ng-container *ngFor="let content of contentList; let isFirstIndex = first">
		<c-content
			[bypassGetContentFiles]="true"
			[content]="content"
			[contentList]="contentList"
			[contentViewState]="{ layout: 'list-view' }"
			[isFirstIndex]="isFirstIndex"
		></c-content>
	</ng-container>
</div>
