export class ViewState {
	csrId: number;
	pageNumber: number;
	installStateId: number;
	playerModelId: number;
	clientId: number;
	toggleBtnState: string;
	productTypeId: number;
	orderByCol: string;
	orderDesc: boolean;
	includeDemoAccounts: boolean;
	showPoorNetworkOnly: boolean;

	constructor(clientId: number, pageNumber: number, installStateId: number, playerModelId: number, toggelBtnState: string, productTypeId: number, orderByCol: string, orderDesc: boolean) {
		this.clientId = clientId;
		this.pageNumber = pageNumber;
		this.installStateId = installStateId;
		this.playerModelId = playerModelId;
		this.toggleBtnState = toggelBtnState;
		this.productTypeId = productTypeId;
		this.orderByCol = orderByCol;
		this.orderDesc = orderDesc;
		this.includeDemoAccounts = false;
		this.showPoorNetworkOnly = false;
	}
}