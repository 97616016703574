import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AppStateService } from 'src/app/core/services';
import { DeliveryQueue } from '../api-models/delivery';
import { environment } from '../../../environments/environment';
import { PlayersDragDrop } from '../view-models/admin';

@Injectable({
	providedIn: 'root'
})
export class DeliveryService {

	constructor(
		private appStateService: AppStateService,
		private httpClient: HttpClient) { }

	public getPlaylistPlayers(playlistId: number): Observable<PlayersDragDrop[]> {
		return this.httpClient.get(`${environment.deliveryUrl}DeliveryQueue/PlayerList/${playlistId}/${this.appStateService.currentClient.Id}`).pipe(

			mergeMap((deliveryQueueArr: DeliveryQueue[]) => {
				if (deliveryQueueArr.length < 1) {
					return of([])
				}
				return forkJoin(deliveryQueueArr.map((deliveryQueue: DeliveryQueue) => {
					return this.httpClient.get(`${environment.adminUrl}CorePlayers/${deliveryQueue.PlayerId}`).pipe(
						map((players: PlayersDragDrop[]) => {
							return this.playerView(deliveryQueueArr, players[0]);
						})
					)
				}))
			})
		)
	}

	private playerView(deliveryQueueArr: DeliveryQueue[], player: PlayersDragDrop): PlayersDragDrop {
		player.deliveryQueue = deliveryQueueArr.find(delivery => delivery.PlayerId === player.Id);
		return player;
	}
}
