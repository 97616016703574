import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppStateService, BrowserDetectorService, UtilitiesService } from '../../../core/services';
import { UserRole } from '../../api-models/admin/user-role.enum';

@Component({
	selector: 'portal-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
	@Input() playlistBuilderVisible: boolean;

	readonly enumUserRole = UserRole;
	public animation: string;
	public currentUserFriendlyName: string;
	public helpMenu: any[] = [
		{
			name: 'Help Center',
			iconClass: 'far fa-life-ring',
			href: 'https://help.works24.com/',
			newTab: true
		},
		{
			name: 'Video Tutorials',
			iconClass: 'fas fa-video',
			href: 'https://help.works24.com/video-learning-center/',
			newTab: true
		},
		{
			name: 'Radio Channels',
			iconClass: 'fas fa-volume-up',
			href: 'https://help.works24.com/knowledge-base/radio-channel-guide/',
			newTab: true
		},
		{
			name: 'Contact Us',
			iconClass: 'far fa-envelope',
			route: '/contact-us'
		}
	];
	public helpMobileMenuVisible: boolean;
	public helpMenuVisible: boolean;
	public internalMenuVisible: boolean;
	public logoutVisible: boolean;
	public mobileMenu: any[];
	public mobileMenuHidden: string;
	public mobileMenuChecked: boolean;
	public toolsMenu: any[] = [
		{
			name: 'User Manager',
			iconClass: 'fas fa-user',
			route: '/user-manager'
		},
		{
			name: 'Global Settings',
			iconClass: 'fas fa-globe',
			route: '/global-settings'
		},
		{
			name: 'Large File Transfer',
			iconClass: 'fas fa-cloud-upload-alt',
			route: '/large-file-transfer'
		}
	];
	public toolsMobileMenuVisible: boolean;
	public toolsMenuVisible: boolean;

	private subscriptions: Subscription[] = [];

	constructor(
		public appStateService: AppStateService,
		public browserDetectorService: BrowserDetectorService,
		private router: Router,
		public utilService: UtilitiesService
	) {}

	ngOnInit() {
		this.mobileMenuHidden = 'hidden';
		if (this.appStateService.getSessionItem('currentUser')) {
			this.appStateService.currentUser = JSON.parse(this.appStateService.getSessionItem('currentUser'));
			this.currentUserFriendlyName = this.appStateService.currentUser.FriendlyName;
			this.mobileMenu = [
				{
					name: `Welcome, ${this.currentUserFriendlyName}`
				},
				{
					name: 'My Products',
					iconClass: 'fas fa-boxes'
				},
				{
					name: 'Tools',
					iconClass: 'fas fa-tools'
				},
				{
					name: 'Help',
					iconClass: 'fas fa-question'
				},
				{
					name: 'Sign out',
					iconClass: 'fas fa-sign-out-alt'
				}
			];
		}
		this.userManagerAccess();
	}

	public onToolsMenuVisibleChange(toolsMenuVisible: boolean): void {
		this.toolsMenuVisible = toolsMenuVisible;
	}

	public onHelpMenuVisibleChange(helpMenuVisible: boolean): void {
		this.helpMenuVisible = helpMenuVisible;
	}

	public autoCloseMegaMenuDropdown(event) {
		if (!this.utilService.closest(event, '.noExit') && !this.utilService.closest(event, '.active')) {
			this.internalMenuVisible = false;
		}
	}

	onInternalMenuClick() {
		this.internalMenuVisible = !this.internalMenuVisible;
	}

	onMegaMenuButtonClick(): void {
		this.internalMenuVisible = false;
	}

	onToolsDropdownItemClick(menuItem: any): void {
		switch (menuItem.name) {
			case 'User Manager':
				this.toolsMenuVisible = false;
				this.router.navigate(['/user-manager']);
				break;
			case 'Global Settings':
				this.toolsMenuVisible = false;
				this.router.navigate(['/global-settings']);
				break;
			case 'Large File Transfer':
				this.router.navigate(['/large-file-transfer']);
				break;
			case 'Download Record24':
				this.router.navigate(['/download-record24']);
				break;
		}
	}

	onMobileDropdownItemClick(menuItem: any): void {
		switch (menuItem.name) {
			case 'My Products':
				this.router.navigate(['/my-products']);
				this.mobileMenuHidden = 'hidden';
				break;
			case 'Logout':
				this.onLogoutClick();
				this.mobileMenuHidden = 'hidden';
				break;
		}
	}

	onMobileMenuItemHover(menuItem: any): void {
		switch (menuItem.name) {
			case 'Tools':
				if (this.animation !== 'fadeOutUp') {
					this.toolsMobileMenuVisible = true;
					this.helpMobileMenuVisible = false;
				}
				break;
			case 'My Products':
				this.toolsMobileMenuVisible = false;
				this.helpMobileMenuVisible = false;
				break;
			case 'Help':
				if (this.animation !== 'fadeOutUp') {
					this.helpMobileMenuVisible = true;
					this.toolsMobileMenuVisible = false;
				}
				break;
			case 'Logout':
				this.toolsMobileMenuVisible = false;
				this.helpMobileMenuVisible = false;
				break;
		}
	}

	public autoCloseDropdown(event) {
		if (!this.utilService.closest(event, '.active') && !this.utilService.closest(event, '.gray-text')) {
			this.helpMenuVisible = false;
			this.toolsMenuVisible = false;
		}
	}

	public headerMenuVisible(): boolean {
		return (
			this.appStateService.currentUser.Role !== UserRole.VIDEO_CONTENT_CONTRACTOR &&
			this.appStateService.currentUser.Role !== UserRole.VOICE_TALENT &&
			this.appStateService.currentUser.Role !== UserRole.AUDIO_CONTENT_CREATOR &&
			this.appStateService.currentUser.Role !== UserRole.SCRIPT_WRITER
		);
	}

	public isEmployee(): boolean {
		return this.appStateService.currentUser.IsEmployee ? true : false;
	}

	public onHelpClick(): void {
		this.helpMenuVisible = !this.helpMenuVisible;
		this.toolsMenuVisible = false;
		this.logoutVisible = false;
	}

	public onLogoutClick(): void {
		this.appStateService.removeSessionItem('currentUser');
		sessionStorage.removeItem('authToken');
		sessionStorage.removeItem('validPin');
		this.router.navigate(['/user-sign-out']);
	}

	public onToolsClick(): void {
		this.toolsMenuVisible = !this.toolsMenuVisible;
		this.helpMenuVisible = false;
		this.logoutVisible = false;
	}

	public onUserNameClick(): void {
		this.logoutVisible = !this.logoutVisible;
		this.helpMenuVisible = false;
		this.helpMenuVisible = false;
	}

	public toggleMobileMenu(): void {
		this.mobileMenuChecked = !this.mobileMenuChecked;
		if (this.mobileMenuHidden === 'hidden') {
			this.mobileMenuHidden = '';
			this.animation = 'fadeIn';
		} else {
			this.animation = 'fadeOut';
			setTimeout(() => {
				this.mobileMenuHidden = 'hidden';
			}, 800);
		}
	}

	private userManagerAccess(): void {
		if (this.appStateService.currentUser.Role === UserRole.USER || this.appStateService.currentUser.Role === UserRole.CONTENT_MANAGER) {
			this.toolsMenu.splice(0, 1);
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => {
			sub.unsubscribe();
		});
	}
}
