import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ClipService } from '../_services';
import { ClipsView } from '../../../_models';
import { ContentRequestClipAttachments } from 'src/app/shared/api-models/admin';
import { CreateEditRequestService, CreateEditViewStateService } from '../../../_services';
import { environment } from '../../../../../../environments/environment';
import { FileUploadService } from 'src/app/core/services';
import { FileUploadStateService } from 'src/app/shared/services';
import { FileUploadModalService } from 'src/app/shared/components/file-upload-modal/file-upload-modal.service';
import { UtilitiesService } from 'src/app/core/services';

@Component({
	selector: 'c-video-view',
	templateUrl: './video-view.component.html',
	styleUrls: ['./video-view.component.scss']
})
export class VideoViewComponent implements OnInit, OnDestroy {
	@Input() clip: ClipsView;
	@Input() index: number;

	public attachmentDownloadUrl: string;

	private subs: Subscription[] = [];

	constructor(
		public clipService: ClipService,
		public createEditRequestService: CreateEditRequestService,
		private fileUploadService: FileUploadService,
		public fileUploadStateService: FileUploadStateService,
		public fileUploadModalService: FileUploadModalService,
		public utilService: UtilitiesService,
		public viewStateService: CreateEditViewStateService
	) {}

	ngOnInit(): void {
		this.clip.backgroundColor = 'gray-bg';
		if (this.createEditRequestService.request.RequestType === 'Video-Logo') {
			this.setMessageText();
		}
		if (this.clip.Id) {
			this.clipService.getAttachments(this.clip).subscribe((attachments: ContentRequestClipAttachments[]) => {
				this.clip.attachments = attachments;
			});
		}
	}

	public onApproveClick(): void {
		this.clip.IsApproved = !this.clip.IsApproved;
		this.clipService.clip = this.clip;
		this.clipService.saveClip();
		this.clip.detailsVisible = false;
	}

	public onAttachmentDeleteClick(attachment: ContentRequestClipAttachments, clip: ClipsView): void {
		const confirmOk: boolean = confirm(`Delete "${attachment.FriendlyName}"? This action cannot be undone.`);
		if (confirmOk) {
			this.clipService.deleteAttachment(attachment, clip);
		}
	}

	public onClipDeleteClick(): void {
		const confirmDelete: boolean = confirm('Delete this clip? This cannot be undone.');
		if (confirmDelete) {
			this.clipService.deleteClip(this.clip);
		}
	}

	public onCompletedFileDeleteClick(clip: ClipsView): void {
		const confirmOk: boolean = confirm(`Delete completed file? File will be deleted immediately and cannot be undone.`);
		if (confirmOk) {
			this.clipService.deleteCompletedFile(clip);
		}
	}

	public onDownloadAttachmentClick(attachment: ContentRequestClipAttachments): void {
		this.attachmentDownloadUrl = `${environment.adminUrl}ContentRequestClipAttachments/${attachment.Id}/File`;
	}

	public onResizeMethodClick(resizeMethod: string): void {
		this.fileUploadStateService.uploadData.ResizeMethod = resizeMethod;
	}

	public onReplaceInputClick(): void {
		this.clip.completedFileUploadInProgress = true;
		this.fileUploadStateService.replacementProps = { contentFileId: this.clip.completedVideoFile.Id, targetLibraryId: this.targetLibraryId() };
		this.fileUploadService.onReplaceInputClick(this.clip.Id, 'ContentRequestCompletedFile');
	}

	public isVideoLogo(): boolean {
		return this.createEditRequestService.request.RequestType === 'Video-Logo';
	}

	private targetLibraryId(): number {
		//Bug logo hard-wired to Logo Library
		return this.isVideoLogo() ? 1000395 : this.createEditRequestService.request.TargetLibraryId;
	}

	//For view only
	private setMessageText(): void {
		this.clip.logoDescription = 'Uploads to "Logo Library - 1000395"';
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
