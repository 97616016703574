import { Injectable } from '@angular/core';

import { CanvasService } from '../canvas/_services/canvas.service';
import { EditorState } from '../_models';
import { InitService } from '../timeline/layers-timeline-wrapper/_services/init.service';
import { LayersService } from './layers.service';
import { LayersTimelineWrapperService } from '../timeline/layers-timeline-wrapper/_services/layers-timeline-wrapper.service';
import { StateService } from './state.service';
import { UtilitiesService } from 'src/app/core/services';
import { VideoControlsService } from './video-controls.service';

@Injectable({
	providedIn: 'root'
})
export class UndoRedoService {
	public past: EditorState[];
	public tooltip: string;
	public historyHoverIndex: number;
	public index: number;
	public showHistoryPanel: boolean;

	constructor(
		private canvasService: CanvasService,
		private initService: InitService,
		private layersService: LayersService,
		private ltwService: LayersTimelineWrapperService,
		private stateService: StateService,
		private utilService: UtilitiesService,
		private videoControlsService: VideoControlsService
	) {}

	public recordState(tooltip?: string): void {
		//If making a change in the app after undoing,
		//remove all undo state ahead of current state
		if (this.index < this.past.length - 1) {
			this.past.splice(-(this.past.length - (this.index + 1)));
		}
		this.past.push(JSON.parse(JSON.stringify(this.stateService.state(tooltip))));

		//Limit past array to 50
		if (this.past.length > 50) {
			this.past.pop();
		}
		//Limit index so it won't go above 50
		if (this.index < 50) {
			this.index = this.index + 1;
		}
		this.setTooltip();
	}

	public onUndoRedoClick(e, str: 'undo' | 'redo' | 'historyPanel', index?: number): void {
		if (e.shiftKey) {
			this.showHistoryPanel = true;
		} else {
			if (((str === 'undo' || str === 'historyPanel') && this.canUndo()) || ((str === 'redo' || str === 'historyPanel') && this.canRedo())) {
				this.resetIndex(str, index);

				let previousState: EditorState = this.past[this.index];

				//Reset active layer and layers array
				if (previousState?.activeLayer) {
					this.layersService.activeLayer = JSON.parse(JSON.stringify(previousState.activeLayer));
				}
				this.layersService.layers = JSON.parse(JSON.stringify(previousState.layers));
				this.canvasService.bgContent = null;

				setTimeout(() => {
					this.canvasService.bgContent = JSON.parse(JSON.stringify(previousState.bgContent));
					let duration: number = this.canvasService.bgContent?.contentFiles[0].DurationMs;
					// duration = duration > 0 ? duration : 100000;
					this.ltwService.contentDuration = duration;

					//Remove each canvas object
					this.canvasService.canvas.getObjects().forEach((obj) => {
						this.canvasService.canvas.remove(obj);
					});

					//Re-add each canvas object
					this.stateService.addEachCanvasObj(previousState);

					//Remove each timeline object
					this.ltwService.canvas
						.getObjects()
						.filter((obj) => obj.layerId >= 0)
						.forEach((obj) => {
							this.ltwService.canvas.remove(obj);
						});

					//Re-add each timeline object
					this.stateService.addEachTimelineObj(previousState);
					this.setTooltip();
					this.initService.timelineInit();
					this.initService.setGroupControls();
					if (this.videoControlsService.videoPlayer) {
						this.resetVideoTimeToZero();
					}
				}, 10);
			}
		}
	}

	private resetIndex(str: 'undo' | 'redo' | 'historyPanel', index: number): void {
		switch (str) {
			case 'undo':
				this.index = this.index - 1;
				break;
			case 'redo':
				this.index = this.index + 1;
				break;
			case 'historyPanel':
				this.index = index;
		}
	}

	private setTooltip(): void {
		if (this.index > 0 && this.past[this.index]) {
			this.tooltip = this.past[this.index].tooltip;
		}
	}

	private resetVideoTimeToZero(): void {
		this.videoControlsService.playClock = 0 / this.ltwService.pixPerMs();
		//Update current time of the video
		this.videoControlsService.videoPlayer.nativeElement.currentTime = this.videoControlsService.playClock;
	}

	public canUndo(): boolean {
		return this.index > 0;
	}

	public canRedo(): boolean {
		return this.index < this.past.length - 1;
	}

	public autoCloseDropdown(event) {
		if (!this.utilService.closest(event, '.noExit')) {
			this.showHistoryPanel = false;
		}
	}
}
