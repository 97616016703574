import { Injectable } from '@angular/core';
import { CountdownLayerView } from './countdown-layer-view';
import { DisplayOptions } from './display-options';
import { FabricObject } from './fabric-object';
import { LayerType } from './layer-type';
import { CountdownInterval } from './countdown-interval.enum';
import { Create24ContentType } from '../../_models/create-24-state.enum';

export class Layer {
	id: number;
	idPreDrag: number;
	name: string;
	icon: string;
	type: LayerType;
	start: number;
	end: number;
	transitionIn: string;
	transitionOut: string;
	transitionInFired: boolean;
	transitionOutFired: boolean;
	visible: boolean;
	canvasObj: FabricObject;
	timelineObj: FabricObject;
	previewUrl: string;
	nameState: 'read' | 'edit';
	isDuplicate: boolean;
	transitionOutStart: number;
	transitionInEnd: number;
	transOutDuration: number;
	transInDuration: number;
	totalTransDuration: number;
	feedSourceName: 'Title' | 'Body';
	feedSourceValue: string;
	countdownInterval: CountdownInterval;
	countdownDate: string;
	countdownLayerView: CountdownLayerView;
	countdownDisplayOption: DisplayOptions;
	contentType: Create24ContentType;

	constructor(
		id: number,
		name: string,
		icon: string,
		timelineColor: string,
		type: LayerType,
		contentType: Create24ContentType,
		canvasObjTop?: number,
		canvasObjLeft?: number
	) {
		this.canvasObj = new FabricObject();
		this.timelineObj = new FabricObject();
		this.id = id;
		this.name = name;
		this.icon = icon;
		this.type = type;
		this.visible = true;
		this.canvasObj.fill = '#000000';
		this.canvasObj.stroke = '#000';
		this.canvasObj.strokeWidth = type === 'Line' ? 4 : 0;
		this.canvasObj.opacity = 1;
		this.canvasObj.angle = 0;
		this.canvasObj.text = 'New text layer';
		this.canvasObj.top = canvasObjTop === 0 ? canvasObjTop : 80; //Transparent layer (Id: 0) value here is 0
		this.canvasObj.left = canvasObjLeft === 0 ? canvasObjLeft : 60; //Transparent layer (Id: 0) value here is 0
		this.canvasObj.feedImgBoundingBoxLeft = 60;
		this.canvasObj.feedImgBoundingBoxTop = 80;
		this.canvasObj.feedImgBoundingBoxCalcHeight = 375;
		this.canvasObj.feedImgBoundingBoxCalcWidth = 375;
		this.canvasObj.lineHeight = 1.2;
		this.canvasObj.charSpacing = 1;
		this.canvasObj.scaleX = type === 'Image' ? 0.6 : 1;
		this.canvasObj.scaleY = type === 'Image' ? 0.6 : 1;
		this.canvasObj.shadow = { offsetX: 4, offsetY: 3, blur: 4, color: '#000' };
		this.canvasObj.fontWeight = 'normal';
		this.canvasObj.fontSize = type === 'Feed Text' ? 20 : 42;
		this.canvasObj.fontStyle = 'normal';
		this.canvasObj.fontFamily = 'Tahoma';
		this.canvasObj.underline = false;
		this.canvasObj.textAlign = 'left';
		this.canvasObj.rx = 0;
		this.canvasObj.ry = 0;
		this.canvasObj.feedImgBoundingBoxScaleY = 1;
		this.canvasObj.feedImgBoundingBoxScaleX = 1;
		if (this.type === 'Feed Image') {
			this.canvasObj.height = 300;
			this.canvasObj.width = 300;
		}
		this.timelineObj.top = 58;
		this.timelineObj.scaleX = 1;
		this.timelineObj.fill = timelineColor;
		this.timelineObj.width = 954;
		this.start = 0;
		this.transitionInFired = false;
		this.transitionOutFired = false;
		this.visible = true;
		this.nameState = 'read';
		this.transitionIn = 'Not set';
		this.transitionOut = this.id === 0 || contentType === 'image' ? 'Not set' : 'Fade out';
		this.transOutDuration = 1250;
		this.transInDuration = 1250;
		this.totalTransDuration = 1250;
		this.contentType = contentType;
	}
}
