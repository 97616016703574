export const toggleIsSelected = (state, prop, selectedItem): any => {
    return state[prop].map(item => ({...item})).map(item => {
        if(item.Id === selectedItem.Id) {
            return {
                ...item,
                isSelected: !item.isSelected
            }
        } else {
            return item;
        }
    })
}