<portal-header></portal-header>

<div *ngIf="createEditRequestService.showPreviewModal" class="absolute left0 right0 top140">
	<div class="modal-overlay" (click)="createEditRequestService.showPreviewModal = false"></div>
	<div class="row">
		<div
			style="top: -16px; margin-bottom: 60px; z-index: 200"
			class="col-xs-12 col-md-8 col-lg-6 col-sm-offset-3 min-height600 margin mgn-bottom30 absolute left0 right0 slideUp"
		>
			<c-preview-modal (exitClickNotify)="createEditRequestService.showPreviewModal = false"></c-preview-modal>
		</div>
	</div>
</div>

<div *ngIf="createEditRequestService.request" class="container-fluid pd-top10 mgn-top30 relative">
	<a class="dark-text absolute topneg21 left15" (click)="onBackClick()">
		<i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
		<span class="ft-size16">Back</span>
	</a>
	<div class="row">
		<div class="{{ viewStateService.route !== 'content-requests' ? titleCol() : 'col-sm-12' }}">
			<h1 *ngIf="viewStateService.route !== 'content-requests'">
				<span class="semi-bold">{{ createEditRequestService.request.Title }}</span>
				<span *ngIf="createEditRequestService.request.Title?.length > 0">|</span>
				<em>{{ createEditRequestService.request.RequestType }}</em>
				|
				<span class="{{ createEditRequestService.request.StateName === stateName.CANCELLED ? 'red-text-light' : null }}">
					{{ createEditRequestService.request.StateName }}
				</span>
			</h1>

			<!--Client view-->
			<h1 *ngIf="viewStateService.route === 'content-requests'">
				<span class="semi-bold">{{ createEditRequestService.request.Title }}</span>
				<span *ngIf="createEditRequestService.request.Title?.length > 0">|</span>
				<em>{{ createEditRequestService.request.clientStatusView }}</em>
			</h1>
		</div>
		<div *ngIf="securityService.userIsNatalieOrKelsey() && viewStateService.route !== 'content-requests'" class="col-sm-5">
			<!--**************************************
				MARK REQUEST AS FEATURED CONTENT
			***************************************-->
			<div class="row">
				<div class="col-xs-4">
					<i
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ appendTo: 'parent', placement: 'bottom' }"
						data-tippy-content="Allows this content to appear on the featured content page. Clients are emailed a link to this page monthly."
						class="far fa-question-circle relative topneg15 leftneg6"
					></i>
					<h4 class="semi-bold in-block mgn-right8 pointer" (click)="onMarkAsFeaturedClick()">Mark As Featured</h4>
					<div class="in-block absolute">
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[isChecked]="createEditRequestService.request.IsFeatured"
							[type]="'checkbox'"
							(checkboxClickNotify)="onMarkAsFeaturedClick()"
						></portal-checkbox>
					</div>
				</div>
				<div class="col-sm-4 relative">
					<span [ngStyle]="{ opacity: createEditRequestService.request.IsFeatured ? 1 : 0.4 }" class="ft-size12 absolute topneg18">Start</span>
					<portal-date-time-selector
						[backgroundColor]="'white-bg'"
						[dateModel]="createEditRequestService.request.FeatureStartDate"
						[disabled]="!createEditRequestService.request.IsFeatured"
						(dateChanged)="onFeatureDateChanged($event, 'FeatureStartDate')"
					></portal-date-time-selector>
				</div>
				<div class="col-sm-4 relative">
					<span [ngStyle]="{ opacity: createEditRequestService.request.IsFeatured ? 1 : 0.4 }" class="ft-size12 absolute topneg18">End</span>
					<portal-date-time-selector
						[backgroundColor]="'white-bg'"
						[dateModel]="createEditRequestService.request.FeatureEndDate"
						[disabled]="!createEditRequestService.request.IsFeatured"
						(dateChanged)="onFeatureDateChanged($event, 'FeatureEndDate')"
					></portal-date-time-selector>
				</div>
			</div>
		</div>
		<div *ngIf="viewStateService.route !== 'content-requests'" class="col-sm-2 text-right">
			<h2 class="gray-text-darker"><em>Internal View</em></h2>
		</div>
	</div>
	<div class="{{ createEditRequestService.request.RequestType === 'On-Hold Message' ? 'blue-gradient' : 'red-gradient' }} ht2 mgn-top20 border-radius"></div>
	<div class="row mgn-top30">
		<cer-details></cer-details>
		<cer-clips *ngIf="createEditRequestService.request.RequestType !== 'Video-Other'"></cer-clips>
		<cer-comments></cer-comments>
	</div>
</div>
