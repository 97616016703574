import { Component, OnInit } from '@angular/core';

import { Clients } from 'src/app/shared/api-models/admin';
import { ClientMergeToolStore } from './client-merge-tool.store';
import { ClientMergeToolService } from './client-merge-tool.service';

@Component({
    selector: 'app-client-merge-tool',
    templateUrl: './client-merge-tool.component.html',
    styleUrls: ['./client-merge-tool.component.scss'],
    providers: [ClientMergeToolStore]
})
export class ClientMergeToolComponent implements OnInit {

    public clients$ = this.clientMergeToolStore.clients$;

    constructor(
        public clientMergeToolService: ClientMergeToolService,
        public clientMergeToolStore: ClientMergeToolStore
    ) { }

    ngOnInit(): void {
        this.clientMergeToolStore.getClients$();
    }

    public onFromClientSelect(client: Clients): void {
        this.clientMergeToolStore.setFromClient(client);
    }

    public onToClientSelect(client: Clients): void {
        this.clientMergeToolStore.setToClient(client);
    }

    public onContinueClick(): void {
        this.clientMergeToolService.continueClicked = true;
    }
}
