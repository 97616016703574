import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'portal-toggle-buttons',
    templateUrl: './toggle-buttons.component.html',
    styleUrls: ['./toggle-buttons.component.scss']
})
export class ToggleButtonsComponent implements OnInit {

    @Input() flow: string;
    @Input() fontSize: string;
    @Input() tooltip: string;
    @Input() isOn: boolean;

    @Output() toggleClickNotify: EventEmitter<any> = new EventEmitter<any>();


    constructor() { }

    ngOnInit() {
    }

}
