import { Component, OnInit } from '@angular/core';

import { CreateEditRequestService, CreateEditViewStateService, HeightService } from '../../_services';
import { DetailsService, DetailsViewService } from '../_services';
import { UtilitiesService } from 'src/app/core/services';

@Component({
	selector: 'd-client-view',
	templateUrl: './client-view.component.html',
	styleUrls: ['./client-view.component.scss']
})
export class ClientViewComponent implements OnInit {
	constructor(
		public createEditRequestService: CreateEditRequestService,
		public detailsService: DetailsService,
		public detailsViewService: DetailsViewService,
		public heightService: HeightService,
		public utilService: UtilitiesService,
		public viewStateService: CreateEditViewStateService
	) {}

	ngOnInit() {}
}
