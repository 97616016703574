import { Component, ElementRef, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { debounceTime } from 'rxjs/operators';

import { UsageReportsStore } from '../usage-reports.store';
import { UtilitiesService } from 'src/app/core/services';
import { CategoryPlaceholder } from '../_models/usage-report.interface';
import * as moment from 'moment';

@Component({
	selector: 'ur-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
	@ViewChild('table') table: ElementRef;

	public fromDate: string = new Date('01-01-2004').toString();
	public toDate: string = new Date().toString();
	public typeahead = new EventEmitter<string>();
	public categoryPlaceholder = CategoryPlaceholder;

	constructor(public usageReportsStore: UsageReportsStore, private utilitiesService: UtilitiesService) {
		this.typeahead.pipe(debounceTime(500)).subscribe(() => {
			this.usageReportsStore.getReport$();
		});
	}

	ngOnInit(): void {}

	public onDownloadClick() {
		const table = document.querySelector('#simpleTable1');
		const rows = table.querySelectorAll('tr');
		const csvData = [];

		rows.forEach((row) => {
			const rowData = [];
			row.querySelectorAll('td, th').forEach((cell: any) => {
				rowData.push(cell.innerText);
			});
			csvData.push(rowData.join(','));
		});

		const csvContent = 'data:text/csv;charset=utf-8,' + csvData.join('\n');
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'table_data.csv');
		document.body.appendChild(link);
		link.click(); // Trigger download
		document.body.removeChild(link);
	}

	public onPageClick(pageNumber: number): void {
		this.usageReportsStore.setPageNumber(pageNumber);
		this.utilitiesService.scrollToTop();
		setTimeout(() => {
			this.usageReportsStore.getReport$();
		}, 500);
	}

	public onPageSizeEnter(pageSize: number): void {
		this.usageReportsStore.setPageSize(pageSize);
		this.usageReportsStore.getReport$();
	}

	public onCategorySelect(category: string, type: 'category' | 'subCategory'): void {
		if (category === CategoryPlaceholder.CATEGORY || category === CategoryPlaceholder.SUBCATEGORY) {
			this.resetCategories();
			this.usageReportsStore.getReport$();
			return;
		}
		if (type === 'category') {
			this.usageReportsStore.setCategory(category);
		} else if (type === 'subCategory') {
			this.usageReportsStore.setSubCategory(category);
		}
		this.usageReportsStore.getReport$();
	}

	public onDateChanged(date, str: string): void {
		date = moment(date).format('M/D/YYYY');
		if (str === 'from') {
			this.usageReportsStore.setFromCreateDateString(date);
		} else if (str === 'to') {
			this.usageReportsStore.setToCreateDateString(date);
		}
		this.typeahead.next();
	}

	public onNameSearch(clipName: string): void {
		this.usageReportsStore.setClipNameSearchTerm(clipName);
		this.typeahead.next();
	}

	private resetCategories(): void {
		this.usageReportsStore.setCategory(CategoryPlaceholder.NONE);
		this.usageReportsStore.setSubCategory(CategoryPlaceholder.NONE);
	}
}
