import { Injectable, HostListener } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ViewportWidthService {

  public isXs: boolean;
  public isSm: boolean;
  public isMd: boolean;
  public isLg: boolean;

  constructor() { 
  	//HostListener not available in an Angular service
  	window.addEventListener('resize', (event) => {
  		this.checkViewportWidth();	
    }) 
  }


  public checkViewportWidth(): void {
  	window.innerWidth < 768 ? this.isXs = true : this.isXs = false;
  	window.innerWidth >= 768 && window.innerWidth < 992 ? this.isSm = true : this.isSm = false;
  	window.innerWidth >= 992 && window.innerWidth < 1200 ? this.isMd = true : this.isMd = false;
    window.innerWidth >= 1200 ? this.isLg = true : this.isLg = false;
  }
}
