import { Injectable } from '@angular/core';

import { CanvasService } from '../canvas/_services/canvas.service';
import { Layer } from '../_models';
import { LayersTimelineWrapperService } from '../timeline/layers-timeline-wrapper/_services/layers-timeline-wrapper.service';

@Injectable({
    providedIn: 'root'
})
export class TransUtilService {

    constructor(
        private canvasService: CanvasService,
        private ltwService: LayersTimelineWrapperService
    ) { }

    public resumeTransOut(layer: Layer): boolean {
        return layer.transOutDuration > 0 && layer.transOutDuration < layer.totalTransDuration;
    }

    public resumeTransIn(layer: Layer): boolean {
        return layer.transInDuration > 0 && layer.transInDuration < layer.totalTransDuration;
    }

    public playheadAfterLayerTimelineEnd(layer: Layer): boolean {
        return this.ltwService.playheadPos > layer.end;
    }

    public playheadGreaterThanLayerStart(layer: Layer): boolean {
        return +this.ltwService.playheadPos >= +layer.start;
    }

    public playheadGreaterThanTransOutStart(layer: Layer): boolean {
        return +this.ltwService.playheadPos.toFixed(0) > layer.transitionOutStart;
    }

    public playheadLessThanTransInEnd(layer: Layer): boolean {
        return +this.ltwService.playheadPos.toFixed(0) < layer.transitionInEnd;
    }
    public animateToFrom(layer: Layer, prop: string, obj): number {
        let canvasHeight = this.canvasService.canvasSize().height;
        let canvasWidth = this.canvasService.canvasSize().width;

        switch (layer[prop]) {
            case 'Slide in down': return -((obj.height * obj.scaleY));
            case 'Slide in left': return -(obj.width * obj.scaleX);
            case 'Slide in up': return canvasHeight;
            case 'Slide in right': return canvasWidth;

            case 'Slide out down': return canvasHeight;
            case 'Slide out left': return -(obj.width * obj.scaleX);
            case 'Slide out up': return -((obj.height * obj.scaleY) + 10);

            case 'Slide out right': return canvasWidth;
            case 'Fade in': return 0;
            case 'Fade out': return 0;
        }
    }

    /**
     * @param t = elapsed time
     * @param b = start x
     * @param c = end x
     * @param d = duration
     */
    public easeOutCubic(t, b, c, d): number {
        return c * ((t = t / d - 1) * t * t + 1) + b;
    }

    public easeInCubic(t, b, c, d): number {
        return c * (t /= d) * t * t + b;
    }
}