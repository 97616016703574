<portal-header></portal-header>

<div class="container-fluid mgn-top30 relative">
	<div class="row mgn-bottom30">
		<div class="col-sm-12">
			<div style="border-radius: 16px" class="white-bg box-shadow pd30 relative">
				<a *ngIf="clientHealthDashboardService.showClientDetails" class="dark-text absolute topneg26 left15" (click)="location.back()">
					<i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
					<span class="ft-size16">Back</span>
				</a>

				<div class="row">
					<div class="col-sm-8">
						<h1>
							<i class="fas fa-chart-pie client-health-red-text mgn-right15" aria-hidden="true"></i>
							<span *ngIf="!clientHealthDashboardService.showClientDetails" class="semi-bold">Client Health Dashboard</span>
							<span *ngIf="clientHealthDashboardService.showClientDetails" class="semi-bold">
								{{ clientHealthDashboardService.selectedClient?.ClientName }}
							</span>
							|
							<em>
								{{
									clientHealthDashboardService.showClientDetails
										? clientHealthDashboardService.selectedClient?.CsrName
										: clientHealthDashboardService.selectedUser?.Name
								}}
							</em>
						</h1>
					</div>

					<ng-container *ngIf="clientHealthDashboardService.showClientDetails">
						<div class="col-sm-2">
							<button (click)="onCrmClick()" id="crm-btn" class="client-health-red white-text">
								<i class="fas fa-user-circle mgn-right12"></i>
								CRM
							</button>
						</div>
						<div class="col-sm-2">
							<button (click)="onBackClick()" id="back-btn" class="client-health-green white-text">
								<i class="fas fa-angle-left mgn-right12"></i>
								Back to all clients
							</button>
						</div>
					</ng-container>

					<ng-container *ngIf="!clientHealthDashboardService.showClientDetails">
						<div class="col-sm-2 relative">
							<button (click)="showRuleKey = !showRuleKey" class="gray-bg">
								<i class="fas {{ showRuleKey ? 'fa-times' : 'fa-key mgn-right12' }}"></i>
								{{ showRuleKey ? '' : 'Rule key' }}
							</button>
							<div
								*ngIf="showRuleKey"
								style="right: 6%; z-index: 100; width: 900px; height: 850px"
								class="absolute scrollable top62 ft-size16 white-bg mgn-bottom100 shadow-wide border-radius container-fluid"
							>
								<div class="row mgn-top15">
									<div class="col-sm-12 relative">
										<div class="mgn-bottom30">
											<h2 class="semi-bold">Code Key for Player Status</h2>
											<em>
												NOTE: Red and X rules are only applied to players where the player's current install state is "Installed" and the player's current
												repair state is "Ok" or "Fickle"
											</em>
										</div>
										<span class="pd5 ft-size28 absolute topneg10 right10 pointer" (click)="showRuleKey = false">
											<i class="fa fa-times" aria-hidden="true"></i>
										</span>
										<div class="row">
											<div class="col-sm-6">
												<h2 class="semi-bold mgn-bottom15">PLAYERS</h2>
												<ul>
													<li class="mgn-bottom20">
														<h3 style="border-radius: 24px" class="semi-bold client-health-purple white-text in-block pd10 mgn-leftneg5 shadow">Purple</h3>
														<p class="mgn-top5 regular">PP1: Repair state is idle</p>
													</li>
													<li class="mgn-bottom20">
														<h3 style="border-radius: 24px" class="semi-bold client-health-blue white-text in-block pd10 mgn-leftneg5 shadow">Blue</h3>
														<p class="mgn-top5 regular">PB1: Repair state is fickle</p>
													</li>
													<li class="mgn-bottom20">
														<h3 style="border-radius: 24px" class="semi-bold client-health-orange white-text in-block pd10 mgn-leftneg5 shadow">Orange</h3>
														<p class="mgn-top5 regular">PO1: Not installed</p>
														<p class="mgn-top5 regular">PO2: Repair state Being Repaired or Pending Completion</p>
													</li>
													<li class="mgn-bottom20">
														<h3 style="border-radius: 24px" class="semi-bold client-health-red white-text in-block pd10 mgn-leftneg5 shadow">Red</h3>
														<p class="mgn-top5 regular">PR1: Not checked in today (Not heard from in the last 24 hours) and repair state is not Fickle</p>
														<p class="mgn-top5 regular">PR2: Playlist not delivered (after 2 hours)</p>
														<p class="mgn-top5 regular">PR3: Needs software update (Player software, not the OS)</p>
													</li>
													<li class="mgn-bottom20">
														<h3 style="border-radius: 24px" class="semi-bold dark-bg white-text in-block pd10 mgn-leftneg5 shadow">X</h3>
														<p class="mgn-top5 regular">PX1: Poor network conditions</p>
													</li>
												</ul>
											</div>
											<div class="col-sm-6">
												<h2 class="semi-bold mgn-bottom15">CLIENTS</h2>
												<ul>
													<li class="mgn-bottom20">
														<h3 style="border-radius: 24px" class="semi-bold client-health-purple white-text in-block pd10 mgn-leftneg5 shadow">Purple</h3>
														<p class="mgn-top5 regular">CP1: Player(s) set to idle</p>
													</li>
													<li class="mgn-bottom20">
														<h3 style="border-radius: 24px" class="semi-bold client-health-blue white-text in-block pd10 mgn-leftneg5 shadow">Blue</h3>
														<p class="mgn-top5 regular">CB1: Player(s) set to fickle</p>
													</li>
													<li class="mgn-bottom20">
														<h3 style="border-radius: 24px" class="semi-bold client-health-orange white-text in-block pd10 mgn-leftneg5 shadow">Orange</h3>
														<p class="mgn-top5 regular">CO1: Player(s) not installed</p>
														<p class="mgn-top5 regular">CO2: Player(s) Repair state Being Repaired or Pending Completion</p>
													</li>
													<li class="mgn-bottom20">
														<h3 style="border-radius: 24px" class="semi-bold client-health-red white-text in-block pd10 mgn-leftneg5 shadow">Red</h3>
														<p class="mgn-top5 regular">CR1: Player(s) not checked in (Not heard from in the last 24 hours) and repair state is not Fickle</p>
														<p class="mgn-top5 regular">CR2: Player(s) with undelivered playlists (after two hours)</p>
														<p class="mgn-top5 regular">CR3: Player(s) need software update</p>
													</li>
													<li class="mgn-bottom20">
														<h3 style="border-radius: 24px" class="semi-bold dark-bg white-text in-block pd10 mgn-leftneg5 shadow">X</h3>
														<p class="mgn-top5 regular">CX1: Player(s) with poor network conditions</p>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<em>Scroll for more</em>
								<hr class="mgn-top0" />
								<div class="row mgn-top30 mgn-bottom30">
									<div class="col-xs-12">
										<h3 class="regular">All Services</h3>
										<p>
											Whatever is reported to be happening to a player, if you see an X on it, deal with the network first. Whatever the player is doing wrong
											is probably a result of the network issue. Fix the network first.
										</p>
										<h3 class="regular mgn-top30">Video Players</h3>
										<p>
											X on a red or orange player: Address the network issues first, then troubleshoot any remaining issues. X on a green player: There is a
											good chance this player/client is going to be a problem soon. If other players for this client have problems, the problem is probably at
											the client level, not the player level. The network issue could also be transient and may self-resolve on the next polling cycle. (Think
											network congestion during the workday)
										</p>
										<h3 class="regular mgn-top30">Play24/Barix</h3>
										<p>
											On Play24 and Barix, the X means playback has stopped or is buffering a lot due to network congestion. Expect a call soon if the client is
											paying attention.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-2">
							<portal-dropdown-menu
								[backgroundColor]="'gray-bg'"
								[height]="'ht40'"
								[options]="clientHealthDashboardService.csrList"
								[key]="'Name'"
								[selectedOption]="clientHealthDashboardService.selectedUser?.Name"
								(onOptionSelect)="onCsrSelect($event)"
							></portal-dropdown-menu>
						</div>
					</ng-container>
				</div>
				<em *ngIf="!clientHealthDashboardService.showClientDetails" class="ft-size12 absolute bottom5">
					Last refreshed: {{ clientHealthDashboardService.currentDateTime }}
				</em>
			</div>
		</div>
	</div>

	<router-outlet></router-outlet>
</div>
