<div class="col-sm-6 {{ createEditRequestService.request.RequestType === 'Video-Other' ? '' : 'col-lg-4' }} ft-size-md">
	<div [ngStyle]="{ 'height.rem': heightService.detailsAndClipsHeight() }" class="shadow border-radius white-bg pd15 mgn-bottom60 min-height800 setHeight">
		<div class="row mgn-bottom30">
			<div class="col-sm-12">
				<h2 class="semi-bold">{{ viewStateService.mode === 'edit' ? 'Edit Request Details' : 'New Request' }}</h2>
			</div>
		</div>

		<div class="row mgn-bottom30 relative">
			<!--********************************
				CLIENT NAME
			*********************************-->
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6 in-block">Client Name</h4>
				<i *ngIf="viewStateService.mode === 'new'" class="fa fa-asterisk ft-size7 red-text-light relative topneg8" aria-hidden="true"></i>
				<br />
				<div *ngIf="viewStateService.mode === 'new'">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="detailsService.clientList"
						[key]="'Name'"
						[scrollbarHidden]="true"
						[searchEnabled]="true"
						[selectedOption]="createEditRequestService.request.clientName"
						(onOptionSelect)="detailsViewService.onDropdownSelect($event, 'ClientId', 'Id')"
						(onSearchKeyupNotify)="detailsViewService.onClientSearchKeyup($event)"
					></portal-dropdown-menu>
				</div>
				<span *ngIf="viewStateService.mode === 'edit'">{{ createEditRequestService.request.clientName }}</span>
			</div>

			<!--********************************
				STATUS
			*********************************-->
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">Status</h4>
				<span>{{ createEditRequestService.request.StateName }}</span>
			</div>
		</div>

		<div class="row mgn-bottom30">
			<!--********************************
				TITLE
			*********************************-->
			<div class="col-xs-6 relative">
				<h4 class="semi-bold mgn-bottom6 in-block">Title</h4>
				<i class="fa fa-asterisk ft-size7 red-text-light relative topneg8" aria-hidden="true"></i>
				<input type="text" #title (keyup)="detailsViewService.onKeyup('Title')" [(ngModel)]="createEditRequestService.request.Title" />
			</div>

			<!--********************************
				REQUEST TYPE
			*********************************-->
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">Request Type</h4>
				<span>{{ createEditRequestService.request.RequestType }}</span>
			</div>
		</div>

		<!--********************************
			CREATED AND DUE DATES
		*********************************-->
		<div class="row mgn-bottom30">
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">Created Date</h4>
				<span>{{ createEditRequestService.request.CreateDateTime | date : 'M/d/yyyy' }}</span>
			</div>
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">Due Date</h4>
				<portal-date-time-selector
					*ngIf="appStateService.currentUser.Role === enumUserRole.CSR"
					[dateModel]="createEditRequestService.request.DueDate"
					(dateChanged)="detailsViewService.onDateChanged($event, 'DueDate')"
				></portal-date-time-selector>
				<span *ngIf="appStateService.currentUser.Role !== enumUserRole.CSR">{{ createEditRequestService.request.DueDate | date : 'M/d/yyyy' }}</span>
			</div>
		</div>
		<div class="row mgn-bottom30">
			<!--********************************
				NOTES
			*********************************-->
			<div class="col-xs-12">
				<h4 class="semi-bold mgn-bottom6">Notes</h4>
				<textarea rows="4" (keyup)="detailsViewService.onKeyup('Description')" [(ngModel)]="createEditRequestService.request.Description"></textarea>
			</div>
		</div>
		<div *ngIf="createEditRequestService.request.RequestType !== 'Video-Other'" class="row mgn-bottom30">
			<!--********************************
				COMPLIANCE TAGS
			*********************************-->
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">Compliance Tags</h4>
				<input
					type="text"
					[disabled]="createEditRequestService.request.RequestType === 'Video-Logo'"
					(keyup)="detailsViewService.onKeyup('ComplianceTags')"
					[(ngModel)]="createEditRequestService.request.ComplianceTags"
				/>
			</div>

			<!--********************************
				LIBRARY/PRODUCER
			*********************************-->
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6 in-block">Target Library</h4>
				<i class="fa fa-asterisk ft-size7 red-text-light relative topneg8" aria-hidden="true"></i>
				<div
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					attr.data-tippy-content="{{ createEditRequestService.request.targetLibraryName }}"
					[tippyProps]="{ appendTo: 'parent' }"
				>
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[isDisabled]="createEditRequestService.request.RequestType === 'Video-Logo'"
						[options]="detailsService.libraries"
						[key]="'LibraryName'"
						[scrollbarHidden]="true"
						[searchEnabled]="true"
						[selectedOption]="createEditRequestService.request.targetLibraryName"
						(onOptionSelect)="detailsViewService.onDropdownSelect($event, 'TargetLibraryId', 'Id')"
					></portal-dropdown-menu>
				</div>
			</div>
		</div>

		<div *ngIf="createEditRequestService.request.RequestType !== 'Video-Other'" class="row mgn-bottom30">
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">CSR</h4>
				<span>{{ createEditRequestService.request.csrUsername }}</span>
			</div>

			<!--********************************
				PRODUCER
			*********************************-->
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">Producer</h4>
				<div>
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="detailsService.producers$ | async"
						[key]="'UserName'"
						[scrollbarHidden]="true"
						[selectedOption]="createEditRequestService.request.producerUsername"
						(onOptionSelect)="detailsViewService.onDropdownSelect($event, 'ProducerId', 'Id')"
					></portal-dropdown-menu>
				</div>
			</div>
		</div>

		<div *ngIf="createEditRequestService.request.RequestType === 'Video-Other'" class="row">
			<div class="col-xs-12">
				<h4 class="semi-bold mgn-bottom6">Producer</h4>
				<div>
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="detailsService.producers$ | async"
						[key]="'UserName'"
						[scrollbarHidden]="true"
						[selectedOption]="createEditRequestService.request.producerUsername"
						(onOptionSelect)="detailsViewService.onDropdownSelect($event, 'ProducerId', 'Id')"
					></portal-dropdown-menu>
				</div>
			</div>
		</div>

		<div *ngIf="viewStateService.mode === 'edit'" class="row mgn-bottom30">
			<!--********************************
				ATTACHMENTS
			*********************************-->
			<div class="col-sm-6 relative">
				<h4 class="semi-bold in-block mgn-right10">Attachments</h4>
				<portal-file-upload-drop-target
					[activeUploadSequence]="'contentRequestAttachmentSequence'"
					[dropTargetHeight]="'ht50'"
					[itemId]="createEditRequestService.request.Id"
					[paddingTop]="'pd-top2'"
				></portal-file-upload-drop-target>
			</div>
			<div *ngIf="percentComplete > 0" class="col-sm-5 relative">
				<span><em>Uploading...</em></span>
				<span>{{ percentComplete }}%</span>
			</div>
		</div>

		<perfect-scrollbar *ngIf="viewStateService.mode === 'edit'" style="height: 116px" [config]="{ suppressScrollX: true }" class="">
			<div *ngFor="let attachment of createEditRequestService.request?.attachments" style="width: 103%" class="row">
				<div class="col-sm-8">
					<h4 class="ellipsis ft-size14">{{ attachment.FriendlyName }}</h4>
					<br />
				</div>
				<div class="col-sm-2 pd-right0 text-right">
					<a
						class="border-radius gray-bg dark-text absolute top0 rightneg8 pd-top8 pd-bottom6 pd-right10 pd-left10"
						href="{{ detailsService.attachmentDownloadUrl }}"
						(click)="detailsViewService.onDownloadAttachmentClick(attachment)"
					>
						<i class="fa fa-download" aria-hidden="true"></i>
					</a>
				</div>
				<div class="col-sm-2 pd-left0 text-right">
					<button
						class="wdth33 ht34 red-bg-light pd-top8 pd-bottom6 pd-right10 pd-left10 white-text relative"
						(click)="detailsViewService.onAttachmentDeleteClick(attachment)"
					>
						<i class="far fa-trash-alt absolute center-unknown-height-width" aria-hidden="true"></i>
					</button>
				</div>
			</div>
		</perfect-scrollbar>

		<div *ngIf="viewStateService.mode === 'edit'" class="row mgn-top10">
			<div class="col-sm-4">
				<span *ngIf="createEditRequestService.request.attachments?.length > 3">
					<i class="fa fa-angle-down" aria-hidden="true"></i>
					<em class="ft-size10 mgn-left6">scroll for more</em>
				</span>
			</div>
			<div class="col-sm-8 text-right">
				<span>Total attachments: {{ createEditRequestService.request.attachments?.length }}</span>
			</div>
		</div>
	</div>
</div>
