type ColumnType = 'button' | 'string' | 'checkbox' | 'iconAndNumber' | 'link' | 'date' | 'icon' | 'image';
type StateMode = 'readMode' | 'editMode';

export class Column {
	key: string;
	title: string;
	elementClassList: string;
	state: StateMode = 'readMode';
	spinnerActive: boolean;
	tooltip: string;
	color: string;
	visibility: string;
	columnDisabled: string;
	btnBadge: string;
	btnIcon: string;
	isSortable: boolean;
	type: ColumnType;
	isEditable: boolean;
	showIconOnly: boolean;
	includeTime: boolean;
	width: string;
	sortKey: string;
	snapshotDimensions: string;

	constructor(
		key: string, // 1
		title: string, // 2
		type: ColumnType, // 3
		isSortable?: boolean, // 4
		elementClassList?: string, // 5
		showIconOnly?: boolean, // 6
		isEditable?: boolean, // 7
		btnIcon?: string, // 8
		includeTime?: boolean, // 9
		width?: string, // 10
		tooltip?: string, // 11
		color?: string, // 12
		visibility?: string, // 13
		columnDisabled?: string, // 14
		btnBadge?: string, // 15
		sortKey?: string, // 16,
		snapshotDimensions?: string //17
	) {
		this.key = key;
		this.title = title;
		this.type = type;
		this.isSortable = isSortable;
		this.elementClassList = elementClassList;
		this.showIconOnly = showIconOnly;
		this.isEditable = isEditable;
		this.btnIcon = btnIcon;
		this.includeTime = includeTime;
		this.width = width;
		this.tooltip = tooltip;
		this.color = color;
		this.visibility = visibility;
		this.columnDisabled = columnDisabled;
		this.btnBadge = btnBadge;
		this.sortKey = sortKey;
		this.snapshotDimensions = snapshotDimensions;
	}

	setIsSortable(isSortable: boolean): Column {
		this.isSortable = isSortable;
		return this;
	}
	setElementClassList(elementClassList: string): Column {
		this.elementClassList = elementClassList;
		return this;
	}
	setShowIconOnly(showIconOnly: boolean): Column {
		this.showIconOnly = showIconOnly;
		return this;
	}
	setIsEditable(isEditable: boolean): Column {
		this.isEditable = isEditable;
		return this;
	}
	setBtnIcon(btnIcon: string): Column {
		this.btnIcon = btnIcon;
		return this;
	}
	setIncludeTime(includeTime: boolean): Column {
		this.includeTime = includeTime;
		return this;
	}
	setWidth(width: string): Column {
		this.width = width;
		return this;
	}
	setTooltip(tooltip: string): Column {
		this.tooltip = tooltip;
		return this;
	}
	setColor(color: string): Column {
		this.color = color;
		return this;
	}
	setVisibility(visibility: string): Column {
		this.visibility = visibility;
		return this;
	}
	setColumnDisabled(columnDisabled: string): Column {
		this.columnDisabled = columnDisabled;
		return this;
	}
	setBtnBadge(btnBadge: string): Column {
		this.btnBadge = btnBadge;
		return this;
	}
	setSortKey(sortKey: string): Column {
		this.sortKey = sortKey;
		return this;
	}
	setSnapshotDimensions(snapshotDimensions: string): Column {
		this.snapshotDimensions = snapshotDimensions;
		return this;
	}
}
