<!--*******************
	CONTENT TITLE
*********************-->
<div *ngIf="!removeHeaderFooter" class="pd-left30 pd-right30 pd-top15 pd-bottom15 title relative white-bg">
	<div class="row mgn-bottom30">
		<div *ngIf="backBtnVisible()" class="col-sm-2">
			<a (click)="onBackClick()" class="dark-text">
				<i class="fa fas fa-chevron-left mgn-right10" aria-hidden="true"></i>
				Back
			</a>
		</div>
		<div class="{{ backBtnVisible() ? 'col-sm-8' : 'col-sm-10 col-sm-offset-1' }} text-center">
			<h1 class="in-block semi-bold">{{ playlistService.selectedContentItem.contentName }}</h1>
			<h4 *ngIf="playlistService.selectedContentItem.breadcrumbs?.length" class="mgn-top5">
				<em>
					{{ playlistService.selectedContentItem.breadcrumbs[0] }} > {{ playlistService.selectedContentItem.breadcrumbs[1] }}
					<em *ngIf="playlistService.selectedContentItem.breadcrumbs[2]">></em>
					{{ playlistService.selectedContentItem.breadcrumbs[2] }}
				</em>
			</h4>
		</div>
		<div class="{{ backBtnVisible() ? 'col-sm-2' : 'col-sm-1' }} text-right">
			<a class="ft-size26 dark-text" (click)="exitClickNotify.emit()"><i class="fa fa-times" aria-hidden="true"></i></a>
		</div>
	</div>
</div>

<!--**************************
	VIDEO AND STILL PREVIEW
****************************-->
<div
	*ngIf="appStateService.product?.Route !== 'hold' && !playlistService.selectedContentItem?.isChannel"
	(mouseover)="onPreviewHover('over')"
	(mouseleave)="onPreviewHover('out')"
	class="video-container col-centered relative {{ removeHeaderFooter ? 'pd-top50 pd-left30 pd-right30 pd-bottom30 white-bg border-radius' : '' }}"
>
	<a *ngIf="removeHeaderFooter" class="ft-size26 dark-text absolute right10 top5" (click)="exitClickNotify.emit()">
		<i class="fa fa-times" aria-hidden="true"></i>
	</a>
	<video
		*ngIf="playlistService.selectedContentItem?.contentType === 'video/mp4'"
		#video
		(playing)="onPlay()"
		(ended)="onVideoEnd()"
		(timeupdate)="setCurrentTime($event)"
		[ngStyle]="{ 'max-height.px': maxVideoHeight, 'width.%': appStateService.product?.Route === 'poster' ? '50' : null }"
		src="{{ playlistService.selectedContentItem.previewUrl }}"
		class="white-bg"
		type="video/mp4"
		autoplay
	>
		Oops! Your browser does not support this preview.
	</video>

	<c-video-controls
		*ngIf="showControls"
		[classList]="'pd15 setWidth50 bottom15 ft-size26'"
		[currentTimeView]="currentTimeView"
		[duration]="duration"
		[video]="video"
		(onVideoControlClick)="onVideoControlClick($event)"
	></c-video-controls>

	<img
		*ngIf="playlistService.selectedContentItem?.contentType === 'image/png'"
		(load)="onPlay()"
		class="{{ heightWidth() }} relative shadow border-radius"
		src="{{ playlistService.selectedContentItem.previewUrl }}"
	/>

	<ng-container *ngIf="contentViewService.showC24HTML(playlistService.selectedContentItem, contentViewService.c24SVG)">
		<span [innerHTML]="contentViewService.c24SVG" class="animated fadeIn"></span>
	</ng-container>
</div>

<!--******************************
	HOLD VOICE TALENT PREVIEWS
*******************************-->
<div *ngIf="appStateService.product?.Route === 'hold'" class="white-bg pd30">
	<div class="row">
		<div class="col-xs-12">
			<h2 class="semi-bold">
				Available Voice Talents
				<em>(click to preview)</em>
			</h2>
		</div>
	</div>
	<div class="row mgn-top16">
		<div
			*ngFor="let contentFile of playlistService.selectedContentItem.contentFiles"
			class="col-sm-3 {{ playlistService.selectedContentItem.contentFiles.length > 4 ? 'mgn-bottom15' : null }}"
		>
			<button (click)="onTalentPreviewClick(contentFile)" class="noExit {{ contentFile.previewBtnColor }} {{ contentFile.previewBtnTextColor }} relative">
				<i class="fa {{ contentFile.previewBtnIcon }} mgn-right6 noExit" aria-hidden="true"></i>
				<i
					*ngIf="playlistService.selectedContentItem.contentFileId === contentFile.Id"
					class="fa fa-check absolute top2 right3 ft-size12"
					aria-hidden="true"
				></i>
				{{ contentFile.previewBtnText }}
			</button>
		</div>
	</div>
</div>

<div *ngIf="!removeHeaderFooter" class="white-bg script pd30 mgn-topneg5">
	<!--*******************
		CONTENT SCRIPT
	*********************-->
	<div class="row mgn-bottom15">
		<div class="col-sm-12">
			<h2 class="semi-bold">Script</h2>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<p class="ft-size16">{{ playlistService.selectedContentItem.script }}</p>
		</div>
	</div>

	<ng-container *ngIf="playlistService.selectedContentItem.description">
		<div class="row mgn-top30 mgn-bottom15">
			<div class="col-sm-12">
				<h2 class="semi-bold">Description</h2>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				<p class="ft-size16">{{ playlistService.selectedContentItem.description }}</p>
			</div>
		</div>
	</ng-container>

	<!--*******************
		FAVORITE BUTTON
	*********************-->
	<div *ngIf="appStateService.activeFeatureArea !== featureArea.PLAYLIST_DETAILS" class="row mgn-top30">
		<div class="col-xs-2">
			<button
				class="{{ playlistService.selectedContentItem.isFavorite ? 'pink-bg' : 'white-bg' }} relative white-text"
				ngxTippy
				tippyName="{{ utilService.guid | wrapFn }}"
				[tippyProps]="{ appendTo: 'parent' }"
				attr.data-tippy-content="{{ playlistService.selectedContentItem.favoriteBtnTooltip }}"
				(click)="onFavoriteClick()"
			>
				<i class="fa fa-heart {{ playlistService.selectedContentItem.isFavorite ? 'white-text' : 'pink-text' }}" aria-hidden="true"></i>
				<i *ngIf="playlistService.selectedContentItem.isFavorite" class="fa fa-check absolute ft-size10 top6 right10" aria-hidden="true"></i>
			</button>
		</div>
		<div *ngIf="appStateService.product?.Route === 'hold'" class="col-xs-4 col-xs-offset-6 text-right pd-top6">
			<portal-checkbox
				[backgroundColor]="'white-bg'"
				[type]="'checkbox'"
				[isChecked]="playlistService.selectedContentItem.silenceMusic"
				(checkboxClickNotify)="onSilenceMusicClick()"
			></portal-checkbox>
			<span (click)="onSilenceMusicClick()" class="relative bottom6 mgn-left10 pointer">Silence music track</span>
			<i
				ngxTippy
				tippyName="{{ utilService.guid | wrapFn }}"
				data-tippy-content="Silences the music track for this message only. Useful for custom messages that contain their own music."
				[tippyProps]="{ appendTo: 'parent' }"
				class="far fa-question-circle relative bottom15 rightneg6"
			></i>
		</div>
	</div>
</div>
