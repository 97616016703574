import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppErrorService {

    public showAppError: boolean;
    public error: HttpErrorResponse;
    public friendlyMessage: string;
    public hideReloadPageBtn: boolean;

    constructor(
        private httpClient: HttpClient
    ) { }


    public sendEmail(obj): void {
        this.httpClient.post(`${environment.adminUrl}Email`, obj).subscribe();
    }
}
