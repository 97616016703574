import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import * as moment from 'moment';
import { IMyDateModel } from 'angular-mydatepicker';

import { DateTimeSelectorService } from './date-time-selector.service';
import { UtilitiesService } from '../../../core/services';
import { View } from './models';

@Component({
    selector: 'portal-date-time-selector',
    templateUrl: './date-time-selector.component.html',
    styleUrls: ['./date-time-selector.component.scss']
})
export class DateTimeSelectorComponent implements OnInit {

    @Input() backgroundColor: string;
    @Input() clearDateEnabled: boolean;
    @Input() dateModel: string;
    @Input() disabled: boolean;
    @Input() disableUntil: any;
    @Input() fontSize: string;
    @Input() height: string;
    @Input() includeTime: boolean;

    @Output() dateChanged = new EventEmitter<string>();

    public timesOfDay: any[];
    public dateView: View;

    constructor(public dateTimeSelectorService: DateTimeSelectorService, private utilService: UtilitiesService) { }

    ngOnInit() {
        this.timesOfDay = this.utilService.getTimesOfDay();
        if (!this.dateTimeSelectorService.model.Date) {
            const todayWithTimeMidnight = new Date().setHours(0, 0, 0, 0);
            this.dateTimeSelectorService.view.date = this.dateTimeSelectorService.convertDateModelToView((moment(todayWithTimeMidnight)).toString()).date;
        }
        this.dateView = this.dateTimeSelectorService.convertDateModelToView(this.dateModel);
    }

    onDateChanged(selectedDate: IMyDateModel): void {
        this.dateTimeSelectorService.view.date = selectedDate;
        this.dateModel = this.dateTimeSelectorService.convertDateViewToModel(this.dateTimeSelectorService.view.date, this.dateTimeSelectorService.view.time);
        this.dateChanged.emit(this.dateModel !== 'Invalid date' ? this.dateModel : null);
    }

    onTimeSelect(selectedTime: any): void {
        selectedTime = selectedTime.name;
        this.dateTimeSelectorService.view.time = selectedTime;
        this.dateModel = this.dateTimeSelectorService.convertDateViewToModel(this.dateTimeSelectorService.view.date, this.dateTimeSelectorService.view.time);
        this.dateChanged.emit(this.dateModel !== 'Invalid date' ? this.dateModel : null);
    }

}
