import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { ClientHealthDashboardService } from './client-health-dashboard.service';

type NameAndId = {Name: string, Id: number};

@Component({
    selector: 'app-client-gumball-report',
    templateUrl: './client-health-dashboard.component.html',
    styleUrls: ['./client-health-dashboard.component.scss']
})
export class ClientHealthDashboardComponent implements OnInit {

    
    public context: CanvasRenderingContext2D;
    public showRuleKey: boolean;

    constructor(
        public clientHealthDashboardService: ClientHealthDashboardService,
        public location: Location,
        private router: Router
    ) {}

    ngOnInit(): void {
    }

    public onBackClick(): void {
        this.clientHealthDashboardService.backBtnClicked = true;
        this.router.navigate([`/client-health-dashboard/user-id/${this.clientHealthDashboardService.selectedUser.Id}`]);
        this.clientHealthDashboardService.statusCircles = this.clientHealthDashboardService.gumballClientsAll;
    }   

    public onCrmClick(): void {
        localStorage.setItem('changeClientUrl', `/crm/client-details/locations/`);
        window.open(`/my-products/${this.clientHealthDashboardService.selectedClient.ClientId}`);
    }

    public onCsrSelect(user: NameAndId): void {
        this.clientHealthDashboardService.selectedUser = user;
        this.router.navigate([`/client-health-dashboard/user-id/${this.clientHealthDashboardService.selectedUser.Id}`]);
        this.clientHealthDashboardService.clientDoughnutChart.destroy(); //Destroy so it can re-initialize
        this.clientHealthDashboardService.onCsrSelect();
    }
}
