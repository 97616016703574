import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { DeliveryQueueView } from '../_models/delivery-queue-view';
import { environment } from '../../../../environments/environment';
import { Clients, Players, ServiceLocations } from '../../../shared/api-models/admin';
import { UtilitiesService } from '../../../core/services';

@Injectable({
	providedIn: 'root'
})
export class HoldDeliveryQueueService {
	public pageNumber: number = 1;
	public totalItems: number;

	constructor(private httpClient: HttpClient, private utilService: UtilitiesService) {}

	public delete(id: number): Observable<any> {
		return this.httpClient.delete(environment.deliveryUrl + `DeliveryQueue/${id}`);
	}

	public getOnHoldQueue(): Observable<DeliveryQueueView[]> {
		return this.httpClient.get(environment.deliveryUrl + `DeliveryQueue/OnHoldQueue?pageSize=999`, { observe: 'response' }).pipe(
			switchMap((res: HttpResponse<any>) => {
				const deliveryQueue: DeliveryQueueView[] = res.body;
				this.totalItems = parseInt(res.headers.get('x-result-count'));
				const clients$ = deliveryQueue.map((queue) => {
					if (queue.ClientId) {
						return this.httpClient.get(`${environment.adminUrl}CoreClients/${queue.ClientId}`);
					}
					return of('');
				});

				const players$ = deliveryQueue.map((queue) => {
					if (queue.PlayerModelTypeId) {
						return this.httpClient.get(`${environment.adminUrl}CorePlayers/${queue.PlayerId}`).pipe(map((res) => res[0]));
					}
					return of('');
				});

				const observArr$ = [].concat.apply([], [clients$, players$]);

				if (deliveryQueue.length === 0) {
					return of([]);
				}
				return forkJoin(observArr$).pipe(
					map((otherData: any) => {
						//Additional data retrieved from deliveryQueue foreign key Id's

						const clients: Clients[] = otherData.slice(0, deliveryQueue.length);
						const players: Players[] = otherData.slice(deliveryQueue.length, deliveryQueue.length * 2);

						return this.deliveryQueueView(deliveryQueue, clients, players);
					})
				);
			})
		);
	}

	public patch(url: string, id: number, deliveryQueue: DeliveryQueueView): Observable<any> {
		return this.httpClient.patch(environment.deliveryUrl + `${url}/${id}`, deliveryQueue);
	}

	private deliveryQueueView(deliveryQueue: DeliveryQueueView[], clients?: Clients[], players?: Players[]): DeliveryQueueView[] {
		return deliveryQueue.map((queue, index) => {
			queue.forceToTopBtn = 'fas fa-arrow-up';
			queue.resetBtn = 'fas fa-undo-alt';
			queue.deleteBtn = 'far fa-trash-alt';
			queue.classList = `${this.setRowColors(queue)}`;
			queue.border = 'dark-border';
			queue.phoneMAC = this.setPhoneMAC(players, index);
			// queue.status = queue.StatusJson ? queue.StatusJson.substring(0, 160) + (this.addEllipsis(queue.StatusJson) ? '...' : '') : null;
			queue.status = queue.StatusJson ? JSON.stringify(queue.StatusJson?.substring(0, 160)) + (this.addEllipsis(queue.StatusJson) ? '...' : '') : 'N/A';
			queue.clientName = clients[index].Name;
			queue.locationName = this.location(players[index].Location);
			queue.playerModel = players[index].PlayerModel.Name;
			queue.playerModelType = players[index].PlayerModel.PlayerModelType.Name;
			return queue;
		});
	}

	private isJsonString(str) {
		try {
			return JSON.parse(str) && !!str;
		} catch (e) {
			return false;
		}
	}

	private setPhoneMAC(players: Players[], index): string {
		const player: Players = players[index];
		if (player.PlayerModel.PlayerModelTypeId === 3) {
			//Hold-Network
			return player.MacAddress;
		} else if (player.PlayerModel.PlayerModelTypeId === 1) {
			//Hold-Dialup
			return this.utilService.formatPhoneNumber(player.RemotePhone);
		}
	}

	private addEllipsis(str: string): boolean {
		return str?.length > 160;
	}

	private location(location: ServiceLocations): any {
		return `<span class="semi-bold">${location.Name}</span><br> (${location.City}, ${location.State})`;
	}

	private setRowColors(queue: DeliveryQueueView): string {
		if (queue.IsHighPriority) {
			return 'delivery-queue-green-bg';
		}
		switch (queue.DeliveryStatusId) {
			case 2:
				return 'delivery-queue-yellow-bg';
			case 3:
				return 'delivery-queue-brown-bg';
			case 4:
				return 'delivery-queue-red-bg';
		}
	}
}
