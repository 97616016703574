<div class="relative in-block">
	<button class="{{isSubCategory ? 'visibility-hidden' : ''}} {{bgColor}} mgn-right6 ht24 wdth24 relative" (mouseenter)="onHoverBtnMouseenter('new')" (mouseleave)="onHoverBtnMouseleave()" (click)="createCategoryClickNotify.emit()">
		<i class="fas fa-plus ft-size12 absolute center-unknown-height-width"></i>
	</button>
	<div *ngIf="showNewTooltip" style="opacity: 0.9; z-index: 200" [ngStyle]="{'width.px': isLibrary ? 100 : 118}" class=" right15 absolute center ft-size12 bottomneg26 border-radius pd3 dark-bg white-text">
			<span>{{isLibrary ? 'New category' : 'New sub category'}}</span>
	</div>
</div>
<button *ngIf="itemName !== 'New Content'" class="{{bgColor}} {{deletable ? 'mgn-right6' : ''}} ht24 wdth24 relative" (mouseenter)="onHoverBtnMouseenter('edit')" (mouseleave)="onHoverBtnMouseleave()" (click)="nameEditNotify.emit()">
	<i class="fas fa-pencil-alt ft-size12 absolute center-unknown-height-width"></i>
</button>
<div *ngIf="showEditTooltip" style="opacity: 0.9; z-index: 200;" class="{{deletable ? 'edit-tooltip-delete-btn-visible' : 'edit-tooltip-delete-btn-hidden'}} absolute center ft-size12 bottomneg26 border-radius pd3 dark-bg white-text">
		Edit name
</div>

<button *ngIf="deletable" class="gray-bg ht24 wdth24 relative {{bgColor}}" (click)="deleteClickNotify.emit(category)" (mouseenter)="onHoverBtnMouseenter('delete')" (mouseleave)="onHoverBtnMouseleave()">
	<i class="far fa-trash-alt ft-size12 absolute center-unknown-height-width"></i>
</button>
<div *ngIf="showDeleteTooltip" style="opacity: 0.9; width: 125%; right: 0%; z-index: 200;" class="absolute center ft-size12 bottomneg26 border-radius pd3 dark-bg white-text">
		Delete category
</div>
