import { Component, HostListener, Input, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';

import { AppStateService, UtilitiesService } from 'src/app/core/services';
import { CanvasService } from '../_services';
import { EProductTypes } from 'src/app/shared/api-models/admin';

@Component({
	selector: 'me-grid-lines',
	templateUrl: './grid-lines.component.html',
	styleUrls: ['./grid-lines.component.scss']
})
export class GridLinesComponent implements OnInit, AfterViewInit {
	@Input() canvasContainerWidth: number;

	public horizontalLines: { top: number }[] = [];
	public verticalLines: { left: number }[] = [];
	public horizontalPixelMarkers: { position: number; value: number; subMarkers: number[] }[] = [];
	public verticalPixelMarkers: { position: number; value: number; subMarkers: number[] }[] = [];
	private isDraggingHorizontal: number | null = null;
	private isDraggingVertical: number | null = null;

	constructor(
		private appStateService: AppStateService,
		private canvasService: CanvasService,
		private changeDetectorRef: ChangeDetectorRef,
		public utilService: UtilitiesService
	) {}

	ngOnInit() {
		// Add event listeners for horizontal lines
		this.addHorizontalLineListeners();
		this.addVerticalLineListeners();
		window.addEventListener('resize', () => {
			this.generatePixelMarkers();
		});
	}

	ngAfterViewInit() {
		this.generateVerticalPixelMarkers();
	}

	ngOnChanges() {
		this.generatePixelMarkers();
	}

	@HostListener('document:mousemove', ['$event'])
	onMouseMove(event: MouseEvent) {
		const container = document.getElementById('container');
		if (container) {
			const containerRect = container.getBoundingClientRect();

			if (this.isDraggingHorizontal !== null) {
				const newY = event.clientY - containerRect.top;
				if (newY >= 0 && newY <= containerRect.height) {
					this.horizontalLines[this.isDraggingHorizontal].top = newY;
					this.changeDetectorRef.detectChanges();
				}
			}

			if (this.isDraggingVertical !== null) {
				const newX = event.clientX - containerRect.left;
				if (newX >= 0 && newX <= containerRect.width) {
					this.verticalLines[this.isDraggingVertical].left = newX;
					this.changeDetectorRef.detectChanges();
				}
			}
		}
	}

	@HostListener('document:mouseup', ['$event'])
	onMouseUp() {
		this.isDraggingHorizontal = null;
		this.isDraggingVertical = null;
		document.body.style.cursor = 'default';
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: Event) {
		this.generatePixelMarkers();
		this.changeDetectorRef.detectChanges();
	}

	public addHorizontalLine() {
		this.horizontalLines.push({ top: 16 });
		this.changeDetectorRef.detectChanges();
		this.addHorizontalLineListeners();
	}

	public addVerticalLine() {
		this.verticalLines.push({ left: 16 });
		this.changeDetectorRef.detectChanges();
		this.addVerticalLineListeners();
	}

	public removeHorizontalLine() {
		this.horizontalLines.pop();
		this.changeDetectorRef.detectChanges();
	}

	public removeVerticalLine() {
		this.verticalLines.pop();
		this.changeDetectorRef.detectChanges();
	}

	private generatePixelMarkers(): void {
		const canvasWidth = this.canvasService.canvasSize().width;
		const browserWidth = window.innerWidth;

		const markerInterval = 100; // Pixels between each marker
		const subMarkerCount = 8; // Number of sub-markers between main markers
		this.horizontalPixelMarkers = [];

		const startOffset = (this.canvasContainerWidth - canvasWidth) / 2;

		// Calculate the number of full markers
		const fullMarkerCount = Math.floor(canvasWidth / markerInterval);

		// Generate markers and sub-markers
		for (let i = 0; i <= fullMarkerCount; i++) {
			const markerPosition = i * markerInterval;
			const subMarkers = [];

			// Generate sub-markers
			for (let j = 1; j <= subMarkerCount; j++) {
				const subMarkerPosition = markerPosition + (j * markerInterval) / (subMarkerCount + 1);
				if (subMarkerPosition <= canvasWidth) {
					subMarkers.push(startOffset + subMarkerPosition);
				} else {
					break; // Stop if we exceed the canvas width
				}
			}

			// Add the marker if it's within the canvas width
			if (markerPosition <= canvasWidth) {
				this.horizontalPixelMarkers.push({
					position: startOffset + markerPosition,
					value: markerPosition,
					subMarkers: subMarkers
				});
			} else if (subMarkers.length > 0) {
				// If the marker is beyond canvas width but we have sub-markers,
				// add them to the previous marker
				this.horizontalPixelMarkers[this.horizontalPixelMarkers.length - 1].subMarkers.push(...subMarkers);
			}
		}

		// Remove any empty sub-markers from the last marker
		if (this.horizontalPixelMarkers.length > 0) {
			const lastMarker = this.horizontalPixelMarkers[this.horizontalPixelMarkers.length - 1];
			lastMarker.subMarkers = lastMarker.subMarkers.filter((subMarker) => subMarker <= startOffset + canvasWidth);
		}

		// Adjust markers if browser width is less than 1520px
		if (browserWidth < 1520 && this.horizontalPixelMarkers.length > 1 && this.appStateService.product.Id === EProductTypes.LOBBY_VIDEO) {
			// Remove the last 3 sub-markers from the last marker
			const lastMarker = this.horizontalPixelMarkers[this.horizontalPixelMarkers.length - 1];
			lastMarker.subMarkers = lastMarker.subMarkers.slice(0, -3);
		}
	}

	private generateVerticalPixelMarkers(): void {
		const totalHeight = 558; // Total height for markers
		const startOffset = 21; // Start 21 pixels from the top
		const markerInterval = 100; // Pixels between each marker
		const subMarkerCount = 8; // Number of sub-markers between main markers
		const subMarkersToRemove = 5; // Number of sub-markers to remove

		for (let i = 0; i <= totalHeight; i += markerInterval) {
			const subMarkers = [];
			for (let j = 1; j <= subMarkerCount; j++) {
				subMarkers.push(startOffset + i + (j * markerInterval) / (subMarkerCount + 1));
			}

			// If it's the last marker, remove the last 5 sub-markers
			if (i + markerInterval > totalHeight) {
				subMarkers.splice(-subMarkersToRemove);
			}

			this.verticalPixelMarkers.push({
				position: startOffset + i,
				value: i,
				subMarkers: subMarkers
			});
		}
	}

	private addHorizontalLineListeners() {
		setTimeout(() => {
			const index = this.horizontalLines.length - 1;
			const lineElement = document.getElementById(`draggableLineHorizontal${index}`);
			if (lineElement) {
				lineElement.addEventListener('mousedown', (event) => {
					event.preventDefault();
					this.isDraggingHorizontal = index;
					document.body.style.cursor = 'ns-resize';
				});
			}
		}, 0);
	}
	private addVerticalLineListeners() {
		setTimeout(() => {
			const index = this.verticalLines.length - 1;
			const lineElement = document.getElementById(`draggableLineVertical${index}`);
			if (lineElement) {
				lineElement.addEventListener('mousedown', (event) => {
					event.preventDefault();
					this.isDraggingVertical = index;
					document.body.style.cursor = 'ew-resize';
				});
			}
		}, 0);
	}
}
