import { inject } from '@angular/core';
import { Router, CanActivateFn, ActivatedRouteSnapshot } from '@angular/router';

import { AppStateService } from '../app-state.service';
import { AuthUser } from '../../../shared/api-models/auth/auth-user';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

export const isEmployeeContractorOrVoiceTalentGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
	const appStateService = inject(AppStateService);
	const router = inject(Router);

	const currentUser: AuthUser = JSON.parse(appStateService.getSessionItem('currentUser'));

	// Check if the current user is an employee, video content contractor, or voice talent
	if (currentUser.IsEmployee || currentUser.Role === UserRole.VIDEO_CONTENT_CONTRACTOR || currentUser.Role === UserRole.VOICE_TALENT) {
		return true;
	} else {
		router.navigate(['/access-denied']);
		return false; // Explicitly return false to prevent navigation
	}
};
