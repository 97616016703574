export class ContentRequestClips {
    Id: number;
    ContentRequestId: number;
    Title: string;
    Description: string;
    SimilarClip: string;
    ScriptText: string;
    CurrentRevision: number;
    LastRevisedDateTime: string;
    IsApproved: boolean;
    CompletedContentId: number;
    CompletedFilename: string;
    LastUpdatedBy: number;
    LastUpdatedDateTime: string;
}