<portal-header></portal-header>
<div class="container-fluid mx-4 mgn-top30 relative">
	<div class="row mgn-bottom30">
		<h1 class="semi-bold">
			<i class="fa fa-phone-volume mgn-right15" aria-hidden="true"></i>
			On Hold Delivery Queue
		</h1>
	</div>

	<div class="row">
		<div class="col-lg-2 pd-left0 relative">
			<portal-checkbox
				[backgroundColor]="'white-bg'"
				[isChecked]="autoRefresh"
				[type]="'checkbox'"
				(checkboxClickNotify)="onAutoRefreshClick()"
			></portal-checkbox>
			<label (click)="onAutoRefreshClick()" class="relative mgn-left5 bottom7 pointer">Auto Refresh (30s)</label>
		</div>

		<div class="hidden-xs col-lg-5">
			<div id="status-key" class="row">
				<div class="col-sm-3 center delivery-queue-yellow-bg">
					<span>SCHEDULED</span>
				</div>
				<div class="col-sm-3 center delivery-queue-brown-bg">
					<span>IN PROCESS</span>
				</div>
				<div class="col-sm-3 center delivery-queue-green-bg">
					<span class="high-priority">HIGH PRIORITY</span>
				</div>
				<div class="col-sm-3 center delivery-queue-red-bg">
					<span>ERROR</span>
				</div>
			</div>
		</div>

		<div class="hidden-xs col-lg-4 col-lg-offset-1">
			<div class="row btn-key">
				<div class="col-sm-3 col-lg-4">
					<button style="width: initial" class="blue-bg pd-left4 pd-right4 white-text">
						<i class="fa fa-arrow-up" aria-hidden="true"></i>
					</button>
					<span>- High priority</span>
				</div>
				<div class="col-sm-3 col-lg-4">
					<button style="width: initial" class="blue-bg pd-left4 pd-right4 white-text">
						<i class="fa fa-undo" aria-hidden="true"></i>
					</button>
					<span>- Reset playlist</span>
				</div>
				<div class="col-sm-3 col-lg-4">
					<button style="width: initial" class="red-bg-light pd-left4 pd-right4 white-text">
						<i class="far fa-trash-alt" aria-hidden="true"></i>
					</button>
					<span>- Delete playlist</span>
				</div>
			</div>
		</div>
	</div>
	<div class="row relative mgn-bottom15">
		<div class="col-sm-1 pd-left0">
			<button class="white-bg" (click)="getOnHoldQueue()">
				<span class="refresh-now">Refresh</span>
				<i class="fas fa-sync-alt refresh-icon" aria-hidden="true"></i>
			</button>
		</div>
		<div class="col-sm-3">
			<h4 *ngIf="refreshingVisible" class="mgn-left8 mgn0 in-block relative">
				<em>Refreshing...</em>
				<portal-spinner [height]="'htpx18'" [width]="'wdthpx18'" class="absolute left100 top10"></portal-spinner>
			</h4>
		</div>
	</div>

	<div class="row mgn-bottom15">
		<div class="col-sm-2 pd-left0">
			<button class="hidden-xs blue-bg white-text setWidth75" (click)="onResetSortClick()">
				<i class="fa fa-sort mgn-right15" aria-hidden="true"></i>
				Default Sort
			</button>
		</div>
		<div class="col-sm-3">
			<span class="hidden-xs relative top10"><em>(By date, with bumped first and errors last)</em></span>
		</div>
	</div>

	<div class="row relative">
		<portal-table
			[table]="tableConfigService.table"
			[config]="tableConfigService.configuration"
			[data]="deliveryQueue"
			[showJumpToPage]="true"
			[sortBy]="resetSort"
			[totalItems]="holdDeliveryQueueService.totalItems"
			(buttonClickNotify)="onTableButtonClick($event)"
			(currentPageNotify)="onPageSelect($event)"
		></portal-table>
	</div>

	<h3 *ngIf="noResultsFound" class="mgn-top30 mgn-bottom50 gray-text-darker"><em>No playlists in the delivery queue.</em></h3>
</div>
