import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class BrowserDetectorService {

  constructor(private deviceDetectorService: DeviceDetectorService) { }

  public browserNotChromeOrFirefox(): boolean {
    return this.deviceDetectorService.getDeviceInfo().browser !== 'Chrome' &&
    this.deviceDetectorService.getDeviceInfo().browser !== 'Firefox';
  }

  public isRecord24SupportedBrowser(): boolean {
    let browser = this.deviceDetectorService.getDeviceInfo().browser;
    let supportedBrowsers = ['Chrome', 'Firefox', 'Safari', 'MS-Edge-Chromium'];
    return supportedBrowsers.some(b => b === browser);
  }

  public isMajorBrowser(): boolean {
    return this.browserIsChrome() || this.browserIsFirefox() || this.browserIsSafari() || this.browserIsIE() || this.browserIsEdge();
  }

  public browserIsIE(): boolean {
    return this.deviceDetectorService.getDeviceInfo().browser === 'IE';
  }

  public browserIsSafari(): boolean {
    return this.deviceDetectorService.getDeviceInfo().browser === 'Safari';
  }

  public browserIsFirefox(): boolean {
    return this.deviceDetectorService.getDeviceInfo().browser === 'Firefox';
  }

  public browserIsChrome(): boolean {
    return this.deviceDetectorService.getDeviceInfo().browser === 'Chrome';
  }

  public browserIsEdge(): boolean {
    return this.deviceDetectorService.getDeviceInfo().browser === 'MS-Edge-Chromium';
  }

  public isMajorBrowserOldVersion(): boolean {
  	return this.isChromeOldVersion() ||
  		   this.isFirefoxOldVersion() ||
  		   this.isSafariOldVersion() ||
  		   this.isIEOldVersion();
  }

  public isMobileDevice(): boolean {
      return this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet();
  }

  public osIsWindows(): boolean {
    return this.deviceDetectorService.getDeviceInfo().os === 'Windows';
  }

  private isChromeOldVersion(): boolean {
  	return this.browserIsChrome() && +this.deviceDetectorService.browser_version < 88;
  }

  private isFirefoxOldVersion(): boolean {
  	return this.browserIsFirefox() && +this.deviceDetectorService.browser_version < 80;
  }

  private isSafariOldVersion(): boolean {
  	return this.browserIsSafari() && +this.deviceDetectorService.browser_version < 13;
  }

  private isIEOldVersion(): boolean {
  	return this.browserIsIE() && +this.deviceDetectorService.browser_version < 11;
  }
}
