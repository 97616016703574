import { Injectable } from '@angular/core';

import { Column, Table, TableConfig } from '../../../shared/components/table/view-models';

@Injectable()
export class TableConfigService {
	public columns: Column[] = [
		new Column('openBtn', 'View', 'button', false, 'gray-bg', null, null, null, null, '8.5'),
		new Column('CompanyName', 'Company', 'string').setIsSortable(true).setElementClassList('semi-bold').setSortKey('Company'),
		new Column('CompanyIndustry', 'Industry', 'string').setIsSortable(true).setSortKey('Industry'),
		new Column('BillingCity', 'City', 'string').setIsSortable(true).setSortKey('City'),
		new Column('BillingState', 'State', 'string').setIsSortable(true).setSortKey('State'),
		new Column('Csr', 'CSR', 'string').setIsSortable(true).setSortKey('CSR'),
		new Column('Salesperson', 'Salesperson', 'string').setIsSortable(true),
		new Column('isHoldCREligibleIcon', 'CR', 'icon').setIsSortable(true).setSortKey('CR'),
		new Column('isHoldClientIcon', 'Hold', 'icon').setIsSortable(true).setElementClassList('blue-text').setSortKey('Hold'),
		new Column('isVideoClientIcon', 'Video', 'icon').setIsSortable(true).setElementClassList('red-text').setSortKey('Video'),
		new Column('isMusicClientIcon', 'Radio', 'icon').setIsSortable(true).setElementClassList('purple-text').setSortKey('Radio'),
		new Column('isVideoWallClientIcon', 'Video Wall', 'icon').setIsSortable(true).setElementClassList('red-text').setSortKey('Video Wall'),
		new Column('isWebPlayerClientIcon', 'Web', 'icon').setIsSortable(true).setElementClassList('red-text').setSortKey('Web'),
		new Column('isPosterClientIcon', 'Poster', 'icon').setIsSortable(true).setElementClassList('red-text').setSortKey('Poster')
	];

	public configuration: TableConfig = {
		itemsPerPage: 25,
		headerColor: 'green-bg',
		rowFontSize: 'ft-size14',
		headerFontSize: 'ft-size14'
	};
	public table: Table = new Table(this.columns);
}
