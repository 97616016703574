<portal-header *ngIf="!contentBrowserService.isPopup"></portal-header>

<div *ngIf="cvStateService.viewState.modalVisible" class="absolute left0 right0">
	<div class="modal-overlay" (mouseup)="modalService.onModalExit()"></div>
	<div class="row">
		<div
			style="top: -16px; margin-bottom: 60px; z-index: 200"
			class="col-xs-12 col-md-8 col-lg-6 col-sm-offset-3 min-height600 margin mgn-bottom30 absolute left0 right0 slideUp"
		>
			<c-preview-modal (exitClickNotify)="modalService.onModalExit()"></c-preview-modal>
		</div>
	</div>
</div>

<section *ngIf="fileUploadStateService.currentStep()?.name === 'uploadModal'" class="absolute left0 right0">
	<div class="modal-overlay"></div>
	<div class="row">
		<div style="top: 100px; margin-bottom: 60px; z-index: 200" class="col-md-7 min-height600 col-centered absolute top50 left0 right0 animated">
			<portal-file-upload-modal [selectedLibrary]="librariesService.selectedLibrary"></portal-file-upload-modal>
		</div>
	</div>
</section>

<section
	*ngIf="fileUploadStateService.currentStep()?.name === 'progressBar' && !browserDetectorService.isMobileDevice()"
	class="container-fluid hidden-xs hidden-sm hidden-md"
>
	<div class="modal-overlay"></div>
	<div class="row">
		<div style="margin-bottom: 60px; z-index: 115" class="col-md-7 min-height600 col-centered absolute top50 left0 right0 animated">
			<portal-file-upload-progress-bar></portal-file-upload-progress-bar>
		</div>
	</div>
</section>

<div *ngIf="appStateService.activeFeatureArea === featureArea.CONTENT_MANAGER" class="container-fluid">
	<div class="row mgn-bottom15 mgn-top15">
		<div class="col-xs-4 col-md-2">
			<div class="row">
				<div class="col-xs-12 col-lg-9">
					<button (click)="location.back()" class="white-bg">
						<i class="fas fa-chevron-left mgn-right12"></i>
						Back
					</button>
				</div>
			</div>
		</div>

		<div class="col-xs-6 col-xs-offset-2 col-md-offset-4 text-right">
			<i class="mgn-right12 ft-size28 fas fa-th-list {{ appStateService.product.color }}-text"></i>
			<h1 class="in-block">
				<span class="semi-bold">Content Manager</span>
				|
				<em>{{ appStateService.product.ProductName }}</em>
			</h1>
		</div>
	</div>
	<div class="{{ appStateService.product.color }}-gradient mgn-bottom45 ht2 border-radius"></div>
</div>

<div class="{{ contentBrowserService.classList(contentBrowserService.isPopup) }}">
	<div *ngIf="!browserDetectorService.isMobileDevice()" class="container-fluid hidden-xs hidden-sm hidden-md">
		<div *ngIf="contentBrowserService.isPopup" class="row">
			<div class="col-xs-6">
				<h1 class="semi-bold">Select Content</h1>
			</div>
			<div class="col-xs-6 relative">
				<a (click)="onExitClick()" class="ft-size26 pointer dark-text absolute right15 top0"><i class="fa fa-times" aria-hidden="true"></i></a>
			</div>
		</div>

		<div *ngIf="!contentBrowserService.isPopup && contentBrowserService.isCreate24()" class="row mgn-top15 mgn-bottom15">
			<div class="col-xs-4 col-md-2">
				<div class="row">
					<div class="col-xs-12 col-lg-9">
						<button (click)="location.back()" class="white-bg">
							<i class="fas fa-chevron-left mgn-right12"></i>
							Back
						</button>
					</div>
				</div>
			</div>

			<div class="col-xs-6 col-xs-offset-2 col-md-offset-4 text-right">
				<i class="mgn-right12 ft-size28 fas fa-cut {{ appStateService.product.color }}-text"></i>
				<h1 class="in-block">
					<span class="semi-bold">Create24</span>
					|
					<em>{{ appStateService.product.ProductName }}</em>
				</h1>
			</div>
		</div>

		<!--*********************
        DIVIDER
        **********************-->
		<div *ngIf="appStateService.activeFeatureArea !== featureArea.CONTENT_MANAGER" class="red-gradient ht2 mgn-bottom45 border-radius"></div>

		<div class="row">
			<!--*********************
                LIBRARY TREE
            **********************-->
			<div class="col-md-3 col-lg-2">
				<portal-lib-tree></portal-lib-tree>

				<div *ngIf="contentBrowserService.playlistInProgress(activeUrl)" class="relative top24 mgn-leftneg6">
					<p class="center mgn-bottom8 red-text">
						<em>You're working on a playlist!</em>
					</p>
					<button (click)="onReturnToPlaylistBuilderClick()" class="{{ appStateService.product.color }}-bg white-text">
						<i class="fa fa-angle-left mgn-right8" aria-hidden="true"></i>
						Return to Playlist Builder
					</button>
				</div>
			</div>

			<div class="{{ create24Service.c24ActiveArea === c24ActiveArea.EDIT_PROJECT ? 'col-md-9 col-lg-10 pd-right30' : 'col-md-6 col-lg-8' }}">
				<cb-content-list-header></cb-content-list-header>

				<!--************************
                    CONTENT LIST
                *************************-->
				<div class="row mgn-top15">
					<div *ngIf="cvStateService.startWithBlankCanvas()" class="col-xs-3 pd-left0 mgn-bottom15 mgn-top15">
						<button class="white-bg" (click)="onBlankCanvasClick()">
							<i class="far fa-file mgn-right10"></i>
							Start with blank canvas
						</button>
					</div>
					<portal-content-container></portal-content-container>
				</div>
			</div>

			<!--*******************
                FILE UPLOADER
            ********************-->
			<div *ngIf="!contentBrowserService.hideUploader()" class="col-md-3 col-lg-2">
				<div class="file-uploader-wrapper border-radius dark-bg pd15">
					<portal-file-upload-drop-target
						[activeUploadSequence]="appStateService.product.Route === 'hold' ? 'uploadFileHoldSequence' : 'uploadFileVideoSequence'"
						[borderColor]="'gray-border-darker'"
						[dropTargetHeight]="'ht157'"
						[fontSize]="'ft-size18'"
						[maxAllowedFileSize]="2000000000"
						[paddingTop]="'pd-top20'"
						[restrictToType]="contentBrowserService.uploadFileType()"
						[textColor]="'gray-text-dark'"
					></portal-file-upload-drop-target>
					<div class="white-text ft-size10 mgn-top10">
						<span class="mgn-bottom0 mgn-top10">Supported Formats</span>
						<br />

						<ng-container [ngSwitch]="appStateService.product.Route">
							<ng-container *ngSwitchCase="'hold'">
								<span class="mgn-bottom0 mgn-top0">Sound Files: WAV, MP3</span>
								<br />
							</ng-container>
							<ng-container *ngSwitchDefault>
								<ng-container *ngIf="contentBrowserService.uploadFileType() === 'image'">
									<span class="mgn-bottom0 mgn-top0">Still Images: BMP, GIF, JPEG, JPG, PNG</span>
									<br />
								</ng-container>
								<ng-container *ngIf="contentBrowserService.uploadFileType() === 'video'">
									<span class="mgn-bottom0 mgn-top0">Video Clips: MPEG, MPG, MP4, FLV, AVI, MOV, M4V, WMV</span>
									<br />
								</ng-container>
								<ng-container *ngIf="!contentBrowserService.uploadFileType()">
									<span class="mgn-bottom0 mgn-top0">Still Images: BMP, GIF, JPEG, JPG, PNG, PDF</span>
									<br />
									<span class="mgn-bottom0 mgn-top0">Video Clips: MPEG, MPG, MP4, FLV, AVI, MOV, M4V, WMV</span>
									<br />
								</ng-container>
							</ng-container>
						</ng-container>
						<span class="mgn-bottom0 mgn-top0">Max File Size: 2GB</span>
					</div>

					<div
						*ngIf="appStateService.product?.Id === 1 && browserDetectorService.isRecord24SupportedBrowser() && appStateService.currentClient.AllowRecord24"
						class="center white-text mgn-top30"
					>
						<h3 class="mgn-bottom10"><em>Feeling inspired?</em></h3>
						<span>
							Record a message with
							<br />
							Record24!
						</span>
						<br />
						<button class="green-bg white-text border-radius setWidth100 mgn-top15 ft-size16" (mouseup)="onRecord24Click()">
							<i class="fa fa-microphone ft-size16 mgn-right10" aria-hidden="true"></i>
							Record24
						</button>
					</div>

					<div *ngIf="appStateService.product?.Id === 1 && !browserDetectorService.isRecord24SupportedBrowser()" class="center white-text mgn-top30">
						<span>
							<i class="fa fa-microphone ft-size16 mgn-right10" aria-hidden="true"></i>
							<em>Record24 works in Chrome, Firefox, Safari, and Microsoft Edge.</em>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="browserDetectorService.isMobileDevice()" class="container-fluid">
	<div class="row mgn-top30">
		<div class="col-xs-12 text-center">
			<h1><em>Please visit the site from your desktop computer to access this page.</em></h1>
			<button class="green-bg border-radius white-text ft-size20 pd12 mgn-top30" [routerLink]="['/create-24/dashboard']">
				<i class="fa fas fa-chevron-left mgn-right10" aria-hidden="true"></i>
				Return to My Products
			</button>
		</div>
	</div>
</div>
<div *ngIf="!browserDetectorService.isMobileDevice()" class="hidden-lg">
	<div class="row mgn-top30">
		<div class="col-xs-12 text-center">
			<h1><em>Please increase your browser window to access this page.</em></h1>
			<button class="green-bg setWidth50 border-radius white-text ft-size20 pd12 mgn-top30" [routerLink]="['/create-24/dashboard']">
				<i class="fa fas fa-chevron-left mgn-right10" aria-hidden="true"></i>
				Return to My Products
			</button>
		</div>
	</div>
</div>
