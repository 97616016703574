<div (mouseover)="onMouseover()" (mouseleave)="onMouseleave()" class="relative">
	<h4
		*ngIf="library.state !== 'edit'"
		class="{{ libraryService.bgColor(library, isFavoriteLibrary) }} shadow ellipsis white-text pd8 mgn-top10 mgn-bottom10 border-radius pointer"
		(click)="onLibraryClick()"
	>
		{{ library.LibraryName }}
	</h4>

	<div *ngIf="libraryService.showHoverButtons(mouseover) && library.state === 'read'" class="absolute top4 right5">
		<loc-hover-buttons
			[bgColor]="'gray-bg'"
			[deletable]="false"
			[isLibrary]="true"
			[itemName]="library.LibraryName"
			(createCategoryClickNotify)="onCreateCategoryClick()"
			(nameEditNotify)="library.state = 'edit'"
		></loc-hover-buttons>
	</div>
</div>

<!--NEW CATEGORY-->
<ng-container *ngIf="library.state === 'new'">
	<input
		style="width: 85%"
		type="text"
		[(ngModel)]="newCategory.CategoryName"
		#libraryInput
		placeholder="New category..."
		class="white-bg ht28 ft-size12"
		(keyup.enter)="onSaveNewCategoryClick()"
	/>
	<div style="width: 15%" class="relative in-block">
		<button class="limeGreen-bg border-radius ht25 white-text pd0 pd-left4 pd-right4 ft-size12" (click)="onSaveNewCategoryClick()">
			<i class="fas fa-check"></i>
		</button>
		<a class="absolute bottom11 rightneg14 pd2 dark-text border-radius" (click)="library.state = 'read'">
			<i class="fa fa-times ft-size11" aria-hidden="true"></i>
		</a>
	</div>
</ng-container>

<!--EDIT LIBRARY NAME-->
<ng-container *ngIf="library.state === 'edit'">
	<input style="width: 85%" type="text" [(ngModel)]="library.LibraryName" #libraryInput class="white-bg ht28 ft-size12" (keyup.enter)="onSaveNameClick()" />
	<div style="width: 15%" class="relative in-block">
		<button class="limeGreen-bg border-radius ht25 white-text pd0 pd-left4 pd-right4 ft-size12" (click)="onSaveNameClick()">
			<i class="fas fa-check"></i>
		</button>
		<a class="absolute bottom11 rightneg14 pd2 dark-text border-radius" (click)="library.state = 'read'">
			<i class="fa fa-times ft-size11" aria-hidden="true"></i>
		</a>
	</div>
</ng-container>

<ng-container *ngIf="!!library?.categories && library.isExpanded && cvStateService.viewState.treeSelection === 'favorites'">
	<l-cat
		*ngFor="let category of library.categories | favoritesFilterPipe"
		[category]="category"
		[library]="library"
		[subCategories]="category.InverseParentCategory"
	></l-cat>
</ng-container>

<ng-container *ngIf="!!library?.categories && library.isExpanded && cvStateService.viewState.treeSelection !== 'favorites'">
	<ng-container *ngFor="let category of library.categories | categoriesFilterPipe">
		<l-cat *ngIf="!category.IsDeleted" [category]="category" [library]="library" [subCategories]="category.InverseParentCategory"></l-cat>
	</ng-container>
</ng-container>
