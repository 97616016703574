<core-transition-overlay *ngIf="transitionOverlayVisible" [message]="transitionOverlayMessage"></core-transition-overlay>
<div *ngIf="savingPreloaderVisible" style="left: 52%; z-index: 102" class="ht40 wdth182 border-radius shadow white-bg fixed top16 animated {{ animateClass }}">
	<core-saving-preloader [savingBubblesVisible]="savingBubblesVisible" [savingText]="savingText"></core-saving-preloader>
</div>

<div *ngIf="sessionExpireService.expireModalVisible || appErrorService.showAppError" class="absolute left0 right0">
	<div class="modal-overlay"></div>
	<div class="row">
		<div
			*ngIf="sessionExpireService.expireModalVisible"
			style="top: 100px; margin-bottom: 60px; z-index: 200"
			class="col-lg-6 col-md-8 col-sm-offset-3 col-xs-12 min-height600 margin mgn-bottom30 absolute left0 right0 slideUp"
		>
			<core-session-expire-modal></core-session-expire-modal>
		</div>
		<div
			*ngIf="appErrorService.showAppError"
			style="top: 100px; margin: auto; z-index: 200"
			class="col-xs-10 col-md-8 col-lg-5 col-sm-offset-2 absolute left0 right0 setZ animated slideUp"
		>
			<core-app-error></core-app-error>
		</div>
	</div>
</div>

<router-outlet></router-outlet>
<core-footer [isDevMode]="appStateService.isDevMode"></core-footer>
