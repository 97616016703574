import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError, of } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';

import { AppErrorService } from 'src/app/core/app-error/app-error.service';
import { Events } from './events';
import { MessageService } from './message.service';
import { SessionExpireService } from './session-expire.service';
import { UtilitiesService } from './utilities.service';

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
	constructor(
		private appErrorService: AppErrorService,
		private messageService: MessageService,
		private router: Router,
		private sessionExpireService: SessionExpireService,
		private utilService: UtilitiesService
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let request;
		//don't set auth headers for initial call to sign in or for calls to get string as blob
		if (req.url.indexOf('/login') < 1) {
			request = req.clone({
				setHeaders: {
					Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
				}
			});
		} else {
			request = req;
		}
		return next.handle(request).pipe(
			retry(1),
			tap((res: HttpResponse<any>) => {
				//reset token on every call so app session doesn't expire if user is active
				if (res.headers) {
					let token = res.headers.get('w24-token');
					if (token) {
						//token coming back null for GET C24 layer.previewUrl (image to blob)
						sessionStorage.setItem('authToken', token);
					}
					this.sessionExpireService.resetTimer();
				}
			}),
			catchError((err: HttpErrorResponse) => {
				//httpClient throws error if response body is empty, this is a fix
				if (err.status >= 200 && err.status < 300) {
					const res = new HttpResponse({
						body: [],
						headers: err.headers,
						status: err.status,
						statusText: err.statusText,
						url: err.url
					});
					return of(res);
				}
				//server error
				if (err instanceof HttpErrorResponse) {
					if (!navigator.onLine) {
						window.alert('No internet connection available.');
					} else {
						console.error(err);
						let errorPayload;
						this.appErrorService.error = err;

						switch (true) {
							case this.expiredToken(err):
								this.router.navigate(['/login/session-expired']);
								sessionStorage.removeItem('authToken');
								sessionStorage.removeItem('validPin');
								break;
							case this.supressError(err):
								return throwError(err);
							case err.status === 404 && !this.utilService.includes(err.url, 'Thumbnail'):
								if (!this.utilService.includes(err.url, 'Preview')) {
									this.appErrorService.showAppError = true;
									errorPayload = [err, `Our apologies, it looks like we have a bad URL.`];
									this.messageService.publish(Events.appError, errorPayload);
								}
								break;
							default:
								this.appErrorService.showAppError = true;
								console.error(err.url);
								errorPayload = [err, `This one's on us. We're working on it!`];
								this.messageService.publish(Events.appError, errorPayload);
						}
					}
					//client error
				} else {
					console.error(err);
				}
				return throwError(err.error);
			})
		);
	}

	//How to supress errors from specific calls
	//https://stackoverflow.com/questions/49740933/angular-5-httpinterceptor-error-handling-with-calling-the-error-handling-of-the
	private supressError(err: HttpErrorResponse): boolean {
		return (
			err.url.includes('Thumbnail') ||
			err.url.includes('C24Showcase') ||
			err.url.includes('CoreUsers') ||
			err.status === 401 ||
			err.url.includes('CsrClientNewsletterSchedule')
		);
	}

	private expiredToken(error: HttpErrorResponse): boolean {
		if (typeof error.error !== 'object') {
			return error.error.indexOf('Unauthorized') !== -1;
		}
		return false;
	}
}
