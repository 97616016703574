import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'core-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	@Input() isDevMode: boolean;
	public fullYear = new Date().getFullYear();

	constructor() {}

	ngOnInit() {}
}
