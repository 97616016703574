import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CopyTagService {

    constructor() { }

    public onTagClick(tag: string): void {
        //Copies to clipboard
        let selBox = document.createElement('textarea');

        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = tag;

        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();

        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}
