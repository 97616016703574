import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppStateService, EncryptService } from 'src/app/core/services';
import { CreateEditRequestService } from '../_services/create-edit-request.service';
import { environment } from '../../../../environments/environment';
import { ContentRequestStateName } from 'src/app/shared/api-models/admin';

@Injectable({
	providedIn: 'root'
})
export class EmailService {
	constructor(
		private appStateService: AppStateService,
		private createEditRequestService: CreateEditRequestService,
		private encryptService: EncryptService,
		private httpClient: HttpClient
	) {}

	public addVideoClipEmail(): any {
		const request = this.createEditRequestService.request;
		return {
			from: `videorequestupdate@works24.com`,
			recipient: request.producerEmail,
			subject: `Video Clip Added - ${request.clientName}`,
			body:
				`A new clip has been added: \n \n Click the link below to view the request... \n` +
				`${environment.apiUrl}system-tools/content-requests/${this.encryptService.encryptData(JSON.stringify(request.Id))}`
		};
	}

	public revisionRequestEmail(): any {
		const request = this.createEditRequestService.request;
		return {
			from: `revisionrequest@works24.com`,
			recipient: request.csrEmail,
			cc: !this.appStateService.isDevMode ? 'holdcontentrequests@works24.com' : '',
			subject: `Script Revisions Requested - ${request.clientName}`,
			body:
				'Revision Request (request has been set back to "Awaiting Script Edits") : \n \n' +
				`Request Title: ${request.Title} \n \n` +
				'Click the link below to view the request... \n' +
				`${environment.apiUrl}system-tools/content-requests/${this.encryptService.encryptData(JSON.stringify(request.Id))}`
		};
	}

	public videoRevisionRequestEmail(): any {
		const request = this.createEditRequestService.request;
		return {
			from: `revisionrequest@works24.com`,
			recipient: request.producerEmail,
			subject: `Video Revisions Requested - ${request.clientName}`,
			body:
				'Revision Request (request has been set to "In Revision") : \n \n' +
				`Request Title: ${request.Title} \n \n` +
				'Click the link below to view the request... \n' +
				`${environment.apiUrl}system-tools/content-requests/${this.encryptService.encryptData(JSON.stringify(request.Id))}`
		};
	}

	public requestClientApprovalEmail(): any {
		const request = this.createEditRequestService.request;
		return {
			from: `${request.csrEmail}`,
			recipient: request.requestApprovalFromEmail,
			subject: 'Your Works24 content request scripts are ready for approval!',
			body: `Click below to view and approve your latest content request... \n ${environment.apiUrl}content-requests/${this.encryptService.encryptData(
				JSON.stringify(request.Id)
			)}/pending-script-approval`
		};
	}

	public requestPendingCsrApprovalEmail(): any {
		const request = this.createEditRequestService.request;
		return {
			from: `requestpendingcsrapproval@works24.com`,
			recipient: request.csrEmail,
			subject: `Content Request Pending CSR Approval - ${request.clientName}`,
			body:
				`Request: "${request.Title}" ` +
				'has just been changed to "Pending CSR Approval". \n \n' +
				'Click the link below to view the request... \n' +
				`${environment.apiUrl}system-tools/content-requests/${this.encryptService.encryptData(JSON.stringify(request.Id))}`
		};
	}

	public requestPendingEditorApprovalEmail(): any {
		const request = this.createEditRequestService.request;
		return {
			from: `requestpendingeditorapproval@works24.com`,
			recipient: 'tiffanyw@works24.com',
			subject: `Content Request Pending Editor Approval - ${request.clientName}`,
			body:
				`${request.Title} ` +
				'has just been changed to "Pending Editor Approval". \n \n' +
				'Click the link below to view the request... \n' +
				`${environment.apiUrl}system-tools/content-requests/${this.encryptService.encryptData(JSON.stringify(request.Id))}`
		};
	}

	public messagesCompleteEmail(): any {
		const { request } = this.createEditRequestService;
		const { clips, requestedByEmail, csrEmail, requestedByFriendlyName, csrFriendlyName } = request;
		return {
			recipient: requestedByEmail,
			from: csrEmail,
			subject: 'Your Works24 Messages are complete!',
			body:
				'Hi ' +
				requestedByFriendlyName +
				', \n \n' +
				'The custom messages you requested are now online and ready for you. ' +
				'They are located in your Custom Message Library within the New Content folder. ' +
				'Click the link below to view the completed request. \n \n' +
				'Messages included in this request: \n' +
				`${clips
					.map((clip, i) => {
						return `    ${i + 1}. ${clip.Title}`;
					})
					.join('\n')} \n \n` +
				`${environment.apiUrl}content-requests/` +
				this.encryptService.encryptData(JSON.stringify(request.Id)) +
				'\n \n' +
				"Don't forget, you can go to the Content Manager and create new folders " +
				'and organize the messages within your Custom Message Library. \n ' +
				'Please let me know if you need anything else! \n \n' +
				'Thanks, \n' +
				`${csrFriendlyName}`
		};
	}

	public sendNewCommentEmail(): void {
		const request = this.createEditRequestService.request;

		if (request.RequestType !== 'On-Hold Message' && this.appStateService.currentUser.IsEmployee) {
			//Adding this *if* here so CSR won't get duplicate email.
			if (!this.currentUserIsRequestCsr()) {
				this.sendEmail(this.newCommentEmail(request.csrEmail, this.urlEmailBodyLinkForEmployee())).subscribe();
			}
			this.sendEmail(this.newCommentEmail(request.producerEmail, this.urlEmailBodyLinkForEmployee())).subscribe();
		}

		//If client comments when ticket status is "Pending Script Approval"
		if (!this.appStateService.currentUser.IsEmployee && request.StateName === ContentRequestStateName.PENDING_SCRIPT_APPROVAL) {
			this.sendEmail(this.newCommentEmail('holdcontentrequests@works24.com', this.urlEmailBodyLinkForEmployee())).subscribe();
			this.sendEmail(this.newCommentEmail(request.csrEmail, this.urlEmailBodyLinkForEmployee())).subscribe();
			return;
		}
		if ((this.currentUserIsRequestCsr() || this.currentUserIsEmployeeScriptWriter()) && request.StateName === ContentRequestStateName.PENDING_SCRIPT_APPROVAL) {
			this.sendEmail(this.newCommentEmail(request.csrEmail, this.urlEmailBodyLinkForEmployee())).subscribe();
			this.sendEmail(this.newCommentEmail(request.requestApprovalFromEmail, this.urlEmailBodyLinkForClient())).subscribe();
			return;
		}

		if (!this.appStateService.currentUser.IsEmployee && request.StateName === ContentRequestStateName.IN_PRODUCTION) {
			this.sendEmail(this.newCommentEmail(request.producerEmail, this.urlEmailBodyLinkForEmployee())).subscribe();
			this.sendEmail(this.newCommentEmail(request.csrEmail, this.urlEmailBodyLinkForEmployee())).subscribe();

			if (request.RequestType === 'On-Hold Message') {
				this.sendEmail(this.newCommentEmail('tharkin@works24.com', this.urlEmailBodyLinkForEmployee())).subscribe();
			}
			return;
		}
	}

	private currentUserIsRequestCsr(): boolean {
		return this.createEditRequestService.request.CsrId === this.appStateService.currentUser.UserId;
	}

	//As of August/2021, script writer is Tiffany Wilken, ID 88
	private currentUserIsEmployeeScriptWriter(): boolean {
		return this.appStateService.currentUser.UserId === 88;
	}

	private newCommentEmail(recipient: string, urlLinkForBody: string): any {
		const request = this.createEditRequestService.request;
		return {
			from: `newcontentrequestcomment@works24.com`,
			recipient: recipient,
			subject: `New Comment Added - ${request.clientName}`,
			body: `A new comment has been added: \n \n Click the link below to view the request... \n ${urlLinkForBody}`
		};
	}

	private urlEmailBodyLinkForClient(): string {
		const request = this.createEditRequestService.request;
		return `${environment.apiUrl}content-requests/${this.encryptService.encryptData(JSON.stringify(request.Id))}`;
	}

	private urlEmailBodyLinkForEmployee(): string {
		const request = this.createEditRequestService.request;
		return `${environment.apiUrl}system-tools/content-requests/${this.encryptService.encryptData(JSON.stringify(request.Id))}`;
	}

	public newRequestEmail(newRequestId: number): any {
		const request = this.createEditRequestService.request;

		return {
			from: `newcontentrequest@works24.com`,
			recipient: request.csrEmail,
			cc: this.newRequestCC(),
			subject: `New ${request.RequestType} Content Request`,
			body:
				`${this.appStateService.currentUser.FriendlyName} of ${request.clientName} has requested new content. \n \n` +
				`Request Title: ${request.Title} \n \n` +
				`Click below to edit the new content request... \n \n` +
				`${environment.apiUrl}system-tools/content-requests/${this.encryptService.encryptData(JSON.stringify(newRequestId))}`
		};
	}

	private newRequestCC(): string {
		if (this.createEditRequestService.request.RequestType !== 'On-Hold Message') {
			return 'videocontentrequests@works24.com';
		}
		return !this.createEditRequestService.request.bypassScriptWriter ? 'holdcontentrequests@works24.com' : null;
	}

	public sendEmail(params: any): Observable<Object> {
		const obj = {
			To: params.recipient,
			Cc: params.cc,
			From: params.from,
			Subject: params.subject,
			Body: params.body
		};
		//send email
		return this.httpClient.post(environment.adminUrl + 'Email', obj);
	}
}
