<div class="row blue-gradient ht2 mgn-top30 border-radius mgn-left0 mgn-right0"></div>

<form (keydown.enter)="$event.preventDefault()" #form="ngForm" novalidate (ngSubmit)="saveService.onSaveClick()">
	<div class="row mgn-top30 mgn-bottom40">
		<div class="col-sm-10 col-sm-offset-2">
			<div class="row mgn-bottom30">
				<div class="col-sm-2">
					<h2 class="semi-bold">General</h2>
				</div>
				<div class="col-sm-2">
					<h3 class="semi-bold in-block">Client ID:</h3>
					<span class="ft-size18">{{ appStateService.currentClient.Id }}</span>
				</div>
				<div class="col-sm-2">
					<h3 class="semi-bold in-block">Location ID:</h3>
					<span class="ft-size18">{{ createEditPlayerService.player.LocationId }}</span>
				</div>
				<div class="col-sm-2 text-right">
					<h3 class="semi-bold in-block">Player ID:</h3>
					<span class="ft-size18">{{ createEditPlayerService.player.Id }}</span>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<label class="semi-bold">Name:</label>
				</div>
				<div class="col-sm-6">
					<input
						style="border: 1px solid #d6d9db"
						(keyup)="viewService.onFormChange()"
						[ngClass]="{ 'red-border-light': !playerName.valid && !playerName.pristine }"
						name="playerName"
						required
						#playerName="ngModel"
						[(ngModel)]="createEditPlayerService.player.PlayerName"
						type="text"
					/>
					<small class="red-text-light" [hidden]="playerName.valid || (playerName.pristine && !form.submitted)">Player name is required.</small>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<label class="semi-bold">Model:</label>
				</div>
				<div class="col-sm-6">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[isDisabled]="createEditPlayerService.viewState === 'edit'"
						[key]="'Name'"
						[selectedOption]="createEditPlayerService.player.PlayerModel?.Name"
						[options]="playerOptionsService.holdPlayerModels"
						(onOptionSelect)="viewService.onPlayerModelSelect($event)"
					></portal-dropdown-menu>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Install State:</span>
				</div>
				<div class="col-sm-6 relative">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[isDisabled]="viewService.installStateDisabled"
						[height]="'ht40'"
						[key]="'Name'"
						[options]="playerOptionsService.installStates"
						[scrollbarHidden]="true"
						[selectedOption]="createEditPlayerService.player.InstallState?.Name"
						(onOptionSelect)="viewService.onInstallStateSelect($event)"
					></portal-dropdown-menu>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Repair State:</span>
				</div>
				<div class="col-sm-6 relative">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[key]="'Name'"
						[options]="playerOptionsService.repairStates"
						[scrollbarHidden]="true"
						[selectedOption]="createEditPlayerService.player.RepairState?.Name"
						(onOptionSelect)="viewService.onRepairStateSelect($event)"
					></portal-dropdown-menu>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Location:</span>
				</div>
				<div class="col-sm-6 relative">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[key]="'Name'"
						[options]="playerOptionsService.locations"
						[selectedOption]="createEditPlayerService.player.Location?.Name"
						(onOptionSelect)="viewService.onLocationSelect($event)"
					></portal-dropdown-menu>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<label for="pause" class="semi-bold">Pause:</label>
				</div>
				<div class="col-sm-6">
					<input
						style="border: 1px solid #d6d9db"
						id="pause"
						(keyup)="viewService.onFormChange()"
						[ngClass]="{ 'red-border-light': !pause.valid && !pause.pristine }"
						name="pause"
						required
						#pause="ngModel"
						class="setWidth100 ht40 gray-bg-dark"
						[(ngModel)]="createEditPlayerService.player.HoldPause"
						type="text"
					/>
					<small class="red-text-light" [hidden]="pause.valid || (pause.pristine && !form.submitted)">Pause is required.</small>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
				</div>
			</div>

			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<label for="volume" class="semi-bold">Volume:</label>
				</div>
				<div class="col-sm-6">
					<input
						style="border: 1px solid #d6d9db"
						id="volume"
						(keyup)="viewService.onFormChange()"
						[ngClass]="{ 'red-border-light': !volume.valid && !volume.pristine }"
						name="volume"
						required
						#volume="ngModel"
						class="setWidth100 ht40 gray-bg-dark"
						[(ngModel)]="createEditPlayerService.player.HoldVolume"
						type="text"
					/>
					<small class="red-text-light" [hidden]="volume.valid || (volume.pristine && !form.submitted)">Volume is required.</small>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
				</div>
			</div>

			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<label class="semi-bold">Install Date:</label>
				</div>
				<div class="col-sm-6">
					<portal-date-time-selector
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[dateModel]="createEditPlayerService.player.InstallDate"
						(dateChanged)="viewService.onDateChange($event)"
					></portal-date-time-selector>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Player Notes:</span>
				</div>
				<div class="col-sm-6">
					<textarea
						style="border: 1px solid #d6d9db"
						(keyup)="viewService.onFormChange()"
						class="setWidth100 gray-bg-dark"
						rows="4"
						name="playerNotes"
						[(ngModel)]="createEditPlayerService.player.PlayerNotes"
						type="text"
					></textarea>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold pointer" (click)="viewService.onCheckboxClick('IsExemptFromReminders')">Exempt From Reminders:</span>
				</div>
				<div class="col-sm-6">
					<portal-checkbox
						[backgroundColor]="'white-bg'"
						[type]="'checkbox'"
						[isChecked]="createEditPlayerService.player.IsExemptFromReminders"
						(checkboxClickNotify)="viewService.onCheckboxClick('IsExemptFromReminders')"
					></portal-checkbox>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Hostname:</span>
				</div>
				<div class="col-sm-6">
					<input
						disabled="{{ viewService.hostnameDisabled }}"
						class="setWidth100 ht40 gray-bg-dark ln-height40"
						(keyup)="viewService.onFormChange()"
						name="hostname"
						#pollingIntervalSec="ngModel"
						[(ngModel)]="createEditPlayerService.player.Hostname"
						type="text"
					/>
				</div>
				<div *ngIf="viewService.hostnameDisabled" class="col-sm-2">
					<button class="red-bg-light white-text" (click)="viewService.onUnlockHostnameClick()">
						<i class="fa fa-exclamation-triangle mgn-right8" aria-hidden="true"></i>
						Unlock Hostname
					</button>
				</div>
			</div>
			<div *ngIf="createEditPlayerService.player.InstallState?.Name !== 'Installed'" class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Activation Code:</span>
				</div>
				<div class="col-sm-6">
					<input
						class="setWidth100 ht40 gray-bg-dark ln-height40"
						maxlength="5"
						oninput="this.value = this.value.toUpperCase()"
						(keyup)="viewService.onFormChange()"
						name="activationCode"
						[(ngModel)]="createEditPlayerService.player.ActivationCode"
						type="text"
					/>
				</div>
			</div>
			<ng-container *ngIf="createEditPlayerService.player.PlayerModelId === 1000006 || createEditPlayerService.player.PlayerModelId === 1000009">
				<h2 class="semi-bold mgn-bottom30 mgn-top60">Technical Settings</h2>
				<!--SETTINGS JSON-->
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Audio Device Name:</span>
					</div>
					<div class="col-sm-6">
						<span>{{ createEditPlayerService.settingsJson.AudioDeviceName }}</span>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Unit ID:</span>
					</div>
					<div class="col-sm-6">
						<span>{{ createEditPlayerService.settingsJson.UnitIdentifier }}</span>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Polling Interval (seconds):</span>
					</div>
					<div class="col-sm-6">
						<input
							class="setWidth100 ht40 gray-bg-dark ln-height40"
							(keyup)="viewService.onFormChange()"
							name="pollingIntervalSec"
							#pollingIntervalSec="ngModel"
							[(ngModel)]="createEditPlayerService.settingsJson.PollingIntervalSec"
							type="number"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Target Version:</span>
					</div>
					<div class="col-sm-6">
						<input
							class="setWidth100 ht40 gray-bg-dark ln-height40"
							(keyup)="viewService.onFormChange()"
							name="targetVersion"
							#targetVersion="ngModel"
							[(ngModel)]="createEditPlayerService.player.playerStatus.TargetVersion"
							type="text"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Reported Version:</span>
					</div>
					<div class="col-sm-6">
						<span>{{ createEditPlayerService.player.playerStatus.ReportedVersion }}</span>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold pointer" (click)="viewService.onCheckboxClick('ForceBomgarReinstall')">Force Remote Access Reinstall:</span>
					</div>
					<div class="col-sm-6">
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[type]="'checkbox'"
							[isChecked]="createEditPlayerService.player.playerStatus.ForceBomgarReinstall"
							(checkboxClickNotify)="viewService.onCheckboxClick('ForceBomgarReinstall')"
						></portal-checkbox>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="createEditPlayerService.showIpConfigOptions()">
				<h2 class="semi-bold mgn-bottom30 mgn-top60">IP Configuration</h2>
				<div *ngIf="viewService.ipConfigDisabled" class="row mgn-top20">
					<div class="col-sm-2">
						<button class="red-bg-light white-text border-radius setWidth100" (click)="viewService.onUnlockIpConfigClick()">
							<i class="fa fa-exclamation-triangle mgn-right8" aria-hidden="true"></i>
							Unlock IP Config
						</button>
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold pointer" (mouseup)="createEditPlayerService.settingsJson.UseDhcp = !createEditPlayerService.settingsJson.UseDhcp">
							Use Dhcp:
						</span>
					</div>
					<div class="col-sm-6">
						<em class="absolute topneg30">NOTE: For reference only. Settings below have no effect on unit.</em>
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[type]="'checkbox'"
							[isChecked]="createEditPlayerService.settingsJson.UseDhcp"
							[isDisabled]="viewService.ipConfigDisabled"
							(checkboxClickNotify)="createEditPlayerService.settingsJson.UseDhcp = !createEditPlayerService.settingsJson.UseDhcp"
						></portal-checkbox>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">IpAddress:</span>
					</div>
					<div class="col-sm-6">
						<input
							name="ipAddress"
							[disabled]="viewService.ipConfigDisabled"
							#ipAddress="ngModel"
							(keyup)="viewService.onFormChange()"
							[(ngModel)]="createEditPlayerService.settingsJson.IpAddress"
							type="text"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Net Mask:</span>
					</div>
					<div class="col-sm-6">
						<input
							name="netMask"
							[disabled]="viewService.ipConfigDisabled"
							#netMask="ngModel"
							(keyup)="viewService.onFormChange()"
							[(ngModel)]="createEditPlayerService.settingsJson.NetMask"
							type="text"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Default Gateway:</span>
					</div>
					<div class="col-sm-6">
						<input
							name="defaultGateway"
							[disabled]="viewService.ipConfigDisabled"
							#defaultGateway="ngModel"
							(keyup)="viewService.onFormChange()"
							[(ngModel)]="createEditPlayerService.settingsJson.DefaultGateway"
							type="text"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Primary DNS:</span>
					</div>
					<div class="col-sm-6">
						<input
							name="primaryDns"
							[disabled]="viewService.ipConfigDisabled"
							#primaryDns="ngModel"
							(keyup)="viewService.onFormChange()"
							[(ngModel)]="createEditPlayerService.settingsJson.PrimaryDns"
							type="text"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Secondary DNS:</span>
					</div>
					<div class="col-sm-6">
						<input
							name="secondaryDns"
							[disabled]="viewService.ipConfigDisabled"
							(keyup)="viewService.onFormChange()"
							#secondaryDns="ngModel"
							[(ngModel)]="createEditPlayerService.settingsJson.SecondaryDns"
							type="text"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">HTTP Proxy URL:</span>
					</div>
					<div class="col-sm-6">
						<input
							name="httpProxyUrl"
							[disabled]="viewService.ipConfigDisabled"
							(keyup)="viewService.onFormChange()"
							#httpProxyUrl="ngModel"
							[(ngModel)]="createEditPlayerService.settingsJson.HttpProxyUrl"
							type="text"
						/>
					</div>
				</div>
			</ng-container>
		</div>
	</div>

	<!-- MODEL TYPE 1 DIALER OPTIONS -->
	<div *ngIf="createEditPlayerService.player.PlayerModel.PlayerModelTypeId === 1" class="row mgn-top30">
		<div class="col-sm-10 col-sm-offset-2">
			<h2 class="semi-bold mgn-bottom30">Dialer Options</h2>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<label for="number" class="semi-bold">Long Distance Number:</label>
				</div>
				<div class="col-sm-3">
					<input
						style="border: 1px solid #d6d9db"
						(change)="viewService.onFormChange()"
						type="checkbox"
						id="number"
						name="isLongDistance"
						[(ngModel)]="createEditPlayerService.player.IsLongDistance"
					/>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<label for="pause" class="semi-bold">Phone:</label>
				</div>
				<div class="col-sm-1 relative">
					<input
						style="border: 1px solid #d6d9db"
						(keyup)="viewService.onFormChange()"
						class="setWidth100 ht40 gray-bg-dark"
						[ngClass]="{ 'red-border-light': !areaCode.valid && !areaCode.pristine }"
						name="areaCode"
						required
						#areaCode="ngModel"
						[(ngModel)]="createEditPlayerService.player.RemoteAreaCode"
						type="text"
					/>
					<small class="red-text-light" [hidden]="areaCode.valid || (areaCode.pristine && !form.submitted)">Area code is required.</small>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
				</div>
				<div class="col-sm-2 relative">
					<input
						style="border: 1px solid #d6d9db"
						(keyup)="viewService.onFormChange()"
						class="setWidth100 ht40 gray-bg-dark"
						maxlength="7"
						[ngClass]="{ 'red-border-light': !remotePhone.valid && !remotePhone.pristine }"
						name="remotePhone"
						required
						#remotePhone="ngModel"
						[(ngModel)]="createEditPlayerService.player.RemotePhone"
						type="text"
					/>
					<small class="red-text-light" [hidden]="remotePhone.valid || (remotePhone.pristine && !form.submitted)">Phone is required.</small>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
				</div>
			</div>

			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Password:</span>
				</div>
				<div class="col-sm-6 relative">
					<input
						style="border: 1px solid #d6d9db"
						(keyup)="viewService.onFormChange()"
						class="setWidth100 ht40 gray-bg-dark"
						[ngClass]="{ 'red-border-light': !password.valid && !password.pristine }"
						name="password"
						required
						#password="ngModel"
						[(ngModel)]="createEditPlayerService.player.RemotePassword"
						type="text"
					/>
					<small class="red-text-light" [hidden]="password.valid || (password.pristine && !form.submitted)">Password is required.</small>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
				</div>
			</div>
		</div>
	</div>

	<!-- MODEL TYPE 2 VOIP OPTIONS -->
	<div *ngIf="createEditPlayerService.player.playerStatus.PlayerModelTypeId === 2" class="row mgn-top30">
		<div class="col-sm-10 col-sm-offset-2">
			<h2 class="semi-bold mgn-bottom30">VOIP Options</h2>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">VOIP File Format:</span>
				</div>
				<div class="col-sm-6 relative">
					<portal-dropdown-menu
						*ngIf="playerOptionsService.voipFormats"
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[key]="'FriendlyName'"
						[options]="playerOptionsService.voipFormats"
						[selectedOption]="createEditPlayerService.player.VoipFormat?.FriendlyName"
						(onOptionSelect)="viewService.onVoipFormatSelect($event)"
					></portal-dropdown-menu>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>

					<div *ngIf="createEditPlayerService.viewState === 'edit'" style="right: -20.75rem; width: 288px" class="absolute top0 red-text-light">
						<i class="fas fa-caret-left ft-size20 absolute top0 leftneg18"></i>
						Changing this setting does NOT change the format for future playlists. When changing, please check for future playlists and re-save with the new
						VOIP format.
					</div>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<label (click)="viewService.onCheckboxClick('VoipNotify')" class="semi-bold pointer">Send VOIP Emails:</label>
				</div>
				<div class="col-sm-6">
					<portal-checkbox
						[backgroundColor]="'white-bg'"
						[type]="'checkbox'"
						[isChecked]="createEditPlayerService.player.VoipNotify"
						(checkboxClickNotify)="viewService.onCheckboxClick('VoipNotify')"
					></portal-checkbox>
				</div>
			</div>
			<div *ngIf="createEditPlayerService.player.VoipNotify" class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<label class="semi-bold">Email Recipients:</label>
					<i
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						data-tippy-content="Semi-colons are required at the end of each email address except the last."
						[tippyProps]="{ appendTo: 'parent' }"
						class="far fa-question-circle relative topneg5 rightneg5"
					></i>
				</div>
				<div class="col-sm-6">
					<textarea
						rows="4"
						#voipEmail
						(input)="onVoipEmailChange(voipEmail.value)"
						(keyup)="viewService.onFormChange()"
						name="AdditionalVoipEmail"
						[(ngModel)]="createEditPlayerService.player.AdditionalVoipEmail"
					></textarea>
				</div>
			</div>
		</div>
	</div>

	<!-- MODEL TYPE 3 NETWORK OPTIONS -->
	<div *ngIf="showMacField" class="row mgn-top30">
		<div class="col-sm-10 col-sm-offset-2">
			<h2 class="semi-bold mgn-bottom30">Network</h2>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">MAC Address:</span>
				</div>
				<!-- PLAYER EDIT MODE -->
				<div *ngIf="createEditPlayerService.viewState === 'edit'" class="col-sm-6 relative">
					<input
						(keyup)="viewService.onFormChange()"
						[ngClass]="{
							'red-border-light':
								(!macAddress.valid || (!macValidationService.isMacAddressUnique && macValidationService.player?.Id !== createEditPlayerService.player.Id)) &&
								!macAddress.pristine
						}"
						name="macAddress"
						#macAddress="ngModel"
						[ngModel]="createEditPlayerService.player.MacAddress"
						pattern=".{12}"
						maxlength="12"
						(ngModelChange)="macValidationService.onMacAddressChange($event)"
						type="text"
					/>
					<small
						class="red-text-light"
						[hidden]="
							(macAddress.valid && (macValidationService.isMacAddressUnique || macValidationService.player?.Id === createEditPlayerService.player.Id)) ||
							(macAddress.pristine && !form.submitted)
						"
					>
						<span *ngIf="createEditPlayerService.player.MacAddress?.length !== 12">Mac address must be 12 characters in length!</span>
						<span *ngIf="createEditPlayerService.player.MacAddress?.length === 12">Mac address must be unique!</span>
					</small>
					<i *ngIf="showAsterisk" class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
					<div
						*ngIf="macValidationService.player?.Id && macValidationService.player?.Id !== createEditPlayerService.player.Id"
						style="right: -18.75rem"
						class="absolute top0 red-text-light"
					>
						<em>This MAC address belongs to:</em>
						<br />
						<span>
							Player:
							<span class="semi-bold">{{ macValidationService.player?.PlayerName }}</span>
							<br />
							Client:
							<a class="semi-bold red-text-light" (mouseup)="macValidationService.onClientNameClick()">{{ macValidationService.client?.Name }}</a>
						</span>
					</div>
				</div>

				<!-- PLAYER NEW MODE -->
				<div *ngIf="createEditPlayerService.viewState === 'new'" class="col-sm-6 relative">
					<input
						class="setWidth100 ht36 ln-height36"
						[ngClass]="{ 'red-border-light': (!macAddress.valid || !macValidationService.isMacAddressUnique) && !macAddress.pristine }"
						name="macAddress"
						#macAddress="ngModel"
						[ngModel]="createEditPlayerService.player.MacAddress"
						pattern=".{12}"
						maxlength="12"
						(ngModelChange)="macValidationService.onMacAddressChange($event)"
						type="text"
					/>
					<small class="red-text-light" [hidden]="(macAddress.valid && macValidationService.isMacAddressUnique) || (macAddress.pristine && !form.submitted)">
						<span *ngIf="createEditPlayerService.player.MacAddress?.length !== 12">Mac address must be 12 characters in length!</span>
						<span *ngIf="createEditPlayerService.player.MacAddress?.length === 12">Mac address must be unique!</span>
					</small>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
					<div *ngIf="macValidationService.player?.Id" style="right: -18.75rem" class="absolute top0 red-text-light">
						<em>This MAC address belongs to:</em>
						<br />
						<span>
							Player:
							<span class="semi-bold">{{ macValidationService.player?.PlayerName }}</span>
							<br />
							Client:
							<a class="semi-bold red-text-light" (mouseup)="macValidationService.onClientNameClick()">{{ macValidationService.client?.Name }}</a>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row mgn-top50 mgn-bottom50">
		<div class="col-sm-10 col-sm-offset-2 mgn-bottom121">
			<div class="row">
				<div class="col-sm-8">
					<div class="row">
						<div class="col-sm-3 col-sm-offset-6">
							<button class="gray-bg" type="button" (click)="viewService.onCancelClick(location)">Cancel</button>
						</div>
						<div class="col-sm-3">
							<button [disabled]="saveBtnDisabled()" type="submit" class="limeGreen-bg white-text">Save</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
