import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of, BehaviorSubject } from 'rxjs';
import { switchMap, tap, catchError, map } from 'rxjs/operators';

import { ClientNewsletter, Content } from 'src/app/shared/api-models/content';

import { AppStateService, Events, MessageService } from 'src/app/core/services';

import { ContentService } from '../shared/components/content-container/content/_services';
import { environment } from 'src/environments/environment';
import { FeaturedPlaylistsService } from '../shared/components/library-tree/_services';
import { PlaylistsExamples } from 'src/app/shared/api-models/content';
import { PlaylistsExamplesCompletePlaylist } from 'src/app/shared/view-models/content';
import { ContentVM } from '../shared/components/content-container/content/_models/content-view';

type Playlist = { playlist: PlaylistsExamples; content: ContentVM[] };
type ContentIdsParsed = { id: number; contentId: number };

@Injectable({
	providedIn: 'root'
})
export class ClientNewsletterService {
	public newsletter = new BehaviorSubject<ClientNewsletter>(undefined);
	public newsletter$: Observable<ClientNewsletter> = this.newsletter.asObservable();

	constructor(
		private appStateService: AppStateService,
		private contentService: ContentService,
		private featuredPlaylistsService: FeaturedPlaylistsService,
		private httpClient: HttpClient,
		private messageService: MessageService
	) {}

	public getNewsletter(): void {
		this.httpClient
			.get<ClientNewsletter>(`${environment.contentUrl}ClientNewsletter/Latest`)
			.pipe(
				switchMap((newsletter: ClientNewsletter) => {
					return this.getC24ShowcaseContent$(newsletter);
				})
			)
			.subscribe();
	}

	public updateNewsletter(updatedNewsletter: ClientNewsletter): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.httpClient
			.put(`${environment.contentUrl}ClientNewsletter/${updatedNewsletter.Id}`, updatedNewsletter)
			.pipe(switchMap(() => this.getC24ShowcaseContent$(updatedNewsletter)))
			.subscribe(() => {
				this.messageService.publish(Events.savingPreloader, 0);
			});
	}

	public getC24ShowcaseContent$(newsletter: ClientNewsletter): Observable<ContentVM[]> {
		const contentIdsParsed: ContentIdsParsed = JSON.parse(newsletter.C24ShowcaseContentIds);
		const contentIdArr: number[] = [];

		for (const prop in contentIdsParsed) {
			contentIdArr.push(contentIdsParsed[prop].contentId);
		}

		const getArr = contentIdArr.map((id) => {
			if (id) {
				return this.httpClient.get<ContentVM>(`${environment.contentUrl}Content/${id}?C24Showcase`).pipe(catchError((err) => of(null)));
			}
			return of(null);
		});

		return forkJoin(getArr).pipe(
			tap((c24ShowcaseContent: ContentVM[]) => {
				newsletter = { ...newsletter, c24ShowcaseContent, contentIdsParsed };
				this.newsletter.next(newsletter);
				//1042735, 1042734, 1042733
			})
		);
	}

	public getVideoFeaturedPlaylist$(): Observable<Playlist> {
		const clientId = this.appStateService.currentClient.Id;
		return this.httpClient.get<PlaylistsExamplesCompletePlaylist>(`${environment.contentUrl}PlaylistExamples/Featured/${clientId}/Video`).pipe(
			switchMap((res: PlaylistsExamplesCompletePlaylist) => {
				if (res) {
					const playlist: Playlist = { playlist: res.PlaylistExample, content: this.featuredPlaylistsService.mapContent(res) };
					return of(playlist);
				}
				return of(null);
			})
		);
	}

	public getHoldFeaturedPlaylist$(): Observable<Playlist> {
		const clientId = this.appStateService.currentClient.Id;
		return this.httpClient.get<PlaylistsExamplesCompletePlaylist>(`${environment.contentUrl}PlaylistExamples/Featured/${clientId}/Hold`).pipe(
			switchMap((res: PlaylistsExamplesCompletePlaylist) => {
				if (res) {
					const playlist: Playlist = { playlist: res.PlaylistExample, content: this.featuredPlaylistsService.mapContent(res) };
					return of(playlist);
				}
				return of(null);
			})
		);
	}

	public getVideoPlaylists$(): Observable<PlaylistsExamples[]> {
		const contentLibraryIds = '422,1962,1542';
		return this.httpClient.get<PlaylistsExamples[]>(`${environment.contentUrl}PlaylistExamples/list/${contentLibraryIds}`).pipe(
			map((playlists) => {
				return playlists.filter((playlist) => !playlist.IsDeleted);
			})
		);
	}

	public getHoldPlaylists$(): Observable<PlaylistsExamples[]> {
		const contentLibraryIds = '2,1200,40';
		return this.httpClient.get<PlaylistsExamples[]>(`${environment.contentUrl}PlaylistExamples/list/${contentLibraryIds}`).pipe(
			map((playlists) => {
				return playlists.filter((playlist) => !playlist.IsDeleted);
			})
		);
	}

	public patchPlaylist(playlist: PlaylistsExamples, prop: string): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.httpClient.patch(`${environment.contentUrl}PlaylistExamples/${playlist.Id}`, { [prop]: playlist[prop] }).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}

	public getSidebarVideoContentAndContentFile$(contentId: number): Observable<ContentVM> {
		return this.httpClient.get<Content>(`${environment.contentUrl}Content/${contentId}`).pipe(
			switchMap((content: ContentVM) => {
				return this.contentService.getContentFiles$(content);
			})
		);
	}
}
