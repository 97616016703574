import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppStateService, DateTimeService, Events, MessageService } from '../../core/services';
import { ClientHistoryNotes } from '../../shared/api-models/log';
import { environment } from '../../../environments/environment';
import { ViewState } from './view-state';

@Injectable()
export class HistoryNotesService {
	public historyNotes: ClientHistoryNotes[];
	public searchTerm: string = '';
	public totalItems: number;
	public viewState: ViewState = new ViewState('LogDate', true, 1, false, null);

	constructor(
		private appStateService: AppStateService,
		private dateTimeService: DateTimeService,
		private httpClient: HttpClient,
		private messageService: MessageService
	) {}

	public getHistoryNotes(locationId: number, refreshData: boolean): Observable<ClientHistoryNotes[]> {
		if (this.historyNotes && !refreshData) {
			return of(this.historyNotes); //return cached history notes
		}
		return this.httpClient.get<ClientHistoryNotes[]>(`${environment.adminUrl}${this.url(locationId)}`, { observe: 'response' }).pipe(
			map((rawResponse: HttpResponse<ClientHistoryNotes[]>) => {
				const historyNotes = rawResponse.body;
				this.totalItems = parseInt(rawResponse.headers.get('x-result-count'));
				return historyNotes.map((historyNote) => {
					return this.historyNote(historyNote);
				});
			})
		);
	}

	public deleteHistoryNote(row: ClientHistoryNotes): void {
		const confirmDelete: boolean = confirm(`Delete history note ID "${row.Id}"?`);
		if (confirmDelete) {
			this.messageService.publish(Events.savingPreloader, 1);
			this.httpClient.delete(`${environment.adminUrl}ClientHistoryNotes/${row.Id}`).subscribe((historyNote: ClientHistoryNotes) => {
				const index = this.historyNotes.findIndex((item) => item.Id === historyNote.Id);
				this.historyNotes.splice(index, 1);
				this.messageService.publish(Events.savingPreloader, 0);
			});
		}
	}

	public updateHistoryNoteColumn(row: ClientHistoryNotes, value: string, prop: string): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.httpClient
			.patch(`${environment.adminUrl}ClientHistoryNotes/${row.Id}`, {
				[prop]: value
			})
			.subscribe((_) => this.messageService.publish(Events.savingPreloader, 0));
	}

	private historyNote(historyNote: ClientHistoryNotes): ClientHistoryNotes {
		historyNote.LogDate = this.dateTimeService.localOffset(historyNote.LogDate);
		historyNote.deleteBtn = 'Delete';
		historyNote['classList'] = 't-row white-bg';
		return historyNote;
	}

	private url(locationId?: number): string {
		const { currentClient } = this.appStateService;

		if (locationId) {
			if (this.searchTerm.length > 0) {
				return `ClientHistoryNotes/SearchGridText/${this.searchTerm}?pageSize25&pageNumber=${this.viewState.pageNumber}&errorOnly=false&clientId=${currentClient.Id}&serviceLocationId=${locationId}`;
			}
			return `ClientHistoryNotes/ClientHistoryGrid/${currentClient.Id}?orderByCol=${this.viewState.orderByCol}&orderDesc=${this.viewState.orderDesc}&pageNumber=${this.viewState.pageNumber}&pageSize=25&serviceLocationId=${this.viewState.locationId}&includeSystemGenerated=${this.viewState.includeSystemGenerated}`;
		}
		if (this.searchTerm.length > 0) {
			return `ClientHistoryNotes/SearchGridText/${this.searchTerm}?pageSize25&pageNumber=${this.viewState.pageNumber}&errorOnly=false&clientId=${currentClient.Id}`;
		}
		return `ClientHistoryNotes/ClientHistoryGrid/${currentClient.Id}?orderByCol=${this.viewState.orderByCol}&orderDesc=${this.viewState.orderDesc}&pageNumber=${this.viewState.pageNumber}&pageSize=25&includeSystemGenerated=${this.viewState.includeSystemGenerated}`;
	}
}
