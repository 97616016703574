import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-playlist-builder-access-denied',
    templateUrl: './playlist-builder-access-denied.component.html',
    styleUrls: ['./playlist-builder-access-denied.component.scss']
})
export class PlaylistBuilderAccessDeniedComponent implements OnInit {

    public message: string;

    constructor(private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.activatedRoute.url.subscribe((res) => {
            const urlParam: string = res[1].path;
            switch (true) {
                case urlParam === 'no-music-library':
                    this.message = `You've reached the Playlist Builder, but you do not have access to the music library. Please contact your portal administrator or Works24 customer service representative for access to the music library.`
                    break;

                case urlParam === 'no-contract-found':
                    this.message = `You've reached the Playlist Builder, but you do not have any active contracts. Please contact your portal administrator or Works24 customer service representative to activate a contract.`
                    break;
                case urlParam === 'no-libraries-found':
                    this.message = `You've reached the Playlist Builder, but you do not have access to any content libraries. Please contact your portal administrator or Works24 customer service representative for library access.`
            }
        })
    }
}
