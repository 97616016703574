import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppStateService } from 'src/app/core/services';
import { AppErrorService } from './app-error.service';

@Component({
    selector: 'core-app-error',
    templateUrl: './app-error.component.html',
    styleUrls: ['./app-error.component.scss']
})
export class AppErrorComponent implements OnInit {

    public status: number;
    public message: string;
    public userText: string;

    constructor(
            public appErrorService: AppErrorService,
            public appStateService: AppStateService,
            private router: Router
    ) { 

    }

    ngOnInit(): void {
    }

    public onSendReportClick(): void {
        this.appErrorService.sendEmail(this.email());
        location.reload();
    }

    public onIgnoreClick(): void {
        location.reload();
    }

    public onMyProductsClick(): void {
        this.appErrorService.showAppError = false;
        this.router.navigate(['/my-products']);
    }

    private email(): any {
        return {
            To: this.appStateService.csrByClient.Email,
            From: 'portalerror@works24.com',
            Subject: 'Portal Error',
            Body: `USER-ENTERED TEXT: \n \n ${this.userText} \n \n \n Logged-in User: ${this.appStateService.currentUser.UserName} \n \n Client: ${this.appStateService.currentClient.Name} \n \n \n` +
            `Internal Error: ${this.appErrorService.error.error}`
        }
    }
}
