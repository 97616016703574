import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Layer, LayerType } from '../_models';
import { Create24Area } from '../../_models/create-24-state.enum';

@Injectable({
	providedIn: 'root'
})
export class LayersService {
	private activeLayerSelectedSource = new Subject<any | null>();
	private canvasExpandClickSource = new Subject<any>();
	private createLayerClickedSource = new Subject<Layer>();
	private changeCanvasBackgroundSource = new Subject<any>();
	private changeContentClickSource = new Subject<Create24Area>();
	private imageLoadCompleteSource = new Subject<Layer>();
	private layerDeleteSource = new Subject<number>();
	private layerReorderSource = new Subject<Layer[]>();

	public activeLayer: Layer;
	public layerId: number = 0;
	public layers: Layer[] = [];

	public activeLayerSelected$ = this.activeLayerSelectedSource.asObservable();
	public canvasExpandClicked$ = this.canvasExpandClickSource.asObservable();
	public createLayerClicked$ = this.createLayerClickedSource.asObservable();
	public changeContentClicked$ = this.changeContentClickSource.asObservable();
	public imageLoadComplete$ = this.imageLoadCompleteSource.asObservable();
	public layerDelete$ = this.layerDeleteSource.asObservable();
	public layerReorder$ = this.layerReorderSource.asObservable();

	constructor() {}

	public onActiveLayerSelect(payload: any[]): void {
		this.activeLayerSelectedSource.next(payload);
	}

	public onCanvasExpandClick(): void {
		this.canvasExpandClickSource.next();
	}

	public onChangeCanvasBackground(): void {
		this.changeCanvasBackgroundSource.next();
	}

	public onChangeContentClick(create24ActiveArea: Create24Area): void {
		this.changeContentClickSource.next(create24ActiveArea);
	}

	public onImageLoadComplete(layer: Layer): void {
		this.imageLoadCompleteSource.next(layer);
	}

	public onLayerDelete(layerId: number): void {
		this.layerDeleteSource.next(layerId);
	}

	public onLayerReorder(layers: Layer[]): void {
		this.layerReorderSource.next(layers);
	}

	public onCreateLayerClick(layer: Layer): void {
		this.createLayerClickedSource.next(layer);
	}

	//LayersTimelineWrapper and CanvasWrapper components both using this
	public onMouseUp(canvas): void {
		canvas.on('mouse:up', (e) => {
			let multiSelect: boolean = e.target?.group?._objects !== undefined || e.target?._objects !== undefined;

			//If multiple layers selected
			if (multiSelect) {
				let groupedObjects: any[] = e.target?.group?._objects !== undefined ? e.target?.group?._objects : e.target?._objects;
				this.onActiveLayerSelect([null, null, groupedObjects]);
				return;
			}
			if (e.target) {
				let payload: any[] = [e.target.layerId, e.target.component, null];
				this.onActiveLayerSelect(payload);
			} else {
				this.onActiveLayerSelect(null);
			}
		});
	}

	public adjustLayerIdsOnDelete(layer: Layer): void {
		//Keep id's neat for re-ordering
		this.layers.forEach((l) => {
			if (l.id > layer.id) {
				l.id = l.id - 1;
			}
		});
	}

	public atLeastOneFeedLayer(): boolean {
		return this.layers.some((layer) => layer.type === 'Feed Image' || layer.type === 'Feed Text');
	}

	public layerCopy(layer: Layer): Layer {
		let layerCopy: Layer = JSON.parse(JSON.stringify(layer));
		layerCopy.id = this.firstLayerId() ? this.firstLayerId() + 1 : 1;
		layerCopy.isDuplicate = true;
		return layerCopy;
	}

	public firstLayerId(): number {
		return this.layers[0].id;
	}

	public resetLayerIdOnDrag(layers: Layer[]): void {
		this.layers.forEach((layer) => (layer.idPreDrag = layer.id));

		//Layer id's should always be in reverse order
		let counter: number = this.layers.length - 1;
		this.layers = layers.map((layer) => {
			layer.id = counter;
			counter = counter - 1;
			return layer;
		});
	}

	public updateActiveLayer(objName: string, obj): void {
		this.layers[objName] = obj;
	}

	public showDuplicateLayerOption(): boolean {
		let layerType: LayerType = this.activeLayer.type;
		return layerType !== 'Countdown' && layerType !== 'Current Date' && layerType !== 'Current Time';
	}
}
