import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AppStateService } from './app-state.service';
import { environment } from '../../../environments/environment';
import { LibrariesDragDrop } from '../../shared/view-models/content';
import { ContactInformation, ProductTypes, Users } from '../../shared/api-models/admin';
import { Players } from '../../shared/api-models/admin';
import { PlayersDragDrop, PlayerGroupsDragDrop } from '../../shared/view-models/admin';
import { UtilitiesService } from '../../core/services/utilities.service';
import { DeliveryQueue } from 'src/app/shared/api-models/delivery';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

type NameAndId = { Name: string; Id: number };

@Injectable({
	providedIn: 'root'
})
export class AdminDataService {
	public noContractedProducts: boolean;

	constructor(private appStateService: AppStateService, private httpClient: HttpClient, private utilService: UtilitiesService) {}

	public getAllCSRs(includeEmail?: boolean): Observable<NameAndId[]> {
		return this.httpClient.get(environment.adminUrl + `CoreUsers/ActiveCsrs`).pipe(
			map((csrList: Users[]) => {
				return this.utilService.sortItems(this.csrList(csrList, includeEmail), 'Name');
			})
		);
	}

	private csrList(csrList: Users[], includeEmail?: boolean): NameAndId[] {
		return csrList.map((csr) => {
			let name: string;
			if (includeEmail) {
				name = `${csr.ContactInformation.FirstName} ${csr.ContactInformation.LastName} (${csr.ContactInformation.Email})`;
			} else {
				name = `${csr.ContactInformation.FirstName} ${csr.ContactInformation.LastName}`;
			}
			return {
				Name: name,
				Id: csr.Id
			};
		});
	}

	//http://blog.danieleghidoli.it/2016/10/22/http-rxjs-observables-angular/
	public getContractedProducts$(clientId: number): Observable<ProductTypes[]> {
		return this.httpClient.get(environment.adminUrl + 'CoreContracts/ContractedProducts/' + clientId).pipe(
			switchMap((contractedProductTypeIds: number[]) => {
				return this.getProductTypes$(null, contractedProductTypeIds);
			})
		);
	}

	public getActiveSalesmen(includeAllOption?: boolean): Observable<NameAndId[]> {
		return this.httpClient.get(environment.adminUrl + `CoreUsers/ActiveSalesmen`).pipe(
			map((response: any) => {
				const salesmenArr: Users[] = response;
				return this.utilService.sortItems(this.salesmenArr(salesmenArr, includeAllOption), 'Name');
			})
		);
	}

	public getCsrAndSalesRepByClient(clientId: number): Observable<[ContactInformation[], ContactInformation[]]> {
		const $csrInfo: Observable<ContactInformation[]> = this.httpClient.get<ContactInformation[]>(
			environment.adminUrl + 'CoreClients/' + clientId + '/' + 'Csr'
		);
		const $salesRepInfo: Observable<ContactInformation[]> = this.httpClient.get<ContactInformation[]>(
			environment.adminUrl + 'CoreClients/' + clientId + '/' + UserRole.SALESPERSON
		);

		return forkJoin([$csrInfo, $salesRepInfo]);
	}

	public getProductTypes$(includeSelectAProductOption?: boolean, contractedProductTypeIds?: number[]): Observable<ProductTypes[]> {
		return this.httpClient.get(environment.adminUrl + `CoreProductTypes`).pipe(
			map((response: any) => {
				const productTypesApiArr: ProductTypes[] = response;
				return this.productTypes(productTypesApiArr, includeSelectAProductOption, contractedProductTypeIds);
			})
		);
	}

	private productTypes(productTypes: ProductTypes[], includeSelectAProductOption?: boolean, contractedProductTypeIds?: number[]): ProductTypes[] {
		const productTypeIds = contractedProductTypeIds ? contractedProductTypeIds : this.appStateService.contractedProducts.map((product) => product.Id);
		productTypes = productTypes.map((productType) => {
			productType.hasContract = productTypeIds.some((id) => id === productType.Id) ? 1 : 0;
			return productType;
		});
		if (includeSelectAProductOption) {
			productTypes.unshift({ ProductName: 'Select a product...' });
		}
		return productTypes;
	}

	public setPlayerViewModel(player: PlayersDragDrop, deliveryQueue?: DeliveryQueue, dragDisabled?: boolean): PlayersDragDrop {
		player.name = `${player.PlayerName}` + ' - ' + '<em>' + `${this.setProductData(player).name}` + '</em>';
		player.draggableClass = dragDisabled ? '' : 'draggable';
		player.icon = this.setProductData(player).icon;
		player.bgColor = this.setProductData(player).bgColor;
		if (deliveryQueue) {
			player.deliveryQueue = deliveryQueue;
		}
		return player;
	}

	public setGroupViewModel(group: PlayerGroupsDragDrop, dragDisabled?: boolean): PlayerGroupsDragDrop {
		group.name = `${group.Name}` + ' - ' + '<em>' + `${this.setProductData(group).name}` + '</em>';
		group.isGroup = true;
		group.icon = this.setProductData(group).icon;
		group.draggableClass = (group.players && group.players.length === 0) || dragDisabled ? '' : 'draggable';
		group.bgColor = this.setProductData(group).bgColor;
		return group;
	}

	public setLibraryViewModel(library: LibrariesDragDrop): LibrariesDragDrop {
		library.name = `${library.LibraryName}` + ' - ' + '<em>' + `${this.setProductData(library).name}` + '</em>';
		library.icon = this.setProductData(library).icon;
		library.draggableClass = 'draggable';
		library.bgColor = this.setProductData(library).bgColor;
		return library;
	}

	private setProductData(playerOrGroup: PlayersDragDrop | PlayerGroupsDragDrop | LibrariesDragDrop): any {
		switch (true) {
			case playerOrGroup.ProductTypeId === 1:
				return { icon: 'fas fa-phone-volume', bgColor: 'blue-bg', name: 'On Hold' };
			case playerOrGroup.ProductTypeId === 2:
				return { icon: 'fas fa-volume-up', bgColor: 'purple-bg', name: 'Works24 Radio' };
			case playerOrGroup.ProductTypeId === 3:
				return { icon: 'fas fa-hdd', bgColor: 'red-bg', name: 'Lobby Video' };
			case playerOrGroup.ProductTypeId === 4:
				return { icon: 'fas fa-hdd', bgColor: 'red-bg', name: 'Poster' };
			case playerOrGroup.ProductTypeId === 6:
				return { icon: 'fas fa-hdd', bgColor: 'red-bg', name: '5x1 Array (H)' };
			case playerOrGroup.ProductTypeId === 7:
				return { icon: 'fas fa-hdd', bgColor: 'red-bg', name: '1x2 Array (V)' };
			case playerOrGroup.ProductTypeId === 8:
				return { icon: 'fas fa-book', bgColor: 'green-bg', name: 'Other Product or Service' };
			case playerOrGroup.ProductTypeId === 16:
				return { icon: 'fas fa-laptop', bgColor: 'red-bg', name: 'Web Player (H)' };
			case playerOrGroup.ProductTypeId === 17:
				return { icon: 'far fa-square', bgColor: 'red-bg', name: 'Web Player (V)' };
			default:
				return { icon: 'fas fa-question', bgColor: 'green-bg', name: 'Unknown' };
		}
	}

	private salesmenArr(salesmenArr: Users[], includeAllOption?: boolean): NameAndId[] {
		const arr = salesmenArr.map((salesman) => {
			return {
				Name: `${salesman.ContactInformation.FirstName} ${salesman.ContactInformation.LastName}`,
				Id: salesman.Id
			};
		});

		if (includeAllOption) {
			arr.unshift({ Name: 'All', Id: -1 });
			return arr;
		}
		return arr;
	}

	public setC24ActiveForCurrentClient(players: Players[]): void {
		const noAppleTvs: boolean = !players.some((player) => player.PlayerModelId === 1000000);
		const atLeastOneHaloWindowsPlayer: boolean = players.some((player) => player.PlayerModelId === 1000007);

		switch (true) {
			case this.appStateService.currentClient.IsDemoAccount:
			case this.appStateService.currentClient.IsInternal:
			case noAppleTvs && atLeastOneHaloWindowsPlayer:
				this.appStateService.newC24Active = true;
				break;
			default:
				this.appStateService.newC24Active = false;
		}
		this.appStateService.setSessionItem('newC24Active', JSON.stringify(this.appStateService.newC24Active));
		this.appStateService.setNewC24Active();
	}
}
