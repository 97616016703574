<div class="{{ backgroundColor }} container-fluid border-radius card-border pd-top15 pd-bottom15">
	<div class="row mgn-bottom15 pd-left15">
		<h3 class="semi-bold">Repeating Schedule</h3>
	</div>
	<div class="row">
		<div class="col-sm-5 mgn-bottom15">
			<span>Name (optional)</span>
			<input
				*ngIf="advancedSchedulerService.saveAsTemplate"
				[(ngModel)]="advancedSchedulerService.view.ScheduleName"
				type="text"
				class="pd-left8 {{ 'gray-bg-dark' }}"
				(keyup)="emitAdvancedSchedule(true)"
			/>

			<input *ngIf="!advancedSchedulerService.saveAsTemplate" type="text" disabled class="setOpacityLow pd-left8" />
		</div>
		<div *ngIf="advancedSchedulerService.templates.length > 1" class="col-sm-5 col-sm-offset-2 setMarginBottom52">
			<span>Load a previously saved schedule</span>
			<span
				class="pd4 pointer"
				ngxTippy
				tippyName="{{ utilService.guid | wrapFn }}"
				data-tippy-content="Deleting a previously saved schedule from this list has no affect on this item's repeating schedule. This list is the same for your whole account, regardless of the user."
				[tippyProps]="{ appendTo: 'parent', maxWidth: 200 }"
			>
				<i class="far fa-question-circle" aria-hidden="true"></i>
			</span>
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[hasItemDeleteIcon]="true"
				[height]="'ht40'"
				[key]="'ScheduleName'"
				[options]="advancedSchedulerService.templates"
				[selectedOption]="'-- none --'"
				(deleteClickNotify)="onTemplateDeleteClick($event)"
				(onOptionSelect)="assignPreviouslySavedSchedule($event)"
			></portal-dropdown-menu>
		</div>
	</div>
	<div class="row mgn-top15">
		<div class="col-sm-5 setMarginBottom43">
			<span>Repeats</span>
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[options]="repeatingOptions"
				[height]="'ht40'"
				[key]="'name'"
				[selectedOption]="advancedSchedulerService.view.RecurrenceType"
				(onOptionSelect)="selectRepeatingFrequency($event)"
			></portal-dropdown-menu>
		</div>
		<div *ngIf="viewService.repeatsMonthly(advancedSchedulerService.view)" class="col-sm-5 setMarginTop setMarginBottom52 hidden-xs">
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[options]="monthlyOptions"
				[height]="'ht40'"
				[key]="'name'"
				[selectedOption]="advancedSchedulerService.view.RecurrenceSubtype"
				(onOptionSelect)="selectMonthlyFrequency($event)"
			></portal-dropdown-menu>
		</div>

		<!-- ====================== START TIME  ============================== -->

		<div [ngClass]="{ 'col-sm-offset-5': advancedSchedulerService.view.RecurrenceType !== 'Monthly' }" class="col-sm-2 hidden-xs mgn-bottom30">
			<span>Start Time</span>
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[key]="'name'"
				[options]="time"
				[selectedOption]="advancedSchedulerService.view.RecurrenceStartTime"
				(onOptionSelect)="setStartingTime($event)"
			></portal-dropdown-menu>
			<small *ngIf="advancedSchedulerService.timeError === timeError.START" class="red-text ft-size12 absolute">
				<em>Choose a time before the stop time.</em>
			</small>
		</div>
	</div>

	<div class="row mgn-top30 setMarginTop0">
		<!-- ====================== MONTHS ============================== -->

		<div *ngIf="viewService.repeatsMonthly(advancedSchedulerService.view)" class="col-sm-2">
			<div *ngFor="let month of monthsOfTheYear; let i = index">
				<div *ngIf="i < 6" class="mgn-bottom4">
					<!-- <input type="checkbox" id="{{month}}" (click)="addRemoveMonthOfTheYear(month)" [checked]='utilService.includes(advancedSchedulerService.view.MonthsOfYear, month)'> -->
					<portal-checkbox
						[backgroundColor]="'white-bg'"
						[isChecked]="utilService.includes(advancedSchedulerService.view.MonthsOfYear, month)"
						[type]="'checkbox'"
						(checkboxClickNotify)="addRemoveMonthOfTheYear(month)"
					></portal-checkbox>
					<label (mouseup)="addRemoveMonthOfTheYear(month)" class="mgn-left8 relative bottom6 pointer">{{ month }}</label>
				</div>
			</div>
		</div>
		<div *ngIf="viewService.repeatsMonthly(advancedSchedulerService.view)" class="col-sm-3 setMarginBottom30">
			<div *ngFor="let month of monthsOfTheYear; let i = index">
				<div *ngIf="i >= 6" class="mgn-bottom4">
					<!-- <input type="checkbox" id="{{month}}" (click)="addRemoveMonthOfTheYear(month)" [checked]='utilService.includes(advancedSchedulerService.view.MonthsOfYear, month)'> -->
					<portal-checkbox
						[backgroundColor]="'white-bg'"
						[isChecked]="utilService.includes(advancedSchedulerService.view.MonthsOfYear, month)"
						[type]="'checkbox'"
						(checkboxClickNotify)="addRemoveMonthOfTheYear(month)"
					></portal-checkbox>
					<label (mouseup)="addRemoveMonthOfTheYear(month)" class="mgn-left8 relative bottom6 pointer">{{ month }}</label>
				</div>
			</div>
		</div>

		<!-- ===================== DAYS ================================ -->

		<div *ngIf="viewService.repeatsMonthly(advancedSchedulerService.view)" class="col-sm-5 setMarginTop setMarginBottom52 hidden-sm hidden-md hidden-lg">
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[options]="monthlyOptions"
				[height]="'ht40'"
				[key]="'name'"
				[selectedOption]="advancedSchedulerService.view.RecurrenceSubtype"
				(onOptionSelect)="selectMonthlyFrequency($event)"
			></portal-dropdown-menu>
		</div>

		<div *ngIf="viewService.repeatsWeeklyOrMonthlyAndWeekdays(advancedSchedulerService.view)" class="col-sm-5 setMarginBottom30">
			<div *ngFor="let day of daysOfTheWeek" class="mgn-bottom4">
				<!-- <input type="checkbox" id="{{day}}" (click)="addRemoveDayOfTheWeek(day)" [checked]='utilService.includes(advancedSchedulerService.view.DaysOfWeek, day)'> -->
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="utilService.includes(advancedSchedulerService.view.DaysOfWeek, day)"
					[type]="'checkbox'"
					(checkboxClickNotify)="addRemoveDayOfTheWeek(day)"
				></portal-checkbox>
				<span (mouseup)="addRemoveDayOfTheWeek(day)" class="mgn-left8 relative bottom6 pointer">{{ day }}</span>
			</div>
		</div>

		<!-- ====================== DATES ================================ -->

		<div *ngIf="viewService.repeatsMonthlyAndSubTypeDaysOfMonth(advancedSchedulerService.view)" class="col-sm-5 setMarginBottom30" #calendar>
			<div *ngFor="let date of datesOfMonth; let i = index" class="in-block">
				<div *ngIf="date !== '99'" class="mgn-right8">
					<input
						type="checkbox"
						id="{{ date }}"
						(click)="addRemoveDateOfTheMonth(date)"
						[checked]="utilService.includes(advancedSchedulerService.view.DaysOfMonth, date)"
					/>
					<label for="{{ date }}">{{ date }}</label>
				</div>
				<div *ngIf="date === '99'" class="mgn-right8">
					<input
						type="checkbox"
						id="{{ date }}"
						(click)="addRemoveDateOfTheMonth(date)"
						[checked]="utilService.includes(advancedSchedulerService.view.DaysOfMonth, date)"
					/>
					<label for="{{ date }}">Last</label>
				</div>
			</div>
		</div>

		<!-- ========================= YEARLY ============================= -->

		<div *ngIf="viewService.repeatsYearly(advancedSchedulerService.view)" class="col-sm-5">
			<div class="col-sm-6 pd-left0 pd-right0">
				<span>Start On</span>
				<portal-date-picker
					[backgroundColor]="'gray-bg-dark'"
					[dateFormat]="'mm/dd'"
					[height]="'ht40'"
					[model]="advancedSchedulerService.view.YearlyStartDate"
					(dateChanged)="setYearlyStartDate($event)"
				></portal-date-picker>
				<small *ngIf="advancedSchedulerService.dateError === dateError.START" class="red-text ft-size12 absolute">
					<em>Choose a date before the end date.</em>
				</small>
			</div>
			<div class="col-sm-6 setPaddingLeft0 pd-right0 setMarginBottom30">
				<span>End On</span>
				<portal-date-picker
					[backgroundColor]="'gray-bg-dark'"
					[dateFormat]="'mm/dd'"
					[height]="'ht40'"
					[model]="advancedSchedulerService.view.YearlyEndDate"
					(dateChanged)="setYearlyEndDate($event)"
				></portal-date-picker>
				<small *ngIf="advancedSchedulerService.dateError === dateError.STOP" class="red-text ft-size12 absolute">
					<em>Choose a date after the start date.</em>
				</small>
			</div>
		</div>

		<!-- ====================== STOP TIME ============================== -->
		<div
			[ngClass]="{ 'col-sm-offset-5': advancedSchedulerService.view.RecurrenceType !== 'Monthly' }"
			class="col-sm-2 hidden-sm hidden-md hidden-lg setMarginBottom52 ht40"
		>
			<span>Start Time</span>
			<portal-dropdown-menu
				[backgroundColor]="backgroundColor === 'white-bg' ? 'gray-bg' : 'white-bg'"
				[height]="'ht40'"
				[options]="time"
				[selectedOption]="advancedSchedulerService.view.RecurrenceStartTime"
				(onOptionSelect)="setStartingTime($event)"
			></portal-dropdown-menu>
		</div>

		<div
			[ngClass]="{
				'col-sm-offset-10': viewService.repeatsEveryDay(advancedSchedulerService.view),
				'col-sm-offset-5':
					viewService.repeatsYearly(advancedSchedulerService.view) ||
					viewService.repeatsMonthlyAndSubTypeEveryDay(advancedSchedulerService.view) ||
					viewService.repeatsWeekly(advancedSchedulerService.view)
			}"
			class="col-sm-2 mgn-bottom15 ht56"
		>
			<span>Stop Time</span>
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[key]="'name'"
				[options]="time"
				[selectedOption]="advancedSchedulerService.view.RecurrenceStopTime"
				(onOptionSelect)="setStopTime($event)"
			></portal-dropdown-menu>
			<small *ngIf="advancedSchedulerService.timeError === timeError.STOP" class="red-text ft-size12 absolute">
				<em>Choose a time after the start time.</em>
			</small>
		</div>
	</div>
	<div class="row mgn-top30">
		<div class="col-sm-6 col-sm-offset-6 text-right">
			<portal-checkbox
				[backgroundColor]="backgroundColor === 'white-bg' ? 'lt-gray-bg' : 'white-bg'"
				[isChecked]="advancedSchedulerService.saveAsTemplate"
				[isDisabled]="isScheduleDirty ? false : true"
				[type]="'checkbox'"
				(checkboxClickNotify)="advancedSchedulerService.saveAsTemplate = !advancedSchedulerService.saveAsTemplate"
			></portal-checkbox>
			<span
				*ngIf="isScheduleDirty"
				class="relative topneg7 mgn-left4 pointer"
				(mouseup)="advancedSchedulerService.saveAsTemplate = !advancedSchedulerService.saveAsTemplate"
			>
				Save repeating schedule for later use
			</span>
			<span *ngIf="!isScheduleDirty" class="relative topneg7 mgn-left4 gray-text-darkest"><em>Save repeating schedule for later use</em></span>
			<span
				class="pd4 pointer relative topneg7"
				ngxTippy
				tippyName="{{ utilService.guid | wrapFn }}"
				data-tippy-content="If checked this will save a copy of the repeating schedule that will appear in the dropdown menu above."
				[tippyProps]="{ appendTo: 'parent', maxWidth: 150 }"
			>
				<i class="far fa-question-circle" aria-hidden="true"></i>
			</span>
		</div>
	</div>
</div>
