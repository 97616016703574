import { ContentRequestClips } from '../../../shared/api-models/admin';
import { ContentFilesView } from './content-files-view';
import { ContentFilesViewVideo } from './content-files-view-video';
import { ContentRequestClipAttachments } from '../../../shared/api-models/admin';

export class ClipsView extends ContentRequestClips {
	contentFiles: ContentFilesView[];
	processingState: string;
	completedFileUploadInProgress: boolean;
	eligibleForRevision: boolean;
	attachments: ContentRequestClipAttachments[];
	completedFileError: boolean;

	resizeMethod: string;
	angleIcon: string;
	backgroundColor: string;
	logoDescription: string;
	detailsVisible: boolean;
	completedVideoFile: ContentFilesViewVideo;

	constructor(title?: string) {
		super();
		this.Title = title;
	}
}
