import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'portal-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {

  @Input() angleIconClass: string;
  @Input() backgroundColorClass: string;
  @Input() disabled: boolean;
  @Input() emptyMessageVisible: boolean;
  @Input() iconClass: string;
  @Input() itemName: any;
  @Input() itemText: string;
  @Input() messageText: string;
  @Input() setCursorClass: string;
  @Input() removeBtnVisible: boolean;
  @Input() shadow: string;
  @Input() textColor: string;
  @Output() removeClickNotify: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleNotify: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  public onRemoveBtnClick(): void {
    this.removeClickNotify.emit();
  }

  public onToggle(): void {
  	this.toggleNotify.emit();
  }

}
