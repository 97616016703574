<div id="{{ wrapperId }}" class="text-left shadow {{ setTop }} {{ setRight }} dropdown-menu dark-bg-light border-radius">
	<ng-container *ngFor="let menuItem of menu; let i = index">
		<a
			[attr.href]="menuItem.href"
			target="{{ menuItem.newTab ? '_blank' : '' }}"
			(click)="onMenuItemClick(menuItem)"
			(mouseenter)="onMouseOver(menuItem)"
			class="gray-text {{ fontSize }}"
		>
			<i class="{{ menuItem.iconClass }} ft-size18 mgn-right8" aria-hidden="true"></i>
			{{ menuItem.name }}
			<i
				*ngIf="i === 0 && wrapperId === 'tools-menu'"
				class="fas fa-question-circle white-text pointer mgn-left10"
				[ngxTippy]="htmlCountdown"
				tippyName="{{ utilService.guid | wrapFn }}"
				[tippyProps]="{ allowHTML: true, interactive: true, placement: 'right', appendTo: 'parent', arrow: true, theme: 'light' }"
			></i>
			<ng-template #htmlCountdown>
				<div [ngStyle]="{ 'width.px': 210 }" class="text-left noExit">
					<p class="noExit">Tutorial:</p>
					<span class="noExit">
						<i class="fas fa-video red-text mgn-right10 noExit"></i>
						<a
							style="display: 'inline-block'; color: '#333 !important'"
							class="underline tutorial noExit active"
							href="https://help.works24.com/knowledge-base/tutorial-adding-a-new-user/"
							target="_blank"
							(click)="onTooltipClick($event)"
						>
							Adding a New User
						</a>
					</span>
				</div>
			</ng-template>
		</a>
	</ng-container>
</div>
