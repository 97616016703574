<div class="noExit relative" (window:mouseup)="autoCloseDropdown($event)">
	<div *ngIf="!searchEnabled || (searchEnabled && !optionsVisible)" [ngClass]="{'setOpacityLow' : isDisabled, 'cursorClass' : !isDisabled}"  (click)="onDropdownClick()" class="{{height}} {{backgroundColor}} {{borderColor()}} ln-height{{heightNum}} {{!hideBorder ? 'setBorder' : null}} setWidth100 relative border-radius in-block pd-left8 pd-right8 space-between noExit">
		<span class="ellipsis relative noExit in-block {{fontSize}} {{textColor}}">{{selectedOption}}</span>
		<button *ngIf="!hideSquareSelectButton" type="button" [ngStyle]="{'height.px' : heightNum/2+10, 'width.px' : heightNum/2+10}" class="noExit rightneg10 ft-size16 absolute centered {{buttonColor()}} border-radius"><i class="ft-size22 top15 fas fa-angle-down absolute center-unknown-height-width {{textColor ? textColor : 'white-text'}} border-radius noExit" aria-hidden="true"></i></button>

		<i *ngIf="hideSquareSelectButton" class="fas fa-angle-down right0 {{textColor}} ft-size16 absolute centered border-radius noExit" aria-hidden="true"></i>
	</div>


	<!--**************************
		OPTIONAL SEARCH INPUT
	***************************-->
	<input #visibleSearchInput [(ngModel)]="searchTerm" *ngIf="optionsVisible && searchEnabled" (keyup)="onVisibleSearchKeyup($event)" (keydown)="onSearchKeydown($event)" (click)="optionsVisible = true" class="{{fontSize}} {{height}} search noExit lt-gray-border-darker setBorder" type="text" placeholder="Search">

	<div *ngIf="optionsVisible && options" #listContainer [ngClass]="{'bottom0' : flow === 'up'}" class="noExit {{listMaxHeight ? listMaxHeight : 'setMaxHeight'}} mgn-bottom50 text-left shadow absolute z setWidth100 {{menuBackgroundColor ? menuBackgroundColor : backgroundColor}} setZ scrollable border gray-border-darker align-left border-radius pd-top1 pd-bottom1 top{{height.slice(-2)}}">

		<!--*******************
			BEGIN LIST
		********************-->
		<ul>
			<li *ngFor="let option of options | searchFilter:searchTerm; let i = index" class="noExit"
				[ngClass]="{'gray-bg' : option[key] === element?.id}" 
				(mouseup)="onOptionClick(option, option[key] ? option[key] : option)" 
				(mouseover)="showHideDeleteIcon(i, 'show')" 
				(mouseleave)="showHideDeleteIcon(i, 'hide')" 
				#options 
				id="{{option[key]}}" 
				[ngStyle]="{'font-family': setFontFamily ? option[key] : null}" 
				class="option cursorClass relative noExit">{{option[key] ? option[key] : option}}
					<i *ngIf="option[key] === selectedOption || option === selectedOption" class="fa fa-check mgn-left10 noExit" aria-hidden="true"></i>
					<span *ngIf="hasItemDeleteIcon && deleteIconVisible && i === itemIndex" class="absolute right10 ft-size16 pd-left2 pd-right2 z noExit" (mouseup)="onDeleteClick($event, option)">
						<i class="far fa-trash-alt red-text-light noExit" aria-hidden="true"></i>
					</span>
			</li>
			<button *ngIf="showClientBrowserBtn" class="gray-bg regular ft-size16 pd-top0 pd-bottom0 noExit" [routerLink]="'/client-browser'">
				<i class="fas fa-user-circle mgn-right6 noExit"></i>
				Client Browser
			</button>
		</ul>
	</div>
	

	<!--***************************************
		HIDDEN INPUT FIELDS FOR EACH OPTION
	****************************************-->
	<div *ngIf="optionsVisible && options?.length > 0">
		<input *ngFor="let option of optionsView" (keyup.enter)="onOptionClick(option, option[key])" style="z-index: -1" #input class="absolute left0 ht2 setWidth75" (keydown)="onSearchKeydown($event)" value="{{option[key]}}">
	</div>


	<!--***********************
		HIDDEN SEARCH INPUT
	************************-->
	<input *ngIf="optionsVisible && options?.length > 0" #hiddenSearchInput type="text" style="z-index: -1" class="setWidth75 ht2 absolute left0 noExit" (keyup)="onHiddenSearchKeyup($event, hiddenSearchInput.value)">
</div>




