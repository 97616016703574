export class CsrNewReleaseSchedule {
    Id: number;
    CsrId: number;
    SendDay: number;
    LastSent: string

    constructor(csrId?: number, sendDay?: number) {
        this.CsrId = csrId;
        this.SendDay = sendDay;
    }
}