import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AppStateService, DateTimeService, Events, MessageService, UtilitiesService } from '../../../../core/services';
import { CreateEditTicketService } from './create-edit-ticket.service';
import { ClientHistoryNotes } from '../../../../shared/api-models/log';
import { EmailService } from './email.service';
import { environment } from '../../../../../environments/environment';
import { Notes } from '../../_models';
import { TicketsView } from '../_models/tickets-view';
import { WorkOrdersView } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class HistoryNoteService {

    constructor(
        private appStateService: AppStateService,
        private ceTicketService: CreateEditTicketService,
        private dateTimeService: DateTimeService,
        private emailService: EmailService,
        private httpClient: HttpClient,
        private messageService: MessageService,
        private utilService: UtilitiesService
    ) { }

    public getHistoryNotes(): Observable<any> {
        return this.httpClient.get(`${environment.adminUrl}ClientHistoryNotes/Ticket/${this.ceTicketService.ticket.Id}`).pipe(
            map( (notes: Notes[]) => {
                this.ceTicketService.ticket.historyNotes = notes;

                this.ceTicketService.getHistoryNotesUserAndLocation('historyNotes', 'UserId', 'ServiceLocationId').subscribe();
            })
        )
    }

    public historyNote(logMessage: string, logSubject: string, entryType: string, serviceLocationId?: number): ClientHistoryNotes {
        let historyNote: ClientHistoryNotes = new ClientHistoryNotes();
        historyNote.ClientId = this.ceTicketService.ticket.ClientId;
        historyNote.ServiceLocationId = serviceLocationId;
        historyNote.UserId = this.appStateService.currentUser.UserId;
        historyNote.TicketId = this.ceTicketService.ticket.Id;
        historyNote.EntrySource = this.ticketTypeToEntrySource();
        historyNote.EntryType = entryType;
        historyNote.LogDate = new Date().toISOString()
        historyNote.LogSubject = logSubject;
        historyNote.IsSystemGenerated = false;
        historyNote.IsError = false;
        historyNote.LogMessage = logMessage;
        return historyNote;
    }

    public postHistoryNote(historyNote: ClientHistoryNotes): Observable<ClientHistoryNotes> {
        return this.httpClient.post(`${environment.adminUrl}ClientHistoryNotes`, historyNote).pipe(
            map( (historyNote: ClientHistoryNotes) => {
                return historyNote;
            })
        )
    }

    public updateHistoryNote(historyNote: ClientHistoryNotes): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.httpClient.patch(`${environment.adminUrl}ClientHistoryNotes/${historyNote.Id}`, historyNote)
            .subscribe( () => {
                this.emailService.sendNotificationEmail('historyNote', null, historyNote);
                this.messageService.publish(Events.savingPreloader, 0);
            })
    }

    public historyNoteNewOrClosedTicket(isTicketClosed: boolean): ClientHistoryNotes {
        return this.historyNote(
            this.ceTicketService.ticket.Description,
            this.historyNoteSubject(isTicketClosed),
            this.entryTypeHistoryNote(this.ceTicketService.ticket),
            this.ceTicketService.ticket.assignedLocations.length === 1 ?
                this.ceTicketService.ticket.assignedLocations[0].Id : null)
    }

    public historyNoteTicketDetailsChanged(subject: string, modelProp: string): ClientHistoryNotes {
        return this.historyNote(
            this.logMessage(modelProp),
            subject,
            this.entryTypeHistoryNote(this.ceTicketService.ticket));
    }

    public addToNotesArray(historyNote: Notes, savedHistoryNote: Notes): void {
        historyNote.createdByUsername = this.appStateService.currentUser.UserName;
        historyNote.LogDate = savedHistoryNote.LogDate;
        historyNote.Id = savedHistoryNote.Id;
        this.ceTicketService.ticket.historyNotes.unshift(historyNote);
    }

    public entryTypeHistoryNote(ticket: TicketsView): string {
        if (this.utilService.includes(ticket.ticketTypeName, 'Install')) {
            return 'Install Ticket'
        }
        if (this.utilService.includes(ticket.ticketTypeName, 'Repair')) {
            return 'Repair Ticket';
        }
        return 'Trouble Ticket';
    }

    public workOrderCompleteHistoryNote(workOrder: WorkOrdersView): ClientHistoryNotes {
        return this.historyNote(
            workOrder.CompletedNote,
            `Work Order Complete: ${workOrder.ConfirmationNumber}`,
            'Other',
            workOrder.ServiceLocationId
        )
    }

    public emailOrFaxHistoryNote(workOrder: WorkOrdersView, emailOrFax: 'email' | 'fax'): ClientHistoryNotes {
        return this.historyNote(
            `Installer: ${workOrder.Installer?.CompanyName}`,
            `Work Order ${emailOrFax === 'email' ? 'Emailed' : 'Faxed'}`,
            'Other',
            workOrder.ServiceLocationId
        )
    }

    public logMessage(modelProp: string): string {
        if (modelProp === 'TicketStateId') {
            return `<span class="semi-bold">From: </span> <span>${this.ceTicketService.ticketCopy.status}</span><br>
                <span class="semi-bold">To: </span><span>${this.ceTicketService.ticket.status}`;
        }
        if (modelProp === 'TicketTypeId') {
            return `<span class="semi-bold">From: </span> <span>${this.ceTicketService.ticketCopy.ticketTypeName}</span><br>
                <span class="semi-bold">To: </span><span>${this.ceTicketService.ticket.ticketTypeName}`;
        }
        if (modelProp === 'Title') {
            return `<span class="semi-bold">From: </span> <span>${this.ceTicketService.ticketCopy.Title}</span><br>
                <span class="semi-bold">To: </span><span>${this.ceTicketService.ticket.Title}`;
        }
        if (modelProp === 'AssignedToUserId') {
            return `<span class="semi-bold">From: </span> <span>${this.ceTicketService.ticketCopy.assignedToUsername}</span><br>
                <span class="semi-bold">To: </span><span>${this.ceTicketService.ticket.assignedToUsername}`;
        }
        if (modelProp === 'PostponeUntil') {
            let date = this.dateTimeService.dateUTCtoLocal(this.ceTicketService.ticket.PostponeUntil);
            let dateCopy = this.dateTimeService.dateUTCtoLocal(this.ceTicketService.ticketCopy.PostponeUntil);
            return `<span class="semi-bold">From: </span> <span>${this.postponeDate(dateCopy)}</span><br>
                <span class="semi-bold">To: </span><span>${this.postponeDate(date)}`;
        }
    }

    private assignedLocationNamesString(locations: any[]): string {
        let locationNames: string = '';
        locations.forEach((location, index) => {
            if (index !== locations.length - 1) {
                locationNames = locationNames.concat(`${location.Name}, `);
            } else {
                locationNames = locationNames.concat(`${location.Name}`);
            }
        })
        return locationNames;
    }

    private historyNoteSubject(isTicketClosed: boolean): string {
        //new ticket
        if (!isTicketClosed) {
            if (this.ceTicketService.ticket.assignedLocations.length === 0) {
                return 'New Ticket, no locations assigned';
            }
            if (this.ceTicketService.ticket.assignedLocations.length === 1) {
                return `New Ticket for ${this.ceTicketService.ticket.assignedLocations[0].Name}`;
            }
            if (this.ceTicketService.ticket.assignedLocations.length > 1) {
                return `New Ticket for ${this.assignedLocationNamesString(this.ceTicketService.ticket.assignedLocations)}`;
            }
            //closing a ticket
        } else {
            if (this.ceTicketService.ticket.assignedLocations.length === 1) {
                return `Ticket closed for ${this.ceTicketService.ticket.assignedLocations[0].Name}`;
            }
            if (this.ceTicketService.ticket.assignedLocations.length > 1) {
                return `Ticket closed for ${this.assignedLocationNamesString(this.ceTicketService.ticket.assignedLocations)}`;
            }
            return 'Ticket closed, no locations assigned';
        }
    }

    private ticketTypeToEntrySource(): string {
        switch (this.ceTicketService.ticket.TicketTypeId) {
            case 1:
            case 5:
            case 9:
                return 'Hold Service';
            case 2:
            case 6:
            case 10:
                return 'Video Service';
            case 3:
            case 7:
            case 11:
                return 'Music Service';
            default:
                return 'Other';
        }
    }

    private postponeDate(date: any): string {
        if (date === 'Invalid date') {
            return 'No date set';
        }
        return date;
    }
}
