import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { adminOrCsrGuard, authGuard, canDeactivateGuard, isEmployeeGuard } from '../core/services/route-guards';
import { CreateEditClientComponent } from './create-edit-client/create-edit-client.component';
import { CreateEditLocationComponent } from './locations/create-edit-location/create-edit-location.component';
import { CreateEditPlayerComponent } from './create-edit-player/create-edit-player.component';
import { CreateHistoryNoteComponent } from './history-notes/create-history-note/create-history-note.component';
import { LocationDetailsComponent } from './locations/location-details/location-details.component';

const routes: Routes = [
	{
		path: 'crm/new-location',
		component: CreateEditLocationComponent,
		canActivate: [authGuard, isEmployeeGuard]
	},
	{
		path: 'crm/edit-location',
		component: CreateEditLocationComponent,
		canActivate: [authGuard, isEmployeeGuard]
	},
	{
		path: 'crm/new-history-note',
		component: CreateHistoryNoteComponent,
		canActivate: [authGuard, isEmployeeGuard]
	},
	{
		path: 'crm/client-details/locations/location-details',
		component: LocationDetailsComponent,
		canActivate: [authGuard, isEmployeeGuard]
	},
	{
		path: 'crm/client-details/locations/location-details/edit-player',
		component: CreateEditPlayerComponent,
		canActivate: [authGuard, isEmployeeGuard],
		canDeactivate: [canDeactivateGuard]
	},
	{
		path: 'crm/client-details/locations/location-details/new-player/:productRoute',
		component: CreateEditPlayerComponent,
		canActivate: [authGuard, isEmployeeGuard]
	},
	{
		path: 'crm/edit-client',
		component: CreateEditClientComponent,
		canActivate: [authGuard, isEmployeeGuard]
	},
	{
		path: 'crm/create-new-client',
		component: CreateEditClientComponent,
		canActivate: [authGuard, isEmployeeGuard, adminOrCsrGuard]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CrmRoutingModule {}
