import { Component, OnInit, Input } from '@angular/core';

import { ContentViewService } from '../../_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { ContentVM } from '../../_models/content-view';

@Component({
	selector: 'vc-featured-content-view',
	templateUrl: './featured-content-view.component.html',
	styleUrls: ['./featured-content-view.component.scss']
})
export class FeaturedContentViewComponent implements OnInit {
	@Input() content: ContentVM;
	@Input() contentList: ContentVM[];
	@Input() isEvenIndex: boolean;
	@Input() isFirstIndex: boolean;
	@Input() isLastIndex: boolean;

	public previewActive: boolean;

	constructor(public contentViewService: ContentViewService, public cvStateService: ContentViewStateService) {}

	ngOnInit(): void {}

	public onPreviewClick(): void {
		if (this.content.contentType === 'video/mp4') {
			this.previewActive = true;
		}
	}
}
