import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { AppErrorService } from 'src/app/core/app-error/app-error.service';
import { AppStateService, Events, MessageService, SessionExpireService } from './core/services';
import { FileUploadStateService } from './shared/services';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	public animateClass: string;
	public savingPreloaderVisible: boolean;
	public savingBubblesVisible: boolean;
	public savingText: string;
	public transitionOverlayMessage: string;
	public transitionOverlayVisible: boolean;

	private subs: Subscription[] = [];
	private ngUnsubscribe: Subject<void> = new Subject();

	constructor(
		public appErrorService: AppErrorService,
		public appStateService: AppStateService,
		public fileUploadStateService: FileUploadStateService,
		private messageService: MessageService,
		public sessionExpireService: SessionExpireService
	) {
		this.savingPreloaderSubscribe();
		this.transitionOverlaySubscribe();
		this.onAppError();
	}

	ngOnInit(): void {}

	onAppError(): void {
		this.subs.push(
			this.messageService.subscribe(Events.appError, (payload) => {
				this.appErrorService.error = payload[0];
				this.appErrorService.friendlyMessage = payload[1];
				this.appErrorService.hideReloadPageBtn = payload[2];
			})
		);
	}

	savingPreloaderSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.savingPreloader, (savingPreloader: number) => {
				if (savingPreloader === 1) {
					this.savingPreloaderVisible = true;
					this.animateClass = 'fadeInUp';
					this.savingProps('Saving', true);
				} else if (savingPreloader === 0) {
					setTimeout(() => {
						this.savingProps('Saved.', false);
					}, 1400);

					setTimeout(() => {
						this.animateClass = 'fadeOutUp';
					}, 3600);
				}

				if (savingPreloader === 0) {
					setTimeout(() => {
						this.savingPreloaderVisible = false;
						this.savingText = '';
					}, 3800);
				}
			})
		);
	}

	private savingProps(savingPopoutText: string, savingBubblesVisible: boolean): void {
		this.savingText = savingPopoutText;
		this.savingBubblesVisible = savingBubblesVisible;
	}

	transitionOverlaySubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.transitionOverlay, (payload: [string, boolean]) => {
				const [message, overlayVisible] = [...payload];

				this.transitionOverlayMessage = message;
				this.transitionOverlayVisible = overlayVisible;
			})
		);
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
