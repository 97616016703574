import { Component, OnInit, OnDestroy } from '@angular/core';

import { Column } from '../../shared/components/table/view-models';
import { DeliveryQueueView } from './_models/delivery-queue-view';
import { HoldDeliveryQueueService, TableConfigService } from './_services';

@Component({
	selector: 'st-hold-delivery-queue',
	templateUrl: './hold-delivery-queue.component.html',
	styleUrls: ['./hold-delivery-queue.component.scss'],
	providers: [TableConfigService]
})
export class HoldDeliveryQueueComponent implements OnInit, OnDestroy {
	public autoRefresh: boolean = true;
	public deliveryQueue: DeliveryQueueView[];
	public noResultsFound: boolean;
	public refreshingVisible: boolean;
	public resetSort: any;

	private timer: any;

	constructor(public holdDeliveryQueueService: HoldDeliveryQueueService, public tableConfigService: TableConfigService) {}

	ngOnInit() {
		this.getOnHoldQueue();
		this.timer = setInterval(() => this.getOnHoldQueue(), 30000);
	}

	public getOnHoldQueue(): void {
		this.holdDeliveryQueueService.getOnHoldQueue().subscribe((deliveryQueue: DeliveryQueueView[]) => {
			this.deliveryQueue = deliveryQueue;
			this.refreshingVisible = true;
			setTimeout(() => (this.refreshingVisible = false), 1200);
			if (this.deliveryQueue.length < 1) {
				this.noResultsFound = true;
			}
		});
	}

	onTableButtonClick(event: [Column, DeliveryQueueView]): void {
		const [, deliveryQueue] = event;
		switch (event[0].key) {
			case 'deleteBtn':
				const confirmDelete: boolean = confirm(`Delete "${deliveryQueue.PlaylistName}" from the delivery queue?`);
				if (confirmDelete) {
					this.holdDeliveryQueueService.delete(deliveryQueue.Id).subscribe(() => {
						this.getOnHoldQueue();
					});
				}
				break;
			case 'forceToTopBtn':
				this.holdDeliveryQueueService.patch('DeliveryQueue', deliveryQueue.Id, this.deliveryQueueApiHighPriority()).subscribe((res) => this.getOnHoldQueue());
				break;
			case 'resetBtn':
				this.holdDeliveryQueueService.patch('DeliveryQueue', deliveryQueue.Id, this.deliveryQueueReset()).subscribe((_) => this.getOnHoldQueue());
				break;
		}
	}

	public onAutoRefreshClick(): void {
		this.autoRefresh = !this.autoRefresh;
		if (this.autoRefresh) {
			this.timer = setInterval(() => this.getOnHoldQueue(), 15000);
			this.getOnHoldQueue();
		} else {
			clearInterval(this.timer);
		}
	}

	public onPageSelect(pageNumber: number): void {
		this.holdDeliveryQueueService.pageNumber = pageNumber;
	}

	public onResetSortClick(): void {
		this.resetSort = { key: '', order: 'asc' };
		this.getOnHoldQueue();
	}

	private deliveryQueueApiHighPriority(): DeliveryQueueView {
		const deliveryQueueApi: DeliveryQueueView = new DeliveryQueueView();
		deliveryQueueApi.IsHighPriority = true;
		return deliveryQueueApi;
	}

	private deliveryQueueReset(): DeliveryQueueView {
		const deliveryQueueApi: DeliveryQueueView = new DeliveryQueueView();
		deliveryQueueApi.DeliveryStatusId = 2;
		deliveryQueueApi.IsHighPriority = false;
		deliveryQueueApi.StatusJson = 'Ready';
		return deliveryQueueApi;
	}

	ngOnDestroy() {
		clearInterval(this.timer);
	}
}
