import { Injectable } from '@angular/core';
import { fabric } from 'fabric';

import { CanvasService } from '../canvas/_services/canvas.service';
import { Layer } from '../_models';
import { LayersService } from './layers.service';
import { TransUtilService } from './trans-util.service';
import { UtilitiesService } from 'src/app/core/services';
import { VideoControlsService } from './video-controls.service';

@Injectable({
    providedIn: 'root'
})
export class TransitionService {

    constructor(
        private canvasService: CanvasService,
        private layersService: LayersService,
        private transUtilService: TransUtilService,
        private utilService: UtilitiesService,
        private videoControlsService: VideoControlsService

    ) { }

    public beginTransition(callback: (obj, layer: Layer) => any): void {
        //Filter out hidden feed text boxes
        this.canvasService.canvas.getObjects().filter(obj => !obj.isFeedTextImage).forEach((obj) => {
            let layer = this.layersService.layers.find(layer => layer.id === obj.layerId);
            callback(obj, layer);
        })
    }

    public transitionIn = (obj, layer: Layer): void => {
        let animateFrom;
        //Playhead hitting layer start OR transition is resuming
        switch (true) {
            case this.startTransitionIn(layer):
                animateFrom = this.transUtilService.animateToFrom(layer, 'transitionIn', obj);
                this.animateIn(obj, layer, animateFrom);
                break;

            case this.resumeTransitionIn(layer):
                animateFrom = this.transition(obj, layer.transitionIn).value;
                this.animateIn(obj, layer, animateFrom);
                break;
        }
    }

    private animateIn(obj: any, layer: Layer, animateFrom: number): void {
        let animateTo = this.transition(obj, layer.transitionIn, layer).canvasValue;
        obj.animate(
            this.transition(obj, layer.transitionIn).prop, //string property value
            animateTo, //obj.top/left/opacity value
            this.animateArgs(animateFrom, 'easeOutCubic', layer.transInDuration));
        layer.transitionInFired = true;
    }

    public transitionOut = (obj, layer: Layer): void => {
        //Playhead hitting transitionOutStart OR transition is resuming
        if (this.fireTransitionOut(layer)) {

            //Animate To will always be the same on trans out, as opposed to trans in where it's dynamic
            let animateTo = this.transUtilService.animateToFrom(layer, 'transitionOut', obj);
            obj.animate(
                this.transition(obj, layer.transitionOut).prop,
                animateTo,
                this.animateArgs(this.transition(obj, layer.transitionOut).value, 'easeInCubic', layer.transOutDuration))
            layer.transitionOutFired = true;
        }
    }

    private transition(obj, trans: string, layer?: Layer): any {
        switch (true) {
            case this.utilService.includes(trans, 'down'):
            case this.utilService.includes(trans, 'up'):
                return { prop: 'top', value: obj.top, canvasValue: layer?.canvasObj.top };

            case this.utilService.includes(trans, 'left'):
            case this.utilService.includes(trans, 'right'):
                return { prop: 'left', value: obj.left, canvasValue: layer?.canvasObj.left };

            case this.utilService.includes(trans, 'Fade in'):
            case this.utilService.includes(trans, 'Fade out'):
                return { prop: 'opacity', value: +obj.opacity, canvasValue: layer?.canvasObj.opacity };
        }
    }

    private fireTransitionOut(layer: Layer): boolean {
        switch (true) {
            case layer.transitionOut && layer.transitionOut !== 'Not set' && !layer.transitionOutFired && this.transUtilService.playheadGreaterThanTransOutStart(layer):
            case layer.transitionOut && layer.transitionOut !== 'Not set' && !layer.transitionOutFired && this.transUtilService.resumeTransOut(layer):
                return true;
        }
    }

    private startTransitionIn(layer: Layer): boolean {
        return layer?.transitionIn !== 'Not set' &&
            !layer.transitionInFired &&
            !this.resumeTransitionIn(layer) &&
            this.transUtilService.playheadGreaterThanLayerStart(layer) &&
            this.transUtilService.playheadLessThanTransInEnd(layer);
    }

    private resumeTransitionIn(layer: Layer): boolean {
        return layer?.transitionIn !== 'Not set' && !layer.transitionInFired && this.transUtilService.resumeTransIn(layer);
    }

    private animateArgs(from: number, ease: string, duration: number): any {
        return {
            from: from,
            duration: duration,
            onChange: this.canvasService.canvas.renderAll.bind(this.canvasService.canvas),
            easing: fabric.util.ease[ease],
            abort: () => !this.videoControlsService.isPlaying
        }
    }
}