import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { switchMap, tap, catchError, first } from "rxjs/operators";
import { EMPTY } from "rxjs";

import { AdminDataService, AppStateService } from "../core/services";
import { ProductCard } from "../my-products/view-models";
import { ProductTypes } from "../shared/api-models/admin";
import { MyProductsService } from "./my-products.service";


export interface ProductCardsState {
    productCards: ProductCard[];
}

@Injectable()
export class MyProductsStore extends ComponentStore<ProductCardsState> {
    constructor(
        private adminDataService: AdminDataService,
        private appStateService: AppStateService,
        private myProductsService: MyProductsService
    ) {
        super({ productCards: [] })
    }


    //Selectors
    readonly productCards$ = this.select(({productCards}) => productCards);


    //Effects
    readonly getProducts$ = this.effect((trigger$) =>
        trigger$.pipe(
            first(),
            switchMap(() =>
                this.adminDataService.getContractedProducts$(this.appStateService.currentClient.Id).pipe(
                    tap((products: ProductTypes[]) => {
                        const mappedProductCards = this.myProductsService.productCardsView(products);         
                        this.setProductCards(mappedProductCards);
                        this.appStateService.setSessionItem('atLeastOneVideoProduct', JSON.stringify(this.myProductsService.atLeastOneVideoProduct(mappedProductCards)));
                    }),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    //Updaters
    readonly setProductCards = this.updater((state, productCards: ProductCard[]) => ({
        ...state,
        productCards
    }));
}