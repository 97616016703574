<div class="col-sm-6 col-lg-4 mgn-bottom60 padding-xs">
	<div
		style="z-index: 100"
		*ngIf="createEditRequestService.request.approvalInstructionsVisible"
		class="container-fluid mx-4 instruction-overlay border-radius white-text"
	>
		<div class="row mgn-top60">
			<div class="col-sm-12">
				<p class="ft-size20">
					1. Your Works24 On-Hold content request is pending script approval. Please check your scripts here in the "Messages" column. You may edit any message,
					approve them as-is, or send them back to the scriptwriter for revisions. Once approved, your on-hold messages should be read and in your library
					within five business days.
				</p>
				<br />
				<p class="ft-size20">
					2. Please leave any questions, comments or concerns you may have for your scriptwriter in the "Comments" section to the right. All communication
					regarding this request should be posted here.
					<i class="fa fa-arrow-right right" aria-hidden="true"></i>
				</p>
				<br />
				<p class="ft-size20">3. Once you are finished please click either "Request script revisions" or "Approve scripts".</p>
			</div>
		</div>
		<div class="row absolute bottom20 setWidth100">
			<div class="col-sm-6">
				<button class="gray-bg dark-text" (mouseup)="showHideInstructionsClick(false)">Don't show this again</button>
			</div>
			<div class="col-sm-6">
				<button class="limeGreen-bg" (mouseup)="createEditRequestService.request.approvalInstructionsVisible = false">Got it!</button>
			</div>
		</div>
	</div>

	<perfect-scrollbar
		*ngIf="createEditRequestService.request.clips"
		[ngStyle]="{ 'height.rem': heightService.detailsAndClipsHeight() }"
		[config]="{ suppressScrollX: true }"
		(psScrollUp)="onScroll()"
		(psScrollDown)="onScroll()"
		class="shadow border-radius mgn-bottom60 white-bg pd15"
	>
		<div class="row mgn-bottom30">
			<div class="col-sm-6">
				<h2 class="semi-bold in-block mgn-right15">
					{{ createEditRequestService.request.clips.length }}
					<span>{{ createEditRequestService.request.RequestType === 'On-Hold Message' ? 'Message' : 'Clip' }}</span>
					<span *ngIf="createEditRequestService.request.clips.length !== 1">s</span>
				</h2>
			</div>

			<!--ADD MESSAGE BUTTON, FOR ON-HOLD ONLY IN NEW MODE-->
			<div
				*ngIf="createEditRequestService.request.RequestType === 'On-Hold Message' && viewStateService.mode === 'new'"
				class="col-sm-5 col-sm-offset-1 text-right"
			>
				<button class="white-bg border-radius setWidth100" (mouseup)="onAddMessageClick()">
					<i class="fas fa-plus mgn-right12"></i>
					Add message
				</button>
			</div>

			<div
				*ngIf="
					createEditRequestService.request.RequestType === 'On-Hold Message' &&
					createEditRequestService.request.clips?.length > 0 &&
					createEditRequestService.request.clips[0].IsApproved
				"
				class="col-sm-6 text-right"
			>
				<span class="green-text ft-size13"><em>All message scripts approved.</em></span>
			</div>

			<!--SHOW INSTRUCTIONS BUTTON, FOR ON-HOLD ONLY IN EDIT MODE AND CLIENT VIEW-->
			<div *ngIf="createEditRequestService.request.showInstructionsBtnVisible" class="col-sm-5 col-sm-offset-1 text-right">
				<button class="white-bg" (mouseup)="showHideInstructionsClick(true)">Show Instructions</button>
			</div>
		</div>

		<div *ngIf="viewStateService.mode === 'new' && createEditRequestService.request.clips?.length === 1" class="row mgn-bottom15">
			<div class="col-sm-12">
				<span class="blue-text semi-bold"><em>*You can add multiple messages to this request.</em></span>
			</div>
		</div>

		<!--SHOW PRINT SCRIPT BUTTON, FOR ON-HOLD ONLY IN EDIT MODE AND CONTENT CREATOR ROLES-->
		<div *ngIf="clipsService.showPrintBtn()" class="row mgn-bottom15">
			<div class="col-sm-4">
				<button class="white-bg" (mouseup)="onPrintScriptsClick()">Print scripts</button>
			</div>
		</div>

		<!--*****************************
			ADD NEW CLIP, VIDEO ONLY
		******************************-->
		<portal-accordion
			*ngIf="createEditRequestService.request?.RequestType !== 'On-Hold Message'"
			[angleIconClass]="angleIcon"
			[backgroundColorClass]="'limeGreen-bg'"
			[disabled]="createEditRequestService.request.RequestType === 'Video-Logo'"
			[itemName]="'Add new clip...'"
			[shadow]="'none'"
			(toggleNotify)="onToggleClick()"
		></portal-accordion>
		<div *ngIf="newClipVideoDetailsVisible" class="border border-radius box-shadow pd15 mgn-bottom15">
			<div class="row mgn-top20 mgn-bottom20">
				<div class="col-sm-12 relative">
					<h4 class="semi-bold mgn-bottom6 in-block">Title</h4>
					<i class="fa fa-asterisk ft-size8 red-text-light absolute top0 left48" aria-hidden="true"></i>
					<input type="text" [(ngModel)]="newClipVideo.Title" />
				</div>
			</div>
			<div class="row mgn-top20 mgn-bottom20">
				<div class="col-sm-12">
					<h4 class="semi-bold mgn-bottom6">Text To Be Used</h4>
					<textarea type="text" rows="5" [(ngModel)]="newClipVideo.ScriptText"></textarea>
				</div>
			</div>
			<div class="row mgn-top20 mgn-bottom20">
				<div class="col-sm-12">
					<h4 class="semi-bold mgn-bottom6">Similar Clips</h4>
					<textarea type="text" rows="4" [(ngModel)]="newClipVideo.SimilarClip"></textarea>
				</div>
			</div>
			<div class="row mgn-top20 mgn-bottom20">
				<div class="col-sm-12">
					<h4 class="semi-bold mgn-bottom6">Description</h4>
					<textarea type="text" rows="5" [(ngModel)]="newClipVideo.Description"></textarea>
				</div>
			</div>
			<div class="row mgn-top20 mgn-bottom20">
				<div class="col-sm-4 col-sm-offset-4">
					<button class="border-radius gray-bg setWidth100">Cancel</button>
				</div>
				<div class="col-sm-4">
					<button [disabled]="!newClipVideo.Title" class="border-radius limeGreen-bg setWidth100 white-text" (mouseup)="onAddNewClipClick()">Add</button>
				</div>
			</div>
		</div>

		<!--*****************************
			BEGIN MESSAGES/CLIPS LOOP
		******************************-->
		<div *ngIf="createEditRequestService.request.clips" class="{{ createEditRequestService.request.RequestType !== 'On-Hold Message' ? 'row' : null }}">
			<c-clip *ngFor="let clip of createEditRequestService.request.clips; let i = index" [clip]="clip" [index]="i"></c-clip>
		</div>
	</perfect-scrollbar>
</div>
