import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

import { AppStateService, DateTimeService, Events, MessageService, SecurityService, UtilitiesService } from '../../../../core/services';
import { environment } from '../../../../../environments/environment';
import { CategoriesTree, LibrariesTree } from '../../library-tree/_models';
import { Content } from '../../../api-models/content';
import { ContentViewStateService } from '../../../services';
import { ContentVM } from '../../content-container/content/_models/content-view';

@Injectable({
	providedIn: 'root'
})
export class BatchChangeCategoryService {
	//View state
	public atLeastOneContentSelected: boolean;
	public parentCategorySelected: boolean;
	public selectAllChecked: boolean;

	public filteredSubCategories: CategoriesTree[];
	public libraries: LibrariesTree[] = [];
	public parentCategories: CategoriesTree[];

	public selectedParentCategory: CategoriesTree;
	public selectedSubCategory: CategoriesTree;
	public subCategories: CategoriesTree[];

	private selectedLibrary: LibrariesTree;

	constructor(
		private appStateService: AppStateService,
		private dateTimeService: DateTimeService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private cvStateService: ContentViewStateService,
		private securityService: SecurityService,
		private utilService: UtilitiesService
	) {}

	public onCheckboxSelect(content: ContentVM): void {
		content.isSelected = !content.isSelected;
		this.atLeastOneContentSelected = this.cvStateService.contentList.some((contentItem) => contentItem.isSelected);
		if (this.atLeastOneContentSelected) {
			this.getLibrariesAndCategories();
		}
	}

	public onSelectAllClick(): void {
		this.selectAllChecked = !this.selectAllChecked;
		this.cvStateService.contentList.forEach((content) => (content.isSelected = this.selectAllChecked));
		this.atLeastOneContentSelected = this.selectAllChecked;

		if (this.selectAllChecked) {
			this.getLibrariesAndCategories();
		}
	}

	public onLibrarySelect(library: LibrariesTree): void {
		this.selectedLibrary = library;
		this.getCategoriesByLibraryId(library.Id);
		this.filteredSubCategories = [];
	}

	public onParentCategorySelect(category: CategoriesTree): void {
		this.parentCategorySelected = true;
		this.selectedParentCategory = category;
		let tempSubCategories = this.subCategories;

		this.filteredSubCategories = tempSubCategories.filter((subCategory) => subCategory.ParentCategoryId === this.selectedParentCategory.Id);
	}

	public onSubCategorySelect(category: CategoriesTree): void {
		this.selectedSubCategory = category;
	}

	public onMoveToCategoryClick(): void {
		this.messageService.publish(Events.savingPreloader, 1);

		//Create patch observables for each content that is selected
		let updateObservables = this.cvStateService.contentList
			.filter((content) => content.isSelected)
			.map((content) => {
				return this.httpClient.patch(`${environment.contentUrl}Content/${content.Id}`, this.content());
			});

		//Forkjoin executes each observable sequentially, one subscribe callback when all are completed
		forkJoin(updateObservables).subscribe(() => {
			//Remove each selected content from current contentList array
			this.cvStateService.contentList = this.cvStateService.contentList.filter((content) => !content.isSelected);
			this.messageService.publish(Events.savingPreloader, 0);
			this.atLeastOneContentSelected = false;
		});
	}

	private content(): Content {
		let content: Content = new Content();

		if (this.selectedSubCategory && this.selectedSubCategory.Id) {
			content.CategoryId = this.selectedSubCategory.Id;
		} else {
			content.CategoryId = this.selectedParentCategory.Id;
		}
		//Clients cannot change libraries
		if (this.selectedLibrary?.Id) {
			content.LibraryId = this.selectedLibrary.Id;
		}
		content.UpdateDate = this.dateTimeService.todayWithCurrentTime();
		content.UpdatedByUserId = this.appStateService.currentUser.UserId;
		return content;
	}

	private getLibrariesAndCategories(): void {
		//if user is employee, can move content between libraries
		if (this.appStateService.currentUser.IsEmployee && this.libraries.length < 1) {
			this.getLibraries();
			//if user not employee, can only move content between categories within current library
		} else if (!this.parentCategories || this.parentCategories?.length === 0) {
			this.getCategoriesByLibraryId(this.cvStateService.contentList[0].LibraryId);
		}
	}

	private getLibraries(): void {
		this.httpClient
			.get(
				`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${this.appStateService.product.Id}&clientId=${this.appStateService.currentClient.Id}&userId=${this.appStateService.currentUser.UserId}`
			)
			.subscribe((libraries: LibrariesTree[]) => {
				this.libraries = this.utilService
					.sortItems(this.securityService.filterLibraries(libraries, this.appStateService.activeFeatureArea), 'LibraryName')
					.map((library) => {
						library.name = library.LibraryName;
						return library;
					});
			});
	}

	private getCategoriesByLibraryId(libraryId: number): void {
		this.httpClient.get(environment.contentUrl + 'ContentLibraries/' + libraryId + '/Categories').subscribe((categories: CategoriesTree[]) => {
			let parentCategories: CategoriesTree[] = categories
				.filter((category) => {
					return !category.ParentCategoryId && !category.IsDeleted;
				})
				.map((filteredCategory) => {
					filteredCategory.name = filteredCategory.CategoryName;
					return filteredCategory;
				});
			this.parentCategories = this.utilService.sortItems(parentCategories, 'name');

			this.subCategories = categories
				.filter((category) => {
					if (category.ParentCategory) {
						return category.ParentCategory.Id === category.ParentCategoryId;
					}
				})
				.filter((subCat) => !subCat.IsDeleted)
				.map((filteredCategory) => {
					filteredCategory.name = filteredCategory.CategoryName;
					return filteredCategory;
				});
			if (this.subCategories && this.subCategories.length > 0) {
				this.subCategories = this.utilService.sortItems(this.subCategories, 'name');
			}
		});
	}
}
