import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';

import { AppStateService } from 'src/app/core/services';
import { ClientNewsletter } from 'src/app/shared/api-models/content';
import { ClientNewsletterService } from 'src/app/client-newsletter/client-newsletter.service';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

@Component({
	selector: 'mp-popup',
	templateUrl: './popup.component.html',
	styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
	@Input() newsletter: ClientNewsletter;
	@Output() onExitClick: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('popup') popup: ElementRef;

	public view: 'read' | 'edit' = 'read';
	readonly enumUserRole = UserRole;

	constructor(public appStateService: AppStateService, private clientNewsletterService: ClientNewsletterService) {}

	ngOnInit(): void {}

	public onPopupChange(value: string, newsletter: ClientNewsletter): void {
		newsletter.ProductsPagePopupHtml = value;
	}

	public onEditContentClick(): void {
		this.view = this.view === 'edit' ? 'read' : 'edit';
		if (this.view === 'edit') {
			setTimeout(() => {
				this.popup?.nativeElement.focus();
			}, 10);
		} else {
			const updatedNewsletter = { ...this.newsletter };
			this.clientNewsletterService.updateNewsletter(updatedNewsletter);
		}
	}

	public onEnableOrDisablePopupClick(): void {
		const isActive = this.newsletter.ProductsPagePopupActive;
		if (!isActive) {
			const isOk = confirm('Active the popup for customers?');
			if (isOk) {
				const updatedNewsletter = { ...this.newsletter, ProductsPagePopupActive: true };
				this.clientNewsletterService.updateNewsletter(updatedNewsletter);
			}
		} else {
			const updatedNewsletter = { ...this.newsletter, ProductsPagePopupActive: false };
			this.clientNewsletterService.updateNewsletter(updatedNewsletter);
		}
	}
}
