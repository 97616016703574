import { CsrTasks } from '../../../shared/api-models/admin';

export class CsrTasksView extends CsrTasks {
    editBtn: string;
    completeBtn: string;
    deleteBtn: string;
    dueDateView: string;
    completeDateView: string;
    classList: string;
    taskType: string;
    clientName: string;
}