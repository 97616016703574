// export const tippyConfig = (params): ITippyConfig => {
// 	const { placement = 'right' } = params;
// 	return { allowHTML: true, interactive: true, placement, appendTo: 'parent', arrow: true, theme: 'dark' };
// };

// export interface ITippyConfig {
// 	allowHTML: boolean;
// 	interactive: boolean;
// 	placement: 'top' | 'right' | 'bottom' | 'left';
// 	appendTo: string;
// 	arrow: boolean;
// 	theme: string;
// }

export const holdPlaylistLinks = [
	{
		title: 'Creating an On Hold Playlist',
		url: 'https://help.works24.com/knowledge-base/tutorial-creating-an-on-hold-playlist/'
	},
	{
		title: 'Using Instant Playlist',
		url: 'https://help.works24.com/knowledge-base/tutorial-using-instant-playlist-for-on-hold/'
	}
];

export const videoPlaylistLinks = [
	{
		title: 'Creating a Video Playlist',
		url: 'https://help.works24.com/knowledge-base/tutorial-creating-a-video-playlist/'
	},
	{
		title: 'Using Instant Playlist',
		url: 'https://help.works24.com/knowledge-base/tutorial-using-instant-playlist-for-video/'
	}
];

export const videoContentLinks = [
	{
		title: 'Creating an Image in Under 5 Minutes',
		url: 'https://help.works24.com/knowledge-base/tutorial-creating-an-image-in-under-5-minutes/'
	},
	{
		title: 'Creating a Video in Under 5 Minutes',
		url: 'https://help.works24.com/knowledge-base/tutorial-creating-a-video-in-under-5-minutes/'
	},
	{
		title: 'Creating a Sidebar Image',
		url: 'https://help.works24.com/knowledge-base/tutorial-creating-a-sidebar-image/'
	},
	{
		title: 'Using a Create24 Template',
		url: 'https://help.works24.com/knowledge-base/tutorial-using-a-create24-template/'
	},
	{
		title: 'Creating a Countdown',
		url: 'https://help.works24.com/knowledge-base/tutorial-creating-a-countdown/'
	},
	{
		title: 'Managing Video Content',
		url: 'https://help.works24.com/knowledge-base/tutorial-managing-video-content/'
	},
	{
		title: 'Uploading a Video',
		url: 'https://help.works24.com/knowledge-base/tutorial-uploading-a-video/'
	}
];

export const holdContentLinks = [
	{
		title: 'Requesting Custom Content',
		url: 'https://help.works24.com/knowledge-base/tutorial-requesting-custom-content/'
	},
	{
		title: 'Managing On Hold Content',
		url: 'https://help.works24.com/knowledge-base/tutorial-managing-on-hold-content/'
	},
	{
		title: 'Uploading an On Hold Message',
		url: 'https://help.works24.com/knowledge-base/tutorial-uploading-an-on-hold-message/'
	}
];

export const videoDisplayLinks = [
	{
		title: 'Applying a Screen Layout',
		url: 'https://help.works24.com/knowledge-base/tutorial-applying-a-screen-layout/'
	},
	{
		title: 'Displaying a Sidebar Image',
		url: 'https://help.works24.com/knowledge-base/displaying-a-sidebar-image/'
	},
	{
		title: 'Creating a Custom Ticker Message',
		url: 'https://help.works24.com/knowledge-base/tutorial-creating-a-custom-ticker-message/'
	},
	{
		title: 'Creating a Stock Ticker Feed',
		url: 'https://help.works24.com/knowledge-base/tutorial-creating-a-stock-ticker-feed/'
	},
	{
		title: 'Adding a Ticker to Your Screen',
		url: 'https://help.works24.com/knowledge-base/tutorial-adding-a-ticker-to-your-screen/'
	}
];

export const holdPlaylistBuilderLinks = [
	{
		title: 'Creating an On Hold Playlist',
		url: 'https://help.works24.com/knowledge-base/tutorial-creating-an-on-hold-playlist/'
	},
	{
		title: 'Using Instant Playlist',
		url: 'https://help.works24.com/knowledge-base/tutorial-using-instant-playlist-for-on-hold/'
	},
	{
		title: 'Getting a VOIP email',
		url: 'https://help.works24.com/knowledge-base/tutorial-voip-email/'
	}
];

export const videoPlaylistBuilderLinks = [
	{
		title: 'Creating a Video Playlist',
		url: 'https://help.works24.com/knowledge-base/tutorial-creating-a-video-playlist/'
	},
	{
		title: 'Using Instant Playlist',
		url: 'https://help.works24.com/knowledge-base/tutorial-using-instant-playlist-for-video/'
	}
];

export const holdCMButtonPlaylistBuilder = [
	{
		title: 'Managing On Hold Content',
		url: 'https://help.works24.com/knowledge-base/tutorial-managing-on-hold-content/'
	}
];

export const c24NewVideoLinks = [
	{
		title: 'Creating a Video',
		url: 'https://help.works24.com/knowledge-base/tutorial-creating-a-video-in-under-5-minutes/'
	},
	{
		title: 'Using a Create24 Template',
		url: 'https://help.works24.com/knowledge-base/tutorial-using-a-create24-template/'
	}
];

export const c24NewImageLinks = [
	{
		title: 'Creating an Image',
		url: 'https://help.works24.com/knowledge-base/tutorial-creating-an-image-in-under-5-minutes/'
	},
	{
		title: 'Using a Create24 Template',
		url: 'https://help.works24.com/knowledge-base/tutorial-using-a-create24-template/'
	}
];

export const c24NewSidebarLinks = [
	{
		title: 'Creating a Sidebar',
		url: 'https://help.works24.com/knowledge-base/tutorial-creating-a-sidebar-image/'
	}
];

export const c24CountdownLinks = [
	{
		title: 'Creating a Countdown',
		url: 'https://help.works24.com/knowledge-base/tutorial-creating-a-countdown/'
	}
];
