<div *ngIf="layer"  style="border-bottom: 1px solid #bbc0c3;" (window:mouseup)="autoCloseDropdown($event)" (click)="onLayerSelect()" class="row {{layersService.activeLayer?.id === layer.id ? 'gray-bg' : 'white-bg'}} {{layer.id > 0 ? 'draggable' : null}} mgn0 ft-size16 pd-top5 pd-bottom5">
    <div class="col-xs-1 pd-right0 {{layer.id > 0 ? 'draggable' : null}}">
        <i *ngIf="layer.id !== 0" class="fas fa-grip-vertical {{layer.id > 0 ? 'draggable' : null}}"></i>
    </div>
    <div class="col-xs-2 center {{layer.id > 0 ? 'draggable' : null}}">
        <i [ngStyle]="{'color': ltwService.colorStringToHex(layer.timelineObj.fill)}" class="{{layer.icon}} ft-size20 {{layer.id > 0 ? 'draggable' : null}}"></i>
    </div>
    <div class="col-xs-5 pd0 relative {{layer.id > 0 ? 'draggable' : null}}">
        <div *ngIf="layer.nameState === 'read'" class="ft-size14 ellipsis {{layer.id > 0 ? 'draggable' : null}}">{{layer.name}}</div>
        <input *ngIf="layer.nameState === 'edit'" #nameInput style="width: 100%;" (keyup)="onEnter($event)" class="ft-size14 ht22" [(ngModel)]="layer.name">
        <i *ngIf="layer.nameState === 'edit'" (click)="onCheckClick()" class="fas fa-check lime-green-text pointer absolute right10 top7 ft-size10 z"></i>
    </div>
    <div class="col-xs-4 ft-size14">
        <div class="row">
            <div class="col-xs-4 pd0">
                <i class="{{transInIcon()}} {{layer.transitionIn === 'Fade in' ? 'ft-size12' : null}} absolute top5 left0 green-text"></i>
                <i class="{{transOutIcon()}} {{layer.transitionOut === 'Fade out' ? 'ft-size12' : null}} absolute top5 right10 green-text "></i>
            </div>
            <div class="col-xs-3 pd-right0 text-right {{layer.id > 0 ? 'draggable' : null}}">
                <i (click)="showLayerOptions = !showLayerOptions" class="fas fa-ellipsis-v noExit pd3 pointer"></i>
        
                <div *ngIf="showLayerOptions" class="absolute noExit top0 text-left right20 wdth162 ft-size14 white-bg shadow z border-radius">
                    <div style="border-bottom: 1px solid #bbc0c3" (click)="onOptionClick('rename')" class="pd15 pointer noExit layer-option">
                        <i class="fas fa-font mgn-right15 noExit"></i>
                        <span class="noExit">Rename</span>
                    </div>
                    <div *ngIf="isEditable()" style="border-bottom: 1px solid #bbc0c3" (click)="onOptionClick('edit')" class="pd15 pointer noExit layer-option">
                        <i class="fas fa-edit mgn-right15 noExit"></i>
                        <span class="noExit">Edit</span>
                    </div>
                    <div *ngIf="layer.id !== 0 && layersService.showDuplicateLayerOption()" style="border-bottom: 1px solid #bbc0c3" (click)="onOptionClick('duplicate')" class="pd15 pointer noExit layer-option">
                        <i class="far fa-clone mgn-right15 noExit"></i>
                        <span class="noExit">Duplicate</span>
                    </div>
                    <div *ngIf="layer.id !== 0" (click)="onOptionClick('delete')" class="pd15 pointer noExit layer-option">
                        <i class="far fa-trash-alt mgn-right15 noExit"></i>
                        <span class="noExit">Delete</span>
                    </div>
                </div>
            </div>
            <div class="col-xs-5 center {{layer.id > 0 ? 'draggable' : null}}">
                <i (click)="onLayerVisibleClick()" class="fas {{layer.visible ? 'fa-eye' : 'fa-eye-slash'}} {{layer.id !== 0 ? 'pointer dark-text' : 'gray-text-darker'}}"></i>
            </div>
        </div>
    </div>
    
</div>

