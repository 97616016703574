<portal-header></portal-header>
<div class="container-fluid">
	<div class="row mgn-bottom15 mgn-top15">
		<div class="col-xs-4 col-md-2">
			<div class="row">
				<div class="col-xs-12 col-lg-9">
					<button [routerLink]="['/my-players/' + appStateService.product.Route]" class="white-bg">
						<i class="fas fa-chevron-left mgn-right12"></i>
						My Players
					</button>
				</div>
			</div>
		</div>
		<div class="col-xs-6 col-xs-offset-2 col-md-offset-4 text-right">
			<i class="mgn-right12 ft-size28 fas fa-history {{ appStateService.product.color }}-text"></i>
			<h1 class="in-block">
				<span *ngIf="appStateService.product.Route !== productRoute.RADIO" class="semi-bold">Player Playlist History</span>
				<span *ngIf="appStateService.product.Route === productRoute.RADIO" class="semi-bold">History Log</span>
				|
				<em>{{ appStateService.product.ProductName }}</em>
			</h1>
		</div>
	</div>

	<div class="{{ appStateService.product.color }}-gradient mgn-bottom45 ht2 border-radius"></div>
</div>

<!--********************
	MODAL
*********************-->
<div *ngIf="pphViewService.modalVisible" class="absolute left0 right0">
	<div class="modal-overlay" (click)="modalVisible = false"></div>
	<div class="row">
		<div
			style="top: -84px; margin-bottom: 60px; z-index: 200"
			class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3 margin mgn-bottom30 absolute left0 right0 slideUp"
		>
			<portal-video-playlist-previewer (onExitClickNotify)="pphViewService.modalVisible = false"></portal-video-playlist-previewer>
		</div>
	</div>
</div>

<div class="container-fluid">
	<div class="row pd-bottom30">
		<div class="col-xs-12">
			<h2 class="semi-bold">
				<i class="{{ appStateService.product.DashIcon }} {{ appStateService.product.color }}-text mgn-right10" aria-hidden="true"></i>
				{{ pphService.player?.PlayerName }}
			</h2>
		</div>
	</div>
	<div *ngIf="pphService.spinnerActive" class="relative top-20 z">
		<portal-spinner [height]="'htpx90'" [width]="'wdthpx90'"></portal-spinner>
	</div>
	<!--****************
		TABLE
	******************-->
	<div [ngClass]="{ 'hidden-xs': appStateService.product.Route !== productRoute.RADIO }" class="relative">
		<portal-table
			[table]="table"
			[config]="tableConfigService.config"
			[data]="appStateService.product.Route === productRoute.RADIO ? pphService.radioHistoryLog : pphService.playlists"
			[page]="pphService.pageNumber"
			[totalItems]="pphService.totalItems"
			(buttonClickNotify)="pphViewService.onTableButtonClick($event)"
			(currentPageNotify)="onPageClick($event)"
		></portal-table>
		<em
			*ngIf="appStateService.product.Route !== productRoute.RADIO && pphService.playlists?.length < 1 && !pphService.spinnerActive"
			class="gray-text-darkest ft-size22"
		>
			No playlists found
		</em>
		<em
			*ngIf="appStateService.product.Route === productRoute.RADIO && pphService.radioHistoryLog?.length < 1 && !pphService.spinnerActive"
			class="gray-text-darkest ft-size22"
		>
			No history log found
		</em>
	</div>

	<!--********************************
 		MOBILE VIEW AND PAGINATION
 	*********************************-->
	<div class="mgn-bottom60 hidden-sm hidden-md hidden-lg">
		<div *ngIf="pphService.spinnerActive" class="relative center-unknown-height-width top200 z">
			<portal-spinner [height]="'htpx90'" [width]="'wdthpx90'"></portal-spinner>
		</div>
		<div *ngFor="let playlist of pphService.playlists">
			<pph-xs-view [playlist]="playlist" [table]="table"></pph-xs-view>
		</div>
	</div>
</div>
