import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { AppStateService, BrowserDetectorService, DurationOptionsViewService, UtilitiesService } from '../core/services';
import { GlobalSettingsService } from './global-settings.service';
import { Players } from '../shared/api-models/admin';

@Component({
	selector: 'app-global-settings',
	templateUrl: './global-settings.component.html',
	styleUrls: ['./global-settings.component.scss']
})
export class GlobalSettingsComponent implements OnInit {
	public clientHasAtLeastOneVoipPlayer: boolean;
	public holdSettingsEnabled: boolean;
	public videoSettingsEnabled: boolean;
	public voipEmailListValid: boolean = false;

	constructor(
		public appStateService: AppStateService,
		public browserDetectorService: BrowserDetectorService,
		public durationOptionsViewService: DurationOptionsViewService,
		private globalSettingsService: GlobalSettingsService,
		public location: Location,
		public utilService: UtilitiesService
	) {}

	ngOnInit(): void {
		this.disableSettings();
		this.globalSettingsService.getAvailableHoldPlayers().subscribe((players: Players[]) => {
			this.clientHasAtLeastOneVoipPlayer = players.some((player) => player.PlayerModelId === 4);
		});
	}

	onDropdownSelect(prop: 'DefaultStillDurationMs' | 'DefaultParagraphPauseMs', duration: { name: string; time: number }): void {
		this.globalSettingsService.updateClientRecord(prop, duration.time);
	}

	onCheckboxClick(prop: 'SendVideoReminders' | 'SendHoldReminders' | 'SendNewReleases'): void {
		this.appStateService.currentClient[prop] = !this.appStateService.currentClient[prop];
		this.globalSettingsService.updateClientRecord(prop, this.appStateService.currentClient[prop]);
	}

	public onVoipEmailChange(value: string): void {
		this.voipEmailListValid = this.globalSettingsService.validateEmailList(value);
	}

	public onSaveVoipEmailsClick(): void {
		this.globalSettingsService.updateClientRecord('AdditionalVoipEmail', this.appStateService.currentClient.AdditionalVoipEmail);
	}

	private disableSettings(): void {
		//Enabled if at least one productTypeId equals 1
		this.holdSettingsEnabled = this.appStateService.contractedProducts.some((productType) => {
			return productType.Id === 1;
		});

		//Enabled if at least one productTypeId is greater than 2
		this.videoSettingsEnabled = this.appStateService.contractedProducts.some((productType) => {
			return productType.Id > 2;
		});
	}
}
