import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ProductRoute } from 'src/app/shared/api-models/admin/product-route.enum';
import { ContentFileState } from 'src/app/shared/api-models/content/content-file-state.enum';
import { environment } from '../../../environments/environment';
import { Events } from '../../core/services/events';
import { MessageService } from '../../core/services/message.service';
import { UtilitiesService } from '../../core/services/utilities.service';
import { ContentFiles, UploadDataCounterpart } from '../../shared/api-models/content';
import { FileUploadStateService } from '../../shared/services/file-upload-state.service';
import { AppStateService } from './app-state.service';

@Injectable({
	providedIn: 'root'
})
export class FileUploadService {
	public subs: Subscription[] = [];
	private cancelSubscription: Subscription = new Subscription();

	constructor(
		private appStateService: AppStateService,
		private fileUploadStateService: FileUploadStateService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private router: Router,
		private utilService: UtilitiesService
	) {
		this.cancelSubscriptionSubscribe();
	}

	cancelSubscriptionSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.abortUpload, () => {
				this.cancelSubscription.unsubscribe();
			})
		);
	}

	public onReplaceInputClick(clipId?: number, key?: string): void {
		let file = this.fileUploadStateService.uploader.queue[0].file.rawFile;
		let resizeMethod = this.appStateService.product.Route !== ProductRoute.HOLD ? 'STRETCH' : null;
		this.fileUploadStateService.setState(file, 'replace');
		this.setActiveUploadSequence();

		if (this.isASupportedFileType()) {
			this.fileUploadStateService.advance(clipId);
			this.replaceExistingFile(resizeMethod, clipId, key);
		} else {
			this.fileUploadStateService.validationError = true;
			setTimeout(() => (this.fileUploadStateService.validationError = false), 4000);
		}
	}

	private replaceExistingFile(resizeMethod?: string, clipId?: number, key?: string): void {
		let formData: FormData = new FormData();

		formData.append('file', this.fileUploadStateService.file);
		this.doProgressRequest(
			this.replaceUrl(
				this.fileUploadStateService.replacementProps.contentFileId,
				this.fileUploadStateService.replacementProps.targetLibraryId,
				resizeMethod,
				clipId
			),
			formData,
			'PUT',
			key
		);
	}

	private setActiveUploadSequence(): void {
		if (this.appStateService.product.Route === ProductRoute.HOLD) {
			this.fileUploadStateService.activeSequence = this.fileUploadStateService.viewState.replaceHoldSequence;
		} else {
			this.fileUploadStateService.activeSequence = this.fileUploadStateService.viewState.replaceVideoSequence;
		}
	}

	public getContentFile(url: string): void {
		this.httpClient.get(url).subscribe((uploadedContentFile: ContentFiles) => {
			uploadedContentFile[0] ? (uploadedContentFile = uploadedContentFile[0]) : null;
			setTimeout(() => {
				if (this.isProcessing(uploadedContentFile)) {
					this.getContentFile(url);
				}
				this.messageService.publish(Events.contentFileStateUpdate, uploadedContentFile);
			}, 4000);
		});
	}

	//attachments are uploaded differently than all other files, 2-step process instead of 1
	public uploadAttachmentFile(uploadData: { Id: number; FriendlyName: string }, url: string, key?: string) {
		let formData: FormData = new FormData();

		formData.append('data', JSON.stringify(uploadData));
		formData.append('file', this.fileUploadStateService.file);
		this.httpClient.post(url, uploadData).subscribe((response: any) => {
			this.doProgressRequest(`${url}/${response.Id}/File/?${uploadData[key]}`, formData, 'POST', key);
		});
	}

	public uploadFileViaGeneralUploadApi(fileToUpload: any, uploadData: any, clipId?: number, key?: string, url?: string): void {
		if (!url) {
			if (!clipId) {
				url = environment.uploadUrl;
			} else {
				url = `${environment.uploadUrl}?${clipId}`;
			}
		}
		this.doProgressRequest(url, this.formData(fileToUpload, uploadData), 'POST', key);
	}

	public uploadCounterpartFile(uploadData: UploadDataCounterpart, clipId?: number): void {
		this.doProgressRequest(this.counterPartUrl(clipId), this.formData(this.fileUploadStateService.file, uploadData), 'POST');
	}

	private counterPartUrl(clipId?: number): string {
		if (clipId) {
			return `${environment.uploadUrl}Counterpart/${this.fileUploadStateService.counterpartProps.targetLibraryId}/?${clipId}`;
		}
		return `${environment.uploadUrl}Counterpart/${this.fileUploadStateService.counterpartProps.targetLibraryId}`;
	}

	public doProgressRequest(url: string, body: any, method: string, key?: string) {
		let postHeaders: HttpHeaders = new HttpHeaders();
		if (sessionStorage.getItem('authToken') && sessionStorage.getItem('authToken') !== 'null') {
			postHeaders = postHeaders.set('Authorization', 'Bearer ' + sessionStorage.getItem('authToken'));
		}

		const req = new HttpRequest(method, url, body, {
			reportProgress: true,
			headers: postHeaders
		});

		this.cancelSubscription = this.httpClient.request(req).subscribe(
			(event: any) => {
				if (event.type === HttpEventType.UploadProgress) {
					let payload = [event, req, key];
					this.messageService.publish(Events.uploadProgress, payload);
				} else if (event.body) {
					this.messageService.publish(Events.uploadComplete, event);
				}
			},
			(err: HttpErrorResponse) => {
				if (err.status === 401) {
					this.router.navigate(['/login']);
					sessionStorage.removeItem('authToken');
					sessionStorage.removeItem('validPin');
				}
			}
		);
	}

	public formData(fileToUpload: any, uploadData?: any): FormData {
		let formData: FormData = new FormData();

		formData.append('data', JSON.stringify(uploadData));
		formData.append('file', fileToUpload);
		return formData;
	}

	private replaceUrl(contentFileId: number, targetLibraryId: number, resizeMethod?: string, clipId?: number): string {
		if (resizeMethod) {
			if (clipId) {
				return `${environment.uploadUrl}Replace/${contentFileId}/${targetLibraryId}/${resizeMethod}/?${clipId}`;
			}
			return `${environment.uploadUrl}Replace/${contentFileId}/${targetLibraryId}/${resizeMethod}`;
		} else {
			return `${environment.uploadUrl}Replace/${contentFileId}/${targetLibraryId}/?${clipId}`;
		}
	}

	/************************************************
      FILE VALIDATION
    ***********************************************/

	public isASupportedFileType(restrictToType?: 'video' | 'image' | null): boolean {
		const fileName: string = this.fileUploadStateService.file.name;
		switch (restrictToType) {
			case 'image':
				return this.utilService.hasExtension(fileName, this.supportedStillExtensions()) && !this.utilService.hasExtension(fileName, ['.pdf']);
			case 'video':
				return this.utilService.hasExtension(fileName, this.supportedVideoExtensions());

			default:
				if (this.appStateService.product?.Route !== ProductRoute.HOLD) {
					//returns true if a supported extension is found
					return this.utilService.hasExtension(fileName, this.supportedStillAndVideoExtensions());

					//selected product is hold
				} else {
					//returns true if a supported extension is found
					return this.utilService.hasExtension(fileName, this.supportedAudioFormats());
				}
		}
	}

	private supportedAudioFormats(): string[] {
		return ['.mp3', '.wav', '.ogg'];
	}

	public supportedStillExtensions(): string[] {
		return ['.jpg', 'jpeg', '.pdf', '.png', '.gif', '.bmp', '.eps', '.ai', '.tif', '.doc', '.docx', '.xsl', '.xslx', '.svg', '.heic', '.psd'];
	}

	private supportedStillAndVideoExtensions(): string[] {
		return [
			'.wav',
			'.wmv',
			'.mp4',
			'.avi',
			'.m4v',
			'.flv',
			'.mpeg',
			'.mpg',
			'.bmp',
			'.gif',
			'.jpeg',
			'.jpg',
			'.png',
			'.pdf',
			'.mov',
			'.eps',
			'.ai',
			'.tif',
			'.doc',
			'.docx',
			'.xsl',
			'.xslx',
			'.svg',
			'.heic',
			'.psd'
		];
	}

	private supportedVideoExtensions(): string[] {
		return ['.wmv', '.mp4', '.avi', '.m4v', '.flv', '.mpeg', '.mpg', '.mov'];
	}

	private isProcessing(uploadedContentFile: ContentFiles): boolean {
		return (
			(uploadedContentFile.ContentFileStateId !== ContentFileState.READY && uploadedContentFile.ContentFileStateId !== 5) ||
			uploadedContentFile.ProcessingStateJson === null
		);
	}
}
