import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { AppStateService, UtilitiesService } from 'src/app/core/services';
import { GroupManagerService } from '../group-manager.service';
import { Groups, PlayersView } from '../_models';

@Component({
	selector: 'gm-drag-drop',
	templateUrl: './drag-drop.component.html',
	styleUrls: ['./drag-drop.component.scss']
})
export class DragDropComponent implements OnInit {
	@ViewChild('newGroupName') newGroupName: ElementRef;

	public groupsSearchResults: Groups[];
	public groupsSearchTerm: string;
	public playersSearchTerm: string;
	public showNewGroup: boolean;

	private playerSearchResults: PlayersView[];

	constructor(public appStateService: AppStateService, public groupManagerService: GroupManagerService, public utilService: UtilitiesService) {}

	ngOnInit(): void {}

	public onGroupSearch(): void {
		//Filter through all players in the groups to find ones that match
		//search term
		this.playerSearchResults = this.groupManagerService.assignedPlayers.filter((player) => {
			if (player.PlayerName.toLocaleLowerCase().indexOf(this.groupsSearchTerm) !== -1) {
				return true;
			}
		});

		//Filter through all groups to find ones that match search term
		this.groupsSearchResults = this.groupManagerService.groups.filter((group) => {
			if (group.Name.toLocaleLowerCase().indexOf(this.groupsSearchTerm) !== -1) {
				return true;
			}
			//Of the players that match the search term,
			//find the ones that match the group id
			for (let player of this.playerSearchResults) {
				if (player.groupId === group.Id) {
					return true;
				}
			}
			return false;
		});
	}

	public onNewGroupClick(): void {
		this.showNewGroup = true;
		setTimeout(() => this.newGroupName.nativeElement.focus(), 10);
	}

	public onNewGroupSaveClick(groupName: string): void {
		this.groupManagerService.createGroup(groupName).subscribe(() => {
			this.showNewGroup = false;
		});
	}
}
