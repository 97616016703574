import { Installers } from './installers';
import { PlayerHardwareTypes } from './player-hardware-types';
import { PlayerModels } from './player-models';
import { PlayerOutputTypes } from './player-output-types';
import { ProductTypes } from './product-types';
import { ServiceLocations } from './service-locations';
import { VoipFormats } from './voip-formats';

export class Players {
	Id: number;
	LocationId: number;
	ClientId: number;
	ProductTypeId: number;
	ProductType: ProductTypes;
	PlayerModelId: number;
	InstallStateId: number;
	InstallDate: any;
	InstallerId: number;
	VoipFormatId: any;
	VoipNotify: any;
	PlayerName: string;
	PlayerNotes: string;
	UnitIdentifier: string;
	PlayerJson: string;
	RemoteAreaCode: string;
	RemotePhone: string;
	IsLongDistance: boolean;
	RemotePassword: string;
	PullsFromPlayerId: number;
	WebPlayerGuid: any;
	HoldPause: number;
	HoldVolume: number;
	OutputType: PlayerOutputTypes;
	OutputTypeId: number;
	MacAddress: any;
	IsExemptFromReminders: boolean;
	RepairStateId: number;
	RepairState: any;
	VoipFileModeId: number;
	HardwareSerialNumber: string;
	ActivationCode: string;
	IsBugLogoCapable: boolean;
	IsLayoutCapable: boolean;
	Hostname: string;
	PlayerHardwareTypeId: number;
	IsCelsius: boolean;
	WindowsKey: string;
	AdditionalVoipEmail: string;
	IsMenuBoard: boolean;
	LastUpdateDateTimeUtc: string;

	PlayerModel: PlayerModels;
	VoipFormat: VoipFormats;
	InstallState: { Id: number; Name: string };
	Location: ServiceLocations;
	Installer: Installers;
	PlayerHardwareType: PlayerHardwareTypes;
	IsTickerCapable: boolean;

	// classList: string;
	// currentPlay24StationId: number;
	// requestedPlay24StationId: number;
	// playerStatus: PlayerStatus;
	// name: string;
	// draggableClass: string;
	// icon: string;
	// groupId: number;
	// bgColor: string;
	// deliveryQueue: DeliveryQueue;
	// stringId: string;
	// editBtn: string;
	// deleteBtn: string;
	// areaCodeAndPhone: string;
	// schedulePlaylistTooltip: string;
}
