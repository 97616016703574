import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'portal-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit {
	@Output() clickedOutside: EventEmitter<void> = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}

	public onOutsideClick(): void {
		this.clickedOutside.emit();
	}
}
