<ng-container *ngIf="saveStateWindowVisible">
	<div style="z-index: 111" class="white-bg border-radius pd60 setWidth75 absolute center-unknown-height-width z">
		<div class="row">
			<div class="col-xs-12">
				<h2 class="semi-bold">Do you want to overwrite this project or create a copy?</h2>
			</div>
		</div>
		<div class="row mgn-top60">
			<div class="col-xs-6">
				<button (click)="onOverwriteOrCopyClick('overwrite')" class="red-bg-light white-text">Overwrite</button>
			</div>
			<div class="col-xs-6">
				<button (click)="onOverwriteOrCopyClick('copy')" class="limeGreen-bg white-text">Create a copy</button>
			</div>
		</div>
	</div>
</ng-container>

<div class="border-radius white-bg pd30 setZ relative">
	<div *ngIf="saveStateWindowVisible" class="modal-overlay border-radius"></div>
	<div class="row">
		<div class="col-sm-12 text-center">
			<span class="ft-size26 dark-text absolute right15 pointer top0" (click)="create24Service.popup = ''"><i class="fa fa-times" aria-hidden="true"></i></span>
			<h1 class="in-block semi-bold">Save To Library</h1>
		</div>
	</div>

	<form (keydown.enter)="$event.preventDefault()" #form="ngForm" novalidate (ngSubmit)="onSaveClick()">
		<div class="row mgn-top56 text-left">
			<div class="col-sm-8 col-sm-offset-2">
				<input
					[ngClass]="{ 'red-border-light': !formTitle.valid && !formTitle.pristine }"
					type="text"
					name="title"
					required
					#formTitle="ngModel"
					placeholder="Please enter a title"
					[(ngModel)]="stateService.c24Content.Title"
				/>
				<small class="red-text-light" [hidden]="formTitle.valid || (formTitle.pristine && !form.submitted)">Title is required.</small>
				<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
			</div>
		</div>
		<div class="row text-left mgn-top25">
			<div class="col-sm-8 col-sm-offset-2">
				<textarea
					type="text"
					name="script"
					#formScript="ngModel"
					rows="5"
					placeholder="Please enter a script"
					[(ngModel)]="stateService.c24Content.Text"
				></textarea>
			</div>
		</div>

		<div *ngIf="exportToLibraryService.saveState !== 'overwrite'" class="row mgn-top30 text-left">
			<div class="col-sm-8 col-sm-offset-2">
				<h3>Select a library for this file:</h3>
				<portal-dropdown-menu
					[backgroundColor]="'gray-bg-dark'"
					[height]="'ht40'"
					[key]="'LibraryName'"
					[options]="exportToLibraryService.libraries"
					[selectedOption]="exportToLibraryService.c24ContentLibrary ? exportToLibraryService.c24ContentLibrary.LibraryName : 'Select a library...'"
					(onOptionSelect)="onLibrarySelect($event)"
				></portal-dropdown-menu>
				<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top25" aria-hidden="true"></i>
			</div>
		</div>
		<div *ngIf="exportToLibraryService.saveState === 'overwrite'" class="row mgn-top30 mgn-bottom15">
			<div class="col-sm-8 col-sm-offset-2">
				<h3>Target Library/Category:</h3>
				<em class="ft-size16">{{ exportToLibraryService.c24ContentLibrary?.LibraryName }} - {{ exportToLibraryService.c24ContentCategory?.CategoryName }}</em>
			</div>
		</div>
		<div *ngIf="exportToLibraryService.saveState !== 'overwrite'" class="row text-left">
			<div class="col-sm-8 col-sm-offset-2">
				<span><em>Your new file will be placed in a category folder called "New Content."</em></span>
				<br />
			</div>
		</div>
		<div *ngIf="exportToLibraryService.isSidebar()" class="row mgn-top30 text-left">
			<div class="col-sm-8 col-sm-offset-2">
				<h3>Publish to a feed (optional):</h3>
				<em *ngIf="exportToLibraryService.saveState === 'overwrite'" class="blue-text semi-bold">
					Please note: Overwriting this project will update the content only. If publishing to a feed, a NEW feed item will be created.
				</em>
				<portal-dropdown-menu
					[backgroundColor]="'gray-bg-dark'"
					[height]="'ht40'"
					[key]="'Name'"
					[options]="exportToLibraryService.feeds"
					[selectedOption]="'Select a feed...'"
					(onOptionSelect)="onFeedSelect($event)"
				></portal-dropdown-menu>
			</div>
		</div>

		<div *ngIf="exportToLibraryService.isSidebar()" class="row mgn-top30 text-left">
			<div class="col-sm-8 col-sm-offset-2">
				<h3>Feed item title (optional, will not display):</h3>
				<input type="text" name="feedItemTitle" placeholder="Please enter a feed item title" [(ngModel)]="createEditFeedItemService.feedItem.title" />
			</div>
		</div>

		<div class="row mgn-top60">
			<div class="col-sm-4 col-sm-offset-2">
				<button class="gray-bg-dark setWidth100" (click)="create24Service.popup = ''" type="button">Cancel</button>
			</div>
			<div class="col-sm-4">
				<button
					[disabled]="!form.valid || !exportToLibraryService.c24ContentLibrary"
					type="submit"
					[ngClass]="!form.valid || !exportToLibraryService.c24ContentLibrary ? 'setOpacityLow' : ''"
					class="blue-bg-darkest white-text"
				>
					Save
					<ng-container [ngSwitch]="true">
						<em *ngSwitchCase="exportToLibraryService.saveState === 'overwrite'">(overwrite)</em>
						<em *ngSwitchCase="exportToLibraryService.saveState === 'copy'">(create a copy)</em>
					</ng-container>
				</button>
			</div>
		</div>
	</form>
</div>
