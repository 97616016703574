import { IMyDateModel } from 'angular-mydatepicker';

export class Model {
	Date: string;
}

export class View {
	date: IMyDateModel;
	time: string;

	constructor(date?, time?) {
		this.date = date;
		this.time = time;
	}
}