import { Observable } from 'rxjs';
import { CanDeactivateFn } from '@angular/router';

export interface CanComponentDeactivate {
	canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

export const canDeactivateGuard: CanDeactivateFn<CanComponentDeactivate> = (component) => {
	return component?.canDeactivate ? component.canDeactivate() : true;
};
