<portal-header></portal-header>

<div class="container mgn-top30">
	<div class="row mgn-bottom30">
		<div class="col-sm-6 pd-left0">
			<h1 class="semi-bold"><i class="fas fa-people-arrows mgn-right15" aria-hidden="true"></i>Client Merge Tool</h1>
        </div>
        
    </div>

    <div class="row green-limeGreen-bg-gradient ht2 border-radius"></div>

    <ng-container *ngIf="!clientMergeToolService.continueClicked">
        <div class="row mgn-top30 center">
            <span class="ft-size30">Move Locations/Data FROM This Client:</span>
        </div>
    
        <ng-container *ngIf="(clients$ | async) as clients">
            <div class="row mgn-top15">
                <div class="col-sm-4 col-sm-offset-4">
                    <portal-dropdown-menu
                                    [backgroundColor]="'gray-bg-dark'"
                                    [height]="'ht40'"
                                    [key]="'Name'"
                                    [options]="clients"
                                    [selectedOption]="'Select a client...'"
                                    (onOptionSelect)="onFromClientSelect($event)">
                    </portal-dropdown-menu>
                </div>
            </div>
    
            <div class="row mgn-top60 center">
                <span class="ft-size30">TO This Client:</span>
            </div>
            <div class="row mgn-top15">
                <div class="col-sm-4 col-sm-offset-4">
                    <portal-dropdown-menu
                                    [backgroundColor]="'gray-bg-dark'"
                                    [height]="'ht40'"
                                    [key]="'Name'"
                                    [options]="clients"
                                    [selectedOption]="'Select a client...'"
                                    (onOptionSelect)="onToClientSelect($event)">
                    </portal-dropdown-menu>
                </div>
            </div>
        </ng-container>
    
        <div class="row mgn-top90 center">
            <div class="col-sm-4 col-sm-offset-4">
                <button (click)="onContinueClick()" [disabled]="(clientMergeToolStore.fromClient$ | async) && (clientMergeToolStore.toClient$ | async) ? '' : 'setOpacityLow'" class="{{(clientMergeToolStore.fromClient$ | async) && (clientMergeToolStore.toClient$ | async) ? '' : 'setOpacityLow'}} green-bg white-text">Continue</button>
            </div>
        </div>
    </ng-container>

    <cmt-merge-data *ngIf="clientMergeToolService.continueClicked"></cmt-merge-data>
</div>


