import { Injectable } from '@angular/core';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { AppStateService, DateTimeService, Events, MessageService, UtilitiesService } from '../../../../core/services';
import { CreateEditTicketService } from '../../create-edit-ticket/_services/create-edit-ticket.service';
import { InstallersView } from '../../_models/installers-view';
import { WorkOrdersView } from '../../create-edit-ticket/_models';
import { WorkOrderPDF } from '../../_models/work-order-pdf';

// declare var pdfMake: any;

@Injectable({
    providedIn: 'root'
})
export class PdfService {

    public logoImage: any;
    public workOrderPDF: WorkOrderPDF;

    constructor(
        private appStateService: AppStateService,
        private ceTicketService: CreateEditTicketService,
        private dateTimeService: DateTimeService,
        private messageService: MessageService,
        private utilService: UtilitiesService) { }


    public createPDF(workOrder: WorkOrdersView, emailOrFax?: 'email' | 'fax', previewInTab?: boolean, assignSentDate?: boolean): any {
        this.workOrderPDF = new WorkOrderPDF();
        this.workOrderPDF.WorkOrder = workOrder;
        this.workOrderPDF.Installer = workOrder.Installer ? workOrder.Installer : new InstallersView();
        if (workOrder.SentDate || assignSentDate) {
            this.workOrderPDF.sentDate = this.dateTimeService.dateUTCtoLocal(workOrder.SentDate);
        } else {
            this.workOrderPDF.sentDate = '(not yet sent)';
        }
        this.workOrderPDF.requestedBy = `${this.appStateService.currentUser.FirstName} ${this.appStateService.currentUser.LastName}, Works24`;
        this.workOrderPDF.productType = workOrder.productTypeName;
        this.workOrderPDF.specialInstructions = workOrder.Notes;

        this.readImage('/assets/works24-logo_copy.jpg', (base64) => {

            var docDefinition = {
                pageMargins: 30,
                content: [
                    {
                        image: base64
                    },
                    //Product type heading
                    {
                        text: `${this.ceTicketService.ticket.ticketTypeName} Service Request`,
                        fontSize: 22,
                        alignment: 'center',
                        margin: [0, 10, 0, 0]
                    },
                    //Sent/requested by
                    {
                        text: `Sent: ${this.workOrderPDF.sentDate}, Requested By: ${this.workOrderPDF.requestedBy}`,
                        fontSize: 12,
                        alignment: 'center',
                        margin: [0, 5, 0, 0],
                        italics: true,
                        bold: true,
                        color: '#D91E18'
                    },
                    //Install Location Heading
                    this.sectionHeading(`${this.repairOrInstall()} LOCATION`),
                    //Company Name
                    {
                        columns: [
                            this.formLabel('Company:'),
                            this.formValue(this.workOrderPDF.WorkOrder.CompanyName ? this.workOrderPDF.WorkOrder.CompanyName : '', true)
                        ],
                        margin: [0, 10, 0, 0]
                    },
                    //Contact
                    {
                        columns: [
                            this.formLabel('Contact:'),
                            this.formValue(this.workOrderPDF.WorkOrder.InstallContactName ? this.workOrderPDF.WorkOrder.InstallContactName : '', true)
                        ],
                        margin: [0, 6, 0, 0]
                    },
                    //Phone System
                    this.phoneSystem(),

                    //Remote/Unit Number
                    this.remoteUnitNumber(),
                    //Address
                    {
                        columns: [
                            this.formLabel('Address:'),
                            this.formValue(this.workOrderPDF.WorkOrder.Address ? this.workOrderPDF.WorkOrder.Address : '')
                        ],
                        margin: [0, 6, 0, 0]
                    },
                    //City/State/Zip
                    {
                        columns: [
                            this.formLabel('City/State/Zip:'),
                            this.formValue(`${this.workOrderPDF.WorkOrder.City}, ${this.workOrderPDF.WorkOrder.State} ${this.workOrderPDF.WorkOrder.Zip}`)
                        ],
                        margin: [0, 6, 0, 0]
                    },
                    //Phone
                    {
                        columns: [
                            this.formLabel('Phone:'),
                            this.formValue(this.workOrderPDF.WorkOrder.PhoneNumber ? this.workOrderPDF.WorkOrder.PhoneNumber : '')
                        ],
                        margin: [0, 6, 0, 0]
                    },
                    //Fax
                    {
                        columns: [
                            this.formLabel('Fax:'),
                            this.formValue(this.workOrderPDF.WorkOrder.FaxNumber ? this.workOrderPDF.WorkOrder.FaxNumber : '')
                        ],
                        margin: [0, 6, 0, 0]
                    },
                    this.lineSeparator(),

                    //Install Instructions Heading
                    this.sectionHeading(`${this.repairOrInstall()} INSTRUCTIONS`),

                    //Install Instructions List
                    {
                        text: [
                            // '- Please contact ',
                            // {
                            //     text: 'Sharon Mosier, Kandi Ketch, Morgan Boyce ',
                            //     bold: true
                            // },
                            // 'or ',
                            // {
                            //     text: 'Leah Hadley ',
                            //     bold: true
                            // },
                            // 'at ',
                            // {
                            //     text: '844-967-5724 ',
                            //     bold: true
                            // },
                            `- Please contact Works24 Tech Support (contact info below) with your scheduled ${this.repairOrInstall() === 'REPAIR' ? 'repair' : 'install'} date `,
                            {
                                text: 'prior to going to the location.',
                                bold: true,
                                italics: true
                            },
                            ' Confirm the necessary tools/equipment you will need to take on-site. (See below)',
                        ],
                        fontSize: 10,
                        margin: [0, 10, 0, 0]
                    },
                    this.text(`- Special Instructions: ${this.workOrderPDF.WorkOrder.Notes ? this.workOrderPDF.WorkOrder.Notes : 'N/A'}`),
                    this.onceOnSiteText(),
                    this.phoneSystemText(),
                    this.remoteUnitNumberText(),
                    {
                        text: [
                            `- After testing and confirmation, you will be given a Confirmation Number to attach to your invoice.`
                        ],
                        fontSize: 10,
                        margin: [0, 6, 0, 0]
                    },
                    this.videoOnlyText(),
                    this.videoOnlyUl(),
                    this.holdOnlyText(),
                    this.holdOnlyUl(),
                    this.radioOnlyText(),
                    this.radioOnlyUl(),
                    {
                        text: [
                            '- For testing and tech support, please call ',
                            {
                                text: 'Works24 Tech Support ',
                                bold: true
                            }
                        ],
                        fontSize: 10,
                        margin: [0, 6, 0, 0]
                    },
                    this.lineSeparator(),

                    {
                        columns: [
                            //Billing Heading
                            {
                                width: '*',
                                margin: [0, 20, 0, 0],
                                text: [
                                    {
                                        text: 'BILLING \n \n',
                                        fontSize: 14,
                                        bold: true
                                    },
                                    {
                                        text: 'Send invoice with confirmation number to: \n',
                                        fontSize: 10,
                                        bold: true,
                                        margin: [0, 20, 0, 0]
                                    },
                                    this.text('Attn: Ryan Humphrey', 8),
                                    this.text('Works24', 3),
                                    this.text('3508 French Park Dr, Suite 1', 3),
                                    this.text('Edmond, OK 73034', 3),
                                    this.text('Fax - 800-460-9876', 3),
                                ],
                            },
                            {
                                width: '*',
                                margin: [0, 20, 0, 0],
                                text: [
                                    {
                                        text: 'WORKS24 TECH SUPPORT \n \n',
                                        fontSize: 14,
                                        bold: true 
                                    },
                                    this.text('Technicians: Sharon Mosier, Kandi Ketch, Morgan Boyce, Leah Hadley', 8),
                                    this.text('Office Hours: Mon-Fri, 7:30AM - 5PM Central Time \n', 8),
                                    {
                                        text: 'Phone: 844-967-5724',
                                        fontSize: 12,
                                        bold: true,
                                        color: '#D91E18'
                                    },
                                ]
                            }
                        ]
                    },

                    this.lineSeparator(),

                    {
                        text: [
                            `Service Request For: `,
                            {
                                text: `${this.workOrderPDF.Installer.CompanyName ? this.workOrderPDF.Installer.CompanyName : ''}`,
                                bold: true
                            }
                        ],
                        fontSize: 12,
                        margin: [0, 10, 0, 0]
                    },
                    {
                        text: `Phone: ${this.workOrderPDF.Installer.Phone ? this.workOrderPDF.Installer.Phone : ''}`,
                        fontSize: 12,
                        margin: [0, 6, 0, 0]
                    },
                    {
                        text: `Fax: ${this.workOrderPDF.Installer.Fax ? this.workOrderPDF.Installer.Fax : ''}`,
                        fontSize: 12,
                        margin: [0, 6, 0, 0]
                    },
                    {
                        text: `Confirmation Number: ${this.workOrderPDF.WorkOrder.ConfirmationNumber ? this.workOrderPDF.WorkOrder.ConfirmationNumber : ''}`,
                        fontSize: 12,
                        margin: [0, 6, 0, 0]
                    }

                ]
            }
            if (previewInTab) {
                pdfMake.createPdf(docDefinition).open();
            } else {
                let payload = [pdfMake.createPdf(docDefinition), workOrder.Id, emailOrFax];
                this.messageService.publish(Events.onWorkOrderPdfCreate, payload);
            }

        })
    }

    public createTestFaxPdf(): void {
        this.readImage('/assets/works24-logo_copy.jpg', (base64) => {

            var docDefinition = {
                pageMargins: 30,
                content: [
                    {
                        image: base64
                    },
                    //Product type heading
                    {
                        text: `Test Fax`,
                        fontSize: 22,
                        alignment: 'center',
                        margin: [0, 10, 0, 0]
                    }
                ]
            }
            let payload = [pdfMake.createPdf(docDefinition), null, null, true];
            this.messageService.publish(Events.onWorkOrderPdfCreate, payload);
        })
    }

    private sectionHeading(text: string, alignment?: string): any {
        return {
            text: text + '\n',
            fontSize: 14,
            alignment: !alignment ? 'left' : alignment,
            bold: true,
            margin: [0, 20, 0, 0]
        }
    }

    private formLabel(text: string): any {
        return {
            width: '20%',
            text: text,
            fontSize: 10
        }
    }

    private formValue(text: string, isBold?: boolean): any {
        return {
            width: '80%',
            text: text,
            fontSize: 10,
            bold: isBold
        }
    }

    private lineSeparator(): any {
        return { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.2 }], margin: [0, 6, 0, 0] };
    }

    private onceOnSiteText(): any {
        if (!this.utilService.includes(this.workOrderPDF.WorkOrder.ticketTypeName, 'Repair')) {
            return {
                text: '- Locate the on-site point of contact and retrieve any equipment from the client, if applicable. When equipment is installed OR for troubleshooting, please call Works24 Tech Support while still on-site.',
                fontSize: 10,
                margin: [0, 6, 0, 0]
            }
        }
    }

    private phoneSystem(): any {
        if (this.utilService.includes(this.workOrderPDF.WorkOrder.ticketTypeName, 'Hold')) {
            return {
                columns: [
                    this.formLabel('Phone System:'),
                    this.formValue(this.workOrderPDF.WorkOrder.PhoneSystem ? this.workOrderPDF.WorkOrder.PhoneSystem : '')
                ],
                margin: [0, 6, 0, 0]
            }
        }
    }

    private phoneSystemText(): any {
        if (this.utilService.includes(this.workOrderPDF.WorkOrder.ticketTypeName, 'Hold')) {
            return this.text(`- Phone System: ${this.workOrderPDF.WorkOrder.PhoneSystem ? this.workOrderPDF.WorkOrder.PhoneSystem : ''}`);
        }
    }

    private remoteUnitNumberText(): void {
        if (this.utilService.includes(this.workOrderPDF.WorkOrder.ticketTypeName, 'Hold')) {
            return this.text(`- Remote/Unit Number: ${this.workOrderPDF.WorkOrder.holdPlayerRemoteNumber ? this.workOrderPDF.WorkOrder.holdPlayerRemoteNumber : ''}`);
        }
    }

    private remoteUnitNumber(): any {
        if (this.utilService.includes(this.workOrderPDF.WorkOrder.ticketTypeName, 'Hold')) {
            return {
                columns: [
                    this.formLabel('Remote/Unit Number:'),
                    this.formValue(`${this.workOrderPDF.WorkOrder.holdPlayerRemoteNumber ? this.workOrderPDF.WorkOrder.holdPlayerRemoteNumber : ''}`)
                ],
                margin: [0, 6, 0, 0]
            }
        }
    }

    private text(text: string, marginTop?: number, isBold?: boolean): any {
        let setMarginTop = marginTop ? marginTop : 6;
        return {
            text: text + '\n',
            fontSize: 10,
            margin: [0, setMarginTop, 0, 0],
            bold: isBold
        }
    }

    private repairOrInstall(): string {
        if (this.utilService.includes(this.workOrderPDF.WorkOrder.ticketTypeName, 'Install')) {
            return 'INSTALL';
        } else if (this.utilService.includes(this.workOrderPDF.WorkOrder.ticketTypeName, 'Repair')) {
            return 'REPAIR';
        } else {
            return '';
        }
    }

    private holdOnlyText(): any {
        if (this.utilService.includes(this.workOrderPDF.WorkOrder.ticketTypeName, 'Hold')) {
            return {
                text: `- You MUST bring the following equipment for On Hold Service Requests: `,
                fontSize: 10,
                margin: [0, 6, 0, 0]
            }
        }
    }

    private holdOnlyUl(): any {
        if (this.utilService.includes(this.workOrderPDF.WorkOrder.ticketTypeName, 'Hold')) {
            return [
                {
                    text: [
                        {
                            text: '* Laptop to confirm network connectivity (7100/Halo), Phone Butt Set, Cat5 Cable Tester. ',
                            bold: true
                        }
                    ],
                    fontSize: 10,
                    margin: [20, 6, 0, 0]
                }
            ]
        }
    }

    private radioOnlyText(): any {
        if (this.utilService.includes(this.workOrderPDF.WorkOrder.ticketTypeName, 'Music')) {
            return {
                text: `- You MUST bring the following equipment for Overhead Music Service Requests: `,
                fontSize: 10,
                margin: [0, 6, 0, 0]
            }
        }
    }

    private radioOnlyUl(): any {
        if (this.utilService.includes(this.workOrderPDF.WorkOrder.ticketTypeName, 'Music')) {
            return [
                {
                    text: [
                        {
                            text: '* Laptop to confirm network connectivity (Barix/Halo), Cat5 Cable Tester. ',
                            bold: true
                        }
                    ],
                    fontSize: 10,
                    margin: [20, 6, 0, 0]
                }
            ]
        }
    }

    private videoOnlyText(): any {
        if (this.isVideoProduct()) {
            return {
                text: `- You MUST bring the following equipment for Video Service Requests: `,
                fontSize: 10,
                margin: [0, 6, 0, 0]
            }
        }
    }

    private videoOnlyUl(): any {
        if (this.isVideoProduct()) {
            return [
                {
                    text: [
                        {
                            text: '* A tested and functioning USB Keyboard and USB Mouse. ',
                            bold: true
                        },
                        `Our video players will not support PS/2.`,
                    ],
                    fontSize: 10,
                    margin: [20, 6, 0, 0]
                },
                {
                    text: [
                        {
                            text: '* A tested and functioning HDMI Monitor ',
                            bold: true
                        },
                        'with necessary cables, capable of supporting 1280x720 resolution at 60hz. (This typically needs to be a newer-model LCD screen.)'
                    ],
                    fontSize: 10,
                    margin: [20, 6, 0, 0]
                },
                {
                    text: [
                        {
                            text: '* Cable Tester ',
                            bold: true
                        }
                    ],
                    fontSize: 10,
                    margin: [20, 6, 0, 0]
                },
                {
                    text: [
                        {
                            text: '* If you are servicing a Drive-Thru Video system, you wil also need a tested and functioning VGA Monitor ',
                            bold: true
                        },
                        'with necessary cables capable of supporting 640x480 resolution. (If your HDMI monitor has a VGA connection, you can just bring one monitor.)'
                    ],
                    fontSize: 10,
                    margin: [20, 6, 0, 0]
                }
            ]
        }
    }

    private isVideoProduct(): boolean {
        return !this.utilService.includes(this.workOrderPDF.WorkOrder.ticketTypeName, 'Hold') &&
            !this.utilService.includes(this.workOrderPDF.WorkOrder.ticketTypeName, 'Music');
    }


    private readImage(url, callback) {
        var request = new
            XMLHttpRequest(); request.onload = function () {
                var file = new FileReader();
                file.onloadend = function () {
                    callback(file.result);
                }
                file.readAsDataURL(request.response);
            };
        request.open('GET', url);
        request.responseType = 'blob';
        request.send();
    }
}
