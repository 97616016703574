<div class="container-fluid {{invertColors ? 'gray-bg' : 'white-bg'}} shadow border-radius pd-top30 pd-bottom30 pd-left15 pd-right15">
	<div class="row">
		<div class="col-sm-5">
			<div class="row">

				<!--*********************
					LEFT HEADING
				**********************-->
				<div class="{{dragDropLeftRightService.toggleBtnsVisible ? 'col-sm-5' : 'col-sm-7 margin'}}">
					<h3 class="semi-bold">{{leftHeading}}</h3>
				</div>

				<!--*********************
					VIEW TOGGLE BUTTON
				**********************-->
				<div *ngIf="dragDropLeftRightService.toggleBtnsVisible" class="col-sm-3">
					<div class="row margin">
						<div class="col-xs-6 pd-right0">
							<button (mouseup)="dragDropLeftRightService.onToggleClick()" class="toggle-button-left ft-size14 {{dragDropLeftRightService.visibleItems === 'players' ? 'gray-bg-darker' : 'gray-bg'}} setWidth100">
								<span class="hidden-sm hidden-md">Players</span>
								<i class="hidden-xs hidden-lg fas fa-hdd" aria-hidden="true"></i>
							</button>
						</div>
						<div class="col-xs-6 pd-left0">
							<button (mouseup)="dragDropLeftRightService.onToggleClick()" class="toggle-button-right ft-size14 {{dragDropLeftRightService.visibleItems === 'groups' ? 'gray-bg-darker' : 'gray-bg'}} setWidth100">
								<span class="hidden-sm hidden-md">Groups</span>
								<i class="hidden-xs hidden-lg fa fa-tags" aria-hidden="true"></i>
							</button>
						</div>
					</div>
				</div>

				<!--*********************
					LEFT SEARCH FIELD
				**********************-->
				<div class="{{!dragDropLeftRightService.toggleBtnsVisible ? 'col-sm-5' : 'col-sm-4'}} relative">
					<input [(ngModel)]="dragDropLeftRightService.availableItemsSearchTerm" (keyup)="dragDropLeftRightService.onAvailableItemsSearch()" class="{{invertColors ? 'white-bg dark-border' : 'gray-bg-dark'}}" type="text" placeholder="Search">
					<button *ngIf="dragDropLeftRightService.availableItemsSearchTerm?.length" (mouseup)="dragDropLeftRightService.onAvailableItemsSearchClear()" style="width: initial;" class="white-bg border pd-top3 pd-bottom3 pd-left6 pd-right6 absolute right20 top5"><i class="fa fa-times" aria-hidden="true"></i></button>
				</div>
			</div>

			<!--*********************
				AVAILABLE ITEMS
			**********************-->
			<div [ngStyle]="{'height.vh': height}" style="overflow:auto;" class="{{invertColors ? 'white-bg' : 'gray-bg'}} mgn-top15 border-radius">
				<div class="drag-border border-radius gray-border mgn-top15">	
					<div *ngIf="dragDropLeftRightService.percentComplete && dragDropLeftRightService.percentComplete < 100 && dragDropLeftRightService.activeAddRemoveAllBtn === 'removeAll'" style="z-index: 100;" class="disabled-overlay relative">
						<portal-spinner [height]="'htpx76'" [width]="'wdthpx76'"></portal-spinner>
					</div>				
					<div dragula="item-bag" [(dragulaModel)]='dragDropLeftRightService.availableItemsView' class="setHeight100 relative z">
						<div *ngFor="let item of dragDropLeftRightService.availableItemsView">
							<ddlr-item
									[item]="item"
									[showDuration]="showDuration">
							</ddlr-item>
						</div>
					</div>									
				</div>
			</div>

			<!--*************************
				AVAILABLE ITEMS COUNT
			**************************-->
			<div class="row mgn-top10">
				<div class="col-xs-6">
					<h4  class="mgn-top8"><em>Available Items: {{dragDropLeftRightService.availableItems.length}}</em></h4>
				</div>
			</div>
		</div>

		<!--***************************
			ADD/REMOVE ALL BUTTONS
		****************************-->
		<div class="col-sm-2 text-center mgn-top55">
			<button *ngIf="!hideAddRemoveAllBtns" (click)="dragDropLeftRightService.onAddAllClick()" [disabled]="addButtonDisabled()" class="{{addButtonDisabled() ? 'setOpacityLow' : null}} limeGreen-bg ft-size13 setWidth75 white-text border-radius" >
				<span class="hidden-sm hidden-md">Add All</span>
				<i class="hidden-xs hidden-sm hidden-md fa fa-angle-right mgn-left8" aria-hidden="true"></i>
				<span class="hidden-xs hidden-lg"><i class="fa fa-plus" aria-hidden="true"></i></span>
			</button><br>
			<i class="fas fa-arrows-alt-h ft-size36 mgn-top12" aria-hidden="true"></i><br>
			<button *ngIf="!hideAddRemoveAllBtns" (click)="dragDropLeftRightService.onRemoveAllClick()" [disabled]="removeButtonDisabled()" class="{{removeButtonDisabled() ? 'setOpacityLow' : null}} dark-bg ft-size13 setWidth75 white-text border-radius mgn-top12" >
				<i class="hidden-xs hidden-sm hidden-md fa fa-angle-left mgn-right8" aria-hidden="true"></i>
				<span class="hidden-sm hidden-md">Remove All</span>
				<span class="hidden-xs hidden-lg"><i class="fa fa-minus" aria-hidden="true"></i></span>
			</button>
			<div *ngIf="dragDropLeftRightService.percentComplete" class="mgn-top30 ft-size22">
				<span>{{dragDropLeftRightService.percentComplete}}%</span>
			</div>
		</div>
		<div class="col-sm-5">
			<div class="row">

				<!--***********************
					RIGHT HEADING
				************************-->
				<div class="col-sm-7 margin">
					<h3 class="semi-bold">{{rightHeading}}</h3>
				</div>

				<!--***********************
					RIGHT SEARCH FIELD
				************************-->
				<div class="col-sm-5">
					<input [(ngModel)]="dragDropLeftRightService.assignedItemsSearchTerm" (keyup)="dragDropLeftRightService.onAssignedItemsSearch()" class="{{invertColors ? 'white-bg dark-border' : 'gray-bg-dark'}}" type="text" placeholder="Search">
					<button *ngIf="dragDropLeftRightService.assignedItemsSearchTerm?.length" (mouseup)="dragDropLeftRightService.onAssignedItemsSearchClear()" style="width: initial;" class="white-bg border pd-top3 pd-bottom3 pd-left6 pd-right6 absolute right20 top5"><i class="fa fa-times" aria-hidden="true"></i></button>
				</div>
			</div>

			<!--***********************
				ASSIGNED ITEMS
			************************-->
			<div [ngStyle]="{'height.vh': height}" style="overflow:auto;" class="{{invertColors ? 'white-bg' : 'gray-bg'}} mgn-top15 border-radius">
				<div [ngStyle]="{'height.vh': height-2}" class="drag-border border-radius gray-border mgn-top15">

					<div *ngIf="dragDropLeftRightService.percentComplete && dragDropLeftRightService.percentComplete < 100 && dragDropLeftRightService.activeAddRemoveAllBtn === 'addAll'" style="z-index: 100;" class="disabled-overlay relative">
						<portal-spinner [height]="'htpx76'" [width]="'wdthpx76'"></portal-spinner>
					</div>					
					<div dragula="item-bag" [(dragulaModel)]='dragDropLeftRightService.assignedItemsView' class="setHeight100 relative z">
						<div *ngFor="let item of dragDropLeftRightService.assignedItemsView" class="">
							<ddlr-item
									[dragDisabled]="true"
									[includeCheckbox]="dragDropLeftRightService.includeAssignedItemsCheckbox"
									[isAssigned]="true"
									[item]="item"
									[showDuration]="showDuration"
									(checkboxClickNotify)="dragDropLeftRightService.onCheckboxClick($event)"
									(removeItemClickNotify)="onRemoveItemClick($event)">
							</ddlr-item>
						</div>
					</div>									
				</div>
			</div>

			<!--***************************
				ASSIGNED ITEMS COUNT
			****************************-->
			<div class="row mgn-top10">
				<div class="col-xs-6">
					<h4  class="mgn-top8"><em>Assigned Items: {{dragDropLeftRightService.assignedItems.length}}</em></h4>
				</div>
			</div>
		</div>
	</div>
</div>
