import { Injectable } from '@angular/core';

import { CreateEditClientService } from './create-edit-client.service';

@Injectable({
    providedIn: 'root'
})
export class FormValidationService {

    public allContractsValid: boolean;
    public formValid: boolean;


    constructor(
        private createEditClientService: CreateEditClientService
    ) { }

    allContractsValidCheck(obj: { tempId: number, isFormValid: boolean }): void {
        //find contract user is working on
        let contract = this.createEditClientService.contracts.filter(contract => contract.tempId === obj.tempId);
        contract[0]['isValid'] = obj.isFormValid;
        //are all required fields for every contract completed?
        this.allContractsValid = this.createEditClientService.contracts.every(contract => contract.isValid);
    }
}
