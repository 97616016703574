export class PlaylistsExamples {
    ClientId: number;
    CreateDate: string;
    CreatedByUserId: number;
    Id: number;
    LibraryId: number;
    Name: string;
    ParagraphPause: number;
    ProductId: number;
    UpdateDate: string;
    UpdatedByUserId: number;
    SecretId: number;
    IsDeleted: boolean;
    IsFeatured: boolean;
    IsGeneric: boolean;
    state: 'edit' | 'read';

    contentFiles?: any;

    constructor() {
        this.state = 'read';
    }
}