export class ContentLibraries {
	ContentType?: any;
	ContentTypeId: number;
	CreateDate: string;
	CreatedByUserId?: number;
	DefaultDirectory: string;
	Id: number;
	IsDeleted: boolean;
	IsGlobal: boolean;
	LibraryName: string;
	ProductTypeId: number;
	UpdateDate: string;
	UpdatedByUserId?: number;
	ContentLibraryTypeId: number;
	AllowContentRequest: boolean;
	AllowCreate24: boolean;
	HideFromPortal: boolean;
	HideFromWebPlayer: boolean;

	ReadAccess: boolean;
	WriteAccess: boolean;
	LibraryId: number;
	ParentCategoryId: number;
	CategoryName: string;

	name: string;
	isSelected: boolean;
}
