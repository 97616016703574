import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Clients, Contracts, ServiceLocations, Users } from 'src/app/shared/api-models/admin';
import { ContentLibraries } from 'src/app/shared/api-models/content';

@Injectable({
    providedIn: 'root'
})
export class ClientMergeToolService {

    public continueClicked: boolean = false;

    constructor(
        private httpClient: HttpClient
    ) { }

    public getClients$(): Observable<Clients[]> {
        return this.httpClient.get<Clients[]>(`${environment.adminUrl}CoreClients`);
    }

    public getLocations$(clientId: number): Observable<ServiceLocations[]> {
        return this.httpClient.get<ServiceLocations[]>(`${environment.adminUrl}CoreServiceLocations/Client/${clientId}`);
    }

    public getUsers$(clientId: number): Observable<Users[]> {
        return this.httpClient.get<Users[]>(`${environment.adminUrl}CoreUsers/GetUsersByClientId/${clientId}`);
    }

    public getLibraries$(clientId: number): Observable<ContentLibraries[]> {
        return this.httpClient.get<ContentLibraries[]>(`${environment.contentUrl}ContentLibraries/Client/${clientId}`);
    }

    public getContracts$(clientId: number): Observable<Contracts[]> {
        return this.httpClient.get<Contracts[]>(`${environment.adminUrl}CoreContracts/ContractsForClient/${clientId}`);
    }

    public mergeLocations$(fromClientId: number, toClientId: number, ids: number[]): Observable<any> {
        return this.httpClient.post(`${environment.adminUrl}ClientMerge/Location/${fromClientId}/${toClientId}`, ids);
    }

    public mergeUsers$(fromClientId: number, toClientId: number, ids: number[]): Observable<any> {
        return this.httpClient.post(`${environment.adminUrl}ClientMerge/User/${fromClientId}/${toClientId}`, ids);
    }

    public mergeLibraries$(fromClientId: number, toClientId: number, ids: number[]): Observable<any> {
        return this.httpClient.post(`${environment.adminUrl}ClientMerge/Library/${fromClientId}/${toClientId}`, ids);
    }

    public mergeContracts$(fromClientId: number, toClientId: number, ids: number[]): Observable<any> {
        return this.httpClient.post(`${environment.adminUrl}ClientMerge/Contract/${fromClientId}/${toClientId}`, ids);
    }

    public contractTypeToProduct(contractTypeId: number): string {
        switch (contractTypeId) {
            case 1: return 'On Hold Messaging';
            case 2: return 'Video/Digital Signage';
            case 3: return 'Works24 Radio';
        }
    }

    public contractTerm(contractTermId: number): string {
        switch (contractTermId) {
            case 1: return '1 yr';
            case 2: return '2 yr';
            case 3: return '3 yr';
            case 4: return '4 yr';
            case 5: return '5 yr';
            case 6: return 'Monthly';
            case 7: return 'Comp/Free';
            case 8: return 'Trial Period';
            case 9: return 'Other - See notes';
        }
    }
}
