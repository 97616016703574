import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

@Component({
    selector: 'portal-hamburger',
    templateUrl: './hamburger.component.html',
    styleUrls: ['./hamburger.component.scss']
})
export class HamburgerComponent implements OnInit {

    @Input() isChecked: boolean;
    @Input() theme: string;
    @Output() onClickNotify: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void {
    }

    public onClick(): void {
        this.onClickNotify.emit();
    }

}
