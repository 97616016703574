import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, CanActivateFn, ActivatedRouteSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppStateService } from '../app-state.service';
import { environment } from '../../../../environments/environment';
import { UtilitiesService } from '../utilities.service';
import { ContentLibraries } from 'src/app/shared/api-models/content';

export const contractGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
	const appStateService = inject(AppStateService);
	const httpClient = inject(HttpClient);
	const router = inject(Router);
	const utilService = inject(UtilitiesService);

	const activeUrl: string = route.pathFromRoot.map((segment) => segment.url.map((u) => u.path).join('/')).join('/');
	const isPlaylistBuilderRoute: boolean = utilService.includes(activeUrl, 'playlist-builder');
	const selectedProductType = appStateService.contractedProducts.find((productType) => utilService.includes(activeUrl, productType.Route));

	const contractFound = (): boolean => {
		return appStateService.contractedProducts.some((productType) => utilService.includes(activeUrl, productType.Route));
	};

	if (isPlaylistBuilderRoute) {
		return httpClient
			.get<ContentLibraries[]>(
				`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${selectedProductType?.Id}&clientId=${appStateService.currentClient.Id}&userId=${appStateService.currentUser.UserId}`
			)
			.pipe(
				map((libraries: ContentLibraries[]) => {
					if (contractFound() && libraries.length > 0) {
						if (selectedProductType?.Id === 1 && libraries.some((library) => library.LibraryName === 'Music Library')) {
							return true;
						} else if (selectedProductType?.Id === 1) {
							router.navigate(['/playlist-builder-access-denied/no-music-library']);
						}
						return true;
					}

					if (!contractFound()) {
						router.navigate(['/playlist-builder-access-denied/no-contract-found']);
					}

					if (libraries.length < 1) {
						router.navigate(['/playlist-builder-access-denied/no-libraries-found']);
					}

					return false;
				})
			);
	}

	if (contractFound()) {
		return of(true);
	}

	router.navigate(['/my-products']);
	return of(false);
};
