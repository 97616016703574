import { Injectable } from '@angular/core';

import { UtilitiesService } from 'src/app/core/services';
import { GumballCircle } from './gumball-circle.model';

@Injectable({
	providedIn: 'root'
})
export class ClientHealthViewService {
	public darkInfoBoxLeft: number;
	public darkInfoBoxTop: number;
	public hoveredElement;
	public hoveredClientOrPlayer: GumballCircle;
	public persistInfoBox: boolean;
	public selectedCircle: any;

	constructor(private utilService: UtilitiesService) {}

	public onCircleHover(clientOrPlayer: GumballCircle, crmView?: boolean): void {
		this.hoveredClientOrPlayer = clientOrPlayer;
		this.darkInfoBoxTop = this.hoveredElement.getBoundingClientRect().top + 25;

		this.setInfoBoxLeft(crmView);
	}

	public setInfoBoxLeft(crmView?: boolean): void {
		if (crmView) {
			this.darkInfoBoxLeft = this.hoveredElement.getBoundingClientRect().left - +`${this.persistInfoBox ? 355 : 360}`;
		} else {
			this.darkInfoBoxLeft = this.hoveredElement.getBoundingClientRect().left - +`${this.persistInfoBox ? 14 : 9}`;
		}
	}

	public onCircleLeave(): void {
		this.hoveredClientOrPlayer = null;
	}

	public autoCloseDropdown(event) {
		if (!this.utilService.closest(event, '.noExit')) {
			this.onXClick();
		}
	}

	public onXClick(): void {
		this.persistInfoBox = false;
		this.onCircleLeave();
	}

	public onPlayerCircleClick(crmView?: boolean, circle?: GumballCircle): void {
		this.persistInfoBox = true;
		this.setInfoBoxLeft(crmView);
		this.selectedCircle = circle;
	}

	public onPlayerCircleLeave(): void {
		if (!this.persistInfoBox) {
			this.onCircleLeave();
		}
	}
}
