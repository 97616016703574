<div style="border-radius: 6px" class="shadow relative {{ isEven ? 'gray-bg' : 'white-bg' }}">
	<div>
		<div class="title pd12 gray-border relative z">
			<div class="row">
				<div class="col-xs-12">
					<h2 class="semi-bold">{{ historyNote.LogSubject }}</h2>
				</div>
			</div>
		</div>

		<div class="row mgn-top30 pd15">
			<div class="col-xs-6">
				<span class="semi-bold">Date:</span>
				<br />
				<span class="ft-size18">{{ historyNote.LogDate | date : 'M/d/yyyy' }}</span>
				,
				<em class="ft-size18">{{ historyNote.LogDate | date : 'h:mm a' }}</em>
			</div>

			<div class="col-xs-6">
				<span class="semi-bold">Author:</span>
				<br />
				<span class="ft-size18">{{ historyNote.UserName }}</span>
			</div>
		</div>

		<div class="row mgn-top30 pd15">
			<div class="col-xs-6 text-left setOverflow">
				<span class="semi-bold">Type:</span>
				<br />
				<span class="ft-size18">{{ historyNote.EntryType }}</span>
			</div>
			<div class="col-xs-6">
				<span class="semi-bold">Device:</span>
				<br />
				<span class="ft-size18">{{ historyNote.EntrySource }}</span>
			</div>
		</div>

		<div class="row mgn-top15 pd15">
			<div class="col-xs-6">
				<span class="semi-bold">Id:</span>
				<br />
				<span class="ft-size18">{{ historyNote.Id }}</span>
			</div>
			<div class="col-xs-6">
				<button (click)="onTableButtonClick()" class="red-bg-light white-text">Delete</button>
			</div>
		</div>
	</div>
</div>
