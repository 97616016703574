<div class="row mgn-bottom15">
	<div class="col-sm-2 ht36">
		<portal-dropdown-menu
			[backgroundColor]="'gray-bg-dark'"
			[height]="'ht40'"
			[key]="'ProductName'"
			[options]="productTypes$ | async"
			[scrollbarHidden]="true"
			[selectedOption]="'Select a product...'"
			(onOptionSelect)="onDropdownSelect($event)"
		></portal-dropdown-menu>
	</div>
	<div class="col-sm-2">
		<button
			[disabled]="!selectedProductType"
			type="button"
			class="{{ !selectedProductType ? 'setOpacityLow' : null }} border-radius limeGreen-bg border white-text setWidth100"
			(click)="onCreateNewContractClick()"
		>
			<i class="fa fa-plus mgn-right15" aria-hidden="true"></i>
			Create new contract
		</button>
	</div>
</div>

<div *ngFor="let contract of contracts" class="mgn-bottom40 relative">
	<c-contract [contract]="contract" (contractDeleteNotify)="onContractDelete($event)" (onCreateOrUpdateNotify)="getContractsForClient()"></c-contract>
	<a class="absolute right0 bottomneg30" (click)="utilService.scrollToTop()">
		Scroll to top
		<i class="fa fa-angle-up mgn-left4" aria-hidden="true"></i>
	</a>
</div>

<div class="mgn-bottom60"></div>
