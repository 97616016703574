import { ContentAdvancedSchedules } from './content-advanced-schedules';
import { FeedStockSymbol } from './feed-stock-symbol';

export class FeedItems extends FeedStockSymbol {
	Id: number;
	FeedId: number;
	PublisherId: number;
	ItemContent: string;
	CreateDate: string;
	StartDate: string;
	StopDate: string;
	AdvancedScheduleId: number;
	IsEnabled: boolean;
	EnabledBy: number;
	EnabledDate: string;
	ClientNotified: boolean;
	IsNew: boolean;
	ItemHash: string;
	AdvancedSchedule: ContentAdvancedSchedules;
	Month: number;
	Day: number;
	SortOrder: number;

	previewUrl: string;
	contentFileStateId: number;
	contentFileId: number;
	classList: string;
	itemText: string;
	editBtn: string;
	deleteBtn: string;
	title: string;
	body: string;
	sourceId: number;
	showBody: boolean;
	showImage: boolean;
	advancedSchedule: ContentAdvancedSchedules;
	startDate: string;
	stopDate: string;
	createDate: string;
	isActive: boolean;
	widgetDuration: number;
	widgetDurationView: string;
	startDateTooltip: string;
}
