import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { EmployeeTicketCount, TicketsTable } from './_models';
import { Events, MessageService, UtilitiesService } from '../../core/services';
import { ColorKeyService, TableConfigService, TicketSystemService, ViewStateService } from './_services';
import { SortParams } from 'src/app/shared/view-models/common-types';
import { PageTitle } from './_models/tickets.enum';

@Component({
	selector: 'app-ticket-system',
	templateUrl: './ticket-system.component.html',
	styleUrls: ['./ticket-system.component.scss']
})
export class TicketSystemComponent implements OnInit, OnDestroy {
	public searchTermChanged: Subject<string> = new Subject<string>();
	readonly pageTitle = PageTitle;

	private subs: Subscription[] = [];

	constructor(
		public colorKeyService: ColorKeyService,
		private messageService: MessageService,
		private router: Router,
		public tableConfigService: TableConfigService,
		public ticketSystemService: TicketSystemService,
		private utilService: UtilitiesService,
		public viewStateService: ViewStateService
	) {
		this.searchTermChanged.pipe(debounceTime(400)).subscribe(() => {
			this.viewStateService.pageNumber = 1;
			this.getTableData();
		});
		this.onColumnSortSubscribe();
	}

	ngOnInit(): void {
		this.viewStateService.table = this.tableConfigService.nonPostponedViewTable;

		if (this.viewStateService.pageTitle !== PageTitle.MANAGE_INSTALLERS) {
			this.ticketSystemService.getEmployeeTicketCount$().subscribe(() => {
				this.getTableData(true);
				this.getTableData();
			});
		} else {
			this.getTableData();
		}
		this.ticketSystemService.getOverdueTicketCount();
	}

	onColumnSortSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.onColumnSort, (sortParams: SortParams) => {
				this.viewStateService.orderByCol = sortParams.key;
				sortParams.order === 'asc' ? (this.viewStateService.orderDesc = false) : (this.viewStateService.orderDesc = true);
				this.getTableData();
			})
		);
	}

	getPage(page: number): void {
		this.utilService.scrollToTop();
		setTimeout(() => {
			this.viewStateService.pageNumber = page;
			this.getTableData();
		}, 400);
	}

	onRowSelect(ticket: TicketsTable): void {
		this.viewStateService.pageTitle === PageTitle.MANAGE_INSTALLERS
			? this.router.navigate([`/ticket-system/edit-installer/${ticket.Id}`])
			: this.router.navigate([`/ticket-system/edit-ticket/${ticket.Id}`]);
	}

	onTakeClick(btnEvent): void {
		const ticket: TicketsTable = btnEvent[1];
		this.ticketSystemService.assignTicket$(ticket).subscribe(() => {
			this.getTableData();
		});
	}

	public onEmployeeTicketCountSelect(employee: EmployeeTicketCount): void {
		this.viewStateService.selectedEmployee = employee;
		this.viewStateService.pageTitle = `Tickets - ${employee.displayName}`;
		this.getTableData();
	}

	public onNewClick(route: string): void {
		this.router.navigate([route]);
	}

	public onSearchClear(): void {
		this.viewStateService.searchTerm = '';
		this.getTableData();
	}

	public onViewPostponedClick(): void {
		this.viewStateService.postponedVisible = !this.viewStateService.postponedVisible;
		this.viewStateService.postponedVisible
			? (this.viewStateService.table = this.tableConfigService.postponedViewTable)
			: (this.viewStateService.table = this.tableConfigService.nonPostponedViewTable);
		this.getTableData();
	}

	public onNavItemClick(pageTitle: PageTitle): void {
		this.viewStateService.searchTerm = '';
		const getUnassignedTickets = true;
		if (pageTitle === PageTitle.MANAGE_INSTALLERS) {
			this.viewStateService.orderByCol = 'CompanyName';
		}
		if (pageTitle === PageTitle.MY_TICKETS) {
			this.viewStateService.pageTitle = `Tickets - ${this.viewStateService.selectedEmployee.displayName}`;
			this.getTableData(getUnassignedTickets);
			this.getTableData();
		} else {
			this.viewStateService.pageTitle = pageTitle;
			this.getTableData();
		}

		this.viewStateService.pageNumber = 1;
	}

	private getTableData(getUnassignedTickets?: boolean) {
		this.ticketSystemService.getTableData$(getUnassignedTickets).subscribe((tableData) => {
			this.ticketSystemService.setTableData(getUnassignedTickets, tableData);
			this.ticketSystemService.mainTableData = tableData;
		});
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
		clearTimeout(this.ticketSystemService.overdueTicketCountTimer);
		this.viewStateService.reset();
	}
}
