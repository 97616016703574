import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdminDataService, UtilitiesService } from '../../../core/services';
import { Clients, ContactInformation, Contracts, ProductTypes, ServiceLocations, Users } from '../../../shared/api-models/admin';
import { environment } from '../../../../environments/environment';

type NameAndId = { Name: string; Id: number };

@Injectable({
	providedIn: 'root'
})
export class CreateEditClientService {
	public client: Clients;
	public unmodifiedClient: Clients;
	public contracts: Contracts[] = [];
	public initialServiceLocation: ServiceLocations;
	public mode: 'edit' | 'new';
	public productType: ProductTypes;

	//Observable Arrays Used In Template
	public activeSalesmen$: Observable<NameAndId[]>;
	public csrList$: Observable<NameAndId[]>;
	public industryTypes$: Observable<NameAndId[]>;
	public mainContact$: Observable<string>;
	public mainServiceLocation$: Observable<string>;
	public productTypes$: Observable<ProductTypes[]>;
	public serviceLocations$: Observable<NameAndId[]>;
	public users$: Observable<NameAndId[]>;

	constructor(private adminDataService: AdminDataService, private httpClient: HttpClient, private utilService: UtilitiesService) {}

	public allTheGets(): void {
		this.activeSalesmen$ = this.adminDataService.getActiveSalesmen();
		this.csrList$ = this.adminDataService.getAllCSRs(true); //include email address (true)
		this.industryTypes$ = this.getIndustryTypes();
		this.mainContact$ = this.getMainContactInfo();
		this.mainServiceLocation$ = this.getMainServiceLocation();
		this.productTypes$ = this.adminDataService.getProductTypes$();
		this.serviceLocations$ = this.getServiceLocations();
		this.users$ = this.getUsers();
	}

	private getIndustryTypes(): Observable<NameAndId[]> {
		return this.httpClient.get(environment.adminUrl + `CoreIndustryTypes`).pipe(
			map((industryTypes: NameAndId[]) => {
				return this.utilService.sortItems(industryTypes, 'Name');
			})
		);
	}

	private getMainContactInfo(): Observable<string> {
		if (this.client.PrimaryContactId) {
			return this.httpClient.get<ContactInformation[]>(`${environment.adminUrl}CoreUsers/${this.client.PrimaryContactId}/ContactInformation`).pipe(
				map(([mainContact]: ContactInformation[]) => {
					return `${mainContact.FirstName} ${mainContact.LastName} (${mainContact.Email})`;
				})
			);
		}
	}

	private getMainServiceLocation(): Observable<string> {
		if (this.client.PrimaryLocationId) {
			return this.httpClient.get(environment.adminUrl + `CoreServiceLocations/${this.client.PrimaryLocationId}`).pipe(
				map(([mainLocation]: ServiceLocations[]) => {
					return `${mainLocation.Name} (${mainLocation.City}, ${mainLocation.State})`;
				})
			);
		}
	}

	private getServiceLocations(): Observable<NameAndId[]> {
		return this.httpClient.get(environment.adminUrl + `CoreServiceLocations/Client/${this.client.Id}`).pipe(
			map((locations: ServiceLocations[]) => {
				return this.utilService.sortItems(this.serviceLocations(locations), 'Name');
			})
		);
	}

	private serviceLocations(locations: ServiceLocations[]): NameAndId[] {
		return locations.map((location) => {
			return {
				Name: `${location.Name} (${location.City}, ${location.State})`,
				Id: location.Id
			};
		});
	}

	private getUsers(): Observable<NameAndId[]> {
		return this.httpClient.get(environment.adminUrl + `CoreUsers/GetUsersByClientId/${this.client.Id}`).pipe(
			map((users: Users[]) => {
				return this.utilService.sortItems(this.users(users), 'Name');
			})
		);
	}

	private users(users: Users[]): NameAndId[] {
		return users
			.map((userApi) => {
				if (userApi.ContactInformation && !userApi.IsDeleted) {
					return {
						Name: `${userApi.ContactInformation.FirstName} ${userApi.ContactInformation.LastName} (${userApi.ContactInformation.Email})`,
						Id: userApi.Id
					};
				}
			})
			.filter((user) => user);
	}

	public contract(contractTempId: number, productType: ProductTypes): any {
		let contract: Contracts = new Contracts();
		contract.tempId = contractTempId;
		contract.ContractTypeId = this.convertProductTypeIdToContractTypeId(productType.Id);
		contract.productTypeId = productType.Id;
		contract.name = productType.ProductName;
		contract.IsActive = true;
		contract.IsEarlyCancel = false;
		contract.IsFulfilledContractCancellation = false;
		contract.IsRenewed = false;
		contract.IsExemptFromStats = false;
		contract['productNoun'] = productType.ProductNoun;
		return contract;
	}

	public convertProductTypeIdToContractTypeId(productTypeId: number): number {
		switch (productTypeId) {
			case 1: //on hold
				return 1;
			case 2: //works24 radio
				return 3;
			case 8: //other
				return 5;
			default: //any video product
				return 2;
		}
	}

	public newClientInit(): void {
		this.client = new Clients();
		this.client.Name = '';
		this.client.IsActive = false;
		this.client.IsInternal = false;
		this.client.MaxOnHoldContentReq = 10;
		this.client.MaxVideoContentReq = 0;
		this.client.MaxPosterContentReq = 0;
		this.client.MaxVideoPlaylistLength = 40;
		this.client.DefaultParagraphPauseMs = 5000;
		this.client.DefaultStillDurationMs = 15000;
		this.client.SendHoldReminders = true;
		this.client.SendVideoReminders = true;
		this.client.SendNewReleases = true;
		this.client.UseAdvancedScheduling = false;
		this.client.BillingZip = '';
		this.client.IndustryType = {};
		this.client.IndustryType.Name = 'General';
		this.client.IndustryTypeId = 11;
		this.client.AllowRecord24 = true;
	}

	public productTypeInit(): void {
		this.productType = new ProductTypes();
		this.productType.ProductName = 'Select a product...';
	}
}
