import { FeedItems } from '../../../shared/api-models/content';

export class FeedItemsView extends FeedItems {
	startDateView: string;
	stopDateView: string;
	editBtn: string;
	disableBtn: string;
	feedName: string;
	headline: string;
	body: string;
	itemContentView: string;
	disabledIcon: string;
	rowBgColor: string;
	isNewFeedItem: boolean;
	feedSourceId: number;
	createDateView: string;
}
