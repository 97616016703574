export class Stock {
	Ask: number;
	AskDate: string;
	AskSize: number;
	Bid: number;
	BidDate: string;
	BidSize: number;
	BidTime: string;
	ChangeFromPreviousClose: number;
	Close: number;
	Currency: string;
	Date: string;
	Delay: number;
	High: number;
	High52Weeks: number;
	Icon: string;
	Identity: string;
	Last: number;
	LastSize: number;
	Low: number;
	Low52Weeks: number;
	Message: string;
	Open: number;
	Outcome: string;
	PercentChangeFromPreviousClose: number;
	PreviousClose: number;
	PreviousCloseDate: string;
	Security: Security;
	Time: string;
	TradingHalted: boolean;
	UTCOffset: number;
	Volume: string;

	name: string;
}

export class Security {
	CIK: string;
	CUSIP: any;
	CategoryOrIndustry: string;
	ISIN: any;
	Market: string;
	MarketIdentificationCode: string;
	MostLiquidExchange: boolean;
	Name: string;
	Symbol: string;
	Valoren: string;
}