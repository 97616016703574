export class Comment {
	ContentRequestId: number;
	CreateDateTime: string;
	Id: number;
	Message: string;
	UserId: number;
	userName: string;

	constructor(contentRequestId: number, userId: number) {
		this.ContentRequestId = contentRequestId;
		this.UserId = userId;
	}
}