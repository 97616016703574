import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';

import { AdminDataService, AppStateService } from '../core/services';
import { ContactInformation } from '../shared/api-models/admin';
import { ContactUsService } from './contact-us.service';

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
	public csr: ContactInformation;
	public isSending: boolean;
	public salesRep: ContactInformation;
	public sentMessage: string;
	public dropdownOptions: { name: string; email: string }[];

	public recipient: { name: string; email: string };

	constructor(
		public adminDataService: AdminDataService,
		public appStateService: AppStateService,
		public contactUsService: ContactUsService,
		public location: Location
	) {}

	ngOnInit(): void {
		this.contactUsService.message = { subject: '', text: '' };
		this.adminDataService.getCsrAndSalesRepByClient(this.appStateService.currentClient.Id).subscribe((res: [ContactInformation[], ContactInformation[]]) => {
			this.csr = res[0][0];
			this.salesRep = res[1][0];
			this.dropdownOptions = [
				{ name: `${this.csr.FirstName} ${this.csr.LastName} (${this.csr.Email})`, email: this.csr.Email },
				{ name: `${this.salesRep.FirstName} ${this.salesRep.LastName} (${this.salesRep.Email})`, email: this.salesRep.Email }
			];
		});
	}

	onRecipientSelect(recipient: { name: string; email: string }): void {
		this.recipient = recipient;
	}

	public onSendClick(form: NgForm): void {
		this.isSending = true;
		this.contactUsService.sendEmail(this.recipient).subscribe(() => {
			setTimeout(() => {
				this.isSending = false;
				form.resetForm();
				this.sentMessage = 'Your message has been sent! We will will reply shortly.';
				setTimeout(() => {
					this.sentMessage = null;
				}, 10000); //Sent message goes away after 10 seconds
			}, 1800); //Short timeout to let the spinner spin feels better
		});
	}
}
