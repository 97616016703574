import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { CountdownAndDateService } from './countdown-and-date.service';
import { DisplayOptions } from '../../_models';
import { CreateLayerSidebarService } from '../create-layer-sidebar.service';
import { LayersService } from '../../_services';
import { Layer } from '../../_models';
import { CountdownInterval } from '../../_models/countdown-interval.enum';

@Component({
	selector: 'cls-countdown-and-date',
	templateUrl: './countdown-and-date.component.html',
	styleUrls: ['./countdown-and-date.component.scss']
})
export class CountdownAndDateComponent implements OnInit {
	@Output() hideCountdownOptions: EventEmitter<boolean> = new EventEmitter<boolean>();

	public includeTime: boolean;
	public showTooltip: boolean;
	public tooltip: string;
	readonly countdownInterval = CountdownInterval;

	public disableDateUntil = {
		year: new Date().getFullYear(),
		month: new Date().getMonth() + 1,
		day: new Date().getDate() - 1
	};

	constructor(
		public countdownAndDateService: CountdownAndDateService,
		public createLayerSidebarService: CreateLayerSidebarService,
		public layersService: LayersService
	) {}

	ngOnInit(): void {
		if (this.countdownAndDateService.mode === 'new') {
			this.countdownAndDateService.countdownInterval = this.countdownInterval.DAYS;
			this.countdownAndDateService.displayOption = 'OneLayer';
			this.countdownAndDateService.view = this.countdownAndDateService.viewInit();
		}

		//Remove "z" from date so view will display correctly
		if (this.countdownAndDateService.mode === 'edit') {
			this.layersService.activeLayer.countdownDate = this.layersService.activeLayer.countdownDate?.substring(
				0,
				this.layersService.activeLayer.countdownDate.length - 1
			);
		}
	}

	public onCountdownDateChanged(date: string): void {
		this.countdownAndDateService.countdownDate = date;
	}

	public onLayerOptionsClick(option: DisplayOptions): void {
		this.countdownAndDateService.displayOption = option;
	}

	public onIntervalClick(interval: CountdownInterval): void {
		this.countdownAndDateService.countdownInterval = interval;
	}

	public onShowTooltip(tooltip: string): void {
		this.tooltip = tooltip;
		this.showTooltip = true;
	}

	public buttonText(): string {
		switch (true) {
			case this.countdownAndDateService.displayOption === 'TwoLayers':
			case this.countdownAndDateService.displayOption === 'ThreeLayers':
			case this.countdownAndDateService.displayOption === 'FourLayers':
				return 'layers';

			default:
				return 'layer';
		}
	}

	public onCancelClick(): void {
		this.hideCountdownOptions.emit();
		this.countdownAndDateService.mode = null;
	}

	public onCreateCountdownClick(): void {
		if (!this.countdownAndDateService.disabled()) {
			if (this.countdownAndDateService.mode === 'new') {
				this.countdownAndDateService.createLayers();
			}
			if (this.countdownAndDateService.mode === 'edit') {
				switch (this.countdownAndDateService.view) {
					case 'countdown':
						const countdownLayers: Layer[] = this.layersService.layers.filter((layer) => layer.type === 'Countdown').reverse();
						//Preserve deleted layers so we can re-add layer props (i.e. text color, font, etc.)
						const deletedCountdownLayersCopy = JSON.parse(JSON.stringify(countdownLayers));

						//Delete current layers first
						this.countdownAndDateService.deleteLayers(countdownLayers);

						//Re-add layers according to changes made
						this.countdownAndDateService.createLayers(deletedCountdownLayersCopy);
						break;

					case 'todaysDate':
						const currentDateLayers: Layer[] = this.layersService.layers.filter((layer) => layer.type === 'Current Date');
						const deletedCurrentDateLayersCopy = JSON.parse(JSON.stringify(currentDateLayers));
						//Delete current layers first
						this.countdownAndDateService.deleteLayers(currentDateLayers);

						//Re-add layers according to changes made
						this.countdownAndDateService.createLayers(deletedCurrentDateLayersCopy);
						break;
					case 'currentTime':
						const currentTimeLayers: Layer[] = this.layersService.layers.filter((layer) => layer.type === 'Current Time');
						const deletedCurrentTimeLayersCopy = JSON.parse(JSON.stringify(currentTimeLayers));
						//Delete current layers first
						this.countdownAndDateService.deleteLayers(currentTimeLayers);

						//Re-add layers according to changes made
						this.countdownAndDateService.createLayers(deletedCurrentTimeLayersCopy);
						break;
				}
			}
			this.hideCountdownOptions.emit();
		}
		this.countdownAndDateService.mode = null;
	}

	//Choose between countdown, today's date, or current time
	public onViewClick(view: 'countdown' | 'todaysDate' | 'currentTime'): void {
		this.countdownAndDateService.view = view;
	}
}
