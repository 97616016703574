import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { authGuard } from '../core/services/route-guards';
import { ContactUsComponent } from './contact-us.component';

const routes: Routes = [
	{
		path: '',
		component: ContactUsComponent,
		canActivate: [authGuard]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ContactUsRoutingModule {}
