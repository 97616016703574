import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppStateService, SecurityService, UtilitiesService } from '../../../core/services';
import { CreateEditRequestService } from '../_services/create-edit-request.service';
import { environment } from '../../../../environments/environment';
import { ContentRequestStateName, ProductTypes } from 'src/app/shared/api-models/admin';
import { Route } from '../../../content-requests/_models';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

@Injectable({
	providedIn: 'root'
})
export class CreateEditViewStateService {
	public mode: 'edit' | 'new';
	public route: Route;
	public isMergedRequest: boolean;
	public isStatusBeforeComplete: boolean;
	public isStatusBeforeInProduction: boolean;
	public showBypassScriptWriter: boolean;
	public view: 'client-view' | 'internal-hold-view' | 'internal-video-view';

	constructor(
		private appStateService: AppStateService,
		private createEditRequestService: CreateEditRequestService,
		private httpClient: HttpClient,
		private securityService: SecurityService,
		private utilService: UtilitiesService
	) {}

	public setViewState(mode: 'edit' | 'new'): void {
		this.appStateService.product = new ProductTypes();
		this.setView();
		this.isStatusBeforeInProduction = this.statusBeforeInProduction();
		this.isStatusBeforeComplete = this.statusBeforeComplete();
		this.mode = mode;
		this.createEditRequestService.request.clientStatusView = this.clientStatusView();
		this.appStateService.product.Route = this.createEditRequestService.requestTypeToProductRoute(this.createEditRequestService.request.RequestType);
	}

	public clientStatusView(): string {
		const stateName = this.createEditRequestService.request.StateName;
		switch (stateName) {
			case ContentRequestStateName.AWAITING_SCRIPT_EDITS:
			case ContentRequestStateName.PENDING_SCRIPT_APPROVAL:
			case ContentRequestStateName.COMPLETE:
			case ContentRequestStateName.CANCELLED:
				return stateName;
			default:
				return ContentRequestStateName.IN_PRODUCTION;
		}
	}

	public statusBeforeComplete(): boolean {
		const stateName = this.createEditRequestService.request.StateName;
		return (
			this.statusBeforeInProduction() ||
			stateName === ContentRequestStateName.IN_PRODUCTION ||
			stateName === ContentRequestStateName.PENDING_CLIENT_APPROVAL ||
			stateName === ContentRequestStateName.PENDING_CSR_APPROVAL
		);
	}

	public statusBeforeInProduction(): boolean {
		const stateName = this.createEditRequestService.request.StateName;
		return (
			stateName === ContentRequestStateName.UNASSIGNED ||
			stateName === ContentRequestStateName.AWAITING_ARTWORK ||
			stateName === ContentRequestStateName.ASSIGNED_AWAITING_PRODUCTION ||
			stateName === ContentRequestStateName.AWAITING_SCRIPT_EDITS ||
			stateName === ContentRequestStateName.PENDING_SCRIPT_APPROVAL ||
			stateName === ContentRequestStateName.REVISION_REQUESTED
		);
	}

	public checkRouteState(): Route {
		const { activeUrl } = this.appStateService;
		switch (true) {
			case this.utilService.includes(activeUrl, 'system-tools'):
				return 'system-tools';
			case this.utilService.includes(activeUrl, 'client-details'):
				return 'client-details';
			default:
				return 'content-requests';
		}
	}

	public setView(): void {
		switch (true) {
			case this.route !== 'content-requests' && this.createEditRequestService.request.RequestType === 'On-Hold Message':
				this.view = 'internal-hold-view';
				break;

			case this.route !== 'content-requests' && this.createEditRequestService.request.RequestType !== 'On-Hold Message':
				this.view = 'internal-video-view';
				break;

			case this.route === 'content-requests':
				this.view = 'client-view';
				break;
		}
	}

	public updateStatusIfContentCreator(): void {
		const request = this.createEditRequestService.request;
		//Is selectedRequest's current status before In Production?
		if (this.statusBeforeInProduction()) {
			//Is current user a content creator and status is not already In Production?
			if (
				this.securityService.userRole(this.appStateService.currentUser.Role) === UserRole.CONTENT_CREATOR &&
				request.StateName !== ContentRequestStateName.IN_PRODUCTION
			) {
				request.StateName = ContentRequestStateName.IN_PRODUCTION;
				this.httpClient.patch(`${environment.adminUrl}ContentRequests/${request.Id}`, { StateName: request.StateName }).subscribe();
			}
		}
	}
}
