<!--*****************************
	CONTENT FILE BUTTON
******************************-->
<button
	id="contentFileButton{{ clipIndex }}{{ index }}"
	class="{{ clip.contentFiles.length > 4 ? 'mgn-top10' : null }} relative z ft-size14 {{
		contentFile.VoiceTalent?.TalentGender === 'Male' ? 'blue-bg' : 'pink-bg'
	}} white-text border-radius"
	[ngxTippy]="htmlButtons"
	tippyName="{{ utilService.guid | wrapFn }}"
	[tippyProps]="{ allowHTML: true, interactive: true, placement: 'top', appendTo: 'parent', arrow: true }"
>
	{{ contentFile.VoiceTalent?.TalentName }}
</button>

<ng-template #htmlButtons>
	<div class="btnsContainer">
		<a
			style="padding-right: 10px; padding-left: 10px; padding-top: 8px; padding-bottom: 9px; margin-right: 6px"
			class="{{ contentFile.VoiceTalent.TalentGender === 'Male' ? 'blue-bg-darker' : 'pink-bg' }} border-radius white-text relative"
			ngxTippy
			tippyName="{{ utilService.guid | wrapFn }}"
			data-tippy-content="Preview"
			[tippyProps]="{ placement: 'top', interactive: true, appendTo: 'parent', arrow: true, offset: [0, 18] }"
			href="{{ contentFile.previewUrl }}"
			target="_blank"
		>
			<i class="fas fa-play" aria-hidden="true"></i>
		</a>

		<button
			(click)="onRevisionClick()"
			[disabled]="!clip.eligibleForRevision"
			style="padding-right: 10px; padding-left: 10px; padding-top: 8px; padding-bottom: 8px; height: 36px; width: 32px"
			[style.margin-right.px]="viewStateService.route !== 'content-requests' ? 6 : null"
			class="{{ clip.eligibleForRevision ? 'green-bg' : 'gray-bg' }} relative white-text"
			[ngxTippy]="htmlRevision"
			tippyName="{{ utilService.guid | wrapFn }}"
			[tippyProps]="{ allowHTML: true, interactive: true, placement: 'top', appendTo: 'parent', arrow: true, offset: [0, 18] }"
		>
			<i *ngIf="clip.eligibleForRevision" class="fas fa-thumbtack absolute center-unknown-height-width" aria-hidden="true"></i>
			<i *ngIf="!clip.eligibleForRevision" class="fas fa-ban white-text absolute center-unknown-height-width" aria-hidden="true"></i>
		</button>
		<ng-template #htmlRevision>
			<div [ngStyle]="{ 'width.px': 106 }">
				<span>Mark for revision</span>
			</div>
		</ng-template>

		<button
			*ngIf="viewStateService.route !== 'content-requests'"
			style="width: 32px"
			class="yellow-bg relative white-text"
			(click)="fileUploadStateService.uploaderInit()"
			(click)="replaceFileInput.click()"
			ngxTippy
			tippyName="{{ utilService.guid | wrapFn }}"
			data-tippy-content="Replace file"
			[tippyProps]="{ placement: 'top', interactive: true, appendTo: 'parent', arrow: true, offset: [0, 18] }"
		>
			<i class="fas fa-redo-alt absolute center-unknown-height-width"></i>
		</button>

		<button
			*ngIf="viewStateService.route !== 'content-requests'"
			style="width: 32px"
			class="red-bg-light relative"
			(click)="onDeleteClick()"
			ngxTippy
			tippyName="{{ utilService.guid | wrapFn }}"
			data-tippy-content="Delete file"
			[tippyProps]="{ placement: 'top', interactive: true, appendTo: 'parent', arrow: true, offset: [0, 18] }"
		>
			<i
				class="fa {{ contentFile?.markedForDelete ? 'fa-undo dark-text' : 'fa-trash-alt white-text' }} absolute center-unknown-height-width"
				aria-hidden="true"
			></i>
		</button>
	</div>
</ng-template>

<i
	*ngIf="contentFile.inRevision"
	class="fa {{ contentFile.isMarkedForRevision ? 'fa-thumb-tack' : 'fa-hourglass-half' }} white-text absolute z top4 right20 ft-size12"
	aria-hidden="true"
></i>

<input
	#replaceFileInput
	name="file"
	type="file"
	onclick="value = null"
	ng2FileSelect
	[uploader]="fileUploadStateService.uploader"
	class="hidden"
	(change)="onReplaceInputClick()"
/>
