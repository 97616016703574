import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppStateService } from 'src/app/core/services';
import { CreateEditRequestService } from '../_services/create-edit-request.service';
import { ClientHistoryNotes } from '../../../shared/api-models/log';
import { EmailService } from '../_services/email.service';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HistoryNoteService {

    constructor(
            private appStateService: AppStateService,
            private createEditRequestService: CreateEditRequestService,
            private emailService: EmailService,
            private httpClient: HttpClient
    ) { }

    public postCompleteEmailHistoryNote(): Observable<any> {
        return this.httpClient.post(`${environment.adminUrl}ClientHistoryNotes`, this.historyNote())
    }

    private historyNote(): ClientHistoryNotes {
        const historyNoteApi: ClientHistoryNotes = new ClientHistoryNotes();

        historyNoteApi.ClientId = this.createEditRequestService.request.ClientId;
        historyNoteApi.ServiceLocationId = null;
        historyNoteApi.UserId = this.appStateService.currentUser.UserId;
        historyNoteApi.EntrySource = 'Hold Service';
        historyNoteApi.EntryType = 'Outgoing Email';
        historyNoteApi.LogSubject = `Content Request Messages Complete Email Sent To ${this.createEditRequestService.request.requestedByEmail}`;
        historyNoteApi.LogMessage = this.emailService.messagesCompleteEmail().body;

        return historyNoteApi;
    }
}
