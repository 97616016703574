<ng-container *ngIf="appStateService.currentUser.Show2faQRCode">
	<div class="row">
		<div class="col-xs-10 col-xs-offset-1 center">
			<div>Scan this code in the Google Authenticator app. The app generates a pin, which you can enter below.</div>
		</div>
	</div>
	<img *ngIf="loginService.tfaImageUrl" [attr.data-testid]="DataTestIds.Login.AuthCode.QRCode" [src]="loginService.tfaImageUrl" alt="QR Code" />
	<div class="mt-8">
		<span class="semi-bold">Manual Setup Key:</span>
		{{ loginService.manualEntryCode }}
	</div>
</ng-container>

<div [ngClass]="appStateService.currentUser.Show2faQRCode ? 'mt-8' : 'mt-36'" class="row">
	<div class="col-xs-10 col-xs-offset-1">
		<input class="!w-3/4 ht55 lt-gray-bg" [(ngModel)]="pin" type="text" placeholder="Enter 6-digit authentication code" (keyup.enter)="onSubmitClick()" />
		<button class="red-bg white-text mt-8 w-3/4 center" [attr.data-testid]="DataTestIds.Login.AuthCode.SubmitButton" (click)="onSubmitClick()">Submit</button>
		<a class="mt-4 p-2 center block" [attr.data-testid]="DataTestIds.Login.AuthCode.CancelLink" (click)="onCancelClick()">Cancel</a>
	</div>
</div>
