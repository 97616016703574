import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { SecurityService } from '../security.service';

export const adminOrCsrGuard = () => {
	const router = inject(Router);
	const securityService = inject(SecurityService);

	if (securityService.userRoleAdminOrCsr()) {
		return true;
	} else {
		router.navigate(['/access-denied']);
		return false;
	}
};
