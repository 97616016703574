import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'me-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {


    constructor(
    ) { }

    ngOnInit(): void {
    }
}
