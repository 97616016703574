import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { ClientErrorLog } from 'src/app/shared/api-models/log';
import { DateTimeService, Events, MessageService, CacheService, UtilitiesService } from '../../../../core/services';
import { environment } from '../../../../../environments/environment';
import { ServiceLocations, Users } from '../../../../shared/api-models/admin';
import { PlayersView, PlayerStatusView } from '../_models';
import { TableService } from '../error-log-modal/table.service';

@Injectable({
	providedIn: 'root'
})
export class LocationDetailsService {
	constructor(
		private dateTimeService: DateTimeService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private cacheService: CacheService,
		private errorModalTableService: TableService,
		private utilService: UtilitiesService
	) {}

	public deletePlayer(player: PlayersView): Observable<void> {
		this.messageService.publish(Events.savingPreloader, 1);
		let cancelFuturePlaylists$ = this.httpClient.get(`${environment.deliveryUrl}DeliveryQueue/CancelFuturePlaylists/${player.Id}`);
		let deletePlayer$ = this.httpClient.delete(`${environment.adminUrl}CorePlayers/${player.Id}`);
		let deletePlayerStatus$ = this.httpClient.delete(`${environment.deliveryUrl}PlayerStatus/${player.playerStatus.Id}`);

		return forkJoin([cancelFuturePlaylists$, deletePlayer$, deletePlayerStatus$]).pipe(
			map(() => {
				this.messageService.publish(Events.savingPreloader, 0);
			})
		);
	}

	public getCsr(csrId: number): Observable<Users> {
		let apiCall = this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${csrId}`);
		return this.cacheService.user$(apiCall, csrId);
	}

	public getLocationAndPlayerData(locationId: number): Observable<ServiceLocations> {
		let location$ = this.httpClient.get(`${environment.adminUrl}CoreServiceLocations/${locationId}`);
		let players$ = this.httpClient.get(`${environment.adminUrl}CoreServiceLocations/${locationId}/Players`);

		return forkJoin([location$, players$]).pipe(
			mergeMap((res: any) => {
				let location: ServiceLocations = res[0][0];
				let players: PlayersView[] = res[1];

				if (players.length > 0) {
					let playerStatusArr$ = players.map((player) => {
						return this.httpClient.get(`${environment.deliveryUrl}PlayerStatus/Player/${player.Id}`);
					});

					return forkJoin(playerStatusArr$).pipe(
						map((playerStatusArrArrs: PlayerStatusView[][]) => {
							let playerStatusArr: PlayerStatusView[] = [].concat.apply([], playerStatusArrArrs);

							return this.locationView(location, players, playerStatusArr);
						})
					);
				}
				return of(location);
			})
		);
	}

	private locationView(location: ServiceLocations, players?: PlayersView[], playerStatusArr?: PlayerStatusView[]): ServiceLocations {
		location.players = players.map((player, index) => {
			player.playerStatus = playerStatusArr[index];
			player.editBtn = 'Edit';
			player.deleteBtn = 'Delete';
			player.errorLogsBtn = 'Error logs';
			player.classList = 't-row white-bg';
			player.schedulePlaylistTooltip = 'Schedule test playlist';
			player.areaCodeAndPhone = player.PlayerModel.Name !== 'VOIP' ? this.utilService.formatPhoneNumber(`${player.RemoteAreaCode}${player.RemotePhone}`) : null;

			if (player.ProductTypeId !== 1) {
				//hold
				player.playerStatus.lastCheckinCrmView = this.setCheckinAndUpdatedViews(player).lastCheckinCrmView;
				player.playerStatus.lastUpdateCrmView = this.setCheckinAndUpdatedViews(player).lastUpdateCrmView;
			}
			return player;
		});
		return location;
	}

	private playerInstalled(player: PlayersView): boolean {
		return player.InstallState !== null && player.InstallState.Id === 1;
	}

	private setCheckinAndUpdatedViews(player: PlayersView): any {
		//show last dates if player is installed
		if (this.playerInstalled(player)) {
			return {
				lastCheckinCrmView: this.dateTimeService.differenceInDaysOrHours(player.playerStatus.LastCheckin),
				lastUpdateCrmView: this.dateTimeService.differenceInDaysOrHours(player.playerStatus.LastPlaylistUpdate)
			};
			//show state name and N/A if player not installed
		} else if (player.InstallState) {
			return {
				lastCheckinCrmView: 'N/A',
				lastUpdateCrmView: player.InstallState.Name
			};
		}
		return {
			lastCheckinCrmView: 'N/A',
			lastUpdateCrmView: 'N/A'
		};
	}

	public getErrorLogs(unitId: string): Observable<ClientErrorLog[]> {
		return this.httpClient.get<ClientErrorLog[]>(`${environment.adminUrl}CorePlayers/GetPlayerErrorLogs/${unitId}`).pipe(
			map((res) => {
				if (res.length > 0) {
					this.errorModalTableService.config.headerColor = this.errorLogsHeaderColor(res[0].ErrorSource);
					return res.map((log) => {
						log.classList = 't-row';
						log.sendNotificationEmail = log.SendNotificationEmail ? 'True' : 'False';
						log.detailMessage = this.isJson(log.ErrorDetail) ? this.isJson(log.ErrorDetail) : this.truncateString(log.ErrorDetail, 800);
						return log;
					});
				}
			})
		);
	}

	private errorLogsHeaderColor(source: string): string {
		switch (true) {
			case source.includes('Hold'):
				return 'blue-bg';
			case source.includes('Music'):
				return 'purple-bg';
			default:
				return 'red-bg';
		}
	}

	private truncateString(str: string, num: number): string {
		if (str.length > num) {
			return str.slice(0, num) + '...';
		}
		return str;
	}

	private isJson(str): string {
		try {
			const o = JSON.parse(str);
			if (o && typeof o === 'object') {
				return o.Message;
			}
			return o;
		} catch (e) {}
	}
}
