import { Component, OnInit, OnDestroy } from '@angular/core';

import { AppStateService } from 'src/app/core/services';
import { BatchChangeCategoryService } from '../../_services';

@Component({
    selector: 'clh-change-category-dropdowns',
    templateUrl: './change-category-dropdowns.component.html',
    styleUrls: ['./change-category-dropdowns.component.scss']
})
export class ChangeCategoryDropdownsComponent implements OnInit, OnDestroy {

    constructor(
        public appStateService: AppStateService,
        public batchChangeCategoryService: BatchChangeCategoryService
    ) { }

    ngOnInit() {
    }

    public subCategoryMenuVisible(): boolean {
        return this.batchChangeCategoryService.parentCategorySelected &&
            this.batchChangeCategoryService.filteredSubCategories.length > 0;
    }

    ngOnDestroy() {
        this.batchChangeCategoryService.libraries = [];
        this.batchChangeCategoryService.parentCategories = [];
        this.batchChangeCategoryService.subCategories = [];
        this.batchChangeCategoryService.filteredSubCategories = [];
        this.batchChangeCategoryService.atLeastOneContentSelected = false;
    }

}
