import { DisplayOptions } from './display-options';
import { Layer } from '.';
import { ProductTypes } from 'src/app/shared/api-models/admin';
import { Feed, FeedItem } from '.';
import { CountdownInterval } from './countdown-interval.enum';
import { ContentVM } from 'src/app/shared/components/content-container/content/_models/content-view';
import { Create24ContentType } from '../../_models/create-24-state.enum';

export class EditorState {
	eligibleForConversion: boolean;
	activeLayer: Layer;
	layers: Layer[];
	canvasObjs: string;
	timelineObjs: string;
	bgContent: ContentVM;
	tooltip: string;
	c24ContentId: number; //Content record that is created on export
	productType: ProductTypes;
	blankBgColor: string;
	currentCanvasWidth: number;
	previousTimelineCanvasWidth: number;
	feed: Feed;
	feedItem: FeedItem;
	countdownSettings: {
		countdownDate: string;
		countdownInterval: CountdownInterval;
		mode: 'new' | 'edit';
		view: 'countdown' | 'todaysDate' | 'currentTime';
		displayOption: DisplayOptions;
	};
	contentType: Create24ContentType;
}
