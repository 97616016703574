import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';

import { DateTimeService } from '../../../core/services';
import { StartStopDateTimeService } from './start-stop-date-time.service';
import { UtilitiesService } from '../../../core/services';

@Component({
	selector: 'portal-start-stop-date-time',
	templateUrl: './start-stop-date-time.component.html',
	styleUrls: ['./start-stop-date-time.component.scss']
})
export class StartStopDateTimeComponent implements OnInit {
	@Input() clearDateEnabled: boolean;
	@Input() initAsNull: boolean;
	@Input() invertColorTheme: boolean;
	@Input() selectTimeHidden: boolean;

	constructor(private dateTimeService: DateTimeService, public ssdtService: StartStopDateTimeService, public utilService: UtilitiesService) {}

	ngOnInit() {
		//if a parent component did not initialize a start date,
		//initialize here to today at midnight
		if (!this.ssdtService.view.startDate && !this.initAsNull) {
			this.startDateTodayMidnight();
		}

		//if a parent component did not initialize a stop date,
		//initialize here to a month from today at midnight
		if (!this.ssdtService.view.stopDate && !this.initAsNull) {
			this.stopDateTodayMidnightAdd1Month();
		}

		//if no start date and dates are initialized as null
		if (!this.ssdtService.view.startDate && this.initAsNull) {
			this.initDateTimeAsNull();
		}

		this.ssdtService.timesOfDay = this.ssdtService.timesOfDay.map((time) => {
			let timeObj: any = {};
			timeObj.name = time;
			return timeObj;
		});
	}

	public onStopDateToggleClick(str: 'on' | 'off'): void {
		let payload: any[] = [str, this.initAsNull];
		this.ssdtService.onStopDateToggleClick(payload);
	}

	private initDateTimeAsNull(): void {
		this.ssdtService.view.startDate = null;
		this.ssdtService.view.startTime = 'Select a time...';
		this.ssdtService.model.StartDate = null;
		this.ssdtService.view.stopDate = null;
		this.ssdtService.view.stopTime = 'Select a time...';
		this.ssdtService.model.StopDate = null;
		this.ssdtService.validate(null, this.initAsNull);
	}

	private startDateTodayMidnight(): void {
		this.ssdtService.view.startDate = this.ssdtService.convertDateModelToView(moment(this.dateTimeService.todayAtMidnightUTC()).toString()).dateView;
		this.ssdtService.view.startTime = '12:00 AM';
		this.ssdtService.model.StartDate = this.ssdtService.convertDateViewToModel(this.ssdtService.view.startDate, this.ssdtService.view.startTime);
		this.ssdtService.validate();
	}

	private stopDateTodayMidnightAdd1Month(): void {
		this.ssdtService.view.stopDate = this.ssdtService.convertDateModelToView(this.dateTimeService.todayPlusOneMonth()).dateView;
		this.ssdtService.view.stopTime = '12:00 AM';
		this.ssdtService.model.StopDate = this.ssdtService.convertDateViewToModel(this.ssdtService.view.stopDate, this.ssdtService.view.stopTime);
	}
}
