<div style="margin-top: 10%" class="row mgn-bottom50">
    <div class="col-sm-12 center white-text">
        <h3><em>{{fileUploadStateService.file.name}}</em></h3>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <h2 class="white-text mgn-bottom15">Choose the voice talent.</h2>
    </div>
</div>

<div class="row mgn-bottom80">
    <div class="col-sm-6">
        <div>
            <portal-dropdown-menu *ngIf="fileUploadModalService.voiceTalentList?.length > 0"
                            [backgroundColor]="'gray-bg'"
                            [height]="'ht40'"
                            [options]="fileUploadModalService.voiceTalentList"
                            [key]="'TalentName'"
                            [scrollbarHidden]="true"
                            [selectedOption]="'Generic Male'"
                            (onOptionSelect)="fileUploadModalService.onVoiceTalentSelect($event)">
            </portal-dropdown-menu>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-sm-6">
        <button class="gray-bg" (click)="onCancelClick()">Cancel</button>
    </div>
    <div class="col-sm-6">
        <button class="blue-bg-darker white-text" (click)="onUploadClick()">Upload</button>
    </div>
</div>
