import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { CreateEditFeedItemService } from './create-edit-feed-item.service';
import { Events, MessageService } from '../../../core/services';
import { Feeds } from '../../../shared/api-models/content';
import { FeedItemsView } from '../_models/feed-items-view';
import { StartStopDateTimeService } from '../../../shared/components/start-stop-date-time/start-stop-date-time.service';
import { UtilitiesService } from '../../../core/services';

@Component({
    selector: 'nm-create-edit-feed-item',
    templateUrl: './create-edit-feed-item.component.html',
    styleUrls: ['./create-edit-feed-item.component.scss'],
    providers: [CreateEditFeedItemService, StartStopDateTimeService]
})
export class CreateEditFeedItemComponent implements OnInit {

    public feeds: Feeds[];
    public feedItem: FeedItemsView;
    public viewState: 'new' | 'edit';

    constructor(
        private activatedRoute: ActivatedRoute,
        private createEditFeedItemService: CreateEditFeedItemService,
        public location: Location,
        private messageService: MessageService,
        private router: Router,
        private ssdtService: StartStopDateTimeService,
        private utilService: UtilitiesService) { }

    ngOnInit() {
        let url = this.router.routerState.snapshot.url;
        this.activatedRoute.params.subscribe((res: { feedId: string, feedItemId: string }) => {
            let feedId: number = +res.feedId;
            let feedItemId: number = +res.feedItemId;

            this.getFeeds();

            //editing current feed item
            if (this.utilService.includes(url, 'edit')) {
                this.viewState = 'edit';

                this.createEditFeedItemService.getFeedItem(feedId, feedItemId)
                    .subscribe((feedItem: FeedItemsView) => {
                        this.feedItem = feedItem;

                        if (this.feedItem.StartDate) {
                            this.ssdtService.startStopDateTimeInit(this.feedItem.StartDate, this.feedItem.StopDate);
                        }
                    })
                return;
            }
            this.newFeedItemInit();
        })
    }

    public newFeedItemInit(): void {
        this.viewState = 'new';
        this.feedItem = new FeedItemsView();
        this.feedItem.CreateDate = new Date().toISOString();
        this.feedItem.StartDate = new Date().toISOString();
        this.feedItem.StopDate = new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)).toISOString();
        this.feedItem.IsEnabled = true;
        this.feedItem.isNewFeedItem = true;
    }

    public formValid(): boolean {
        return this.feedItem.headline && this.feedItem.headline.length > 0 &&
            this.feedItem.FeedId !== null && this.feedItem.FeedId !== undefined;
    }

    public onFeedSelect(selectedFeed: Feeds): void {
        this.feedItem.FeedId = selectedFeed.Id;
    }

    //SAVE FEED ITEM
    public onSaveClick(): void {
        this.feedItem.ItemContent = JSON.stringify(this.itemContent());
        this.feedItem.StartDate = this.ssdtServiceDates().startDate;
        this.messageService.publish(Events.savingPreloader, 1);

        if (this.ssdtService.isStopDateSelected) {
            this.feedItem.StopDate = this.ssdtServiceDates().stopDate;
        } else {
            this.feedItem.StopDate = null;
        }
        //post new feed item
        if (this.feedItem.isNewFeedItem) {
            this.createEditFeedItemService.postFeedItem(this.feedItem)
                .subscribe(() => {
                    this.messageService.publish(Events.savingPreloader, 0);
                    this.router.navigate(['/news-manager']);
                })
            //update feed item
        } else {
            this.createEditFeedItemService.updateFeedItem(this.feedItem)
                .subscribe(() => {
                    this.messageService.publish(Events.savingPreloader, 0);
                    this.router.navigate(['/news-manager']);
                })
        }
    }

    private ssdtServiceDates(): any {
        let startDate: string;
        let stopDate: string;
        if (this.utilService.includes(this.ssdtService.model.StartDate, 'Invalid')) {
            startDate = null;
        } else {
            startDate = this.ssdtService.model.StartDate;
        }
        if (this.utilService.includes(this.ssdtService.model.StopDate, 'Invalid')) {
            startDate = null;
        } else {
            stopDate = this.ssdtService.model.StopDate;
        }
        return { startDate: startDate, stopDate: stopDate };
    }

    private getFeeds(): void {
        this.createEditFeedItemService.getFeeds()
            .subscribe((feeds: Feeds[]) => {
                this.feeds = feeds;
            })
    }

    private itemContent(): any {
        let ItemContent: any = {};
        ItemContent.Body = this.feedItem.body;
        ItemContent.Title = this.feedItem.headline;
        ItemContent.SourceId = this.feedItem.feedSourceId;
        this.feedItem.isNewFeedItem ? ItemContent.HasBadWords = false :
            JSON.parse(this.feedItem.ItemContent).HasBadWords;
        return ItemContent;
    }
}
