import { Component, Input, OnInit } from '@angular/core';

import { AddToPlaylistService, ContentFavoritesService } from '../../_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { PlayerCapacityService } from '../../../../../../playlist-builder/_services';
import { ContentVM } from '../../_models/content-view';

@Component({
	selector: 'vc-video-pb-card-view',
	templateUrl: './video-pb-card-view.component.html',
	styleUrls: ['./video-pb-card-view.component.scss']
})
export class VideoPbCardViewComponent implements OnInit {
	@Input() canUseInPlaylist: boolean;
	@Input() content: ContentVM;
	@Input() viewState: 'playlist-builder' | 'content-manager';

	public previewActive: boolean;
	public showScript: boolean;

	constructor(
		public addToPlaylistService: AddToPlaylistService,
		public contentFavoritesService: ContentFavoritesService,
		public cvStateService: ContentViewStateService,
		public playerCapacityService: PlayerCapacityService
	) {}

	ngOnInit(): void {}

	public onPreviewClick(): void {
		if (this.content.contentType === 'video/mp4') {
			this.previewActive = true;
		}
	}
}
