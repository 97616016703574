<portal-header></portal-header>
<!--*********************
	GREEN HEADER BAR
**********************-->
<div class="container-fluid">
	<div class="row mgn-bottom15 mgn-top15">
		<div class="col-xs-4 col-sm-2">
			<div class="row">
				<div class="col-xs-12 col-lg-9">
					<button (click)="location.back()" class="white-bg">
						<i class="fas fa-chevron-left mgn-right12"></i>
						Back
					</button>
				</div>
			</div>
		</div>
		<div class="col-xs-7 col-xs-offset-1 col-sm-offset-0 col-sm-10 text-right">
			<i class="far fa-envelope mgn-right12 ft-size28 green-text"></i>
			<h1 class="in-block"><span class="semi-bold">Contact Us</span></h1>
		</div>
	</div>

	<div class="green-limeGreen-bg-gradient mgn-bottom45 ht2 border-radius"></div>
</div>

<ng-container *ngIf="csr && salesRep">
	<div class="container-fluid mgn-left15 mgn-right15 pd-top30 pd-bottom30 mgn-bottom60 pd-left15 pd-right15 border-radius white-bg shadow relative">
		<div class="row">
			<div class="col-sm-4">
				<div class="row">
					<div class="col-xs-12">
						<h2 class="semi-bold">{{ csr.FirstName }} {{ csr.LastName }}</h2>
						<span>Works24 Client Service Representative</span>
						<br />
						<span>
							Email:
							<a href="mailto:{{ csr.Email }}">{{ csr.Email }}</a>
						</span>
						<br />
						<span>Phone: 1-800-460-4653</span>
					</div>

					<div class="col-xs-12 mgn-top30">
						<h2 class="semi-bold">{{ salesRep.FirstName }} {{ salesRep.LastName }}</h2>
						<span>Works24 Sales Representative</span>
						<br />
						<span>
							Email:
							<a href="mailto:{{ salesRep.Email }}">{{ salesRep.Email }}</a>
						</span>
						<br />
						<span>Phone: 1-800-460-4653</span>
					</div>
				</div>
			</div>

			<form (keydown.enter)="$event.preventDefault()" #form="ngForm" novalidate (ngSubmit)="onSendClick(form)">
				<div class="col-sm-4">
					<div class="row">
						<div class="col-xs-12">
							<portal-dropdown-menu
								[backgroundColor]="'gray-bg-dark'"
								[height]="'ht40'"
								[key]="'name'"
								[selectedOption]="'Select a recipient...'"
								[options]="dropdownOptions"
								(onOptionSelect)="onRecipientSelect($event)"
							></portal-dropdown-menu>
						</div>
					</div>
					<div class="row mgn-top30">
						<div class="col-xs-12">
							<input
								type="text"
								placeholder="Subject"
								[ngClass]="{ 'red-border-light': !subject.valid && !subject.pristine }"
								[(ngModel)]="contactUsService.message.subject"
								name="subject"
								required
								#subject="ngModel"
							/>
							<small class="red-text-light" [hidden]="subject.valid || (subject.pristine && !form.submitted)">Subject is required.</small>
						</div>
					</div>
					<div class="row mgn-top30">
						<div class="col-xs-12">
							<textarea
								rows="8"
								placeholder="Enter a message..."
								[(ngModel)]="contactUsService.message.text"
								[ngClass]="{ 'red-border-light': !body.valid && !body.pristine }"
								name="body"
								required
								#body="ngModel"
							></textarea>
							<small class="red-text-light" [hidden]="body.valid || (body.pristine && !form.submitted)">Message is required.</small>
						</div>
					</div>
					<div class="row mgn-top30">
						<div class="col-xs-12">
							<button [disabled]="!form.valid || !recipient || isSending" type="submit" class="ht40 limeGreen-bg white-text">
								<ng-container *ngIf="!isSending">Send</ng-container>
								<portal-spinner *ngIf="isSending" [height]="'htpx20'" [width]="'wdthpx20'"></portal-spinner>
							</button>
						</div>
						<div class="col-xs-12 text-center mgn-top15">
							<em>{{ sentMessage }}</em>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</ng-container>
