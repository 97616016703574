<div class="center">
	<a
		[ngClass]="viewStateService.pageTitleSnippet === 'My Active' ? 'active' : null"
		class="dark-text pd22 border-radius"
		(click)="getRequestsClick('My Active', urlString.ACTIVE_VIEW)"
	>
		<i class="fas fa-running mgn-right8 ft-size18" aria-hidden="true"></i>
		My Active Requests
	</a>
	<a
		[ngClass]="viewStateService.pageTitleSnippet === 'My Completed' ? 'active' : null"
		class="dark-text pd22 border-radius"
		(click)="getRequestsClick('My Completed', urlString.COMPLETED_VIEW)"
	>
		<i class="far fa-check-circle mgn-right8 ft-size18" aria-hidden="true"></i>
		My Completed Requests
	</a>
	<a [ngClass]="viewStateService.pageTitleSnippet === 'All' ? 'active' : null" class="dark-text pd22 border-radius" (click)="getRequestsClick('All')">
		<i class="far fa-paper-plane mgn-right8 ft-size18" aria-hidden="true"></i>
		All Requests
	</a>
	<a
		[ngClass]="viewStateService.pageTitleSnippet === 'All Open' ? 'active' : null"
		class="dark-text pd22 border-radius"
		(click)="getRequestsClick('All Open', urlString.OPEN_VIEW)"
	>
		<i class="far fa-envelope-open mgn-right8 ft-size18" aria-hidden="true"></i>
		All Open Requests
	</a>
	<a
		[ngClass]="viewStateService.pageTitleSnippet === 'All Assigned' ? 'active' : null"
		class="dark-text pd22 border-radius"
		(click)="getRequestsClick('All Assigned', urlString.ASSIGNED_VIEW)"
	>
		<i class="fas fa-user-check mgn-right8 ft-size18" aria-hidden="true"></i>
		All Assigned Requests
	</a>
	<a
		[ngClass]="viewStateService.pageTitleSnippet === 'All Unassigned' ? 'active' : null"
		class="dark-text pd22 border-radius"
		(click)="getRequestsClick('All Unassigned', urlString.UNASSIGNED_VIEW)"
	>
		<i class="fas fa-user-times mgn-right8 ft-size18" aria-hidden="true"></i>
		All Unassigned Requests
	</a>
</div>
