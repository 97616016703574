import { Component, Input } from '@angular/core';

import { UtilitiesService } from 'src/app/core/services';
import { PlayerCapacityService, WizardService } from 'src/app/playlist-builder/_services';
import { ContentFiles } from 'src/app/shared/api-models/content';
import { ContentFileState } from 'src/app/shared/api-models/content/content-file-state.enum';
import { ContentViewStateService } from 'src/app/shared/services';
import { ContentFilesVM } from '../../_models';
import { ContentVM } from '../../_models/content-view';
import { AddToPlaylistService, ContentFavoritesService, ContentViewService } from '../../_services';

@Component({
	selector: 'vc-hold-pb-list-view',
	templateUrl: './hold-pb-list-view.component.html',
	styleUrls: ['./hold-pb-list-view.component.scss']
})
export class HoldPbListViewComponent {
	@Input() content: ContentVM;
	@Input() contentList: ContentVM[];
	@Input() isEvenIndex: boolean;
	@Input() isFirstIndex: boolean;
	@Input() isLastIndex: boolean;

	readonly contentFileState = ContentFileState;

	constructor(
		public addToPlaylistService: AddToPlaylistService,
		public contentFavoritesService: ContentFavoritesService,
		public contentViewService: ContentViewService,
		public cvStateService: ContentViewStateService,
		public playerCapacityService: PlayerCapacityService,
		public utilService: UtilitiesService,
		public wizardService: WizardService
	) {}

	public onPlayClick(content: ContentVM, isLastIndex?: boolean, prop?: 'talentPreviewSelectorVisible' | 'talentPickerVisible'): void {
		this.contentViewService.onPlayClick(content, isLastIndex, prop, this.contentList);
	}

	public onSelectVoiceTalentClick(selectedContentFile: ContentFiles, content: ContentVM, prop: 'talentPreviewSelectorVisible' | 'talentPickerVisible'): void {
		this.contentViewService.onSelectVoiceTalentClick(selectedContentFile as ContentFilesVM, content, prop);
	}
}
