import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from '../login.service';
import { PasswordResetComplete } from './password-reset-complete';
import { AlertMessage } from '../_models/login-models';

@Component({
	selector: 'l-reset-password-view',
	templateUrl: './reset-password-view.component.html',
	styleUrls: ['./reset-password-view.component.scss']
})
export class ResetPasswordViewComponent implements OnInit {
	public guid: string;
	public password: string = '';
	public repeatPassword: string = '';
	public updateButtonDisabled: boolean;

	constructor(public loginService: LoginService, private router: Router) {}

	ngOnInit() {}

	public onPasswordKeyup(): void {
		this.loginService.setAlert({
			visible: true,
			bgColor: 'blue-bg-darkest',
			textColor: 'white-text',
			message: AlertMessage.PasswordCreationInstructions,
			icon: ''
		});
	}

	public onUpdatePasswordClick(): void {
		//check if both password fields match
		if (this.password !== this.repeatPassword) {
			this.loginService.setAlert({
				visible: true,
				bgColor: 'red-bg-lighter',
				textColor: 'red-text',
				message: AlertMessage.PasswordMatchError,
				icon: 'fa-exclamation-triangle'
			});

			//check if a password field is blank
		} else if (this.password.length < 1 || this.repeatPassword.length < 1) {
			this.loginService.setAlert({
				visible: true,
				bgColor: 'red-bg-lighter',
				textColor: 'red-text',
				message: AlertMessage.PasswordBlankError,
				icon: 'fa-exclamation-triangle'
			});
			//check if the password doesn't pass the regex requirements
		} else if (!this.loginService.passwordPattern.test(this.password)) {
			this.loginService.setAlert({
				visible: true,
				bgColor: 'red-bg-lighter',
				textColor: 'red-text',
				message: AlertMessage.PasswordCreationInstructions,
				icon: 'fa-exclamation-triangle'
			});
		} else {
			this.updateButtonDisabled = true;
			const url = window.location.href;
			const guid = url.substr(url.lastIndexOf('/') + 1);
			this.loginService.onPasswordResetSubmit(this.password, guid).subscribe((response: PasswordResetComplete) => {
				//Successful message and route back to main login view
				if (response.ResetComplete) {
					this.loginService.setAlert({
						visible: true,
						bgColor: 'green-bg',
						textColor: 'white-text',
						message: AlertMessage.PasswordUpdatedSuccessfully,
						icon: 'fa-check'
					});
					setTimeout(() => {
						this.hideAlert();
						this.router.navigate(['/']);
					}, 4000);
					//Show error if server error
				} else {
					this.loginService.setAlert({
						visible: true,
						bgColor: 'red-bg-lighter',
						textColor: 'red-text',
						message: AlertMessage.SomethingWentWrong,
						icon: 'fa-exclamation-triangle'
					});
					this.router.navigate(['/']);
					setTimeout(() => this.hideAlert(), 4000);
				}
			});
		}
	}

	private hideAlert(): void {
		this.loginService.setAlert({
			...this.loginService.alert,
			visible: false
		});
	}
}
