import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppStateService, UtilitiesService } from 'src/app/core/services';
import { ProductTypes } from 'src/app/shared/api-models/admin';
import { c24NewVideoLinks, c24NewImageLinks, c24NewSidebarLinks } from 'src/app/shared/tooltips/tooltips';

@Component({
	selector: 'c24-start-page',
	templateUrl: './start-page.component.html',
	styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {
	public animateClass: string;
	public backUrl: string;
	public showProducts: boolean;
	public c24NewVideoLinks = c24NewVideoLinks;
	public c24NewImageLinks = c24NewImageLinks;
	public c24NewSidebarLinks = c24NewSidebarLinks;

	constructor(public appStateService: AppStateService, private router: Router, public utilService: UtilitiesService) {}

	ngOnInit(): void {
		this.backUrl = `/my-players/${this.appStateService.product.Route}`;
	}

	public autoClose(event) {
		if (!this.utilService.closest(event, '.no-exit')) {
			this.showProducts = false;
		}
	}

	public onProductClick(product: ProductTypes): void {
		this.appStateService.product = product;
		this.showProducts = false;
		this.router.navigate([`/create-24/dashboard/${product.Route}`]);
	}
}
