<portal-header></portal-header>

<div class="container-fluid mgn-top30 pd-top10 relative" (window:mouseup)="clientHealthViewService.autoCloseDropdown($event)">
	<div class="row mgn-bottom15">
		<div class="col-sm-12">
			<h1 [innerHTML]="heading" class="in-block"></h1>
			<span *ngIf="appStateService.currentClient.IsInternal" class="semi-bold green-text ft-size28">{{ ' ' }}INTERNAL/DEMO</span>
			<span *ngIf="!appStateService.currentClient.IsActive" class="semi-bold green-text ft-size28">{{ ' ' }}INACTIVE</span>
		</div>
	</div>

	<!-- TOP CREATE BAR -->
	<nav class="row white-bg shadow border-radius pd-top15 pd-bottom15 mgn-right0 mgn-left0">
		<div class="col-sm-10 col-md-8">
			<div class="row">
				<div class="col-sm-3 mgn-bottom-xs">
					<button class="gray-bg" [routerLink]="['/crm/new-location']">
						<i class="fas fa-map-marker-alt mgn-right10" aria-hidden="true"></i>
						New Location
					</button>
				</div>
				<div class="col-sm-3 mgn-bottom-xs">
					<button class="gray-bg" [routerLink]="['/crm/new-history-note']">
						<i class="fa fa-history mgn-right10" aria-hidden="true"></i>
						New History Note
					</button>
				</div>
				<div class="col-sm-3 mgn-bottom-xs">
					<button (click)="onNewTicketClick()" class="gray-bg">
						<i class="fas fa-ticket-alt mgn-right10" aria-hidden="true"></i>
						New Ticket
					</button>
				</div>
				<div class="col-sm-3">
					<button class="gray-bg" [routerLink]="['/new-csr-task/' + appStateService.currentClient.Id]">
						<i class="fas fa-tasks mgn-right10" aria-hidden="true"></i>
						New Task
					</button>
				</div>
			</div>
		</div>
		<div class="col-sm-2 col-md-4">
			<div class="row">
				<div class="col-sm-12 col-md-5 col-md-offset-7 col-lg-4 col-lg-offset-8">
					<button class="green-bg white-text" [routerLink]="['/crm/edit-client']">
						<i class="fas fa-user-circle mgn-right6" aria-hidden="true"></i>
						Edit Client
					</button>
				</div>
			</div>
		</div>
	</nav>

	<div class="row">
		<div class="{{ securityService.userRoleSalesperson() || clientHealthDashboardService.statusCircles?.length === 0 ? 'col-sm-12' : 'col-sm-10' }}">
			<section #clientDetailsSection class="white-bg mgn-top50 border-radius pd15 shadow">
				<div class="row mgn-right0 mgn-left0 ft-size16">
					<div class="col-sm-8">
						<h3 class="semi-bold">Client Details</h3>
						<div class="ht1 green-limeGreen-bg-gradient setWidth100 border-radius mgn-bottom15"></div>

						<div class="row">
							<div class="col-sm-6">
								<div class="row">
									<div class="col-sm-6">
										<h4 class="gray-text-darkest mgn-bottom2">INDUSTRY TYPE</h4>
										<span class="ft-size16 regular">{{ appStateService.currentClient.IndustryType.Name }},</span>
										<span *ngIf="locationsService.locationsCount > 1 || locationsService.locationsCount < 1" class="ft-size16 regular">
											{{ locationsService.locationsCount }} Locations
										</span>
										<span *ngIf="locationsService.locationsCount === 1" class="ft-size18 regular">{{ locationsService.locationsCount }} Location</span>
										<br />
									</div>
									<div class="col-sm-6">
										<h4 class="gray-text-darkest mgn-bottom2">LAST LOGIN</h4>
										<div *ngIf="lastLoginUser$ | async as lastLoginUser" class="ellipsis-single-line">
											<!--Last Login Tooltip-->
											<div *ngIf="showLastLoginTooltip" class="absolute topneg8 border-radius dark-bg white-text pd6 ft-size14 z">
												{{ lastLoginUser.UserName }}
											</div>
											<span class="regular ft-size14" (mouseover)="showLastLoginTooltip = true" (mouseleave)="showLastLoginTooltip = false">
												{{ lastLoginUser.UserName }} on
												<em class="regular">{{ dateTimeService.dateAndTimeUTCtoLocal(lastLoginUser.LastLogin) }}</em>
											</span>
										</div>
									</div>
								</div>

								<div class="row mgn-top60">
									<div class="col-xs-6">
										<h4 class="gray-text-darkest">ACTIVE CONTRACTS</h4>
									</div>
									<div class="col-xs-6">
										<h4 class="gray-text-darkest">LAST DOWNLOAD</h4>
									</div>
								</div>

								<div class="ht1 gray-bg-dark setWidth100 mgn-right0 mgn-left0 border-radius mgn-bottom10"></div>

								<div *ngFor="let product of contractedProducts$ | async" class="row mgn-bottom5">
									<div class="col-xs-6">
										<span [ngSwitch]="true">
											<span *ngSwitchCase="product.ProductName === 'On Hold'" class="mgn-right2">
												<i class="fas fa-phone-volume mgn-right8" aria-hidden="true"></i>
											</span>
											<span *ngSwitchCase="product.ProductName === 'Works24 Radio'" class="mgn-right2">
												<i class="fas fa-volume-up mgn-right8" aria-hidden="true"></i>
											</span>
											<span *ngSwitchCase="product.ProductName === 'Poster'" class="mgn-right2">
												<i class="far fa-square mgn-right8" aria-hidden="true"></i>
											</span>
											<span *ngSwitchDefault class="mgn-right2">
												<i class="fas fa-tv mgn-right8" aria-hidden="true"></i>
											</span>
										</span>
										<span class="regular">{{ product.ProductName }}</span>
									</div>

									<div class="col-xs-6">
										<span class="{{ product.textColor }} semi-bold" [innerHTML]="product.lastSuccessfulDownloadDateTime"></span>
									</div>
								</div>
							</div>

							<div class="col-sm-6">
								<div class="row">
									<div class="col-sm-6">
										<h4 class="gray-text-darkest mgn-bottom2">BILLING ADDRESS</h4>
										<span class="ft-size16 regular">{{ appStateService.currentClient.BillingAddress1 }}</span>
										<br />
										<span class="ft-size16 regular">
											{{ appStateService.currentClient.BillingCity }}, {{ appStateService.currentClient.BillingState }}
											{{ appStateService.currentClient.BillingZip }}
										</span>
										<br />
										<em class="regular">Phone: {{ appStateService.currentClient.MainPhoneNumber }}</em>
										<br />
										<em class="regular">Fax: {{ appStateService.currentClient.MainFaxNumber }}</em>
										<br />

										<!--Website Tooltip-->
										<div *ngIf="showWebsiteTooltip" class="absolute top80 border-radius dark-bg white-text pd6 ft-size14 z">
											{{ utilService.addHttp(appStateService.currentClient.Website) }}{{ appStateService.currentClient.Website }}
										</div>

										<div class="ellipsis-single-line relative" (mouseover)="showWebsiteTooltip = true" (mouseleave)="showWebsiteTooltip = false">
											<a
												*ngIf="appStateService.currentClient.Website"
												href="{{ utilService.addHttp(appStateService.currentClient.Website) }}{{ appStateService.currentClient.Website }}"
												target="_blank"
											>
												{{ appStateService.currentClient.Website }}
											</a>
											<br *ngIf="appStateService.currentClient.Website" />
										</div>

										<span *ngIf="appStateService.currentClient.RssdId">Fed ID (RSSD): {{ appStateService.currentClient.RssdId }}</span>
										<br />
									</div>
									<div class="col-sm-6">
										<h4 class="gray-text-darkest mgn-bottom2">PRIMARY CONTACT</h4>
										<div *ngIf="mainContact$ | async as mainContact; else noMainContact" class="ellipsis-single-line">
											<!--Email Tooltip-->
											<div *ngIf="showEmailTooltip" class="absolute topneg8 border-radius dark-bg white-text pd6 ft-size14 z">
												{{ mainContact.Email }}
											</div>

											<span class="regular">{{ mainContact.FirstName }} {{ mainContact.LastName }}</span>
											<br />
											<a href="mailto:{{ mainContact.Email }}" (mouseover)="showEmailTooltip = true" (mouseleave)="showEmailTooltip = false" class="ft-size13">
												{{ mainContact.Email }}
											</a>
											<br />
											<span class="ft-size13">{{ mainContact.Phone1 }}</span>
										</div>
										<ng-template #noMainContact>
											<em class="regular">No primary contact set</em>
										</ng-template>

										<div *ngIf="showCustomerLogos" class="row mgn-top60">
											<div class="col-xs-12">
												<h4 class="gray-text-darkest ft-size14">CUSTOMER LOGOS</h4>
												<div *ngFor="let logo of logos$ | async">
													<a target="_blank" href="{{ logo.url }}">{{ logo.name }}</a>
												</div>
											</div>
										</div>
										<div *ngIf="appStateService.currentClient.HasCustomFonts" class="row mgn-top60">
											<div class="col-xs-12">
												<h4 class="gray-text-darkest ft-size14">CUSTOM FONTS</h4>
												<div>
													{{ appStateService.currentClient.CustomFontNotes }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-4 relative">
						<h3 class="semi-bold">Important Notes</h3>
						<portal-client-notes [rowCount]="10"></portal-client-notes>
					</div>
				</div>
			</section>
		</div>
		<div *ngIf="!securityService.userRoleSalesperson() && clientHealthDashboardService.statusCircles?.length > 0" class="col-md-2 relative">
			<section [ngStyle]="{ 'height.px': playerHealthBoxHeight }" class="white-bg mgn-top50 border-radius scrollable pd15 shadow relative">
				<div class="row mgn-bottom15">
					<div class="col-sm-12">
						<h3 (click)="onClientDashboardClick()" id="player-health-title" style="text-decoration: underline" class="semi-bold in-block pointer">
							Player Health
						</h3>
					</div>
				</div>

				<div class="ht1 gray-bg-dark setWidth100 mgn-right0 mgn-left0 border-radius mgn-bottom10"></div>

				<div class="mgn-top15 noExit">
					<div
						*ngIf="clientHealthViewService.hoveredClientOrPlayer || clientHealthViewService.persistInfoBox"
						style="border-radius: 10px; z-index: 100"
						id="clientInfoBox"
						[ngStyle]="{ 'top.px': clientHealthViewService.darkInfoBoxTop, 'left.px': clientHealthViewService.darkInfoBoxLeft }"
						class="dark-bg noExit {{
							clientHealthViewService.persistInfoBox ? clientHealthViewService.hoveredClientOrPlayer.gumballColorView + '-border' : ''
						}} white-text pd15 wdth400 fixed ft-size14"
					>
						<i
							*ngIf="clientHealthViewService.persistInfoBox"
							(click)="clientHealthViewService.onXClick()"
							class="far fa-times-circle ft-size32 pointer absolute top5 right5 white-text"
						></i>
						<ng-container *ngIf="clientHealthDashboardService.isGumballPlayers(clientHealthViewService.hoveredClientOrPlayer)">
							<h3 class="semi-bold in-block mgn-right10 noExit">
								{{ clientHealthViewService.hoveredClientOrPlayer.PlayerName }}

								<div
									style="border-radius: 50%; height: 16px; width: 16px"
									class="in-block {{ clientHealthViewService.hoveredClientOrPlayer.gumballColorView }} relative left10 shadow noExit"
								>
									<i
										*ngIf="clientHealthViewService.hoveredClientOrPlayer.showX"
										style="pointer-events: none"
										class="fas fa-times absolute center-unknown-height-width ft-size12 white-text"
									></i>
								</div>
							</h3>

							<div class="row mgn-top15 noExit">
								<div class="col-sm-7 noExit">
									<span class="noExit">Product:</span>
								</div>
								<div class="col-sm-5 noExit">
									<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.ProductTypeName }}</span>
								</div>
							</div>
							<div class="row mgn-top5 noExit">
								<div class="col-sm-7 noExit">
									<span class="noExit">Model Name:</span>
								</div>
								<div class="col-sm-5 noExit">
									<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.ModelName }}</span>
								</div>
							</div>
							<div class="row mgn-top5 noExit">
								<div class="col-sm-7 noExit">
									<span class="noExit">Last Check In:</span>
								</div>
								<div class="col-sm-5 noExit">
									<span class="semi-bold noExit">{{ dateTimeService.dateAndTimeUTCtoLocal(clientHealthViewService.hoveredClientOrPlayer.LastCheckin) }}</span>
								</div>
							</div>
							<div class="row mgn-top5 noExit">
								<div class="col-sm-7 noExit">
									<span class="noExit">Last Playlist Update:</span>
								</div>
								<div class="col-sm-5 noExit">
									<span class="semi-bold noExit">
										{{ dateTimeService.dateAndTimeUTCtoLocal(clientHealthViewService.hoveredClientOrPlayer.LastPlaylistUpdate) }}
									</span>
								</div>
							</div>
							<div class="row mgn-top5 noExit">
								<div class="col-sm-7 noExit">
									<span class="noExit">Location Name:</span>
								</div>
								<div class="col-sm-5 noExit">
									<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.ServiceLocationName }}</span>
								</div>
							</div>
							<div class="row mgn-top5 noExit">
								<div class="col-sm-7 noExit">
									<span class="noExit">Hostname:</span>
								</div>
								<div class="col-sm-5 noExit">
									<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.Hostname }}</span>
								</div>
							</div>
							<div class="row mgn-top5 noExit">
								<div class="col-sm-7 noExit">
									<span class="noExit">Unit ID:</span>
								</div>
								<div class="col-sm-5 noExit">
									<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.PlayerUnitIdentifier }}</span>
								</div>
							</div>
							<div class="row mgn-top5 noExit">
								<div class="col-sm-7 noExit">
									<span class="noExit">Install State:</span>
								</div>
								<div class="col-sm-5 noExit">
									<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.InstallState }}</span>
								</div>
							</div>
							<div class="row mgn-top5 noExit">
								<div class="col-sm-7 noExit">
									<span class="noExit">Repair State:</span>
								</div>
								<div class="col-sm-5 noExit">
									<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.RepairState }}</span>
								</div>
							</div>
							<div class="row mgn-top5 noExit">
								<div class="col-sm-7 noExit">
									<span class="noExit">Current Playlist:</span>
								</div>
								<div class="col-sm-5 noExit">
									<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.CurrentlyPlayingProductionName }}</span>
								</div>
							</div>
							<div class="row mgn-top5 noExit">
								<div class="col-sm-7 noExit">
									<span class="noExit">Reported Version:</span>
								</div>
								<div class="col-sm-5 noExit">
									<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.ReportedPlayerVersion }}</span>
								</div>
							</div>
							<div class="row mgn-top5 noExit">
								<div class="col-sm-7 noExit">
									<span class="noExit">Current Release Version:</span>
								</div>
								<div class="col-sm-5 noExit">
									<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.CurrentReleasePlayerVersion }}</span>
								</div>
							</div>
							<div class="row mgn-top15 noExit">
								<div class="col-sm-7 noExit">
									<span class="noExit">Rule Failure List:</span>
								</div>
							</div>
							<div class="row">
								<div class="col-xs-12">
									<span class="semi-bold noExit" [innerHTML]="clientHealthViewService.hoveredClientOrPlayer.ruleFailureListView"></span>
								</div>
							</div>
						</ng-container>
					</div>
					<div
						*ngFor="let circle of clientHealthDashboardService.statusCircles"
						id="{{ circle.PlayerId }}"
						(click)="clientHealthViewService.onPlayerCircleClick(true, circle)"
						(mouseover)="onPlayerCircleHover(circle)"
						(mouseleave)="clientHealthViewService.onPlayerCircleLeave()"
						style="border-radius: 50%; height: 16px; width: 16px"
						class="in-block noExit {{ circle.gumballColorView }} relative shadow mgn-bottom10 mgn-right10"
					>
						<i *ngIf="circle.showX" style="pointer-events: none" class="fas fa-times absolute center-unknown-height-width ft-size12 white-text"></i>
					</div>
				</div>
			</section>
		</div>
	</div>

	<section class="white-bg mgn-top50 border-radius pd15 mgn-bottom60 shadow">
		<!-- NAV BAR -->
		<nav class="mgn-right0 mgn-left0">
			<div class="mgn-bottom-xs in-block mgn-right30">
				<button (click)="onTabBtnClick('locations')" class="{{ activeTab === 'locations' ? 'gray-bg-darker' : 'gray-bg' }} pd-right20 pd-left20">
					<i class="fas fa-map-marker-alt mgn-right10"></i>
					Locations
				</button>
			</div>
			<div class="mgn-bottom-xs in-block mgn-right30">
				<button (click)="onTabBtnClick('history-notes')" class="{{ activeTab === 'history-notes' ? 'gray-bg-darker' : 'gray-bg' }} pd-right20 pd-left20">
					<i class="fas fa-history mgn-right10"></i>
					History Notes
				</button>
			</div>
			<div class="mgn-bottom-xs in-block mgn-right30">
				<button (click)="onTabBtnClick('libraries')" class="{{ activeTab === 'libraries' ? 'gray-bg-darker' : 'gray-bg' }} pd-right20 pd-left20">
					<i class="fas fa-book mgn-right10"></i>
					Content Libraries
				</button>
			</div>
			<div class="mgn-bottom-xs in-block mgn-right30">
				<button (click)="onTabBtnClick('contracts')" class="{{ activeTab === 'contracts' ? 'gray-bg-darker' : 'gray-bg' }} pd-right20 pd-left20">
					<i class="fas fa-file-signature mgn-right10"></i>
					Contracts
				</button>
			</div>
			<div class="mgn-bottom-xs in-block mgn-right30">
				<button
					(click)="onTabBtnClick('content-requests-by-client')"
					class="{{ activeTab === 'content-requests-by-client' ? 'gray-bg-darker' : 'gray-bg' }} pd-right20 pd-left20"
				>
					<i class="far fa-paper-plane mgn-right10"></i>
					Content Requests
				</button>
			</div>
			<div class="mgn-bottom-xs in-block mgn-right30">
				<button
					(click)="onTabBtnClick('csr-tasks-by-client')"
					class="{{ activeTab === 'csr-tasks-by-client' ? 'gray-bg-darker' : 'gray-bg' }} pd-right20 pd-left20"
				>
					<i class="fas fa-tasks mgn-right10"></i>
					Tasks
				</button>
			</div>
			<div class="mgn-bottom-xs in-block mgn-right30">
				<button (click)="onTabBtnClick('tickets')" class="{{ activeTab === 'tickets' ? 'gray-bg-darker' : 'gray-bg' }} pd-right20 pd-left20">
					<i class="fas fa-ticket-alt mgn-right10"></i>
					Tickets
				</button>
			</div>
		</nav>
		<div class="ht1 green-limeGreen-bg-gradient setWidth100 border-radius mgn-bottom60 mgn-top15"></div>

		<!-- INDIVIDUAL TAB VIEWS -->
		<div class="row mgn-top15 relative mgn-right0 mgn-left0">
			<router-outlet></router-outlet>
		</div>
	</section>
</div>
