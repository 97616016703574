import { Component, Input, OnInit } from '@angular/core';

import { AppStateService, AudioPreviewService, ContentSerializerService, FileUploadService, SecurityService } from 'src/app/core/services';
import { ContentFilesVM } from '../../_models';
import { DetailsService } from '../../_services';
import { ContentVoiceTalents } from 'src/app/shared/api-models/content';
import { FileUploadStateService } from 'src/app/shared/services';
import { ContentVM } from '../../_models/content-view';

@Component({
	selector: 'd-content-file',
	templateUrl: './content-file.component.html',
	styleUrls: ['./content-file.component.scss']
})
export class ContentFileComponent implements OnInit {
	@Input() content: ContentVM;
	@Input() contentFile: ContentFilesVM;
	@Input() index: number;

	public deleteReplaceVisible: boolean;
	public deleteTooltipVisible: boolean;
	public replaceTooltipVisible: boolean;
	public selectedIndex: number;

	private deleteTimeout: any;
	private replaceTimeout: any;

	get voiceTalent() {
		const contentFile = this.contentFile as ContentFilesVM;
		return contentFile.VoiceTalent;
	}

	get contentFileHold() {
		return this.contentFile as ContentFilesVM;
	}

	constructor(
		private appStateService: AppStateService,
		private audioPreviewService: AudioPreviewService,
		private contentSerializerService: ContentSerializerService,
		public detailsService: DetailsService,
		public fileUploadService: FileUploadService,
		public fileUploadStateService: FileUploadStateService,
		private securityService: SecurityService
	) {}

	ngOnInit() {
		this.writeAccessCheck();
	}

	onPreviewClick(): void {
		this.audioPreviewService.contentItemAudioPreview(
			this.contentSerializerService.contentFileToContentItem(this.content, this.contentFile),
			this.content.contentFiles as ContentFilesVM[]
		);
		this.contentFile.previewActive = !this.contentFile.previewActive;
	}

	onVoiceTalentSelect(voiceTalent: ContentVoiceTalents): void {
		const contentFile = this.contentFile as ContentFilesVM;
		contentFile.VoiceTalentId = voiceTalent.Id;
		contentFile.VoiceTalent.TalentName = voiceTalent.TalentName;
		contentFile.VoiceTalent.TalentGender = voiceTalent.TalentGender;
		contentFile.previewBtnIcon = voiceTalent.TalentGender === 'Male' ? 'fa-male' : 'fa-female';
		contentFile.iconColor = voiceTalent.TalentGender === 'Male' ? 'blue-text' : 'pink-text';
		contentFile.isDirty = true;
	}

	public onDeleteClick(): void {
		const contentFile = this.contentFile as ContentFilesVM;
		const confirmOk: boolean = confirm(`Are you sure you want to delete content file "${contentFile.VoiceTalent.TalentName}"?`);
		if (confirmOk) {
			this.detailsService.deleteContentFile(this.contentFile, this.content);
		}
	}

	public onReplaceInputClick(): void {
		this.fileUploadStateService.replacementProps = { contentFileId: this.contentFile.Id, targetLibraryId: this.content.LibraryId };
		this.fileUploadService.onReplaceInputClick();
	}

	public replaceButtonVisible(): boolean {
		return this.appStateService.currentUser.IsEmployee && this.securityService.contentManagerCsrOrAdmin();
	}

	private writeAccessCheck(): void {
		this.detailsService.writeAccessCheck(this.content, this.contentFile as ContentFilesVM).subscribe((writeAccess: boolean) => {
			this.contentFile.writeAccess = writeAccess;
		});
	}

	public onHoverBtnMouseenter(prop: string): void {
		if (prop === 'replace') {
			this.replaceTimeout = setTimeout(() => (this.replaceTooltipVisible = true), 400);
			clearTimeout(this.deleteTimeout);
			this.deleteTooltipVisible = false;
		}
		if (prop === 'delete') {
			this.deleteTimeout = setTimeout(() => (this.deleteTooltipVisible = true), 400);
			clearTimeout(this.replaceTimeout);
			this.replaceTooltipVisible = false;
		}
	}

	public onHoverBtnMouseleave(): void {
		this.replaceTooltipVisible = false;
		this.deleteTooltipVisible = false;
		clearTimeout(this.replaceTimeout);
		clearTimeout(this.deleteTimeout);
	}
}
