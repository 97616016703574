import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { AppStateService, Events, MessageService, CacheService, UtilitiesService } from '../../../core/services';
import { Column } from '../../../shared/components/table/view-models';
import { LocationDetailsService, TableService, TestPlaylistService } from './_services';
import { PlayersView } from './_models';
import { ServiceLocations, Users } from '../../../shared/api-models/admin';
import { ClientErrorLog } from 'src/app/shared/api-models/log';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-location-details',
	templateUrl: './location-details.component.html',
	styleUrls: ['./location-details.component.scss']
})
export class LocationDetailsComponent implements OnInit {
	public clientCsr: Users;
	public errorLogModalActive: boolean;
	public location: ServiceLocations;

	public holdTableData: PlayersView[];
	public otherPlayersTableData: PlayersView[];
	public postersTableData: PlayersView[];
	public radioTableData: PlayersView[];
	public videoTableData: PlayersView[];

	public errorLogs$: Observable<ClientErrorLog[]>;
	public selectedPlayer: PlayersView;

	private locationId: number | null;

	constructor(
		public appStateService: AppStateService,
		public loc: Location,
		private locationDetailsService: LocationDetailsService,
		private messageService: MessageService,
		private router: Router,
		public tableService: TableService,
		private testPlaylistService: TestPlaylistService,
		private cacheService: CacheService,
		private utilService: UtilitiesService
	) {}

	ngOnInit() {
		this.locationId = this.appStateService.getSessionItem('locationId') ? JSON.parse(this.appStateService.getSessionItem('locationId')) : null;
		if (this.locationId) {
			this.locationDetailsService.getCsr(this.appStateService.currentClient.CsrId).subscribe((csr: Users) => {
				this.clientCsr = csr;
				this.cacheService.setUserCache(csr);
			});

			this.getLocationAndPlayerData(this.locationId);
		} else {
			this.router.navigate(['/404']);
		}
	}

	public onEditLocationClick(): void {
		this.appStateService.setSessionItem('locationId', JSON.stringify(this.locationId));
		this.router.navigate(['/crm/edit-location']);
	}

	onTableButtonClick(btnEvent: [Column, any, string]): void {
		const column: Column = btnEvent[0];
		const player: PlayersView = btnEvent[1];

		switch (column['key']) {
			case 'editBtn':
				this.appStateService.setSessionItem('playerId', JSON.stringify(player.Id));
				this.appStateService.setSessionItem('locationId', JSON.stringify(this.locationId));
				this.router.navigate([`/crm/client-details/locations/location-details/edit-player`]);
				break;
			case 'deleteBtn':
				let confirmDelete: boolean = confirm(`Delete player "${player.PlayerName}"? You cannot undo this action.`);
				if (confirmDelete) {
					this.messageService.publish(Events.savingPreloader, 1);
					this.locationDetailsService.deletePlayer(player).subscribe(() => {
						this.getLocationAndPlayerData(this.location.Id);
						this.messageService.publish(Events.savingPreloader, 0);
					});
				}
				break;
			case 'errorLogsBtn':
				this.selectedPlayer = player;
				this.errorLogModalActive = true;
				this.utilService.scrollToTop();
				this.errorLogs$ = this.locationDetailsService.getErrorLogs(player.UnitIdentifier);
				break;
			case 'schedulePlaylist':
				this.testPlaylistService.createAndSchedulePlaylist(player, this.location).subscribe();
				break;
		}
	}

	private getLocationAndPlayerData(locationId: number): void {
		this.locationDetailsService.getLocationAndPlayerData(locationId).subscribe((location: ServiceLocations) => {
			this.location = location;
			if (this.location.players) {
				location.players.forEach((player) => {
					if (this.hideErrorLogsButton(player)) {
						player['columnDisabled'] = 'true';
					}
				});
				this.holdTableData = location.players.filter((player) => player.ProductTypeId === 1);
				this.otherPlayersTableData = location.players.filter((player) => [1, 2, 3, 4].indexOf(player.ProductTypeId) < 0);
				this.postersTableData = location.players.filter((player) => player.ProductTypeId === 4);
				this.radioTableData = location.players.filter((player) => player.ProductTypeId === 2);
				this.videoTableData = location.players.filter((player) => player.ProductTypeId === 3);
			}
		});
	}

	private hideErrorLogsButton(player: PlayersView): boolean {
		const playerModelIdsToExclude: number[] = [2, 3, 4, 5, 6, 8, 11, 13, 14, 15, 18, 55];
		return playerModelIdsToExclude.some((playerModelId) => playerModelId === player.PlayerModelId);
	}
}
