import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DateTimeService } from '../../../core/services/date-time.service';
import { environment } from '../../../../environments/environment';
import { FeedBadWordList, FeedItemsView, ViewState } from '../_models';

@Injectable({
	providedIn: 'root'
})
export class NewsManagerService {
	public newBadWord: string;
	public totalItems: number;
	public viewState: ViewState = new ViewState();

	constructor(private dateTimeService: DateTimeService, private httpClient: HttpClient) {}

	public delete(url: string): Observable<any> {
		return this.httpClient.delete(url);
	}

	public get(url: string): Observable<any> {
		return this.httpClient.get(url);
	}

	public put(url: string, obj: any): Observable<any> {
		return this.httpClient.put(url, obj);
	}

	public getFeedItems(): Observable<any> {
		return this.httpClient.get(this.url(), { observe: 'response' }).pipe(
			map((res: HttpResponse<any>) => {
				this.totalItems = parseInt(res.headers.get('x-result-count'));
				let feedItems: FeedItemsView[] = res.body;
				return this.feedItemsView(feedItems);
			})
		);
	}

	public postBadWord(): Observable<any> {
		return this.httpClient.post(`${environment.contentUrl}FeedBadWordList`, { BadWord: this.newBadWord });
	}

	public deleteBadWord(selectedBadWord: FeedBadWordList): Observable<any> {
		return this.httpClient.delete(`${environment.contentUrl}FeedBadWordList/${selectedBadWord.Id}`);
	}

	public feedItemsView(feedItems: FeedItemsView[]): FeedItemsView[] {
		return feedItems.map((feedItem) => {
			feedItem.headline = JSON.parse(feedItem.ItemContent).Title;
			feedItem.body = JSON.parse(feedItem.ItemContent).Body;
			feedItem.feedName = this.viewState.selectedFeed.name;
			feedItem.editBtn = 'Edit';
			feedItem.disableBtn = feedItem.IsEnabled ? 'Disable' : 'Enable';
			feedItem.itemContentView = `<span class="semi-bold">${JSON.parse(feedItem.ItemContent).Title}</span> <br> ${JSON.parse(feedItem.ItemContent).Body}`;
			feedItem.disabledIcon = feedItem.IsEnabled ? '' : 'fas fa-check';
			feedItem.rowBgColor = feedItem.IsEnabled ? '' : 'purple-bg-light-theme';
			feedItem.startDateView = feedItem.StartDate ? this.dateTimeService.dateAndTimeUTCtoLocal(feedItem.StartDate) : null;
			feedItem.stopDateView = feedItem.StopDate ? this.dateTimeService.dateAndTimeUTCtoLocal(feedItem.StopDate) : null;
			feedItem['classList'] = 't-row white-bg';
			feedItem.createDate = this.dateTimeService.dateLocal(feedItem.CreateDate);
			return feedItem;
		});
	}

	public url(): string {
		if (this.viewState.searchTerm && this.viewState.searchTerm.length > 0) {
			return `${environment.contentUrl}Feeds/${this.viewState.selectedFeed.Id}/Search/${this.viewState.searchTerm}?pageSize=25&pageNumber=${this.viewState.pageNumber}`;
		}
		return `${environment.contentUrl}Feeds/${this.viewState.selectedFeed.Id}/FeedItems?orderByCol=${this.viewState.orderByCol}&orderDesc=${this.viewState.orderDesc}&pageSize=25&pageNumber=${this.viewState.pageNumber}`;
	}
}
