import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { AdminDataService, AppStateService, ClientSwitcherService, UtilitiesService } from '../core/services';
import { ClientNewsletter } from '../shared/api-models/content';
import { ClientNewsletterService } from '../client-newsletter/client-newsletter.service';
import { MyProductsStore } from './my-products.store';
import { PromoBarService } from './promo-bar/promo-bar.service';
import { UserRole } from '../shared/api-models/admin/user-role.enum';

@Component({
	selector: 'app-my-products',
	templateUrl: './my-products.component.html',
	styleUrls: ['./my-products.component.scss'],
	providers: [MyProductsStore]
})
export class MyProductsComponent implements OnInit {
	public showPopup: boolean;
	public previewPopup: boolean;
	private prevUrl: string;
	public showPromoBarEditBtn: boolean;
	public showPromoOptionsMenu: boolean;
	readonly enumUserRole = UserRole;

	constructor(
		public adminDataService: AdminDataService,
		public appStateService: AppStateService,
		public clientNewsletterService: ClientNewsletterService,
		private clientSwitcherService: ClientSwitcherService,
		public myProductsStore: MyProductsStore,
		public promoBarService: PromoBarService,
		private router: Router,
		private title: Title,
		private utilService: UtilitiesService
	) {
		this.prevUrl = this.router.getCurrentNavigation().previousNavigation?.finalUrl.toString();
	}

	ngOnInit(): void {
		this.showPopup = this.prevUrl?.includes('login') || this.prevUrl?.includes('user-sign-out');
		//Code below is for switching clients and opening a new tab
		//See ClientSwitcherService for details
		const url = this.router.routerState.snapshot.url;
		const clientId: number = +this.utilService.getLastParam(url);

		if (!Number.isNaN(clientId)) {
			this.clientSwitcherService.onClientChange({ CsrId: null, Id: clientId, Name: null });
			return;
		}
		this.myProductsStore.getProducts$();
		this.clientNewsletterService.getNewsletter();

		//Set page title
		this.title.setTitle(JSON.parse(this.appStateService.getSessionItem('currentClient')).Name);
	}

	public hidePopup(): void {
		this.showPopup = false;
		this.previewPopup = false;
	}

	//TODO: refactor, this is a duplicate method from popup component
	public onEnableOrDisablePopupClick(newsletter: ClientNewsletter): void {
		const isActive = newsletter.ProductsPagePopupActive;
		if (!isActive) {
			const isOk = confirm('Active the popup for customers?');
			if (isOk) {
				const updatedNewsletter = { ...newsletter, ProductsPagePopupActive: true };
				this.clientNewsletterService.updateNewsletter(updatedNewsletter);
			}
		} else {
			const updatedNewsletter = { ...newsletter, ProductsPagePopupActive: false };
			this.clientNewsletterService.updateNewsletter(updatedNewsletter);
		}
	}

	public onTitleOver(): void {
		if (this.appStateService.currentUser.Role === UserRole.ADMINISTRATOR) {
			this.showPromoBarEditBtn = true;
		}
	}
}
