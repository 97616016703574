<div class="row">
    <div *ngIf="appStateService.currentUser.IsEmployee" class="col-lg-3">
        <!--DROPDOWN FOR CHANGING CATEGORIES FOR MULTIPLE CONTENT ITEMS -->
            <portal-dropdown-menu
                                [backgroundColor]="'gray-bg-dark'"
                                [height]="'ht40'"
                                [key]="'LibraryName'"
                                [options]="batchChangeCategoryService.libraries"
                                [selectedOption]="'Select a library...'"
                                (onOptionSelect)="batchChangeCategoryService.onLibrarySelect($event)">
            </portal-dropdown-menu>
    </div>
    
    <div class="col-lg-3">
        <!--DROPDOWN FOR CHANGING CATEGORIES FOR MULTIPLE CONTENT ITEMS -->
            <portal-dropdown-menu
                                [backgroundColor]="'gray-bg-dark'"
                                [height]="'ht40'"
                                [key]="'CategoryName'"
                                [options]="batchChangeCategoryService.parentCategories"
                                [selectedOption]="'Select a category...'"
                                (onOptionSelect)="batchChangeCategoryService.onParentCategorySelect($event)">
            </portal-dropdown-menu>
    </div>

    <div *ngIf="subCategoryMenuVisible()" class="col-lg-3">
        <!--DROPDOWN FOR CHANGING CATEGORIES FOR MULTIPLE CONTENT ITEMS -->
            <portal-dropdown-menu
                                [backgroundColor]="'gray-bg-dark'"
                                [height]="'ht40'"
                                [key]="'CategoryName'"
                                [options]="batchChangeCategoryService.filteredSubCategories"
                                [selectedOption]="'Select a sub-category...'"
                                (onOptionSelect)="batchChangeCategoryService.onSubCategorySelect($event)">
            </portal-dropdown-menu>
    </div>

    <div class="col-lg-3">
        <button class="limeGreen-bg border-radius white-text setWidth100 {{!batchChangeCategoryService.selectedParentCategory?.Id ? 'setOpacityLow' : null}}" [disabled]="!batchChangeCategoryService.selectedParentCategory?.Id" (click)="batchChangeCategoryService.onMoveToCategoryClick()">Move to Category</button>
    </div>
</div>
