import { InstallersView } from '../../_models';
import { WorkOrders } from '../../../../shared/api-models/admin';

export class WorkOrdersView extends WorkOrders {
	completeBoxVisible: boolean;
	createdByUsername: string;
	Installer: InstallersView;
	locationsList: any[];
	productTypesList: any[];
	productTypeName: string;
	sentDateView: string;
	scheduledDatePickerView: string;
	scheduledDateFriendlyView: string;
	currentUserFriendlyName: string;
	ticketTypeName: string;
	holdPlayerRemoteNumber: string;
	mode: 'edit' | 'new';
}