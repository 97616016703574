import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppStateService } from '../app-state.service';
import { AuthUser } from '../../../shared/api-models/auth/auth-user';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

export const contentManagerGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
	const appStateService = inject(AppStateService);
	const router = inject(Router);

	const currentUser: AuthUser = JSON.parse(appStateService.getSessionItem('currentUser'));

	if (currentUser.Role !== UserRole.CONTENT_MANAGER && currentUser.Role !== UserRole.USER) {
		return true;
	} else {
		router.navigate(['/access-denied']);
		return false;
	}
};
