<portal-dropdown-menu
				   [backgroundColor]="'dark-bg'"
				   [fontSize]="'ft-size20'"
				   [height]="'ht68'"
				   [hideBorder]="true"
				   [hideSquareSelectButton]="true"
				   [listMaxHeight]="'max-height424'"
				   [menuBackgroundColor]="'lt-gray-bg'"
				   [options]="searchResults"
				   [key]="'Name'"
				   [searchEnabled]="true"
				   [selectedOption]="currentClientName"
				   [showClientBrowserBtn]="true"
				   [textColor]="'white-text'"
				   (onOptionSelect)="onClientChange($event)"
				   (onSearchKeyupNotify)="onClientSearchKeyup($event)">
</portal-dropdown-menu>