import { PlaylistItemJson } from './playlist-item-json';

export class PlaylistJson {
	PlaylistId: number;
	ClientId: number;
	UserId: number;
	ProductId: number;
	PlaylistName: string;
	Version: string;
	ParagraphPauseMs: number;
	RampDownMs: number;
	RampUpMs: number;
	PlaylistItems: PlaylistItemJson[];
	FeedId: number;
}