import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'propFilter'})

export class PropFilterPipe implements PipeTransform {

    transform(list : any, keyValue: [string, string]): any[] {
        if (list) {
            return list.filter((object: any) => object[keyValue[0]] === keyValue[1]);
        }
    }
}