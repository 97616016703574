import { PipeTransform, Pipe } from '@angular/core';

import { UtilitiesService } from '../../core/services/utilities.service';

@Pipe({
	name: 'searchFilter',
	pure: false
})



export class SearchFilterPipe implements PipeTransform {

	constructor(private utilService: UtilitiesService) { }

	//take in any data array, filter it against user input (term)
	transform(items: any, term: any): any {

		if (term === undefined) return items;
		if (items) {
			let filteredItems = items.filter((item) => {

				for (let property in item) {
					if (item[property] === (null || undefined)) {
						continue;
					}
					if (item[property]) {
						// item[property].toString().toLowerCase().includes(term.toLowerCase())	        	
						if (this.utilService.includes(item[property].toString().toLowerCase(), term.toLowerCase())) {
							return true;
						}
					}
				}
				return false;
			});
			return filteredItems;
		}
	}
}

