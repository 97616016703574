import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { Users } from '../../../shared/api-models/admin';
import { Comment } from '../_models';
import { CreateEditRequestService } from '../_services';
import { environment } from '../../../../environments/environment';
import { CacheService, UtilitiesService } from 'src/app/core/services';

@Injectable({
    providedIn: 'root'
})
export class CommentsService {

    constructor(
        private createEditRequestService: CreateEditRequestService,
        private httpClient: HttpClient,
        private cacheService: CacheService,
        private utilService: UtilitiesService
    ) { }

    public getComments(): void {
        if(this.createEditRequestService.request.Id) {
            this.httpClient.get(`${environment.adminUrl}ContentRequests/${this.createEditRequestService.request.Id}/Comments`).pipe(
                switchMap( (comments: Comment[]) => {
                    const users$ = comments.map( (comment) => {
                        const apiCall = this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${comment.UserId}`);
                        return this.cacheService.user$(apiCall, comment.UserId);
                    })
                    return forkJoin(users$).pipe(
                        map(users => [users, comments])
                    )
                })
            ).subscribe( (payload: [Users[], Comment[]]) => {
                const [users, comments] = payload;
                this.cacheService.setUserCache(users);
                this.createEditRequestService.request.comments = this.utilService.sortItems(this.comments(comments, users), 'CreateDateTime');
            })
            return;
        }
        this.createEditRequestService.request.comments = [];
    }

    private comments(comments: Comment[], users: Users[]): Comment[] {
        return comments.map( (comment, index) => {
            comment.userName = users[index].UserName;
            return comment;
        })
    }
}
