import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

//Override the canvas.toSVG() function to include the layerId
export class ToSVGOverrideService {

    constructor() { }

    public override(obj: any): void {
        obj.toSVG = (function (toSVG) {
            return function () {
                var svgString = toSVG.call(this);
                var domParser = new DOMParser();
                if (obj.name === 'image') {
                    svgString = svgString.replace('xlink:', ''); //dom parser won't work on images if 'xlink:' is left in
                }
                var doc = domParser.parseFromString(svgString, 'image/svg+xml');
                var parentG = doc.querySelector(obj.name);
                parentG.setAttribute('layerId', obj.layerId.toString());
                parentG.setAttribute('name', obj.name);
                parentG.setAttribute('isFeedText', obj.isFeedText);
                parentG.setAttribute('isFeedTextImage', obj.isFeedTextImage);
                parentG.setAttribute('isFeedImage', obj.isFeedImage);
                return doc.documentElement.outerHTML;
            }
        })(obj.toSVG)
    }
}
