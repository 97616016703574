import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { AppStateService, Events, MessageService } from '../../../core/services';
import { ClientHistoryNotes } from '../../../shared/api-models/log';
import { EntryTypesService } from './entry-types.service';
import { environment } from '../../../../environments/environment';
import { LocationsService } from '../../locations/locations.service';
import { ServiceLocations } from '../../../shared/api-models/admin';

type Product = {
	name: string;
};

@Component({
	selector: 'hn-create-history-note',
	templateUrl: './create-history-note.component.html',
	styleUrls: ['./create-history-note.component.scss'],
	providers: [LocationsService]
})
export class CreateHistoryNoteComponent implements OnInit {
	public historyNote: ClientHistoryNotes;
	public locations: ServiceLocations[];
	public products: Product[] = [{ name: 'Hold Service' }, { name: 'Music Service' }, { name: 'Video Service' }, { name: 'User Portal' }, { name: 'Other' }];

	constructor(
		public appStateService: AppStateService,
		public entryTypesService: EntryTypesService,
		private httpClient: HttpClient,
		private locationsService: LocationsService,
		private messageService: MessageService,
		private router: Router
	) {}

	ngOnInit() {
		this.historyNote = new ClientHistoryNotes();
		this.historyNote.ClientId = this.appStateService.currentClient.Id;
		this.historyNote.UserId = this.appStateService.currentUser.UserId;
		this.historyNote.EntryType = 'Appointment';
		this.historyNote.EntrySource = 'Hold Service';
		this.getLocations();
	}

	public onDropdownSelect(obj: any, prop: string): void {
		if (prop === 'ServiceLocationId') {
			if (obj.Id) {
				this.historyNote.ServiceLocationId = obj.Id;
				this.historyNote.LogSubject = obj.name;
			} else {
				this.historyNote.LogSubject = '';
			}
			return;
		}
		this.historyNote[prop] = obj.name;
	}

	public onSaveClick(): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.httpClient.post(`${environment.adminUrl}ClientHistoryNotes`, this.historyNote).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
			if (this.appStateService.currentUser.UserId !== this.appStateService.currentClient.CsrId) {
				this.sendEmail(this.historyNoteEmail());
			}
			this.router.navigate(['/crm/client-details/history-notes']);
		});
	}

	private getLocations(): void {
		this.locationsService.getAllLocationsForClient().subscribe((locations) => {
			locations.unshift({ name: 'General (All locations)' });
			this.locations = locations;
		});
	}

	private sendEmail(params: any): void {
		const obj = {
			To: params.recipient,
			Cc: params.cc,
			From: params.from,
			Subject: params.subject,
			Body: params.body
		};

		//send email
		this.httpClient.post(environment.adminUrl + 'Email', obj).subscribe();
	}

	private historyNoteEmail(): any {
		return {
			from: `newhistorynote@works24.com`,
			recipient: this.appStateService.csrByClient.Email,
			subject: `New History for ${this.appStateService.currentClient.Name} by ${this.appStateService.currentUser.UserName}`,
			body:
				`TEXT OF NEW HISTORY NOTE MADE BY ${this.appStateService.currentUser.UserName} \n` +
				`----------------------------------------------------------------------------- \n \n` +
				`Subject: ${this.historyNote.LogSubject} \n \n` +
				`Note: ${this.historyNote.LogMessage} \n` +
				`-----------------------------------------------------------------------------`
		};
	}
}
