import { PlaylistContentFileBridge, Playlists } from "../../api-models/content";
import { DeliveryQueue } from "../../api-models/delivery";
import { PlaylistFiles } from "./playlist-files";

export class CompletePlaylist {
    DeliveryQueue: DeliveryQueue[];
    Playlist: Playlists;
    PlaylistFileBridge: PlaylistContentFileBridge[];
    PlaylistFiles: PlaylistFiles[];
    PlaylistStatus: 'New' | 'Inactive' | 'Active' | 'Scheduled' | 'Draft';
}