<ng-container *ngIf="layersService.activeLayer.type === 'Feed Text'">
    <div class="row mgn-top30">
        <div class="col-xs-12">
            <span class="ft-size16">Feed Source</span>
        </div>
    </div>
    <div class="row mgn-top15 mgn-bottom60">
        <div class="col-xs-12">
            <portal-dropdown-menu
                        [backgroundColor]="'white-bg'"
                        [height]="'ht40'"
                        [key]="'name'"
                        [options]="[{name: 'Title'}, {name: 'Body'}]"
                        [selectedOption]="layersService.activeLayer.feedSourceName"
                        (onOptionSelect)="onFeedSourceSelect($event)">
            </portal-dropdown-menu>
        </div>
    </div>
    
    <ld-text></ld-text>
</ng-container>

