import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AdminDataService, AppStateService, Events, MessageService, SecurityService, StatesListService, UtilitiesService } from '../../core/services';
import { CreateEditClientService, FormValidationService, SaveService } from './_services';
import { ProductTypes, ServiceLocations } from '../../shared/api-models/admin';

@Component({
	selector: 'app-create-edit-client',
	templateUrl: './create-edit-client.component.html',
	styleUrls: ['./create-edit-client.component.scss']
})
export class CreateEditClientComponent implements OnInit {
	public currentCsr: string;
	public currentSalesman: string;
	public invalidZip: boolean;
	public rootPath: string;
	public selectedProductType: ProductTypes;
	public statesList: any[];

	private contractTempId: number = 0;
	private zipChanged: Subject<string> = new Subject<string>();

	get showMfaCheckbox() {
		return this.securityService.userRoleAdminOrCsr();
	}

	constructor(
		private adminDataService: AdminDataService,
		public appStateService: AppStateService,
		public createEditClientService: CreateEditClientService,
		public formValidationService: FormValidationService,
		public location: Location,
		private messageService: MessageService,
		private router: Router,
		private saveService: SaveService,
		private securityService: SecurityService,
		public statesListService: StatesListService,
		private utilService: UtilitiesService
	) {
		this.zipChanged.pipe(debounceTime(400)).subscribe(() => {
			this.validateZip();
		});
	}

	ngOnInit(): void {
		this.statesList = this.statesListService.statesList;
		if (this.utilService.includes(this.appStateService.activeUrl, 'edit')) {
			this.createEditClientService.mode = 'edit';
			this.createEditClientService.client = { ...JSON.parse(this.appStateService.getSessionItem('currentClient')) };
			this.createEditClientService.unmodifiedClient = { ...JSON.parse(this.appStateService.getSessionItem('currentClient')) };
			this.getCsrAndSalesRepByClient();
			this.formValidationService.allContractsValid = true;
		} else {
			this.createEditClientService.mode = 'new';
			this.createEditClientService.initialServiceLocation = new ServiceLocations();
			this.createEditClientService.newClientInit();
			this.createEditClientService.productTypeInit();
		}
		this.createEditClientService.allTheGets();
	}

	onDropdownSelect(value: any, clientKey: string, valueKey: string): void {
		this.createEditClientService.client[clientKey] = value[valueKey];
		if (clientKey === 'IndustryTypeId') {
			this.createEditClientService.client.IndustryType = { Id: value.Id, Name: value.Name };
		}
	}

	onProductTypeSelect(productType: ProductTypes): void {
		this.createEditClientService.productType = productType;
	}

	onRemoveContractClick(selectedContract): void {
		let index: number = this.createEditClientService.contracts.findIndex((contract) => contract.ContractTypeId === selectedContract.ContractTypeId);
		//Remove from contracts array
		this.createEditClientService.contracts.splice(index, 1);

		//Re-validate contracts
		this.formValidationService.allContractsValid = this.createEditClientService.contracts.every((contract) => contract.isValid);
		this.contractTempId--;
	}

	public onAddContractClick(): void {
		this.contractTempId++;
		this.createEditClientService.contracts.unshift(this.createEditClientService.contract(this.contractTempId, this.createEditClientService.productType));
		this.createEditClientService.productTypeInit();
		setTimeout(() => this.messageService.publish(Events.addContract, null), 10);
		this.formValidationService.allContractsValid = this.createEditClientService.contracts.every((contract) => contract.isValid);
	}

	public onCopyAddressClick(): void {
		this.createEditClientService.initialServiceLocation.Address1 = this.createEditClientService.client.BillingAddress1;
		this.createEditClientService.initialServiceLocation.City = this.createEditClientService.client.BillingCity;
		this.createEditClientService.initialServiceLocation.Zip = this.createEditClientService.client.BillingZip;
		this.createEditClientService.initialServiceLocation.State = this.createEditClientService.client.BillingState;
		this.createEditClientService.initialServiceLocation.Country = this.createEditClientService.client.BillingCountry;
		this.createEditClientService.initialServiceLocation.Name = this.createEditClientService.client.Name;
		this.createEditClientService.initialServiceLocation.Phone = this.createEditClientService.client.MainPhoneNumber;
		this.createEditClientService.initialServiceLocation.Fax = this.createEditClientService.client.MainFaxNumber;
	}

	public onRootPathChange(event): void {
		this.createEditClientService.client.ContentDirectory = event;
	}

	public onGenerateRootPathClick(): void {
		if (this.createEditClientService.client.Name.length < 1) {
			alert('Please enter a company name before generating a root path.');
		} else {
			this.rootPath = this.createEditClientService.client.Name.replace(/ /g, '');
			this.createEditClientService.client.ContentDirectory = this.rootPath;
		}
	}

	public onSaveClick(): void {
		if (this.createEditClientService.mode === 'new') {
			this.saveService.saveNewClient();
		} else {
			this.saveService.saveExistingClient().subscribe({
				next: () => {
					this.saveService.saveSuccess();
				},
				error: (err) => {
					console.error('Error saving client or updating users:', err);
				}
			});
		}
	}

	public onZipKeyup(): void {
		this.invalidZip = false;
		if (this.createEditClientService.client.BillingZip.length >= 5) {
			this.validateZip();
		}
	}

	public validateZip(): void {
		this.utilService.validateZip(this.createEditClientService.client.BillingZip).subscribe((isValid: boolean) => {
			this.invalidZip = !isValid;
		});
	}

	private getCsrAndSalesRepByClient(): void {
		this.adminDataService.getCsrAndSalesRepByClient(this.createEditClientService.client.Id).subscribe((res) => {
			const csrInfo = res[0][0];
			const salesmanInfo = res[1][0];

			this.currentCsr = csrInfo.FirstName + ' ' + csrInfo.LastName;
			this.currentSalesman = salesmanInfo.FirstName + ' ' + salesmanInfo.LastName;
		});
	}
}
