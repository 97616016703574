<div style="min-width: 1400px">
	<portal-header></portal-header>
	<section *ngIf="create24Service.popup !== ''" class="absolute left0 right0">
		<div class="modal-overlay"></div>
		<div class="row">
			<div
				*ngIf="create24Service.popup === 'content-browser'"
				style="top: 100px; margin-bottom: 60px; z-index: 200"
				class="col-xs-12 min-height600 margin mgn-bottom30 absolute left0 right0 slideUp"
			>
				<router-outlet name="popup"></router-outlet>
			</div>
			<div
				*ngIf="create24Service.popup === 'export-to-library'"
				id="export"
				style="top: 100px; margin-bottom: 60px; z-index: 200"
				class="col-xs-8 col-xs-offset-2 min-height600 margin mgn-bottom30 absolute left0 right0 slideUp"
			>
				<c24-export-to-library></c24-export-to-library>
			</div>
		</div>
	</section>

	<div class="container-fluid">
		<div class="row mgn-top15">
			<div class="col-sm-8">
				<a [routerLink]="['/create-24/dashboard/' + appStateService.product.Route]" class="dark-text">
					<i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
					<span class="ft-size16">Create24 Dashboard</span>
				</a>
			</div>
			<div class="col-sm-4 text-right">
				<h1 class="semi-bold in-block">
					<i *ngIf="titleAndIcon" class="{{ titleAndIcon.icon }} red-text mgn-right12" aria-hidden="true"></i>
					Content Editor
				</h1>
				<ng-container *ngIf="titleAndIcon">
					<span class="ft-size28">|</span>
					<em class="ft-size28">{{ titleAndIcon.title }}</em>
				</ng-container>
			</div>
		</div>
	</div>

	<div style="height: 5px" class="red-gradient border-radius"></div>

	<c24-main-editor></c24-main-editor>
</div>
