import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AppStateService } from '../app-state.service';

export const authGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree => {
	const router = inject(Router);
	const appStateService = inject(AppStateService);

	// Check if the route is the login page
	const isLoginPage = route.routeConfig?.path === 'login';

	const clientRequire2fa = appStateService.currentClient?.Require2fa ?? false;
	const isEmployee = appStateService.currentUser?.IsEmployee ?? false;
	const require2fa = clientRequire2fa || isEmployee;
	const currentUrl = state.url;

	// If not on the login page, check authentication conditions
	if (!isLoginPage) {
		const hasValidAuthToken = sessionStorage.getItem('authToken') !== null && sessionStorage.getItem('authToken') !== 'null';
		const hasValidPin = sessionStorage.getItem('validPin') === 'true';

		// For clients with 2fa, check both authToken and validPin. For non-2fa clients, only check authToken
		if (!hasValidAuthToken || (require2fa && !hasValidPin)) {
			if (currentUrl.length > 0) {
				appStateService.setSessionItem('unauthorizedUrl', JSON.stringify(currentUrl));
			}
			return router.createUrlTree(['/login']);
		}
	}

	// If on the login page and authenticated, redirect to my-products
	if (isLoginPage) {
		const hasValidAuthToken = sessionStorage.getItem('authToken') !== null && sessionStorage.getItem('authToken') !== 'null';
		const hasValidPin = sessionStorage.getItem('validPin') === 'true';

		if (hasValidAuthToken && (!require2fa || (require2fa && hasValidPin))) {
			return router.createUrlTree(['/my-products']);
		}
	}

	// Otherwise, allow the navigation
	return true;
};
