import { Injectable } from '@angular/core';

import { Layer } from '../../../_models';
import { LayersService } from '../../../_services/layers.service';
import { VideoControlsService } from '../../../_services/video-controls.service';

@Injectable({
	providedIn: 'root'
})
export class LayersTimelineWrapperService {
	public canvas: any;
	public canvasHeightInit: number = 421;
	public clockInterval: any;
	public contentDuration: number;
	public layerDetailHeight: number = 940;
	public playhead: any;
	public playheadPos: number = 0;

	public previousCanvasWidth: number;

	constructor(private layersService: LayersService, private videoControlsService: VideoControlsService) {}

	//http://scg.ar-ch.org/
	public colorStringToHex(layerColor: string): string {
		switch (layerColor) {
			case 'red':
				return '#C3161E';
			case 'orange':
				return '#F9690E';
			case 'green':
				return '#16a085';
			case 'yellow':
				return '#FED36E';
			case 'purple':
				return '#9b59b6';
			case 'dark':
				return '#23282D';
			case 'blue':
				return '#2980b9';
			case 'blueLight':
				return '#409ad5';
			case 'blueLighter':
				return '#6ab0de';
			case 'blueLightest':
				return '#94c6e7';
			case 'pink':
				return '#D2527F';
		}
	}

	public animatePlayhead(): void {
		this.playhead.animate(
			{
				left: this.canvas.getWidth() - 10
			},
			{
				onChange: this.canvas.renderAll.bind(this.canvas),
				easing: function (t, b, c, d) {
					return (c * t) / d + b;
				},
				onComplete: () => {
					this.playhead.setCoords();
					this.videoControlsService.isPlaying = false;
					clearInterval(this.clockInterval);
					this.videoControlsService.playIcon = 'fas fa-play';
					this.layersService.layers.forEach((layer) => {
						layer.transitionInFired = false;
						layer.transitionOutFired = false;
					});
				},
				abort: () => !this.videoControlsService.isPlaying,
				duration: this.contentDuration - this.videoControlsService.playClock * 1000
			}
		);
	}

	public reorderTimelineObjsOnDrag(reorderedLayers: Layer[]): void {
		const layers: Layer[] = reorderedLayers;
		//Filter non-layer objects (playhead, time graph)
		const objects = this.canvas.getObjects().filter((obj) => obj.layerId >= 0);
		//58 is position of top layer, all other layers are 34 below the one above
		let top = 58 + 34;
		layers.forEach((layer, i) => {
			//If top layer, set to 58
			if (i === 0) {
				layer.timelineObj.top = 58;
				//Set rest of layers to 34 below the above layer
			} else {
				layer.timelineObj.top = top;
				top = top + 34;
			}
		});

		//Reset obj top property according to newly set layer.timelineObj.top
		objects.forEach((obj) => {
			const layer: Layer = this.layersService.layers.find((layer) => layer.id === obj.layerId);
			obj.set('top', layer.timelineObj.top);
		});

		this.layersService.resetLayerIdOnDrag(layers);

		//Keep object layer id's in sync with current layer id
		objects.forEach((obj) => {
			const layer = this.layersService.layers.find((layer) => layer.idPreDrag === obj.layerId);
			obj.layerId = layer.id;
			obj.setCoords(); //Some objects not selectable without this line
		});
		this.canvas.preserveObjectStacking = true;
		this.canvas.renderAll();
	}

	public setTimelineWidth(videoCanvasWidth: number) {
		//96 is width of create layer sidebar, 330 is width of timeline layers panel
		const width = videoCanvasWidth + 96 - 330;

		this.canvas.setDimensions({ width });
		if (this.canvas.getWidth() && !this.previousCanvasWidth) {
			this.previousCanvasWidth = this.canvas.getWidth();
		}
	}

	//Set width of timeline obj on window resize
	public setObjWidth = (obj): void => {
		if (obj.layerId > -1) {
			obj.width = this.canvas.getWidth() - 10;
			obj.setCoords();
		}
	};

	public setObjStartEnd = (obj): void => {
		if (obj.layerId > -1) {
			let layer = this.layersService.layers.find((layer) => layer.id === obj.layerId);
			if (this.previousCanvasWidth) {
				obj.left = obj.left / (this.previousCanvasWidth / this.canvas.getWidth());

				if (obj.layerId === 0) {
					layer.start = 0; //Set background layer start equal to 0
					layer.end = this.msToPx(this.contentDuration); //Set background layer end equal to content duration
				} else {
					layer.start = obj.left;
					layer.end = obj.left + obj.getScaledWidth();
				}
				layer.timelineObj.scaleX = obj.scaleX;
				layer.transitionOutStart = this.transitionOutStart(layer);
				layer.transitionInEnd = this.transitionInEnd(layer);
			}
			obj.setCoords();
		}
	};

	//https://stackoverflow.com/questions/14638990/are-strongly-typed-functions-as-parameters-possible-in-typescript
	public updateObjProps(callback: (obj, layerId?: number) => any, layerId?: number): void {
		this.canvas.getObjects().forEach((o) => {
			callback(o, layerId);
			o.setCoords();
		});
		this.canvas.renderAll();
	}

	public pushObjsDown = (obj): void => {
		if (obj.layerId >= 0) {
			obj.set('top', obj.top + 34);
		}
	};

	public moveUpLayersOnDelete(o, layerId: number): void {
		//Keep layer id's in sync
		if (o.layerId > layerId) {
			o.layerId = o.layerId - 1;
		}

		//Move up all timeline objects below deleted object
		if (o.layerId < layerId && o.layerId >= 0) {
			o.top = o.top - 34;
		}
	}

	public removeGrid = (group): void => {
		if (group.layerId < 0) {
			this.canvas.remove(group);
		}
	};

	public deleteObj(layerId: number): void {
		let obj = this.canvas.getObjects().find((obj) => obj.layerId === layerId);
		this.updateObjProps(this.moveUpLayersOnDelete, layerId);
		this.canvas.remove(obj);
	}

	//Returns px value
	public transitionOutStart(layer: Layer): number {
		if (layer.transitionOut !== 'Not set') {
			//1250 is the total transition duration, we want the end of the transition to match the timeline obj end
			return +(layer.end - (this.pixPerMs() * layer.totalTransDuration) / 1000).toFixed(0);
		} else {
			return +layer.end.toFixed(0);
		}
	}

	//Returns px value
	public transitionInEnd(layer: Layer): number {
		if (layer.transitionIn !== 'Not set') {
			return +(layer.start + this.msToPx(layer.totalTransDuration)).toFixed(0);
		}
		return +layer.start.toFixed(0);
	}

	public pixPerMs(): number {
		return this.canvas.getWidth() / (this.contentDuration / 1000);
	}

	private msPerPx(): number {
		return this.contentDuration / this.canvas?.getWidth();
	}

	public pxToMs(px: number): number {
		return px * this.msPerPx();
	}

	public playClockMs(): number {
		return this.videoControlsService.playClock * 1000;
	}

	public msToPx(ms: number): number {
		return +(ms / this.msPerPx()).toFixed(0);
	}
}
