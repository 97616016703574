import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentRequestsRoutingModule } from './content-requests-routing.module';
import { SharedModule } from '../shared/shared.module';
import { TableConfigService } from './_services';
import { ScriptReadViewComponent } from './create-edit-request/clips/clip/hold-view/script-read-view/script-read-view.component';



@NgModule({
  declarations: [ScriptReadViewComponent],
  imports: [
    CommonModule,
    ContentRequestsRoutingModule,
    SharedModule
  ],
  providers: [TableConfigService]
})
export class ContentRequestsModule { }
