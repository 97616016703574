<portal-header></portal-header>

<div style="min-width: 610px">
	<div class="container-fluid mgn-top30 relative mx-4">
		<div class="row mgn-bottom30">
			<h1 class="semi-bold print-style">
				<i class="fas fa-file-contract mgn-right15" aria-hidden="true"></i>
				Works24 Contract Renewal Report, Generated {{ today }}
			</h1>
		</div>
		<div class="row white-bg shadow border-radius pd-top10 pd-bottom10">
			<div class="col-xs-3">
				<portal-dropdown-menu
					class="dropdown-print-style"
					[backgroundColor]="'gray-bg-dark'"
					[height]="'ht40'"
					[key]="'Name'"
					[options]="activeSalesmen"
					[selectedOption]="'Filter by Salesperson...'"
					(onOptionSelect)="onDropdownSelect($event, 'salespersonId', 'selectedSalesperson')"
				></portal-dropdown-menu>
				<div style="display: none" class="filter-print-style">
					<span>{{ selectedSalesperson.Name }}</span>
				</div>
			</div>

			<div class="col-xs-3">
				<portal-dropdown-menu
					class="dropdown-print-style"
					[backgroundColor]="'gray-bg-dark'"
					[height]="'ht40'"
					[key]="'Name'"
					[options]="contractTypes"
					[selectedOption]="'Filter by Contract Type...'"
					(onOptionSelect)="onDropdownSelect($event, 'contractTypeId', 'selectedContractType')"
				></portal-dropdown-menu>
				<div style="display: none" class="filter-print-style">
					<span>{{ selectedContractType.Name }}</span>
				</div>
			</div>

			<div class="col-xs-3">
				<portal-dropdown-menu
					class="dropdown-print-style"
					[backgroundColor]="'gray-bg-dark'"
					[height]="'ht40'"
					[key]="'Name'"
					[options]="nextDays"
					[selectedOption]="'Next 60 days'"
					(onOptionSelect)="onDropdownSelect($event, 'days', 'selectedNextDays')"
				></portal-dropdown-menu>
				<div style="display: none" class="filter-print-style">
					<span>{{ selectedNextDays.Name === 'All' ? 'Next 60 days' : selectedNextDays.Name }}</span>
				</div>
			</div>

			<div class="col-xs-3 pd-top10 checkbox-print">
				<label class="pointer mgn-right10" for="statsExempt">Hide Stats Exempt:</label>
				<input type="checkbox" id="statsExempt" [(ngModel)]="hideStatsExempt" (input)="onHideStatsExemptClick()" class="relative top2 pointer" />
			</div>
		</div>
		<div class="row mgn-top15">
			<div *ngIf="spinnerActive" class="relative top150">
				<portal-spinner [width]="'wdthpx76'" [height]="'htpx76'"></portal-spinner>
			</div>
			<portal-table
				[table]="tableConfigService.table"
				[config]="tableConfigService.configuration"
				[data]="reportData"
				(linkClickNotify)="onCompanyNameClick($event)"
			></portal-table>
		</div>
	</div>
</div>
