import { ContentAdvancedSchedules } from './content-advanced-schedules';

export class PlaylistItemJson {
	ContentId: number;
	ContentName: string;
	ContentFileId: number;
	ContentPreviewUrl: string;
	Duration: number;
	Order: number;
	StartDate: string;
	StopDate: string;
	AdvancedSchedule: ContentAdvancedSchedules;
	StillDuration: number;
	SilenceMusic: boolean;
	IsMusicTrack: boolean;
	IsChannel: boolean;
	ChannelContentId: number;
	ContentTypeId: number;
	WeatherOverride?: string;
	FeedId: number;
	Directory: string;
	MasterFileName: string;
}