<video
	*ngIf="canvasService.bgContent?.previewUrl && canvasService.bgContent?.contentType === 'video/mp4'"
	#videoPlayer
	crossOrigin="anonymous"
	[ngStyle]="{ 'width.px': canvasService.canvasSize().width, 'height.px': canvasService.canvasSize().height }"
	preload="auto"
	class="border-radius shadow"
>
	<source [src]="canvasService.bgContent?.previewUrl" type="video/mp4" />
</video>

<img
	*ngIf="canvasService.bgContent?.previewUrl && canvasService.bgContent?.contentType === 'image/png'"
	crossOrigin="anonymous"
	#stillImage
	[ngStyle]="{ 'width.px': canvasService.canvasSize().width, 'height.px': canvasService.canvasSize().height }"
	class="border-radius shadow"
	src="{{ canvasService.bgContent.previewUrl }}"
/>

<div
	*ngIf="!canvasService.bgContent"
	[ngStyle]="{
		'width.px': canvasService.canvasSize().width,
		'height.px': canvasService.canvasSize().height,
		'background-color': bgLayer()?.canvasObj.blankBgColor
	}"
	class="border-radius shadow"
></div>

<div class="absolute top0">
	<canvas id="video-canvas" crossOrigin="anonymous" style="background-color: transparent"></canvas>
</div>
