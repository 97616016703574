import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, publishReplay, refCount } from 'rxjs/operators';

import { AdminDataService, AppStateService, DateTimeService, CacheService, UtilitiesService } from '../../../../core/services';
import { environment } from '../../../../../environments/environment';
import {
	Clients,
	ContactInformation,
	TicketAttachments,
	TicketComments,
	TicketStates,
	TicketTypes,
	ServiceLocations,
	Users,
	PlayerModels
} from '../../../../shared/api-models/admin';
import { Locations, Notes } from '../../_models';
import { LocationsTicketEdit } from '../_models';
import { PlayersView, TicketsView, WorkOrdersView } from '../_models';
import { PlayerStatus } from '../../../../shared/api-models/delivery';
import { ClientIds } from 'src/app/shared/api-models/admin/client-ids.enum';

@Injectable({
	providedIn: 'root'
})
export class CreateEditTicketService {
	public clientList: [{ CsrId: number; Id: number; Name: string }];
	public employees: Users[] | any;
	public mode: 'edit' | 'new';
	public newHistoryNote: Notes;
	public playerModels: PlayerModels[];
	public ticket: TicketsView;
	public ticketCopy: TicketsView; //need to preserve pre-edited ticket state for history note
	public ticketStatusList: TicketStates[];
	public ticketTypesList: TicketTypes[];

	public locationsModalActive: boolean;

	constructor(
		private adminDataService: AdminDataService,
		private appStateService: AppStateService,
		private dateTimeService: DateTimeService,
		private httpClient: HttpClient,
		private cacheService: CacheService,
		private utilService: UtilitiesService
	) {}

	public newTicketInit(): void {
		this.ticket = new TicketsView();
		this.ticket.client = new Clients();
		this.ticket.CreatedBy = this.appStateService.currentUser.UserId;
		this.ticket.createdByUsername = this.appStateService.currentUser.UserName;
		this.ticket.LastUpdateBy = this.appStateService.currentUser.UserId;
		this.ticket.lastUpdateByUsername = this.appStateService.currentUser.UserName;
		this.ticket.TicketStateId = 1;
		this.ticket.status = 'New';
		this.ticket.assignedLocations = [];
		this.ticket.LastTicketActionBy = this.appStateService.currentUser.UserId;
		this.ticket.IsComplete = false;
		this.ticket.VideoUnitsRequired = 0;
		this.ticket.VideoUnitsShipped = 0;
		this.ticket.IsHighPriority = false;
		this.ticket.LocationComplete = false;
		this.ticket.PostponeNotificationSent = false;
		this.ticket.cstLocationNotesByClient = [];
	}

	public getActiveCsts(): Observable<void> {
		return this.httpClient.get(`${environment.adminUrl}CoreUsers/ActiveCsts`).pipe(
			map((cstList: Users[]) => {
				this.ticket.activeCstEmailList = cstList
					.map((cst) => {
						return `${cst.ContactInformation.Email}; `;
					})
					.join('');
			})
		);
	}

	public getCrmContact(): Observable<ContactInformation | ''> {
		if (this.ticket.client.PrimaryContactId) {
			return this.httpClient.get(environment.adminUrl + `CoreUsers/${this.ticket.client.PrimaryContactId}/ContactInformation`).pipe(
				map((res) => {
					return res[0];
				})
			);
		}
		return of('');
	}

	public getTicket(ticketId: number): Observable<void> {
		return this.httpClient.get(`${environment.adminUrl}Tickets/${ticketId}`).pipe(
			mergeMap((ticket: TicketsView) => {
				this.ticket = ticket;
				this.ticket.client = new Clients();
				const status$ = this.cacheService.ticketState$(
					this.httpClient.get<TicketStates>(`${environment.adminUrl}TicketsStates/${ticket.TicketStateId}`),
					ticket.TicketStateId
				);
				const ticketType$ = this.httpClient.get(`${environment.adminUrl}TicketsTypes/${ticket.TicketTypeId}`);
				const lastUpdateBy$ = ticket.LastUpdateBy
					? this.cacheService.user$(this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${ticket.LastUpdateBy}`), ticket.LastUpdateBy)
					: of('');
				const createdBy$ = ticket.CreatedBy
					? this.cacheService.user$(
							this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${ticket.CreatedBy}`).pipe(catchError((e) => of(null))),
							ticket.CreatedBy
					  )
					: of('');
				const client$ = this.httpClient.get(`${environment.adminUrl}CoreClients/${ticket.ClientId}`);
				const assignedTo$ = ticket.AssignedToUserId
					? this.cacheService.user$(this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${ticket.AssignedToUserId}`), ticket.AssignedToUserId)
					: of('');
				const assignedLocations$ = this.httpClient.get(`${environment.adminUrl}Tickets/${ticket.Id}/Locations`);
				const attachments$ = this.httpClient.get(`${environment.adminUrl}Tickets/${ticket.Id}/Attachments`);
				const lastActionBy$ = ticket.LastTicketActionBy
					? this.cacheService.user$(this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${ticket.LastTicketActionBy}`), ticket.LastTicketActionBy)
					: of('');
				const csrAndSalesRep$ = this.adminDataService.getCsrAndSalesRepByClient(ticket.ClientId);
				const historyNotes$ = this.httpClient.get(`${environment.adminUrl}ClientHistoryNotes/Ticket/${this.ticket.Id}`);
				const oldNotes$ = this.httpClient.get(`${environment.adminUrl}Tickets/${this.ticket.Id}/Comments`);
				const workOrders$ = this.httpClient.get(`${environment.adminUrl}Tickets/${this.ticket.Id}/Workorders`);

				return forkJoin([
					status$,
					ticketType$,
					lastUpdateBy$,
					createdBy$,
					client$,
					assignedTo$,
					assignedLocations$,
					attachments$,
					lastActionBy$,
					csrAndSalesRep$,
					historyNotes$,
					oldNotes$,
					workOrders$
				]).pipe(
					map(
						(
							res: [
								TicketStates,
								TicketTypes,
								Users,
								Users,
								Clients,
								Users,
								Locations[],
								TicketAttachments[],
								Users,
								[ContactInformation[], ContactInformation[]],
								Notes[],
								TicketComments[],
								WorkOrdersView[]
							]
						) => {
							const [
								state,
								type,
								lastUpdateBy,
								createdBy,
								client,
								assignedTo,
								assignedLocations,
								attachments,
								lastActionBy,
								csrAndSalesRep,
								historyNotes,
								oldNotes,
								workOrders
							] = res;
							this.ticket.status = state?.Name;
							this.ticket.ticketTypeName = type?.Name;
							this.ticket.lastUpdateByUsername = lastUpdateBy?.UserName;
							this.ticket.createdByUsername = createdBy?.UserName;
							this.ticket.client = client;
							this.ticket.assignedToUsername = assignedTo?.UserName;
							this.ticket.assignedToEmail = assignedTo ? assignedTo.ContactInformation?.Email : null;
							this.ticket.assignedLocations = assignedLocations?.map((location) => this.serviceLocation(location));
							this.ticket.attachments = attachments;
							this.ticket.lastActionByUsername = lastActionBy ? lastActionBy.UserName : null;
							this.ticket.csrEmail = csrAndSalesRep[0][0]?.Email;
							this.ticket.csrFriendlyName = `${csrAndSalesRep[0][0]?.FirstName} ${csrAndSalesRep[0][0]?.LastName}`;
							this.ticket.salesmanFriendlyName = `${csrAndSalesRep[1][0]?.FirstName} ${csrAndSalesRep[1][0]?.LastName}`;
							this.ticket.historyNotes = historyNotes;
							this.ticket.oldNotes = oldNotes;
							this.ticket.workOrders = workOrders;

							if (this.ticket.NotifyList) {
								this.ticket.notifyListArr = JSON.parse(this.ticket.NotifyList);
							} else {
								this.ticket.notifyListArr = [];
							}
							this.ticketCopy = JSON.parse(JSON.stringify(this.ticket));

							this.getCrmContact().subscribe((crmContact: ContactInformation) => {
								this.ticket.crmContact = crmContact;
							});
							this.cacheService.setUserCache([lastUpdateBy, createdBy, assignedTo, lastActionBy]);
						}
					)
				);
			})
		);
	}

	private serviceLocation(location: Locations): LocationsTicketEdit {
		let loc = new LocationsTicketEdit();
		loc = location.location as LocationsTicketEdit;
		loc.isRepaired = location.IsRepaired;
		return loc;
	}

	public getDropdownMenuData(): Observable<void> {
		const ticketStates$ = this.httpClient.get(`${environment.adminUrl}TicketsStates`);
		const employees$ = this.httpClient.get(`${environment.adminUrl}CoreUsers/GetUsersByClientId/${ClientIds.WORKS24_OFFICE_ACCOUNT}`);
		const ticketTypes$ = this.httpClient.get(`${environment.adminUrl}TicketsTypes`);

		if (!this.ticketStatusList) {
			return forkJoin([ticketStates$, employees$, ticketTypes$]).pipe(
				publishReplay(1), // tells Rx to cache the latest emitted
				refCount(), // tells Rx to keep the Observable alive as long as there are any Subscribers
				map((res: [TicketStates[], Users[], TicketTypes[]]) => {
					this.ticketStatusList = this.utilService.sortItems(
						res[0].filter((status) => status.Name !== 'New' && status.Name !== 'Closed'),
						'Sort'
					);
					this.employees = [{ UserName: 'Unassigned' }].concat(this.utilService.sortItems(res[1], 'UserName').filter((employee) => !employee.IsDeleted));
					this.ticketTypesList = this.utilService.sortItems(res[2], 'Sort');
				})
			);
		}
		return of();
	}

	public getCsrAndSalesman(): Observable<void> {
		return this.adminDataService.getCsrAndSalesRepByClient(this.ticket.ClientId).pipe(
			map((res: [ContactInformation[], ContactInformation[]]) => {
				this.ticket.csrFriendlyName = `${res[0][0].FirstName} ${res[0][0].LastName}`;
				this.ticket.csrEmail = res[0][0].Email;
				this.ticket.salesmanFriendlyName = `${res[1][0].FirstName} ${res[1][0].LastName}`;
			})
		);
	}

	public getHistoryNotesUserAndLocation(
		key: 'historyNotes' | 'oldNotes',
		userKey: 'UserId' | 'CreatedBy',
		locationKey: 'ServiceLocationId' | 'LocationId'
	): Observable<void> {
		const users$ = this.ticket[key].map((note) => {
			if (note[userKey]) {
				return this.cacheService.user$(this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${note[userKey]}`), note[userKey]);
			}
			return of('');
		});

		const locations$ = this.ticket[key].map((note) => {
			if (note[locationKey]) {
				return this.httpClient.get(`${environment.adminUrl}CoreServiceLocations/${note[locationKey]}`).pipe(map((res) => res[0]));
			}
			return of('');
		});

		const observs$ = users$.concat(locations$);

		return forkJoin(observs$).pipe(
			map((res: any[]) => {
				const users: Users[] = res.slice(0, this.ticket[key].length);
				const locations: ServiceLocations[] = res.slice(this.ticket[key].length);

				this.ticket[key].forEach((note, index) => {
					this.ticket[key][index].createdByUsername = users[index].UserName;
					this.ticket[key][index].locationName = locations[index].Name;
				});
				this.cacheService.setUserCache(users);
			})
		);
	}

	public getPlayers(): Observable<void> {
		const players$ = this.ticket.assignedLocations.map((location) => {
			return this.httpClient.get(`${environment.adminUrl}CoreServiceLocations/${location.Id}/Players`);
		});

		//Get players for each assigned location
		return forkJoin(players$).pipe(
			mergeMap((res: PlayersView[][]) => {
				//Flatten array of arrays
				let players: PlayersView[] = [].concat.apply([], res);

				const playerStatus$ = players.map((player) => {
					return this.httpClient.get(`${environment.deliveryUrl}PlayerStatus/Player/${player.Id}`);
				});

				//Get player status for each player
				return forkJoin(playerStatus$).pipe(
					map((playerStatus: PlayerStatus[]) => {
						players = players.map((player, index) => {
							player.lastCheckin = this.dateTimeService.differenceInDaysOrHours(playerStatus[index].LastCheckin);
							return player;
						});
						//Assign players to each location
						this.ticket.assignedLocations.forEach((location) => {
							location.players = players
								.filter((player) => player.LocationId === location.Id)
								.filter((player) => this.ticketTypeArr(player.ProductTypeId).some((ticketTypeId) => ticketTypeId === this.ticket.TicketTypeId));
						});
					})
				);
			})
		);
	}

	public extractEmails(text: string): string[] {
		return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
	}

	private ticketTypeArr(productTypeId: number): number[] {
		switch (productTypeId) {
			case 1: //On Hold
				return [1, 5, 9];

			case 2: //Works24 Radio
				return [3, 7, 11];

			case 8: //Other
				return [8, 12, 13, 14, 15, 31, 32, 33, 34];

			default: //any video product
				return [2, 6, 10];
		}
	}

	public refreshLastUpdatedLastActionView(): void {
		this.ticket.LastTicketActionBy = this.appStateService.currentUser.UserId;
		this.ticket.LastTicketActionDate = new Date().toISOString();
		this.ticket.LastUpdate = new Date().toISOString();
		this.ticket.lastUpdateByUsername = this.appStateService.currentUser.UserName;
		this.ticket.lastActionByUsername = this.appStateService.currentUser.UserName;
	}

	public productColor(): string {
		if (this.ticket) {
			switch (true) {
				case this.utilService.includes(this.ticket.ticketTypeName, 'Video'):
					return 'red-bg';
				case this.utilService.includes(this.ticket.ticketTypeName, 'OnHold'):
					return 'blue-bg';
				case this.utilService.includes(this.ticket.ticketTypeName, 'Music'):
					return 'purple-bg';
				default:
					return 'limeGreen-blue-bg-darkest-gradient';
			}
		}
	}
}
