import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AppStateService } from '../../../core/services';
import { Clients } from '../../api-models/admin';
import { environment } from '../../../../environments/environment';
import { Events, MessageService } from '../../../core/services';

@Component({
    selector: 'portal-client-notes',
    templateUrl: './client-notes.component.html',
    styleUrls: ['./client-notes.component.scss']
})
export class ClientNotesComponent implements OnInit {

    @Input() rowCount: number;

    public notesChanged: Subject <string> = new Subject <string>();
    public savingNotes: boolean;
  

    constructor(public appStateService: AppStateService, private httpClient: HttpClient, private messageService: MessageService) {
        this.notesChanged.pipe(
            debounceTime(800),
        ).subscribe((val) => {
            this.appStateService.currentClient.Notes = val;
            this.updateNotes();
        })
    }

    ngOnInit() {}

    private updateNotes(): void {
        this.httpClient.patch(environment.adminUrl + `CoreClients/${this.appStateService.currentClient.Id}`, this.clientsApi())
            .subscribe(
                res => {
                    this.appStateService.setSessionItem('currentClient', JSON.stringify(this.appStateService.currentClient));
                    this.appStateService.setClientAndUser();
                    this.savingNotes = true;
                    setTimeout(() => this.savingNotes = false, 1600);
                });
    }

    private clientsApi(): Clients {
        let client: Clients = new Clients();
        client.Notes = this.appStateService.currentClient.Notes;
        return client;
    }
}
