import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';

import { ContentAdvancedSchedules } from '../../../../shared/api-models/content';
import { AdvancedSchedulerView } from '../_models/advanced-scheduler-view';
import { AppStateService, DateTimeService } from '../../../../core/services';
import { environment } from '../../../../../environments/environment';
import { DateTimeError } from '../_models/time-error.enum';

@Injectable({
	providedIn: 'root'
})
export class AdvancedSchedulerService {
	public model: ContentAdvancedSchedules;
	public saveAsTemplate: boolean;
	public templates: ContentAdvancedSchedules[] = [];
	public view: AdvancedSchedulerView;
	public timeError: DateTimeError = DateTimeError.NONE;
	public dateError: DateTimeError = DateTimeError.NONE;

	constructor(private appStateService: AppStateService, private dateTimeService: DateTimeService, private httpClient: HttpClient) {}

	public getTemplates(): void {
		this.httpClient
			.get<ContentAdvancedSchedules[]>(environment.contentUrl + 'AdvancedScheduleTemplates/Client/' + this.appStateService.currentClient.Id)
			.subscribe((templates: ContentAdvancedSchedules[]) => {
				this.templates = templates;
			});
	}

	public deleteTemplate(selectedTemplate: ContentAdvancedSchedules): Observable<any> {
		return this.httpClient.delete(`${environment.contentUrl}AdvancedScheduleTemplates/${selectedTemplate.Id}`);
	}

	public buildModel(model: ContentAdvancedSchedules, view: AdvancedSchedulerView): ContentAdvancedSchedules {
		model.ScheduleName = view.ScheduleName ? view.ScheduleName : 'Created on ' + moment(new Date()).format('MM/DD/YYYY, h:mm A');
		model.DaysOfWeek = view.DaysOfWeek && view.DaysOfWeek.length > 0 ? this.stringifyArray(view.DaysOfWeek) : null;
		model.DaysOfMonth = view.DaysOfMonth && view.DaysOfMonth.length > 0 ? this.stringifyArray(view.DaysOfMonth) : null;
		model.MonthsOfYear = view.MonthsOfYear && view.MonthsOfYear.length > 0 ? this.stringifyArray(view.MonthsOfYear) : null;
		model.isValid = this.isValid();
		return model;
	}

	public createTemplate(): void {
		if (this.saveAsTemplate) {
			let modelCopy: ContentAdvancedSchedules = JSON.parse(JSON.stringify(this.model));
			delete modelCopy.Id;
			this.httpClient.post(`${environment.contentUrl}AdvancedScheduleTemplates`, this.buildModel(modelCopy, this.view)).subscribe();
		}
	}

	public modelScheduleToView(schedule: ContentAdvancedSchedules) {
		let type: string;
		let subType: string;
		switch (schedule.RecurrenceTypeId) {
			case 1:
				type = 'Every Day';
				break;
			case 2:
				type = 'Weekly';
				break;
			case 3:
				type = 'Monthly';
				break;
			case 4:
				type = 'Yearly';
				break;
		}
		switch (schedule.RecurrenceSubtypeId) {
			case 1:
				subType = 'Every Day';
				break;
			case 2:
				subType = 'Weekdays';
				break;
			case 3:
				subType = 'Days of Month';
				break;
			default:
				subType = null;
				break;
		}
		this.view = {
			ScheduleName: schedule.ScheduleName,
			RecurrenceStartTime: this.dateTimeService.convertUtcTimeToView(schedule.RecurrenceStartTime),
			RecurrenceStopTime: this.dateTimeService.convertUtcTimeToView(schedule.RecurrenceStopTime),
			RecurrenceType: type,
			RecurrenceSubtype: subType,
			DaysOfWeek: schedule.DaysOfWeek ? schedule.DaysOfWeek.split(',') : [],
			DaysOfMonth: schedule.DaysOfMonth ? schedule.DaysOfMonth.split(',') : [],
			MonthsOfYear: schedule.MonthsOfYear ? schedule.MonthsOfYear.split(',') : [],
			YearlyStartDate: schedule.YearlyStartDate ? this.dateTimeService.iMyDatePickerDateInit(schedule.YearlyStartDate) : null,
			YearlyEndDate: schedule.YearlyEndDate ? this.dateTimeService.iMyDatePickerDateInit(schedule.YearlyEndDate) : null
		};
	}

	public setDefaultValues(): void {
		this.view = {
			ScheduleName: null,
			RecurrenceStartTime: '8:00 AM',
			RecurrenceStopTime: '5:00 PM',
			RecurrenceType: 'Every Day',
			RecurrenceSubtype: null,
			DaysOfWeek: [],
			DaysOfMonth: [],
			MonthsOfYear: [],
			YearlyStartDate: null,
			YearlyEndDate: null
		};
		// set model default views
		this.model = {
			ClientId: this.appStateService.currentClient.Id,
			ScheduleName: null,
			RecurrenceStartTime: this.dateTimeService.convertTimeViewToUtc('8:00 AM'),
			RecurrenceStopTime: this.dateTimeService.convertTimeViewToUtc('5:00 PM'),
			RecurrenceTypeId: 1,
			RecurrenceSubtypeId: null,
			DaysOfWeek: null,
			DaysOfMonth: null,
			MonthsOfYear: null,
			YearlyStartDate: null,
			YearlyEndDate: null,
			isValid: false
		};
	}

	public stopDateAfterStartDate(): boolean {
		return moment(this.model.YearlyStartDate).isBefore(this.model.YearlyEndDate);
	}

	private stringifyArray(array: any[]): string {
		let len = array.length;
		let value = '';
		for (let i = 0; i < len; i++) {
			if (i === len - 1) {
				value += array[i];
			} else {
				value += array[i] + ',';
			}
		}
		return value;
	}

	private isValid() {
		if (this.timeError !== DateTimeError.NONE || this.dateError !== DateTimeError.NONE) {
			return false;
		}
		switch (true) {
			case this.model.RecurrenceTypeId === 2: //weekly
				//at least one day selected
				if (this.model.DaysOfWeek) {
					return true;
				}
				return false;

			case this.model.RecurrenceTypeId === 3: //monthly
				//at least one month selected and sub-type is every day
				if (this.model.MonthsOfYear && this.model.RecurrenceSubtypeId === 1) {
					return true;
				}

				//at least one month selected and sub-type is weekdays
				if (this.model.MonthsOfYear && this.model.RecurrenceSubtypeId === 2) {
					//at least one weekday selected
					if (this.model.DaysOfWeek) {
						return true;
					}
					return false;
				}

				//at least one month selected and sub-type is days of month
				if (this.model.MonthsOfYear && this.model.RecurrenceSubtypeId === 3) {
					//at least one date selected
					if (this.model.DaysOfMonth) {
						return true;
					}
					return false;
				}
				return false;

			case this.model.RecurrenceTypeId === 1:
			case this.model.RecurrenceTypeId === 4:
				return true;
		}
	}
}
