import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { CreateEditTicketService } from './create-edit-ticket.service';
import { EmailService } from './email.service';
import { environment } from '../../../../../environments/environment';
import { Events, MessageService } from '../../../../core/services';
import { HistoryNoteService } from './history-note.service';
import { TicketsServiceLocationsBridge, ServiceLocations } from '../../../../shared/api-models/admin';
import { TicketsView } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class SaveService {

    constructor(
        private ceTicketService: CreateEditTicketService,
        private emailService: EmailService,
        private historyNoteService: HistoryNoteService,
        private httpClient: HttpClient,
        private messageService: MessageService,
        private router: Router
    ) { }

    public createTicket(): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.ceTicketService.ticket.CreatedDate = new Date().toISOString();
        this.ceTicketService.ticket.LastTicketActionDate = new Date().toISOString();
        this.ceTicketService.ticket.LastUpdate = new Date().toISOString();
        this.ceTicketService.ticket.TicketStateChanged = new Date().toISOString();
        let ticketCopy = JSON.parse(JSON.stringify(this.ceTicketService.ticket));
        delete ticketCopy.client;
        this.httpClient.post(`${environment.adminUrl}Tickets`, ticketCopy).pipe(
            mergeMap((ticket: TicketsView) => {

                let assignedLocations$ = this.ceTicketService.ticket.assignedLocations.map((location) => {
                    return this.httpClient.post(`${environment.adminUrl}TicketsServiceLocationsBridge`, this.ticketsServiceLocationsBridge(location, ticket.Id));
                })
                this.historyNoteService.postHistoryNote(this.historyNoteService.historyNoteNewOrClosedTicket(false)).subscribe();
                this.emailService.sendEmail(this.emailService.newTicketEmail(ticket));

                if (this.ceTicketService.ticket.assignedLocations.length > 0) {
                    return forkJoin(assignedLocations$).pipe(
                        map(() => {
                            return ticket;
                        })
                    )
                }
                return of(ticket);
            })
        ).subscribe((ticket: TicketsView) => {
            this.messageService.publish(Events.savingPreloader, 0);
            this.router.navigate([`/ticket-system/edit-ticket/${ticket.Id}`]);
        });
    }

    public updateTicket(): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.httpClient.put(`${environment.adminUrl}Tickets/${this.ceTicketService.ticket.Id}`, this.ceTicketService.ticket)
            .subscribe(() => {
                this.messageService.publish(Events.savingPreloader, 0);
            })
    }

    public patchTicketsServiceLocationsBridge(location: ServiceLocations): Observable<void> {
        this.messageService.publish(Events.savingPreloader, 1);
        return this.httpClient.patch(`${environment.adminUrl}TicketsServiceLocationsBridge/${this.ceTicketService.ticket.Id}/${location.Id}`, { IsRepaired: location.isRepaired }).pipe(
            map(() => {
                this.messageService.publish(Events.savingPreloader, 0);
            })
        )
    }

    public postTicketsServiceLocationsBridge(location: ServiceLocations): Observable<void> {
        this.messageService.publish(Events.savingPreloader, 1);
        return this.httpClient.post(`${environment.adminUrl}TicketsServiceLocationsBridge`, this.ticketsServiceLocationsBridge(location, this.ceTicketService.ticket.Id)).pipe(
            map(() => {
                this.messageService.publish(Events.savingPreloader, 0);
            })
        )
    }

    public deleteTicketsServiceLocationsBridge(location: ServiceLocations): Observable<void> {
        this.messageService.publish(Events.savingPreloader, 1);
        return this.httpClient.delete(`${environment.adminUrl}TicketsServiceLocationsBridge/${this.ceTicketService.ticket.Id}/${location.Id}`).pipe(
            map(() => {
                this.messageService.publish(Events.savingPreloader, 0);
            })
        )
    }

    private ticketsServiceLocationsBridge(location: ServiceLocations, ticketId: number): TicketsServiceLocationsBridge {
        let serviceLocationBridge: TicketsServiceLocationsBridge = {
            TicketId: ticketId,
            LocationId: location.Id,
            IsRepaired: false
        };
        return serviceLocationBridge;
    }
}
