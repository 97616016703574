<div class="relative">
	<input readonly 
        style="padding: 0.5em;"
		class="setWidth100 noExit {{height}} {{fontSize}} pointer {{backgroundColor}} {{backgroundColor === 'gray-bg-dark' ? 'dark-border' : null}}" 
		placeholder="Click to select" 
    	angular-mydatepicker name="mydate" 
    	#dp="angular-mydatepicker" 
    	[(ngModel)]="model" 
    	[options]="datePickerOptions" 
    	(click)="dp.toggleCalendar()" 
		(dateChanged)="onDateChanged($event)"/>
	<i *ngIf="clearDateEnabled" (click)="dp.clearDate()" class="fas fa-times ft-size20 absolute centered right36 pointer" aria-hidden="true"></i>
    <i (click)="dp.toggleCalendar()" class="far fa-calendar-alt ft-size20 absolute centered right4 pointer" aria-hidden="true"></i>
</div>
