<div *ngIf="!loginService.alert.visible" class="row">
	<div class="col-xs-10 col-xs-offset-1">
		<span [attr.data-testid]="DataTestIds.Login.ForgotPassword.Instructions">
			Enter your username and we'll email you instructions to reset your password. Need help?
			<br />
			Email your CSR, or call them at 800-460-4653.
		</span>
	</div>
</div>

<div class="row mgn-top15">
	<div class="col-xs-10 col-xs-offset-1">
		<input
			[attr.data-testid]="DataTestIds.Login.ForgotPassword.UsernameInput"
			class="pd-left36 setWidth100 ht55 lt-gray-bg"
			type="text"
			placeholder="Enter your username"
			(keyup.enter)="onSubmitClick()"
			[(ngModel)]="emailOrUsername"
		/>
		<i class="fa fa-user gray-text-dark absolute wdth10 left24 top15 ft-size22" id="user-icon" aria-hidden="true"></i>
	</div>
</div>

<div class="row mgn-top20 mgn-bottom40">
	<div class="col-xs-10 col-xs-offset-1">
		<button
			[attr.data-testid]="DataTestIds.Login.ForgotPassword.SubmitButton"
			[disabled]="submitClicked"
			class="{{ submitClicked ? 'setOpacityLow' : null }} border-radius red-bg white-text setWidth100 shadow ht38"
			(click)="onSubmitClick()"
		>
			<span *ngIf="!submitClicked">Submit</span>
			<span *ngIf="submitClicked">Done!</span>
		</button>
		<a [attr.data-testid]="DataTestIds.Login.ForgotPassword.CancelLink" class="center relative top20" (click)="onCancelClick()">Cancel</a>
	</div>
</div>
