<div 
	ng2FileDrop 
	[uploader]="fileUploadStateService.uploader"
	class="wrapper pointer"
	(fileOver)="onDragHover($event)"
	(onFileDrop)="onDrop($event)"
	(click)="fileInput.click()">
	<input #fileInput name="file" type="file" class="hidden" onclick="value = null" (change)="onChange($event)" />
	<i *ngIf="showImagePreview && previewUrl" class="fa fa-times-circle close-icon ft-size36" aria-hidden="true" (click)="onRemoveImageClick($event)"></i>
	<div [ngClass]="{'gray-border-darkest' : isHovered, 'gray-border-darker' : !isHovered && !unsupportedFile, 'red-border' : !isHovered && (unsupportedFile || fileTooLarge)}" class="{{ !borderHidden ? 'upload-box' : null}} border-radius {{dropTargetHeight}} {{paddingTop}} {{fontSize}} {{textPosition ? '' : 'center'}} relative">
		<div class="{{textPosition && !browserDetectorService.browserIsFirefox() ? textPosition : ''}}">
			<span *ngIf="!isHovered && !unsupportedFile && !fileTooLarge" class="{{textColor}} {{previewUrl ? 'visibility-hidden' : null}}">Drag file here <br>or click to upload.</span>
			<span *ngIf="isHovered" class="gray-text-darkest">Drop it!</span>
			<span *ngIf="!isHovered && unsupportedFile" class="red-text">Sorry!<br>Unsupported file type.</span>
			<span *ngIf="!isHovered && fileTooLarge" class="red-text">Bummer!<br>This file exceeds the max allowed size.<br>(10 MB)</span>
		</div>
<!-- 		<img *ngIf="showImagePreview && previewUrl && !displayPreviewAsUrl" class="image-contain uploaded-image" src="data:{{previewUrl}}" /> -->
		<img *ngIf="showImagePreview && previewUrl && displayPreviewAsUrl" [ngStyle]="{'height.px' : isSidebarFeedImage ? 168.44 : null, 'width.px' : isSidebarFeedImage ? 110 : null}" class="{{!isSidebarFeedImage ? 'image-contain' : null}} uploaded-image" src="{{previewUrl}}" />
	</div>

</div>

