import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { switchMap, tap, catchError, withLatestFrom } from "rxjs/operators";
import { EMPTY, concat, of } from "rxjs";
import { toggleIsSelected } from "src/app/shared/helpers/ngrx-component-store.helpers";

import { ClientMergeToolService } from "./client-merge-tool.service";
import { Clients, Contracts, ServiceLocations, Users } from "src/app/shared/api-models/admin";
import { ContentLibraries } from "src/app/shared/api-models/content";
import { Events, MessageService } from "src/app/core/services";

export interface MergeToolState {
    clients: Clients[];
    fromClient: Clients;
    toClient: Clients;
    locations: ServiceLocations[];
    users: Users[];
    libraries: ContentLibraries[];
    contracts: Contracts[];
}

@Injectable()
export class ClientMergeToolStore extends ComponentStore<MergeToolState> {
    constructor(
        private clientMergeToolService: ClientMergeToolService,
        private messageService: MessageService
    ) {
        super({ clients: [], fromClient: null, toClient: null, locations: [], users: [], libraries: [], contracts: [] })
    }


    //Selectors
    readonly clients$ = this.select(({clients}) => clients);
    readonly toClient$ = this.select(({toClient}) => toClient);
    readonly fromClient$ = this.select(({fromClient}) => fromClient);
    readonly locations$ = this.select(({locations}) => locations);
    readonly users$ = this.select(({users}) => users);
    readonly libraries$ = this.select(({libraries}) => libraries);
    readonly contracts$ = this.select(({contracts}) => contracts);

    //Effects
    readonly getClients$ = this.effect((trigger$) =>
        trigger$.pipe(
            switchMap(() =>
                this.clientMergeToolService.getClients$().pipe(
                    tap((clients: Clients[]) => {  
                        const clientsSorted = clients.sort( (a,b) => a.Name.localeCompare(b.Name));
                        this.setClients(clientsSorted);
                    }),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    readonly getLocations$ = this.effect((trigger$) =>
        trigger$.pipe(
            withLatestFrom(this.fromClient$),
            switchMap( ([, client]) => {
                return this.clientMergeToolService.getLocations$(client.Id).pipe(
                    tap( (locations: ServiceLocations[]) => {
                        this.setLocations(locations);
                    })
                )
            })
        )
    );

    readonly getUsers$ = this.effect((trigger$) =>
        trigger$.pipe(
            withLatestFrom(this.fromClient$),
            switchMap( ([, client]) => {
                return this.clientMergeToolService.getUsers$(client.Id).pipe(
                    tap( (users: Users[]) => {
                        this.setUsers(users);
                    })
                )
            })
        )
    );

    readonly getLibraries$ = this.effect((trigger$) =>
        trigger$.pipe(
            withLatestFrom(this.fromClient$),
            switchMap( ([, client]) => {
                return this.clientMergeToolService.getLibraries$(client.Id).pipe(
                    tap( (libraries: ContentLibraries[]) => {
                        const filteredLibraries = libraries.filter(l => !l.IsGlobal);
                        this.setLibraries(filteredLibraries);
                    })
                )
            })
        )
    );

    readonly getContracts$ = this.effect((trigger$) =>
        trigger$.pipe(
            withLatestFrom(this.fromClient$),
            switchMap( ([, client]) => {
                return this.clientMergeToolService.getContracts$(client.Id).pipe(
                    tap( (contracts: Contracts[]) => {
                        this.setContracts(contracts);
                    })
                )
            })
        )
    );

    readonly mergeClients$ = this.effect((trigger$) =>
        trigger$.pipe(
            withLatestFrom(this.fromClient$, this.toClient$, this.locations$, this.users$, this.libraries$, this.contracts$),
            switchMap( ([, fromClient, toClient, locations, users, libraries, contracts]) => {
                const locationIds: number[] = locations.filter(location => location.isSelected).map(l => l.Id);
                const userIds: number[] = users.filter(user => user.isSelected).map(u => u.Id);
                const libraryIds: number[] = libraries.filter(library => library.isSelected).map(l => l.Id);
                const contractIds: number[] = contracts.filter(contract => contract.isSelected).map(c => c.Id);

                const mergeLocations$ = locationIds.length > 0 ? this.clientMergeToolService.mergeLocations$(fromClient.Id, toClient.Id, locationIds) : of('');
                const mergeUsers$ = userIds.length > 0 ? this.clientMergeToolService.mergeUsers$(fromClient.Id, toClient.Id, userIds) : of('');
                const mergeLibraries$ = libraryIds.length > 0 ? this.clientMergeToolService.mergeLibraries$(fromClient.Id, toClient.Id, libraryIds) : of('');
                const mergeContracts$ = contractIds.length > 0 ? this.clientMergeToolService.mergeContracts$(fromClient.Id, toClient.Id, contractIds) : of('');

                this.messageService.publish(Events.savingPreloader, 1);
                return concat(...[mergeLocations$, mergeUsers$, mergeLibraries$, mergeContracts$]).pipe(
                    tap( () => {
                        this.resetState();
                        this.messageService.publish(Events.savingPreloader, 0);
                    })
                )
            })
        )
    );

    //Updaters
    readonly setClients = this.updater((state, clients: Clients[]) => ({
        ...state,
        clients
    }));

    readonly setFromClient = this.updater((state, fromClient: Clients) => ({
        ...state,
        fromClient
    }));

    readonly setToClient = this.updater((state, toClient: Clients) => ({
        ...state,
        toClient
    }));

    readonly setLocations = this.updater((state, locations: ServiceLocations[]) => ({
        ...state,
        locations
    }));

    readonly setUsers = this.updater((state, users: Users[]) => ({
        ...state,
        users
    }));

    readonly setLibraries = this.updater((state, libraries: ContentLibraries[]) => ({
        ...state,
        libraries
    }));

    readonly setContracts = this.updater((state, contracts: Contracts[]) => ({
        ...state,
        contracts
    }));

    readonly selectOrDeselectAllLocations = this.updater( (state, isSelected: boolean) => ({
        ...state,
        locations: state.locations.map( (location) => {location.isSelected = isSelected; return location;})
    }))

    readonly selectOrDeselectAllUsers = this.updater( (state, isSelected: boolean) => ({
        ...state,
        users: state.users.map( (user) => {user.isSelected = isSelected; return user;})
    }))

    readonly selectOrDeselectAllLibraries = this.updater( (state, isSelected: boolean) => ({
        ...state,
        libraries: state.libraries.map( (library) => {library.isSelected = isSelected; return library;})
    }))

    readonly selectOrDeselectAllContracts = this.updater( (state, isSelected: boolean) => ({
        ...state,
        contracts: state.contracts.map( (contract) => {contract.isSelected = isSelected; return contract;})
    }))

    readonly selectAll = this.updater( (state) => ({
        ...state,
        locations: [...state.locations.map( (location) => {location.isSelected = true; return location;})],
        users: [...state.users.map( (user) => {user.isSelected = true; return user;})],
        libraries: [...state.libraries.map( (library) => {library.isSelected = true; return library;})],
        contracts: [...state.contracts.map( (contract) => {contract.isSelected = true; return contract;})]
    }))

    toggleSingleItem(prop, selectedItem): void {
        this.patchState( (state) => ({
            ...state,
            [prop]: toggleIsSelected(state, prop, selectedItem)
        }))
    }
    
    resetState(): void {
        this.setLocations([]);
        this.setUsers([]);
        this.setLibraries([])
        this.setContracts([]);
        this.setFromClient(null);
        this.setToClient(null);
        this.clientMergeToolService.continueClicked = false;
    }
}