export class GumballClients {
    ClientId: number;
    CsrId: number;
    CsrName: string;
    ClientName: string;
    PlayerNotCheckedInCount: number;
    PlayerNotInstalledCount: number;
    PlayerNotFixedCount: number;
    PlayerNotOnCurrentVersionCount: number;
    ContractsUpForRenewalCount: number;
    OpenTicketCount: number;
    OldestOpenTicketInDays: number;
    OpenContentRequestCount: number;
    OldestOpenContentRequestInDays: number;
    PlayersCallingInTooOftenCount: number;
    GumballColor: string;
    RuleFailureList: string;

    clientNameSansUselessWords: string;
    clientNameInitials: string;
    ruleFailureListView: string;
    firstLetter: string;
    gumballColorView: string;
    showX: boolean;
}