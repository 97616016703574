import { Component, Input, OnInit } from '@angular/core';

import { AddToPlaylistService } from '../../_services';
import { ContentViewService } from '../../_services';
import { PlayerCapacityService } from '../../../../../../playlist-builder/_services';
import { UtilitiesService } from 'src/app/core/services';
import { ContentVM } from '../../_models/content-view';

@Component({
	selector: 'vc-video-starter-playlists-list-view',
	templateUrl: './video-starter-playlists-list-view.component.html',
	styleUrls: ['./video-starter-playlists-list-view.component.scss']
})
export class FeaturedPlaylistsListViewComponent implements OnInit {
	@Input() canUseInPlaylist: boolean;
	@Input() content: ContentVM;
	@Input() isEvenIndex: boolean;
	@Input() isFirstIndex: boolean;
	@Input() isLastIndex: boolean;

	constructor(
		public addToPlaylistService: AddToPlaylistService,
		public contentViewService: ContentViewService,
		public playerCapacityService: PlayerCapacityService,
		public utilService: UtilitiesService
	) {}

	ngOnInit(): void {}
}
