import { Injectable } from '@angular/core';

import { AppStateService } from './app-state.service';
import { CategoriesTree, LibrariesTree } from '../../shared/components/library-tree/_models';
import { ContentFilesVM } from '../../shared/components/content-container/content/_models';
import { UtilitiesService } from './utilities.service';
import { ContentVM } from 'src/app/shared/components/content-container/content/_models/content-view';
import { ProductRoute } from 'src/app/shared/api-models/admin/product-route.enum';

@Injectable({
	providedIn: 'root'
})
export class TypeGuardService {
	constructor(private appStateService: AppStateService, private utilService: UtilitiesService) {}

	public contentIsHold(content: ContentVM): boolean {
		return content?.ContentTypeId === 1 || content?.ContentTypeId === 2 || content?.ContentTypeId === 3;
	}

	public contentIsVideo(content: Partial<ContentVM>): boolean {
		return content.ContentTypeId !== 1 && content.ContentTypeId !== 2 && content.ContentTypeId !== 3;
	}

	public contentFileIsHold(contentFile: ContentFilesVM): contentFile is ContentFilesVM {
		return this.appStateService.product?.Route === ProductRoute.HOLD || this.utilService.includes(contentFile.PreviewFileName, 'mp3');
	}

	public filesAreHold(contentFiles: ContentFilesVM[]): contentFiles is ContentFilesVM[] {
		return this.appStateService.product?.Route === ProductRoute.HOLD;
	}

	public contentFileIsVideo(contentFile: ContentFilesVM): contentFile is ContentFilesVM {
		return this.appStateService.product.Route !== ProductRoute.HOLD;
	}

	public isCategory(libraryOrCategory: LibrariesTree | CategoriesTree): libraryOrCategory is CategoriesTree {
		return !(libraryOrCategory as CategoriesTree).isLibrary && !(libraryOrCategory as CategoriesTree).isSubCategory;
	}

	public isSubCategory(libraryOrCategory: LibrariesTree | CategoriesTree): libraryOrCategory is CategoriesTree {
		return !(libraryOrCategory as CategoriesTree).isLibrary && !this.isCategory(libraryOrCategory);
	}

	public isLibrary(libraryOrCategory: LibrariesTree | CategoriesTree): libraryOrCategory is LibrariesTree {
		return (libraryOrCategory as CategoriesTree).isLibrary;
	}
}
