<div class="col-sm-6 col-lg-4 ft-size-md">
	<div [ngStyle]="{ 'height.rem': heightService.detailsAndClipsHeight() }" class="shadow border-radius white-bg pd15 mgn-bottom20">
		<div class="row mgn-bottom30">
			<div class="{{ viewStateService.mode === 'new' ? 'col-sm-6' : 'col-sm-12' }}">
				<h2 class="semi-bold">{{ viewStateService.mode !== 'new' ? 'Request Details' : 'New Request' }}</h2>
			</div>
		</div>

		<!--********************************
			TITLE
		*********************************-->
		<div class="row mgn-bottom30">
			<div class="col-sm-12 relative">
				<h4 class="gray-text-darkest mgn-bottom2 in-block">Title</h4>
				<i class="fa fa-asterisk ft-size7 red-text-light relative topneg10 left2" aria-hidden="true"></i>
				<input
					type="text"
					[ngClass]="{ 'red-border-light': !title.valid && !title.pristine }"
					name="title"
					required
					#title="ngModel"
					[(ngModel)]="createEditRequestService.request.Title"
				/>
				<small class="red-text-light" [hidden]="title.valid || title.pristine">Title is required.</small>
			</div>
		</div>

		<div class="row mgn-bottom30">
			<div class="col-xs-6">
				<h4 class="gray-text-darkest mgn-bottom2">Status</h4>
				<span class="ft-size18 regular">{{ createEditRequestService.request.clientStatusView }}</span>
			</div>

			<!--********************************
				VOICE TALENT 
			*********************************-->
			<div class="col-xs-6 relative">
				<h4 class="gray-text-darkest mgn-bottom2">Voice Talent</h4>
				<i *ngIf="viewStateService.mode === 'new'" class="fa fa-asterisk ft-size7 red-text-light relative topneg10 left2" aria-hidden="true"></i>
				<div *ngIf="viewStateService.isStatusBeforeInProduction">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="[{ name: 'Alternating Male/Female' }, { name: 'Both Male/Female' }, { name: 'All Female' }, { name: 'All Male' }]"
						[key]="'name'"
						[scrollbarHidden]="true"
						[selectedOption]="createEditRequestService.request.voiceTalentView"
						(onOptionSelect)="detailsViewService.onDropdownSelect($event, 'VoiceTalent', 'name')"
					></portal-dropdown-menu>
				</div>
				<span *ngIf="!viewStateService.isStatusBeforeInProduction" class="ft-size18 regular">{{ createEditRequestService.request.VoiceTalent }}</span>
			</div>
		</div>

		<!--********************************
			NOTES
		*********************************-->
		<div class="row mgn-bottom30">
			<div class="col-sm-12">
				<h4 class="gray-text-darkest mgn-bottom2">Notes</h4>
				<textarea
					*ngIf="viewStateService.isStatusBeforeInProduction"
					rows="4"
					placeholder="Anything special we should know about this request? Tell us here!"
					(keyup)="detailsViewService.onKeyup('Description')"
					[(ngModel)]="createEditRequestService.request.Description"
				></textarea>
				<textarea *ngIf="!viewStateService.isStatusBeforeInProduction" readonly rows="4">
					{{ createEditRequestService.request.Description }}
				</textarea
				>
			</div>
		</div>

		<!--***************************************
			REQUESTED BY & REQUEST APPROVAL FROM
			************************************-->
		<div class="row mgn-bottom30">
			<div class="col-xs-6">
				<h4 class="gray-text-darkest mgn-bottom2 in-block">Request Approval From</h4>
				<span
					*ngIf="!viewStateService.isStatusBeforeInProduction"
					class="pointer mgn-left6"
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					data-tippy-content="User who approved the scripts for this request."
					[tippyProps]="{ appendTo: 'parent' }"
				>
					<i class="far fa-question-circle" aria-hidden="true"></i>
				</span>
				<span
					*ngIf="viewStateService.isStatusBeforeInProduction"
					class="pointer relative left6"
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					data-tippy-content="Select a different user to approve the scripts for this request (optional, defaults to you)."
					[tippyProps]="{ appendTo: 'parent' }"
				>
					<i class="far fa-question-circle" aria-hidden="true"></i>
				</span>
				<div *ngIf="viewStateService.isStatusBeforeInProduction">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="detailsService.usersByClient$ | async"
						[key]="'UserName'"
						[scrollbarHidden]="true"
						[selectedOption]="createEditRequestService.request.requestApprovalFromUsername"
						(onOptionSelect)="detailsViewService.onDropdownSelect($event, 'RequestApprovalFrom', 'Id')"
					></portal-dropdown-menu>
				</div>
				<div *ngIf="!viewStateService.isStatusBeforeInProduction" class="ellipsis">
					<span class="ft-size18 regular">{{ createEditRequestService.request.requestApprovalFromUsername }}</span>
				</div>
			</div>
			<div class="col-xs-6">
				<h4 class="gray-text-darkest mgn-bottom2">Requested By</h4>
				<div class="ellipsis">
					<span class="ft-size18 regular">{{ createEditRequestService.request.requestedByUsername }}</span>
				</div>
			</div>
		</div>

		<!--***************************************
			TARGET LIBRARY & CREATED DATE
			************************************-->
		<div class="row">
			<div class="col-sm-6 ht62 mgn-bottom20">
				<h4 class="gray-text-darkest mgn-bottom2 in-block">Target Library</h4>
				<i class="fa fa-asterisk ft-size7 red-text-light relative topneg10 left2" aria-hidden="true"></i>
				<span
					class="pointer relative left6"
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					data-tippy-content="Once your messages are fully produced and approved they will be placed in this library."
					[tippyProps]="{ appendTo: 'parent' }"
				>
					<i class="far fa-question-circle" aria-hidden="true"></i>
				</span>
				<br />
				<div *ngIf="viewStateService.isStatusBeforeComplete">
					<portal-dropdown-menu
						*ngIf="detailsService.libraries"
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="detailsService.libraries"
						[key]="'LibraryName'"
						[scrollbarHidden]="true"
						[selectedOption]="createEditRequestService.request.targetLibraryName"
						(onOptionSelect)="detailsViewService.onDropdownSelect($event, 'TargetLibraryId', 'Id')"
					></portal-dropdown-menu>
				</div>
				<span *ngIf="!viewStateService.isStatusBeforeComplete" class="ft-size18 regular">{{ createEditRequestService.request.targetLibraryName }}</span>
			</div>
			<div class="col-sm-6">
				<h4 class="gray-text-darkest mgn-bottom2">Created Date</h4>
				<span class="ft-size18 regular">{{ createEditRequestService.request.CreateDateTime | date : 'MM/dd/yyyy' }}</span>
			</div>
		</div>

		<div *ngIf="viewStateService.mode === 'new'" class="row mgn-top30 mgn-bottom30">
			<div class="col-sm-6 relative">
				<h4 class="gray-text-darkest in-block">Bypass script writer?</h4>
				<span
					class="pointer relative left6"
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					data-tippy-content='Happy with your message scripts and spelling? Select "Yes" for your request to go directly to our studio for production.'
					[tippyProps]="{ appendTo: 'parent' }"
				>
					<i class="far fa-question-circle" aria-hidden="true"></i>
				</span>
				<br />
				<label class="toggleSwitch nolabel mgn-top6 ft-size13 absolute top4">
					<input type="checkbox" (change)="detailsViewService.onBypassScriptWriterClick()" [checked]="createEditRequestService.request.bypassScriptWriter" />
					<span>
						<span>No</span>
						<span>Yes</span>
					</span>
					<a></a>
				</label>
			</div>
			<div class="col-sm-6">
				<span *ngIf="createEditRequestService.request.bypassScriptWriter">Request will go directly to the studio for production.</span>
				<span *ngIf="!createEditRequestService.request.bypassScriptWriter">Request will go to our script writer for editing.</span>
			</div>
		</div>
	</div>
</div>
