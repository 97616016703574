import { Component, OnInit } from '@angular/core';

import { Quotes } from '../not-found/quotes';

@Component({
  selector: 'portal-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  public quotes = Quotes;
  public randomQuote: {quote: string, author: string};

  constructor() { }

  ngOnInit() {
  	let index = Math.floor(Math.random() * this.quotes.length);
  	this.randomQuote = this.quotes[index];
  }
}
