<div class="pd20 border-radius relative white-bg shadow-wide box">
    <div class="row">
        <div class="col-sm-12 text-center">
            <h2 class="semi-bold"><em>Your session is about to expire</em></h2>				
        </div>
    </div>
    <div class="row mgn-top60 mgn-bottom30">
        <div class="col-xs-10 col-xs-offset-1 center">
            <h3>You've been inactive for awhile. For your security, we'll sign you out in approximately 1 minute. You may choose "Stay signed in" to continue or sign out if you're done.</h3>
        </div>
    </div>

    <div class="row mgn-top60">
        <div class="col-xs-6">
            <button (click)="sessionExpireService.signOut()" class="gray-bg">Sign out</button>
        </div>
        <div class="col-xs-6">
            <button id="stay-signed-in" (click)="onStaySignedInClick()" class="blue-bg-darkest white-text">Stay signed in</button>
        </div>
    </div>
</div>