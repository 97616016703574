<portal-header></portal-header>

<div class="container-fluid mx-4 mgn-top30 relative">
	<a [routerLink]="['/crm/client-details/locations']" class="dark-text absolute topneg21 left0">
		<i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
		<span class="ft-size16">Back</span>
	</a>
	<div class="ft-size16">
		<div class="row relative">
			<h1 class="semi-bold mgn-top10">
				<i class="fa fa-history mgn-right12" aria-hidden="true"></i>
				New History Note
			</h1>
		</div>
		<div class="row green-limeGreen-bg-gradient ht2 mgn-top15 border-radius"></div>

		<form (keydown.enter)="$event.preventDefault()" #form="ngForm" novalidate (ngSubmit)="onSaveClick()">
			<div class="white-bg shadow pd15 mgn-leftneg15 mgn-rightneg15 border-radius mgn-top30 mgn-bottom40">
				<div class="row">
					<div class="col-sm-10 col-sm-offset-2">
						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<span class="semi-bold">Client:</span>
							</div>
							<div class="col-sm-6 relative">
								<span>{{ appStateService.currentClient.Name }}</span>
							</div>
						</div>
						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<span class="semi-bold">Author:</span>
							</div>
							<div class="col-sm-6 relative">
								<span>{{ appStateService.currentUser.UserName }}</span>
							</div>
						</div>
						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<span class="semi-bold">Location:</span>
							</div>
							<div class="col-sm-6 relative">
								<portal-dropdown-menu
									[backgroundColor]="'gray-bg-dark'"
									[height]="'ht40'"
									[key]="'name'"
									[options]="locations"
									[selectedOption]="locations ? locations[0].name : null"
									(onOptionSelect)="onDropdownSelect($event, 'ServiceLocationId')"
								></portal-dropdown-menu>
								<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
							</div>
						</div>

						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<span class="semi-bold">Product:</span>
							</div>
							<div class="col-sm-6 relative">
								<portal-dropdown-menu
									[backgroundColor]="'gray-bg-dark'"
									[height]="'ht40'"
									[key]="'name'"
									[options]="products"
									[selectedOption]="products[0].name"
									(onOptionSelect)="onDropdownSelect($event, 'EntrySource')"
								></portal-dropdown-menu>
								<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
							</div>
						</div>

						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<span class="semi-bold">Entry Type:</span>
							</div>
							<div class="col-sm-6 relative">
								<portal-dropdown-menu
									[backgroundColor]="'gray-bg-dark'"
									[height]="'ht40'"
									[key]="'name'"
									[options]="entryTypesService.entryTypes"
									[selectedOption]="entryTypesService.entryTypes[0].name"
									(onOptionSelect)="onDropdownSelect($event, 'EntryType')"
								></portal-dropdown-menu>
								<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
							</div>
						</div>

						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<span class="semi-bold">Subject:</span>
							</div>
							<div class="col-sm-6 relative">
								<input
									[ngClass]="{ 'red-border-light': !subject.valid && !subject.pristine }"
									required
									#subject="ngModel"
									id="subject"
									name="subject"
									[(ngModel)]="historyNote.LogSubject"
									type="text"
								/>
								<small class="red-text-light" [hidden]="subject.valid || (subject.pristine && !form.submitted)">Subject is required.</small>
								<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
							</div>
						</div>

						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<span class="semi-bold">Notes:</span>
							</div>
							<div class="col-sm-6 relative">
								<textarea
									[ngClass]="{ 'red-border-light': !notes.valid && !notes.pristine }"
									rows="6"
									required
									#notes="ngModel"
									id="notes"
									name="notes"
									[(ngModel)]="historyNote.LogMessage"
									type="text"
								></textarea>
								<small class="red-text-light" [hidden]="notes.valid || (notes.pristine && !form.submitted)">
									Notes are required (I mean it's a history NOTE :).
								</small>
								<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
							</div>
						</div>
					</div>
				</div>

				<div class="row mgn-top50 mgn-bottom50">
					<div class="col-sm-10 col-sm-offset-2">
						<div class="row">
							<div class="col-sm-8">
								<div class="row">
									<div class="col-xs-3 col-sm-offset-6">
										<button type="button" class="gray-bg" [routerLink]="['/crm/client-details/locations']">Cancel</button>
									</div>
									<div class="col-xs-3">
										<button [disabled]="!form.valid" class="limeGreen-bg white-text">Save</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
