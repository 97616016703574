<div (mouseover)="showDeleteBtn = true" (mouseleave)="showDeleteBtn = false" class="relative mgn-left5 mgn-top5">
    <span class="mgn-top4 mgn-bottom4 pointer {{librariesService.selectedFeaturedPlaylist?.PlaylistExample.Id === playlist.Id ? 'semi-bold' : ''}}" (click)="onPlaylistClick()"><i class="fas fa-play-circle mgn-right10"></i>{{playlist.Name}}</span>
    <i *ngIf="userIsAdmin() && showDeleteBtn === true;" (click)="onDeleteClick()" class="far fa-trash-alt absolute topneg3 right10 ft-size14 red-text-light pd5 pointer"></i>
    <i *ngIf="userIsAdmin() && showDeleteBtn === true;" (click)="onEditClick()" class="fas fa-pencil-alt absolute topneg3 right32 ft-size14 pd5 pointer"></i>

    <!--EDIT INSTANT PLAYLIST NAME-->
<ng-container *ngIf="playlist.state === 'edit'">
    <input style="width: 85%;" type="text" [(ngModel)]="playlist.Name" #playlistNameInput class="white-bg ht28 ft-size12" (keyup.enter)="onSaveNameClick()">
    <div style="width:15%;" class="relative in-block">
        <button class="limeGreen-bg border-radius ht25 white-text pd0 pd-left4 pd-right4 ft-size12" (click)="onSaveNameClick()"><i class="fas fa-check"></i></button>
        <a class="absolute bottom11 rightneg14 pd2 dark-text border-radius" (click)="playlist.state = 'read'"><i class="fa fa-times ft-size11 " aria-hidden="true"></i></a>
    </div>
</ng-container>
</div>


