import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Create24Module } from './create-24/create-24.module';
import { SoftwareRoutingModule } from './software-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    Create24Module,
    SoftwareRoutingModule
  ]
})
export class SoftwareModule { }
