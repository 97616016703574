import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AppStateService, DateTimeService } from '../../../core/services';
import { ApiUrlsService } from '../_services/api-urls.service';
import { ClientHistoryNotes } from '../../../shared/api-models/log';
import { CsrTasksView } from '../_models/csr-tasks-view';
import { environment } from '../../../../environments/environment';
import { ViewStateService } from '../_services/view-state.service';

@Injectable({
	providedIn: 'root'
})
export class CsrTasksService {
	public totalItems: number;

	constructor(
		private appStateService: AppStateService,
		private apiUrlsService: ApiUrlsService,
		private dateTimeService: DateTimeService,
		private httpClient: HttpClient,
		private viewStateService: ViewStateService
	) {}

	public getTasks(route: 'my-tasks' | 'by-client'): Observable<CsrTasksView[]> {
		return this.httpClient.get(this.url(route), { observe: 'response' }).pipe(
			map((res: HttpResponse<any>) => {
				this.totalItems = parseInt(res.headers.get('x-result-count'));
				let tasks = res.body;
				return this.tasksView(tasks);
			})
		);
	}

	public activeUrlHasCompletedOnlyParam(): boolean {
		return [12, 13, 14, 15, 16, 17, 18, 19].indexOf(this.activeUrlIndex()) < 0;
	}

	public updateTask(task: CsrTasksView): Observable<CsrTasksView> {
		return this.httpClient.put<CsrTasksView>(`${environment.adminUrl}CoreCsrTasks/${task.Id}`, task);
	}

	public deleteTask(task: CsrTasksView): Observable<any> {
		return this.httpClient.delete(`${environment.adminUrl}CoreCsrTasks/${task.Id}`);
	}

	private url(route: 'my-tasks' | 'by-client'): string {
		if (route === 'my-tasks') {
			return this.apiUrlsService.apiUrls()[this.activeUrlIndex()].url;
		} else if (route === 'by-client') {
			return `${environment.adminUrl}CoreCsrTasks/Client/${this.appStateService.currentClient.Id}`;
		}
	}

	public activeUrlIndex(): number {
		return this.viewStateService.urlIndexes().find((urlIndex) => urlIndex !== -1);
	}

	private tasksView(tasks: CsrTasksView[]): CsrTasksView[] {
		return tasks.map((task) => {
			task.editBtn = 'Edit';
			task.completeBtn = task.CompleteDate ? 'Uncomplete' : 'Complete';
			task.deleteBtn = 'Delete';
			task.dueDateView = this.dateTimeService.dateLocal(task.DueDate);
			task.completeDateView = task.CompleteDate ? this.dateTimeService.dateUTCtoLocal(task.CompleteDate) : '<em>N/A</em>';
			task.classList = task.CompleteDate ? 'pd-top5 pd-bottom5 gray-bg-darkest' : 't-row white-bg pd-top5 pd-bottom5';
			return task;
		});
	}

	public historyNote(logMessage: string, logSubject: string, clientId: number): ClientHistoryNotes {
		let historyNote: ClientHistoryNotes = new ClientHistoryNotes();
		historyNote.ClientId = clientId;
		historyNote.UserId = this.appStateService.currentUser.UserId;
		historyNote.EntrySource = 'Other';
		historyNote.EntryType = 'Completed Task';
		historyNote.LogDate = new Date().toISOString();
		historyNote.LogSubject = logSubject;
		historyNote.IsSystemGenerated = false;
		historyNote.IsError = false;
		historyNote.LogMessage = logMessage;
		return historyNote;
	}

	private logMessage(task: CsrTasksView): string {
		return `Service Task Complete: ${task.Notes}`;
	}

	private logSubject(task: CsrTasksView): string {
		return `Task Complete: ${task.Subject}`;
	}

	public postHistoryNote(task: CsrTasksView): Observable<ClientHistoryNotes> {
		let historyNote: ClientHistoryNotes = this.historyNote(this.logMessage(task), this.logSubject(task), task.ClientId);
		return this.httpClient.post(`${environment.adminUrl}ClientHistoryNotes`, historyNote);
	}
}
