export enum EPlayerModels {
	TWENTY_SEVEN_HUNDRED_THREE_FIFTEEN = 2,
	TWENTY_SEVEN_TWELVE = 3,
	VOIP = 4,
	TWENTY_SEVEN_O_SIX = 5,
	NET_7000 = 6,
	TWENTY_SEVEN_O_FOUR = 8,
	SEVENTY_FIVE_HUNDRED = 9,
	SEVENTY_FIVE_HUNDRED_ANALOG = 10,
	TWENTY_SEVEN_O_EIGHT = 11,
	TWENTY_SEVEN_O_THREE_THREE_MIN = 13,
	TWENTY_SEVEN_O_THREE_E = 15,
	SEVENTY_ONE_HUNDRED = 18,
	BARIX_EXSTREAMER = 55,
	PLAY_24 = 56,
	LICENSE_ONLY = 99,
	SONY_NSP1 = 102,
	WIN_PC = 104,
	LENOVO = 111,
	NEXCOM = 112,
	SENECA = 113,
	NEW_UNIT = 124,
	INTEL_NUC_I5 = 125,
	INTEL_NUC_I3 = 126,
	FLASH_WEB_PLAYER = 127,
	JS_WEB_PLAYER = 128,
	VIDEO_WALL_PC = 129,
	APPLE_TV = 1000000,
	HALO_MUSIC = 1000005,
	HALO_NETWORK_HOLD = 1000006,
	HALO_VIDEO = 1000007,
	LINUX_HOLD = 1000009,
	LINUX_OVERHEAD_PLAY_24 = 1000011
}
