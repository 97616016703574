export class PlayerStatusReport {
	Id: number;
	ClientName: string;
	LocationName: string;
	Csr: string;
	UnitId_Mac: string;
	Notes: string;
	Model: string;
	InstallState: string;
	Repair: string;
	LastCheckin: string;
	LastUpdate: string;
	ActivePlaylist: string;
	Hostname: string;
	PlayerName: string;
	ReportedVersion: string;
	TargetVersion: string;
	_clientId: number;
	_locationId: number;
	_csrId: number;
	_playerId: number;
	_productTypeId: number;
	_poorNetworkConditions: boolean;
	_IsDemoAccount: boolean;
	_clientIsInternal: boolean;
}
