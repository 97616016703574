<portal-header></portal-header>

<section class="container-fluid mgn-top30 relative">
    <a [routerLink]="['/client-browser']" class="dark-text absolute topneg21 left15">
        <i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
        <span class="ft-size16">Client Browser</span>
    </a>
    <div class="row mgn-top10">
		<div class="col-sm-9">
            <h1 class="relative">
                <i class="fas fa-bullhorn mgn-right15"></i>
                <i class="far fa-envelope absolute left0 topneg5 ft-size16"></i>
                
                <span class="semi-bold">Email Blast Tool</span> | <em class="capitalize"> {{routeFriendlyView()}}</em>
            </h1>
        </div>
    </div>
    <div class="row mgn-right0 mgn-left0 white-bg shadow ht64 ln-height64 border-radius mgn-top15">
        <nav class="col-xs-12 center">
            <a [ngClass]="route === 'my-mailings' ? 'active' : null" (click)="onNavClick('my-mailings')" class="dark-text pd22 border-radius">
                <i class="far fa-paper-plane mgn-right8 ft-size18"></i> My Mailings
            </a>
            <a [ngClass]="route === 'my-templates' ? 'active' : null" (click)="onNavClick('my-templates')" class="dark-text pd22 border-radius">
                <i class="far fa-edit mgn-right8 ft-size18"></i> My Templates
            </a>          
        </nav>
    </div>
</section>

<div class="row mgn-right0 mgn-left0 mgn-top30">
    <div *ngIf="route === 'my-templates'" class="col-sm-2">
        <button [routerLink]="['/email-blast-tool/create-template']" class="white-bg"><i class="fas fa-plus mgn-right12"></i>Create template</button>
    </div>
    <div class="col-xs-3 relative {{route === 'my-templates' ? 'col-xs-offset-7' : 'col-xs-offset-9'}}">
        <input (keyup)="onSearchKeyup()" [(ngModel)]="searchTerm" type="text" placeholder="Search...">
        <button *ngIf="searchTerm.length > 0" style="width: initial;" class="absolute top8 white-bg pd12 right20 z" (click)="searchTerm = ''">
            <i class="fa fa-times absolute center-unknown-height-width" aria-hidden="true"></i>
        </button>
    </div>
</div>

<section [ngSwitch]="true" class="container-fluid mgn-top15">
    <ng-container *ngSwitchCase="route === 'my-templates'">
        <portal-table *ngIf="emailBlastToolService.templates; else noItems"
                    [table]="tableConfigService.templatesTable"
                    [config]="tableConfigService.config"
                    [data]="searchTerm.length > 0 ? searchResults : emailBlastToolService.templates" 
                    [page]="1"
                    [totalItems]="searchTerm.length > 0 ? searchResults.length : emailBlastToolService.templates.length"
                    (buttonClickNotify)="onTableButtonClick($event)">
        </portal-table>
        <ng-template #noItems><h3><em>No results found</em></h3></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="route === 'my-mailings'">
        <portal-table *ngIf="emailBlastToolService.mailings; else noItems"
                    [table]="tableConfigService.mailingsTable"
                    [config]="tableConfigService.config"
                    [data]="searchTerm.length > 0 ? searchResults : emailBlastToolService.mailings" 
                    [page]="1"
                    [sortBy]="{key: 'CreatedDate', order: 'desc'}"
                    [totalItems]="searchTerm.length > 0 ? searchResults.length : emailBlastToolService.mailings.length"
                    (buttonClickNotify)="onTableButtonClick($event)">
        </portal-table>
        <ng-template #noItems><h3><em>No results found</em></h3></ng-template>
    </ng-container>
    
</section>
<ng-template #loading><div class="relative center-unknown-height-width top200 z"><portal-spinner [height]="'htpx90'" [width]="'wdthpx90'"></portal-spinner></div></ng-template>
