import { Component, Input, OnInit } from '@angular/core';

import { AppStateService } from '../../../../../core/services';
import { ContentViewService } from '../_services';
import { ContentSerializerService, Events, MessageService, UtilitiesService } from '../../../../../core/services';
import { PlaylistService } from 'src/app/playlist-builder/_services';
import { ContentVM } from '../_models/content-view';

@Component({
	selector: 'portal-preview-thumbnail',
	templateUrl: './preview-thumbnail.component.html',
	styleUrls: ['./preview-thumbnail.component.scss']
})
export class PreviewThumbnailComponent implements OnInit {
	@Input() content: Partial<ContentVM>;

	constructor(
		public appStateService: AppStateService,
		private contentSerializerService: ContentSerializerService,
		public contentViewService: ContentViewService,
		private messageService: MessageService,
		private playlistService: PlaylistService,
		public utilService: UtilitiesService
	) {}

	ngOnInit() {}

	public contentFileStateId(): number {
		if (this.content.contentFiles && this.content.contentFiles[0]) {
			return this.content.contentFiles[0].ContentFileStateId;
		}
	}

	public onVideoPreviewClick(): void {
		this.utilService.scrollToTop();
		this.playlistService.selectedContentItem = this.contentSerializerService.contentToContentItem(this.content);
		this.messageService.publish(Events.showPbModal, ['playlist-item-preview', this.playlistService.selectedContentItem.contentId]);
	}

	public height(): number {
		if (this.content.ContentTypeId === 1000009 || this.content.ContentTypeId === 1000002) {
			//Video-Sidebar-Create24
			//Sidebar is not a product, so we can't use product dimensions
			return this.utilService.calculateAspectRatioFit(485, 725, 150, 170).height;
		}
		return this.utilService.calculateAspectRatioFit(this.appStateService.product?.WidthPx, this.appStateService.product?.HeightPx, 150, 170).height;
	}

	public width(): number {
		if (this.content.ContentTypeId === 1000009 || this.content.ContentTypeId === 1000002) {
			//Video-Sidebar-Create24
			//Sidebar is not a product, so we can't use product dimensions
			return this.utilService.calculateAspectRatioFit(485, 725, 150, 170).width;
		}
		return this.utilService.calculateAspectRatioFit(this.appStateService.product?.WidthPx, this.appStateService.product?.HeightPx, 150, 170).width;
	}
}
