import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AppStateService, Events, MessageService } from '../../../../../core/services';
import { CategoriesService } from '../../../library-tree/_services/categories.service';
import { ContentItem } from '../../../../view-models/content';
import { environment } from '../../../../../../environments/environment';
import { FavoriteContent } from '../../../../api-models/content';
import { LibrariesService } from '../../../library-tree/_services/libraries.service';
import { ContentViewStateService } from '../../../../services';
import { ContentService } from './content.service';
import { ContentVM } from '../_models/content-view';

@Injectable({
	providedIn: 'root'
})
export class ContentFavoritesService {
	constructor(
		private appStateService: AppStateService,
		private categoriesService: CategoriesService,
		private contentService: ContentService,
		private httpClient: HttpClient,
		private librariesService: LibrariesService,
		private messageService: MessageService,
		private cvStateService: ContentViewStateService
	) {}

	public onFavoriteClick(selectedContent: ContentVM | ContentItem): void {
		this.messageService.publish(Events.savingPreloader, 1);

		//if content already is a favorite remove it
		if (selectedContent.isFavorite) {
			this.deleteFavorite(selectedContent).subscribe((refreshedContent: ContentVM[]) => {
				//only refresh if search is not active
				if (!this.cvStateService.viewState.contentSearchActive) {
					//refresh favorites for view
					this.saveOrDeleteFavoriteComplete(refreshedContent);
				}
				selectedContent.isFavorite = false;
				selectedContent.favoriteBtnTooltip = 'Add to favorites';
				this.messageService.publish(Events.savingPreloader, 0);
			});

			//save as favorite
		} else {
			this.saveAsFavorite(selectedContent).subscribe((refreshedContent: ContentVM[]) => {
				//only refresh if search is not active
				if (!this.cvStateService.viewState.contentSearchActive) {
					//refresh favorites for view
					this.saveOrDeleteFavoriteComplete(refreshedContent);
				}
				selectedContent.isFavorite = true;
				selectedContent.favoriteBtnTooltip = 'Remove from favorites';
				this.messageService.publish(Events.savingPreloader, 0);
			});
		}
	}

	private deleteFavorite(content: ContentVM | ContentItem): Observable<any> {
		return this.httpClient.delete(`${environment.contentUrl}FavoriteContent/${this.appStateService.currentClient.Id}/${content.Id}`).pipe(
			mergeMap(() => {
				return this.httpClient.get(`${environment.contentUrl}Content/Favorites/${this.appStateService.currentClient.Id}`);
			})
		);
	}

	private saveAsFavorite(content: ContentVM | ContentItem): Observable<any> {
		return this.httpClient.post(`${environment.contentUrl}FavoriteContent`, this.favoriteContent(content)).pipe(
			mergeMap(() => {
				return this.httpClient.get(`${environment.contentUrl}Content/Favorites/${this.appStateService.currentClient.Id}`);
			})
		);
	}

	private favoriteContent(content: ContentVM | ContentItem): FavoriteContent {
		return {
			ClientId: this.appStateService.currentClient.Id,
			ContentId: content.Id
		};
	}

	private saveOrDeleteFavoriteComplete(content: ContentVM[]): void {
		this.librariesService.contentFavorites = content;

		//update favorite libraries list in view
		this.librariesService.libraries.forEach((library) => {
			library.isOrContainsFavorites = this.librariesService.contentFavorites.some((favorite) => favorite.LibraryId === library.Id);
		});

		//re-create favorite libraries array
		this.librariesService.assignFavoriteLibraries(this.librariesService.libraries);

		if (this.categoriesService.clickedCategory) {
			//re-get content from selected category
			let payload = [this.categoriesService.clickedCategory];
			this.contentService.displayContent(payload);
		}
	}
}
