import { InstallersView } from './installers-view';
import { WorkOrdersView } from '../create-edit-ticket/_models';

export class WorkOrderPDF {
	sentDate: string;
	requestedBy: string;
	productType: string;
	specialInstructions: string;
	Installer: InstallersView;
	WorkOrder: WorkOrdersView;
}