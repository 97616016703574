import { Component, EventEmitter, Input, OnInit, Output, OnChanges, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BrowserDetectorService, UtilitiesService } from '../../../core/services';
import { SearchFilterPipe } from '../../../shared/pipes/search-filter.pipe';

@Component({
	selector: 'portal-dropdown-menu',
	templateUrl: './dropdown-menu.component.html',
	styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnInit, OnChanges {
	@Input() backgroundColor: string;
	@Input() errorVisible: boolean;
	@Input() flow: string;
	@Input() fontSize: string;
	@Input() hasItemDeleteIcon: boolean;
	@Input() height: string;
	@Input() hideBorder: boolean;
	@Input() hideSquareSelectButton: boolean;
	@Input() isDisabled: boolean;
	@Input() key: string;
	@Input() listMaxHeight: string;
	@Input() menuBackgroundColor: string;
	@Input() options: any[];
	@Input() searchEnabled: boolean;
	@Input() selectedOption: any;
	@Input() setFontFamily: boolean;
	@Input() showClientBrowserBtn: boolean;
	@Input() scrollbarHidden: boolean;
	@Input() textColor: string;

	@Output() deleteClickNotify: EventEmitter<any> = new EventEmitter<any>();
	@Output() dropdownClickNotify: EventEmitter<any> = new EventEmitter<any>();
	@Output() onOptionSelect: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSearchKeyupNotify: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('hiddenSearchInput') hiddenSearchInput: ElementRef;
	@ViewChild('listContainer') listContainer: ElementRef;
	@ViewChild('visibleSearchInput') visibleSearchInput: ElementRef;
	@ViewChildren('options') domOptions: QueryList<ElementRef>;
	@ViewChildren('input') listItem: QueryList<ElementRef>;

	public arrowkeyLocation: number = 0;
	public heightNum: number;
	public deleteIconVisible: boolean;
	public element: any;
	public itemIndex: number;
	public optionsVisible: boolean;
	public optionsView: any;
	public searchTerm: string;

	constructor(
		public browserDetectorService: BrowserDetectorService,
		private deviceDetectorService: DeviceDetectorService,
		private searchFilterPipe: SearchFilterPipe,
		private utilService: UtilitiesService
	) {}

	ngOnInit() {
		this.heightNum = +this.height.slice(2);
	}

	ngOnChanges() {
		this.optionsView = this.options;
		//Automatically highlight first search option
		if (this.optionsView?.length) {
			setTimeout(() => {
				this.element = document.getElementById(this.optionsView[0][this.key]);
			}, 20);
		}
	}

	public buttonColor(): string {
		switch (this.backgroundColor) {
			case 'gray-bg':
				return 'gray-bg-darker';

			case 'gray-bg-dark':
				return 'gray-bg-darkest';

			case 'white-bg':
				return 'gray-bg-darker';

			case 'white-bg':
				return 'lt-gray-bg-darker';
		}
	}

	public borderColor(): string {
		if (this.errorVisible) {
			return 'red-border-light';
		}
		switch (this.backgroundColor) {
			case 'gray-bg-dark':
				return 'gray-border-dark';
			case 'white-bg':
				return 'gray-border-darker';

			default:
				return 'lt-gray-border-darker';
		}
	}

	public classList(): string {
		switch (true) {
			case this.isDisabled:
				return 'disabled';

			case !this.errorVisible && !this.borderColor:
				return 'lt-gray-border-darker pointer';

			case !this.errorVisible && this.borderColor !== null && this.borderColor !== undefined:
				return `${this.borderColor} pointer`;

			case this.errorVisible:
				return 'red-border-light pointer';
		}
	}

	public onDeleteClick(event, option): void {
		event.stopPropagation(); //prevent onOptionClick() from firing
		this.optionsVisible = false;
		this.deleteClickNotify.emit(option);
	}

	//Opens the dropdown
	public onDropdownClick(): void {
		if (!this.isDisabled) {
			this.optionsVisible = !this.optionsVisible;
		}
		this.dropdownClickNotify.emit();
		if (this.options && this.optionsVisible) {
			//automatically select hidden input so we can quickly find an item
			setTimeout(() => this.hiddenSearchInput?.nativeElement.select(), 10);
		}
		if (this.searchEnabled) {
			setTimeout(() => this.visibleSearchInput.nativeElement.select(), 10);
		}

		//Preserve previous scoll position
		setTimeout(() => {
			if (this.listContainer) {
				let totalHeight: number = 0;
				let avgHeight: number = 0;

				this.domOptions.forEach((option) => {
					const height = option.nativeElement.offsetHeight;
					totalHeight += height;
				});
				avgHeight = totalHeight / this.options.length;

				//Find index of selected option
				const index = this.options.findIndex((o) => o[this.key] === this.selectedOption);
				const scrollNumPerItem: number = avgHeight;
				//Set scroll top
				this.listContainer.nativeElement.scrollTop = scrollNumPerItem * index;
			}
		}, 50);
	}

	public onHiddenSearchKeyup(event, term: string): void {
		const lowerCaseTerm = term.toLocaleLowerCase();
		if (this.optionsView) {
			const foundOption = this.optionsView.find((option) => {
				if (option[this.key]) {
					return this.utilService.includes(option[this.key].toLocaleLowerCase(), lowerCaseTerm);
				}
			});
			this.scrollToFoundOption(foundOption);
			this.selectOptionOnEnterClick(event, foundOption);
		}
	}

	public onInputChange(event, term: string): void {
		const lowerCaseTerm = term.toLocaleLowerCase();
		const foundOption = this.options.find((option) => {
			if (option[this.key]) {
				return this.utilService.includes(option[this.key].toLocaleLowerCase(), lowerCaseTerm);
			}
		});
		this.scrollToFoundOption(foundOption);
		this.selectOptionOnEnterClick(event, foundOption);
	}

	//Select a list item
	public onOptionClick(option: any, name: any): void {
		this.selectedOption = name;
		this.optionsVisible = false;
		this.onOptionSelect.emit(option);
	}

	public onSearchKeydown(event: KeyboardEvent): void {
		switch (event.keyCode) {
			case 38: // this is the ascii of arrow up
				if (this.arrowkeyLocation === 0) {
					//select search input
					setTimeout(() => this.visibleSearchInput.nativeElement.focus(), 10);
				}
				if (this.arrowkeyLocation > 0) {
					this.arrowkeyLocation--;
					setTimeout(() => this.listItem.toArray()[this.arrowkeyLocation].nativeElement.focus(), 20);
					//scroll up on arrow up
					this.onHiddenSearchKeyup(event, this.listItem.toArray()[this.arrowkeyLocation].nativeElement.value);
				}
				break;
			case 40: // this is the ascii of arrow down
				if (this.optionsView.length === 1) {
					setTimeout(() => this.listItem.toArray()[0].nativeElement.focus(), 20);
					return;
				}
				if (this.arrowkeyLocation < this.optionsView.length - 1) {
					this.arrowkeyLocation++;
					setTimeout(() => this.listItem.toArray()[this.arrowkeyLocation].nativeElement.focus(), 20);
					//scroll down on arrow down
					this.onHiddenSearchKeyup(event, this.listItem.toArray()[this.arrowkeyLocation].nativeElement.value);
				}
				break;
		}
	}

	public showHideDeleteIcon(index: number, stringId: 'show' | 'hide'): void {
		this.itemIndex = index;
		if (stringId === 'show') {
			this.deleteIconVisible = true;
		} else if (stringId === 'hide') {
			this.deleteIconVisible = false;
		}
	}

	public autoCloseDropdown(event) {
		if (!this.utilService.closest(event, '.noExit')) {
			this.optionsVisible = false;
		}
	}

	public onVisibleSearchKeyup(event): void {
		//filter list by search term
		this.optionsView = this.searchFilterPipe.transform(this.options, this.searchTerm);

		this.onSearchKeyupNotify.emit(this.searchTerm);
		//not backspace or the arrow keys
		if (event.keyCode !== 8 && event.keyCode !== 38 && event.keyCode !== 40) {
			this.onHiddenSearchKeyup(event, this.searchTerm);
		}
	}

	private scrollToFoundOption(foundOption): void {
		if (foundOption) {
			this.element = document.getElementById(foundOption[this.key]);
			switch (this.deviceDetectorService.browser) {
				//only browsers that support this method - 8/2018
				case 'Chrome':
				case 'Safari':
					this.element.scrollIntoViewIfNeeded();
					break;
				default:
					this.element.scrollIntoView();
					break;
			}
		}
	}

	private selectOptionOnEnterClick(event, foundOption): void {
		if (event.keyCode === 13) {
			this.optionsVisible = false;
			this.onOptionClick(foundOption, this.element.id);
		}
	}
}
