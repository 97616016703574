import { FeedItemsView } from './feed-items-view';
import { Feeds } from '../../../shared/api-models/content';

export class ViewState {
	selectedFeed: Feeds;
	selectedFeedItem: FeedItemsView;
	pageNumber: number;
	orderByCol: string;
	orderDesc: boolean;
	searchActive: boolean;
	searchTerm: string;
}