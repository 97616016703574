import { Injectable } from '@angular/core';

import { LayersService } from '../_services/layers.service';


@Injectable({
    providedIn: 'root'
})
export class CreateLayerSidebarService {

    public showCountdownOptionsPopup: boolean;

    constructor(
        private layersService: LayersService
    ) { }

    public countdownExists(): boolean {
        return this.layersService.layers.some(layer => !!layer.countdownDate);
    }

    public currentDateExists(): boolean {
        return this.layersService.layers.some(layer => layer.type === 'Current Date');
    }

    public currentTimeExists(): boolean {
        return this.layersService.layers.some(layer => layer.type === 'Current Time');
    }
}
