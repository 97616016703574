import { ProductRoute } from './product-route.enum';

export class ProductTypes {
	Id?: number;
	ProductName?: string;
	WidthPx?: number;
	HeightPx?: number;
	IsCoreProduct?: boolean;
	IsOnDashboard?: boolean;
	DashIcon?: string;
	DashImage?: string;
	ProductNoun?: string;
	Route?: ProductRoute;
	DashboardOrder?: number;
	IsVideoProduct?: boolean;

	backgroundColor?: string;
	backgroundColorDark?: string;
	gradient?: string;
	textColor?: string;
	lastSuccessfulDownloadDateTime?: string;
	hasContract?: number;
	color?: string;

	constructor(Id?: number) {
		this.Id = Id;
	}
}
