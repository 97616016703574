import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AppStateService } from '../../../core/services';
import { CreateEditPlayerService, PlayerOptionsService, SaveService, ViewService } from '../_services';
import { Installers, PlayerHardwareTypes, PlayerModels } from '../../../shared/api-models/admin';

@Component({
	selector: 'cep-video-view',
	templateUrl: './video-view.component.html',
	styleUrls: ['./video-view.component.scss']
})
export class VideoViewComponent implements OnInit {
	@Output() onCancelClickNotify = new EventEmitter<any>();

	public installersList: Installers[];
	public playerHardwareTypes: PlayerHardwareTypes[];

	private searchTermChanged: Subject<string> = new Subject<string>();

	constructor(
		public appStateService: AppStateService,
		public createEditPlayerService: CreateEditPlayerService,
		public location: Location,
		public playerOptionsService: PlayerOptionsService,
		public saveService: SaveService,
		public viewService: ViewService
	) {
		this.searchTermChanged.pipe(debounceTime(400)).subscribe((val) => {
			this.playerOptionsService.onInstallerSearchKeyup(val).subscribe((installers: Installers[]) => {
				this.installersList = installers;
			});
		});
	}

	ngOnInit() {
		this.getPlayerHardwareTypes();
		this.getVideoPlayerModels();
	}

	private getPlayerHardwareTypes(): void {
		this.playerOptionsService.getPlayerHardwareTypes().subscribe((res: PlayerHardwareTypes[]) => {
			this.playerHardwareTypes = res;
		});
	}

	private getVideoPlayerModels(): void {
		this.playerOptionsService.getVideoPlayerModels().subscribe((videoPlayerModels: PlayerModels[]) => {
			this.playerOptionsService.videoPlayerModels = videoPlayerModels;
		});
	}

	onInstallerSearchKeyup(searchTerm: string): void {
		if (searchTerm.length > 0) {
			this.searchTermChanged.next(searchTerm);
		}
	}
}
