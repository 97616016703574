import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DragulaService } from 'ng2-dragula';

import { DragDropLeftRightService } from './drag-drop-left-right.service';

@Component({
    selector: 'portal-drag-drop-left-right',
    templateUrl: './drag-drop-left-right.component.html',
    styleUrls: ['./drag-drop-left-right.component.scss']
})
export class DragDropLeftRightComponent implements OnInit, OnDestroy {

    @Input() height: number;
    @Input() hideAddRemoveAllBtns: boolean;
    @Input() isAssigned: boolean;
    @Input() invertColors: boolean;
    @Input() leftHeading: string;
    @Input() rightHeading: string;
    @Input() showDuration: boolean;


    constructor(
        public dragDropLeftRightService: DragDropLeftRightService,
        private dragulaService: DragulaService) {
        this.dragulaService.destroy('item-bag');
        this.dragulaService.dropModel('item-bag')
            .subscribe(({ el, target, source, sourceModel, targetModel, item }) => {
                const targetItem = item;
                const payload = [sourceModel, targetModel, targetItem];
                this.dragDropLeftRightService.onDrop(payload);
            })

        dragulaService.createGroup('item-bag', {
            moves: (el: Element, target: Element, event): boolean => {
                return event.classList.contains('draggable');
            }
        });
    }

    ngOnInit() {}

    public addButtonDisabled(): boolean {
        if (this.dragDropLeftRightService.availableItemsView.length < 1) {
            return true;
        }
        if (this.dragDropLeftRightService.percentComplete && this.dragDropLeftRightService.percentComplete < 100) {
            return true;
        }
        if(this.dragDropLeftRightService.availableItemsSearchTerm.length > 0) {
            return true;
        }
        return false;
    }

    public removeButtonDisabled(): boolean {
        if (this.dragDropLeftRightService.assignedItemsView.length < 1) {
            return true;
        }
        if (this.dragDropLeftRightService.percentComplete && this.dragDropLeftRightService.percentComplete < 100) {
            return true;
        }
        if(this.dragDropLeftRightService.assignedItemsSearchTerm.length > 0) {
            return true;
        }
        return false;
    }

    onRemoveItemClick(item: any): void {
        this.dragDropLeftRightService.onRemoveItem(item);
    }

    ngOnDestroy() {
        this.dragulaService.destroy('item-bag');
    }
}
