import { Injectable } from '@angular/core';

import { CreateEditRequestService } from '../_services/create-edit-request.service';
import { CreateEditViewStateService } from '../_services/create-edit-view-state.service';
import { ContentRequestStateName } from 'src/app/shared/api-models/admin';

@Injectable({
	providedIn: 'root'
})
export class CloneRequestService {
	constructor(private createEditRequestService: CreateEditRequestService, private viewStateService: CreateEditViewStateService) {}

	public cloneRequest(): void {
		const request = this.createEditRequestService.request;
		delete request.Id;
		delete request.comments;
		request.RequestType === 'On-Hold Message'
			? (request.StateName = ContentRequestStateName.AWAITING_SCRIPT_EDITS)
			: (request.StateName = ContentRequestStateName.UNASSIGNED);
		request.CreateDateTime = new Date().toISOString();
		request.DueDate = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(); //generates 7 days out
		this.viewStateService.mode = 'new';
		this.updateClips();
	}

	private updateClips(): void {
		this.createEditRequestService.request.clips.forEach((clip) => {
			delete clip.Id;
			clip.CompletedContentId = null;
			clip.CompletedFilename = null;
			clip.attachments = null;
			clip.completedVideoFile = null;
			clip.IsApproved = false;
			clip.LastRevisedDateTime = new Date().toISOString();
			clip.LastUpdatedDateTime = new Date().toISOString();
		});
	}
}
