import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, concat } from 'rxjs';
import { map, last, mergeMap } from 'rxjs/operators';

import { ContentRequestEdit } from '../_models';
import { CreateEditRequestService, CreateEditViewStateService, EmailService } from '../_services';
import { environment } from '../../../../environments/environment';
import { AppStateService, EncryptService, Events, MessageService } from 'src/app/core/services';
import { ContentRequestStateName } from 'src/app/shared/api-models/admin';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

@Injectable({
	providedIn: 'root'
})
export class MasterButtonsService {
	constructor(
		private appStateService: AppStateService,
		private createEditRequestService: CreateEditRequestService,
		private emailService: EmailService,
		private encryptService: EncryptService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private router: Router,
		private viewStateService: CreateEditViewStateService
	) {}

	public formValid(): boolean {
		if (this.requiredFields()) {
			//Video-Other does not have clips
			if (this.createEditRequestService.request.RequestType !== 'Video-Other') {
				return this.clipsValid();
			}
			return true;
		}
	}

	private requiredFields(): boolean {
		//Video logos have libraries hard-coded, so library picker is disabled
		switch (this.createEditRequestService.request.RequestType) {
			case 'Video-Logo':
				return !!this.createEditRequestService.request.Title && !!this.createEditRequestService.request.clips;

			case 'Video-Other':
				return !!this.createEditRequestService.request.Title;
			default:
				return (
					!!this.createEditRequestService.request.Title &&
					!!this.createEditRequestService.request.clips &&
					!!this.createEditRequestService.request.TargetLibraryId
				);
		}
	}

	private clipsValid(): boolean {
		if (this.createEditRequestService.request.RequestType === 'On-Hold Message') {
			return this.validateHoldClips();
		} else {
			return this.validateVideoClips();
		}
	}

	//Title and script is required
	private validateHoldClips(): boolean {
		if (this.createEditRequestService.request.clips) {
			return this.createEditRequestService.request.clips.every((clip) => {
				if (clip.Title && clip.ScriptText) {
					return clip.Title.length > 0 && clip.ScriptText.length > 0;
				}
			});
		}
	}

	//Only title is required
	private validateVideoClips(): boolean {
		if (this.createEditRequestService.request.clips.length > 0) {
			return this.createEditRequestService.request.clips.every((clip) => {
				return clip.Title?.length > 0;
			});
		}
		return false;
	}

	public saveNewRequestClick(): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.bypassScriptWriterCheck();

		//Save new request
		this.httpClient
			.post(`${environment.adminUrl}ContentRequests`, this.createEditRequestService.request)
			.pipe(
				mergeMap((newRequest: ContentRequestEdit) => {
					//Create save clips observables
					const clipsPost$ = this.createEditRequestService.request.clips.map((clip) => {
						clip.ContentRequestId = newRequest.Id;
						return this.httpClient.post(`${environment.adminUrl}ContentRequestClips`, clip);
					});
					//Send new request email
					const newRequestEmail$ = this.emailService.sendEmail(this.emailService.newRequestEmail(newRequest.Id));

					const observs$ = clipsPost$.concat(newRequestEmail$);

					//Save all clips
					//https://stackoverflow.com/questions/42865820/rxjs-observable-run-sequentially-and-return-an-array
					return concat(...observs$).pipe(
						last(),
						map(() => {
							this.messageService.publish(Events.savingPreloader, 0);

							//Route to newly saved request edit page
							if (this.viewStateService.route === 'content-requests') {
								this.router.navigate([`/content-requests/${this.encryptService.encryptData(JSON.stringify(newRequest.Id))}`]);
							} else {
								this.router.navigate([`/system-tools/content-requests/${this.encryptService.encryptData(JSON.stringify(newRequest.Id))}`]);
							}
						})
					);
				})
			)
			.subscribe();
	}

	public updateRequest(): Observable<any> {
		this.messageService.publish(Events.savingPreloader, 1);
		return this.httpClient
			.put(`${environment.adminUrl}ContentRequests/${this.createEditRequestService.request.Id}`, this.createEditRequestService.request)
			.pipe(
				map(() => {
					this.messageService.publish(Events.savingPreloader, 0);
				})
			);
	}

	private bypassScriptWriterCheck(): void {
		if (this.createEditRequestService.request.RequestType === 'On-Hold Message') {
			if (this.createEditRequestService.request.bypassScriptWriter) {
				this.assignProducer('tharkin', 14, ContentRequestStateName.ASSIGNED_AWAITING_PRODUCTION);

				/* Currently (2022) only one script writer, Tiffany Wilken.
                   If not sending directly to studio, assign to her for review. */
			} else {
				this.assignProducer('tiffanyw', 88, ContentRequestStateName.AWAITING_SCRIPT_EDITS);
			}
		} else {
			//Bypass script writer if not on hold (excludes her from email list)
			this.createEditRequestService.request.bypassScriptWriter = true;
		}
	}

	private assignProducer(producerUsername: string, producerId: number, stateName: ContentRequestStateName): void {
		this.createEditRequestService.request.producerUsername = producerUsername;
		this.createEditRequestService.request.ProducerId = producerId;
		this.createEditRequestService.request.StateName = stateName;
		this.createEditRequestService.request.clientStatusView = this.createEditRequestService.clientStatus(this.createEditRequestService.request.StateName);
	}

	public showPendingEditorApprovalBtn(): boolean {
		return (
			this.viewStateService.route !== 'content-requests' &&
			this.createEditRequestService.request.StateName !== ContentRequestStateName.PENDING_EDITOR_APPROVAL &&
			(this.appStateService.currentUser.Role === UserRole.VIDEO_CONTENT_CREATOR || this.appStateService.currentUser.Role === UserRole.ADMINISTRATOR) &&
			this.viewStateService.mode === 'edit'
		);
	}
}
