<ng-container *ngIf="(libraries$ | async) as libraries">
    <div class="col-sm-4 pd-left0">
        <div class="mgn-bottom30">
            <input (input)="onSearch()" type="text" class="ht54 ft-size22 mgn-top20" placeholder="Search for a library..." [(ngModel)]="searchTerm">
            <button *ngIf="searchTerm?.length > 0" (click)="onSearchClear()" style="width: initial;" class="white-bg absolute right20 top28 pd-left12 pd-right12">Clear</button>
        </div>
        <div class="row">
            <div class="col-md-6">
                From:
                <portal-date-time-selector
                        [backgroundColor]="'gray-bg'"
                        [dateModel]="fromDate"
                        [disableUntil]="disableFromDateUntil"
                        (dateChanged)="onDateChanged($event, 'from')">
                </portal-date-time-selector>
            </div>
            <div class="col-md-6">
                To:
                <portal-date-time-selector
                        [backgroundColor]="'gray-bg'"
                        [dateModel]="toDate"
                        (dateChanged)="onDateChanged($event, 'to')">
                </portal-date-time-selector>
            </div>
        </div>
        <ng-container *ngIf="usageReportsStore.atleastOneLibrarySelected$ | async">
            <div class="mgn-top30">
                <p class="semi-bold mgn-bottom15">Show report for content files in these libraries:</p>
                <p *ngFor="let library of usageReportsStore.selectedLibraries$ | async" class="mgn-bottom6 ft-size16">{{library.LibraryName}}</p>
            </div>
            <button (click)="onGenerateReportClick()" class="orange-bg white-text mgn-top30 ft-size20">Generate report</button>
        </ng-container>
        
    </div>
    <div class="col-sm-8">
        <div *ngIf="searchTerm?.length > 0 && showResults"  class="row">
            <p class="mgn-left15">Search Results ({{libraries?.length}})</p>
            <ng-container *ngIf="libraries.length; else empty">
                <div *ngFor="let library of libraries | searchFilter:searchTerm; let i = index" class="col-sm-6">
                    <div [attr.id]="library.Id" (mouseover)="onRowHover(i)" (mouseleave)="isHovered = false;" (click)="onLibrarySelect(library)"  class="{{index === i && isHovered ? 'setOpacity' : null}} {{library.isSelected ? library.bgColor + '-darkest' : library.bgColor}} shadow pd15 ft-size18 pd10 border-radius shadow mgn-bottom15 pointer">
                        <span [ngClass]="{'semi-bold' : library.isSelected}" class="white-text"><i class="{{library.icon}} mgn-right10" aria-hidden="true"></i>{{library.LibraryName}}</span>
                    </div>
                </div>
            </ng-container>
            <ng-template #empty>
               <p class="ft-size16 mgn-left15">No libraries found.</p> 
            </ng-template>
        </div>
    </div>
</ng-container>