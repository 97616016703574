import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { Events, MessageService } from 'src/app/core/services';

import { environment } from 'src/environments/environment';
import { ClipsView, ContentFilesViewHold } from '../../../_models';
import { ContentRequestStateName } from 'src/app/shared/api-models/admin';

@Injectable({
	providedIn: 'root'
})
export class ContentFileService {
	constructor(private httpClient: HttpClient, private messageService: MessageService) {}

	public updateFileOnRevisionClick(contentFile: ContentFilesViewHold, clip: ClipsView): void {
		this.messageService.publish(Events.savingPreloader, 1);

		contentFile.ContentFileStateId = 7;
		contentFile.inRevision = true;

		let updateContentFile$ = this.httpClient.patch(`${environment.contentUrl}ContentFiles/${contentFile.Id}`, { ContentFileStateId: 7 });
		let updateRequestState$ = this.httpClient.patch(`${environment.adminUrl}ContentRequests/${clip.ContentRequestId}`, this.requestPatchProps());

		forkJoin([updateContentFile$, updateRequestState$]).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
			clip.eligibleForRevision = false;
		});
	}

	private requestPatchProps(): { StateName: ContentRequestStateName; DueDate: string } {
		return {
			StateName: ContentRequestStateName.REVISION_REQUESTED,
			DueDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString()
		};
	}

	public onDelete(contentFile: ContentFilesViewHold, clip: ClipsView): void {
		let confirmOk: boolean = confirm(`Delete content file ${contentFile.VoiceTalent.TalentName}? File will be deleted immediately and cannot be undone.`);
		if (confirmOk) {
			this.messageService.publish(Events.savingPreloader, 1);
			this.httpClient.delete(`${environment.contentUrl}ContentFiles/${contentFile.Id}`).subscribe(() => {
				this.messageService.publish(Events.savingPreloader, 0);
				let index = clip.contentFiles.findIndex((c) => c.Id === contentFile.Id);
				clip.contentFiles.splice(index, 1);
			});
		}
	}
}
