import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';

import { CreateEditPlayerService, MacValidationService, PlayerOptionsService, SaveService, ViewService } from '../_services';
import { AppStateService } from '../../../core/services';
import { EPlayerModels } from 'src/app/shared/api-models/admin/player-models.enum';

@Component({
	selector: 'cep-radio-view',
	templateUrl: './radio-view.component.html',
	styleUrls: ['./radio-view.component.scss']
})
export class RadioViewComponent implements OnInit {
	@Output() onCancelClickNotify: EventEmitter<any> = new EventEmitter();
	readonly playerModel = EPlayerModels;

	get playerIsHalo() {
		const playerModelId = this.createEditPlayerService.player.PlayerModelId;
		return playerModelId === this.playerModel.HALO_MUSIC || playerModelId === this.playerModel.LINUX_OVERHEAD_PLAY_24;
	}

	constructor(
		public appStateService: AppStateService,
		public createEditPlayerService: CreateEditPlayerService,
		public location: Location,
		public macValidationService: MacValidationService,
		public playerOptionsService: PlayerOptionsService,
		public saveService: SaveService,
		public viewService: ViewService
	) {}

	ngOnInit() {
		this.playerOptionsService.getRadioPlayerModelTypes();
		if (this.createEditPlayerService.viewState === 'edit') {
			this.macValidationService.isMacAddressUnique = true;
		}
	}

	public onCancelClick(): void {
		this.onCancelClickNotify.emit();
	}
}
