import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ClientHistoryNotes } from '../../../shared/api-models/log';
import { Column } from 'src/app/shared/components/table/view-models';

@Component({
	selector: 'hn-xs-view',
	templateUrl: './hn-xs-view.component.html',
	styleUrls: ['./hn-xs-view.component.scss']
})
export class HnXsViewComponent implements OnInit {
	@Input() historyNote: ClientHistoryNotes;
	@Input() isEven: boolean;

	@Output() buttonClickNotify: EventEmitter<[Partial<Column>, ClientHistoryNotes, string]> = new EventEmitter<[Partial<Column>, ClientHistoryNotes, string]>();

	constructor() {}

	ngOnInit() {}

	public onTableButtonClick(): void {
		const column: Pick<Column, 'key'> = { key: 'deleteBtn' };
		this.buttonClickNotify.emit([column, this.historyNote, '']);
	}
}
