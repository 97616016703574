import { FeedItem } from './feed-item';

export class Feed {
    FeedSchema: string;
    Id: number;
    IsGlobal: boolean;
    IsTicker: boolean;
    Items: FeedItem[];
    Name: string;
    FeedSourceId: number;
}