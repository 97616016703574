import { Pipe, PipeTransform } from '@angular/core';

import { CategoriesTree } from '../_models';

@Pipe({
    name: 'categoriesFilterPipe',
    pure: false
})

export class CategoriesFilterPipe implements PipeTransform {

    constructor(

    ) { }


    transform(categories: CategoriesTree[]): any {
        if(categories) {
            return categories.filter(category => !category.ParentCategoryId);
        }
    }
}
