import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlayerModels } from '../../api-models/admin';
import { UtilitiesService } from 'src/app/core/services';

interface Item {
	id?: number;
	bgColor: string;
	draggableClass: string;
	name: string;
	deleteDisabled: boolean;
	icon: string;
	WriteAccess: boolean;
	PlayerModel: PlayerModels;
}

@Component({
	selector: 'ddlr-item',
	templateUrl: './ddlr-item.component.html',
	styleUrls: ['./ddlr-item.component.scss']
})
export class DdlrItemComponent<T extends Item> implements OnInit {
	@Input() includeCheckbox: boolean;
	@Input() item: T;
	@Input() isAssigned: boolean;
	@Input() showDuration: boolean;
	@Input() dragDisabled: boolean;

	@Output() checkboxClickNotify: EventEmitter<T> = new EventEmitter<T>();
	@Output() removeItemClickNotify: EventEmitter<T> = new EventEmitter<T>();

	get draggable() {
		return this.dragDisabled ? '' : this.item.draggableClass;
	}

	constructor(public utilService: UtilitiesService) {}

	ngOnInit() {}

	public setBackgroundColor(): string {
		if (this.isAssigned || this.item.draggableClass === 'draggable') {
			return this.item.bgColor;
		}
		return 'gray-bg-darkest';
	}

	public onCheckboxClick(): void {
		this.item.WriteAccess = !this.item.WriteAccess;
		this.checkboxClickNotify.emit(this.item);
	}
}
