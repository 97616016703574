<div class="row">
	<div class="col-sm-6">
		<h2 class="semi-bold in-block">
			<i class="fas fa-ticket-alt mgn-right10"></i>
			Tickets |
			<em class="regular">{{ viewStateService.includeClosed ? 'All' : 'Open only' }}</em>
		</h2>
	</div>
	<div class="col-sm-2 col-sm-offset-4">
		<button (click)="onIncludeClosedClick()" class="{{ viewStateService.includeClosed ? 'limeGreen-bg white-text' : 'gray-bg' }}">Include closed</button>
	</div>
</div>

<div class="row mgn-top15">
	<div class="col-xs-12">
		<portal-table
			*ngIf="tickets?.length > 0; else empty"
			class="hidden-xs"
			[table]="viewStateService.table"
			[totalItems]="viewStateService.mainTableTotalItems"
			[config]="tableConfigService.config"
			[data]="tickets"
			[showJumpToPage]="true"
			[page]="viewStateService.pageNumber"
			(rowSelectNotify)="onRowSelect($event)"
			(currentPageNotify)="onPageClick($event)"
		></portal-table>
		<ng-template #empty><em class="ft-size16">Nothing to see here. Nice!</em></ng-template>
	</div>
</div>
