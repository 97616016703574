import { Injectable } from '@angular/core';

import { CountdownLayerView, DisplayOptions, LayerType } from '../../_models';
import { CreateLayerSidebarService } from '../create-layer-sidebar.service';
import { DateTimeService } from 'src/app/core/services/date-time.service';
import { LayersService } from '../../_services/layers.service';
import { Layer } from '../../_models';
import { Create24Service } from '../../../create-24.service';
import { CountdownInterval } from '../../_models/countdown-interval.enum';

@Injectable({
	providedIn: 'root'
})
export class CountdownAndDateService {
	public countdownDate: string;
	public countdownInterval: CountdownInterval;
	public displayOption: DisplayOptions;
	public mode: 'new' | 'edit' = 'new';
	public view: 'countdown' | 'todaysDate' | 'currentTime';

	constructor(
		private create24Service: Create24Service,
		private createLayerSidebarService: CreateLayerSidebarService,
		private dateTimeService: DateTimeService,
		private layersService: LayersService
	) {}

	public countdownText(layer: Layer): string {
		const { dateDiffHrMinSec } = this.dateTimeService;
		switch (this.countdownInterval) {
			case CountdownInterval.DAYS:
				switch (this.displayOption) {
					case 'OneLayer':
						//Show number and "Days" in one layer
						return `${this.dateDiffNum()} ${this.dateDiffNum() === 1 ? 'Day' : 'Days'}`;

					case 'TwoLayers':
						switch (layer.countdownLayerView) {
							case 'countdownNumber':
								//Show number only
								return `${this.dateDiffNum()}`;

							case 'countdownText':
								//Show "Days" only
								return `${this.dateDiffNum() === 1 ? 'Day' : 'Days'}`;
						}
				}
			case CountdownInterval.TIME:
				switch (this.displayOption) {
					//Show hours, minutes, seconds on one layer
					case 'OneLayer':
						return dateDiffHrMinSec(this.dateNoZ());

					case 'ThreeLayers':
						switch (layer.countdownLayerView) {
							//Show hours only
							case 'countdownHours':
								return `${dateDiffHrMinSec(this.dateNoZ(), 'hoursOnly')} Hours`;
							//Show minutes only
							case 'countdownMinutes':
								return `${dateDiffHrMinSec(this.dateNoZ(), 'minutesOnly')} Minutes`;
							//Show seconds only
							case 'countdownSeconds':
								return `${dateDiffHrMinSec(this.dateNoZ(), 'secondsOnly')} Seconds`;
						}
						break;
				}
		}
	}

	private createLayer(values: { layerName: string; layerType: LayerType; countdownLayerView: CountdownLayerView }, deletedLayer?: Layer): void {
		const layer = new Layer(
			this.layersService.firstLayerId() ? this.layersService.firstLayerId() + 1 : 1,
			null,
			'far fa-calendar-alt',
			'blueLight',
			values.layerType,
			this.create24Service.contentType()
		);
		layer.countdownInterval = this.countdownInterval;
		layer.countdownDate = this.countdownDate;
		layer.countdownLayerView = values.countdownLayerView;
		layer.countdownDisplayOption = this.displayOption;

		if (!!deletedLayer) {
			layer.canvasObj = deletedLayer.canvasObj;
			layer.timelineObj = deletedLayer.timelineObj;
			layer.start = deletedLayer.start;
			layer.transitionIn = deletedLayer.transitionIn;
			layer.transitionOut = deletedLayer.transitionOut;
		}

		layer.name = values.layerName;

		switch (this.view) {
			case 'countdown':
				layer.canvasObj.text = this.countdownText(layer);
				break;

			case 'todaysDate':
				layer.canvasObj.text = this.dateTimeService.friendlyFormat(new Date().toString(), layer.countdownLayerView);
				break;
			case 'currentTime':
				layer.canvasObj.text = this.dateTimeService.friendlyFormat(new Date().toString(), layer.countdownLayerView);
				break;
		}

		this.layersService.onCreateLayerClick(layer);
	}

	public createLayers(deletedLayersCopy?: Layer[]): void {
		type LayerProps = { layerName: string; layerType: LayerType; countdownLayerView: CountdownLayerView };
		let arr: LayerProps[] = [];

		switch (this.view) {
			case 'countdown':
				switch (this.displayOption) {
					case 'OneLayer':
						this.createLayer(
							{ layerName: 'Countdown', layerType: 'Countdown', countdownLayerView: 'countdownDisplay' },
							deletedLayersCopy && deletedLayersCopy[0]
						);
						break;

					case 'TwoLayers':
						arr = [
							{ layerName: 'Countdown number', layerType: 'Countdown', countdownLayerView: 'countdownNumber' },
							{ layerName: `Countdown "Days"`, layerType: 'Countdown', countdownLayerView: 'countdownText' }
						];

						//Create one layer for each array item
						arr.forEach((val, i) => {
							this.createLayer(val, deletedLayersCopy && deletedLayersCopy[i]);
						});
						break;
					case 'ThreeLayers':
						arr = [
							{ layerName: 'Countdown hours', layerType: 'Countdown', countdownLayerView: 'countdownHours' },
							{ layerName: 'Countdown minutes', layerType: 'Countdown', countdownLayerView: 'countdownMinutes' },
							{ layerName: 'Countdown seconds', layerType: 'Countdown', countdownLayerView: 'countdownSeconds' }
						];

						//Create one layer for each array item
						arr.forEach((val, i) => {
							this.createLayer(val, deletedLayersCopy && deletedLayersCopy[i]);
						});
						break;
				}
				break;
			case 'todaysDate':
				switch (this.displayOption) {
					case 'short':
						arr = [{ layerName: 'Short date', layerType: 'Current Date', countdownLayerView: 'short' }];

						arr.forEach((val, i) => {
							this.createLayer(val, deletedLayersCopy && deletedLayersCopy[i]);
						});
						break;
					case 'dayMonthDate':
						arr = [{ layerName: 'Day|Month|Date', layerType: 'Current Date', countdownLayerView: 'dayMonthDate' }];

						arr.forEach((val, i) => {
							this.createLayer(val, deletedLayersCopy && deletedLayersCopy[i]);
						});
						break;

					case 'dayMonthDateYear':
						arr = [{ layerName: 'Full date', layerType: 'Current Date', countdownLayerView: 'dayMonthDateYear' }];

						arr.forEach((val, i) => {
							this.createLayer(val, deletedLayersCopy && deletedLayersCopy[i]);
						});
						break;
					case 'TwoLayers':
						var layer1: LayerProps = { layerName: 'Day', layerType: 'Current Date', countdownLayerView: 'todaysDateWeekday' };
						var layer2: LayerProps = { layerName: 'Month and date', layerType: 'Current Date', countdownLayerView: 'todaysDateMonthDay' };
						arr = [layer1, layer2];

						arr.forEach((val, i) => {
							this.createLayer(val, deletedLayersCopy && deletedLayersCopy[i]);
						});
						break;
					case 'FourLayers':
						var layer1: LayerProps = { layerName: 'Day', layerType: 'Current Date', countdownLayerView: 'todaysDateWeekday' };
						var layer2: LayerProps = { layerName: 'Month', layerType: 'Current Date', countdownLayerView: 'todaysDateMonth' };
						var layer3: LayerProps = { layerName: 'Date', layerType: 'Current Date', countdownLayerView: 'todaysDateDay' };
						var layer4: LayerProps = { layerName: 'Year', layerType: 'Current Date', countdownLayerView: 'todaysDateYear' };
						arr = [layer1, layer2, layer3, layer4];
						arr.forEach((val, i) => {
							this.createLayer(val, deletedLayersCopy && deletedLayersCopy[i]);
						});
						break;
				}
				break;
			case 'currentTime':
				switch (this.displayOption) {
					case 'OneLayer':
						this.createLayer(
							{ layerName: 'Current time', layerType: 'Current Time', countdownLayerView: 'hoursMinutesAmPm' },
							deletedLayersCopy && deletedLayersCopy[0]
						);
						break;

					case 'SansPeriod':
						this.createLayer(
							{ layerName: 'Hours|Minutes', layerType: 'Current Time', countdownLayerView: 'currentTime' },
							deletedLayersCopy && deletedLayersCopy[0]
						);
						break;
					case 'TwoLayers':
						var layer1: LayerProps = { layerName: 'Time', layerType: 'Current Time', countdownLayerView: 'currentTime' };
						var layer2: LayerProps = { layerName: 'am/pm', layerType: 'Current Time', countdownLayerView: 'currentTimePeriod' };
						arr = [layer1, layer2];

						arr.forEach((val, i) => {
							this.createLayer(val, deletedLayersCopy && deletedLayersCopy[i]);
						});
						break;
				}
				break;
		}
	}

	public deleteLayers(layers: Layer[]): void {
		//Delete each layer
		layers.forEach((layer) => {
			this.layersService.layers = this.layersService.layers.filter((l) => l.id !== layer.id);
			this.layersService.onLayerDelete(layer.id);
			this.layersService.adjustLayerIdsOnDelete(layer);
		});
	}

	public dateDiffNum(): number {
		return this.dateTimeService.dateDifferenceInDays(new Date(), new Date(this.dateNoZ()));
	}

	public dateNoZ(): string {
		return this.countdownDate.slice(0, -1);
	}

	//Limit layer count to 30
	public disabled(): boolean {
		return this.layersService.layers.length === 30;
	}

	public viewInit(): 'countdown' | 'todaysDate' | 'currentTime' {
		switch (true) {
			case this.createLayerSidebarService.countdownExists() && !this.createLayerSidebarService.currentDateExists():
				return 'todaysDate';

			case this.createLayerSidebarService.countdownExists() &&
				this.createLayerSidebarService.currentDateExists() &&
				!this.createLayerSidebarService.currentTimeExists():
				return 'currentTime';

			default:
				return 'countdown';
		}
	}

	public viewHeadline(): string {
		switch (this.view) {
			case 'countdown':
				if (this.mode === 'new') {
					return `Create a Countdown`;
				}
				if (this.mode === 'edit') {
					return `Edit Countdown Date`;
				}
			case 'todaysDate':
				return `Show Today's Date`;
			case 'currentTime':
				return 'Show Current Time';
		}
	}

	public dateOptions(displayOption: DisplayOptions): string {
		switch (displayOption) {
			case 'dayMonthDateYear':
			case 'FourLayers':
				return `{"weekday": "long", "month": "long", "day": "numeric", "year": "numeric"}`;

			case 'short':
				return `{  "day": "2-digit", "month": "2-digit", "year": "numeric"}`;
			default:
				return `{"weekday": "long", "month": "long", "day": "numeric"}`;
		}
	}
}
