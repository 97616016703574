export class FileUploadViewState {
    uploadFileHoldSequence: SequenceStep[] = [
        {
            name: 'validate',
            isCurrentStep: true
        },
        {
            name: 'uploadModal',
            title: true,
            script: true,
            selectLibrary: true,
            selectVoiceTalent: true
        },
        {
            name: 'progressBar',
            successMessage: 'Your audio file has been queued for processing on our content server where it will be encoded for playback. It should appear in the “New Content” category of the library you selected in the next 30 minutes.'
        }
    ];

    uploadFileVideoSequence: SequenceStep[] = [
        {
            name: 'validate',
            isCurrentStep: true
        },
        {
            name: 'uploadModal',
            title: true,
            script: true,
            selectLibrary: true,
            chooseResizeMethod: true
        },
        {
            name: 'progressBar',
            successMessage: 'Your video has been queued for processing on our content server where it will be resized and encoded for playback on your video player. It should appear in the “New Content” category of the library you selected in the next 30 minutes.'
        }
    ];

    uploadCounterpartSequence: SequenceStep[] = [
        {
            name: 'validate',
            isCurrentStep: true
        },
        {
            name: 'uploadModal',
            contentBreadCrumbs: true,
            selectVoiceTalent: true
        },
        {
            name: 'progressBar',
            successMessage: 'Your audio file has been queued for processing on our content server where it will be encoded for playback. It should appear as an additional version in this category in the next 30 minutes.'
        }
    ];

    replaceHoldSequence: SequenceStep[] = [
        {
            name: 'validate',
            isCurrentStep: true
        },
        {
            name: 'progressBar',
            successMessage: 'Your audio file has been queued for processing on our content server where it will be encoded for playback. The updated file should be available for preview shortly.'
        }
    ];

    replaceVideoSequence: SequenceStep[] = [
        {
            name: 'validate',
            isCurrentStep: true
        },
        {
            name: 'progressBar',
            successMessage: 'Your video has been queued for processing on our content server where it will be resized and encoded for playback on your video player. The updated file should be available for preview shortly.'
        }
    ];

    //Completed files
    contentRequestCompletedFileSequenceHold: SequenceStep[] = [
        {
            name: 'validate',
            isCurrentStep: true
        },
        {
            name: 'uploadModal',
            selectVoiceTalent: true
        },
        {
            name: 'progressContentRequestCompletedFile'
        }
    ];

    
    contentRequestCompletedFileSequenceVideo: SequenceStep[] = [
        {
            name: 'validate',
            isCurrentStep: true
        },
        {
            name: 'uploadModal'
        },
        {
            name: 'progressContentRequestCompletedFile' 
        }
    ];

    contentRequestAttachmentSequence: SequenceStep[] = [
        {
            name: 'validate',
            isCurrentStep: true
        },
        {
            name: 'progressContentRequestAttachment' 
        }
    ];

    contentRequestClipAttachmentSequence: SequenceStep[] = [
        {
            name: 'validate',
            isCurrentStep: true
        },
        {
            name: 'progressContentRequestClipAttachment' 
        }
    ];

    ticketSystemAttachmentSequence: SequenceStep[] = [
        {
            name: 'validate',
            isCurrentStep: true
        },
        {
            name: 'progressTicketSystemAttachment' 
        }
    ];

    ticketSystemInvoiceSequence: SequenceStep[] = [
        {
            name: 'validate',
            isCurrentStep: true
        },
        {
            name: 'progressTicketSystemInvoice' 
        }
    ];

    installerAttachmentSequence: SequenceStep[] = [
        {
            name: 'validate',
            isCurrentStep: true
        },
        {
            name: 'progressTicketSystemInvoice' 
        }
    ];

    feedItemSequence: SequenceStep[] = [
        {
            name: 'validate',
            isCurrentStep: true
        },
        {
            name: 'showQueuedFile'
        }
    ];

    reminderEmailsImage: SequenceStep[] = [
        {
            name: 'validate',
            isCurrentStep: true
        },
        {
            name: 'reminderEmailsUploadComplete',
            successMessage: 'Your image has been successfully uploaded!'
        }
    ];

    activeSequence: SequenceStep[];
}

export class SequenceStep {
    name: string;
    title?: boolean;
    script?: boolean;
    selectLibrary?: boolean;
    selectVoiceTalent?: boolean;
    chooseResizeMethod?: boolean;
    successMessage?: string;
    isCurrentStep?: boolean;
    contentBreadCrumbs?: boolean;
}