<portal-header></portal-header>
<div class="row">
	<div class="col-sm-12 text-center gray-text-darker mgn-top20">
		<i class="fa fa-frown-o ft-size100" aria-hidden="true"></i>
		<h1 class="ft-size100 mgn-bottom30">We're sorry!</h1>
		<h2 class="ft-size60 mgn-bottom60">Access Denied</h2>
		<h3 class="ft-size26 mgn-top20 mgn-bottom20">Perhaps we can cheer you up with an inspirational quote:</h3>
		<h3 class="ft-size20"><em>"{{randomQuote.quote}}"</em></h3>
		<h3 class="ft-size20"><em>- {{randomQuote.author}}</em></h3>
		<button style="width: initial;" class="green-bg border-radius white-text pd-left10 pd-right10 mgn-top40 ft-size18" [routerLink]="['/my-products']"><i class="fa fas fa-chevron-left mgn-right10" aria-hidden="true"></i>Return to My Products</button>
	</div>
</div>