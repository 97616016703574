export class Button {	
	name: string;
	routerUrl: string;
	backgroundColor: string;
	
	constructor(
		name: string, 
		routerUrl: string) {
		this.name = name;
		this.routerUrl = routerUrl;
	}	
}