import { Component, Input, OnInit } from '@angular/core';

import { AppStateService, DateTimeService, SecurityService, UtilitiesService } from '../../../../../core/services';
import { ContentService, ContentViewService, DetailsService } from '../_services';
import { ContentVoiceTalents } from 'src/app/shared/api-models/content';
import { Events, FileUploadService, MessageService } from '../../../../../core/services';
import { FileUploadStateService } from '../../../../services';
import { FeatureArea } from 'src/app/shared/view-models';
import { ProductRoute } from 'src/app/shared/api-models/admin/product-route.enum';
import { ContentVM } from '../_models/content-view';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

@Component({
	selector: 'c-details',
	templateUrl: './details.component.html',
	styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
	@Input() content: ContentVM;

	public isGlobalSet: boolean;
	readonly featureArea = FeatureArea;
	readonly productRoute = ProductRoute;

	public get showSortCategories() {
		return (
			(this.securityService.userIsNatalieOrKelsey() || this.appStateService.currentUser.Role === UserRole.ADMINISTRATOR) &&
			this.appStateService.activeFeatureArea === FeatureArea.CONTENT_MANAGER
		);
	}

	constructor(
		public appStateService: AppStateService,
		private contentService: ContentService,
		public contentViewService: ContentViewService,
		public dateTimeService: DateTimeService,
		public detailsService: DetailsService,
		public fileUploadService: FileUploadService,
		public fileUploadStateService: FileUploadStateService,
		private messageService: MessageService,
		public securityService: SecurityService,
		public utilService: UtilitiesService
	) {}

	ngOnInit() {
		this.getBreadCrumbs();
		this.writeAccessCheck();
		if (this.appStateService.product.Route === 'hold') {
			this.getVoiceTalentList();
		}
	}

	public onMarkAsFeaturedClick(): void {
		this.content.IsFeatured = !this.content.IsFeatured;
	}

	onFeatureDateChanged(date: string, prop: string): void {
		this.content[prop] = date;
	}

	onFeatureCodeDateChanged(date: string, prop: string): void {
		this.content[prop] = date;
	}

	onSortCategorySelect(featureCode: { Id: number; Name: string }): void {
		this.content.FeatureCodeId = featureCode.Id;
	}

	public featureCodeName(): string {
		switch (this.content.FeatureCodeId) {
			case 5:
				return 'NEW';
			case 10:
				return 'Featured';
			case 15:
				return 'Popular/Hot';
			default:
				return 'Select a sort category';
		}
	}

	public onReplaceInputClick(): void {
		this.fileUploadStateService.replacementProps = { contentFileId: this.content.contentFiles[0].Id, targetLibraryId: this.content.LibraryId };
		this.fileUploadService.onReplaceInputClick();
	}

	public onSaveClick(): void {
		this.content.detailsVisible = false;
		this.content.contentFiles.forEach((contentFile) => (contentFile.isDirty = false));
		this.detailsService.updateContentAndContentFiles(this.content).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}

	private getBreadCrumbs(): void {
		this.contentService.getContentBreadcrumbs(this.content.Id).subscribe((breadcrumbs: string[]) => {
			this.content.breadcrumbs = breadcrumbs;
		});
	}

	private getVoiceTalentList(): void {
		this.detailsService.getVoiceTalentList().subscribe((voiceTalentList: ContentVoiceTalents[]) => {
			this.detailsService.voiceTalentList = voiceTalentList;
		});
	}

	//Check manually here instead of getting from library.IsGlobal
	//to cover search queries as well. Content.isGlobal is checked
	//in security service.
	private writeAccessCheck(): void {
		this.detailsService.writeAccessCheck(this.content).subscribe((writeAccess: boolean) => {
			this.content.writeAccess = writeAccess;
		});
	}
}
