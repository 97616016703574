import { Injectable } from '@angular/core';
import { Subscription, Subject } from 'rxjs';

import { CanvasService } from './canvas.service';
import { LayersService } from '../../_services/layers.service';
import { UndoRedoService } from '../../_services/undo-redo.service';

@Injectable({
	providedIn: 'root'
})
export class CEventsService {
	private windowResizeSource = new Subject<number>();
	private imageScalingSource = new Subject<any>();
	private objectRotatingSource = new Subject<any>();

	public imageScaling$ = this.imageScalingSource.asObservable();
	public objectRotating$ = this.objectRotatingSource.asObservable();
	public windowResize$ = this.windowResizeSource.asObservable();

	public isGroupSelected: boolean;
	private isObjectMoving: boolean;
	private isObjectRotating: boolean;
	private isObjectScaling: boolean;
	private lastTextboxHeight: number;
	public textChanged: Subject<string> = new Subject<string>();
	public feedObjChanged: Subject<any> = new Subject<any>();
	public subs: Subscription[] = [];

	constructor(private canvasService: CanvasService, private layersService: LayersService, private undoRedoService: UndoRedoService) {}

	public enableObjectSnapping(): void {
		this.canvasService.canvas.on('object:rotating', (options) => {
			let obj = options.target;
			let layer = this.layersService.layers.find((layer) => layer.id === obj.layerId);
			options.target.snapAngle = 0;
			//Easily snap angles with shift key
			if (options.e.shiftKey) {
				options.target.snapAngle = 10;
			} else {
				options.target.snapAngle = 0;
			}
			layer.canvasObj.angle = obj.angle;

			//Remove sliding transition if obj rotated
			if (layer.canvasObj.angle !== 0 && this.transitionIsSlide(layer.transitionIn)) {
				layer.transitionIn = 'Not set';
			}
			if (layer.canvasObj.angle !== 0 && this.transitionIsSlide(layer.transitionOut)) {
				layer.transitionOut = 'Not set';
			}
			if (obj.isFeedImageBoundingBox) {
				let imageObj = this.canvasService.canvas.getObjects().find((obj) => obj.layerId === this.layersService.activeLayer.id && obj.name === 'image');
				imageObj.rotate(obj.angle);
			}

			this.isObjectRotating = true;
			let feedTextImgObj = this.canvasService.canvas.getObjects().find((object) => object.layerId === obj.layerId && object.isFeedTextImage);
			if (feedTextImgObj) {
				feedTextImgObj.set('angle', obj.angle);
			}

			this.objectRotatingSource.next();
		});
	}

	private transitionIsSlide(transition): boolean {
		return transition !== 'Not set' && transition !== 'Fade in' && transition !== 'Fade out';
	}

	public onObjectMoving(): void {
		this.canvasService.canvas.on('object:moving', (e) => {
			let activeLayerType = this.layersService.activeLayer?.type;
			let isCountdownType = ['Countdown', 'Current Date', 'Current Time'].some((type) => type === activeLayerType);

			//If multiple layers selected
			if (e.target._objects) {
				e.target._objects.forEach((obj) => {
					this.setLayerProps(obj, e.target);
					obj.setCoords();
				});
				this.canvasService.canvas.renderAll();
				return;
			}
			let obj = e.target;

			//If layer is not background
			if (obj.layerId !== 0 && !obj.isCropObj) {
				this.setLayerProps(obj);
				this.isObjectMoving = true;
				if (!isCountdownType) {
					this.restrictToBoundary(obj);
				}
			} else if (obj.isCropObj) {
				this.canvasService.cropObj = obj;
			}

			if (obj.isFeedImageBoundingBox) {
				this.canvasService.matchFeedImageToContainer(obj);
			}
			this.canvasService.canvas.renderAll();
		});
	}

	private restrictToBoundary(obj): void {
		let topLeftArr: string[] = ['top', 'left'];

		//Set top and left boundaries
		topLeftArr.forEach((calc) => {
			if (obj[calc] < 0) {
				if (obj.type === 'textbox') {
					obj[calc] = 0;
				} else if (obj.type === 'line') {
					obj[calc] = 0;
				} else {
					obj[calc] = 0;
				}
			}
		});

		//Set right boundary
		if (obj.left + obj.width * obj.scaleX > obj.canvas.width) {
			if (obj.type === 'textbox') {
				obj.left = obj.canvas.width - obj.width;
			} else {
				obj.left = obj.canvas.width - obj.width * obj.scaleX;
			}
		}

		//Set bottom boundary
		if (obj.top + obj.height * obj.scaleY > obj.canvas.height) {
			if (obj.type === 'line') {
				obj.top = obj.canvas.height - (obj.height + 5);
			} else {
				obj.top = obj.canvas.height - obj.height * obj.scaleY;
			}
		}
	}

	private setLayerProps(obj, group?): void {
		let layer = this.layersService.layers.find((layer) => layer.id === obj.layerId);
		layer.canvasObj.top = group ? group.top + obj.top + group.height / 2 : obj.top;
		layer.canvasObj.left = group ? group.left + obj.left + group.getScaledWidth() / 2 : obj.left;
	}

	public onObjectChangeEnd(): void {
		this.canvasService.canvas.on('mouse:up', (event) => {
			let obj = event.target;

			switch (true) {
				case this.isObjectMoving:
					this.isObjectMoving = false;
					if (!obj.isCropObj) {
						this.undoRedoService.recordState('Move canvas object');
					}
					break;

				case this.isObjectRotating:
					this.isObjectRotating = false;
					this.undoRedoService.recordState('Rotate canvas object');
					break;

				case this.isObjectScaling:
					this.isObjectScaling = false;
					if (!obj.isCropObj) {
						this.undoRedoService.recordState('Scale canvas object');
					}
					break;
			}
		});
	}

	public onSelectionCreated(): void {
		this.canvasService.canvas.on('selection:created', (e) => {
			if (e.selected?.length > 1) {
				this.isGroupSelected = true;
			}
		});
	}

	public onSelectionUpdated(): void {
		this.canvasService.canvas.on('selection:updated', (e) => {
			if (e.target?._objects?.length > 1) {
				this.isGroupSelected = true;
			}
		});
	}

	public onSelectionCleared(): void {
		this.canvasService.canvas.on('selection:cleared', (e) => {
			this.isGroupSelected = false;
		});
	}

	public onObjectScale(): void {
		this.canvasService.canvas.on('object:scaling', (e) => {
			let obj = e.target;
			let activeLayer = this.layersService.activeLayer;

			if (activeLayer) {
				activeLayer.canvasObj.scaleX = obj.scaleX;
				activeLayer.canvasObj.scaleY = obj.scaleY;
				activeLayer.canvasObj.left = obj.left;
				activeLayer.canvasObj.top = obj.top;

				//Keep text same size as bounding box width or height changes
				if (this.anyTextLayer()) {
					this.updateTextSize(obj);
				}
				if (this.layersService.activeLayer.name === 'Feed Text') {
					this.feedObjChanged.next(obj);
				}
				if (activeLayer.name === 'Feed Image') {
					activeLayer.canvasObj.height = obj.height * obj.scaleY;
					activeLayer.canvasObj.width = obj.width * obj.scaleX;
				}

				if (obj.isFeedImageBoundingBox) {
					this.canvasService.matchFeedImageToContainer(obj);
				}
			}

			this.isObjectScaling = true;
			if (obj.isCropObj) {
				this.canvasService.cropObj = obj;
			}

			//Tells layer detail panel that we're scaling, so it can re-calculate height/width
			if (obj.name === 'image') {
				this.imageScalingSource.next();
			}

			//Keep track of updated obj in layer.canvasObj so we can duplicate
			this.layersService.updateActiveLayer('canvasObj', obj);
			this.canvasService.canvas.renderAll();
		});
	}

	private anyTextLayer(): boolean {
		return (
			this.layersService.activeLayer.name === 'Text' ||
			this.layersService.activeLayer.name === 'Feed Text' ||
			this.layersService.activeLayer.name === 'Countdown' ||
			this.layersService.activeLayer.name === 'Current Date'
		);
	}

	public onTextChanged(): void {
		this.canvasService.canvas.on('text:changed', (opt) => {
			let obj = opt.target;
			if (obj.width > obj.fixedWidth) {
				obj.width = obj.fixedWidth;
			}
			let layer = this.layersService.layers.find((layer) => layer.id === obj.layerId);
			layer.canvasObj.text = obj.text;
			//If not set here, when duplicating a multi-line text box, the duplicate
			//will still think it's a single-line box and not select correctly
			if (layer) {
				layer.canvasObj.height = obj.height;
			}
			//Allows text styles to change after changing text
			this.canvasService.canvas.getActiveObject().styles = [];
			this.canvasService.canvas.renderAll();
			this.textChanged.next();
		});
	}

	private updateTextSize(textbox): void {
		const controlPoint = textbox.__corner;
		//mr and ml are the only controlPoints that dont modify textbox height
		if (controlPoint && controlPoint !== 'mr' && controlPoint !== 'ml') {
			this.lastTextboxHeight = textbox.height * textbox.scaleY;
		}
		textbox.set({
			height: this.lastTextboxHeight || textbox.height,
			scaleY: 1
		});
	}

	public onWindowResize(): void {
		this.windowResizeSource.next(this.canvasService.canvasContainerWidth);
	}
}
