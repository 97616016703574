import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppStateService, FileUploadService, RotateImageService, UtilitiesService } from '../../../core/services';
import { ContentLibraries } from '../../api-models/content';
import { LibrariesTree } from '../library-tree/_models';
import { FileUploadStateService } from '../../services';
import { FileUploadModalService } from './file-upload-modal.service';

@Component({
	selector: 'portal-file-upload-modal',
	templateUrl: './file-upload-modal.component.html',
	styleUrls: ['./file-upload-modal.component.scss']
})
export class FileUploadModalComponent implements OnInit, OnDestroy {
	@Input() selectedLibrary: Partial<LibrariesTree>;
	@Input() scriptText: string;
	@Output() cancelClickNotify: EventEmitter<any> = new EventEmitter<any>();

	public libraries: any[];
	public percentComplete: number = 0;
	public showTitleError: boolean;
	public uploadComplete: boolean;

	public error = {
		titleErrorVisible: false,
		libraryErrorVisible: false,
		voiceTalentErrorVisible: false,
		titleErrorText: '',
		libraryErrorText: ''
	};
	private subscriptions: Subscription[] = [];

	constructor(
		public appStateService: AppStateService,
		public fileUploadService: FileUploadService,
		public fileUploadStateService: FileUploadStateService,
		public rotateImageService: RotateImageService,
		public fileUploadModalService: FileUploadModalService,
		public utilService: UtilitiesService
	) {}

	ngOnInit() {
		this.fileUploadModalService.modalInit();
		this.setVoiceTalentList();
		this.getLibraries();
		this.checkFileType();
	}

	private checkFileType(): void {
		const file = this.fileUploadStateService.file;
		if (this.utilService.hasExtension(file.name, this.fileUploadService.supportedStillExtensions()) && !this.utilService.hasExtension(file.name, ['.pdf'])) {
			this.rotateImageService.getBase64(file);
			this.fileUploadModalService.isStillImage = true;
		} else {
			this.fileUploadModalService.isStillImage = false;
		}
	}

	private getLibraries(): void {
		this.fileUploadModalService.getLibraries$().subscribe((libraries: ContentLibraries[]) => {
			this.libraries = libraries;
			this.setTargetLibraryId();
		});
	}

	onLibrarySelect(selectedLibrary: ContentLibraries): void {
		this.fileUploadStateService.uploadData.TargetLibraryId = selectedLibrary.Id;
		this.error.libraryErrorVisible = false;
	}

	private setVoiceTalentList(): void {
		if (this.appStateService.currentUser.IsEmployee) {
			this.fileUploadModalService.getVoiceTalentList();
		} else {
			this.fileUploadModalService.voiceTalentList = [
				{ Id: 1, TalentGender: 'Male', TalentName: 'Generic Male', name: 'Generic Male' },
				{ Id: 2, TalentGender: 'Female', TalentName: 'Generic Female', name: 'Generic Female' }
			];
		}
	}

	private setTargetLibraryId(): void {
		//Did user already navigate to a library in the library tree? And is the selected lib in the list of filtered libraries?
		if (this.selectedLibrary && this.libraries.some((lib) => lib.Id === this.selectedLibrary.Id)) {
			this.fileUploadStateService.uploadData.TargetLibraryId = this.selectedLibrary.Id;

			//Is there only one library available?
		} else if (this.libraries.length === 1) {
			this.selectedLibrary = this.libraries[0];
			this.fileUploadStateService.uploadData.TargetLibraryId = this.selectedLibrary.Id;
		} else {
			this.selectedLibrary = new LibrariesTree();
			this.selectedLibrary.LibraryName = 'Select a library...';
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}
}
