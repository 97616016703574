import { Component, OnInit } from '@angular/core';

import { AppStateService, CacheService, UtilitiesService } from 'src/app/core/services';
import { BatchChangeCategoryService } from '../_services';
import { BatchDeleteService } from '../../../../shared/services/batch-delete.service';
import { CategoriesService } from '../../library-tree/_services';
import { ChooseContentService } from 'src/app/playlist-builder/wizard-steps/choose-content/choose-content.service';
import { ContentViewStateService } from '../../../../shared/services';
import { Create24Service } from 'src/app/user-tools/create-24/create-24.service';
import { FileUploadModalService } from '../../file-upload-modal/file-upload-modal.service';
import { ContentService } from '../../content-container/content/_services';
import { IGetContentParams } from 'src/app/shared/api-models/content';
import { FeatureArea } from 'src/app/shared/view-models';
import { Create24Area } from 'src/app/user-tools/create-24/_models/create-24-state.enum';
import { LibrariesService } from '../../library-tree/_services';
import { ContentVM } from '../../content-container/content/_models/content-view';

@Component({
	selector: 'cb-content-list-header',
	templateUrl: './content-list-header.component.html',
	styleUrls: ['./content-list-header.component.scss'],
	providers: [BatchDeleteService]
})
export class ContentListHeaderComponent implements OnInit {
	public contentDeletable: boolean;
	public instructions: string;
	public orderByCol: 'Title' | 'CreateDate' = 'Title';
	public titleDesc: boolean = true;
	public dateDesc: boolean = true;

	get selectedVoiceTalent() {
		const voiceTalentId = this.cvStateService.viewState.voiceTalentId;
		if (voiceTalentId && voiceTalentId !== 0) {
			return this.fileUploadModalService.voiceTalentList.find((voiceTalent) => voiceTalent.Id === voiceTalentId)?.TalentName;
		}
		return 'All';
	}

	get showDateColumnHeader() {
		return !this.librariesService.selectedLibrary?.isGlobal || this.appStateService.currentUser.IsEmployee;
	}

	constructor(
		public appStateService: AppStateService,
		public batchChangeCategoryService: BatchChangeCategoryService,
		public batchDeleteService: BatchDeleteService,
		private cacheService: CacheService,
		private categoriesService: CategoriesService,
		public chooseContentService: ChooseContentService,
		private contentService: ContentService,
		public create24Service: Create24Service,
		public cvStateService: ContentViewStateService,
		public fileUploadModalService: FileUploadModalService,
		public librariesService: LibrariesService,
		public utilService: UtilitiesService
	) {}

	ngOnInit() {
		if (this.appStateService.product.Route === 'hold') {
			this.instructions = 'Select a message library from the options on the left and choose a category to begin organizing your messages.';
		} else {
			switch (this.appStateService.activeFeatureArea) {
				case FeatureArea.CONTENT_MANAGER:
					this.instructions = 'Select a video library from the options on the left and choose a category to begin editing your clips.';
					break;
				default:
					this.instructions = 'Select a library from the options on the left and choose a category to view your content.';
					break;
			}
		}
		this.contentDeletable = this.appStateService.activeFeatureArea !== FeatureArea.CREATE_24;
		this.cvStateService.viewState.hideDeleted = true;
	}

	public batchChangeCategoryLibrariesVisible(): boolean {
		return this.cvStateService.viewState.atLeastOneContentSelected && this.appStateService.currentUser.IsEmployee;
	}

	public clearSearchResultsBtnVisible(): boolean {
		return this.cvStateService.viewState.contentListVisible && this.cvStateService.viewState.contentSearchActive;
	}

	public tipVisible(): boolean {
		return (
			this.cvStateService.viewState.contentListVisible &&
			!this.cvStateService.viewState.contentSearchActive &&
			!this.batchChangeCategoryService.atLeastOneContentSelected
		);
	}

	public onDeletedContentToggleClick(str: 'off' | 'on'): void {
		const hideDeleted = str === 'off' ? true : false;
		this.cvStateService.viewState.hideDeleted = hideDeleted;

		const args: IGetContentParams = {
			clickedCategory: this.categoriesService.clickedCategory,
			hideDeleted
		};

		if (this.cvStateService.viewState.contentSearchActive) {
			this.contentService.getContentBySearchTerm(args).subscribe((contentList) => {
				this.setContentList(contentList);
			});
		} else {
			this.contentService.getContentForCategories(args).subscribe((contentList) => {
				this.setContentList(contentList);
			});
		}
	}

	private setContentList(contentList): void {
		const index = this.cacheService.contentList.findIndex((entry) => entry.category.Id === this.categoriesService.clickedCategory.Id);
		if (index !== -1) {
			this.cacheService.contentList[index].contentList = contentList;
			this.cacheService.contentList[index].totalItemsContentList = contentList.length;
		}
		this.cvStateService.contentList = contentList;
		this.cvStateService.viewState.contentListCurrentPage = 1;
		this.cvStateService.viewState.contentListVisible = true;
	}

	public onSortClick(orderByCol: 'Title' | 'CreateDate'): void {
		const { clickedCategory } = this.categoriesService;
		const { contentSearchActive } = this.cvStateService.viewState;
		const create24ContentOnly =
			this.appStateService.activeFeatureArea === FeatureArea.CREATE_24 && this.create24Service.c24ActiveArea === Create24Area.EDIT_PROJECT;
		let orderDesc;

		if (orderByCol === 'Title') {
			this.titleDesc = !this.titleDesc;
			orderDesc = this.titleDesc;
		} else if (orderByCol === 'CreateDate') {
			this.dateDesc = !this.dateDesc;
			orderDesc = this.dateDesc;
		}
		this.cvStateService.viewState.sortParams = { orderByCol, orderDesc };

		if (contentSearchActive) {
			this.contentService.getContentBySearchTerm({ clickedCategory, create24ContentOnly }).subscribe((contentList: ContentVM[]) => {
				this.cvStateService.contentList = contentList;
				this.cacheService.storeContent(clickedCategory, contentList, this.cvStateService.totalItemsContentList);
			});
		} else {
			this.contentService.getContentForCategories({ clickedCategory, create24ContentOnly }).subscribe((contentList: ContentVM[]) => {
				this.cvStateService.contentList = contentList;
				this.cacheService.storeContent(clickedCategory, contentList, this.cvStateService.totalItemsContentList);
			});
		}
	}
}
