import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { fabric } from 'fabric';
import { Observable, Observer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { CanvasService } from '../canvas/_services/canvas.service';
import { environment } from '../../../../../environments/environment';
import { IImage, Layer } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class FeedService {

    constructor(
        private canvasService: CanvasService,
        private httpClient: HttpClient
    ) { }

    public textJson(obj): any {
        return {
            // SizeMode: 'website',
            Height: +(obj.height + 30).toFixed(0),
            Width: +(obj.width + 40).toFixed(0),
            Padding: 15,
            FontFamily: obj.fontFamily,
            FontWeight: obj.fontWeight,
            FontSize: obj.fontSize,
            FontStyle: obj.fontStyle,
            FontColor: obj.fill,
            FontOpacity: obj.opacity,
            HorizontalAlign: obj.textAlign,
            TextRotation: 0,
            HasBorder: obj.strokeWidth > 0,
            BorderOnly: obj.fill === 'transparent',
            BorderColor: obj.stroke,
            BorderSize: obj.strokeWidth,
            BorderOpacity: obj.opacity,
            HasDropShadow: obj.shadow !== null,
            ShadowColor: '#000000',
            ShadowOffsetX: obj.shadow?.offsetX,
            ShadowOffsetY: obj.shadow?.offsetY,
            ShadowBlur: obj.shadow?.blur,
            ImageText: obj.text
        }
    }

    public textToBase64$(obj): Observable<any> {
        return this.httpClient.post(`${environment.contentUrl}TextToImage/CreateImage`, this.textJson(obj), { responseType: 'blob' }).pipe(
            mergeMap((res: Blob) => {
                let image: any = {};
                image.layerId = obj.layerId;
                image.blob = res;

                var reader = new FileReader();
                reader.readAsDataURL(image.blob);

                return new Observable((observer: Observer<any>) => {
                    reader.onload = () => {
                        observer.next({ layerId: image.layerId, base64: reader.result });
                        observer.complete();
                    }
                })
            })
        )
    }

    public addImage$(layer: Layer, feedTextbox): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            fabric.Image.fromURL(layer.previewUrl, (img) => {
                let image: IImage = img as IImage;
                image.set({
                    component: 'canvas',
                    name: 'image',
                    layerId: layer.id,
                    strokeWidth: 0,
                    padding: 15,
                    strokeUniform: true,
                    hasBorders: true,
                    hasRotatingPoint: false,
                    hasControls: true,
                    isFeedTextImage: true,
                    cornerColor: '#2980b9',
                    cornerStrokeColor: '#fff',
                    borderColor: '#2980b9',
                    transparentCorners: false,
                    top: layer.canvasObj.top,
                    left: layer.canvasObj.left,
                    width: feedTextbox.width,
                    height: feedTextbox.height + 30,
                    visible: false
                })
                image.setControlsVisibility({
                    tr: false,
                    tl: false,
                    br: false,
                    bl: false,
                    ml: true,
                    mt: true,
                    mr: true,
                    mb: true,
                    mtr: true,
                })
                //Replace feed text obj with text converted to image
                this.canvasService.canvas.add(image);
                this.canvasService.canvas.renderAll();
                observer.next(null);
                observer.complete();
                
            }, { crossOrigin: 'anonymous' });
        })
    }

    public serializeJson(json): string {
        return btoa(encodeURIComponent(json));
    }

    public dataFormatAttr(layer: Layer): string {
        return `data-format-${layer.feedSourceName.toLocaleLowerCase()}=`;
    }

    public className(feedSourceName: 'Title' | 'Body'): string {
        switch (feedSourceName) {
            case 'Title': return 'c24FeedTitle'
            case 'Body': return 'c24FeedBody';
            default: return '';
        }
    }
}
