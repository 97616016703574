import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { AppStateService, Events, MessageService, StatesListService, UtilitiesService } from '../../../core/services';
import { CreateEditLocationService } from './create-edit-location.service';
import { ServiceLocations } from '../../../shared/api-models/admin';
import { Users } from '../../../shared/api-models/admin';

@Component({
	selector: 'app-create-edit-location',
	templateUrl: './create-edit-location.component.html',
	styleUrls: ['./create-edit-location.component.scss']
})
export class CreateEditLocationComponent implements OnInit {
	public invalidZip: boolean;
	public location: ServiceLocations;
	public primaryContactUser: string;
	public saveInProgress: boolean;
	public users: Users[];

	public viewState: 'new' | 'edit';

	constructor(
		private appStateService: AppStateService,
		private createEditLocationService: CreateEditLocationService,
		public routerLocation: Location,
		private messageService: MessageService,
		private router: Router,
		public statesListService: StatesListService,
		private utilService: UtilitiesService
	) {}

	ngOnInit() {
		const url = this.router.routerState.snapshot.url;
		const locationId: number | null = this.appStateService.getSessionItem('locationId') ? JSON.parse(this.appStateService.getSessionItem('locationId')) : null;
		this.createEditLocationService.getUsersByClient().subscribe((users: Users[]) => {
			this.users = users;
		});

		if (url.includes('edit')) {
			this.viewState = 'edit';

			this.createEditLocationService.getLocation(locationId).subscribe((location: ServiceLocations[]) => {
				this.location = location[0];
				if (this.location.PrimaryContactUser) {
					this.primaryContactUser = `${this.location.PrimaryContactUser.ContactInformation.FirstName} ${this.location.PrimaryContactUser.ContactInformation.LastName} (${this.location.PrimaryContactUser.ContactInformation.Email})`;
				}
			});
		} else if (url.includes('new')) {
			this.newLocationInit();
		}
	}

	onDropdownSelect(event: any, prop: string) {
		if (prop === 'State') {
			this.location[prop] = event.code;
		} else if (prop === 'PrimaryContactUserId') {
			this.location[prop] = event.Id;
		}
	}

	public onSaveClick(): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.saveInProgress = true;

		if (this.viewState === 'edit') {
			this.createEditLocationService.updateLocation(this.location).subscribe(() => {
				this.routeToLocationsList();
			});
		} else if (this.viewState === 'new') {
			this.createEditLocationService.postLocation(this.location).subscribe(() => {
				this.routeToLocationsList();
			});
		}
	}

	public onZipBlur(): void {
		this.validateZip();
	}

	public onZipKeyup(): void {
		this.invalidZip = false;
		if (this.location.Zip.length >= 5) {
			this.validateZip();
		}
	}

	public validateZip(): void {
		this.utilService.validateZip(this.location.Zip).subscribe(
			(isValid: boolean) => {
				this.invalidZip = !isValid;
			},
			(err) => {
				this.invalidZip = true;
			}
		);
	}

	private newLocationInit(): void {
		this.viewState = 'new';
		this.location = new ServiceLocations();
		this.location.ClientId = this.appStateService.currentClient.Id;
	}

	private routeToLocationsList(): void {
		this.messageService.publish(Events.savingPreloader, 0);
		this.saveInProgress = false;
		this.router.navigate(['/crm/client-details/locations']);
	}
}
