import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorKeyService {

  public colorKeyItems = [
    {
      ticketStatus: 'New',
      yellowNumber: 'N/A',
      redNumber: 1
    },
    {
      ticketStatus: 'Assigned/Active',
      yellowNumber: 1,
      redNumber: 2
    },
    {
      ticketStatus: 'Waiting on Vendor',
      yellowNumber: 1,
      redNumber: 2
    },
    {
      ticketStatus: 'Waiting on Walkthrough',
      yellowNumber: 2,
      redNumber: 3
    },
    {
      ticketStatus: 'Pending Shipment',
      yellowNumber: 2,
      redNumber: 3
    },
    {
      ticketStatus: 'Waiting on Salesperson',
      yellowNumber: 3,
      redNumber: 4
    },
    {
      ticketStatus: 'Waiting on Client',
      yellowNumber: 4,
      redNumber: 5
    },
    {
      ticketStatus: 'Pending Return',
      yellowNumber: 4,
      redNumber: 5
    },
    {
      ticketStatus: 'Waiting on Forms',
      yellowNumber: 5,
      redNumber: 6
    },
    {
      ticketStatus: 'Construction',
      yellowNumber: 5,
      redNumber: 6
    },
    {
      ticketStatus: 'Self Install',
      yellowNumber: 5,
      redNumber: 6
    }
  ]

  constructor() { }

}
