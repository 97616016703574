import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import * as moment from 'moment';

import { DateTimeService } from '../../../core/services';
import { Clients, CsrTaskTypes } from '../../../shared/api-models/admin';
import { CsrTasksView } from '../_models/csr-tasks-view';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CreateEditTaskService {

    public taskTypes: CsrTaskTypes[];

    constructor(
        private dateTimeService: DateTimeService,
        private httpClient: HttpClient
    ) { }

    public getTask(taskId: number): Observable<CsrTasksView> {
        return this.httpClient.get(`${environment.adminUrl}CoreCsrTasks/${taskId}`).pipe(
            mergeMap( (task: CsrTasksView) => {
                return this.httpClient.get(`${environment.adminUrl}CoreClients/${task.ClientId}`).pipe(
                    map( (client: Clients) => {
                        return this.csrTasksView(task, client);
                    })
                )
            })
        )
    }

    public getTaskTypes(): Observable<CsrTaskTypes[]> {
        return this.httpClient.get<CsrTaskTypes[]>(`${environment.adminUrl}CoreCsrTaskTypes`);
    }

    public postNewTask(task: CsrTasksView): Observable<any> {
        task.Created = new Date().toDateString();
        return this.httpClient.post(`${environment.adminUrl}CoreCsrTasks`, task);
    }

    public updateTask(task: CsrTasksView): Observable<any> {
        return this.httpClient.put(`${environment.adminUrl}CoreCsrTasks/${task.Id}`, task);
    }

    private csrTasksView(task: CsrTasksView, client: Clients): CsrTasksView {
        task.dueDateView = this.dateTimeService.buildDatePickerDate(moment(task.DueDate));
        task.taskType = this.taskTypes.find(taskType => taskType.Id === task.CsrTaskTypeId).Name;
        task.clientName = client.Name;
        return task;
    }
}
