import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppStateService, DateTimeService } from '../../core/services';
import { Contracts } from '../../shared/api-models/admin';
import { ContractTermsService } from './contract-terms.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContractsService {

    constructor(
        private appStateService: AppStateService,
        private contractTermsService: ContractTermsService,
        private dateTimeService: DateTimeService,
        private httpClient: HttpClient) { }

    public getContractsForClient(): Observable<Contracts[]> {
        return this.httpClient.get(`${environment.adminUrl}CoreContracts/ContractsForClient/${this.appStateService.currentClient.Id}`).pipe(
            map((contracts: Contracts[]) => {
                return this.contractsView(contracts);
            })
        )
    }

    private contractsView(contracts: Contracts[]): Contracts[] {
        return contracts.map((contract) => {
            contract.term = this.contractTerm(contract);
            contract.earlyCancelDateView = contract.EarlyCancelDate ? this.dateTimeService.dateLocal(contract.EarlyCancelDate) : '';
            contract.renewDateView = this.dateTimeService.dateLocal(contract.RenewDate);
            contract.saleDateView = this.dateTimeService.dateLocal(contract.SaleDate);
            contract.viewState = 'readMode';
            return contract;
        }).sort((a, b) => {
            let textA = a.SaleDate;
            let textB = b.SaleDate;
            return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
        })
    }

    private contractTerm(contract: Contracts): string {
        return this.contractTermsService.contractTerms.find((contractTerm) => {
            return contractTerm.Id === contract.ContractTermId;
        }).name;
    }

    public productTypeIdToContractTypeId(productTypeId: number): number {
        switch (productTypeId) {
            case 1: //on hold
                return 1;
            case 2: //radio
                return 3;
            case 8: //other
                return 5;
            default: //any video
                return 2;
        }
    }

    public setBgColor(productTypeId: number): string {
        switch (productTypeId) {
            case 1:
                return 'blue-bg-light-theme';
            case 2:
                return 'purple-bg-light-theme';
            case 8:
                return 'gray-bg-light-theme';
            default:
                return 'red-bg-light-theme';
        }
    }
}
