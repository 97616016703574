<div [ngStyle]="{ 'width.px': 1000 }" class="absolute pd15 top275 text-left left85 ft-size18 white-bg shadow z border-radius">
	<!--HEADLINE-->
	<div class="row">
		<div class="col-sm-5">
			<h3 class="semi-bold">{{ countdownAndDateService.viewHeadline() }}</h3>
		</div>
		<ng-container *ngIf="countdownAndDateService.mode === 'new'">
			<div class="col-sm-2">
				<button
					[disabled]="createLayerSidebarService.countdownExists()"
					class="{{ createLayerSidebarService.countdownExists() ? 'setOpacityLow' : null }} {{
						countdownAndDateService.view === 'countdown' ? 'gray-bg-darker' : 'gray-bg'
					}}"
					(click)="onViewClick('countdown')"
				>
					Countdown
				</button>
			</div>
			<div class="col-sm-2">
				<button
					[disabled]="createLayerSidebarService.currentDateExists()"
					class="{{ createLayerSidebarService.currentDateExists() ? 'setOpacityLow' : null }} {{
						countdownAndDateService.view === 'todaysDate' ? 'gray-bg-darker' : 'gray-bg'
					}}"
					(click)="onViewClick('todaysDate')"
				>
					Today's Date
				</button>
			</div>
			<div class="col-sm-2">
				<button
					[disabled]="createLayerSidebarService.currentTimeExists()"
					class="{{ createLayerSidebarService.currentTimeExists() ? 'setOpacityLow' : null }} {{
						countdownAndDateService.view === 'currentTime' ? 'gray-bg-darker' : 'gray-bg'
					}}"
					(click)="onViewClick('currentTime')"
				>
					Current Time
				</button>
			</div>
			<div class="col-sm-1 text-right">
				<a class="dark-text pd10 border-radius" (click)="hideCountdownOptions.emit()"><i class="fas fa-times ft-size22"></i></a>
			</div>
		</ng-container>
	</div>

	<!--DIVIDER BAR-->
	<div class="mgn-top15 mgn-bottom15 red-gradient ht1 border-radius"></div>

	<!--COUNTDOWN OPTIONS-->
	<ng-container *ngIf="countdownAndDateService.view === 'countdown'">
		<div class="row mgn-top15">
			<div class="col-sm-3">
				<span class="ft-size16 regular">Interval</span>
			</div>
			<div class="col-sm-3">
				<span class="ft-size16" (click)="onIntervalClick(countdownInterval.DAYS)">Days</span>
				<br />
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="countdownAndDateService.countdownInterval.includes(countdownInterval.DAYS)"
					[type]="'checkbox'"
					(checkboxClickNotify)="onIntervalClick(countdownInterval.DAYS)"
				></portal-checkbox>
			</div>
			<div class="col-sm-3">
				<span class="ft-size16" (click)="onIntervalClick(countdownInterval.TIME)">Hours</span>
				<br />
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="countdownAndDateService.countdownInterval.includes(countdownInterval.TIME)"
					[type]="'checkbox'"
					(checkboxClickNotify)="onIntervalClick(countdownInterval.TIME)"
				></portal-checkbox>
			</div>
		</div>
		<ng-container *ngIf="countdownAndDateService.view === 'countdown'">
			<div class="row mgn-top30">
				<div class="col-sm-3">
					<span class="ft-size16 regular">Display Options</span>
				</div>
				<ng-container *ngIf="countdownAndDateService.countdownInterval === countdownInterval.DAYS">
					<div class="col-sm-3">
						<span class="ft-size16" (click)="onLayerOptionsClick('OneLayer')">One layer</span>
						<i class="far fa-question-circle ft-size14 relative left5" (mouseover)="onShowTooltip('OneLayer')" (mouseleave)="showTooltip = false"></i>
						<br />
						<div *ngIf="showTooltip && tooltip === 'OneLayer'" class="absolute white-bg shadow pd15 z border-radius" style="left: 126px; top: 0">
							<p *ngIf="countdownAndDateService.countdownInterval.includes(countdownInterval.DAYS)" class="ft-size20 semi-bold">12 Days</p>
							<p *ngIf="countdownAndDateService.countdownInterval.includes(countdownInterval.TIME)" class="ft-size20 semi-bold">128:54:32</p>
						</div>
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[isChecked]="countdownAndDateService.displayOption === 'OneLayer'"
							[type]="'checkbox'"
							(checkboxClickNotify)="onLayerOptionsClick('OneLayer')"
						></portal-checkbox>
					</div>
					<div class="col-sm-3">
						<span class="ft-size16" (click)="onLayerOptionsClick('TwoLayers')">Two layers</span>
						<i class="far fa-question-circle ft-size14 relative left5" (mouseover)="onShowTooltip('TwoLayers')" (mouseleave)="showTooltip = false"></i>
						<br />
						<div *ngIf="showTooltip && tooltip === 'TwoLayers'" class="absolute white-bg shadow wdth400 pd15 z border-radius" style="left: 126px; top: 0">
							<p class="ft-size14 mgn-bottom30">
								With multiple layers, you can move and format each part separately. Individual layers can also be deleted if desired.
							</p>
							<img src="assets/option-countdown-2-layers.png" />
						</div>
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[isChecked]="countdownAndDateService.displayOption === 'TwoLayers'"
							[type]="'checkbox'"
							(checkboxClickNotify)="onLayerOptionsClick('TwoLayers')"
						></portal-checkbox>
					</div>
				</ng-container>
				<ng-container *ngIf="countdownAndDateService.countdownInterval === countdownInterval.TIME">
					<div class="col-sm-3">
						<span class="ft-size16" (click)="onLayerOptionsClick('OneLayer')">One layer</span>
						<i class="far fa-question-circle ft-size14 relative left5" (mouseover)="onShowTooltip('OneLayer')" (mouseleave)="showTooltip = false"></i>
						<br />
						<div *ngIf="showTooltip && tooltip === 'OneLayer'" class="absolute white-bg shadow pd15 z border-radius" style="left: 126px; top: 0">
							<p *ngIf="countdownAndDateService.countdownInterval.includes(countdownInterval.DAYS)" class="ft-size20 semi-bold">12 Days</p>
							<p *ngIf="countdownAndDateService.countdownInterval.includes(countdownInterval.TIME)" class="ft-size20 semi-bold">128:54:32</p>
						</div>
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[isChecked]="countdownAndDateService.displayOption === 'OneLayer'"
							[type]="'checkbox'"
							(checkboxClickNotify)="onLayerOptionsClick('OneLayer')"
						></portal-checkbox>
					</div>
					<div class="col-sm-3">
						<span class="ft-size16" (click)="onLayerOptionsClick('ThreeLayers')">Three layers</span>
						<i class="far fa-question-circle ft-size14 relative left5" (mouseover)="onShowTooltip('ThreeLayers')" (mouseleave)="showTooltip = false"></i>
						<br />
						<div *ngIf="showTooltip && tooltip === 'ThreeLayers'" class="absolute white-bg shadow wdth400 pd15 z border-radius" style="left: 134px; top: -10px">
							<p class="ft-size14 mgn-bottom30">
								With multiple layers, you can move and format each part separately. Individual layers can also be deleted if desired.
							</p>
							<img src="assets/Hover-Countdown-Hours.png" />
						</div>
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[isChecked]="countdownAndDateService.displayOption === 'ThreeLayers'"
							[type]="'checkbox'"
							(checkboxClickNotify)="onLayerOptionsClick('ThreeLayers')"
						></portal-checkbox>
					</div>
				</ng-container>
			</div>
		</ng-container>
		<div class="row mgn-top30">
			<div class="col-sm-3">
				<span class="ft-size16 regular">Countdown Date</span>
			</div>
			<div class="col-sm-9">
				<div class="row">
					<div class="col-xs-6">
						<span class="ft-size16">Date</span>
					</div>
					<div class="col-xs-6">
						<span class="ft-size16">Time</span>
					</div>
				</div>
				<portal-date-time-selector
					[backgroundColor]="'gray-bg-dark'"
					[dateModel]="layersService.activeLayer.countdownDate"
					[disableUntil]="disableDateUntil"
					[height]="'ht47'"
					[includeTime]="true"
					(dateChanged)="onCountdownDateChanged($event)"
				></portal-date-time-selector>
			</div>
		</div>
	</ng-container>

	<!--TODAY'S DATE OPTIONS-->
	<ng-container *ngIf="countdownAndDateService.view === 'todaysDate'">
		<div class="row mgn-top30">
			<div class="col-sm-3">
				<span class="ft-size16 regular">Display Options</span>
			</div>
		</div>
		<div class="row mgn-top30">
			<div class="col-sm-2 col-sm-offset-1">
				<span class="ft-size14" (click)="onLayerOptionsClick('short')">1/25/2026</span>
				<br />
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="countdownAndDateService.displayOption === 'short'"
					[type]="'checkbox'"
					(checkboxClickNotify)="onLayerOptionsClick('short')"
				></portal-checkbox>
			</div>
			<div class="col-sm-2 relative">
				<span class="ft-size14" (click)="onLayerOptionsClick('dayMonthDate')">Sunday, January 25th</span>
				<br />

				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="countdownAndDateService.displayOption === 'dayMonthDate'"
					[type]="'checkbox'"
					(checkboxClickNotify)="onLayerOptionsClick('dayMonthDate')"
				></portal-checkbox>
			</div>
			<div class="col-sm-3">
				<span class="ft-size14" (click)="onLayerOptionsClick('dayMonthDateYear')">Sunday, January 25, 2026</span>
				<br />
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="countdownAndDateService.displayOption === 'dayMonthDateYear'"
					[type]="'checkbox'"
					(checkboxClickNotify)="onLayerOptionsClick('dayMonthDateYear')"
				></portal-checkbox>
			</div>
			<div class="col-sm-2 relative">
				<span class="absolute ft-size14" style="top: -37px" (click)="onLayerOptionsClick('TwoLayers')">
					<em>(2 layers)</em>
					<br />
					Sunday
					<br />
					January 25th
				</span>
				<i class="far fa-question-circle ft-size14 absolute right52" (mouseover)="onShowTooltip('TwoLayers')" (mouseleave)="showTooltip = false"></i>
				<br />
				<div *ngIf="showTooltip && tooltip === 'TwoLayers'" class="absolute white-bg shadow wdth400 pd15 z border-radius" style="left: 126px; top: 0">
					<p class="ft-size14 mgn-bottom30">
						With multiple layers, you can move and format each part separately. Individual layers can also be deleted if desired.
					</p>
					<img src="assets/Option-Date-2-Layers.png" />
				</div>
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="countdownAndDateService.displayOption === 'TwoLayers'"
					[type]="'checkbox'"
					(checkboxClickNotify)="onLayerOptionsClick('TwoLayers')"
				></portal-checkbox>
			</div>
			<div class="col-sm-2 relative">
				<span class="absolute ft-size14" style="top: -77px" (click)="onLayerOptionsClick('FourLayers')">
					<em>(4 layers)</em>
					<br />
					Sunday
					<br />
					January
					<br />
					25
					<br />
					2026
				</span>
				<i class="far fa-question-circle ft-size14 absolute right98" (mouseover)="onShowTooltip('FourLayers')" (mouseleave)="showTooltip = false"></i>
				<br />
				<div *ngIf="showTooltip && tooltip === 'FourLayers'" class="absolute white-bg shadow wdth400 pd15 z border-radius" style="left: 86px; top: 0">
					<p class="ft-size14 mgn-bottom30">
						With multiple layers, you can move and format each part separately. Individual layers can also be deleted if desired.
					</p>
					<img src="assets/Option-Date-4-Layers.png" />
				</div>
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="countdownAndDateService.displayOption === 'FourLayers'"
					[type]="'checkbox'"
					(checkboxClickNotify)="onLayerOptionsClick('FourLayers')"
				></portal-checkbox>
			</div>
		</div>
	</ng-container>

	<!--CURRENT TIME OPTIONS-->
	<ng-container *ngIf="countdownAndDateService.view === 'currentTime'">
		<div class="row mgn-top30">
			<div class="col-sm-3">
				<span class="ft-size16 regular">Display Options</span>
			</div>
		</div>
		<div class="row mgn-top15">
			<div class="col-sm-3 col-sm-offset-3">
				<span class="ft-size14" (click)="onLayerOptionsClick('OneLayer')">12:00 pm</span>
				<br />
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="countdownAndDateService.displayOption === 'OneLayer'"
					[type]="'checkbox'"
					(checkboxClickNotify)="onLayerOptionsClick('OneLayer')"
				></portal-checkbox>
			</div>
			<div class="col-sm-3">
				<span class="ft-size14" (click)="onLayerOptionsClick('SansPeriod')">12:00</span>
				<br />
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="countdownAndDateService.displayOption === 'SansPeriod'"
					[type]="'checkbox'"
					(checkboxClickNotify)="onLayerOptionsClick('SansPeriod')"
				></portal-checkbox>
			</div>
			<div class="col-sm-3">
				<span class="ft-size14 absolute" style="top: -37px" (click)="onLayerOptionsClick('TwoLayers')">
					<em>(2 layers)</em>
					<br />
					12:00
					<br />
					pm
				</span>
				<i
					class="far fa-question-circle ft-size14 absolute"
					style="left: 58px; top: 6px"
					(mouseover)="showTooltip = true"
					(mouseleave)="showTooltip = false"
				></i>
				<br />
				<div *ngIf="showTooltip" class="absolute white-bg shadow wdth400 pd15 z border-radius" style="left: 134px; top: -10px">
					<p class="ft-size14 mgn-bottom30">
						With multiple layers, you can move and format each part separately. Individual layers can also be deleted if desired.
					</p>
					<img src="assets/Hover-Time.png" />
				</div>
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="countdownAndDateService.displayOption === 'TwoLayers'"
					[type]="'checkbox'"
					(checkboxClickNotify)="onLayerOptionsClick('TwoLayers')"
				></portal-checkbox>
			</div>
		</div>
	</ng-container>

	<!--CANCEL/SAVE BUTTONS-->
	<div class="row mgn-top60">
		<div class="col-sm-4 col-sm-offset-4">
			<button class="gray-bg" (click)="onCancelClick()">Cancel</button>
		</div>
		<div class="col-sm-4">
			<button
				[disabled]="countdownAndDateService.view === 'countdown' && !countdownAndDateService.countdownDate"
				class="{{ countdownAndDateService.view === 'countdown' && !countdownAndDateService.countdownDate ? 'setOpacityLow' : null }} limeGreen-bg white-text"
				(click)="onCreateCountdownClick()"
			>
				{{ countdownAndDateService.mode === 'new' ? 'Create' : 'Update' }} {{ buttonText() }}
			</button>
		</div>
	</div>
</div>
