import { Pipe, PipeTransform } from '@angular/core';

import { CategoriesTree } from '../_models';
import { ContentViewStateService } from 'src/app/shared/services';
@Pipe({
	name: 'favoritesFilterPipe',
	pure: false
})
export class FavoritesFilterPipe implements PipeTransform {
	constructor(private cvStateService: ContentViewStateService) {}

	transform(categories: CategoriesTree[], isSubCategoryArr?: boolean): any {
		if (categories) {
			return categories.filter((category) => {
				if (this.cvStateService.viewState.treeSelection === 'favorites') {
					if (category.containsFavorites && !isSubCategoryArr && !category.isSubCategory) {
						return true;
					}
					if (isSubCategoryArr && category.containsFavorites) {
						return true;
					}
					return false;
				}
				return true;
			});
		}
	}
}
