import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'core-saving-preloader',
  templateUrl: './saving-preloader.component.html',
  styleUrls: ['./saving-preloader.component.scss']
})
export class SavingPreloaderComponent implements OnInit {

  @Input() savingBubblesVisible: boolean;
  @Input() savingText: string;
  
  constructor() { }

  ngOnInit() {
  }

}
