import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TicketsTable } from 'src/app/system-tools/ticket-system/_models';

import { AppStateService, Events, MessageService } from 'src/app/core/services';
import { TableConfigService } from '../../system-tools/ticket-system/_services';
import { TicketSystemService } from 'src/app/system-tools/ticket-system/_services';
import { TicketsTableApi } from 'src/app/shared/api-models/admin/tickets-table-api.type';
import { ViewStateService } from 'src/app/system-tools/ticket-system/_services';
import { PageTitle } from 'src/app/system-tools/ticket-system/_models/tickets.enum';
import { Subscription } from 'rxjs';
import { SortParams } from 'src/app/shared/view-models/common-types';

@Component({
	selector: 'app-client-tickets',
	templateUrl: './client-tickets.component.html',
	styleUrls: ['./client-tickets.component.scss']
})
export class ClientTicketsComponent implements OnInit, OnDestroy {
	public tickets: TicketsTableApi[];
	private subs: Subscription[] = [];

	constructor(
		private appStateService: AppStateService,
		private messageService: MessageService,
		private router: Router,
		public tableConfigService: TableConfigService,
		public ticketSystemService: TicketSystemService,
		public viewStateService: ViewStateService
	) {
		this.onColumnSortSubscribe();
	}

	ngOnInit(): void {
		this.viewStateService.pageTitle = PageTitle.OPEN_TICKETS;
		this.viewStateService.table = this.tableConfigService.nonPostponedViewTable;
		this.viewStateService.clientId = this.appStateService.currentClient.Id;
		this.getTickets();
	}

	onColumnSortSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.onColumnSort, (sortParams: SortParams) => {
				this.viewStateService.orderByCol = sortParams.key;
				sortParams.order === 'asc' ? (this.viewStateService.orderDesc = false) : (this.viewStateService.orderDesc = true);
				this.getTickets();
			})
		);
	}

	public onRowSelect(ticket: TicketsTable): void {
		this.router.navigate([`/ticket-system/edit-ticket/${ticket.Id}`]);
	}

	public onPageClick(pageNumber: number): void {
		this.viewStateService.pageNumber = pageNumber;
		this.getTickets();
	}

	public onIncludeClosedClick(): void {
		this.viewStateService.includeClosed = !this.viewStateService.includeClosed;
		this.getTickets();
	}

	private getTickets(): void {
		this.ticketSystemService.getTableData$().subscribe((tickets: TicketsTableApi[]) => {
			this.tickets = tickets;
		});
	}

	ngOnDestroy(): void {
		this.viewStateService.reset();
	}
}
