import { Component, OnInit } from '@angular/core';

import { ContentRequestsService, TableViewStateService } from '../_services';
import { UrlString } from '../_models/url-string.enum';

@Component({
	selector: 'cr-nav-bar',
	templateUrl: './nav-bar.component.html',
	styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
	public mobileMenuVisible: boolean;
	readonly urlString = UrlString;

	constructor(private contentRequestsService: ContentRequestsService, public viewStateService: TableViewStateService) {}

	ngOnInit() {}

	public getRequestsClick(pageTitleSnippet: string, urlString?: UrlString): void {
		this.viewStateService.urlString = urlString;
		this.viewStateService.pageTitleSnippet = pageTitleSnippet;
		this.contentRequestsService.setPageTitle(pageTitleSnippet);
		this.contentRequestsService.getRequests().subscribe((requests) => {
			this.contentRequestsService.requests = requests;
		});
	}
}
