import { Injectable } from '@angular/core';

import { Column, Table, TableConfig } from '../../shared/components/table/view-models';

@Injectable({
	providedIn: 'root'
})
export class TableConfigService {
	public columns: Column[] = [
		new Column('viewBtn', 'View', 'button').setElementClassList('gray-bg pd-left8 pd-right8'),
		new Column('Name', 'Name', 'string').setIsSortable(true).setElementClassList('semi-bold'),
		new Column('Address1', 'Address', 'string').setIsSortable(true),
		new Column('City', 'City', 'string').setIsSortable(true),
		new Column('State', 'State', 'string').setIsSortable(true),
		new Column('Phone', 'Phone', 'string').setIsSortable(true),
		new Column('holdRemoteNumber', 'Remote #', 'string').setIsSortable(true),
		new Column('holdUnit', 'Hold Unit', 'string').setIsSortable(true),
		new Column('hold', 'fas fa-phone-volume', 'iconAndNumber').setIsSortable(true).setShowIconOnly(true).setSortKey('hold.number'),
		new Column('video', 'fas fa-tv', 'iconAndNumber').setIsSortable(true).setShowIconOnly(true).setSortKey('video.number'),
		new Column('poster', 'far fa-square', 'iconAndNumber').setIsSortable(true).setShowIconOnly(true).setSortKey('poster.number'),
		new Column('radio', 'fas fa-volume-up', 'iconAndNumber').setIsSortable(true).setShowIconOnly(true).setSortKey('radio.number'),
		new Column('web', 'fas fa-laptop', 'iconAndNumber').setIsSortable(true).setShowIconOnly(true).setSortKey('web.number'),
		new Column('videoWall', 'fas fa-th', 'iconAndNumber').setIsSortable(true).setShowIconOnly(true).setSortKey('videoWall.number'),
		new Column('deleteBtn', 'far fa-trash-alt', 'button')
			.setElementClassList('white-bg red-text-light semi-bold ht40 pd-left12 pd-right12')
			.setShowIconOnly(true)
	];

	public table: Table = new Table(this.columns);

	public configuration: TableConfig = {
		itemsPerPage: 25,
		headerColor: 'green-bg',
		nonServerPagination: true
	};
}
