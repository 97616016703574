<div class="row">
	<div class="{{includeTime ? 'col-xs-6' : 'col-xs-12'}} {{fontSize}}">
		<div *ngIf="disabled" style="background: rgba(255,255,255,0.7)" class="disabled-overlay z mgn-right15 mgn-left15 border-radius"></div>
		<portal-date-picker
				[backgroundColor]="backgroundColor"
				[clearDateEnabled]="clearDateEnabled"
				[disableUntil]="disableUntil"
		  		[height]="height"
		  		[model]="dateView.date"
		  		(dateChanged)="onDateChanged($event)">	
		</portal-date-picker>
	</div>
	<div *ngIf="includeTime" class="col-xs-6 {{fontSize}}">
		<div *ngIf="disabled" style="background: rgba(255,255,255,0.7)" class="disabled-overlay z mgn-right15 mgn-left15 border-radius"></div>
		<portal-dropdown-menu 
		   [backgroundColor]="backgroundColor"
		   [height]="height"
		   [key]="'name'"
		   [selectedOption]="dateView.time"
		   [options]="timesOfDay"
		   (onOptionSelect)="onTimeSelect($event)">	
		</portal-dropdown-menu>
	</div>
</div>
