import { Component, OnInit } from '@angular/core';

import { CanvasService } from '../../canvas/_services';
import { Create24Service } from '../../../create-24.service';
import { LayerDetailService } from '../_services/layer-detail.service';
import { LayersService, UndoRedoService } from '../../_services';

@Component({
	selector: 'ld-shared-controls',
	templateUrl: './shared-controls.component.html',
	styleUrls: ['./shared-controls.component.scss']
})
export class SharedControlsComponent implements OnInit {
	get dropShadowDisabled() {
		const isCropping = this.layerDetailService.isCropping;
		const { activeLayer } = this.layersService;

		//Chrome does not support svg images that have a filter (drop shadow in our case)
		//that also animate
		const transitionExists = activeLayer.transitionIn !== 'Not set' || activeLayer.transitionOut !== 'Not set';
		if (activeLayer.type === 'Feed Image' || activeLayer.type === 'Image') {
			if (transitionExists || isCropping) {
				return true;
			}
			return false;
		}
		return this.layerDetailService.isCropping;
	}

	constructor(
		private canvasService: CanvasService,
		public create24Service: Create24Service,
		public layerDetailService: LayerDetailService,
		public layersService: LayersService,
		public undoRedoService: UndoRedoService
	) {}

	ngOnInit(): void {}

	public isImage(): boolean {
		//SVG does not support borders on an image
		switch (true) {
			case this.layersService.activeLayer.type === 'Feed Image':
			case this.layersService.activeLayer.type === 'Image':
				return true;
		}
	}

	public activeLayerIsLine(): boolean {
		return this.layersService.activeLayer.type === 'Line';
	}

	public cropBtnBg(): string {
		switch (true) {
			case this.layerDetailService.isCropping:
				return 'yellow-bg dark-text';

			case this.layerDetailService.activeObj()?.isFeedText:
				return 'gray-bg-dark gray-text-darkest';
			default:
				return 'white-bg dark-text';
		}
	}

	public onBorderWidthChange(value: number): void {
		this.layersService.activeLayer.canvasObj.strokeWidth = value;
		this.layerDetailService.updateProperty('strokeWidth', +value);
		this.undoRedoService.recordState('Change border width');
		this.layerDetailService.updateFeedTextImage();
	}

	//https://nyan.im/posts/2786.html
	public onCompleteCropClick(): void {
		this.layerDetailService.cropImage();
	}

	public onCropClick(cropType: string): void {
		if (!this.layerDetailService.isCropping) {
			this.layerDetailService.isCropping = true;
			this.showHideNonActiveObjects(false);
			switch (cropType) {
				case 'Rectangle':
					this.canvasService.addCropRect();
					break;
			}
		} else {
			this.layerDetailService.isCropping = false;
			this.layerDetailService.cropImage();
		}
	}

	public onCancelCropClick(): void {
		this.layerDetailService.isCropping = false;
		this.showHideNonActiveObjects(true);
		this.canvasService.canvas.remove(this.canvasService.cropObj);
	}

	public onBorderRadiusChange(value: number): void {
		this.layersService.activeLayer.canvasObj.rx = value;
		this.layersService.activeLayer.canvasObj.ry = value;
		this.layerDetailService.updateProperty('rx', +value);
		this.layerDetailService.updateProperty('ry', +value);
		this.undoRedoService.recordState('Change border radius');
	}

	public onOpacityChange(value: number): void {
		this.layersService.activeLayer.canvasObj.opacity = value;
		this.layerDetailService.updateProperty('opacity', +value);
		this.layerDetailService.updateFeedTextImage();
	}

	onDropShadowClick(): void {
		this.layersService.activeLayer.canvasObj.includeDropShadow = !this.layersService.activeLayer.canvasObj.includeDropShadow;
		if (!this.layersService.activeLayer.canvasObj.includeDropShadow) {
			this.layerDetailService.updateProperty('shadow', null);
		} else {
			this.layerDetailService.updateProperty('shadow', this.dropShadowDefaults());
		}
		this.undoRedoService.recordState('Select drop shadow');
		this.layerDetailService.updateFeedTextImage();
	}

	private dropShadowDefaults(): any {
		return {
			color: '#000000',
			blur: this.layersService.activeLayer.canvasObj.shadow.blur,
			offsetX: this.layersService.activeLayer.canvasObj.shadow.offsetX,
			offsetY: this.layersService.activeLayer.canvasObj.shadow.offsetY
		};
	}

	public onBorderOnlyClick(): void {
		this.layersService.activeLayer.canvasObj.borderOnly = !this.layersService.activeLayer.canvasObj.borderOnly;
		if (this.layersService.activeLayer.canvasObj.borderOnly) {
			this.layerDetailService.updateProperty('fill', 'transparent');
		} else {
			this.layersService.activeLayer.canvasObj.fill = this.layersService.activeLayer.canvasObj.fillView;
			this.layerDetailService.updateProperty('fill', this.layersService.activeLayer.canvasObj.fill);
		}
		this.undoRedoService.recordState('Select border only');
		this.layerDetailService.updateFeedTextImage();
	}

	public opacityValueView(): number {
		return +(this.layersService.activeLayer.canvasObj.opacity * 100).toFixed(2);
	}

	private showHideNonActiveObjects(visible: boolean): void {
		this.canvasService.canvas
			.getObjects()
			.filter((obj) => obj.layerId !== this.layersService.activeLayer.id)
			.forEach((obj) => {
				obj.set('visible', visible);
			});
	}
}
