import { Pipe, PipeTransform } from '@angular/core';

import { ContentLibraries } from '../../../api-models/content';

@Pipe({
    name: 'prodBuilderFilter',
    pure: false
})

export class ProdBuilderFilterPipe implements PipeTransform {

    constructor(

    ) {}


	/* if step 1, show all non-music libraries.
	   if step 2, show only music libraries */
    transform(libraries: ContentLibraries[], currentPlaylistBuilderStep?: string): any {
    	return libraries?.filter( (library) => {

            //currentPlaylistBuilderStep won't be defined in Content Manager
            if(currentPlaylistBuilderStep) {
                if(currentPlaylistBuilderStep === 'choose-music' && library.ContentLibraryTypeId === 1) {
                    return true;
                }
                else if(currentPlaylistBuilderStep !== 'choose-music' && library.ContentLibraryTypeId !== 1) {
                    return true;
                }
                return false;
            }
    		return true;
    	});
    }
}