import { DeliveryQueue } from '../../../shared/api-models/delivery';

export class DeliveryQueueView extends DeliveryQueue {
    forceToTopBtn: string;
    resetBtn: string;
    scheduleDate: string;
    phoneMAC: string;
    status: string;
    deleteBtn: string;
    rowBgColor: string;
    classList: string;
    playerId: number;
    clientName: string;
    locationName: string;
    playerModelType: string;
    playerModel: string;
    border: string;
}