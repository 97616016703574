import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChangeCategoryDropdownsComponent } from 'src/app/shared/components/content-browser/content-list-header/change-category-dropdowns/change-category-dropdowns.component';
import { ContentBrowserComponent } from 'src/app/shared/components/content-browser/content-browser.component';
import { ContentBrowserRoutingModule } from './content-browser-routing.module';
import { ContentListHeaderComponent } from 'src/app/shared/components/content-browser/content-list-header/content-list-header.component';
import { SharedModule } from '../../shared.module';

@NgModule({
  declarations: [
    ChangeCategoryDropdownsComponent,
    ContentBrowserComponent,
    ContentListHeaderComponent],
  imports: [
    CommonModule,
    ContentBrowserRoutingModule,
    SharedModule
  ],
  exports: [
    ChangeCategoryDropdownsComponent,
    ContentBrowserComponent,
    ContentListHeaderComponent
  ]
})
export class ContentBrowserModule { }
