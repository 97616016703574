import { ContentView } from './content/content-view';
import { FeatureArea } from './feature-area';

//Playlist Builder/Content Manager View State
export class ContentViewState {
	contentListVisible?: boolean;
	contentListCurrentPage?: number;
	contentSearchActive?: boolean;
	activeFeatureArea?: FeatureArea;

	//more than one modal can be active at a time, but only one visible
	//i.e. nav from Playlist Expanded modal to Schedule, when exiting
	//the schedule, you are returned to the Playlist Expanded modal
	activeModals?: string[];
	modalVisible?: boolean;
	searchTerm?: string;
	isAdvancedSchedule?: boolean;
	atLeastOneContentSelected?: boolean;
	layout?: 'list-view' | 'card-view';
	treeSelection?: 'featured-playlist' | 'favorites' | 'regular-content';
	contentView?: ContentView;
	hideDeleted?: boolean;
	voiceTalentId?: number;
	sortParams?: { orderByCol: string; orderDesc: boolean };

	constructor() {
		this.sortParams = { orderByCol: '', orderDesc: false };
	}
}

export enum TreeSelection {
	FEATURED_PLAYLIST = 'featured_playlist',
	FAVORITES = 'favorites',
	REGULAR_CONTENT = 'regular-content'
}

export enum ContentLayout {
	LIST = 'list',
	CARD = 'card'
}
