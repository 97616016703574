import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import uuid from 'uuid';

import { AppStateService, Events, MessageService, UtilitiesService } from '../../../core/services';
import { Clients, Contracts, Users } from '../../../shared/api-models/admin';
import { ClientContentLibraryBridge, ContentLibraries } from '../../../shared/api-models/content';
import { CreateEditClientService } from './create-edit-client.service';
import { environment } from '../../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class SaveService {
	private newClient: Clients;

	constructor(
		private appStateService: AppStateService,
		private createEditClientService: CreateEditClientService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private router: Router,
		private utilService: UtilitiesService
	) {}

	public saveNewClient(): void {
		this.messageService.publish(Events.savingPreloader, 1);
		delete this.createEditClientService.client.IndustryType;

		//Save new client
		this.httpClient
			.post(`${environment.adminUrl}CoreClients/`, this.createEditClientService.client)
			.pipe(
				mergeMap((newClient: Clients) => {
					this.newClient = newClient;
					this.createEditClientService.initialServiceLocation.ClientId = this.newClient.Id;
					const initialServiceLocation$ = this.httpClient.post(
						`${environment.adminUrl}CoreServiceLocations`,
						this.createEditClientService.initialServiceLocation
					);

					//Post client contracts
					const contractsPost$ = this.createEditClientService.contracts.map((contract) => {
						contract.ClientId = this.newClient.Id;
						const contractClone = JSON.parse(JSON.stringify(contract));
						//delete productTypeId when posting to CoreContracts, keep it for bridge post
						delete contractClone.productTypeId;
						return this.httpClient.post(`${environment.adminUrl}CoreContracts`, contractClone);
					});

					//Post only one library per contract product type
					const newLibrariesPost$ = this.uniqueContracts().map((contract) => {
						return this.httpClient.post(`${environment.contentUrl}ContentLibraries/Client/${newClient.Id}`, this.library(contract));
					});

					const observArr$ = [].concat.apply([], [initialServiceLocation$, contractsPost$, newLibrariesPost$]);

					return forkJoin(observArr$).pipe(
						mergeMap((res) => {
							const contracts: Contracts[] = res.slice(1, this.uniqueContracts().length + 1) as Contracts[];
							const newLibraries: ContentLibraries[] = res.slice(this.uniqueContracts().length * 2) as ContentLibraries[];

							//Contracts products bridge necessary for each contract
							const contractsProductsBridge$ = contracts.map((contract, index) => {
								const obj = {
									ContractId: contract.Id,
									ProductTypeId: this.createEditClientService.contracts[index].productTypeId
								};
								return this.httpClient.post(environment.adminUrl + `CoreContractsProductsBridge`, obj);
							});

							//Client content library bridge necessary for each library
							const clientContentLibraryBridge$ = newLibraries.map((library) => {
								return this.httpClient.post(`${environment.contentUrl}ClientContentLibraryBridge`, this.clientContentLibraryBridge(library));
							});

							const observArr2$ = [].concat.apply([], [contractsProductsBridge$, clientContentLibraryBridge$]);

							return forkJoin(observArr2$);
						})
					);
				})
			)
			.subscribe(() => {
				this.saveSuccess();
			});
	}

	public setShow2faQrCodeForAllUsers$(): Observable<any> {
		const getUsers$ = this.httpClient.get(`${environment.adminUrl}CoreUsers/GetUsersByClientId/${this.createEditClientService.unmodifiedClient.Id}`);

		return getUsers$.pipe(
			switchMap((users: Users[]) => {
				let payload: Partial<Users>;
				if (this.createEditClientService.client.Require2fa) {
					payload = { Show2faQRCode: true, SecurityKey: uuid.v4() };
				} else {
					payload = { Show2faQRCode: false };
				}
				const updateUserRequests$ = users
					.filter((user) => !user.IsDeleted)
					.map((user) => {
						return this.httpClient.patch(`${environment.adminUrl}CoreUsers/${user.Id}`, payload);
					});
				return forkJoin(updateUserRequests$);
			})
		);
	}

	public saveExistingClient(): Observable<any> {
		this.messageService.publish(Events.savingPreloader, 1);

		// Ensure 'UseAdvancedScheduling' is explicitly set
		!this.createEditClientService.client.UseAdvancedScheduling ? (this.createEditClientService.client.UseAdvancedScheduling = false) : null;

		return this.httpClient.put(`${environment.adminUrl}CoreClients/${this.createEditClientService.client.Id}`, this.createEditClientService.client).pipe(
			switchMap(() => {
				this.appStateService.setSessionItem('currentClient', JSON.stringify(this.createEditClientService.client));
				this.appStateService.currentClient = this.createEditClientService.client;

				if (this.createEditClientService.client.Require2fa !== this.createEditClientService.unmodifiedClient.Require2fa) {
					return this.setShow2faQrCodeForAllUsers$();
				} else {
					return of(null);
				}
			})
		);
	}

	public saveSuccess(): void {
		this.messageService.publish(Events.savingPreloader, 0);
		if (this.createEditClientService.mode === 'edit') {
			this.router.navigate(['/crm/client-details/locations']);
		} else if (this.createEditClientService.mode === 'new') {
			localStorage.setItem('changeClientUrl', `/crm/client-details/locations/`);
			window.open(`/my-products/${this.newClient.Id}`);
			location.reload();
		}
	}

	private clientContentLibraryBridge(library: ContentLibraries): ClientContentLibraryBridge {
		const bridge: ClientContentLibraryBridge = new ClientContentLibraryBridge();

		bridge.ClientId = this.newClient.Id;
		bridge.ContentLibraryId = library.Id;
		bridge.ReadAccess = true;
		bridge.WriteAccess = true;
		return bridge;
	}

	private uniqueContracts(): Contracts[] {
		const productTypeArr: number[] = [];

		return this.createEditClientService.contracts.filter((contract) => {
			const isDuplicate: boolean = this.utilService.includes(productTypeArr, contract.productTypeId as any);
			productTypeArr.push(contract.productTypeId);
			if (!isDuplicate) {
				return true;
			}
		});
	}

	private library(contract: Contracts): ContentLibraries {
		const library: ContentLibraries = new ContentLibraries();
		library.ContentLibraryTypeId = this.productTypeIdToContentLibraryTypeId(contract.productTypeId);
		library.LibraryName = `${this.newClient.Name} Custom ${this.productNoun(contract.productNoun)} Library`;
		library.ProductTypeId = contract.productTypeId;
		library.IsGlobal = false;
		library.AllowContentRequest = true;
		library.AllowCreate24 = true;
		library.CreateDate = new Date().toISOString();
		library.CreatedByUserId = this.appStateService.currentUser.UserId;
		library.UpdateDate = new Date().toISOString();
		library.IsDeleted = false;

		return library;
	}

	private productNoun(productNoun: string): string {
		return productNoun === 'Hold' ? 'Message' : productNoun;
	}

	private productTypeIdToContentLibraryTypeId(productTypeId: number): number {
		switch (productTypeId) {
			case 1: //on hold
				return 2;
			case 2: //works24 radio
				return 1;
			case 8: //other
				return null;
			default: //any video product
				return 3; //video-playable
		}
	}
}
