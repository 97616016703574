<portal-header></portal-header>

<div class="container-fluid mx-4 mgn-top30 relative">
	<div class="row mgn-bottom30">
		<div class="col-sm-9 pd-left0">
			<h1 class="semi-bold">
				<i class="far fa-bell mgn-right15" aria-hidden="true"></i>
				30/60/90 Reminder Emails
			</h1>
		</div>
	</div>

	<div class="row gray-bg-darker ht2 border-radius"></div>

	<ng-container *ngIf="reminderEmailsService.showLargeDateMonth()">
		<div class="row mgn-top30 center">
			<span class="ft-size30">Choose a month:</span>
		</div>
		<div class="row mgn-top15">
			<div class="col-sm-4 col-sm-offset-4">
				<portal-dropdown-menu
					[backgroundColor]="'gray-bg-dark'"
					[height]="'ht40'"
					[options]="reminderEmailsService.months"
					[key]="'name'"
					[selectedOption]="'Select a month...'"
					(onOptionSelect)="onDateSelect('month', $event)"
				></portal-dropdown-menu>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="!reminderEmailsService.showLargeDateMonth()">
		<div class="row mgn-top30">
			<div class="col-sm-2">
				<portal-dropdown-menu
					[backgroundColor]="'gray-bg-dark'"
					[height]="'ht40'"
					[options]="reminderEmailsService.months"
					[key]="'name'"
					[selectedOption]="reminderEmailsService.date.month?.name"
					(onOptionSelect)="onDateSelect('month', $event)"
				></portal-dropdown-menu>
			</div>
			<div class="col-sm-3 col-sm-offset-4">
				<button style="border: none" (click)="onButtonClick('Hold')" class="{{ product === 'Hold' ? 'blue-bg-darkest' : 'blue-bg' }} white-text ft-size28">
					<i class="fas fa-phone-volume mgn-right15"></i>
					Hold
					<i *ngIf="product === 'Hold'" class="fas fa-check absolute top25 right60 ft-size20"></i>
				</button>
			</div>
			<div class="col-sm-3">
				<button style="border: none" (click)="onButtonClick('Video')" class="{{ product === 'Video' ? 'red-bg-darkest' : 'red-bg' }} white-text ft-size28">
					<i class="fas fa-tv mgn-right15"></i>
					Video
					<i *ngIf="product === 'Video'" class="fas fa-check absolute top25 right60 ft-size20"></i>
				</button>
			</div>
		</div>
		<div *ngIf="mailers" class="row mgn-top60 mgn-bottom60">
			<div class="col-sm-3">
				<h3 class="mgn-bottom15 semi-bold">Email Image</h3>

				<div class="relative">
					<img
						*ngIf="!uploadInProgress"
						onError="this.src='assets/no-image.jpg';"
						class="box-shadow border-radius mgn-bottom30"
						src="{{ imageUrl }}"
						width="100%"
					/>
					<portal-file-upload-drop-target
						[activeUploadSequence]="'reminderEmailsImage'"
						[dropTargetHeight]="'ht100'"
						[fontSize]="'ft-size16'"
						[paddingTop]="'pd-top24'"
					></portal-file-upload-drop-target>
				</div>
			</div>
			<div class="col-sm-3">
				<h3 class="mgn-bottom15 semi-bold in-block mgn-bottom15">30 Day</h3>
				|
				<em class="ft-size18">{{ product === 'Hold' ? 'Hold' : 'Video' }}</em>
				<br />
				<em>Subject:</em>
				<input
					class="mgn-bottom15"
					#thirtysub
					(input)="onChange(30, 'TemplateSubject', thirtysub.value)"
					[(ngModel)]="product === 'Hold' ? mailers[0].TemplateSubject : mailers[3].TemplateSubject"
					type="text"
				/>

				<em>Body:</em>
				<textarea
					class="ft-size16 scrollable"
					#thirty
					(input)="onChange(30, 'TemplateBody', thirty.value)"
					[(ngModel)]="product === 'Hold' ? mailers[0].TemplateBody : mailers[3].TemplateBody"
					rows="14"
				></textarea>
			</div>
			<div class="col-sm-3">
				<h3 class="mgn-bottom15 semi-bold in-block mgn-bottom15">60 Day</h3>
				|
				<em class="ft-size18">{{ product === 'Hold' ? 'Hold' : 'Video' }}</em>
				<br />
				<em>Subject:</em>
				<input
					class="mgn-bottom15"
					#sixtysub
					(input)="onChange(60, 'TemplateSubject', sixtysub.value)"
					[(ngModel)]="product === 'Hold' ? mailers[1].TemplateSubject : mailers[4].TemplateSubject"
					type="text"
				/>

				<em>Body:</em>
				<textarea
					class="ft-size16 scrollable"
					#sixty
					(input)="onChange(60, 'TemplateBody', sixty.value)"
					[(ngModel)]="product === 'Hold' ? mailers[1].TemplateBody : mailers[4].TemplateBody"
					rows="14"
				></textarea>
			</div>
			<div class="col-sm-3">
				<h3 class="mgn-bottom15 semi-bold in-block mgn-bottom15">90 Day</h3>
				|
				<em class="ft-size18">{{ product === 'Hold' ? 'Hold' : 'Video' }}</em>
				<br />
				<em>Subject:</em>
				<input
					class="mgn-bottom15"
					#ninetysub
					(input)="onChange(90, 'TemplateSubject', ninetysub.value)"
					[(ngModel)]="product === 'Hold' ? mailers[2].TemplateSubject : mailers[5].TemplateSubject"
					type="text"
				/>

				<em>Body:</em>
				<textarea
					class="ft-size16 scrollable"
					#ninety
					(input)="onChange(90, 'TemplateBody', ninety.value)"
					[(ngModel)]="product === 'Hold' ? mailers[2].TemplateBody : mailers[5].TemplateBody"
					rows="14"
				></textarea>
			</div>
		</div>
		<div *ngIf="mailers" class="row mgn-top60">
			<div class="col-xs-3 col-xs-offset-9">
				<i
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					data-tippy-content='Test email will be sent to Works24 Office Account users with "Emails" enabled. Must confirm before sending.'
					[tippyProps]="{ appendTo: 'parent' }"
					class="far fa-question-circle"
				></i>
				<button class="yellow-bg" (click)="onSendTestEmailClick()">
					<i class="far fa-envelope mgn-right15"></i>
					Send test email
				</button>
			</div>
		</div>
	</ng-container>
</div>
