import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';

import { AppStateService } from '../app-state.service';

export const videoProductsOnlyGuard: CanActivateFn = () => {
	const appStateService = inject(AppStateService);
	const router = inject(Router);

	// Retrieve session item for video products
	const atLeastOneVideoProduct = appStateService.getSessionItem('atLeastOneVideoProduct');

	if (atLeastOneVideoProduct) {
		if (JSON.parse(atLeastOneVideoProduct)) {
			return true;
		} else {
			router.navigate(['/access-denied']);
			return false;
		}
	} else {
		router.navigate(['/my-products']);
		return false;
	}
};
