import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Clients } from '../../../shared/api-models/admin';
import { CreateEditPlayerService } from '../_services/create-edit-player.service';
import { environment } from '../../../../environments/environment';
import { PlayersView } from '../_models/players-view';

@Injectable({
    providedIn: 'root'
})
export class MacValidationService {

    public client: Clients; //Client to whom mac address belongs if it is not unique
    public isMacAddressUnique: boolean;
    public player: PlayersView;

    constructor(
        private createEditPlayerService: CreateEditPlayerService,
        private httpClient: HttpClient
    ) { }

    public onClientNameClick(): void {
        localStorage.setItem('changeClientUrl', `/crm/client-details/locations/`);
        window.open(`/my-products/${this.player.ClientId}`);
    }

    public onMacAddressChange(value: string): void {
        this.createEditPlayerService.player.MacAddress = value;
        this.checkMacAddressLength(value);
    }

    private checkMacAddressLength(value: string): void {
        if (this.createEditPlayerService.player.MacAddress.length === 12) {
            this.validate(value);
        }

        if (this.createEditPlayerService.player.MacAddress.length < 12 || this.createEditPlayerService.player.MacAddress.length > 12) {
            this.isMacAddressUnique = false;
        }
    }

    private getClient(): void {
        this.httpClient.get(`${environment.adminUrl}CoreClients/${this.player.ClientId}`)
            .subscribe((client: Clients) => {
                this.client = client;
            })
    }

    private resetPlayerAndClient(): void {
        this.client = null;
        this.player = null;
    }

    private validate(macAddress: string): void {
        this.httpClient.get(`${environment.adminUrl}CorePlayers/ByMac/${macAddress}`)
            .subscribe((response: PlayersView[] | any[]) => {
                if (response.length < 1) {
                    this.isMacAddressUnique = true;
                    this.resetPlayerAndClient();
                    return;
                }
                this.isMacAddressUnique = false;
                this.player = response[0];
                this.getClient();
            })
    }
}