export class MailerTemplates {
    Id: number;
    Name: string;
    HtmlTemplate: string;
    CsrBlastCsrId: number;
    Description: string;

    editBtn: string;
    templatesDeleteBtn: string;
    classList: string;
}