export class Contracts {
	Id: number;
	ClientId: number;
	ContractTypeId: number;
	IsActive: boolean;
	ContractTermId: number;
	SalespersonId: number;
	SaleDate: string;
	RenewDate: string;
	TotalValue: number;
	MainLocMonthly: number;
	DupLocMonthly: number;
	NumLocations: number;
	Notes: string;
	IsEarlyCancel: boolean;
	EarlyCancelDate: string;
	EarlyCancelReason: string;
	IsExemptFromStats: boolean;
	ExemptFromStatsReason: string;
	IsFulfilledContractCancellation: boolean;
	FulfilledContractCancellationReason: boolean;
	IsRenewed: boolean;

	bgColor: string;
	term: string;
	textColor: string;
	earlyCancelDateView: string;
	renewDateView: string;
	saleDateView: string;
	salespersonName: string;
	productTypeId: number;
	productType: string;
	viewState: 'readMode' | 'editMode';
	tempId: number;
	name: string;
	productNoun: string;
	isValid: boolean;
	isSelected: boolean;
}