import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AdminDataService, AppStateService, UtilitiesService } from '../../core/services';
import { Contracts, ProductTypes } from '../../shared/api-models/admin';
import { ContractsService } from './contracts.service';

@Component({
	selector: 'app-contracts',
	templateUrl: './contracts.component.html',
	styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {

	public contracts: Contracts[];
	public productTypes$: Observable<ProductTypes[]> = this.adminDataService.getProductTypes$();
	public selectedProductType: ProductTypes;

	constructor(
		private adminDataService: AdminDataService,
		private appStateService: AppStateService,
		private contractsService: ContractsService,
		public utilService: UtilitiesService) { }

	ngOnInit() {
		this.getContractsForClient();
	}

	onContractDelete(contractId?: number): void {
		let index;
		if (contractId) {
			index = this.contracts.findIndex(contract => contract.Id === contractId);
		} else {
			index = this.contracts.findIndex(contract => !contract.Id)
		}
		this.contracts.splice(index, 1);
	}

	onDropdownSelect(productType: ProductTypes): void {
		this.selectedProductType = productType;
	}

	public getContractsForClient(): void {
		this.contractsService.getContractsForClient()
			.subscribe((contracts: Contracts[]) => {
				this.contracts = contracts;
			})
	}

	public onCreateNewContractClick(): void {
		this.contracts.unshift(this.contract());
	}

	private contract(): Contracts {
		let contract: Contracts = new Contracts();

		contract.viewState = 'editMode';
		contract.ContractTypeId = this.contractsService.productTypeIdToContractTypeId(this.selectedProductType.Id);
		contract.bgColor = this.contractsService.setBgColor(this.selectedProductType.Id);
		contract.ClientId = this.appStateService.currentClient.Id;
		contract.IsActive = true;
		contract.IsEarlyCancel = false;
		contract.IsExemptFromStats = false;
		contract.IsFulfilledContractCancellation = false;
		contract.IsRenewed = false;
		contract.DupLocMonthly = 0;
		contract.productTypeId = this.selectedProductType.Id;
		return contract;
	}
}
