export class ContentRequestClipAttachments {
    Id: number;
	ContentRequestClipId: number;
	FileId: string;
	FriendlyName: string;

	constructor(ContentRequestClipId: number, FriendlyName: string) {
		this.ContentRequestClipId = ContentRequestClipId;
		this.FriendlyName = FriendlyName;
	}
}