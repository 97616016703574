import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppStateService } from 'src/app/core/services';
import { Create24Service } from './create-24.service';
import { ContentViewStateService } from 'src/app/shared/services';
import { ContentBrowserService } from 'src/app/shared/components/content-browser/content-browser.service';
import { LayersService } from './main-editor/_services';
import { Create24Area } from './_models/create-24-state.enum';

@Component({
	selector: 'app-create24',
	templateUrl: './create-24.component.html',
	styleUrls: ['./create-24.component.scss']
})
export class Create24Component implements OnInit, OnDestroy {
	public contentType: string;
	public titleAndIcon: any;

	private subs: Subscription[] = [];

	constructor(
		private activatedRoute: ActivatedRoute,
		public appStateService: AppStateService,
		public create24Service: Create24Service,
		private contentBrowserService: ContentBrowserService,
		public cvStateService: ContentViewStateService,
		private layersService: LayersService,
		private router: Router
	) {
		this.onChangeContentClick();
	}

	ngOnInit(): void {
		this.contentType = this.activatedRoute.snapshot.paramMap.get('contentType');
		this.titleAndIcon = this.setTitleAndIcon();
	}

	canDeactivate(): boolean {
		//If session has expired, allow log out and navigate to login page
		let authToken: string = sessionStorage.getItem('authToken');
		if (!authToken) {
			return true;
		}
		if (!this.create24Service.popup) {
			if (this.layersService.layers.length > 1) {
				let confirmOk = confirm(`Leave this page? Your changes will not be saved.`);
				if (confirmOk) {
					return true;
				}
				return false;
			}
			return true;
		}
		return true;
	}

	onChangeContentClick(): void {
		this.subs.push(
			this.layersService.changeContentClicked$.subscribe((c24ActiveArea: Create24Area) => {
				this.create24Service.popup = 'content-browser';
				this.contentBrowserService.isPopup = true;
				this.router.navigate([{ outlets: { popup: [c24ActiveArea] } }], { relativeTo: this.activatedRoute });
			})
		);
	}

	public setTitleAndIcon(): any {
		switch (true) {
			case this.contentType.includes('image'):
				return { title: 'Still Image', icon: 'far fa-image' };

			case this.contentType.includes('video'):
				return { title: 'Video', icon: 'fas fa-tv' };

			case this.contentType.includes('sidebar'):
			case this.create24Service.c24ActiveArea === Create24Area.NEW_SIDEBAR:
				return { title: 'Sidebar', icon: 'fas fa-columns' };
		}
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
		this.create24Service.popup = '';
	}
}
