export class PlayerLayoutThemes {
	Id: number;
	Name: string;
	IsDeleted: boolean;
	ClientId: number;
	IsGlobal: boolean;
	BackgroundContentFileId: number;
	Css: string;
	WeatherIconSet: number;
	Category: string;

	thumbnailUrl: string;
	previewUrl: string;
}
