<div class="container-fluid white-bg border-radius pd15">
    <ng-container *ngIf="errorLogs">
        <div class="row mgn-bottom30">
            <div class="col-sm-8">
                <h2 class="semi-bold"><i class="fas fa-exclamation-triangle mgn-right10 red-text"></i>Error Logs | <em class="regular">{{player.PlayerName}} - {{player.UnitIdentifier}}</em></h2>
                <span class="ft-size18">{{errorLogs[0].ErrorSource}}</span>
            </div>
            <div class="col-sm-4">
                <a class="ft-size26 dark-text absolute right15 top0" (click)="onExitClick.emit()"><i class="fa fa-times" aria-hidden="true"></i></a>
            </div>
        </div>
        <portal-table 
            [table]="tableService.table"
            [config]="tableService.config"
            [data]="errorLogs">
        </portal-table>
   
    </ng-container>
    <ng-container *ngIf="!errorLogs">
        <div class="row">
            <div class="col-sm-8">
                <em class="ft-size20">No error logs available for this player.</em>
            </div>
            <div class="col-sm-4">
                <a class="ft-size26 dark-text absolute right15 topneg4" (click)="onExitClick.emit()"><i class="fa fa-times" aria-hidden="true"></i></a>
            </div>
        </div>
    </ng-container>
</div>

