import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { authGuard } from '../core/services/route-guards';
import { GlobalSettingsComponent } from './global-settings.component';

const routes: Routes = [
	{
		path: '',
		canActivate: [authGuard],
		component: GlobalSettingsComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class GlobalSettingsRoutingModule {}
