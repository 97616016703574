export class PlayerModels {
	DefaultPlayerSettings: string;
	Id: number;
	MaxDurationSeconds: number;
	MemoryCapacity: number;
	Name: string;
	PlayerModelTypeId: number;
	UnitPrefix: string;
	CurrentSoftwareVersion: string;

	PlayerModelType: {Id: number, Name: string};
	name: string;
}