import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ClientErrorLog } from 'src/app/shared/api-models/log';
import { PlayersView } from 'src/app/crm/create-edit-player/_models';
import { TableService } from './table.service';

@Component({
  selector: 'ld-error-log-modal',
  templateUrl: './error-log-modal.component.html',
  styleUrls: ['./error-log-modal.component.scss']
})
export class ErrorLogModalComponent {

  @Input() errorLogs: ClientErrorLog[];
  @Input() player: PlayersView;

  @Output() onExitClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public tableService: TableService
  ) { }
}
