<div class="row">
	<!--************************
            INSTRUCTIONS TEXT
        *************************-->
	<div *ngIf="!cvStateService.viewState.contentListVisible" class="col-md-12">
		<span *ngIf="!utilService.activeFeatureIsC24() || !cvStateService.showSelectC24BackgroundInstructions()" class="ft-size18 regular">
			{{ instructions }}
			<a
				*ngIf="appStateService.product.Route === 'hold'"
				class="regular blue-text ft-size14"
				target="_blank"
				href="https://help.works24.com/knowledge-base/tutorial-managing-on-hold-content/"
			>
				Tutorial: Managing On Hold Content
				<i class="fas fa-video blue-text mgn-left6"></i>
			</a>
		</span>

		<ng-container *ngIf="utilService.activeFeatureIsC24() && cvStateService.showSelectC24BackgroundInstructions()">
			<h2 class="semi-bold mgn-bottom15">{{ cvStateService.startWithBlankCanvas() ? 'Three' : 'Two' }} Ways To Select A Background</h2>
			<ul>
				<li class="ft-size20">Upload a new {{ create24Service.contentType() }}, then open the library and select the {{ create24Service.contentType() }}</li>
				<li class="ft-size20">Choose an existing {{ create24Service.contentType() }} from your libraries</li>
				<li *ngIf="cvStateService.startWithBlankCanvas()" class="ft-size20">Start with a blank canvas</li>
			</ul>
		</ng-container>
	</div>

	<div>
		<div
			*ngIf="cvStateService.contentList?.length > 0 && batchChangeCategoryService.atLeastOneContentSelected && cvStateService.viewState.contentListVisible"
			class="col-sm-10 pd-left0"
		>
			<clh-change-category-dropdowns></clh-change-category-dropdowns>
		</div>

		<!--***************************************
                SEARCH RESULTS TEXT & CLEAR BUTTON
            ****************************************-->
		<div *ngIf="cvStateService.viewState.contentSearchActive" class="col-md-12 pd-left0 mgn-bottom10">
			<div class="row">
				<div *ngIf="cvStateService.contentList?.length > 0" class="col-md-3">
					<h3>
						<i class="fa fa-search mgn-right10" aria-hidden="true"></i>
						Search Results
					</h3>
				</div>
				<div *ngIf="cvStateService.contentList?.length > 0" class="col-md-2">
					<button (click)="cvStateService.onSearchClearClick()" class="white-bg">
						<i class="fa fa-times mgn-right10" aria-hidden="true"></i>
						Clear
					</button>
				</div>
				<div
					*ngIf="appStateService.product.Route === 'hold'"
					[ngClass]="cvStateService.contentList?.length > 0 ? 'col-md-offset-2' : 'col-md-offset-9'"
					class="col-md-3 pd-right0"
				>
					<span>Filter by voice talent</span>
					<portal-dropdown-menu
						*ngIf="fileUploadModalService.voiceTalentList?.length > 0"
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="fileUploadModalService.voiceTalentList"
						[key]="'TalentName'"
						[scrollbarHidden]="false"
						[selectedOption]="'All'"
						(onOptionSelect)="chooseContentService.onVoiceTalentSelect($event)"
					></portal-dropdown-menu>
				</div>
				<div
					*ngIf="cvStateService.contentList?.length > 0 && !batchChangeCategoryService.atLeastOneContentSelected"
					[ngClass]="appStateService.product.Route === 'hold' ? 'col-md-offset-1' : 'col-md-offset-6'"
					class="col-md-1 hidden-xs hidden-sm text-right"
				>
					<a
						(click)="onDeletedContentToggleClick('off')"
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ appendTo: 'parent' }"
						data-tippy-content="Hide deleted"
						[tippyProps]="{ appendTo: 'parent', arrow: false }"
					>
						<i
							class="{{ utilService.playerIcon() }} ft-size16 mgn-right10"
							[ngClass]="cvStateService.viewState.hideDeleted ? 'dark-text' : 'gray-text-dark'"
							aria-hidden="true"
						></i>
					</a>
					<a
						(click)="onDeletedContentToggleClick('on')"
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ appendTo: 'parent' }"
						data-tippy-content="Show deleted"
						[tippyProps]="{ appendTo: 'parent', arrow: false }"
					>
						<i class="fas fa-trash-alt ft-size16" [ngClass]="!cvStateService.viewState.hideDeleted ? 'dark-text' : 'gray-text-dark'" aria-hidden="true"></i>
					</a>
				</div>
			</div>
		</div>

		<!--*************
                TIP
            **************-->
		<div *ngIf="tipVisible()" class="col-md-8 pd-left0">
			<span class="ft-size16">Tip: To change an item's category, simply drag it onto a new category at the left.</span>
			<br />
			<a
				*ngIf="appStateService.product.Route === 'hold'"
				class="regular blue-text"
				target="_blank"
				href="https://help.works24.com/knowledge-base/tutorial-managing-on-hold-content/"
			>
				Tutorial: Managing On Hold Content
				<i class="fas fa-video blue-text mgn-left6"></i>
			</a>
		</div>

		<div
			*ngIf="
				appStateService.product.Route === 'hold' &&
				cvStateService.viewState.contentListVisible &&
				!cvStateService.viewState.contentSearchActive &&
				!batchChangeCategoryService.atLeastOneContentSelected
			"
			class="col-md-3 pd-right0"
		>
			<span>Filter by voice talent</span>
			<portal-dropdown-menu
				*ngIf="fileUploadModalService.voiceTalentList?.length > 0"
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[options]="fileUploadModalService.voiceTalentList"
				[key]="'TalentName'"
				[scrollbarHidden]="false"
				[selectedOption]="selectedVoiceTalent"
				(onOptionSelect)="chooseContentService.onVoiceTalentSelect($event)"
			></portal-dropdown-menu>
		</div>
		<div
			*ngIf="cvStateService.viewState.contentListVisible && !clearSearchResultsBtnVisible() && !batchChangeCategoryService.atLeastOneContentSelected"
			[ngClass]="appStateService.product.Route === 'hold' ? '' : 'col-md-offset-3'"
			class="col-md-1 hidden-xs hidden-sm text-right"
		>
			<a
				(click)="onDeletedContentToggleClick('off')"
				ngxTippy
				tippyName="{{ utilService.guid | wrapFn }}"
				data-tippy-content="Hide deleted"
				[tippyProps]="{ appendTo: 'parent', arrow: false }"
			>
				<i
					class="{{ utilService.playerIcon() }} ft-size16 mgn-right10"
					[ngClass]="cvStateService.viewState.hideDeleted ? 'dark-text' : 'gray-text-dark'"
					aria-hidden="true"
				></i>
			</a>
			<a
				(click)="onDeletedContentToggleClick('on')"
				ngxTippy
				tippyName="{{ utilService.guid | wrapFn }}"
				data-tippy-content="Show deleted"
				[tippyProps]="{ appendTo: 'parent', arrow: false }"
			>
				<i class="fas fa-trash-alt ft-size16" [ngClass]="!cvStateService.viewState.hideDeleted ? 'dark-text' : 'gray-text-dark'" aria-hidden="true"></i>
			</a>
		</div>
	</div>
</div>

<!--*********************
        BREADCRUMBS
    **********************-->
<ng-container *ngIf="cvStateService.viewState.contentListVisible && cvStateService.contentList?.length > 0 && !clearSearchResultsBtnVisible()">
	<div class="row mgn-top30">
		<div class="col-md-10 pd-left0">
			<em class="semi-bold">{{ cvStateService.breadcrumbs }}</em>
		</div>
		<!--****************************
            SELECT & DELETE ALL
        *****************************-->
		<div class="col-md-2">
			<div *ngIf="contentDeletable" class="row">
				<div class="col-md-6">
					<button
						(click)="batchDeleteService.onDeleteSelectedClick(cvStateService.contentList, 'content', 'Content')"
						class="red-bg-light white-text relative"
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ appendTo: 'parent' }"
						data-tippy-content="Delete selected"
					>
						<i class="far fa-trash-alt ft-size18" aria-hidden="true"></i>
					</button>
				</div>
				<div class="col-md-6 text-right">
					<portal-checkbox
						[backgroundColor]="'white-bg'"
						[isChecked]="batchChangeCategoryService.selectAllChecked"
						[type]="'checkbox'"
						(checkboxClickNotify)="batchChangeCategoryService.onSelectAllClick()"
						(checkboxClickNotify)="batchDeleteService.onSelectAllClick(cvStateService.contentList)"
					></portal-checkbox>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<div *ngIf="cvStateService.viewState.contentListVisible && cvStateService.contentList?.length > 0" class="row">
	<div class="col-md-10">
		<div class="row">
			<div
				[ngClass]="{
					'col-md-offset-5 col-md-5': appStateService.product.Route !== 'hold',
					'col-md-offset-4 col-md-6': appStateService.product.Route === 'hold'
				}"
			>
				<h4 (click)="onSortClick('Title')" class="semi-bold cursor-pointer inline-block">Title</h4>
				<i *ngIf="!titleDesc && cvStateService.viewState.sortParams.orderByCol === 'Title'" class="fas fa-caret-up ml-2"></i>
				<i *ngIf="titleDesc && cvStateService.viewState.sortParams.orderByCol === 'Title'" class="fas fa-caret-down ml-2"></i>
			</div>
			<div *ngIf="showDateColumnHeader" class="col-md-2">
				<h4 (click)="onSortClick('CreateDate')" class="semi-bold cursor-pointer inline-block">Date</h4>
				<i *ngIf="!dateDesc && cvStateService.viewState.sortParams.orderByCol === 'CreateDate'" class="fas fa-caret-up ml-2"></i>
				<i *ngIf="dateDesc && cvStateService.viewState.sortParams.orderByCol === 'CreateDate'" class="fas fa-caret-down ml-2"></i>
			</div>
		</div>
	</div>
</div>
