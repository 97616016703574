import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppStateService, SecurityService, UtilitiesService } from '../../../../core/services';
import { CategoriesTree, LibrariesTree } from '../_models';
import { Content, ContentLibraries } from '../../../api-models/content';
import { environment } from '../../../../../environments/environment';
import { FeatureArea } from '../../../../shared/view-models';
import { PlaylistsExamplesCompletePlaylist } from 'src/app/shared/view-models/content';
import { Create24Area } from 'src/app/user-tools/create-24/_models/create-24-state.enum';
import { Create24Service } from 'src/app/user-tools/create-24/create-24.service';
import { ContentVM } from '../../content-container/content/_models/content-view';

@Injectable({
	providedIn: 'root'
})
export class LibrariesService {
	public clickedLibraryId: number;
	public contentFavorites: ContentVM[];
	public hoveredName: string;
	public libraries: LibrariesTree[];
	public favoriteLibraries: LibrariesTree[] = [];
	public favoritesExist: boolean;
	public favoritesIsExpanded: boolean;
	public selectedLibrary: LibrariesTree | CategoriesTree;
	public selectedFeaturedPlaylist: PlaylistsExamplesCompletePlaylist;

	constructor(
		private appStateService: AppStateService,
		private create24Service: Create24Service,
		private httpClient: HttpClient,
		private securityService: SecurityService,
		private utilService: UtilitiesService
	) {}

	public getLibraries(productId: number): Observable<LibrariesTree[]> {
		if (this.appStateService.activeFeatureArea === FeatureArea.CUSTOM_DATE_FEEDS) {
			productId = 3;
		}
		return forkJoin(this.createObservableGETRequests$(productId)).pipe(
			map((response: any[]) => {
				let libraries = Array.isArray(response[0]) ? response[0] : response;
				const { activeFeatureArea } = this.appStateService;
				this.contentFavorites = response[1];

				libraries = this.libraryTreeFilter(libraries, activeFeatureArea).map((library) => {
					//check if library contains at least one favorite content item
					library.containsFavorites = this.contentFavorites?.some((favorite) => favorite.LibraryId === library.Id);
					library.name = library.LibraryName;
					library.isLibrary = true;
					library.isGlobal = library.IsGlobal;
					library.writeAccess = library.WriteAccess;
					return library;
				});

				if (activeFeatureArea === FeatureArea.CONTENT_MANAGER) {
					return this.filterForContentManager(libraries);
				}
				if (this.appStateService.activeFeatureArea === FeatureArea.CREATE_24 && this.create24Service.c24ActiveArea === Create24Area.EDIT_PROJECT) {
					return this.utilService.sortItems(
						libraries.filter((lib) => lib.WriteAccess),
						'name'
					);
				}
				this.assignFavoriteLibraries(libraries);

				//show favorites library if favorites exist, hide it if they don't
				this.favoritesExist = libraries.some((library) => library.containsFavorites);
				return this.utilService.sortItems(libraries, 'name');
			})
		);
	}

	private filterForContentManager(libraries): any {
		const libs = this.securityService.filterLibraries(libraries);
		if (libs?.length > 0) {
			return this.utilService.sortItems(libs, 'name');
		} else {
			return [];
		}
	}

	private createObservableGETRequests$(productId: number): any[] {
		const clientId: number = this.appStateService.currentClient.Id;
		const userId: number = this.appStateService.currentUser.UserId;

		//If user is employee, returns all libraries regardless of userId
		const pbCmLibraries$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${productId}&clientId=${clientId}&userId=${userId}`
		);
		const contentFavorites$: Observable<Content[]> = this.httpClient.get<Content[]>(`${environment.contentUrl}Content/Favorites/${clientId}`);
		const c24PlacedImages$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=8&clientId=${clientId}&userId=${userId}&contentLibraryTypeId=7&allowCreate24=true`
		);
		const c24CustomAndGlobalVideo$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${productId}&clientId=${clientId}&userId=${userId}&contentLibraryTypeId=6&allowCreate24=true`
		);
		const c24CustomAndGlobalStill$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${productId}&clientId=${clientId}&userId=${userId}&contentLibraryTypeId=5&allowCreate24=true`
		);
		const c24VideoTemplates$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/${productId === 4 ? 1001771 : 1000717}`
		);
		const c24StillTemplates$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/${productId === 4 ? 1001772 : 1001400}`
		);
		const c24SidebarTemplates$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/${1001642}`
		);
		const c24ClientCustomLibrary$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/AvailableLibrariesMulti?productId=${productId}&clientId=${clientId}&userId=${userId}&libTypeIds=3&libTypeIds=12&allowCreate24=true`
		);
		const sidebarBg$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${productId}&clientId=${clientId}&userId=${userId}&contentLibraryTypeId=13&allowCreate24=true`
		);
		const sidebar$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${productId}&clientId=${clientId}&userId=${userId}&contentLibraryTypeId=12&allowCreate24=true`
		);

		if (this.appStateService.activeFeatureArea === FeatureArea.CUSTOM_DATE_FEEDS) {
			return [sidebar$];
		}
		switch (this.create24Service.c24ActiveArea) {
			case Create24Area.NEW_VIDEO:
			case Create24Area.NEW_VIDEO_REPLACE:
				return [c24CustomAndGlobalVideo$];
			case Create24Area.NEW_IMAGE:
			case Create24Area.NEW_IMAGE_REPLACE:
				return [c24CustomAndGlobalStill$];
			case Create24Area.IMAGE_LAYER_CREATE:
			case Create24Area.IMAGE_LAYER_REPLACE:
				return [c24PlacedImages$];
			case Create24Area.VIDEO_TEMPLATE:
				return [c24VideoTemplates$];
			case Create24Area.STILL_TEMPLATE:
				return [c24StillTemplates$];
			case Create24Area.SIDEBAR_TEMPLATE:
				return [c24SidebarTemplates$];
			case Create24Area.EDIT_PROJECT:
				return [c24ClientCustomLibrary$];
			case Create24Area.NEW_SIDEBAR:
			case Create24Area.NEW_SIDEBAR_REPLACE:
			case Create24Area.SIDEBAR_REPLACE:
				return [sidebarBg$];
			case Create24Area.FEED_ITEM:
				return [sidebar$];
			default:
				return [pbCmLibraries$, contentFavorites$];
		}
	}

	public assignFavoriteLibraries(libraries: LibrariesTree[]): void {
		const favoriteLibraries: LibrariesTree[] = libraries.filter((library) => library.containsFavorites);
		this.favoriteLibraries = [];
		if (favoriteLibraries && favoriteLibraries.length > 0) {
			this.favoriteLibraries = this.utilService.sortItems(JSON.parse(JSON.stringify(favoriteLibraries)), 'name');
		}
	}

	public isPlayerCompatible(library: LibrariesTree): boolean {
		if (this.utilService.includes(this.appStateService.product.Route, 'web')) {
			return !library.HideFromWebPlayer;
		}
		return true;
	}

	private libraryTreeFilter(libraries: LibrariesTree[], activeFeatureArea: FeatureArea): LibrariesTree[] {
		return libraries.filter((library) => {
			if (activeFeatureArea === FeatureArea.PLAYLIST_BUILDER) {
				//Filter logo, layout and widget libraries from playlist builder
				return (
					library.ContentLibraryTypeId !== 10 &&
					library.ContentLibraryTypeId !== 11 &&
					library.ContentLibraryTypeId !== 12 &&
					//Exclude dynamic libraries from web players
					this.isPlayerCompatible(library) &&
					this.utilService.displaysInPortal(library)
				);
			}
			if (activeFeatureArea === FeatureArea.CREATE_24 || activeFeatureArea === FeatureArea.CUSTOM_DATE_FEEDS) {
				return library.AllowCreate24;
			}
			if (this.securityService.userSeesAllLibraries()) {
				return true;
			}
			return this.utilService.displaysInPortal(library);
		});
	}

	public closeLibraryAndCategoryFolders(libraries: LibrariesTree[]): void {
		libraries.forEach((library) => {
			library.isExpanded = false;
			library.categories?.forEach((category) => {
				category.isExpanded = false;
			});
			library.categories?.forEach((cat) => cat.InverseParentCategory?.forEach((sub) => (sub.isExpanded = false)));
		});
	}

	public featuredPlaylistsBgColor(showFeaturedPlaylists: boolean): string {
		if (showFeaturedPlaylists) {
			return this.appStateService.product.Route === 'hold' ? 'blue-bg-darker' : 'red-bg-darker';
		}
		return this.appStateService.product.Route === 'hold' ? 'blue-bg' : 'red-bg';
	}
}
