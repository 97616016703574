import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ServiceLocations } from '../api-models/admin/service-locations';

@Injectable()
export class LocationsService {
	public cachePlayersPerLocation: boolean;
	public selectedLocation: ServiceLocations;
	public serviceLocationsData: ServiceLocations[];
	public totalServiceLocations: number;

	constructor(private httpClient: HttpClient) {}

	/*****************************************
     GET DATA
   *****************************************/

	public getAllServiceLocationsForClient(currentClientId: number, refreshData: boolean): Observable<ServiceLocations[]> {
		if (this.serviceLocationsData && !refreshData) {
			return of(this.serviceLocationsData);
		}
		return this.httpClient.get(environment.adminUrl + `CoreServiceLocations/Client/${currentClientId}`).pipe(
			map((response: any) => {
				this.totalServiceLocations = this.serviceLocationsViewArr(response).length;
				return this.serviceLocationsViewArr(response);
			})
		);
	}

	public getServiceLocation(locationId: number): Observable<ServiceLocations> {
		if (this.serviceLocationsData) {
			let selectedLocation: ServiceLocations = this.serviceLocationsData.find((location) => location.Id === locationId);
			if (selectedLocation) {
				return of(selectedLocation);
			}
		}
		return this.httpClient.get(environment.adminUrl + `CoreServiceLocations/${locationId}`).pipe(map((res) => this.serviceLocationsView(res[0])));
	}

	public getPlayersPerServiceLocation(locationId: number, refreshData: boolean): Observable<any> {
		return this.httpClient.get(environment.adminUrl + `CoreServiceLocations/${locationId}/Players`);
	}

	private serviceLocationsViewArr(serviceLocationsApiArr: ServiceLocations[]): ServiceLocations[] {
		return serviceLocationsApiArr.map((serviceLocationsApi) => {
			return this.serviceLocationsView(serviceLocationsApi);
		});
	}

	public serviceLocationsView(serviceLocation: ServiceLocations): ServiceLocations {
		serviceLocation['delete'] = 'Delete';
		serviceLocation.hold = { icon: 'fa-phone-volume' };
		serviceLocation.radio = { icon: 'fa-volume-up' };
		serviceLocation['open'] = 'Open';
		serviceLocation['player'] = 'My player';
		serviceLocation.poster = { icon: 'fa-square' };
		serviceLocation.video = { icon: 'fa-tv' };
		serviceLocation['border'] = 'gray-border';
		return serviceLocation;
	}
}
