export class GumballPlayers {
	PlayerId: number;
	ClientId: number;
	ProductTypeId: number;
	ModelId: number;
	ClientName: string;
	ProductTypeName: string;
	ModelName: string;
	LastCheckin: string;
	LastPlaylistUpdate: string;
	MacAddress: string;
	ServiceLocationName: string;
	PlayerName: string;
	Hostname: string;
	PlayerUnitIdentifier: string;
	InstallState: string;
	RepairState: string;
	CurrentlyPlayingProductionName: string;
	ReportedPlayerVersion: string;
	CurrentReleasePlayerVersion: string;
	GumballColor: string;
	RuleFailureList: string;
}
