import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Contracts } from '../../../shared/api-models/admin';
import { ContractTermsService, Events, MessageService } from '../../../core/services';

type NameAndId = { Name: string; Id: number };

@Component({
	selector: 'cec-contract',
	templateUrl: './contract.component.html',
	styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit, OnDestroy {
	@Input() activeSalesmen: NameAndId[];
	@Input() addContractClicked: boolean;
	@Input() contract: Contracts;
	@Input() index: number;

	public backgroundColor: string;
	public detailsVisible: boolean;
	private formFields: any[] = [
		{
			name: 'SalespersonId'
		},
		{
			name: 'NumLocations'
		},
		{
			name: 'SaleDate'
		},
		{
			name: 'TotalValue'
		},
		{
			name: 'ContractTermId'
		},
		{
			name: 'MainLocMonthly'
		},
		{
			name: 'RenewDate'
		},
		{
			name: 'DupLocMonthly'
		}
	];

	private formValid: boolean;
	private subs: Subscription[] = [];

	@Output() inputChangeNotify: EventEmitter<any> = new EventEmitter<any>();
	@Output() onRemoveContractNotify: EventEmitter<any> = new EventEmitter<any>();

	constructor(public contractTermsService: ContractTermsService, private messageService: MessageService) {
		this.addContractSubscribe();
	}

	ngOnInit(): void {
		this.backgroundColor = this.setBackgroundColor();
	}

	addContractSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.addContract, () => {
				this.detailsVisible = this.index === 0 ? true : false;
			})
		);
	}

	onDateChanged(date: string, key: string): void {
		this.contract[key] = date;
	}

	onDropdownSelect(event: any, key: string): void {
		this.contract[key] = event.Id;
		this.formValid = this.formValidCheck();
		this.notifyParentOnInputChange();
	}

	onInputChange(): void {
		this.formValid = this.formValidCheck();
		this.notifyParentOnInputChange();
	}

	private checkIfFieldHasValue(): void {
		this.formFields.forEach((field) => {
			if (isNaN(this.contract[field.name])) {
				//string fields are populated
				this.contract[field.name] ? (field.hasValue = true) : (field.hasValue = false);
			} else {
				//numbers are zero or greater and field is not blank
				this.contract[field.name] >= 0 && this.contract[field.name] !== null ? (field.hasValue = true) : (field.hasValue = false);
			}
		});
	}

	private notifyParentOnInputChange(): void {
		let obj = {
			tempId: this.contract.tempId,
			isFormValid: this.formValid
		};
		this.inputChangeNotify.emit(obj);
	}

	private formValidCheck(): boolean {
		this.checkIfFieldHasValue();
		return this.formFields.every((field) => field.hasValue);
	}

	private setBackgroundColor(): string {
		switch (this.contract.productTypeId) {
			case 1: //on hold
				return 'blue-bg';
			case 2: //works24 radio
				return 'purple-bg';
			case 8: //other
				return 'green-bg';
			default: //any video product
				return 'red-bg';
		}
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
