import { ContentFileState } from './content-file-state.enum';

export class ContentFiles {
	Id?: number;
	ContentId: number;
	Directory: string;
	FeedId?: number;
	FeedTemplateId?: number;
	Create24ProjectJson?: string;
	Create24ProjectJsonV4?: string;
	Create24OutputHtmlV4?: string;
	Create24BgContentId?: number;
	MasterFileName: string;
	MasterMp4FileName: string;
	PreviewFileName: string;
	ThumbnailFileName: string;
	DurationMs?: number;
	VoiceTalentId?: number;
	CreateDate: string;
	CreatedByUserId: number;
	UpdateDate: string;
	UpdatedByUserId: number;
	LastAccessDate: string;
	PreviewAccessCount: number;
	MasterAccessCount: number;
	LegacyContentId?: number;
	IsDeleted: boolean;
	ContentFileStateId: ContentFileState;
	ProcessingStateJson?: string;
	IsMigrated: boolean;
	PreMigrationMaster?: string;
	FeedIsRequired: boolean;
}
