export class CsrBlastGroups {
    Id: number;
    Name: string;
    ClientBrowserUrl: string;
    CsrId: number;
    CreatedDate: string;
    Description: string;

    mailBtn: string;
    groupsDeleteBtn: string;
    classList: string;
}