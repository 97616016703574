import { Injectable } from '@angular/core';

import { CanvasService } from './canvas.service';
import { Create24Service } from '../../../create-24.service';
import { Layer } from '../../_models';
import { LayersService } from '../../_services/layers.service';
import { LayersTimelineWrapperService } from '../../timeline/layers-timeline-wrapper/_services/layers-timeline-wrapper.service';
import { TransUtilService } from '../../_services/trans-util.service';
import { VideoControlsService } from '../../_services/video-controls.service';;

@Injectable({
    providedIn: 'root'
})
export class CanvasObjService {

    constructor(
        private canvasService: CanvasService,
        private create24Service: Create24Service,
        private layersService: LayersService,
        private ltwService: LayersTimelineWrapperService,
        private transUtilService: TransUtilService,
        private videoControlsService: VideoControlsService,
    ) { }

    public resetObjValues(): void {
        //Reset positions/opacity back to original (transitions change object positions)
        this.canvasService.canvas.getObjects().forEach((obj) => {
            let layer = this.layersService.layers.find(layer => layer.id === obj.layerId);
            obj.set('top', this.objTop(layer, obj));
            obj.set('left', this.objLeft(layer, obj));
            obj.set('opacity', this.objOpacity(layer, obj));
            obj.setCoords();
        })
        this.showHideObjs();
    }

    public showHideObjs(): void {
        this.canvasService.canvas.getObjects().forEach((obj) => {
            let layer = this.layersService.layers.find(layer => layer.id === obj.layerId);
            if (layer && layer.visible) {

                if (this.videoControlsService.isPlaying) {
                    obj.hasControls = false;
                    obj.hasBorders = false;
                } else {
                    obj.hasControls = this.playheadWithinObj(layer) && layer.id > 0 ? true : false;
                    obj.hasBorders = this.playheadWithinObj(layer) && layer.id > 0 ? true : false;
                }

                switch (true) {
                    case this.playheadWithinObj(layer) && !(obj.name === 'image' && obj.isFeedTextImage):
                        //feed text img objs are always hidden...their text counterparts are visible
                        obj.set('visible', true);
                        break;
                    case !this.playheadWithinObj(layer) || (obj.name === 'image' && obj.isFeedTextImage):
                        //feed text img objs are always hidden
                        obj.set('visible', false);
                        
                        break;
                }
            }
            obj.setCoords();
        })
        this.canvasService.canvas.renderAll();
    }

    //Clicking through a transition sets obj position/opacity accordingly
    public objTop(layer: Layer, obj): number {
        let top = obj.isFeedImageBoundingBox ? layer.canvasObj.feedImgBoundingBoxTop : layer.canvasObj.top;
        if (this.midTransitionOut(layer)) {
            let elapsedTime = this.ltwService.playClockMs() - this.ltwService.pxToMs(layer.transitionOutStart);

            switch (layer.transitionOut) {
                case 'Slide out down':
                    return this.transUtilService.easeInCubic(
                        elapsedTime,
                        top,
                        this.transUtilService.animateToFrom(layer, 'transitionOut', obj) - top,
                        layer.totalTransDuration);

                case 'Slide out up':
                    return this.transUtilService.easeInCubic(
                        elapsedTime,
                        top,
                        -(top + Math.abs(this.transUtilService.animateToFrom(layer, 'transitionOut', obj))),
                        layer.totalTransDuration);
            }
        }
        if (this.midTransitionIn(layer)) {
            let elapsedTime = this.ltwService.playClockMs() - this.ltwService.pxToMs(layer.start);

            switch (layer.transitionIn) {
                case 'Slide in down':
                    return this.transUtilService.easeOutCubic(
                        elapsedTime,
                        this.transUtilService.animateToFrom(layer, 'transitionIn', obj),
                        top + Math.abs(this.transUtilService.animateToFrom(layer, 'transitionIn', obj)),
                        layer.totalTransDuration);

                case 'Slide in up':
                    return this.transUtilService.easeOutCubic(
                        elapsedTime,
                        this.transUtilService.animateToFrom(layer, 'transitionIn', obj),
                        top - this.transUtilService.animateToFrom(layer, 'transitionIn', obj),
                        layer.totalTransDuration);
            }
        }
        //If not clicking in within a transition, set to original value
        return obj.isFeedImageBoundingBox ? layer?.canvasObj.feedImgBoundingBoxTop : layer?.canvasObj.top;
    }

    public objLeft(layer: Layer, obj): number {
        let left = obj.isFeedImageBoundingBox ? layer.canvasObj.feedImgBoundingBoxLeft : layer.canvasObj.left;
        if (this.midTransitionOut(layer)) {
            let elapsedTime = this.ltwService.playClockMs() - this.ltwService.pxToMs(layer.transitionOutStart);
            

            switch (layer.transitionOut) {
                case 'Slide out right':
                    return this.transUtilService.easeInCubic(
                        elapsedTime,
                        left,
                        this.transUtilService.animateToFrom(layer, 'transitionOut', obj) - left,
                        layer.totalTransDuration);

                case 'Slide out left':
                    
                    return this.transUtilService.easeInCubic(
                        elapsedTime,
                        left,
                        -(left + Math.abs(this.transUtilService.animateToFrom(layer, 'transitionOut', obj))),
                        layer.totalTransDuration);
            }
        }
        if (this.midTransitionIn(layer)) {
            let elapsedTime = this.ltwService.playClockMs() - this.ltwService.pxToMs(layer.start);

            switch (layer.transitionIn) {
                case 'Slide in right':
                    return this.transUtilService.easeOutCubic(
                        elapsedTime,
                        this.transUtilService.animateToFrom(layer, 'transitionIn', obj),
                        left - this.transUtilService.animateToFrom(layer, 'transitionIn', obj),
                        layer.totalTransDuration);

                case 'Slide in left':
                    return this.transUtilService.easeOutCubic(
                        elapsedTime,
                        this.transUtilService.animateToFrom(layer, 'transitionIn', obj),
                        left + Math.abs(this.transUtilService.animateToFrom(layer, 'transitionIn', obj)),
                        layer.totalTransDuration);
            }
        }
        return obj.isFeedImageBoundingBox ? layer?.canvasObj.feedImgBoundingBoxLeft : layer?.canvasObj.left;
    }

    public objOpacity(layer: Layer, obj): number {
        if (this.midTransitionOut(layer) && layer.transitionOut === 'Fade out') {
            let elapsedTime = this.ltwService.pxToMs(layer.end) - this.ltwService.playClockMs();

            return this.transUtilService.easeOutCubic(
                elapsedTime,
                this.transUtilService.animateToFrom(layer, 'transitionOut', obj),
                layer.canvasObj.opacity,
                layer.totalTransDuration);
        }
        if (this.midTransitionIn(layer) && layer.transitionIn === 'Fade in') {
            let elapsedTime = this.ltwService.playClockMs() - this.ltwService.pxToMs(layer.start);

            return this.transUtilService.easeOutCubic(
                elapsedTime,
                this.transUtilService.animateToFrom(layer, 'transitionIn', obj),
                layer.canvasObj.opacity,
                layer.totalTransDuration);
        }
        return layer?.canvasObj.opacity;
    }

    //Is play clock stopped in the middle of transition out
    public midTransitionOut(layer: Layer): boolean {
        return this.ltwService.playClockMs() > this.ltwService.pxToMs(layer.transitionOutStart) && this.ltwService.playClockMs() < this.ltwService.pxToMs(layer.end);
    }

    public midTransitionIn(layer: Layer): boolean {
        return this.ltwService.playClockMs() > this.ltwService.pxToMs(layer.start) && this.ltwService.playClockMs() < this.ltwService.pxToMs(layer.transitionInEnd);
    }

    public playheadWithinObj(layer: Layer): boolean {
        if(this.create24Service.contentType() === 'image') {
            return true;
        }
        return this.ltwService.playClockMs() > this.ltwService.pxToMs(layer.start) && this.ltwService.playClockMs() < this.ltwService.pxToMs(layer.end);
    }
}
