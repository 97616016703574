import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { AppStateService } from '../app-state.service';
import { SecurityService } from '../security.service';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

export const contentCreatorGuard: CanActivateFn = () => {
	const appStateService = inject(AppStateService);
	const router = inject(Router);
	const securityService = inject(SecurityService);

	const userRole = appStateService.currentUser.Role;

	if (securityService.userRole(userRole) === UserRole.CONTENT_CREATOR && userRole !== UserRole.VIDEO_CONTENT_CREATOR) {
		router.navigate(['/access-denied']);
		return false;
	}
	return true;
};
