import { Component, Input, OnInit } from '@angular/core';

import { AppStateService, SecurityService, UtilitiesService } from '../../../../../../core/services';
import { BatchChangeCategoryService } from '../../../../content-browser/_services';
import { ContentViewService } from '../../_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { ContentVM } from '../../_models/content-view';

@Component({
	selector: 'vc-hold-cm-list-view',
	templateUrl: './hold-cm-list-view.component.html',
	styleUrls: ['./hold-cm-list-view.component.scss']
})
export class HoldCmListViewComponent implements OnInit {
	@Input() content: ContentVM;
	@Input() contentList: ContentVM[];
	@Input() isEvenIndex: boolean;
	@Input() isFirstIndex: boolean;
	@Input() isLastIndex: boolean;

	public fileNameTooltipClicked: boolean;
	public fileNameTooltipVisible: boolean;

	constructor(
		public appStateService: AppStateService,
		public batchChangeCategoryService: BatchChangeCategoryService,
		public contentViewService: ContentViewService,
		public cvStateService: ContentViewStateService,
		public securityService: SecurityService,
		public utilService: UtilitiesService
	) {}

	ngOnInit() {}

	public onFilenameClick(event): void {
		event.stopPropagation();
		this.fileNameTooltipClicked = true;
	}

	public onMouseleave(): void {
		if (!this.fileNameTooltipClicked) {
			this.fileNameTooltipVisible = false;
		}
	}

	public onWindowClick(): void {
		this.fileNameTooltipClicked = false;
		this.fileNameTooltipVisible = false;
	}
}
