import { Injectable } from '@angular/core';

import { ViewState } from '../_models';

@Injectable({
    providedIn: 'root'
  })
export class ViewStateService {

    public state: ViewState = new ViewState(1, 'Name', '', 'my-tasks');

    constructor(
    ) { }

    public urlIndexes(): number[] {
        return [
            this.myTasksTodayOnlySearch(),
            this.myTasksTodayOnly(),
            this.allCsrTasksTodayOnly(),
            this.allCsrTasksTodayOnlySearch(),
            this.myTasksPastOnly(),
            this.myTasksPastOnlySearch(),
            this.allCsrTasksPastOnly(),
            this.allCsrTasksPastOnlySearch(),
            this.myTasksTodayAndFutureOnly(),
            this.myTasksTodayAndFutureOnlySearch(),
            this.allCsrTasksTodayAndFutureOnly(),
            this.allCsrTasksTodayAndFutureOnlySearch(),
            this.myTasksNoMatterDateCompletedOnly(),
            this.myTasksNoMatterDateCompletedOnlySearch(),
            this.allCsrTasksNoMatterDateCompletedOnly(),
            this.allCsrTasksNoMatterDateCompletedOnlySearch(),
            this.myTasksNoMatterDateUncompletedOnly(),
            this.myTasksNoMatterDateUncompletedOnlySearch(),
            this.allCsrTasksNoMatterDateUncompletedOnly(),
            this.allCsrTasksNoMatterDateUncompletedOnlySearch(),
            this.myTasksDateRangeOnly(),
            this.allCsrTasksDateRangeOnly()
        ]
    }

    public myTasksTodayOnly(): number {
        if (this.todayOnly() &&
            this.noDateRangeNoSearch() &&
            !this.state.allCsrs) {
            return 0;
        }
        return -1;
    }

    private myTasksTodayOnlySearch(): number {
        if (this.todayOnly() &&
            this.noDateRangeYesSearch() &&
            !this.state.allCsrs) {
            return 1;
        }
        return -1;
    }

    private allCsrTasksTodayOnly(): number {
        if (this.todayOnly() &&
            this.noDateRangeNoSearch() &&
            this.state.allCsrs) {
            return 2;
        }
        return -1;
    }

    private allCsrTasksTodayOnlySearch(): number {
        if (this.todayOnly() &&
            this.noDateRangeYesSearch() &&
            this.state.allCsrs) {
            return 3;
        }
        return -1;
    }

    private myTasksPastOnly(): number {
        if (this.pastOnly() &&
            this.noDateRangeNoSearch() &&
            !this.state.allCsrs) {
            return 4;
        }
        return -1;
    }

    private myTasksPastOnlySearch(): number {
        if (this.pastOnly() &&
            this.noDateRangeYesSearch() &&
            !this.state.allCsrs) {
            return 5;
        }
        return -1;
    }

    private allCsrTasksPastOnly(): number {
        if (this.pastOnly() &&
            this.noDateRangeNoSearch() &&
            this.state.allCsrs) {
            return 6;
        }
        return -1;
    }

    private allCsrTasksPastOnlySearch(): number {
        if (this.pastOnly() &&
            this.noDateRangeYesSearch() &&
            this.state.allCsrs) {
            return 7;
        }
        return -1;
    }

    private myTasksTodayAndFutureOnly(): number {
        if (this.todayAndFutureOnly() &&
            this.noDateRangeNoSearch() &&
            !this.state.allCsrs) {
            return 8;
        }
        return -1;
    }

    private myTasksTodayAndFutureOnlySearch(): number {
        if (this.todayAndFutureOnly() &&
            this.noDateRangeYesSearch() &&
            !this.state.allCsrs) {
            return 9;
        }
        return -1;
    }

    private allCsrTasksTodayAndFutureOnly(): number {
        if (this.todayAndFutureOnly() &&
            this.noDateRangeNoSearch() &&
            this.state.allCsrs) {
            return 10;
        }
        return -1;
    }

    private allCsrTasksTodayAndFutureOnlySearch(): number {
        if (this.todayAndFutureOnly() &&
            this.noDateRangeYesSearch() &&
            this.state.allCsrs) {
            return 11;
        }
        return -1;
    }

    private myTasksNoMatterDateCompletedOnly(): number {
        if (this.pastAndTodayAndFuture() &&
            this.noDateRangeNoSearch() &&
            !this.state.allCsrs &&
            this.state.completedOnly) {
            return 12;
        }
        return -1;
    }

    private myTasksNoMatterDateCompletedOnlySearch(): number {
        if (this.pastAndTodayAndFuture() &&
            this.noDateRangeYesSearch() &&
            !this.state.allCsrs &&
            this.state.completedOnly) {
            return 13;
        }
        return -1;
    }

    private allCsrTasksNoMatterDateCompletedOnly(): number {
        if (this.pastAndTodayAndFuture() &&
            this.noDateRangeNoSearch() &&
            this.state.allCsrs &&
            this.state.completedOnly) {
            return 14;
        }
        return -1;
    }

    private allCsrTasksNoMatterDateCompletedOnlySearch(): number {
        if (this.pastAndTodayAndFuture() &&
            this.noDateRangeYesSearch() &&
            this.state.allCsrs &&
            this.state.completedOnly) {
            return 15;
        }
        return -1;
    }

    private myTasksNoMatterDateUncompletedOnly(): number {
        if (this.pastAndTodayAndFuture() &&
            this.noDateRangeNoSearch() &&
            !this.state.allCsrs &&
            !this.state.completedOnly) {
            return 16;
        }
        return -1;
    }

    private myTasksNoMatterDateUncompletedOnlySearch(): number {
        if (this.pastAndTodayAndFuture() &&
            this.noDateRangeYesSearch() &&
            !this.state.allCsrs &&
            !this.state.completedOnly) {
            return 17;
        }
        return -1;
    }

    private allCsrTasksNoMatterDateUncompletedOnly(): number {
        if (this.pastAndTodayAndFuture() &&
            this.noDateRangeNoSearch() &&
            this.state.allCsrs &&
            !this.state.completedOnly) {
            return 18;
        }
        return -1;
    }

    private allCsrTasksNoMatterDateUncompletedOnlySearch(): number {
        if (this.pastAndTodayAndFuture() &&
            this.noDateRangeYesSearch() &&
            this.state.allCsrs &&
            !this.state.completedOnly) {
            return 19;
        }
        return -1;
    }

    private myTasksDateRangeOnly(): number {
        if (this.yesDateRangeNoSearch() &&
            !this.state.allCsrs) {
            return 20;
        }
        return -1;
    }

    private allCsrTasksDateRangeOnly(): number {
        if (this.yesDateRangeNoSearch() &&
            this.state.allCsrs) {
            return 21;
        }
        return -1;
    }

    /*****************
        HELPERS
     *****************/

    private todayOnly(): boolean {
        return !this.state.showPast &&
            !this.state.showTodayAndFuture
    }

    private pastOnly(): boolean {
        return this.state.showPast &&
            !this.state.showTodayAndFuture;
    }

    private todayAndFutureOnly(): boolean {
        return !this.state.showPast &&
            this.state.showTodayAndFuture;
    }

    private pastAndTodayAndFuture(): boolean {
        return this.state.showTodayAndFuture && this.state.showTodayAndFuture;
    }

    private noDateRangeNoSearch(): boolean {
        return !this.state.dateRange && this.state.searchTerm.length === 0;
    }

    private noDateRangeYesSearch(): boolean {
        return !this.state.dateRange && this.state.searchTerm.length > 0;
    }

    private yesDateRangeNoSearch(): boolean {
        return this.state.dateRange && this.state.searchTerm.length === 0;
    }
}
