import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppStateService, Events, MessageService } from 'src/app/core/services';
import { environment } from '../../../../environments/environment';
import { MailerTemplates, CsrBlasts } from '../../../shared/api-models/admin';

@Injectable({
	providedIn: 'root'
})
export class EmailBlastToolService {
	public mailings: CsrBlasts[];
	public templates: MailerTemplates[];

	constructor(private appStateService: AppStateService, private httpClient: HttpClient, private messageService: MessageService) {}

	public getTemplates(): void {
		this.httpClient
			.get(`${environment.adminUrl}MailerTemplate/GetCsrBlastTemplatesForCsr/${this.appStateService.currentUser.UserId}`)
			.pipe(
				map((templates: MailerTemplates[]) => {
					return templates.map((template) => {
						template.templatesDeleteBtn = 'Delete';
						template.editBtn = 'Edit';
						template.classList = 't-row';
						return template;
					});
				})
			)
			.subscribe((templates: MailerTemplates[]) => {
				this.templates = templates;
			});
	}

	public getMailings(): void {
		this.httpClient
			.get(`${environment.adminUrl}CsrBlasts/GetBlastsByCsr/${this.appStateService.currentUser.UserId}`)
			.pipe(
				map((mailings: CsrBlasts[]) => {
					return mailings.map((mailing) => {
						mailing.mailingsEditSendBtn = 'Edit/Send';
						mailing.classList = 't-row white-bg';
						mailing.mailingsDeleteBtn = 'Delete';
						return mailing;
					});
				})
			)
			.subscribe((mailings: CsrBlasts[]) => {
				this.mailings = mailings;
			});
	}

	public updateGroupColumn(row: any, value: string, prop: string): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.httpClient
			.patch(`${environment.adminUrl}CsrBlastGroups/${row.Id}`, { [prop]: value })
			.subscribe(() => this.messageService.publish(Events.savingPreloader, 0));
	}

	public delete(route: string, id: number): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.httpClient.delete(this.deleteUrl(route, id)).subscribe(() => {
			this.refreshOnDeleteSuccess(route);
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}

	private deleteUrl(route: string, id: number): string {
		switch (route) {
			case 'my-groups':
				return `${environment.adminUrl}CsrBlastGroups/${id}`;
			case 'my-mailings':
				return `${environment.adminUrl}CsrBlasts/${id}`;
			case 'my-templates':
				return `${environment.adminUrl}MailerTemplate/${id}`;
		}
	}

	private refreshOnDeleteSuccess(route: string): void {
		switch (route) {
			case 'my-mailings':
				this.getMailings();
				break;
			case 'my-templates':
				this.getTemplates();
				break;
		}
	}
}
