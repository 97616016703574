import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { fabric } from 'fabric';
import { Observable, Observer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AppStateService } from 'src/app/core/services';
import { Create24Service } from '../../../create-24.service';
import { ICircle, IImage, ILine, IRect, IText, Layer, LayerType } from '../../_models';
import { Feed, FeedItem } from '../../_models';
import { LayersService } from '../../_services/layers.service';
import { UtilitiesService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';
import { CountdownAndDateService } from '../../create-layer-sidebar/countdown-and-date/countdown-and-date.service';
import { ContentVM } from 'src/app/shared/components/content-container/content/_models/content-view';

@Injectable({
	providedIn: 'root'
})
export class CanvasService {
	public bgContent: ContentVM;
	public canvas: any;
	public canvasContainerWidth: number;
	public cropObj: any;
	public feedsList: Feed[];
	public feed: Feed;
	public feedItem: FeedItem;

	constructor(
		private appStateService: AppStateService,
		private countdownAndDateService: CountdownAndDateService,
		private create24Service: Create24Service,
		private httpClient: HttpClient,
		private layersService: LayersService,
		private utilService: UtilitiesService
	) {}

	//Acts as the background video layer so canvas object indexes will stay in sync
	public addTransparentLayer(): void {
		let rect = new fabric.Rect({
			component: 'canvas',
			name: 'transparent',
			layerId: 0,
			width: this.canvasSize().width,
			height: this.canvasSize().height,
			hoverCursor: 'auto',
			lockMovementY: true,
			lockMovementX: true,
			hasBorders: false,
			hasControls: false,
			fill: 'rgba(0,0,0,0)',
			top: 0,
			left: 0
		} as IRect);
		rect.selectable = false; //Allows to click and drag to select objs as a group
		this.canvas.add(rect);
	}

	private addRect(layer: Layer): void {
		let rect = new fabric.Rect({
			component: 'canvas',
			name: 'rect',
			layerId: layer.id,
			width: 200,
			height: 200,
			cornerColor: '#2980b9',
			strokeWidth: 0,
			strokeUniform: true,
			cornerStrokeColor: '#fff',
			borderColor: '#2980b9',
			transparentCorners: false,
			fill: '#000',
			rx: 0,
			ry: 0,
			top: 80,
			left: 60
		} as IRect);
		if (layer.isDuplicate) {
			this.addDuplicateProps(rect, layer);
		}
		this.canvas.add(rect).setActiveObject(rect);
	}

	public addCropRect(): void {
		let canvasObj = this.layersService.activeLayer.canvasObj;
		let rect = new fabric.Rect({
			isCropObj: true,
			component: 'canvas',
			name: 'rect',
			cornerColor: '#23282D',
			stroke: '#FED36E',
			strokeWidth: 2,
			strokeUniform: true,
			cornerStrokeColor: '#FED36E',
			borderColor: '#FED36E',
			fill: 'rgba(0,0,0,0)'
		} as IRect);
		rect.top = canvasObj.top;
		rect.left = canvasObj.left;
		rect.height = canvasObj.height * canvasObj.scaleY;
		rect.width = canvasObj.width * canvasObj.scaleX;
		this.cropObj = rect;
		this.canvas.add(rect).setActiveObject(rect);
	}

	private addCircle(layer: Layer): void {
		let circle = new fabric.Circle({
			component: 'canvas',
			name: 'circle',
			layerId: layer.id,
			radius: 100,
			cornerColor: '#2980b9',
			strokeWidth: 0,
			strokeUniform: true,
			cornerStrokeColor: '#fff',
			borderColor: '#2980b9',
			transparentCorners: false,
			fill: '#000',
			top: 80,
			left: 60
		} as ICircle);
		if (layer.isDuplicate) {
			this.addDuplicateProps(circle, layer);
		}
		this.canvas.add(circle).setActiveObject(circle);
	}

	private addLine(layer: Layer): void {
		let line = new fabric.Line([50, 250, 250, 250], {
			component: 'canvas',
			name: 'line',
			padding: 20,
			layerId: layer.id,
			cornerColor: '#2980b9',
			stroke: '#000',
			strokeWidth: 4,
			strokeUniform: true,
			cornerStrokeColor: '#fff',
			borderColor: '#2980b9',
			transparentCorners: false,
			top: 80,
			left: 60
		} as ILine);
		line.setControlsVisibility({
			tr: false,
			tl: false,
			br: false,
			bl: false,
			ml: true,
			mt: false,
			mr: true,
			mb: false,
			mtr: true
		});
		if (layer.isDuplicate) {
			this.addDuplicateProps(line, layer);
		}
		layer.canvasObj.width = line.width;
		this.canvas.add(line).setActiveObject(line);
	}

	public addFeedText(layer: Layer): void {
		let text = new fabric.Textbox(this.feedTextPlaceholder(layer), {
			component: 'canvas',
			name: 'text',
			isFeedText: true,
			layerId: layer.id,
			paintFirst: 'stroke',
			fontFamily: 'Tahoma',
			fill: '#000000',
			top: 80,
			left: 60,
			width: 275,
			padding: 15,
			fontSize: 20,
			editable: false,
			cornerColor: '#2980b9',
			strokeUniform: true,
			cornerStrokeColor: '#fff',
			borderColor: '#2980b9',
			transparentCorners: false
		} as IText);

		text.setControlsVisibility({
			tr: false,
			tl: false,
			br: false,
			bl: false,
			ml: true,
			mt: true,
			mr: true,
			mb: true,
			mtr: true
		});

		if (layer.isDuplicate) {
			this.addDuplicateProps(text, layer);
		}
		if (this.appStateService.product.ProductName === 'Poster') {
			text.width = 400;
		} else {
			text.width = 650;
		}

		layer.canvasObj.height = text.height;
		layer.canvasObj.width = text.width;
		this.canvas.add(text).setActiveObject(text);
		this.canvas.renderAll();
	}

	private feedTextPlaceholder(layer: Layer): string {
		let parsedValues = Array.isArray(this.feedItem?.parsedValues) ? this.feedItem?.parsedValues[0] : this.feedItem?.parsedValues;
		switch (true) {
			case layer.type === 'Feed Text' && !this.feedItem:
				return 'Choose Feed - Feed Item - Feed Source';
			case layer.type === 'Feed Text' && !!layer.feedSourceValue:
				return layer.feedSourceValue;
			case layer.type === 'Feed Text' && !!this.feedItem && !layer.feedSourceValue:
				return 'Choose Feed Source';
			case layer.type === 'Feed Image' && !!this.feed && !!this.feedItem && !parsedValues?.ContentFileId:
				return 'No image exists for this feed item';
			default:
				return 'Choose Feed - Feed Item';
		}
	}

	private addText(layer: Layer): void {
		const text = new fabric.Textbox(layer.canvasObj.text, {
			left: this.countdownAndDateService.mode === 'edit' ? layer.canvasObj.left : this.textLeft(layer),
			top: this.countdownAndDateService.mode === 'edit' ? layer.canvasObj.top : this.textTop(layer),
			fontSize: this.countdownAndDateService.mode === 'edit' ? layer.canvasObj.fontSize : this.fontSize(layer.type),
			fill: layer.canvasObj.fill,
			fontFamily: this.countdownAndDateService.mode === 'edit' ? layer.canvasObj.fontFamily : 'Tahoma',
			textAlign: this.countdownAndDateService.mode === 'edit' ? layer.canvasObj.textAlign : '',
			component: 'canvas',
			name: 'text',
			layerId: layer.id,
			paintFirst: 'stroke',
			width: this.textWidth(layer),
			padding: 15,
			transparentCorners: false,
			cornerColor: '#2980b9',
			strokeUniform: true,
			cornerStrokeColor: '#fff',
			borderColor: '#2980b9',
			editable: layer.type !== 'Countdown' && layer.type !== 'Current Date' && layer.type !== 'Current Time'
		} as IText);

		//Countdown, date, and time layers MUST remain on a single line, therefore remove middle
		//drag handles for these types
		text.setControlsVisibility({
			tr: false,
			tl: false,
			br: false,
			bl: false,
			ml: layer.type === 'Countdown' || layer.type === 'Current Date' || layer.type === 'Current Time' ? false : true,
			mt: true,
			mr: layer.type === 'Countdown' || layer.type === 'Current Date' || layer.type === 'Current Time' ? false : true,
			mb: true,
			mtr: true
		});
		if (layer.isDuplicate) {
			this.addDuplicateProps(text, layer);
		}
		this.canvas.add(text).setActiveObject(text);
		this.canvas.renderAll();
	}

	private textWidth(layer: Layer): number {
		if (this.appStateService.product.ProductName === 'Lobby Video') {
			switch (layer.type) {
				case 'Current Date':
					return 750;
				case 'Current Time':
				case 'Countdown':
					return 650;
				default:
					return 275;
			}
		}
		if (this.appStateService.product.ProductName === 'Poster') {
			switch (layer.type) {
				case 'Current Date':
				case 'Current Time':
				case 'Countdown':
					return 360;
				default:
					return 220;
			}
		}
		return 220;
	}

	public textLeft(layer: Layer): number {
		switch (layer.countdownLayerView) {
			case 'countdownText':
				return 95;
			default:
				return 60;
		}
	}

	public textTop(layer: Layer): number {
		switch (layer.countdownLayerView) {
			case 'todaysDateMonth':
			case 'todaysDateMonthDay':
			case 'currentTime':
				return 130;
			case 'todaysDateDay':
			case 'currentTimePeriod':
				return 180;
			case 'todaysDateYear':
				return 230;
			case 'countdownMinutes':
				return 140;
			case 'countdownSeconds':
				return 200;
			default:
				return 80;
		}
	}

	private fontSize(layerType: LayerType): number {
		if (this.appStateService.product.ProductName === 'Poster') {
			return 21;
		}
		return layerType === 'Feed Text' ? 20 : 42;
	}

	public addImage(layer: Layer, isFeedImage?: boolean): void {
		//Replace url with base64 string
		this.imageToBase64$(layer).subscribe((base64: string) => {
			fabric.Image.fromURL(
				base64,
				(img) => {
					let image: IImage = img as IImage;
					image.set({
						isFeedImage: isFeedImage,
						component: 'canvas',
						name: 'image',
						layerId: layer.id,
						cornerColor: '#2980b9',
						strokeWidth: 0,
						strokeUniform: true,
						cornerStrokeColor: '#fff',
						borderColor: '#2980b9',
						top: layer.canvasObj.top,
						left: layer.canvasObj.left,
						transparentCorners: false,
						hasRotatingPoint: layer.type === 'Feed Image' ? false : true
					});

					image.setControlsVisibility({
						tr: layer.type === 'Feed Image' ? false : true,
						tl: layer.type === 'Feed Image' ? false : true,
						br: layer.type === 'Feed Image' ? false : true,
						bl: layer.type === 'Feed Image' ? false : true,
						ml: false,
						mt: false,
						mr: false,
						mb: false,
						mtr: layer.type === 'Feed Image' ? false : true
					});
					if (layer.isDuplicate) {
						this.addDuplicateProps(image, layer);
					}

					layer.canvasObj.height = image.height;
					layer.canvasObj.width = image.width;

					this.canvas.add(image);
					if (layer.type === 'Feed Image') {
						this.addFeedImageBoundingBox(layer, image);
					}
					this.canvas.renderAll();
					this.layersService.onImageLoadComplete(layer);
				},
				{ crossOrigin: 'anonymous' }
			);
		});
	}

	public addFeedImageBoundingBox(layer: Layer, image): void {
		var rect = new fabric.Rect({
			layerId: layer.id,
			component: 'canvas',
			name: 'rect',
			isFeedImageBoundingBox: true,
			hasRotatingPoint: false,
			transparentCorners: false,
			cornerColor: '#2980b9',
			stroke: '#2980b9',
			strokeWidth: 0,
			strokeUniform: true,
			cornerStrokeColor: '#fff',
			borderColor: '#2980b9',
			fill: 'rgba(0,0,0,0)'
		} as IRect);
		rect.top = layer.canvasObj.feedImgBoundingBoxTop;
		rect.left = layer.canvasObj.feedImgBoundingBoxLeft;
		rect.height = layer.canvasObj.feedImgBoundingBoxCalcHeight;
		rect.width = layer.canvasObj.feedImgBoundingBoxCalcWidth;

		let imgRatio = image.width / image.height;
		let boundingRectRatio = rect.width / rect.height;

		//Scale image to fit in bounding box
		if (imgRatio <= boundingRectRatio) {
			if (image.height > rect.height) {
				image.scaleToHeight(rect.height);
			}
		} else {
			if (image.width > rect.width) {
				image.scaleToWidth(rect.width);
			}
		}

		//Center image inside bounding rect
		image.left = (rect.getScaledWidth() - image.getScaledWidth()) / 2 + rect.left;
		image.top = rect.top + (rect.height - image.getScaledHeight()) / 2;
		this.canvas.add(rect).setActiveObject(rect);
	}

	public addObj(layer: Layer): void {
		let parsedValues = Array.isArray(this.feedItem?.parsedValues) ? this.feedItem?.parsedValues[0] : this.feedItem?.parsedValues;
		switch (layer.type) {
			case 'Rectangle':
				this.addRect(layer);
				break;
			case 'Circle':
				this.addCircle(layer);
				break;
			case 'Line':
				this.addLine(layer);
				break;
			case 'Text':
			case 'Countdown':
			case 'Current Date':
			case 'Current Time':
				this.addText(layer);
				break;
			case 'Feed Text':
				this.addFeedText(layer);
				break;
			case 'Feed Image':
				if (parsedValues?.ContentFileId) {
					layer.previewUrl = `${environment.contentUrl}File/Video/${parsedValues.ContentFileId}/Preview`;
					this.addImage(layer, true);
				} else if (!this.feedItem || !parsedValues?.ContentFileId) {
					this.addFeedText(layer);
				}
				break;
			case 'Image':
				this.addImage(layer);
		}
	}

	private addDuplicateProps(obj: any, layer: Layer): void {
		obj.scaleX = layer.canvasObj.scaleX;
		obj.scaleY = layer.canvasObj.scaleY;
		obj.fill = layer.canvasObj.fill;
		obj.stroke = layer.canvasObj.stroke;
		obj.strokeWidth = layer.canvasObj.strokeWidth;
		obj.opacity = layer.canvasObj.opacity;
		obj.angle = layer.canvasObj.angle;
		obj.text = layer.canvasObj.text;
		obj.fontFamily = layer.canvasObj.fontFamily;
		obj.textAlign = layer.canvasObj.textAlign;
		obj.fontWeight = layer.canvasObj.fontWeight;
		obj.fontSize = layer.canvasObj.fontSize;
		obj.charSpacing = layer.canvasObj.charSpacing;
		obj.selectable = true;
		obj.fontStyle = layer.canvasObj.fontStyle;

		if (layer.canvasObj.includeDropShadow) {
			obj.set('shadow', layer.canvasObj.shadow);
		}
	}

	public canvasSize(): any {
		let height = this.utilService.calculateAspectRatioFit(this.appStateService.product?.WidthPx, this.appStateService.product?.HeightPx, 992, 558).height;
		let width = this.utilService.calculateAspectRatioFit(this.appStateService.product?.WidthPx, this.appStateService.product?.HeightPx, 992, 558).width;

		//Sidebars are not a product, so use hard dimensions (485x725)
		if (this.utilService.includes(this.create24Service.c24ActiveArea, 'sidebar')) {
			height = this.utilService.calculateAspectRatioFit(485, 725, 992, 558).height;
			width = this.utilService.calculateAspectRatioFit(485, 725, 992, 558).width;
		}
		return { height: height, width: width };
	}

	public imageToBase64$(layer: Layer): Observable<any> {
		return this.httpClient.get(layer.previewUrl, { responseType: 'blob' }).pipe(
			mergeMap((blob: Blob) => {
				var reader = new FileReader();
				reader.readAsDataURL(blob);

				return new Observable((observer: Observer<any>) => {
					reader.onload = () => {
						this.httpClient.post(`${environment.contentUrl}ImageResizer/ResizePlacedImage`, { image: reader.result }).subscribe((res) => {
							observer.next(res);
							observer.complete();
						});
					};
				});
			})
		);
	}

	/* TAYLOR ADDED THIS */

	public matchFeedImageToContainer(containerObj): void {
		let imageObj = this.canvas.getObjects().find((obj) => obj.layerId === this.layersService.activeLayer.id && obj.name === 'image');
		let imageCalcWidth = imageObj.width * imageObj.scaleX;
		let imageCalcHeight = imageObj.height * imageObj.scaleY;

		let containerCalcWidth = containerObj.width * containerObj.scaleX;
		let containerCalcHeight = containerObj.height * containerObj.scaleY;

		if (containerCalcHeight / imageCalcHeight > containerCalcWidth / imageCalcWidth) {
			imageObj.scaleToWidth(containerCalcWidth, true);
			imageObj.set('top', containerObj.top + (containerCalcHeight - imageCalcHeight) / 2);
			imageObj.set('left', containerObj.left);
		} else {
			imageObj.scaleToHeight(containerCalcHeight, true);
			imageObj.set('top', containerObj.top);
			imageObj.set('left', containerObj.left + (containerCalcWidth - imageCalcWidth) / 2);
		}
		let layer = this.layersService.activeLayer;
		layer.canvasObj.top = imageObj.top;
		layer.canvasObj.left = imageObj.left;
		layer.canvasObj.feedImgBoundingBoxLeft = containerObj.left;
		layer.canvasObj.feedImgBoundingBoxTop = containerObj.top;
		layer.canvasObj.feedImgBoundingBoxCalcHeight = containerCalcHeight;
		layer.canvasObj.feedImgBoundingBoxCalcWidth = containerCalcWidth;
	}

	/* ^^^ TAYLOR ADDED THIS ^^^ */

	public omitDuplicateLayerId(array, prop: 'layerId' | 'id') {
		// Use reduce to accumulate objects by layerId
		const resultArray = array.reduce((acc, obj) => {
			// If an object with the same layerId doesn't exist, add it
			if (!acc.some((item) => item[prop] === obj[prop])) {
				acc.push(obj);
			}
			return acc;
		}, []);

		return resultArray;
	}
}
