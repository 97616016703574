import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class RotateImageService {
	public imageSrc: string;
	public processing: boolean;

	constructor() {}

	public getBase64(file) {
		this.processing = true;
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			this.getOrientation(file, (srcOrientation) => {
				this.resetOrientation(reader.result, srcOrientation, (src) => {
					this.imageSrc = src;
					this.processing = false;
				});
			});
		};
	}

	private resetOrientation(srcBase64, srcOrientation, callback) {
		var img = new Image();

		img.onload = function () {
			var width = img.width,
				height = img.height,
				canvas = document.createElement('canvas'),
				ctx = canvas.getContext('2d');

			// set proper canvas dimensions before transform & export
			if (4 < srcOrientation && srcOrientation < 9) {
				canvas.width = height;
				canvas.height = width;
			} else {
				canvas.width = width;
				canvas.height = height;
			}

			// transform context before drawing image
			switch (srcOrientation) {
				case 2:
					ctx.transform(-1, 0, 0, 1, width, 0);
					break;
				case 3:
					ctx.transform(-1, 0, 0, -1, width, height);
					break;
				case 4:
					ctx.transform(1, 0, 0, -1, 0, height);
					break;
				case 5:
					ctx.transform(0, 1, 1, 0, 0, 0);
					break;
				case 6:
					ctx.transform(0, 1, -1, 0, height, 0);
					break;
				case 7:
					ctx.transform(0, -1, -1, 0, height, width);
					break;
				case 8:
					ctx.transform(0, -1, 1, 0, 0, width);
					break;
				default:
					break;
			}

			// draw image
			ctx.drawImage(img, 0, 0);

			// export base64
			callback(canvas.toDataURL());
		};

		img.src = srcBase64;
	}

	//https://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side/32490603#32490603
	private getOrientation(file, callback) {
		var reader = new FileReader();
		reader.onload = (e: any) => {
			var view = new DataView(e.target.result);
			if (view.getUint16(0, false) != 0xffd8) {
				return callback(-2);
			}
			var length = view.byteLength,
				offset = 2;
			while (offset < length) {
				if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
				var marker = view.getUint16(offset, false);
				offset += 2;
				if (marker == 0xffe1) {
					if (view.getUint32((offset += 2), false) != 0x45786966) {
						return callback(-1);
					}

					var little = view.getUint16((offset += 6), false) == 0x4949;
					offset += view.getUint32(offset + 4, little);
					var tags = view.getUint16(offset, little);
					offset += 2;
					for (var i = 0; i < tags; i++) {
						if (view.getUint16(offset + i * 12, little) == 0x0112) {
							return callback(view.getUint16(offset + i * 12 + 8, little));
						}
					}
				} else if ((marker & 0xff00) != 0xff00) {
					break;
				} else {
					offset += view.getUint16(offset, false);
				}
			}
			return callback(-1);
		};
		reader.readAsArrayBuffer(file);
	}

	public onRotateClick(direction): void {
		this.processing = true;
		let angleInDegrees = 0;

		if (direction === 'right') {
			angleInDegrees += 90 % 360;
		} else {
			if (angleInDegrees == 0) angleInDegrees = 270;
			else angleInDegrees -= 90;
		}
		this.rotateImage(angleInDegrees, (src) => {
			this.imageSrc = src;
			this.processing = false;
		});
	}

	//https://stackoverflow.com/questions/17411991/html5-canvas-rotate-image
	private rotateImage(angleInDegrees, callback): void {
		var img = new Image();
		img.onload = () => {
			if (canvas) {
				canvas = null;
			}
			var width = img.width,
				height = img.height,
				canvas = document.createElement('canvas'),
				ctx = canvas.getContext('2d');

			// set proper canvas dimensions before transform & export
			if (angleInDegrees === 90 || angleInDegrees === 270) {
				canvas.width = height;
				canvas.height = width;
			} else {
				canvas.width = width;
				canvas.height = height;
			}

			ctx.clearRect(0, 0, canvas.width, canvas.height);
			if (angleInDegrees === 90 || angleInDegrees === 270) {
				ctx.translate(img.height / 2, img.width / 2);
			} else {
				ctx.translate(img.width / 2, img.height / 2);
			}
			ctx.rotate((angleInDegrees * Math.PI) / 180);
			ctx.drawImage(img, -img.width / 2, -img.height / 2);
			ctx.restore();

			// export base64
			callback(canvas.toDataURL());
		};

		img.src = this.imageSrc;
	}
}
