<div class="shadow pd-bottom15 mgn-bottom30 border-radius white-bg">
    <div class="title pd15 gray-border gray-bg">
        <div class="row">
            <div class="col-xs-10">
                <h2 class="ellipsis">{{playlist.Name}}</h2>
            </div>
            <div class="col-xs-2 text-right">
                <button (mouseup)="pphViewService.onTableButtonClick([table.columns[2], playlist])" class="border-radius pd-right4 pd-left4 blue-bg-darkest white-text"><i class="fas fa-play mgn-right4 ft-size14" aria-hidden="true"></i></button>
            </div>
        </div>		
    </div>
    <div class="row pd-left15 pd-right15">
        <div class="col-xs-6 mgn-top12 mgn-bottom10">
            <span class="semi-bold">Playlist Name:</span><br>
            <h3>{{playlist.Name}}</h3>
        </div>
        <div class="col-xs-6 mgn-top12 mgn-bottom10">
            <span class="semi-bold">Delivery Date:</span><br>
            <h3>{{playlist.deliverComplete | date:'M/d/yyyy'}}</h3>
        </div>
    </div>	
    <div class="row pd-left15 pd-right15 mgn-top88">
        <div class="col-xs-6">
            <button (mouseup)="pphViewService.onTableButtonClick([table.columns[0], playlist])" class="white-bg">
                <i class="fa fa-info-circle mgn-right6" aria-hidden="true"></i>
                Details
            </button>
        </div>
        <div class="col-xs-6">
            <button (mouseup)="pphViewService.onTableButtonClick([table.columns[1], playlist])" class="white-bg">
                <span><i class="fas fa-pencil-alt mgn-right8" aria-hidden="true"></i>Edit</span>
            </button>
        </div>
    </div>
</div>


