import { Component, OnInit } from '@angular/core';

import { SecurityService, ViewportWidthService } from 'src/app/core/services';
import { ContentViewState } from 'src/app/shared/view-models';
import { ContentViewStateService } from 'src/app/shared/services';
import { FeaturedContentService } from './featured-content.service';

@Component({
	selector: 'app-featured-content',
	templateUrl: './featured-content.component.html',
	styleUrls: ['./featured-content.component.scss']
})
export class FeaturedContentComponent implements OnInit {
	public monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	public hasHold: boolean;
	public hasVideo: boolean;

	public month: string;

	constructor(
		private cvStateService: ContentViewStateService,
		public featuredContentService: FeaturedContentService,
		public securityService: SecurityService,
		public viewportWidthService: ViewportWidthService
	) {}

	ngOnInit(): void {
		this.cvStateService.viewState = new ContentViewState();
		this.cvStateService.viewState.contentListVisible = true;
		this.viewportWidthService.checkViewportWidth();
		this.featuredContentService.getContent();
		this.setMonth();
	}

	public showMusicTitle(): boolean {
		return this.featuredContentService.holdContentList?.some((content) => content.ContentTypeId === 1);
	}

	public setMonth(): void {
		const day = new Date().getDate();
		const currentMonth = new Date().getMonth();

		switch (true) {
			//Return current month if date is less than 10th
			case day < 10:
				this.month = this.monthNames[currentMonth];
				break;
			//Go back to January if current month is December
			case day >= 10 && currentMonth === 11:
				this.month = this.monthNames[0];
				break;
			//Return next month if date is 10th or later and not December
			case day >= 10 && currentMonth !== 11:
				this.month = this.monthNames[currentMonth + 1];
				break;
		}
	}

	public year(): number {
		const day = new Date().getDate();
		if (this.month === 'January' && day >= 10) {
			return new Date().getFullYear() + 1;
		}
		return new Date().getFullYear();
	}
}
