<portal-header></portal-header>
<!--*********************
	GREEN HEADER BAR
**********************-->
<div class="container-fluid">
	<div class="row mgn-bottom15 mgn-top15">
		<div class="col-xs-4 col-sm-2">
			<div class="row">
				<div class="col-xs-12 col-lg-9">
					<button (click)="location.back()" class="white-bg"><i class="fas fa-chevron-left mgn-right12"></i>Back</button>
				</div>
			</div>
			
		</div>
		<div class="col-xs-7 col-xs-offset-1 col-sm-offset-0 col-sm-10 text-right">
			<i class="fas fa-cloud-upload-alt mgn-right12 ft-size28 green-text"></i>
			<h1 class="in-block"><span class="semi-bold">Large File Transfer</span></h1>
		</div>
	</div>

	<div class="green-limeGreen-bg-gradient mgn-bottom45 ht2 border-radius"></div>
</div>

<div class="container-fluid">
	<div class="row">
		<div class="col-sm-6 col-sm-offset-3">
			<h2 class="semi-bold">Transfer Large Files to Works24 Creative Team</h2>
			<h3>Notes/Directions to CSR/Video Studio</h3>
			<textarea rows="4" class="setWidth100" [(ngModel)]="notes"></textarea>
			<h3 class="mgn-top15">Select/Drag files to upload (5 files max, file size < 2GB)</h3>
			<div ng2FileDrop
                 [ngClass]="{'file-over': hasBaseDropZoneOver}"
                 (fileOver)="fileOverBase($event)"
                 (onFileDrop)="onDrop()"
                 [uploader]="uploader"
                 class="my-drop-zone setWidth100 border-radius ht80">
                 <h3 *ngIf="!hasBaseDropZoneOver" class="gray-text-darker text-center mgn-top24">Drop files here</h3>
                 <h3 *ngIf="hasBaseDropZoneOver" class="gray-text-darker text-center mgn-top24">Drop it!</h3>
			</div>
			<div class="row mgn-top30">
				<div class="col-sm-6">									 
					Single
					<input type="file" #singleInput style="background: initial; border: initial;" ng2FileSelect [uploader]="uploader" (change)="onChange()" />
				</div>
				<div class="col-sm-6">
					Multiple
					<input type="file" style="background: initial; border: initial;" ng2FileSelect [uploader]="uploader" multiple (change)="onChange()"  />
				</div>
			</div>
			<div class="row mgn-top30">
				<div class="col-sm-12">
					<h3 class="semi-bold">Upload queue</h3>
			           <p>Queue length: {{ uploader?.queue?.length }}</p>
			
			           <div class="row border-bottom mgn-bottom10 mgn-top15">
		                   <div class="col-xs-4">Name</div>
		                   <div class="col-xs-2">Size</div>
		                   <div class="col-xs-4">Progress</div>
		                   <div class="col-xs-2">Remove</div>
		                </div>
		                <div *ngFor="let item of uploader.queue" class="row mgn-bottom6">

	                	    <div class="col-xs-4 semi-bold">
	                	    	<h4 class="ellipsis">{{ item?.file?.name }}</h4>
	                	    </div>
	                	    <div class="col-xs-2">{{ item?.file?.size/1024/1024 | number : '1.2-2' }} MB</div>

                	        <div class="col-xs-4 relative"> 
                                <!-- 	 -->
                	            <div *ngIf="showProgressBar(item)" class="blue-gradient border-radius ht25 text-center transition" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }">
                                    <span [ngClass]="{'dark-text' : item.progress < 48, 'white-text' : item.progress > 48}" class="absolute center-unknown-height-width">{{item.progress}}%</span>
                                </div> 
                	        </div>
	                	    <div class="col-xs-1" >
	                	        <button *ngIf="!uploadAllClicked" class="white-bg"
	                	                (click)="onItemRemoveClick(item)">
	                	            <i class="fa fa-times" aria-hidden="true"></i>
	                	        </button>
	                	    </div>
		                </div>
		                <div class="row mgn-top15">
                            <div class="col-xs-12">
                                <span *ngIf="lengthErrorVisible" class="red-text-light"><em>Five files per upload is the limit!</em></span>
                                <span *ngIf="uploadSuccess" class="green-text">All files uploaded successfully!</span>
                                <div *ngIf="uploadSuccess" class="row mgn-top30">
                                    <div class="col-xs-4 col-xs-offset-4">
                                        <button class="gray-bg-dark" (click)="uploadMoreFilesClick()">My Products</button>
                                    </div>
                                    <div class="col-xs-4">
                                        <button class="blue-bg white-text" (click)="uploadMoreFilesClick()">Upload more files</button>
                                    </div>
                                </div>
                                
                            </div>
						</div>
			            <div *ngIf="!uploadSuccess" class="row mgn-top40 mgn-bottom60">
			                <div class="col-xs-3">
			               		<button *ngIf="!uploadAllClicked" type="button" class="red-bg-light white-text"
			               		        (click)="onRemoveAllClick()">
			               		     Remove All
			               		</button>
			                </div>
			                <div class="col-xs-3 col-sm-offset-3">
			               		<button type="button" class="gray-bg-dark"
			               		        (click)="onCancelAllClick()">
			               		     Cancel All
			               		</button>
			                </div>
			                <div class="col-xs-3">
			               		
			               		<button [ngClass]="{'setOpacityLow' : uploadAllClicked}" [disabled]="uploadAllClicked" type="button" class="limeGreen-bg white-text"
			               		        (click)="onUploadAllClick()">
			               		    	 Upload All
			               		</button>
			                </div>					                 
			           </div>
				</div>
			</div>
		</div>
		<div class="col-sm-3">
			<div class="shadow border-radius white-bg">
				<div class="title ht40 border-radius pd-left10 pd-top8">
					<h3 class="semi-bold">About this tool</h3>
                </div>
                <div class="green-limeGreen-bg-gradient ht1 border-radius mgn-left10 mgn-right10"></div>
				<p class="pd10">This tool is designed to allow you to transfer files to the Works24 creative team that might be too large to email (video clips, high resolution advertising documents, etc.) Files uploaded using this tool do NOT go into your library and cannot be used onscreen.</p>
			</div>
		</div>
	</div>
</div>