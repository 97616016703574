<!--***************************
	HOLD CONTENT
****************************-->
<div
	*ngIf="!contentViewService.contentIsVideo(content)"
	class="row border-radius {{ !isLastIndex ? 'border-first-index' : 'border-last-index border-first-index mgn-bottom1' }} white-bg pd-top15 pd-bottom15"
>
	<div class="col-xs-12">
		<div class="row">
			<!--***************************
			     SCRIPT BUTTON
			****************************-->
			<div class="col-xs-2 ft-size16">
				<button *ngIf="content.ContentTypeId !== 1" class="z gray-bg noExit" (mouseup)="content.detailsVisible = !content.detailsVisible">
					<i class="{{ content.detailsVisible ? 'fas fa-times' : 'far fa-file-alt' }} icon-hidden mgn-right10" aria-hidden="true"></i>
					{{ content.detailsVisible ? 'Close' : 'Script' }}
				</button>
				<em *ngIf="content.contentFiles && contentViewService.contentFileIsMusicTrack(content.contentFiles[0])" class="orange-text">*Music Track</em>
			</div>

			<!--***************************
				PREVIEW BUTTON
			****************************-->
			<div class="col-xs-2 relative">
				<button
					*ngIf="content.ContentTypeId !== 1"
					(mouseup)="contentViewService.onPlayClick(content, isLastIndex, 'talentPreviewSelectorVisible')"
					class="noExit white-text blue-bg"
				>
					<i class="fa {{ content.talentPreviewSelectorVisible ? 'fa-times' : 'fas fa-play' }} noExit white-text mgn-right10" aria-hidden="true"></i>
					{{ content.talentPreviewSelectorVisible ? 'Close' : 'Play' }}
				</button>

				<button
					*ngIf="content.ContentTypeId === 1 && content.contentFiles?.length > 0"
					(click)="contentViewService.onPlayClick(content, null, null, contentList)"
					class="noExit {{ content.contentFiles[0].previewActive ? 'yellow-bg' : 'white-bg' }}"
				>
					<i class="fa {{ content.contentFiles[0].previewActive ? 'fa-times' : 'fas fa-play' }} noExit mgn-right6" aria-hidden="true"></i>
					{{ content.contentFiles[0].previewActive ? 'Stop' : 'Play' }}
				</button>

				<div *ngIf="content.talentPreviewSelectorVisible" style="left: 96%; z-index: 100" class="absolute top0 mgn-bottom30">
					<portal-select-box
						[content]="content"
						[title]="'Select a Preview Version'"
						[width]="'wdth226'"
						(onSelectNotify)="contentViewService.onSelectVoiceTalentClick($event, content, 'talentPreviewSelectorVisible')"
					></portal-select-box>
				</div>
			</div>

			<!--***************************
				CONTENT TITLE
			****************************-->
			<div class="col-xs-8 ft-size16">
				<span>{{ content.Title }}</span>
			</div>
		</div>
	</div>

	<!--***************************
		SCRIPT
	****************************-->
	<div *ngIf="content.detailsVisible" class="col-lg-12 mgn-bottom15">
		<c-details [content]="content"></c-details>
	</div>
</div>

<!--***************************
	VIDEO CONTENT
****************************-->
<div *ngIf="contentViewService.contentIsVideo(content)" class="container-fluid border-radius shadow white-bg mgn-bottom30 relative">
	<div class="title pd-top15 pd-bottom15 gray-border white-bg border-radius">
		<h3 class="ellipsis semi-bold">{{ content.Title }}</h3>
	</div>
	<hr class="gray-border mgn-top0 mgn-bottom30" />

	<div class="row mgn-top10">
		<div class="col-xs-12">
			<div *ngIf="!previewActive" class="in-block relative center">
				<div
					class="in-block overlay absolute setWidth100 z {{ content?.contentType === 'video/mp4' ? 'pointer' : null }} white-text"
					(click)="onPreviewClick()"
				>
					<span>
						<i class="fa {{ content.thumbIcon }} ft-size22 absolute center-unknown-height-width" aria-hidden="true"></i>
					</span>
				</div>
				<img *ngIf="contentViewService.contentIsVideo(content)" src="{{ content.thumbnailUrl }}" class="img-responsive border-radius box-shadow" />
			</div>

			<div *ngIf="previewActive" class="relative">
				<video
					#video
					*ngIf="content.contentType === 'video/mp4'"
					src="{{ content.previewUrl }}"
					type="video/mp4"
					class="setWidth100 border-radius relative z box-shadow"
					autoplay
					controls="true"
					controlsList="nodownload"
				>
					Oops! Your browser does not support this preview.
				</video>
				<i
					(click)="previewActive = false"
					style="z-index: 100; right: 0px; top: -31px"
					class="fas fa-times dark-text pd4 absolute topneg22 pointer ft-size26"
					aria-hidden="true"
				></i>

				<img
					*ngIf="content?.contentType === 'image/png'"
					src="{{ content.previewUrl }}"
					width="300"
					height="200"
					class="border-radius relative box-shadow z img-responsive"
				/>
			</div>
		</div>
	</div>

	<div class="row mgn-top30">
		<div class="col-lg-12">
			<h4 *ngIf="content.breadcrumbs?.length" class="ft-size11 in-block">
				<span>
					{{ content.breadcrumbs[0] }} > {{ content.breadcrumbs[1] }}
					<span *ngIf="content.breadcrumbs[2]">></span>
					{{ content.breadcrumbs[2] }}
				</span>
			</h4>
		</div>
	</div>

	<div class="row mgn-top5">
		<div class="col-xs-12">
			<span class="semi-bold">Content Script</span>
		</div>
	</div>
	<div class="row mgn-top5 mgn-bottom30">
		<div class="col-xs-12">
			<div style="border: 1px solid #d5d5d5; height: 72px" class="gray-bg scrollable pd10 border-radius">
				<p>{{ content.Text }}</p>
			</div>
		</div>
	</div>
</div>
