<portal-header></portal-header>

<portal-spinner *ngIf="!feedItem" [height]="'htpx76'" [width]="'wdthpx76'"></portal-spinner>

<div class="container-fluid mx-4 mgn-top30 relative">
	<a class="dark-text absolute topneg21 left0" (mouseup)="location.back()">
		<i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
		<span class="ft-size16">Back</span>
	</a>
	<div class="row mgn-bottom30">
		<h1 class="semi-bold">
			<i class="far fa-newspaper mgn-right15" aria-hidden="true"></i>
			News Manager
		</h1>
	</div>

	<div class="row green-limeGreen-bg-gradient ht2 mgn-top20 border-radius"></div>

	<div *ngIf="feedItem" class="row mgn-top30 mgn-bottom60">
		<div class="col-sm-12 white-bg shadow border-radius pd15">
			<div class="row mgn-bottom20">
				<div class="col-sm-10">
					<h2 class="semi-bold in-block">
						{{ viewState === 'new' ? 'Create Story' : 'Edit Story' }}
					</h2>
				</div>
			</div>
			<div class="row mgn-bottom30">
				<div class="col-sm-6">
					<span class="semi-bold">Feed Name:</span>
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[key]="'Name'"
						[options]="feeds"
						[selectedOption]="!feedItem.feedName ? 'Select a feed...' : feedItem.feedName"
						(onOptionSelect)="onFeedSelect($event)"
					></portal-dropdown-menu>
				</div>
			</div>

			<div class="row mgn-bottom15">
				<div class="col-sm-6">
					<span class="semi-bold">Feed Item Headline:</span>
					<input [(ngModel)]="feedItem.headline" type="text" placeholder="Enter a headline..." />
				</div>
				<div class="col-sm-6">
					<span class="semi-bold">Feed Item Body:</span>
					<textarea [(ngModel)]="feedItem.body" rows="6" placeholder="Enter a story..."></textarea>
				</div>
			</div>
			<div class="row mgn-bottom60">
				<div class="col-xs-12 mgn-top30">
					<portal-start-stop-date-time [clearDateEnabled]="true" [initAsNull]="true" [invertColorTheme]="true"></portal-start-stop-date-time>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-3 col-xs-offset-6 col-sm-2 col-sm-offset-8">
					<button class="gray-bg" [routerLink]="['/news-manager']">Cancel</button>
				</div>
				<div class="col-xs-3 col-sm-2">
					<button [disabled]="!formValid()" class="{{ !formValid() ? 'setOpacityLow' : null }} limeGreen-bg white-text" (mouseup)="onSaveClick()">Save</button>
				</div>
			</div>
		</div>
	</div>
</div>
