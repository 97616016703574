export type TicketsTableApi = {
	[TicketsTableApiKeys.ActionColor]: string;
	[TicketsTableApiKeys.AssignedToUserId]: number;
	[TicketsTableApiKeys.AssignedToUserName]: string;
	[TicketsTableApiKeys.ClientId]: number;
	[TicketsTableApiKeys.ClientName]: string;
	[TicketsTableApiKeys.CompletedDate]: string;
	[TicketsTableApiKeys.CreatedDate]: string;
	[TicketsTableApiKeys.DaysSinceLastAction]: number;
	[TicketsTableApiKeys.Description]: string;
	[TicketsTableApiKeys.Id]: number;
	[TicketsTableApiKeys.IsComplete]: boolean;
	[TicketsTableApiKeys.IsHighPriority]: boolean;
	[TicketsTableApiKeys.LastTicketActionDate]: string;
	[TicketsTableApiKeys.LocationCount]: number;
	[TicketsTableApiKeys.PostponeUntil]: string;
	[TicketsTableApiKeys.ReminderTime]: string;
	[TicketsTableApiKeys.TicketStateChanged]: string;
	[TicketsTableApiKeys.TicketStateId]: number;
	[TicketsTableApiKeys.TicketStateName]: string;
	[TicketsTableApiKeys.TicketTypeId]: number;
	[TicketsTableApiKeys.TicketTypeName]: string;
	[TicketsTableApiKeys.Title]: string;

	// Additional properties
	[TicketsTableApiKeys.classList]: string;
	[TicketsTableApiKeys.isHighPriorityString]: string;
	[TicketsTableApiKeys.lastActionDateView]: string;
	[TicketsTableApiKeys.rowBgColor]: string;
	[TicketsTableApiKeys.take]: 'Take';
};

export enum TicketsTableApiKeys {
	ActionColor = 'ActionColor',
	AssignedToUserId = 'AssignedToUserId',
	AssignedToUserName = 'AssignedToUserName',
	ClientId = 'ClientId',
	ClientName = 'ClientName',
	CompletedDate = 'CompletedDate',
	CreatedDate = 'CreatedDate',
	DaysSinceLastAction = 'DaysSinceLastAction',
	Description = 'Description',
	Id = 'Id',
	IsComplete = 'IsComplete',
	IsHighPriority = 'IsHighPriority',
	LastTicketActionDate = 'LastTicketActionDate',
	LocationCount = 'LocationCount',
	PostponeUntil = 'PostponeUntil',
	ReminderTime = 'ReminderTime',
	TicketStateChanged = 'TicketStateChanged',
	TicketStateId = 'TicketStateId',
	TicketStateName = 'TicketStateName',
	TicketTypeId = 'TicketTypeId',
	TicketTypeName = 'TicketTypeName',
	Title = 'Title',

	// Additional keys
	classList = 'classList',
	isHighPriorityString = 'isHighPriorityString',
	lastActionDateView = 'lastActionDateView',
	rowBgColor = 'rowBgColor',
	take = 'take'
}
