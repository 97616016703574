import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import { AdminDataService } from '../../core/services';
import { ContractRenewalReport } from '../../shared/api-models/reporting';
import { RenewalsService, TableConfigService } from './_services';
import { Column } from 'src/app/shared/components/table/view-models';

type NameAndId = { Name: string, Id: number };

@Component({
    selector: 'app-contract-renewals-report',
    templateUrl: './contract-renewals-report.component.html',
    styleUrls: ['./contract-renewals-report.component.scss'],
    providers: [RenewalsService, TableConfigService]
})
export class ContractRenewalsReportComponent implements OnInit {

    public activeSalesmen: NameAndId[];
    public contractTypes: NameAndId[];
    public hideStatsExempt: boolean;
    public nextDays: NameAndId[];
    public reportData: ContractRenewalReport[];
    public spinnerActive: boolean;
    public today: string = moment(new Date).format("M/D/YYYY");

    private contractTypeId: number = -1;
    private days: number = 60;
    private salespersonId: number = -1;

    public selectedSalesperson: NameAndId = {Name: 'All', Id: -1};
    public selectedContractType: NameAndId = {Name: 'All', Id: -1};;
    public selectedNextDays: NameAndId = {Name: 'All', Id: -1};;

    constructor(
        private activatedRoute: ActivatedRoute,
        public adminDataService: AdminDataService,
        public renewalsService: RenewalsService,
        public tableConfigService: TableConfigService
    ) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe((param) => {
            this.salespersonId = param.salespersonId ? param.salespersonId : -1;

            this.renewalsService.getSalesmenAndContractTypes()
                .subscribe((res) => {
                    let activeSalesmen: NameAndId[] = res[0];
                    let contractTypes = res[1];

                    this.activeSalesmen = activeSalesmen;
                    this.selectedSalesperson = this.activeSalesmen.find(s => s.Id === +this.salespersonId);

                    //If url salespersonId param is not linked to a current salespersonId,
                    //set back to -1;
                    if(!this.selectedSalesperson) {
                        this.salespersonId = -1;
                    }

                    this.contractTypes = contractTypes.map((item) => {
                        return { Name: item.Name, Id: item.Id };
                    });
                    this.contractTypes.unshift({ Name: 'All Products', Id: null });

                    this.spinnerActive = true;
                    this.nextDays = [
                        { Name: 'Next 30 days', Id: 30 }, { Name: 'Next 60 days', Id: 60 }, { Name: 'Next 90 days', Id: 90 }, { Name: 'Next 120 days', Id: 120 }, { Name: 'All', Id: 50000 }
                    ]
                    this.getReport(this.reportUrl(), true);
                })
        })
    }

    onCompanyNameClick(payload: [any, Column]): void {
        let report: ContractRenewalReport = payload[0];
        localStorage.setItem('changeClientUrl', `/crm/client-details/locations/`);
        window.open(`/my-products/${report.ClientId}`);
    }

    onDropdownSelect(nameAndId: NameAndId, stringId: string, viewProp: string): void {
        this[viewProp].Name = nameAndId.Name;
        this[stringId] = nameAndId.Id;
        this.getReport(this.reportUrl())
    }


    public onHideStatsExemptClick(): void {
        this.hideStatsExempt = !this.hideStatsExempt;
        if (this.hideStatsExempt) {
            this.reportData = this.reportData.filter(item => !item.IsExempt);
        } else {
            this.reportData = this.renewalsService.cachedData;
        }
    }

    private getReport(url: string, onInit?: boolean): void {
        this.renewalsService.getReport(url)
            .subscribe((report: ContractRenewalReport[]) => {
                this.setData(report);
                onInit ? this.onHideStatsExemptClick() : null;
                this.spinnerActive = false;
            });
    }

    private reportUrl(): string {
        if (this.contractTypeId) {
            return `Reports/Renewal?salespersonId=${this.salespersonId}&contractTypeId=${this.contractTypeId}&days=${this.days}&hideExempt=false`;
        }
        return `Reports/Renewal?salespersonId=${this.salespersonId}&days=${this.days}&hideExempt=false`;
    }

    private setData(report: ContractRenewalReport[]): void {
        this.reportData = report;
        this.renewalsService.cachedData = report;
    }

}
