import { ClipsView } from './clips-view';
import { ContentRequest, ContentRequestAttachments } from '../../../shared/api-models/admin';

export class ContentRequestEdit extends ContentRequest {
	clips?: ClipsView[];
	comments?: any[];
	clientName?: string;
	csrUsername?: string;
	producerUsername?: string;
	requestApprovalFromUsername?: string;
	requestApprovalFromEmail?: string;
	requestedByUsername?: string;
	requestedByEmail?: string;
	requestedByFriendlyName?: string;
	requestedByIsEmployee?: boolean;
	csrFriendlyName?: string;
	targetLibraryName?: string;
	clientStatusView?: string;
	createDateView?: string;
	bypassScriptWriter?: boolean;
	csrEmail?: string;
	producerEmail?: string;
	approvalInstructionsVisible?: boolean;
	showInstructionsBtnVisible?: boolean;
	isMergedRequest?: boolean;
	attachments?: ContentRequestAttachments[];
	voiceTalentView?: string;
}
