import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import Chart, { ChartType } from 'chart.js/auto';

import { environment } from 'src/environments/environment';
import { AdminDataService, AppStateService, DateTimeService, UtilitiesService } from 'src/app/core/services';
import { mergeMap } from 'rxjs/operators';
import { Users } from 'src/app/shared/api-models/admin';
import { GumballCircle } from './gumball-circle.model';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

type NameAndId = { Name: string; Id: number };

@Injectable({
	providedIn: 'root'
})
export class ClientHealthDashboardService {
	private csrSelectSource = new Subject<any>();
	public csrSelect$ = this.csrSelectSource.asObservable();

	public statusCircles: GumballCircle[];

	public gumballClientsAll: GumballCircle[];
	public gumballClientsRed: GumballCircle[];
	public gumballClientsOrange: GumballCircle[];
	public gumballClientsGreen: GumballCircle[];
	public gumballClientsPurple: GumballCircle[];
	public gumballClientsBlue: GumballCircle[];

	public gumballPlayers: GumballCircle[];
	public gumballPlayersRed: GumballCircle[];
	public gumballPlayersOrange: GumballCircle[];
	public gumballPlayersGreen: GumballCircle[];
	public gumballPlayersPurple: GumballCircle[];
	public gumballPlayersBlue: GumballCircle[];

	public playersMIACount: number;
	public playersNotInstalledCount: number;
	public playersNotFixedCount: number;
	public outdatedPlayersCount: number;
	public openTicketCount: number;
	public openContentRequestCount: number;

	public csrList: NameAndId[];
	public selectedUser: NameAndId = { Name: 'All Clients', Id: 0 };

	public clientDoughnutChart: Chart;
	public playersDoughnutChart: Chart;

	public hoveredClient: GumballCircle;
	public selectedClient: GumballCircle;
	public showClientDetails: boolean;

	public backBtnClicked: boolean;
	public currentDateTime: string;

	public timer: any;

	constructor(
		private adminDataService: AdminDataService,
		private appStateService: AppStateService,
		private dateTimeService: DateTimeService,
		private httpClient: HttpClient,
		private utilService: UtilitiesService
	) {}

	isGumballClients(obj: any): obj is GumballCircle {
		return 'ClientId' in obj;
	}

	isGumballPlayers(obj: any): obj is GumballCircle {
		return 'PlayerId' in obj;
	}

	public onCsrSelect(): void {
		this.csrSelectSource.next();
	}

	public pageInit(userId: number, doughnutChart?: any, csrHomeView?: boolean): void {
		this.onInterval(userId, doughnutChart);

		this.timer = setInterval(() => {
			this.onInterval(userId, doughnutChart);
		}, 900000); // 15 min.

		this.adminDataService.getAllCSRs().subscribe((csrList: NameAndId[]) => {
			this.csrList = null;
			this.csrList = csrList;
			if (!csrHomeView) {
				this.csrList.unshift({ Name: 'All Clients', Id: 0 });
			}
		});
	}

	private onInterval(userId: number, doughnutChart?: any): void {
		this.clientDoughnutChart?.destroy();
		this.getUserAndClients(userId, doughnutChart);
		this.currentDateTime = this.dateTimeService.dateAndTimeLocal(new Date().toString());
	}

	//If getting all clients (userId == 0), don't also get user
	//If getting by userId from the url, grab the user
	private observ$(userId: number): Observable<GumballCircle[]> {
		if (userId > 0) {
			return this.httpClient.get<Users>(environment.adminUrl + `CoreUsers/${userId}`).pipe(
				mergeMap((res: Users) => {
					let user = res;
					this.selectedUser.Id = user.Id;
					this.selectedUser.Name = user.ContactInformation.FirstName + ' ' + user.ContactInformation.LastName;
					return this.httpClient.get<GumballCircle[]>(environment.adminUrl + `Reports/Gumball/Clients/${this.selectedUser.Id}`);
				})
			);
		} else {
			return this.httpClient.get<GumballCircle[]>(environment.adminUrl + `Reports/Gumball/Clients/${this.selectedUser.Id}`);
		}
	}

	public getUserAndClients(userId: number, cxt?: CanvasRenderingContext2D): void {
		this.observ$(userId).subscribe((gumballClients: GumballCircle[]) => {
			this.gumballClientsRed = gumballClients.filter((client) => client.GumballColor === `#FF0000`);
			this.gumballClientsGreen = gumballClients.filter((client) => client.GumballColor === `#00FF00`);
			this.gumballClientsOrange = gumballClients.filter((client) => client.GumballColor === `#FFA500`);
			this.gumballClientsPurple = gumballClients.filter((client) => client.GumballColor === `#800080`);
			this.gumballClientsBlue = gumballClients.filter((client) => client.GumballColor === `#0000FF`);

			gumballClients = gumballClients.map((client) => {
				client.gumballColorView = this.gumballColor(client.GumballColor);
				client.ruleFailureListView = client.RuleFailureList.replace(/;/g, '<br>');
				client.showX = this.utilService.includes(client.RuleFailureList, 'CX1') || this.utilService.includes(client.RuleFailureList, 'PX1');
				return client;
			});
			this.gumballClientsAll = this.utilService.mapOrder(gumballClients, this.sortOrder(), 'gumballColorView');
			this.setStats();
			//CSR Home page does not have a chart
			if (cxt) {
				this.clientDoughnutChartInit(cxt);
			}
			this.statusCircles = this.gumballClientsAll;
			this.backBtnClicked = false;
		});
	}

	private setStats(): void {
		this.playersMIACount = this.gumballClientsAll.reduce((accum, item) => accum + item.PlayerNotCheckedInCount, 0);
		this.playersNotInstalledCount = this.gumballClientsAll.reduce((accum, item) => accum + item.PlayerNotInstalledCount, 0);
		this.playersNotFixedCount = this.gumballClientsAll.reduce((accum, item) => accum + item.PlayerNotFixedCount, 0);
		this.outdatedPlayersCount = this.gumballClientsAll.reduce((accum, item) => accum + item.PlayerNotOnCurrentVersionCount, 0);
		this.openTicketCount = this.gumballClientsAll.reduce((accum, item) => accum + item.OpenTicketCount, 0);
		this.openContentRequestCount = this.gumballClientsAll.reduce((accum, item) => accum + item.OpenContentRequestCount, 0);
	}

	public gumballColor(gumballColor: string): string {
		switch (gumballColor) {
			//green
			case `#00FF00`:
				return 'client-health-green';
			//orange
			case `#FFA500`:
				return 'client-health-orange';
			//red
			case `#FF0000`:
				return 'client-health-red';
			case `#0000FF`:
				return 'client-health-blue';
			//purple
			case `#800080`:
				return 'client-health-purple';
		}
	}

	public getPlayersByClient(clientId: number, cxt?): void {
		this.httpClient.get(environment.adminUrl + `Reports/Gumball/Players/${clientId}`).subscribe((players: GumballCircle[]) => {
			this.gumballPlayersRed = players.filter((client) => client.GumballColor === `#FF0000`);
			this.gumballPlayersGreen = players.filter((client) => client.GumballColor === `#00FF00`);
			this.gumballPlayersOrange = players.filter((client) => client.GumballColor === `#FFA500`);
			this.gumballPlayersPurple = players.filter((client) => client.GumballColor === `#800080`);
			this.gumballPlayersBlue = players.filter((client) => client.GumballColor === `#0000FF`);

			players = players.map((player) => {
				player.ruleFailureListView = player.RuleFailureList.replace(/;/g, '<br>');
				player.gumballColorView = this.gumballColor(player.GumballColor);
				player.showX = this.utilService.includes(player.RuleFailureList, 'CX1') || this.utilService.includes(player.RuleFailureList, 'PX1');
				return player;
			});

			this.gumballPlayers = this.utilService.mapOrder(players, this.sortOrder(), 'gumballColorView');
			this.statusCircles = this.gumballPlayers;
			if (!!cxt) {
				this.playersDoughnutChartInit(cxt);
			}
		});
	}

	private sortOrder(): string[] {
		return ['client-health-red', 'client-health-orange', 'client-health-green', 'client-health-blue', 'client-health-purple'];
	}

	public getSingleClient(clientId: number): Observable<GumballCircle[]> {
		return this.httpClient.get<GumballCircle[]>(environment.adminUrl + `Reports/Gumball/Client/${clientId}`);
	}

	public clientDoughnutChartInit(ctx: CanvasRenderingContext2D): void {
		this.clientDoughnutChart = new Chart(ctx, {
			type: 'doughnut' as ChartType,
			data: {
				labels: ['Critical', 'In Repair', 'OK', 'Fickle', 'Idle'],
				datasets: [
					{
						label: 'My First Dataset',
						data: [
							this.gumballClientsRed.length,
							this.gumballClientsOrange.length,
							this.gumballClientsGreen.length,
							this.gumballClientsBlue.length,
							this.gumballClientsPurple.length
						],
						backgroundColor: ['#ff6384', '#FFA347', '#4BC0C0', '#0D5EAB', '#9A18C9'],
						hoverOffset: 4
					}
				]
			},
			options: {
				responsive: true,
				plugins: {
					legend: {
						display: false
					}
				},
				maintainAspectRatio: false
			}
		});
	}

	public playersDoughnutChartInit(ctx): void {
		this.playersDoughnutChart = new Chart(ctx, {
			type: 'doughnut' as ChartType,
			data: {
				labels: ['Critical', 'In Repair', 'OK', 'Fickle', 'Idle'],
				datasets: [
					{
						label: 'My First Dataset',
						data: [
							this.gumballPlayersRed.length,
							this.gumballPlayersOrange.length,
							this.gumballPlayersGreen.length,
							this.gumballPlayersBlue.length,
							this.gumballPlayersPurple.length
						],
						backgroundColor: ['#ff6384', '#FFA347', '#4BC0C0', '#0D5EAB', '#9A18C9'],
						hoverOffset: 4
					}
				]
			},
			options: {
				responsive: true,
				plugins: {
					legend: {
						display: false
					}
				},
				maintainAspectRatio: false
			}
		});
	}

	public clientHealthDashUserId(): number {
		let currentUser = this.appStateService.currentUser;
		if (currentUser.Role === UserRole.CSR) {
			return currentUser.UserId;
		}
		return 0;
	}
}
