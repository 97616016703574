import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AppStateService, Events, MessageService, UtilitiesService } from '../../core/services';
import { environment } from '../../../environments/environment';
import { Feeds, FeedItems, FeedSources, FeedProperties } from '../../shared/api-models/content';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

@Injectable({
	providedIn: 'root'
})
export class MyFeedsService {
	public feeds: Feeds[];
	public feedType: string;
	public totalItems: number;
	public url: string;

	constructor(
		private appStateService: AppStateService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private utilService: UtilitiesService
	) {}

	public getFeedsByClient(): Observable<Feeds[]> {
		return this.httpClient.get<Feeds[]>(`${environment.contentUrl}Feeds/Client/${this.appStateService.currentClient.Id}?includeGlobals=true`).pipe(
			mergeMap((feedsArr: Feeds[]) => {
				const filteredFeeds: Feeds[] = feedsArr.filter((feed) => this.feedsFilter(feed));
				const filteredByType: Feeds[] = filteredFeeds.filter((feed) => this.isCompatible(feed));
				const filteredForSalesmen: Feeds[] = filteredByType.filter((feed) => this.salesmenFilter(feed));
				const feedItems$ = filteredForSalesmen.map((feed: Feeds) => this.httpClient.get(`${environment.contentUrl}Feeds/${feed.Id}/feedItems?pageSize=10000`));
				const feedSources$ = filteredForSalesmen.map((feed: Feeds) => this.httpClient.get(`${environment.contentUrl}FeedSources/${feed.FeedSourceId}`));
				const feedProperties$ = filteredForSalesmen.map((feed: Feeds) => this.httpClient.get(`${environment.contentUrl}feeds/${feed.Id}/Properties`));

				if (filteredByType.length < 1) {
					return of([]);
				}

				const observs$ = [].concat.apply([], [feedItems$, feedSources$, feedProperties$]);
				return forkJoin(observs$).pipe(
					map((res: any) => {
						const feedItemsArr: FeedItems[][] = res.slice(0, filteredByType.length);
						const feedSourcesArr: FeedSources[] = res.slice(filteredByType.length, filteredByType.length * 2);
						const feedPropertiesArr: FeedProperties[][] = res.slice(filteredByType.length * 2);

						return this.utilService.sortAscOrDesc(this.feedsView(filteredByType, feedItemsArr, feedSourcesArr, feedPropertiesArr), 'CreateDate', 'desc');
					})
				);
			})
		);
	}

	private isCompatible(feed: Feeds): boolean {
		switch (true) {
			case this.utilService.includes(this.url, 'playlist-data'):
				const compatibleFeedSourceIds: number[] = [10, 12, 13, 14, 1000002];
				return compatibleFeedSourceIds.some((id) => feed.FeedSourceId === id);

			case this.utilService.includes(this.url, 'sidebar'):
				return feed.FeedSourceId === 1000008;

			case this.utilService.includes(this.url, 'ticker-messages'):
				return feed.FeedSourceId === 1000005;

			case this.utilService.includes(this.url, 'ticker-stocks'):
				return feed.FeedSourceId === 8;
		}
	}

	public deleteFeed(selectedFeed: Feeds, view: 'feedsList' | 'feedItems', location?: Location): void {
		const nonDeletableFeedSourceIds: number[] = [10, 12, 13, 14];
		const isDeletable: boolean = !nonDeletableFeedSourceIds.some((id) => selectedFeed.FeedSourceId === id);
		if (isDeletable) {
			const confirmOk: boolean = confirm(`Delete feed "${selectedFeed.Name}"?`);
			if (confirmOk) {
				this.messageService.publish(Events.savingPreloader, 1);
				this.httpClient
					.patch(`${environment.contentUrl}feeds/${selectedFeed.Id}`, {
						IsDeleted: true
					})
					.subscribe(() => {
						this.messageService.publish(Events.savingPreloader, 0);
						if (view === 'feedsList') {
							this.feeds = this.feeds.filter((feed) => feed.Id !== selectedFeed.Id);
						}
						if (view === 'feedItems') {
							location.back();
						}
					});
			}
		} else {
			alert('This feed cannot be deleted!');
		}
	}

	public getFeedTickers(feedId: number): Observable<any> {
		return this.httpClient.get(`${environment.contentUrl}Feeds/${feedId}/Tickers`);
	}

	private feedsFilter(feed: Feeds): boolean {
		return (
			!feed.IsDeleted &&
			(feed.FeedSourceId > 999 ||
				feed.FeedSourceId === 8 ||
				feed.FeedSourceId === 10 ||
				feed.FeedSourceId === 12 ||
				feed.FeedSourceId === 13 ||
				feed.FeedSourceId === 14)
		);
	}

	private salesmenFilter(feed: Feeds): boolean {
		if (this.appStateService.currentUser.Role === UserRole.SALESPERSON) {
			return feed.FeedSourceId !== 10 && feed.FeedSourceId !== 12 && feed.FeedSourceId !== 13 && feed.FeedSourceId !== 14;
		}
		return true;
	}

	public findNewestFeedItemDate(feedItems: FeedItems[]): string {
		if (feedItems.length > 0) {
			feedItems.sort((a: any, b: any) => {
				a = new Date(a.CreateDate);
				b = new Date(b.CreateDate);
				return a > b ? -1 : a < b ? 1 : 0;
			});
			return feedItems[0].CreateDate;
		}
	}

	private feedsView(feedsArr: Feeds[], feedItemsArr: FeedItems[][], feedSourcesArr: FeedSources[], feedPropertiesArr: FeedProperties[][]): Feeds[] {
		return feedsArr.map((feed, index) => {
			feed.editBtn = 'Edit';
			feed.deleteBtn = 'Delete';
			feed['isDeletable'] = [10, 12, 13, 14].some((feedSourceId) => feedSourceId === feed.FeedSourceId);
			feed.classList = 'white-bg t-row pd-top10 pd-bottom10';
			feed.activeItemsCount = this.activeItemsCount(feed, feedPropertiesArr[index]);
			feed.newestItemDate = this.findNewestFeedItemDate(feedItemsArr[index]);
			feed.feedItemsCount = feedItemsArr[index].length > 0 ? feedItemsArr[index].length : 0;
			feed.feedType = `<i class="fa ${feedSourcesArr[index].Icon} ${feedSourcesArr[index].IconColor} mgn-right5" aria-hidden="true"></i> ${feedSourcesArr[index].Name}`;
			return feed;
		});
	}

	public activeItemsCount(feed: Feeds, feedProperties: FeedProperties[]): string {
		if (feed.FeedSourceId === 8 && feedProperties[0]) {
			//stock feeds
			return JSON.parse(feedProperties[0].Value).length;
		}

		if (feed.FeedSourceId !== 8 && feedProperties[0]) {
			return feedProperties[0].Value;
		}

		if (feed.FeedSourceId !== 8 && !feedProperties[0]) {
			return '0';
		}
	}
}
