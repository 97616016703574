import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppStateService } from '../../core/services';
import { PlaylistService } from './playlist.service';
import { environment } from '../../../environments/environment';
import { LibrariesService } from 'src/app/shared/components/library-tree/_services';
import { ClientIds } from 'src/app/shared/api-models/admin/client-ids.enum';
import { ProductRoute } from 'src/app/shared/api-models/admin/product-route.enum';

@Injectable({
	providedIn: 'root'
})
export class PlayerCapacityService {
	public disableSave: boolean;
	public highestMaxLength: number;
	public lowestMaxLength: number;
	public showWarning: boolean;
	public warningMessage: string;

	constructor(
		private appStateService: AppStateService,
		private httpClient: HttpClient,
		private librariesService: LibrariesService,
		private playlistService: PlaylistService
	) {}

	public loadFeaturedPlaylistBtnEnabled(): boolean {
		const featuredPlaylistLength: number = this.librariesService.selectedFeaturedPlaylist.PlaylistFiles.length;
		const pbPlaylistLength = this.playlistService.playlist.contentItems.length;
		const maxPlaylistLength = this.appStateService.currentClient.MaxVideoPlaylistLength;
		const diff = maxPlaylistLength - pbPlaylistLength;

		if (this.appStateService.product.Route !== ProductRoute.HOLD) {
			if (featuredPlaylistLength < diff + 1) {
				return true;
			}
			return false;
		}
		return !this.showWarning;
	}

	public getPlayerCapacityRange = (): void => {
		if (this.appStateService.product.Route === ProductRoute.HOLD) {
			this.getHoldPlayerCapacityRange().subscribe((response) => {
				this.lowestMaxLength = response.LowestMaxLength;
				this.highestMaxLength = response.HighestMaxLength;
				this.warningMessage = this.setWarningMessage();
			});
		} else {
			this.warningMessage = this.setWarningMessage();
		}
	};

	private getHoldPlayerCapacityRange(): Observable<any> {
		let url: string;
		//if not a works24 employee, pass in userId
		if (this.appStateService.currentClient.Id !== ClientIds.WORKS24_OFFICE_ACCOUNT) {
			url = `CorePlayers/HoldPlayerCapacityRange/?clientId=${this.appStateService.currentClient.Id}&userId=${this.appStateService.currentUser.UserId}`;
		} else {
			url = `CorePlayers/HoldPlayerCapacityRange/?clientId=${this.appStateService.currentClient.Id}`;
		}
		return this.httpClient.get(environment.adminUrl + url);
	}

	private playlistLongerThanHighestMaxLength(): boolean {
		//This method not called on page refresh, set highest max to 3600 by default
		this.highestMaxLength = !this.highestMaxLength ? 3600 : this.highestMaxLength;
		return this.playlistService.getPlaylistDuration() > this.highestMaxLength * 1000;
	}

	private longerThanLowestShorterThanHighest(): boolean {
		return (
			this.playlistService.getPlaylistDuration() > this.lowestMaxLength * 1000 && this.playlistService.getPlaylistDuration() < this.highestMaxLength * 1000
		);
	}

	public setWarningMessage(): string {
		if (this.appStateService.product.Route === ProductRoute.HOLD) {
			switch (true) {
				case this.longerThanLowestShorterThanHighest():
					this.showWarning = true;
					this.disableSave = false;
					return 'This playlist is too long for at least one of your players!';

				case this.playlistLongerThanHighestMaxLength():
					this.disableSave = true;
					return `You've reached the max allowed number of messages!`;
				default:
					this.showWarning = false;
					this.disableSave = false;
					return;
			}
		} else {
			if (this.playlistService.playlist.contentItems && this.playlistService.playlist.contentItems.length > 0) {
				if (this.playlistService.playlist.contentItems.length >= this.appStateService.currentClient.MaxVideoPlaylistLength) {
					this.showWarning = true;
					return `You've reached the max allowed number of clips!`;
				} else {
					this.showWarning = false;
				}
			} else {
				this.showWarning = false;
			}
		}
	}
}
