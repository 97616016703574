<div *ngIf="!searchActive" class="row">
	<div *ngIf="!nameEditable" class="col-xs-12 ht20 in-block mgn-bottom14 cursor-text" (click)="onNameClick()">
		<i class="fa fa-tags white-text pd-right6" aria-hidden="true"></i>
		<h4 class="ft-size16 in-block">{{ group.Name }}</h4>
	</div>

	<div *ngIf="nameEditable" class="in-block col-xs-12">
		<input
			type="text"
			style="width: initial"
			autofocus="autofocus"
			[(ngModel)]="group.Name"
			class="group-name mgn-right15 {{ appStateService.product.color }}-bg relative z"
			(keyup.enter)="onGroupNameSaveClick()"
		/>
		<button style="width: initial" class="limeGreen-bg border-radius white-text pd-right6 pd-left6 mgn-right6" (click)="onGroupNameSaveClick()">Save</button>
		<span><em>Enter your group name and hit save!</em></span>
		<a class="mgn-left22" (click)="nameEditable = false"><i class="fa fa-times ft-size14 dark-text" aria-hidden="true"></i></a>
	</div>

	<button
		*ngIf="group.players?.length < 1"
		ngxTippy
		tippyName="{{ utilService.guid | wrapFn }}"
		data-tippy-content="Delete group"
		[tippyProps]="{ appendTo: 'parent', placement: 'left' }"
		class="white-bg dark-text right mgn-top4 mgn-right25 ht25 wdth25 relative"
		(click)="onDeleteGroupClick()"
	>
		<i class="far fa-trash-alt ft-size14 absolute center-unknown-height-width" aria-hidden="true"></i>
	</button>
</div>

<div
	*ngIf="!searchActive"
	dragula="group-bag"
	id="{{ dragContainerId }}"
	class="relative z"
	(mouseover)="onDropAreaHover()"
	(mouseleave)="dropAreaVisible = false"
	(dragleave)="dropAreaVisible = false"
>
	<div *ngFor="let player of group.players">
		<gm-player [group]="group" [showRemoveBtn]="true" [player]="player"></gm-player>
	</div>
</div>

<!--Search results only-->
<div
	*ngIf="searchActive && group"
	id="{{ dragContainerId }}"
	class="pd-top10"
	dragula="group-bag"
	(mouseover)="onDropAreaHover()"
	(mouseleave)="dropAreaVisible = false"
	(dragleave)="dropAreaVisible = false"
>
	<i class="fa fa-tags white-text pd-right6" aria-hidden="true"></i>
	<h4 class="ft-size16 mgn-bottom14 in-block">{{ group.Name }}</h4>

	<div *ngFor="let player of group.players">
		<gm-player [group]="group" [player]="player" [showRemoveBtn]="true"></gm-player>
	</div>
</div>
