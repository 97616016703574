import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppStateService } from '../core/services';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ContactUsService {

    public message: {subject: string, text: string};

    constructor(
            private appStateService: AppStateService,
            private httpClient: HttpClient
    ) { }

    public sendEmail(recipient: {name: string, email: string}): Observable<any> {
        const obj = {
            To: recipient.email,
            From: 'portalcontactform@works24.com',
            Subject: this.message.subject,
            Body: this.body()
        }
        //send email
        return this.httpClient.post(environment.adminUrl + 'Email', obj);
    }

    private body(): string {
        return `${this.appStateService.currentUser.FirstName} ${this.appStateService.currentUser.LastName} from ${this.appStateService.currentClient.Name} has submitted a contact request form. \n \n \n` + 
        this.message.text;
    }
}
