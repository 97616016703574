<portal-header></portal-header>

<div class="container-fluid mx-4 mgn-top30">
	<!--**************************
		PAGE TITLE
	***************************-->
	<div class="row mgn-bottom15 relative">
		<a *ngIf="usageReportsStore.showBackBtn$ | async" (click)="onBackClick()" class="dark-text absolute topneg21">
			<i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
			<span class="ft-size16">Back</span>
		</a>
		<h1 class="semi-bold">
			<i class="fas fa-chart-bar mgn-right15" aria-hidden="true"></i>
			Usage Reports
		</h1>
	</div>

	<div class="row orange-bg ht2 border-radius"></div>

	<div class="row pd15 border-radius white-bg shadow mgn-top30">
		<router-outlet></router-outlet>
	</div>
</div>
