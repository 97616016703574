import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'portal-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
	@Input() backgroundColor: string;
	@Input() height: string;
	@Input() isChecked: boolean;
	@Input() isDisabled: boolean;
	@Input() type: string;
	@Input() width: string;
	@Output() checkboxClickNotify: EventEmitter<boolean> = new EventEmitter<boolean>();

	public hasHeightWidth: boolean;

	constructor() {}

	ngOnInit() {
		if (this.height && this.width) {
			this.hasHeightWidth = true;
		}
	}

	public onCheckClick(): void {
		if (!this.isDisabled) {
			this.checkboxClickNotify.emit(this.isChecked);
		}
	}
}
