import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AppStateService } from './app-state.service';
import { ContentLibraries } from '../../shared/api-models/content';
import { environment } from '../../../environments/environment';
import { TimesOfDay } from '../../shared/api-models/view/schedule-data';
import { FeatureArea } from 'src/app/shared/view-models';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';
import { ProductRoute } from 'src/app/shared/api-models/admin/product-route.enum';

@Injectable({
	providedIn: 'root'
})
export class UtilitiesService {
	constructor(private appStateService: AppStateService, private deviceDetectorService: DeviceDetectorService, private httpClient: HttpClient) {}

	public activeFeatureIsC24(): boolean {
		return this.appStateService.activeFeatureArea === FeatureArea.CREATE_24;
	}

	public addHttp(url: string): string | null {
		let pattern = /^((http|https):\/\/)/;
		if (!pattern.test(url)) {
			return 'http://';
		}
	}

	public autoCloseDropdown(prop: boolean, classStr: string, event: any): void {
		if (this.closest(event, classStr)) {
		}
		prop = false;
	}

	public calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
		const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
		return {
			width: Math.floor(srcWidth * ratio),
			height: Math.floor(srcHeight * ratio)
		};
	}

	//IE supported version of .closest()
	public closest(el, selector): boolean {
		let matchesFn;

		// find vendor prefix
		['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
			if (typeof document.body[fn] == 'function') {
				matchesFn = fn;
				return true;
			}
			return false;
		});

		let parent;

		// traverse parents
		while (el) {
			parent = el.target;
			if (parent && parent[matchesFn] && parent[matchesFn](selector)) {
				return parent;
			}
			el = parent;
		}
		return null;
	}

	public changeClient(clientId: number, openNewTab?: boolean, newTabUrl?: string): void {
		let authUrl: string = environment.authUrl + 'Login/SwitchClient/' + clientId;
		let clientUrl: string = environment.adminUrl + 'CoreClients/' + clientId;

		forkJoin(this.httpClient.get(authUrl), this.httpClient.get(clientUrl)).subscribe((data) => {
			this.appStateService.setUserItem('newTabClientAndUser', JSON.stringify(data));
			if (openNewTab) {
				window.open(newTabUrl, '_blank');
			}
		});
	}

	//https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
	public dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	}

	//https://stackoverflow.com/questions/21987909/how-to-get-the-difference-between-two-arrays-of-objects-in-javascript
	public diffInTwoArrays(arr1: any[], arr2: any[], prop: any): any[] {
		return arr1.filter((x) => {
			return !arr2.some((y) => {
				return x[prop] === y[prop];
			});
		});
	}

	public displaysInPortal(library: ContentLibraries): boolean {
		return !library.HideFromPortal || this.appStateService.activeFeatureArea === FeatureArea.CREATE_24;
	}

	public emailValidation(email: string): string {
		let emailPattern: RegExp = new RegExp(/^.+@\S*(?!@).$/);
		let atSymbolCount: number = (email.match(/@/g) || []).length;

		if (atSymbolCount > 1) {
			return 'multiple addresses present';
		} else if (!emailPattern.test(email)) {
			return 'not valid';
		} else {
			return 'valid';
		}
	}

	public formatPhoneNumber(phoneNumber: string): string {
		var cleaned = ('' + phoneNumber).replace(/\D/g, '');
		var tenDigits = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		var sevenDigits = cleaned.match(/^(\d{3})(\d{4})$/);
		if (tenDigits) {
			return '(' + tenDigits[1] + ')' + ' ' + tenDigits[2] + '-' + tenDigits[3];
		} else if (sevenDigits) {
			return sevenDigits[1] + '-' + sevenDigits[2];
		}
		return null;
	}

	public formatTime(time: number, toFixed2?: boolean): string {
		// Hours, minutes and seconds
		const hrs = Math.floor(time / 3600);
		const mins = Math.floor((time % 3600) / 60);
		let secs;
		if (toFixed2) {
			secs = +(time % 60).toFixed(2);
		} else {
			secs = time % 60;
		}

		// Output like "1:01" or "4:03:59"const "123:03:59"
		let ret = '';

		if (hrs > 0) {
			ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
		}

		ret += '' + mins + ':' + (secs < 10 ? '0' : '');
		ret += '' + secs;
		return ret;
	}

	public getLastParam(url: string): string {
		return url.substr(url.lastIndexOf('/') + 1);
	}

	public getTimesOfDay(): any[] {
		let timesOfDay = TimesOfDay;
		return timesOfDay.map((time) => {
			let timeObj: any = {};
			timeObj.name = time;
			return timeObj;
		});
	}

	public hasExtension(fileName: string, extensions: string[]): boolean {
		return new RegExp('(' + extensions.join('|').replace(/\./g, '\\.') + ')$', 'i').test(fileName);
	}

	public hasWhiteSpace(string: string): boolean {
		return /\s/g.test(string);
	}

	public includes(container: any, value: string): boolean {
		if (container) {
			let index: number = container.indexOf(value);
			return index >= 0 ? true : false;
		}
	}

	public isEmptyObj(obj): boolean {
		return Object.keys(obj).length === 0;
	}

	public mapOrder(array, order, key): any[] {
		array.sort(function (a, b) {
			var A = a[key],
				B = b[key];

			if (order.indexOf(A) > order.indexOf(B)) {
				return 1;
			} else {
				return -1;
			}
		});
		return array;
	}

	public productTypeIdToContentLibraryTypeId(productTypeId: number): number {
		switch (productTypeId) {
			case 1: //on hold
				return 2;
			case 2: //works24 radio
				return 1;
			case 8: //other
				return null;
			default: //any video product
				return 3; //video-playable
		}
	}

	public lastSuccessfulDownloadTextColor(daysSinceUpdate: number): string {
		switch (true) {
			case daysSinceUpdate <= 30:
				return 'green-text';
			case daysSinceUpdate >= 31 && daysSinceUpdate <= 59:
				return 'yellow-text-darkest';
			case daysSinceUpdate >= 60 && daysSinceUpdate <= 89:
				return 'orange-text';
			case daysSinceUpdate >= 90:
				return 'red-text-light';
		}
	}

	public productColorGradient(productTypeId: number): string {
		if (productTypeId) {
			switch (productTypeId) {
				case 1:
					return 'blue-gradient';
				case 2:
					return 'purple-gradient';
				case 8:
					return 'limeGreen-blue-bg-darkest-gradient';
				default:
					return 'red-gradient';
			}
		}
		return 'limeGreen-blue-bg-darkest-gradient';
	}

	public removeLastParam(url: string): string {
		return url.slice(0, url.lastIndexOf('/'));
	}

	public scrollToTop = () => {
		const c = document.documentElement.scrollTop || document.body.scrollTop;
		if (c > 0) {
			window.requestAnimationFrame(this.scrollToTop);
			window.scrollTo(0, c - c / 8);
		}
	};

	public sortByProductTypeIdAndName(items: any): any[] {
		return items.sort((a, b) => {
			//sort first by productTypeId then alphabetically
			return a.ProductTypeId - b.ProductTypeId || a.name.localeCompare(b.name);
		});
	}

	public sortItems(items: any[], prop: any): any[] {
		if (items?.length === 0) {
			return [];
		}
		//http://bit.ly/sort-objects-in-array
		if (typeof items[0][prop] === 'string') {
			return items.sort((a, b) => {
				let textA = a[prop].toLocaleLowerCase();
				let textB = b[prop].toLocaleLowerCase();
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			});
		} else if (typeof items[0][prop] === 'number') {
			return items.sort((a, b) => {
				return a[prop] - b[prop];
			});
		}
	}

	//TODO: Update to this sortItems function as a separate issue
	// public sortItems<T>(items: T[], prop: keyof T): T[] {
	// 	if (items?.length === 0) {
	// 		return [];
	// 	}
	// 	if (typeof items[0][prop] === 'string') {
	// 		return items.sort((a, b) => {
	// 			let textA = (a[prop] as string).toLocaleLowerCase();
	// 			let textB = (b[prop] as string).toLocaleLowerCase();
	// 			return textA < textB ? -1 : textA > textB ? 1 : 0;
	// 		});
	// 	} else if (typeof items[0][prop] === 'number') {
	// 		return items.sort((a, b) => {
	// 			return (a[prop] as number) - (b[prop] as number);
	// 		});
	// 	}
	// 	return items; // Return the original array if prop is not a string or number
	// }

	public sortAscOrDesc(items: any[], prop: any, sortOrder: string): any[] {
		return items.sort((a, b) => {
			if (typeof a[prop] === 'string') {
				let textA = a[prop];
				let textB = b[prop];

				if (sortOrder === 'desc') {
					return textA > textB ? -1 : textA < textB ? 1 : 0;
				} else if (sortOrder === 'asc') {
					return textA < textB ? -1 : textA > textB ? 1 : 0;
				}
			} else if (typeof a[prop] === 'number') {
				if (sortOrder === 'desc') {
					return b[prop] - a[prop];
				} else if (sortOrder === 'asc') {
					return a[prop] - b[prop];
				}
			}
		});
	}

	//https://stackoverflow.com/questions/44582097/how-to-sort-array-by-date
	public sortByDate(items: any[], dateProp: string): any[] {
		return items.sort((a, b) => {
			switch (this.deviceDetectorService.browser) {
				case 'Firefox':
					return +new Date(a[this.lowercaseFirstLetter(dateProp)]) - +new Date(b[this.lowercaseFirstLetter(dateProp)]);

				default:
					// convert date object into number to resolve issue in typescript
					return +new Date(b[this.lowercaseFirstLetter(dateProp)]) - +new Date(a[this.lowercaseFirstLetter(dateProp)]);
			}
		});
	}

	public userRole(userRole: string): string {
		switch (userRole) {
			case UserRole.VIDEO_CONTENT_CONTRACTOR:
			case UserRole.VOICE_TALENT:
			case UserRole.AUDIO_CONTENT_CREATOR:
			case UserRole.VIDEO_CONTENT_CREATOR:
				return UserRole.CONTENT_CREATOR;
			default:
				return userRole;
		}
	}

	public validateZip(zip: string): Observable<boolean> {
		let isUSZip = /^\d+$/.test(zip); //checks if string is only numbers

		//https://stackoverflow.com/questions/15774555/efficient-regex-for-canadian-postal-code-function
		let isValidCanadianZip: boolean = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(zip);
		if (isUSZip) {
			return this.httpClient.get(`${environment.adminUrl}CoreZipCodeLookup/IsValid/${zip}`).pipe(
				map((res) => {
					return res ? true : false;
				})
			);
		} else if (isValidCanadianZip) {
			return of(true);
		} else {
			return of(false);
		}
	}

	private lowercaseFirstLetter(string: string): string {
		return string.charAt(0).toLowerCase() + string.slice(1);
	}

	public arrayPropIsUnique(arr: any[], prop: string): boolean {
		var tempArr = [];
		for (var obj in arr) {
			if (tempArr.indexOf(arr[obj][prop]) < 0) {
				tempArr.push(arr[obj][prop]);
			} else {
				return false; // Duplicate value for prop found
			}
		}
		return true; // No duplicate values found for prop
	}

	public trimEnd(str: string, char: string): string {
		if (str.endsWith(char)) {
			return str.slice(0, -1);
		}
		return str;
	}

	public playerIcon(): string {
		switch (this.appStateService.product.Route) {
			case ProductRoute.HOLD:
				return 'fas fa-phone-volume';
			case ProductRoute.RADIO:
				return 'fas fa-volume-up';
			default:
				return 'fas fa-tv';
		}
	}

	public guid(): string {
		const S4 = () => {
			return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
		};
		return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
	}
}
