import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from '../login.service';
import { AlertMessage, LoginView } from '../_models/login-models';
import { AppStateService, UtilitiesService } from 'src/app/core/services';

@Component({
	selector: 'l-main-view',
	templateUrl: './main-view.component.html',
	styleUrls: ['./main-view.component.scss']
})
export class MainViewComponent implements OnInit {
	public autoFocus: string;
	public password: string;
	public signInClicked: boolean;
	public username: string;

	constructor(public appStateService: AppStateService, public loginService: LoginService, private router: Router, public utilService: UtilitiesService) {}

	ngOnInit() {}

	public onSignInClick(): void {
		this.signInClicked = true;

		this.loginService.authenticateUser(this.username, this.password).subscribe(
			({ tfaImageUrl, manualEntryCode }: { tfaImageUrl: string; manualEntryCode: string }) => {
				const require2fa = this.appStateService.currentUser.IsEmployee || this.appStateService.currentClient.Require2fa;
				if (require2fa) {
					this.loginService.tfaImageUrl = tfaImageUrl;
					this.loginService.manualEntryCode = manualEntryCode;
					this.loginService.viewState = LoginView.AuthCode;
					this.loginService.alert = { ...this.loginService.alert, visible: false };
				} else {
					this.router.navigate(['/my-products']);
				}
			},
			(err) => {
				this.loginService.setAlert({
					visible: true,
					bgColor: 'gray-bg-dark',
					textColor: 'red-text-light',
					message: `${AlertMessage.InvalidCredentials} ${AlertMessage.Default}`,
					icon: 'fa-exclamation'
				});
				this.signInClicked = false;
				setTimeout(() => {
					this.loginService.setAlert({
						...this.loginService.alert,
						visible: false
					});
				}, 6000);
			}
		);
	}

	public onForgotPasswordClick(): void {
		this.loginService.viewState = LoginView.ForgotPassword;
		this.loginService.alert = { ...this.loginService.alert, visible: false };
	}
}
