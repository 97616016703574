export class ViewState {
	orderByCol: string;
	orderDesc: boolean;
	pageNumber: number;
	includeSystemGenerated: boolean;
	locationId: number;

	constructor(orderByCol: string, orderDesc: boolean, pageNumber: number, includeSystemGenerated: boolean, locationId: number) {
		this.orderByCol = orderByCol;
		this.orderDesc = orderDesc;
		this.pageNumber = pageNumber;
		this.includeSystemGenerated = includeSystemGenerated;
		this.locationId = locationId;
	}
}