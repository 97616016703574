import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SearchFilterPipe } from '../../../shared/pipes/search-filter.pipe';
import { UtilitiesService } from '../../../core/services';

@Component({
	selector: 'portal-dropdown-multi-select',
	templateUrl: './dropdown-multi-select.component.html',
	styleUrls: ['./dropdown-multi-select.component.scss']
})
export class DropdownMultiSelectComponent implements OnInit {
	@Input() backgroundColor: string;
	@Input() fontSize: string;
	@Input() height: string;
	@Input() isDisabled: boolean;
	@Input() key: string;
	@Input() outputKey: string;
	@Input() menuBackgroundColor: string;
	@Input() options: any[];
	@Input() selectedOption: any;
	@Input() textColor: string;

	@Output() dropdownClickNotify: EventEmitter<any> = new EventEmitter<any>();
	@Output() onOptionSelect: EventEmitter<any> = new EventEmitter<any>();

	public heightNum: number;
	public optionsVisible: boolean;
	public selectedCount: number = 0;
	public selectableOptions: any[];

	constructor(private utilService: UtilitiesService) {}

	ngOnInit(): void {
		this.heightNum = +this.height.slice(2);
		let optionsCopy = JSON.parse(JSON.stringify(this.options));
		this.selectableOptions = optionsCopy.map((option) => {
			option.isSelected = false;
			return option;
		});
	}

	public buttonColor(): string {
		switch (this.backgroundColor) {
			case 'gray-bg':
				return 'gray-bg-darker';

			case 'gray-bg-dark':
				return 'gray-bg-darkest';

			case 'white-bg':
				return 'gray-bg-darker';

			case 'white-bg':
				return 'lt-gray-bg-darker';
		}
	}

	public borderColor(): string {
		switch (this.backgroundColor) {
			case 'gray-bg-dark':
				return 'gray-border-dark';
			case 'white-bg':
				return 'gray-border-darker';

			default:
				return 'lt-gray-border-darker';
		}
	}

	public onDropdownClick(): void {
		if (!this.isDisabled) {
			this.optionsVisible = !this.optionsVisible;
		}
		this.dropdownClickNotify.emit();
	}

	public onOptionClick(option: any, name: any): void {
		if (name === 'Reset') {
			this.optionsVisible = false;
			this.selectedCount = 0;
			this.selectableOptions.forEach((o) => (o.isSelected = false));
			return;
		}
		option.isSelected = !option.isSelected;
		let outputArr = this.selectableOptions
			.map((o) => {
				if (o.isSelected) {
					return o;
				}
			})
			.filter((item) => item);
		this.selectedCount = this.selectableOptions.filter((o) => o.isSelected).length;
		this.onOptionSelect.emit(outputArr);
	}

	public autoCloseDropdown(event) {
		if (!this.utilService.closest(event, '.noExit')) {
			this.optionsVisible = false;
		}
	}
}
