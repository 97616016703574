<div class="row">
	<div class="col-sm-2 pd-top20">
		<button id="button-excel" (click)="onDownloadClick()" class="mgn-bottom30 orange-bg white-text">Download Excel File</button>
	</div>
	<div class="col-sm-2 pd-top20">
		<input type="text" #clipName (keyup)="onNameSearch(clipName.value)" placeholder="Filter by clip name..." />
	</div>
	<div class="col-sm-2 pd-top20">
		<portal-dropdown-menu
			[backgroundColor]="'gray-bg-dark'"
			[height]="'ht40'"
			[options]="(usageReportsStore.categories$ | async)?.categories"
			[scrollbarHidden]="true"
			[selectedOption]="categoryPlaceholder.CATEGORY"
			(onOptionSelect)="onCategorySelect($event, 'category')"
		></portal-dropdown-menu>
	</div>
	<div class="col-sm-2 pd-top20">
		<portal-dropdown-menu
			[backgroundColor]="'gray-bg-dark'"
			[height]="'ht40'"
			[options]="(usageReportsStore.categories$ | async)?.subCategories"
			[scrollbarHidden]="true"
			[selectedOption]="categoryPlaceholder.SUBCATEGORY"
			(onOptionSelect)="onCategorySelect($event, 'subCategory')"
		></portal-dropdown-menu>
	</div>
	<div class="col-sm-2">
		From create date:
		<portal-date-time-selector
			[backgroundColor]="'gray-bg'"
			[dateModel]="fromDate"
			[height]="'ht40'"
			(dateChanged)="onDateChanged($event, 'from')"
		></portal-date-time-selector>
	</div>
	<div class="col-sm-2">
		To create date:
		<portal-date-time-selector
			[backgroundColor]="'gray-bg'"
			[dateModel]="toDate"
			[height]="'ht40'"
			(dateChanged)="onDateChanged($event, 'to')"
		></portal-date-time-selector>
	</div>
</div>

<table #table id="simpleTable1" class="border-radius shadow" data-cols-width="10,12,10,30,10,10,12">
	<tbody>
		<tr class="ft-size12 gray-bg">
			<th data-f-bold="true" data-f-sz="10" class="semi-bold">Usage Count</th>
			<th data-f-bold="true" data-f-sz="10" class="semi-bold">Preview Count</th>
			<th data-f-bold="true" data-f-sz="10" class="semi-bold">Clip Name</th>
			<th data-f-bold="true" data-f-sz="10" class="semi-bold">Script</th>
			<th data-f-bold="true" data-f-sz="10" class="semi-bold">Library</th>
			<th data-f-bold="true" data-f-sz="10" class="semi-bold">Category</th>
			<th data-f-bold="true" data-f-sz="10" class="semi-bold">Sub-category</th>
			<th data-f-bold="true" data-f-sz="10" class="semi-bold">Report Date</th>
			<th data-f-bold="true" data-f-sz="10" class="semi-bold">Content File Create Date</th>
			<th data-f-bold="true" data-f-sz="10" class="semi-bold">ID</th>
		</tr>

		<tr
			*ngFor="
				let report of usageReportsStore.report$
					| async
					| paginate
						: {
								itemsPerPage: usageReportsStore.pageSize$ | async,
								currentPage: usageReportsStore.pageNumber$ | async,
								totalItems: usageReportsStore.totalItems$ | async
						  }
			"
		>
			<td data-t="n">
				<div style="border-radius: 40px; width: 65px" class="pd6 semi-bold orange-bg white-text text-center">
					{{ report.MasterDownloadCount }}
				</div>
			</td>
			<td data-t="n">
				<div style="border-radius: 40px; width: 65px" class="pd6 semi-bold green-bg white-text text-center">
					{{ report.PreviewDownloadCount }}
				</div>
			</td>
			<td data-a-wrap="true" class="regular">{{ report.ClipName }}</td>
			<td data-a-wrap="true" class="regular">{{ report.Script }}</td>
			<td data-a-wrap="true" class="regular">{{ report.LibraryName }}</td>
			<td data-a-wrap="true" class="regular">{{ report.Category }}</td>
			<td data-a-wrap="true" class="regular">{{ report?.SubCategory }}</td>
			<td data-a-wrap="true" class="regular">{{ report.Date | date : 'shortDate' }}</td>
			<td data-a-wrap="true" class="regular">{{ report.ContentFileCreationDate | date : 'shortDate' }}</td>
			<td data-t="n" class="regular">{{ report.ContentId }}</td>
		</tr>
	</tbody>
</table>

<div class="row mgn-top15">
	<div class="col-sm-6 text-left mgn-topneg16">
		<pagination-controls (pageChange)="onPageClick($event)" class="pagination"></pagination-controls>
	</div>
	<div class="col-sm-6 text-right">
		<span>
			<span class="semi-bold">Total Items:</span>
			{{ usageReportsStore.totalItems$ | async }}
		</span>
	</div>
</div>
<div class="row mgn-top15 mgn-bottom60">
	<div class="col-sm-1">
		<span>Go to page</span>
		<input
			class="setWidth100 ht40 gray-bg-dark ln-height40"
			#pageSelector
			name="pageSelector"
			[ngModel]="usageReportsStore.pageNumber$ | async"
			(input)="onPageClick(+pageSelector.value)"
			type="number"
		/>
	</div>
	<div class="col-sm-1">
		<span>Page size</span>
		<input
			class="setWidth100 ht40 gray-bg-dark ln-height40"
			#pageSize
			name="pageSize"
			[ngModel]="usageReportsStore.pageSize$ | async"
			(input)="onPageSizeEnter(+pageSize.value)"
			type="number"
		/>
	</div>
</div>
