import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Create24Area, Create24ContentType } from './_models/create-24-state.enum';
import { filter } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class Create24Service {
	public popup: 'content-browser' | 'export-to-library' | '' = '';
	public c24ActiveArea: Create24Area;

	constructor(private router: Router) {
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((_) => {
			const activeUrl: string = this.router.routerState.snapshot.url;
			const segments = activeUrl.split('/').filter((segment) => segment !== '');
			const c24AreaSegmentMatch =
				this.checkForPopup(segments) ?? (segments.find((segment) => Object.values(Create24Area).includes(segment as Create24Area)) as Create24Area);
			this.c24ActiveArea = c24AreaSegmentMatch ?? Create24Area.NONE;
		});
	}

	public contentType(): Create24ContentType {
		const contentType: Create24ContentType = this.router.routerState.snapshot.root.firstChild.params?.contentType;
		const { c24ActiveArea } = this;

		if (
			c24ActiveArea === Create24Area.EDIT_PROJECT ||
			c24ActiveArea === Create24Area.VIDEO_TEMPLATE ||
			c24ActiveArea === Create24Area.STILL_TEMPLATE ||
			c24ActiveArea === Create24Area.SIDEBAR_TEMPLATE ||
			c24ActiveArea === Create24Area.EDIT_PROJECT_SIDEBAR
		) {
			if (contentType === Create24ContentType.SIDEBAR) {
				return Create24ContentType.IMAGE;
			}
			return contentType;
		}
		switch (true) {
			case c24ActiveArea === Create24Area.NEW_VIDEO:
			case c24ActiveArea === Create24Area.NEW_VIDEO_REPLACE:
				return Create24ContentType.VIDEO;
			case c24ActiveArea === Create24Area.NEW_IMAGE_REPLACE:
			case c24ActiveArea === Create24Area.NEW_IMAGE:
			case c24ActiveArea === Create24Area.NEW_SIDEBAR:
				return Create24ContentType.IMAGE;
		}
	}

	private checkForPopup(segments: string[]): Create24Area | null {
		const containsPopup = segments.some((segment) => segment.includes('popup:'));

		if (containsPopup) {
			return this.removePopupPrefix(segments[segments.length - 1]);
		}
		return null;
	}

	private removePopupPrefix(segment: string): Create24Area {
		const startIndex = segment.indexOf(':') + 1;
		const endIndex = segment.indexOf(')');
		return segment.substring(startIndex, endIndex) as Create24Area;
	}
}
