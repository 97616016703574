import { Injectable } from '@angular/core';

import { Column, Table, TableConfig } from '../../../shared/components/table/view-models';

@Injectable({
	providedIn: 'root'
})
export class TableConfigService {
	public templatesColumns: Column[] = [
		new Column('editBtn', 'Edit', 'button', false, 'gray-bg setWidth50'),
		new Column('Name', 'Template Name', 'string', true),
		new Column('deleteBtn', 'far fa-trash-alt', 'button', false, 'white-bg red-text-light semi-bold ht40 setWidth50', true)
	];

	public templatesTable: Table = new Table(this.templatesColumns);

	public mailingsColumns: Column[] = [
		new Column('mailingsEditSendBtn', 'Edit/Send', 'button', false, 'gray-bg'),
		new Column('MailingName', 'Mailing Name', 'string', true),
		new Column('CreatedDate', 'Created', 'date', true),
		new Column('SendDate', 'Sent', 'date', true),
		new Column('TotalRecipients', 'Total', 'string', true),
		new Column('deleteBtn', 'far fa-trash-alt', 'button', false, 'white-bg red-text-light semi-bold ht40', true)
	];

	public mailingsTable: Table = new Table(this.mailingsColumns);

	public config: TableConfig = {
		itemsPerPage: 25,
		headerColor: 'green-bg',
		rowFontSize: 'ft-size15',
		headerFontSize: 'ft-size12'
	};
}
