<div class="pd30 border-radius relative white-bg">
	<a class="absolute right15 top2 pd10" style="z-index: 102;" (click)="exitClickNotify.emit()"><i class="fa fa-times right ft-size28 dark-text" aria-hidden="true"></i></a>
	<div class="row">
		<div class="col-xs-12 text-center">
			<h1 class="semi-bold">Scheduled Players &amp; Dates</h1>
		</div>
	</div>

	<div class="row mgn-top30">
		<div class="col-xs-6">
			<h3 class="semi-bold">Player Name</h3>
		</div>
		<div class="col-xs-3">
			<h3 class="semi-bold">Start Date</h3>
		</div>
		<div class="col-xs-3">
			<h3 class="semi-bold">Stop Date</h3>
		</div>
	</div>

	<hr class="mgn0">

	<div *ngFor="let player of players$ | async" class="row mgn-top10 ft-size16">
		<div class="col-xs-6">
			<span>{{player.PlayerName}}</span>
		</div>
		<div class="col-xs-3">
			<span *ngIf="dateTimeService.dateAndTimeUTCtoLocal(player.deliveryQueue.StartDate) !== 'Invalid date'">{{dateTimeService.dateAndTimeUTCtoLocal(player.deliveryQueue.StartDate)}}</span>
		</div>
		<div class="col-xs-3">
			<span *ngIf="dateTimeService.dateAndTimeUTCtoLocal(player.deliveryQueue.StopDate) !== 'Invalid date'">{{dateTimeService.dateAndTimeUTCtoLocal(player.deliveryQueue.StopDate)}}</span>
		</div>
	</div>
</div>
