import { Injectable } from '@angular/core';

type FontList = {
	name: string;
	isWebSafe?: boolean;
};

@Injectable()
export class TextService {
	public fontList: FontList[] = [
		{
			name: 'Amatic SC'
		},
		{
			name: 'Architects Daughter'
		},
		{
			name: 'Archivo Black'
		},
		{
			name: 'Arial',
			isWebSafe: true
		},
		{
			name: 'Arsenal'
		},
		{
			name: 'Arvo'
		},
		{
			name: 'Cabin'
		},
		{
			name: 'Calibri'
		},
		{
			name: 'Cambria',
			isWebSafe: true
		},
		{
			name: 'Cinzel'
		},
		{
			name: 'Consolas'
		},
		{
			name: 'Cormorant Infant'
		},
		{
			name: 'Courier New',
			isWebSafe: true
		},
		{
			name: 'Crimson Text'
		},
		{
			name: 'Cutive'
		},
		{
			name: 'Franklin Gothic'
		},
		{
			name: 'Fondamento'
		},
		{
			name: 'Georgia',
			isWebSafe: true
		},
		{
			name: 'Great Vibes'
		},
		{
			name: 'Ibarra Real Nova'
		},
		{
			name: 'Impact',
			isWebSafe: true
		},
		{
			name: 'Jura'
		},
		{
			name: 'Lato'
		},
		{
			name: 'Life Savers'
		},
		{
			name: 'Lora'
		},
		{
			name: 'Lucida Console',
			isWebSafe: true
		},
		{
			name: 'Merienda'
		},
		{
			name: 'Merriweather'
		},
		{
			name: 'Montserrat'
		},
		{
			name: 'Mulish'
		},
		{
			name: 'Newsreader'
		},
		{
			name: 'Oswald'
		},
		{
			name: 'Pacifico'
		},
		{
			name: 'Permanent Marker'
		},
		{
			name: 'Playball'
		},
		{
			name: 'Playfair Display'
		},
		{
			name: 'Poppins'
		},
		{
			name: 'Quicksand'
		},
		{
			name: 'Roboto'
		},
		{
			name: 'Roboto Condensed'
		},
		{
			name: 'Roboto Mono'
		},
		{
			name: 'Roboto Slab'
		},
		{
			name: 'Rosario'
		},
		{
			name: 'Rubik'
		},
		{
			name: 'Sancreek'
		},
		{
			name: 'Special Elite'
		},
		{
			name: 'Tahoma',
			isWebSafe: true
		},
		{
			name: 'Times New Roman',
			isWebSafe: true
		},
		{
			name: 'Trebuchet MS',
			isWebSafe: true
		},
		{
			name: 'Uncial Antiqua'
		},
		{
			name: 'Verdana',
			isWebSafe: true
		},
		{
			name: 'Yellowtail'
		}
	];

	public fontSizes: { fontSize: number }[] = [
		{ fontSize: 6 },
		{ fontSize: 8 },
		{ fontSize: 10 },
		{ fontSize: 12 },
		{ fontSize: 14 },
		{ fontSize: 16 },
		{ fontSize: 18 },
		{ fontSize: 19 },
		{ fontSize: 21 },
		{ fontSize: 24 },
		{ fontSize: 28 },
		{ fontSize: 32 },
		{ fontSize: 36 },
		{ fontSize: 38 },
		{ fontSize: 42 },
		{ fontSize: 48 },
		{ fontSize: 56 },
		{ fontSize: 64 },
		{ fontSize: 72 },
		{ fontSize: 75 },
		{ fontSize: 80 },
		{ fontSize: 88 },
		{ fontSize: 96 },
		{ fontSize: 104 },
		{ fontSize: 120 },
		{ fontSize: 144 }
	];

	constructor() {}
}
