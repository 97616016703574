import { Component, OnInit, OnDestroy } from '@angular/core';
import * as FontFaceObserver from 'fontfaceobserver';
import { Subscription } from 'rxjs';

import { AppStateService, UtilitiesService } from 'src/app/core/services';
import { LayerDetailService, TextService } from '../_services';
import { LayersService, UndoRedoService } from '../../_services';
import { LayerType } from '../../_models';
import { ProductTypes } from 'src/app/shared/api-models/admin';

@Component({
	selector: 'ld-text',
	templateUrl: './text.component.html',
	styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit, OnDestroy {
	public fontSizeList: { fontSize: number }[];
	public showAlignmentOptions: boolean;

	private subs: Subscription[] = [];

	constructor(
		private appStateService: AppStateService,
		public layerDetailService: LayerDetailService,
		public layersService: LayersService,
		public textService: TextService,
		private undoRedoService: UndoRedoService,
		public utilService: UtilitiesService
	) {
		this.onLayerSelect();
	}

	ngOnInit(): void {
		this.filterFontList();
	}

	onLayerSelect(): void {
		this.subs.push(
			this.layersService.activeLayerSelected$.subscribe((payload: [number, string]) => {
				this.filterFontList();
			})
		);
	}

	public alignBtn(): string {
		switch (true) {
			case this.layersService.activeLayer.canvasObj.textAlign === null:
			case this.layersService.activeLayer.canvasObj.textAlign === undefined:
			case this.layersService.activeLayer.canvasObj.textAlign === 'left':
				return 'fa-align-left';
			case this.layersService.activeLayer.canvasObj.textAlign === 'center':
				return 'fa-align-center';
			case this.layersService.activeLayer.canvasObj.textAlign === 'right':
				return 'fa-align-right';
			case this.layersService.activeLayer.canvasObj.textAlign === 'justify':
				return 'fa-align-justify';
			default:
				break;
		}
	}

	public onBoldClick(): void {
		if (this.layersService.activeLayer.canvasObj.fontWeight === 'normal') {
			this.layersService.activeLayer.canvasObj.fontWeight = 'bold';
		} else {
			this.layersService.activeLayer.canvasObj.fontWeight = 'normal';
		}
		this.layerDetailService.updateProperty('fontWeight', this.layersService.activeLayer.canvasObj.fontWeight);
		this.undoRedoService.recordState(`Font weight ${this.layersService.activeLayer.canvasObj.fontWeight}`);
		this.layerDetailService.updateFeedTextImage();
	}

	public onItalicClick(): void {
		let fontStyle = this.layersService.activeLayer.canvasObj.fontStyle;
		this.layersService.activeLayer.canvasObj.fontStyle = fontStyle === 'normal' ? 'italic' : 'normal';
		this.layerDetailService.updateProperty('fontStyle', this.layersService.activeLayer.canvasObj.fontStyle);
		this.undoRedoService.recordState(`Font style ${this.layersService.activeLayer.canvasObj.fontStyle}`);
		this.layerDetailService.updateFeedTextImage();
	}

	public onFontSelect(font: { name: string; isWebSafe: boolean }): void {
		this.layersService.activeLayer.canvasObj.fontFamily = font.name;
		if (font.isWebSafe) {
			this.layerDetailService.updateProperty('fontFamily', font.name);
			this.undoRedoService.recordState(`Change font`);
			//load from fonts.scss
		} else {
			this.loadAndUse(font.name);
		}
		this.layerDetailService.updateFeedTextImage();
	}

	private loadAndUse(font) {
		var myfont = new FontFaceObserver(font);
		myfont
			.load()
			.then(() => {
				// when font is loaded, use it.
				this.layerDetailService.updateProperty('fontFamily', font);
				this.layerDetailService.updateFeedTextImage();
				this.undoRedoService.recordState(`Change font`);
			})
			.catch((e) => {
				console.log(e);
			});
	}

	public onFontSizeSelect(e: { fontSize: number }): void {
		this.layersService.activeLayer.canvasObj.fontSize = e.fontSize;
		this.layerDetailService.updateProperty('fontSize', e.fontSize);
		this.undoRedoService.recordState(`Change font size`);
		this.layerDetailService.updateFeedTextImage();
	}

	public onAlignClick(alignment: string): void {
		this.layersService.activeLayer.canvasObj.textAlign = alignment;
		this.layerDetailService.updateProperty('textAlign', alignment);
		this.undoRedoService.recordState(`Align ${this.layersService.activeLayer.canvasObj.textAlign}`);
		this.layerDetailService.updateFeedTextImage();
		this.showAlignmentOptions = false;
	}

	public onSliderChange(prop: string, value: number): void {
		this.layersService.activeLayer[prop] = value;
		this.layerDetailService.updateProperty(prop, value);
		this.layerDetailService.updateFeedTextImage();
	}

	public recordState(prop: string): void {
		this.undoRedoService.recordState(`${prop === 'lineHeight' ? 'Change line height' : 'Change letter spacing'}`);
	}

	private filterFontList(): void {
		let layerType: LayerType = this.layersService.activeLayer?.type;
		let productType: ProductTypes = this.appStateService.product;

		//Limit font sizes so the date and time stay on a single line
		this.fontSizeList = this.textService.fontSizes.filter((list) => {
			if (layerType === 'Current Date') {
				return productType.ProductName === 'Poster' ? list.fontSize < 24 : list.fontSize < 48;
			}
			if (layerType === 'Current Time' || layerType === 'Countdown') {
				return productType.ProductName === 'Poster' ? list.fontSize < 64 : list.fontSize < 120;
			}
			return list;
		});
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
