import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'render'
})

export class RenderPipe implements PipeTransform {
  transform(row: any, key: any) {
    const split = key.split('.');
    return this.getPath(split, row);
  }

  private getPath(split, row) {
      // https://github.com/dherges/ng-packagr/issues/696
      const result = split.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, row);
      return result;
    }
}