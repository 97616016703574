<div (mouseover)="onMouseover()" (mouseleave)="onMouseleave()" class="relative">
	<div
		*ngIf="category.state !== 'edit'"
		[ngStyle]="{ 'width.%': category.isSubCategory ? 90 : 100 }"
		(click)="onCategoryClick()"
		class="mgn-bottom10 {{ category.isSubCategory ? 'mgn-left30' : 'mgn-left5' }} mgn-left5 pointer ellipsis z"
	>
		<i class="far {{ category.isExpanded ? 'fa-folder-open' : 'fa-folder' }} mgn-right10 "></i>
		<span class="{{ categoriesService.clickedCategory?.Id === category.Id ? 'semi-bold' : '' }} {{ showDropArea ? 'white-text' : 'dark-text' }}">
			{{ category.CategoryName }}
		</span>
	</div>

	<div
		dragula='"item-bag"'
		*ngIf="showDropArea"
		id="{{ category.Id }}"
		class="onOverContainer mgn-bottom4 mgn-topneg4 dark-bg ht34 pd1 border-radius absolute top0"
	>
		<div class="onOver pd-top4 pd-left10 ht32 border-radius white-text">
			<i class="far {{ category.isExpanded ? 'fa-folder-open' : 'fa-folder' }} mgn-right10 "></i>
			{{ category.CategoryName }}
		</div>
	</div>
	<!-- *ngIf="showHoverButtons() && !cursorNotAllowed"  -->

	<div *ngIf="catService.showHoverButtons(mouseover) && !cursorNotAllowed" class="absolute topneg5 right5">
		<loc-hover-buttons
			[bgColor]="'gray-bg-dark'"
			[deletable]="catService.categoryDeletable"
			[isLibrary]="false"
			[isSubCategory]="category.isSubCategory"
			[itemName]="category.CategoryName"
			(createCategoryClickNotify)="onCreateCategoryClick()"
			(deleteClickNotify)="onCategoryDeleteClick()"
			(nameEditNotify)="onNameEditClick()"
		></loc-hover-buttons>
	</div>
	<div *ngIf="cursorNotAllowed" class="edit-delete-btns absolute top2 right1">
		<i class="fas fa-ban ft-size22 dark-text" aria-hidden="true"></i>
	</div>
</div>

<!--NEW CATEGORY-->
<ng-container *ngIf="category.state === 'new'">
	<input
		style="width: 85%"
		type="text"
		[(ngModel)]="newSubCategory.CategoryName"
		#categoryInput
		placeholder="New sub category..."
		class="white-bg ht28 ft-size12"
		(keyup.enter)="onSaveNewSubCategoryClick()"
	/>
	<div style="width: 15%" class="relative in-block">
		<button class="limeGreen-bg border-radius ht25 white-text pd0 pd-left4 pd-right4 ft-size12" (click)="onSaveNewSubCategoryClick()">
			<i class="fas fa-check"></i>
		</button>
		<a class="absolute bottom11 rightneg14 pd2 dark-text border-radius" (click)="category.state = 'read'">
			<i class="fa fa-times ft-size11" aria-hidden="true"></i>
		</a>
	</div>
</ng-container>

<!--EDIT CATEGORY NAME-->
<ng-container *ngIf="category.state === 'edit'">
	<input style="width: 85%" type="text" [(ngModel)]="category.CategoryName" #categoryInput class="white-bg ht28 ft-size12" (keyup.enter)="onSaveNameClick()" />
	<div style="width: 15%" class="relative in-block">
		<button class="limeGreen-bg border-radius ht25 white-text pd0 pd-left4 pd-right4 ft-size12" (click)="onSaveNameClick()">
			<i class="fas fa-check"></i>
		</button>
		<a class="absolute bottom11 rightneg14 pd2 dark-text border-radius" (click)="category.state = 'read'">
			<i class="fa fa-times ft-size11" aria-hidden="true"></i>
		</a>
	</div>
</ng-container>

<ng-container *ngIf="!!category?.InverseParentCategory && category.isExpanded && cvStateService.viewState.treeSelection === 'favorites'">
	<l-cat
		*ngFor="let subCategory of category.InverseParentCategory | favoritesFilterPipe : true"
		[category]="subCategory"
		[library]="library"
		[subCategories]="category.InverseParentCategory"
	></l-cat>
</ng-container>

<ng-container *ngIf="!!category?.InverseParentCategory && category.isExpanded && cvStateService.viewState.treeSelection !== 'favorites'">
	<ng-container *ngFor="let subCategory of category.InverseParentCategory">
		<l-cat *ngIf="!subCategory.IsDeleted" [category]="subCategory" [library]="library" [subCategories]="category.InverseParentCategory"></l-cat>
	</ng-container>
</ng-container>
