import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { AppStateService } from 'src/app/core/services';
import { ContentViewService } from '../../content-container/content/_services';
import { LibrariesService } from '../_services';
import { PlaylistsExamples } from 'src/app/shared/api-models/content';
import { FeaturedPlaylistsService } from '../_services';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

@Component({
	selector: 'lt-featured-playlist',
	templateUrl: './featured-playlist.component.html',
	styleUrls: ['./featured-playlist.component.scss']
})
export class FeaturedPlaylistComponent implements OnInit {
	@Input() playlist: PlaylistsExamples;
	@ViewChild('playlistNameInput') playlistNameInput: ElementRef;

	public showDeleteBtn: boolean;

	constructor(
		private appStateService: AppStateService,
		private contentViewService: ContentViewService,
		public librariesService: LibrariesService,
		public featuredPlaylistsService: FeaturedPlaylistsService
	) {}

	ngOnInit(): void {}

	public onPlaylistClick(): void {
		this.featuredPlaylistsService.onPlaylistClick(this.playlist);
		//This handler just scrolls content back to the top
		this.contentViewService.onCategoryClick();
	}

	public onSaveNameClick(): void {
		this.featuredPlaylistsService.updatePlaylistName(this.playlist);
		this.playlist.state = 'read';
	}

	public onEditClick(): void {
		setTimeout(() => this.playlistNameInput?.nativeElement.focus(), 20);
		this.playlist.state = 'edit';
	}

	public onDeleteClick(): void {
		let ok = confirm(`Delete "${this.playlist.Name}"?`);
		if (ok) {
			this.featuredPlaylistsService.softDelete(this.playlist);
		}
	}

	public userIsAdmin(): boolean {
		return this.appStateService.currentUser.Role === UserRole.ADMINISTRATOR;
	}
}
