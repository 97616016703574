import { PlayerStatusReport } from '../../../shared/api-models/reporting';

export class PsReportView extends PlayerStatusReport {
    lastCheckinView: string;
    lastContentUpdateView: string;
    classList: string;
    rowBgColor: string;
    activePlaylistName: string;
    repairStateView: string;
    checkinTooltip: string;
    lastUpdateTooltip: string;
    networkView: string;
}