<div class="row mgn-top30">
    <div class="col-sm-12">
        <h1 style="border-radius: 16px;" class="box-shadow white-bg pd15">
            FROM: 
            <em class="ft-size36 regular">{{(clientMergeToolStore.fromClient$ | async).Name}} ({{(clientMergeToolStore.fromClient$ | async).Id}})</em> 
            <i class="fas fa-long-arrow-alt-right mgn-left30 mgn-right30"></i>
            TO: 
            <em class="ft-size36 regular">{{(clientMergeToolStore.toClient$ | async).Name}} ({{(clientMergeToolStore.toClient$ | async).Id}})</em>
        </h1>
    </div>
</div>

<div class="row mgn-top30">
    <div class="col-sm-12">
        <div class="white-bg shadow border-radius pd10 yellow-bg-light">
            <p class="ft-size14">History notes and tickets go with location. This means if ALL of a company's locations are merged, all the history notes and tickets (including company-level history/tickets) are transferred. If only SOME locations are merged, then ONLY history and tickets associated with merged locations are transferred.</p>
        </div>
    </div>
</div>

<div class="row mgn-top60">
    <div class="col-sm-3">
        <button (click)="onMergeEverythingClick()" class="green-bg white-text">Merge everything (select all data)</button>
    </div>
</div>

<div class="row mgn-top60">
    <div *ngIf="(locations$ | async) as locations" class="col-sm-6">
        <div class="row">
            <div class="col-sm-8">
                <h3 class="semi-bold"><i class="fas fa-map-marker-alt mgn-right12"></i>Select Locations To Move</h3>
            </div>
            <div class="col-sm-2">
                <button (click)="onCheckAllClick('locations')" class="green-bg white-text">Check all</button>
            </div>
            <div class="col-sm-2">
                <button (click)="onUncheckAllClick('locations')" class="white-bg">Uncheck all</button>
            </div>
        </div>
        <div *ngFor="let location of locations" (click)="onSelect('locations', location)" class="row mgn-top15 pointer">
            <div class="col-xs-12">
                <div class="pd8 border-radius shadow {{location.isSelected ? 'green-bg white-text' : 'white-bg'}}">
                    <span class="ft-size16 regular"><i class="fas fa-map-marker-alt"></i> - {{location.Name}}</span>
                </div>
                
            </div>
        </div>
        
    </div>
    <div *ngIf="(users$ | async) as users" class="col-sm-6">
        <div class="row">
            <div class="col-sm-8">
                <h3 class="semi-bold"><i class="fas fa-users mgn-right12"></i>Select Users To Move</h3>
            </div>
            <div class="col-sm-2">
                <button (click)="onCheckAllClick('users')" class="green-bg white-text">Check all</button>
            </div>
            <div class="col-sm-2">
                <button (click)="onUncheckAllClick('users')" class="white-bg">Uncheck all</button>
            </div>
        </div>
        
        <div *ngFor="let user of users" (click)="onSelect('users', user)" class="row mgn-top15 pointer">
            <div class="col-xs-12">
                <div class="pd8 border-radius {{user.isSelected ? 'green-bg white-text' : 'white-bg'}} shadow">
                    <span class="ft-size16 regular"><i class="fas fa-users"></i> - {{user.ContactInformation.FirstName}} {{user.ContactInformation.LastName}} - {{user.ContactInformation.Email}}</span>
                </div>
                
            </div>
        </div>
    </div>
</div>





<div class="row mgn-top60">
    <div *ngIf="(libraries$ | async) as libraries" class="col-sm-6">
        <div class="row">
            <div class="col-sm-8">
                <h3 class="semi-bold"><i class="fas fa-book mgn-right12"></i>Select Libraries To Move</h3>
            </div>
            <div class="col-sm-2">
                <button (click)="onCheckAllClick('libraries')" class="green-bg white-text">Check all</button>
            </div>
            <div class="col-sm-2">
                <button (click)="onUncheckAllClick('libraries')" class="white-bg">Uncheck all</button>
            </div>
        </div>
        <div *ngFor="let library of libraries" (click)="onSelect('libraries', library)" class="row mgn-top15 pointer">
            <div class="col-xs-12">
                <div class="pd8 border-radius shadow {{library.isSelected ? 'green-bg white-text' : 'white-bg'}}">
                    <span class="ft-size16 regular"><i class="fas fa-book"></i> - {{library.LibraryName}}</span>
                </div>
                
            </div>
        </div>
        
    </div>
    <div *ngIf="(contracts$ | async) as contracts" class="col-sm-6">
        <div class="row">
            <div class="col-sm-8">
                <h3 class="semi-bold"><i class="fas fa-file-signature mgn-right12"></i>Select Contracts To Move</h3>
            </div>
            <div class="col-sm-2">
                <button (click)="onCheckAllClick('contracts')" class="green-bg white-text">Check all</button>
            </div>
            <div class="col-sm-2">
                <button (click)="onUncheckAllClick('contracts')" class="white-bg">Uncheck all</button>
            </div>
        </div>
        
        <div *ngFor="let contract of contracts" (click)="onSelect('contracts', contract)" class="row mgn-top15 pointer">
            <div class="col-xs-12">
                <div class="pd8 border-radius {{contract.isSelected ? 'green-bg white-text' : 'white-bg'}} shadow">
                    <span class="ft-size16 regular"><i class="fas fa-file-signature"></i> - {{clientMergeToolService.contractTypeToProduct(contract.ContractTypeId)}}, {{clientMergeToolService.contractTerm(contract.ContractTermId)}}, Sold {{dateTimeService.dateLocal(contract.SaleDate)}}, Renews {{dateTimeService.dateLocal(contract.RenewDate)}}</span>
                </div>
                
            </div>
        </div>
    </div>
</div>

<div class="row mgn-top60 mgn-bottom100">
    <div class="col-sm-2">
        <button (click)="onCancelClick()" class="gray-bg ft-size18">Cancel</button>
    </div>
    <div class="col-sm-2 col-sm-offset-8">
        <button (click)="onMergeClientsClick()" class="limeGreen-bg white-text ft-size18"><i class="fas fa-people-arrows mgn-right10"></i>Merge clients</button>
    </div>
</div>



