import { ContentItem } from '../../view-models/content';

export class PreviewModel {
    height: number;
    width: number;
    totalItems: number;
    currentItem: number;
    isPaused: boolean;
    isMuted: boolean;
    currentClip: ContentItem;
    currentTime: number;
    totalTime: number;
    timeLabel: string;
    nameLabel: string;

    constructor(clipCount: number) {
        this.totalItems = clipCount;
        this.isPaused = false;
        this.isMuted = false;
        this.currentItem = 0;
        this.timeLabel = '';
        this.nameLabel = 'Now Playing: Loading...';
    }
}
