import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdminDataService } from '../core/services/admin-data.service';
import { AppStateService } from '../core/services/app-state.service';
import { environment } from '../../environments/environment';
import { Players } from '../shared/api-models/admin';
import { Button, ProductCard } from '../my-products/view-models';
import { EProductTypes } from '../shared/api-models/admin';
import { ProductTypes } from '../shared/api-models/admin';

@Injectable({
	providedIn: 'root'
})
export class MyProductsService {
	constructor(private adminDataService: AdminDataService, private appStateService: AppStateService, private httpClient: HttpClient) {}

	public productCardsView(productTypes: ProductTypes[]): ProductCard[] {
		return productTypes
			.map((productType) => {
				const card: ProductCard = new ProductCard();

				card.imagePath = this.imagePath(productType.Id);
				card.productType = productType;
				card.button = new Button('Players Dashboard', `my-players/${card.productType.Route}`);
				return card;
			})
			.filter((card) => card.productType.IsCoreProduct || (card.productType.hasContract === 1 && card.productType.IsOnDashboard))
			.sort((a, b) => b.productType.hasContract - a.productType.hasContract || a.productType.DashboardOrder - b.productType.DashboardOrder);
	}

	public getPlayersAndProducts(): Observable<any> {
		const currentClient = this.appStateService.currentClient;
		const currentUser = this.appStateService.currentUser;

		const availableVideoPlayers$ = this.httpClient.get(
			`${environment.adminUrl}CorePlayers/AvailableVideoPlayers?clientId=${currentClient.Id}&userId=${currentUser.UserId}`
		);

		return availableVideoPlayers$.pipe(
			map((availableVideoPlayers: Players[]) => {
				const contractedProducts = this.appStateService.contractedProducts;
				this.adminDataService.setC24ActiveForCurrentClient(availableVideoPlayers);

				return [contractedProducts, availableVideoPlayers];
			})
		);
	}

	public atLeastOneVideoProduct(cardResponse: ProductCard[]): boolean {
		return cardResponse.some((card) => {
			return card.productType.IsVideoProduct;
		});
	}

	private imagePath(productTypeId: number): string {
		switch (productTypeId) {
			case EProductTypes.ON_HOLD:
				EProductTypes.ON_HOLD;
				return '/assets/products-hold.png';
			case EProductTypes.RADIO:
				return '/assets/products-radio2.png';
			case EProductTypes.POSTER:
				return '/assets/products-poster2.png';
			case EProductTypes.WEB_H:
			case EProductTypes.WEB_V:
				return '/assets/web-player.jpg';
			case EProductTypes.V_WALL_1X2_V:
				return '/assets/1x2-V-Tile.png';
			case EProductTypes.V_WALL_1X2_H:
				return '/assets/1x2-H-Tile.png';
			case EProductTypes.V_WALL_1X3_H:
				return '/assets/1x3-H.png';
			case EProductTypes.V_WALL_2X1_V:
				return '/assets/2x1-V-Tile.png';
			case EProductTypes.V_WALL_2X1_H:
				return '/assets/2x1-H-Tile.png';
			case EProductTypes.V_WALL_2X2_V:
				return '/assets/2x2-V.png';
			case EProductTypes.V_WALL_2X2_H:
				return '/assets/2x2-H-Tile.png';
			case EProductTypes.V_WALL_3X1_H:
				return '/assets/3x1-H-Tile.png';
			case EProductTypes.V_WALL_3X1_V:
				return '/assets/3x1-V-Tile.png';
			case EProductTypes.V_WALL_4X1_V:
				return '/assets/4x1-V-Tile.png';
			case EProductTypes.V_WALL_4X1_H:
				return '/assets/4x1-H-Tile.png';
			default:
				return '/assets/products-video.png';
		}
	}
}
