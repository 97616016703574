import { FeedSources } from './feed-sources';
import { FeedStockSymbol } from './feed-stock-symbol';
import { FeedProperties } from './feed-properties';
import { FeedItems } from './feed-items';

export class Feeds {
	Id: number;
	FeedSourceId: number;
	ClientId: number;
	Name: string;
	FeedSchema: string;
	IsModerated: boolean;
	NotifyOnNewItems: boolean;
	CreateDate: any;
	CreatedByUserId: number;
	UpdateDate: any;
	UpdatedByUserId: number;
	IsDeleted: boolean;
	IsSorted: boolean;

	bgColor: string;
	name: string;
	draggableClass: string;
	icon: string;
	stringId: string;
	isGlobal: boolean;
	IsGlobal: boolean; //at least one url returns this prop, but not all
	editBtn: string;
	deleteBtn: string;
	activeItemsCount: string;
	newestItemDate: string;
	feedType: string;
	classList: string;
	feedSource: FeedSources;
	stockSymbols: FeedStockSymbol[];
	properties: FeedProperties;
	feedItems: FeedItems[];
	feedItemsCount: number;
}
