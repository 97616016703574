import { Injectable } from '@angular/core';
import { Step } from '../_models';

@Injectable()
export class ViewStateService {

    mode: 'edit' | 'new';
    currentStep: Step;
    steps: Step[] = [
        {
            number: 1,
            title: 'On Screen Logo Corner',
            description: `Your logo will appear as an onscreen overlay only with fullscreen content on the players you
            select in step two.<br> It will appear automatically on Screen Layouts if selected.`,
            nextBtnText: 'Select players',
            nextBtnColor: 'red-bg',
            nextBtnIcon: 'fas fa-chevron-right',
            isCurrentStep: true
        },
        {
            number: 2,
            title: 'Select Players',
            description: `Drag players into Active Players box to assign.`,
            previousBtnText: `Select corner`,
            previousBtnIcon: 'fas fa-chevron-left',
            previousBtnVisible: true,
            nextBtnText: 'Save',
            nextBtnIcon: 'fas fa-save',
            nextBtnColor: 'limeGreen-bg',
        }
    ];

    constructor() { }

    public advanceOneStep(): void {
        const index = this.setIsCurrentFalse();
        this.steps[index + 1].isCurrentStep = true;
        this.currentStep = this.steps.find(step => step.isCurrentStep);
    }

    public back(): void {
        const index = this.setIsCurrentFalse();
        this.steps[index - 1].isCurrentStep = true;
        this.currentStep = this.steps.find(step => step.isCurrentStep);
    }

    private setIsCurrentFalse(): number {
        const index = this.steps.findIndex(step => step.isCurrentStep);
        this.steps[index].isCurrentStep = false;
        return index;
    }
}
