import { Injectable, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VideoControlsService {

    private controlClickedSource = new Subject<string>();

    controlClicked$ = this.controlClickedSource.asObservable();

    public isPlaying: boolean;
    public playClock: number = 0;
    public playClockId: any;
    public playIcon: 'fas fa-play' | 'fas fa-pause';
    public videoPlayer: ElementRef;

    constructor(
    ) { }

    public onClick(button: string): void {
        this.controlClickedSource.next(button);
    }
}
