import { Injectable } from '@angular/core';

import { Column, Table, TableConfig } from '../../../shared/components/table/view-models';

@Injectable({
	providedIn: 'root'
})
export class TableConfigService {
	constructor() {}

	public columns: Column[] = [
		new Column('editBtn', 'Edit', 'button', false, 'gray-bg pd-left10 pd-right10'),
		new Column('completeBtn', 'Complete', 'button', false, 'blue-bg-darker white-text pd-left8 pd-right8'),
		new Column('Client.Name', 'Client', 'link').setIsSortable(true).setSortKey('Client.Name'),
		new Column('Subject', 'Subject', 'string').setIsSortable(true),
		new Column('CsrTaskType.Name', 'Type', 'string').setIsSortable(true).setSortKey('CsrTaskType.Name'),
		new Column('dueDateView', 'Due', 'string').setIsSortable(true).setElementClassList('semi-bold').setSortKey('DueDate'),
		new Column('Csr.UserName', 'CSR', 'string').setIsSortable(true).setSortKey('Csr.UserName'),
		new Column('completeDateView', 'Completed', 'string').setIsSortable(true).setSortKey('CompleteDate'),
		new Column('deleteBtn', 'far fa-trash-alt', 'button')
			.setElementClassList('white-bg red-text-light semi-bold ht40 pd-left14 pd-right14')
			.setShowIconOnly(true)
	];

	public table: Table = new Table(this.columns);

	public config: TableConfig = {
		itemsPerPage: 10,
		headerColor: 'green-bg',
		rowFontSize: 'ft-size15',
		headerFontSize: 'ft-size14'
	};
}
