<div
	*ngIf="contentViewService.contentIsVideo(content)"
	class="row border-radius {{ !isLastIndex ? 'border-first-index' : 'border-last-index border-first-index mgn-bottom1' }} white-bg pd-top15 pd-bottom5"
>
	<!--***************************
		VIDEO PREVIEW
	****************************-->
	<div class="col-sm-5">
		<portal-preview-thumbnail [content]="content"></portal-preview-thumbnail>
	</div>

	<!--***************************
		CONTENT TITLE
	****************************-->
	<div class="col-sm-7 ft-size14 relative">
		<i
			*ngIf="!!contentViewService.featuredContentProps(content)"
			class="{{ contentViewService.featuredContentProps(content).icon }} {{ contentViewService.featuredContentProps(content).color }} mgn-right10"
			ngxTippy
			tippyName="{{ utilService.guid | wrapFn }}"
			[tippyProps]="{ appendTo: 'parent' }"
			attr.data-tippy-content="{{ contentViewService.featuredContentProps(content).tooltip }}"
		></i>
		<span>{{ content.Title }}</span>
	</div>
</div>

<div *ngIf="content.ContentTypeId === contentType.HOLD_MUSIC || content.ContentTypeId === contentType.HOLD_MESSAGE" class="row">
	<!--***************************
		PREVIEW BUTTON
	****************************-->
	<div class="col-xs-3 col-sm-4 col-md-5 col-lg-4 relative">
		<button
			*ngIf="content.ContentTypeId === contentType.HOLD_MUSIC && content.contentFiles?.length > 0"
			(click)="onPlayClick(content, null, null, contentList)"
			class="noExit {{ content.contentFiles[0].previewActive ? 'yellow-bg' : 'white-bg' }} ft-size12"
		>
			<i class="fa {{ content.contentFiles[0].previewActive ? 'fa-times' : 'fas fa-play' }} noExit mgn-right10" aria-hidden="true"></i>
			{{ content.contentFiles[0].previewActive ? 'Stop' : 'Play' }}
		</button>

		<button
			*ngIf="content.ContentTypeId !== contentType.HOLD_MUSIC"
			(click)="onPlayClick(content, isLastIndex, 'newsletter', contentList)"
			class="noExit white-text blue-bg ft-size12"
		>
			<i class="fa {{ content.contentFiles[0].previewActive ? 'fa-times' : 'fas fa-play' }} noExit white-text mgn-right10" aria-hidden="true"></i>
			{{ content.contentFiles[0].previewActive ? 'Stop' : 'Play' }}
		</button>
	</div>

	<!--***************************
		CONTENT TITLE
	****************************-->
	<div class="col-xs-9 col-sm-8 col-md-7 col-lg-8 ft-size14">
		<span>{{ content.Title }}</span>
	</div>
</div>
