import { Component, OnInit, ViewChild } from '@angular/core';

import { AppStateService } from 'src/app/core/services';
import { CopyTagService } from '../_services';
import { CreateEditTemplateService } from './_services/create-edit-template.service';
import { RichTextService, UtilitiesService } from 'src/app/core/services';
import { MailerTemplates } from 'src/app/shared/api-models/admin';

@Component({
	selector: 'app-create-edit-template',
	templateUrl: './create-edit-template.component.html',
	styleUrls: ['./create-edit-template.component.scss']
})
export class CreateEditTemplateComponent implements OnInit {
	@ViewChild('form', { static: true }) form;

	constructor(
		private appStateService: AppStateService,
		public copyTagService: CopyTagService,
		public createEditTemplateService: CreateEditTemplateService,
		public richTextService: RichTextService,
		public utilService: UtilitiesService
	) {}

	ngOnInit(): void {
		const { activeUrl } = this.appStateService;
		switch (true) {
			case this.utilService.includes(activeUrl, 'create'):
				this.createEditTemplateService.mode = 'new';
				this.createEditTemplateService.template = new MailerTemplates();
				break;

			case this.utilService.includes(activeUrl, 'edit'):
				this.createEditTemplateService.mode = 'edit';
				this.createEditTemplateService.getTemplate(+this.utilService.getLastParam(activeUrl));
				break;
		}
	}

	public saveBtnDisabled(): boolean {
		return !this.form.valid || this.createEditTemplateService.template.HtmlTemplate?.length < 1;
	}

	public onSaveClick(): void {
		this.createEditTemplateService.saveTemplate();
	}
}
