<div class="col-sm-6 col-lg-3">
	<div class="product-card pd20 shadow center white-bg relative">
		<div *ngIf="card.productType.hasContract !== 1" class="dash-disabled-overlay text-center setZ"></div>
		<img style="width: 100%; border-radius: 12px" (click)="onProductButtonClick()" class="shadow relative z pointer" src="{{ card.imagePath }}" />

		<i
			style="font-size: 160px; top: 64% !important; color: #f7f7f7"
			class="{{ card.productType.DashIcon }} {{ card.productType.DashIcon === 'far fa-square' ? 'posterStyles' : 'center-unknown-height-width' }} absolute"
		></i>

		<h1 class="mt-[60px] semi-bold mgn-bottom15 relative z">{{ card.productType.ProductName }}</h1>
		<div class="row mgn-top100">
			<div class="col-xs-12">
				<ng-container [ngSwitch]="card.productType.Id">
					<button *ngSwitchCase="1" (click)="onProductButtonClick()" class="blue-bg white-text ft-size20">
						<i class="{{ card.productType.DashIcon }} mgn-right12"></i>
						Players Dashboard
					</button>
					<button *ngSwitchCase="2" (click)="onProductButtonClick()" class="purple-bg white-text ft-size20">
						<i class="{{ card.productType.DashIcon }} mgn-right12"></i>
						Players Dashboard
					</button>
					<button *ngSwitchDefault (click)="onProductButtonClick()" class="red-bg white-text ft-size20">
						<i class="{{ card.productType.DashIcon }} mgn-right12"></i>
						Players Dashboard
					</button>
				</ng-container>
			</div>
		</div>
	</div>
</div>
