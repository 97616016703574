import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AppStateService } from '../../core/services';
import { ContentRequestTable } from '../_models';
import { ContentRequestsService } from '../_services/content-requests.service';
import { ContentRequestStateName } from 'src/app/shared/api-models/admin';

@Injectable({
	providedIn: 'root'
})
export class MergeRequestsService {
	public atLeastTwoSelected: boolean;
	public isAllSelected: boolean;
	public mergeValid: boolean;

	constructor(public appStateService: AppStateService, public contentRequestsService: ContentRequestsService, private router: Router) {}

	onCheckboxClick(selectedRequest: ContentRequestTable): void {
		selectedRequest.isSelected = !selectedRequest.isSelected;

		//Table makes a copy of the data, splice in player here so change detection will run
		const index = this.contentRequestsService.requests.findIndex((request) => request.Id === selectedRequest.Id);
		this.contentRequestsService.requests.splice(index, 1, selectedRequest);
		this.atLeastTwoSelected = this.atLeastTwoSelectedCheck();
		this.mergeValid = this.validateMerge();
	}

	onSelectAllClick(): void {
		this.isAllSelected = !this.isAllSelected;
		this.contentRequestsService.requests.forEach((x) => (x.isSelected = this.isAllSelected));
		this.atLeastTwoSelected = this.atLeastTwoSelectedCheck();
		this.mergeValid = this.validateMerge();
	}

	public onMergeRequestsClick(): void {
		const selectedRequests = this.contentRequestsService.requests.filter((request) => request.isSelected);
		this.appStateService.setSessionItem('requestsForMerge', JSON.stringify(selectedRequests));
		this.router.navigate([`/system-tools/content-requests/new/On-Hold Message`]);
	}

	private atLeastTwoSelectedCheck(): boolean {
		return this.contentRequestsService.requests.filter((request) => request.isSelected).length >= 2;
	}

	private validateMerge(): boolean {
		const selectedRequests = this.contentRequestsService.requests.filter((request) => request.isSelected);
		const firstSelectedRequest = selectedRequests[0];

		if (firstSelectedRequest) {
			const matchingClientId: boolean = selectedRequests.every((request) => request.ClientId === firstSelectedRequest.ClientId);
			const matchingVoiceTalent: boolean = selectedRequests.every((request) => request.VoiceTalent === firstSelectedRequest.VoiceTalent);
			const matchingRequestedBy: boolean = selectedRequests.every((request) => request.RequestedBy === firstSelectedRequest.RequestedBy);
			const matchingTargetLibrary: boolean = selectedRequests.every((request) => request.TargetLibraryId === firstSelectedRequest.TargetLibraryId);
			const correctStatus: boolean =
				firstSelectedRequest.StateName === ContentRequestStateName.ASSIGNED_AWAITING_PRODUCTION ||
				firstSelectedRequest.StateName === ContentRequestStateName.IN_PRODUCTION;

			if (correctStatus) {
				const matchingStatus: boolean = selectedRequests.every((request) => request.StateName === firstSelectedRequest.StateName);
				return this.atLeastTwoSelected && matchingClientId && matchingVoiceTalent && matchingRequestedBy && matchingTargetLibrary && matchingStatus;
			}
			return false;
		}
	}
}
