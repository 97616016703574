import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { switchMap, tap, catchError, withLatestFrom } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { toggleIsSelected } from 'src/app/shared/helpers/ngrx-component-store.helpers';
import * as moment from 'moment';

import { Content, ContentLibraries } from 'src/app/shared/api-models/content';
import { UsageReportsService } from './usage-reports.service';
import { IContentUsageReport } from 'src/app/shared/api-models/reporting';
import { ICategories } from './_models/usage-report.interface';

export interface UsageReportsState {
	libraries: ContentLibraries[];
	categories: ICategories;
	selectedLibraries: ContentLibraries[];
	libraryIdsFromUrl: number[];
	report: IContentUsageReport[];
	showBackBtn: boolean;
	librarySearchTerm: string;
	clipNameSearchTerm: string;
	fromDateString: string;
	toDateString: string;
	totalItems: number;
	pageNumber: number;
	pageSize: number;
	category: string;
	subCategory: string;
	fromCreateDateString: string;
	toCreateDateString: string;
}

@Injectable()
export class UsageReportsStore extends ComponentStore<UsageReportsState> {
	constructor(private usageReportsService: UsageReportsService) {
		super({
			libraries: [],
			categories: {} as ICategories,
			selectedLibraries: [],
			libraryIdsFromUrl: [],
			report: [],
			showBackBtn: false,
			librarySearchTerm: '',
			clipNameSearchTerm: '',
			fromDateString: moment('12-31-2022').format('M/D/YYYY'),
			toDateString: moment(new Date()).format('M/D/YYYY'),
			totalItems: 0,
			pageNumber: 1,
			pageSize: 25,
			category: '',
			subCategory: '',
			fromCreateDateString: moment('01-01-2004').format('M/D/YYYY'),
			toCreateDateString: moment(new Date()).format('M/D/YYYY')
		});
	}

	//Selectors
	readonly libraries$ = this.select(({ libraries }) => libraries);
	readonly categories$ = this.select(({ categories }) => categories);
	readonly selectedLibraries$ = this.select(({ libraries }) => libraries.filter((lib) => lib.isSelected));
	readonly libraryIdsFromUrl$ = this.select(({ libraryIdsFromUrl }) => libraryIdsFromUrl);
	readonly atleastOneLibrarySelected$ = this.select(({ libraries }) => libraries.some((lib) => lib.isSelected));
	readonly report$ = this.select(({ report }) => report);
	readonly showBackBtn$ = this.select(({ showBackBtn }) => showBackBtn);
	readonly librarySearchTerm$ = this.select(({ librarySearchTerm }) => librarySearchTerm);
	readonly clipNameSearchTerm$ = this.select(({ clipNameSearchTerm }) => clipNameSearchTerm);
	readonly fromDateString$ = this.select(({ fromDateString }) => fromDateString);
	readonly toDateString$ = this.select(({ toDateString }) => toDateString);
	readonly totalItems$ = this.select(({ totalItems }) => totalItems);
	readonly pageNumber$ = this.select(({ pageNumber }) => pageNumber);
	readonly pageSize$ = this.select(({ pageSize }) => pageSize);
	readonly category$ = this.select(({ category }) => category);
	readonly subCategory$ = this.select(({ subCategory }) => subCategory);
	readonly fromCreateDateString$ = this.select(({ fromCreateDateString }) => fromCreateDateString);
	readonly toCreateDateString$ = this.select(({ toCreateDateString }) => toCreateDateString);

	//Effects
	readonly getLibraries$ = this.effect((trigger$) =>
		trigger$.pipe(
			withLatestFrom(this.librarySearchTerm$),
			switchMap(([, librarySearchTerm]) =>
				this.usageReportsService.getLibraries$(librarySearchTerm).pipe(
					tap((libraries: ContentLibraries[]) => {
						const librariesSorted = libraries.filter((lib) => !lib.IsDeleted);
						this.setLibraries(librariesSorted);
					}),
					catchError(() => EMPTY)
				)
			)
		)
	);

	readonly getReport$ = this.effect((trigger$) =>
		trigger$.pipe(
			withLatestFrom(this.libraryIdsFromUrl$),
			switchMap(([, libraryIds]) => this.usageReportsService.getLibrariesByIdArr$(libraryIds)),
			tap((libraries: ContentLibraries[]) => {
				this.setLibraries(libraries);
			}),
			withLatestFrom(
				this.libraryIdsFromUrl$,
				this.fromDateString$,
				this.toDateString$,
				this.pageNumber$,
				this.pageSize$,
				this.clipNameSearchTerm$,
				this.category$,
				this.subCategory$,
				this.fromCreateDateString$,
				this.toCreateDateString$
			),
			switchMap(
				([
					,
					libraryIds,
					fromDateString,
					toDateString,
					pageNumber,
					pageSize,
					clipNameSearchTerm,
					category,
					subCategory,
					fromCreateDateString,
					toCreateDateString
				]) =>
					this.usageReportsService
						.getUsageReport$(
							libraryIds,
							fromDateString,
							toDateString,
							pageNumber,
							pageSize,
							clipNameSearchTerm,
							category,
							subCategory,
							fromCreateDateString,
							toCreateDateString
						)
						.pipe(
							tap((res: [IContentUsageReport[], number]) => {
								const [report, totalItems] = res;
								this.setReport(report);
								this.setTotalItems(totalItems);
							})
						)
			)
		)
	);

	readonly getCategories$ = this.effect((trigger$) =>
		trigger$.pipe(
			withLatestFrom(this.libraryIdsFromUrl$),
			switchMap(([, libraryIds]) => this.usageReportsService.getLibrariesByIdArr$(libraryIds)),
			tap((libraries: ContentLibraries[]) => {
				this.setLibraries(libraries);
			}),
			withLatestFrom(this.libraryIdsFromUrl$, this.fromDateString$, this.toDateString$, this.pageNumber$, this.pageSize$, this.clipNameSearchTerm$),
			switchMap(([, libraryIds, fromDateString, toDateString, pageNumber, pageSize, clipNameSearchTerm]) =>
				this.usageReportsService.getCategories$(libraryIds, fromDateString, toDateString, pageNumber, pageSize, clipNameSearchTerm).pipe(
					tap((categories: ICategories) => {
						this.setCategories(categories);
					})
				)
			)
		)
	);

	//Updaters
	readonly setLibraries = this.updater((state, libraries: ContentLibraries[]) => ({
		...state,
		libraries
	}));

	readonly setCategories = this.updater((state, categories: ICategories) => ({
		...state,
		categories
	}));

	readonly setSelectedLibraryId = this.updater((state, selectedLibraryId: number) => ({
		...state,
		selectedLibraryId
	}));

	readonly setLibraryIdsFromUrl = this.updater((state, libraryIdsFromUrl: number[]) => ({
		...state,
		libraryIdsFromUrl
	}));

	readonly deselectAllLibraries = this.updater((state) => ({
		...state,
		libraries: state.libraries.map((library) => {
			library.isSelected = false;
			return library;
		})
	}));

	readonly setReport = this.updater((state, report: IContentUsageReport[]) => ({
		...state,
		report
	}));

	readonly setContent = this.updater((state, content: Content[][]) => ({
		...state,
		content
	}));

	readonly setShowBackBtn = this.updater((state, showBackBtn: boolean) => ({
		...state,
		showBackBtn
	}));

	readonly setLibrarySearchTerm = this.updater((state, librarySearchTerm: string) => ({
		...state,
		librarySearchTerm
	}));

	readonly setClipNameSearchTerm = this.updater((state, clipNameSearchTerm: string) => ({
		...state,
		clipNameSearchTerm
	}));

	readonly setFromDateString = this.updater((state, fromDateString: string) => ({
		...state,
		fromDateString
	}));

	readonly setToDateString = this.updater((state, toDateString: string) => ({
		...state,
		toDateString
	}));

	readonly setTotalItems = this.updater((state, totalItems: number) => ({
		...state,
		totalItems
	}));

	readonly setPageNumber = this.updater((state, pageNumber: number) => ({
		...state,
		pageNumber
	}));

	readonly setPageSize = this.updater((state, pageSize: number) => ({
		...state,
		pageSize
	}));

	readonly setCategory = this.updater((state, category: string) => ({
		...state,
		category
	}));

	readonly setSubCategory = this.updater((state, subCategory: string) => ({
		...state,
		subCategory
	}));

	readonly setFromCreateDateString = this.updater((state, fromCreateDateString: string) => ({
		...state,
		fromCreateDateString
	}));

	readonly setToCreateDateString = this.updater((state, toCreateDateString: string) => ({
		...state,
		toCreateDateString
	}));

	toggleSingleItem(prop, selectedItem): void {
		this.patchState((state) => ({
			...state,
			[prop]: toggleIsSelected(state, prop, selectedItem)
		}));
	}
}
