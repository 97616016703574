<div class="relative">
	<div class="relative">
		<i class="fas fa-search absolute ft-size18 gray-text-darkest top11 left10"></i>
		<input
			style="border: 0.125rem solid #d6d9db"
			#searchInputField
			class="setWidth100 pd-left38 ht40 ft-size16 gray-bg-dark mgn-bottom15"
			[ngModel]="searchTerm"
			(ngModelChange)="onSearchKeyup($event)"
			placeholder="Search content"
		/>
	</div>
	<button *ngIf="searchTerm?.length > 0" style="width: initial" class="absolute top8 white-bg pd12 right24 z">
		<i class="fa fa-times absolute center-unknown-height-width" aria-hidden="true"></i>
	</button>
	<div style="height: 55vh" class="white-bg shadow border-radius scrollable pd-top5 pd-left15 pd-right15 pd-bottom15">
		<ng-container *ngIf="appStateService.activeFeatureArea === featureArea.PLAYLIST_BUILDER">
			<ng-container *ngIf="instantPlaylistsActive">
				<div
					(click)="onFeaturedPlaylistsClick()"
					class="{{
						librariesService.featuredPlaylistsBgColor(featuredPlaylistsService.showFeaturedPlaylists)
					}} white-text shadow ellipsis pd8 mgn-top10 mgn-bottom10 border-radius pointer relative"
				>
					<h4>Instant Playlist</h4>
					<i class="fas fa-magic mgn-left45 absolute right15 top12"></i>
				</div>

				<div *ngIf="featuredPlaylistsService.showFeaturedPlaylists">
					<p class="gray-text-darkest">COLLECTIONS</p>
					<div *ngFor="let playlist of featuredPlaylistsService.featuredPlaylists" class="mgn-bottom5">
						<lt-featured-playlist [playlist]="playlist"></lt-featured-playlist>
					</div>
				</div>
			</ng-container>

			<hr *ngIf="instantPlaylistsActive" class="gray-border-dark" />
			<div
				*ngIf="librariesService.favoriteLibraries?.length > 0"
				(click)="onFavoritesClick()"
				class="{{
					librariesService.favoritesIsExpanded ? 'pink-bg-darker' : 'pink-bg'
				}} white-text shadow ellipsis pd8 mgn-top10 mgn-bottom10 border-radius pointer relative"
			>
				<h4>Favorites</h4>
				<i class="fas fa-heart absolute right15 top12"></i>
			</div>

			<div *ngIf="librariesService.favoritesIsExpanded" class="ml-2">
				<!--FAVORITES-->
				<lt-library
					*ngFor="let library of librariesService.favoriteLibraries | prodBuilderFilter : wizardService.currentStep()?.name"
					[isFavoriteLibrary]="true"
					[library]="library"
					[treeSelection]="'favorites'"
				></lt-library>
				<hr class="gray-border-dark" />
			</div>
		</ng-container>

		<!--REGULAR LIBRARIES-->
		<lt-library
			*ngFor="let library of librariesService.libraries | prodBuilderFilter : wizardService.currentStep()?.name"
			[library]="library"
			[treeSelection]="'regular-content'"
		></lt-library>
	</div>
	<span *ngIf="librariesService.hoveredName" style="bottom: -40px" class="absolute regular">{{ librariesService.hoveredName }}</span>
</div>
