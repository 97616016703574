export class HaloSettingsJson {
	UnitIdentifier: string;
	UseDhcp: boolean;
	IpAddress: string;
	NetMask: string;
	DefaultGateway: string;
	PrimaryDns: string;
	SecondaryDns: string;
	HttpProxyUrl: string;
	Volume: number;
	AudioDeviceName: string;
	PollingIntervalSec: number;
	DaysOfWeekSchedule: string[];
	StartTimeUtcSchedule: string;
	EndTimeUtcSchedule: string;
	PlayerOn: boolean;
	UseSchedule: boolean;
	Password: string;
	SSID: string;
}
