import { Component, Input, OnInit } from '@angular/core';

import { PphViewService, TableConfigService } from '../_services';
import { SavedPlaylists } from '../../shared/view-models/content';
import { Table } from 'src/app/shared/components/table/view-models';


@Component({
    selector: 'pph-xs-view',
    templateUrl: './pph-xs-view.component.html',
    styleUrls: ['./pph-xs-view.component.scss']
})
export class PphXsViewComponent implements OnInit {

    @Input() playlist: SavedPlaylists;
    @Input() table: Table;

    constructor(
            public pphViewService: PphViewService,
            public tableConfigService: TableConfigService
    ) { }

    ngOnInit(): void {
    }


}
