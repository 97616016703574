import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
	AppStateService,
	BrowserDetectorService,
	Events,
	FileUploadService,
	MessageService,
	UtilitiesService,
	ViewportWidthService
} from 'src/app/core/services';
import { ChooseContentService } from 'src/app/playlist-builder/wizard-steps/choose-content/choose-content.service';
import { ContentBrowserService } from './content-browser.service';
import { ContentViewService } from 'src/app/shared/components/content-container/content/_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { ContentViewState, FeatureArea } from 'src/app/shared/view-models';
import { Create24Service } from '../../../user-tools/create-24/create-24.service';
import { FileUploadStateService } from 'src/app/shared/services';
import { LibrariesService } from 'src/app/shared/components/library-tree/_services';
import { ModalService } from '../../../playlist-builder/_services';
import { Create24Area } from 'src/app/user-tools/create-24/_models/create-24-state.enum';

@Component({
	selector: 'portal-content-browser',
	templateUrl: './content-browser.component.html',
	styleUrls: ['./content-browser.component.scss']
})
export class ContentBrowserComponent implements OnInit, OnDestroy {
	public percentComplete: number;
	public returnToPlaylistClicked: boolean;
	public showProgress: boolean;

	public activeUrl: string;
	private routeParams: any;
	private subs: Subscription[] = [];
	readonly c24ActiveArea = Create24Area;
	readonly featureArea = FeatureArea;

	constructor(
		public appStateService: AppStateService,
		public browserDetectorService: BrowserDetectorService,
		private chooseContentService: ChooseContentService,
		public create24Service: Create24Service,
		public contentBrowserService: ContentBrowserService,
		private contentViewService: ContentViewService,
		public cvStateService: ContentViewStateService,
		private fileUploadService: FileUploadService,
		public fileUploadStateService: FileUploadStateService,
		public librariesService: LibrariesService,
		public location: Location,
		private messageService: MessageService,
		public modalService: ModalService,
		private router: Router,
		private utilService: UtilitiesService,
		private viewportWidthService: ViewportWidthService
	) {
		this.showModalClickSubscribe();
	}

	ngOnInit(): void {
		this.routeParams = this.router.routerState.snapshot.root.firstChild.params;
		this.activeUrl = this.router.url;
		this.cvStateService.viewState = new ContentViewState();
		this.cvStateService.viewState.activeModals = [];
		this.appStateService.product.backgroundColor = 'red-bg';
		this.viewportWidthService.checkViewportWidth();
		this.cvStateService.viewState.contentListCurrentPage = 1;
	}

	canDeactivate(): boolean {
		if (this.contentBrowserService.playlistInProgress(this.activeUrl)) {
			if (!this.returnToPlaylistClicked) {
				const confirmDisregardChanges: boolean = confirm('You have an unsaved playlist! You may lose your work if you navigate away.');
				if (confirmDisregardChanges) {
					this.appStateService.removeSessionItem('playlist');
					return true;
				} else {
					return false;
				}
			} else {
				return true;
			}
		}
		return true;
	}

	showModalClickSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.showPbModal, (payload: [string, number]) => {
				this.cvStateService.viewState.modalVisible = true;
			})
		);
	}

	public onBlankCanvasClick(): void {
		const { c24ActiveArea } = this.create24Service;

		this.router.navigate([`create-24/editor/${this.routeParams.productRoute}/${c24ActiveArea}/${this.contentViewService.c24ContentType()}`]);
		setTimeout(() => {
			const content: any = null;
			const replaceContent: boolean = this.utilService.includes(this.activeUrl, 'replace');
			this.contentViewService.onSelectBgContent([content, replaceContent]);
		}, 10);
	}

	public onExitClick(): void {
		this.router.navigate([`${this.utilService.removeLastParam(this.activeUrl)}`]);
		this.create24Service.popup = '';
	}

	public onReturnToPlaylistBuilderClick(): void {
		this.returnToPlaylistClicked = true;
		this.chooseContentService.contentManagerBtnClicked = false;
		this.router.navigate([`playlist-builder/${this.appStateService.product.Route}`]);
		this.messageService.publish(Events.transitionOverlay, ['Reloading playlist...', true]);
	}

	public onRecord24Click(): void {
		this.router.navigate(['/record-24']);
	}

	uploadProgress(): void {
		this.subs.push(
			this.messageService.subscribe(Events.uploadProgress, (payload: any[]) => {
				const event = payload[0];
				this.percentComplete = this.contentBrowserService.uploadPercentComplete(event);
				this.showProgress = true;
				if (this.percentComplete === 100) {
					setTimeout(() => (this.showProgress = false), 5000);
				}
			})
		);
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
		this.cvStateService.contentList = null;
		this.fileUploadStateService.resetSteps();
		this.fileUploadService.subs.length = 0;
		this.contentBrowserService.isPopup = false;
	}
}
