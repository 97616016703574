<div class="row hidden-xs">
    <div class="col-sm-3 text-right">
      <portal-checkbox 
          [backgroundColor]="'lt-gray-bg'" 
          [isChecked]="logoOverlayService.logoPosition === 1"
          [type]="'radio'"
          (checkboxClickNotify)="onCheckboxClick(1)">
      </portal-checkbox>
      <label (mouseup)="onCheckboxClick(1)" class="relative topneg6 pointer semi-bold mgn-left5">Top Left</label>
    </div>
    <div class="col-sm-6">
      <div class="setWidth100 ht250 dark-bg border-radius relative">
        <em class="center-unknown-height-width absolute white-text center ft-size26">My TV Screen</em>
      </div>
    </div>
    <div class="col-sm-3">
      <portal-checkbox 
          [backgroundColor]="'lt-gray-bg'"
          [isChecked]="logoOverlayService.logoPosition === 2" 
          [type]="'radio'"
          (checkboxClickNotify)="onCheckboxClick(2)">
      </portal-checkbox>
      <label (mouseup)="onCheckboxClick(2)" class="relative topneg6 pointer semi-bold mgn-left5">Top Right</label>
    </div>
  </div>

  <div class="row mgn-top60 visible-xs">
    <div class="col-xs-6">
      <portal-checkbox 
          [backgroundColor]="'lt-gray-bg'" 
          [isChecked]="logoOverlayService.logoPosition === 1"
          [type]="'radio'"
          (checkboxClickNotify)="onCheckboxClick(1)">
      </portal-checkbox>
      <label (mouseup)="onCheckboxClick(1)" class="relative topneg6 pointer semi-bold mgn-left5">Top Left</label>
    </div>
    <div class="col-xs-6 text-right">
      <portal-checkbox 
          [backgroundColor]="'lt-gray-bg'" 
          [isChecked]="logoOverlayService.logoPosition === 2"
          [type]="'radio'"
          (checkboxClickNotify)="onCheckboxClick(2)">
      </portal-checkbox>
      <label (mouseup)="onCheckboxClick(2)" class="relative topneg6 pointer semi-bold mgn-left5">Top Right</label>
    </div>
  </div>

  <div class="row visible-xs">
    <div class="col-xs-12">
      <div class="setWidth100 ht250 dark-bg border-radius relative">
        <em class="center-unknown-height-width absolute white-text ft-size26">My TV Screen</em>
      </div>
    </div>
  </div>


  <div style="top: -1.563rem" class="row relative hidden-xs">
    <div class="col-sm-3 text-right">
      <portal-checkbox 
          [backgroundColor]="'lt-gray-bg'" 
          [isChecked]="logoOverlayService.logoPosition === 3"
          [type]="'radio'"
          (checkboxClickNotify)="onCheckboxClick(3)">
      </portal-checkbox>
      <label (mouseup)="onCheckboxClick(3)" class="relative topneg6 pointer semi-bold mgn-left5">Bottom Left</label>
    </div>
    <div class="col-sm-3 col-sm-offset-6">
      <portal-checkbox 
          [backgroundColor]="'lt-gray-bg'" 
          [isChecked]="logoOverlayService.logoPosition === 4"
          [type]="'radio'"
          (checkboxClickNotify)="onCheckboxClick(4)">
      </portal-checkbox>
      <label (mouseup)="onCheckboxClick(4)" class="relative topneg6 pointer semi-bold mgn-left5">Bottom Right</label>
    </div>
  </div>

  <div class="row relative mgn-top15 visible-xs">
    <div class="col-xs-6">
      <portal-checkbox 
          [backgroundColor]="'lt-gray-bg'" 
          [isChecked]="logoOverlayService.logoPosition === 3"
          [type]="'radio'"
          (checkboxClickNotify)="onCheckboxClick(3)">
      </portal-checkbox>
      <label (mouseup)="onCheckboxClick(3)" class="relative topneg6 pointer semi-bold mgn-left5">Bottom Left</label>
    </div>
    <div class="col-xs-6 text-right">
      <portal-checkbox 
          [backgroundColor]="'lt-gray-bg'" 
          [isChecked]="logoOverlayService.logoPosition === 4"
          [type]="'radio'"
          (checkboxClickNotify)="onCheckboxClick(4)">
      </portal-checkbox>
      <label (mouseup)="onCheckboxClick(4)" class="relative topneg6 pointer semi-bold mgn-left5">Bottom Right</label>
    </div>
  </div>
