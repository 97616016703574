import { ContentLibraryCategories } from '../../../api-models/content';

export class CategoriesTree extends ContentLibraryCategories {
    InverseParentCategory: CategoriesTree[];
    subCategories: CategoriesTree[];
    categories: CategoriesTree[];
    isSelected: boolean;
    folderIcon: string;
    highlightTextColor: string;
    isOrContainsFavorites: boolean;
    containsFavorites: boolean;
    getContentForMultipleCategories: boolean;
    productTypeId: number;
    isDroppable: boolean;
    name: string;
    isExpanded: boolean;
    isLibrary: boolean;
    isSubCategory: boolean;
    getContentForOneCategory: boolean;
    isMusicLibrary: boolean;
    ContentLibraryTypeId: number;
    isGlobal: boolean;
    writeAccess: boolean;
    state: 'read' | 'edit' | 'new';
}