<portal-header></portal-header>

<div class="container-fluid mgn-top30 relative">
	<div class="row mgn-top10">
		<div class="col-sm-4">
			<h1 class="semi-bold">
				<i class="fas fa-user-circle mgn-right12" aria-hidden="true"></i>
				Client Browser
			</h1>

			<div class="row mgn-top30">
				<div class="col-xs-5 relative">
					<button (click)="clientBrowserService.onCreateMailGroupClick()" class="blue-bg white-text">Create mail group</button>
					<i
						class="far fa-question-circle absolute rightneg2 topneg5"
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ appendTo: 'parent' }"
						data-tippy-content="Create a mailing group based on the filter criteria on this page."
					></i>
				</div>
				<div class="col-xs-5">
					<button [routerLink]="['/email-blast-tool/my-mailings']" class="blue-bg white-text relative">Email blast tool</button>
				</div>
			</div>
		</div>
		<div class="col-sm-4 col-sm-offset-4">
			<span>Additional search fields:</span>
			<div class="row">
				<div class="col-sm-6">
					<input type="text" placeholder="Location Name" class="mgn-bottom10" #locationName (keyup)="onSearch(locationName.value, 'locationName')" />
					<input type="text" placeholder="Username" #username (keyup)="onSearch(username.value, 'username')" />
				</div>
				<div class="col-sm-6">
					<input type="text" placeholder="Email Address" class="mgn-bottom10" #email (keyup)="onSearch(email.value, 'email')" />
					<input type="text" placeholder="Unit Identifier" #unitIdentifier (keyup)="onSearch(unitIdentifier.value, 'unitIdentifier')" />
				</div>
			</div>
			<div class="row mgn-top10">
				<div class="col-sm-6">
					<input type="text" placeholder="First or Last Name" #contactName (keyup)="onSearch(contactName.value, 'contactName')" />
				</div>
			</div>
		</div>
	</div>
	<div class="row mgn-bottom15 mgn-top30">
		<div class="col-xs-10">
			<div class="row">
				<div class="col-xs-2">
					<button (click)="onIncludeDemoAccountsClick()" class="{{ clientBrowserService.includeDemoAccounts ? 'gray-bg-darker' : 'white-bg' }}">
						Show demo accounts
					</button>
				</div>
				<div class="col-xs-2">
					<button (click)="onShowInactiveClick()" class="{{ clientBrowserService.includeInactive ? 'gray-bg-darker' : 'white-bg' }}">
						Show inactive clients
					</button>
				</div>
				<div class="col-xs-2">
					<button (click)="onHoldCREligibleOnlyClick()" class="{{ clientBrowserService.holdCrOnly ? 'gray-bg-darker' : 'white-bg' }}">
						Hold CR eligible only
					</button>
				</div>
				<div class="col-xs-3">
					<span class="absolute topneg22">OR, AND, or NOT</span>

					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="['OR', 'AND', 'NOT']"
						[selectedOption]="'OR (default)'"
						(onOptionSelect)="onVerbSelect($event)"
					></portal-dropdown-menu>
				</div>
				<div class="col-xs-3">
					<span class="absolute topneg22">Product Filters</span>
					<portal-dropdown-multi-select
						*ngIf="clientBrowserService.queryVerb !== queryVerbEnum.NOT"
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[key]="'name'"
						[options]="productFilterOptions"
						(onOptionSelect)="onProductFilterMultiSelect($event)"
					></portal-dropdown-multi-select>

					<portal-dropdown-menu
						*ngIf="clientBrowserService.queryVerb === queryVerbEnum.NOT"
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[key]="'name'"
						[options]="productFilterOptions"
						(onOptionSelect)="onProductFilterSelect($event)"
					></portal-dropdown-menu>
				</div>
			</div>
		</div>
		<div class="col-xs-2 relative">
			<span class="absolute topneg22">Apple TV Filter</span>
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[options]="[
					{ name: 'All', param: 'all' },
					{ name: 'Apple Tvs Only', param: 'include' },
					{ name: 'Non-Apple TVs Only', param: 'exclude' }
				]"
				[key]="'name'"
				[scrollbarHidden]="true"
				[selectedOption]="'All'"
				(onOptionSelect)="onAppleTVFilterSelect($event)"
			></portal-dropdown-menu>
		</div>
	</div>
	<div class="gray-bg-dark border-radius ht1 setWidth100 mgn-bottom45"></div>
	<div class="row mgn-right0 mgn-left0 white-bg border border-radius pd-top15 pd-bottom15 shadow">
		<div class="col-sm-4">
			<div class="row">
				<div class="col-sm-3">
					<h3 class="semi-bold in-block">Search:</h3>
				</div>
				<div class="col-sm-5">
					<input type="text" placeholder="Company" #companyName (keyup)="onSearch(companyName.value, 'companyName')" />
				</div>
				<div class="col-sm-4">
					<input type="text" placeholder="Industry" #industry (keyup)="onSearch(industry.value, 'industry')" />
				</div>
			</div>
		</div>
		<div class="col-md-5">
			<div class="row">
				<div class="col-sm-3">
					<input type="text" placeholder="City" #city (keyup)="onSearch(city.value, 'city')" />
				</div>
				<div class="col-sm-3">
					<input type="text" placeholder="State" #state (keyup)="onSearch(state.value, 'state')" />
				</div>
				<div class="col-sm-3">
					<input type="text" placeholder="CSR" #csr (keyup)="onSearch(csr.value, 'csr')" />
				</div>
				<div class="col-sm-3">
					<input type="text" placeholder="Salesperson" #salesperson (keyup)="onSearch(salesperson.value, 'salesperson')" />
				</div>
			</div>
		</div>
		<div *ngIf="clearResultsBtnVisible" class="col-sm-1">
			<button (click)="onClearResultsClick()" class="white-bg">
				<i class="fas fa-times mgn-right8"></i>
				Clear
			</button>
		</div>
	</div>
	<div class="row mgn-right0 mgn-left0 mgn-bottom100">
		<div *ngIf="spinnerActive" class="relative top150 z">
			<portal-spinner [width]="'wdthpx76'" [height]="'htpx76'"></portal-spinner>
		</div>
		<portal-table
			[table]="tableConfigService.table"
			[config]="tableConfigService.configuration"
			[data]="clients"
			[showJumpToPage]="true"
			[page]="clientBrowserService.pageNumber"
			[totalItems]="clientBrowserService.totalItems"
			(buttonClickNotify)="onOpenBtnClick($event)"
			(currentPageNotify)="currentTablePage($event)"
			(linkClickNotify)="onEmailClick($event)"
		></portal-table>
	</div>
</div>
