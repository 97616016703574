import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppStateService, DateTimeService, UtilitiesService } from '../../core/services';
import { ContactInformation, ProductTypes, Users } from '../../shared/api-models/admin';
import { DeliveryQueue } from '../../shared/api-models/delivery';
import { environment } from '../../../environments/environment';
import { ContentFiles } from 'src/app/shared/api-models/content';

@Injectable({
	providedIn: 'root'
})
export class ClientDetailsService {
	constructor(
		private appStateService: AppStateService,
		private dateTimeService: DateTimeService,
		private httpClient: HttpClient,
		private utilService: UtilitiesService
	) {}

	public getContractedProducts$(): Observable<ProductTypes[]> {
		const currentClient = this.appStateService.currentClient;

		const successfulDownload$ = this.appStateService.contractedProducts.map((productType) => {
			return this.httpClient.get(`${environment.deliveryUrl}DeliveryQueue/LastSuccessfulDownload/${currentClient.Id}/${productType.Id}`);
		});

		return forkJoin(successfulDownload$).pipe(
			map((deliveryQueue: DeliveryQueue[]) => {
				return this.appStateService.contractedProducts.map((product, index) => {
					if (deliveryQueue[index] && deliveryQueue[index].DeliverComplete) {
						const daysSinceLastSuccessfulDownload: string = this.dateTimeService
							.dateDifferenceInDays(new Date(deliveryQueue[index].DeliverComplete), new Date())
							.toString();
						product.lastSuccessfulDownloadDateTime = this.dateTimeService.dateAndTimeUTCtoLocal(deliveryQueue[index].DeliverComplete);
						product['textColor'] = this.utilService.lastSuccessfulDownloadTextColor(+daysSinceLastSuccessfulDownload);
					}
					return product;
				});
			})
		);
	}

	public getLastLogin$(): Observable<Users> {
		return this.httpClient.get<Users[]>(`${environment.adminUrl}CoreClients/${this.appStateService.currentClient.Id}/LastUserLogin`).pipe(
			map((user: Users[]) => {
				return user[0];
			})
		);
	}

	public getLastYearRequestCount$(): Observable<number> {
		return this.httpClient.get<number>(`${environment.adminUrl}ContentRequests/Count/LastYear/${this.appStateService.currentClient.Id}`);
	}

	public getMainContactInfo$(): Observable<ContactInformation> {
		return this.httpClient.get(environment.adminUrl + `CoreUsers/${this.appStateService.currentClient.PrimaryContactId}/ContactInformation`).pipe(
			map((user: ContactInformation) => {
				return user[0];
			})
		);
	}

	public getLogoContentFiles$(): Observable<{ name: string; url: string }[]> {
		const { currentClient } = this.appStateService;
		const screenLayoutsLogo$ = currentClient.ContentLogoContentId
			? this.httpClient.get<ContentFiles[]>(`${environment.contentUrl}Content/${currentClient.ContentLogoContentId}/ContentFiles`)
			: of(null);
		const overlayLogo$ = currentClient.BugLogoContentId
			? this.httpClient.get<ContentFiles[]>(`${environment.contentUrl}Content/${currentClient.BugLogoContentId}/ContentFiles`)
			: of(null);

		return forkJoin([screenLayoutsLogo$, overlayLogo$]).pipe(
			map((res) => {
				const [screenLayoutsLogo, overlayLogo] = res;
				const arr = [];

				if (overlayLogo) {
					const overlay = { name: 'Logo Overlay', url: `${environment.contentUrl}File/Video/${overlayLogo[0].Id}/Preview` };
					arr.push(overlay);
				}
				if (screenLayoutsLogo) {
					const logo = { name: 'Screen Layouts Logo', url: `${environment.contentUrl}File/Video/${screenLayoutsLogo[0].Id}/Preview` };
					arr.push(logo);
				}
				return arr;
			})
		);
	}
}
