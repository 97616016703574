import { Pipe, PipeTransform } from '@angular/core';
import { FiltersService } from '../services/filters.service';

@Pipe({
	name: 'sort',
	pure: false
})
export class SortPipe implements PipeTransform {
	private static compare(a: any, b: any, key: string): number {
		const aV = FiltersService.getPath(key.split('.'), a);
		const bV = FiltersService.getPath(key.split('.'), b);
		if (typeof aV === 'string' && typeof bV === 'string') {
			return aV.localeCompare(bV);
		} else {
			return aV - bV;
		}
	}

	transform(value: any[], args: { key: string; order: string }): any[] {
		if (!args.key || args.key === '') {
			return value;
		}
		if (args.order === 'asc' && value) {
			return value.sort((a, b) => SortPipe.compare(a, b, args.key));
		} else {
			if (value) {
				return value.sort((a, b) => SortPipe.compare(b, a, args.key));
			}
		}
	}
}
