<div style="width: 80%;" class="in-block">
    <me-create-layer-sidebar></me-create-layer-sidebar>
    <me-canvas-wrapper></me-canvas-wrapper>
    <me-timeline></me-timeline>
</div>

<div style="width: 20%; vertical-align: top;" class="in-block">
    <me-layer-detail></me-layer-detail>
</div>




