import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AppStateService, DateTimeService, FileUploadService, CacheService, UtilitiesService } from '../../../core/services';
import { Clients, ContentRequestStateName, Users } from '../../../shared/api-models/admin';
import { ContentLibraries } from '../../../shared/api-models/content';
import { ContentRequestEdit, ClipsView } from '../_models';
import { ContentRequestsService } from '../../_services';
import { environment } from '../../../../environments/environment';
import { ContentRequestTable, Route } from '../../../content-requests/_models';
import { ProductRoute } from 'src/app/shared/api-models/admin/product-route.enum';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

@Injectable({
	providedIn: 'root'
})
export class CreateEditRequestService {
	public request: ContentRequestEdit;
	public showPreviewModal: boolean;

	constructor(
		private appStateService: AppStateService,
		private contentRequestsService: ContentRequestsService,
		private dateTimeService: DateTimeService,
		private fileUploadService: FileUploadService,
		private httpClient: HttpClient,
		private cacheService: CacheService,
		private utilService: UtilitiesService
	) {}

	public getRequest(requestId: number): Observable<any> {
		return this.httpClient.get<ContentRequestEdit>(`${environment.adminUrl}ContentRequests/${requestId}`).pipe(
			mergeMap((request: ContentRequestEdit) => {
				const observs$ = [
					this.client$(request),
					this.csr$(request),
					this.producer$(request),
					this.requestApprovalFrom$(request),
					this.requestedBy$(request),
					this.targetLibrary$(request)
				];
				return forkJoin(observs$).pipe(
					map((response: any) => {
						this.request = this.requestView(request, response);
					})
				);
			})
		);
	}

	private requestView(request: ContentRequestEdit, response: any): ContentRequestEdit {
		const client: Clients = response[0];
		const csr: Users = response[1];
		const producer: Users = response[2];
		const requestApprovalFrom: Users = response[3];
		const requestedBy: Users = response[4];
		const targetLibrary: ContentLibraries = response[5];

		if (request.VoiceTalent === 'Unknown Male') {
			request.voiceTalentView = 'All Male';
		} else if (request.VoiceTalent === 'Unknown Female') {
			request.voiceTalentView = 'All Female';
		} else {
			request.voiceTalentView = request.VoiceTalent;
		}

		request.clientName = client.Name;
		request.csrUsername = csr.UserName;
		request.producerUsername = producer.UserName;
		request.requestApprovalFromUsername = requestApprovalFrom.UserName;
		request.requestedByUsername = requestedBy?.UserName;
		request.requestedByEmail = requestedBy?.ContactInformation?.Email;
		request.requestedByFriendlyName = `${requestedBy?.ContactInformation?.FirstName}`;
		request.requestedByIsEmployee = requestedBy?.IsEmployee;
		request.csrFriendlyName = `${csr.ContactInformation.FirstName} ${csr.ContactInformation?.LastName}`;
		request.targetLibraryName = targetLibrary.LibraryName;
		request.clientStatusView = this.contentRequestsService.clientStatus(request.StateName);
		request.csrEmail = csr.ContactInformation.Email;
		request.producerEmail = producer.ContactInformation?.Email;
		if (requestApprovalFrom.ContactInformation) {
			request.requestApprovalFromEmail = requestApprovalFrom.ContactInformation.Email;
		}
		this.cacheService.setUserCache(csr);
		this.cacheService.setUserCache(producer);
		this.cacheService.setUserCache(requestApprovalFrom);
		this.cacheService.setUserCache(requestedBy);
		return request;
	}

	private client$(request: ContentRequestEdit): Observable<any> {
		if (request.ClientId) {
			return this.httpClient.get(`${environment.adminUrl}CoreClients/${request.ClientId}`);
		}
		return of('');
	}

	public csr$(request: ContentRequestEdit): Observable<any> {
		if (request.CsrId) {
			const apiCall = this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${request.CsrId}`);
			return this.cacheService.user$(apiCall, request.CsrId);
		}
		return of('');
	}

	private producer$(request: ContentRequestEdit): Observable<any> {
		if (request.ProducerId) {
			const apiCall = this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${request.ProducerId}`);
			return this.cacheService.user$(apiCall, request.ProducerId);
		}
		return of('');
	}

	private requestApprovalFrom$(request: ContentRequestEdit): Observable<any> {
		if (request.RequestApprovalFrom) {
			const apiCall = this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${request.RequestApprovalFrom}`);
			return this.cacheService.user$(apiCall, request.RequestApprovalFrom);
		}
		return of('');
	}

	private requestedBy$(request: ContentRequestEdit): Observable<any> {
		if (request.RequestedBy) {
			const apiCall = this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${request.RequestedBy}`);
			return this.cacheService.user$(apiCall, request.RequestedBy);
		}
		return of('');
	}

	private targetLibrary$(request: ContentRequestEdit): Observable<any> {
		if (request.TargetLibraryId && request.TargetLibraryId !== 0) {
			return this.httpClient.get(`${environment.contentUrl}ContentLibraries/${request.TargetLibraryId}`);
		}
		return of('');
	}

	public requestTypeToProductRoute(requestType: string): ProductRoute {
		switch (requestType) {
			case 'On-Hold Message':
				return ProductRoute.HOLD;
			case 'Video-Lobby':
				return ProductRoute.VIDEO;
			case 'Video-Poster':
				return ProductRoute.POSTER;
		}
	}

	public updateClipAndGetContentFile(clip: ClipsView): void {
		//update clip to include CompletedContentId
		this.httpClient.put(`${environment.adminUrl}ContentRequestClips/${clip.Id}`, clip).subscribe((res) => {
			//get content file for newly uploaded content
			this.fileUploadService.getContentFile(`${environment.contentUrl}Content/${clip.CompletedContentId}/ContentFiles`);
		});
	}

	public updateContentToIsApproved(clip: ClipsView): Observable<any> {
		return this.httpClient.patch(`${environment.contentUrl}Content/${clip.CompletedContentId}`, { IsApproved: clip.IsApproved });
	}

	public setUpdateDate$(contentFileId: number): Observable<any> {
		return this.httpClient.patch(`${environment.contentUrl}ContentFiles/${contentFileId}`, { UpdateDate: this.dateTimeService.todayWithCurrentTimeUTC() });
	}

	public updateContentToIsApprovedAllClips(): void {
		const content$ = this.request.clips.map((clip) => {
			return this.updateContentToIsApproved(clip);
		});

		forkJoin(content$).subscribe();
	}

	public allowVideoApproval(): boolean {
		return (
			this.request.RequestType !== 'On-Hold Message' &&
			this.request.StateName !== ContentRequestStateName.COMPLETE &&
			(this.appStateService.currentUser.Role === UserRole.ADMINISTRATOR ||
				this.appStateService.currentUser.Role === UserRole.CSR ||
				this.appStateService.currentUser.Role === UserRole.VIDEO_CONTENT_CREATOR)
		);
	}

	public newRequestInit(route: Route): void {
		const { activeUrl } = this.appStateService;
		this.request = new ContentRequestEdit();
		this.request.clips = [];
		this.request.DueDate = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(); //generates 7 days out
		this.request.requestedByUsername = this.appStateService.currentUser.UserName;
		this.request.RequestedBy = this.appStateService.currentUser.UserId;
		this.request.RequestApprovalFrom = this.appStateService.currentUser.UserId;
		this.request.requestApprovalFromUsername = this.appStateService.currentUser.UserName;
		this.request.CreatedBy = this.appStateService.currentUser.UserId;
		this.request.VoiceTalent = 'Alternating Male/Female';
		this.request.voiceTalentView = 'Alternating Male/Female';
		this.request.Title = '';
		this.request.ClientId = this.appStateService.currentClient.Id;
		this.request.clientName = this.appStateService.currentClient.Name;
		this.request.CsrId = this.appStateService.currentClient.CsrId;
		this.request.csrEmail = this.appStateService.csrByClient.Email;
		this.request.csrFriendlyName = `${this.appStateService.csrByClient.FirstName} ${this.appStateService.csrByClient.LastName}`;

		//Client-facing view
		if (route === 'content-requests') {
			this.request.RequestType = 'On-Hold Message';
		}

		//Employee-only view
		if (route !== 'content-requests') {
			this.request.RequestType = this.utilService.getLastParam(activeUrl).replace(/%20/g, ' ');
		}

		if (this.request.RequestType === 'On-Hold Message') {
			this.request.producerUsername = 'tiffanyw';
			this.request.ProducerId = 88;
			this.request.StateName = ContentRequestStateName.AWAITING_SCRIPT_EDITS;
		} else {
			this.request.StateName = ContentRequestStateName.UNASSIGNED;
		}
	}

	public thumbnailString(): string {
		if (this.request.RequestType === 'On-Hold Message') {
			return 'Hold/Message/';
		} else {
			return 'Video/';
		}
	}

	public clientStatus(stateName: string): string {
		switch (stateName) {
			case 'Awaiting Script Edits':
			case 'Pending Script Approval':
			case 'Complete':
			case 'Cancelled':
				return stateName;
			default:
				return 'In Production';
		}
	}
}
