<div class="row mgn-top30 mgn-bottom360 relative">
	<div class="col-sm-4">
		<div style="border-radius: 16px" class="white-bg box-shadow scrollable pd15 {{ clientHealthDashboardService.showClientDetails ? 'ht588' : 'ht500' }}">
			<!--Health Status Header-->
			<div class="row mgn-bottom15">
				<div class="col-sm-6">
					<h3 class="regular in-block">Health Status</h3>
				</div>
				<div class="col-sm-6 text-right">
					<span class="ft-size16">
						{{
							clientHealthDashboardService.showClientDetails
								? clientHealthDashboardService.gumballPlayers?.length
								: clientHealthDashboardService.gumballClientsAll?.length
						}}
						{{ clientHealthDashboardService.showClientDetails ? 'Players' : 'Clients' }}
					</span>
				</div>
			</div>

			<!--Gray Divider-->
			<hr class="gray-border-darker mgn-bottom30 mgn-top0" />

			<!--Client Details Box-->
			<ng-container
				*ngIf="clientHealthViewService.hoveredClientOrPlayer && clientHealthDashboardService.isGumballClients(clientHealthViewService.hoveredClientOrPlayer)"
			>
				<div
					style="border-radius: 10px; z-index: 100"
					id="clientInfoBox"
					[ngStyle]="{ 'top.px': clientHealthViewService.darkInfoBoxTop, 'left.px': clientHealthViewService.darkInfoBoxLeft }"
					class="dark-bg white-text pd15 wdth360 fixed ft-size14"
				>
					<h3 class="semi-bold in-block mgn-right10">
						{{ clientHealthViewService.hoveredClientOrPlayer.ClientName }} |
						<em class="light">{{ clientHealthViewService.hoveredClientOrPlayer.CsrName }}</em>
						<div
							style="border-radius: 50%; height: 14px; width: 14px"
							class="in-block {{ clientHealthViewService.hoveredClientOrPlayer.gumballColorView }} relative left10 shadow"
						>
							<i *ngIf="clientHealthViewService.hoveredClientOrPlayer.showX" class="fas fa-times absolute center-unknown-height-width ft-size12 white-text"></i>
						</div>
					</h3>

					<div class="row mgn-top15">
						<div class="col-sm-10">
							<span>Players Not Checked In:</span>
							<br />
							<span>Players Not Installed:</span>
							<br />
							<span>Players Not Fixed:</span>
							<br />
							<span>Players Outdated:</span>
							<br />
							<span>Open Tickets:</span>
							<br />
							<span>Oldest Open Ticket In Days:</span>
							<br />
							<span>Open Content Requests:</span>
							<br />
							<span>Oldest Open Content Request In Days:</span>
							<br />
							<span>Rule Failure List:</span>
						</div>
						<div class="col-sm-2 text-right">
							<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.PlayerNotCheckedInCount }}</span>
							<br />
							<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.PlayerNotInstalledCount }}</span>
							<br />
							<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.PlayerNotFixedCount }}</span>
							<br />
							<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.PlayerNotOnCurrentVersionCount }}</span>
							<br />
							<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.OpenTicketCount }}</span>
							<br />
							<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.OldestOpenTicketInDays }}</span>
							<br />
							<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.OpenContentRequestCount }}</span>
							<br />
							<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.OldestOpenContentRequestInDays }}</span>
							<br />
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<span class="semi-bold" [innerHTML]="clientHealthViewService.hoveredClientOrPlayer.ruleFailureListView"></span>
						</div>
					</div>
				</div>
			</ng-container>

			<div
				*ngFor="let circle of clientHealthDashboardService.statusCircles"
				id="{{ circle.ClientId }}"
				(mouseover)="onClientCircleHover(circle)"
				(mouseleave)="clientHealthViewService.onCircleLeave()"
				(click)="onClientClick(circle)"
				style="border-radius: 50%; height: 16px; width: 16px"
				class="in-block {{ circle.gumballColorView }} relative shadow mgn-bottom10 mgn-right10"
			>
				<i *ngIf="circle.showX" class="fas fa-times absolute center-unknown-height-width ft-size12 white-text"></i>
			</div>
		</div>
	</div>

	<div class="col-sm-4">
		<div style="border-radius: 16px" class="white-bg box-shadow pd15 pd-bottom112 ht500">
			<div class="row mgn-bottom15">
				<div class="col-sm-12">
					<h3 class="regular">Health Status Summary Chart</h3>
				</div>
			</div>

			<hr class="gray-border-darker mgn-bottom30 mgn-top0" />

			<div style="border-radius: 16px" class="mgn-bottom15">
				<div style="display: flex; justify-content: space-between">
					<div>
						<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block client-health-red shadow mgn-bottom10 mgn-right8"></div>
						<span class="relative topneg14">Critical {{ clientHealthDashboardService.gumballClientsRed?.length }}</span>
					</div>
					<div>
						<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block client-health-orange shadow mgn-bottom10 mgn-right8"></div>
						<span class="relative topneg14">In Repair {{ clientHealthDashboardService.gumballClientsOrange?.length }}</span>
					</div>
					<div>
						<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block client-health-green shadow mgn-bottom10 mgn-right8"></div>
						<span class="relative topneg14">OK {{ clientHealthDashboardService.gumballClientsGreen?.length }}</span>
					</div>
					<div>
						<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block client-health-blue shadow mgn-bottom10 mgn-right8"></div>
						<span class="relative topneg14">Fickle {{ clientHealthDashboardService.gumballClientsBlue?.length }}</span>
					</div>
					<div>
						<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block client-health-purple shadow mgn-bottom10 mgn-right8"></div>
						<span class="relative topneg14">Idle {{ clientHealthDashboardService.gumballClientsPurple?.length }}</span>
					</div>
				</div>
			</div>

			<div class="ht340">
				<canvas #myDoughnut>{{ clientHealthDashboardService.clientDoughnutChart }}</canvas>
			</div>
		</div>
	</div>

	<div class="col-sm-4">
		<div class="row">
			<div class="col-xs-12">
				<div style="border-radius: 16px" class="white-bg box-shadow pd15 pd-bottom112 ht500">
					<div class="row mgn-bottom15">
						<div class="col-sm-6">
							<h3 class="regular">Overall Stats</h3>
						</div>
					</div>

					<hr class="gray-border-darker mgn-bottom30 mgn-top0" />

					<div class="row mgn-bottom15">
						<div class="col-sm-8">
							<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block dark-bg shadow mgn-right15 relative top2"></div>
							<span class="regular ft-size18">Players Offline</span>
						</div>
						<div class="col-sm-4 text-right">
							<span class="ft-size24">{{ clientHealthDashboardService.playersMIACount }}</span>
						</div>
					</div>

					<div class="row mgn-bottom15">
						<div class="col-sm-8">
							<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block dark-bg shadow mgn-right15 relative top2"></div>
							<span class="regular ft-size18">Players Not Installed</span>
						</div>
						<div class="col-sm-4 text-right">
							<span class="ft-size24">{{ clientHealthDashboardService.playersNotInstalledCount }}</span>
						</div>
					</div>

					<div class="row mgn-bottom15">
						<div class="col-sm-8">
							<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block dark-bg shadow mgn-right15 relative top2"></div>
							<span class="regular ft-size18">Players Not Fixed</span>
						</div>
						<div class="col-sm-4 text-right">
							<span class="ft-size24">{{ clientHealthDashboardService.playersNotFixedCount }}</span>
						</div>
					</div>

					<div class="row mgn-bottom15">
						<div class="col-sm-8">
							<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block dark-bg shadow mgn-right15 relative top2"></div>
							<span class="regular ft-size18">Players' Version Not Current</span>
						</div>
						<div class="col-sm-4 text-right">
							<span class="ft-size24">{{ clientHealthDashboardService.outdatedPlayersCount }}</span>
						</div>
					</div>

					<div class="row mgn-bottom15">
						<div class="col-sm-8">
							<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block dark-bg shadow mgn-right15 relative top2"></div>
							<span class="regular ft-size18">Open Tickets</span>
						</div>
						<div class="col-sm-4 text-right">
							<span class="ft-size24">{{ clientHealthDashboardService.openTicketCount }}</span>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-8">
							<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block dark-bg shadow mgn-right15 relative top2"></div>
							<span class="regular ft-size18">Open Content Requests</span>
						</div>
						<div class="col-sm-4 text-right">
							<span class="ft-size24">{{ clientHealthDashboardService.openContentRequestCount }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
