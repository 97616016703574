import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Events, MessageService, UtilitiesService } from 'src/app/core/services';
import { FileUploadStateService } from '../../shared/services';
import { Mailer } from '../../shared/api-models/admin';
import { ReminderEmailsService } from './reminder-emails.service';

@Component({
	selector: 'app-reminder-emails',
	templateUrl: './reminder-emails.component.html',
	styleUrls: ['./reminder-emails.component.scss']
})
export class ReminderEmailsComponent implements OnInit, OnDestroy {
	public imageUrl: string;
	public mailers: Mailer[];
	public product: 'Hold' | 'Video' = 'Hold';
	public uploadInProgress: boolean;

	private subs: Subscription[] = [];
	private templateBodyChanged: Subject<[string, string, string]> = new Subject<[string, string, string]>();

	constructor(
		private fileUploadStateService: FileUploadStateService,
		private messageService: MessageService,
		public reminderEmailsService: ReminderEmailsService,
		public utilService: UtilitiesService
	) {
		this.fileUploadAdvanceSequence();
		this.fileUploadComplete();

		this.templateBodyChanged.pipe(debounceTime(500)).subscribe((val: [string, string, string]) => {
			let mailerId: number = this.mailers.find((mailer) => `${mailer.Name}` === val[0]).Id;
			let key: string = val[1];
			let value: string = val[2];
			this.reminderEmailsService.patchMailer(mailerId, key, value);
		});
	}

	ngOnInit(): void {}

	public onButtonClick(product: 'Hold' | 'Video'): void {
		this.product = product;
		this.getMailers();
		this.setImgUrl();
	}

	public onDateSelect(monthOrYear: 'month' | 'year', value: string): void {
		this.reminderEmailsService.date.month = value;
		this.getMailers();
		this.setImgUrl();
	}

	public onChange(interval: number, key: string, value: string): void {
		let mailerName: string = `${interval}Day${this.product}${this.reminderEmailsService.date.month.name}`;
		this.templateBodyChanged.next([mailerName, key, value]);
	}

	public onSendTestEmailClick(): void {
		let confirmOk: boolean = confirm(`If you're ready, fire away.`);
		if (confirmOk) {
			this.reminderEmailsService.sendTestEmail();
		}
	}

	fileUploadAdvanceSequence(): void {
		this.subs.push(
			this.messageService.subscribe(Events.fileUploadAdvanceSequence, (itemId: number) => {
				if (this.fileUploadStateService.currentStep().name === 'reminderEmailsUploadComplete') {
					Object.defineProperty(this.fileUploadStateService.file, 'name', { value: `${this.reminderEmailsService.date.month.name}${this.product}.jpg` });
					this.uploadInProgress = true;
					this.reminderEmailsService.postImage(this.fileUploadStateService.file);
				}
			})
		);
	}

	fileUploadComplete(): void {
		this.subs.push(
			this.messageService.subscribe(Events.uploadComplete, () => {
				this.imageUrl = this.imageUrl + `?lastmod=${new Date()}`;
				this.uploadInProgress = false;
			})
		);
	}

	private getMailers(): void {
		this.reminderEmailsService.getMailers().subscribe((mailers: Mailer[]) => {
			this.mailers = mailers;
		});
	}

	private setImgUrl(): void {
		if (this.reminderEmailsService.date.month) {
			this.imageUrl = `${
				environment.contentUrl
			}StaticFile/Reminder/reminder-emails/${this.reminderEmailsService.date.month.name.toLowerCase()}${this.product.toLowerCase()}.jpg`;
		}
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
		this.reminderEmailsService.date = {};
	}
}
