import { Injectable } from '@angular/core';

import { Column, Table, TableConfig } from '../../../shared/components/table/view-models';

@Injectable()
export class TableConfigService {


    public columns: Column[] = [
        new Column('CompanyName', 'Company', 'link', true, 'semi-bold pointer'),
        new Column('ContactPerson', 'Contact', 'string', true),
        new Column('PhoneNumber', 'Phone', 'string'),
        new Column('ServiceType', 'Type', 'string', true),
        new Column('Salesperson', 'Salesperson', 'string', true),
        new Column('Csr', 'Csr', 'string', true),
        new Column('ContractTerm', 'Term', 'string', true),
        new Column('RenewDate', 'Renew Date', 'date'),
        new Column('totalValue', 'Total', 'string', true),
        new Column('LocationCount', 'Location Count', 'string', true),
        new Column('Notes', 'Notes', 'string', true),
        new Column('SaleDate', 'Sale Date', 'date', true)
    ];

    public table: Table = new Table(this.columns);

    public configuration: TableConfig = {
        rowFontSize: 'ft-size12',
        headerColor: 'white-bg',
        headerFontSize: 'ft-size12',
        paginationDisabled: true,
    };
}
