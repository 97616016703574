import { IMyDateModel } from 'angular-mydatepicker';

export class Model {
	StartDate: string;
	StopDate: string;
}

export class View {
	startDate: IMyDateModel;
	stopDate: IMyDateModel;
	startTime: string;
	stopTime: string;
	isStopDateSelected: boolean;
}