<portal-header></portal-header>
<!--*********************
	HEADER BAR
**********************-->
<div class="container-fluid">
	<div class="row mgn-bottom15 mgn-top15">
		<div class="col-xs-4 col-md-2">
			<div class="row">
				<div class="col-xs-12 col-lg-9">
					<button [routerLink]="[backBtnUrl]" class="white-bg"><i class="fas fa-chevron-left mgn-right12"></i>Dashboard</button>
				</div>
			</div>
			
		</div>
		<div class="col-xs-6 col-xs-offset-2 col-md-offset-4 text-right">
			<i class="fas fa-leaf mgn-right12 ft-size28 red-text"></i><h1 class="in-block semi-bold">On-Screen Logo Overlay</h1>
		</div>
	</div>

	<div class="red-gradient mgn-bottom45 ht2 border-radius"></div>
</div>

<div [ngStyle]="{'min-height.rem' : 33}"
	class="row height-sm mgn-right15 mgn-left15 border-radius pd26 white-bg col-centered mgn-bottom60 shadow">

	<div class="col-lg-2 border-right set-height-lg gray-text-darkest mgn-bottom40 relative">
		<span class="ft-size36 semi-bold">{{viewStateService.currentStep.number}}/2</span><br>
		<ng-container [ngSwitch]="viewStateService.currentStep.number">
			<span *ngSwitchCase="1" class="semi-bold ft-size16">Select Active Corner</span>
			<span *ngSwitchCase="2" class="semi-bold ft-size16">Select Players</span>
		</ng-container>
		<div class="row absolute setWidth100 bottom0">
			<div class="col-xs-5 col-sm-3 col-lg-12">
				<button class="visible-lg white-bg dark-text mgn-bottom30 ft-size14"
					(click)="onRemoveFromAllPlayersClick()"><i class="ft-size18 fa fa-times mgn-right12"
						aria-hidden="true"></i>Remove from all players</button>
			</div>
		</div>
	</div>

	<div class="col-lg-10 setHeight100 relative">
		<div class="row">
			<div class="col-lg-10 col-lg-offset-1">
				<div class="row">
					<div class="col-sm-10">
						<h3 class="semi-bold">{{viewStateService.currentStep.title}}</h3>
						<p innerHTML="{{viewStateService.currentStep.description}}"></p>
					</div>
				</div>
				<div class="row mgn-top60">
					<div class="{{viewStateService.currentStep.number !== 1 ? 'hidden' : null}} col-sm-10 col-sm-offset-1 col-lg-offset-0">
						<lo-step-one></lo-step-one>
					</div>
					<div class="{{viewStateService.currentStep.number !== 2 ? 'hidden' : null}} col-xs-12">
						<portal-drag-drop-left-right
							[height]="17"
							[invertColors]="true"
							[leftHeading]="'Available Players'"
							[rightHeading]="'Active Players'">
						</portal-drag-drop-left-right>
					</div>
				</div>	
			</div>
		</div>

		<div class="row position-md mgn-top-xs-sm">
			<div class="col-lg-10 col-lg-offset-1">
				<div class="row">
					<div class="col-sm-1">
						<a (click)="onCancelClick()">Cancel</a>
					</div>
					<div class="col-sm-3 col-sm-offset-3">
						<button *ngIf="viewStateService.currentStep.previousBtnVisible" (mouseup)="viewStateService.back()" class="border-radius ft-size16 setWidth100 gray-bg">
								<i class="ft-size18 fa {{viewStateService.currentStep.previousBtnIcon}} mgn-right16" aria-hidden="true"></i>{{viewStateService.currentStep.previousBtnText}}
						</button>
					</div>
					<div class="col-sm-3 mgn-top-xs">
						<button (mouseup)="onNextBtnClick()" class="border-radius white-text ft-size16 setWidth100 {{viewStateService.currentStep.nextBtnColor}}">
							{{viewStateService.currentStep.nextBtnText}}<i class="ft-size18 fa {{viewStateService.currentStep.nextBtnIcon}} mgn-left16" aria-hidden="true"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>