<div class="mgn0 shadow border-radius white-bg mgn-top15 pd-bottom15 mgn-bottom60">
	<div class="row pd-left15 pd-right15">
		<div class="col-sm-6">
			<div class="row mgn-top14">
				<div class="col-xs-8">
					<h3 class="in-block mgn-right12">Available Players</h3>
					<em><span class="hidden-xs hidden-sm hidden-md ft-size12">Drag a player from here to a group to the right</span></em>
					<span
						class="hidden-lg dark-text"
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ appendTo: 'parent' }"
						data-tippy-content="Drag a player from here to a group to the right."
					>
						<i class="far fa-question-circle ft-size14" aria-hidden="true"></i>
					</span>
				</div>
				<div class="col-xs-4 pull-right text-right relative">
					<input [(ngModel)]="playersSearchTerm" class="hidden-xs hidden-sm" type="text" placeholder="Search players" />
					<input [(ngModel)]="playersSearchTerm" class="hidden-md hidden-lg" type="text" placeholder="Search" />

					<button *ngIf="playersSearchTerm?.length > 0" class="white-bg absolute right20 top7 ht26 wdth26 z" (click)="playersSearchTerm = ''">
						<i class="fa fa-times absolute center-unknown-height-width" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			<div class="scrollable gray-bg-dark drag-container border-radius card-border mgn-top18" dragula="group-bag">
				<div *ngFor="let player of groupManagerService.allPlayers | searchFilter : playersSearchTerm">
					<gm-player [draggable]="'draggable'" [player]="player"></gm-player>
				</div>
			</div>
		</div>

		<div class="col-sm-6">
			<div class="row mgn-top14 relative">
				<div class="col-xs-8">
					<h3 class="in-block">Groups</h3>

					<button style="width: initial" class="limeGreen-bg white-text pd-left6 pd-right6 mgn-left18 mgn-right12 mgn-topneg6" (click)="onNewGroupClick()">
						New group
					</button>
					<em><span class="hidden-xs hidden-sm hidden-md absolute left220 top7 ft-size12">Click on a group name to edit</span></em>
					<span
						class="hidden-lg dark-text"
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ appendTo: 'parent' }"
						data-tippy-content="Click on a group name to edit."
					>
						<i class="far fa-question-circle ft-size14" aria-hidden="true"></i>
					</span>
				</div>
				<div class="col-xs-4 pull-right text-right relative">
					<input (keyup)="onGroupSearch()" class="hidden-xs hidden-sm" type="text" [(ngModel)]="groupsSearchTerm" placeholder="Search groups or players" />
					<input (keyup)="onGroupSearch()" class="hidden-md hidden-lg" type="text" [(ngModel)]="groupsSearchTerm" placeholder="Search" />

					<button *ngIf="groupsSearchTerm?.length > 0" class="white-bg absolute right20 top7 ht26 wdth26 z" (click)="groupsSearchTerm = ''">
						<i class="fa fa-times absolute center-unknown-height-width" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			<div class="scrollable drag-container border-radius card-border gray-bg-dark mgn-top18">
				<div *ngIf="showNewGroup" class="setWidth100 border-radius {{ appStateService.product.color }}-bg pd-left10 white-text mgn-bottom6 ft-size14">
					<input
						#newGroupName
						type="text"
						autofocus="autofocus"
						style="width: initial"
						class="group-name {{ appStateService.product.color }}-bg relative z"
						(keyup.enter)="onNewGroupSaveClick(newGroupName.value)"
					/>
					<button
						style="width: initial"
						class="limeGreen-bg mgn-top4 white-text pd-right6 pd-left6 mgn-right6"
						(click)="onNewGroupSaveClick(newGroupName.value)"
					>
						Save
					</button>
					<span class="hidden-xs hidden-sm hidden-md"><em>Enter your group name and hit save!</em></span>
					<span class="hidden-lg"><em>Enter your group name!</em></span>
					<a class="hidden-xs hidden-sm hidden-md mgn-left22 pointer" (click)="showNewGroup = false">
						<i class="fa fa-times ft-size14 white-text" aria-hidden="true"></i>
					</a>
					<a class="hidden-xs hidden-lg mgn-left6 pointer" (click)="showNewGroup = false">
						<i class="fa fa-times ft-size14 white-text" aria-hidden="true"></i>
					</a>
				</div>

				<div *ngIf="groupsSearchTerm?.length < 1" dragula="group-bag">
					<div
						*ngFor="let group of groupManagerService.groups"
						class="player shadow border-radius {{ appStateService.product.color }}-bg pd-left10 pd-top10 pd-bottom14 white-text mgn-bottom6 ft-size14"
					>
						<gm-group [group]="group"></gm-group>
					</div>
				</div>

				<div *ngIf="groupsSearchTerm?.length > 0" dragula="group-bag">
					<div
						*ngFor="let group of groupsSearchResults"
						class="player shadow border-radius {{ appStateService.product.color }}-bg pd-left10 pd-bottom14 white-text mgn-bottom6 ft-size14"
					>
						<gm-group [group]="group" [searchActive]="groupsSearchTerm?.length > 0"></gm-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
