import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppStateService, Events, MessageService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';
import { LibrariesService } from '../_services';
import { LibrariesTree } from '../_models';
import { FeatureArea } from 'src/app/shared/view-models';

@Injectable({
	providedIn: 'root'
})
export class LibraryService {
	constructor(
		private appStateService: AppStateService,
		private httpClient: HttpClient,
		private librariesService: LibrariesService,
		private messageService: MessageService
	) {}

	public updateLibraryName(library: LibrariesTree): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.httpClient.patch(`${environment.contentUrl}ContentLibraries/${library.Id}`, library).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}

	public showHoverButtons(mouseover: boolean): boolean {
		return !!mouseover && this.appStateService.activeFeatureArea === FeatureArea.CONTENT_MANAGER;
	}

	public bgColor(library: LibrariesTree, isFavoriteLibrary: boolean): string {
		if (this.librariesService.clickedLibraryId === library.Id && library.isExpanded) {
			if (isFavoriteLibrary) {
				return 'pink-bg-darker';
			}
			return this.appStateService.product.Route === 'hold' ? 'blue-bg-darker' : 'red-bg-darker';
		}
		if (isFavoriteLibrary) {
			return 'pink-bg';
		}
		return this.appStateService.product.Route === 'hold' ? 'blue-bg' : 'red-bg';
	}
}
