import { Injectable } from '@angular/core';

import { LayersService } from './layers.service';

@Injectable({
	providedIn: 'root'
})
export class ExportSvgScriptsService {
	constructor(private layersService: LayersService) {}

	public feedFunction(): string {
		return `function c24ContentInsertDataFeed() {
                    try {
                        if (typeof this.parent.GetNextC24ContentFeedItem == "function") {
                            var data = this.parent.GetNextC24ContentFeedItem();
                            if (data == undefined) {
                                this.parent.onClipEnd();
                            } else {
                                var titleElements = document.getElementsByClassName("c24FeedTitle");
                                var bodyElements = document.getElementsByClassName("c24FeedBody");
                                var imageElements = document.getElementsByClassName("c24FeedImage");
                                Array.from(titleElements).forEach(function (element) {
                                    element.setAttribute("href", data.TitleImageBase64);
                                });
                                Array.from(bodyElements).forEach(function (element) {
                                    element.setAttribute("href", data.BodyImageBase64);
                                });
                                Array.from(imageElements).forEach(function (element) {
                                    element.setAttribute("href", data.ImageBase64);
                                });
                            }
                        } else {
                            this.parent.onClipEnd();
                        }
                    } catch (err) {
                        console.log(err);
                        this.parent.onClipEnd();
                    }
                }`;
	}

	private countdown(): string {
		return `function countdown(){
            try {
                var interval;
                var dateDiff;
                var toDateTime = document.getElementById("countdownDateTime").firstChild.textContent;
                var countdownInterval = document.getElementById("countdownInterval").firstChild.textContent;
                var options = document.getElementById("countdownOptions").firstChild.textContent;
                //Parse date to milliseconds
                if (isNaN(toDateTime)) {
                    //strip off UTC time
                    if (toDateTime.charAt(toDateTime.length - 1) == "Z") {
                        toDateTime = toDateTime.substring(0, toDateTime.length - 1);
                    }
                    toDateTime = Date.parse(toDateTime);
                }
        
                var today = new Date().setHours(0, 0, 0, 0);
                var date = new Date(parseInt(toDateTime));
                var dateDiff = Math.round((date - today) / (1000 * 60 * 60 * 24));
        
                //Remove countdown the day after it expires
                if (dateDiff < 0) {
                    this.parent.removePlaylistItem();
                    return;
                }
        
                if (countdownInterval == "Days") {
                    getDayDiff();
                } else if (countdownInterval == "Time") {
                    getTimeDiff();
                    interval = setInterval(() => {
                        getTimeDiff();
                    }, 1000);
                }
            } catch {
        
            }
            function getDayDiff() {
                if (options == "OneLayer") {
                    var countdownDateElement = document.getElementById("countdownDisplay");
                    if (dateDiff == 1) {
                        countdownDateElement.firstChild.textContent = dateDiff + " Day";
                    } else {
                        countdownDateElement.firstChild.textContent = dateDiff + " Days";
                    }
                } else if (options == "TwoLayers") {
                    var numberElement = document.getElementById("countdownNumber");
                    var textElement = document.getElementById("countdownText");
        
                    numberElement.firstChild.textContent = dateDiff;
                    textElement.firstChild.textContent = dateDiff == 1 ? "Day" : "Days";
                }
            }
            function getTimeDiff() {
                var now = Date.now();
                var timeDiff = toDateTime - now;
                var hours = Math.floor(timeDiff / (1000 * 60 * 60));
                var minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((timeDiff % (1000 * 60)) / (1000));
        
                if (0 >= hours && 0 >= minutes && 0 >= seconds) {
                    clearInterval(interval);
                    hours = minutes = seconds = 0;
                }
        
                if (10 > hours) {
                    hours = '0' + hours.toString();
                }
                if (10 > minutes) {
                    minutes = '0' + minutes.toString();
                }
                if (10 > seconds) {
                    seconds = '0' + seconds.toString();
                }
        
                if (options == "OneLayer") {
                    var countdownTimeElement = document.getElementById("countdownDisplay");
                    countdownTimeElement.firstChild.textContent = hours + ":" + minutes + ":" + seconds;
                } else if (options == "ThreeLayers") {
                    document.getElementById("countdownHours").firstChild.textContent = hours;
                    document.getElementById("countdownMinutes").firstChild.textContent = minutes;
                    document.getElementById("countdownSeconds").firstChild.textContent = seconds;
                }
            }
        }`;
	}

	private currentTime(): string {
		return `function currentTime() {
            try {
                var timeOptions = { hour: "numeric", minute: "2-digit" };
                var timeDisplay = document.getElementById("timeDisplay").firstChild.textContent;
                var time = new Date();
                var locale = 'en-us';
        
                var hours = time.getHours();
                if (hours > 12) {
                    hours = hours - 12;
                } else if (hours == 0) {
                    hours = 12;
                }
                var minutes = time.getMinutes();
                if (minutes < 10) {
                    minutes = "0" + minutes.toString();
                }
        
                var timeElement = document.getElementById("currentTime");
                var periodElement = document.getElementById("currentTimePeriod");
                if (timeDisplay == "OneLayer") {
                    timeElement.firstChild.textContent = time.toLocaleTimeString(locale, timeOptions);
                    if (!!periodElement) { //In case the period element sneaks in
                        document.getElementById("currentTimePeriod").firstChild.textContent = "";
                    }
                } else if (timeDisplay == "SansPeriod") {
                    timeElement.firstChild.textContent = hours + ":" + minutes;
                    if (!!periodElement) { //In case the period element sneaks in
                        document.getElementById("currentTimePeriod").firstChild.textContent = "";
                    }
                } else if (timeDisplay == "TwoLayers") {
                    var period = time.getHours() > 11 ? "pm" : "am";
                    timeElement.firstChild.textContent = hours + ":" + minutes;
                    document.getElementById("currentTimePeriod").firstChild.textContent = period;
                }
            } catch {
        
            }
            }`;
	}

	private todaysDate(): string {
		return `function todaysDate() {
            try {
                var dateOptions;
                var display;
                var today = new Date();
                var locale = 'en-us';
        
                display = document.getElementById("dateDisplay").firstChild.textContent;
                try {
                    dateOptions = JSON.parse(document.getElementById('dateOptions').firstChild.textContent);
                } catch {
                    dateOptions = {
                        "weekday": "long",
                        "year": "numeric",
                        "month": "long",
                        "day": "numeric"
                    };
                }
                if (display == "OneLayer") {
                    if (!!dateOptions.year) {
                        document.getElementById('todaysDate').firstChild.textContent = today.toLocaleDateString(locale, dateOptions);
                    } else {
                        document.getElementById('todaysDate').firstChild.textContent = today.toLocaleDateString(locale, dateOptions) + getDateOrdinal(today.getDate());
                    }
                } else if (display == "TwoLayers") {
                    document.getElementById("todaysDateWeekday").firstChild.textContent = today.toLocaleDateString(locale, {
                        weekday: dateOptions.weekday
                    });
                    document.getElementById("todaysDateMonthDay").firstChild.textContent = today.toLocaleDateString(locale, {
                        month: dateOptions.month,
                        day: dateOptions.day
                    }) + getDateOrdinal(today.getDate());
                } else if (display == "ThreeLayers") {
                    document.getElementById("todaysDateWeekday").firstChild.textContent = today.toLocaleDateString(locale, {
                        weekday: dateOptions.weekday
                    });
                    document.getElementById("todaysDateMonth").firstChild.textContent = today.toLocaleDateString(locale, {
                        month: dateOptions.month
                    });
                    document.getElementById("todaysDateDay").firstChild.textContent = today.toLocaleDateString(locale, {
                        day: dateOptions.day
                    });
                }
                else if (display == "FourLayers") {
                    document.getElementById("todaysDateWeekday").firstChild.textContent = today.toLocaleDateString(locale, {
                        weekday: dateOptions.weekday
                    });
                    document.getElementById("todaysDateMonth").firstChild.textContent = today.toLocaleDateString(locale, {
                        month: dateOptions.month
                    });
                    document.getElementById("todaysDateDay").firstChild.textContent = today.toLocaleDateString(locale, {
                        day: dateOptions.day
                    });
                    document.getElementById("todaysDateYear").firstChild.textContent = today.toLocaleDateString(locale, {
                        year: dateOptions.year
                    });
                }
            } catch { }
            function getDateOrdinal(day) {
                if (day > 3 && day < 21) {
                    return "th";
                } else {
                    switch (day % 10) {
                        case 1: return "st";
                        case 2: return "nd";
                        case 3: return "rd";
                        default: return "th";
                    }
                }
            }
        }`;
	}

	public script(): string {
		return `<script type="text/javascript">
                    <![CDATA[ function c24DynamicContentLoad() {
                        ${this.scriptFunctions().execution}
                    }
                    ${this.scriptFunctions().functions}
                    ]]>
                </script>`;
	}

	public scriptFunctions(): any {
		const hasFeed: boolean = this.layersService.atLeastOneFeedLayer();
		const hasCountdownDate: boolean = this.layersService.layers.some((layer) => layer.type === 'Countdown');
		const hasCurrentDate: boolean = this.layersService.layers.some((layer) => layer.type === 'Current Date');
		const hasCurrentTime: boolean = this.layersService.layers.some((layer) => layer.type === 'Current Time');

		const atLeastOneDynamicLayer = [hasCountdownDate, hasCurrentDate, hasCurrentTime].some((x) => x === true);

		switch (true) {
			//Feed only
			case hasFeed && !hasCountdownDate && !hasCurrentDate && !hasCurrentTime:
				return { execution: 'c24ContentInsertDataFeed()', functions: this.feedFunction() };

			//Feed and at least one countdown, current date, or current time layer
			case hasFeed && atLeastOneDynamicLayer:
				return {
					execution: 'c24ContentInsertDataFeed(); \n countdown(); \n currentTime(); \n todaysDate();',
					functions: `${this.feedFunction()} ${this.countdown()} ${this.currentTime()} ${this.todaysDate()}`
				};

			//No feed and at least one countdown, current date, or current time layer
			case !hasFeed && atLeastOneDynamicLayer:
				return { execution: 'countdown(); \n currentTime(); \n todaysDate();', functions: `${this.countdown()} ${this.currentTime()} ${this.todaysDate()}` };

			//No nothing
			case !hasFeed && !atLeastOneDynamicLayer:
				return { execution: '', functions: `` };
		}
	}
}
