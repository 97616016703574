export class CsrBlastRecipients {
    Id: number;
    CsrBlastId: number;
    ClientName: string;
    UserFirstName: string;
    UserLastName: string;
    UserEmail: string;
    IsPrimaryContact: boolean;
    ClientId: number;
    UserId: number;

    deleteBtn: string;
    classList: string;
    friendlyName: string;
    mainContact: string;
}