import { AuthInterceptorService } from './core/services/auth-interceptor.service';
import { BrowserModule } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AccessDeniedComponent } from './core/access-denied/access-denied.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ContactUsModule } from './contact-us/contact-us.module';
import { ContentBrowserModule } from 'src/app/shared/components/content-browser/content-browser.module';
import { ContentRequestsModule } from './content-requests/content-requests.module';
import { CoreModule } from './core/core.module';
import { CrmModule } from './crm/crm.module';
import { FeaturedContentComponent } from './featured-content/featured-content.component';
import { ForgotPasswordViewComponent } from './login/forgot-password-view/forgot-password-view.component';
import { GlobalSettingsModule } from './global-settings/global-settings.module';
import { GroupManagerModule } from './group-manager/group-manager.module';
import { LargeFileTransferComponent } from './large-file-transfer/large-file-transfer.component';
import { LoginComponent } from './login/login.component';
import { loginPageGuard } from './core/services/route-guards';
import { LogoOverlayComponent } from './logo-overlay/logo-overlay.component';
import { MainViewComponent } from './login/main-view/main-view.component';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { PlayerPlaylistHistoryModule } from './player-playlist-history/player-playlist-history.module';
import { PlaylistBuilderAccessDeniedComponent } from './core/playlist-builder-access-denied/playlist-builder-access-denied.component';
import { ProductsModule } from './my-products/my-products.module';
import { ResetPasswordViewComponent } from './login/reset-password-view/reset-password-view.component';
import { SearchFilterPipe } from './shared/pipes/search-filter.pipe';
import { SharedModule } from './shared/shared.module';
import { SoftwareModule } from './user-tools/software.module';
import { StepOneComponent } from './logo-overlay/step-one/step-one.component';
import { SystemToolsModule } from './system-tools/system-tools.module';
import { AuthCodeViewComponent } from './login/auth-code-view/auth-code-view.component';

@NgModule({
	declarations: [
		AppComponent,
		FeaturedContentComponent,
		ForgotPasswordViewComponent,
		LargeFileTransferComponent,
		LoginComponent,
		LogoOverlayComponent,
		MainViewComponent,
		ResetPasswordViewComponent,
		StepOneComponent,
		AuthCodeViewComponent
	],
	imports: [
		AppRoutingModule,
		BrowserModule,
		ContactUsModule,
		ContentBrowserModule,
		ContentRequestsModule,
		CoreModule,
		CrmModule,
		ProductsModule,
		FormsModule,
		GroupManagerModule,
		GlobalSettingsModule,
		HttpClientModule,
		SharedModule,
		SoftwareModule,
		SystemToolsModule,
		PlayerPlaylistHistoryModule,
		RouterModule.forRoot(
			[
				{ path: '', component: LoginComponent, canActivate: [loginPageGuard] },
				{ path: 'login', component: LoginComponent, canActivate: [loginPageGuard] },
				{ path: 'user-sign-out', component: LoginComponent, canActivate: [loginPageGuard] },
				{ path: 'session-expired', component: LoginComponent, canActivate: [loginPageGuard] },
				{ path: 'PasswordReset/:guid', component: LoginComponent },
				{ path: 'access-denied', component: AccessDeniedComponent },
				{ path: 'playlist-builder-access-denied/:param', component: PlaylistBuilderAccessDeniedComponent },
				{ path: '404', component: NotFoundComponent },
				{ path: 'content-manager/video', redirectTo: 'content-manager/video/browser' },
				{ path: 'content-manager/hold', redirectTo: 'content-manager/hold/browser' },
				{ path: 'content-manager/poster', redirectTo: 'content-manager/poster/browser' },
				{ path: 'content-manager/webV', redirectTo: 'content-manager/webV/browser' },
				{ path: 'content-manager/webH', redirectTo: 'content-manager/webH/browser' },
				{ path: 'content-manager/vwall2x1v', redirectTo: 'content-manager/vwall2x1v/browser' },
				{ path: 'content-manager/vwall2x1h', redirectTo: 'content-manager/vwall2x1h/browser' },
				{ path: 'content-manager/vwall3x1v', redirectTo: 'content-manager/vwall3x1v/browser' },
				{ path: 'content-manager/vwall5x1v', redirectTo: 'content-manager/vwall5x1v/browser' },
				{ path: 'content-manager/vwall1x2v', redirectTo: 'content-manager/vwall1x2v/browser' },
				{ path: 'content-manager/vwall1x2h', redirectTo: 'content-manager/vwall1x2h/browser' },
				{ path: 'content-manager/vwall4x1v', redirectTo: 'content-manager/vwall4x1v/browser' },
				{ path: 'content-manager/vwall4x1h', redirectTo: 'content-manager/vwall4x1h/browser' },
				{ path: 'content-manager/vwall5x3h', redirectTo: 'content-manager/vwall5x3h/browser' },
				{ path: 'content-manager/vwall2x2h', redirectTo: 'content-manager/vwall2x2h/browser' },
				{ path: 'content-manager/vwall3x1h', redirectTo: 'content-manager/vwall3x1h/browser' },
				{ path: 'content-manager/vwall1x3h', redirectTo: 'content-manager/vwall1x3h/browser' },
				{ path: 'content-manager/vwall2x2v', redirectTo: 'content-manager/vwall2x2v/browser' },
				{ path: '**', redirectTo: '/404' }
			],
			{}
		)
	],
	providers: [
		DeviceDetectorService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true
		},
		SearchFilterPipe
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
