import { Component, OnInit, OnDestroy } from '@angular/core';

import { CanvasService, CEventsService } from '../../canvas/_services';
import { LayerDetailService } from '../_services/layer-detail.service';
import { LayersService } from '../../_services';
import { Subscription } from 'rxjs';
import { Create24Area } from '../../../_models/create-24-state.enum';

@Component({
	selector: 'ld-image',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit, OnDestroy {
	public dimensionsLinked: boolean;
	public heightPx: number;
	public widthPx: number;

	private subs: Subscription[] = [];
	readonly create24Area = Create24Area;

	constructor(
		private canvasService: CanvasService,
		private cEventsService: CEventsService,
		public layerDetailService: LayerDetailService,
		public layersService: LayersService
	) {
		this.onImageScaling();
	}

	ngOnInit(): void {
		this.dimensionsLinked = true;
		this.setHeightWidth();
	}

	onImageScaling(): void {
		this.subs.push(
			this.cEventsService.imageScaling$.subscribe(() => {
				this.setHeightWidth();
			})
		);
	}

	public onCrop(key: string, value): void {
		this.layersService.activeLayer.canvasObj[key] = value;
		this.layerDetailService.updateProperty(key, value);
	}

	public isFeedImageLayer(): boolean {
		return this.layersService.activeLayer.type === 'Feed Image';
	}

	public onHeightChange(): void {
		if (!this.layerDetailService.isCropping) {
			this.layersService.activeLayer.canvasObj.scaleY = this.heightPx / this.layersService.activeLayer?.canvasObj.height;
			let obj = this.canvasService.canvas.getObjects().find((obj) => obj.layerId === this.layersService.activeLayer.id);
			obj.scaleY = this.heightPx / this.layersService.activeLayer?.canvasObj.height;
			this.canvasService.canvas.renderAll();

			if (this.dimensionsLinked) {
				let ratio = this.layersService.activeLayer?.canvasObj.width / this.layersService.activeLayer?.canvasObj.height;
				this.widthPx = +(this.heightPx * ratio).toFixed(0);
				obj.scaleX = this.widthPx / this.layersService.activeLayer?.canvasObj.width;
				this.canvasService.canvas.renderAll();
			}
		}
	}

	public onWidthChange(): void {
		if (!this.layerDetailService.isCropping) {
			this.layersService.activeLayer.canvasObj.scaleX = this.widthPx / this.layersService.activeLayer?.canvasObj.width;
			let obj = this.canvasService.canvas.getObjects().find((obj) => obj.layerId === this.layersService.activeLayer.id);
			obj.scaleX = this.widthPx / this.layersService.activeLayer?.canvasObj.width;
			this.canvasService.canvas.renderAll();

			if (this.dimensionsLinked) {
				let ratio = this.layersService.activeLayer?.canvasObj.height / this.layersService.activeLayer?.canvasObj.width;
				this.heightPx = +(this.widthPx * ratio).toFixed(0);
				obj.scaleY = this.heightPx / this.layersService.activeLayer?.canvasObj.height;
				this.canvasService.canvas.renderAll();
			}
		}
	}

	private setHeightWidth(): void {
		this.heightPx = +(this.layersService.activeLayer?.canvasObj.height * this.layersService.activeLayer?.canvasObj.scaleY).toFixed(0);
		this.widthPx = +(this.layersService.activeLayer?.canvasObj.width * this.layersService.activeLayer?.canvasObj.scaleX).toFixed(0);
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
