import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { AppStateService, UtilitiesService } from '../../core/services';
import { environment } from '../../../environments/environment';
import { EProductTypes, Players, ServiceLocations } from '../../shared/api-models/admin';

@Injectable()
export class LocationsService {
	public locations: ServiceLocations[];
	public locationsDropdownView: ServiceLocations[];
	public locationsCount: number;

	constructor(private appStateService: AppStateService, private httpClient: HttpClient, private utilService: UtilitiesService) {}

	public deleteLocation(locationId: number): Observable<any> {
		return this.httpClient.delete(`${environment.adminUrl}CoreServiceLocations/${locationId}`);
	}

	public getAllLocationsForClient(): Observable<ServiceLocations[]> {
		return this.httpClient.get<ServiceLocations[]>(`${environment.adminUrl}CoreServiceLocations/Client/${this.appStateService.currentClient.Id}`).pipe(
			switchMap((locations: ServiceLocations[]) => {
				this.locationsCount = locations.length;
				const players$ = locations.map((location) => this.httpClient.get<Players[]>(`${environment.adminUrl}CoreServiceLocations/${location.Id}/Players`));
				return forkJoin(players$).pipe(
					map((players: Players[][]) => {
						return this.locationsView(locations, players);
					})
				);
			})
		);
	}

	private locationsView(locations: ServiceLocations[], players: Players[][]): ServiceLocations[] {
		return locations.map((location, index) => {
			location.players = players[index];
			const holdUnitCount: number = location.players.filter((player) => player.ProductTypeId === 1)?.length;
			const holdUnit: string = holdUnitCount > 1 ? 'multiple units' : location.players.find((player) => player.ProductTypeId === 1)?.PlayerModel.Name;
			const holdRemoteNumber: string =
				holdUnitCount > 1
					? 'multiple units'
					: this.utilService.formatPhoneNumber(
							`${location.players.find((player) => player.ProductTypeId === 1)?.RemoteAreaCode}${
								location.players.find((player) => player.ProductTypeId === 1)?.RemotePhone
							}`
					  );

			location.deleteBtn = 'Delete';
			location.classList = 't-row';
			location.name = location.Name;
			location.holdRemoteNumber = holdRemoteNumber;
			location.holdUnit = holdUnit;
			location.viewBtn = 'View';
			location.hold = { number: this.playerCount(location.players, EProductTypes.ON_HOLD), icon: 'fas fa-phone-volume' };
			location.radio = { number: this.playerCount(location.players, EProductTypes.RADIO), icon: 'fas fa-volume-up' };
			location.video = { number: this.playerCount(location.players, EProductTypes.LOBBY_VIDEO), icon: 'fas fa-tv' };
			location.poster = { number: this.playerCount(location.players, EProductTypes.POSTER), icon: 'far fa-square' };
			location.web = { number: this.webPlayersCount(location.players), icon: 'fas fa-laptop' };
			location.videoWall = { number: this.videoWallCount(location.players), icon: 'fas fa-th' };
			return location;
		});
	}

	private webPlayersCount(players: Players[]): number {
		const webPlayerTypes: EProductTypes[] = [EProductTypes.WEB_H, EProductTypes.WEB_V];
		return players.filter((player) => webPlayerTypes.includes(player.ProductTypeId)).length;
	}

	private videoWallCount(players: Players[]): number {
		const vWallEntries: EProductTypes[] = Object.keys(EProductTypes)
			.filter((key) => key.startsWith('V_WALL_'))
			.map((key) => EProductTypes[key]);
		return players.filter((player) => vWallEntries.includes(player.ProductTypeId)).length;
	}

	private playerCount(players: Players[], productType: EProductTypes): number {
		return players.filter((player) => player.ProductTypeId === productType)?.length;
	}
}
