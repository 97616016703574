import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Column } from '../table/view-models/column';
import { Table, TableConfig } from './view-models';
import { BrowserDetectorService, DateTimeService, Events, MessageService, UtilitiesService } from '../../../core/services';

@Component({
	selector: 'portal-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {
	@Input() table: Table;
	@Input() page: number;
	@Input() config: TableConfig;
	@Input() data: any[];
	@Input() isAllSelected: boolean;
	@Input() showJumpToPage: boolean;
	@Input() sortBy: any;
	@Input() totalItems: number;
	@Input() guid: string;

	@Output() buttonClickNotify: EventEmitter<any> = new EventEmitter<any>();
	@Output() columnOverNotify: EventEmitter<any> = new EventEmitter<any>();
	@Output() columnLeaveNotify: EventEmitter<any> = new EventEmitter<any>();
	@Output() currentPageNotify: EventEmitter<number> = new EventEmitter<number>();
	@Output() getSearchValueNotify: EventEmitter<any> = new EventEmitter<any>();
	@Output() headerCheckboxClickNotify: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() linkClickNotify: EventEmitter<[any, Column]> = new EventEmitter<[any, Column]>();
	@Output() checkboxClickNotify: EventEmitter<any> = new EventEmitter<any>();
	@Output() rowSelectNotify: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('rowIndex') row;

	public columnHovered: boolean;
	public currentSearchFieldIndex: number;
	public dataBoxVisible: boolean;
	public headerCheckboxClicked: boolean;
	public inputValue: string;
	public order: string;
	public reverse: boolean;
	public globalSearchTerm: string;
	public selectedColumnIndex: number;
	public selectedRowIndex: number;
	public isHovered: boolean;
	public rowId: number;

	public buttonMouseoverOn: boolean;
	private subs: Subscription[] = [];

	constructor(
		public browserDetectorService: BrowserDetectorService,
		public dateTimeService: DateTimeService,
		private messageService: MessageService,
		public utilService: UtilitiesService
	) {
		this.detectChangesSubscribe();
		this.uncheckHeaderCheckbox();
	}

	ngOnInit() {
		if (!this.sortBy) {
			this.sortBy = { key: '' };
		}
		if (this.guid) {
			this.table.guid = this.guid;
		}
		this.checkForTableGuid();
	}

	ngOnChanges() {
		if (this.totalItems) {
			this.table.totalItems = this.totalItems;
		}
		/* Bind this.data to this.table.data so we can distinguish between
          two tables in the same view if necessary. */
		if (this.data) {
			this.table.data = this.data;
		}
	}

	onCheckboxClick(row: any): void {
		this.checkboxClickNotify.emit(row);
	}

	public onSnapshotHover(id: number): void {
		this.rowId = id;
		this.isHovered = true;
	}

	uncheckHeaderCheckbox() {
		this.subs.push(
			this.messageService.subscribe(Events.uncheckHeaderCheckbox, (_) => {
				this.headerCheckboxClicked = false;
			})
		);
	}

	public buttonDisabled(row, column: Column): boolean {
		switch (true) {
			case row.isDisabled && row[column['columnDisabled']] === 'true':
			case !row.isDisabled && row[column['columnDisabled']] === 'true':
				return true;
			default:
				return false;
		}
	}

	private checkForTableGuid(): void {
		!this.table.guid ? (this.table.guid = 'id') : null;
	}

	public getInputValue(value: string, currentSearchFieldIndex: number): void {
		let payload = [value, currentSearchFieldIndex];
		if (this.config.nonServerPagination) {
			this.globalSearchTerm = value;
		}

		this.getSearchValueNotify.emit(payload);
		this.currentSearchFieldIndex = currentSearchFieldIndex;
		this.inputValue = value;
	}

	public onButtonClick(column: any, row: any, value?: string): void {
		let payload: any = [column, row, value];
		//are we saving an editable column?
		if (column.isEditable) {
			row[column.key] = value;
			column.state = 'readMode';
		}
		this.buttonClickNotify.emit(payload);
	}

	public renderColReadMode(column: any, rowIndex, colIndex): boolean {
		if (column.state === 'editMode') {
			return this.selectedRowIndex !== rowIndex && this.selectedColumnIndex === colIndex;
		}
		return true;
	}

	public onColumnClick(column: any, row: Object, colIndex: number, rowIndex: number): void {
		//allow only one column to be in an edit state at a time
		if (column.isEditable && this.columnsState(this.table.columns).every((val) => val !== 'editMode')) {
			this.setColumnAndRowIndex(colIndex, rowIndex);
			column.state = 'editMode';
		}
		if (column.type === 'link') {
			let payload: [any, Column] = [row, column];
			this.linkClickNotify.emit(payload);
		}
	}

	public onColumnOver(col, row, colIndex: number, rowIndex: number): void {
		this.columnHovered = true;
		if (this.columnsState(this.table.columns).every((val) => val !== 'editMode')) {
			this.setColumnAndRowIndex(colIndex, rowIndex);
		}
		this.columnOverNotify.emit([col, row]);
	}

	public onColumnLeave(): void {
		this.columnHovered = false;
		this.columnLeaveNotify.emit();
	}

	public onRowSelect(row: any): void {
		if (this.config.rowSelect && !this.buttonMouseoverOn) {
			this.rowSelectNotify.emit(row);
		}
	}

	currentPage(page: number): void {
		this.page = page;
		if (!this.config.disableScrollToTop) {
			this.utilService.scrollToTop();
		}

		if (!this.config.nonServerPagination) {
			setTimeout(() => {
				this.currentPageNotify.emit(page);
			}, 700);
		}
	}

	onHeaderCheckboxClick(payload): void {
		this.headerCheckboxClicked = !this.headerCheckboxClicked;
		this.headerCheckboxClickNotify.emit(payload);
	}

	orderBy(key: string, column: Column, sortKey?: string): void {
		if (column.isSortable) {
			this.sortBy.key = sortKey ?? key;

			if (this.sortBy.order === 'asc') {
				this.sortBy.order = 'desc';
			} else {
				this.sortBy.order = 'asc';
			}
			const sortParams = {
				key: sortKey ?? key,
				order: this.sortBy.order
			};
			if (this.config.nonServerPagination) {
				this.table.data = [...this.table.data];
			} else {
				this.messageService.publish(Events.onColumnSort, sortParams);
			}
		}
	}

	public setRowHeight(column: Column, rowIndex: number): string {
		switch (true) {
			case this.fixedRowHeightAndReadMode(column):
				return 'ht70';

			case this.editModeAndNotSelectedRow(column, rowIndex):
				return 'ht70';
		}
	}

	/* If making multiple calls to populate the table, this method enables the 
       table to update. Tables with a guid can be distinguished from one another, 
       and with the data being bound to this.table, the method below will only update 
       the appropriate data. */
	private detectChangesSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.detectTableChanges, (payload) => {
				//If we need to detect multiple tables in the same view.
				//Table guid is just a unique table identifier.
				if (this.table.guid !== 'id') {
					if (this.table.guid === payload[1]) {
						this.table.data = JSON.parse(JSON.stringify(payload[0]));
						return;
					}
					return;
				}
				//If only using one table, no guid is necessary.
				this.table.data = JSON.parse(JSON.stringify(payload));
			})
		);
	}

	private fixedRowHeightAndReadMode(column: Column): boolean {
		return this.config.fixedRowHeight && column.state === 'readMode';
	}

	private editModeAndNotSelectedRow(column: Column, rowIndex: number): boolean {
		return column.state === 'editMode' && this.selectedRowIndex !== rowIndex;
	}

	private columnsState(arr): any {
		return arr.map((item) => {
			return item.state;
		});
	}

	private setColumnAndRowIndex(colIndex: number, rowIndex: number): void {
		this.selectedColumnIndex = colIndex;
		this.selectedRowIndex = rowIndex;
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
