import { Component, OnInit } from '@angular/core';

import { LayersService, UndoRedoService } from '../../../_services';
import { LayerDetailService } from '../../_services/layer-detail.service';

@Component({
    selector: 'ld-drop-shadow',
    templateUrl: './drop-shadow.component.html',
    styleUrls: ['./drop-shadow.component.scss']
})
export class DropShadowComponent implements OnInit {

    constructor(
        public layerDetailService: LayerDetailService,
        public layersService: LayersService,
        public undoRedoService: UndoRedoService
    ) { }

    ngOnInit(): void {

    }

    public onSliderChange(prop: string, value: number): void {
        this.layersService.activeLayer.canvasObj.shadow[prop] = value;
        this.setShadow();
        this.layerDetailService.updateFeedTextImage();
    }

    private setShadow(): void {
        this.layerDetailService.updateProperty('shadow', this.layersService.activeLayer.canvasObj.shadow);
    }
}
