import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import uuid from 'uuid';

import { AppStateService, Events, MessageService } from '../../../core/services';
import { environment } from '../../../../environments/environment';
import { CreateEditTicketService, EmailService, HistoryNoteService, SaveService } from '../create-edit-ticket/_services';
import { CreateEditWorkOrderService } from '../create-edit-work-order/_services/create-edit-work-order.service';
import { PdfService } from '../create-edit-work-order/_services/pdf.service';
import { Users } from '../../../shared/api-models/admin';
import { WorkOrdersView } from '../create-edit-ticket/_models';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderViewService {

    constructor(
        private appStateService: AppStateService,
        private ceTicketService: CreateEditTicketService,
        private ceWorkOrderService: CreateEditWorkOrderService,
        private emailService: EmailService,
        private historyNoteService: HistoryNoteService,
        private httpClient: HttpClient,
        private messageService: MessageService,
        private pdfService: PdfService,
        private saveService: SaveService
    ) { }

    public onCompleteClick(workOrder: WorkOrdersView): void {
        workOrder.IsComplete = true;
        workOrder.CompletedDate = new Date().toISOString();
        workOrder.completeBoxVisible = false;
        workOrder.ConfirmationNumber = this.confirmationNumber();
        this.ceTicketService.refreshLastUpdatedLastActionView();
        this.historyNoteService.postHistoryNote(this.historyNoteService.workOrderCompleteHistoryNote(workOrder))
            .subscribe( () => {
                this.messageService.publish(Events.onPostHistoryNote, null);
            })
        this.ceWorkOrderService.updateWorkOrder(workOrder);
        if(this.appStateService.currentUser.UserId !== this.ceTicketService.ticket.AssignedToUserId) {
            this.emailService.sendEmail(this.emailService.workOrderCompleteEmail(workOrder));   
        }
        this.saveService.updateTicket();
    }

    public onConfirmClick(workOrder: WorkOrdersView): void {
        workOrder.IsApproved = !workOrder.IsApproved;
        this.ceTicketService.refreshLastUpdatedLastActionView();
        this.ceWorkOrderService.updateWorkOrder(workOrder);
        this.saveService.updateTicket();
    }

    onDateChanged(date: string, workOrder: WorkOrdersView): void {
        workOrder.ScheduledDate = date;
        this.ceWorkOrderService.updateWorkOrder(workOrder);
    }

    public confirmationNumber(): string {      
        let guid: string = uuid.v4();
        guid = guid.slice(-12);
        guid = guid.slice(0, 4) + " " + guid.slice(4);
        guid = guid.slice(0, 9) + " " + guid.slice(9);
        guid = guid.slice(0, 14) + " " + guid.slice(14);
        return guid;
    }

    public onEmailOrFaxClick(workOrder: WorkOrdersView, emailOrFax: 'email' | 'fax') {
        this.ceWorkOrderService.pdfGenerateInProcess = true;

        let currentUser$ = this.httpClient.get(`${environment.adminUrl}CoreUsers/${this.appStateService.currentUser.UserId}`);
        let assignedToUser$ = this.httpClient.get(`${environment.adminUrl}CoreUsers/${this.ceTicketService.ticket.AssignedToUserId}`);
        this.messageService.publish(Events.savingPreloader, 1);

        forkJoin([currentUser$, assignedToUser$])
            .subscribe((response: [Users, Users]) => {
                let currentUser: Users = response[0];
                let assignedToUser: Users = response[1];
                this.ceTicketService.ticket.assignedToEmail = assignedToUser.ContactInformation.Email;
                this.ceTicketService.ticket.currentUserEmail = currentUser.ContactInformation.Email;
                workOrder.SentDate = new Date().toISOString();

                this.ceTicketService.refreshLastUpdatedLastActionView();
                this.saveService.updateTicket();
                this.historyNoteService.postHistoryNote(this.historyNoteService.emailOrFaxHistoryNote(workOrder, emailOrFax)).subscribe( () => {
                    this.messageService.publish(Events.onPostHistoryNote, null);
                });

                //generate PDF, event is emitted and subscribe to in component to send the email
                this.pdfService.createPDF(workOrder, emailOrFax, false, true);
            })
    }
}
