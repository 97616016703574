import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppStateService, UtilitiesService } from 'src/app/core/services';
import { CanvasService } from '../../canvas/_services';
import { environment } from '../../../../../../environments/environment';
import { Feed } from '../../_models';
import { LayerDetailService } from '../_services';
import { ClientIds } from 'src/app/shared/api-models/admin/client-ids.enum';

@Injectable({
	providedIn: 'root'
})
export class FeedService {
	constructor(
		private appStateService: AppStateService,
		private canvasService: CanvasService,
		private httpClient: HttpClient,
		private layerDetailService: LayerDetailService,
		private utilService: UtilitiesService
	) {}

	public getFeeds(): void {
		this.httpClient
			.get<Feed[]>(`${environment.contentUrl}Feeds/Summaries/Client/${this.appStateService.currentClient.Id}?includeGlobals=${true}&feedItemCount=50`)
			.pipe(
				map((feeds: Feed[]) => {
					const allAccounts = [10, 11, 12, 13, 14, 1000002];
					const w24OfficeAccount = [...allAccounts, 1, 2, 3];
					let filteredFeeds;
					if (this.appStateService.currentClient.Id === ClientIds.WORKS24_OFFICE_ACCOUNT) {
						filteredFeeds = feeds.filter((feed) => w24OfficeAccount.some((feedSourceId) => feedSourceId === feed.FeedSourceId));
					} else {
						filteredFeeds = feeds.filter((feed) => allAccounts.some((feedSourceId) => feedSourceId === feed.FeedSourceId));
					}
					return this.utilService.sortItems(filteredFeeds, 'Name');
				})
			)
			.subscribe((feeds) => {
				this.canvasService.feedsList = feeds;

				//Keeps feed item text in sync if text is edited in feed manager
				if (this.canvasService.feed) {
					this.canvasService.feed = this.canvasService.feedsList.find((feed) => feed.Id === this.canvasService.feed.Id);
					this.updateFeedItem();
				}
			});
	}

	public updateFeedItem(): void {
		//Order is the int displayed in the dropdown menu
		this.canvasService.feed?.Items.forEach((item, i) => {
			item.order = i + 1;
		});
		this.setFeedItemValues();

		//Update values for all feed objects
		this.layerDetailService.updateValuesAllFeedObjs();

		//Limit feed items array length to 15 so dropdown doesn't get too long
		this.canvasService.feed.Items = this.canvasService.feed.Items.slice(0, 15);
	}

	public setFeedItemValues(): void {
		//Update feed item
		this.canvasService.feedItem = this.canvasService.feed.Items[this.canvasService.feedItem?.order - 1];

		if (this.canvasService.feedItem) {
			this.canvasService.feedItem.parsedValues = JSON.parse(this.canvasService.feedItem?.Values);
		}
	}
}
