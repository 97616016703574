export class ContentLibraryCategories {
	Id?: number;
	LibraryId: number;
	ParentCategoryId: number;
	ParentCategory?: ContentLibraryCategories;
	CategoryName: string;
	IsDeleted: boolean;
	
	ProductTypeId?: number;
	subCategory?: ContentLibraryCategories;
}