<div class="col-xs-12 {{ createEditRequestService.request.RequestType === 'Video-Other' ? 'col-sm-6' : 'col-lg-4' }} ft-size-md relative">
	<div [ngStyle]="{ 'height.rem': heightService.commentsHeight() }" class="shadow border-radius white-bg pd15 scrollable mgn-bottom60">
		<div class="row mgn-bottom15">
			<div class="col-xs-8">
				<h2 *ngIf="createEditRequestService.request.comments as comments" class="semi-bold">
					<ng-container *ngIf="comments?.length === 1; else plural">1 Comment</ng-container>
					<ng-template #plural>{{ comments?.length }} Comments</ng-template>
				</h2>
			</div>
			<div class="col-xs-4">
				<button *ngIf="viewStateService.mode !== 'new'" class="white-bg border-radius setWidth100" (mouseup)="onAddCommentClick()">Add comment</button>
			</div>
		</div>

		<!--BEGIN COMMENTS LOOP-->
		<div
			*ngFor="let comment of createEditRequestService.request.comments | customOrderBy : 'CreateDateTime' : true"
			class="border-radius border mgn-top20 box-shadow gray-bg pd15"
		>
			<div class="row">
				<div class="col-sm-6">
					<span class="semi-bold">{{ comment.userName }}</span>
				</div>
				<div class="col-sm-6 text-right">
					<span>
						<em>{{ dateTimeService.dateAndTimeUTCtoLocal(comment.CreateDateTime) }}</em>
					</span>
				</div>
			</div>
			<div class="row mgn-top15">
				<div class="col-sm-12">
					<span *ngIf="comment.Id">{{ comment.Message }}</span>
					<textarea
						#commentBox
						*ngIf="!comment.Id"
						class="setWidth100 white-bg"
						placeholder="Enter your comment here..."
						rows="4"
						[(ngModel)]="comment.Message"
					></textarea>
				</div>
			</div>
			<div *ngIf="!comment.Id" class="row mgn-top15">
				<div class="col-sm-2">
					<button class="border-radius white-bg setWidth100" (click)="onXClick()"><i class="fa fa-times" aria-hidden="true"></i></button>
				</div>
				<div class="col-sm-5 col-sm-offset-5">
					<button [disabled]="comment.Message?.length < 1" class="white-text limeGreen-bg" (click)="onPostCommentClick()">
						<i class="fa fa-check mgn-right8" aria-hidden="true"></i>
						Post comment
					</button>
				</div>
			</div>
		</div>
	</div>

	<cer-master-buttons></cer-master-buttons>
</div>
