export class CsrBlasts {
	Id: number;
	CsrId: number;
	MailingName: string;
	CreatedDate: string;
	Description: string;
	FromType: string;
	EmailSubject: string;
	HistorySubject: string;
	Html: string;
	IncludePrimaryContactOnly: boolean;
	TestEmail: string;
	SendDate: string;
	TotalRecipients: number;
	CustomFromEmail: string;
	CustomFromName: string;
	CustomCcEmail: string;

	mailingsEditSendBtn: string;
	classList: string;
	mailingsDeleteBtn: string;

	constructor() {
		this.MailingName = '';
		this.EmailSubject = '';
		this.HistorySubject = '';
		this.Html = '';
		this.TestEmail = '';
	}
}
