export class ContractRenewalReport {
    Id: number;
    CompanyName: string;
    ContactPerson: string;
    PhoneNumber: string;
    ServiceType: string;
    Salesperson: string;
    Csr: string;
    ContractTerm: string;
    RenewDate: string;
    TotalValue: number;
    LocationCount: number;
    Notes: string;
    SaleDate: string;
    ClientId: number;
    IsEarlyCancel: boolean;
    IsFulfilledContractCancellation: boolean;
    IsRenewed: boolean;
    IsExempt: boolean;
    SalespersonId: number;
    ContractTypeId: number;

    border: string;
    totalValue: string;
}