import { Injectable } from '@angular/core';

import { Column, Table, TableConfig } from '../../shared/components/table/view-models/';

@Injectable({
	providedIn: 'root'
})
export class TableConfigService {
	public tableColumns: Column[] = [
		new Column('detailsBtn', 'Details', 'button', false, 'gray-bg ht40'),
		new Column('editBtn', 'Edit', 'button', false, 'gray-bg ht40'),
		new Column('previewBtnIcon', 'fas fa-play', 'button', false, 'gray-bg ht40 noExit', true, null, 'previewBtnIcon'),
		new Column('Name', 'Playlist Name', 'string', true),
		new Column('deliverComplete', 'Delivery Date', 'date', true)
	];

	public tableColumnsAdvancedSchedule: Column[] = [
		new Column('detailsBtn', 'Details', 'button', false, 'gray-bg ht40'),
		new Column('editBtn', 'Edit', 'button', false, 'gray-bg ht40'),
		new Column('previewBtnIcon', 'Preview', 'button', false, 'gray-bg ht40 noExit', true),
		new Column('Name', 'Playlist Name', 'string', true),
		new Column('CreateDate', 'Created Date/Time', 'date', true, '', null, null, null, true),
		new Column('startDate', 'Start Date', 'date', true),
		new Column('deleteBtn', 'Delete', 'button', false, 'relative z102 ht40 white-bg red-text-light', true),
		new Column('select', 'Select', 'checkbox')
	];

	public radioColumns: Column[] = [
		new Column('stationName', 'Channel', 'string').setIsSortable(true),
		new Column('LogDate', 'Change Date', 'date').setIsSortable(true),
		new Column('changedByUser', 'Changed By', 'string').setIsSortable(true)
	];

	public tableSimpleSchedule: Table = new Table(this.tableColumns);
	public tableAdvancedSchedule: Table = new Table(this.tableColumnsAdvancedSchedule);
	public tableRadio: Table = new Table(this.radioColumns);

	public config: TableConfig = {
		headerColor: '',
		rowFontSize: 'ft-size16',
		headerFontSize: 'ft-size14',
		itemsPerPage: 25
	};
}
