<div class="noExit relative" (window:mouseup)="autoCloseDropdown($event)">
	<div
		[ngClass]="{ setOpacityLow: isDisabled, cursorClass: !isDisabled }"
		(click)="onDropdownClick()"
		class="{{ height }} {{ backgroundColor }} {{ borderColor() }} ln-height{{
			heightNum
		}} setBorder setWidth100 relative border-radius in-block pd-left8 pd-right8 space-between noExit pointer"
	>
		<span style="line-height: initial" class="ellipsis relative top4 noExit in-block {{ fontSize }} {{ textColor }}">
			<span *ngIf="selectedCount > 0">{{ selectedCount }} items selected</span>
			<span *ngIf="selectedCount < 1">Select items...</span>
		</span>
		<button
			[ngStyle]="{ 'height.px': heightNum / 2 + 10, 'width.px': heightNum / 2 + 10 }"
			class="noExit rightneg10 ft-size16 absolute centered {{ buttonColor() }} border-radius"
		>
			<i
				class="ft-size22 top15 fas fa-angle-down absolute center-unknown-height-width {{ textColor ? textColor : 'white-text' }} border-radius noExit"
				aria-hidden="true"
			></i>
		</button>
	</div>

	<div
		*ngIf="optionsVisible && options"
		class="noExit setMaxHeight mgn-bottom50 text-left shadow absolute z setWidth100 {{
			menuBackgroundColor ? menuBackgroundColor : backgroundColor
		}} setZ scrollable border gray-border-darker align-left border-radius pd-top1 pd-bottom1 top{{ height.slice(-2) }}"
	>
		<!--*******************
			BEGIN LIST
		********************-->
		<ul *ngFor="let option of selectableOptions; let i = index" class="noExit">
			<li (mouseup)="onOptionClick(option, option[key] ? option[key] : option)" #options id="{{ option[key] }}" class="option pointer relative noExit pd6">
				{{ option[key] ? option[key] : option }}
				<div class="absolute right15 top5 z noExit">
					<portal-checkbox
						class="noExit"
						[backgroundColor]="'white-bg'"
						[isChecked]="option.isSelected"
						[type]="'checkbox'"
						(checkboxClickNotify)="onOptionClick(option, option[key] ? option[key] : option)"
						(mouseup)="$event.stopPropagation()"
					></portal-checkbox>
				</div>
			</li>
		</ul>
	</div>
</div>
