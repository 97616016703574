import { Component, OnInit } from '@angular/core';

import { SessionExpireService } from '../services/';

@Component({
    selector: 'core-session-expire-modal',
    templateUrl: './session-expire-modal.component.html',
    styleUrls: ['./session-expire-modal.component.scss']
})
export class SessionExpireModalComponent implements OnInit {

    constructor(
        public sessionExpireService: SessionExpireService
    ) { }

    ngOnInit(): void {
    }

    public onStaySignedInClick(): void {
        this.sessionExpireService.resetTimer();
        this.sessionExpireService.expireModalVisible = false;
    }

}
