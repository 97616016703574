import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AppStateService, Events, MessageService } from '../../core/services';
import { DragDropLeftRightService } from '../../shared/components/drag-drop-left-right/drag-drop-left-right.service';
import { environment } from '../../../environments/environment';
import { PlayerStatus } from '../../shared/api-models/delivery';
import { PlayersView } from '../_models';
import { ProductTypes } from '../../shared/api-models/admin';


@Injectable()
export class LogoOverlayService {

    public logoPosition: number;

    constructor(
        private appStateService: AppStateService,
        private dragDropLeftRightService: DragDropLeftRightService,
        private httpClient: HttpClient,
        private messageService: MessageService
    ) { }

    public getPlayers$(): Observable<PlayersView[]> {
        const currentClient = this.appStateService.currentClient;
        const currentUser = this.appStateService.currentUser;

        return this.httpClient.get(environment.adminUrl + `CorePlayers/AvailableVideoPlayers?clientId=${currentClient.Id}&userId=${currentUser.UserId}`).pipe(
            switchMap((players: PlayersView[]) => {

                const productTypes$ = players.map((player) => {
                    return this.httpClient.get(`${environment.adminUrl}CoreProductTypes/${player.ProductTypeId}`);
                })

                const playerStatus$ = players.map((player) => {
                    return this.httpClient.get(`${environment.deliveryUrl}PlayerStatus/Player/${player.Id}`);
                })

                const observs$ = productTypes$.concat(playerStatus$);

                return forkJoin(observs$).pipe(
                    map((res) => {
                        const otherData: any[] = res.map((item) => {
                            if (item) {
                                return item;
                            }
                            return '';
                        })

                        const productTypes: ProductTypes[] = otherData.slice(0, players.length);
                        const playerStatus: PlayerStatus[] = otherData.slice(players.length, players.length * 2);

                        return this.playerView(players, productTypes, playerStatus).filter(player => player.IsBugLogoCapable);
                    })
                )
            })
        )
    }

    private playerView(players: PlayersView[], productTypes: ProductTypes[], playerStatus: PlayerStatus[]): PlayersView[] {
        return players.map((player, index) => {
            player.product = productTypes[index].ProductNoun;
            player.draggableClass = 'draggable';
            player.bgColor = 'red-bg';
            player.name = `<em>${productTypes[index].ProductNoun}</em>: ${player.PlayerName}`;
            player.icon = productTypes[index].DashIcon;
            player.logoPosition = playerStatus[index].LogoPosition;
            return player;
        })
    }

    public save(): void {
        this.messageService.publish(Events.savingPreloader, 1);

        const assignedPlayers$ = this.dragDropLeftRightService.assignedItems.map((assignedPlayer: PlayersView) => {
            return this.httpClient.patch(`${environment.deliveryUrl}PlayerStatus/${assignedPlayer.Id}`, { LogoPosition: this.logoPosition });
        })

        const unassignedPlayers$ = this.dragDropLeftRightService.availableItems.map((unassignedPlayer: PlayersView) => {
            return this.httpClient.patch(`${environment.deliveryUrl}PlayerStatus/${unassignedPlayer.Id}`, { LogoPosition: 0 });
        })

        const observs$ = assignedPlayers$.concat(unassignedPlayers$);

        forkJoin(observs$)
            .subscribe((res) => {
                this.messageService.publish(Events.savingPreloader, 0);
            })
    }
}
