import { Injectable } from '@angular/core';

import { ContentViewStateService } from '../../shared/services';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(private cvStateService: ContentViewStateService) { }


    public onModalExit(): void {
        this.cvStateService.viewState.modalVisible = false;
        this.cvStateService.viewState.activeModals = [];
    }

    public activeModal(): string {
        return this.cvStateService.viewState.activeModals[this.lastModalIndex()];
    }

    private lastModalIndex(): number {
        return this.cvStateService.viewState.activeModals.length - 1;
    }
}
