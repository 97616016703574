import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import * as uuid from 'uuid';

import { AppStateService, DateTimeService, Events, MessageService, PlaylistJsonService } from '../../../../core/services';
import { ContentItem, PbPlaylist } from '../../../../shared/view-models/content';
import { DeliveryQueue, DeliveryJson } from '../../../../shared/api-models/delivery';
import { environment } from '../../../../../environments/environment';
import { PlayersDragDrop } from '../../../../shared/view-models/admin';
import { PlayersView } from '../_models';
import { Playlists } from '../../../../shared/api-models/content';
import { PlaylistContentFileBridge } from '../../../../shared/api-models/content';
import { ServiceLocations } from 'src/app/shared/api-models/admin';

@Injectable({
    providedIn: 'root'
})
export class TestPlaylistService {


    constructor(
        private appStateService: AppStateService,
        private dateTimeService: DateTimeService,
        private httpClient: HttpClient,
        private messageService: MessageService,
        private playlistJsonService: PlaylistJsonService) { }

    public createAndSchedulePlaylist(player: PlayersView, location: ServiceLocations): Observable<void> {
        this.messageService.publish(Events.savingPreloader, 1);
        return this.httpClient.post(environment.contentUrl + 'Playlists', this.newPlaylist()).pipe(
            mergeMap( (playlist: PbPlaylist) => {
                playlist.contentItems = this.playlistContentItems();

                let playlistContentFileBridgeArr$ = playlist.contentItems.map( (contentItem) => {
                    return this.httpClient.post(`${environment.contentUrl}PlaylistContentFileBridge`, this.playlistContentFileBridge(playlist, contentItem));
                })
                let deliveryQueue$ = this.httpClient.post(`${environment.deliveryUrl}DeliveryQueue`, this.deliveryQueue(player as PlayersDragDrop, playlist, location));

                let observs$ = [].concat.apply([], [playlistContentFileBridgeArr$, deliveryQueue$]);
                return forkJoin(observs$).pipe(
                    map( () => {
                        this.messageService.publish(Events.savingPreloader, 0);
                    })
                )
            })
        )
    }

    //Can't reuse from Playlist Builder - ScheduleService b/c the schedule service is provided in playlist builder component
    public deliveryQueue(player: PlayersDragDrop, playlist: PbPlaylist, location: ServiceLocations): DeliveryQueue {
		let deliveryQueue: DeliveryQueue = new DeliveryQueue();

		deliveryQueue.DeliveryQueueMessageTypeId = 1;
		deliveryQueue.ProductId = player.ProductTypeId;
		deliveryQueue.ClientId = this.appStateService.currentClient.Id;
		deliveryQueue.UserId = this.appStateService.currentUser.UserId;
		deliveryQueue.PlayerId = player.Id;
		deliveryQueue.PlayerModelId = player.PlayerModelId;
		deliveryQueue.PlayerModelTypeId = player.PlayerModel.PlayerModelTypeId;
		deliveryQueue.IsExclusivePlaylist = true;
		deliveryQueue.PlaylistId = playlist.Id;
		deliveryQueue.PlaylistName = playlist.Name;
		deliveryQueue.PlaylistLastUpdate = new Date().toISOString();
		deliveryQueue.UnitIdentifier = player.UnitIdentifier;
		deliveryQueue.BatchIdentifier = uuid.v4();
		deliveryQueue.PlaylistJson = JSON.stringify(this.playlistJsonService.playlistJson(playlist, 1));
		deliveryQueue.DeliveryJson = this.deliveryJson(player, location);
		deliveryQueue.SavedOn = new Date().toISOString();
		deliveryQueue.DeliveryStatusId = 2;
		deliveryQueue.DeliverOn = this.dateTimeService.toUtc(playlist.startDate);
		deliveryQueue.StartDate = this.dateTimeService.toUtc(playlist.startDate);
		deliveryQueue.StopDate = playlist.stopDate;

		let guid: string = uuid.v4();
		deliveryQueue.SecretId = guid;
		deliveryQueue.MemoryCapacity = player.PlayerModel.MemoryCapacity;

		if (playlist.advancedScheduleActive) {
			deliveryQueue.PlaylistAdvancedSchedule = playlist.advancedSchedule;
		}
		return deliveryQueue;
	}

	private deliveryJson(player: PlayersDragDrop, location: ServiceLocations): string {
		let deliveryJson: DeliveryJson = new DeliveryJson();
		deliveryJson.PlayerId = player.Id;
		deliveryJson.ModelTypeName = player.PlayerModel.PlayerModelType.Name;
		deliveryJson.ModelTypeId = player.PlayerModel.PlayerModelTypeId;
		deliveryJson.ClientName = this.appStateService.currentClient.Name;
		deliveryJson.LocationName = location.Name;
		deliveryJson.VoipNotify = player.VoipNotify;
		deliveryJson.PlayerName = player.PlayerName;
		deliveryJson.IsLongDistance = player.IsLongDistance;
		deliveryJson.RemoteAreaCode = player.RemoteAreaCode;
		deliveryJson.RemotePhone = player.RemotePhone;
		deliveryJson.RemotePassword = player.RemotePassword;
		deliveryJson.HoldVolume = player.HoldVolume;
		deliveryJson.VoipFormatId = this.voipFormatId(player);
		deliveryJson.VoipFormat = player.VoipFormat;
		deliveryJson.VoipFileModeId = this.voipFileModeId(player);
		return JSON.stringify(deliveryJson);
	}

	private voipFormatId(player: PlayersDragDrop): number | null {
		if (player.VoipFormatId) {
			return player.VoipFormatId;
		}
		//fall back to client record if no player.VoipFormatId exists
		if (this.appStateService.currentClient.DefaultVoipFormat) {
			return this.appStateService.currentClient.DefaultVoipFormat;
		}
	}

	private voipFileModeId(player: PlayersDragDrop): number | null {
		if (player.VoipFileModeId) {
			return player.VoipFileModeId;
		}
		//fall back to client record if no player.voipFileModeId exists
		if (this.appStateService.currentClient.DefaultVoipFileMode) {
			return this.appStateService.currentClient.DefaultVoipFileMode
		}
	}

    private newPlaylist(): Playlists {
        let playlist: Playlists = new Playlists();
        playlist.ClientId = this.appStateService.currentClient.Id;
        playlist.ProductId = 1;
        playlist.CreateDate = new Date().toISOString();
        playlist.CreatedByUserId = this.appStateService.currentUser.UserId;
        playlist.Name = 'CST Test';
        playlist.ParagraphPause = 5000;
        return playlist;
    }

    private playlistContentFileBridge(newPlaylist: PbPlaylist, contentItem: ContentItem): PlaylistContentFileBridge {
        let playlistContentFileBridge: PlaylistContentFileBridge = new PlaylistContentFileBridge(
            newPlaylist.Id,
            contentItem.contentFileId,
            contentItem.isMusicTrack,
            contentItem.order,
            contentItem.startDate,
            contentItem.duration,
            this.checkIfContentFileStopDate(contentItem),
            this.contentItemContentId(contentItem)
        );
        if (contentItem.advancedSchedule) {
            playlistContentFileBridge.ContentAdvancedSchedule = contentItem.advancedSchedule;
        }
        playlistContentFileBridge.WeatherOverride = contentItem.weatherOverride;
        playlistContentFileBridge.SilenceMusic = contentItem.silenceMusic;
        contentItem.feedId ? playlistContentFileBridge.FeedId = contentItem.feedId : null;
        return playlistContentFileBridge;
    }

    private checkIfContentFileStopDate(contentItem: ContentItem): any {
        if (!contentItem.isStopDateSelected) {
            return null;
        } else {
            return contentItem.stopDate;
        }
    }

    private contentItemContentId(contentItem: ContentItem): number {
        if (contentItem.isChannel) {
            return contentItem.contentId;
        }
    }

    private playlistContentItems(): ContentItem[] {
        let contentItems: any[] = [
            {
                contentFileId: 1000317,
                contentFileStateId: 4,
                contentId: 372907,
                duration: 0,
                isContentFile: true,
                isMusicTrack: true,
                name: '* All In Good Fun',
                startDate: new Date().toISOString(),
                previewUrl: 'https://content.api.works24.com/File/Hold/Music/1000317/Preview',
                masterFileName: 'MusicBakery_031518_AllinGoodFun.wav'
            },
            {
                contentFileId: 1009619,
                contentFileStateId: 4,
                contentId: 114126,
                duration: 15000,
                isContentFile: true,
                isMusicTrack: false,
                contentName: '*1 - Here to Accommodate You',
                startDate: new Date().toISOString(),
                order: 1,
                isChannel: false,
                silenceMusic: false,
                previewUrl: 'https://content.api.works24.com/File/Hold/Message/1009619/Preview',
                masterFileName: '0bde1c1c9f3c4106b56f1719eee0ada0.wav'
            },
            {
                contentFileId: 1006397,
                contentFileStateId: 4,
                contentId: 17773,
                duration: 15000,
                isContentFile: true,
                isMusicTrack: false,
                contentName: '*2 - Thank You for Calling 2',
                startDate: new Date().toISOString(),
                order: 2,
                isChannel: false,
                silenceMusic: false,
                previewUrl: 'https://content.api.works24.com/File/Hold/Message/1006397/Preview',
                masterFileName: 'SHORTTAGS_P06_F.wav'
            },
            {
                contentFileId: 1009908,
                contentFileStateId: 4,
                contentId: 114131,
                duration: 15000,
                isContentFile: true,
                isMusicTrack: false,
                contentName: '*3 - Here to Serve You',
                startDate: new Date().toISOString(),
                order: 3,
                isChannel: false,
                silenceMusic: false,
                previewUrl: 'https://content.api.works24.com/File/Hold/Message/1009908/Preview',
                masterFileName: '1c3e694dc6864fb79e0d7de504f35475.wav'
            },
            {
                contentFileId: 1007065,
                contentFileStateId: 4,
                contentId: 114128,
                duration: 15000,
                isContentFile: true,
                isMusicTrack: false,
                contentName: '*4 - Thank You - Please Stay on the Line',
                startDate: new Date().toISOString(),
                order: 4,
                isChannel: false,
                silenceMusic: false,
                previewUrl: 'https://content.api.works24.com/File/Hold/Message/1007065/Preview',
                masterFileName: 'bcf400f7986444dab6742e1ebf914545.wav'
            },
            {
                contentFileId: 1010901,
                contentFileStateId: 4,
                contentId: 114130,
                duration: 15000,
                isContentFile: true,
                isMusicTrack: false,
                contentName: '*5 - We Appreciate Your Call',
                startDate: new Date().toISOString(),
                order: 5,
                isChannel: false,
                silenceMusic: false,
                previewUrl: 'https://content.api.works24.com/File/Hold/Message/1010901/Preview',
                masterFileName: 'f1834631fd21476095a72446c482b058.wav'
            },
            {
                contentFileId: 1007066,
                contentFileStateId: 4,
                contentId: 114129,
                duration: 15000,
                isContentFile: true,
                isMusicTrack: false,
                contentName: '*6 - Thank You for Continuing to Hold',
                startDate: new Date().toISOString(),
                order: 6,
                isChannel: false,
                silenceMusic: false,
                previewUrl: 'https://content.api.works24.com/File/Hold/Message/1007066/Preview',
                masterFileName: 'ae8011630d59413c9764cd8749ca1bc5.wav'
            }
        ]
        return contentItems;
    }
}
