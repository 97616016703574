export const Quotes: any[] = [
			{
				quote: `Don't let yesterday take up too much of today.`,
				author: 'Will Rogers'
			},
			{
				quote: `You learn more from failure than from success. Don't let it stop you. Failure builds character.`,
				author: 'Unknown'
			},
			{
				quote: `People who are crazy enough to think they can change the world, are the ones who do.`,
				author: 'Rob Siltanen'
			},
			{
				quote: `We may encounter many defeats but we must not be defeated.`,
				author: 'Maya Angelou'
			},
			{
				quote: `Creativity is intelligence having fun.`,
				author: 'Albert Einstein'
			},
			{
				quote: `What you lack in talent can be made up with desire, hustle, and giving 110% all the time.`,
				author: 'Don Zimmer'
			},
			{
				quote: `Things work out best for those who make the best of how things work out.`,
				author: 'John Wooden'
			},
			{
				quote: `Today's accomplishments were yesterday's impossibilities.`,
				author: 'Robert H. Schuller'
			},
			{
				quote: `The only way to do great work is to love what you do. If you haven't found it yet, keep looking. Don't settle.`,
				author: 'Steve Jobs'
			}
]