import { Injectable } from '@angular/core';

import { CreateEditRequestService } from './create-edit-request.service';
import { CreateEditViewStateService } from './create-edit-view-state.service';
import { ContentRequestStateName } from 'src/app/shared/api-models/admin';

@Injectable({
	providedIn: 'root'
})
export class HeightService {
	constructor(private createEditRequestService: CreateEditRequestService, private viewStateService: CreateEditViewStateService) {}

	public detailsAndClipsHeight(): number {
		if (this.createEditRequestService.request.RequestType === 'On-Hold Message') {
			switch (true) {
				case this.clientRouteNew():
					return 48.75; //rem

				case this.clientRouteEdit():
					return 43.125; //rem

				case this.internalRouteNew():
				case this.internalRouteEdit():
					return 57.5; //rem
			}
		} else {
			return 60.938;
		}
	}

	public commentsHeight(): number {
		if (this.createEditRequestService.request.RequestType === 'On-Hold Message') {
			switch (true) {
				case this.clientRouteNew():
					return 40.625; //rem

				case this.clientRouteEdit():
					return 43.125; //rem

				case this.internalRouteNew():
					return 49.375;

				case this.internalRouteEditAndPendingScriptApproval():
					return 43.375; //rem

				case this.internalRouteEditNotPendingScriptApproval():
					return 49.625;
			}
		} else {
			switch (true) {
				case this.internalRouteEdit():
					return 53.063;

				case this.internalRouteNew():
					return 52.813;
			}
		}
	}

	private clientRouteNew(): boolean {
		return this.viewStateService.route === 'content-requests' && this.viewStateService.mode === 'new';
	}

	private clientRouteEdit(): boolean {
		return this.viewStateService.route === 'content-requests' && this.viewStateService.mode === 'edit';
	}

	private internalRouteNew(): boolean {
		return this.viewStateService.route !== 'content-requests' && this.viewStateService.mode === 'new';
	}

	private internalRouteEdit(): boolean {
		return this.viewStateService.route !== 'content-requests' && this.viewStateService.mode === 'edit';
	}

	private internalRouteEditNotPendingScriptApproval(): boolean {
		return (
			this.viewStateService.route !== 'content-requests' &&
			this.viewStateService.mode === 'edit' &&
			this.createEditRequestService.request.StateName !== ContentRequestStateName.PENDING_SCRIPT_APPROVAL
		);
	}

	private internalRouteEditAndPendingScriptApproval(): boolean {
		return (
			this.viewStateService.route !== 'content-requests' &&
			this.viewStateService.mode === 'edit' &&
			this.createEditRequestService.request.StateName === ContentRequestStateName.PENDING_SCRIPT_APPROVAL
		);
	}
}
