import { inject } from '@angular/core';
import { Router, CanActivateFn, ActivatedRouteSnapshot } from '@angular/router';

import { AppStateService } from '../app-state.service';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';
import { ClientIds } from 'src/app/shared/api-models/admin/client-ids.enum';

export const userGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
	const appStateService = inject(AppStateService);
	const router = inject(Router);

	const currentUser = appStateService.currentUser;
	const currentClient = appStateService.currentClient;

	switch (true) {
		case currentUser.Role === UserRole.USER:
		case currentClient.Id === ClientIds.WORKS24_OFFICE_ACCOUNT && currentUser.Role === UserRole.SALESPERSON:
			router.navigate(['/access-denied']);
			return false;
		default:
			return true;
	}
};

//Employee content creators should access content manager but not feeds or user manager.
///Content Manager role should do everything except manage users
