import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { CanvasService } from '../../../canvas/_services/canvas.service';
import { CEventsService } from '../../../canvas/_services/c-events.service';
import { CanvasObjService } from '../../../canvas/_services/canvas-obj.service';
import { Create24Service } from 'src/app/user-tools/create-24/create-24.service';
import { LayersTimelineWrapperService } from './layers-timeline-wrapper.service';
import { LayersService, UndoRedoService, VideoControlsService } from '../../../_services';

@Injectable({
	providedIn: 'root'
})
export class LtwEventsService {
	private canvasClickSource = new Subject<any>();

	public onCanvasClick$ = this.canvasClickSource.asObservable();

	private isGroupSelected: boolean;
	private isObjectMoving: boolean;
	private isObjectScaling: boolean;

	constructor(
		private canvasService: CanvasService,
		private canvasObjService: CanvasObjService,
		private cEventsService: CEventsService,
		private create24Service: Create24Service,
		private layersService: LayersService,
		private ltwService: LayersTimelineWrapperService,
		private undoRedoService: UndoRedoService,
		private videoControlsService: VideoControlsService
	) {}

	public onPlayheadDrag(): void {
		this.ltwService.canvas.on('object:moving', (e) => {
			//If clicking on playhead
			if (e.target.layerId === -1) {
				let xValue = e.pointer.x;
				this.updatePlayheadCoords(xValue - 8);
				this.updateVideoTime(xValue);
				this.canvasObjService.resetObjValues();
			}
		});
	}

	public onObjectDrag(): void {
		this.ltwService.canvas.on('object:moving', (e) => {
			//If multiple layers selected
			//-1 is playhead
			if (e.target._objects && e.target.layerId !== -1) {
				e.target.lockMovementY = true;
				this.restrictToCanvasBoundary(e.target);
				e.target._objects.forEach((obj) => {
					this.setLayerStartEnd(obj, e.target);
					this.canvasObjService.showHideObjs();
					this.isObjectScaling = true;
				});
				return;
			}
			let obj = e.target;
			this.restrictToCanvasBoundary(obj);
			//If layer is not playhead
			if (obj.layerId !== -1) {
				this.setLayerStartEnd(obj);
				this.canvasObjService.showHideObjs();
				this.isObjectMoving = true;
			}
		});
	}

	//Resizing layer
	public onObjectScale(): void {
		this.ltwService.canvas.on('object:scaling', (e) => {
			let obj = e.target;
			//If layer is not playhead
			if (obj.layerId !== -1) {
				this.setLayerStartEnd(obj);
				this.canvasObjService.showHideObjs();
				this.isObjectScaling = true;
			}
		});
	}

	public onCanvasClick(): void {
		this.ltwService.canvas.on('mouse:up', (e) => {
			//Prevent moving playhead if clicking on timeline object or if video is playing
			if (this.create24Service.contentType() === 'video' && !e.target && !this.videoControlsService.isPlaying) {
				if (this.isGroupSelected) {
					//Deselects grouped objects. Obj positions aren't accurate if group remains selected on playback.
					this.canvasService.canvas.discardActiveObject().renderAll();
					this.isGroupSelected = false;
				}
				if (this.cEventsService.isGroupSelected) {
					this.canvasService.canvas.discardActiveObject().renderAll();
					//Tells layer detail panel to revert to single layer selected view
					this.cEventsService.isGroupSelected = false;
				}
				//Get click position x value
				let xValue: number = e.absolutePointer.x - 4; //4 is half the width of the playhead triangle
				this.updateVideoTime(xValue);
				this.updatePlayheadCoords(xValue);

				//Selects the background layer if objs were grouped on the canvas
				if (!this.layersService.activeLayer) {
					this.layersService.activeLayer = this.layersService.layers.find((layer) => layer.id === 0);
				}
				this.canvasClickSource.next(); //Re-draw image for color
			}
			if (this.create24Service.contentType() === 'video' && !this.videoControlsService.isPlaying) {
				this.preserveActiveLayer(e);
				//Reset positions/opacity back to original, transitions change object positions
				this.canvasObjService.resetObjValues();
				this.layersService.layers.forEach((layer) => {
					switch (true) {
						//If clicking within transition out, calculate remaining duration length
						case this.canvasObjService.midTransitionOut(layer):
							layer.transOutDuration = layer.totalTransDuration - (this.ltwService.playClockMs() - this.ltwService.pxToMs(layer.transitionOutStart));
							//Reset transInDuration, otherwise transIn animation will fire
							layer.transInDuration = layer.totalTransDuration;
							break;

						//If clicking within transition in, calculate remaining duration length
						case this.canvasObjService.midTransitionIn(layer):
							layer.transInDuration = layer.totalTransDuration - (this.ltwService.playClockMs() - this.ltwService.pxToMs(layer.start));
							//Reset transOutDuration, otherwise transOut animation will fire
							layer.transOutDuration = layer.totalTransDuration;
							break;

						//If clicking outside the transition, set it back to total duration length (defined in Layer model)
						default:
							layer.transOutDuration = layer.totalTransDuration;
							layer.transInDuration = layer.totalTransDuration;
							break;
					}
				});
			}
		});
	}

	public onSelectionCreated(): void {
		this.ltwService.canvas.on('selection:created', (e) => {
			let group = this.ltwService.canvas.getActiveObject();
			let playhead = this.ltwService.canvas.getObjects().find((obj) => obj.layerId === -1);
			if (e.selected?.length > 1) {
				this.isGroupSelected = true;
				group.removeWithUpdate(playhead);
				this.ltwService.canvas.renderAll();
			}
		});
	}

	private setLayerStartEnd(obj, group?: any): void {
		let layer = this.layersService.layers.find((l) => l.id === obj.layerId);
		layer.start = group ? group.left + obj.left + group.width / 2 : obj.left;
		layer.end = this.layerEnd(obj, group);
		layer.timelineObj.scaleX = obj.scaleX;
		layer.transitionOutStart = this.ltwService.transitionOutStart(layer);
		layer.transitionInEnd = this.ltwService.transitionInEnd(layer);
	}

	//https://stackoverflow.com/questions/29829475/how-to-get-the-canvas-relative-position-of-an-object-that-is-in-a-group
	private layerEnd(obj, group?): number {
		let distanceFromGroupLeft = group?.getScaledWidth() / 2 + obj.left;
		let objStartEqualsGroupStart: boolean = Math.abs(obj.left) === group?.getScaledWidth() / 2;
		let objEndEqualsGroupEnd: boolean = distanceFromGroupLeft + obj.getScaledWidth() === group?.getScaledWidth();
		if (group) {
			//In a group, obj.left is relative to group center, not the canvas.
			switch (true) {
				case objStartEqualsGroupStart:
					return group.left + group.getScaledWidth() - (group.getScaledWidth() - obj.getScaledWidth());
				case objEndEqualsGroupEnd:
					return group.left + group.getScaledWidth();
				//Obj is neither at front nor end of group
				default:
					return group.left + obj.getScaledWidth() + (group.getScaledWidth() / 2 + obj.left);
			}
		} else {
			return obj.left + obj.getScaledWidth();
		}
	}

	//https://stackoverflow.com/questions/23548608/fabric-js-group-members-properties-dont-update
	public updatePlayheadCoords(xValue: number): void {
		this.ltwService.playhead?.set('left', xValue);
		this.ltwService.playhead?.set('y1', 0);
		this.ltwService.playhead?.set('y2', this.ltwService.canvas.height);
		this.ltwService.playheadPos = this.ltwService.pixPerMs() * this.videoControlsService.playClock;
		this.ltwService.playhead?.setCoords();
		this.ltwService.canvas.renderAll();
	}

	private updateVideoTime(xValue: number): void {
		//xValue + 10 offsets the diff between the playhead triangle and playhead line,
		//so canv obj will appear right when line meets with layer start
		this.videoControlsService.playClock = xValue / this.ltwService.pixPerMs();
		//Update current time of the video
		this.videoControlsService.videoPlayer.nativeElement.currentTime = this.videoControlsService.playClock;
	}

	public onObjectChangeEnd(): void {
		this.ltwService.canvas.on('mouse:up', (event) => {
			switch (true) {
				case this.isObjectMoving:
					this.isObjectMoving = false;
					this.undoRedoService.recordState('Move timeline layer');
					break;

				case this.isObjectScaling:
					this.isObjectScaling = false;
					this.undoRedoService.recordState('Change layer start/end');
					break;
			}
		});
	}

	private restrictToCanvasBoundary(obj): void {
		let isPlayhead = obj.layerId === -1;
		// if object is too big ignore
		if (obj.currentWidth > obj.canvas.width) {
			return;
		}
		obj.setCoords();
		// left corner
		if (obj.getBoundingRect().left < 5 && !isPlayhead) {
			obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left) + 5;
		}

		if (obj.getBoundingRect().left < 0 && isPlayhead) {
			obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
			this.deselectPlayhead(); //prevents playhead from being draggable off canvas
		}
		// right corner
		if (obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width && !isPlayhead) {
			obj.left = Math.min(obj.left, obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left) - 5;
		}

		if (obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width && isPlayhead) {
			obj.left = Math.min(obj.left, obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left);
			this.deselectPlayhead();
		}
		this.ltwService.canvas.renderAll();
	}

	private deselectPlayhead(): void {
		let group = this.ltwService.canvas.getActiveObject();
		let playhead = this.ltwService.canvas.getObjects().find((obj) => obj.layerId === -1);
		group.removeWithUpdate(playhead);
		this.restrictClockToBoundary();
	}

	private restrictClockToBoundary(): void {
		if (this.ltwService.playClockMs() >= this.ltwService.contentDuration) {
			this.videoControlsService.playClock = this.ltwService.contentDuration / 1000;
		}
		if (this.ltwService.playClockMs() <= 0) {
			this.videoControlsService.playClock = 0;
		}
	}

	private preserveActiveLayer(e): void {
		let obj = e.target;
		let canvasActiveObj = this.canvasService.canvas.getActiveObject();
		//If clicking playhead, retains active layer instead of it being undefined
		if (obj?.layerId === -1) {
			if (canvasActiveObj) {
				this.layersService.activeLayer = this.layersService.layers.find((layer) => layer.id === this.canvasService.canvas.getActiveObject().layerId);
			} else {
				this.layersService.activeLayer = this.layersService.layers[0];
			}
		}
	}
}
