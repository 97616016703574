import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { CreateEditPlayerService } from './create-edit-player.service';
import {
	Installers,
	PlayerHardwareTypes,
	PlayerInstallStates,
	PlayerModels,
	PlayerOutputTypes,
	PlayerRepairStates,
	ProductTypes,
	ServiceLocations,
	VoipFormats
} from '../../../shared/api-models/admin/';
import { PlayerOptionsService } from './player-options.service';
import { UtilitiesService } from '../../../core/services';

@Injectable()
export class ViewService {
	public formChanged: boolean = false;
	public installStateDisabled: boolean;
	public installStateIdOriginal: number; //Holds InstallStateId value on page load
	public lastUpdateDateTimeUtcOriginal: string;
	public tempInstallStateId: number;
	public updatedRepairStateName: string;
	public ipConfigDisabled: boolean = false;
	public hostnameDisabled: boolean;

	constructor(
		private createEditPlayerService: CreateEditPlayerService,
		private playerOptionsService: PlayerOptionsService,
		private utilService: UtilitiesService
	) {}

	public onCancelClick(location: Location): void {
		if (this.formChanged) {
			let confirmOk: boolean = confirm(`You have unsaved changes! Leave without saving?`);
			if (confirmOk) {
				location.back();
			}
		} else {
			location.back();
		}
	}

	onDateChange(date: string): void {
		this.createEditPlayerService.player.InstallDate = date;
		this.onFormChange();
	}

	public onCheckboxClick(prop: string): void {
		if (prop === 'ForceBomgarReinstall') {
			this.createEditPlayerService.player.playerStatus[prop] = !this.createEditPlayerService.player.playerStatus[prop];
		} else {
			this.createEditPlayerService.player[prop] = !this.createEditPlayerService.player[prop];
		}

		this.onFormChange();
	}

	public onInstallStateSelect(installState: PlayerInstallStates): void {
		//If changing from Awaiting Activation to Installed, set Repair State to Ok
		if (this.createEditPlayerService.player.InstallStateId === 14 && installState.Id === 1) {
			this.createEditPlayerService.player.RepairStateId = 4;
			this.createEditPlayerService.player.RepairState.Name = 'Ok';
		}
		this.tempInstallStateId = installState.Id;
		if (this.tempInstallStateId !== 1) {
			this.createEditPlayerService.player.ActivationCode = null;
			this.createEditPlayerService.player.playerStatus.PlaybackJson = null;
		}
		this.onFormChange();
	}

	public onRepairStateSelect(repairState: PlayerRepairStates): void {
		this.createEditPlayerService.player.RepairStateId = repairState.Id;
		this.updatedRepairStateName = repairState.Name;
		this.onFormChange();
	}

	public onPlayerHardwareTypeSelect(hardwareType: PlayerHardwareTypes): void {
		this.createEditPlayerService.player.PlayerHardwareTypeId = hardwareType.Id;
		this.onFormChange();
	}

	public onProductTypeSelect(productType: ProductTypes): void {
		this.createEditPlayerService.player.ProductTypeId = productType.Id;
		this.createEditPlayerService.player.playerStatus.ProductId = productType.Id;
		this.onFormChange();
	}

	public onTargetVersionSelect(e: any): void {
		this.createEditPlayerService.player.playerStatus.TargetVersion = JSON.stringify(e.Version);
		this.onFormChange();
	}

	public onLocationSelect(location: ServiceLocations): void {
		this.createEditPlayerService.player.LocationId = location.Id;
		this.createEditPlayerService.player.playerStatus.LocationId = location.Id;
		this.createEditPlayerService.player.playerStatus.LocationName = location.Name;
		this.onFormChange();
	}

	public onInstallerSelect(installer: Installers): void {
		this.createEditPlayerService.player.InstallerId = installer.Id;
		this.onFormChange();
	}

	public onPlayerOutputTypeSelect(outputType: PlayerOutputTypes): void {
		this.createEditPlayerService.player.OutputTypeId = outputType.Id;
		this.onFormChange();
	}

	public onPlayerModelSelect(playerModel: PlayerModels): void {
		this.onFormChange();
		this.createEditPlayerService.player.PlayerModelId = playerModel.Id;
		this.createEditPlayerService.player.PlayerModel.PlayerModelTypeId = playerModel.PlayerModelTypeId;
		this.createEditPlayerService.player.playerStatus.PlayerModelTypeId = playerModel.PlayerModelTypeId;

		if (this.createEditPlayerService.playerIsTypeVOIP()) {
			this.playerOptionsService.getVoipFormats().subscribe((voipFormats: VoipFormats[]) => {
				this.playerOptionsService.voipFormats = this.utilService.sortItems(voipFormats, 'name');

				//Initialize VOIP format
				this.createEditPlayerService.player.VoipFormat = new VoipFormats();
				this.createEditPlayerService.player.VoipFormat.FriendlyName = 'WAVE (CD Quality - 16bit 44kHz)';
				this.createEditPlayerService.player.VoipFormatId = 7;
			});
		} else if (this.createEditPlayerService.player.PlayerModelId === 1000006 || this.createEditPlayerService.player.PlayerModelId === 1000009) {
			this.createEditPlayerService.player.VoipFormatId = 4;
		} else {
			this.createEditPlayerService.player.VoipFormatId = null;
		}

		//All Halo players
		if (this.playerIsHalo()) {
			this.createEditPlayerService.getPlayerModel$(playerModel.Id).subscribe((playerModel: PlayerModels) => {
				this.createEditPlayerService.player.playerStatus.TargetVersion = playerModel.CurrentSoftwareVersion;
				this.createEditPlayerService.player.InstallState.Name = 'Awaiting Activation';
				this.createEditPlayerService.player.InstallStateId = 14;
			});
		} else {
			this.createEditPlayerService.player.playerStatus.TargetVersion = null;
			this.createEditPlayerService.player.InstallState.Name = 'Not-Installed';
			this.createEditPlayerService.player.InstallStateId = 2;
		}
	}

	public onUnlockIpConfigClick(): void {
		const confirmOk: boolean = confirm('Unlock the IP Config fields?');
		if (confirmOk) {
			this.ipConfigDisabled = false;
		}
	}

	public onUnlockHostnameClick(): void {
		const confirmOk: boolean = confirm('Unlock the Hostname field?');
		if (confirmOk) {
			this.hostnameDisabled = false;
		}
	}

	private playerIsHalo(): boolean {
		return [1000000, 1000001, 1000005, 1000006, 1000007, 1000009, 1000011].some((modelId) => modelId === this.createEditPlayerService.player.PlayerModelId);
	}

	public playerIsInstalled(): boolean {
		return this.createEditPlayerService.player.InstallState?.Name === 'Installed';
	}

	public onVoipFormatSelect(voipFormat: VoipFormats): void {
		this.createEditPlayerService.player.VoipFormatId = voipFormat.Id;
	}

	public onFormChange(): void {
		this.formChanged = true;
	}
}
