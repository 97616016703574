import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppStateService, Events, MessageService, UtilitiesService } from '../../core/services';
import { ContentLibraryType } from '../../shared/api-models/content';
import { DragDropLeftRightService } from '../../shared/components/drag-drop-left-right/drag-drop-left-right.service';
import { LibrariesDragDrop } from '../../shared/view-models/content';
import { LibrariesService } from './libraries.service';
import { ProductTypes } from '../../shared/api-models/admin';

@Component({
	selector: 'app-libraries',
	templateUrl: './libraries.component.html',
	styleUrls: ['./libraries.component.scss'],
	providers: [DragDropLeftRightService]
})
export class LibrariesComponent implements OnInit, OnDestroy {
	public allCreateLibraryFieldsVisible: boolean;
	public libraryTypes: ContentLibraryType[];
	public libraryTypesVisible: boolean;
	public newLibrary: LibrariesDragDrop = new LibrariesDragDrop();
	public productTypes: ProductTypes[];
	public saveInProgress: boolean;
	public selectedLibraryType: any;

	private subs: Subscription[] = [];

	constructor(
		private appStateService: AppStateService,
		private dragDropLeftRightService: DragDropLeftRightService,
		private librariesService: LibrariesService,
		private messageService: MessageService,
		public utilService: UtilitiesService
	) {
		this.onDropNotifySubscribe();
		this.onRemoveClickSubscribe();
	}

	ngOnInit() {
		this.allCreateLibraryFieldsVisible = true;
		this.libraryTypesVisible = true;
		this.selectedLibraryType = 'Select a library type...';
		this.getLibrariesByProduct();
		this.librariesService.getProductTypes().subscribe((productTypes: ProductTypes[]) => {
			this.productTypes = productTypes;
		});
	}

	/******************************
      ASSIGN/UNASSIGN LIBRARY
    ******************************/

	onDropNotifySubscribe(): void {
		this.subs.push(
			this.dragDropLeftRightService.onDrop$.subscribe((payload) => {
				const library: LibrariesDragDrop = payload[2];

				this.messageService.publish(Events.savingPreloader, 1);

				//save library as assigned
				this.librariesService.postClientBridgeAndUserAccess(library).subscribe(() => {
					this.messageService.publish(Events.savingPreloader, 0);
					this.getLibrariesByProduct();
				});
			})
		);
	}

	onRemoveClickSubscribe(): void {
		this.subs.push(
			this.dragDropLeftRightService.onRemoveItem$.subscribe((library: LibrariesDragDrop) => {
				this.librariesService.removeAssignedLibrary(library).subscribe(() => {
					this.getLibrariesByProduct();
				});
			})
		);
	}

	/******************************
      CREATE NEW LIBRARY
    ******************************/

	public onCreateLibraryClick(): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.saveInProgress = true;
		this.librariesService.postLibrary(this.newLibrary).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
			this.newLibrary = new LibrariesDragDrop();
			this.saveInProgress = false;
			this.allCreateLibraryFieldsVisible = false;
			setTimeout(() => (this.allCreateLibraryFieldsVisible = true), 1);
			this.getLibrariesByProduct();
		});
	}

	public onCreateDefaultLibrariesClick(): void {
		this.librariesService.createDefaultLibraries();
	}

	public allFieldsComplete(): any {
		return this.newLibrary.ProductTypeId && this.newLibrary.ContentLibraryTypeId && this.newLibrary.LibraryName;
	}

	onDropdownSelect(item: ProductTypes, prop: string): void {
		this.newLibrary[prop] = item.Id;

		switch (prop) {
			case 'ProductTypeId':
				this.newLibrary.LibraryName = `${this.appStateService.currentClient.Name} Custom ${this.librariesService.productNoun(item.ProductNoun)} Library`;
				this.newLibrary.ContentLibraryTypeId = null;
				this.libraryTypesVisible = false;

				setTimeout(() => {
					this.selectedLibraryType = 'Select a library type...';
					this.libraryTypesVisible = true;
				}, 1);

				this.librariesService.getContentLibraryTypes(this.newLibrary).subscribe((libraryTypes: ContentLibraryType[]) => {
					this.libraryTypes = libraryTypes;
					//Automatically assign video-playable library since it's the only one
					if (this.newLibrary.ProductTypeId !== 1) {
						this.selectedLibraryType = 'Video-Playable';
						this.newLibrary.ContentLibraryTypeId = 3;
					}
				});
				switch (item.ProductName) {
					case 'Hold-Music':
						this.newLibrary.LibraryName = `${this.appStateService.currentClient.Name} Custom ${this.librariesService.productNoun('Music')} Library`;
						break;

					case 'Hold-Message':
						this.newLibrary.LibraryName = `${this.appStateService.currentClient.Name} Custom ${this.librariesService.productNoun('Message')} Library`;
						break;
				}
				break;

			case 'ContentLibraryTypeId':
				switch (item.Id) {
					case 5:
						this.newLibrary.LibraryName = `${this.appStateService.currentClient.Name} Background Images`;
						break;
					case 6:
						this.newLibrary.LibraryName = `${this.appStateService.currentClient.Name} Background Videos`;
						break;
					case 7:
						this.newLibrary.LibraryName = `${this.appStateService.currentClient.Name} Placeable Image Library`;
						break;
					case 12:
						this.newLibrary.LibraryName = `${this.appStateService.currentClient.Name} Custom Sidebar Library`;
						break;
					case 13:
						this.newLibrary.LibraryName = `${this.appStateService.currentClient.Name} Custom Sidebar Background Library`;
						break;
				}
				break;
		}
	}

	private getLibrariesByProduct(): void {
		this.librariesService.getLibrariesByProduct().subscribe((libraries: LibrariesDragDrop[]) => {
			this.dragDropLeftRightService.availableItemsView = this.utilService
				.sortByProductTypeIdAndName(libraries)
				.filter((library) => !library.clientContentLibraryBridgeId);
			this.dragDropLeftRightService.availableItems = this.utilService
				.sortByProductTypeIdAndName(libraries)
				.filter((library) => !library.clientContentLibraryBridgeId);

			this.dragDropLeftRightService.assignedItemsView = this.utilService
				.sortByProductTypeIdAndName(libraries)
				.filter((library) => library.clientContentLibraryBridgeId);
			this.dragDropLeftRightService.assignedItems = this.utilService
				.sortByProductTypeIdAndName(libraries)
				.filter((library) => library.clientContentLibraryBridgeId);
		});
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
