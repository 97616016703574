import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { map, switchMap } from 'rxjs/operators';
import uuid from 'uuid';

import { AppStateService, DateTimeService } from '../../../core/services';
import { environment } from '../../../../environments/environment';
import {
	Installers,
	PlayerInstallStates,
	Players,
	PlayerModels,
	PlayerOutputTypes,
	PlayerRepairStates,
	ProductTypes,
	ServiceLocations,
	VoipFormats
} from '../../../shared/api-models/admin';
import { PlayerStatus } from '../../../shared/api-models/delivery';
import { HaloSettingsJson, PlayersView } from '../_models';
import { ProductRoute } from 'src/app/shared/api-models/admin/product-route.enum';

@Injectable()
export class CreateEditPlayerService {
	public myDatePickerOptions: IAngularMyDpOptions = {
		// other options...
		dateFormat: 'mm/dd/yyyy',
		openSelectorTopOfInput: false,
		inline: false,
		firstDayOfWeek: 'su'
	};
	public player: PlayersView;
	public settingsJson: HaloSettingsJson = new HaloSettingsJson();
	public viewState: 'edit' | 'new';
	public webPlayerHtml: { head: string; body: string } = { head: '', body: '' };

	constructor(private appStateService: AppStateService, private dateTimeService: DateTimeService, private httpClient: HttpClient) {}

	public getLocation(locationId: number): Observable<ServiceLocations> {
		return this.httpClient.get(`${environment.adminUrl}CoreServiceLocations/${locationId}`).pipe(map((res) => res[0]));
	}

	public getPlayerModel$(playerModelId: number): Observable<PlayerModels> {
		return this.httpClient.get<PlayerModels>(`${environment.adminUrl}CorePlayerModels/${playerModelId}`);
	}

	public getPlayer(playerId: number): Observable<PlayersView> {
		return this.httpClient.get(`${environment.adminUrl}CorePlayers/${playerId}`).pipe(
			switchMap(([player]: PlayersView[]) => {
				const playerStatus$: Observable<PlayerStatus> = this.httpClient.get<PlayerStatus>(`${environment.deliveryUrl}PlayerStatus/Player/${playerId}`);
				const installer$: Observable<Installers> = this.httpClient.get<Installers>(`${environment.adminUrl}CoreInstallers/${player.InstallerId}`);
				const playersByHostname$: Observable<Players[]> = this.httpClient.get<PlayersView[]>(
					`${environment.adminUrl}CorePlayers/ByHostname/${player.Hostname}`
				);

				let observArr$: any[];
				if (player.InstallerId && player.Hostname) {
					observArr$ = [playerStatus$, playersByHostname$, installer$];
				} else if (player.InstallerId) {
					observArr$ = [playerStatus$, installer$];
				} else {
					observArr$ = [playerStatus$];
				}

				return forkJoin(observArr$).pipe(
					switchMap(([playerStatus, playersByHostname, installer]: [PlayerStatus, PlayersView[] | undefined, Installers | undefined]) => {
						const playerStatusByHostname$ = playersByHostname?.map((player) => {
							return this.httpClient.get(`${environment.deliveryUrl}PlayerStatus/Player/${player.Id}`);
						});

						if (playersByHostname?.length) {
							return forkJoin(playerStatusByHostname$).pipe(
								map((playerStatusByHostName: PlayerStatus[]) => {
									return this.playerView(player, playerStatus, installer, playersByHostname, playerStatusByHostName);
								})
							);
						}
						return of(this.playerView(player, playerStatus, installer));
					})
				);
			})
		);
	}

	//https://stackoverflow.com/questions/12001953/javascript-and-regex-split-string-and-keep-the-separator
	public getWebPlayerHtml(playerId: number): void {
		this.httpClient.get(`${environment.contentUrl}WebPlayer/Html/${playerId}`, { responseType: 'text' }).subscribe((html: string) => {
			const splitHtml = html.split(/(?=Body:)/g);
			this.webPlayerHtml.head = splitHtml[0].replace('Head:', '');
			this.webPlayerHtml.body = splitHtml[1].replace('Body:', '');
		});
	}

	private playerView(
		player: PlayersView,
		playerStatus: PlayerStatus,
		installer: Installers,
		playersByHostname?: PlayersView[],
		playerStatusByHostName?: PlayerStatus[]
	): PlayersView {
		player.playerStatus = playerStatus;
		player.installer = installer;
		if (playersByHostname) {
			player.playersByHostname = playersByHostname;
			player.playersByHostname.forEach((player, index) => {
				player.playerStatus = playerStatusByHostName[index];
			});
		}
		return player;
	}

	public newPlayerInit(route: ProductRoute, location: ServiceLocations): void {
		this.player = new PlayersView();
		this.player.InstallState = new PlayerInstallStates();
		this.player.RepairState = new PlayerRepairStates();
		this.player.ProductType = new ProductTypes();
		this.player.OutputType = new PlayerOutputTypes();
		this.player.PlayerModel = new PlayerModels();

		this.player.RepairState.Name = 'Ok';
		this.player.RepairStateId = 4;
		this.player.Location = location;
		this.player.LocationId = location.Id;
		this.player.ClientId = this.appStateService.currentClient.Id;
		this.player.IsExemptFromReminders = false;
		this.player.VoipNotify = false;
		this.player.PlayerJson = '';
		this.player.IsLongDistance = false;
		this.player.WebPlayerGuid = this.guid();
		this.player.ProductType.Route = route;
		this.player.LastUpdateDateTimeUtc = this.dateTimeService.todayWithCurrentTimeUTC();

		switch (route) {
			case ProductRoute.VIDEO:
				this.player.InstallState.Name = 'Awaiting Activation';
				this.player.InstallStateId = 14;
				this.player.ProductType.ProductName = 'Lobby Video';
				this.player.ProductTypeId = 3;
				this.player.OutputType.Description = 'HDMI';
				this.player.OutputTypeId = 3;
				this.player.PlayerModel.Name = 'Halo Windows';
				this.player.PlayerModelId = 1000007;
				break;

			case ProductRoute.HOLD:
				this.player.ProductTypeId = 1;
				this.player.InstallState.Name = 'Not-Installed';
				this.player.InstallStateId = 2;
				this.player.VoipFormat = new VoipFormats();
				this.player.VoipFormat.FriendlyName = 'WAVE (CD Quality - 16bit 44kHz)';
				this.player.VoipFormatId = 7;
				this.player.PlayerModel.Name = 'VOIP';
				this.player.PlayerModelId = 4;
				this.player.HoldPause = 5000;
				this.player.HoldVolume = 80;
				break;

			case ProductRoute.RADIO:
				this.player.ProductTypeId = 2;
				this.player.InstallState.Name = 'Not-Installed';
				this.player.InstallStateId = 2;
				this.player.PlayerModel.Name = 'Barix Exstreamer';
				this.player.PlayerModelId = 55;
				break;
		}
	}

	public newPlayerStatusInit(route: ProductRoute): void {
		this.player.playerStatus = new PlayerStatus();

		this.player.playerStatus.ClientId = this.appStateService.currentClient.Id;
		this.player.playerStatus.ClientName = this.appStateService.currentClient.Name;
		this.player.playerStatus.LocationId = this.player.LocationId;
		this.player.playerStatus.LocationName = this.player.Location.Name;
		this.player.playerStatus.ProductId = this.player.ProductTypeId;
		this.player.playerStatus.PlayerName = this.player.PlayerName;
		this.player.playerStatus.ShowTicker = false;
		this.player.playerStatus.LoggingLevel = 5;

		switch (route) {
			case ProductRoute.VIDEO:
				this.player.playerStatus.PlayerModelTypeId = 5; //Halo Windows
				this.player.playerStatus.ReportedVersion = '999';
				break;
			case ProductRoute.HOLD:
				this.player.playerStatus.PlayerModelTypeId = 2; //Hold-VOIP
				break;
			case ProductRoute.RADIO:
				this.player.playerStatus.PlayerModelTypeId = 9;
				this.player.playerStatus.RequestedPlay24StationId = 1;
				break;
		}
	}

	public defaultSettingsJson(): HaloSettingsJson {
		return {
			UnitIdentifier: null,
			UseDhcp: true,
			IpAddress: null,
			NetMask: null,
			DefaultGateway: null,
			PrimaryDns: null,
			SecondaryDns: null,
			HttpProxyUrl: null,
			Volume: 80,
			AudioDeviceName: null,
			PollingIntervalSec: 600,
			DaysOfWeekSchedule: null,
			StartTimeUtcSchedule: null,
			EndTimeUtcSchedule: null,
			PlayerOn: true,
			UseSchedule: true,
			Password: '',
			SSID: null
		};
	}

	public defaultSettingsJsonRadio(): HaloSettingsJson {
		return {
			UnitIdentifier: null,
			UseDhcp: true,
			IpAddress: null,
			NetMask: null,
			DefaultGateway: null,
			PrimaryDns: null,
			SecondaryDns: null,
			HttpProxyUrl: null,
			Volume: 80,
			AudioDeviceName: null,
			PollingIntervalSec: 600,
			DaysOfWeekSchedule: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
			StartTimeUtcSchedule: this.dateTimeService.convertTimeViewToUtc('8:00 AM'),
			EndTimeUtcSchedule: this.dateTimeService.convertTimeViewToUtc('5:00 PM'),
			PlayerOn: true,
			UseSchedule: true,
			Password: '',
			SSID: null
		};
	}

	public playerIsTypeVOIP(): boolean {
		return this.player.PlayerModel.PlayerModelTypeId === 2;
	}

	public showIpConfigOptions(): boolean {
		//NET 7000, 7500, 7100, Barix Exstreamer, Play24, Halo Linux, Halo Music, Halo Network Hold
		let playerModelIds: number[] = [6, 9, 18, 55, 56, 1000001, 1000005, 1000006, 1000007, 1000009, 1000011];
		return playerModelIds.some((id) => id === this.player.PlayerModelId);
	}

	private guid(): string {
		let guid: string = uuid.v4();
		return guid;
	}
}
