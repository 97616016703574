<div class="col-sm-6 col-lg-4 ft-size-md">
	<div [ngStyle]="{ 'height.rem': heightService.detailsAndClipsHeight() }" class="shadow border-radius white-bg pd15 mgn-bottom60">
		<div class="row mgn-bottom30">
			<div class="{{ viewStateService.mode === 'new' ? 'col-sm-6' : 'col-sm-12' }}">
				<h2 class="semi-bold">{{ viewStateService.mode === 'edit' ? 'Edit Request Details' : 'New Request' }}</h2>
			</div>

			<div *ngIf="viewStateService.mode === 'new'" class="col-sm-6 relative">
				<span class="mgn-right26">Bypass script writer?</span>
				<label class="toggleSwitch nolabel mgn-top6 ft-size13 absolute top4">
					<input type="checkbox" (change)="detailsViewService.onBypassScriptWriterClick()" [checked]="createEditRequestService.request.bypassScriptWriter" />
					<span>
						<span>No</span>
						<span>Yes</span>
					</span>
					<a></a>
				</label>
			</div>
		</div>

		<div class="row mgn-bottom30 relative">
			<!--********************************
				CLIENT NAME
			*********************************-->
			<div class="col-sm-6">
				<h4 class="semi-bold mgn-bottom6 in-block">Client Name</h4>
				<i *ngIf="viewStateService.mode === 'new'" class="fa fa-asterisk ft-size7 red-text-light relative topneg10 left2" aria-hidden="true"></i>
				<br />
				<div *ngIf="viewStateService.mode === 'new'">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="detailsService.clientList"
						[key]="'Name'"
						[scrollbarHidden]="true"
						[searchEnabled]="true"
						[selectedOption]="createEditRequestService.request.clientName"
						(onOptionSelect)="detailsViewService.onDropdownSelect($event, 'ClientId', 'Id')"
						(onSearchKeyupNotify)="detailsViewService.onClientSearchKeyup($event)"
					></portal-dropdown-menu>
				</div>
				<span *ngIf="viewStateService.mode === 'edit'">{{ createEditRequestService.request.clientName }}</span>
			</div>
			<div class="col-sm-6">
				<h4 class="semi-bold mgn-bottom6">ID</h4>
				<span>{{ createEditRequestService.request.Id }}</span>
			</div>
		</div>

		<div class="row mgn-bottom30">
			<!--********************************
				TITLE
			*********************************-->
			<div class="col-xs-6 relative">
				<h4 class="semi-bold mgn-bottom6 in-block">Title</h4>
				<i class="fa fa-asterisk ft-size7 red-text-light relative topneg10 left2" aria-hidden="true"></i>
				<input type="text" #title (keyup)="detailsViewService.onKeyup('Title')" [(ngModel)]="createEditRequestService.request.Title" />
			</div>

			<!--********************************
				REQUEST TYPE
			*********************************-->
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">Request Type</h4>
				<div *ngIf="viewStateService.mode === 'edit' && viewStateService.isStatusBeforeInProduction">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="detailsService.requestTypes$ | async"
						[key]="'Name'"
						[scrollbarHidden]="true"
						[selectedOption]="createEditRequestService.request.RequestType"
						(onOptionSelect)="detailsViewService.onDropdownSelect($event, 'RequestType', 'Name')"
					></portal-dropdown-menu>
				</div>
				<span *ngIf="viewStateService.mode === 'new'">{{ createEditRequestService.request.RequestType }}</span>
				<span *ngIf="viewStateService.mode === 'edit' && !viewStateService.isStatusBeforeInProduction">{{ createEditRequestService.request.RequestType }}</span>
			</div>
		</div>

		<div class="row mgn-bottom30">
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">Status</h4>
				<span>{{ createEditRequestService.request.StateName }}</span>
			</div>

			<!--********************************
				VOICE TALENT
			*********************************-->
			<div class="col-xs-6 relative">
				<h4 class="semi-bold mgn-bottom6 in-block">Voice Talent</h4>
				<i *ngIf="viewStateService.mode === 'new'" class="fa fa-asterisk ft-size7 red-text-light relative topneg10 left2" aria-hidden="true"></i>
				<br />
				<div *ngIf="viewStateService.isStatusBeforeInProduction">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="[{ name: 'Alternating Male/Female' }, { name: 'Both Male/Female' }, { name: 'All Male' }, { name: 'All Female' }]"
						[key]="'name'"
						[scrollbarHidden]="true"
						[selectedOption]="createEditRequestService.request.voiceTalentView"
						(onOptionSelect)="detailsViewService.onDropdownSelect($event, 'VoiceTalent', 'name')"
					></portal-dropdown-menu>
				</div>
				<span *ngIf="!viewStateService.isStatusBeforeInProduction">{{ createEditRequestService.request.voiceTalentView }}</span>
			</div>
		</div>

		<!--********************************
			CREATED AND DUE DATES
		*********************************-->
		<div class="row mgn-bottom30">
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">Created Date</h4>
				<span>{{ createEditRequestService.request.CreateDateTime | date : 'M/d/yyyy' }}</span>
			</div>
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">Due Date</h4>
				<portal-date-time-selector
					*ngIf="appStateService.currentUser.Role === userRole.CSR"
					[backgroundColor]="'gray-bg-dark'"
					[height]="'ht40'"
					[dateModel]="createEditRequestService.request.DueDate"
					(dateChanged)="detailsViewService.onDateChanged($event, 'DueDate')"
				></portal-date-time-selector>
				<span *ngIf="appStateService.currentUser.Role !== userRole.CSR">{{ createEditRequestService.request.DueDate | date : 'M/d/yyyy' }}</span>
			</div>
		</div>
		<div class="row mgn-bottom30">
			<!--********************************
				NOTES
			*********************************-->
			<div class="col-sm-12">
				<h4 class="semi-bold mgn-bottom6">Notes</h4>
				<textarea
					*ngIf="viewStateService.isStatusBeforeInProduction"
					rows="4"
					(keyup)="detailsViewService.onKeyup('Description')"
					[(ngModel)]="createEditRequestService.request.Description"
				></textarea>
				<textarea *ngIf="!viewStateService.isStatusBeforeInProduction" rows="4" readonly [(ngModel)]="createEditRequestService.request.Description"></textarea>
			</div>
		</div>
		<div class="row mgn-bottom30">
			<!--********************************
				CSR/PRODUCER
			*********************************-->
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">CSR</h4>
				<span>{{ createEditRequestService.request.csrFriendlyName }}</span>
			</div>

			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">Producer</h4>
				<div>
					<portal-dropdown-menu
						*ngIf="securityService.userIsAudioContentCreatorOrVoiceTalent()"
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="detailsService.producers$ | async"
						[key]="'UserName'"
						[selectedOption]="createEditRequestService.request.producerUsername"
						(onOptionSelect)="detailsViewService.onDropdownSelect($event, 'ProducerId', 'Id')"
					></portal-dropdown-menu>
				</div>
				<span *ngIf="!securityService.userIsAudioContentCreatorOrVoiceTalent()">{{ createEditRequestService.request.producerUsername }}</span>
			</div>
		</div>

		<!--****************************************
			   REQUESTED BY & TARGET LIBRARY
			*************************************-->
		<div class="row mgn-bottom30">
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">Requested By</h4>
				<div *ngIf="viewStateService.mode === 'new'">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="detailsService.usersByClient$ | async"
						[key]="'UserName'"
						[selectedOption]="createEditRequestService.request.requestedByUsername ? createEditRequestService.request.requestedByUsername : null"
						(onOptionSelect)="detailsViewService.onDropdownSelect($event, 'RequestedBy', 'Id')"
					></portal-dropdown-menu>
				</div>
				<span *ngIf="viewStateService.mode === 'edit'">{{ createEditRequestService.request.requestedByUsername }}</span>
			</div>

			<div class="col-xs-6 relative">
				<h4 class="semi-bold mgn-bottom6 in-block">Target Library</h4>
				<i class="fa fa-asterisk ft-size7 red-text-light relative topneg10 left2" aria-hidden="true"></i>
				<br />
				<div
					*ngIf="viewStateService.isStatusBeforeComplete"
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					attr.data-tippy-content="{{ createEditRequestService.request.targetLibraryName }}"
					[tippyProps]="{ appendTo: 'parent' }"
				>
					<portal-dropdown-menu
						*ngIf="detailsService.libraries"
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="detailsService.libraries"
						[key]="'LibraryName'"
						[scrollbarHidden]="true"
						[selectedOption]="createEditRequestService.request.targetLibraryName"
						(onOptionSelect)="detailsViewService.onDropdownSelect($event, 'TargetLibraryId', 'Id')"
					></portal-dropdown-menu>
				</div>
				<span *ngIf="!viewStateService.isStatusBeforeComplete">{{ createEditRequestService.request.targetLibraryName }}</span>
			</div>
		</div>

		<!--**************************************
			REQUEST APPROVAL FROM & APPROVAL EMAIL
		***************************************-->
		<div *ngIf="viewStateService.isStatusBeforeInProduction" class="row">
			<div class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">Request Approval From</h4>
				<div>
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="detailsService.usersByClient$ | async"
						[key]="'UserName'"
						[selectedOption]="createEditRequestService.request.requestApprovalFromUsername"
						(onOptionSelect)="detailsViewService.onDropdownSelect($event, 'RequestApprovalFrom', 'Id')"
					></portal-dropdown-menu>
				</div>
			</div>
			<div *ngIf="viewStateService.mode === 'edit'" class="col-xs-6">
				<h4 class="semi-bold mgn-bottom6">Request Approval</h4>
				<button
					*ngIf="createEditRequestService.request.requestApprovalFromEmail"
					class="blue-bg white-text ft-size14"
					(mouseup)="detailsViewService.onRequestClientApprovalClick()"
				>
					{{ createEditRequestService.request.requestApprovalFromEmail }}
				</button>
			</div>
		</div>

		<div *ngIf="createEditRequestService.request.StateName === stateName.PENDING_CSR_APPROVAL" class="row mgn-top20 mgn-bottom20">
			<div class="col-xs-6 col-sm-12 col-md-6">
				<h4 class="semi-bold mgn-bottom6 in-block">Send Complete Email</h4>
				<span
					class="pointer"
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					data-tippy-content="Notify the client who requested this content that their messages are complete."
					[tippyProps]="{ appendTo: 'parent' }"
				>
					<i class="far fa-question-circle" aria-hidden="true"></i>
				</span>
				<button
					*ngIf="!createEditRequestService.request.requestedByIsEmployee"
					class="blue-bg white-text"
					(click)="detailsViewService.onSendCompleteEmailClick()"
				>
					{{ createEditRequestService.request.requestedByEmail }}
				</button>
				<div *ngIf="createEditRequestService.request.requestedByIsEmployee">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[options]="detailsService.usersByClient$ | async"
						[key]="'UserName'"
						[selectedOption]="'Select a user...'"
						(onOptionSelect)="detailsViewService.onSendCompleteEmailDropdownSelect($event)"
					></portal-dropdown-menu>
					<button
						[disabled]="!detailsViewService.sendCompleteEmailUserSelected"
						class="blue-bg white-text mgn-top8"
						(click)="detailsViewService.onSendCompleteEmailClick()"
					>
						{{ createEditRequestService.request.requestedByEmail }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
