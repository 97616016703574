import { Injectable } from '@angular/core';

import { Layer } from '../_models';
import { LayersService } from './layers.service';
import { LayersTimelineWrapperService } from '../timeline/layers-timeline-wrapper/_services/layers-timeline-wrapper.service';
import { StateService } from './state.service';

@Injectable({
    providedIn: 'root'
})
export class ExportUtilService {

    constructor(
        private layersService: LayersService,
        private ltwService: LayersTimelineWrapperService,
        private stateService: StateService
    ) { }

    public matchingLayer(layerId: number): Layer {
        //Find layer of matching element layer Id
        return this.layersService.layers.find(layer => layer.id === layerId);
    }

    public matchingObj(layerId: number): any {
        return JSON.parse(this.stateService.state().canvasObjs).objects.find(obj => obj.layerId === layerId);
    }

    public allMatchingIndices(str, toSearch): number[] {
        var indices = [];
        for (var pos = str.indexOf(toSearch); pos !== -1; pos = str.indexOf(toSearch, pos + 1)) {
            indices.push(pos);
        }
        return indices;
    }

    public layerStartOrEnd(startOrEnd: string, layerId: number): number {
        switch (startOrEnd) {
            case 'start': return this.matchingLayer(layerId)[startOrEnd] / this.pixPerSec();
            case 'end': return this.ltwService.transitionOutStart(this.matchingLayer(layerId)) / this.pixPerSec();
        }
    }

    public pixPerSec(): number {
        return this.ltwService.canvas.getWidth() / (this.ltwService.contentDuration);
    }

    public removeSelfClosingTags(xml): any {
        var split = xml.split("/>");
        var newXml = "";
        for (var i = 0; i < split.length - 1; i++) {
            var edsplit = split[i].split("<");
            newXml += split[i] + "></" + edsplit[edsplit.length - 1].split(" ")[0] + ">";
        }
        return newXml + split[split.length - 1];
    }
}
