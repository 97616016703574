export class Events {
	// Content Manager Service
	static readonly uploadProgress: string = 'upload-progress';
	static readonly uploadComplete: string = 'upload-complete';

	// Content Requests
	static readonly actionsBoxVisible: string = 'actions-box-visible';
	static readonly contentFileMouseover: string = 'content-file-mouseover';

	//Upload Modal
	static readonly abortUpload: string = 'abort-upload';
	static readonly contentFileStateUpdate: string = 'content-file-state-update';

	//Custom Feeds
	//Create-Edit-Feed-Item
	static readonly removeFeedImage: string = 'remove-feed-image';
	static readonly getCreate24FeedItem: string = 'get-create-24-feed-item';

	//Core
	static readonly appError: string = 'app-error';
	static readonly transitionOverlay: string = 'transition-overlay';
	static readonly savingPreloader: string = 'saving-preloader';
	static readonly tokenSet: string = 'token-set';
	static readonly uploadModal: string = 'upload-modal';
	static readonly fileUploadAdvanceSequence: string = 'file-upload-advance-sequence'; //Advance the sequence one step

	//CRM
	//Create Edit Client
	static readonly addContract: string = 'add-contract';

	//My Players
	static readonly refreshPlayers: string = 'refresh-players';

	//Playlist Builder
	static readonly showPbModal: string = 'show-hide-modal';

	//Library
	static readonly onLibraryOrCategorySelect: string = 'on-library-or-category-select';
	static readonly refreshCategoriesOnCreateNew: string = 'refresh-categories-on-create-new';
	static readonly refreshCategoriesOnDelete: string = 'refresh-categories-on-delete';
	static readonly isLibrarySelected: string = 'is-library-selected';

	//Library Tree
	static readonly onContentSearch: string = 'on-content-search';
	static readonly onContentSearchClear: string = 'on-clear-library-search';

	// Shared

	//Advanced Scheduler
	static readonly notifyAdvancedSchedulerOfSave: string = 'notify-advanced-scheduler-of-save';

	//Content
	static readonly removeItems: string = 'remove-content';

	//Get Playlist Data Service
	static readonly getPlaylistContentComplete: string = 'get-playlist-content-complete';

	//Google Maps Typeahead Service
	static readonly onGMTypeaheadOptionSelect: string = 'on-gm-typeahead-option-select';

	//PlaylistDetails
	static readonly getScheduledPlayersComplete: string = 'get-scheduled-players-complete';

	//Table Experiment
	static readonly detectTableChanges: string = 'detect-changes';
	static readonly onColumnSort: string = 'on-column-sort';

	//Ticket System
	static readonly onPostHistoryNote: string = 'on-post-history-note';
	static readonly onWorkOrderPdfCreate: string = 'on-work-order-pdf-create';

	//Table
	static readonly uncheckHeaderCheckbox: string = 'uncheck-header-checkbox';
}
