import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { LoginService } from '../login.service';
import { AlertMessage, LoginView } from '../_models/login-models';
import { DataTestIds } from 'src/app/shared/helpers/data-test-ids';

@Component({
	selector: 'l-forgot-password-view',
	templateUrl: './forgot-password-view.component.html',
	styleUrls: ['./forgot-password-view.component.scss']
})
export class ForgotPasswordViewComponent implements OnInit {
	public emailOrUsername: string;
	public submitClicked: boolean;
	readonly DataTestIds = DataTestIds;

	@Output() timeoutNotify: EventEmitter<any> = new EventEmitter<any>();

	constructor(public loginService: LoginService) {}

	ngOnInit() {}

	public onCancelClick(): void {
		this.loginService.alert = { ...this.loginService.alert, visible: false };
		this.loginService.viewState = LoginView.MainView;
	}

	public onSubmitClick(): void {
		this.submitClicked = true;
		this.loginService.setAlert({
			visible: true,
			bgColor: 'green-bg',
			textColor: 'white-text',
			message: AlertMessage.AfterResetSubmit,
			icon: ''
		});

		this.loginService.sendPasswordResetEmail(this.emailOrUsername).subscribe();
		setTimeout(() => {
			this.loginService.viewState = LoginView.MainView;
			this.loginService.alert = { ...this.loginService.alert, visible: false };
		}, 6000);
	}
}
