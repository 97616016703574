import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService } from '../app-state.service';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

export const adminOrNatalieGuard = () => {
	const appStateService = inject(AppStateService);
	const router = inject(Router);

	const currentUser = JSON.parse(appStateService.getSessionItem('currentUser'));

	const currentUserIsAdmin = () => currentUser.Role === UserRole.ADMINISTRATOR;
	const currentUserIsNatalie = () => currentUser.UserId === 35;

	if (currentUserIsAdmin() || currentUserIsNatalie()) {
		return true;
	} else {
		router.navigate(['/access-denied']);
		return false;
	}
};
