import { ContentFiles, ContentLibraryCategories } from '.';
import { CategoriesTree } from '../../components/library-tree/_models';

export class Content {
	Id?: number;
	LibraryId: number;
	ContentTypeId: number;
	CategoryId: number;
	IsChannel: boolean;
	IsEditable: boolean;
	Title: string;
	Text: string;
	CreateDate: string;
	CreatedByUserId: number;
	UpdateDate: string;
	UpdatedByUserId: number;
	IsDeleted: boolean;
	IsFeatured: boolean;
	FeatureStartDate?: string;
	FeatureEndDate?: string;
	IsApproved: boolean;
	HideFromWebPlayer: boolean;
	FeatureCodeId?: number;
	FeatureCodeStart?: string;
	FeatureCodeEnd?: string;
	DisplayedFeatureCodeId?: number;

	contentFiles?: ContentFiles[];
	ContentFiles?: ContentFiles[];
	category?: ContentLibraryCategories;
}

export interface IGetContentParams {
	clickedCategory?: CategoriesTree;
	create24ContentOnly?: boolean;
	hideDeleted?: boolean;
	searchArea?: string;
	isMusicStep?: boolean;
	orderByCol?: string;
	orderDesc?: boolean;
}
