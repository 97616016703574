import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class EncryptService {

    constructor() { }

    public encryptData(data: string): string {
        let encryptedData = crypto.AES.encrypt(JSON.stringify(data), 'seehemewepatputpopseethreetreebeetophopstop').toString();
        return this.base64EncodeUrl(window.btoa(encryptedData));
    }

    public decryptData(data: string): string {
        let decryptedData = window.atob(this.base64DecodeUrl(data));

        const bytes = crypto.AES.decrypt(decryptedData, 'seehemewepatputpopseethreetreebeetophopstop');
        if (bytes.toString()) {
            return (bytes.toString(crypto.enc.Utf8));
        }
    }

    private base64EncodeUrl(str: string) {
        return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
    }

    private base64DecodeUrl(str) {
        str = (str + '=').slice(0, str.length + (str.length % 4));
        return str.replace(/-/g, '+').replace(/_/g, '/');
    }
}
