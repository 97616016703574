import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { PlayerPlaylistHistoryComponent } from './player-playlist-history.component';
import { PlayerPlaylistHistoryRoutingModule } from './player-playlist-history-routing.module';
import { PlaylistDetailsWrapperComponent } from './playlist-details-wrapper/playlist-details-wrapper.component';
import { PphXsViewComponent } from './pph-xs-view/pph-xs-view.component';

@NgModule({
  declarations: [PlayerPlaylistHistoryComponent, PlaylistDetailsWrapperComponent, PphXsViewComponent],
  imports: [
    CommonModule,
    SharedModule,
    PlayerPlaylistHistoryRoutingModule
  ]
})
export class PlayerPlaylistHistoryModule { }
