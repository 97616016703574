import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AppStateService, DateTimeService, Events, MessageService } from '../../../core/services';
import { environment } from '../../../../environments/environment';
import { EmployeeTicketCount, InstallersView, TicketsTable } from '../_models';
import { TableConfigService } from './table-config.service';
import { ViewStateService } from './view-state.service';
import { TicketsTableApi } from 'src/app/shared/api-models/admin/tickets-table-api.type';
import { PageTitle } from '../_models/tickets.enum';

@Injectable({
	providedIn: 'root'
})
export class TicketSystemService {
	public mainTableData: TicketsTable[] | InstallersView[] | TicketsTableApi[];
	public overdueTicketCountTimer: any;
	public unassignedTickets: TicketsTable[];

	public pastDueTickets: TicketsTable[];
	public openTickets: TicketsTable[];
	public allTickets: TicketsTable[];
	public installers: InstallersView[];

	constructor(
		private appStateService: AppStateService,
		private dateTimeService: DateTimeService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private tableConfigService: TableConfigService,
		private viewStateService: ViewStateService
	) {}

	public getTableData$(getUnassignedTickets?: boolean): Observable<TicketsTableApi[] | InstallersView[]> {
		this.viewStateService.spinnerActive = true;
		this.tableConfigService.setTable();
		this.tableConfigService.setTableConfig();
		return this.httpClient.get(this.url(getUnassignedTickets), { observe: 'response' }).pipe(
			map((rawResponse: HttpResponse<TicketsTableApi[] | InstallersView[]>) => {
				const response: TicketsTableApi[] | InstallersView[] = rawResponse.body;
				this.viewStateService.mainTableTotalItems = parseInt(rawResponse.headers.get('x-result-count'));

				this.viewStateService.spinnerActive = false;
				if (this.viewStateService.pageTitle !== PageTitle.MANAGE_INSTALLERS) {
					return this.mappedTickets(response as TicketsTableApi[]);
				} else {
					return this.mappedInstallers(response as InstallersView[]);
				}
			})
		);
	}

	private mappedTickets(tickets: TicketsTableApi[]) {
		return (
			tickets.map((ticket) => {
				ticket.classList = 't-row';
				ticket.lastActionDateView = this.dateTimeService.dateAndTimeUTCtoLocal(ticket.LastTicketActionDate, true);
				ticket.isHighPriorityString = ticket.IsHighPriority ? '<span class="red-text semi-bold"><em>On</em></span>' : '<span><em>Off</em></span>';
				ticket.rowBgColor = this.rowBgColor(ticket.ActionColor);
				ticket.take = 'Take';
				return ticket;
			}) || []
		);
	}

	public setTableData(getUnassignedTickets: boolean, ticketsOrInstallers: TicketsTable[] | InstallersView[]): void {
		if (getUnassignedTickets) {
			this.unassignedTickets = ticketsOrInstallers as TicketsTable[];
		} else {
			this.mainTableData = ticketsOrInstallers;
		}
	}

	private mappedInstallers(installers: InstallersView[]): InstallersView[] {
		return installers.map((installer) => {
			installer.classList = 't-row white-bg';
			installer.isActive = installer.IsActive ? `<span class="green-text">Yes</span>` : `<span class="gray-text-darker"><em>No</em></span>`;
			installer.rowBgColor = !installer.IsActive ? 'gray-bg-darkest' : null;
			installer.Email = this.isStringifiedArray(installer.Email) ? this.formatedEmailList(installer.Email) : installer.Email;
			return installer;
		});
	}

	public formatedEmailList(email: string): string {
		let formattedString: string = ``;
		JSON.parse(email).forEach((e) => {
			formattedString = formattedString.concat(e, '<br>');
		});
		return formattedString;
	}

	public isStringifiedArray(string: string): boolean {
		if (string) {
			return string.charAt(0) === '[';
		}
	}

	public assignTicket$(ticket: TicketsTable): Observable<Object> {
		this.messageService.publish(Events.savingPreloader, 1);
		const currentUser = JSON.parse(this.appStateService.getSessionItem('currentUser'));
		return this.httpClient.patch(`${environment.adminUrl}Tickets/${ticket.Id}`, { AssignedToUserId: currentUser.UserId }).pipe(
			tap(() => {
				this.messageService.publish(Events.savingPreloader, 0);

				//remove ticket from unassigned table
				const index: number = this.unassignedTickets.findIndex((t) => t.Id === ticket.Id);
				this.unassignedTickets.splice(index, 1);

				//refresh table so it gets the changes
				this.messageService.publish(Events.detectTableChanges, [this.unassignedTickets, this.tableConfigService.unclaimedTable.guid]);
			})
		);
	}

	public getEmployeeTicketCount$(): Observable<any> {
		return this.httpClient.get(`${environment.adminUrl}Tickets/EmployeeTicketCount`).pipe(
			tap((ticketCount: EmployeeTicketCount[]) => {
				this.viewStateService.employeeTicketCountList = ticketCount.map((item) => {
					item.displayName = `${item.FirstName} ${item.LastName} (${item.Count})`;
					return item;
				});

				//If title is not previously defined, find current user so we can display it as the selectedUser in the dropdown list
				if (!this.viewStateService.pageTitle) {
					this.viewStateService.selectedEmployee = this.viewStateService.employeeTicketCountList.find(
						(employee) => employee.Id === this.appStateService.currentUser.UserId
					);
					this.viewStateService.pageTitle = `Tickets - ${this.viewStateService.selectedEmployee.displayName}`;
				}
			})
		);
	}

	public getOverdueTicketCount(): void {
		this.httpClient.get(`${environment.adminUrl}Tickets/OverdueTicketCount`).subscribe((count: number) => {
			this.viewStateService.overdueTicketCount = count;
			this.overdueTicketCountTimer = setTimeout(() => {
				this.getOverdueTicketCount();
			}, 1800000); //30 min.
		});
	}

	public rowBgColor(actionColor: string): string {
		switch (actionColor) {
			case 'red':
				return 'red-bg-light white-text';
			case 'yellow':
				return 'yellow-bg';
			default:
				return 'white-bg';
		}
	}

	public url(getUnassignedTickets?: boolean): string {
		const { orderByCol, orderDesc, pageNumber, postponedVisible, searchTerm, selectedEmployee, includeClosed, clientId } = this.viewStateService;
		const searchActive: boolean = searchTerm.length > 0;
		if (getUnassignedTickets) {
			return `${environment.adminUrl}Tickets/UnassignedView`;
		}

		switch (this.viewStateService.pageTitleView()) {
			case 'My Tickets':
				return searchActive
					? `${environment.adminUrl}Tickets/SearchView/${searchTerm}?&includeClosed=false&userId=${selectedEmployee.Id}&orderByCol=${orderByCol}&orderDesc=${orderDesc}&pageSize=10&pageNumber=${pageNumber}`
					: `${environment.adminUrl}Tickets/PostponedView/${postponedVisible}?userId=${selectedEmployee.Id}&includeClosed=false&orderByCol=${orderByCol}&orderDesc=${orderDesc}&pageSize=10&pageNumber=${pageNumber}`;

			case 'Open Tickets':
				return searchActive
					? `${environment.adminUrl}Tickets/SearchView/${searchTerm}?&includeClosed=false&orderByCol=${orderByCol}&orderDesc=${orderDesc}&pageSize=10&pageNumber=${pageNumber}`
					: `${environment.adminUrl}Tickets/OpenView?&clientId=${clientId}&orderByCol=${orderByCol}&includeClosed=${includeClosed}&orderDesc=${orderDesc}&pageSize=10&pageNumber=${pageNumber}`;

			case 'Overdue Tickets':
				return searchActive
					? `${environment.adminUrl}Tickets/SearchView/${searchTerm}?&includeClosed=false&overdueOnly=true&orderByCol=${orderByCol}&orderDesc=${orderDesc}&pageSize=10&pageNumber=${pageNumber}`
					: `${environment.adminUrl}Tickets/OpenView?orderByCol=${orderByCol}&includeClosed=false&overdueOnly=true&orderDesc=${orderDesc}&pageSize=10&pageNumber=${pageNumber}`;

			case 'All Tickets':
				return searchActive
					? `${environment.adminUrl}Tickets/SearchView/${searchTerm}?&orderByCol=${orderByCol}&orderDesc=${orderDesc}&includeClosed=true&pageSize=10&pageNumber=${pageNumber}`
					: `${environment.adminUrl}Tickets/OpenView/?orderByCol=${orderByCol}&orderDesc=${orderDesc}&includeClosed=true&pageSize=10&pageNumber=${pageNumber}`;

			case 'Manage Installers':
				return searchActive
					? `${environment.adminUrl}CoreInstallers/Search/${searchTerm}?includeInactive=true`
					: `${environment.adminUrl}CoreInstallers?pageSize=10&pageNumber=${pageNumber}&orderByCol=${orderByCol}&orderDesc=${orderDesc}`;
		}
	}
}
