import { ContentItem } from './content-item';
import { ContentAdvancedSchedules, Playlists } from '../../api-models/content';
import { PlayersDragDrop } from '../admin/players-drag-drop';

export class PbPlaylist extends Playlists {
	contentItems: ContentItem[];
	playlistItems: PlaylistItem[];
	musicContentItem: ContentItem;
	duration: any;
	isScheduled: boolean;
	isStopDateSelected: boolean;
	isValid: boolean;
	startDate: string;
	stopDate: string;
	status: 'New' | 'Inactive' | 'Active' | 'Scheduled' | 'Draft';
	statusView: string;
	isWritable: boolean;
	preview: any;
	version: string;
	previewBtnIcon: string;
	previewActive: boolean;
	advancedScheduleActive: boolean;
	advancedSchedule: ContentAdvancedSchedules;
	deleteBtn: string;
	isDisabled: boolean;
	isSelected: boolean;
	createdByUserFriendlyName: string;
	scheduledPlayers?: PlayersDragDrop[];
	isLoadedPlaylist: boolean;
	voipRecipients: string;
	LibraryId: number; //belongs to PlaylistsExamples
}

export type PlaylistItem = {
	contentId: number;
	duration: number;
	isMusicTrack: boolean;
	name: string;
	script: string;
};
