<portal-header></portal-header>

<div class="container-fluid mx-4 mgn-top30 relative">
	<div class="row mgn-bottom30">
		<div class="col-sm-7 pd-left0">
			<h1 class="semi-bold">
				<i class="fas fa-hdd mgn-right15" aria-hidden="true"></i>
				Player Status Report
			</h1>
		</div>
		<div class="col-sm-2">
			<h4 class="semi-bold">Errors:</h4>
			<i class="fas fa-exclamation-triangle red-text"></i>
			=
			<span>Undownloaded playlist</span>
		</div>
		<div class="col-sm-2">
			<h4 class="semi-bold">Repair States:</h4>
			<i class="far fa-clock" aria-hidden="true"></i>
			=
			<span>Idle</span>
			<br />
			<i class="fas fa-wrench" aria-hidden="true"></i>
			=
			<span>Being Repaired</span>
			<br />
			<i class="fas fa-hourglass-half" aria-hidden="true"></i>
			=
			<span>Pending Completion</span>
			<br />
			<i class="fas fa-toggle-off"></i>
			=
			<span>Fickle</span>
			<br />
			<i class="fas fa-check" aria-hidden="true"></i>
			=
			<span>OK</span>
		</div>
		<div class="col-sm-1 relative">
			<button class="white-bg" (mouseup)="colorKeyVisible = !colorKeyVisible">Color Key</button>
			<div *ngIf="colorKeyVisible" class="absolute wdth600 right15 white-bg shadow border-radius pd15 z">
				<div class="row">
					<div class="col-sm-12">
						<span class="semi-bold">Players not being repaired:</span>
						<span>Green if last checkin date < 24 hours, white if > 24 hours</span>
						<br />
						<span class="semi-bold">Players being repaired:</span>
						<span>Yellow</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row white-bg shadow border-radius pd-top10 pd-bottom10 mgn-bottom15">
		<div class="col-md-2">
			<button
				[ngClass]="playerStatusReportService.viewState.toggleBtnState === 'client' ? 'gray-bg-darker' : 'gray-bg'"
				class="first-btn in-block mgn-rightneg6"
				(click)="onToggleBtnClick('client')"
			>
				Client
			</button>
			<button
				[ngClass]="playerStatusReportService.viewState.toggleBtnState === 'csr' ? 'gray-bg-darker' : 'gray-bg'"
				class="last-btn in-block"
				(click)="onToggleBtnClick('csr')"
			>
				CSR
			</button>
		</div>
		<div class="col-md-2">
			<div class="row">
				<div class="col-sm-6">
					<button
						(click)="onShowDemoAccountsClick()"
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						data-tippy-content="Include demo accounts"
						[tippyProps]="{ appendTo: 'parent' }"
						class="{{ playerStatusReportService.viewState.includeDemoAccounts ? 'limeGreen-bg white-text' : 'white-bg dark-text' }} relative"
					>
						<i class="{{ playerStatusReportService.viewState.includeDemoAccounts ? 'fas fa-toggle-on' : 'fas fa-toggle-off' }}"></i>
					</button>
				</div>
				<div class="col-sm-6">
					<button
						(click)="onShowPoorNetworkOnlyClick()"
						class="{{ playerStatusReportService.viewState.showPoorNetworkOnly ? 'limeGreen-bg white-text' : 'white-bg dark-text' }} relative"
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						data-tippy-content="Show poor network only"
						[tippyProps]="{ appendTo: 'parent' }"
					>
						<i class="fas fa-download"></i>
						<i *ngIf="playerStatusReportService.viewState.showPoorNetworkOnly" class="fas fa-times ft-size11 absolute top14 right12 white-text"></i>
					</button>
				</div>
			</div>
		</div>
		<div *ngIf="playerStatusReportService.viewState.toggleBtnState === 'client'" class="col-md-2">
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[key]="'Name'"
				[options]="clients"
				[selectedOption]="'Filter by client...'"
				[searchEnabled]="true"
				(onOptionSelect)="onDropdownSelect($event, 'clientId')"
				(onSearchKeyupNotify)="onClientSearch($event)"
			></portal-dropdown-menu>
		</div>

		<div *ngIf="playerStatusReportService.viewState.toggleBtnState === 'csr'" class="col-md-2">
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[key]="'Name'"
				[options]="csrList"
				[selectedOption]="'Filter by CSR...'"
				(onOptionSelect)="onDropdownSelect($event, 'csrId')"
			></portal-dropdown-menu>
		</div>

		<div class="col-md-2">
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[key]="'Name'"
				[options]="playerModels"
				[selectedOption]="'Filter by player model...'"
				(onOptionSelect)="onDropdownSelect($event, 'playerModelId')"
			></portal-dropdown-menu>
		</div>

		<div class="col-md-2">
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[key]="'Name'"
				[options]="installStates"
				[selectedOption]="'Filter by install state...'"
				(onOptionSelect)="onDropdownSelect($event, 'installStateId')"
			></portal-dropdown-menu>
		</div>

		<div class="col-md-2">
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[key]="'Name'"
				[options]="productTypes"
				[selectedOption]="'Filter by product...'"
				(onOptionSelect)="onDropdownSelect($event, 'productTypeId')"
			></portal-dropdown-menu>
		</div>
	</div>
	<div class="row">
		<div *ngIf="spinnerActive" style="left: 47%" class="absolute z top256">
			<portal-spinner [width]="'wdthpx76'" [height]="'htpx76'"></portal-spinner>
		</div>
		<portal-table
			[table]="tableConfigService.table"
			[config]="tableConfigService.configuration"
			[data]="playerStatusReport"
			[showJumpToPage]="true"
			[page]="playerStatusReportService.viewState.pageNumber"
			[totalItems]="playerStatusReportService.totalItems"
			(currentPageNotify)="currentTablePage($event)"
			(linkClickNotify)="onClientOrLocationClick($event)"
		></portal-table>
	</div>
</div>
