export class PlayerStatus {
	Id: number;
	ClientId: number;
	ClientName: string;
	LocationId: number;
	LocationName: string;
	ProductId: number;
	PlayerId: number;
	PlayerName: string;
	PlayerIdentifier: string;
	PlaybackJson: string;
	SettingsJson: string;
	PerformanceJson: string;
	ShowTicker: boolean;
	TickerId: number;
	Play24StationId: number;
	ReportedVersion: string;
	TargetVersion: string;
	SourceIp: string;
	OsVersion: string;
	OsUpdateJson: string;
	LastPlaylistUpdate: string;
	LastCheckin: string;
	CurrentPlay24StationId: number;
	RequestedPlay24StationId: number;
	LoggingLevel: number;
	PlayerModelTypeId: number;
	Stations: any[];
	LayoutId: number;
	LayoutThemeId: number;
	WidgetId: number;
	WidgetFeedId: number;
	LogoPosition: number;
	ForceBomgarReinstall: boolean;

	// daysSinceUpdate: number;
	// daysSinceCheckin: number;
	// lastUpdateCrmView: string;
	// lastCheckinCrmView: string;
}