import { Component, OnInit, HostListener } from '@angular/core';

import { CanvasService } from '../../canvas/_services';
import { Create24Service } from 'src/app/user-tools/create-24/create-24.service';
import { LayersService, StateService } from '../../_services';
import { VideoControlsService } from '../../_services/video-controls.service';

@Component({
	selector: 't-video-controls',
	templateUrl: './video-controls.component.html',
	styleUrls: ['./video-controls.component.scss']
})
export class VideoControlsComponent implements OnInit {
	//Play/Pause with spacebar
	@HostListener('document:keyup', ['$event'])
	space(e) {
		if (this.playViaSpaceEnabled(e)) {
			this.videoControlsService.onClick('play');
		}
	}
	@HostListener('window:keydown', ['$event'])
	preventScroll(e) {
		if (e.code === 'Space' && e.target === document.body) {
			e.preventDefault(); // Prevent scroll down
		}
	}

	get showClickPlayText() {
		const { isPlaying, playClock } = this.videoControlsService;
		return !isPlaying && playClock === 0 && this.create24Service.contentType() === 'video' && !this.timerExpired;
	}

	public timerExpired: boolean = false;

	constructor(
		private canvasService: CanvasService,
		public create24Service: Create24Service,
		private layersService: LayersService,
		private stateService: StateService,
		public videoControlsService: VideoControlsService
	) {}

	ngOnInit(): void {
		this.videoControlsService.playIcon = 'fas fa-play';
		setTimeout(() => {
			this.timerExpired = true;
		}, 6000);
	}

	public playBtnClassList(): string {
		if (this.create24Service.contentType() === 'image') {
			return 'relative in-block ft-size18 lt-gray-bg topneg5 text-center';
		}
		return 'relative pointer in-block ft-size18 red-bg topneg5 text-center';
	}

	public onCanvasExpandClick(): void {
		this.layersService.onCanvasExpandClick();
	}

	private playViaSpaceEnabled(e): boolean {
		const activeObj = this.canvasService.canvas.getActiveObject();
		return (
			e.code === 'Space' &&
			this.create24Service.contentType() === 'video' &&
			(!activeObj || !activeObj?.isEditing) &&
			(!this.layersService.activeLayer?.nameState || this.layersService.activeLayer?.nameState === 'read') &&
			!this.stateService.exportWindowVisible
		);
	}
}
