export class ClientErrorLog {
    ClientId: number;
    ErrorDetail: string;
    ErrorName: string;
    ErrorSource: string;
    Id: number;
    LogDate: string;
    PlayerIdentifier: string;
    SendNotificationEmail: boolean;
    SystemInfo: string;
    UserId: number;

    classList: string;
    sendNotificationEmail: string;
    detailMessage: string;
}