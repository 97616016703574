import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { FileUploadService } from '../../core/services';
import { Events, MessageService } from 'src/app/core/services';

@Injectable({
    providedIn: 'root'
})
export class ReminderEmailsService {

    public date: {month?: any} = {};
    public months: any[] = [
        { name: 'January' }, { name: 'February' }, { name: 'March' }, { name: 'April' }, { name: 'May' },
        { name: 'June' }, { name: 'July' }, { name: 'August' }, { name: 'September' }, { name: 'October' },
        { name: 'November' }, { name: 'December' }
    ];

    constructor(
        private fileUploadService: FileUploadService,
        private messageService: MessageService,
        private httpClient: HttpClient,
    ) { }

    public postImage(file: any): void {
        let url = `${environment.contentUrl}StaticFile/Named/reminder-emails/${file.name}?overwriteFile=true`;
        this.fileUploadService.doProgressRequest(url, this.fileUploadService.formData(file), 'POST')
    }

    public getMailers(): Observable<any> {
        return this.httpClient.get(`${environment.adminUrl}Mailer/GetReminderMailersByMonth/${this.date.month.name}`);
    }

    public patchMailer(id: number, key: string, value: string): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.httpClient.patch(`${environment.adminUrl}Mailer/${id}`, {[key]: value})
            .subscribe( () => {
                this.messageService.publish(Events.savingPreloader, 0);
            })
    }

    public sendTestEmail(): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.httpClient.get(`${environment.adminUrl}Mailer/SendTestReminderMailer/${this.date.month.name}`).subscribe( () => {
            this.messageService.publish(Events.savingPreloader, 0);
        });
    }

    public delete(): void {
        this.httpClient.delete(`${environment.contentUrl}StaticFile/Named/reminder-emails/august2020hold`).subscribe();
        this.httpClient.delete(`${environment.contentUrl}StaticFile/Named/reminder-emails/september2020hold`).subscribe();
    }

    // public generateYears(): void {
    //     let thisYear = new Date().getFullYear();
    //     let year = new Date().getFullYear()

    //     while(year < thisYear + 11) {
    //         this.years.push({name: year});
    //         year = year + 1;
    //     }
    // }

    public showLargeDateMonth(): boolean {
        return this.date.month === undefined;
    }
}
