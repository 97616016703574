import { Clients } from './clients';
import { CsrTaskTypes } from './csr-task-types';
import { Users } from './users';

export class CsrTasks {
	Id: number;
	CsrId: number;
	ClientId: number;
	Created: string;
	Reminder: string;
	DueDate: string;
	CsrTaskTypeId: number;
	Completed: boolean;
	CompleteDate: string;
	Subject: string;
	Notes: string;

	Csr: Users;
	CsrTaskType: CsrTaskTypes;
	Client: Clients;
}
