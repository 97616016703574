import { Component, OnInit } from '@angular/core';

import { ClientMergeToolStore } from '../client-merge-tool.store';
import { ClientMergeToolService } from '../client-merge-tool.service';
import { DateTimeService } from 'src/app/core/services';

@Component({
    selector: 'cmt-merge-data',
    templateUrl: './merge-data.component.html',
    styleUrls: ['./merge-data.component.scss']
})
export class MergeDataComponent implements OnInit {

    public locations$ = this.clientMergeToolStore.locations$;
    public users$ = this.clientMergeToolStore.users$;
    public libraries$ = this.clientMergeToolStore.libraries$;
    public contracts$ = this.clientMergeToolStore.contracts$;

    constructor(
        public clientMergeToolService: ClientMergeToolService,
        public clientMergeToolStore: ClientMergeToolStore,
        public dateTimeService: DateTimeService
    ) { }

    ngOnInit(): void {
        this.clientMergeToolStore.getLocations$();
        this.clientMergeToolStore.getUsers$();
        this.clientMergeToolStore.getLibraries$();
        this.clientMergeToolStore.getContracts$();
    }

    public onSelect(prop: string, item): void {
        this.clientMergeToolStore.toggleSingleItem(prop, item);
    }

    public onCheckAllClick(prop: string): void {
        switch (prop) {
            case 'locations':
                this.clientMergeToolStore.selectOrDeselectAllLocations(true);
                break;
            case 'users':
                this.clientMergeToolStore.selectOrDeselectAllUsers(true);
                break;
            case 'libraries':
                this.clientMergeToolStore.selectOrDeselectAllLibraries(true);
                break;
            case 'contracts':
                this.clientMergeToolStore.selectOrDeselectAllContracts(true);
                break;
        }
    }

    public onUncheckAllClick(prop: string): void {
        switch (prop) {
            case 'locations':
                this.clientMergeToolStore.selectOrDeselectAllLocations(false);
                break;
            case 'users':
                this.clientMergeToolStore.selectOrDeselectAllUsers(false);
                break;
            case 'libraries':
                this.clientMergeToolStore.selectOrDeselectAllLibraries(false);
                break;
            case 'contracts':
                this.clientMergeToolStore.selectOrDeselectAllContracts(false);
                break;
        }
    }

    public onMergeEverythingClick(): void {
        this.clientMergeToolStore.selectAll();
    }

    public onCancelClick(): void {
        this.clientMergeToolStore.resetState();
    }

    public onMergeClientsClick(): void {
        const confirmOk = confirm('Merge clients? This action cannot be undone.');
        if(confirmOk) {
            this.clientMergeToolStore.mergeClients$();
        }
    }
}
