import { Component, OnInit } from '@angular/core';

import { CanvasService } from '../../canvas/_services';
import { LayerDetailService } from '../_services';
import { LayersService } from '../../_services';

@Component({
    selector: 'ld-feed',
    templateUrl: './feed.component.html',
    styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit {


    constructor(
        private canvasService: CanvasService,
        private layerDetailService: LayerDetailService,
        public layersService: LayersService
    ) { }

    ngOnInit(): void {

    }

    public onFeedSourceSelect(feedSource: { name: 'Title' | 'Body' }): void {
        let layer = this.layersService.layers.find(layer => layer.id === this.layersService.activeLayer.id);
        layer.feedSourceName = feedSource.name;
        let value: any = this.value(feedSource);
        value = !isNaN(value) ? value.toString() : value;
        layer.feedSourceValue = value;
        layer.canvasObj.text = value;

        this.layerDetailService.updateProperty('text', value);
        this.layerDetailService.updateFeedTextImage();
        if (this.layerDetailService.activeObj().isFeedImage) {
            this.canvasService.addImage(layer, true);
        }
    }

    private value(feedSource: { name: 'Title' | 'Body' }): string {
        if (Array.isArray(this.canvasService.feedItem.parsedValues)) {
            return this.canvasService.feedItem.parsedValues[0][feedSource.name];
        }
        return this.canvasService.feedItem.parsedValues[feedSource.name];
    }
}