import { Injectable } from '@angular/core';

import { Column, Table, TableConfig } from '../../../../shared/components/table/view-models';

@Injectable({
  providedIn: 'root'
})
export class TableConfigService {

  public columns: Column[] = [
    new Column('ClientName', 'Company', 'string', true),
    new Column('friendlyName', 'Name', 'string', true),
    new Column('UserEmail', 'Email', 'string', true),
    new Column('mainContact', 'Main Contact', 'icon'),
    new Column('deleteBtn', 'Delete', 'button', false, 'red-bg-light white-text'),
  ];

  public table: Table = new Table(this.columns);


  public configuration: TableConfig = {
    itemsPerPage: 20,
    headerColor: 'green-bg'
  }
}
