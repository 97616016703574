import { Component, OnInit } from '@angular/core';

import { LogoOverlayService } from '../_services';

@Component({
    selector: 'lo-step-one',
    templateUrl: './step-one.component.html',
    styleUrls: ['./step-one.component.scss']
})
export class StepOneComponent implements OnInit {


    constructor(
            public logoOverlayService: LogoOverlayService
    ) { }

    ngOnInit() {
    }

    public onCheckboxClick(position: number): void {
        this.logoOverlayService.logoPosition = position;
    }
}
