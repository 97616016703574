<portal-header></portal-header>

<div class="container-fluid mgn-top30 relative">
	<a [routerLink]="['/my-products']" class="dark-text absolute topneg21 left15">
		<i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
		<span class="ft-size16">My Products</span>
	</a>
	<div class="ft-size16">
		<div class="row relative ht40 mgn-bottom17">
			<div class="col-sm-10 mgn-top10">
				<h1 class="in-block"><i class="far fa-lightbulb mgn-right12" aria-hidden="true"></i></h1>
				<h1 class="semi-bold in-block">Featured Content</h1>
				<span class="ft-size32">|</span>
				<em class="ft-size32">{{ month }} {{ year() }}</em>
			</div>
			<div *ngIf="securityService.userRoleAdminOrCsr()" class="col-sm-2">
				<button class="white-bg" [routerLink]="['/featured-content/set-send-date/apple-tvs']">
					<i class="far fa-calendar-alt mgn-right15"></i>
					Set send date
				</button>
			</div>
		</div>
	</div>

	<div class="row blue-red-bg-gradient ht2 mgn-top30 border-radius mgn-left0 mgn-right0"></div>

	<div class="row mgn-top30 mgn-bottom100">
		<div class="col-md-6">
			<div class="row">
				<div class="col-xs-8">
					<h2 class="semi-bold">
						<i class="fas fa-phone-volume mgn-right10"></i>
						Messages
						<span *ngIf="showMusicTitle()">/Background Music</span>
					</h2>
				</div>
				<div *ngIf="hasHold" class="col-xs-4">
					<button class="blue-bg white-text" [routerLink]="['/playlist-builder/hold']">
						<i class="fas fa-plus mgn-right10"></i>
						New Playlist
					</button>
				</div>
			</div>
			<div class="row mgn-top15">
				<div class="col-xs-12">
					<div *ngIf="featuredContentService.holdContentList; else loading">
						<ng-container *ngIf="featuredContentService.holdContentList.length; else noItems">
							<perfect-scrollbar #directiveRef="ngxPerfectScrollbar" class="border-top" style="max-height: 70vh">
								<div style="min-height: 52vh" class="col-md-12">
									<div *ngFor="let content of featuredContentService.holdContentList; let isEvenIndex = even; let isFirstIndex = first; let isLastIndex = last">
										<c-content
											[content]="content"
											[contentList]="featuredContentService.holdContentList"
											[contentViewState]="{ layout: viewportWidthService.isLg ? 'list-view' : 'card-view' }"
											[isEvenIndex]="isEvenIndex"
											[isFirstIndex]="isFirstIndex"
											[isLastIndex]="isLastIndex"
										></c-content>
									</div>
								</div>
							</perfect-scrollbar>
						</ng-container>
						<ng-template #noItems>
							<h3><em>No results found</em></h3>
						</ng-template>
					</div>
					<ng-template #loading>
						<div class="relative top200 z"><portal-spinner [height]="'htpx76'" [width]="'wdthpx76'"></portal-spinner></div>
					</ng-template>
				</div>
			</div>
		</div>

		<div class="col-md-6">
			<div class="row">
				<div class="col-xs-8">
					<h2 class="semi-bold">
						<i class="fas fa-film mgn-right10"></i>
						Video Clips
					</h2>
				</div>
				<div *ngIf="hasVideo" class="col-xs-4">
					<button class="red-bg white-text" [routerLink]="['/playlist-builder/video']">
						<i class="fas fa-plus mgn-right10"></i>
						New Playlist
					</button>
				</div>
			</div>
			<div class="row mgn-top15">
				<div class="col-xs-12">
					<div *ngIf="featuredContentService.videoContentList; else loading">
						<ng-container *ngIf="featuredContentService.videoContentList.length; else noItems">
							<perfect-scrollbar #directiveRef="ngxPerfectScrollbar" style="max-height: 70vh">
								<div *ngFor="let content of featuredContentService.videoContentList; let isEvenIndex = even; let isFirstIndex = first; let isLastIndex = last">
									<div class="col-md-6">
										<c-content
											[content]="content"
											[contentList]="featuredContentService.videoContentList"
											[contentViewState]="{ layout: viewportWidthService.isLg ? 'list-view' : 'card-view' }"
											[isEvenIndex]="isEvenIndex"
											[isFirstIndex]="isFirstIndex"
											[isLastIndex]="isLastIndex"
										></c-content>
									</div>
								</div>
							</perfect-scrollbar>
						</ng-container>
						<ng-template #noItems>
							<h3><em>No results found</em></h3>
						</ng-template>
					</div>
					<ng-template #loading>
						<div class="relative top200 z"><portal-spinner [height]="'htpx76'" [width]="'wdthpx76'"></portal-spinner></div>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</div>
