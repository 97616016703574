import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { AdminDataService } from '../../../core/services';
import { ContractRenewalReport } from '../../../shared/api-models/reporting';
import { environment } from '../../../../environments/environment';

type NameAndId = {Name: string, Id: number};

@Injectable()
export class RenewalsService {

    public cachedData: any[];

    constructor(
            private adminDataService: AdminDataService,
            private httpClient: HttpClient) { }

    public getSalesmenAndContractTypes(): Observable<any> {
        let activeSalesmen$ = this.adminDataService.getActiveSalesmen(true);
        let contractTypes$ = this.getContractTypes();
  
        return forkJoin([activeSalesmen$, contractTypes$]);
    }


    public getContractTypes(): Observable<any[]> {
        return this.httpClient.get(environment.adminUrl + `CoreContractTypes`).pipe(
            map((contractTypes: NameAndId[]) => {
                return contractTypes.filter(item => item.Id !== 6)
            })
        )
    }

    public getReport(url: string): Observable<any[]> {
        return this.httpClient.get(environment.adminUrl + url).pipe(
            map((report: ContractRenewalReport[]) => {
                return this.reportArrView(report);
            })
        )
    }

    private reportArrView(report: ContractRenewalReport[]): any {
        return report.map((r) => {
            return this.report(r);
        });
    }

    private report(report: ContractRenewalReport): any {
        report.totalValue = `$${Number(report.TotalValue).toLocaleString()}`,
        report.border = 'dark-border',

        this.setRowColors(report) ? report['rowBgColor'] = this.setRowColors(report).bgColor : null;
        this.setRowColors(report) ? report['rowTextColor'] = this.setRowColors(report).textColor : null;
        return report;
    }

    private setRowColors(report: ContractRenewalReport) {
        let today = moment(new Date);
        let date = moment(report.RenewDate);
        let difference = today.diff(date, 'days');


        if (difference < 0) {
            return { bgColor: 'green-bg', textColor: 'dark-text' };
        }
        if (difference > 0 && difference <= 90) {
            return { bgColor: 'yellow-bg', textColor: 'dark-text' };
        }
        if (difference >= 91) {
            return { bgColor: 'red-bg-darkest', textColor: 'white-text' };
        }
    }
}
