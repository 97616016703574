import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SharedModule } from '../shared/shared.module';
import { PlaylistBuilderAccessDeniedComponent } from './playlist-builder-access-denied/playlist-builder-access-denied.component';
import { TransitionOverlayComponent } from './transition-overlay/transition-overlay.component';
import { SavingPreloaderComponent } from './saving-preloader/saving-preloader.component';
import { SessionExpireModalComponent } from './session-expire-modal/session-expire-modal.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AppErrorComponent } from './app-error/app-error.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  declarations: [FooterComponent, NotFoundComponent, PlaylistBuilderAccessDeniedComponent, TransitionOverlayComponent, SavingPreloaderComponent, SessionExpireModalComponent, AccessDeniedComponent, AppErrorComponent],
  exports: [AppErrorComponent, FooterComponent, SavingPreloaderComponent, SessionExpireModalComponent, TransitionOverlayComponent]
})
export class CoreModule { }
