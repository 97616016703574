import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
import { IMyDateModel } from 'angular-mydatepicker';
import * as moment from 'moment';

import { DateTimeService } from '../../../core/services';
import { Model, View } from './models';
import { TimesOfDay } from '../../api-models/view/schedule-data';

@Injectable()
export class StartStopDateTimeService {

    //Observable sources
    private onModelChangeSource = new Subject < any > ();

    //Observable streams
    public onModelChange$ = this.onModelChangeSource.asObservable();

    public backgroundColor: string;
    public datePickerBgColor: string;
    public errorMessage: string;
    public errorMessageVisible: boolean;
    public flow: string;
    public isStopDateSelected: boolean;
    public isScheduleValid: boolean;
    public model: Model = new Model();
    public timesOfDay: string[] = TimesOfDay;
    public view: View = new View();

    constructor(private dateTimeService: DateTimeService) {}

    //Initialize existing date/time, call from component (not ssdt component)
    public startStopDateTimeInit(startDate: string, stopDate?: string): void {
        this.view.startDate = this.convertDateModelToView(startDate).dateView;
        this.view.startTime = this.convertDateModelToView(startDate).timeView;

        if(startDate) {
            if(startDate?.slice(-1) !== 'Z') {
                startDate = startDate + 'Z'; //must have Z suffix to convert correctly
            }
            this.model.StartDate = startDate; //Incoming start date should already be UTC, don't convert again
        }

        if (stopDate) {
            this.isStopDateSelected = true;
            this.view.stopTime = this.convertDateModelToView(stopDate).timeView;
            this.view.stopDate = this.convertDateModelToView(stopDate).dateView;

            if(stopDate.slice(-1) !== 'Z') {
                stopDate = stopDate + 'Z'; //must have Z suffix to convert correctly
            }
            this.model.StopDate = stopDate; //Incoming stop date should already be UTC, don't convert again
        }
        this.validate();
    }


    onStartTimeChanged(time: {name: string}): void {
        this.view.startTime = time.name;
        this.model.StartDate = this.convertDateViewToModel(this.view.startDate, time.name);
        this.validate();
        this.onModelChangeSource.next([this.isScheduleValid, this.isStopDateSelected]);
    }

    onStopTimeChanged(time: {name: string}): void {
        this.view.stopTime = time.name;
        this.model.StopDate = this.convertDateViewToModel(this.view.stopDate, time.name);
        this.validate();
        this.onModelChangeSource.next([this.isScheduleValid, this.isStopDateSelected]);
    }

    onStopDateToggleClick(payload: any[]): void {
        const str: string = payload[0];
        const initAsNull: boolean = payload[1];

        this.isStopDateSelected = str === 'on' ? true : false;
        //if turning off stop date, re-inizialize view and model to one month from today
        if(!this.isStopDateSelected && !initAsNull) {
            this.view.stopDate = this.convertDateModelToView(this.dateTimeService.todayPlusOneMonth()).dateView;
            this.view.stopTime = '';
            this.model.StopDate = this.convertDateViewToModel(this.view.stopDate, '');
            setTimeout( () => this.view.stopTime = '12:00 AM', 10);
        }
        this.validate(true, initAsNull);
        this.onModelChangeSource.next([this.isScheduleValid, this.isStopDateSelected]);
    }

    onStartDateChanged(event: IMyDateModel): void {
        this.view.startDate = event;
        this.model.StartDate = this.convertDateViewToModel(this.view.startDate, this.view.startTime);
        this.validate();
        this.onModelChangeSource.next([this.isScheduleValid, this.isStopDateSelected]);
    }

    onStopDateChanged(event: IMyDateModel): void {
        this.view.stopDate = event;
        this.model.StopDate = this.convertDateViewToModel(this.view.stopDate, this.view.stopTime);
        this.validate();
        this.onModelChangeSource.next([this.isScheduleValid, this.isStopDateSelected]);
    }


    public convertDateViewToModel(date: IMyDateModel, time: string): string {
        return moment(moment(`${date.singleDate.date.month}/${date.singleDate.date.day}/${date.singleDate.date.year}`, 'MM/DD/YYYY').format('YYYY-MM-DD') + time, 'YYYY-MM-DDLT').utc()
            .format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    }

    //Assumes incoming date is UTC, outputs local 
    public convertDateModelToView(date: string): {dateView: IMyDateModel, timeView: string} {
        if(date?.slice(-1) !== 'Z') {
             date = date + 'Z'; //must have Z suffix to convert correctly
        }
        
        const dateView: IMyDateModel = this.dateTimeService.iMyDatePickerDateInit(date);

        const timeView: string = moment.utc(new Date(date)).local().format('h:mm A');
        return {
            dateView,
            timeView
        };
    }

    public validate(hideErrorMessage? : boolean, initAsNull?: boolean): void {
        if(initAsNull) {
            this.isScheduleValid = true;
            return;
        }
        //Only start date selected
        if (!this.isStopDateSelected) {
            if (this.view.startDate) {
                this.isScheduleValid = true;
                this.errorMessageVisible = false;
                return;
            }
        }

        //Start and stop dates selected
        if (this.isStopDateSelected) {
            if (this.startAndStopDateFieldsComplete() && this.stopDateAfterStartDate()) {
                this.isScheduleValid = true;
                this.errorMessageVisible = false;
                return;
            } else if (!this.stopDateAfterStartDate()) {
                this.isScheduleValid = false;
                if (!hideErrorMessage) {
                    this.errorMessageVisible = true;
                }

            } else {
                this.isScheduleValid = false;
                if (!hideErrorMessage) {
                    this.errorMessageVisible = true;
                }
            }
        }
    }

    private startAndStopDateFieldsComplete(): boolean {
        return this.view.startDate !== null && this.view.startDate !== undefined &&
            this.view.stopDate !== null && this.view.stopDate !== undefined
    }

    private stopDateAfterStartDate(): boolean {
        return moment(new Date(this.model.StartDate)).isBefore(new Date(this.model.StopDate));
    }
}
