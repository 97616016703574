export class Playlists {
	Id: number;
	SecretId: string;
	ClientId: number;
	ProductId: number;
	CreateDate: string;
	CreatedByUserId: number;
	UpdateDate: string;
	UpdatedByUserId: number;
	Name: string;
	IsDeleted: boolean;
	ParagraphPause: number;
}