<div style="min-height: 246px" class="container-fluid border-radius shadow p-4 white-bg mgn-bottom30 relative">
	<div class="title pd-bottom15 gray-border white-bg border-radius">
		<h3 class="ellipsis semi-bold">{{ content.Title }}</h3>
	</div>
	<hr class="gray-border mgn-top0 mgn-bottom30" />

	<div class="row">
		<div class="col-xs-6">
			<div class="row">
				<div class="col-xs-8 mgn-bottom15">
					<button
						*ngIf="content.ContentTypeId !== 1"
						(click)="contentViewService.onPlayClick(content, isLastIndex, 'talentPreviewSelectorVisible')"
						class="noExit blue-bg white-text"
					>
						<i class="fa {{ content.talentPreviewSelectorVisible ? 'fa-times' : 'fas fa-play' }} mgn-right10" aria-hidden="true"></i>
						{{ content.talentPreviewSelectorVisible ? 'Close' : 'Play' }}
					</button>

					<button
						*ngIf="content.ContentTypeId === 1"
						(click)="contentViewService.onPlayClick(content, null, null, cvStateService.contentList)"
						class="noExit {{ content.contentFiles[0].previewActive ? 'yellow-bg dark-text' : 'blue-bg white-text' }}"
					>
						<i class="fa {{ content.contentFiles[0].previewActive ? 'fa-times' : 'fas fa-play' }} mgn-right6" aria-hidden="true"></i>
						{{ content.contentFiles[0].previewActive ? 'Stop' : 'Play' }}
					</button>

					<div *ngIf="content.talentPreviewSelectorVisible" style="left: 96%; z-index: 100" class="absolute top0 mgn-bottom30">
						<portal-select-box
							[content]="content"
							[title]="'Select a Preview Version'"
							[width]="'wdth226'"
							(onSelectNotify)="contentViewService.onSelectVoiceTalentClick($event, content, 'talentPreviewSelectorVisible')"
						></portal-select-box>
					</div>
				</div>
				<div class="col-xs-8 mgn-bottom15">
					<button
						(click)="showScript = !showScript"
						[disabled]="content.Text.length < 1"
						[ngClass]="{ 'gray-bg': content.Text.length > 0, setOpacityLow: content.Text.length < 1 }"
					>
						<i [ngClass]="{ 'fas fa-times': showScript, 'far fa-file-alt': !showScript }"></i>
					</button>
				</div>
				<div *ngIf="cvStateService.viewState.treeSelection !== 'featured-playlist'" class="col-xs-8">
					<button (click)="contentFavoritesService.onFavoriteClick(content)" class="{{ content.isFavorite ? 'pink-bg' : 'white-bg' }} relative white-text">
						<i class="fa fa-heart {{ content.isFavorite ? 'white-text' : 'pink-text' }}" aria-hidden="true"></i>
						<i *ngIf="content.isFavorite" class="fa fa-check absolute ft-size10 top2 right2" aria-hidden="true"></i>
					</button>
				</div>
			</div>
		</div>
		<div class="col-xs-6">
			<div class="row">
				<div class="col-xs-8 col-xs-offset-4">
					<button
						*ngIf="content.contentFiles?.length > 0"
						[disabled]="
							playerCapacityService.disableSave ||
							(content.contentFiles?.length === 1 && content.contentFiles[0].ContentFileStateId === contentFileState.IN_REVISION)
						"
						(click)="contentViewService.onAddMessageClick(content, isLastIndex, 'talentPickerVisible')"
						class="noExit gray-bg"
					>
						{{ content.talentPickerVisible ? 'Close' : 'Add' }}
						<i *ngIf="!content.talentPickerVisible" class="fa fa-plus mgn-left6" aria-hidden="true"></i>
						<i *ngIf="content.talentPickerVisible" class="fa fa-times mgn-left8" aria-hidden="true"></i>
					</button>
					<span *ngIf="content.contentFiles[0].ContentFileStateId === contentFileState.IN_REVISION" class="italic text-gray-400">In Revision</span>
					<button *ngIf="content.contentFiles?.length < 1" class="gray-bg-darker cursor-not-allowed">Unavailable</button>
					<div *ngIf="content.talentPickerVisible" class="absolute right15 top40 z mgn-bottom30">
						<portal-select-box
							[content]="content"
							[title]="'Select a Voice Talent'"
							[width]="'wdth226'"
							(onSelectNotify)="contentViewService.onSelectVoiceTalentClick($event, content, 'talentPickerVisible')"
						></portal-select-box>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="showScript" style="left: 31%; width: 66%" class="absolute top85 z">
		<div style="max-height: 9.5rem" class="setWidth100 relative transparent-bg white-text pd8 border-radius scrollable ft-size18">
			<span class="pd8 ft-size16 absolute right0 topneg5 pointer"><i class="fa fa-times" aria-hidden="true"></i></span>
			<span>
				{{ content.Text }}
			</span>
		</div>
	</div>
</div>
