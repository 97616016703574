import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AppStateService } from '../../core/services/app-state.service';

@Injectable({
	providedIn: 'root'
})
export class SessionExpireService {
	public expireModalVisible: boolean;

	private timer: any;
	private hourTimer: any;

	constructor(private appStateService: AppStateService, private router: Router) {}

	public resetTimer(): void {
		if (sessionStorage.getItem('authToken') && this.appStateService.currentUser) {
			let expireTime: number;

			if (this.appStateService.currentUser.IsEmployee) {
				expireTime = 28800000; //8 hours 28800000
			} else {
				expireTime = 3500000; // 1 hour 3500000
			}
			//show alert after inactive for 1 hour
			clearTimeout(this.hourTimer);
			clearTimeout(this.timer);
			this.hourTimer = setTimeout(() => {
				this.expireModalVisible = true;
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					this.signOut();
				}, 60000); // 1 min 60000
			}, expireTime);
		}
	}

	public signOut(): void {
		clearTimeout(this.timer);
		sessionStorage.removeItem('authToken');
		sessionStorage.removeItem('validPin');
		this.router.navigate(['session-expired']);
		this.expireModalVisible = false;
		setTimeout(() => {
			location.reload();
		}, 1000);
	}
}
