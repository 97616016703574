import { Injectable } from '@angular/core';

import { Column, Table, TableConfig } from '../../shared/components/table/view-models';

@Injectable({
	providedIn: 'root'
})
export class TableConfigService {
	public columns: Column[] = [
		new Column('LogDate', 'Date', 'date').setIsSortable(true).setElementClassList('semi-bold').setIncludeTime(true).setWidth('5'),
		new Column('LogSubject', 'Subject', 'string').setIsSortable(true).setIsEditable(true).setWidth('35'),
		new Column('LogMessage', 'Notes', 'string').setIsSortable(true).setIsEditable(true).setWidth('35'),
		new Column('UserName', 'Author', 'string').setIsSortable(true).setWidth('5'),
		new Column('EntryType', 'Type', 'string').setIsSortable(true).setWidth('5'),
		new Column('EntrySource', 'Device', 'string').setIsSortable(true).setWidth('5'),
		new Column('Id', 'ID', 'string').setIsSortable(true).setWidth('5'),
		new Column('deleteBtn', 'far fa-trash-alt', 'button')
			.setElementClassList('white-bg red-text-light semi-bold ht40 pd-left14 pd-right14')
			.setShowIconOnly(true)
			.setWidth('5')
	];

	public table: Table = new Table(this.columns);

	public configuration: TableConfig = {
		itemsPerPage: 25,
		headerColor: 'green-bg',
		fixedRowHeight: true
	};
}
