import { Component, Input, OnInit, EventEmitter, Output, ElementRef, OnDestroy } from '@angular/core';
import { IMyDateModel } from 'angular-mydatepicker';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { MonthlyOption, RepeatingOption } from './_models';
import { ContentAdvancedSchedules } from '../../api-models/content';
import { DateTimeService, Events, MessageService, UtilitiesService } from '../../../core/services';
import { TimesOfDay, DatesOfMonth, MonthlyOptions, MonthsOfTheYear, RepeatingOptions, DaysOfTheWeek } from './advanced-scheduler-data';
import { AdvancedSchedulerService, ViewService } from './_services';
import { DateTimeError } from './_models/time-error.enum';

@Component({
	selector: 'portal-advanced-scheduler',
	templateUrl: './advanced-scheduler.component.html',
	styleUrls: ['./advanced-scheduler.component.scss']
})
export class AdvancedSchedulerComponent implements OnInit, OnDestroy {
	@Input() backgroundColor: string;
	@Input() editSchedule: ContentAdvancedSchedules;

	@Output() onScheduleChange: EventEmitter<ContentAdvancedSchedules> = new EventEmitter<ContentAdvancedSchedules>();

	public calendarElement: ElementRef;
	public currentDate = new Date();
	public databaseUrl: string;
	public datesOfMonth = DatesOfMonth;
	public dateWidth: number;
	public daysOfTheWeek = DaysOfTheWeek;
	public isScheduleDirty: boolean;
	public monthlyOptions = MonthlyOptions;

	public repeatingOptions = RepeatingOptions;
	public monthsOfTheYear = MonthsOfTheYear;
	public scheduleOptions: any[] = [{ name: '-- none --' }];
	public time: { name: string }[];
	private startTimeIndex: number = 32;
	private stopTimeIndex: number = 68;
	public timeError = DateTimeError;
	public dateError = DateTimeError;
	private startDateMoment: moment.Moment;
	private stopDateMoment: moment.Moment;

	private subscriptions: Subscription[] = [];

	constructor(
		public advancedSchedulerService: AdvancedSchedulerService,
		private dateTimeService: DateTimeService,
		private messageService: MessageService,
		public utilService: UtilitiesService,
		public viewService: ViewService
	) {
		this.notifyAdvancedSchedulerOfSaveSubscribe();
	}

	ngOnInit() {
		this.time = TimesOfDay.map((time) => {
			const obj: any = {};
			obj.name = time;
			return obj;
		});
		this.advancedSchedulerService.getTemplates();
		if (this.editSchedule) {
			this.advancedSchedulerService.modelScheduleToView(this.editSchedule);
			this.advancedSchedulerService.model = this.editSchedule;
			this.startDateMoment = moment(this.advancedSchedulerService.model.YearlyStartDate);
			this.stopDateMoment = moment(this.advancedSchedulerService.model.YearlyEndDate);
		} else {
			this.startDateMoment = moment(this.currentDate);
			this.stopDateMoment = moment(this.currentDate).add(1, 'M');
			// set default view values
			this.advancedSchedulerService.setDefaultValues();
		}
		this.emitAdvancedSchedule(false);
	}

	notifyAdvancedSchedulerOfSaveSubscribe(): void {
		this.subscriptions.push(
			this.messageService.subscribe(Events.notifyAdvancedSchedulerOfSave, () => {
				this.advancedSchedulerService.createTemplate();
			})
		);
	}

	public assignPreviouslySavedSchedule(template: ContentAdvancedSchedules): void {
		this.advancedSchedulerService.modelScheduleToView(template);
		this.advancedSchedulerService.model = Object.assign({}, template);
		delete this.advancedSchedulerService.model.Id;
		this.advancedSchedulerService.model.ScheduleName = null;
		this.advancedSchedulerService.view.ScheduleName = null;

		this.emitAdvancedSchedule(false);
	}

	public onTemplateDeleteClick(template: ContentAdvancedSchedules): void {
		const confirmOk: boolean = confirm(
			`Delete template "${template.ScheduleName}"? This will delete the template only and have no affect on this item's schedule.`
		);
		if (confirmOk) {
			this.messageService.publish(Events.savingPreloader, 1);
			this.advancedSchedulerService.deleteTemplate(template).subscribe(() => {
				const index: number = this.advancedSchedulerService.templates.findIndex((t) => t.Id === template.Id);
				this.advancedSchedulerService.templates.splice(index, 1);
				this.messageService.publish(Events.savingPreloader, 0);
			});
		}
	}

	public selectRepeatingFrequency(option: RepeatingOption): void {
		if (this.advancedSchedulerService.view.RecurrenceType !== option.name) {
			this.advancedSchedulerService.view.RecurrenceType = option.name;
			this.advancedSchedulerService.view.RecurrenceSubtype = 'Every Day';
			this.resetCheckboxOptions();
		}

		this.advancedSchedulerService.model.RecurrenceTypeId = option.RecurrenceTypeId;
		this.advancedSchedulerService.model.RecurrenceSubtypeId = option.RecurrenceSubtypeId;
		this.clearYearlyStartStopDates();

		if (option.name === 'Yearly') {
			this.advancedSchedulerService.model.YearlyStartDate = moment(this.currentDate).toISOString();
			this.advancedSchedulerService.model.YearlyEndDate = moment(this.currentDate).add(1, 'M').toISOString();

			const iMyDateModelStart = this.dateTimeService.iMyDatePickerDateInit(this.advancedSchedulerService.model.YearlyStartDate);
			const iMyDateModelEnd = this.dateTimeService.iMyDatePickerDateInit(this.advancedSchedulerService.model.YearlyEndDate);

			this.advancedSchedulerService.view.YearlyStartDate = iMyDateModelStart;
			this.advancedSchedulerService.view.YearlyEndDate = iMyDateModelEnd;
		}
		this.emitAdvancedSchedule(true);
	}

	public selectMonthlyFrequency(option: MonthlyOption): void {
		if (this.advancedSchedulerService.view.RecurrenceSubtype !== option.name) {
			this.advancedSchedulerService.view.RecurrenceSubtype = option.name;
			this.advancedSchedulerService.model.RecurrenceSubtypeId = option.RecurrenceSubtypeId;

			switch (this.advancedSchedulerService.view.RecurrenceSubtype) {
				case 'Every Day':
					this.advancedSchedulerService.view.DaysOfMonth = [];
					this.advancedSchedulerService.view.DaysOfWeek = [];
					break;
				case 'Weekdays':
					this.advancedSchedulerService.view.DaysOfMonth = [];
					break;
				case 'Days of Month':
					this.advancedSchedulerService.view.DaysOfWeek = [];
					break;
			}
		}
		this.emitAdvancedSchedule(true);
	}

	public addRemoveDayOfTheWeek(selectedDay: string): void {
		const dayFound = this.advancedSchedulerService.view.DaysOfWeek.find((day) => day === selectedDay);
		if (dayFound) {
			//removes day from array
			this.advancedSchedulerService.view.DaysOfWeek.splice(this.advancedSchedulerService.view.DaysOfWeek.indexOf(selectedDay), 1);
		} else {
			//adds day to array
			this.advancedSchedulerService.view.DaysOfWeek.push(selectedDay);
		}
		this.emitAdvancedSchedule(true);
	}

	public addRemoveMonthOfTheYear(selectedMonth: string): void {
		const monthFound = this.advancedSchedulerService.view.MonthsOfYear.find((month) => month === selectedMonth);
		if (monthFound) {
			this.advancedSchedulerService.view.MonthsOfYear.splice(this.advancedSchedulerService.view.MonthsOfYear.indexOf(selectedMonth), 1);
		} else {
			this.advancedSchedulerService.view.MonthsOfYear.push(selectedMonth);
		}
		this.emitAdvancedSchedule(true);
	}

	public addRemoveDateOfTheMonth(selectedDate: string): void {
		if (selectedDate === 'Last') {
			selectedDate = '99';
		}
		const dateFound = this.advancedSchedulerService.view.DaysOfMonth.find((date) => date === selectedDate);
		if (dateFound) {
			this.advancedSchedulerService.view.DaysOfMonth.splice(this.advancedSchedulerService.view.DaysOfMonth.indexOf(selectedDate), 1);
		} else {
			this.advancedSchedulerService.view.DaysOfMonth.push(selectedDate);
		}
		this.emitAdvancedSchedule(true);
	}

	public setStartingTime(startTime: { name: string }): void {
		this.startTimeIndex = this.time.findIndex((obj) => obj.name === startTime.name);
		this.advancedSchedulerService.view.RecurrenceStartTime = startTime.name;
		this.advancedSchedulerService.model.RecurrenceStartTime = this.dateTimeService.convertTimeViewToUtc(startTime.name);
		if (this.startTimeIndex >= this.stopTimeIndex) {
			this.advancedSchedulerService.timeError = this.timeError.START;
			this.emitAdvancedSchedule(true);
		} else {
			this.advancedSchedulerService.timeError = this.timeError.NONE;
			this.emitAdvancedSchedule(true);
		}
	}

	public setStopTime(stopTime: { name: string }): void {
		this.stopTimeIndex = this.time.findIndex((obj) => obj.name === stopTime.name);
		this.advancedSchedulerService.view.RecurrenceStopTime = stopTime.name;
		this.advancedSchedulerService.model.RecurrenceStopTime = this.dateTimeService.convertTimeViewToUtc(stopTime.name);
		if (this.startTimeIndex >= this.stopTimeIndex) {
			this.advancedSchedulerService.timeError = this.timeError.STOP;
			this.emitAdvancedSchedule(true);
		} else {
			this.advancedSchedulerService.timeError = this.timeError.NONE;
			this.emitAdvancedSchedule(true);
		}
	}

	public setYearlyStartDate(date: IMyDateModel): void {
		this.startDateMoment = moment(`${date.singleDate.date.month}/${date.singleDate.date.day}/${date.singleDate.date.year}`, 'MM/DD/YYYY');
		this.advancedSchedulerService.model.YearlyStartDate = this.dateTimeService.localToUtc(this.startDateMoment.format('YYYY-MM-DD'));
		this.advancedSchedulerService.view.YearlyStartDate = this.dateTimeService.iMyDatePickerDateInit(this.advancedSchedulerService.model.YearlyStartDate);
		if (this.datesValid()) {
			this.advancedSchedulerService.dateError = this.dateError.NONE;
			this.emitAdvancedSchedule(true);
		} else {
			this.advancedSchedulerService.dateError = this.dateError.START;
			this.emitAdvancedSchedule(true);
		}
	}

	public setYearlyEndDate(date: IMyDateModel): void {
		this.stopDateMoment = moment(`${date.singleDate.date.month}/${date.singleDate.date.day}/${date.singleDate.date.year}`, 'MM/DD/YYYY');
		this.advancedSchedulerService.model.YearlyEndDate = this.dateTimeService.localToUtc(this.stopDateMoment.format('YYYY-MM-DD'));
		this.advancedSchedulerService.view.YearlyEndDate = this.dateTimeService.iMyDatePickerDateInit(this.advancedSchedulerService.model.YearlyEndDate);
		if (this.datesValid()) {
			this.advancedSchedulerService.dateError = this.dateError.NONE;
			this.emitAdvancedSchedule(true);
		} else {
			this.advancedSchedulerService.dateError = this.dateError.STOP;
			this.emitAdvancedSchedule(true);
		}
	}

	private clearYearlyStartStopDates(): void {
		this.advancedSchedulerService.model.YearlyStartDate = null;
		this.advancedSchedulerService.model.YearlyEndDate = null;
	}

	public emitAdvancedSchedule(isScheduleDirty: boolean): void {
		this.isScheduleDirty = isScheduleDirty;
		this.onScheduleChange.emit(this.advancedSchedulerService.buildModel(this.advancedSchedulerService.model, this.advancedSchedulerService.view));
	}

	private resetCheckboxOptions(): void {
		this.advancedSchedulerService.view.DaysOfMonth = [];
		this.advancedSchedulerService.view.DaysOfWeek = [];
		this.advancedSchedulerService.view.MonthsOfYear = [];
		this.advancedSchedulerService.view.YearlyStartDate = null;
		this.advancedSchedulerService.view.YearlyEndDate = null;
	}

	private datesValid(): boolean {
		return this.stopDateMoment?.isAfter(this.startDateMoment);
	}

	ngOnDestroy() {
		this.advancedSchedulerService.saveAsTemplate = false;
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}
}
