import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';

import { AppStateService, BrowserDetectorService, Events, FileUploadService, MessageService, CacheService } from '../core/services';
import { environment } from '../../environments/environment';
import { Users } from '../shared/api-models/admin';
const URL = `${environment.uploadUrl}Transfer`;


@Component({
    selector: 'lft-large-file-transfer',
    templateUrl: './large-file-transfer.component.html',
    styleUrls: ['./large-file-transfer.component.scss']
})


export class LargeFileTransferComponent implements OnInit {

    @ViewChild('singleInput') singleInput: ElementRef;

    public notes: string = '';
    public uploader: FileUploader = new FileUploader({
        url: URL
    });
    public hasBaseDropZoneOver: boolean = false;
    public hasAnotherDropZoneOver: boolean = false;
    public lengthErrorVisible: boolean;
    public uploadAllClicked: boolean;
    public uploadSuccess: boolean;

    private csrEmail: string;
    private fileLinksArr: any[] = [];
    private uploadQueueCounter: number = 0;
    private subscriptions: Subscription[] = [];

    constructor(
        private appStateService: AppStateService,
        public browserDetectorService: BrowserDetectorService,
        private fileUploadService: FileUploadService,
        private httpClient: HttpClient,
        public location: Location,
        private messageService: MessageService,
        private cacheService: CacheService,) {
        this.uploadProgressCompleteSubscribe();
        this.uploadProgressSubscribe();
    }

    ngOnInit() {
        const getUser$ = this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${this.appStateService.currentClient.CsrId}`);
        this.cacheService.user$(getUser$, this.appStateService.currentClient.CsrId)
            .subscribe((csr: Users) => {
                this.csrEmail = csr.ContactInformation.Email;
                this.cacheService.setUserCache(csr);
            })
    }

    uploadProgressSubscribe(): void {
        this.subscriptions.push(this.messageService.subscribe(Events.uploadProgress, (payload: any[]) => {
            const event = payload[0];
            this.fileInProgressOrComplete().progress = Math.round(100 * event.loaded / event.total);
        }));
    }

    uploadProgressCompleteSubscribe(): void {
        this.subscriptions.push(this.messageService.subscribe(Events.uploadComplete, (event) => {

            //if more than one file to upload
            if (this.uploader.queue) {
                const uploadedItem = this.fileInProgressOrComplete();
                uploadedItem.isUploaded = true;
                //add link to array so we can add all links to 1 email to CSR
                this.fileLinksArr.push(event.body.UncPath);

                this.uploadQueueCounter++;
                //keep loading files until all have been uploaded
                if (this.uploadQueueCounter < this.uploader.queue.length) {
                    this.uploadFile(this.uploader.queue[this.uploadQueueCounter].file.rawFile);
                } else {
                    //send email when all files have completed
                    this.sendEmailToCSR();
                    this.uploadSuccess = true;
                }
            }
        }));
    }


    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    public onCancelAllClick(): void {
        this.uploader.cancelAll();
        //unsubscribe to post in api service
        this.messageService.publish(Events.abortUpload, null);
        this.uploader.queue.forEach((item) => {
            item.progress = 0;
        })
        this.uploadAllClicked = false;
    }

    public onChange(): void {
        this.validateQueueLength();
        this.assignIndex();
    }

    public onDrop(): void {
        this.validateQueueLength();
        this.assignIndex();
    }

    public onItemRemoveClick(item: any): void {
        this.uploader.queue.splice(item.index, 1);
        this.assignIndex();
        this.lengthErrorVisible = false;
    }

    public onRemoveAllClick(): void {
        if (this.uploader.queue) {
            this.uploader.clearQueue();
            this.lengthErrorVisible = false;
            this.singleInput.nativeElement.value = '';
        }
    }

    public onUploadAllClick(): void {
        if (this.uploader.queue) {
            if (this.uploader.queue.length > 0) {
                this.uploadAllClicked = true;
                this.uploadFile(this.uploader.queue[0].file.rawFile);
            }
        }
    }

    public showProgressBar(item: any): boolean {
        return item.index === this.uploadQueueCounter && this.uploadAllClicked || item.isUploaded;
    }

    private uploadFile(fileToUpload: any) {
        const formData: FormData = new FormData();

        formData.append('file', fileToUpload);
        this.fileUploadService.doProgressRequest(environment.uploadUrl + 'Transfer', formData, "POST");
    }

    public uploadMoreFilesClick(): void {
        this.uploadSuccess = false;
        this.uploader.queue.length = 0;
        this.uploadAllClicked = false;
        this.uploadQueueCounter = 0;
        this.singleInput.nativeElement.value = '';
    }

    private assignIndex(): void {
        //assign index to each item
        this.uploader.queue.forEach((item, index) => {
            item.index = index;
        });
    }

    private fileInProgressOrComplete(): any {
        return this.uploader.queue.find(item => item.index === this.uploadQueueCounter);
    }

    private emailPayload(): any {
        const obj = {
            To: this.csrEmail,
            From: 'largefiletransfer@works24.com',
            Subject: this.appStateService.currentClient.Name + ` Uploaded a Large File`,
            Body: this.emailBody()
        }
        return obj;
    }

    private emailBody(): string {
        const htmlString = `
       ${this.appStateService.currentUser.FriendlyName}, with ${this.appStateService.currentClient.Name}, has uploaded a large file for the creative team.
       Files:
       ${this.linksToUploadedFiles()}
       Notes:
       ${this.notes}
     `
        return htmlString;
    }

    private linksToUploadedFiles(): string {
        let fileLinks: string = '';
        if (this.fileLinksArr) {
            this.fileLinksArr.forEach((link) => {
                fileLinks = fileLinks.concat(`${link}\n`)
            });
        }
        return fileLinks;
    }

    private sendEmailToCSR(): void {
        this.httpClient.post(environment.adminUrl + 'Email', this.emailPayload())
            .subscribe(() => { });
    }

    private validateQueueLength(): void {
        if (this.uploader.queue.length > 5) {
            this.uploader.queue.length = 5;
            this.lengthErrorVisible = true;
        } else {
            this.lengthErrorVisible = false;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
