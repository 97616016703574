import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { PreviewModel } from './preview-model';
import { ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppStateService, UtilitiesService } from '../../../core/services';
import { ContentItem } from '../../view-models/content';
import { ContentViewService } from 'src/app/shared/components/content-container/content/_services';
import { GetPlaylistDataService } from '../../../shared/services';
import { PbPlaylist, SavedPlaylists } from '../../../shared/view-models/content';
import { PlaylistService } from '../../../playlist-builder/_services';

@Component({
	selector: 'portal-video-playlist-previewer',
	templateUrl: './video-playlist-previewer.component.html',
	styleUrls: ['./video-playlist-previewer.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class VideoPlaylistPreviewerComponent implements OnInit {
	@Input() alignLeft: boolean;
	@Input() isSmall: boolean;
	@Input() playerHeight: number;
	@Input() playerWidth: number;
	@Input() playlistBuilderVisible: boolean;
	@Input() notSetManualWidth: boolean;

	@Output() onExitClickNotify: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('fullPreviewVideo') fullPreviewVideo: ElementRef;

	public clockTimer = null;
	public previewModel: PreviewModel;
	public fadeAnimation: string;
	public loading: boolean;
	public maxVideoHeight: number;
	public mobileNavControlsVisible: boolean;
	public playlist: PbPlaylist | SavedPlaylists;

	private elapsedTime: number;
	private subscription: Subscription[] = [];

	constructor(
		public appStateService: AppStateService,
		public contentViewService: ContentViewService,
		private getPlaylistDataService: GetPlaylistDataService,
		private playlistService: PlaylistService,
		public utilService: UtilitiesService
	) {}

	ngOnInit() {
		this.maxVideoHeight = this.appStateService.product.Route === 'poster' ? null : 745;
		if (this.playlistService.playlist) {
			this.setPlaylistAndPlay(this.playlistService.playlist);
		} else {
			this.loading = true;
			let playlistId: number = JSON.parse(this.appStateService.getSessionItem('playlistId'));
			this.getPlaylistDataService.getCompletePlaylist(playlistId).subscribe((playlist: PbPlaylist) => {
				this.setPlaylistAndPlay(playlist);
				this.loading = false;
			});
		}
	}

	private setPlaylistAndPlay(playlist: PbPlaylist): void {
		this.playlist = playlist;
		this.playlist.contentItems.forEach((contentItem) => {
			this.contentViewService.setC24Props(contentItem);
		});

		this.previewModel = new PreviewModel(this.playlist.contentItems.length);
		this.playPlaylist();
	}

	onPlay(clip) {
		this.contentViewService.videoPlaying = true;
		if (this.elapsedTime <= 1) {
			//Prevent svg from reinitializing on pause
			this.contentViewService.setAndSanitizeSvg(clip);
		}
	}

	/****************************************
        PLAYER CONTROLS
    ****************************************/

	public backClicked(): void {
		this.contentViewService.c24SVG = null;
		this.clearElapsedTime();
		if (this.previewModel.isPaused) {
			this.previewModel.isPaused = false;
			this.clockTimer = setInterval(this.updatePlayClock, 1000);
		}
		this.previewModel.currentItem -= 2;
		this.playNextItemOnEnd();
	}

	public skipClicked(): void {
		this.contentViewService.c24SVG = null;
		this.clearElapsedTime();
		if (this.previewModel.isPaused) {
			this.previewModel.isPaused = false;
			this.clockTimer = setInterval(this.updatePlayClock, 1000);
		}
		this.playNextItemOnEnd();
	}

	public muteClicked(): void {
		this.previewModel.isMuted = !this.previewModel.isMuted;
		const video: HTMLVideoElement = <HTMLVideoElement>document.getElementById('fullPreviewVideo');
		if (video != null) {
			video.muted = this.previewModel.isMuted;
		}
	}

	public pauseClicked(): void {
		let svg: any = document.getElementById('c24Svg');
		this.previewModel.isPaused = !this.previewModel.isPaused;
		let video: HTMLVideoElement = null;
		if (this.fullPreviewVideo != null && this.fullPreviewVideo.nativeElement != null) {
			video = <HTMLVideoElement>this.fullPreviewVideo.nativeElement;
		}
		if (this.previewModel.isPaused) {
			clearInterval(this.clockTimer);
			if (video != null) {
				video.pause();
				if (svg) {
					svg.pauseAnimations();
				}
			}
		} else {
			this.clockTimer = setInterval(this.updatePlayClock, 1000);
			if (video != null) {
				video.play();
				if (svg) {
					svg.unpauseAnimations();
				}
			}
		}
	}

	public maxWidthHeight(): number {
		if (this.isSmall) {
			return 420;
		} else {
			return 720;
		}
	}

	public setWidth(): number {
		return this.utilService.calculateAspectRatioFit(
			this.appStateService.product.WidthPx,
			this.appStateService.product.HeightPx,
			this.maxWidthHeight(),
			this.maxWidthHeight()
		).width;
	}

	public setHeight(): number {
		return this.utilService.calculateAspectRatioFit(
			this.appStateService.product.WidthPx,
			this.appStateService.product.HeightPx,
			this.maxWidthHeight(),
			this.maxWidthHeight()
		).height;
	}

	public heightWidth(): string {
		return this.appStateService.product.Route !== 'poster' ? 'setWidth100 setHeight100' : null;
	}

	public onExitClick(): void {
		this.onExitClickNotify.emit();
	}

	public onMouseLeave(): void {
		this.fadeAnimation = 'fadeOut';

		let mobileNavHidden = () => {
			this.mobileNavControlsVisible = false;
		};
		//set mobileNavControlsVisible to false after fadeOut runs
		setTimeout(mobileNavHidden, 500);
	}

	public onMouseOver(): void {
		this.fadeAnimation = 'fadeIn';
		this.mobileNavControlsVisible = true;
	}

	public onVideoError = (event) => {
		console.log('Video Playback Error:');
		console.log(event);
		this.playNextItemOnEnd();
	};

	public playNextItemOnEnd(): void {
		this.clearElapsedTime();

		if (this.previewModel.currentItem >= this.previewModel.totalItems) {
			this.previewModel.currentItem = 0;
		}
		if (this.previewModel.currentItem < 0) {
			this.previewModel.currentItem = this.previewModel.totalItems - 1;
		}
		const newClip = this.playlist.contentItems[this.previewModel.currentItem];
		this.setClipName(newClip.contentName, this.previewModel.currentItem, this.previewModel.totalItems);

		this.previewModel.currentClip = newClip;

		this.previewModel.currentTime = 0;
		if (this.clipIsImage()) {
			this.previewModel.totalTime = this.previewModel.currentClip.duration;
		} else {
			this.previewModel.totalTime = 0;
		}

		this.previewModel.currentItem++;
	}

	private clearElapsedTime = () => {
		this.previewModel.timeLabel = '';
		this.elapsedTime = 0;
	};

	private containsAtLeastOneChannel(contentItems: ContentItem[]): boolean {
		return contentItems.some((item) => {
			return item.isChannel;
		});
	}

	private playPlaylist = () => {
		this.previewModel.totalItems = this.playlist.contentItems.length;
		if (this.previewModel.totalItems > 0) {
			this.playNextItemOnEnd();
			this.clockTimer = setInterval(this.updatePlayClock, 1000);
		}
	};

	private setClipName = (name: string, current: number, total: number) => {
		if (!name) {
			this.previewModel.nameLabel = current + 1 + '/' + total + ': Name Empty/Not Found';
		} else {
			this.previewModel.nameLabel = current + 1 + '/' + total + ': ' + name; //.substr(name.indexOf(' ') + 1)
		}
	};

	private setElapsedTime = (current: number, total: number) => {
		if (isNaN(current) || isNaN(total)) {
			this.clearElapsedTime();
			return;
		}
		this.elapsedTime = current;
		this.previewModel.timeLabel = this.utilService.formatTime(Math.floor(current)) + '/' + this.utilService.formatTime(Math.floor(total));
	};

	private setRandomItemViewModel(randomItem: ContentItem, contentItem: ContentItem[]): ContentItem {
		randomItem.previewFileName = contentItem[0].previewFileName;
		randomItem.previewUrl = contentItem[0].previewUrl;
		randomItem.contentFileId = contentItem[0].contentFileId;
		randomItem.contentName = randomItem.name;
		return randomItem;
	}

	private updatePlayClock = () => {
		if (this.previewModel.isPaused) {
			return;
		}
		const video: HTMLVideoElement = <HTMLVideoElement>document.getElementById('fullPreviewVideo');

		if (this.clipIsVideo()) {
			if (video) {
				this.setElapsedTime(video.currentTime, video.duration);
			}
		} else if (this.clipIsImage()) {
			this.previewModel.currentTime++;
			this.setElapsedTime(this.previewModel.currentTime, this.previewModel.totalTime / 1000);

			if (this.previewModel.currentTime >= this.previewModel.totalTime / 1000) {
				this.clearElapsedTime();
				this.playNextItemOnEnd();
			}
		} else {
			this.clearElapsedTime();
		}
	};

	private clipIsImage(): boolean {
		return this.utilService.includes(this.previewModel.currentClip.contentType, 'image/png');
	}

	private clipIsVideo(): boolean {
		return this.utilService.includes(this.previewModel.currentClip.contentType, 'video/mp4');
	}

	ngOnDestroy() {
		this.appStateService.removeSessionItem('playlistId');
		this.subscription.forEach((sub) => {
			sub.unsubscribe();
		});
		clearInterval(this.clockTimer);
	}
}
