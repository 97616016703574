export class Tickets {
    Id: number;
	TicketTypeId: number;
    TicketStateId: number;
    TicketStateChanged: string;
	PlayerModelId: number;
	NotifyList: string;
	ClientId: number;
	ClientString: string;
	LastTicketActionDate: string;
	LastTicketActionBy: number;
	LocationId: number;
	LocationString: string;
	PrimaryContact: string;
	Title: string;
	Description: string;
	CreatedDate: string;
	CreatedBy: number;
	LastUpdate: string;
	LastUpdateBy: number;
	AssignedToUserId: number;
	IsComplete: boolean;
	CompletedDate: string;
	CompletedBy: number;
    VideoUnitsRequired: number;
    VideoUnitsShipped: number;
	IsHighPriority: boolean;
	ReminderTime: string;
	LocationComplete: boolean;
	PostponeUntil: string;
	PostponeNote: string;
	PostponeNotificationSent: boolean;
	FormsReceived: boolean;
	WalkthroughComplete: boolean;
	IsEquipmentShipped: boolean;
}