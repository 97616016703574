import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UsageReportsStore } from '../usage-reports.store';

@Component({
	selector: 'ur-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
	constructor(private activatedRoute: ActivatedRoute, private usageReportsStore: UsageReportsStore) {}

	ngOnInit(): void {
		const libraryIds: any = this.activatedRoute.snapshot.paramMap.get('libraryIds');
		const libIdArr = libraryIds.split(',').map((id) => {
			return parseInt(id, 10);
		});
		this.usageReportsStore.setLibraryIdsFromUrl(libIdArr);
		this.usageReportsStore.getReport$();
		this.usageReportsStore.getCategories$();
		this.usageReportsStore.setShowBackBtn(true);
	}
}
