import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AppStateService, DateTimeService, Events, MessageService, UtilitiesService } from '../../core/services';
import { Column } from '../../shared/components/table/view-models';
import { environment } from '../../../environments/environment';
import { Feeds } from '../../shared/api-models/content';
import { FeedItemsView } from './_models/feed-items-view';
import { NewsManagerService, TableConfigService } from './_services';

type FeedBadWordList = { Id: number; BadWord: string };
type FeedSource = { name: string; id: number; feedSourceTypeId: number };

@Component({
	selector: 'app-news-manager',
	templateUrl: './news-manager.component.html',
	styleUrls: ['./news-manager.component.scss'],
	providers: [NewsManagerService, TableConfigService]
})
export class NewsManagerComponent implements OnInit, OnDestroy {
	public badWordListVisible: boolean;
	public createEditStoryVisible: boolean;
	public newsManagerData: FeedItemsView[] = [];
	public feedBadWordList: any[];
	public feeds: Feeds[];
	public feedSources: FeedSource[];
	public getStartedTextVisible: boolean = true;

	private searchTermChanged: Subject<string> = new Subject<string>();
	private subscriptions: Subscription[] = [];

	constructor(
		private appStateService: AppStateService,
		private dateTimeService: DateTimeService,
		private messageService: MessageService,
		public newsManagerService: NewsManagerService,
		private router: Router,
		public tableConfigService: TableConfigService,
		private utilService: UtilitiesService
	) {
		this.onColumnSortSubscribe();

		this.searchTermChanged.pipe(debounceTime(400)).subscribe((val) => {
			this.getFeedItems();
		});
	}

	ngOnInit() {
		this.getBadWordList();
		this.getFeeds();
		this.newsManagerService.viewState.pageNumber = 1;

		if (this.appStateService.getSessionItem('news-manager-selected-feed')) {
			this.newsManagerService.viewState.selectedFeed = JSON.parse(this.appStateService.getSessionItem('news-manager-selected-feed'));
			this.getStartedTextVisible = false;
			this.getFeedItems();
		}
	}

	onColumnSortSubscribe(): void {
		this.subscriptions.push(
			this.messageService.subscribe(Events.onColumnSort, (sortParams) => {
				this.newsManagerService.viewState.orderByCol = sortParams.key;
				sortParams.order === 'asc' ? (this.newsManagerService.viewState.orderDesc = false) : (this.newsManagerService.viewState.orderDesc = true);
				this.getFeedItems();
			})
		);
	}

	/************************************
      BAD WORD LIST
     ***********************************/

	public onAddBadWordClick(): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.newsManagerService.postBadWord().subscribe(() => {
			this.getBadWordList();
			this.messageService.publish(Events.savingPreloader, 0);
		});
		this.newsManagerService.newBadWord = '';
	}

	public onDeleteBadWordClick(selectedBadWord: FeedBadWordList): void {
		const confirmOk: boolean = confirm(`Delete bad word ${selectedBadWord.BadWord}?`);
		if (confirmOk) {
			this.messageService.publish(Events.savingPreloader, 1);
			this.newsManagerService.delete(`${environment.contentUrl}FeedBadWordList/${selectedBadWord.Id}`).subscribe(() => {
				this.getBadWordList();
				this.messageService.publish(Events.savingPreloader, 0);
			});
		}
	}

	private getBadWordList(): void {
		this.newsManagerService.get(`${environment.contentUrl}FeedBadWordList`).subscribe((feedBadWordList: FeedBadWordList[]) => {
			this.feedBadWordList = this.utilService.sortItems(feedBadWordList, 'BadWord');
		});
	}

	/************************************
      FEEDS
     ***********************************/

	getPage(pageNumber: number): void {
		this.utilService.scrollToTop();
		this.newsManagerService.viewState.pageNumber = pageNumber;
		this.getFeedItems();
	}

	onTableButtonClick(btnEvent: any): void {
		const column: Column = btnEvent[0];
		const selectedFeedItem: FeedItemsView = btnEvent[1];
		if (column.key === 'editBtn') {
			this.appStateService.setSessionItem('news-manager-selected-feed', JSON.stringify(this.newsManagerService.viewState.selectedFeed));
			this.router.navigate([`/news-manager/edit-feed-item/${this.newsManagerService.viewState.selectedFeed.Id}/${selectedFeedItem.Id}`]);
		}
		if (column.key === 'disableBtn') {
			this.enableDisableFeedItem(selectedFeedItem);
		}
	}

	public onDropdownSelect(feed: Feeds): void {
		this.getStartedTextVisible = false;
		this.newsManagerService.viewState.selectedFeed = feed;
		this.getFeedItems();
	}

	public onNewStoryClick(): void {
		this.router.navigate([`news-manager/new-feed-item`]);
	}

	public onSearchKeyup(): void {
		this.newsManagerService.viewState.pageNumber = 1;
		this.searchTermChanged.next(this.newsManagerService.viewState.searchTerm);
	}

	public onSearchClear(): void {
		this.newsManagerService.viewState.searchTerm = '';
		this.getFeedItems();
	}

	private enableDisableFeedItem(selectedFeedItem: FeedItemsView): void {
		selectedFeedItem.IsEnabled
			? this.updateFeedItemRowProps(selectedFeedItem, false, 'Enable', 'purple-bg-light-theme', 'fa-check')
			: this.updateFeedItemRowProps(selectedFeedItem, true, 'Disable', '', '');

		//table wouldn't update without replacing feedItem with selectedFeedItem
		const index: number = this.newsManagerData.findIndex((feedItem) => feedItem.Id === selectedFeedItem.Id);
		this.newsManagerData.splice(index, 1, selectedFeedItem);
		this.messageService.publish(Events.detectTableChanges, this.newsManagerData);
		this.messageService.publish(Events.savingPreloader, 1);
		this.updateFeedItem(selectedFeedItem);
	}

	private updateFeedItemRowProps(selectedFeedItem: FeedItemsView, isEnabled: boolean, disableBtn: string, rowBgColor: string, disabledIcon: string): void {
		selectedFeedItem.IsEnabled = isEnabled;
		selectedFeedItem.disableBtn = disableBtn;
		selectedFeedItem.rowBgColor = rowBgColor;
		selectedFeedItem.disabledIcon = disabledIcon;
	}

	private updateFeedItem(selectedFeedItem: FeedItemsView): void {
		this.newsManagerService.put(`${environment.contentUrl}FeedItems/${selectedFeedItem.Id}`, selectedFeedItem).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}

	private getFeeds(): void {
		this.newsManagerService.get(`${environment.contentUrl}Feeds/NewsManager`).subscribe((feeds: Feeds[]) => {
			this.feeds = feeds;
		});
	}

	private getFeedItems(): void {
		this.newsManagerService.getFeedItems().subscribe((feedItems: FeedItemsView[]) => {
			this.newsManagerData = feedItems;
		});
	}

	ngOnDestroy() {
		if (!this.utilService.includes(this.router.url, 'news-manager')) {
			this.appStateService.removeSessionItem('news-manager-selected-feed');
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}
}
