import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ColorPickerService {

    public hex: string;

    constructor() { }

    public onCanvasClick(e, canvasElement, videoPlayerElement): void {
        var x = this.getMousePos(e, canvasElement).x;
        var y = this.getMousePos(e, canvasElement).y;
        var p = canvasElement.getContext('2d').getImageData(x, y, videoPlayerElement.width, videoPlayerElement.height).data;
        var hex = "#" + ("000000" + this.rgbToHex(p[0], p[1], p[2])).slice(-6);
        this.hex = hex;
    }

    //https://stackoverflow.com/questions/18850868/how-to-get-the-context-x-and-y-position-within-a-canvas-html5
    private getMousePos(e, canvasElement) {
        /// getBoundingClientRect is supported in most browsers and gives you
        /// the absolute geometry of an element
        var rect = canvasElement.getBoundingClientRect();

        /// as mouse event coords are relative to document you need to
        /// subtract the element's left and top position:
        return { x: e.clientX - rect.left, y: e.clientY - rect.top };
    }

    private rgbToHex(r, g, b) {
        if (r > 255 || g > 255 || b > 255)
            throw "Invalid color component";
        return ((r << 16) | (g << 8) | b).toString(16);
    }
}
