// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	screenLayoutsActive: true,
	logosActive: true,
	adminUrl: 'https://admin-dev.api.works24.com/',
	apiUrl: 'https://portal-dev.works24.com/',
	authUrl: 'https://auth-dev.api.works24.com/',
	contentUrl: 'https://content-dev.api.works24.com/',
	deliveryUrl: 'https://delivery-dev.api.works24.com/',
	uploadUrl: 'https://upload-dev.api.works24.com/'
};

// export const environment = {
// 	production: false,
// 	screenLayoutsActive: true,
// 	logosActive: true,
// 	adminUrl: 'https://admin.api.works24.com/',
// 	apiUrl: 'https://portal.works24.com/',
// 	authUrl: 'https://auth.api.works24.com/',
// 	contentUrl: 'https://content.api.works24.com/',
// 	deliveryUrl: 'https://delivery.api.works24.com/',
// 	uploadUrl: 'https://upload.api.works24.com/'
// };

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
