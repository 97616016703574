import { Injectable } from '@angular/core';

import { Column, Table, TableConfig } from '../../../../shared/components/table/view-models';

@Injectable({
    providedIn: 'root'
})
export class TableService {



    public columns: Column[] = [
        new Column('LogDate', 'Date', 'date', null, 'semi-bold'),
        new Column('ErrorName', 'Name', 'string'),
        new Column('detailMessage', 'Message', 'string'),
        new Column('sendNotificationEmail', 'Send Notification Email', 'string'),
        new Column('SystemInfo', 'System Info', 'string'),
        new Column('Id', 'ID', 'string'),
    ];

    public table: Table = new Table(this.columns)

    public config: TableConfig = {
        itemsPerPage: 15,
        headerColor: 'dark-bg',
        headerFontSize: 'ft-size12'
    }
}
