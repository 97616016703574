import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppStateService, UtilitiesService } from '../../../core/services';
import { environment } from '../../../../environments/environment';
import { ServiceLocations } from '../../../shared/api-models/admin';
import { Users } from '../../../shared/api-models/admin';

@Injectable({
	providedIn: 'root'
})
export class CreateEditLocationService {
	constructor(private appStateService: AppStateService, private httpClient: HttpClient, private utilService: UtilitiesService) {}

	public getLocation(locationId: number): Observable<ServiceLocations[]> {
		return this.httpClient.get<ServiceLocations[]>(`${environment.adminUrl}CoreServiceLocations/${locationId}`);
	}

	public getUsersByClient(): Observable<Users[]> {
		return this.httpClient.get<Users[]>(`${environment.adminUrl}CoreUsers/GetUsersByClientId/${this.appStateService.currentClient.Id}`).pipe(
			map((users: Users[]) => {
				const filteredUsers: Users[] = users
					.filter((user) => user.ContactInformation && !user.IsDeleted)
					.map((user) => {
						user.name = `${user.ContactInformation.FirstName} ${user.ContactInformation.LastName} (${user.ContactInformation.Email})`;
						return user;
					});
				return this.utilService.sortItems(filteredUsers, 'name');
			})
		);
	}

	public postLocation(location: ServiceLocations): Observable<any> {
		return this.httpClient.post(`${environment.adminUrl}CoreServiceLocations`, location);
	}

	public updateLocation(location: ServiceLocations): Observable<any> {
		return this.httpClient.put(`${environment.adminUrl}CoreServiceLocations/${location.Id}`, location);
	}
}
