import { Component, ElementRef, Input, DoCheck, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { CanvasService } from '../../../canvas/_services';
import { LayerDetailService } from '../../_services/layer-detail.service';
import { LayersService } from '../../../_services';
import { LtwEventsService } from '../../../timeline/layers-timeline-wrapper/_services';
import { ColorPickerService, UndoRedoService, VideoControlsService } from '../../../_services';
import { UtilitiesService } from 'src/app/core/services';

@Component({
	selector: 's-color-select',
	templateUrl: './color-select.component.html',
	styleUrls: ['./color-select.component.scss']
})
export class ColorSelectComponent implements DoCheck, OnInit, OnDestroy {
	@Input() isDisabled: boolean;
	@Input() title: string;
	@Input() objectProp: 'fill' | 'stroke';

	@ViewChild('eyedropper', { static: false }) eyedropper: ElementRef;
	@ViewChild('eyedropperBtn', { static: false }) eyedropperBtn: ElementRef;
	@ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef;
	@ViewChild('eyedropperContainer') eyedropperContainer: ElementRef;

	public colorPickerOpen: boolean;
	public eyedropperOpen: boolean;
	public eyedropperPositionTop: number;
	private eyedropperPositionTopInit: number; //initial value of the container
	public showPresets: boolean;
	public showSavePresets: boolean;

	private scrollValue: number;
	private subs: Subscription[] = [];

	public url: string;

	constructor(
		public canvasService: CanvasService,
		private colorPickerService: ColorPickerService,
		public layerDetailService: LayerDetailService,
		public layersService: LayersService,
		private ltwEventsService: LtwEventsService,
		private undoRedoService: UndoRedoService,
		public utilService: UtilitiesService,
		private videoControlsService: VideoControlsService
	) {
		this.onButtonClick();
		this.onLayerDetailScroll();
		this.onTimelineCanvasClick();
		this.onVideoControlsClick();
	}

	ngOnInit(): void {}

	ngDoCheck() {
		if (this.videoControlsService.videoPlayer && this.eyedropper) {
			this.drawImage();
		}
	}

	onTimelineCanvasClick(): void {
		this.subs.push(
			this.ltwEventsService.onCanvasClick$.subscribe(() => {
				this.drawImage();
			})
		);
	}

	public color(): string {
		if (this.objectProp === 'fill') {
			if (this.layersService.activeLayer.canvasObj.fill === 'transparent') {
				return this.layersService.activeLayer.canvasObj.fillView;
			}
			return this.layersService.activeLayer.canvasObj[this.objectProp];
		}
		return this.layersService.activeLayer.canvasObj[this.objectProp];
	}

	/**
	 * @param payload[0] clicked button
	 * @param payload[1] component title
	 */
	onButtonClick(): void {
		this.subs.push(
			this.layerDetailService.onButtonClick$.subscribe((payload: [string, string]) => {
				this.layerDetailService.buttons.forEach((showBtnOptionsName: string) => {
					let clickedBtn: string = payload[0];
					let componentTitle: string = payload[1];
					if (clickedBtn !== showBtnOptionsName) {
						//Close all button options that are not the clicked option
						this[showBtnOptionsName] = false;
					}
					if (clickedBtn === showBtnOptionsName && this.title !== componentTitle) {
						this[showBtnOptionsName] = false;
					}
				});
			})
		);
	}

	//Absolute position divs don't scroll with scrolling div (outer section scrolls)
	//Adjust top position as we scroll to maintain position relationship with button
	public onLayerDetailScroll(): void {
		this.subs.push(
			this.layerDetailService.layerDetailScroll$.subscribe((value) => {
				this.scrollValue = value;
				this.eyedropperPositionTop = this.eyedropperPositionTopInit - value;
			})
		);
	}

	/**
	 * COLOR PICKER
	 */

	public onColorChange(e): void {
		let hex: string = e.color.hex;
		if (this.objectProp === 'fill') {
			this.layersService.activeLayer.canvasObj.fillView = hex;
		}
		this.layerDetailService.updateProperty(this.objectProp, hex);
		this.layerDetailService.updateFeedTextImage();

		if (this.layersService.activeLayer.canvasObj.borderOnly) {
			this.layerDetailService.updateProperty('fill', 'transparent');
		}
	}

	/**
	 * EYEDROPPER
	 */

	public onEyedropperBtnClick(): void {
		this.eyedropperOpen = true;
		setTimeout(() => {
			this.eyedropperPositionTopInit = this.eyedropperBtn.nativeElement.offsetTop + 40;
			this.eyedropperPositionTop = this.eyedropperPositionTopInit - this.scrollValue;
		}, 5);
		this.layerDetailService.onButtonClick(['eyedropperOpen', this.title]);
	}

	public onEyedropperImageClick(e): void {
		this.colorPickerService.onCanvasClick(e, this.eyedropper.nativeElement, this.videoPlayer.nativeElement);
		this.layerDetailService.updateProperty(this.objectProp, this.colorPickerService.hex);
		this.layerDetailService.updateFeedTextImage();
	}

	public onSaveAsPresetClick(): void {
		this.showSavePresets = !this.showSavePresets;
		//Closes all other open button options
		this.layerDetailService.onButtonClick(['showSavePresets', this.title]);
	}

	public onColorPickerClick(): void {
		this.colorPickerOpen = !this.colorPickerOpen;
		//Closes all other open button options
		this.layerDetailService.onButtonClick(['colorPickerOpen', this.title]);
	}

	public onPresetsClick(): void {
		this.showPresets = !this.showPresets;
		//Closes all other open button options
		this.layerDetailService.onButtonClick(['showPresets', this.title]);
	}

	public onSaveClick(): void {
		this.colorPickerOpen = false;
		this.eyedropperOpen = false;
		this.layersService.activeLayer.canvasObj[this.objectProp] = this.layerDetailService.activeObj()[this.objectProp];
		this.layersService.activeLayer.canvasObj.opacity = this.layerDetailService.activeObj().opacity;
		this.undoRedoService.recordState(`Change ${this.objectProp} color`);
	}

	public onCancelClick(): void {
		this.colorPickerOpen = false;
		this.eyedropperOpen = false;
		this.eyedropperPositionTop = null;
		this.layerDetailService.updateProperty(this.objectProp, this.layersService.activeLayer.canvasObj[this.objectProp]);
		this.layerDetailService.updateProperty('opacity', this.layersService.activeLayer.canvasObj.opacity);
		this.layerDetailService.updateFeedTextImage();
	}

	onVideoControlsClick(): void {
		this.subs.push(
			this.videoControlsService.controlClicked$.subscribe((button: string) => {
				if (button === 'play') {
					if (!this.videoControlsService.isPlaying) {
						this.drawImage();
					}
				}
			})
		);
	}

	private drawImage(): void {
		this.eyedropper.nativeElement
			.getContext('2d')
			.drawImage(this.videoControlsService.videoPlayer.nativeElement, 0, 0, this.videoPlayer.nativeElement.width, this.videoPlayer.nativeElement.height);
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
