import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ContentSerializerService } from '../../../core/services';
import { ContentFiles } from '../../api-models/content';
import { ContentFileState } from '../../api-models/content/content-file-state.enum';
import { ContentItem } from '../../view-models/content';
import { ContentFilesVM } from '../content-container/content/_models';
import { ContentVM } from '../content-container/content/_models/content-view';

@Component({
	selector: 'portal-select-box',
	templateUrl: './select-box.component.html',
	styleUrls: ['./select-box.component.scss']
})
export class SelectBoxComponent implements OnInit {
	@Input() content: ContentVM;
	@Input() contentItem: ContentItem;
	@Input() isAudioPreviewPicker: boolean;
	@Input() textColorClass: string;
	@Input() title: string;
	@Input() width: string;

	@Output() onPickerExitNotify: EventEmitter<ContentFiles> = new EventEmitter<ContentFiles>();
	@Output() onSelectNotify: EventEmitter<ContentFilesVM> = new EventEmitter<ContentFilesVM>();

	public audioPreviewVisible: boolean;
	readonly contentFileState = ContentFileState;

	constructor(private contentSerializerService: ContentSerializerService) {}

	ngOnInit() {
		if (this.content) {
			this.contentItem = this.contentSerializerService.contentToContentItem(this.content);
		}
	}

	public onAudioPreviewExit(): void {
		this.audioPreviewVisible = false;
	}

	public onItemSelect(selectedContentFile: ContentFilesVM): void {
		this.onSelectNotify.emit(selectedContentFile);
	}

	public isContentFilesHold(file: ContentFilesVM): file is ContentFilesVM {
		return (file as ContentFilesVM).VoiceTalent !== undefined;
	}
}
