import { Injectable } from '@angular/core';

import { ContentFilesVM } from '../_models';
import { ContentItem } from '../../../../view-models/content';
import { AppStateService, ContentSerializerService } from '../../../../../core/services';
import { ContentViewStateService } from 'src/app/shared/services';
import { PlaylistService } from '../../../../../playlist-builder/_services/playlist.service';
import { PlayerCapacityService } from '../../../../../playlist-builder/_services/player-capacity.service';
import { ContentVM } from '../_models/content-view';

@Injectable({
	providedIn: 'root'
})
export class AddToPlaylistService {
	constructor(
		private appStateService: AppStateService,
		private contentSerializerService: ContentSerializerService,
		private cvStateService: ContentViewStateService,
		private playerCapacityService: PlayerCapacityService,
		private playlistService: PlaylistService
	) {}

	public onAddClick(content: ContentVM, contentFile?: ContentFilesVM): void {
		let contentItem: ContentItem = new ContentItem();

		//any video product
		if (this.appStateService.product.Route !== 'hold') {
			contentItem = this.contentSerializerService.contentToContentItem(content);

			//hold
		} else {
			contentItem = this.contentSerializerService.contentFileToContentItem(content, contentFile);
		}
		if (content.ContentTypeId !== 1) {
			//content not music

			this.playlistService.playlist.contentItems.push(contentItem);
			this.playlistService.orderCount++;
			this.playlistService.orderArray.push(this.playlistService.orderCount);
		} else if (content.ContentTypeId === 1) {
			//content is music track
			this.playlistService.playlist.musicContentItem = JSON.parse(JSON.stringify(this.contentSerializerService.contentFileToContentItem(content, contentFile)));
		}
		this.playlistService.updatePlaylistDuration();
		this.playerCapacityService.warningMessage = this.playerCapacityService.setWarningMessage();
	}

	public onAddPlaylistClick(): void {
		this.cvStateService.contentList.forEach((content) => {
			if (this.appStateService.product.Route === 'hold') {
				this.onAddClick(content, content.contentFiles[0]);
			} else {
				this.onAddClick(content);
			}
		});
	}
}
