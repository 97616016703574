import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

import { Events, MessageService } from '../../../core/services';
import { ClipsView, Comment, ContentRequestEdit } from '../_models';
import { ContentRequestTable } from '../../_models';
import { CreateEditRequestService } from '../_services/create-edit-request.service';
import { environment } from '../../../../environments/environment';
import { ContentRequestStateName, Users } from '../../../shared/api-models/admin';

@Injectable({
	providedIn: 'root'
})
export class MergedRequestService {
	private mergedRequests: ContentRequestEdit[];

	constructor(private createEditRequestService: CreateEditRequestService, private httpClient: HttpClient, private messageService: MessageService) {}

	public createMergedRequest(mergedRequests: ContentRequestTable[]): Observable<ContentRequestEdit> {
		this.mergedRequests = this.serializeView(mergedRequests);
		const firstRequest: ContentRequestEdit = this.mergedRequests[0];
		const clips$ = this.mergedRequests.map((request) => {
			return this.httpClient.get(`${environment.adminUrl}ContentRequests/${request.Id}/Clips`);
		});

		const comments$ = this.mergedRequests.map((request) => {
			return this.httpClient.get(environment.adminUrl + `ContentRequests/${request.Id}/Comments`);
		});

		const observs$ = clips$.concat(comments$, this.requestApprovalFrom$(firstRequest), this.targetLibrary$(firstRequest));

		return forkJoin(observs$).pipe(
			map((res: any) => {
				const unflattenedClips: ClipsView[][] = res.slice(0, this.mergedRequests.length);
				const unflattenedComments: Comment[][] = res.slice(this.mergedRequests.length, this.mergedRequests.length * 2);
				const clips: ClipsView[] = [].concat.apply([], unflattenedClips);
				const comments: Comment[] = [].concat.apply([], unflattenedComments);
				const nonClipsProps: Users[] = res.slice(this.mergedRequests.length * 2);
				return this.mergedRequestInit(firstRequest, clips, comments, nonClipsProps);
			})
		);
	}

	private serializeView(mergedRequests: ContentRequestTable[]): ContentRequestEdit[] {
		return mergedRequests.map((request) => {
			const { ProducerName, CsrName, RequestedByName, ClientName } = request;
			return {
				...request,
				producerUsername: ProducerName,
				csrUsername: CsrName,
				requestedByUsername: RequestedByName,
				clientName: ClientName
			} as unknown as ContentRequestEdit;
		});
	}

	private requestApprovalFrom$(firstRequest: ContentRequestEdit): Observable<any> {
		if (firstRequest.RequestApprovalFrom) {
			return this.httpClient.get(`${environment.adminUrl}CoreUsers/${firstRequest.RequestApprovalFrom}`);
		} else {
			return of('');
		}
	}

	private targetLibrary$(firstRequest: ContentRequestEdit): Observable<any> {
		if (firstRequest.TargetLibraryId) {
			return this.httpClient.get(`${environment.contentUrl}ContentLibraries/${firstRequest.TargetLibraryId}`);
		} else {
			return of('');
		}
	}

	//All merged requests have to match, so using first request in array to set props
	private mergedRequestInit(firstRequest: ContentRequestEdit, clips: ClipsView[], comments: Comment[], nonClipsProps: any[]): ContentRequestEdit {
		const request = new ContentRequestEdit();
		request.DueDate = this.getMinDate('DueDate'); //get soonest due date
		request.CreateDateTime = this.getMinDate('CreateDateTime');
		request.requestedByUsername = firstRequest.requestedByUsername;
		request.RequestedBy = firstRequest.RequestedBy;
		request.RequestApprovalFrom = firstRequest.RequestApprovalFrom;
		request.requestApprovalFromUsername = nonClipsProps[0].UserName;
		request.CreatedBy = firstRequest.CreatedBy;
		request.RequestType = 'On-Hold Message';
		request.ClientId = firstRequest.ClientId;
		request.clientName = firstRequest.clientName;
		request.producerUsername = firstRequest.producerUsername;
		request.ProducerId = firstRequest.ProducerId;
		request.StateName = firstRequest.StateName;
		request.TargetLibraryId = firstRequest.TargetLibraryId;
		request.targetLibraryName = nonClipsProps[1].LibraryName;
		request.VoiceTalent = firstRequest.VoiceTalent;
		request.CsrId = firstRequest.CsrId;
		request.csrUsername = firstRequest.csrUsername;
		request.Title = 'Merged Request';
		request.Description = this.mergedRequests
			.map((request) => {
				if (request.Description) {
					return `${request.Description} - Original Request ID: ${request.Id}`;
				}
				return `Original Request ID: ${request.Id}`;
			})
			.toString()
			.replace(',', '\n \n');

		request.clips = clips;
		request.comments = comments;
		return request;
	}

	private getMinDate(prop: string): string {
		const dates = this.mergedRequests.map((request) => {
			return moment(request[prop]);
		});
		return moment.min(dates).format('YYYY-MM-DDTHH:mm:ss.SSS');
	}

	public onSaveMergedRequestClick$(): Observable<Object[]> {
		const request = this.createEditRequestService.request;
		this.messageService.publish(Events.savingPreloader, 1);
		return this.httpClient.post(`${environment.adminUrl}ContentRequests`, request).pipe(
			switchMap((newRequest: ContentRequestEdit) => {
				const clips$ = request.clips.map((clip) => {
					delete clip.Id;
					clip.ContentRequestId = newRequest.Id;
					return this.httpClient.post(`${environment.adminUrl}ContentRequestClips`, clip);
				});

				const comments$ = request.comments.map((comment) => {
					delete comment.Id;
					comment.ContentRequestId = newRequest.Id;
					return this.httpClient.post(`${environment.adminUrl}ContentRequestComments`, comment);
				});

				const cancelMergedRequests$ = this.mergedRequests.map((request) => {
					request.StateName = ContentRequestStateName.MERGED;
					if (request.ApprovedDateTime === 'N/A') {
						request.ApprovedDateTime = null;
					}
					return this.httpClient.put(`${environment.adminUrl}ContentRequests/${request.Id}`, request);
				});

				const observs$ = clips$.concat(comments$, cancelMergedRequests$);

				return forkJoin(observs$);
			})
		);
	}
}
