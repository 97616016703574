import { ContentLibraries } from '../../api-models/content';

export class LibrariesDragDrop extends ContentLibraries {
    name: string;
    icon: string;
    draggableClass: string;
    bgColor: string;
    userContentLibraryBridgeId: number;
    productName: string;
    clientContentLibraryBridgeId: number;
    deleteDisabled: boolean;
}