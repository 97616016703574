import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ContentVoiceTalents, IGetContentParams } from 'src/app/shared/api-models/content';

import { AppStateService } from '../../../core/services';
import { CategoriesService } from 'src/app/shared/components/library-tree/_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { ContentService } from 'src/app/shared/components/content-container/content/_services';
import { PlaylistService } from '../../_services';
import { ContentVM } from 'src/app/shared/components/content-container/content/_models/content-view';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

@Injectable({
	providedIn: 'root'
})
export class ChooseContentService {
	public contentManagerBtnClicked: boolean;

	constructor(
		private appStateService: AppStateService,
		private categoriesService: CategoriesService,
		private contentService: ContentService,
		private cvStateService: ContentViewStateService,
		private playlistService: PlaylistService,
		private router: Router
	) {}

	public onContentManagerClick(): void {
		this.contentManagerBtnClicked = true;
		this.appStateService.setSessionItem('playlist', JSON.stringify(this.playlistService.playlist));
		this.router.navigate([`content-manager/${this.appStateService.product.Route}/playlist-in-progress`]);
	}

	public showContentManagerBtn(): boolean {
		return this.appStateService.currentUser.Role !== UserRole.USER;
	}

	public onVoiceTalentSelect(voiceTalent: ContentVoiceTalents): void {
		this.cvStateService.viewState.voiceTalentId = voiceTalent.Id;
		if (this.cvStateService.viewState.contentSearchActive) {
			this.contentService.getContentBySearchTerm().subscribe((contentList) => {
				this.cvStateService.contentList = contentList;
			});
		} else {
			const args: IGetContentParams = {
				clickedCategory: this.categoriesService.clickedCategory
			};
			this.contentService.getContentForCategories(args).subscribe((contentList: ContentVM[]) => {
				this.cvStateService.contentList = contentList;
			});
		}
	}
}
