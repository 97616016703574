<div [ngStyle]="{ border: isFirstIndex ? '1px solid #d5d5d5' : null }" class="row t-row border-radius white-bg pd-top15 pd-bottom15">
	<!--*********************
		SCRIPT BUTTON
	**********************-->
	<div class="col-sm-1">
		<button
			*ngIf="!contentViewService.contentFileIsMusicTrack(content.contentFiles[0])"
			(mouseup)="content.detailsVisible = !content.detailsVisible"
			class="gray-bg"
		>
			<i class="{{ content.detailsVisible ? 'fas fa-times' : 'far fa-file-alt' }} mgn-right10" aria-hidden="true"></i>
			{{ content.detailsVisible ? 'Close' : 'Script' }}
		</button>
		<em *ngIf="contentViewService.contentFileIsMusicTrack(content.contentFiles[0])" class="orange-text">*Music Track</em>
	</div>

	<div class="{{ appStateService.product.Route === 'hold' ? 'col-sm-1' : 'col-sm-2' }}">
		<!--***************************
			VIDEO THUMBNAIL/PREVIEW
		****************************-->
		<portal-preview-thumbnail *ngIf="appStateService.product.Route !== 'hold'" [content]="content"></portal-preview-thumbnail>

		<!--***********************
			HOLD PREVIEW BUTTON
		************************-->
		<button
			*ngIf="appStateService.product.Route === 'hold'"
			(click)="onPlayClick()"
			class="{{ content.contentFiles[0].previewActive ? 'yellow-bg dark-text' : 'blue-bg white-text' }} noExit"
		>
			<i class="fa {{ content.contentFiles[0].previewActive ? 'fa-times' : 'fas fa-play' }} mgn-right10 noExit" aria-hidden="true"></i>
			{{ content.contentFiles[0].previewActive ? 'Stop' : 'Play' }}
		</button>
	</div>

	<!--***********************
		CONTENT TITLE
	************************-->
	<div class="col-sm-6 ft-size16">
		<span>
			{{ content.Title }}
			<span *ngIf="content.IsDeleted" class="red-text-light semi-bold">
				- DELETED
				<i
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					[tippyProps]="{ appendTo: 'parent' }"
					data-tippy-content="Deleted content has been removed from your Library. Although this clip was included in this Saved Playlist, it is not available for future use."
					class="far fa-question-circle mgn-left10 dark-text"
				></i>
			</span>
		</span>
	</div>
</div>

<!--*********************
	CONTENT SCRIPT
**********************-->
<c-details *ngIf="content.detailsVisible" [content]="content"></c-details>
