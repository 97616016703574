import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';

import { AddToPlaylistService } from './add-to-playlist.service';
import {
	AppStateService,
	AudioPreviewService,
	ContentSerializerService,
	Events,
	MessageService,
	TypeGuardService,
	UtilitiesService
} from '../../../../../core/services';
import { ContentFilesVM } from '../_models';
import { ContentViewState } from 'src/app/shared/view-models';
import { DetailsService } from './details.service';
import { environment } from '../../../../../../environments/environment';
import { ContentViewStateService } from '../../../../services';
import { ContentItem } from 'src/app/shared/view-models/content';
import { ContentVM } from '../_models/content-view';

@Injectable({
	providedIn: 'root'
})

//This service provided in top-level feature area component
export class ContentViewService {
	//Observable sources
	private autoScrollContainerSource = new Subject<any>();
	private c24BgContentSelectSource = new Subject<[ContentVM, boolean]>();
	private c24LayerContentSelectSource = new Subject<ContentVM>();
	private c24ExistingProjectSelectSource = new Subject<ContentVM>();
	private onAddMessageOrPlayClickSource = new Subject<any>();
	private categoryClickedSource = new Subject<any>();

	//Observable streams
	public autoScrollContainer$ = this.autoScrollContainerSource.asObservable();
	public onAddMessageOrPlayClick$ = this.onAddMessageOrPlayClickSource.asObservable();
	public onC24BgContentSelect$ = this.c24BgContentSelectSource.asObservable();
	public onC24ExistingProjectSelect$ = this.c24ExistingProjectSelectSource.asObservable();
	public onC24LayerContentSelect$ = this.c24LayerContentSelectSource.asObservable();
	public onCategoryClick$ = this.categoryClickedSource.asObservable();

	public c24SVG: any;
	public contentWithActiveAudioPreview: ContentVM;
	public videoPlaying: boolean;
	public viewState: ContentViewState;

	constructor(
		private addToPlaylistService: AddToPlaylistService,
		private appStateService: AppStateService,
		private audioPreviewService: AudioPreviewService,
		private contentSerializerService: ContentSerializerService,
		private cvStateService: ContentViewStateService,
		private detailsService: DetailsService,
		private domSanitizer: DomSanitizer,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private typeGuardService: TypeGuardService,
		private utilService: UtilitiesService
	) {}

	/********************
      HOLD METHODS
    ********************/

	public onPlayClick(
		content: ContentVM,
		isLastIndex?: boolean,
		prop?: 'talentPreviewSelectorVisible' | 'talentPickerVisible' | 'playlist-details' | 'newsletter',
		contentList?: ContentVM[]
	): void {
		let payload = [content, prop];
		this.scrollContainerDownIfLastIndex(isLastIndex);

		if (
			content.ContentTypeId !== 1 &&
			prop !== 'playlist-details' &&
			prop !== 'newsletter' &&
			this.cvStateService.viewState.treeSelection !== 'featured-playlist'
		) {
			//content is not music and feature area not playlist-details
			this.onAddMessageOrPlayClickSource.next(payload);

			//this.contentWithActiveAudioPreview will be undefined on the first click
			if (this.contentWithActiveAudioPreview) {
				this.audioPreviewService.stopCurrentPreview(
					this.contentSerializerService.contentToContentItem(this.contentWithActiveAudioPreview),
					this.contentWithActiveAudioPreview.contentFiles as ContentFilesVM[]
				);
			}

			//Store content in this variable so when clicking another "Play" button we can
			//stop the currently playing audio preview with "this.audioPreviewService.stopCurrentPreview()" method above
			this.contentWithActiveAudioPreview = content;
		} else if (
			content.ContentTypeId === 1 ||
			prop === 'playlist-details' ||
			prop === 'newsletter' ||
			this.cvStateService.viewState.treeSelection === 'featured-playlist'
		) {
			//content is music or feature area is playlist-details
			let tempContentFiles = contentList.map((content) => {
				return content.contentFiles;
			});

			let contentFile = contentList.find((c) => c.Id === content.Id).contentFiles[0];

			//music files from each content
			let contentFiles = [].concat.apply([], tempContentFiles);
			this.playAudioPreview(contentFile as ContentFilesVM, contentFiles, content);
		}
	}

	private playAudioPreview(selectedContentFile: ContentFilesVM, contentFiles: ContentFilesVM[], content: ContentVM): void {
		this.audioPreviewService.contentItemAudioPreview(
			this.contentSerializerService.contentFileToContentItem(content, selectedContentFile),
			contentFiles as ContentFilesVM[]
		);
		selectedContentFile.previewActive = !selectedContentFile.previewActive;
	}

	public contentFileIsMusicTrack(contentFile: ContentFilesVM): boolean {
		return this.typeGuardService.contentFileIsHold(contentFile) && contentFile.isMusicTrack;
	}

	public onAddMessageClick(content: ContentVM, isLastIndex: boolean, prop: 'talentPreviewSelectorVisible' | 'talentPickerVisible'): void {
		let payload = [content, prop];
		this.scrollContainerDownIfLastIndex(isLastIndex);
		switch (true) {
			//Not music and more than one content file
			case content.ContentTypeId !== 1 && content.contentFiles.length > 1:
				this.onAddMessageOrPlayClickSource.next(payload);
				break;

			//Is music or just one content file
			case content.ContentTypeId === 1 || content.contentFiles.length === 1:
				this.addToPlaylistService.onAddClick(content, content.contentFiles[0]);
				break;
		}
	}

	//C24 content for image layer
	public onSelectLayerContent(content: ContentVM): void {
		this.c24LayerContentSelectSource.next(content);
	}

	public onSelectBgContent(payload: [ContentVM, boolean]): void {
		this.c24BgContentSelectSource.next(payload);
	}

	public onSelectExistingProject(content: ContentVM): void {
		this.c24ExistingProjectSelectSource.next(content);
	}

	public onSaveContentDetailsClick(content: ContentVM): void {
		content.detailsVisible = !content.detailsVisible;
		if (!content.detailsVisible) {
			this.detailsService.updateContentAndContentFiles(content).subscribe(() => {
				this.messageService.publish(Events.savingPreloader, 0);
			});
		}
	}

	private scrollContainerDownIfLastIndex(isLastIndex: boolean): void {
		if (isLastIndex) {
			this.autoScrollContainerSource.next(); //scroll container down so select box is all the way in view
		}
	}

	public onSelectVoiceTalentClick(selectedContentFile: ContentFilesVM, content: ContentVM, prop: 'talentPreviewSelectorVisible' | 'talentPickerVisible'): void {
		//adding to playlist
		if (prop === 'talentPickerVisible') {
			this.addToPlaylistService.onAddClick(content, selectedContentFile as ContentFilesVM);
			this.onAddMessageOrPlayClickSource.next([content, prop]);

			//previewing a voice talent
		} else if (prop === 'talentPreviewSelectorVisible') {
			this.playAudioPreview(selectedContentFile as ContentFilesVM, content.contentFiles, content);
		}
	}

	public onCategoryClick(): void {
		this.categoryClickedSource.next();
	}

	/********************
      VIDEO METHODS
    ********************/

	public setC24Props(content: ContentVM | ContentItem): void {
		if (content.contentFiles && content.contentFiles[0]?.Create24ProjectJsonV4) {
			let c24BgContent: ContentVM = JSON.parse(content.contentFiles[0].Create24ProjectJsonV4)?.bgContent;
			let c24BlankBgColor: string = JSON.parse(content.contentFiles[0].Create24ProjectJsonV4)?.blankBgColor;

			if (c24BgContent) {
				content.previewUrl = c24BgContent.previewUrl;
				content.contentType = c24BgContent.contentType;
			} else {
				content.create24BlankBgColor = c24BlankBgColor;
				content.contentType = 'image/png';
				content.previewUrl = this.blankCanvasLink(content);
				this.videoPlaying = true;
			}

			content.thumbIcon = this.contentSerializerService.icon(content);
		}
	}

	public setAndSanitizeSvg(clip): void {
		this.setC24Props(clip);
		this.c24SVG = this.sanitizeC24Html(clip);
	}

	private blankCanvasLink(content: any): string {
		if (content.ContentTypeId === 1000009 || content.contentTypeId === 1000009) {
			//Video-Sidebar-Create24
			return `${environment.contentUrl}File/Video/1131995/Preview/?${new Date().toString()}`;
		}
		if (this.appStateService.product.Route === 'poster') {
			return `${environment.contentUrl}File/Video/1131994/Preview/?${new Date().toString()}`;
		}
		return `${environment.contentUrl}File/Video/1131993/Preview/?${new Date().toString()}`;
	}

	public sanitizeC24Html(content: ContentVM | ContentItem): SafeHtml | null {
		if (content?.contentFiles && content.contentFiles[0]?.Create24OutputHtmlV4) {
			return this.domSanitizer.bypassSecurityTrustHtml(content.contentFiles[0].Create24OutputHtmlV4);
		} else {
			return null;
		}
	}

	public showC24HTML(content: ContentVM | ContentItem, c24SVG: SafeHtml): boolean {
		if (content?.contentTypeId === 26) {
			//Disable for weather slides
			return false;
		}
		return c24SVG !== null && c24SVG !== undefined && this.videoPlaying;
	}

	/********************
      CONTENT MANAGER
    ********************/

	public onContentDrag(content: ContentVM): void {
		this.appStateService.setSessionItem('draggedContent', JSON.stringify(content));
	}

	public onDeleteClick(content: ContentVM): void {
		const isDeleted = !content.IsDeleted;
		const confirmMessage = content.IsDeleted ? 'Restore ' : 'Delete ';
		const confirmOk: boolean = confirm(`${confirmMessage}"${content.Title}"?`);
		if (confirmOk) {
			this.messageService.publish(Events.savingPreloader, 1);
			this.httpClient.patch(`${environment.contentUrl}Content/${content.Id}`, { IsDeleted: isDeleted }).subscribe(() => {
				if (this.cvStateService.viewState.hideDeleted && !content.IsDeleted) {
					this.cvStateService.contentList = this.cvStateService.contentList.filter((c) => c.Id !== content.Id); //Remove deleted content from list
					this.cvStateService.totalItemsContentList = this.cvStateService.contentList.length;
				}
				content.IsDeleted = isDeleted;
				this.messageService.publish(Events.savingPreloader, 0);
			});
		}
	}

	public showCreateDate(content: ContentVM): boolean {
		if (this.appStateService.currentUser.IsEmployee) {
			return true;
		}
		return !content.isGlobal;
	}

	/********************
      PLAYLIST BUILDER
    ********************/
	public canUse(content: ContentVM): boolean {
		if (content.contentFiles && content.contentFiles.length > 0) {
			return (
				content.contentFiles.every((contentFile) => contentFile.ContentFileStateId === 4) && //4 === Ready
				content.contentFiles.every((contentFile) => contentFile.MasterFileName !== 'unknown') &&
				this.isPlayerCompatible(content)
			);
		}
		if (!content.IsChannel) {
			return false;
		}
		return true;
	}

	private isPlayerCompatible(content: ContentVM): boolean {
		if (this.utilService.includes(this.appStateService.product?.Route, 'web')) {
			return !content.HideFromWebPlayer;
		}
		return true;
	}

	/********************
      ALL
    ********************/
	public thumbnailString(): string {
		if (this.appStateService.product.Route === 'hold') {
			return 'Hold/Message/';
		} else {
			return 'Video/';
		}
	}

	public featuredContentProps(content: ContentVM): { icon: string; color: string; tooltip: string } {
		switch (content.displayedFeatureCodeId) {
			case 5:
				return { icon: 'fas fa-star', color: 'blue-text', tooltip: 'New!' }; //New
			case 10:
				return { icon: 'fas fa-lightbulb', color: 'yellow-text-darkest', tooltip: 'Featured!' }; //Featured
			case 15:
				return { icon: 'fas fa-fire', color: 'orange-text', tooltip: 'Hot!' }; //Hot/popular
			default:
				return null; //Not featured
		}
	}

	public contentIsVideo(content: ContentVM): boolean {
		return content.ContentTypeId !== 1 && content.ContentTypeId !== 2 && content.ContentTypeId !== 3;
	}

	public saveEnabled(content: ContentVM): boolean {
		if (content.FeatureCodeStart || content.FeatureCodeEnd) {
			return !!content.FeatureCodeStart && !!content.FeatureCodeEnd;
		}
		return true;
	}

	public c24ContentType(content?: ContentVM): string {
		const contentTypeId = content ? content.ContentTypeId : 43;
		switch (true) {
			case contentTypeId === 43:
			case contentTypeId === 1000006:
				return 'image';
			case contentTypeId === 42:
			case contentTypeId === 1000007:
				return 'video';
			case contentTypeId === 1000002:
			case contentTypeId === 1000009:
				return 'sidebar';
		}
	}
}
