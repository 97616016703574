import { ContentAdvancedSchedules } from './content-advanced-schedules';

export class PlaylistContentFileBridge {
	Id: number;
	PlaylistId: number;
	ContentFileId: number;
	ChannelContentId?: number;
	StartDate: string;
	StopDate: string;
	ContentAdvancedScheduleId: number;
	Order: number;
	IsMusicTrack: boolean;
	SilenceMusic: boolean;
	StillDuration: number;
	WeatherOverride?: string;
	FeedId: number;

	ContentAdvancedSchedule: ContentAdvancedSchedules;
	

	constructor(playlistId, contentFileId, isMusicTrack, order, startDate, stillDuration, stopDate, channelContentId) {
		this.PlaylistId = playlistId;
		this.ContentFileId = contentFileId;
		this.IsMusicTrack = isMusicTrack;
		this.Order = order;
		this.StartDate = startDate;
		this.StillDuration = stillDuration;
		this.StopDate = stopDate;
		this.ChannelContentId = channelContentId;
	}
}