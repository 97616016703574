export class WorkOrders {
    Id: number;
	TicketId: number;
	CompanyName: string;
	PhoneSystem: string;
	ServiceLocationId: number;
	LocationName: string;
	Address: string;
	City: string;
	State: string;
	Zip: string;
	PhoneNumber: string;
	FaxNumber: string;
	InstallerId: number;
	Notes: string;
	ProductTypeId: number
	CreatedBy: number;
	CreatedDate: string;
	IsApproved: boolean;
	ScheduledDate: string;
	EmailBody: string;
	IsSent: boolean;
	SentDate: string;
	CompletedNote: string;
	IsComplete: boolean;
	ConfirmationNumber: string;
	CompletedDate: string;
	InstallContactName: string;
}