import { Component, Input, OnInit } from '@angular/core';

import { AppStateService, UtilitiesService } from '../../core/services';
import { environment } from '../../../environments/environment';
import { GroupManagerService } from '../group-manager.service';
import { Groups, PlayersView } from '../_models';

@Component({
	selector: 'gm-player',
	templateUrl: './player.component.html',
	styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {
	@Input() showRemoveBtn: boolean;
	@Input() draggable: string;
	@Input() group: Groups;
	@Input() player: PlayersView;

	public icon: string;

	constructor(public appStateService: AppStateService, private groupManagerService: GroupManagerService, public utilService: UtilitiesService) {}

	ngOnInit(): void {}

	onPlayerDrag(event): void {
		this.player.isDraggable = event.target.classList.contains('draggable');
		this.appStateService.setSessionItem('draggedPlayer', JSON.stringify(this.player));
	}

	public onRemovePlayerClick(): void {
		const confirmOk: boolean = confirm(`Remove player "${this.player.PlayerName}" from group "${this.group.Name}"?`);
		if (confirmOk) {
			this.groupManagerService.delete(`${environment.adminUrl}CorePlayerGroupsBridge/${this.group.Id}/${this.player.Id}`);
		}
	}
}
