import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ClientBrowserService, TableConfigService } from './_services';
import { ClientsView } from './clients-view';
import { Column } from '../../shared/components/table/view-models';
import { Events, MessageService, UtilitiesService } from 'src/app/core/services';
import { QueryVerb } from './query-verb.enum';
import { SortParams } from 'src/app/shared/view-models/common-types';

@Component({
	selector: 'app-client-browser',
	templateUrl: './client-browser.component.html',
	styleUrls: ['./client-browser.component.scss'],
	providers: [TableConfigService]
})
export class ClientBrowserComponent implements OnInit {
	@ViewChild('companyName') companyName: ElementRef;
	@ViewChild('locationName') locationName: ElementRef;
	@ViewChild('industry') industry: ElementRef;
	@ViewChild('city') city: ElementRef;
	@ViewChild('state') state: ElementRef;
	@ViewChild('csr') csr: ElementRef;
	@ViewChild('salesperson') salesperson: ElementRef;
	@ViewChild('username') username: ElementRef;
	@ViewChild('email') email: ElementRef;
	@ViewChild('unitIdentifier') unitIdentifier: ElementRef;

	public clearResultsBtnVisible: boolean;
	public clients: ClientsView[];
	public spinnerActive: boolean;
	public productFilterOptions: any = [
		{ name: 'Reset' },
		{ name: 'Hold Clients', prop: 'includeHold' },
		{ name: 'Video Clients', prop: 'includeVideo' },
		{ name: 'Radio Clients', prop: 'includeRadio' },
		{ name: 'Poster Clients', prop: 'includePoster' },
		{ name: 'Video Wall Clients', prop: 'includeVideoWall' },
		{ name: 'Web Video Clients', prop: 'includeWeb' }
	];

	private searchTermChanged: Subject<string> = new Subject<string>();
	public queryVerbEnum = QueryVerb;
	private subs: Subscription[] = [];

	constructor(
		public clientBrowserService: ClientBrowserService,
		private messageService: MessageService,
		public tableConfigService: TableConfigService,
		public utilService: UtilitiesService
	) {
		this.searchTermChanged.pipe(debounceTime(400)).subscribe((_) => {
			this.clientBrowserService.pageNumber = 1;
			this.getClientBrowserData();
		});
		this.onColumnSort();
	}

	ngOnInit() {
		this.getClientBrowserData();
	}

	onColumnSort(): void {
		this.subs.push(
			this.messageService.subscribe(Events.onColumnSort, (sortParams: SortParams) => {
				const { key } = sortParams;
				this.clientBrowserService.orderByCol = key;
				sortParams.order === 'asc' ? (this.clientBrowserService.orderDesc = false) : (this.clientBrowserService.orderDesc = true);
				this.getClientBrowserData();
			})
		);
	}

	private getClientBrowserData(): void {
		this.spinnerActive = true;
		this.clientBrowserService.getClientBrowserData().subscribe((clients: ClientsView[]) => {
			this.clients = clients;
			this.spinnerActive = false;
		});
	}

	currentTablePage(pageNumber: number): void {
		this.clientBrowserService.pageNumber = pageNumber;
		setTimeout(() => {
			this.getClientBrowserData();
		}, 750);
	}

	onEmailClick(payload: [any, Column]): void {
		const row = payload[0];
		window.location.href = `mailto:${row.email}`;
	}

	onOpenBtnClick(payload: [Column, any, string]): void {
		localStorage.setItem('changeClientUrl', `/crm/client-details/locations/`);
		window.open(`/my-products/${payload[1].ClientId}`);
	}

	onVerbSelect(queryVerb: QueryVerb): void {
		this.clientBrowserService.queryVerb = queryVerb;
		if (queryVerb === this.queryVerbEnum.NOT) {
			this.productFilterOptions = this.productFilterOptions.filter((option) => option.name !== 'Reset');

			this.productFilterOptions.forEach((option) => {
				this.clientBrowserService[option.prop] = true;
			});
		}
		this.getClientBrowserData();
	}

	onProductFilterMultiSelect(selectedOptions: any[]): void {
		const resetClicked = selectedOptions.some((o) => o.name === 'Reset');
		if (!resetClicked) {
			this.productFilterOptions.forEach((option) => {
				this.clientBrowserService[option.prop] = selectedOptions.some((o) => o.prop === option.prop);
			});
		} else {
			this.productFilterOptions.forEach((option) => {
				this.clientBrowserService[option.prop] = true;
			});
		}
		this.getClientBrowserData();
	}

	onProductFilterSelect(selectedOption): void {
		this.productFilterOptions.forEach((option) => {
			this.clientBrowserService[option.prop] = selectedOption.prop.includes(option.prop);
		});
		this.getClientBrowserData();
	}

	public onAppleTVFilterSelect(option: { name: string; param: 'all' | 'include' | 'exclude' }): void {
		this.clientBrowserService.appleClientFilter = option.param;
		this.getClientBrowserData();
	}

	public onIncludeDemoAccountsClick(): void {
		this.clientBrowserService.includeDemoAccounts = !this.clientBrowserService.includeDemoAccounts;
		this.getClientBrowserData();
	}

	public onShowInactiveClick(): void {
		this.clientBrowserService.includeInactive = !this.clientBrowserService.includeInactive;
		this.getClientBrowserData();
	}

	public onHoldCREligibleOnlyClick(): void {
		this.clientBrowserService.holdCrOnly = !this.clientBrowserService.holdCrOnly;
		this.getClientBrowserData();
	}

	public onClearResultsClick(): void {
		//set all template input fields to empty string
		this.companyName.nativeElement.value = '';
		this.locationName.nativeElement.value = '';
		this.industry.nativeElement.value = '';
		this.city.nativeElement.value = '';
		this.state.nativeElement.value = '';
		this.csr.nativeElement.value = '';
		this.salesperson.nativeElement.value = '';
		this.username.nativeElement.value = '';
		this.email.nativeElement.value = '';
		this.unitIdentifier.nativeElement.value = '';

		//set each search param back to empty string
		Object.keys(this.clientBrowserService.searchParams).forEach((param) => (this.clientBrowserService.searchParams[param] = ''));
		this.clearResultsBtnVisible = false;
		this.getClientBrowserData();
	}

	public onSearch(value: string, param: string): void {
		this.clientBrowserService.searchParams[param] = value;
		if (value.length === 0) {
			this.clientBrowserService.searchParams[param] = '';
		}
		this.showClearBtnIfSearchParams();
		this.searchTermChanged.next(value);
	}

	private showClearBtnIfSearchParams(): void {
		//loop through each property value
		const values = Object.keys(this.clientBrowserService.searchParams).map((e) => this.clientBrowserService.searchParams[e]);
		//if at least one search box has a value, show clear button
		this.clearResultsBtnVisible = values.some((value) => value);
	}
}
