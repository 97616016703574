<div class="absolute gray-bg border-radius row {{ classList }} left0 right0 center" style="opacity: 0.9; margin: auto; z-index: 99">
	<div class="col-xs-6">
		<div class="row">
			<div class="col-xs-6">
				<i class="fas fa-step-backward pointer" (click)="onVideoControlClick.emit('back')"></i>
			</div>
			<div class="col-xs-6">
				<i class="{{ video?.nativeElement.paused ? 'fas fa-play' : 'fas fa-pause' }} pointer" (click)="onVideoControlClick.emit('play')"></i>
			</div>
		</div>
	</div>
	<div *ngIf="durationSet" class="col-xs-6">
		<span>{{ currentTimeView }}/{{ duration }}</span>
	</div>
</div>
