<div
	(mousedown)="onPlayerDrag($event)"
	id="{{ player.Id }}"
	class="hidden-xs hidden-sm {{ draggable }} pd-left10 player border-radius {{
		appStateService.product.color
	}}-bg-darker ht42 ln-height42 white-text mgn-bottom6 ft-size14"
>
	<i class="fa {{ icon }} white-text pd-right4" aria-hidden="true"></i>
	{{ player.PlayerName }}
	<button
		*ngIf="showRemoveBtn"
		ngxTippy
		tippyName="{{ utilService.guid | wrapFn }}"
		data-tippy-content="Remove player from group"
		[tippyProps]="{ appendTo: 'parent', placement: 'left' }"
		class="white-bg dark-text right mgn-right10 mgn-top12 ht20 wdth20 relative z"
		(click)="onRemovePlayerClick()"
	>
		<i class="fa fa-times ft-size12 absolute center-unknown-height-width" aria-hidden="true"></i>
	</button>
</div>

<!--Small devices-->
<div
	(mousedown)="onPlayerDrag($event)"
	(touchstart)="onPlayerDrag($event)"
	id="{{ player.Id }}"
	class="hidden-md hidden-lg {{ draggable }} pd-left10 player border-radius {{
		appStateService.product.color
	}}-bg-darker ht72 ln-height72 white-text mgn-bottom6 ft-size14"
>
	<i class="fa {{ icon }} white-text pd-right4" aria-hidden="true"></i>
	{{ player.PlayerName }}
	<button
		*ngIf="showRemoveBtn"
		ngxTippy
		tippyName="{{ utilService.guid | wrapFn }}"
		data-tippy-content="Remove player from group"
		[tippyProps]="{ appendTo: 'parent', placement: 'left' }"
		class="red-bg-light white-text right mgn-right10 mgn-top24 ht24 wdth24 relative z"
		(click)="onRemovePlayerClick()"
	>
		<i class="fa fa-times ft-size20 absolute top2 left4" aria-hidden="true"></i>
	</button>
</div>
