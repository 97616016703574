import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { DateTimeService } from 'src/app/core/services';
import { ClientHealthDashboardService } from '../client-health-dashboard.service';
import { ClientHealthViewService } from '../client-health-view.service';
import { GumballCircle } from '../gumball-circle.model';

type NameAndId = { Name: string; Id: number };

@Component({
	selector: 'app-main-view',
	templateUrl: './main-view.component.html',
	styleUrls: ['./main-view.component.scss']
})
export class MainViewComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('myDoughnut') myDoughnut: ElementRef<HTMLCanvasElement>;

	public clientDetailsBoxTop: number;
	public clientDetailsBoxLeft: number;

	private subs: Subscription[] = [];

	constructor(
		private activatedRoute: ActivatedRoute,
		public clientHealthDashboardService: ClientHealthDashboardService,
		public clientHealthViewService: ClientHealthViewService,
		public dateTimeService: DateTimeService,
		private router: Router
	) {
		this.onCsrSelect();
	}

	ngOnInit(): void {
		this.clientHealthDashboardService.showClientDetails = false;
	}

	ngAfterViewInit() {
		const userId: number = +this.activatedRoute.snapshot.paramMap.get('userId');
		this.clientHealthDashboardService.pageInit(userId, this.myDoughnut.nativeElement.getContext('2d'));
	}

	onCsrSelect(): void {
		this.subs.push(
			this.clientHealthDashboardService.csrSelect$.subscribe(() => {
				this.clientHealthDashboardService.getUserAndClients(this.clientHealthDashboardService.selectedUser.Id, this.myDoughnut.nativeElement.getContext('2d'));
				this.clientHealthDashboardService.currentDateTime = this.dateTimeService.dateAndTimeLocal(new Date().toString());
			})
		);
	}

	public onClientClick(client: GumballCircle): void {
		this.router.navigate([`/client-health-dashboard/client-details/${client.ClientId}`]);
	}

	public onClientCircleHover(client: GumballCircle): void {
		this.clientHealthViewService.hoveredElement = document.getElementById(JSON.stringify(client.ClientId));
		this.clientHealthViewService.onCircleHover(client);
	}

	ngOnDestroy() {
		this.clientHealthViewService.hoveredClientOrPlayer = null;
		this.clientHealthDashboardService.backBtnClicked = false;
		clearInterval(this.clientHealthDashboardService.timer);
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
