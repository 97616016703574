import { MonthlyOption, RepeatingOption } from './_models';

export const TimesOfDay: string[] = ['12:00 AM','12:15 AM','12:30 AM','12:45 AM','1:00 AM','1:15 AM','1:30 AM','1:45 AM','2:00 AM','2:15 AM','2:30 AM','2:45 AM','3:00 AM','3:15 AM','3:30 AM','3:45 AM','4:00 AM','4:15 AM','4:30 AM','4:45 AM','5:00 AM','5:15 AM','5:30 AM','5:45 AM','6:00 AM','6:15 AM','6:30 AM','6:45 AM','7:00 AM','7:15 AM','7:30 AM','7:45 AM','8:00 AM','8:15 AM','8:30 AM','8:45 AM','9:00 AM','9:15 AM','9:30 AM','9:45 AM','10:00 AM','10:15 AM','10:30 AM','10:45 AM','11:00 AM','11:15 AM','11:30 AM','11:45 AM','12:00 PM','12:15 PM','12:30 PM','12:45 PM','1:00 PM','1:15 PM','1:30 PM','1:45 PM','2:00 PM','2:15 PM','2:30 PM','2:45 PM','3:00 PM','3:15 PM','3:30 PM','3:45 PM','4:00 PM','4:15 PM','4:30 PM','4:45 PM','5:00 PM','5:15 PM','5:30 PM','5:45 PM','6:00 PM','6:15 PM','6:30 PM','6:45 PM','7:00 PM','7:15 PM','7:30 PM','7:45 PM','8:00 PM','8:15 PM','8:30 PM','8:45 PM','9:00 PM','9:15 PM','9:30 PM','9:45 PM','10:00 PM','10:15 PM','10:30 PM','10:45 PM','11:00 PM','11:15 PM','11:30 PM','11:45 PM'
];

export const DatesOfMonth = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '99'];

export const MonthsOfTheYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
	'September', 'October', 'November', 'December'];

export const  DaysOfTheWeek = ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const MonthlyOptions: MonthlyOption[] = [
						{
							name: 'Every Day',
							RecurrenceSubtypeId: 1
						},
						{
							name: 'Weekdays',
							RecurrenceSubtypeId: 2
						},
						{
							name: 'Days of Month',
							RecurrenceSubtypeId: 3
						}
]

export const RepeatingOptions: RepeatingOption[] = [
						{
						    name: 'Every Day',
						    RecurrenceTypeId: 1,
						    RecurrenceSubtypeId: null
						}, 
						{
						    name: 'Weekly',
						    RecurrenceTypeId: 2,
						    RecurrenceSubtypeId: null
						}, 
						{
						    name: 'Monthly',
						    RecurrenceTypeId: 3,
						    RecurrenceSubtypeId: 1
						}, 
						{
						    name: 'Yearly',
						    RecurrenceTypeId: 4,
						    RecurrenceSubtypeId: null
						}
]
