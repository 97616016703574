import { PlayersView } from 'src/app/crm/locations/location-details/_models/players-view';
import { Users } from './users';
import { ProductTypeTable } from '../../view-models/admin/product-types-view';

export class ServiceLocations {
	Id?: number;
	ClientId?: number;
	PrimaryContactUserId?: number;
	Name?: string;
	Notes?: string;
	Address1?: string;
	Address2?: string;
	City?: string;
	State?: string;
	Zip?: string;
	Phone?: string;
	Fax?: string;
	Country?: string;
	WeatherZip?: string;
	PhoneSystem?: any;
	InstallationNotes?: string;
	PreviousInstallerId?: number;

	PrimaryContactUser?: Users;
	players?: any[];
	viewBtn?: string;
	hold?: ProductTypeTable;
	video?: ProductTypeTable;
	poster?: ProductTypeTable;
	radio?: ProductTypeTable;
	web?: ProductTypeTable;
	videoWall?: ProductTypeTable;
	deleteBtn?: string;
	classList?: string;
	name?: string;
	isRepaired?: boolean;
	holdUnit?: string;
	holdRemoteNumber?: string;
	isSelected?: boolean;
}
