import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CategoriesTree } from './_models';
import { TypeGuardService } from 'src/app/core/services';

@Component({
    selector: 'loc-hover-buttons',
    templateUrl: './hover-buttons.component.html',
    styleUrls: ['./hover-buttons.component.scss']
})
export class HoverButtonsComponent implements OnInit {

    @Input() bgColor: string;
    @Input() category: CategoriesTree;
    @Input() cursorNotAllowed: boolean;
    @Input() deletable: boolean;
    @Input() itemName: string;
    @Input() isLibrary: boolean;
    @Input() isSubCategory: boolean;

    @Output() deleteClickNotify: EventEmitter<CategoriesTree> = new EventEmitter<CategoriesTree>();
    @Output() createCategoryClickNotify: EventEmitter<any> = new EventEmitter<any>();
    @Output() nameEditNotify: EventEmitter<any> = new EventEmitter<any>();

    public showDeleteTooltip: boolean;
    public showEditTooltip: boolean;
    public showNewTooltip: boolean;

    private deleteTimeout: any;
    private editTimeout: any
    private newTimeout: any;

    constructor(
            public typeGuardService: TypeGuardService,
    ) { }

    ngOnInit() {
    }

    public onHoverBtnMouseenter(prop: string): void {
        if (prop === 'new') {
            this.newTimeout = setTimeout(() => this.showNewTooltip = true, 400);
            clearTimeout(this.editTimeout);
            clearTimeout(this.deleteTimeout);
            this.showEditTooltip = false;
            this.showDeleteTooltip = false;
        }
        if (prop === 'edit') {
            this.editTimeout = setTimeout(() => this.showEditTooltip = true, 400);
            clearTimeout(this.newTimeout);
            clearTimeout(this.deleteTimeout);
            this.showNewTooltip = false;
            this.showDeleteTooltip = false;
        }

        if (prop === 'delete') {
            this.deleteTimeout = setTimeout(() => this.showDeleteTooltip = true, 400);
            clearTimeout(this.newTimeout);
            clearTimeout(this.editTimeout);
            this.showNewTooltip = false;
            this.showEditTooltip = false;
        }
    }

    public onHoverBtnMouseleave(): void {
        this.showNewTooltip = false;
        this.showEditTooltip = false;
        this.showDeleteTooltip = false;
        clearTimeout(this.editTimeout);
        clearTimeout(this.newTimeout);
        clearTimeout(this.deleteTimeout);
    }
}
