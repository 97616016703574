import { Injectable } from '@angular/core';
import uuid from 'uuid';

import { Column, Table, TableConfig } from '../../../../shared/components/table/view-models';

@Injectable({
	providedIn: 'root'
})
export class TableService {
	/**********************************
	HOLD TABLE
**********************************/

	public holdTableColumns: Column[] = [
		new Column('editBtn', 'Edit', 'button', false, 'gray-bg pd-left8 pd-right8'),
		new Column('errorLogsBtn', 'Error logs', 'button', false, 'gray-bg pd-left8 pd-right8', null, null, null, null, null, null, null, null, 'columnDisabled'),
		new Column('UnitIdentifier', 'Unit ID', 'string'),
		new Column(
			'schedulePlaylist',
			'far fa-calendar-alt',
			'button',
			false,
			'gray-bg ht40 pd-left12 pd-right12',
			true,
			null,
			null,
			null,
			null,
			'schedulePlaylistTooltip'
		),
		new Column('PlayerName', 'Name', 'string', true, 'semi-bold'),
		new Column('PlayerModel.Name', 'Model', 'string', true),
		new Column('PlayerNotes', 'Notes', 'string', true),
		new Column('areaCodeAndPhone', 'Remote Phone', 'string', true),
		new Column('VoipFormat.FriendlyName', 'File Format', 'string', true),
		new Column('deleteBtn', 'far fa-trash-alt', 'button', false, 'white-bg red-text-light semi-bold ht40 pd-left12 pd-right12', true)
	];

	public holdTable: Table = new Table(this.holdTableColumns, this.guid());

	public holdConfig: TableConfig = {
		itemsPerPage: 25,
		headerColor: 'blue-bg'
	};

	/**********************************
	ALL VIDEO PRODUCTS TABLES
**********************************/

	public videoTableColumns: Column[] = [
		new Column('editBtn', 'Edit', 'button', false, 'gray-bg pd-left8 pd-right8'),
		new Column('errorLogsBtn', 'Error logs', 'button', false, 'gray-bg pd-left8 pd-right8'),
		new Column('UnitIdentifier', 'Unit ID', 'string'),
		new Column('PlayerName', 'Name', 'string', true, 'semi-bold'),
		new Column('Hostname', 'Hostname', 'string'),
		new Column('PlayerModel.Name', 'Model', 'string', true),
		new Column('PlayerNotes', 'Notes', 'string', true),
		new Column('playerStatus.lastUpdateCrmView', 'Last Update', 'string'),
		new Column('playerStatus.lastCheckinCrmView', 'Last Check-In', 'string'),
		new Column('deleteBtn', 'far fa-trash-alt', 'button', false, 'white-bg red-text-light semi-bold ht40 pd-left12 pd-right12', true)
	];

	public videoTable: Table = new Table(this.videoTableColumns, this.guid());
	public posterTable: Table = new Table(this.videoTableColumns, this.guid());
	public otherTable: Table = new Table(this.videoTableColumns, this.guid());

	public videoConfig: TableConfig = {
		itemsPerPage: 25,
		headerColor: 'red-bg'
	};

	/**********************************
	Radio TABLES
**********************************/

	public radioTableColumns: Column[] = [
		new Column('editBtn', 'Edit', 'button', false, 'gray-bg'),
		new Column('errorLogsBtn', 'Error logs', 'button', false, 'gray-bg pd-left8 pd-right8', null, null, null, null, null, null, null, null, 'columnDisabled'),
		new Column('MacAddress', 'Mac Address', 'string'),
		new Column('PlayerName', 'Name', 'string', true, 'semi-bold'),
		new Column('Hostname', 'Hostname', 'string'),
		new Column('PlayerModel.Name', 'Model', 'string', true),
		new Column('PlayerNotes', 'Notes', 'string', true),
		new Column('deleteBtn', 'far fa-trash-alt', 'button', false, 'white-bg red-text-light semi-bold ht40', true)
	];

	public radioTable: Table = new Table(this.radioTableColumns, this.guid());

	public radioConfig: TableConfig = {
		itemsPerPage: 25,
		headerColor: 'purple-bg'
	};

	private guid(): string {
		return uuid.v4();
	}
}
