import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AppStateService, AdminDataService, Events, MessageService, UtilitiesService } from '../../core/services';
import { environment } from '../../../environments/environment';
import { ClientContentLibraryBridge, ContentLibraries, ContentLibraryType } from '../../shared/api-models/content';
import { LibrariesDragDrop } from '../../shared/view-models/content';
import { ProductCard } from '../../my-products/view-models/product-card';
import { ProductTypes } from '../../shared/api-models/admin';

@Injectable({
	providedIn: 'root'
})
export class LibrariesService {
	constructor(
		private adminDataService: AdminDataService,
		private appStateService: AppStateService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private utilService: UtilitiesService
	) {}

	/* Per Natalie, 10/24/2019, this call to populate available
         libraries is correct. They sometimes need the ability to 
         assign a custom library from one client to another */
	public getLibrariesByProduct(): Observable<LibrariesDragDrop[]> {
		const productTypeIdArr: number[] = this.appStateService.contractedProducts.map((product) => product.Id);
		productTypeIdArr.push(8); //8 is placeable images, which are product agnostic

		const libraries$: Observable<LibrariesDragDrop[]> = this.httpClient.post<LibrariesDragDrop[]>(
			`${environment.contentUrl}ContentLibraries/ByProduct`,
			productTypeIdArr
		);
		const bridgeArr$: Observable<ClientContentLibraryBridge[]> = this.httpClient.get<ClientContentLibraryBridge[]>(
			`${environment.contentUrl}ClientContentLibraryBridge/Client/${this.appStateService.currentClient.Id}`
		);

		return forkJoin([libraries$, bridgeArr$]).pipe(
			map((res: any) => {
				const libraries: LibrariesDragDrop[] = res[0];
				const bridgeArr: ClientContentLibraryBridge[] = res[1];
				return this.librariesView(libraries, bridgeArr).filter((library) => !library.IsDeleted);
			})
		);
	}

	private librariesView(libraries: LibrariesDragDrop[], bridgeArr: ClientContentLibraryBridge[]): LibrariesDragDrop[] {
		return libraries.map((library) => {
			library.icon = this.setProductProps(library.ProductTypeId).icon;
			library.name = `${library.LibraryName} - ${library.Id}`;
			library.productName = this.setProductProps(library.ProductTypeId).product;

			const matchingBridgeRecord = bridgeArr.find((bridge) => bridge.ContentLibraryId === library.Id);

			if (matchingBridgeRecord) {
				//matching bridge record exists if library is assigned
				library.clientContentLibraryBridgeId = matchingBridgeRecord.Id;
				library.WriteAccess = matchingBridgeRecord.WriteAccess;
			}
			library.draggableClass = library.WriteAccess && library.clientContentLibraryBridgeId ? '' : 'draggable';
			library.bgColor = library.WriteAccess && library.clientContentLibraryBridgeId ? 'gray-bg-darker' : this.setProductProps(library.ProductTypeId).bgColor;
			library.deleteDisabled = library.WriteAccess && this.appStateService.currentUser.UserId !== 35 && this.appStateService.currentUser.UserId !== 106; //Natalie/Austin
			return library;
		});
	}

	public getContentLibraryTypes(newLibrary: LibrariesDragDrop): Observable<ContentLibraryType[]> {
		return this.httpClient.get(`${environment.contentUrl}ContentLibraryType`).pipe(
			map((libraryTypes: ContentLibraryType[]) => {
				return (libraryTypes = libraryTypes
					.map((type) => {
						type.name = type.Name;
						return type;
					})
					.filter((libraryType) => {
						if (newLibrary.ProductTypeId === 1) {
							//Hold
							return libraryType.name === 'Hold-Music' || libraryType.name === 'Hold-Message';
						}
						return libraryType.name !== 'Hold-Music' && libraryType.name !== 'Hold-Message';
					})
					.filter((libraryType) => {
						//filter all library types with the following id's
						return [4, 8, 9].indexOf(libraryType.Id) === -1;
					}));
			})
		);
	}

	public getProductTypes(): Observable<ProductTypes[]> {
		return this.adminDataService.getProductTypes$().pipe(
			map((productTypes: ProductTypes[]) => {
				return productTypes.filter((productType) => {
					return productType.ProductName !== 'Works24 Radio' && productType.ProductName !== 'Other Product or Service';
				});
			})
		);
	}

	public postClientBridgeAndUserAccess(library: LibrariesDragDrop): Observable<any> {
		const clientBridge$ = this.httpClient.post(`${environment.contentUrl}ClientContentLibraryBridge`, this.clientContentLibraryBridge(library));
		const userAccess$ = this.httpClient.post(
			`${environment.contentUrl}UserContentLibraryBridge/GrantUsersAccess/Client/${this.appStateService.currentClient.Id}/Library/${library.Id}`,
			null
		);

		return forkJoin([clientBridge$, userAccess$]);
	}

	public postLibrary(newLibrary: LibrariesDragDrop): Observable<any> {
		return this.httpClient.post(`${environment.contentUrl}ContentLibraries/Client/${this.appStateService.currentClient.Id}`, this.library(newLibrary)).pipe(
			switchMap((savedLibrary: ContentLibraries) => {
				const clientBridge$ = this.httpClient.post(`${environment.contentUrl}ClientContentLibraryBridge`, this.clientContentLibraryBridge(savedLibrary));
				const userAccess$ = this.httpClient.post(
					`${environment.contentUrl}ContentLibraries/GrantFullAccess/Client/${this.appStateService.currentClient.Id}/Library/${savedLibrary.Id}`,
					null
				);

				let arr = [clientBridge$];
				//Video-Playable, Video-StillBG, Video-PlacedImage
				const contentLibraryTypeIds: number[] = [3, 5, 7, 12, 13];
				//If at least one matching library type, grant write access to all users
				const addUserAccess: boolean = contentLibraryTypeIds.some((id) => savedLibrary.ContentLibraryTypeId === id);
				arr = addUserAccess ? arr.concat(userAccess$) : arr;

				return forkJoin(arr);
			})
		);
	}

	private library(newLibrary: LibrariesDragDrop, isOnClientCreate?: boolean, productNoun?: string): LibrariesDragDrop {
		const library: LibrariesDragDrop = new LibrariesDragDrop();
		if (isOnClientCreate) {
			library.ContentLibraryTypeId = this.utilService.productTypeIdToContentLibraryTypeId(newLibrary.ProductTypeId);
			library.LibraryName = `${this.appStateService.currentClient.Name} Custom ${this.productNoun(productNoun)} Library`;
		} else {
			library.ContentLibraryTypeId = newLibrary.ContentLibraryTypeId;
			library.LibraryName = newLibrary.LibraryName;
		}
		library.ProductTypeId = newLibrary.ContentLibraryTypeId === 7 ? 8 : newLibrary.ProductTypeId; //ProductType should be Other for Placeable images libraries, but coding it by product for now since the portal loads libraries by selected product
		library.IsGlobal = false;
		library.AllowContentRequest = true;
		library.AllowCreate24 = true;
		library.CreatedByUserId = this.appStateService.currentUser.UserId;
		library.IsDeleted = false;
		library.HideFromPortal = this.hideFromPortal(newLibrary);
		return library;
	}

	private hideFromPortal(newLibrary: LibrariesDragDrop): boolean {
		return (
			newLibrary.ContentLibraryTypeId === 5 ||
			newLibrary.ContentLibraryTypeId === 6 ||
			newLibrary.ContentLibraryTypeId === 7 ||
			newLibrary.ContentLibraryTypeId === 12 ||
			newLibrary.ContentLibraryTypeId === 13
		);
	}

	public removeAssignedLibrary(library: LibrariesDragDrop): Observable<any> {
		return this.httpClient.delete(`${environment.contentUrl}ClientContentLibraryBridge/${library.clientContentLibraryBridgeId}`);
	}

	public productNoun(productNoun: string): string {
		return productNoun === 'Hold' ? 'Message' : productNoun;
	}

	private clientContentLibraryBridge(library: ContentLibraries): ClientContentLibraryBridge {
		const bridgeRow: ClientContentLibraryBridge = new ClientContentLibraryBridge();

		bridgeRow.ClientId = this.appStateService.currentClient.Id;
		bridgeRow.ContentLibraryId = library.Id;
		bridgeRow.ReadAccess = true;
		library.IsGlobal ? (bridgeRow.WriteAccess = false) : (bridgeRow.WriteAccess = true);
		return bridgeRow;
	}

	private setProductProps(productTypeId: number): any {
		switch (productTypeId) {
			case 1:
				return {
					bgColor: 'blue-bg',
					product: 'On Hold',
					icon: 'fas fa-phone-volume'
				};
			case 2:
				return {
					bgColor: 'purple-bg',
					product: 'Radio',
					icon: 'fas fa-volume-up'
				};
			case 3:
				return {
					bgColor: 'red-bg',
					product: 'Video',
					icon: 'fas fa-tv'
				};
			case 4:
				return {
					bgColor: 'red-bg',
					product: 'Poster',
					icon: 'far fa-square'
				};
			case 8:
				return {
					bgColor: 'green-bg',
					product: 'Other',
					icon: 'fas fa-book'
				};
			default:
				return {
					bgColor: 'red-bg',
					product: 'Other Video',
					icon: 'fas fa-th-large'
				};
		}
	}

	public createDefaultLibraries(): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.httpClient.get(`${environment.contentUrl}CreateLibraries/${this.appStateService.currentClient.Id}`).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}
}
