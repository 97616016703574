import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { CreateEditTicketService } from './create-edit-ticket.service';
import { CstLocationNotes, ServiceLocations } from '../../../../shared/api-models/admin';
import { LocationsTicketEdit } from '../_models';
import { UtilitiesService } from '../../../../core/services';

@Injectable({
    providedIn: 'root'
})
export class LocationsService {

    constructor(
            private ceTicketService: CreateEditTicketService,
            private httpClient: HttpClient,
            private utilService: UtilitiesService
    ) { }

    public getCstLocationNotesByClient(): Observable<void> {
        return this.httpClient.get<CstLocationNotes[]>(`${environment.adminUrl}TicketsCstLocationNotes/GetCstLocationNotesByClient/${this.ceTicketService.ticket.ClientId}`).pipe(
            map( (notes: CstLocationNotes[]) => {
                this.ceTicketService.ticket.cstLocationNotesByClient = notes;

                this.ceTicketService.ticket.assignedLocations.forEach( (location) => {
                    let foundNote = this.ceTicketService.ticket.cstLocationNotesByClient.find(note => note.ServiceLocationId === location.Id);

                    if(foundNote) {
                        location.cstLocationNote = JSON.parse(JSON.stringify(foundNote));
                    }
                    
                    if(!location.cstLocationNote) {
                        location.cstLocationNote = new CstLocationNotes();
                        location.cstLocationNote.ClientId = this.ceTicketService.ticket.ClientId;
                        location.cstLocationNote.ServiceLocationId = location.Id;
                    }
                })
            })
        );
    }

    public getClientLocations(): Observable<void> {
        return this.httpClient.get(`${environment.adminUrl}CoreServiceLocations/Client/${this.ceTicketService.ticket.ClientId}`).pipe(
            map((locations: ServiceLocations[]) => {
                if (this.unassignedLocations(locations).length > 0) {
                    this.ceTicketService.ticket.unassignedLocations = this.utilService.sortItems(this.unassignedLocations(locations), 'Name');
                }
            })
        )
    }

    public onCstLocationSave(location: LocationsTicketEdit): Observable<any> {
        let recordExists: boolean = this.ceTicketService.ticket.cstLocationNotesByClient.some(note => note.ServiceLocationId === location.Id);
        if(recordExists) {
            return this.httpClient.patch(`${environment.adminUrl}TicketsCstLocationNotes/${location.cstLocationNote.Id}`, {Notes: location.cstLocationNote.Notes});
        } 
        location.cstLocationNote.ClientId = this.ceTicketService.ticket.ClientId;
        location.cstLocationNote.ServiceLocationId = location.Id;
        return this.httpClient.post(`${environment.adminUrl}TicketsCstLocationNotes`, location.cstLocationNote);
    } 

    private unassignedLocations(locations: ServiceLocations[]): any {
        return locations.filter((location) => {
            if (this.ceTicketService.ticket.assignedLocations) {
                return this.ceTicketService.ticket.assignedLocations.every(assignedLocation => assignedLocation.Id !== location.Id);
            }
        })
    }
}
