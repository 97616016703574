import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AppStateService, Events, MessageService } from '../core/services';
import { environment } from '../../environments/environment';
import { Groups, PlayersView } from './_models';
import { PlayerGroups, UserPlayerGroupsBridge } from '../shared/api-models/admin';

@Injectable()
export class GroupManagerService {

    public allPlayers: PlayersView[];
    public assignedPlayers: PlayersView[] = [];
    public groups: Groups[];

    constructor(
        private appStateService: AppStateService,
        private httpClient: HttpClient,
        private messageService: MessageService,
    ) { }

    public getGroupsAndPlayers(): void {
        let groups$ = this.httpClient.get(`${environment.adminUrl}CoreUsers/${this.appStateService.currentUser.UserId}/PlayerGroups`);
        let allPlayers$ = this.httpClient.get(`${environment.adminUrl}CoreUsers/${this.appStateService.currentUser.UserId}/Players`);

        forkJoin([groups$, allPlayers$]).pipe(
            mergeMap((res: [Groups[], PlayersView[]]) => {
                let groups = res[0];
                let allPlayers = res[1];

                let playersByGroup$ = res[0].map((group) => {
                    return this.httpClient.get(`${environment.adminUrl}CorePlayerGroups/${group.Id}/Players`);
                })

                //Get players for each group
                return forkJoin(playersByGroup$).pipe(
                    map((playersByGroup: PlayersView[][]) => {

                        //Assign players array to each group
                        groups = groups.map((group, index) => {
                            let players = playersByGroup[index].map((player) => {
                                player.groupId = group.Id;
                                return player;
                            })
                            group.players = players;
                            return group;
                        })

                        //Assigned players array is for search
                        this.assignedPlayers = [];
                        groups.forEach((group) => {
                            group.players.forEach((player) => {
                                this.assignedPlayers.push(player);
                            })
                        })
                        this.assignedPlayers = this.assignedPlayers.filter(player => player.ProductTypeId === this.appStateService.product.Id);
                        return [groups, allPlayers];
                    })
                );
            })
        ).subscribe((res: [Groups[], PlayersView[]]) => {
            this.groups = res[0].filter(group => this.appStateService.product.Id === group.ProductTypeId);
            this.allPlayers = res[1].filter(player => this.appStateService.product.Id === player.ProductTypeId);
        })
    }

    public createGroup(name: string): Observable<any> {
        this.messageService.publish(Events.savingPreloader, 1);
        return this.httpClient.post(environment.adminUrl + 'CorePlayerGroups', this.group(name)).pipe(
            mergeMap((newGroup: PlayerGroups) => {
                this.messageService.publish(Events.savingPreloader, 0);
                this.getGroupsAndPlayers();
                let userPlayerGroupsBridge = new UserPlayerGroupsBridge(this.appStateService.currentUser.UserId, newGroup.Id);
                return this.httpClient.post(`${environment.adminUrl}CoreUserPlayerGroupsBridge`, userPlayerGroupsBridge);
            })
        )
    }

    private group(newGroupName: string): PlayerGroups {
        let group: PlayerGroups = new PlayerGroups();

        group.Name = newGroupName;
        group.ClientId = this.appStateService.currentClient.Id;
        group.ProductTypeId = this.appStateService.product.Id;
        return group;
    }

    public updateGroupName(group: Groups): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.httpClient.put(`${environment.adminUrl}CorePlayerGroups/${group.Id}`, group)
            .subscribe(() => {
                this.messageService.publish(Events.savingPreloader, 0);
            })
    }

    public postPlayerGroupsBridge(groupId: number, playerId: number): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.httpClient.post(environment.adminUrl + 'CorePlayerGroupsBridge/', { PlayerGroupId: groupId, PlayerId: playerId })
            .subscribe(() => {
                this.messageService.publish(Events.savingPreloader, 0);
                this.getGroupsAndPlayers();
            });
    }

    public delete(url: string): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.httpClient.delete(url)
            .subscribe(() => {
                this.messageService.publish(Events.savingPreloader, 0);
                this.getGroupsAndPlayers();
            })
    }
}
