import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { CEventsService } from '../../../canvas/_services';
import { LayerDetailService } from '../../_services/layer-detail.service';
import { LayersService, UndoRedoService } from '../../../_services';
import { LayersTimelineWrapperService } from '../../../timeline/layers-timeline-wrapper/_services';
import { UtilitiesService } from 'src/app/core/services';

@Component({
	selector: 'ld-transition-select',
	templateUrl: './transition-select.component.html',
	styleUrls: ['./transition-select.component.scss']
})
export class TransitionSelectComponent implements OnInit, OnDestroy {
	public transInOptions: { name: string }[];
	public transOutOptions: { name: string }[];
	private subs: Subscription[] = [];

	get transitionsDisabled() {
		const isCropping = this.layerDetailService.isCropping;
		const { activeLayer } = this.layersService;

		//Chrome does not support svg images that animate
		//and also have a drop shadow
		const dropShadowChecked = activeLayer.canvasObj.includeDropShadow;
		if (activeLayer.type === 'Feed Image' || activeLayer.type === 'Image') {
			if (dropShadowChecked || isCropping) {
				return true;
			}
			return false;
		}
		return this.layerDetailService.isCropping;
	}

	constructor(
		private cEventsService: CEventsService,
		public layerDetailService: LayerDetailService,
		public layersService: LayersService,
		private ltwService: LayersTimelineWrapperService,
		private undoRedoService: UndoRedoService,
		public utilService: UtilitiesService
	) {
		this.onLayerSelected();
		this.onObjectRotating();
	}

	ngOnInit(): void {
		this.setTransitionInOptions();
		this.setTransitionOutOptions();
	}

	onObjectRotating() {
		this.subs.push(
			this.cEventsService.objectRotating$.subscribe(() => {
				this.setTransitionInOptions();
				this.setTransitionOutOptions();
			})
		);
	}

	onLayerSelected() {
		this.subs.push(
			this.layersService.activeLayerSelected$.subscribe((payload) => {
				if (payload) {
					let groupedObjects: any[] = payload[2];
					if (!groupedObjects) {
						this.setTransitionInOptions();
						this.setTransitionOutOptions();
					}
				}
			})
		);
	}

	public onTransitionSelect(transition: { name: string }, prop: 'transitionIn' | 'transitionOut'): void {
		this.layersService.activeLayer[prop] = transition.name;
		this.layersService.activeLayer.transitionOutStart = this.ltwService.transitionOutStart(this.layersService.activeLayer);
		this.layersService.activeLayer.transitionInEnd = this.ltwService.transitionInEnd(this.layersService.activeLayer);
		this.undoRedoService.recordState('Change transition');
	}

	public setTransitionInOptions(): void {
		if (this.layersService.activeLayer.canvasObj.angle !== 0) {
			this.transInOptions = this.layerDetailService.transitionInOptions.filter((option) => option.name === 'Fade in');
		} else {
			this.transInOptions = this.layerDetailService.transitionInOptions;
		}
	}

	public setTransitionOutOptions(): void {
		if (this.layersService.activeLayer.canvasObj.angle !== 0) {
			this.transOutOptions = this.layerDetailService.transitionOutOptions.filter((option) => option.name === 'Fade out');
		} else {
			this.transOutOptions = this.layerDetailService.transitionOutOptions;
		}
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
