<table style="width: 100%">
	<colgroup>
		<col *ngFor="let column of table?.columns" span="1" [ngStyle]="{ width: column.width + '%' }" />
	</colgroup>
	<thead [ngClass]="config.headerColor === 'white-bg' ? 'dark-text' : 'white-text'" class="{{ config.headerColor }} {{ config.headerFontSize }} shadow ht32">
		<tr>
			<th
				*ngFor="let column of table.columns; let colIndex = index"
				class="pd-left15 pd-right15 semi-bold {{ browserDetectorService.browserIsSafari() ? 'pd-top6 pd-bottom6' : '' }}"
				[class.pointer]="column.isSortable"
				(click)="orderBy(column['key'], column, column['sortKey'])"
			>
				<ng-container *ngIf="!column.showIconOnly && column.type !== 'checkbox'">{{ column['title'] }}</ng-container>
				<ng-container *ngIf="column.showIconOnly"><i class="{{ column['btnIcon'] }}"></i></ng-container>
				<!--SORT DIRECTION ICONS-->
				<ng-container *ngIf="column.isSortable">
					<i *ngIf="(sortBy.key === column['key'] || sortBy.key === column['sortKey']) && sortBy.order === 'asc'" class="fas fa-angle-up mgn-left8"></i>
					<i *ngIf="(sortBy.key === column['key'] || sortBy.key === column['sortKey']) && sortBy.order === 'desc'" class="fas fa-angle-down mgn-left8"></i>
				</ng-container>
				<ng-container *ngIf="column.showIconOnly">
					<i class="{{ column['title'] }}"></i>
				</ng-container>
				<ng-container *ngIf="column.type === 'checkbox'">
					<portal-checkbox
						class="{{ column.elementClassList }}"
						[backgroundColor]="'white-bg'"
						[isChecked]="headerCheckboxClicked"
						[type]="'checkbox'"
						(checkboxClickNotify)="onHeaderCheckboxClick($event)"
					></portal-checkbox>
				</ng-container>
			</th>
		</tr>
	</thead>
	<tbody>
		<tr
			*ngFor="
				let row of table.data
					| sort : sortBy
					| global : globalSearchTerm
					| paginate : { itemsPerPage: config.itemsPerPage, currentPage: page, totalItems: table.totalItems, id: table.guid };
				let rowIndex = index
			"
			[ngClass]="row.border ? 'row-border' : ''"
			[attr.id]="row.Id"
			class="relative {{ row.isDisabled ? 'gray-bg-darker' : null }} {{ row.classList }} {{ row.border }} {{ row.rowBgColor }} {{ config.rowFontSize }} {{
				row.rowTextColor
			}} {{ config.rowSelect ? 'pointer hoverStyle' : null }}"
			(click)="onRowSelect(row)"
		>
			<ng-container *ngFor="let column of table.columns; let colIndex = index">
				<!--IF COLUMN IS A STRING-->
				<!--read mode-->

				<td
					*ngIf="
						column.type === 'string' && renderColReadMode(column, rowIndex, colIndex) && row[column['tooltip']] !== undefined && row[column['tooltip']] !== ''
					"
					[ngClass]="{ 'cursor-text': column.isEditable, setHeight100: config.fixedRowHeight, rowDisabled: row.isDisabled }"
					class="relative pd-left15 pd-right15 {{ column.elementClassList }} {{ row[column['color']] }} text-color-print"
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					[tippyProps]="{ appendTo: 'parent' }"
					attr.data-tippy-content="{{ row[column['tooltip']] }}"
					(click)="onColumnClick(column, row, colIndex, rowIndex)"
					(mouseover)="onColumnOver(column, row, colIndex, rowIndex)"
					(mouseleave)="onColumnLeave()"
				>
					<i
						*ngIf="columnHovered && column.isEditable && selectedRowIndex === rowIndex && selectedColumnIndex === colIndex"
						class="far fa-edit absolute top10 left0 green-text ft-size12"
					></i>
					<p
						[innerHTML]="row | render : column['key']"
						[ngStyle]="{ 'max-height.px': config.fixedRowHeight ? '80' : null, overflow: config.fixedRowHeight ? 'hidden' : null }"
					></p>
				</td>

				<td
					*ngIf="column.type === 'string' && renderColReadMode(column, rowIndex, colIndex) && !row[column['tooltip']]"
					[ngClass]="{ 'cursor-text': column.isEditable, setHeight100: config.fixedRowHeight, rowDisabled: row.isDisabled }"
					class="relative pd-left15 pd-right15 {{ column.elementClassList }} {{ row[column['color']] }} text-color-print"
					(click)="onColumnClick(column, row, colIndex, rowIndex)"
					(mouseover)="onColumnOver(column, row, colIndex, rowIndex)"
					(mouseleave)="onColumnLeave()"
				>
					<i
						*ngIf="columnHovered && column.isEditable && selectedRowIndex === rowIndex && selectedColumnIndex === colIndex"
						class="far fa-edit absolute top10 left0 green-text ft-size12"
					></i>
					<p
						[innerHTML]="row | render : column['key']"
						[ngStyle]="{ 'max-height.px': config.fixedRowHeight ? '80' : null, overflow: config.fixedRowHeight ? 'hidden' : null }"
					></p>
				</td>

				<td *ngIf="column.type === 'string' && column.state === 'editMode' && selectedRowIndex === rowIndex && selectedColumnIndex === colIndex">
					<!--edit mode-->
					<textarea #input rows="12" class="{{ column.elementClassList }}" (click)="onColumnClick(column, row, colIndex, rowIndex)"
						>{{ row | render : column['key'] }}
					</textarea
					>
					<button class="gray-bg setWidth25 pd-top8 pd-bottom8 mgn-right10" (click)="column.state = 'readMode'">
						<i class="fas fa-times"></i>
					</button>
					<button
						*ngIf="column.state === 'editMode' && selectedRowIndex === rowIndex && selectedColumnIndex === colIndex"
						class="white-text limeGreen-bg pd-top8 pd-bottom8 setWidth25"
						(click)="onButtonClick(column, row, input.value)"
					>
						<i class="fas fa-check"></i>
					</button>
				</td>

				<!--IF COLUMN IS A LINK-->
				<td
					*ngIf="column.type === 'link' && !row.isDisabled"
					[ngClass]="{ setHeight100: config.fixedRowHeight }"
					class="pd-left15 pd-right15 {{ column.elementClassList }} pointer"
					(click)="onColumnClick(column, row, colIndex, rowIndex)"
				>
					<a [ngClass]="{ rowDisabled: row.isDisabled }" class="{{ row.rowTextColor }} {{ column.elementClassList }} text-color-print">
						{{ row | render : column['key'] }}
					</a>
				</td>

				<!--IF COLUMN LINK IS DISABLED-->
				<td *ngIf="column.type === 'link' && row.isDisabled">
					<em class="gray-text-darkest">{{ row | render : column['key'] }}</em>
				</td>

				<!--IF COLUMN IS A DATE-->
				<td
					*ngIf="column.type === 'date' && row[column['key']]"
					[ngClass]="{ 'cursor-text': column.isEditable, setHeight100: config.fixedRowHeight, rowDisabled: row.isDisabled }"
					class="pd-left15 pd-right15 {{ column.elementClassList }}"
				>
					<!--Trying to simplify dates, switching to UTC time in this project (Angular9)-->
					<span *ngIf="column.includeTime" [innerHTML]="dateTimeService.dateAndTimeUTCtoLocal(row[column['key']])"></span>

					<span *ngIf="!column.includeTime">
						{{ dateTimeService.dateUTCtoLocal(row[column['key']]) }}
					</span>
					<i *ngIf="row[column['tooltip']]" class="fa fa-calendar green-text mgn-left6" aria-hidden="true"></i>
				</td>

				<td *ngIf="column.type === 'date' && !row[column['key']]" [ngClass]="{ rowDisabled: row.isDisabled }" class="pd-left15 pd-right15">
					<span>N/A</span>
				</td>

				<!--IF COLUMN IS A BUTTON-->
				<td *ngIf="column.type === 'button' && !buttonDisabled(row, column)" class="pd-left15 pd-right15">
					<button
						*ngIf="row[column['tooltip']] !== undefined && row[column['tooltip']] !== ''"
						class="{{ column.elementClassList }} {{ row[column['color']] }} {{ row[column['visibility']] }} relative"
						[ngClass]="{ rowDisabled: buttonDisabled(row, column), setOpacityLow: buttonDisabled(row, column) }"
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ appendTo: 'parent' }"
						attr.data-tippy-content="{{ row[column['tooltip']] }}"
						(click)="onButtonClick(column, row)"
						(mouseover)="buttonMouseoverOn = true"
						(mouseleave)="buttonMouseoverOn = false"
					>
						<span *ngIf="!column.showIconOnly">{{ row | render : column['key'] }}</span>

						<i *ngIf="column.showIconOnly && row[column['btnIcon']]" class="{{ row[column['btnIcon']] }} noExit absolute center-unknown-height-width"></i>
						<i *ngIf="column.showIconOnly && !row[column['btnIcon']]" class="{{ column['title'] }} noExit absolute center-unknown-height-width"></i>
						<div
							*ngIf="row[column['btnBadge']]"
							style="border-radius: 50%; line-height: 1.35rem; width: 1.35rem; font-size: 0.75rem"
							class="red-bg-light white-text shadow z absolute topneg8 rightneg11 center"
						>
							{{ row[column['btnBadge']] }}
						</div>
					</button>
					<button
						*ngIf="!row[column['tooltip']]"
						class="{{ column.elementClassList }} {{ row[column['color']] }} {{ row[column['visibility']] }} relative"
						[ngClass]="{ rowDisabled: buttonDisabled(row, column), setOpacityLow: buttonDisabled(row, column) }"
						(click)="onButtonClick(column, row)"
						(mouseover)="buttonMouseoverOn = true"
						(mouseleave)="buttonMouseoverOn = false"
					>
						<span *ngIf="!column.showIconOnly">{{ row | render : column['key'] }}</span>

						<i *ngIf="column.showIconOnly && row[column['btnIcon']]" class="{{ row[column['btnIcon']] }} noExit absolute center-unknown-height-width"></i>
						<i *ngIf="column.showIconOnly && !row[column['btnIcon']]" class="{{ column['title'] }} noExit absolute center-unknown-height-width"></i>
						<div
							*ngIf="row[column['btnBadge']]"
							style="border-radius: 50%; line-height: 1.35rem; width: 1.35rem; font-size: 0.75rem"
							class="red-bg-light white-text shadow z absolute topneg8 rightneg11 center"
						>
							{{ row[column['btnBadge']] }}
						</div>
					</button>
				</td>

				<!--IF COLUMN BUTTON IS DISABLED-->
				<td *ngIf="column.type === 'button' && buttonDisabled(row, column)" class="pd-left15 pd-right15">
					<button
						[ngClass]="{ rowDisabled: buttonDisabled(row, column), setOpacityLow: buttonDisabled(row, column) }"
						class="{{ column.elementClassList }} {{ row[column['visibility']] }} relative"
						disabled
					>
						<span *ngIf="!column.showIconOnly">{{ row | render : column['key'] }}</span>
						<i *ngIf="column.showIconOnly" class="fa {{ column['title'] }} absolute center-unknown-height-width"></i>
					</button>
				</td>

				<!--IF COLUMN IS ICON-->
				<td *ngIf="column.type === 'icon'" class="pd-left15 pd-right15">
					<i [ngClass]="{ rowDisabled: row.isDisabled }" class="{{ row[column['key']] }} {{ column.elementClassList }}"></i>
				</td>

				<!--IF COLUMN IS ICON AND NUMBER-->
				<td *ngIf="column.type === 'iconAndNumber'">
					<i class="{{ row[column['key']]?.icon }} mgn-right4"></i>
					<span>{{ row[column['key']]?.number }}</span>
				</td>

				<!--IF COLUMN IS AN IMAGE-->
				<td *ngIf="column.type === 'image' && column.snapshotDimensions" class="pd-left15 pd-right15">
					<img
						[ngClass]="{ rowDisabled: row.isDisabled, setOpacityLow: row.isDisabled }"
						onError="this.src='assets/no-image.jpg';"
						class="{{ column.elementClassList }} border-radius relative"
						src="{{ row | render : column['key'] }}"
						[ngStyle]="{
							transform: isHovered && row.Id === rowId && !row.isDisabled ? 'scale(1.5)' : 'scale(1)',
							'height.px': row?.snapshotDimensions?.height,
							'width.px': row?.snapshotDimensions?.width,
							'z-index': isHovered && row.Id === rowId ? 999 : 0
						}"
						style="transition: transform 0.3s ease-in-out"
						(mouseover)="onSnapshotHover(row.Id)"
						(mouseout)="isHovered = false"
					/>
				</td>

				<td *ngIf="column.type === 'image' && !column.snapshotDimensions" class="pd-left15 pd-right15">
					<img
						[ngClass]="{ rowDisabled: row.isDisabled, setOpacityLow: row.isDisabled }"
						(mouseover)="onColumnOver(column, row, colIndex, rowIndex)"
						(mouseleave)="columnLeaveNotify.emit()"
						onError="this.src='assets/no-image.jpg';"
						class="{{ column.elementClassList }} border-radius"
						src="{{ row | render : column['key'] }}"
					/>
				</td>

				<!--IF COLUMN IS A CHECKBOX-->
				<td *ngIf="column.type === 'checkbox'" class="pd-left15 pd-right15">
					<portal-checkbox
						class="{{ column.elementClassList }} {{ row[column['visibility']] }}"
						[backgroundColor]="'white-bg'"
						[isChecked]="row.isSelected"
						[isDisabled]="row.isDisabled"
						[type]="'checkbox'"
						(click)="$event.stopPropagation()"
						(checkboxClickNotify)="onCheckboxClick(row)"
					></portal-checkbox>
				</td>
			</ng-container>
		</tr>
	</tbody>
</table>

<div class="mgn-top15 {{ table.data?.length ? 'mgn-bottom40' : null }}">
	<div *ngIf="table.data?.length && !config.paginationDisabled" class="row">
		<div class="col-sm-6 text-left">
			<pagination-controls id="{{ table.guid }}" (pageChange)="currentPage($event)" class="pagination"></pagination-controls>
		</div>
		<div *ngIf="table.totalItems" class="col-sm-6 text-right">
			<span>
				<span class="semi-bold">Total Items:</span>
				{{ table.totalItems }}
			</span>
		</div>
	</div>
	<div *ngIf="showJumpToPage && !config.paginationDisabled" class="row mgn-top15 mgn-bottom60">
		<div class="col-sm-1">
			<span>Go to page</span>
			<input
				class="setWidth100 ht40 gray-bg-dark ln-height40"
				#pageSelector
				name="pageSelector"
				[(ngModel)]="page"
				(input)="currentPage(+pageSelector.value)"
				type="number"
			/>
		</div>
	</div>
</div>
