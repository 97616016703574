import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursMinutesSeconds'
})
export class HoursMinutesSecondsPipe implements PipeTransform {

  transform(seconds: number): string {
		let ms: any = Math.floor((seconds*1000) % 1000);
	    let s: any = Math.floor(seconds%60);
	    let m: any = Math.floor((seconds*1000/(1000*60))%60);
	    let strFormat: any = "MM:SS:XX";
	    
	    if(s < 10) s = "0" + s;
	    if(m < 10) m = "0" + m;
	    if(ms < 10) ms = "0" + ms;

	    strFormat = strFormat.replace(/MM/, m);
	    strFormat = strFormat.replace(/SS/, s);
	    strFormat = strFormat.replace(/XX/, ms.toString().slice(0,2));
	    
	    return strFormat;
  }
}