import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DurationOptionsViewService {

    public clipDurationsView = [
        {
            name: '1 sec.',
            time: 1000
        },
        {
            name: '2 sec.',
            time: 2000
        },
        {
            name: '5 sec.',
            time: 5000
        },
        {
            name: '10 sec.',
            time: 10000
        },
        {
            name: '15 sec.',
            time: 15000
        },
        {
            name: '20 sec.',
            time: 20000
        },
        {
            name: '25 sec.',
            time: 25000
        },
        {
            name: '30 sec.',
            time: 30000
        },
        {
            name: '45 sec.',
            time: 45000
        },
        {
            name: '1 min.',
            time: 60000
        },
        {
            name: '2 min.',
            time: 120000
        },
        {
            name: '5 min.',
            time: 300000
        },
        {
            name: '10 min.',
            time: 600000
        },
        {
            name: '15 min.',
            time: 900000
        }
    ]
    public holdDurationsView = [
        {
            name: '1 sec.',
            time: 1000
        },
        {
            name: '2 sec.',
            time: 2000
        },
        {
            name: '3 sec.',
            time: 3000
        },
        {
            name: '5 sec.',
            time: 5000
        },
        {
            name: '10 sec.',
            time: 10000
        },
        {
            name: '15 sec.',
            time: 15000
        },
        {
            name: '20 sec.',
            time: 20000
        },
        {
            name: '25 sec.',
            time: 25000
        },
        {
            name: '30 sec.',
            time: 30000
        },
        {
            name: '45 sec.',
            time: 45000
        },
        {
            name: '60 sec.',
            time: 60000
        },
        {
            name: '2 min.',
            time: 120000
        },
        {
            name: '5 min.',
            time: 300000
        }
    ]
    constructor() { }

    public getDurationView(time: number): string {
        switch(time) {
            case 1000:
                return '1 sec.';
            case 2000:
                return '2 sec.';
            case 3000:
                return '3 sec.';
            case 5000:
                return '5 sec.';
            case 10000:
                return '10 sec.';
            case 15000:
                return '15 sec.';
            case 20000:
                return '20 sec.';
            case 25000:
                return '25 sec.';
            case 30000:
                return '30 sec.';
            case 60000:
                return '1 min.';
            case 120000:
                return '2 min.';
            case 300000:
                return '5 min.'
            case 600000:
                return '10 min.';
            case 900000:
                return '15 min.';
        }
    }
}
