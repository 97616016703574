import { Injectable } from '@angular/core';

import { AppStateService } from 'src/app/core/services';
import { CanvasService } from '../canvas/_services/canvas.service';
import { CountdownAndDateService } from '../create-layer-sidebar/countdown-and-date/countdown-and-date.service';
import { Create24Service } from '../../create-24.service';
import { ExportSvgScriptsService } from './export-svg-scripts.service';
import { ExportUtilService } from './export-util.service';
import { FeedService } from './feed.service';
import { Layer } from '../_models';
import { LayersService } from './layers.service';
import { StateService } from './state.service';
import { UtilitiesService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root'
})
export class ExportSVGService {
	constructor(
		private appStateService: AppStateService,
		private canvasService: CanvasService,
		private countdownAndDateService: CountdownAndDateService,
		private create24Service: Create24Service,
		private exportSvgScriptsService: ExportSvgScriptsService,
		private exportUtilService: ExportUtilService,
		private feedService: FeedService,
		private layersService: LayersService,
		private stateService: StateService,
		private utilService: UtilitiesService
	) {}

	private formatForPlayer(payloadString): any {
		const domParser = new DOMParser();
		const doc = domParser.parseFromString(payloadString, 'text/xml');
		const svg = doc.getElementsByTagName('svg')[0];
		const circleTags = Array.from(doc.querySelectorAll('circle'));
		const rectTags = Array.from(doc.querySelectorAll('rect'));
		const lineTags = Array.from(doc.querySelectorAll('line'));
		const gTags = Array.from(doc.querySelectorAll('g'));
		const defs = doc.getElementsByTagName('defs')[0];

		const duration = this.canvasService.bgContent?.contentFiles[0].DurationMs;
		defs.insertAdjacentHTML('beforeend', `<text id="clipDuration">${duration / 1000}</text>`);
		const countdownDateLayer: Layer = this.layersService.layers.find((layer) => layer.type === 'Countdown');
		const currentDateLayer: Layer = this.layersService.layers.find((layer) => layer.type === 'Current Date');
		const currentTimeLayer: Layer = this.layersService.layers.find((layer) => layer.type === 'Current Time');

		if (!!countdownDateLayer) {
			defs.insertAdjacentHTML('beforeend', `<text id="countdownDateTime">${countdownDateLayer.countdownDate}</text>`);
			defs.insertAdjacentHTML('beforeend', `<text id="countdownInterval">${this.countdownAndDateService.countdownInterval}</text>`);
			defs.insertAdjacentHTML('beforeend', `<text id="countdownOptions">${countdownDateLayer.countdownDisplayOption}</text>`);
		}
		if (!!currentDateLayer) {
			defs.insertAdjacentHTML(
				'beforeend',
				`<text id="dateOptions">${this.countdownAndDateService.dateOptions(currentDateLayer.countdownDisplayOption)}</text>`
			);

			if (
				currentDateLayer.countdownDisplayOption === 'dayMonthDate' ||
				currentDateLayer.countdownDisplayOption === 'dayMonthDateYear' ||
				currentDateLayer.countdownDisplayOption === 'short'
			) {
				defs.insertAdjacentHTML('beforeend', `<text id="dateDisplay">OneLayer</text>`);
			} else {
				defs.insertAdjacentHTML('beforeend', `<text id="dateDisplay">${currentDateLayer.countdownDisplayOption}</text>`);
			}
		}
		if (!!currentTimeLayer) {
			defs.insertAdjacentHTML('beforeend', `<text id="timeDisplay">${currentTimeLayer.countdownDisplayOption}</text>`);
		}

		//Shadows not working for lines. This code enables the shadow in the SVG output,
		//but I'm unable to change the code for the UI
		// const list = doc.getElementsByTagName("filter");
		// for(const item in list) {
		//     const nodeName = list[item]?.nextSibling?.nextSibling?.nodeName;
		//     if(nodeName === 'line') {
		//         list[item].setAttribute('filterUnits', 'userSpaceOnUse');
		//     }
		// }

		gTags.forEach((g) => {
			const gChildren = Array.from(g.children);
			const textTags = gChildren.filter((c) => c.getAttribute('name') === 'feedText' || c.getAttribute('name') === 'text');
			textTags.forEach((text) => {
				const layerId: string = text.getAttribute('layerId');
				const nameAttr: string = text.getAttribute('name');
				const isFeedTextAttr: string = text.getAttribute('isFeedText');
				const layer = this.exportUtilService.matchingLayer(+layerId);
				if (nameAttr === 'text' && isFeedTextAttr && isFeedTextAttr !== 'undefined') {
					text.setAttribute('visibility', 'hidden');
				} else {
					Array.from(text.children).forEach((t) => {
						const x = t.getAttribute('x');
						const y = t.getAttribute('y');

						if (layer.countdownLayerView === 'hoursMinutesAmPm') {
							layer.countdownLayerView = 'currentTime';
						}

						//Insert countdown/todays date/current time display option as tspan id
						if (layer.countdownLayerView === 'dayMonthDate' || layer.countdownLayerView === 'dayMonthDateYear' || layer.countdownLayerView === 'short') {
							t.setAttribute('id', 'todaysDate');
						} else {
							t.setAttribute('id', layer.countdownLayerView);
						}

						if (layer.visible) {
							switch (true) {
								case layer.canvasObj.strokeWidth > 0 && !layer.canvasObj.borderOnly:
									//animate fill opacity and stroke opacity
									t.insertAdjacentHTML(
										'beforeend',
										this.inAnimateTagXy('</text>', +layerId, x, y) +
											this.outAnimateTagXy('</text>', +layerId, x, y) +
											this.inAnimateTagXy('</text>', +layerId, x, y, true) +
											this.outAnimateTagXy('</text>', +layerId, x, y, true)
									);
									break;
								case layer.canvasObj.strokeWidth > 0 && layer.canvasObj.borderOnly:
									//animate stroke opacity only
									t.insertAdjacentHTML(
										'beforeend',
										this.inAnimateTagXy('</text>', +layerId, x, y, true) + this.outAnimateTagXy('</text>', +layerId, x, y, true)
									);
									break;
								case layer.canvasObj.strokeWidth < 1:
									//animate fill opacity only
									t.insertAdjacentHTML('beforeend', this.inAnimateTagXy('</text>', +layerId, x, y) + this.outAnimateTagXy('</text>', +layerId, x, y));
									break;
							}
						} else {
							text.setAttribute('visibility', 'hidden');
						}
					});
				}
			});

			const imgTags = gChildren.filter((c) => c.getAttribute('name') === 'image');

			imgTags.forEach((img) => {
				const layerId: string = img.getAttribute('layerId');
				const layer = this.exportUtilService.matchingLayer(+layerId);
				const x = img.getAttribute('x');
				const y = img.getAttribute('y');
				const width = img.getAttribute('width');
				const height = img.getAttribute('height');
				const isFeedTextImage = img.getAttribute('isFeedTextImage');
				const isFeedImage = img.getAttribute('isFeedImage');

				if (isFeedTextImage && isFeedTextImage !== 'undefined') {
					img.remove();
					const obj = this.canvasService.canvas.getObjects().find((obj) => obj.layerId === layer.id && obj.isFeedText);
					g.insertAdjacentHTML('beforeend', this.feedTextImgTag(x, y, width, height, obj, layer));
				}

				if (isFeedImage && isFeedImage !== 'undefined') {
					img.setAttribute('class', 'c24FeedImage');
				}

				const gChildren = Array.from(g.children); //gChildren needs to be redefined here
				if (layer.visible) {
					//Insert animate tag before closing tag
					gChildren.forEach((img) =>
						img.insertAdjacentHTML('beforeend', this.inAnimateTagXy('</image>', +layerId, x, y) + this.outAnimateTagXy('</image>', +layerId, x, y))
					);
				} else {
					img.setAttribute('visibility', 'hidden');
				}
			});
		});

		//Preserves aspect ratio
		svg.setAttribute('preserveAspectRatio', 'none'); //changed from 'xMidYMid meet' to none
		svg.removeAttribute('width');
		svg.removeAttribute('height');
		let blankBgColor;

		//Fixes bug where blank bg was originally selected, but then changed to image
		//Bug kept the blank bg instead of the newer image
		if (!!this.stateService.state().bgContent) {
			blankBgColor = null;
		} else {
			blankBgColor = this.stateService.state().blankBgColor;
		}

		if (this.appStateService.product.ProductName === 'Poster') {
			svg.setAttribute('style', `position:absolute; width:50%; left:0; background-color: ${blankBgColor}`);
			svg.setAttribute('class', `center-unknown-height-width`);
		} else {
			svg.setAttribute('style', `position:absolute; width:100%; left:0; background-color: ${blankBgColor}`);
		}
		svg.setAttribute('id', 'c24Svg');

		//Remove x and y from transparent rect layer
		rectTags
			.find((tag, i) => {
				return i === 0;
			})
			.removeAttribute('x');
		rectTags
			.find((tag, i) => {
				return i === 0;
			})
			.removeAttribute('y');

		rectTags.forEach((rect, i) => {
			const layerId: string = rect.getAttribute('layerId');
			const layer = this.exportUtilService.matchingLayer(+layerId);
			const x = rect.getAttribute('x');
			const y = rect.getAttribute('y');
			const isHiddenFeedImageBoundingBox = layer.type === 'Feed Image';

			//Hide feed image bounding box
			if (layer.visible && !isHiddenFeedImageBoundingBox) {
				//Insert animate tag before closing tag
				switch (true) {
					case layer.canvasObj.strokeWidth > 0 && !layer.canvasObj.borderOnly:
						//animate fill opacity and stroke opacity
						rect.insertAdjacentHTML(
							'beforeend',
							this.inAnimateTagXy('</rect>', +layerId, x, y) +
								this.outAnimateTagXy('</rect>', +layerId, x, y) +
								this.inAnimateTagXy('</rect>', +layerId, x, y, true) +
								this.outAnimateTagXy('</rect>', +layerId, x, y, true)
						);
						break;
					case layer.canvasObj.strokeWidth > 0 && layer.canvasObj.borderOnly:
						//animate stroke opacity only
						rect.insertAdjacentHTML('beforeend', this.inAnimateTagXy('</rect>', +layerId, x, y, true) + this.outAnimateTagXy('</rect>', +layerId, x, y, true));
						break;
					case layer.canvasObj.strokeWidth < 1:
						//animate fill opacity only
						rect.insertAdjacentHTML('beforeend', this.inAnimateTagXy('</rect>', +layerId, x, y) + this.outAnimateTagXy('</rect>', +layerId, x, y));
						break;
				}
			} else {
				rect.setAttribute('visibility', 'hidden');
			}
		});

		lineTags.forEach((line) => {
			const layerId: string = line.getAttribute('layerId');
			const layer = this.exportUtilService.matchingLayer(+layerId);
			const x1: string = line.getAttribute('x1');
			const x2: string = line.getAttribute('x2');
			const y1: string = line.getAttribute('y1');
			const y2: string = line.getAttribute('y2');
			const coords: any = { x1: x1, x2: x2, y1: y1, y2: y2 };

			const attrIn = () => {
				switch (true) {
					case this.includes(layer.transitionIn, 'down') || this.includes(layer.transitionIn, 'up'):
						return 'y';
					case this.includes(layer.transitionIn, 'right') || this.includes(layer.transitionIn, 'left'):
						return 'x';
				}
			};

			const attrOut = () => {
				switch (true) {
					case this.includes(layer.transitionOut, 'down') || this.includes(layer.transitionOut, 'up'):
						return 'y';
					case this.includes(layer.transitionOut, 'right') || this.includes(layer.transitionOut, 'left'):
						return 'x';
				}
			};

			if (layer.visible) {
				//Insert animate tag before closing tag
				line.insertAdjacentHTML(
					'beforeend',
					this.inAnimateTagLine('</line>', +layerId, attrIn() + '1', coords) +
						this.inAnimateTagLine('</line>', +layerId, attrIn() + '2', coords) +
						this.outAnimateTagLine('</line>', +layerId, attrOut() + '1', coords) +
						this.outAnimateTagLine('</line>', +layerId, attrOut() + '2', coords)
				);
			} else {
				line.setAttribute('visibility', 'hidden');
			}
		});

		circleTags.forEach((circle) => {
			const attributes = Array.from(circle.attributes);
			const layerId: string = circle.getAttribute('layerId');
			const ellipse = doc.createElement('ellipse');
			const layer = this.exportUtilService.matchingLayer(+layerId);

			//Add all attributes from circle to ellipse element
			attributes.forEach((attr) => ellipse.setAttribute(attr.name, attr.value));
			const cx: string = circle.getAttribute('cx');
			const cy: string = circle.getAttribute('cy');
			const coords: any = { cx: cx, cy: cy };

			const attrIn = () => {
				switch (true) {
					case this.includes(layer.transitionIn, 'down') || this.includes(layer.transitionIn, 'up'):
						return 'cy';
					case this.includes(layer.transitionIn, 'right') || this.includes(layer.transitionIn, 'left'):
						return 'cx';
				}
			};

			const attrOut = () => {
				switch (true) {
					case this.includes(layer.transitionOut, 'down') || this.includes(layer.transitionOut, 'up'):
						return 'cy';
					case this.includes(layer.transitionOut, 'right') || this.includes(layer.transitionOut, 'left'):
						return 'cx';
				}
			};

			if (layer.visible) {
				//Insert animate tag before closing tag
				circle.insertAdjacentHTML(
					'beforeend',
					this.inAnimateTagCircle('</circle>', +layerId, attrIn(), coords) + this.outAnimateTagCircle('</circle>', +layerId, attrOut(), coords)
				);
			} else {
				circle.setAttribute('visibility', 'hidden');
			}
		});

		//Remove image and rect tags that do not have a layerId (thumbnail and transparent layer respectively)
		doc.querySelectorAll('image').forEach((img) => (+img.getAttribute('layerId') < 1 ? img.remove() : null));
		doc.querySelectorAll('rect').forEach((rect) => (+rect.getAttribute('layerId') < 1 ? rect.remove() : null));

		//Insert script tags for dynamic content
		svg.setAttribute('onload', 'c24DynamicContentLoad()');
		svg.insertAdjacentHTML('beforeend', this.exportSvgScriptsService.script());

		return this.xmlDoctype() + doc.documentElement.outerHTML.replace(/>\s+</g, '><'); //Replace removes white space between tags
	}

	//Feed text is converted to an image on the server
	private feedTextImgTag(x, y, width, height, obj, layer: Layer): string {
		return `
        <image style="visibility: ${this.create24Service.contentType() === 'image' ? 'visible' : 'hidden'}" class="${this.feedService.className(
			layer.feedSourceName
		)}" ${this.feedService.dataFormatAttr(layer)}"${this.feedService.serializeJson(JSON.stringify(this.feedService.textJson(obj)))}" href="${
			layer.previewUrl
		}" x="${x}" y="${y}" vector-effect="non-scaling-stroke" width="${width}" height="${height}" layerId="${obj.layerId}" name="${obj.name}"></image>
        `;
	}

	private xmlDoctype(): string {
		return `<?xml version="1.0" encoding="UTF-8" ?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`;
	}

	public finalOutput(str: string): string {
		return this.formatForPlayer(str);
	}

	private inAnimateTagLine(tag: string, layerId: number, attr: string, coords: any): string {
		const transitionIn: string = this.exportUtilService.matchingLayer(layerId).transitionIn;

		switch (true) {
			case this.includes(transitionIn, 'Slide'):
				return this.lineSlideInAnimateTag(layerId, attr, coords);
			case this.includes(transitionIn, 'Fade'):
				return this.fadeInAnimateTag(layerId, this.fadeAttrName(tag));
			default:
				return this.noAnimateStart(layerId);
		}
	}

	private outAnimateTagLine(tag: string, layerId: number, attr: string, coords: any): string {
		let transitionOut: string = this.exportUtilService.matchingLayer(layerId).transitionOut;
		if (this.create24Service.contentType() === 'image') {
			transitionOut = 'Not set';
		}

		switch (true) {
			case this.includes(transitionOut, 'Slide'):
				return this.lineSlideOutAnimateTag(layerId, attr, coords);
			case this.includes(transitionOut, 'Fade'):
				return this.fadeOutAnimateTag(layerId, this.fadeAttrName(tag));
			default:
				return this.noAnimateEnd(layerId);
		}
	}

	private inAnimateTagXy(tag: string, layerId: number, x: string, y: string, isTextStroke?: boolean): string {
		const transitionIn: string = this.exportUtilService.matchingLayer(layerId).transitionIn;

		switch (true) {
			case this.includes(transitionIn, 'Slide'):
				switch (tag) {
					case '</rect>':
					case '</image>':
						return this.rectSlideInAnimateTag(layerId, x, y);
					case '</text>':
						return this.textSlideInAnimateTag(layerId, x, y);
				}
				break;

			case this.includes(transitionIn, 'Fade'):
				return this.fadeInAnimateTag(layerId, this.fadeAttrName(tag, isTextStroke));
			default:
				return this.noAnimateStart(layerId);
		}
	}

	private outAnimateTagXy(tag: string, layerId: number, x: string, y: string, isTextStroke?: boolean): string {
		let transitionOut: string = this.exportUtilService.matchingLayer(layerId).transitionOut;
		if (this.create24Service.contentType() === 'image') {
			transitionOut = 'Not set';
		}

		switch (true) {
			case this.includes(transitionOut, 'Slide'):
				switch (tag) {
					case '</rect>':
					case '</image>':
						return this.rectSlideOutAnimateTag(layerId, x, y);
					case '</text>':
						return this.textSlideOutAnimateTag(layerId, x, y);
				}
				break;

			case this.includes(transitionOut, 'Fade'):
				return this.fadeOutAnimateTag(layerId, this.fadeAttrName(tag, isTextStroke));
			default:
				return this.noAnimateEnd(layerId);
		}
	}

	private inAnimateTagCircle(tag: string, layerId: number, attr: string, coords: any): string {
		const transitionIn: string = this.exportUtilService.matchingLayer(layerId).transitionIn;

		switch (true) {
			case this.includes(transitionIn, 'Slide'):
				return this.circleSlideInAnimateTag(layerId, attr, coords);

			case this.includes(transitionIn, 'Fade'):
				return this.fadeInAnimateTag(layerId, this.fadeAttrName(tag));
			default:
				return this.noAnimateStart(layerId);
		}
	}

	private outAnimateTagCircle(tag: string, layerId: number, attr: string, coords: any): string {
		let transitionOut: string = this.exportUtilService.matchingLayer(layerId).transitionOut;
		if (this.create24Service.contentType() === 'image') {
			transitionOut = 'Not set';
		}

		switch (true) {
			case this.includes(transitionOut, 'Slide'):
				return this.circleSlideOutAnimateTag(layerId, attr, coords);

			case this.includes(transitionOut, 'Fade'):
				return this.fadeOutAnimateTag(layerId, this.fadeAttrName(tag));
			default:
				return this.noAnimateStart(layerId);
		}
	}

	private fadeAttrName(objTag: string, isTextStroke?: boolean): string {
		if (isTextStroke) {
			return 'stroke-opacity';
		}
		switch (objTag) {
			case '</line>':
				return 'stroke-opacity';
			case '</image>':
				return 'opacity';
			default:
				return 'fill-opacity';
		}
	}

	private noAnimateStart(layerId: number): string {
		const duration: number = this.canvasService.bgContent?.contentFiles[0].DurationMs;
		//No animate tag if duration === 0 (still images)
		if (duration > 0) {
			return `
            <animate 
                attributeType="xml"
                attributeName="fill-opacity" 
                begin="${this.exportUtilService.layerStartOrEnd('start', layerId) / 1000}s" />
            <set attributeName="visibility" from="hidden" to="visible" begin="${this.exportUtilService.layerStartOrEnd('start', layerId) / 1000}s" />`;
		}
		return '';
	}

	private noAnimateEnd(layerId: number): string {
		const duration: number = this.canvasService.bgContent?.contentFiles[0].DurationMs;
		//No animate tag if duration === 0 (still images)
		if (duration > 0) {
			return `
            <animate 
                attributeType="xml"
                attributeName="fill-opacity" 
                begin="${this.exportUtilService.layerStartOrEnd('end', layerId) / 1000}s" />
            <set attributeName="visibility" from="visible" to="hidden" begin="${this.exportUtilService.layerStartOrEnd('end', layerId) / 1000}s" />`;
		}
		return '';
	}

	private fadeInAnimateTag(layerId: number, attrName: string): string {
		return `
        <animate 
            attributeType="xml"
            attributeName="${attrName}" 
            begin="${this.exportUtilService.layerStartOrEnd('start', layerId) / 1000}s" 
            values="0;1"
            calcMode="spline"
            keySplines="0.28 0 0.26 0.99"
            dur="1.25s"
            repeatCount="1" 
            fill="freeze"/>
        <set attributeName="visibility" from="hidden" to="visible" begin="${this.exportUtilService.layerStartOrEnd('start', layerId) / 1000}s" />`;
	}

	private fadeOutAnimateTag(layerId: number, attrName: string): string {
		return `
        <animate 
            attributeType="xml"
            attributeName="${attrName}" 
            begin="${this.exportUtilService.layerStartOrEnd('end', layerId) / 1000}s" 
            values="1;0"
            calcMode="spline"
            keySplines="0.7 0 0.53 0.99"
            dur="1.25s"
            repeatCount="1" 
            fill="freeze"/>
        <set attributeName="visibility" from="visible" to="hidden" begin="${this.exportUtilService.layerStartOrEnd('end', layerId) / 1000 + 2}s" />`;
	}

	private circleSlideInAnimateTag(layerId: number, attr: string, coords: any): string {
		return `
        <animate 
        attributeName="${attr}"
        attributeType="XML"
        begin="${this.exportUtilService.layerStartOrEnd('start', layerId) / 1000}s"
        dur="1.25s"
        values="${this.circleAnimateInValues(layerId, coords)}"
        repeatCount="1"
        fill="freeze"
        calcMode="spline"
        keySplines="0.14 0.22 0.12 0.99"/>
        <set attributeName="visibility" from="hidden" to="visible" begin="${this.exportUtilService.layerStartOrEnd('start', layerId) / 1000}s"/>
        `;
	}

	private circleSlideOutAnimateTag(layerId: number, attr: string, coords: any): string {
		return `
        <animate 
        attributeName="${attr}"
        attributeType="XML"
        begin="${this.exportUtilService.layerStartOrEnd('end', layerId) / 1000}s"
        dur="1.25s"
        values="${this.circleAnimateOutValues(layerId, coords)}"
        repeatCount="1"
        fill="freeze"
        calcMode="spline"
        keySplines="0.7 0 0.53 0.99"/>
        <set attributeName="visibility" from="visible" to="hidden" begin="${this.exportUtilService.layerStartOrEnd('end', layerId) / 1000 + 2}s"/>
        `;
	}

	private rectSlideInAnimateTag(layerId: number, x, y): string {
		return `
        <animate 
        attributeName="${this.rectAnimateInAttributes(layerId, x, y).attributeName}"
        attributeType="XML"
        begin="${this.exportUtilService.layerStartOrEnd('start', layerId) / 1000}s"
        dur="1.25s"
        values="${this.rectAnimateInAttributes(layerId, x, y).values}"
        repeatCount="1"
        fill="freeze"
        calcMode="spline"
        keySplines="0.14 0.22 0.12 0.99"/>
        <set attributeName="visibility" from="hidden" to="visible" begin="${this.exportUtilService.layerStartOrEnd('start', layerId) / 1000}s"/>
        `;
	}

	private rectSlideOutAnimateTag(layerId: number, x, y): string {
		return `
        <animate 
        attributeName="${this.rectAnimateOutAttributes(layerId, x, y).attributeName}"
        attributeType="XML"
        begin="${this.exportUtilService.layerStartOrEnd('end', layerId) / 1000}s"
        dur="1.25s"
        values="${this.rectAnimateOutAttributes(layerId, x, y).values}"
        repeatCount="1"
        fill="freeze"
        calcMode="spline"
        keySplines="0.7 0 0.53 0.99"/>
        <set attributeName="visibility" from="visible" to="hidden" begin="${this.exportUtilService.layerStartOrEnd('end', layerId) / 1000 + 2}s"/>
        `;
	}

	private textSlideInAnimateTag(layerId: number, x, y): string {
		return `
        <animate 
        attributeName="${this.textAnimateInAttributes(layerId, x, y).attributeName}"
        attributeType="XML"
        begin="${this.exportUtilService.layerStartOrEnd('start', layerId) / 1000}s"
        dur="1.25s"
        values="${this.textAnimateInAttributes(layerId, x, y).values}"
        repeatCount="1"
        fill="freeze"
        calcMode="spline"
        keySplines="0.14 0.22 0.12 0.99"/>
        <set attributeName="visibility" from="hidden" to="visible" begin="${this.exportUtilService.layerStartOrEnd('start', layerId) / 1000}s"/>
        `;
	}

	private textSlideOutAnimateTag(layerId: number, x, y): string {
		return `
        <animate 
        attributeName="${this.textAnimateOutAttributes(layerId, x, y).attributeName}"
        attributeType="XML"
        begin="${this.exportUtilService.layerStartOrEnd('end', layerId) / 1000}s"
        dur="1.25s"
        values="${this.textAnimateOutAttributes(layerId, x, y).values}"
        repeatCount="1"
        fill="freeze"
        calcMode="spline"
        keySplines="0.7 0 0.53 0.99"/>
        <set attributeName="visibility" from="visible" to="hidden" begin="${this.exportUtilService.layerStartOrEnd('end', layerId) / 1000 + 2}s"/>
        `;
	}

	private lineSlideInAnimateTag(layerId: number, attr: string, coords: any): string {
		return `
        <animate 
        attributeName="${attr}"
        attributeType="XML"
        begin="${this.exportUtilService.layerStartOrEnd('start', layerId) / 1000}s"
        dur="1.25s"
        values="${this.lineAnimateInValues(layerId, attr, coords)}"
        repeatCount="1"
        fill="freeze"
        calcMode="spline"
        keySplines="0.14 0.22 0.12 0.99"/>
        <set attributeName="visibility" from="hidden" to="visible" begin="${this.exportUtilService.layerStartOrEnd('start', layerId) / 1000}s"/>
        `;
	}

	private lineSlideOutAnimateTag(layerId: number, attr: string, coords: any): string {
		return `
        <animate 
        attributeName="${attr}"
        attributeType="XML"
        begin="${this.exportUtilService.layerStartOrEnd('end', layerId) / 1000}s"
        dur="1.25s"
        values="${this.lineAnimateOutValues(layerId, attr, coords)}"
        repeatCount="1"
        fill="freeze"
        calcMode="spline"
        keySplines="0.7 0 0.53 0.99"/>
        <set attributeName="visibility" from="visible" to="hidden" begin="${this.exportUtilService.layerStartOrEnd('end', layerId) / 1000 + 2}s"/>
        `;
	}

	private lineAnimateInValues(layerId: number, attr: string, coords: any): string {
		const layer = this.exportUtilService.matchingLayer(layerId);
		const obj = this.canvasService.canvas.getObjects().find((obj) => obj.layerId === layerId);
		const translateX = obj.calcTransformMatrix()[4];
		const translateY = obj.calcTransformMatrix()[5];
		const animateUpFromY = (this.canvasHeight() - translateY) / obj.scaleY + obj.strokeWidth;
		const animateDownFromY = -(translateY + obj.padding * 2);
		const animateLeftFromX = -(translateX / obj.scaleX + obj.width);
		const animateRightFromX = (this.canvasWidth() - translateX) / obj.scaleX;

		switch (true) {
			case this.includes(layer.transitionIn, 'left'):
				switch (attr) {
					case 'x1':
						return `${animateLeftFromX}; ${coords.x1}`;
					case 'x2':
						return `${animateLeftFromX + (obj.width + obj.scaleX)}; ${coords.x2}`;
				}
			case this.includes(layer.transitionIn, 'right'):
				switch (attr) {
					case 'x1':
						return `${animateRightFromX}; ${coords.x1}`;
					case 'x2':
						return `${animateRightFromX + (obj.width + obj.scaleX)}; ${coords.x2}`;
				}

			case this.includes(layer.transitionIn, 'up'):
				return `${animateUpFromY}; ${coords[attr]}`;

			case this.includes(layer.transitionIn, 'down'):
				return `${animateDownFromY}; ${coords[attr]}`;
		}
	}

	private lineAnimateOutValues(layerId: number, attr: string, coords: any): string {
		const layer = this.exportUtilService.matchingLayer(layerId);
		const obj = this.canvasService.canvas.getObjects().find((obj) => obj.layerId === layerId);
		const translateX = obj.calcTransformMatrix()[4];
		const translateY = obj.calcTransformMatrix()[5];
		const animateUpToY = -(translateY + obj.padding * 2);
		const animateDownToY = (this.canvasHeight() - translateY) / obj.scaleY + obj.strokeWidth;
		const animateLeftToX = -(translateX / obj.scaleX + obj.width);
		const animateRightToX = (this.canvasWidth() - translateX) / obj.scaleX;

		//Return x or y and start/end values, according to selected transition
		switch (true) {
			case this.includes(layer.transitionOut, 'left'):
				switch (attr) {
					case 'x1':
						return `${coords.x1}; ${animateLeftToX};`;
					case 'x2':
						return `${coords.x2}; ${animateLeftToX + obj.width * obj.scaleX}`;
				}
			case this.includes(layer.transitionOut, 'right'):
				switch (attr) {
					case 'x1':
						return `${coords.x1}; ${animateRightToX}`;
					case 'x2':
						return `${coords.x2}; ${animateRightToX + obj.width * obj.scaleX}`;
				}

			case this.includes(layer.transitionOut, 'up'):
				return `${coords[attr]}; ${animateUpToY}`;

			case this.includes(layer.transitionOut, 'down'):
				return `${coords[attr]}; ${animateDownToY}`;
		}
	}

	private textAnimateInAttributes(layerId: number, tspanX: string, tspanY: string): { attributeName: string; values: string } {
		const layer = this.exportUtilService.matchingLayer(layerId);
		const obj = this.canvasService.canvas.getObjects().find((obj) => obj.layerId === layerId);
		const translateX = obj.calcTransformMatrix()[4];
		const translateY = obj.calcTransformMatrix()[5];

		const animateUpFromY = this.canvasHeight() - translateY + +tspanY + obj.height / 2; //+ converts tspanY to num
		const animateDownFromY = -(translateY / obj.scaleY + obj.scaleY * obj.fontSize - +tspanY + obj.height / 2);
		const animateLeftFromX = -(translateX / obj.scaleX - +tspanX + obj.width / 2);
		const animateRightFromX = (this.canvasWidth() - translateX) / obj.scaleX + +tspanX + obj.width / 2;

		//Return x or y and start/end values, according to selected transition
		switch (true) {
			case this.includes(layer.transitionIn, 'left'):
				return { attributeName: 'x', values: `${animateLeftFromX}; ${tspanX}` };

			case this.includes(layer.transitionIn, 'right'):
				return { attributeName: 'x', values: `${animateRightFromX}; ${tspanX}` };

			case this.includes(layer.transitionIn, 'up'):
				return { attributeName: 'y', values: `${animateUpFromY}; ${tspanY}` };

			case this.includes(layer.transitionIn, 'down'):
				return { attributeName: 'y', values: `${animateDownFromY}; ${tspanY}` };
		}
	}

	private textAnimateOutAttributes(layerId: number, tspanX: string, tspanY: string): { attributeName: string; values: string } {
		const layer = this.exportUtilService.matchingLayer(layerId);
		const obj = this.canvasService.canvas.getObjects().find((obj) => obj.layerId === layerId);
		const translateX = obj.calcTransformMatrix()[4];
		const translateY = obj.calcTransformMatrix()[5];

		const animateUpFromY = -(translateY / obj.scaleY + obj.scaleY * obj.fontSize - +tspanY + obj.height / 2);
		const animateDownToY = this.canvasHeight() - translateY + +tspanY + obj.height / 2;
		const animateLeftToX = -(translateX / obj.scaleX - +tspanX + obj.width / 2);
		const animateRightToX = (this.canvasWidth() - translateX) / obj.scaleX + +tspanX + obj.width / 2;

		//Return x or y and start/end values, according to selected transition
		switch (true) {
			case this.includes(layer.transitionOut, 'left'):
				return { attributeName: 'x', values: `${tspanX}; ${animateLeftToX}` };

			case this.includes(layer.transitionOut, 'right'):
				return { attributeName: 'x', values: `${tspanX}; ${animateRightToX}` };

			case this.includes(layer.transitionOut, 'up'):
				return { attributeName: 'y', values: `${tspanY}; ${animateUpFromY}` };

			case this.includes(layer.transitionOut, 'down'):
				return { attributeName: 'y', values: `${tspanY}; ${animateDownToY}` };
		}
	}

	private rectAnimateInAttributes(layerId: number, x, y): { attributeName: string; values: string } {
		const layer = this.exportUtilService.matchingLayer(layerId);
		const obj = this.canvasService.canvas.getObjects().find((obj) => obj.layerId === layerId);
		const translateX = obj.calcTransformMatrix()[4];
		const translateY = obj.calcTransformMatrix()[5];
		const animateUpFromY = (this.canvasHeight() - translateY) / obj.scaleY + obj.strokeWidth;
		const animateDownFromY = -(translateY / obj.scaleY + obj.width + obj.strokeWidth); //puts obj JUST off screen
		const animateLeftFromX = -(translateX / obj.scaleX + obj.width + obj.strokeWidth);
		const animateRightFromX = (this.canvasWidth() - translateX) / obj.scaleX + obj.strokeWidth;

		//Return x or y and start/end values, according to selected transition
		switch (true) {
			case this.includes(layer.transitionIn, 'left'):
				return { attributeName: 'x', values: `${animateLeftFromX}; ${x}` };
			case this.includes(layer.transitionIn, 'right'):
				return { attributeName: 'x', values: `${animateRightFromX}; ${x}` };
			case this.includes(layer.transitionIn, 'up'):
				return { attributeName: 'y', values: `${animateUpFromY}; ${y}` };
			case this.includes(layer.transitionIn, 'down'):
				return { attributeName: 'y', values: `${animateDownFromY}; ${y}` };
		}
	}

	private rectAnimateOutAttributes(layerId: number, x, y): { attributeName: string; values: string } {
		const layer = this.exportUtilService.matchingLayer(layerId);
		const obj = this.canvasService.canvas.getObjects().find((obj) => obj.layerId === layerId);
		const translateX = obj.calcTransformMatrix()[4];
		const translateY = obj.calcTransformMatrix()[5];
		const animateUpFromY = -(translateY / obj.scaleY + obj.height + obj.strokeWidth);
		const animateDownToY = (this.canvasHeight() - translateY) / obj.scaleY + obj.strokeWidth; //puts obj JUST off screen
		const animateLeftToX = -(translateX / obj.scaleX + obj.width) + obj.strokeWidth;
		const animateRightToX = (this.canvasWidth() - translateX) / obj.scaleX + obj.strokeWidth;

		//Return x or y and start/end values, according to selected transition
		switch (true) {
			case this.includes(layer.transitionOut, 'left'):
				return { attributeName: 'x', values: `${x}; ${animateLeftToX}` };

			case this.includes(layer.transitionOut, 'right'):
				return { attributeName: 'x', values: `${x}; ${animateRightToX}` };

			case this.includes(layer.transitionOut, 'up'):
				return { attributeName: 'y', values: `${y}; ${animateUpFromY}` };

			case this.includes(layer.transitionOut, 'down'):
				return { attributeName: 'y', values: `${y}; ${animateDownToY}` };
		}
	}

	private circleAnimateInValues(layerId: number, coords: any): string {
		const layer = this.exportUtilService.matchingLayer(layerId);
		const obj = this.canvasService.canvas.getObjects().find((obj) => obj.layerId === layerId);
		const translateX = obj.calcTransformMatrix()[4];
		const translateY = obj.calcTransformMatrix()[5];
		const animateDownFromY = -(translateY / obj.scaleY + 100);
		const animateUpFromY = (this.canvasHeight() - translateY) / obj.scaleY + 100;
		const animateLeftFromX = -(translateX / obj.scaleX + 100);
		const animateRightFromX = (this.canvasWidth() - translateX) / obj.scaleX + 100;

		//Return x or y and start/end values, according to selected transition
		switch (true) {
			case this.includes(layer.transitionIn, 'left'):
				return `${animateLeftFromX}; ${coords.cx}`;
			case this.includes(layer.transitionIn, 'right'):
				return `${animateRightFromX}; ${coords.cx}`;
			case this.includes(layer.transitionIn, 'up'):
				return `${animateUpFromY}; ${coords.cy}`;
			case this.includes(layer.transitionIn, 'down'):
				return `${animateDownFromY}; ${coords.cy}`;
		}
	}

	private circleAnimateOutValues(layerId: number, coords: any): string {
		const layer = this.exportUtilService.matchingLayer(layerId);
		const obj = this.canvasService.canvas.getObjects().find((obj) => obj.layerId === layerId);
		const translateX = obj.calcTransformMatrix()[4];
		const translateY = obj.calcTransformMatrix()[5];
		const animateUpFromY = -(translateY / obj.scaleY + 100);
		const animateDownToY = (this.canvasHeight() - translateY) / obj.scaleY + 100; //puts obj JUST off screen
		const animateLeftToX = -(translateX / obj.scaleX + 100);
		const animateRightToX = (this.canvasWidth() - translateX) / obj.scaleX + 100;

		//Return x or y and start/end values, according to selected transition
		switch (true) {
			case this.includes(layer.transitionOut, 'left'):
				return `${coords.cx}; ${animateLeftToX}`;
			case this.includes(layer.transitionOut, 'right'):
				return `${coords.cx}; ${animateRightToX}`;
			case this.includes(layer.transitionOut, 'up'):
				return `${coords.cy}; ${animateUpFromY}`;
			case this.includes(layer.transitionOut, 'down'):
				return `${coords.cy}; ${animateDownToY}`;
		}
	}

	private canvasHeight(): number {
		return this.canvasService.canvasSize().height;
	}

	private canvasWidth(): number {
		return this.canvasService.canvasSize().width;
	}

	private includes(transition: string, value: string): boolean {
		return this.utilService.includes(transition, value);
	}
}
