import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { GroupManagerRoutingModule } from './group-manager-routing.module';
import { DragDropComponent } from './drag-drop/drag-drop.component';
import { PlayerComponent } from './player/player.component';
import { GroupComponent } from './group/group.component';
import { GroupManagerComponent } from './group-manager.component';

@NgModule({
  declarations: [DragDropComponent, GroupManagerComponent, PlayerComponent, GroupComponent],
  imports: [
    CommonModule,
    GroupManagerRoutingModule,
    SharedModule
  ]
})
export class GroupManagerModule { }
