export  class Installers {
	Id: number;
	CompanyName: string;
	ContactName: string;
	Address: string;
	City: string;
	State: string;
	Zip: string;
	Phone: string;
	Fax: string;
	Email: string;
	IsActive: boolean;
	Notes: string;
	Longitude: string;
	Latitude: string;
	DoesHold: boolean;
	DoesVideo: boolean;
	DoesMusic: boolean;
	Rate: string;

	name: string;
}