export const DataTestIds = {
	Login: {
		MainView: {
			UsernameInput: 'main-view__username-input',
			PasswordInput: 'main-view__password-input',
			SignInButton: 'main-view__sign-in-button',
			ForgotPasswordLink: 'main-view__forgot-password-link'
		},
		AuthCode: {
			QRCode: 'auth-code__qr-code',
			SubmitButton: 'auth-code__submit-button',
			CancelLink: 'auth-code__cancel-link'
		},
		ForgotPassword: {
			Instructions: 'forgot-password__instructions',
			UsernameInput: 'forgot-password__username-input',
			SubmitButton: 'forgot-password__submit-button',
			CancelLink: 'forgot-password__cancel-link'
		}
	}
};
