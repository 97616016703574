<em *ngIf="mode === 'set'">Select a color to replace.</em>
<em *ngIf="mode === 'get'">Select a color.</em>
<div style="display: flex; justify-content: space-between;" class="mgn-top15">
    <div *ngFor="let color of userColors" [ngStyle]="{'background-color': color}" (click)="onColorClick(color)" style="height: 22px; width: 22px;" class="in-block border-radius pointer"></div>
</div>
<div class="row mgn-top60">
    <div class="col-xs-5 col-xs-offset-2">
        <button (click)="onCancelClick()" class="gray-bg ft-size14">Cancel</button>
    </div>
    <div class="col-xs-5">
        <button (click)="onSaveClick()" class="blue-bg ft-size14 white-text">Save</button>
    </div>
</div>
