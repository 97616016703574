<div class="row mgn-top30 mgn-bottom60 relative" (window:mouseup)="clientHealthViewService.autoCloseDropdown($event)">
	<div class="col-sm-4">
		<div class="row">
			<div class="col-xs-12">
				<div style="border-radius: 16px" class="white-bg box-shadow pd15 pd-bottom112 ht588">
					<div class="row mgn-bottom15">
						<div class="col-sm-12">
							<h3><span class="regular">Client Stats</span></h3>
						</div>
					</div>

					<hr class="gray-border-darker mgn-bottom30 mgn-top0" />

					<div class="row mgn-bottom15">
						<div class="col-sm-8">
							<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block dark-bg shadow mgn-right15 relative top2"></div>
							<span class="regular ft-size18">Players Offline</span>
						</div>
						<div class="col-sm-4 text-right">
							<span class="ft-size24">{{ clientHealthDashboardService.selectedClient?.PlayerNotCheckedInCount }}</span>
						</div>
					</div>

					<div class="row mgn-bottom15">
						<div class="col-sm-8">
							<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block dark-bg shadow mgn-right15 relative top2"></div>
							<span class="regular ft-size18">Players Not Installed</span>
						</div>
						<div class="col-sm-4 text-right">
							<span class="ft-size24">{{ clientHealthDashboardService.selectedClient?.PlayerNotInstalledCount }}</span>
						</div>
					</div>

					<div class="row mgn-bottom15">
						<div class="col-sm-8">
							<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block dark-bg shadow mgn-right15 relative top2"></div>
							<span class="regular ft-size18">Players Not Fixed</span>
						</div>
						<div class="col-sm-4 text-right">
							<span class="ft-size24">{{ clientHealthDashboardService.selectedClient?.PlayerNotFixedCount }}</span>
						</div>
					</div>

					<div class="row mgn-bottom15">
						<div class="col-sm-8">
							<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block dark-bg shadow mgn-right15 relative top2"></div>
							<span class="regular ft-size18">Players' Version Not Current</span>
						</div>
						<div class="col-sm-4 text-right">
							<span class="ft-size24">{{ clientHealthDashboardService.selectedClient?.PlayerNotOnCurrentVersionCount }}</span>
						</div>
					</div>

					<div class="row mgn-bottom15">
						<div class="col-sm-8">
							<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block dark-bg shadow mgn-right15 relative top2"></div>
							<span class="regular ft-size18">Open Tickets</span>
						</div>
						<div class="col-sm-4 text-right">
							<span class="ft-size24">{{ clientHealthDashboardService.selectedClient?.OpenTicketCount }}</span>
						</div>
					</div>

					<div class="row mgn-bottom15">
						<div class="col-sm-8">
							<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block dark-bg shadow mgn-right15 relative top2"></div>
							<span class="regular ft-size18">Open Content Requests</span>
						</div>
						<div class="col-sm-4 text-right">
							<span class="ft-size24">{{ clientHealthDashboardService.selectedClient?.OpenContentRequestCount }}</span>
						</div>
					</div>

					<div class="row mgn-bottom15">
						<div class="col-sm-8">
							<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block dark-bg shadow mgn-right15 relative top2"></div>
							<span class="regular ft-size18">Oldest Open Ticket in Days</span>
						</div>
						<div class="col-sm-4 text-right">
							<span class="ft-size24">{{ clientHealthDashboardService.selectedClient?.OldestOpenTicketInDays }}</span>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-10">
							<div style="border-radius: 50%; height: 14px; width: 14px" class="in-block dark-bg shadow mgn-right15 relative top2"></div>
							<span class="regular ft-size18">Oldest Open Content Request in Days</span>
						</div>
						<div class="col-sm-2 text-right">
							<span class="ft-size24">{{ clientHealthDashboardService.selectedClient?.OldestOpenContentRequestInDays }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="col-sm-4 relative">
		<div style="border-radius: 16px" class="white-bg box-shadow pd15 scrollable ht588">
			<!--Health Status Header-->
			<div class="row mgn-bottom15">
				<div class="col-sm-6">
					<h3 class="regular in-block">Player Health Status</h3>
				</div>
				<div class="col-sm-6 text-right">
					<span class="ft-size16">
						{{ clientHealthDashboardService.gumballPlayers?.length }} Player{{ clientHealthDashboardService.gumballPlayers?.length !== 1 ? 's' : '' }}
					</span>
				</div>
			</div>

			<!--Gray Divider-->
			<hr class="gray-border-darker mgn-bottom30 mgn-top0" />

			<!--Player Details Box-->
			<ng-container *ngIf="clientHealthViewService.hoveredClientOrPlayer || clientHealthViewService.persistInfoBox">
				<div
					*ngIf="clientHealthDashboardService.isGumballPlayers(clientHealthViewService.hoveredClientOrPlayer)"
					style="border-radius: 10px; z-index: 100"
					id="clientInfoBox"
					[ngStyle]="{ 'top.px': clientHealthViewService.darkInfoBoxTop, 'left.px': clientHealthViewService.darkInfoBoxLeft }"
					class="dark-bg noExit {{
						clientHealthViewService.persistInfoBox ? clientHealthViewService.hoveredClientOrPlayer?.gumballColorView + '-border' : ''
					}} white-text pd15 wdth400 fixed ft-size14"
				>
					<i
						*ngIf="clientHealthViewService.persistInfoBox"
						(click)="clientHealthViewService.onXClick()"
						class="far fa-times-circle ft-size32 pointer absolute top5 right5 white-text"
					></i>
					<h3 class="semi-bold in-block mgn-right10 noExit">
						{{ clientHealthViewService.hoveredClientOrPlayer.PlayerName }}

						<div
							style="border-radius: 50%; height: 16px; width: 16px"
							class="in-block {{ clientHealthViewService.hoveredClientOrPlayer.gumballColorView }} relative left10 shadow noExit"
						>
							<i *ngIf="clientHealthViewService.hoveredClientOrPlayer.showX" class="fas fa-times absolute center-unknown-height-width ft-size12 white-text"></i>
						</div>
					</h3>
					<div class="row mgn-top15 noExit">
						<div class="col-sm-7 noExit">
							<span class="noExit">Product:</span>
						</div>
						<div class="col-sm-5 noExit">
							<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.ProductTypeName }}</span>
						</div>
					</div>
					<div class="row mgn-top5 noExit">
						<div class="col-sm-7 noExit">
							<span class="noExit">Model Name:</span>
						</div>
						<div class="col-sm-5 noExit">
							<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.ModelName }}</span>
						</div>
					</div>
					<div class="row mgn-top5 noExit">
						<div class="col-sm-7 noExit">
							<span class="noExit">Last Check In:</span>
						</div>
						<div class="col-sm-5 noExit">
							<span class="semi-bold noExit">{{ dateTimeService.dateAndTimeUTCtoLocal(clientHealthViewService.hoveredClientOrPlayer.LastCheckin) }}</span>
						</div>
					</div>
					<div class="row mgn-top5 noExit">
						<div class="col-sm-7 noExit">
							<span class="noExit">Last Playlist Update:</span>
						</div>
						<div class="col-sm-5 noExit">
							<span class="semi-bold noExit">
								{{ dateTimeService.dateAndTimeUTCtoLocal(clientHealthViewService.hoveredClientOrPlayer.LastPlaylistUpdate) }}
							</span>
						</div>
					</div>
					<div class="row mgn-top5 noExit">
						<div class="col-sm-7 noExit">
							<span class="noExit">Location Name:</span>
						</div>
						<div class="col-sm-5 noExit">
							<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.ServiceLocationName }}</span>
						</div>
					</div>
					<div class="row mgn-top5 noExit">
						<div class="col-sm-7 noExit">
							<span class="noExit">Hostname:</span>
						</div>
						<div class="col-sm-5 noExit">
							<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.Hostname }}</span>
						</div>
					</div>
					<div class="row mgn-top5 noExit">
						<div class="col-sm-7 noExit">
							<span class="noExit">MAC Address:</span>
						</div>
						<div class="col-sm-5 noExit">
							<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.MacAddress }}</span>
						</div>
					</div>
					<div class="row mgn-top5 noExit">
						<div class="col-sm-7 noExit">
							<span class="noExit">Unit ID:</span>
						</div>
						<div class="col-sm-5 noExit">
							<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.PlayerUnitIdentifier }}</span>
						</div>
					</div>
					<div class="row mgn-top5 noExit">
						<div class="col-sm-7 noExit">
							<span class="noExit">Install State:</span>
						</div>
						<div class="col-sm-5 noExit">
							<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.InstallState }}</span>
						</div>
					</div>
					<div class="row mgn-top5 noExit">
						<div class="col-sm-7 noExit">
							<span class="noExit">Repair State:</span>
						</div>
						<div class="col-sm-5 noExit">
							<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.RepairState }}</span>
						</div>
					</div>
					<div class="row mgn-top5 noExit">
						<div class="col-sm-7 noExit">
							<span class="noExit">Current Playlist:</span>
						</div>
						<div class="col-sm-5 noExit">
							<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.CurrentlyPlayingProductionName }}</span>
						</div>
					</div>
					<div class="row mgn-top5 noExit">
						<div class="col-sm-7 noExit">
							<span class="noExit">Reported Version:</span>
						</div>
						<div class="col-sm-5 noExit">
							<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.ReportedPlayerVersion }}</span>
						</div>
					</div>
					<div class="row mgn-top5 noExit">
						<div class="col-sm-7 noExit">
							<span class="noExit">Current Release Version:</span>
						</div>
						<div class="col-sm-5 noExit">
							<span class="semi-bold noExit">{{ clientHealthViewService.hoveredClientOrPlayer.CurrentReleasePlayerVersion }}</span>
						</div>
					</div>
					<div class="row mgn-top15 noExit">
						<div class="col-sm-7 noExit">
							<span class="noExit">Rule Failure List:</span>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<span class="semi-bold noExit" [innerHTML]="clientHealthViewService.hoveredClientOrPlayer.ruleFailureListView"></span>
						</div>
					</div>
				</div>
			</ng-container>

			<div
				*ngFor="let circle of clientHealthDashboardService.statusCircles"
				id="{{ circle.PlayerId }}"
				(click)="clientHealthViewService.onPlayerCircleClick()"
				(mouseover)="onPlayerCircleHover(circle)"
				(mouseleave)="clientHealthViewService.onPlayerCircleLeave()"
				style="border-radius: 50%; height: 16px; width: 16px"
				class="in-block noExit {{ circle.gumballColorView }} relative shadow mgn-bottom10 mgn-right10"
			>
				<i *ngIf="circle.showX" style="pointer-events: none" class="fas fa-times absolute center-unknown-height-width ft-size12 white-text"></i>
			</div>
		</div>
	</div>

	<div class="col-sm-4">
		<div style="border-radius: 16px" class="white-bg box-shadow pd15 pd-bottom112 ht588">
			<div class="row mgn-bottom15">
				<div class="col-sm-12">
					<h3 class="regular">Player Health Status Summary Chart</h3>
				</div>
			</div>

			<hr class="gray-border-darker mgn-bottom30 mgn-top0" />

			<div style="border-radius: 16px" class="mgn-bottom15">
				<div style="display: flex; justify-content: space-between">
					<div>
						<div style="border-radius: 50%; height: 16px; width: 16px" class="in-block client-health-red shadow mgn-bottom10 mgn-right8"></div>
						<span class="relative topneg14">Critical {{ clientHealthDashboardService.gumballPlayersRed?.length }}</span>
					</div>
					<div>
						<div style="border-radius: 50%; height: 16px; width: 16px" class="in-block client-health-orange shadow mgn-bottom10 mgn-right8"></div>
						<span class="relative topneg14">In Repair {{ clientHealthDashboardService.gumballPlayersOrange?.length }}</span>
					</div>
					<div>
						<div style="border-radius: 50%; height: 16px; width: 16px" class="in-block client-health-green shadow mgn-bottom10 mgn-right8"></div>
						<span class="relative topneg14">OK {{ clientHealthDashboardService.gumballPlayersGreen?.length }}</span>
					</div>
					<div>
						<div style="border-radius: 50%; height: 16px; width: 16px" class="in-block client-health-blue shadow mgn-bottom10 mgn-right8"></div>
						<span class="relative topneg14">Fickle {{ clientHealthDashboardService.gumballPlayersBlue?.length }}</span>
					</div>
					<div>
						<div style="border-radius: 50%; height: 16px; width: 16px" class="in-block client-health-purple shadow mgn-bottom10 mgn-right8"></div>
						<span class="relative topneg14">Idle {{ clientHealthDashboardService.gumballPlayersPurple?.length }}</span>
					</div>
				</div>
			</div>

			<div class="ht340">
				<canvas #myDoughnut>{{ clientHealthDashboardService.playersDoughnutChart }}</canvas>
			</div>
		</div>
	</div>
</div>
