<div class="row mgn-top30">
	<div class="col-xs-12">
		<span class="ft-size20">Transition In</span>
		<span
			class="ft-size14 relative rightneg10 topneg10"
			ngxTippy
			[tippyProps]="{ appendTo: 'parent' }"
			tippyName="{{ utilService.guid | wrapFn }}"
			data-tippy-content="Sliding transitions are not supported for rotated objects as well as images with a drop shadow."
		>
			<i class="far fa-question-circle" aria-hidden="true"></i>
		</span>
	</div>
</div>

<div class="row mgn-top15">
	<div class="col-xs-12">
		<portal-dropdown-menu
			[backgroundColor]="'white-bg'"
			[isDisabled]="transitionsDisabled"
			[height]="'ht40'"
			[key]="'name'"
			[options]="transInOptions"
			[selectedOption]="layersService.activeLayer.transitionIn"
			(onOptionSelect)="onTransitionSelect($event, 'transitionIn')"
		></portal-dropdown-menu>
	</div>
</div>

<div class="row mgn-top30">
	<div class="col-xs-12">
		<span class="ft-size20">Transition Out</span>
	</div>
</div>

<div class="row mgn-top15 mgn-bottom60">
	<div class="col-xs-12">
		<portal-dropdown-menu
			[backgroundColor]="'white-bg'"
			[isDisabled]="transitionsDisabled"
			[height]="'ht40'"
			[key]="'name'"
			[options]="transOutOptions"
			[selectedOption]="layersService.activeLayer.transitionOut"
			(onOptionSelect)="onTransitionSelect($event, 'transitionOut')"
		></portal-dropdown-menu>
	</div>
</div>
