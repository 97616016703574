export class InstallersAttachments {
	Id: number;
	InstallerId: number;
	FriendlyName: string;
	FileId: string;
	markedForDelete: boolean;

	constructor(InstallerId: number, FriendlyName: string, FileId: string) {
		this.InstallerId = InstallerId;
		this.FriendlyName = FriendlyName;
		this.FileId = FileId;
	}
}
