import { Component, Input, OnInit } from '@angular/core';

import { AppStateService, UtilitiesService } from '../../../../../../core/services';
import { ContentViewService } from '../../_services';
import { ContentVM } from '../../_models/content-view';

@Component({
	selector: 'vc-playlist-details-view',
	templateUrl: './playlist-details-view.component.html',
	styleUrls: ['./playlist-details-view.component.scss']
})
export class PlaylistDetailsViewComponent implements OnInit {
	@Input() content: ContentVM;
	@Input() contentList: ContentVM[];
	@Input() isFirstIndex: boolean;

	constructor(public appStateService: AppStateService, public contentViewService: ContentViewService, public utilService: UtilitiesService) {}

	ngOnInit() {}

	public onPlayClick(): void {
		this.contentViewService.onPlayClick(this.content, null, 'playlist-details', this.contentList);
	}
}
