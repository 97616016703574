export type Alert = {
	visible: boolean;
	icon?: string;
	bgColor: string;
	textColor: string;
	message: string;
};

export enum LoginView {
	MainView = 'main-view',
	ForgotPassword = 'forgot-password',
	ResetPassword = 'reset-password',
	AuthCode = 'auth-code'
}

export enum AlertMessage {
	Default = 'IMPORTANT: We have updated our security procedures. If you are logging in for the first time since 7/7/2024, please reset your password by clicking "Forgot password?"',
	InvalidCredentials = 'Please enter a valid username and password.',
	PasswordCreationInstructions = 'Password must be at least 16 characters long and include: one lowercase letter, one uppercase letter, one number, one punctuation character (!, #, $, %, &, etc.). DO NOT USE THIS PASSWORD FOR ANY OTHER ACCOUNT.',
	ResetLinkExpired = 'This password reset link has expired, please try again.',
	InactivitySignOut = `You've been signed out due to inactivity.`,
	SuccessfulSignOut = `You have successfully signed out.`,
	AfterResetSubmit = 'If we found your info in our system, you should receive reset instructions via email within 15 minutes.',
	PasswordMatchError = `Password fields don't match! Please try again.`,
	PasswordBlankError = `Password fields can't be blank!`,
	PasswordUpdatedSuccessfully = 'Password updated successfully!',
	SomethingWentWrong = 'Something went wrong! Please try again.',
	IncorrectPin = 'The pin you entered is incorrect.'
}
