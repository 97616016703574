export class ContactInformation {
	Id: number;
	ContactTypeId: number;
	ClientId: number;
	FirstName: string;
	LastName: string;
	Phone1: string;
	Phone2: string;
	Fax: string;
	Email: string;
	LAccountid: number;
	Lemployeeid: number;

	constructor(contactTypeId?, clientId?, firstName?, lastName?, email?, phone1?, id?) {
		this.ContactTypeId = contactTypeId;
		this.ClientId = clientId;
		this.FirstName = firstName;
		this.LastName = lastName;
		this.Email = email;
		this.Phone1 = phone1;
		this.Id = id;
	}
}