import { Injectable } from '@angular/core';

import { AppStateService } from '../../../core/services';
import { ViewStateService } from './view-state.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlsService {

  

  constructor(
          private appStateService: AppStateService,
          private viewStateService: ViewStateService
  ) { }
  
  //Indexes manually written just to easily identify a url
  public apiUrls(): any[] {
    return [
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/${this.appStateService.currentUser.UserId}/Today?completedOnly=${this.viewStateService.state.completedOnly}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 0,
        description: 'myTasksTodayOnly',
        friendlyDesc: 'My Tasks Due Today'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/${this.appStateService.currentUser.UserId}/Today?completedOnly=${this.viewStateService.state.completedOnly}&searchTerm=${this.viewStateService.state.searchTerm}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 1,
        description: 'myTasksTodayOnlySearch',
        friendlyDesc: 'My Tasks Due Today - Search Results'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/Today?completedOnly=${this.viewStateService.state.completedOnly}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 2,
        description: 'allCsrTasksTodayOnly',
        friendlyDesc: 'All Tasks Due Today'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/Today?completedOnly=${this.viewStateService.state.completedOnly}&searchTerm=${this.viewStateService.state.searchTerm}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 3,
        description: 'allCsrTasksTodayOnlySearch',
        friendlyDesc: 'All Tasks Due Today - Search Results'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/${this.appStateService.currentUser.UserId}/Past?completedOnly=${this.viewStateService.state.completedOnly}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 4,
        description: 'myTasksPastOnly',
        friendlyDesc: 'My Tasks Past Only'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/${this.appStateService.currentUser.UserId}/Past?completedOnly=${this.viewStateService.state.completedOnly}&searchTerm=${this.viewStateService.state.searchTerm}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 5,
        description: 'myTasksPastOnlySearch',
        friendlyDesc: 'My Tasks Past Only - Search Results'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/Past?completedOnly=${this.viewStateService.state.completedOnly}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 6,
        description: 'allCsrTasksPastOnly',
        friendlyDesc: 'All Tasks Past Only'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/Past?completedOnly=${this.viewStateService.state.completedOnly}&searchTerm=${this.viewStateService.state.searchTerm}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 7,
        description: 'allCsrTasksPastOnlySearch',
        friendlyDesc: 'All Tasks Past Only - Search Results'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/${this.appStateService.currentUser.UserId}/Future?completedOnly=${this.viewStateService.state.completedOnly}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 8,
        description: 'myTasksTodayAndFutureOnly',
        friendlyDesc: 'My Tasks Future (includes Today)'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/${this.appStateService.currentUser.UserId}/Future?completedOnly=${this.viewStateService.state.completedOnly}&searchTerm=${this.viewStateService.state.searchTerm}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 9,
        description: 'myTasksTodayAndFutureOnlySearch',
        friendlyDesc: 'My Tasks Today And Future - Search Results'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/Future?completedOnly=${this.viewStateService.state.completedOnly}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 10,
        description: 'allCsrTasksTodayAndFutureOnly',
        friendlyDesc: 'All Tasks Future (includes Today)'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/Future?completedOnly=${this.viewStateService.state.completedOnly}&searchTerm=${this.viewStateService.state.searchTerm}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 11,
        description: 'allCsrTasksTodayAndFutureOnlySearch',
        friendlyDesc: 'All Tasks Future (includes Today) - Search Results'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/${this.appStateService.currentUser.UserId}/Completed?&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 12,
        description: 'myTasksNoMatterDateCompletedOnly',
        friendlyDesc: 'All My Tasks, Completed'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/${this.appStateService.currentUser.UserId}/Completed?&searchTerm=${this.viewStateService.state.searchTerm}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 13,
        description: 'myTasksNoMatterDateCompletedOnlySearch',
        friendlyDesc: 'All My Tasks, Completed - Search Results'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/Completed?&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 14,
        description: 'allCsrTasksNoMatterDateCompletedOnly',
        friendlyDesc: 'All Tasks, Completed'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/Completed?&searchTerm=${this.viewStateService.state.searchTerm}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 15,
        description: 'allCsrTasksNoMatterDateCompletedOnlySearch',
        friendlyDesc: 'All Tasks, Completed - Search Results'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/${this.appStateService.currentUser.UserId}/Uncompleted?&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 16,
        description: 'myTasksNoMatterDateUncompletedOnly',
        friendlyDesc: 'All My Tasks, Uncompleted'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/${this.appStateService.currentUser.UserId}/Uncompleted?&searchTerm=${this.viewStateService.state.searchTerm}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 17,
        description: 'myTasksNoMatterDateUncompletedOnlySearch',
        friendlyDesc: 'All My tasks, Uncompleted - Search Results'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/Uncompleted?&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 18,
        description: 'allCsrTasksNoMatterDateUncompletedOnly',
        friendlyDesc: 'All Tasks, Uncompleted'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/Uncompleted?&searchTerm=${this.viewStateService.state.searchTerm}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 19,
        description: 'allCsrTasksNoMatterDateUncompletedOnlySearch',
        friendlyDesc: 'All Tasks, Uncompleted - Search Results'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/${this.appStateService.currentUser.UserId}/Due/${this.viewStateService.state.dateRangeStart}/${this.viewStateService.state.dateRangeEnd}?completedOnly=${this.viewStateService.state.completedOnly}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 20,
        description: 'myTasksDateRangeOnly',
        friendlyDesc: 'My Tasks Within Date Range'
      },
      {
        url: `${environment.adminUrl}CoreCsrTasks/Csr/Due/${this.viewStateService.state.dateRangeStart}/${this.viewStateService.state.dateRangeEnd}?completedOnly=${this.viewStateService.state.completedOnly}&orderByCol=${this.viewStateService.state.orderByCol}&orderDesc=${this.viewStateService.state.orderDesc}&pageSize=10&pageNumber=${this.viewStateService.state.pageNumber}`,
        index: 21,
        description: 'allCsrTasksDateRangeOnly',
        friendlyDesc: 'All Tasks Within Date Range'
      }
    ]
  }
}
