import { Injectable } from '@angular/core';
import { IMyDateModel } from 'angular-mydatepicker';
import { Subject } from 'rxjs';
import * as moment from 'moment';

import { DateTimeService } from '../../../core/services';
import { Model, View } from './models';

@Injectable({
    providedIn: 'root'
})
export class DateTimeSelectorService {

    //Observable sources
    private onModelChangeSource = new Subject<any>();

    //Observable streams
    public onModelChange$ = this.onModelChangeSource.asObservable();

    public model: Model = new Model();
    public view: View = new View();

    constructor(private dateTimeService: DateTimeService) { }



    public convertDateModelToView(date: string): View {
        date = date ? date : '';
        let dateView: IMyDateModel = {
            isRange: false,
            singleDate: {
                date: this.dateTimeService.buildDatePickerDate(moment(new Date(date))),
                jsDate: new Date(date)
            }
        };
        let timeView: string = moment(dateView.singleDate.jsDate).format('h:mm A');
        return new View(dateView, timeView !== 'Invalid date' ? timeView : '12:00 AM');
    }

    public convertDateViewToModel(date: IMyDateModel, time: string): string {
        return moment(moment(`${date.singleDate.date.month}/${date.singleDate.date.day}/${date.singleDate.date.year}`, 'MM/DD/YYYY').format('YYYY-MM-DD') + time, 'YYYY-MM-DDLT')
            .format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    }
}
