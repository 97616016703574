import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppStateService, Events, MessageService } from 'src/app/core/services';
import { ClientsView } from '../clients-view';
import { CsrBlastGroups } from '../../../shared/api-models/admin';
import { environment } from '../../../../environments/environment';
import { QueryVerb } from '../query-verb.enum';

@Injectable({
	providedIn: 'root'
})
export class ClientBrowserService {
	public csrBlastGroup: CsrBlastGroups = new CsrBlastGroups();
	public appleClientFilter: 'all' | 'include' | 'exclude';
	public includeDemoAccounts: boolean = true;
	public includeInactive: boolean = true;
	public includeHold: boolean = true;
	public includeVideo: boolean = true;
	public includeRadio: boolean = true;
	public includePoster: boolean = true;
	public includeVideoWall: boolean = true;
	public includeWeb: boolean = true;
	public holdCrOnly: boolean = false;
	public queryVerb: QueryVerb = QueryVerb.OR;
	public pageNumber: number = 1;
	public searchParams = {
		companyName: '',
		locationName: '',
		industry: '',
		city: '',
		state: '',
		csr: '',
		salesperson: '',
		username: '',
		email: '',
		unitIdentifier: '',
		contactName: ''
	};
	public orderByCol: string = 'Company';
	public orderDesc: boolean = false;

	public totalItems: number;

	constructor(private appStateService: AppStateService, private httpClient: HttpClient, private messageService: MessageService, private router: Router) {}

	public onCreateMailGroupClick(): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.csrBlastGroup.CreatedDate = new Date().toISOString();
		this.csrBlastGroup.ClientBrowserUrl = this.url();
		this.csrBlastGroup.Name = 'My Mail Group';
		this.csrBlastGroup.CsrId = this.appStateService.currentUser.UserId;
		this.httpClient.post(`${environment.adminUrl}CsrBlastGroups`, this.csrBlastGroup).subscribe((group: CsrBlastGroups) => {
			this.messageService.publish(Events.savingPreloader, 0);
			this.router.navigate([`/email-blast-tool/create-mailing/group-id/${group.Id}`]);
		});
	}

	public getClientBrowserData(): Observable<ClientsView[]> {
		return this.httpClient.get(environment.adminUrl + this.url(), { observe: 'response' }).pipe(
			map((response: HttpResponse<any>) => {
				let clients: ClientsView[] = response.body;
				this.totalItems = parseInt(response.headers.get('x-result-count'));
				return this.clientsView(clients);
			})
		);
	}

	private clientsView(clients: ClientsView[]): ClientsView[] {
		return clients.map((client) => {
			client.openBtn = 'View';
			client.classList = 'white-bg t-row';
			client.isHoldCREligibleIcon = client.IsHoldCREligible ? 'fas fa-check' : '';
			client.isHoldClientIcon = client.IsHoldClient ? 'fas fa-phone-volume' : '';
			client.isVideoClientIcon = client.IsVideoClient ? 'fas fa-tv' : '';
			client.isMusicClientIcon = client.IsMusicClient ? 'fas fa-volume-up' : '';
			client.isVideoWallClientIcon = client.IsVideoWallClient ? 'fas fa-th' : '';
			client.isWebPlayerClientIcon = client.IsWebPlayerClient ? 'fas fa-laptop' : '';
			client.isPosterClientIcon = client.IsPosterClient ? 'far fa-square' : '';
			if (!client.IsClientActive) {
				client.CompanyName = `${client.CompanyName} - <span class="semi-bold red-text-light">INACTIVE</span>`;
			}
			if (client.IsDemoAccount) {
				client.CompanyName = `${client.CompanyName} - <span class="semi-bold purple-text">DEMO</span>`;
			}
			return client;
		});
	}

	public url(): string {
		return `Reports/ClientBrowser?companyName=${this.searchParams.companyName}&companyIndustry=${this.searchParams.industry}&billingCity=${this.searchParams.city}&billingState=${this.searchParams.state}&locationName=${this.searchParams.locationName}&csr=${this.searchParams.csr}&salesperson=${this.searchParams.salesperson}&username=${this.searchParams.username}&emailAddress=${this.searchParams.email}&unitIdentifier=${this.searchParams.unitIdentifier}&contactName=${this.searchParams.contactName}&includeInactive=${this.includeInactive}&includeHoldClients=${this.includeHold}&includeVideoClients=${this.includeVideo}&includeMusicClients=${this.includeRadio}&includeHoldCREligibleOnly=${this.holdCrOnly}&includeDemoAccounts=${this.includeDemoAccounts}&includePosterClients=${this.includePoster}&includeVideoWallClients=${this.includeVideoWall}&includeWebClients=${this.includeWeb}&andOrQuery=${this.queryVerb}&appleClientFilter=${this.appleClientFilter}&orderDesc=${this.orderDesc}&orderByCol=${this.orderByCol}&pageSize=25&pageNumber=${this.pageNumber}`;
	}
}
