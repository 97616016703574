import { Column } from './column';

export class Table {
	columns: Column[];
	guid: string;
	data: any[];
	totalItems: number;

	constructor(columns?: Column[], guid?: string) {
		this.columns = columns;
		this.guid = guid;
	};
}