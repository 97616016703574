<portal-header></portal-header>

<div class="container-fluid mx-4 mgn-top30 relative">
	<div class="row mgn-bottom30">
		<h1 class="semi-bold">
			<i class="far fa-newspaper mgn-right15" aria-hidden="true"></i>
			News Manager
		</h1>
	</div>
	<span *ngIf="getStartedTextVisible && !badWordListVisible" class="absolute left0 top50"><em>Please select a feed to get started.</em></span>
	<div *ngIf="!badWordListVisible && !createEditStoryVisible" class="row white-bg shadow mgn-bottom15 border-radius pd-top10 pd-bottom10">
		<div class="col-sm-5">
			<div class="row">
				<div class="col-sm-5">
					<div class="setWidth100 ht36">
						<portal-dropdown-menu
							[backgroundColor]="'gray-bg-dark'"
							[height]="'ht40'"
							[key]="'Name'"
							[options]="feeds"
							[selectedOption]="newsManagerService.viewState.selectedFeed ? newsManagerService.viewState.selectedFeed.Name : 'Select a feed...'"
							(onOptionSelect)="onDropdownSelect($event)"
						></portal-dropdown-menu>
					</div>
				</div>
				<div class="col-sm-3">
					<button class="border-radius white-bg setWidth100" (mouseup)="onNewStoryClick()">New Story</button>
				</div>
				<div class="col-sm-4">
					<button class="border-radius white-bg setWidth100" (mouseup)="badWordListVisible = !badWordListVisible">
						{{ !badWordListVisible ? 'Edit Bad Word List' : 'Back to News' }}
					</button>
				</div>
			</div>
		</div>
		<div class="col-sm-7">
			<div class="row">
				<div *ngIf="!getStartedTextVisible" class="col-sm-4 col-sm-offset-8">
					<input type="text" [(ngModel)]="newsManagerService.viewState.searchTerm" (keyup)="onSearchKeyup()" placeholder="Search..." />
					<button *ngIf="newsManagerService.viewState.searchTerm?.length > 0" (mouseup)="onSearchClear()" class="white-bg wdth26 ht26 absolute right20 top6">
						<i class="fa fa-times absolute center-unknown-height-width" aria-hidden="true"></i>
					</button>
				</div>
			</div>
		</div>
	</div>

	<!--*****************************
		NEWS TABLE
	******************************-->
	<div *ngIf="!badWordListVisible && !createEditStoryVisible" class="row">
		<portal-table
			[table]="tableConfigService.table"
			[config]="tableConfigService.configuration"
			[data]="newsManagerData"
			[showJumpToPage]="true"
			[page]="newsManagerService.viewState.pageNumber"
			[totalItems]="newsManagerService.totalItems"
			(buttonClickNotify)="onTableButtonClick($event)"
			(currentPageNotify)="getPage($event)"
		></portal-table>
	</div>

	<!--*****************************
    	BAD WORD LIST
    ******************************-->

	<div *ngIf="badWordListVisible" class="row mgn-top30">
		<div class="col-sm-4">
			<div class="row mgn-bottom20">
				<div class="col-sm-10">
					<h3 class="semi-bold">Bad Word List</h3>
				</div>
				<div class="col-sm-2 text-right">
					<i class="fa fa-times ft-size24 pd-right8 pd-left8 pd-bottom8 pointer" aria-hidden="true" (mouseup)="badWordListVisible = false"></i>
				</div>
			</div>

			<div class="row mgn-bottom15">
				<div class="col-sm-8">
					<input
						style="border: 1px solid #d6d9db"
						class="ht40 setWidth100 gray-bg-dark"
						[(ngModel)]="newsManagerService.newBadWord"
						type="text"
						placeholder="Enter a new word..."
					/>
				</div>
				<div class="col-sm-4">
					<button
						[disabled]="!newsManagerService.newBadWord"
						class="{{ !newsManagerService.newBadWord ? 'setOpacityLow' : null }} border-radius white-bg setWidth100"
						(mouseup)="onAddBadWordClick()"
					>
						Add
					</button>
				</div>
			</div>
			<div class="scrollable white-bg box-shadow mgn-bottom60 ht495 setBorder gray-border border-radius pd-top10 pd-left15 pd-right15 pd-bottom10">
				<div
					*ngFor="let feedBadWord of feedBadWordList; let i = index"
					class="row border-radius {{ i !== 0 ? 'pd-top10' : null }} pd-bottom6 border-bottom gray-border"
				>
					<div class="col-sm-10 ht38 ln-height38">
						<span>{{ feedBadWord.BadWord }}</span>
					</div>
					<div class="col-sm-2 ht38">
						<button class="border-radius red-bg-light setWidth100 white-text" (mouseup)="onDeleteBadWordClick(feedBadWord)">
							<i class="far fa-trash-alt" aria-hidden="true"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
