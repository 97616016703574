import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, take } from 'rxjs/operators';
import * as moment from 'moment';

import { ContentLibraries } from 'src/app/shared/api-models/content';
import { UsageReportsStore } from '../usage-reports.store';

@Component({
	selector: 'ur-library-search',
	templateUrl: './library-search.component.html',
	styleUrls: ['./library-search.component.scss']
})
export class LibrarySearchComponent implements OnInit {
	public libraries$ = this.usageReportsStore.libraries$;
	public index: number;
	public isHovered: boolean;
	public searchTerm: string;
	public showResults: boolean = false;
	public typeahead = new EventEmitter<string>();
	public disableFromDateUntil: any = {
		year: new Date('12-31-2022').getFullYear(),
		month: new Date('12-31-2022').getMonth() + 1,
		day: new Date('12-31-2022').getDate() - 1
	};
	public fromDate: string = new Date('12-31-2022').toString();
	public toDate: string = new Date().toString();

	constructor(private router: Router, public usageReportsStore: UsageReportsStore) {
		this.typeahead.pipe(debounceTime(500)).subscribe(() => {
			this.usageReportsStore.getLibraries$();
			this.showResults = true;
		});
	}

	ngOnInit(): void {}

	public onDateChanged(date: string, toFrom: 'to' | 'from'): void {
		date = moment(date).format('M/D/YYYY');
		if (toFrom === 'from') {
			this.usageReportsStore.setFromDateString(date);
		} else if (toFrom === 'to') {
			this.usageReportsStore.setToDateString(date);
		}
	}

	public onLibrarySelect(library: ContentLibraries): void {
		this.usageReportsStore.toggleSingleItem('libraries', library);
	}

	//highlight hovered row
	public onRowHover(index: number): void {
		this.index = index;
		this.isHovered = true;
	}

	public onSearch(): void {
		this.usageReportsStore.setLibrarySearchTerm(this.searchTerm);
		if (this.searchTerm.length > 0) {
			this.typeahead.next();
		} else {
			this.showResults = false;
			this.usageReportsStore.setLibraries([]);
		}
	}

	public onSearchClear(): void {
		this.showResults = false;
		this.searchTerm = '';
		this.usageReportsStore.deselectAllLibraries();
		this.usageReportsStore.setLibraries([]);
	}

	public onGenerateReportClick(): void {
		this.usageReportsStore.selectedLibraries$.pipe(take(1)).subscribe((libraries) => {
			const libraryIds = libraries.map((lib) => lib.Id);
			this.router.navigate([`/usage-reports/report/${libraryIds}`]);
		});
	}
}
