<portal-header></portal-header>

<div class="container-fluid mgn-top30 relative">
	<a [routerLink]="['/email-blast-tool/my-mailings']" class="dark-text absolute topneg21 left15">
		<i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
		<span class="ft-size16">My Mailings</span>
	</a>
	<div class="row mgn-top10">
		<div class="col-sm-5">
			<h1 class="relative">
				<i class="fas fa-mail-bulk mgn-right15 relative"></i>
				<i class="fas {{ createMailingService.mode === 'new' ? 'fa-plus' : 'fa-pencil-alt' }} absolute top0 left30 ft-size12"></i>
				<span class="semi-bold">{{ createMailingService.mode === 'new' ? 'Create' : 'Edit' }} Mailing</span>
				<ng-container *ngIf="createMailingService.mode === 'new'">
					|
					<em>Group Name: {{ createMailingService.group?.Name }}</em>
				</ng-container>
				<ng-container *ngIf="createMailingService.mode === 'edit'">
					<span *ngIf="createMailingService.mailing?.MailingName.length > 0">|</span>
					<em *ngIf="createMailingService.mailing?.MailingName.length > 0">Mailing Name: {{ createMailingService.mailing?.MailingName }}</em>
				</ng-container>
			</h1>
		</div>
		<div class="col-sm-7">
			<p style="border-radius: 12px" class="regular ft-size20 pd10 yellow-bg">
				Reminder: You must start in the Works24 Office Account for the Email Blast Tool to work properly
			</p>
		</div>
	</div>

	<div class="row green-limeGreen-bg-gradient ht2 mgn-top30 border-radius mgn-left0 mgn-right0"></div>

	<form (keydown.enter)="$event.preventDefault()" #form="ngForm" novalidate>
		<section class="pd15 shadow border-radius white-bg mgn-top30">
			<div class="row">
				<div class="col-xs-12">
					<h2 class="semi-bold">Mail Setup</h2>
				</div>
			</div>
			<div class="row mgn-top15">
				<div class="col-sm-4">
					<!--MAILING NAME-->
					<div class="row">
						<div class="col-sm-12">
							<span>Mailing Name:</span>
							<i class="fas fa-asterisk ft-size7 red-text-light relative left5 bottom5"></i>
							<input [readonly]="createMailingService.mailing.SendDate" type="text" name="mailingName" [(ngModel)]="createMailingService.mailing.MailingName" />
						</div>
					</div>

					<!--EMAIL SUBJECT-->
					<div (mouseover)="showEmailSubjectTags = true" (mouseleave)="showEmailSubjectTags = false" class="row mgn-top15">
						<div class="col-sm-12 relative">
							<span>Email Subject:</span>
							<i class="fas fa-asterisk ft-size7 red-text-light relative left5 bottom5"></i>
							<input
								[readonly]="createMailingService.mailing.SendDate"
								type="text"
								name="emailSubject"
								#emailSubject
								[(ngModel)]="createMailingService.mailing.EmailSubject"
							/>
							<div
								*ngIf="showEmailSubjectTags && !createMailingService.mailing.SendDate"
								style="display: flex; justify-content: space-between"
								class="absolute wdth155 top20 right15"
							>
								<button
									(click)="onInsertTagClick('[[FIRSTNAME]]', 'EmailSubject')"
									ngxTippy
									[tippyProps]="{ appendTo: 'parent' }"
									tippyName="{{ utilService.guid | wrapFn }}"
									data-tippy-content="FIRST NAME"
									class="white-bg mgn-right5"
								>
									F
								</button>
								<button
									(click)="onInsertTagClick('[[LASTNAME]]', 'EmailSubject')"
									ngxTippy
									[tippyProps]="{ appendTo: 'parent' }"
									tippyName="{{ utilService.guid | wrapFn }}"
									data-tippy-content="LAST NAME"
									class="white-bg mgn-right5"
								>
									L
								</button>
								<button
									(click)="onInsertTagClick('[[CSRNAME]]', 'EmailSubject')"
									ngxTippy
									[tippyProps]="{ appendTo: 'parent' }"
									tippyName="{{ utilService.guid | wrapFn }}"
									data-tippy-content="CSR NAME"
									class="white-bg mgn-right5"
								>
									C
								</button>
								<button
									(click)="onInsertTagClick('[[SALESNAME]]', 'EmailSubject')"
									ngxTippy
									[tippyProps]="{ appendTo: 'parent' }"
									tippyName="{{ utilService.guid | wrapFn }}"
									data-tippy-content="SALES NAME"
									class="white-bg"
								>
									S
								</button>
							</div>
						</div>
					</div>

					<!--HISTORY NOTE SUBJECT-->
					<div (mouseover)="showHistoryNoteSubjectTags = true" (mouseleave)="showHistoryNoteSubjectTags = false" class="row mgn-top15">
						<div class="col-sm-12 relative">
							<span>History Note Subject:</span>
							<i class="fas fa-asterisk ft-size7 red-text-light relative left5 bottom5"></i>
							<input
								[readonly]="createMailingService.mailing.SendDate"
								type="text"
								name="historySubject"
								#historySubject
								[(ngModel)]="createMailingService.mailing.HistorySubject"
							/>
							<div
								*ngIf="showHistoryNoteSubjectTags && !createMailingService.mailing.SendDate"
								style="display: flex; justify-content: space-between"
								class="absolute wdth155 top20 right15"
							>
								<button
									(click)="onInsertTagClick('[[FIRSTNAME]]', 'HistorySubject')"
									ngxTippy
									[tippyProps]="{ appendTo: 'parent' }"
									tippyName="{{ utilService.guid | wrapFn }}"
									data-tippy-content="FIRST NAME"
									class="white-bg mgn-right5"
								>
									F
								</button>
								<button
									(click)="onInsertTagClick('[[LASTNAME]]', 'HistorySubject')"
									ngxTippy
									[tippyProps]="{ appendTo: 'parent' }"
									tippyName="{{ utilService.guid | wrapFn }}"
									data-tippy-content="LAST NAME"
									class="white-bg mgn-right5"
								>
									L
								</button>
								<button
									(click)="onInsertTagClick('[[CSRNAME]]', 'HistorySubject')"
									ngxTippy
									[tippyProps]="{ appendTo: 'parent' }"
									tippyName="{{ utilService.guid | wrapFn }}"
									data-tippy-content="CSR NAME"
									class="white-bg mgn-right5"
								>
									C
								</button>
								<button
									(click)="onInsertTagClick('[[SALESNAME]]', 'HistorySubject')"
									ngxTippy
									[tippyProps]="{ appendTo: 'parent' }"
									tippyName="{{ utilService.guid | wrapFn }}"
									data-tippy-content="SALES NAME"
									class="white-bg"
								>
									S
								</button>
							</div>
						</div>
					</div>

					<!--EMAIL FROM-->
					<div class="row mgn-top15">
						<div class="col-sm-12">
							<span>Email From:</span>
							<i class="fas fa-asterisk ft-size7 red-text-light relative left5 bottom5"></i>
							<br />
							<portal-dropdown-menu
								*ngIf="!createMailingService.mailing.SendDate"
								[backgroundColor]="'gray-bg-dark'"
								[height]="'ht40'"
								[options]="[{ Name: 'CSR' }, { Name: 'Sales' }, { Name: 'CSR, CC Sales' }, { Name: 'Sales, CC CSR' }, { Name: 'Custom...' }]"
								[selectedOption]="createMailingService.mode === 'new' ? 'Email FROM...' : createMailingService.mailing.FromType"
								[key]="'Name'"
								(onOptionSelect)="onEmailFromSelect($event)"
							></portal-dropdown-menu>
							<span *ngIf="createMailingService.mailing.SendDate">{{ createMailingService.mailing.FromType }}</span>
						</div>
					</div>

					<ng-container *ngIf="createMailingService.mailing.FromType === 'Custom'">
						<div class="row mgn-top15">
							<!--CUSTOM FROM EMAIL-->
							<div class="col-sm-6">
								<span>Custom FROM Email:</span>
								<i class="fas fa-asterisk ft-size7 red-text-light relative left5 bottom5"></i>
								<input
									[readonly]="createMailingService.mailing.SendDate"
									type="text"
									name="customFromEmail"
									[(ngModel)]="createMailingService.mailing.CustomFromEmail"
								/>
							</div>

							<!--CUSTOM FROM NAME-->
							<div class="col-sm-6">
								<span>Custom FROM Name:</span>
								<i class="fas fa-asterisk ft-size7 red-text-light relative left5 bottom5"></i>
								<input
									[readonly]="createMailingService.mailing.SendDate"
									type="text"
									name="customFromName"
									[(ngModel)]="createMailingService.mailing.CustomFromName"
								/>
							</div>
						</div>

						<!--CUSTOM CC EMAIL-->
						<div class="row mgn-top15">
							<div class="col-sm-6">
								<span>Custom CC Email:</span>
								<input
									[readonly]="createMailingService.mailing.SendDate"
									type="text"
									name="customCcEmail"
									[(ngModel)]="createMailingService.mailing.CustomCcEmail"
								/>
							</div>
						</div>
					</ng-container>

					<!--CHOOSE A TEMPLATE-->
					<div *ngIf="!createMailingService.mailing.SendDate" class="row mgn-top15">
						<div class="col-sm-12">
							<span>Template:</span>
							<portal-dropdown-menu
								*ngIf="templates$ | async as templates"
								[backgroundColor]="'gray-bg-dark'"
								[height]="'ht40'"
								[options]="templates"
								[selectedOption]="'Choose a template...'"
								[key]="'Name'"
								(onOptionSelect)="onTemplateSelect($event)"
							></portal-dropdown-menu>
						</div>
					</div>

					<!--INCLUDE MAIN CONTACTS ONLY-->
					<div *ngIf="!createMailingService.mailing.SendDate" class="row mgn-top15">
						<div class="col-sm-12">
							<portal-checkbox
								[backgroundColor]="'white-bg'"
								[isChecked]="createMailingService.mailing.IncludePrimaryContactOnly"
								[type]="'checkbox'"
								(checkboxClickNotify)="onIncludeMainContactsOnlyClick()"
							></portal-checkbox>
							<span (click)="onIncludeMainContactsOnlyClick()" class="mgn-left10 relative bottom6 pointer">Include main contacts only</span>
						</div>
					</div>

					<!--SAVE AS TEMPLATE-->
					<div *ngIf="!createMailingService.mailing.SendDate" class="row mgn-top15">
						<div class="col-sm-6 pd-top8">
							<portal-checkbox
								[backgroundColor]="'white-bg'"
								[isChecked]="createMailingService.saveAsTemplate"
								[type]="'checkbox'"
								(checkboxClickNotify)="createMailingService.saveAsTemplate = !createMailingService.saveAsTemplate"
							></portal-checkbox>
							<span (click)="createMailingService.saveAsTemplate = !createMailingService.saveAsTemplate" class="mgn-left10 relative bottom6 pointer">
								Save as template
							</span>
						</div>
						<div *ngIf="createMailingService.saveAsTemplate" class="col-sm-6">
							<input type="text" name="templateName" [(ngModel)]="createMailingService.mailerTemplate.Name" />
						</div>
					</div>
				</div>
				<div class="col-sm-7 col-sm-offset-1">
					<div *ngIf="!createMailingService.mailing.SendDate" class="row mgn-bottom15">
						<div class="col-xs-2 relative">
							<span>Copy/Paste tags:</span>
							<i
								ngxTippy
								tippyName="{{ utilService.guid | wrapFn }}"
								data-tippy-content="Click the tag to copy, then paste into the editor."
								[tippyProps]="{ appendTo: 'parent' }"
								class="far fa-question-circle absolute topneg16 right15"
							></i>
						</div>
						<div class="col-xs-2">
							<button (click)="copyTagService.onTagClick('[[FIRSTNAME]]')" class="white-bg">FIRST NAME</button>
						</div>
						<div class="col-xs-2">
							<button (click)="copyTagService.onTagClick('[[LASTNAME]]')" class="white-bg">LAST NAME</button>
						</div>
						<div class="col-xs-2">
							<button (click)="copyTagService.onTagClick('[[CSRNAME]]')" class="white-bg">CSR NAME</button>
						</div>
						<div class="col-xs-2">
							<button (click)="copyTagService.onTagClick('[[SALESNAME]]')" class="white-bg">SALES NAME</button>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12 relative">
							<span>Email Body:</span>
							<i class="fas fa-asterisk ft-size7 red-text-light relative left5 bottom5"></i>
							<em *ngIf="!createMailingService.mailing.SendDate" class="absolute top34 right25 ft-size11 z">Press "Shift + Enter" to begin a new line.</em>
							<angular-editor
								*ngIf="!createMailingService.mailing.SendDate"
								[config]="richTextService.editorConfig"
								[placeholder]="'Enter text here...'"
								name="editor"
								[(ngModel)]="createMailingService.mailing.Html"
							></angular-editor>
							<textarea *ngIf="createMailingService.mailing.SendDate" [readonly]>{{ createMailingService.mailing.Html }}</textarea>
						</div>
					</div>
				</div>
			</div>
			<div class="row mgn-top60">
				<div class="col-sm-2">
					<button
						*ngIf="!createMailingService.mailing.SendDate"
						(click)="onSendTestEmailClick()"
						[disabled]="createMailingService.mailing.TestEmail.length < 1"
						class="blue-bg white-text {{ createMailingService.mailing.TestEmail.length < 1 ? 'setOpacityLow' : '' }}"
					>
						<i class="far fa-envelope mgn-right10"></i>
						Send test message
					</button>
				</div>
				<div class="col-sm-2">
					<input
						*ngIf="!createMailingService.mailing.SendDate"
						type="text"
						name="testEmail"
						placeholder="Enter email address..."
						[(ngModel)]="createMailingService.mailing.TestEmail"
					/>
				</div>
				<div class="col-sm-2 col-sm-offset-1 relative">
					<i
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						data-tippy-content='Saves a copy in "Email Blast Tool - My Mailings". Does not send live emails. Disabled once email has been sent.'
						[tippyProps]="{ appendTo: 'parent' }"
						class="far fa-question-circle absolute topneg20 right15"
					></i>
					<button
						[disabled]="createMailingService.mailing.SendDate"
						(click)="createMailingService.createMailing(groupId)"
						class="white-bg {{ createMailingService.mailing.SendDate ? 'setOpacityLow' : null }}"
					>
						<i class="far fa-file-alt mgn-right10"></i>
						Save as draft
					</button>
				</div>

				<div class="col-sm-2 col-sm-offset-3 relative">
					<i
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						data-tippy-content="Sends the email to all recipients below. You must confirm after clicking this button. Disabled once email has been sent."
						[tippyProps]="{ appendTo: 'parent' }"
						class="far fa-question-circle absolute topneg20 right15"
					></i>
					<button
						[disabled]="createMailingService.mailing.SendDate"
						(click)="onSendLiveEmailsClick()"
						class="yellow-bg {{ createMailingService.mailing.SendDate ? 'setOpacityLow' : null }}"
					>
						<i class="fas fa-mail-bulk mgn-right10"></i>
						SEND LIVE EMAILS
					</button>
				</div>
			</div>
		</section>
	</form>

	<section class="pd15 shadow border-radius white-bg mgn-top30 mgn-bottom60">
		<div class="row">
			<div class="col-xs-9">
				<h2 class="semi-bold in-block">Recipient Preview</h2>
				<em class="ft-size24">({{ createMailingService.recipientsView?.length }})</em>
			</div>
			<div class="col-xs-3">
				<input type="text" (keyup)="onSearchKeyup()" [(ngModel)]="searchTerm" placeholder="Search..." />
				<button *ngIf="searchTerm.length > 0" style="width: initial" class="absolute top8 white-bg pd12 right20 z" (click)="searchTerm = ''">
					<i class="fa fa-times absolute center-unknown-height-width" aria-hidden="true"></i>
				</button>
			</div>
		</div>
		<div class="row mgn-top15">
			<div *ngIf="createMailingService.spinnerActive" class="relative top150">
				<portal-spinner [width]="'wdthpx76'" [height]="'htpx76'"></portal-spinner>
			</div>
			<div class="col-xs-12">
				<portal-table
					[table]="tableConfigService.table"
					[config]="tableConfigService.configuration"
					[data]="searchTerm.length > 0 ? createMailingService.searchResults : createMailingService.recipientsView"
					[totalItems]="searchTerm.length > 0 ? createMailingService.searchResults?.length : createMailingService.recipientsView?.length"
					(buttonClickNotify)="onDeleteRecipientClick($event)"
				></portal-table>
			</div>
		</div>
	</section>
</div>
