<portal-header></portal-header>

<div class="container-fluid pd-top10 mgn-top30 relative">
    <a class="dark-text absolute topneg21 left15" (click)="location.back()">
		
		<button class="blue-bg white-text pd10"><i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>Back to request</button>
    </a>
    <div class="row mgn-top5">
        <div class="col-xs-12 text-right">
            <em class="ft-size30">{{clipForReaderView.Title}}</em>
        </div>
    </div>
    <div class="row mgn-top15 mgn-bottom30">
        <div class="col-xs-12">
            <textarea style="height: 78vh; line-height: 1.75" class="setWidth100 ft-size36 pd15">{{clipForReaderView.ScriptText}}</textarea>
        </div>
    </div>
</div>