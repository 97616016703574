export enum EProductTypes {
	ON_HOLD = 1,
	RADIO = 2,
	LOBBY_VIDEO = 3,
	POSTER = 4,
	V_WALL_1X2_V = 7,
	OTHER = 8,
	V_WALL_4X1_V = 10,
	V_WALL_3X1_H = 12,
	V_WALL_2X2_H = 14,
	WEB_H = 16,
	WEB_V = 17,
	V_WALL_3X1_V = 20,
	V_WALL_2X1_V = 21,
	V_WALL_1X2_H = 23,
	V_WALL_2X1_H = 24,
	V_WALL_1X3_H = 25,
	V_WALL_2X2_V = 26,
	V_WALL_4X1_H = 27,
	OVERHEAD_MESSAGING = 31
}
