<div class="border-radius white-bg pd30 center relative">
    <div class="row">
        <div class="col-sm-12 text-center">
            <h1 class="in-block semi-bold">File Uploader</h1>
            <h3 class="mgn-top12"><em>{{fileUploadStateService.file.name}}</em></h3>
            <h4 *ngIf="fileUploadStateService.currentStep().contentBreadCrumbs" class="mgn-top15 ft-size12"><em>{{fileUploadStateService.selectedContent.breadcrumbs[0]}} > {{fileUploadStateService.selectedContent.breadcrumbs[1]}} 
                <span *ngIf="!fileUploadStateService.selectedContent.breadcrumbs[2]"> > {{fileUploadStateService.selectedContent.Title}}</span>
                <span *ngIf="fileUploadStateService.selectedContent.breadcrumbs[2]">
                    >					
                </span> {{fileUploadStateService.selectedContent.breadcrumbs[2]}} > {{fileUploadStateService.selectedContent.Title}}</em>
            </h4>
        </div>
    </div>
    <div class="ht26 gray-bg setWidth100 border-radius text-center relative shadow mgn-top30">
        <h3 [ngClass]="{'dark-text' : fileUploadStateService.percentComplete < 48, 'white-text' : fileUploadStateService.percentComplete > 48}" class="absolute setWidth100 setHeight100">{{fileUploadStateService.percentComplete}}%</h3>
        <div [ngStyle]="{'width.%': fileUploadStateService.percentComplete}" class="blue-gradient setHeight100 border-radius transition"></div>
    </div>
    <div *ngIf="fileUploadStateService.uploadComplete" class="row mgn-top20">
        <div class="col-sm-8 col-sm-offset-2">
            <h3>Success!</h3>
            <span>
                <em>{{fileUploadStateService.currentStep().successMessage}}</em>
            </span>
        </div>
    </div>
    <div class="row mgn-top60">
        <div class="col-sm-4 col-sm-offset-4">
            <button class="border-radius {{fileUploadStateService.uploadComplete ? 'gray-bg-dark' : 'red-bg-light white-text'}} ht40 setWidth100 ft-size16" (click)="onExitOrAbortClick()">
            <i *ngIf="!fileUploadStateService.uploadComplete" class="fa fa-exclamation-triangle mgn-right10 white-text" aria-hidden="true"></i>
            <span *ngIf="!fileUploadStateService.uploadComplete">Abort upload</span>
            <span *ngIf="fileUploadStateService.uploadComplete">Exit</span>
            </button>
        </div>
    </div>
</div>
