<div class="row mgn-bottom15">
	<div class="col-sm-2">
		<h2 class="semi-bold"><i class="fa fa-history mgn-right10 green-text" aria-hidden="true"></i>History Notes</h2>
	</div>
	<div class="col-sm-2">
		<portal-dropdown-menu *ngIf="locationsService.locationsDropdownView"
							  [backgroundColor]="'gray-bg-dark'"
							  [height]="'ht40'"
							  [key]="'Name'"
							  [scrollbarHidden]="true"
							  [selectedOption]="'Filter by location...'"
							  [options]="locationsService.locationsDropdownView"
							  (onOptionSelect)="onLocationSelect($event)">
		</portal-dropdown-menu>
	</div>
	<div class="col-sm-2 mgn-bottom-xs">
		<div class="relative top9 in-block mgn-right6">
			<portal-checkbox 
						[backgroundColor]="'white-bg'"
						[isChecked]="historyNotesService.viewState.includeSystemGenerated"
						[type]="'checkbox'"
						(checkboxClickNotify)="showSystemMessagesClick()">
			</portal-checkbox>
		</div>
		<label class="pointer relative top2" (click)="showSystemMessagesClick()">Show system messages</label>
	</div>
	<div class="col-sm-3 col-sm-offset-3">
		<input style="border: 0.125rem solid #d6d9db;" class="ht40 setWidth100 gray-bg-dark" [ngModel]="historyNotesService.searchTerm" (ngModelChange)="onSearchKeyup($event)" placeholder="Search...">
		<button *ngIf="historyNotesService.searchTerm?.length > 0" style="width: initial" class="absolute top8 white-bg pd12 right24 z" (click)="onSearchClear()">
			<i class="fa fa-times absolute center-unknown-height-width" aria-hidden="true"></i>
		</button>
	</div>
</div>

<div *ngIf="spinnerActive" class="relative z top200">
	<portal-spinner [width]="'wdthpx76'" [height]="'htpx76'"></portal-spinner>
</div>
<portal-table class="hidden-xs"
			[table]="tableConfigService.table"
			[totalItems]="historyNotesService.totalItems"
			[config]="tableConfigService.configuration"
			[data]="searchResults.length ? searchResults : historyNotesService.historyNotes"
			[showJumpToPage]="true"	
			[page]="historyNotesService.viewState.pageNumber"					
			(buttonClickNotify)="onTableButtonClick($event)"
			(currentPageNotify)="onPageClick($event)">
</portal-table>

<!--***********************
	MOBILE VIEW
*************************-->
<div class="mgn-bottom60 hidden-sm hidden-md hidden-lg">
	<div *ngIf="spinnerActive" class="relative center-unknown-height-width top200 z"><portal-spinner [height]="'htpx90'" [width]="'wdthpx90'" ></portal-spinner></div>
	<div *ngFor="let historyNote of historyNotesService.historyNotes; let isEven = even;" class="mgn-bottom30">
		<hn-xs-view 
				[isEven]="isEven"
				[historyNote]="historyNote"
				(buttonClickNotify)="onTableButtonClick($event)">
		</hn-xs-view>
	</div>
</div>