import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { CsrTasksView } from '../csr-tasks/_models';
import { CsrHomeContentRequestTable } from 'src/app/content-requests/_models';

@Injectable({
	providedIn: 'root'
})
export class CsrHomeService {
	public myRequests: CsrHomeContentRequestTable[];
	public myTasks: CsrTasksView[];

	constructor(private httpClient: HttpClient) {}

	public getMyTasks(completedOnly: boolean, userId: number): void {
		let url = `${environment.adminUrl}CoreCsrTasks/Csr/${userId}/Today?completedOnly=${completedOnly}&orderByCol=Name&orderDesc=false&pageSize=1000&pageNumber=1`;

		this.httpClient.get(url).subscribe((myTasks: CsrTasksView[]) => {
			this.myTasks = this.tasksView(myTasks);
		});
	}

	private tasksView(tasks: CsrTasksView[]): CsrTasksView[] {
		return tasks.map((task) => {
			task.completeBtn = task.CompleteDate ? 'fas fa-undo-alt' : 'fas fa-check';
			return task;
		});
	}

	public getMyContentRequests(userId: number): void {
		let url = `${environment.adminUrl}ContentRequests/Active/CSR/${userId}`;
		this.httpClient.get(url).subscribe((requests: CsrHomeContentRequestTable[]) => {
			this.myRequests = this.requestsView(requests);
		});
	}

	private requestsView(requests: CsrHomeContentRequestTable[]): CsrHomeContentRequestTable[] {
		return requests.map((request) => {
			request.viewBtn = request.ContentRequest.RequestType === 'On-Hold Message' ? 'blue-bg' : 'client-health-red';
			return request;
		});
	}
}
