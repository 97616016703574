export class FeedSources {
	Id: number;
	FeedSourceTypeId: number;
	Name: string;
	Description: string;
	Icon: string;
	InternalUrl: string;
	ExternalUrl: string;
	Username: string;
	Password: string;
	InternalFeedTypeId: number;
	PreloadFeed: boolean;
	TickerCompatible: boolean;
	IsGlobal: boolean;
	IconColor: string;
}