<div
	[ngClass]="content.IsDeleted ? 'gray-bg-dark' : 'white-bg '"
	class="row border-radius {{ !isLastIndex ? 'border-first-index' : 'border-last-index border-first-index mgn-bottom1' }} pd-top15 pd-bottom15"
>
	<div class="col-md-10">
		<div class="row {{ securityService.writeAccessEnabled ? 'draggable' : null }}" (mousedown)="contentViewService.onContentDrag(content)">
			<!--***************************
				EDIT BUTTON
			****************************-->
			<div class="col-md-2 ft-size16 relative">
				<button
					class="z {{ content.detailsVisible ? 'limeGreen-bg white-text' : 'gray-bg' }} {{ !contentViewService.saveEnabled(content) ? 'setOpacityLow' : '' }}"
					[disabled]="content.detailsVisible && !contentViewService.saveEnabled(content)"
					(click)="contentViewService.onSaveContentDetailsClick(content)"
				>
					{{ content.detailsVisible ? 'Save' : 'Edit' }}
				</button>
				<span *ngIf="appStateService.currentUser.Role === enumUserRole.ADMINISTRATOR" class="absolute bottomneg41 left15 ft-size14">ID: {{ content.Id }}</span>
			</div>

			<!--***************************
				VIDEO PREVIEW
			****************************-->
			<div class="col-md-3">
				<portal-preview-thumbnail *ngIf="thumbVisible" [content]="content"></portal-preview-thumbnail>
			</div>

			<!--***************************
				CONTENT TITLE
			****************************-->
			<div class="col-md-5 ft-size16 relative {{ securityService.writeAccessEnabled ? 'draggable' : null }}">
				<div class="relative">
					<i
						*ngIf="!!contentViewService.featuredContentProps(content)"
						class="{{ contentViewService.featuredContentProps(content).icon }} {{
							contentViewService.featuredContentProps(content).color
						}} absolute leftneg22 top2"
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ appendTo: 'parent' }"
						attr.data-tippy-content="{{ contentViewService.featuredContentProps(content).tooltip }}"
					></i>
					<span class="{{ securityService.writeAccessEnabled ? 'draggable' : null }}">{{ content.Title }}</span>
				</div>
			</div>

			<div class="col-md-2">
				<!--*******************
					CREATE DATE
				********************-->
				<em *ngIf="contentViewService.showCreateDate(content)" class="ft-size12">
					<i class="fa fa-calendar-plus-o mgn-right5" aria-hidden="true"></i>
					{{ content.CreateDate | date : 'mediumDate' }}
				</em>

				<!--*******************
					FILE NAME
				********************-->
				<div *ngIf="appStateService.currentUser.IsEmployee && content.contentFiles?.length > 0">
					<h4 (mouseover)="fileNameTooltipVisible = true" (mouseleave)="fileNameTooltipVisible = false" class="ft-size11 ellipsis">
						{{ content.contentFiles[0].MasterFileName }}
					</h4>
				</div>

				<!--****************************
					UPLOAD PROCESSING STATE
				******************************-->
				<em class="ft-size12 {{ content.processingStateJson === 'Ready' ? 'green-text' : null }} ">{{ content.processingStateJson }}</em>

				<!--***********************
					FILE NAME TOOLTIP
				************************-->
				<div *ngIf="fileNameTooltipVisible" style="opacity: 0.8; z-index: 200" class="top42 right0 dark-bg white-text border-radius absolute pd2 ft-size11">
					<h4 class="ft-size12 pd5">{{ content.contentFiles[0].MasterFileName }}</h4>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-2">
		<div class="row">
			<!--***************************
				DELETE BUTTON
			****************************-->
			<div class="col-md-6">
				<button
					(click)="contentViewService.onDeleteClick(content)"
					[ngClass]="{ 'red-bg-light white-text': !content.IsDeleted, 'gray-bg-darker': content.IsDeleted }"
					class="relative"
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					[tippyProps]="{ appendTo: 'parent' }"
					attr.data-tippy-content="{{ content.IsDeleted ? 'Restore' : 'Delete' }}"
				>
					<i [ngClass]="content.IsDeleted ? 'fas fa-redo-alt' : 'far fa-trash-alt'" class="ft-size18" aria-hidden="true"></i>
				</button>
			</div>

			<!--***************************
				SELECT CHECKBOX
			****************************-->
			<div class="col-md-6 text-right">
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="content.isSelected"
					[isDisabled]="content.IsDeleted"
					[type]="'checkbox'"
					(checkboxClickNotify)="batchChangeCategoryService.onCheckboxSelect(content)"
				></portal-checkbox>
			</div>
		</div>
	</div>

	<!--***************************
		SCRIPT
	****************************-->
	<div *ngIf="content.detailsVisible" class="col-lg-12">
		<c-details [content]="content"></c-details>
	</div>
</div>
