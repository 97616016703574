import { Injectable } from "@angular/core";
@Injectable()
export class Product {

	//These values don't change. Each item is a product
    public products: Object = {
        hold: 1,
        radio: 2,
        video: 3,
        poster: 4,
        vwall5x1v: 6,
        vwall1x2v: 7,
        other: 8,
    }
    

    public getProducts(): Object {
        return this.products;
    }
}