<div class="modal-overlay"></div>
<div class="centered setZ border-radius gray-bg shadow pd16 setWidth34">
	<div class="row">
		<div class="col-sm-10">
			<h4 class=" ft-size24 ">{{message}}</h4>
		</div>
		<div class="col-sm-2 ht40">
			<portal-spinner class="" [width]="'wdthpx36'" [height]="'htpx36'"></portal-spinner>
		</div>
	</div>
</div>