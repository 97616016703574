<portal-header></portal-header>

<div class="container-fluid mx-4 mgn-top30 relative">
	<a (click)="routerLocation.back()" class="dark-text absolute topneg21 left0">
		<i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
		<span class="ft-size16">Back</span>
	</a>
	<div *ngIf="location" class="ft-size16">
		<div class="row relative">
			<h1>
				<i class="fas fa-map-marker-alt mgn-right12" aria-hidden="true"></i>
				<span class="semi-bold">{{ viewState === 'new' ? 'New' : 'Edit' }} Location</span>
				<span *ngIf="location.Name?.length > 0">|</span>
				<em>{{ location.Name }}</em>
			</h1>
		</div>
		<div class="row purple-gradient ht2 mgn-top15 border-radius"></div>

		<form (keydown.enter)="$event.preventDefault()" #form="ngForm" novalidate (ngSubmit)="onSaveClick()">
			<div class="white-bg shadow pd15 mgn-leftneg15 mgn-rightneg15 border-radius mgn-top30 mgn-bottom40">
				<div class="row">
					<div class="col-sm-10 col-sm-offset-2">
						<h2 class="semi-bold mgn-bottom30">Location Information</h2>
						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<span class="semi-bold">Name:</span>
							</div>
							<div class="col-sm-6 relative ht40">
								<input
									[ngClass]="{ 'red-border-light': !locationName.valid && !locationName.pristine }"
									required
									#locationName="ngModel"
									id="locationName"
									name="locationName"
									[(ngModel)]="location.Name"
									type="text"
								/>
								<small class="red-text-light" [hidden]="locationName.valid || (locationName.pristine && !form.submitted)">Location name is required.</small>
								<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
							</div>
						</div>
						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<span class="semi-bold">Address:</span>
							</div>
							<div class="col-sm-6">
								<input name="address" [(ngModel)]="location.Address1" type="text" />
							</div>
						</div>

						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<label class="semi-bold">City:</label>
							</div>
							<div class="col-sm-2">
								<input name="city" [(ngModel)]="location.City" type="text" />
							</div>
							<div class="col-sm-2 text-right">
								<label class="semi-bold">State:</label>
							</div>
							<div class="col-sm-2">
								<portal-dropdown-menu
									[backgroundColor]="'gray-bg-dark'"
									[height]="'ht40'"
									[key]="'name'"
									[options]="statesListService.statesList"
									[selectedOption]="location.State"
									(onOptionSelect)="onDropdownSelect($event, 'State')"
								></portal-dropdown-menu>
							</div>
						</div>
						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<label class="semi-bold">Zip:</label>
							</div>
							<div class="col-sm-2 relative">
								<input
									class="{{ invalidZip ? 'border red-border-light' : null }}"
									name="zip"
									(blur)="onZipBlur()"
									(keyup)="onZipKeyup()"
									[(ngModel)]="location.Zip"
									type="text"
								/>
								<small *ngIf="invalidZip" class="absolute setWidth100 bottomneg16 left15 red-text-light ft-size10">*Not a valid zip code.</small>
							</div>
							<div class="col-sm-2 text-right">
								<label class="semi-bold">Weather Override:</label>
							</div>
							<div class="col-sm-2">
								<input name="weatherZip" [(ngModel)]="location.WeatherZip" type="text" />
							</div>
						</div>
						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<label class="semi-bold">Phone:</label>
							</div>
							<div class="col-sm-2">
								<input name="phone" [(ngModel)]="location.Phone" type="text" />
							</div>
							<div class="col-sm-2 text-right">
								<label class="semi-bold">Fax:</label>
							</div>
							<div class="col-sm-2">
								<input name="fax" [(ngModel)]="location.Fax" type="text" />
							</div>
						</div>
					</div>
				</div>
				<div class="row mgn-top30 mgn-bottom40">
					<div class="col-sm-10 col-sm-offset-2">
						<h2 class="semi-bold mgn-bottom30">Location Settings</h2>
						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<span class="semi-bold">Main Contact:</span>
							</div>
							<div class="col-sm-6">
								<portal-dropdown-menu
									[backgroundColor]="'gray-bg-dark'"
									[height]="'ht40'"
									[key]="'name'"
									[options]="users"
									[selectedOption]="location.PrimaryContactUser ? primaryContactUser : 'Please select a user'"
									(onOptionSelect)="onDropdownSelect($event, 'PrimaryContactUserId')"
								></portal-dropdown-menu>
							</div>
						</div>
						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<label class="semi-bold">Phone System:</label>
							</div>
							<div class="col-sm-6">
								<input name="phoneSystem" [(ngModel)]="location.PhoneSystem" type="text" />
							</div>
						</div>
					</div>
				</div>
				<div class="row mgn-top50 mgn-bottom50">
					<div class="col-sm-10 col-sm-offset-2">
						<div class="row">
							<div class="col-sm-8">
								<!-- <hr class="mgn-top30"> -->
								<div class="row">
									<div class="col-xs-3 col-sm-offset-6">
										<button type="button" class="gray-bg border-radius setWidth100" [routerLink]="['/crm/client-details/locations']">Cancel</button>
									</div>
									<div class="col-xs-3">
										<button
											[disabled]="!form.valid || saveInProgress || invalidZip"
											[ngClass]="!form.valid || saveInProgress || invalidZip ? 'setOpacityLow' : ''"
											class="limeGreen-bg border-radius white-text setWidth100"
										>
											Save
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
