<portal-header></portal-header>

<div class="container-fluid fadeInUp">
	<div class="row mgn-bottom15 mgn-top15">
		<div class="col-xs-4 col-md-2">
			<div class="row">
				<div class="col-xs-12 col-lg-9">
					<button [routerLink]="[backUrl]" class="white-bg">
						<i class="fas fa-chevron-left mgn-right12"></i>
						Dashboard
					</button>
				</div>
			</div>
		</div>
		<div class="col-xs-6 col-xs-offset-2 col-md-offset-4 text-right">
			<i class="mgn-right12 ft-size28 fas fa-cut red-text"></i>
			<h1 class="in-block">
				<span class="semi-bold">Create24</span>
				|
				<em>{{ appStateService.product.ProductName }}</em>
			</h1>
		</div>
	</div>

	<div class="red-gradient mgn-bottom45 ht2 border-radius"></div>

	<div class="row mgn-top30">
		<div class="col-sm-6 {{ appStateService.product.ProductName === 'Lobby Video' ? 'col-lg-3' : 'col-lg-4' }}">
			<div id="create-video" style="min-height: 34.688rem" class="card pd20 shadow center white-bg">
				<img style="width: 100%" src="assets/c24-new-video2.png" />
				<h1 class="ft-size28 mgn-top60 in-block relative">
					<span class="semi-bold">Create New Video</span>
					<i
						*ngIf="appStateService.product.Route === 'video'"
						class="fas fa-question-circle red-text pointer mgn-left15 relative topneg10 ft-size16"
						[ngxTippy]="htmlVideo"
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ allowHTML: true, interactive: true, placement: 'top', appendTo: 'parent', arrow: true, theme: 'dark' }"
					></i>
					<ng-template #htmlVideo>
						<div [ngStyle]="{ 'width.px': 210 }" class="text-left">
							<p>Tutorials:</p>
							<span>
								<ng-container *ngFor="let link of c24NewVideoLinks">
									<i class="fas fa-video red-text mgn-right10"></i>
									<a class="white-text underline" href="{{ link.url }}" target="_blank">
										{{ link.title }}
									</a>
									<br />
								</ng-container>
							</span>
						</div>
					</ng-template>
				</h1>

				<button
					[routerLink]="['/create-24/dashboard/content-browser/' + appStateService.product.Route + '/new-video']"
					class="red-bg mgn-top60 white-text ft-size20"
				>
					Get started
				</button>
				<button
					[routerLink]="['/create-24/dashboard/content-browser/' + appStateService.product.Route + '/video-template']"
					[ngStyle]="{
						visibility: appStateService.product.ProductName === 'Lobby Video' || appStateService.product.ProductName === 'Poster' ? 'visible' : 'hidden'
					}"
					class="mgn-top40 red-bg white-text ft-size20"
				>
					Start with a template
				</button>
			</div>
		</div>
		<div class="col-sm-6 {{ appStateService.product.ProductName === 'Lobby Video' ? 'col-lg-3' : 'col-lg-4' }}">
			<div id="create-image" style="min-height: 34.688rem" class="card pd20 shadow center white-bg">
				<img style="width: 100%" src="assets/c24-new-image6.png" />
				<h1 class="ft-size28 mgn-top60 in-block relative">
					<span class="semi-bold">Create New Image</span>
					<i
						*ngIf="appStateService.product.Route === 'video'"
						class="fas fa-question-circle red-text pointer mgn-left15 relative topneg10 ft-size16"
						[ngxTippy]="htmlImage"
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ allowHTML: true, interactive: true, placement: 'top', appendTo: 'parent', arrow: true, theme: 'dark' }"
					></i>
					<ng-template #htmlImage>
						<div [ngStyle]="{ 'width.px': 210 }" class="text-left">
							<p>Tutorials:</p>
							<span>
								<ng-container *ngFor="let link of c24NewImageLinks">
									<i class="fas fa-video red-text mgn-right10"></i>
									<a class="white-text underline" href="{{ link.url }}" target="_blank">
										{{ link.title }}
									</a>
									<br />
								</ng-container>
							</span>
						</div>
					</ng-template>
				</h1>

				<button
					[routerLink]="['/create-24/dashboard/content-browser/' + appStateService.product.Route + '/new-image']"
					class="relative mgn-top60 red-bg white-text ft-size20"
				>
					Get started
				</button>
				<button
					[routerLink]="['/create-24/dashboard/content-browser/' + appStateService.product.Route + '/still-template']"
					[ngStyle]="{
						visibility: appStateService.product.ProductName === 'Lobby Video' || appStateService.product.ProductName === 'Poster' ? 'visible' : 'hidden'
					}"
					class="mgn-top40 red-bg white-text ft-size20"
				>
					Start with a template
				</button>
			</div>
		</div>
		<div *ngIf="appStateService.product.ProductName === 'Lobby Video'" class="col-sm-6 col-lg-3">
			<div id="template" style="min-height: 34.688rem" class="card pd20 shadow center white-bg">
				<img style="width: 100%" src="assets/c24-widget2.png" />
				<h1 class="ft-size28 mgn-top60 in-block relative">
					<span class="semi-bold">Create New Sidebar</span>
					<i
						class="fas fa-question-circle red-text pointer mgn-left15 relative topneg10 ft-size16"
						[ngxTippy]="htmlSidebar"
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ allowHTML: true, interactive: true, placement: 'top', appendTo: 'parent', arrow: true, theme: 'dark' }"
					></i>
					<ng-template #htmlSidebar>
						<div [ngStyle]="{ 'width.px': 210 }" class="text-left">
							<p>Tutorial:</p>
							<span>
								<ng-container *ngFor="let link of c24NewSidebarLinks">
									<i class="fas fa-video red-text mgn-right10"></i>
									<a class="white-text underline" href="{{ link.url }}" target="_blank">
										{{ link.title }}
									</a>
									<br />
								</ng-container>
							</span>
						</div>
					</ng-template>
				</h1>
				<button
					[routerLink]="['/create-24/dashboard/content-browser/' + appStateService.product.Route + '/new-sidebar']"
					class="red-bg mgn-top60 white-text ft-size20"
				>
					Get started
				</button>
				<button
					[routerLink]="['/create-24/dashboard/content-browser/' + appStateService.product.Route + '/sidebar-template']"
					class="mgn-top40 red-bg white-text ft-size20"
				>
					Start with a template
				</button>
			</div>
		</div>
		<div class="col-sm-6 {{ appStateService.product.ProductName === 'Lobby Video' ? 'col-lg-3' : 'col-lg-4' }}">
			<div id="custom-library" style="min-height: 34.688rem" class="card pd20 shadow center white-bg">
				<img style="width: 100%" src="assets/c24-edit-project1.png" />
				<h1 class="ft-size28 mgn-top60 semi-bold">Edit An Existing Project</h1>
				<button
					class="mgn-top60 red-bg white-text ft-size20"
					[routerLink]="['/create-24/dashboard/content-browser/' + appStateService.product.Route + '/edit-project']"
				>
					Get started
				</button>
				<button style="visibility: hidden" class="mgn-top40 ft-size20">Start with a template</button>
			</div>
		</div>
	</div>
</div>
