import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppStateService } from 'src/app/core/services';
import { Column } from '../../shared/components/table/view-models';
import { Events, MessageService } from 'src/app/core/services';
import { LocationsService } from './locations.service';
import { SearchFilterPipe } from '../../shared/pipes/search-filter.pipe';
import { ServiceLocations } from '../../shared/api-models/admin';
import { TableConfigService } from './table-config.service';

@Component({
	selector: 'crm-locations',
	templateUrl: './locations.component.html',
	styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
	public searchActive: boolean;
	public searchResults: ServiceLocations[] = [];
	public searchTerm: string;

	constructor(
		private appStateService: AppStateService,
		public locationsService: LocationsService,
		private messageService: MessageService,
		private router: Router,
		private searchFilterPipe: SearchFilterPipe,
		public tableConfigService: TableConfigService
	) {}

	ngOnInit() {
		this.getLocations();
	}

	private getLocations(): void {
		this.locationsService.getAllLocationsForClient().subscribe((locations) => {
			this.locationsService.locations = locations;
		});
	}

	public onSearchClear(): void {
		this.searchTerm = null;
		this.searchResults = [];
	}

	public onSearchKeyup(): void {
		this.searchActive = this.searchTerm.length > 0 ? true : false;
		this.searchResults = this.searchFilterPipe.transform(this.locationsService.locations, this.searchTerm);
	}

	public onTableButtonClick(btnEvent: [Column, ServiceLocations]): void {
		const [column, location] = btnEvent;
		if (column.key === 'viewBtn') {
			this.appStateService.setSessionItem('locationId', JSON.stringify(location.Id));
			this.router.navigate([`/crm/client-details/locations/location-details`]);
		}
		if (column.key === 'deleteBtn') {
			let confirmDelete: boolean = confirm(`Delete service location "${location.name}"?`);
			if (confirmDelete) {
				this.messageService.publish(Events.savingPreloader, 1);
				this.locationsService.deleteLocation(location.Id).subscribe(() => {
					this.getLocations();
					this.messageService.publish(Events.savingPreloader, 0);
				});
			}
		}
	}
}
