import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppStateService, Events, MessageService } from '../core/services';
import { environment } from '../../environments/environment';
import { Players } from '../shared/api-models/admin';

@Injectable({
    providedIn: 'root'
})
export class GlobalSettingsService {

    constructor(
            private appStateService: AppStateService,
            private httpClient: HttpClient,
            private messageService: MessageService
    ) { }

    public updateClientRecord(prop: string, value: any): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.httpClient.patch(`${environment.adminUrl}CoreClients/${this.appStateService.currentClient.Id}`, {[prop]: value})
            .subscribe( () => {
                this.appStateService.currentClient[prop] = value;
                this.appStateService.setSessionItem('currentClient', JSON.stringify(this.appStateService.currentClient));
                this.messageService.publish(Events.savingPreloader, 0);
            })
    }

    public getAvailableHoldPlayers(): Observable<Players[]> {
        return this.httpClient.get<Players[]>(`${environment.adminUrl}CorePlayers/AvailablePlayers?productTypeId=1&clientId=${this.appStateService.currentClient.Id}`);
    }

    public validateEmailList(emailList: string): boolean {
        const emails = emailList.split(';')

        let valid = true;
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (var i = 0; i < emails.length; i++) {
            if( emails[i] === "" || !regex.test(emails[i].replace(/\s/g, ""))){
                valid = false;
            }
        }
        return valid;
    }
}
