export class ContentRequestAttachments {
    Id: number;
    ContentRequestId: number;
    FileId: number;
    FriendlyName: string;

    constructor(contentRequestId: number, friendlyName: string) {
		this.ContentRequestId = contentRequestId;
		this.FriendlyName = friendlyName;
	}
}