import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { AppStateService, DateTimeService, Events, MessageService, UtilitiesService } from '../../../core/services';
import { CreateEditTaskService } from './create-edit-task.service';
import { CsrTaskTypes } from '../../../shared/api-models/admin';
import { CsrTasksView } from '../_models/csr-tasks-view';

@Component({
	selector: 'ct-create-edit-task',
	templateUrl: './create-edit-task.component.html',
	styleUrls: ['./create-edit-task.component.scss'],
	providers: [CreateEditTaskService]
})
export class CreateEditTaskComponent implements OnInit {
	public editMode: 'edit' | 'new';
	public task: CsrTasksView;

	constructor(
		public appStateService: AppStateService,
		public createEditTaskService: CreateEditTaskService,
		private dateTimeService: DateTimeService,
		public location: Location,
		private messageService: MessageService,
		private utilService: UtilitiesService
	) {}

	ngOnInit() {
		let taskId: number = +this.utilService.getLastParam(this.appStateService.activeUrl);
		this.setEditMode();
		this.getTaskTypes(taskId);

		if (this.editMode === 'new') {
			this.newTaskInit();
		}
	}

	private getTaskTypes(taskId?: number): void {
		this.createEditTaskService.getTaskTypes().subscribe((taskTypes: CsrTaskTypes[]) => {
			this.createEditTaskService.taskTypes = taskTypes;

			if (this.editMode === 'edit') {
				this.getTask(taskId);
			}
		});
	}

	private getTask(taskId: number): void {
		this.createEditTaskService.getTask(taskId).subscribe((task: CsrTasksView) => {
			this.task = task;
		});
	}

	onDateChange(date: string): void {
		this.task.DueDate = this.dateTimeService.dateUtcToLocalString(date);
	}

	public onEnterClick(): void {
		//Add a line break when clicking enter
		this.task.Notes = this.task.Notes.concat('\n');
	}

	public onSaveClick(): void {
		this.messageService.publish(Events.savingPreloader, 1);
		if (this.editMode === 'new') {
			this.createEditTaskService.postNewTask(this.task).subscribe(() => {
				this.onSaveSuccess();
			});
		} else if (this.editMode === 'edit') {
			this.createEditTaskService.updateTask(this.task).subscribe(() => {
				this.onSaveSuccess();
			});
		}
	}

	private onSaveSuccess(): void {
		this.messageService.publish(Events.savingPreloader, 0);
		this.location.back();
	}

	public onTaskTypeSelect(taskType: CsrTaskTypes): void {
		this.task.CsrTaskTypeId = taskType.Id;
	}

	private newTaskInit(): void {
		this.task = new CsrTasksView();
		this.task.ClientId = this.appStateService.currentClient.Id;
		this.task.clientName = this.appStateService.currentClient.Name;
		this.task.CsrId = this.appStateService.currentUser.UserId;
		this.task.Completed = false;
		this.task.CsrTaskTypeId = 7;
		this.task.taskType = 'CSR';
	}

	private setEditMode(): void {
		if (this.utilService.includes(this.appStateService.activeUrl, 'new')) {
			this.editMode = 'new';
		} else {
			this.editMode = 'edit';
		}
	}
}
