import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IAngularMyDpOptions, AngularMyDatePickerDirective, IMyDateModel } from 'angular-mydatepicker';

@Component({
    selector: 'portal-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss']
})

//DON'T USE THIS COMPONENT BY ITSELF!
//Use either DateTimeSelectorComponent or
//StartStopDateTimeComponent. They each have
//model and view conversions for date and time.
export class DatePickerComponent implements OnInit {

    @Input() backgroundColor: string;
    @Input() clearDateEnabled: boolean;
    @Input() dateFormat: string;
    @Input() disableUntil: any;
    @Input() fontSize: string;
    @Input() height: string;
    @Input() model: IMyDateModel;

    @Output() dateChanged = new EventEmitter<any>();
    @ViewChild('dp') mydp: AngularMyDatePickerDirective;

    public datePickerOptions: IAngularMyDpOptions = {
        // other options...
        dateFormat: 'mm/dd/yyyy',
        firstDayOfWeek: 'su',
        stylesData: {
            styles: `
                    .myDpDaycell .myDpHighlight {
                        color: #16a085;
                    }
                   .myDpDaycell {
                       color: #2980b9;
                   }
                   
                   .myDpIcon,
                   .myDpHeaderBtn {
                       color: #23282D;
                   }
                   .myDpSelector {
                       box-shadow: rgba(0,0,0,0.5) 0px 7px 27px -13px;
                   }
            `
        }
    }

    constructor() { }

    ngOnInit() {
        if(this.dateFormat) {
            this.datePickerOptions.dateFormat = this.dateFormat; 
        }
        if(this.disableUntil) {
            this.datePickerOptions.disableUntil = this.disableUntil;
        }
    }

    public clearDate(): void {
        this.mydp.clearDate();
    }

    public onDateChanged(event): void {
        this.dateChanged.emit(event);
    }
}
