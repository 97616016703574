import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { UtilitiesService } from '../../core/services/utilities.service';
import { ContentLibraries } from 'src/app/shared/api-models/content';
import { LibrariesView } from '../library-editor/libraries-view';
import { IContentUsageReport } from 'src/app/shared/api-models/reporting';
import { CategoryPlaceholder, ICategories } from './_models/usage-report.interface';

@Injectable({
	providedIn: 'root'
})
export class UsageReportsService {
	constructor(private httpClient: HttpClient, private utilService: UtilitiesService) {}

	public getLibraries$(searchTerm: string): Observable<LibrariesView[]> {
		return this.httpClient.get(`${environment.contentUrl}ContentLibraries/NameSearch/${searchTerm}`).pipe(
			map((libraries: LibrariesView[]) => {
				libraries = libraries
					.map((library) => {
						library.name = library.LibraryName;
						library.productTypeId = library.ProductTypeId;
						library.bgColor = this.setProductProps(library).bgColor;
						library.icon = this.setProductProps(library).icon;
						library.gradientColor = this.setProductProps(library).gradientColor;
						library.productName = this.setProductProps(library).productName;
						return library;
					})
					.filter((library) => !library.IsDeleted);
				return this.utilService.sortByProductTypeIdAndName(libraries);
			})
		);
	}

	public getLibrariesByIdArr$(idArr: number[]): Observable<ContentLibraries[]> {
		const getLibraries$ = idArr.map((id) => {
			return this.httpClient.get<ContentLibraries>(`${environment.contentUrl}ContentLibraries/${id}`);
		});
		return forkJoin(getLibraries$);
	}

	public getCategories$(
		libraryIds: number[],
		fromDateString: string,
		toDateString: string,
		pageNumber: number,
		pageSize: number,
		clipNameSearchTerm: string
	): Observable<ICategories> {
		const params = { fromDateString, toDateString, pageNumber, pageSize, clipNameSearchTerm };
		return this.httpClient.post(`${environment.adminUrl}Reports/ContentUsage/Libraries/Categories?${this.buildReportQueryParams(params)}`, libraryIds).pipe(
			map((res: ICategories) => {
				const categories: string[] = res.categories.sort((a, b) => a.localeCompare(b));
				const subCategories: string[] = res.subCategories.sort((a, b) => a.localeCompare(b));
				categories.unshift(CategoryPlaceholder.CATEGORY);
				subCategories.unshift(CategoryPlaceholder.SUBCATEGORY);
				return { categories, subCategories };
			})
		);
	}

	public getUsageReport$(
		libraryIds: number[],
		fromDateString: string,
		toDateString: string,
		pageNumber: number,
		pageSize: number,
		clipNameSearchTerm: string,
		category: string,
		subCategory: string,
		fromCreateDateString,
		toCreateDateString
	): Observable<[IContentUsageReport[], number]> {
		const params = { fromDateString, toDateString, pageNumber, pageSize, clipNameSearchTerm, category, subCategory, fromCreateDateString, toCreateDateString };
		return this.httpClient
			.post<IContentUsageReport[]>(`${environment.adminUrl}Reports/ContentUsage/Libraries?${this.buildReportQueryParams(params)}`, libraryIds, {
				observe: 'response'
			})
			.pipe(
				map((res: any) => {
					const report: IContentUsageReport[] = res.body;
					const totalItems: number = parseInt(res.headers.get('x-result-count'));
					return [report, totalItems];
				})
			);
	}

	private buildReportQueryParams(params: any): string {
		let query: string = '';
		query += params.fromDateString ? `fromDateString=${params.fromDateString}&` : '';
		query += params.toDateString ? `toDateString=${params.toDateString}&` : '';
		query += params.pageSize ? `pageSize=${params.pageSize}&` : '';
		query += params.pageNumber ? `pageNumber=${params.pageNumber}&` : '';
		query += params.clipNameSearchTerm ? `titleSearch=${params.clipNameSearchTerm}&` : '';
		query += params.category ? `category=${params.category}&` : '';
		query += params.subCategory ? `subCategory=${params.subCategory}&` : '';
		query += params.fromCreateDateString ? `fromCreateDateString=${params.fromCreateDateString}&` : '';
		query += params.toCreateDateString ? `toCreateDateString=${params.toCreateDateString}&` : '';
		return this.utilService.trimEnd(query, '&');
	}

	public getLibrary$(libraryId: number): Observable<ContentLibraries> {
		return this.httpClient.get<ContentLibraries>(`${environment.contentUrl}ContentLibraries/${libraryId}`);
	}

	public setProductProps(library: ContentLibraries): any {
		switch (library.ProductTypeId) {
			case 1:
				return {
					bgColor: 'blue-bg',
					gradientColor: 'blue-green-bg-gradient',
					productName: 'On Hold Library',
					icon: 'fas fa-phone-volume'
				};

			case 2:
				return {
					bgColor: 'purple-bg',
					gradientColor: 'purple-blue-bg-gradient',
					productName: 'Radio Library',
					icon: 'fas fa-volume-up'
				};

			case 3:
				return {
					bgColor: 'red-bg',
					gradientColor: 'red-yellow-bg-gradient',
					productName: 'Lobby Video Library',
					icon: 'fab fa-youtube'
				};

			case 8:
				return {
					bgColor: 'green-bg',
					gradientColor: 'green-limeGreen-bg-gradient',
					productName: `Other Products & Services Library`,
					icon: 'fas fa-book'
				};

			case 4:
				return {
					bgColor: 'red-bg',
					gradientColor: 'red-yellow-bg-gradient',
					productName: 'Poster Library',
					icon: 'far fa-square'
				};

			default:
				return {
					bgColor: 'red-bg',
					gradientColor: 'red-yellow-bg-gradient',
					productName: 'Lobby Video Library',
					icon: 'fab fa-youtube'
				};
		}
	}
}
