import { Component, ChangeDetectorRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';

import { ContentService, ContentViewService } from './content/_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { ViewportWidthService } from '../../../core/services';

@Component({
	selector: 'portal-content-container',
	templateUrl: './content-container.component.html',
	styleUrls: ['./content-container.component.scss']
})
export class ContentContainerComponent implements OnInit, OnDestroy {
	public scrollYReachEnd: boolean;
	public scrollYReachStart: boolean = true;

	private subs: Subscription[] = [];

	get showScrollForMore() {
		return (!this.scrollYReachEnd || this.scrollYReachStart) && this.directiveRef?.directiveRef.scrollable('y');
	}

	@ViewChild('directiveRef') directiveRef: PerfectScrollbarComponent;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		public contentService: ContentService,
		private contentViewService: ContentViewService,
		private dragulaService: DragulaService,
		public cvStateService: ContentViewStateService,
		public viewportWidthService: ViewportWidthService
	) {
		this.autoScrollContainerSubscribe();
		this.onCategoryClick();

		const bag: any = this.dragulaService.find('item-bag');
		if (bag !== undefined) {
			this.dragulaService.destroy('item-bag');
		}

		dragulaService.createGroup('item-bag', {
			moves: (el: Element, target: Element, event): boolean => {
				return event.classList.contains('draggable');
			}
		});
	}

	ngOnInit() {}

	autoScrollContainerSubscribe(): void {
		this.subs.push(
			this.contentViewService.autoScrollContainer$.subscribe(() => {
				if (this.directiveRef.directiveRef) {
					this.directiveRef.directiveRef.scrollToBottom(-150, 500);
				}
			})
		);
	}

	onCategoryClick(): void {
		this.subs.push(
			this.contentViewService.onCategoryClick$.subscribe(() => {
				if (this.directiveRef) {
					this.directiveRef.directiveRef.scrollToTop();
				}
			})
		);
	}

	onScrollYReachEnd(): void {
		this.scrollYReachEnd = true;
		this.changeDetectorRef.detectChanges();
	}

	onScrollYReachStart(): void {
		this.scrollYReachStart = true;
		this.changeDetectorRef.detectChanges();
	}

	onScrollUp(): void {
		this.scrollYReachEnd = false;
		this.changeDetectorRef.detectChanges();
	}

	onScrollDown(): void {
		this.scrollYReachStart = false;
		this.changeDetectorRef.detectChanges();
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
		this.dragulaService.destroy('item-bag');
	}
}
