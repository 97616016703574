import { Injectable } from '@angular/core';

import { ContentViewStateService } from 'src/app/shared/services';
import { PbWizardStep, WizardViewState } from '../_models';
import { AppStateService } from '../../core/services';
import { PlaylistService } from './playlist.service';
import { ProductRoute } from 'src/app/shared/api-models/admin/product-route.enum';

@Injectable({
	providedIn: 'root'
})
export class WizardService {
	public instructions: any = {};
	public viewState: WizardViewState;

	constructor(private appStateService: AppStateService, private cvStateService: ContentViewStateService, private playlistService: PlaylistService) {}

	/*************************
      NAV FORWARD AND BACK
     ************************/
	public advance(stepsToAdvance: number): void {
		this.cvStateService.viewState.contentListVisible = false;
		let index = this.setIsCurrentFalse();
		this.viewState.activeStepsArr[index + stepsToAdvance].isCurrentStep = true;
		this.setNavBarStepsTextColor('forward');
		this.setInstructions();
	}

	public back(stepsBack?: number): void {
		stepsBack = stepsBack ? stepsBack : 1;
		this.cvStateService.viewState.contentListVisible = false;
		let index = this.setIsCurrentFalse();
		this.viewState.activeStepsArr[index - stepsBack].isCurrentStep = true;
		this.setNavBarStepsTextColor('back');
		this.setInstructions();
	}

	private setNavBarStepsTextColor(navDirection: 'forward' | 'back'): void {
		let currentStepIndex: number = this.viewState.activeStepsArr.findIndex((step) => step.isCurrentStep);
		if (this.playlistService.playlist.isWritable) {
			//set current and all previous steps to dark if nav forward
			//set all but current step back to gray if nav back
			this.viewState.activeStepsArr
				.filter((step, i) => {
					if (navDirection === 'forward') {
						return i <= currentStepIndex;
					} else if (navDirection === 'back') {
						return i > currentStepIndex;
					}
				})
				.forEach((filteredStep) => {
					filteredStep.navBarTextColor = navDirection === 'forward' ? 'dark-text' : 'gray-text-darker';
					filteredStep.navBarProgressIconColor = navDirection === 'forward' ? 'dark-text' : 'gray-text-darker';
				});
		} else if (!this.playlistService.playlist.isWritable) {
			//set rocket text color to dark
			this.currentStep().navBarTextColor = 'dark-text';
			this.currentStep().navBarProgressIconColor = 'dark-text';

			//turn all previous steps gray (completed but now disabled)
			this.viewState.activeStepsArr
				.filter((step, i) => {
					return i < currentStepIndex;
				})
				.forEach((step) => {
					step.navBarTextColor = 'gray-text-darker';
					step.navBarProgressIconColor = 'gray-text-darker';
					step.isCompleted = true;
				});
		}
	}

	public currentStep(): PbWizardStep {
		return this.viewState?.activeStepsArr?.find((step) => step.isCurrentStep);
	}

	public setIsCurrentFalse(): number {
		let index = this.viewState.activeStepsArr.findIndex((step) => step.isCurrentStep);
		this.viewState.activeStepsArr[index].isCurrentStep = false;
		return index;
	}

	//Show scheduled players list only if playlist is one of these status'
	public scheduledPlayersVisible(): boolean {
		return this.playlistService.playlist.status === 'Active' || this.playlistService.playlist.status === 'Scheduled';
	}

	public setInstructions(): void {
		if (this.appStateService.product.Route === ProductRoute.HOLD && this.currentStep().name === 'choose-content') {
			this.instructions.title = 'Build your playlist!';
			this.instructions.libraryText = this.libraryText();
			this.instructions.addContentText = 'Add a message to your playlist by selecting the "Add" button.';
			this.instructions.icon = 'fas fa-phone-volume';
		} else if (this.appStateService.product.Route === ProductRoute.HOLD && this.currentStep().name === 'choose-music') {
			this.instructions.title = 'Pick your music!';
			this.instructions.libraryText = 'Select a category from the list of options.';
			this.instructions.addContentText = 'Add music to your playlist by selecting the "Add" button.';
			this.instructions.icon = 'fas fa-phone-volume';
		} else {
			this.instructions.title = 'Build your playlist!';
			this.instructions.libraryText = this.libraryText();
			this.instructions.addContentText = 'Add a clip to your playlist by selecting the "Add clip" button.';
			this.instructions.icon = 'fas fa-tv';
		}
	}

	private libraryText(): string {
		let selectedProduct = this.appStateService.product.Route;
		switch (selectedProduct) {
			case ProductRoute.HOLD:
				if (!this.appStateService.clientHasAppleTvs) {
					return (
						`<span class="semi-bold">Instant Playlist:</span><br><span>Click the Instant Playlist button to browse hand-picked messages and music that you can load in one click. <a href='https://help.works24.com/knowledge-base/use-instant-playlist-on-hold/' target="_blank" class="regular blue-text">Watch how it works.<i class='fas fa-play-circle mgn-left5 blue-text'></i></a></span><br><br>` +
						`<span class="semi-bold">From scratch:</span><br><span>Click a library to browse and select individual messages.</span>`
					);
				}
				return `Click a library to browse and select individual messages.`;
			default:
				if (!this.appStateService.clientHasAppleTvs && this.appStateService.product.Route === ProductRoute.VIDEO) {
					return (
						`<span class="semi-bold">Instant Playlist:</span><br><span>Click the Instant Playlist button to browse hand-picked collections that you can load in one click. <a href='https://help.works24.com/knowledge-base/use-instant-playlist-for-video/' target="_blank" class="regular red-text underline">Watch how it works.</a><i class='fas fa-play-circle mgn-left5 red-text'></i></span><br><br>` +
						`<span class="semi-bold">From scratch:</span><br><span>Click a library to browse and select individual clips.</span>`
					);
				}
				return `Click a library to browse and select individual clips.`;
		}
	}
}
