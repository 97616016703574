import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppStateService, DateTimeService, Events, MessageService } from '../../../core/services';
import { CreateEditRequestService, CreateEditViewStateService, EmailService, HeightService } from '../_services';
import { Comment } from '../_models';
import { CommentsService } from './comments.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'cer-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

    public newComment: Comment;

    @ViewChild('commentBox') commentBox: ElementRef;

    constructor(
        private appStateService: AppStateService,
        public createEditRequestService: CreateEditRequestService,
        private commentsService: CommentsService,
        public dateTimeService: DateTimeService,
        private emailService: EmailService,
        public heightService: HeightService,
        private httpClient: HttpClient,
        private messageService: MessageService,
        public viewStateService: CreateEditViewStateService
    ) { }

    ngOnInit() {
        this.createEditRequestService.request.comments = [];
        if (this.viewStateService.mode === 'edit') {
            this.commentsService.getComments();
        }
    }

    public onAddCommentClick(): void {
        this.newComment = new Comment(this.createEditRequestService.request.Id, this.appStateService.currentUser.UserId);
        setTimeout(() => this.commentBox.nativeElement.select(), 10);
        this.createEditRequestService.request.comments.unshift(this.newComment);
    }

    public onPostCommentClick(): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.newComment.CreateDateTime = this.dateTimeService.todayWithCurrentTime();
        this.httpClient.post(`${environment.adminUrl}ContentRequestComments`, this.newComment)
            .subscribe(() => {
                this.emailService.sendNewCommentEmail()
                this.commentsService.getComments();
                this.messageService.publish(Events.savingPreloader, 0);
            })
    }

    public onXClick(): void {
        this.createEditRequestService.request.comments.shift();
    }
}
