import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Quotes } from './quotes';

@Component({
  selector: 'core-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  public quotes = Quotes;
  public randomQuote: any;

  constructor(private router: Router) { }

  ngOnInit() {
  	if(sessionStorage.getItem("authToken") === 'null' ||
  		sessionStorage.getItem("authToken") === null ||
  		sessionStorage.getItem("authToken") === undefined) {
  		this.router.navigate(['/login']);
  	}
    const index = Math.floor(Math.random() * this.quotes.length);

    this.randomQuote = this.quotes[index];
  }

}

