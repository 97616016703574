import { Injectable } from '@angular/core';

type ContractTerm = {Id: number, name: string, months: number};

@Injectable({
  providedIn: 'root'
})
export class ContractTermsService {

	public contractTerms: ContractTerm[] = [
			{
	            Id: null,
	            name: 'Select a term...',
	            months: 0
	        },
	        {
	            Id: 1,
	            name: '1 yr',
	            months: 12
	        },
	        {
	            Id: 2,
	            name: '2 yr',
	            months: 24
	        },
	        {
	            Id: 3,
	            name: '3 yr',
	            months: 36
	        },
	        {
	            Id: 4,
	            name: '4 yr',
	            months: 48
	        },
	        {
	            Id: 5,
	            name: '5 yr',
	            months: 60
	        },
	        {
	            Id: 6,
	            name: 'Monthly',
	            months: 0
	        },
	        {
	            Id: 7,
	            name: 'Comp/Free',
	            months: 0
	        },
	        {
	            Id: 8,
	            name: 'Trial Period',
	            months: 1
	        },
	        {
	            Id: 9,
	            name: 'Other - See Notes',
	            months: 0
	        }
	]
}