import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sp-playlist-details-wrapper',
  templateUrl: './playlist-details-wrapper.component.html',
  styleUrls: ['./playlist-details-wrapper.component.scss']
})
export class PlaylistDetailsWrapperComponent implements OnInit {
 

  constructor() { }

  ngOnInit() {
  	
  }

}
