<portal-header *ngIf="viewStateService.state.route === 'my-tasks'"></portal-header>

<div *ngIf="viewStateService.state.route === 'my-tasks'" class="container-fluid mgn-top30">
	<!--********************
		PAGE TITLE
	*********************-->
	<div class="mgn-bottom30">
		<h1>
			<i class="fas fa-tasks mgn-right5" aria-hidden="true"></i>
			<span class="semi-bold">CSR Tasks</span>
			|
			<em>
				{{ apiUrlsService.apiUrls()[csrTasksService.activeUrlIndex()].friendlyDesc }}
				<span *ngIf="csrTasksService.activeUrlHasCompletedOnlyParam()">, {{ viewStateService.state.completedOnly ? 'Completed' : 'Uncompleted' }}</span>
			</em>
		</h1>
	</div>
	<div class="row mgn-right0 mgn-left0 shadow white-bg border-radius pd-top15 pd-bottom15 mgn-bottom15">
		<!--********************
			NEW TASK BUTTON
		*********************-->
		<div class="col-sm-2">
			<button (click)="onNewTaskClick()" class="limeGreen-bg border-radius white-text setWidth100">
				<i class="fa fa-plus mgn-right12" aria-hidden="true"></i>
				New Task
			</button>
		</div>

		<!--********************
			DATE RANGE
		*********************-->
		<div class="{{ viewStateService.state.dateRange ? 'col-sm-10' : 'col-sm-8' }} ft-size16 pd-top8">
			<div class="in-block mgn-right20">
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="viewStateService.state.dateRange"
					[type]="'checkbox'"
					(checkboxClickNotify)="onCheckboxClick('dateRange')"
				></portal-checkbox>
				<label (click)="onCheckboxClick('dateRange')" for="csrs" class="pointer topneg5 relative mgn-left2">Date Range</label>
			</div>

			<!--************************
				DATE RANGE TO/FROM
			*************************-->
			<div *ngIf="viewStateService.state.dateRange" style="width: 40%" class="relative in-block ht24 topneg7 right15">
				<div class="row">
					<div class="col-sm-2 text-right">
						<label class="relative top9">From:</label>
					</div>
					<div class="col-sm-4 pd-left0">
						<portal-date-time-selector
							[backgroundColor]="'white-bg'"
							[height]="'ht40'"
							(dateChanged)="viewStateService.state.dateRangeStart = $event"
						></portal-date-time-selector>
					</div>
					<div class="col-sm-1 text-right">
						<label class="relative top9">To:</label>
					</div>
					<div class="col-sm-4 pd-left0">
						<portal-date-time-selector
							[backgroundColor]="'white-bg'"
							[height]="'ht40'"
							(dateChanged)="viewStateService.state.dateRangeEnd = $event"
						></portal-date-time-selector>
					</div>
					<div class="col-sm-1 pd-left0 pd-right0">
						<button (click)="onDateRangeGoClick()" class="ft-size14 limeGreen-bg white-text relative right5 top4">Go</button>
					</div>
				</div>
			</div>

			<!--***************
				PAST
			****************-->
			<div *ngIf="!viewStateService.state.dateRange" class="in-block mgn-right20">
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="viewStateService.state.showPast"
					[type]="'checkbox'"
					(checkboxClickNotify)="onCheckboxClick('showPast')"
				></portal-checkbox>
				<label (click)="onCheckboxClick('showPast')" for="csrs" class="pointer topneg5 relative mgn-left2">Past</label>
			</div>

			<!--********************
				TODAY AND FUTURE
			*********************-->
			<div *ngIf="!viewStateService.state.dateRange" class="in-block mgn-right20">
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="viewStateService.state.showTodayAndFuture"
					[type]="'checkbox'"
					(checkboxClickNotify)="onCheckboxClick('showTodayAndFuture')"
				></portal-checkbox>
				<label (click)="onCheckboxClick('showTodayAndFuture')" for="csrs" class="pointer topneg5 relative mgn-left2">Future (includes Today)</label>
			</div>

			<!--********************
				COMPLETED
			*********************-->
			<div class="in-block mgn-right20">
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="viewStateService.state.completedOnly"
					[type]="'checkbox'"
					(checkboxClickNotify)="onCheckboxClick('completedOnly')"
				></portal-checkbox>
				<label (click)="onCheckboxClick('completedOnly')" for="csrs" class="pointer topneg5 relative mgn-left2">Completed</label>
			</div>

			<!--********************
				ALL CSRS
			*********************-->
			<div class="in-block mgn-right20">
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="viewStateService.state.allCsrs"
					[type]="'checkbox'"
					(checkboxClickNotify)="onCheckboxClick('allCsrs')"
				></portal-checkbox>
				<label (click)="onCheckboxClick('allCsrs')" for="csrs" class="pointer topneg5 relative mgn-left2">All CSRs</label>
			</div>
		</div>

		<!--********************
			SEARCH
		*********************-->
		<div *ngIf="!viewStateService.state.dateRange" class="col-sm-2 relative">
			<span
				class="pointer absolute leftneg5"
				ngxTippy
				tippyName="{{ utilService.guid | wrapFn }}"
				[tippyProps]="{ appendTo: 'parent' }"
				data-tippy-content="Search respects the checkbox filters."
			>
				<i class="far fa-question-circle" aria-hidden="true"></i>
			</span>
			<input placeholder="Search" type="text" (keyup)="onSearchKeyup()" [(ngModel)]="viewStateService.state.searchTerm" />
			<button
				*ngIf="viewStateService.state.searchTerm.length > 0"
				style="width: initial"
				class="absolute top8 white-bg pd12 right22 z"
				(click)="onSearchClear()"
			>
				<i class="fa fa-times absolute center-unknown-height-width" aria-hidden="true"></i>
			</button>
		</div>
	</div>
</div>

<!--*******************************
	CLIENT-SPECIFIC VIEW (CRM)
********************************-->
<div *ngIf="viewStateService.state.route === 'by-client'" class="row mgn-bottom15 mgn-left15 mgn-right15">
	<div class="col-sm-2 pd-left0">
		<h2 class="semi-bold">
			<i class="fas fa-tasks green-text mgn-right12" aria-hidden="true"></i>
			CSR Tasks
		</h2>
	</div>
	<div class="col-sm-2 col-sm-offset-8 pd-right0 relative">
		<input type="text" placeholder="Search" (keyup)="onSearchKeyup()" [(ngModel)]="viewStateService.state.searchTerm" />
		<button
			*ngIf="viewStateService.state.searchTerm.length > 0"
			style="width: initial"
			class="absolute top8 white-bg pd12 right8 z border-radius"
			(click)="onSearchClear()"
		>
			<i class="fa fa-times absolute center-unknown-height-width" aria-hidden="true"></i>
		</button>
	</div>
</div>

<!--********************
	TASKS TABLE
********************-->
<div class="mgn-left15 mgn-right15 relative">
	<div *ngIf="spinnerActive" style="left: 47%" class="absolute z top172">
		<portal-spinner [width]="'wdthpx76'" [height]="'htpx76'"></portal-spinner>
	</div>
	<portal-table
		[table]="tableConfigService.table"
		[config]="tableConfigService.config"
		[data]="byClientRouteAndSearchActive() ? byClientSearchResults : tasks"
		[showJumpToPage]="true"
		[page]="viewStateService.state.pageNumber"
		[totalItems]="csrTasksService.totalItems"
		(buttonClickNotify)="onTableButtonClick($event)"
		(currentPageNotify)="getPage($event)"
		(linkClickNotify)="onClientNameClick($event)"
	></portal-table>
</div>
