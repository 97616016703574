<div
	(window:click)="onWindowClick()"
	[ngClass]="content.IsDeleted ? 'gray-bg-dark' : 'white-bg'"
	class="row border-radius {{ !isLastIndex ? 'border-first-index' : 'border-last-index border-first-index mgn-bottom1' }}  pd-top15 pd-bottom15"
>
	<div class="col-md-10">
		<div class="row {{ securityService.writeAccessEnabled ? 'draggable' : null }}" (mousedown)="contentViewService.onContentDrag(content)">
			<!--***************************
				EDIT BUTTON
			****************************-->
			<div class="col-md-2 ft-size16">
				<button
					(click)="contentViewService.onSaveContentDetailsClick(content)"
					class="z {{ content.detailsVisible ? 'limeGreen-bg white-text' : 'gray-bg' }} {{ !contentViewService.saveEnabled(content) ? 'setOpacityLow' : '' }}"
					[disabled]="content.detailsVisible && !contentViewService.saveEnabled(content)"
				>
					{{ content.detailsVisible ? 'Save' : 'Edit' }}
				</button>
			</div>

			<!--***************************
				PLAY BUTTON
			****************************-->
			<div class="col-md-2 ft-size16">
				<button
					*ngIf="content.contentFiles?.length > 0"
					(click)="contentViewService.onPlayClick(content, isLastIndex, 'talentPreviewSelectorVisible', contentList)"
					class="noExit {{ content.contentFiles[0].previewActive ? 'yellow-bg dark-text' : 'blue-bg white-text' }}"
				>
					<i class="{{ content.contentFiles[0].previewActive ? 'fas fa-times' : 'fas fa-play' }} mgn-right10 noExit" aria-hidden="true"></i>
					{{ content.contentFiles[0].previewActive ? 'Stop' : 'Play' }}
				</button>

				<div *ngIf="content.talentPreviewSelectorVisible" style="left: 96%; z-index: 100" class="absolute top0 mgn-bottom30">
					<portal-select-box
						[content]="content"
						[title]="'Select a Preview Version'"
						[width]="'wdth242'"
						(onSelectNotify)="contentViewService.onSelectVoiceTalentClick($event, content, 'talentPreviewSelectorVisible')"
					></portal-select-box>
				</div>
			</div>

			<!--***************************
				CONTENT TITLE
			****************************-->
			<div class="col-md-6 ft-size16 relative {{ securityService.writeAccessEnabled ? 'draggable' : null }}">
				<i
					*ngIf="!!contentViewService.featuredContentProps(content)"
					class="{{ contentViewService.featuredContentProps(content).icon }} {{ contentViewService.featuredContentProps(content).color }} mgn-right10"
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					[tippyProps]="{ appendTo: 'parent' }"
					attr.data-tippy-content="{{ contentViewService.featuredContentProps(content).tooltip }}"
				></i>
				<span class="{{ securityService.writeAccessEnabled ? 'draggable' : null }}">{{ content.Title }}</span>
			</div>
			<div class="col-md-2">
				<!--*******************
					CREATE DATE
				********************-->
				<em *ngIf="contentViewService.showCreateDate(content)" class="ft-size12">
					<i class="fa fa-calendar-plus-o mgn-right5" aria-hidden="true"></i>
					{{ content.CreateDate | date : 'mediumDate' }}
				</em>

				<!--*******************
					FILE NAME(S)
				********************-->
				<div *ngIf="appStateService.currentUser.IsEmployee">
					<h4
						*ngIf="content.contentFiles?.length === 1"
						(click)="onFilenameClick($event)"
						(mouseover)="fileNameTooltipVisible = true"
						(mouseleave)="onMouseleave()"
						class="ft-size11 ellipsis"
					>
						{{ content.contentFiles[0].MasterFileName }}
					</h4>
					<h4
						*ngIf="content.contentFiles?.length > 1"
						(click)="onFilenameClick($event)"
						(mouseover)="fileNameTooltipVisible = true"
						(mouseleave)="onMouseleave()"
						class="ft-size11 ellipsis"
					>
						File names
					</h4>
				</div>

				<!--****************************
					UPLOAD PROCESSING STATE
				******************************-->
				<em class="ft-size12 {{ content.processingStateJson === 'Ready' ? 'green-text' : null }} ">{{ content.processingStateJson }}</em>

				<!--***********************
					FILE NAME TOOLTIP
				************************-->
				<div
					*ngIf="fileNameTooltipVisible"
					(click)="$event.stopPropagation()"
					style="opacity: 0.8; z-index: 200"
					class="{{ isLastIndex ? 'top0 right140' : 'top42 right0' }} tooltip dark-bg white-text border-radius absolute pd2 ft-size11"
				>
					<h4 *ngIf="content.contentFiles?.length === 1" class="ft-size12 pd5">{{ content.contentFiles[0].MasterFileName }}</h4>
					<div *ngIf="content.contentFiles?.length > 1" class="pd5">
						<h4 *ngFor="let contentFile of content.contentFiles; let last = last" class="ft-size12 {{ !last ? 'mgn-bottom5' : null }}">
							{{ contentFile.MasterFileName }}
						</h4>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-2">
		<div class="row">
			<!--***************************
				DELETE BUTTON
			****************************-->
			<div class="col-md-6">
				<button
					(click)="contentViewService.onDeleteClick(content)"
					[ngClass]="{ 'red-bg-light white-text': !content.IsDeleted, 'gray-bg-darker': content.IsDeleted }"
					class="relative"
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					[tippyProps]="{ appendTo: 'parent' }"
					attr.data-tippy-content="{{ content.IsDeleted ? 'Restore' : 'Delete' }}"
				>
					<i [ngClass]="content.IsDeleted ? 'fas fa-redo-alt' : 'far fa-trash-alt'" class="ft-size18" aria-hidden="true"></i>
				</button>
			</div>

			<!--***************************
				SELECT CHECKBOX
			****************************-->
			<div class="col-md-6 text-right">
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="content.isSelected"
					[isDisabled]="content.IsDeleted"
					[type]="'checkbox'"
					(checkboxClickNotify)="batchChangeCategoryService.onCheckboxSelect(content)"
				></portal-checkbox>
			</div>
		</div>
	</div>

	<!--**********************************
		TITLE/SCRIPT/CONTENT FILES
	***********************************-->
	<div *ngIf="content.detailsVisible" class="col-lg-12">
		<c-details [content]="content"></c-details>
	</div>
</div>
