import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Events, MessageService } from '../../../../core/services';
import { CreateEditRequestService, CreateEditViewStateService, EmailService, HistoryNoteService } from '../../_services';
import { DetailsService } from './details.service';
import { environment } from '../../../../../environments/environment';
import { ClientNameSearch, ContentRequestAttachments, ContentRequestStateName, ContentRequestType, Users } from '../../../../shared/api-models/admin';

@Injectable({
	providedIn: 'root'
})
export class DetailsViewService {
	private clientSearchChanged: Subject<string> = new Subject<string>();
	private formChanged: Subject<string> = new Subject<string>();
	public key: string;
	public sendCompleteEmailUserSelected: boolean;

	constructor(
		private createEditRequestService: CreateEditRequestService,
		private detailsService: DetailsService,
		private emailService: EmailService,
		private historyNoteService: HistoryNoteService,
		private messageService: MessageService,
		private httpClient: HttpClient,
		private viewStateService: CreateEditViewStateService
	) {
		this.formChanged.pipe(debounceTime(500)).subscribe(() => {
			this.saveRequest();
		});

		this.clientSearchChanged.pipe(debounceTime(400)).subscribe((searchTerm: string) => {
			this.httpClient.get(`${environment.adminUrl}CoreClients/NameSearch/${searchTerm}`).subscribe((clientList: ClientNameSearch[]) => {
				this.detailsService.clientList = clientList;
			});
		});
	}

	public onAttachmentDeleteClick(attachment: ContentRequestAttachments): void {
		const confirmDelete: boolean = confirm(`Delete attachment "${attachment.FriendlyName}"? This action cannot be undone.`);
		if (confirmDelete) {
			this.messageService.publish(Events.savingPreloader, 1);
			this.httpClient.delete(`${environment.adminUrl}ContentRequestAttachments/${attachment.Id}`).subscribe(() => {
				this.messageService.publish(Events.savingPreloader, 0);
				this.createEditRequestService.request.attachments = this.createEditRequestService.request.attachments.filter((a) => a.Id !== attachment.Id);
			});
		}
	}

	public onClientSearchKeyup(searchTerm: string): void {
		this.clientSearchChanged.next(searchTerm);
	}

	public onDownloadAttachmentClick(attachment: ContentRequestAttachments): void {
		this.detailsService.attachmentDownloadUrl = `${environment.adminUrl}ContentRequestAttachments/${attachment.Id}/File`;
	}

	public onBypassScriptWriterClick(): void {
		this.createEditRequestService.request.bypassScriptWriter = !this.createEditRequestService.request.bypassScriptWriter;
	}

	public onDateChanged(value: string, key: string): void {
		this.createEditRequestService.request[key] = value;
		this.key = key;
		this.saveRequest();
	}

	public onDropdownSelect(value: any, key: string, saveProp: string): void {
		if (key === 'VoiceTalent') {
			switch (value.name) {
				case 'All Male':
					this.createEditRequestService.request.voiceTalentView = 'All Male';
					this.createEditRequestService.request.VoiceTalent = 'Unknown Male';
					break;

				case 'All Female':
					this.createEditRequestService.request.voiceTalentView = 'All Female';
					this.createEditRequestService.request.VoiceTalent = 'Unknown Female';
					break;
				default:
					this.createEditRequestService.request.voiceTalentView = value.name;
					this.createEditRequestService.request.VoiceTalent = value.name;
			}
		} else {
			this.createEditRequestService.request[key] = value[saveProp];
		}

		if (this.viewStateService.mode === 'edit') {
			this.key = key;
			this.saveRequest();
		}
		if (key === 'ClientId') {
			this.createEditRequestService.request.CsrId = value.CsrId;
			this.detailsService.getLibraries().subscribe();
			this.detailsService.usersByClient$ = this.detailsService.getUsersByClient();
			this.createEditRequestService.csr$(this.createEditRequestService.request).subscribe((csr: Users) => {
				this.createEditRequestService.request.CsrId = csr.Id;
				this.createEditRequestService.request.csrUsername = csr.UserName;
				this.createEditRequestService.request.csrEmail = csr.ContactInformation.Email;
				this.createEditRequestService.request.csrFriendlyName = `${csr.ContactInformation.FirstName} ${csr.ContactInformation.LastName}`;
			});
		}
	}

	public onSendCompleteEmailDropdownSelect(user: Users): void {
		const { Email, FirstName } = user.ContactInformation;
		this.sendCompleteEmailUserSelected = true;
		this.createEditRequestService.request.requestedByEmail = Email;
		this.createEditRequestService.request.requestedByFriendlyName = FirstName;
	}

	public onKeyup(key: string): void {
		if (this.viewStateService.mode === 'edit') {
			this.key = key;
			this.formChanged.next();
		}
	}

	public onRequestClientApprovalClick(): void {
		const confirmOk: boolean = confirm('Send client approval email?');
		if (confirmOk) {
			this.createEditRequestService.request.StateName = ContentRequestStateName.PENDING_SCRIPT_APPROVAL;
			this.key = 'StateName';
			this.emailService.sendEmail(this.emailService.requestClientApprovalEmail()).subscribe();
			this.saveRequest();
		}
	}

	public onSendCompleteEmailClick(): void {
		const confirmOk: boolean = confirm(`Send messages complete email to ${this.createEditRequestService.request.requestedByEmail}?`);
		if (confirmOk) {
			this.emailService.sendEmail(this.emailService.messagesCompleteEmail()).subscribe();
			this.historyNoteService.postCompleteEmailHistoryNote().subscribe();
		}
	}

	//saves in real time as user edits
	public saveRequest(): void {
		this.messageService.publish(Events.savingPreloader, 1);
		const request = this.createEditRequestService.request;

		this.httpClient.patch(`${environment.adminUrl}ContentRequests/${request.Id}`, { [this.key]: request[this.key] }).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}
}
