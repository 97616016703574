import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { AppStateService } from 'src/app/core/services';
import { ClipsView } from 'src/app/content-requests/create-edit-request/_models';

@Component({
    selector: 'app-script-read-view',
    templateUrl: './script-read-view.component.html',
    styleUrls: ['./script-read-view.component.scss']
})
export class ScriptReadViewComponent implements OnInit {

    public clipForReaderView: ClipsView;

    constructor(
        private appStateService: AppStateService,
        public location: Location
    ) { }

    ngOnInit(): void {
        if(this.appStateService.getSessionItem('clipForReaderView')) {
            this.clipForReaderView = JSON.parse(this.appStateService.getSessionItem('clipForReaderView'));
        }
    }

}
