<div class="pd20 border-radius white-bg shadow-wide min-height262 relative z-index slideUp">
	<div class="row">
		<div class="col-sm-12 text-center">
			<h1 class="in-block semi-bold red-text-light"><i class="fas fa-exclamation-triangle mgn-right10"></i>{{appErrorService.error.status}}</h1>
			<h2 class="red-text-light"><em>{{appErrorService.friendlyMessage}}</em></h2>				
		</div>
    </div>
    <div class="row mgn-top60 text-center">
        <div class="col-xs-12">
            <p class="ft-size16 red-text-light">Please explain briefly what you were doing that triggered the error and click "Send report to CSR". This will enable us to fix the problem in a timely manner. Thanks!</p>
        </div>
    </div>

    <div class="row mgn-top30">
        <div class="col-xs-12">
            <textarea [(ngModel)]="userText" rows="5" placeholder="Enter your message here..."></textarea>
        </div>
    </div>

    <div class="row mgn-top30">
        <div class="col-xs-12">
            <span class="ft-size16"><span class="semi-bold">CSR:</span> {{appStateService.csrByClient.FirstName}} {{appStateService.csrByClient.LastName}} <em> ({{appStateService.csrByClient.Email}})</em></span>
        </div>
    </div>

    <div class="row mgn-top15">
        <div class="col-xs-12">
            <span class="semi-bold ft-size16">Phone: </span> <em class="ft-size16">1-800-460-4653</em>
        </div>
    </div>
	
	<div class="row mgn-top60">
        <div class="col-sm-6">
            <button (click)="onMyProductsClick()" class="gray-bg">My Products</button>
        </div>
		<div *ngIf="!appErrorService.hideReloadPageBtn" class="col-sm-6">
			<button (click)="onIgnoreClick()" class="gray-bg">Reload page</button>
		</div>	
        <div *ngIf="appErrorService.hideReloadPageBtn" class="col-sm-6">
			<button (click)="onSendReportClick()" class="red-bg-light white-text">Send report to CSR</button>
		</div>	
	</div>

    <div *ngIf="!appErrorService.hideReloadPageBtn" class="row mgn-top45">
        <div class="col-xs-12">
			<button (click)="onSendReportClick()" class="red-bg-light white-text">Send report to CSR</button>
		</div>
    </div>
</div>