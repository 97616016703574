import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { FileUploadService, UtilitiesService } from '../../../../../core/services';
import { ClipsView, ContentFilesViewHold } from '../../../_models';
import { CreateEditRequestService, CreateEditViewStateService } from '../../../_services';
import { ContentFileService } from './content-file.service';
import { FileUploadStateService } from 'src/app/shared/services';

@Component({
	selector: 'hv-content-file',
	templateUrl: './content-file.component.html',
	styleUrls: ['./content-file.component.scss']
})
export class ContentFileComponent implements OnInit, OnDestroy {
	@Input() clip: ClipsView;
	@Input() clipIndex: number;
	@Input() contentFile: ContentFilesViewHold;
	@Input() index: number;

	private subs: Subscription[] = [];

	constructor(
		private contentFileService: ContentFileService,
		private createEditRequestService: CreateEditRequestService,
		private fileUploadService: FileUploadService,
		public fileUploadStateService: FileUploadStateService,
		public utilService: UtilitiesService,
		public viewStateService: CreateEditViewStateService
	) {}

	ngOnInit() {}

	public onRevisionClick(): void {
		this.contentFileService.updateFileOnRevisionClick(this.contentFile, this.clip);
	}

	public onDeleteClick(): void {
		this.contentFileService.onDelete(this.contentFile, this.clip);
	}

	public onReplaceInputClick(): void {
		this.clip.completedFileUploadInProgress = true;
		this.fileUploadStateService.replacementProps = {
			contentFileId: this.contentFile.Id,
			targetLibraryId: this.createEditRequestService.request.TargetLibraryId
		};
		this.fileUploadService.onReplaceInputClick(this.clip.Id);
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
