import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ClipService } from './_services';
import { ClipsView, ContentFilesView } from '../../_models';
import { ContentRequestClipAttachments } from '../../../../shared/api-models/admin';
import { CreateEditRequestService } from '../../_services';
import { environment } from '../../../../../environments/environment';
import { Events, FileUploadService, MessageService, UtilitiesService } from 'src/app/core/services';
import { FileUploadStateService } from 'src/app/shared/services';

@Component({
	selector: 'c-clip',
	templateUrl: './clip.component.html',
	styleUrls: ['./clip.component.scss'],
	providers: [ClipService]
})
export class ClipComponent implements OnInit, OnDestroy {
	@Input() clip: ClipsView;
	@Input() index: number;

	private subs: Subscription[] = [];

	constructor(
		private clipService: ClipService,
		public createEditRequestService: CreateEditRequestService,
		private fileUploadService: FileUploadService,
		private fileUploadStateService: FileUploadStateService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		public utilService: UtilitiesService
	) {
		this.fileUploadAdvanceSequenceSubscribe();
		this.uploadProgressSubscribe();
		this.uploadCompleteSubscribe();
		this.contentFileStateUpdateSubscribe();
	}

	ngOnInit() {
		if (this.clip.CompletedContentId) {
			this.clip.contentFiles = [];
			this.clipService.getCompletedContentFiles(this.clip).subscribe();
		}
	}

	fileUploadAdvanceSequenceSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.fileUploadAdvanceSequence, (itemId: number) => {
				if (this.fileUploadStateService.currentStep().name === 'progressContentRequestClipAttachment' && this.clip.Id === itemId) {
					const uploadData: { Id: number; FriendlyName: string } = new ContentRequestClipAttachments(this.clip.Id, this.fileUploadStateService.file.name);
					this.fileUploadService.uploadAttachmentFile(uploadData, `${environment.adminUrl}ContentRequestClipAttachments`, 'ContentRequestClipId');
				}
			})
		);
	}

	uploadProgressSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.uploadProgress, (payload: any[]) => {
				const [event, originalRequest, key] = [...payload];
				const requestClipId: number = +this.utilService.getLastParam(originalRequest.url).slice(1);

				//Check if this is a completed file or attachment upload
				if (requestClipId === this.clip.Id && key === 'ContentRequestCompletedFile') {
					this.clipService.percentCompleteCompletedFile = this.percentComplete(event);
				} else if (requestClipId === this.clip.Id && key === 'ContentRequestClipId') {
					this.clipService.percentCompleteAttachment = this.percentComplete(event);
				}
			})
		);
	}

	private percentComplete(event): number {
		return Math.round((100 * event.loaded) / event.total);
	}

	uploadCompleteSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.uploadComplete, (event) => {
				const clipId = +this.utilService.getLastParam(event.url).slice(1);

				if (this.isAttachment(event)) {
					this.getUploadedAttachment(event);
				} else if (this.isCompletedFile(event, clipId)) {
					this.clip.CompletedContentId = event.body.ContentId;
					this.createEditRequestService.updateClipAndGetContentFile(this.clip);

					//For Video requests only
					if (this.createEditRequestService.request.RequestType !== 'On-Hold Message') {
						this.clip.IsApproved = false;

						//API defaults new content to IsApproved true, needs to be flipped here to false
						this.createEditRequestService.updateContentToIsApproved(this.clip).subscribe();
					}

					//Clients table needs to be updated for video logo uploads, except for the first index (Create24 Logo)
					if (this.createEditRequestService.request.RequestType === 'Video-Logo' && this.index !== 0) {
						this.messageService.publish(Events.savingPreloader, 1);
						this.clipService.updateCoreClients(this.index, this.clip as ClipsView);
					}
				}
			})
		);
	}

	//Listen as newly uploaded completed content file is processing
	contentFileStateUpdateSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.contentFileStateUpdate, (contentFile: ContentFilesView) => {
				if (contentFile.ProcessingStateJson && this.clip.CompletedContentId === contentFile.ContentId) {
					this.clip.processingState = JSON.parse(contentFile.ProcessingStateJson).Message;

					if (contentFile.ContentFileStateId === 4) {
						//ready
						this.clip.processingState = null;
						this.clipService.getCompletedContentFiles(this.clip).subscribe();
						this.clip.completedFileUploadInProgress = false;
						this.clip.completedFileError = false;
						this.createEditRequestService.setUpdateDate$(contentFile.Id).subscribe();
					} else if (contentFile.ContentFileStateId === 5) {
						this.clip.completedFileError = true;
						this.clip.completedFileUploadInProgress = false;
					}
				}
			})
		);
	}

	private getUploadedAttachment(event: any): void {
		//get uploaded file and attach to view
		this.httpClient
			.get(`${environment.adminUrl}ContentRequestClipAttachments/${event.body.Id}`)
			.subscribe((contentRequestClipAttachment: ContentRequestClipAttachments) => {
				this.clip.attachments.push(contentRequestClipAttachment);
				this.clipService.percentCompleteAttachment = 0;
			});
	}

	private isAttachment(event): boolean {
		return event.body.ContentRequestClipId && event.body.ContentRequestClipId === this.clip.Id;
	}

	private isCompletedFile(event, clipId: number): boolean {
		return event.body.ContentId && clipId === this.clip.Id;
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
		//Prevent upload modal from still being active when navigating away
		this.fileUploadStateService.activeSequence = [];
	}
}
