import { Injectable } from '@angular/core';

import { Column, Table, TableConfig } from '../../../shared/components/table/view-models';

@Injectable()
export class TableConfigService {
	public columns: Column[] = [
		new Column('forceToTopBtn', 'fas fa-arrow-up', 'button').setElementClassList('blue-bg ht32 white-text wdth26').setShowIconOnly(true),
		new Column('resetBtn', 'fas fa-undo-alt', 'button').setElementClassList('blue-bg ht32 white-text wdth26').setShowIconOnly(true),
		new Column('DeliverOn', 'Scheduled', 'date').setIsSortable(true),
		new Column('clientName', 'Client', 'string').setIsSortable(true),
		new Column('Csr', 'Csr', 'string').setIsSortable(true),
		new Column('locationName', 'Location', 'string').setIsSortable(true),
		new Column('PlaylistName', 'Playlist Name', 'string').setIsSortable(true),
		new Column('playerModelType', 'Type', 'string').setIsSortable(true),
		new Column('playerModel', 'Model', 'string').setIsSortable(true),
		new Column('phoneMac', 'Phone/MAC', 'string').setIsSortable(true),
		new Column('status', 'Status', 'string').setIsSortable(true).setElementClassList('wdth20'),
		new Column('deleteBtn', 'far fa-trash-alt', 'button').setElementClassList('ht32 white-bg red-text-light wdth26').setShowIconOnly(true)
	];

	public table: Table = new Table(this.columns);

	public configuration: TableConfig = {
		itemsPerPage: 15,
		headerColor: 'white-bg',
		nonServerPagination: true
	};
}
