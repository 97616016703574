<portal-header></portal-header>

<div class="container-fluid mx-4 mgn-top30 relative">
	<a (click)="location.back()" class="dark-text absolute topneg21 left0">
		<i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
		<span class="ft-size16">Back</span>
	</a>
	<div class="ft-size16">
		<div class="row relative">
			<h1 class="semi-bold mgn-top10">
				<i class="fas fa-tasks mgn-right12" aria-hidden="true"></i>
				{{ editMode === 'edit' ? 'Edit' : 'New' }} CSR Task
			</h1>
		</div>
		<div class="row green-limeGreen-bg-gradient ht2 mgn-top15 border-radius"></div>

		<form *ngIf="task" (keydown.enter)="$event.preventDefault()" #form="ngForm" novalidate (ngSubmit)="onSaveClick()">
			<div class="white-bg shadow pd15 mgn-leftneg15 mgn-rightneg15 border-radius mgn-top30 mgn-bottom40">
				<div class="row">
					<div class="col-sm-10 col-sm-offset-2">
						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<span class="semi-bold">Client:</span>
							</div>
							<div class="col-sm-6 relative">
								<span>{{ task.clientName }}</span>
							</div>
						</div>
						<div class="row mgn-top20">
							<div class="col-sm-2 ht36 text-right">
								<span class="semi-bold">Type:</span>
							</div>
							<div class="col-sm-6 relative">
								<portal-dropdown-menu
									[backgroundColor]="'gray-bg-dark'"
									[height]="'ht40'"
									[key]="'Name'"
									[options]="createEditTaskService.taskTypes"
									[selectedOption]="task.taskType"
									(onOptionSelect)="onTaskTypeSelect($event)"
								></portal-dropdown-menu>
								<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
							</div>
						</div>

						<div class="row mgn-top20">
							<div class="col-sm-2 ht36 text-right">
								<span class="semi-bold">Due:</span>
							</div>
							<div class="col-sm-6 relative">
								<portal-date-time-selector
									[backgroundColor]="'gray-bg-dark'"
									[height]="'ht40'"
									[dateModel]="task.DueDate"
									(dateChanged)="onDateChange($event)"
								></portal-date-time-selector>
								<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
							</div>
						</div>

						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<span class="semi-bold">Subject:</span>
							</div>
							<div class="col-sm-6 relative">
								<input
									[ngClass]="{ 'red-border-light': !subject.valid && !subject.pristine }"
									required
									#subject="ngModel"
									id="subject"
									name="subject"
									[(ngModel)]="task.Subject"
									type="text"
								/>
								<small class="red-text-light" [hidden]="subject.valid || (subject.pristine && !form.submitted)">Subject is required.</small>
								<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
							</div>
						</div>

						<div class="row mgn-top20">
							<div class="col-sm-2 text-right">
								<span class="semi-bold">Notes:</span>
							</div>
							<div class="col-sm-6 relative">
								<textarea (keyup.enter)="onEnterClick()" rows="6" #notes="ngModel" id="notes" name="notes" [(ngModel)]="task.Notes" type="text"></textarea>
							</div>
						</div>
					</div>
				</div>

				<div class="row mgn-top50 mgn-bottom50">
					<div class="col-sm-10 col-sm-offset-2">
						<div class="row">
							<div class="col-sm-8">
								<div class="row">
									<div class="col-xs-3 col-sm-offset-6">
										<button type="button" class="gray-bg border-radius setWidth100" (mouseup)="location.back()">Cancel</button>
									</div>
									<div class="col-xs-3">
										<button [disabled]="!form.valid" [ngClass]="!form.valid ? 'setOpacityLow' : ''" class="limeGreen-bg border-radius white-text setWidth100">
											Save
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
