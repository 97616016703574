import { Injectable } from '@angular/core';

import { Column, Table, TableConfig } from '../../../shared/components/table/view-models';

@Injectable()
export class TableConfigService {
	public columns: Column[] = [
		new Column('ClientName', 'Client', 'link').setIsSortable(true).setElementClassList('semi-bold dark-text'),
		new Column('LocationName', 'Location', 'link').setIsSortable(true),
		new Column('PlayerName', 'Player', 'string').setIsSortable(true),
		new Column('Csr', 'CSR', 'string').setIsSortable(true),
		new Column('Hostname', 'Hostname', 'string').setIsSortable(true),
		new Column('UnitId_Mac', 'Unit ID/MAC', 'string').setIsSortable(true),
		new Column('ReportedVersion', 'Reported Version', 'string').setIsSortable(true),
		new Column('Model', 'Model', 'string').setIsSortable(true),
		new Column('InstallState', 'Install State', 'string').setIsSortable(true),
		new Column('repairStateView', 'Repair State', 'icon').setIsSortable(true).setSortKey('Repair'),
		new Column('lastCheckinView', 'Last Check-In', 'string').setIsSortable(true).setTooltip('checkinTooltip').setSortKey('LastCheckin'),
		new Column('lastContentUpdateView', 'Last Updated', 'string').setIsSortable(true).setTooltip('lastUpdateTooltip').setSortKey('LastUpdate'),
		new Column('activePlaylistName', 'Active Playlist', 'string').setIsSortable(true),
		new Column('networkView', 'Network', 'icon').setIsSortable(true).setSortKey('_poorNetworkConditions')
	];

	public table: Table = new Table(this.columns);

	public configuration: TableConfig = {
		itemsPerPage: 25,
		headerColor: 'white-bg',
		headerFontSize: 'ft-size12'
	};
}
