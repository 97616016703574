import { Injectable } from '@angular/core';

import { AdvancedSchedulerView } from '../_models/advanced-scheduler-view';

@Injectable({
  providedIn: 'root'
})
export class ViewService {

  constructor() { }

  public repeatsMonthly(view: AdvancedSchedulerView): boolean {
  	return view.RecurrenceType === 'Monthly';
  }

  public repeatsWeeklyOrMonthlyAndWeekdays(view: AdvancedSchedulerView): boolean {
  	return view.RecurrenceType === 'Weekly' || (view.RecurrenceSubtype === 'Weekdays' && view.RecurrenceType === 'Monthly');
  }

  public repeatsMonthlyAndSubTypeDaysOfMonth(view: AdvancedSchedulerView): boolean {
  	return view.RecurrenceType === 'Monthly' && view.RecurrenceSubtype === 'Days of Month';
  }

  public repeatsMonthlyAndSubTypeEveryDay(view: AdvancedSchedulerView): boolean {
  	return view.RecurrenceType === 'Monthly' && view.RecurrenceSubtype === 'Every Day';
  }

  public repeatsYearly(view: AdvancedSchedulerView): boolean {
  	return view.RecurrenceType === 'Yearly';
  }

  public repeatsEveryDay(view: AdvancedSchedulerView): boolean {
  	return view.RecurrenceType === 'Every Day';
  }

  public repeatsWeekly(view: AdvancedSchedulerView): boolean {
  	return view.RecurrenceType === 'Weekly';
  }

}
