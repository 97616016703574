export class ViewState {
	pageNumber: number;
	isSimpleSchedule: boolean;
	includeDeleted: boolean;
	orderByCol: string;
	isDesc: boolean;

	constructor(pageNumber, isSimpleSchedule, includeDeleted, orderByCol, isDesc) {
		this.pageNumber = pageNumber;
		this.isSimpleSchedule = isSimpleSchedule;
		this.includeDeleted = includeDeleted;
		this.orderByCol = orderByCol;
		this.isDesc = isDesc;
	}
}