export class FabricObject {
    fill: string;
    fillView: string; //holds fill color if fill becomes transparent
    top: number;
    left: number;
    scaleX: number;
    scaleY: number;
    lineHeight: number;
    charSpacing: number;
    fontSize: number;
    stroke: string;
    strokeWidth: number;
    opacity: number;
    width: number;
    height: number;
    shadow: {offsetX: number, offsetY: number, blur: number, color: string, affectStroke?: boolean, id?: number, nonScaling?: boolean};
    fontWeight: 'normal' | 'bold';
    fontFamily: string;
    fontStyle: string;
    underline: boolean;
    textAlign: string;
    borderOnly: boolean;
    includeDropShadow: boolean;
    angle: number;
    text: string;
    //Calculated y value for text objects that is accurate for the player
    textCalculatedY: number;
    blankBgColor: string;
    rx: number;
    ry: number;
    isFeed: boolean;
    feedImgBoundingBoxScaleX: number;
    feedImgBoundingBoxScaleY: number;
    feedImgBoundingBoxTop: number;
    feedImgBoundingBoxLeft: number;
    feedImgBoundingBoxCalcHeight: number;
    feedImgBoundingBoxCalcWidth: number;
}