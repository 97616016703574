export class ContentRequest {
	Id: number;
	RequestType: string;
	VoiceTalent: string;
	RequestedBy: number;
	CsrId: number;
	ProducerId: number;
	StateName: ContentRequestStateName;
	Title: string;
	ClientId: number;
	Description: string;
	ComplianceTags: string;
	TargetLibraryId: number;
	IsGlobalLibrary: boolean;
	DueDate: string;
	RequestApprovalFrom: number;
	ApprovedBy: number;
	ApprovedDateTime: string;
	CompletedDateTime: string;
	CreatedBy: number;
	CreateDateTime: string;
	LastUpdatedBy: number;
	LastUpdatedDateTime: string;
	CompletionNotes: string;
	IsFeatured: boolean;
	FeatureStartDate: string;
	FeatureEndDate: string;
}

export enum ContentRequestStateName {
	ASSIGNED_AWAITING_PRODUCTION = 'Assigned, Awaiting Production',
	AWAITING_ARTWORK = 'Awaiting Artwork',
	AWAITING_SCRIPT_EDITS = 'Awaiting Script Edits',
	CANCELLED = 'Cancelled',
	COMPLETE = 'Complete',
	IN_PRODUCTION = 'In Production',
	MERGED = 'Merged',
	PENDING_CLIENT_APPROVAL = 'Pending Client Approval',
	PENDING_CSR_APPROVAL = 'Pending CSR Approval',
	PENDING_EDITOR_APPROVAL = 'Pending Editor Approval',
	PENDING_SCRIPT_APPROVAL = 'Pending Script Approval',
	REVISION_REQUESTED = 'Revision Requested',
	UNASSIGNED = 'Unassigned'
}
