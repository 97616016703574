<portal-header></portal-header>
<!--*********************
	GREEN HEADER BAR
**********************-->
<div class="container-fluid">
	<div class="row mgn-bottom15 mgn-top15">
		<div class="col-xs-4 col-sm-2">
			<div class="row">
				<div class="col-xs-12 col-lg-9">
					<button (click)="location.back()" class="white-bg">
						<i class="fas fa-chevron-left mgn-right12"></i>
						Back
					</button>
				</div>
			</div>
		</div>
		<div class="col-xs-7 col-xs-offset-1 col-sm-offset-0 col-sm-10 text-right">
			<i class="fas fa-globe mgn-right12 ft-size28 green-text"></i>
			<h1 class="in-block">
				<span class="semi-bold">Global Settings</span>
				|
				<em>{{ appStateService.currentClient.Name }}</em>
			</h1>
		</div>
	</div>

	<div class="green-limeGreen-bg-gradient mgn-bottom45 ht2 border-radius"></div>
</div>

<ng-container>
	<div class="mgn-left15 mgn-right15 pd-top30 pd-bottom30 mgn-bottom60 pd-left15 pd-right15 border-radius white-bg shadow relative">
		<div class="row">
			<div *ngIf="appStateService.currentUser.IsEmployee" class="col-sm-6">
				<h2 class="semi-bold mgn-bottom15">Account Settings</h2>
				<div class="row mgn-bottom15">
					<div class="col-sm-1">
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[isChecked]="appStateService.currentClient.SendVideoReminders"
							[type]="'checkbox'"
							(checkboxClickNotify)="onCheckboxClick('SendVideoReminders')"
						></portal-checkbox>
					</div>
					<div class="col-sm-11">
						<label (mouseup)="onCheckboxClick('SendVideoReminders')" class="pointer">
							<span class="semi-bold">Enable Video Update Reminder Emails.</span>

							Receive email reminders at 30, 60, and 90 days when your video content has not been updated. Each user can be opted out of these emails in the
							user manager, unchecking it here disables these emails for your entire company.
						</label>
					</div>
				</div>

				<div class="row mgn-bottom15">
					<div class="col-sm-1">
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[isChecked]="appStateService.currentClient.SendHoldReminders"
							[type]="'checkbox'"
							(checkboxClickNotify)="onCheckboxClick('SendHoldReminders')"
						></portal-checkbox>
					</div>
					<div class="col-sm-11">
						<label (mouseup)="onCheckboxClick('SendHoldReminders')" class="pointer">
							<span class="semi-bold">Enable On Hold Update Reminder Emails.</span>

							Receive email reminders at 30, 60, and 90 days when your on hold content has not been updated. Each user can be opted out of these emails in the
							user manager, unchecking it here disables these emails for your entire company.
						</label>
					</div>
				</div>

				<div class="row mgn-bottom30">
					<div class="col-sm-1">
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[isChecked]="appStateService.currentClient.SendNewReleases"
							[type]="'checkbox'"
							(checkboxClickNotify)="onCheckboxClick('SendNewReleases')"
						></portal-checkbox>
					</div>
					<div class="col-sm-11">
						<label (mouseup)="onCheckboxClick('SendNewReleases')" class="pointer">
							<span class="semi-bold">Enable New Release Emails.</span>

							Receive monthly emails highlighting new content in Works24's on hold and video libraries. Each user can be opted out of these emails in the user
							manager, unchecking it here disables these emails for your entire company.
						</label>
					</div>
				</div>
				<ng-container *ngIf="clientHasAtLeastOneVoipPlayer">
					<h2 class="semi-bold mgn-bottom15 in-block mgn-right10">Additional VOIP Email Recipients</h2>
					<i
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ appendTo: 'parent' }"
						data-tippy-content="Semi-colons should be at end of each email address except the last."
						class="far fa-question-circle relative topneg5"
					></i>
					<div class="row relative">
						<div class="col-sm-12">
							<textarea
								rows="4"
								#voipEmail
								(input)="onVoipEmailChange(voipEmail.value)"
								[(ngModel)]="appStateService.currentClient.AdditionalVoipEmail"
							></textarea>
						</div>
					</div>
					<div class="row mgn-top30 mgn-bottom30">
						<div class="col-sm-5 col-sm-offset-7">
							<i
								ngxTippy
								tippyName="{{ utilService.guid | wrapFn }}"
								[tippyProps]="{ appendTo: 'parent' }"
								data-tippy-content="Saves VOIP email recipients only. All other options on this page save as the change is made."
								class="far fa-question-circle absolute right15 topneg20"
							></i>
							<button
								(click)="onSaveVoipEmailsClick()"
								[disabled]="!voipEmailListValid && appStateService.currentClient.AdditionalVoipEmail.length > 0"
								class="{{
									!voipEmailListValid && appStateService.currentClient.AdditionalVoipEmail.length > 0 ? 'setOpacityLow' : null
								}} limeGreen-bg white-text"
							>
								Save VOIP email recipients
							</button>
						</div>
					</div>
				</ng-container>
			</div>

			<div *ngIf="!appStateService.currentUser.IsEmployee && clientHasAtLeastOneVoipPlayer" class="col-sm-6">
				<h2 class="semi-bold mgn-bottom15 in-block mgn-right10">Additional VOIP Email Recipients</h2>
				<i
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					[tippyProps]="{ appendTo: 'parent' }"
					data-tippy-content="Semi-colons are required at end of each email address except the last."
					class="far fa-question-circle relative topneg5"
				></i>
				<div class="row relative">
					<div class="col-sm-12">
						<textarea
							rows="4"
							#voipEmail
							(input)="onVoipEmailChange(voipEmail.value)"
							[(ngModel)]="appStateService.currentClient.AdditionalVoipEmail"
						></textarea>
					</div>
				</div>
				<div class="row mgn-top30 mgn-bottom30">
					<div class="col-sm-5 col-sm-offset-7">
						<i
							ngxTippy
							tippyName="{{ utilService.guid | wrapFn }}"
							[tippyProps]="{ appendTo: 'parent' }"
							data-tippy-content="Saves VOIP email recipients only. All other options on this page save as the change is made."
							class="far fa-question-circle absolute right15 topneg20"
						></i>
						<button
							(click)="onSaveVoipEmailsClick()"
							[disabled]="!voipEmailListValid"
							class="{{ !voipEmailListValid ? 'setOpacityLow' : null }} limeGreen-bg white-text"
						>
							Save VOIP email recipients
						</button>
					</div>
				</div>
			</div>

			<div class="col-sm-6">
				<div class="row">
					<div [ngClass]="{ disabled: !holdSettingsEnabled }" class="col-xs-6">
						<h2 class="semi-bold mgn-bottom15">
							<i class="fa fa-phone-volume mgn-right10" aria-hidden="true"></i>
							On Hold Settings
						</h2>
						<span>Default Paragraph Pause</span>
						<br />
						<portal-dropdown-menu
							[backgroundColor]="'gray-bg-dark'"
							[height]="'ht40'"
							[isDisabled]="!holdSettingsEnabled"
							[key]="'name'"
							[selectedOption]="durationOptionsViewService.getDurationView(appStateService.currentClient.DefaultParagraphPauseMs)"
							[options]="durationOptionsViewService.holdDurationsView"
							(onOptionSelect)="onDropdownSelect('DefaultParagraphPauseMs', $event)"
						></portal-dropdown-menu>
						<p class="ft-size16 mgn-top15">
							<em>
								This is the default gap between your messages when just the music track is playing. You can edit this per-playlist in the Playlist Builder.
							</em>
						</p>
					</div>

					<div [ngClass]="{ disabled: !videoSettingsEnabled }" class="col-xs-6">
						<h2 class="semi-bold mgn-bottom15">
							<i class="fas fa-tv mgn-right10" aria-hidden="true"></i>
							Video Settings
						</h2>
						<span>Default Still Image Duration</span>
						<br />
						<portal-dropdown-menu
							[backgroundColor]="'gray-bg-dark'"
							[height]="'ht40'"
							[isDisabled]="!videoSettingsEnabled"
							[key]="'name'"
							[selectedOption]="durationOptionsViewService.getDurationView(appStateService.currentClient.DefaultStillDurationMs)"
							[options]="durationOptionsViewService.clipDurationsView"
							(onOptionSelect)="onDropdownSelect('DefaultStillDurationMs', $event)"
						></portal-dropdown-menu>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
