<portal-header></portal-header>
<div class="container-fluid mx-4 mgn-top30 pd-top10">
	<div class="row">
		<h1 class="semi-bold in-block">
			<i class="fas fa-ticket-alt mgn-right15" aria-hidden="true"></i>
			Ticket System
		</h1>
	</div>
	<!--*************************
		NAV BAR
	**************************-->
	<div class="row border-radius white-bg shadow ht64 ln-height64 mgn-top15 mgn-bottom30">
		<div class="col-md-12 center">
			<div class="center">
				<a
					[ngClass]="viewStateService.pageTitleView() === pageTitle.OVERDUE_TICKETS ? 'active' : null"
					class="{{ viewStateService.overdueTicketCount > 0 ? 'red-text-light' : 'green-text' }} pd22"
					(click)="onNavItemClick(pageTitle.OVERDUE_TICKETS)"
				>
					<i *ngIf="viewStateService.overdueTicketCount > 0" class="fas fa-exclamation-triangle mgn-right8 ft-size18" aria-hidden="true"></i>
					<i *ngIf="viewStateService.overdueTicketCount < 1" class="far fa-smile-wink mgn-right8 ft-size18" aria-hidden="true"></i>
					<span *ngIf="viewStateService.overdueTicketCount > 0">{{ viewStateService.overdueTicketCount }} Tickets Past Due</span>
					<span *ngIf="viewStateService.overdueTicketCount < 1">{{ viewStateService.overdueTicketCount }} Tickets Past Due!</span>
				</a>
				<a
					[ngClass]="viewStateService.pageTitleView() === pageTitle.MY_TICKETS ? 'active' : null"
					class="dark-text pd22"
					(click)="onNavItemClick(pageTitle.MY_TICKETS)"
				>
					<i class="fas fa-user-tag mgn-right8 ft-size18" aria-hidden="true"></i>
					Open Tickets By User
				</a>
				<a
					[ngClass]="viewStateService.pageTitleView() === pageTitle.OPEN_TICKETS ? 'active' : null"
					class="dark-text pd22"
					(click)="onNavItemClick(pageTitle.OPEN_TICKETS)"
				>
					<i class="far fa-envelope-open mgn-right8 ft-size18" aria-hidden="true"></i>
					Open Tickets
				</a>
				<a
					[ngClass]="viewStateService.pageTitleView() === pageTitle.ALL_TICKETS ? 'active' : null"
					class="dark-text pd22"
					(click)="onNavItemClick(pageTitle.ALL_TICKETS)"
				>
					<i class="fas fa-ticket-alt mgn-right8 ft-size18" aria-hidden="true"></i>
					All Tickets
				</a>
				<a
					[ngClass]="viewStateService.pageTitleView() === pageTitle.MANAGE_INSTALLERS ? 'active' : null"
					class="dark-text pd22"
					(click)="onNavItemClick(pageTitle.MANAGE_INSTALLERS)"
				>
					<i class="fas fa-network-wired mgn-right8 ft-size18" aria-hidden="true"></i>
					Manage Installers
				</a>
				<a class="dark-text pd22" [routerLink]="['/ticket-system/installer-map']">
					<i class="fas fa-map-marker-alt mgn-right8 ft-size18" aria-hidden="true"></i>
					Installer Map
				</a>
				<a class="dark-text pd22" (click)="viewStateService.colorKeyVisible = !viewStateService.colorKeyVisible">
					<i class="fas fa-key mgn-right8 ft-size18" aria-hidden="true"></i>
					Color Key
				</a>

				<!--******************
					COLOR KEY
				*******************-->
				<div
					*ngIf="viewStateService.colorKeyVisible"
					style="z-index: 103; line-height: initial"
					class="pd-left15 pd-right15 pd-top30 pd-bottom30 wdth600 white-bg box-shadow absolute border-radius right15 top70 text-left"
				>
					<div class="row">
						<div class="col-xs-3">
							<h3 class="semi-bold">Color Key</h3>
						</div>
						<div class="col-xs-7 text-right">
							<em>Numbers are business days since last action date.</em>
						</div>
						<div class="col-xs-2 text-right">
							<button class="white-bg border-radius setWidth75 ft-size16" (click)="viewStateService.colorKeyVisible = false">
								<i class="fa fa-times" aria-hidden="true"></i>
							</button>
						</div>
					</div>
					<div class="row mgn-top15">
						<div class="col-xs-4">
							<h4 class="semi-bold">Ticket Status</h4>
						</div>
						<div class="col-xs-4">
							<h4 class="yellow-text-darkest semi-bold">Yellow</h4>
						</div>
						<div class="col-xs-4">
							<h4 class="red-text-light semi-bold">Dark Red</h4>
						</div>
					</div>
					<hr class="mgn0" />
					<div *ngFor="let keyItem of colorKeyService.colorKeyItems" class="row mgn-top10">
						<div class="col-xs-4">
							<span>{{ keyItem.ticketStatus }}</span>
						</div>
						<div class="col-xs-4">
							<span>{{ keyItem.yellowNumber }}</span>
						</div>
						<div class="col-xs-4">
							<span>{{ keyItem.redNumber }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<!--*****************************
			NEW TICKET/INSTALLER BUTTON
		******************************-->
		<div class="col-sm-2 pd-left0">
			<button
				*ngIf="viewStateService.pageTitleView() !== pageTitle.MANAGE_INSTALLERS"
				class="limeGreen-bg white-text"
				(click)="onNewClick('/ticket-system/new-ticket')"
			>
				<i class="fas fa-plus mgn-right10"></i>
				New ticket
			</button>
			<button
				*ngIf="viewStateService.pageTitleView() === pageTitle.MANAGE_INSTALLERS"
				class="limeGreen-bg white-text"
				(click)="onNewClick('/ticket-system/new-installer')"
			>
				<i class="fas fa-plus mgn-right10"></i>
				New installer
			</button>
		</div>

		<!--*************************
			VIEW POSTPONED TICKETS
		**************************-->
		<div *ngIf="viewStateService.pageTitleView() === pageTitle.MY_TICKETS" class="col-sm-2">
			<button
				[disabled]="viewStateService.spinnerActive"
				class="{{ viewStateService.spinnerActive ? 'setOpacityLow' : null }} white-bg"
				(click)="onViewPostponedClick()"
			>
				{{ viewStateService.postponedVisible ? 'Hide Postponed' : 'View Postponed' }}
			</button>
		</div>

		<!--***************************
			EMPLOYEE TICKET COUNT LIST
		****************************-->
		<div *ngIf="viewStateService.pageTitleView() === pageTitle.MY_TICKETS" class="col-sm-2">
			<div>
				<portal-dropdown-menu
					[backgroundColor]="'gray-bg-dark'"
					[height]="'ht40'"
					[key]="'displayName'"
					[options]="viewStateService.employeeTicketCountList"
					[selectedOption]="viewStateService.selectedEmployee?.displayName"
					[scrollbarHidden]="true"
					(onOptionSelect)="onEmployeeTicketCountSelect($event)"
				></portal-dropdown-menu>
			</div>
		</div>
	</div>

	<!--*************************
		MAIN TICKETS TABLE
	**************************-->
	<div class="row mgn-top30 mgn-bottom15">
		<div class="col-sm-9 pd-left0">
			<h1 class="semi-bold relative">
				{{ viewStateService.pageTitle }}
				<i
					*ngIf="viewStateService.pageTitleView() === pageTitle.MY_TICKETS"
					class="fa fa-asterisk ft-size9 gray-text-darkest relative bottom15"
					aria-hidden="true"
				></i>
				<span *ngIf="viewStateService.postponedVisible"><em>- Postponed</em></span>
			</h1>
		</div>
		<div class="col-sm-2 col-sm-offset-1 pd-right0 relative">
			<input type="text" [(ngModel)]="viewStateService.searchTerm" (keyup)="searchTermChanged.next(viewStateService.searchTerm)" placeholder="Search..." />
			<button
				*ngIf="viewStateService.searchTerm?.length > 0"
				style="width: initial"
				class="absolute top8 white-bg pd12 right8 z border-radius"
				(click)="onSearchClear()"
			>
				<i class="fa fa-times absolute center-unknown-height-width" aria-hidden="true"></i>
			</button>
		</div>
	</div>
	<div class="row relative">
		<div *ngIf="viewStateService.spinnerActive" style="left: 47%" class="absolute z top172">
			<portal-spinner [width]="'wdthpx76'" [height]="'htpx76'"></portal-spinner>
		</div>
		<portal-table
			[table]="viewStateService.table"
			[config]="tableConfigService.config"
			[data]="ticketSystemService.mainTableData"
			[showJumpToPage]="true"
			[page]="viewStateService.pageNumber"
			[totalItems]="viewStateService.mainTableTotalItems"
			(currentPageNotify)="getPage($event)"
			(rowSelectNotify)="onRowSelect($event)"
		></portal-table>
	</div>
	<div *ngIf="viewStateService.pageTitleView() === pageTitle.MY_TICKETS" class="row mgn-bottom30">
		<div class="col-sm-12">
			<span class="gray-text-darkest">
				<em>*Count includes {{ viewStateService.postponedVisible ? 'non-postponed' : 'postponed' }} tickets.</em>
			</span>
		</div>
	</div>

	<!--*************************
		UNCLAIMED TICKETS TABLE
	**************************-->
	<div *ngIf="viewStateService.pageTitleView() === pageTitle.MY_TICKETS" class="row">
		<div class="col-sm-4 pd-left0">
			<h1 class="semi-bold">Unclaimed Tickets</h1>
		</div>
	</div>

	<div *ngIf="viewStateService.pageTitleView() === pageTitle.MY_TICKETS" class="row mgn-bottom30 relative">
		<portal-table
			[table]="tableConfigService.unclaimedTable"
			[config]="tableConfigService.unclaimedTableConfig"
			[data]="ticketSystemService.unassignedTickets"
			(buttonClickNotify)="onTakeClick($event)"
			(rowSelectNotify)="onRowSelect($event)"
		></portal-table>
		<span class="right">
			<span class="semi-bold">Total Items:</span>
			{{ ticketSystemService.unassignedTickets?.length }}
		</span>
	</div>
</div>
