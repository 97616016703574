import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AdvancedScheduleSummaryService } from './advanced-schedule-summary.service';
import { environment } from '../../../environments/environment';
import { DateTimeService, Events, MessageService, UtilitiesService } from '../../core/services';
import { ContentFiles, Feeds, FeedItems, FeedSources, FeedProperties, ContentAdvancedSchedules } from '../../shared/api-models/content';
import { MyFeedsService } from './my-feeds.service';

@Injectable({
	providedIn: 'root'
})
export class FeedItemsService {
	public allContainNoImage: boolean;
	public pageNumber: number = 1;
	public searchTerm: string = '';
	public totalItems: number;
	public itemsPerPage: number;

	constructor(
		private advancedScheduleSummaryService: AdvancedScheduleSummaryService,
		private dateTimeService: DateTimeService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private myFeedsService: MyFeedsService,
		private utilService: UtilitiesService
	) {}

	public getFeedAndFeedSource(feedId: number): Observable<Feeds> {
		return this.httpClient.get(`${environment.contentUrl}Feeds/${feedId}`).pipe(
			mergeMap((feed: Feeds) => {
				const feedSource$ = this.httpClient.get(`${environment.contentUrl}FeedSources/${feed.FeedSourceId}`);
				const feedProperties$ = this.httpClient.get(`${environment.contentUrl}feeds/${feed.Id}/Properties`);

				return forkJoin([feedSource$, feedProperties$]).pipe(
					map((res: [FeedSources, FeedProperties[]]) => {
						const feedSource: FeedSources = res[0];
						const feedProperties: FeedProperties[] = res[1];
						return this.feedView([feed, feedSource, feedProperties]);
					})
				);
			})
		);
	}

	public getFeedItems(feed: Feeds, searchActive?: boolean): Observable<FeedItems[]> {
		let url;
		if (searchActive) {
			url = `${environment.contentUrl}Feeds/${feed.Id}/Search/${this.searchTerm}?pageNumber=${this.pageNumber}`;
		} else {
			//These types contains thousands of items, not doable to show all on one page
			const feedItemsNotSortable = [10, 12, 13, 14].some((feedSourceId) => feedSourceId === feed.FeedSourceId);

			if (feedItemsNotSortable) {
				url = `${environment.contentUrl}Feeds/${feed.Id}/FeedItems?pageNumber=${this.pageNumber}`;
				this.itemsPerPage = 25;
			} else {
				url = `${environment.contentUrl}Feeds/${feed.Id}/FeedItems?pageSize=200`;
				this.itemsPerPage = 200;
			}
		}
		return this.httpClient.get(url, { observe: 'response' }).pipe(
			mergeMap((response: HttpResponse<any>) => {
				this.totalItems = parseInt(response.headers.get('x-result-count'));
				let feedItems: FeedItems[] = response.body;

				if (feed.IsSorted) {
					const sortedItems = this.utilService.sortItems(feedItems, 'SortOrder');
					feedItems = !!sortedItems ? sortedItems : feedItems;
				}
				if (this.isPlaylistFeed(feed) || feed.FeedSourceId === 1000008) {
					if (feedItems.length === 0) {
						return of([]);
					}
					const contentFiles$ = feedItems.map((feedItem) => {
						const isArray = Array.isArray(JSON.parse(feedItem.ItemContent));
						feedItem.contentFileId = isArray ? JSON.parse(feedItem.ItemContent)[0].ContentFileId : JSON.parse(feedItem.ItemContent).ContentFileId;
						if (feedItem.contentFileId) {
							return this.httpClient.get(`${environment.contentUrl}ContentFiles/${feedItem.contentFileId}`);
						}
						return of('');
					});

					const advancedSchedules$ = feedItems.map((feedItem) => {
						if (feedItem.AdvancedScheduleId) {
							return this.httpClient.get(`${environment.contentUrl}contentadvancedSchedules/${feedItem.AdvancedScheduleId}`);
						}
						return of('');
					});

					const observs$ = [].concat.apply([], [contentFiles$, advancedSchedules$]);

					return forkJoin(observs$).pipe(
						map((resArr: any[]) => {
							const data = resArr.map((item) => {
								return item ? item : '';
							});
							const contentFiles: ContentFiles[] = data.slice(0, feedItems.length);
							const advancedSchedules: ContentAdvancedSchedules[] = data.slice(feedItems.length, feedItems.length * 2);
							const feedItemsView = this.feedItemsView(feedItems, contentFiles, feed, advancedSchedules);

							const atLeastOneBodyWithText = feedItemsView.some((feedItem) => feedItem.body.length > 0);
							this.allContainNoImage = feedItemsView.every((feedItem) => this.utilService.includes(feedItem.previewUrl, 'undefined'));

							return feedItemsView.map((feedItem) => {
								//If playlist data feed and at least one feed item body contains text, show body for all
								if (this.isPlaylistFeed(feed) && atLeastOneBodyWithText) {
									feedItem.showBody = true;
								}
								//If at least one feed item has an image, show images for all
								if (!this.allContainNoImage) {
									feedItem.showImage = true;
								}
								return feedItem;
							});
						})
					);
				}
				return of(this.feedItemsView(feedItems, null, feed));
			})
		);
	}

	private isPlaylistFeed(feed: Feeds): boolean {
		const feedSourceIds: number[] = [10, 12, 13, 14, 1000002];
		return feedSourceIds.some((id) => id === feed.FeedSourceId);
	}

	public deleteFeedItem(feedItem: FeedItems): Observable<void> {
		this.messageService.publish(Events.savingPreloader, 1);
		const deleteFeedItem$ = this.httpClient.delete(`${environment.contentUrl}FeedItems/${feedItem.Id}`);
		const deleteContentFile$ = this.httpClient.delete(`${environment.contentUrl}ContentFiles/${feedItem.contentFileId}`);

		const observs$ = feedItem.contentFileId ? [deleteFeedItem$, deleteContentFile$] : [deleteFeedItem$];

		return forkJoin(observs$).pipe(
			map(() => {
				this.messageService.publish(Events.savingPreloader, 0);
			})
		);
	}

	private feedItemsView(feedItems: FeedItems[], contentFiles?: ContentFiles[], feed?: Feeds, advancedSchedules?: ContentAdvancedSchedules[]): FeedItems[] {
		return feedItems.map((feedItem, index) => {
			let itemContent = JSON.parse(feedItem.ItemContent);
			itemContent = Array.isArray(itemContent) ? itemContent[0] : itemContent;

			if (contentFiles) {
				feedItem.contentFileStateId = contentFiles[index].ContentFileStateId;
				feedItem.previewUrl = environment.contentUrl + 'File/Video/' + contentFiles[index].Id + '/Preview';
			}
			if (advancedSchedules) {
				feedItem.advancedSchedule = advancedSchedules[index];
			}

			feedItem.classList = 't-row';
			feedItem.editBtn = 'Edit';
			feedItem.deleteBtn = 'Delete';
			feedItem.itemText = this.itemText(feedItem, feed);
			feedItem.title = itemContent.Title;
			feedItem.body = itemContent.Body;
			feedItem.sourceId = itemContent.SourceId;
			feedItem.startDate = this.dateTimeService.dateAndTimeUTCtoLocal(feedItem.StartDate);
			feedItem.stopDate = this.dateTimeService.dateAndTimeUTCtoLocal(feedItem.StopDate);
			feedItem.createDate = this.dateTimeService.dateAndTimeUTCtoLocal(feedItem.CreateDate);
			feedItem.startDateTooltip = feedItem.AdvancedScheduleId ? this.advancedScheduleSummaryService.friendlyStringOutput(feedItem.AdvancedSchedule) : null;
			return feedItem;
		});
	}

	private itemText(feedItem: FeedItems, feed: Feeds): string {
		let itemContent = JSON.parse(feedItem.ItemContent);
		itemContent = Array.isArray(itemContent) ? itemContent[0] : itemContent;
		if (itemContent.Title) {
			return `<span class="${feed.FeedSourceId === 1000002 ? 'semi-bold' : null}">${itemContent.Title}</span><br>${itemContent.Body}`;
		} else {
			return `${itemContent.Body}`;
		}
	}

	private feedView(feedData: [Feeds, FeedSources, FeedProperties[]]): Feeds {
		const feed: Feeds = feedData[0];
		feed.editBtn = 'Edit';
		feed.classList = 't-row';
		feed.feedSource = feedData[1];
		feed.feedType = feed.feedSource.Name;
		feed.properties = feedData[2][0];
		feed.activeItemsCount = this.myFeedsService.activeItemsCount(feed, feedData[2]);
		if (feed.FeedSourceId === 8) {
			feed.stockSymbols = JSON.parse(feed.properties.Value);
		}
		return feed;
	}

	public patchFeedItemSort(feedItems: FeedItems[]): void {
		this.messageService.publish(Events.savingPreloader, 1);
		const feedItemsPatchArr$ = feedItems.map((feedItem) => {
			return this.httpClient.patch(`${environment.contentUrl}FeedItems/${feedItem.Id}`, { SortOrder: feedItem.SortOrder });
		});
		forkJoin(feedItemsPatchArr$).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}

	public patchFeedSort(feed: Feeds): void {
		this.httpClient.patch(`${environment.contentUrl}Feeds/${feed.Id}`, { IsSorted: true }).subscribe();
	}

	public updateSortOrder(feedItemsView: FeedItems[]): void {
		feedItemsView.forEach((feedItem, i) => {
			feedItem.SortOrder = i + 1;
		});
		this.patchFeedItemSort(feedItemsView);
	}

	//If feed item was recently added, sort order is incorrect unless updated
	public updateSortOrderIfDups(feedItems: FeedItems[]): void {
		if (!this.utilService.arrayPropIsUnique(feedItems, 'SortOrder')) {
			this.updateSortOrder(feedItems);
		}
	}
}
