import { Component, ElementRef, DoCheck, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppStateService } from 'src/app/core/services';
import { CanvasService } from './_services';
import { Layer } from '../_models';
import { LayersService } from '../_services';
import { VideoControlsService } from '../_services';

@Component({
	selector: 'me-canvas',
	templateUrl: './canvas.component.html',
	styleUrls: ['./canvas.component.scss']
})
export class CanvasComponent implements OnInit, OnDestroy, DoCheck {
	@ViewChild('stillImage') stillImage: ElementRef;
	@ViewChild('videoPlayer') videoPlayer: ElementRef;

	private subs: Subscription[] = [];

	constructor(
		private appStateService: AppStateService,
		public canvasService: CanvasService,
		private layersService: LayersService,
		private videoControlsService: VideoControlsService
	) {}

	ngOnInit(): void {
		this.canvasService.canvas.getContext('2d').imageSmoothingEnabled = true;
		if (this.appStateService.getSessionItem('c24BgContent')) {
			this.canvasService.bgContent = JSON.parse(this.appStateService.getSessionItem('c24BgContent'));
		}
		this.canvasService.canvas.setHeight(this.canvasService.canvasSize().height);
		this.canvasService.canvas.setWidth(this.canvasService.canvasSize().width);
	}

	ngDoCheck() {
		if (this.videoPlayer) {
			this.videoControlsService.videoPlayer = this.videoPlayer;
		}
		if (this.stillImage) {
			this.videoControlsService.videoPlayer = this.stillImage;
		}
	}

	public bgLayer(): Layer {
		return this.layersService.layers.find((layer) => layer.id === 0);
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
