export class Play24Stations {
	Id: number;
	ClientId: number;
	Play24StationCategoryId: number;
	Channel: number;
	Name: string;
	Description: string;
	ServerUrl: string;
	PreviewUrl: string;
	IsEnabled: boolean;
}