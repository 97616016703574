import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Events } from './events';
import { MessageService } from './message.service';

//Use case for this service is to open a new tab and change clients
//without affecting the original tab. As of this comment (12/2019),
//we always want to route to the CRM when opening a new tab and switching clients.
//Here's the sequence:

//1. A component will initiate the new tab with this line: window.open({dashboard/clientId});
    //(we have to route somewhere first (dashboard) before going to the CRM, otherwise we have to do a 
    //hard reload on the CRM page, which is really slow).
//2. My Products component loads. If it sees an ID on the end of the url, it calls the onClientChange
    //method in this service.
//3. Once the token is set from the method below, the ClientSwitcher component subscribes to
    //the new token being set, sets the new client in AppStateService, and routes to the CRM.


@Injectable({
    providedIn: 'root'
})
export class ClientSwitcherService {

    public newClientId: number;

    constructor(
        private httpClient: HttpClient,
        private messageService: MessageService
    ) { }

    onClientChange(client: { CsrId: number, Id: number, Name: string }) {
        this.newClientId = client.Id;
        this.httpClient.get(environment.authUrl + "Login/SwitchClient/" + this.newClientId)
            .subscribe( (res) => {
                this.messageService.publish(Events.tokenSet, null);
            })
    }
}
