<div class="row border-radius {{ !isLastIndex ? 'border-first-index' : 'border-last-index border-first-index mgn-bottom1' }} white-bg pd-top15 pd-bottom15">
	<div class="col-md-9">
		<div class="row">
			<!--***************************
				VIDEO SCRIPT BUTTON
			****************************-->
			<div class="col-md-2 ft-size16">
				<button class="setWidth100 z gray-bg" (mouseup)="content.detailsVisible = !content.detailsVisible">
					<i class="{{ content.detailsVisible ? 'fas fa-times' : 'far fa-file-alt' }} icon-hidden mgn-right10" aria-hidden="true"></i>
					{{ content.detailsVisible ? 'Close' : 'Script' }}
				</button>
			</div>

			<!--***************************
				VIDEO PREVIEW
			****************************-->
			<div class="col-lg-4">
				<portal-preview-thumbnail [content]="content"></portal-preview-thumbnail>
			</div>

			<!--***************************
				CONTENT TITLE
			****************************-->
			<div class="col-lg-6 ft-size16">
				<div class="relative">
					<i
						*ngIf="!!contentViewService.featuredContentProps(content)"
						class="{{ contentViewService.featuredContentProps(content).icon }} {{
							contentViewService.featuredContentProps(content).color
						}} absolute leftneg22 top2"
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ appendTo: 'parent' }"
						attr.data-tippy-content="{{ contentViewService.featuredContentProps(content).tooltip }}"
					></i>
					<span>{{ content.Title }}</span>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-3">
		<div class="row">
			<!--***************************
				FAVORITE BUTTON
			****************************-->
			<div class="col-md-4">
				<button
					*ngIf="cvStateService.viewState.treeSelection !== 'featured-playlist'"
					class="{{ content.isFavorite ? 'pink-bg' : 'white-bg' }} white-text relative"
					(click)="contentFavoritesService.onFavoriteClick(content)"
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					[tippyProps]="{ appendTo: 'parent' }"
					attr.data-tippy-content="{{ content.favoriteBtnTooltip }}"
				>
					<i class="fa fa-heart {{ content.isFavorite ? 'white-text' : 'pink-text' }}" aria-hidden="true"></i>
					<i *ngIf="content.isFavorite" class="fa fa-check absolute ft-size10 top2 right2" aria-hidden="true"></i>
				</button>
			</div>

			<!--***************************
				ADD CLIP BUTTON
			****************************-->
			<div class="col-md-8">
				<ng-container *ngIf="content.contentFiles?.length > 0 && canUseInPlaylist && !playerCapacityService.showWarning">
					<button
						*ngIf="cvStateService.viewState.treeSelection === 'featured-playlist'"
						class="gray-bg ft-size11"
						(mouseup)="addToPlaylistService.onAddClick(content)"
					>
						Add just this clip
						<i class="fa fa-angle-right mgn-left10" aria-hidden="true"></i>
					</button>
					<button *ngIf="cvStateService.viewState.treeSelection !== 'featured-playlist'" class="gray-bg" (mouseup)="addToPlaylistService.onAddClick(content)">
						Add clip
						<i class="fa fa-angle-right mgn-left10" aria-hidden="true"></i>
					</button>
				</ng-container>

				<ng-container *ngIf="!canUseInPlaylist || playerCapacityService.showWarning">
					<button
						*ngIf="cvStateService.viewState.treeSelection === 'featured-playlist'"
						class="{{ content.IsChannel ? 'ft-size14' : null }} setOpacityLow gray-bg ft-size11 cursor-not-allowed"
					>
						Add just this clip
						<i class="fa fa-angle-right mgn-left10" aria-hidden="true"></i>
					</button>
					<button
						*ngIf="cvStateService.viewState.treeSelection !== 'featured-playlist'"
						class="{{ content.IsChannel ? 'ft-size14' : null }} setOpacityLow gray-bg cursor-not-allowed"
					>
						Add clip
						<i class="fa fa-angle-right mgn-left10" aria-hidden="true"></i>
					</button>
				</ng-container>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				<h4 class="mgn-top6 right green-text">
					<em>{{ content.processingStateJson }}</em>
				</h4>
			</div>
		</div>
	</div>

	<!--***************************
		SCRIPT
	****************************-->
	<div *ngIf="content.detailsVisible" class="col-lg-12 mgn-bottom15">
		<c-details [content]="content"></c-details>
	</div>
</div>
