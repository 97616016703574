<portal-header></portal-header>
<div *ngIf="errorLogModalActive" class="absolute left0 right0">
	<div class="modal-overlay" (mouseup)="errorLogModalActive = false"></div>
	<div class="row">
		<div
			style="top: 150px; margin-bottom: 60px; z-index: 200"
			class="col-xs-10 col-xs-offset-1 min-height600 margin mgn-bottom30 absolute left0 right0 slideUp"
		>
			<ld-error-log-modal
				[errorLogs]="errorLogs$ | async"
				[player]="selectedPlayer"
				(onExitClick)="errorLogModalActive = false"
				(click)="$event.stopPropagation()"
			></ld-error-log-modal>
		</div>
	</div>
</div>

<div class="container-fluid mgn-top30 relative">
	<div class="mgn-bottom10">
		<a (click)="loc.back()" class="dark-text absolute topneg21 left15">
			<i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
			<span class="ft-size16">Back</span>
		</a>
	</div>
	<div class="row mgn-bottom15 mgn-right0 mgn-left0">
		<div class="col-sm-12 pd-left0">
			<h1>
				<span class="semi-bold">{{ appStateService.currentClient.Name }}</span>
				|
				<em>{{ location?.Name }}</em>
			</h1>
		</div>
	</div>

	<div class="row shadow white-bg border-radius pd-top15 pd-bottom15 mgn-right0 mgn-left0">
		<div class="col-sm-10 col-md-8">
			<div class="row">
				<div class="col-sm-3 mgn-bottom-xs">
					<button class="gray-bg" [routerLink]="['/crm/new-history-note']">
						<i class="fa fa-history mgn-right10" aria-hidden="true"></i>
						New History Note
					</button>
				</div>
				<div class="col-sm-3 mgn-bottom-xs">
					<button class="gray-bg" [routerLink]="['/crm/client-details/locations/location-details/new-player/hold']">
						<i class="fa fa-phone-volume mgn-right10" aria-hidden="true"></i>
						New Hold Player
					</button>
				</div>
				<div class="col-sm-3 mgn-bottom-xs">
					<button class="gray-bg" [routerLink]="['/crm/client-details/locations/location-details/new-player/video']">
						<i class="fas fa-tv mgn-right10" aria-hidden="true"></i>
						New Video Player
					</button>
				</div>
				<div class="col-sm-3">
					<button class="gray-bg" [routerLink]="['/crm/client-details/locations/location-details/new-player/radio']">
						<i class="fa fa-volume-up mgn-right10" aria-hidden="true"></i>
						New Radio Player
					</button>
				</div>
			</div>
		</div>
		<div class="col-sm-2 col-md-4">
			<div class="row">
				<div class="col-sm-12 col-md-5 col-md-offset-7 col-lg-4 col-lg-offset-8">
					<button class="gray-bg" (click)="onEditLocationClick()">
						<i class="fas fa-map-marker-alt mgn-right10" aria-hidden="true"></i>
						Edit Location
					</button>
				</div>
			</div>
		</div>
	</div>

	<section class="white-bg mgn-top50 border-radius pd15 shadow">
		<div *ngIf="location" class="row ft-size16 mgn-right0 mgn-left0">
			<div class="col-sm-3">
				<ng-container *ngIf="location">
					<h4 class="gray-text-darkest mgn-bottom2">ADDRESS</h4>
					<span class="ft-size18 regular">{{ location.Address1 }}</span>
					<span class="ft-size18 regular">{{ location.City }}, {{ location.State }} {{ location.Zip }}</span>
					<br />
					<em class="ft-size18 regular">Phone: {{ location.Phone }}</em>
				</ng-container>
			</div>
			<div class="col-sm-3">
				<h4 class="gray-text-darkest mgn-bottom2">CLIENT CSR</h4>
				<a href="mailto:{{ clientCsr?.ContactInformation.Email }}" class="ft-size18 regular">{{ clientCsr?.ContactInformation.Email }}</a>
			</div>
			<div class="col-sm-3 relative">
				<h4 class="gray-text-darkest mgn-bottom2">PRIMARY CONTACT</h4>
				<ng-container *ngIf="location.PrimaryContactUser; else noPrimary">
					<span class="ft-size18 regular">
						{{ location.PrimaryContactUser.ContactInformation.FirstName }} {{ location.PrimaryContactUser.ContactInformation.LastName }}
					</span>
					<br />
					<a href="mailto:{{ location.PrimaryContactUser.ContactInformation.Email }}" class="ft-size18 regular">
						{{ location.PrimaryContactUser.ContactInformation.Email }}
					</a>
				</ng-container>
				<ng-template #noPrimary>
					<em class="regular">No primary contact set</em>
				</ng-template>
			</div>
			<div class="col-sm-3">
				<h4 class="gray-text-darkest mgn-bottom2">PHONE SYSTEM</h4>
				<ng-container *ngIf="location.PhoneSystem; else noPhoneSystem">
					<span class="ft-size18 regular">{{ location.PhoneSystem }}</span>
					<br />
				</ng-container>
				<ng-template #noPhoneSystem>
					<em class="regular">No phone system data available</em>
				</ng-template>
			</div>
		</div>
	</section>

	<div class="row mgn-top30 relative mgn-right0 mgn-left0">
		<h2 class="semi-bold">
			<i class="fa fa-phone-volume mgn-right6" aria-hidden="true"></i>
			On Hold Players
		</h2>
		<span *ngIf="!holdTableData?.length"><em>No On Hold players installed at this location.</em></span>
		<portal-table
			*ngIf="holdTableData?.length"
			[table]="tableService.holdTable"
			[config]="tableService.holdConfig"
			[data]="holdTableData"
			[totalItems]=""
			(buttonClickNotify)="onTableButtonClick($event)"
		></portal-table>
	</div>

	<hr class="mgn-bottom53" />
	<div class="row mgn-top30 mgn-right0 mgn-left0">
		<h2 class="semi-bold">
			<i class="fas fa-tv mgn-right6" aria-hidden="true"></i>
			Video Players
		</h2>
		<span *ngIf="!videoTableData?.length"><em>No Video players installed at this location.</em></span>
		<portal-table
			*ngIf="videoTableData?.length"
			[table]="tableService.videoTable"
			[config]="tableService.videoConfig"
			[data]="videoTableData"
			(buttonClickNotify)="onTableButtonClick($event)"
		></portal-table>
	</div>
	<hr class="mgn-bottom53" />
	<div class="row mgn-top30 mgn-right0 mgn-left0">
		<h2 class="semi-bold">
			<i class="far fa-square mgn-right6" aria-hidden="true"></i>
			Poster Players
		</h2>
		<span *ngIf="!postersTableData?.length"><em>No Poster players installed at this location.</em></span>
		<portal-table
			*ngIf="postersTableData?.length"
			[table]="tableService.posterTable"
			[config]="tableService.videoConfig"
			[data]="postersTableData"
			(buttonClickNotify)="onTableButtonClick($event)"
		></portal-table>
	</div>
	<hr class="mgn-bottom53" />
	<div class="row mgn-top30 mgn-right0 mgn-left0">
		<h2 class="semi-bold">
			<i class="fa fa-th-large mgn-right6" aria-hidden="true"></i>
			Other Video Players
		</h2>
		<span *ngIf="!otherPlayersTableData?.length"><em>No Other players installed at this location.</em></span>
		<portal-table
			*ngIf="otherPlayersTableData?.length"
			[table]="tableService.otherTable"
			[config]="tableService.videoConfig"
			[data]="otherPlayersTableData"
			(buttonClickNotify)="onTableButtonClick($event)"
		></portal-table>
	</div>
	<hr class="mgn-bottom53" />
	<div class="row mgn-top30 mgn-right0 mgn-left0">
		<h2 class="semi-bold">
			<i class="fa fa-volume-up mgn-right6" aria-hidden="true"></i>
			Radio Players
		</h2>
		<span *ngIf="!radioTableData?.length"><em>No Radio players installed at this location.</em></span>
		<portal-table
			*ngIf="radioTableData?.length"
			[table]="tableService.radioTable"
			[config]="tableService.radioConfig"
			[data]="radioTableData"
			(buttonClickNotify)="onTableButtonClick($event)"
		></portal-table>
	</div>
	<hr class="mgn-bottom53" />
</div>
