import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as moment from 'moment';

import { Contracts, ProductTypes } from '../../../shared/api-models/admin';
import { ContractsService } from '../contracts.service';
import { ContractsProductsBridge, Users } from '../../../shared/api-models/admin';
import { environment } from '../../../../environments/environment';
import { CacheService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root'
})
export class ContractService {
	constructor(private contractsService: ContractsService, private httpClient: HttpClient, private cacheService: CacheService) {}

	public deleteContract(contractId: number): Observable<any> {
		return this.httpClient.delete(`${environment.adminUrl}CoreContracts/${contractId}`);
	}

	public getProductByContract(contract: Contracts): Observable<any> {
		return this.httpClient.get(`${environment.adminUrl}CoreContracts/${contract.Id}/Products`).pipe(
			map((productTypes: ProductTypes[]) => {
				if (productTypes[0]) {
					return { productType: productTypes[0].ProductName, bgColor: this.contractsService.setBgColor(productTypes[0].Id) };
				}
			})
		);
	}

	public getSalesperson(contract: Contracts): Observable<Users> {
		let apiCall = this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${contract.SalespersonId}`);
		return this.cacheService.user$(apiCall, contract.SalespersonId);
	}

	public postContract(unsavedContract: Contracts): Observable<any> {
		return this.httpClient.post(`${environment.adminUrl}CoreContracts`, unsavedContract).pipe(
			mergeMap((savedContract: Contracts) => {
				return this.httpClient.post(`${environment.adminUrl}CoreContractsProductsBridge`, this.contractsProductsBridge(unsavedContract, savedContract));
			})
		);
	}

	public putContract(contract: Contracts): Observable<any> {
		return this.httpClient.put(`${environment.adminUrl}CoreContracts/${contract.Id}`, contract);
	}

	private contractsProductsBridge(unsavedContract: Contracts, savedContract: Contracts): ContractsProductsBridge {
		let contractsProductsBridge: ContractsProductsBridge = new ContractsProductsBridge();
		contractsProductsBridge.ContractId = savedContract.Id;
		contractsProductsBridge.ProductTypeId = unsavedContract.productTypeId;
		return contractsProductsBridge;
	}

	public setContractStatus(contract: Contracts): string {
		let today = moment(new Date());
		let renewDate = moment(contract.RenewDate);
		let renewDateInFuture = today.isBefore(renewDate);

		if (!contract.Id) {
			return 'UNSAVED CONTRACT';
		}

		switch (true) {
			case contract.IsRenewed && !contract.IsExemptFromStats:
				this.setExpiredContractProps(contract, 'gray-bg-darker');
				return 'RENEWED';

			case contract.IsRenewed && contract.IsExemptFromStats:
				this.setExpiredContractProps(contract, 'gray-bg-darker');
				return 'RENEWED - EXEMPT FROM STATS';

			case contract.IsEarlyCancel && !contract.IsExemptFromStats:
				this.setExpiredContractProps(contract, 'gray-bg-darker', 'red-text');
				return 'EARLY CANCEL';

			case contract.IsEarlyCancel && contract.IsExemptFromStats:
				this.setExpiredContractProps(contract, 'gray-bg-darker', 'red-text');
				return 'EARLY CANCEL - EXEMPT FROM STATS';

			case contract.IsFulfilledContractCancellation && !contract.IsExemptFromStats:
				this.setExpiredContractProps(contract, 'gray-bg-darker', 'red-text');
				return 'FULLY EXPIRED';

			case contract.IsFulfilledContractCancellation && contract.IsExemptFromStats:
				this.setExpiredContractProps(contract, 'gray-bg-darker', 'red-text');
				return 'FULLY EXPIRED - EXEMPT FROM STATS';

			case !contract.IsRenewed && !contract.IsEarlyCancel && !contract.IsFulfilledContractCancellation && !renewDateInFuture:
				this.setExpiredContractProps(contract, 'gray-bg-darker', 'red-text');
				return 'EXPIRED - RENEWAL PENDING';

			default:
				this.setExpiredContractProps(contract, contract.bgColor, 'dark-text');
				return 'ACTIVE CONTRACT';
		}
	}

	private setExpiredContractProps(contract: Contracts, bgColor: string, textColor?: string): void {
		contract.bgColor = bgColor;
		contract.textColor = textColor;
	}
}
