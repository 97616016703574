<portal-header></portal-header>

<div class="container-fluid mgn-top30 relative">
	<a [routerLink]="['/email-blast-tool/my-templates']" class="dark-text absolute topneg21 left15">
		<i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
		<span class="ft-size16">My Templates</span>
	</a>
	<div class="row mgn-top10">
		<div class="col-sm-12">
			<h1 class="relative">
				<i class="{{ createEditTemplateService.mode === 'new' ? 'fas fa-plus' : 'far fa-edit' }} mgn-right15 relative"></i>
				<span class="semi-bold">{{ createEditTemplateService.mode === 'new' ? 'Create' : 'Edit' }} Template</span>
			</h1>
		</div>
	</div>

	<div class="row green-limeGreen-bg-gradient ht2 mgn-top30 border-radius mgn-left0 mgn-right0"></div>

	<form (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSaveClick()" #form="ngForm" novalidate>
		<section *ngIf="createEditTemplateService.template" class="pd15 shadow border-radius white-bg mgn-top30">
			<div class="row">
				<div class="col-xs-12">
					<h2 class="semi-bold">{{ createEditTemplateService.mode === 'new' ? 'Create' : 'Edit' }}</h2>
				</div>
			</div>
			<div class="row mgn-top15">
				<div class="col-sm-4">
					<!--TEMPLATE NAME-->
					<div class="row">
						<div class="col-sm-12">
							<span>Name:</span>
							<i class="fas fa-asterisk ft-size7 red-text-light relative left5 bottom5"></i>
							<input
								required
								type="text"
								[ngClass]="{ 'red-border-light': !templateName.valid && !templateName.pristine }"
								name="templateName"
								#templateName="ngModel"
								[(ngModel)]="createEditTemplateService.template.Name"
							/>
							<small class="red-text-light" [hidden]="templateName.valid || (templateName.pristine && !form.submitted)">Name is required.</small>
						</div>
					</div>
				</div>
				<div class="col-sm-7 col-sm-offset-1">
					<div class="row mgn-bottom15">
						<div class="col-xs-2 relative">
							<span>Copy/Paste tags:</span>
							<i
								ngxTippy
								tippyName="{{ utilService.guid | wrapFn }}"
								data-tippy-content="Click the tag to copy, then paste into the editor."
								[tippyProps]="{ appendTo: 'parent' }"
								class="far fa-question-circle absolute topneg16 right15"
							></i>
						</div>
						<div class="col-xs-2">
							<button type="button" (click)="copyTagService.onTagClick('[[FIRSTNAME]]')" class="white-bg">FIRST NAME</button>
						</div>
						<div class="col-xs-2">
							<button type="button" (click)="copyTagService.onTagClick('[[LASTNAME]]')" class="white-bg">LAST NAME</button>
						</div>
						<div class="col-xs-2">
							<button type="button" (click)="copyTagService.onTagClick('[[CSRNAME]]')" class="white-bg">CSR NAME</button>
						</div>
						<div class="col-xs-2">
							<button type="button" (click)="copyTagService.onTagClick('[[SALESNAME]]')" class="white-bg">SALES NAME</button>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12 relative">
							<span>Template:</span>
							<i class="fas fa-asterisk ft-size7 red-text-light relative left5 bottom5"></i>
							<em class="absolute top34 right25 ft-size11 z">Press "Shift + Enter" to begin a new line.</em>
							<angular-editor
								name="template"
								#template="ngModel"
								required
								[config]="richTextService.editorConfig"
								[placeholder]="'Enter text here...'"
								[(ngModel)]="createEditTemplateService.template.HtmlTemplate"
							></angular-editor>
							<small class="red-text-light" [hidden]="template.valid || (template.pristine && !form.submitted)">Template is required.</small>
						</div>
					</div>
				</div>
			</div>
			<div class="row mgn-top30">
				<div class="col-sm-2 col-sm-offset-8">
					<button [routerLink]="['/email-blast-tool/my-templates']" class="gray-bg">Cancel</button>
				</div>
				<div class="col-sm-2">
					<button type="submit" [disabled]="saveBtnDisabled()" class="{{ saveBtnDisabled() ? 'setOpacityLow' : '' }} limeGreen-bg white-text">Save</button>
				</div>
			</div>
		</section>
	</form>
</div>
