<section class="mgn-top30">
	<ng-container *ngIf="showSortCategories">
		<div class="row mgn-bottom60 yellow-bg-light pd30">
			<div class="col-sm-10 relative">
				<div class="row">
					<div class="col-sm-4">
						<h4 class="semi-bold absolute topneg22">New Sort Categories</h4>
						<portal-dropdown-menu
							[backgroundColor]="'white-bg'"
							[height]="'ht40'"
							[key]="'Name'"
							[options]="[
								{ Id: 5, Name: 'NEW' },
								{ Id: 10, Name: 'Featured' },
								{ Id: 15, Name: 'Popular/Hot' },
								{ Id: 99, Name: 'No Feature' }
							]"
							[selectedOption]="featureCodeName()"
							(onOptionSelect)="onSortCategorySelect($event)"
						></portal-dropdown-menu>
					</div>
					<div class="col-sm-4">
						<span class="ft-size12 absolute topneg18">Start</span>
						<portal-date-time-selector
							[backgroundColor]="'white-bg'"
							[dateModel]="content.FeatureCodeStart"
							(dateChanged)="onFeatureCodeDateChanged($event, 'FeatureCodeStart')"
						></portal-date-time-selector>
					</div>
					<div class="col-sm-4">
						<span class="ft-size12 absolute topneg18">End</span>
						<portal-date-time-selector
							[backgroundColor]="'white-bg'"
							[dateModel]="content.FeatureCodeEnd"
							(dateChanged)="onFeatureCodeDateChanged($event, 'FeatureCodeEnd')"
						></portal-date-time-selector>
					</div>
				</div>
				<em class="absolute bottomneg26">Beta testing</em>
			</div>
		</div>

		<div class="row mgn-bottom30">
			<div class="col-sm-10">
				<!--**************************************
					MARK REQUEST AS FEATURED CONTENT
				***************************************-->
				<div class="row">
					<div class="col-xs-4">
						<i
							ngxTippy
							tippyName="{{ utilService.guid | wrapFn }}"
							data-tippy-content="Allows this content to appear on the featured content page. Clients are emailed a link to this page monthly."
							[tippyProps]="{ appendTo: 'parent', maxWidth: 175 }"
							class="far fa-question-circle relative topneg15 leftneg6"
						></i>
						<h4 class="semi-bold in-block mgn-right8 pointer" (click)="onMarkAsFeaturedClick()">Mark As Featured</h4>
						<div class="in-block absolute">
							<portal-checkbox
								[backgroundColor]="'white-bg'"
								[isChecked]="content.IsFeatured"
								[type]="'checkbox'"
								(checkboxClickNotify)="onMarkAsFeaturedClick()"
							></portal-checkbox>
						</div>
						<br />
						<br />
						<div class="ft-size12">
							<em>Last updated by UserId:</em>
							<span>{{ content.UpdatedByUserId }}</span>
							<br />
							<em>Date:</em>
							<span>{{ dateTimeService.dateAndTimeUTCtoLocal(content.UpdateDate) }}</span>
						</div>
					</div>
					<div class="col-sm-4 relative">
						<span [ngStyle]="{ opacity: content.IsFeatured ? 1 : 0.4 }" class="ft-size12 absolute topneg18">Start</span>
						<portal-date-time-selector
							[backgroundColor]="'white-bg'"
							[dateModel]="content.FeatureStartDate"
							[disabled]="!content.IsFeatured"
							(dateChanged)="onFeatureDateChanged($event, 'FeatureStartDate')"
						></portal-date-time-selector>
					</div>
					<div class="col-sm-4 relative">
						<span [ngStyle]="{ opacity: content.IsFeatured ? 1 : 0.4 }" class="ft-size12 absolute topneg18">End</span>
						<portal-date-time-selector
							[backgroundColor]="'white-bg'"
							[dateModel]="content.FeatureEndDate"
							[disabled]="!content.IsFeatured"
							(dateChanged)="onFeatureDateChanged($event, 'FeatureEndDate')"
						></portal-date-time-selector>
					</div>
				</div>
			</div>
		</div>
		<hr />
	</ng-container>

	<div class="row">
		<div class="col-lg-12">
			<h4 *ngIf="content.breadcrumbs?.length" class="ft-size11 in-block">
				<span>
					{{ content.breadcrumbs[0] }} > {{ content.breadcrumbs[1] }}
					<span *ngIf="content.breadcrumbs[2]">></span>
					{{ content.breadcrumbs[2] }}
				</span>
			</h4>
		</div>
	</div>

	<!--********************
		CONTENT TITLE
	*********************-->
	<div *ngIf="appStateService.activeFeatureArea === featureArea.CONTENT_MANAGER" class="row mgn-bottom15">
		<div class="col-xs-9">
			<span class="semi-bold">Content Title</span>
			<br />
			<input *ngIf="content.writeAccess" class="gray-bg-dark" type="text" [(ngModel)]="content.Title" (keyup)="content.isDirty = true" />

			<h3 *ngIf="!content.writeAccess" class="">
				<em>{{ content.Title }}</em>
			</h3>
		</div>
	</div>

	<!--********************
		CONTENT SCRIPT
	*********************-->
	<div class="row mgn-top15 mgn-bottom30">
		<div class="col-xs-9">
			<span class="semi-bold">Content Script</span>
			<br />
			<ng-container [ngSwitch]="true">
				<textarea
					*ngSwitchCase="content.writeAccess && appStateService.activeFeatureArea === featureArea.CONTENT_MANAGER && securityService.userIsEmployee()"
					rows="5"
					class="ft-size14 gray-bg-dark scrollable setWidth100"
					(keyup)="content.isDirty = true"
					[(ngModel)]="content.Text"
				></textarea>
				<div *ngSwitchDefault style="border: 1px solid #b5b5b5; max-height: 162px" class="lt-gray-bg scrollable pd15 border-radius">
					<p>{{ content.Text }}</p>
				</div>
			</ng-container>
		</div>
	</div>

	<!--***********************************************
		HOLD CONTENT MANAGER ONLY CONTENT FILES
	************************************************-->
	<div *ngIf="appStateService.activeFeatureArea === featureArea.CONTENT_MANAGER && appStateService.product.Route === productRoute.HOLD">
		<div class="row">
			<div class="col-xs-9">
				<div class="row">
					<div class="col-xs-4">
						<span class="semi-bold">Content File Versions</span>
					</div>
					<div class="col-xs-4">
						<span class="semi-bold mgn-right5">Update Gender Label</span>
						<span
							ngxTippy
							tippyName="{{ utilService.guid | wrapFn }}"
							data-tippy-content="This setting only changes how the file is labeled, useful if labeled incorrectly."
							[tippyProps]="{ appendTo: 'parent', maxWidth: 175 }"
							class="relative"
						>
							<i class="far fa-question-circle" aria-hidden="true"></i>
						</span>
					</div>
					<div class="col-xs-4">
						<span class="semi-bold mgn-right5">Upload File</span>
						<span
							ngxTippy
							tippyName="{{ utilService.guid | wrapFn }}"
							data-tippy-content="Upload additional versions for this content."
							[tippyProps]="{ appendTo: 'parent', maxWidth: 175 }"
							class="relative"
						>
							<i class="far fa-question-circle" aria-hidden="true"></i>
						</span>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-sm-9">
				<div class="row">
					<div class="col-sm-8">
						<d-content-file
							*ngFor="let contentFile of content.contentFiles; let i = index"
							[content]="content"
							[contentFile]="contentFile"
							[index]="i"
						></d-content-file>
					</div>
					<div class="col-sm-4">
						<portal-file-upload-drop-target
							[content]="content"
							[activeUploadSequence]="'uploadCounterpartSequence'"
							[counterpartOrReplace]="'counterpart'"
							[counterpartProps]="{ contentId: content.Id, targetLibraryId: content.LibraryId }"
							[dropTargetHeight]="'ht80'"
							[fontSize]="'ft-size18'"
							[paddingTop]="'pd-top12'"
							[textColor]="'dark-text'"
						></portal-file-upload-drop-target>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="appStateService.activeFeatureArea === featureArea.CONTENT_MANAGER" class="row mgn-top60 mgn-bottom30">
		<div class="col-xs-3">
			<button (click)="content.detailsVisible = false" class="gray-bg">Cancel</button>
		</div>
		<div *ngIf="detailsService.atLeastOneItemDirty(content) && appStateService.product.Route === productRoute.HOLD" class="col-xs-3">
			<div class="yellow-bg border-radius setWidth100 center pd-top5 ht30">
				<i class="fa fa-exclamation-triangle mgn-right5" aria-hidden="true"></i>
				<em>Unsaved changes pending!</em>
			</div>
		</div>
		<div *ngIf="appStateService.product.Route !== productRoute.HOLD" class="col-xs-3 text-right relative">
			<div *ngIf="detailsService.atLeastOneItemDirty(content)" style="top: -3.125rem" class="absolute center right15">
				<i class="fa fa-exclamation-triangle mgn-right5" aria-hidden="true"></i>
				<em>Unsaved changes pending!</em>
			</div>

			<div *ngIf="appStateService.currentUser.IsEmployee && securityService.contentManagerCsrOrAdmin() && !detailsService.disableReplaceFile(content)">
				<button
					(click)="fileUploadStateService.uploaderInit()"
					[disabled]="content.contentFiles?.length < 1"
					(click)="replaceFileInput.click()"
					class="{{ content.contentFiles?.length < 1 ? 'setOpacityLow' : null }} yellow-bg setWidth50"
				>
					Replace file
				</button>
				<span
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					data-tippy-content="Must replace file with same type. Cannot replace an image with a video and vice versa."
					[tippyProps]="{ appendTo: 'parent', maxWidth: 175 }"
					class="absolute top0 rightneg4"
				>
					<i class="far fa-question-circle" aria-hidden="true"></i>
				</span>
			</div>
		</div>
	</div>
</section>
<input
	#replaceFileInput
	name="file"
	type="file"
	onclick="value = null"
	ng2FileSelect
	[uploader]="fileUploadStateService.uploader"
	class="hidden"
	(change)="onReplaceInputClick()"
/>
