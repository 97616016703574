<c-hold-view *ngIf="createEditRequestService.request.RequestType === 'On-Hold Message'" #holdView
			[clip]="clip"
			[clipIndex]="index">
</c-hold-view>

<c-video-view *ngIf="createEditRequestService.request.RequestType !== 'On-Hold Message'" #videoView
			[clip]="clip"
			[index]="index">
</c-video-view>



