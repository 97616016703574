// import { Feeds } from './feeds';
// import { PlayerStatus } from '../delivery';

export class Tickers {
	Id: number;
	TickerTemplateId: number;
	ClientId: number;
	Name: string;
	ShowClock: boolean;
	ShowWeather: boolean;
	TickerSize: number;
	TickerSpeed: number;

	editBtn: string;
	classList: string;
	templateImage: string;
	activePlayersNames: string;
	activeFeedsNames: string;
	sizeView: string;
	speedView: string;
	name: string;
}