import { Content } from 'src/app/shared/api-models/content';
import { ContentFilesVM } from './content-files-view';

export class ContentVM extends Content {
	breadcrumbs?: string[];
	contentType?: string;
	contentFiles?: ContentFilesVM[];
	favoriteBtnTooltip?: string;
	isFavorite?: boolean;
	isSelected?: boolean;
	previewUrl?: string;
	detailsVisible?: boolean;
	talentPickerVisible?: boolean;
	talentPreviewSelectorVisible?: boolean;
	processingStateJson?: string;
	isGlobal?: boolean;
	writeAccess?: boolean;
	isDirty?: boolean;
	order?: number;
	startDate?: string;
	stopDate?: string;
	displayedFeatureCodeId?: number;
	isDisabled?: boolean;
	thumbIcon?: string;
	thumbnailUrl?: string;
	create24BlankBgColor?: string;
	contentTypeId?: number;
}
