<div *ngIf="allCreateLibraryFieldsVisible" class="row mgn-bottom15">
	<div style="z-index: 200" class="col-sm-2 relative">
		<portal-dropdown-menu
			[backgroundColor]="'gray-bg-dark'"
			[height]="'ht40'"
			[key]="'ProductName'"
			[options]="productTypes"
			[scrollbarHidden]="true"
			[selectedOption]="'Select a product type...'"
			(onOptionSelect)="onDropdownSelect($event, 'ProductTypeId')"
		></portal-dropdown-menu>
	</div>
	<div style="z-index: 200" class="col-sm-2 relative">
		<portal-dropdown-menu
			*ngIf="libraryTypesVisible"
			[backgroundColor]="'gray-bg-dark'"
			[height]="'ht40'"
			[key]="'name'"
			[options]="libraryTypes"
			[scrollbarHidden]="true"
			[selectedOption]="selectedLibraryType"
			(onOptionSelect)="onDropdownSelect($event, 'ContentLibraryTypeId')"
		></portal-dropdown-menu>
	</div>
	<div class="col-sm-2">
		<input
			style="border: 0.125rem solid #d6d9db"
			class="setWidth100 ht40 gray-bg-dark"
			placeholder="Name your library..."
			[(ngModel)]="newLibrary.LibraryName"
		/>
	</div>
	<div class="col-sm-2">
		<button
			[disabled]="!allFieldsComplete() || saveInProgress"
			type="button"
			class="{{ !allFieldsComplete() || saveInProgress ? 'setOpacityLow' : null }} limeGreen-bg white-text"
			(click)="onCreateLibraryClick()"
		>
			<i class="fa fa-plus mgn-right15" aria-hidden="true"></i>
			Create new library
		</button>
	</div>
	<div class="col-sm-2 col-sm-offset-2 relative">
		<i
			ngxTippy
			tippyName="{{ utilService.guid | wrapFn }}"
			data-tippy-content="Will create default libraries only once, no matter how many times this button is clicked."
			[tippyProps]="{ appendTo: 'parent' }"
			class="far fa-question-circle absolute topneg20"
		></i>
		<button (click)="onCreateDefaultLibrariesClick()" class="white-bg">Create default libraries</button>
	</div>
</div>

<div class="mgn-bottom60">
	<portal-drag-drop-left-right
		[height]="27"
		[hideAddRemoveAllBtns]="true"
		[leftHeading]="'Available Libraries'"
		[rightHeading]="'Assigned Libraries'"
	></portal-drag-drop-left-right>
</div>
