import { inject } from '@angular/core';
import { Router, CanActivateFn, ActivatedRouteSnapshot } from '@angular/router';

import { AppStateService } from '../app-state.service';

export const isEmployeeGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
	const appStateService = inject(AppStateService);
	const router = inject(Router);

	if (appStateService.currentUser.IsEmployee) {
		return true;
	} else {
		router.navigate(['/access-denied']);
		return false;
	}
};
