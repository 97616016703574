import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { BatchChangeCategoryService } from '../../../../content-browser/_services';
import { ContentViewService } from '../../_services';
import { AppStateService, Events, MessageService, SecurityService, UtilitiesService } from '../../../../../../core/services';
import { ContentVM } from '../../_models/content-view';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';

@Component({
	selector: 'vc-video-cm-list-view',
	templateUrl: './video-cm-list-view.component.html',
	styleUrls: ['./video-cm-list-view.component.scss']
})
//Video List Content Manager View
export class VideoCmListViewComponent implements OnInit {
	@Input() content: ContentVM;
	@Input() isEvenIndex: boolean;
	@Input() isFirstIndex: boolean;
	@Input() isLastIndex: boolean;

	public fileNameTooltipVisible: boolean;
	public thumbVisible: boolean = true;
	readonly enumUserRole = UserRole;

	private subs: Subscription[] = [];

	constructor(
		public appStateService: AppStateService,
		public batchChangeCategoryService: BatchChangeCategoryService,
		public contentViewService: ContentViewService,
		private messageService: MessageService,
		public securityService: SecurityService,
		public utilService: UtilitiesService
	) {
		this.uploadProgressSubscribe();
	}

	ngOnInit() {}

	//Update thumbnail after uploading
	uploadProgressSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.uploadComplete, (payload: any[]) => {
				this.thumbVisible = false;
				setTimeout(() => {
					this.thumbVisible = true;
				}, 5000);
			})
		);
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
