export class PlaylistExamplesContentFileBridge {
    Id: number;
    PlaylistExampleId: number;
    ContentFileId: number;
    Order: number;
    IsMusicTrack: boolean;
    SilenceMusic: boolean;
    StillDuration: number;
    StartDate: string;
    StopDate: string;
    ChannelContentId: number;
    ContentAdvancedScheduleId: number;
    FeedId: number;
}