import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { ProductCard } from '../view-models/product-card';

@Component({
    selector: 'db-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit {

    @Input() card: ProductCard;

    public alertBoxVisible: boolean;
    public moreOptionsVisible: boolean;
    public productLinkUrl: string;

    constructor(
        private router: Router) { }

    ngOnInit() {

    }


    public onProductButtonClick(): void {
        this.router.navigate([this.card.button.routerUrl]);
    }
}
