import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppStateService, DateTimeService } from 'src/app/core/services';
import { ClientHistoryNotes } from 'src/app/shared/api-models/log';
import { CreateEditPlayerService } from './create-edit-player.service';
import { environment } from '../../../../environments/environment';
import { ViewService } from './view.service';

@Injectable({
    providedIn: 'root'
})
export class HistoryNoteService {

    constructor(
        private appStateService: AppStateService,
        private createEditPlayerService: CreateEditPlayerService,
        private dateTimeService: DateTimeService,
        private httpClient: HttpClient,
        private viewService: ViewService
    ) { }

    public postHistoryNote(): Observable<any> {
        return this.httpClient.post(`${environment.adminUrl}ClientHistoryNotes`, this.historyNote())
    }

    private historyNote(): ClientHistoryNotes {
        let player = this.createEditPlayerService.player;
        let historyNoteApi: ClientHistoryNotes = new ClientHistoryNotes();

        historyNoteApi.ClientId = this.appStateService.currentClient.Id;
        historyNoteApi.ServiceLocationId = player.LocationId;
        historyNoteApi.UserId = this.appStateService.currentUser.UserId;
        historyNoteApi.PlayerId = player.Id;
        historyNoteApi.UnitIdentifier = player.UnitIdentifier;
        historyNoteApi.LogDate = this.dateTimeService.todayWithCurrentTimeUTC();
        historyNoteApi.EntrySource = 'User Portal';
        historyNoteApi.EntryType = 'Other';
        historyNoteApi.LogSubject = `Repair State Changed`;
        historyNoteApi.LogMessage = `Location: ${player.Location?.Name} \nPlayer Name: ${player.PlayerName} \n` + 
        `Player Model: ${player.PlayerModel?.Name} \nRepair state changed from: ${player.RepairState.Name} \n` + 
        `Repair state changed to: ${this.viewService.updatedRepairStateName} \nUpdated By: ${this.appStateService.currentUser.UserName}` 

        return historyNoteApi;
    }
}
