import { ElementRef, Injectable } from '@angular/core';

import { Events, MessageService } from '../../../core/services';

declare let google: any;

@Injectable({
    providedIn: 'root'
})
export class GoogleMapsTypeaheadService {

    public autocomplete: any;

    constructor(private messageService: MessageService) { }

    //Used MapsAPILoader in old project instead of setTimeout, but in Angular 9 
    //it gave a provider error. But when providing it, that also gave an error
    public autocompleteInit(searchField: ElementRef) {
        const autocomplete = new google.maps.places.Autocomplete(searchField.nativeElement,
            {
                componentRestrictions: { country: 'US' },
                types: ["geocode"]  // 'establishment' / 'address' / 'geocode'
            });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            this.messageService.publish(Events.onGMTypeaheadOptionSelect, place.formatted_address);
        });
    }
}
