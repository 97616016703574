import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppStateService } from 'src/app/core/services';
import { Create24Service } from 'src/app/user-tools/create-24/create-24.service';
import { FeatureArea } from 'src/app/shared/view-models';
import { UtilitiesService } from 'src/app/core/services';
import { Create24Area } from 'src/app/user-tools/create-24/_models/create-24-state.enum';

@Injectable({
	providedIn: 'root'
})
export class ContentBrowserService {
	public isPopup: boolean;

	constructor(private appStateService: AppStateService, private create24Service: Create24Service, private utilService: UtilitiesService) {}

	public classList(isPopup: boolean): string {
		if (isPopup) {
			return 'pd20 border-radius relative white-bg shadow-wide box';
		}
	}

	public isCreate24(): boolean {
		return this.appStateService.activeFeatureArea === FeatureArea.CREATE_24;
	}

	public playlistInProgress(url): boolean {
		return this.utilService.includes(url, 'playlist-in-progress');
	}

	public hideUploader(): boolean {
		const { c24ActiveArea } = this.create24Service;
		switch (c24ActiveArea) {
			case Create24Area.EDIT_PROJECT:
			case Create24Area.VIDEO_TEMPLATE:
			case Create24Area.FEED_ITEM:
				return true;

			default:
				return false;
		}
	}

	public uploadPercentComplete(event): number {
		return Math.round((100 * event.loaded) / event.total);
	}

	public uploadFileType(): 'video' | 'image' | null {
		const { c24ActiveArea } = this.create24Service;
		switch (true) {
			case this.utilService.includes(c24ActiveArea, 'image'):
			case this.utilService.includes(c24ActiveArea, 'sidebar'):
				return 'image';
			case this.utilService.includes(c24ActiveArea, 'video'):
				return 'video';
			default:
				return null;
		}
	}
}
