export class ViewState {
    allCsrs: boolean;
    showPast: boolean;
    showTodayAndFuture: boolean;
    completedOnly: boolean;
    dateRange: boolean;
    dateRangeStart: string;
    dateRangeEnd: string;
    pageNumber: number;
    orderDesc: boolean;
    orderByCol: string;
    searchTerm: string;
    route: 'my-tasks' | 'by-client';

    constructor(pageNumber: number, orderByCol: string, searchTerm: string, route: 'my-tasks' | 'by-client') {
        this.pageNumber = pageNumber;
        this.orderByCol = orderByCol;
        this.searchTerm = searchTerm;
        this.route = route;
        this.completedOnly = false;
        this.orderDesc = false;
    }
}