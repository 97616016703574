<div class="row">
	<div class="col-xs-6 pd-right0">
		<button (mouseup)="toggleClickNotify.emit('off')" class="toggle-button-left {{isOn ? 'lt-gray-bg-darker' : 'gray-bg-darker'}} {{fontSize}}">
			<span class="">Off</span>
		</button>
	</div>
	<div class="col-xs-6 pd-left0">
		<button (mouseup)="toggleClickNotify.emit('on')" class="toggle-button-right {{isOn ? 'limeGreen-bg white-text' : 'lt-gray-bg-darker dark-text'}} {{fontSize}}">
			<span class="">On</span>
		</button>
	</div>
</div>