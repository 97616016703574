import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Events } from '../../core/services/events';
import { MessageService } from '../../core/services/message.service';
import { Playlists } from '../../shared/api-models/content';

@Injectable()
export class BatchDeleteService {
	public selectAllText: string;

	private selectAllChecked: boolean;

	constructor(private httpClient: HttpClient, private messageService: MessageService) {
		this.selectAllText = 'Select All';
	}

	public atLeastOneSelected(itemArr: any[]): boolean {
		if (itemArr) {
			return itemArr.some((item) => {
				return item.isSelected;
			});
		}
	}

	public deleteSelectedClick(itemArr: any[], itemApiStringIdentifier: string, urlParam: string): void {
		if (this.atLeastOneSelected(itemArr)) {
			const confirmDelete: boolean = confirm(`Delete all selected ${itemApiStringIdentifier}? You can undelete them later if necessary.`);
			if (confirmDelete) {
				this.selectAllChecked = false;

				//start saving indicator
				this.messageService.publish(Events.savingPreloader, 1);

				//delete each checked item
				itemArr.forEach((item) => {
					if (item.isSelected) {
						this.itemApi(itemApiStringIdentifier).IsDeleted = true;
						this.patchData(urlParam, item, itemApiStringIdentifier);
					}
				});
				// this.batchChangeCategoryService.atLeastOneContentItemSelected = false;
				//stop saving indicator
				this.messageService.publish(Events.savingPreloader, 0);
			}
		} else {
			alert('Please select one or more items to delete.');
		}
	}

	//see if every checkbox is checked
	public isAllChecked(itemArr: any[]): boolean {
		//data isn't available onInit, but is immediately after
		if (itemArr) {
			return itemArr.every((item) => item.isSelected);
		}
	}

	//check all checkboxes
	public selectAll(itemArr: any): void {
		if (itemArr) {
			this.selectAllChecked = !this.selectAllChecked;
			itemArr.forEach((x) => (x.isSelected = this.selectAllChecked));
			if (this.selectAllChecked) {
				this.selectAllText = 'Deselect All';
			} else {
				this.selectAllText = 'Select All';
			}
		}
	}

	private itemApi(itemApiStringIdentifier: string): any {
		let itemApi: any;
		switch (true) {
			case itemApiStringIdentifier === 'content':
				// itemApi = new ContentApi();
				//   	itemApi.IsDeleted = true;
				break;
			case itemApiStringIdentifier === 'playlists':
				itemApi = new Playlists();
				itemApi.IsDeleted = true;
				break;
		}
		return itemApi;
	}

	private itemId(item: any): number {
		if (item.contentId) {
			item.Id = item.contentId;
		}
		return item.Id;
	}

	private patchData(urlParam: string, item: any, itemApiStringIdentifier: string): void {
		this.httpClient.patch(environment.contentUrl + urlParam + this.itemId(item), this.itemApi(itemApiStringIdentifier)).subscribe(() => {
			item.isSelected = false;
			const payload: [any, boolean] = [item, true];
			//remove items from view
			this.messageService.publish(Events.removeItems, payload);
		});
	}
}
