import { ContentLibraries } from '../../../api-models/content';
import { CategoriesTree } from './categories-tree';

export class LibrariesTree extends ContentLibraries {
    isDroppable: boolean;
    isLibrary: boolean;
    isOrContainsFavorites: boolean;
    containsFavorites: boolean;
    categories: CategoriesTree[];
    isMusicLibrary: boolean;
    isExpanded: boolean;
    name: string;
    isSelected: boolean;
    isGlobal: boolean;
    writeAccess: boolean;
    state: 'read' | 'edit' | 'new';
}