<div class="row border-radius {{ !isLastIndex ? 'border-first-index' : 'border-last-index border-first-index mgn-bottom1' }} white-bg pd-top15 pd-bottom15">
	<div class="col-md-9">
		<div class="row">
			<!--***************************
			     SCRIPT BUTTON
			****************************-->
			<div *ngIf="content.ContentTypeId !== 1" class="col-md-2 ft-size16">
				<button class="z gray-bg noExit" (mouseup)="content.detailsVisible = !content.detailsVisible">
					<i class="{{ content.detailsVisible ? 'fas fa-times' : 'far fa-file-alt' }} icon-hidden mgn-right10" aria-hidden="true"></i>
					{{ content.detailsVisible ? 'Close' : 'Script' }}
				</button>
			</div>

			<!--***************************
				PREVIEW BUTTON
			****************************-->
			<div class="col-md-2 relative">
				<button
					*ngIf="content.ContentTypeId !== 1 && cvStateService.viewState.treeSelection !== 'featured-playlist'"
					(click)="onPlayClick(content, isLastIndex, 'talentPreviewSelectorVisible')"
					class="noExit white-text blue-bg"
				>
					<i class="fa {{ content.talentPreviewSelectorVisible ? 'fa-times' : 'fas fa-play' }} noExit white-text mgn-right10" aria-hidden="true"></i>
					{{ content.talentPreviewSelectorVisible ? 'Close' : 'Play' }}
				</button>
				<button
					*ngIf="content.ContentTypeId !== 1 && cvStateService.viewState.treeSelection === 'featured-playlist'"
					(click)="onPlayClick(content, isLastIndex, 'talentPreviewSelectorVisible')"
					class="noExit {{ content.contentFiles[0].previewActive ? 'yellow-bg dark-text' : 'blue-bg white-text' }}"
				>
					<i
						class="{{ content.contentFiles[0].previewActive ? 'fas fa-times dark-text' : 'fas fa-play white-text' }} noExit mgn-right10"
						aria-hidden="true"
					></i>
					Play
				</button>

				<button
					*ngIf="content.ContentTypeId === 1 && content.contentFiles?.length > 0"
					(click)="onPlayClick(content, null, null)"
					class="noExit {{ content.contentFiles[0].previewActive ? 'yellow-bg dark-text' : 'blue-bg white-text' }}"
				>
					<i class="fa {{ content.contentFiles[0].previewActive ? 'fa-times' : 'fas fa-play' }} noExit mgn-right6" aria-hidden="true"></i>
					{{ content.contentFiles[0].previewActive ? 'Stop' : 'Play' }}
				</button>

				<div *ngIf="content.talentPreviewSelectorVisible" style="left: 96%; z-index: 100" class="absolute top0 mgn-bottom30">
					<portal-select-box
						[content]="content"
						[title]="'Select a Preview Version'"
						[width]="'wdth226'"
						(onSelectNotify)="onSelectVoiceTalentClick($event, content, 'talentPreviewSelectorVisible')"
					></portal-select-box>
				</div>
			</div>

			<!--***************************
				CONTENT TITLE
			****************************-->
			<div class="{{ content.ContentTypeId === 1 ? 'col-md-10' : 'col-md-8' }} ft-size16 relative">
				<i
					*ngIf="!!contentViewService.featuredContentProps(content)"
					class="{{ contentViewService.featuredContentProps(content).icon }} {{ contentViewService.featuredContentProps(content).color }} mgn-right10"
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					[tippyProps]="{ appendTo: 'parent' }"
					attr.data-tippy-content="{{ contentViewService.featuredContentProps(content).tooltip }}"
				></i>
				<span>{{ content.Title }}</span>
			</div>
		</div>
	</div>
	<div class="col-md-3">
		<div class="row">
			<!--***************************
				FAVORITE BUTTON
			****************************-->
			<div class="col-md-4">
				<button
					*ngIf="cvStateService.viewState.treeSelection !== 'featured-playlist'"
					class="{{ content.isFavorite ? 'pink-bg' : 'white-bg' }} white-text relative"
					(click)="contentFavoritesService.onFavoriteClick(content)"
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					[tippyProps]="{ appendTo: 'parent' }"
					attr.data-tippy-content="{{ content.favoriteBtnTooltip }}"
				>
					<i class="fa fa-heart {{ content.isFavorite ? 'white-text' : 'pink-text' }}" aria-hidden="true"></i>
					<i *ngIf="content.isFavorite" class="fa fa-check absolute ft-size10 top2 right2" aria-hidden="true"></i>
				</button>
			</div>

			<!--***************************
				ADD MESSAGE BUTTON
			****************************-->
			<div class="col-md-8 relative">
				<button
					*ngIf="cvStateService.viewState.treeSelection === 'featured-playlist' && !playerCapacityService.disableSave && content.ContentTypeId !== 1"
					(click)="addToPlaylistService.onAddClick(content, content.contentFiles[0])"
					class="ft-size10 gray-bg"
				>
					Add just this message
					<i class="fas fa-angle-right mgn-left6"></i>
				</button>
				<button
					*ngIf="cvStateService.viewState.treeSelection === 'featured-playlist' && playerCapacityService.disableSave && content.ContentTypeId !== 1"
					class="ft-size10 gray-bg cursor-not-allowed setOpacityLow"
				>
					Add just this message
					<i class="fas fa-angle-right mgn-left6"></i>
				</button>

				<button
					*ngIf="cvStateService.viewState.treeSelection !== 'featured-playlist' && content.contentFiles?.length > 0 && !playerCapacityService.disableSave"
					(click)="contentViewService.onAddMessageClick(content, isLastIndex, 'talentPickerVisible')"
					class="noExit gray-bg"
				>
					{{ content.talentPickerVisible ? 'Close' : 'Add' }}
					<i
						*ngIf="!content.talentPickerVisible"
						class="fa {{ content.contentFiles?.length > 1 ? 'fa-angle-down' : 'fa-angle-right' }} mgn-left6"
						aria-hidden="true"
					></i>
					<i *ngIf="content.talentPickerVisible" class="fa fa-times mgn-left8" aria-hidden="true"></i>
				</button>
				<button
					*ngIf="cvStateService.viewState.treeSelection === 'featured-playlist' && wizardService.currentStep().name === 'choose-music'"
					(click)="contentViewService.onAddMessageClick(content, isLastIndex, 'talentPickerVisible')"
					class="gray-bg noExit"
				>
					Add
					<i class="fa fa-angle-right mgn-left6" aria-hidden="true"></i>
				</button>
				<button
					*ngIf="cvStateService.viewState.treeSelection !== 'featured-playlist' && content.contentFiles?.length > 0 && playerCapacityService.disableSave"
					class="gray-bg setOpacityLow"
				>
					Add
				</button>
				<button *ngIf="content.contentFiles?.length < 1" class="gray-bg-darker cursor-not-allowed">Unavailable</button>
				<div *ngIf="content.talentPickerVisible" class="absolute right15 top40 z mgn-bottom30">
					<portal-select-box
						[content]="content"
						[title]="'Select a Voice Talent'"
						[width]="'wdth226'"
						(onSelectNotify)="contentViewService.onSelectVoiceTalentClick($event, content, 'talentPickerVisible')"
					></portal-select-box>
				</div>
			</div>
		</div>
	</div>

	<!--***************************
		SCRIPT
	****************************-->
	<div *ngIf="content.detailsVisible" class="col-lg-12 mgn-bottom15">
		<c-details [content]="content"></c-details>
	</div>
</div>
