import { Component, EventEmitter, ElementRef, Output, Input, OnInit } from '@angular/core';

@Component({
	selector: 'c-video-controls',
	templateUrl: './video-controls.component.html',
	styleUrls: ['./video-controls.component.scss']
})
export class VideoControlsComponent implements OnInit {
	@Input() classList: string;
	@Input() currentTimeView: string;
	@Input() duration: number | string;
	@Input() video: ElementRef;

	@Output() onVideoControlClick = new EventEmitter<string>();

	get durationSet() {
		return this.duration !== 'NaN:NaN';
	}

	constructor() {}

	ngOnInit(): void {}
}
