import { Injectable } from '@angular/core';

import { EmployeeTicketCount } from '../_models';
import { Table } from '../../../shared/components/table/view-models';

@Injectable({
	providedIn: 'root'
})
export class ViewStateService {
	public colorKeyVisible: boolean;
	public currentUserDisplayName: string;
	public employeeTicketCountList: EmployeeTicketCount[];
	public mainTableTotalItems: number;
	public orderByCol: string = 'ReminderTime';
	public orderDesc: boolean = false;
	public overdueTicketCount: number;
	public pageNumber: number = 1;
	public pageTitle: string;
	public postponedVisible: boolean = false;
	public searchTerm: string = '';
	public selectedEmployee: EmployeeTicketCount;
	public spinnerActive: boolean;
	public table: Table;
	public includeClosed: boolean = false;
	public clientId: number = 0;

	constructor() {}

	public pageTitleView(): string {
		switch (this.pageTitle) {
			case 'Open Tickets':
			case 'Overdue Tickets':
			case 'All Tickets':
			case 'Manage Installers':
				return this.pageTitle;
			default:
				return 'My Tickets';
		}
	}

	public reset(): void {
		this.colorKeyVisible = false;
		this.currentUserDisplayName = '';
		this.employeeTicketCountList;
		this.mainTableTotalItems = 0;
		this.orderByCol = 'ReminderTime';
		this.orderDesc = false;
		this.overdueTicketCount = 0;
		this.pageNumber = 1;
		this.pageTitle = '';
		this.postponedVisible = false;
		this.searchTerm = '';
		this.selectedEmployee = null;
		this.spinnerActive = false;
		this.table = null;
		this.includeClosed = false;
		this.clientId = 0;
	}
}
