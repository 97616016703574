import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppStateService, DateTimeService } from '../../../../core/services';
import { CreateEditTicketService } from './create-edit-ticket.service';
import { environment } from '../../../../../environments/environment';
import { TicketsView } from '../_models';
import { WorkOrdersView } from '../_models';
import { Notes } from '../../_models';

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    constructor(
        private appStateService: AppStateService,
        private ceTicketService: CreateEditTicketService,
        private dateTimeService: DateTimeService,
        private httpClient: HttpClient
    ) { }

    public sendNotificationEmail(str: 'statusUpdate' | 'historyNote', subject?: string, note?: Notes, previousStatus?: string): void {
        let logSubject = subject ? subject : note.LogSubject;
        //Recipients are "assigned to" user, CSR, and all email addresses in selectedTicket.notifyListArr. Filter nulls
        let recipientsArr = this.statusUpdateOrHistoryNoteRecipients().filter(r => r);

        //If "assigned to" user is one making changes, do not send email to them.
        if (this.ceTicketService.ticket.AssignedToUserId === this.appStateService.currentUser.UserId) {
            recipientsArr.splice(0, 1);
        }

        recipientsArr.forEach((recipient) => {
            this.sendEmail(this.statusUpdateOrHistoryNoteEmail(logSubject, recipient, this.notificationEmailBody(str, note, previousStatus)));
        })
    }

    private notificationEmailBody(str: 'statusUpdate' | 'historyNote', note: Notes, previousStatus?: string): string {
        switch (str) {
            case 'statusUpdate':
                return `Client: ${this.ceTicketService.ticket.client.Name} \n \n` +
                `Logged-in user: ${this.appStateService.currentUser.UserName} \n \n \n` + 
                `From: ${previousStatus} \n \n To: ${this.ceTicketService.ticket.status} \n \n \n` +
                `${environment.apiUrl}ticket-system/edit-ticket/${this.ceTicketService.ticket.Id}`;
        
            case 'historyNote':
                return `Logged-in user: ${this.appStateService.currentUser.UserName} \n \n \n` +
                `Client: ${this.ceTicketService.ticket.client.Name} \n \n` +  
                `Location: ${note.locationName ? note.locationName : 'No location selected.'} \n \n` +
                `Comment: ${note.LogMessage} \n \n \n ${environment.apiUrl}ticket-system/edit-ticket/${this.ceTicketService.ticket.Id}`;
        }
    }

    public newTicketEmail(savedTicket: TicketsView): any {
        return {
            from: 'newticket@works24.com',
            recipient: this.ceTicketService.ticket.csrEmail,
            cc: this.ceTicketService.ticket.activeCstEmailList,
            subject: 'New Ticket',
            body: `${this.appStateService.currentUser.UserName} created a new ticket on ${this.dateTimeService.dateAndTimeUTCtoLocal(this.ceTicketService.ticket.LastUpdate)}: \n \n` +
                `Client: ${this.ceTicketService.ticket.client.Name} \n \n` +
                `Ticket Status: ${this.ceTicketService.ticket.status} \n \n` +
                `Ticket Type: ${this.ceTicketService.ticket.ticketTypeName} \n \n` +
                `Summary: ${this.ceTicketService.ticket.Title} \n \n` +
                `Description: ${this.ceTicketService.ticket.Description} \n \n` +
                `${environment.apiUrl}/ticket-system/edit-ticket/${savedTicket.Id}`
        }
    }

    public statusUpdateOrHistoryNoteEmail(subject: string, recipient: string, emailBody: string): any {
        return {
            from: `ticketsystem@works24.com`,
            recipient: recipient,
            subject: subject,
            body: emailBody
        }
    }

    public workOrderCompleteEmail(workOrder: WorkOrdersView): any {
        return {
            from: 'workordercomplete@works24.com',
            recipient: this.ceTicketService.ticket.assignedToEmail,
            subject: `Work Order Completed for ${this.ceTicketService.ticket.client.Name}`,
            body: `${this.appStateService.currentUser.FirstName} ${this.appStateService.currentUser.LastName} has completed a work order ` +
            `for ${this.ceTicketService.ticket.client.Name}. \n \n \n Location: ${workOrder.LocationName} \n \n Completed Note: ` +
            `${workOrder.CompletedNote} \n \n Confirmation Number: ${workOrder.ConfirmationNumber} \n \n \n ` +
            `${environment.apiUrl}/ticket-system/edit-ticket/${this.ceTicketService.ticket.Id}`
        }
    }

    public assignedToUpdatedEmail(email: string): any {
        return {
            from: `ticketsystem@works24.com`,
            recipient: email,
            subject: `You've been assigned a ticket!`,
            body: `${this.appStateService.currentUser.UserName} has assigned this ticket to you: \n \n` + 
            `${environment.apiUrl}/ticket-system/edit-ticket/${this.ceTicketService.ticket.Id}`
        }
    }

    public statusUpdateOrHistoryNoteRecipients(): string[] {
        let ticket = this.ceTicketService.ticket;
        return [ticket.assignedToEmail, ticket.csrEmail].concat(ticket.notifyListArr);
    }

    public sendEmail(params: any): void {
        let obj = {
            To: params.recipient,
            Cc: params.cc,
            From: params.from,
            Subject: params.subject,
            Body: params.body
        }
        //send email
        this.httpClient.post(environment.adminUrl + 'Email', obj).subscribe();
    }
}
