import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { Players, PlayerGroups } from '../../api-models/admin';
import { SearchFilterPipe } from '../../../shared/pipes/search-filter.pipe';

@Injectable()
export class DragDropLeftRightService {


    //Observable sources
    private onAddAllClickSource = new Subject<any>();
    private onCheckboxClickSource = new Subject<any>();
    private onDropSource = new Subject<any[]>();
    private onRemoveAllClickSource = new Subject<any>();
    private onRemoveItemSource = new Subject<any>();
    private onToggleSource = new Subject<any>();

    //Observable streams
    public onAddAllClick$ = this.onAddAllClickSource.asObservable();
    public onCheckboxClick$ = this.onCheckboxClickSource.asObservable();
    public onDrop$ = this.onDropSource.asObservable();
    public onRemoveAllClick$ = this.onRemoveAllClickSource.asObservable();
    public onRemoveItem$ = this.onRemoveItemSource.asObservable();
    public onToggle$ = this.onToggleSource.asObservable();

    public activeAddRemoveAllBtn: 'addAll' | 'removeAll';
    public availableItems: any[] = []; //all available items, not affected by search filter
    public availableItemsView: any[] = []; //items actually displaying on screen
    public availableItemsSearchTerm: string = '';
    public assignedItems: any[] = []; //all assigned items, not affected by search filter
    public assignedItemsView: any[] = []; //items actually displaying on screen
    public assignedItemIndex: number;
    public assignedItemViewIndex: number;
    public assignedItemsSearchTerm: string = '';
    public includeAssignedItemsCheckbox: boolean;
    public percentComplete: number;
    public toggleBtnsVisible: boolean;
    public visibleItems?: 'players' | 'groups';

    constructor(private searchFilterPipe: SearchFilterPipe) { }

    onCheckboxClick(item: any): void {
        this.onCheckboxClickSource.next(item);
    }

    //Service message commands
    onDrop(payload: any[]) {
        const targetItem: any = payload[2];

        if (!targetItem.players) {
            this.assignedItems.push(targetItem);
        }
        //Filters out from all available items arrays the items that are assigned
        this.availableItemsView = this.availableItemsView.filter(player => !this.atLeastOneMatch(this.assignedItems, player, 'Id'));
        this.availableItems = this.availableItems.filter(player => !this.atLeastOneMatch(this.assignedItems, player, 'Id'));
        this.onDropSource.next(payload);
    }

    onRemoveItem(item: any) {
        this.assignedItemIndex = this.assignedItems.findIndex(assignedItem => assignedItem.Id === item.Id);
        this.assignedItemViewIndex = this.assignedItemsView.findIndex(assignedItemView => assignedItemView.Id === item.Id);

        //Adding items back to available view is handled in the individual component services.
        //Method below is specific to the Playlist Builder
        if (this.visibleItems === 'groups') {
            this.assignedItemsView.splice(this.assignedItemViewIndex, 1);
            this.assignedItems.splice(this.assignedItemIndex, 1);
        }
        this.onRemoveItemSource.next(item);
    }

    onToggleClick() {
        if (!this.visibleItems || this.visibleItems === 'groups') {
            this.visibleItems = 'players';
        } else {
            this.visibleItems = 'groups';
        }
        this.availableItemsSearchTerm = '';
        this.onToggleSource.next(this.visibleItems);
    }

    public onAddAllClick(): void {
        this.activeAddRemoveAllBtn = 'addAll';
        //loop over availableItemsView so when searching
        //only search results will be added
        if (this.visibleItems !== 'groups') {
            this.availableItemsView.forEach((availableItem) => {
                this.assignedItemsView.push(availableItem);
            })

            this.availableItemsView.forEach((availableItem) => {
                this.assignedItems.push(availableItem);
            })
            this.availableItems = this.availableItems.filter(availableItem => !this.atLeastOneMatch(this.assignedItems, availableItem, 'Id'));
        }
        this.onAvailableItemsSearchClear();
        this.onAssignedItemsSearchClear();
        this.onAddAllClickSource.next();
    }

    public onRemoveAllClick(): void {
        this.activeAddRemoveAllBtn = 'removeAll';
        //Loop over assignedItemsView so when searching
        //only search results will be removed

        if (this.visibleItems !== 'groups') {
            this.assignedItemsView.forEach((assignedItem) => {
                this.availableItemsView.push(assignedItem);
            })

            this.assignedItemsView.forEach((assignedItem) => {
                this.availableItems.push(assignedItem);
            })
            this.assignedItems = this.assignedItems.filter(assignedItem => !this.atLeastOneMatch(this.availableItems, assignedItem, 'Id'));
            this.onAvailableItemsSearchClear();
            this.onAssignedItemsSearchClear();
        }
        this.onRemoveAllClickSource.next();
    }

    public onAvailableItemsSearch(): void {
        this.availableItemsView = this.searchFilterPipe.transform(this.availableItems, this.availableItemsSearchTerm);
    }

    public onAvailableItemsSearchClear(): void {
        this.availableItemsSearchTerm = '';
        this.availableItemsView = this.searchFilterPipe.transform(this.availableItems, this.availableItemsSearchTerm);
    }

    public onAssignedItemsSearchClear(): void {
        this.assignedItemsSearchTerm = '';
        this.assignedItemsView = this.searchFilterPipe.transform(this.assignedItems, this.assignedItemsSearchTerm);
    }

    public onAssignedItemsSearch(): void {
        this.assignedItemsView = this.searchFilterPipe.transform(this.assignedItems, this.assignedItemsSearchTerm);
    }

    public atLeastOneMatch(assignedItems: any[], itemToCheck: any, prop: string): boolean {
        return assignedItems.some(assignedItem => assignedItem[prop] === itemToCheck[prop]);
    }

    public setPercentComplete(items: any, index: number): void {
        const percentComplete: number = Math.round(index / items.length * 100);
        if (percentComplete <= 100) {
            this.percentComplete = percentComplete;
            if (percentComplete === 100) {
                setTimeout(() => this.percentComplete = null, 2000);
            }
        }
    }
}
