<section
	dragula="layers"
	[(dragulaModel)]="layersService.layers"
	[ngStyle]="{ 'border-bottom': layersService.layers.length < 11 ? '1px solid #bbc0c3' : '' }"
	style="min-height: 368px; padding-bottom: 3px"
	class="in-block lt-gray-bg layers-wrapper relative"
>
	<ltw-layer *ngFor="let layer of layersService.layers" class="{{ layer.id > 0 ? 'draggable' : null }}" [layer]="layer"></ltw-layer>
</section>
<section style="top: -68px" class="in-block relative">
	<canvas id="canvas" width="965" height="421" style="background-color: transparent" class="mgn-bottom60"></canvas>
</section>
