export enum UserRole {
	USER = 'User',
	CONTENT_MANAGER = 'Content Manager',
	SUPERVISOR = 'Supervisor',
	VIDEO_CONTENT_CREATOR = 'Video Content Creator',
	VIDEO_CONTENT_CONTRACTOR = 'Video Content Contractor',
	SALESPERSON = 'Salesperson',
	CSR = 'CSR',
	ADMINISTRATOR = 'Administrator',
	VOICE_TALENT = 'Voice Talent',
	AUDIO_CONTENT_CREATOR = 'Audio Content Creator',
	SCRIPT_WRITER = 'Script Writer',
	CONTENT_CREATOR = 'contentcreator',
	TECHNICIAN = 'Technician'
}
