import { Injectable } from '@angular/core';

import { ChoosePlayersDataService } from './choose-players-data.service';
import { DragDropLeftRightService } from '../../shared/components/drag-drop-left-right/drag-drop-left-right.service';
import { PlayersDragDrop, PlayerGroupsDragDrop } from '../../shared/view-models/admin';

@Injectable()
export class ChoosePlayersDragDropService {


    constructor(
        private choosePlayersDataService: ChoosePlayersDataService,
        private dragDropLeftRightService: DragDropLeftRightService) {
    }

    

    public addGroupPlayersToAssignedItems(targetItem: PlayerGroupsDragDrop): void {
        //Filter out players that are already in the assigned list, push remaining players to assigned
        targetItem.players.filter(player => !this.dragDropLeftRightService.atLeastOneMatch(this.dragDropLeftRightService.assignedItems, player, 'Id'))
            .forEach(filteredPlayer => this.dragDropLeftRightService.assignedItems.push(filteredPlayer));
    }

    public addGroupPlayersToAssignedItemsView(targetItem: PlayerGroupsDragDrop): void {
        //Filter out players that are already in the assigned view, push remaining players to assigned view
        targetItem.players.filter(player => !this.dragDropLeftRightService.atLeastOneMatch(this.dragDropLeftRightService.assignedItemsView, player, 'Id'))
            .forEach(filteredPlayer => this.dragDropLeftRightService.assignedItemsView.push(filteredPlayer));
    }

    //Called on drop, filters out from all available items arrays the players that are assigned
    public filterAlreadyAssignedItems(): void {
        this.choosePlayersDataService.players = this.choosePlayersDataService.players.filter(player => !this.dragDropLeftRightService.atLeastOneMatch(this.dragDropLeftRightService.assignedItems, player, 'Id'));
    }

    public isGroupDraggable(group: PlayerGroupsDragDrop): void {
        if (this.dragDropLeftRightService.visibleItems === 'groups') {

            const groupDisabled: boolean = group.players.some(player => player.draggableClass !== 'draggable');
            const assignedPlayersFromGroup: PlayersDragDrop[] = group.players.filter(player => !this.dragDropLeftRightService.atLeastOneMatch(this.dragDropLeftRightService.assignedItems, player, 'Id'));
            
            //Check here first
            if(groupDisabled) {
                group.draggableClass = '';
                return;
            }
            group.draggableClass = assignedPlayersFromGroup.length > 0 ? 'draggable' : '';
        }
    }
}
