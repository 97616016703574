import { ColorChromeModule } from 'ngx-color/chrome';
import { ColorBlockModule } from 'ngx-color/block';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CanvasComponent } from './main-editor/canvas/canvas.component';
import { CanvasWrapperComponent } from './main-editor/canvas/canvas-wrapper.component';
import { ColorSelectComponent } from './main-editor/layer-detail/shared-controls/color-select/color-select.component';
import { Create24Component } from './create-24.component';
import { Create24RoutingModule } from './create-24-routing.module';
import { CreateLayerSidebarComponent } from './main-editor/create-layer-sidebar/create-layer-sidebar.component';
import { DropShadowComponent } from './main-editor/layer-detail/shared-controls/drop-shadow/drop-shadow.component';
import { ExportToLibraryComponent } from './export-to-library/export-to-library.component';
import { FeedComponent } from './main-editor/layer-detail/feed/feed.component';
import { ImageComponent } from './main-editor/layer-detail/image/image.component';
import { LayerComponent } from './main-editor/timeline/layers-timeline-wrapper/layer/layer.component';
import { LayerDetailComponent } from './main-editor/layer-detail/layer-detail.component';
import { LayersTimelineWrapperComponent } from './main-editor/timeline/layers-timeline-wrapper/layers-timeline-wrapper.component';
import { MainEditorComponent } from './main-editor/main-editor.component';
import { SharedControlsComponent } from './main-editor/layer-detail/shared-controls/shared-controls.component';
import { SharedModule } from '../../shared/shared.module';
import { StartPageComponent } from './start-page/start-page.component';
import { TextComponent } from './main-editor/layer-detail/text/text.component';
import { TimelineComponent } from './main-editor/timeline/timeline.component';
import { TransitionSelectComponent } from './main-editor/layer-detail/shared-controls/transition-select/transition-select.component';
import { UserColorsComponent } from './main-editor/layer-detail/shared-controls/user-colors/user-colors.component';
import { VideoControlsComponent } from './main-editor/timeline/video-controls/video-controls.component';
import { CountdownAndDateComponent } from './main-editor/create-layer-sidebar/countdown-and-date/countdown-and-date.component';
import { TextService } from './main-editor/layer-detail/_services';
import { GridLinesComponent } from './main-editor/canvas/grid-lines/grid-lines.component';

@NgModule({
	imports: [Create24RoutingModule, SharedModule, ColorChromeModule, ColorBlockModule],
	exports: [RouterModule],
	declarations: [
		CanvasComponent,
		CanvasWrapperComponent,
		ColorSelectComponent,
		Create24Component,
		CreateLayerSidebarComponent,
		DropShadowComponent,
		ExportToLibraryComponent,
		FeedComponent,
		ImageComponent,
		LayerComponent,
		LayerDetailComponent,
		LayersTimelineWrapperComponent,
		MainEditorComponent,
		SharedControlsComponent,
		StartPageComponent,
		TextComponent,
		TimelineComponent,
		TransitionSelectComponent,
		UserColorsComponent,
		VideoControlsComponent,
		CountdownAndDateComponent,
  GridLinesComponent
	],
	providers: [TextService]
})
export class Create24Module {}
