import { Injectable } from '@angular/core';

import { Column, Table, TableConfig } from '../../../shared/components/table/view-models';

@Injectable()
export class TableConfigService {
	public columns: Column[] = [
		new Column('editBtn', 'Edit', 'button', false, 'gray-bg pd-left10 pd-right10'),
		new Column('disableBtn', 'Disable', 'button', false, 'gray-bg pd-left10 pd-right10'),
		new Column('itemContentView', 'Story', 'string', true),
		new Column('startDateView', 'Start', 'string', true),
		new Column('stopDateView', 'Stop', 'string', true),
		new Column('disabledIcon', 'Disabled', 'icon'),
		new Column('createDate', 'Created', 'string')
	];
	public table: Table = new Table(this.columns);

	public configuration: TableConfig = {
		headerColor: 'green-bg'
	};
}
