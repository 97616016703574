import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { fabric } from 'fabric';

import { CanvasService } from '../../canvas/_services/canvas.service';
import { CEventsService } from '../../canvas/_services/c-events.service';
import { IImage, LayerType } from '../../_models';
import { Layer } from '../../_models';
import { LayersService } from '../../_services/layers.service';
import { UndoRedoService } from '../../_services/undo-redo.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class LayerDetailService {
	private layerDetailScrollSource = new Subject<number>();
	private buttonClickSource = new Subject<[string, string]>();
	public onButtonClick$ = this.buttonClickSource.asObservable();
	public layerDetailScroll$ = this.layerDetailScrollSource.asObservable();
	public isCropping: boolean;
	public transitionInOptions: { name: string }[] = [
		{
			name: 'Not set'
		},
		{
			name: 'Fade in'
		},
		{
			name: 'Slide in down'
		},
		{
			name: 'Slide in left'
		},
		{
			name: 'Slide in right'
		},
		{
			name: 'Slide in up'
		}
	];

	public transitionOutOptions: { name: string }[] = [
		{
			name: 'Not set'
		},
		{
			name: 'Fade out'
		},
		{
			name: 'Slide out down'
		},
		{
			name: 'Slide out left'
		},
		{
			name: 'Slide out right'
		},
		{
			name: 'Slide out up'
		}
	];

	//All buttons that needs to close their options when another button is clicked
	public buttons: string[] = ['showSavePresets', 'showPresets', 'colorPickerOpen', 'eyedropperOpen'];

	constructor(
		private canvasService: CanvasService,
		private cEventsService: CEventsService,
		private layersService: LayersService,
		private undoRedoService: UndoRedoService
	) {}

	public onButtonClick(payload: [string, string]): void {
		this.buttonClickSource.next(payload);
	}

	public cropImage(): void {
		const img: any = this.activeObj();
		let left: number = this.canvasService.cropObj.left;
		let top: number = this.canvasService.cropObj.top;
		let height: number = this.canvasService.cropObj.height * this.canvasService.cropObj.scaleY;
		let width: number = this.canvasService.cropObj.width * this.canvasService.cropObj.scaleX;

		if (top < img.top) {
			height = height - (img.top - top);
			top = img.top;
		}
		if (left < img.left) {
			width = width - (img.left - left);
			left = img.left;
		}
		if (top + height > img.top + img.height) height = img.top + img.height - top;
		if (left + width > img.left + img.width) width = img.left + img.width - left;

		const canvas_crop: any = new fabric.Canvas('canvas_crop');

		fabric.Image.fromURL(
			this.canvasService.canvas.toDataURL('jpeg'),
			(image) => {
				image.set('left', -left - 2);
				image.set('top', -top - 2);
				canvas_crop.add(image);
				canvas_crop.setHeight(height - 2);
				canvas_crop.setWidth(width - 2);
				canvas_crop.renderAll();

				fabric.Image.fromURL(
					canvas_crop.toDataURL('jpeg'),
					(i) => {
						const croppedImg = i as IImage;
						croppedImg.set('left', left);
						croppedImg.set('top', top);
						croppedImg.component = 'canvas';
						croppedImg.name = 'image';
						croppedImg.layerId = this.layersService.activeLayer.id;
						croppedImg.cornerColor = '#2980b9';
						croppedImg.strokeWidth = 0;
						croppedImg.strokeUniform = true;
						croppedImg.cornerStrokeColor = '#fff';
						croppedImg.transparentCorners = false;
						croppedImg.hasRotatingPoint = true;
						croppedImg.setControlsVisibility({
							tr: true,
							tl: true,
							br: true,
							bl: true,
							ml: false,
							mt: false,
							mr: false,
							mb: false,
							mtr: true
						});
						this.layersService.activeLayer.canvasObj.width = croppedImg.width;
						this.layersService.activeLayer.canvasObj.height = croppedImg.height;
						this.layersService.activeLayer.canvasObj.scaleX = croppedImg.scaleX;
						this.layersService.activeLayer.canvasObj.scaleY = croppedImg.scaleY;
						this.layersService.activeLayer.canvasObj.top = croppedImg.top;
						this.layersService.activeLayer.canvasObj.left = croppedImg.left;
						this.canvasService.canvas.remove(this.canvasService.cropObj);
						this.canvasService.canvas.remove(this.canvasService.canvas.item(this.layersService.activeLayer.id));
						this.canvasService.canvas
							.getObjects()
							.filter((obj) => obj.layerId !== img.layerId)
							.forEach((obj) => {
								obj.set('visible', true);
							});
						this.canvasService.canvas.add(croppedImg).renderAll();
						this.undoRedoService.recordState('Crop image');
					},
					{ crossOrigin: 'anonymous' }
				);
			},
			{ crossOrigin: 'anonymous' }
		);
	}

	public onLayerDetailScroll(value: number): void {
		this.layerDetailScrollSource.next(value);
	}

	public activeObj(): any {
		let activeLayer = this.layersService.activeLayer;
		if (activeLayer) {
			return this.canvasService.canvas.getObjects().find((obj) => obj.layerId === activeLayer.id && !obj.isFeedTextImage);
		}
	}

	public updateProperty(objectProp: string | string[], value: string | number | boolean | any): void {
		// let val = isNaN(value) ? value : JSON.stringify(value);

		if (Array.isArray(objectProp)) {
			objectProp.forEach((prop) => {
				this.activeObj().set(prop, value);
				this.activeObj().setCoords();
			});
		} else {
			this.activeObj().set(objectProp, value);
			this.activeObj().setCoords();
		}
		this.canvasService.canvas.renderAll();
	}

	public updateValuesAllFeedObjs(): void {
		this.layersService.layers
			.filter((layer) => this.isFeedLayer(layer))
			.forEach((layer) => {
				let objs = this.canvasService.canvas.getObjects().filter((obj) => obj.layerId === layer.id);
				let feedTextObj = this.canvasService.canvas.getObjects().find((obj) => obj.layerId === layer.id && obj.isFeedText);
				let parsedValues = Array.isArray(this.canvasService.feedItem?.parsedValues)
					? this.canvasService.feedItem?.parsedValues[0]
					: this.canvasService.feedItem?.parsedValues;
				switch (true) {
					//Feed image and content file Id exists
					case layer.type === 'Feed Image' && !!parsedValues?.ContentFileId:
						layer.previewUrl = `${environment.contentUrl}File/Video/${parsedValues.ContentFileId}/Preview`;

						objs.forEach((obj) => this.canvasService.canvas.remove(obj));
						this.canvasService.addImage(layer, true);
						break;
					//Feed image and no contentFileId
					case layer.type === 'Feed Image' && !parsedValues?.ContentFileId:
						objs.forEach((obj) => this.canvasService.canvas.remove(obj));
						this.canvasService.addFeedText(layer);
						break;
					//Feed text
					default:
						feedTextObj.set('text', this.feedObjValue(layer) ? this.feedObjValue(layer) : '');
						this.cEventsService.feedObjChanged.next(feedTextObj);
						break;
				}
			});
		this.canvasService.canvas.renderAll();
	}

	public isFeedLayer(layer: Layer): boolean {
		return layer.type === 'Feed Image' || layer.type === 'Feed Text';
	}

	public feedObjValue(layer: Layer): string {
		if (this.canvasService.feed.Items[this.canvasService.feedItem?.order - 1]) {
			let values = JSON.parse(this.canvasService.feed.Items[this.canvasService.feedItem?.order - 1].Values);
			if (Array.isArray(values)) {
				let value = JSON.parse(this.canvasService.feed.Items[this.canvasService.feedItem?.order - 1].Values)[0][layer.feedSourceName];
				return !isNaN(value) ? value.toString() : value; //If number, convert to string
			}
			let value = JSON.parse(this.canvasService.feed.Items[this.canvasService.feedItem?.order - 1].Values)[layer.feedSourceName];
			return !isNaN(value) ? value.toString() : value; //If number, convert to string
		}
	}

	public showLineHeightLetterSpacing(): boolean {
		let layerType: LayerType = this.layersService.activeLayer.type;
		return layerType !== 'Feed Text' && layerType !== 'Countdown' && layerType !== 'Current Time' && layerType !== 'Current Date';
	}

	public updateFeedTextImage(): void {
		if (this.activeObj()?.isFeedText) {
			this.cEventsService.feedObjChanged.next(this.activeObj());
		}
	}

	//If at least one feed layer exists, feed, item, and source must be selected before saving
	public feedLayersValid(): boolean {
		let allFeedTextLayers: Layer[] = this.layersService.layers.filter((layer) => layer.type === 'Feed Text');
		let allHaveFeedSourceName: boolean = allFeedTextLayers.every((layer) => !!layer.feedSourceName);

		if (this.layersService.atLeastOneFeedLayer()) {
			return !!this.canvasService.feed && !!this.canvasService.feedItem && allHaveFeedSourceName;
		}
		return true;
	}
}
