import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { CsrBlasts, CsrBlastGroups, CsrBlastRecipients, MailerTemplates, Mailer } from 'src/app/shared/api-models/admin';
import { UnsavedRecipients } from '../../_models';
import { AppStateService, DateTimeService, Events, MessageService, UtilitiesService } from 'src/app/core/services';

@Injectable({
    providedIn: 'root'
})
export class CreateEditTemplateService {

    public mode: 'edit' | 'new';
    public template: MailerTemplates;

    constructor(
            private appStateService: AppStateService,
            private httpClient: HttpClient,
            private messageService: MessageService,
            private router: Router
    ) { }

    public getTemplate(templateId: number): void {
        this.httpClient.get(`${environment.adminUrl}MailerTemplate/${templateId}`)
            .subscribe( (template: MailerTemplates) => {
                this.template = template;
            })
    }

    public saveTemplate(): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.template.CsrBlastCsrId = this.appStateService.currentUser.UserId;
        let url: string = this.mode === 'edit' ? `${environment.adminUrl}MailerTemplate/${this.template.Id}` : `${environment.adminUrl}MailerTemplate`;
        let method: string = this.mode === 'edit' ? 'put' : 'post';
        this.httpClient[method](url, this.template)
            .subscribe( () => {
                this.messageService.publish(Events.savingPreloader, 0);
            })

    }
}
