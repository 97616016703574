import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ClientBrowserComponent } from './client-browser/client-browser.component';
import { SharedModule } from '../shared/shared.module';
import { SystemToolsRoutingModule } from './system-tools-routing.module';
import { ContractRenewalsReportComponent } from './contract-renewals-report/contract-renewals-report.component';
import { LibraryEditorComponent } from './library-editor/library-editor.component';
import { NewsManagerComponent } from './news-manager/news-manager.component';
import { CreateEditFeedItemComponent } from './news-manager/create-edit-feed-item/create-edit-feed-item.component';
import { HoldDeliveryQueueComponent } from './hold-delivery-queue/hold-delivery-queue.component';
import { PlayerStatusReportComponent } from './player-status-report/player-status-report.component';
import { SystemToolsCrmSharedModule } from '../shared/system-tools-crm-shared.module';
import { TicketSystemComponent } from './ticket-system/ticket-system.component';
import { CsrTasksComponent } from './csr-tasks/csr-tasks.component';
import { CreateEditTaskComponent } from './csr-tasks/create-edit-task/create-edit-task.component';
import { ReminderEmailsComponent } from './reminder-emails/reminder-emails.component';
import { CreateEditMailingComponent } from './email-blast-tool/create-edit-mailing/create-edit-mailing.component';
import { EmailBlastToolComponent } from './email-blast-tool/email-blast-tool.component';
import { CreateEditTemplateComponent } from '../system-tools/email-blast-tool/create-edit-template/create-edit-template.component';
import { ClientHealthDashboardComponent } from './client-health-dashboard/client-health-dashboard.component';
import { ClientDetailsComponent } from './client-health-dashboard/client-details/client-details.component';
import { MainViewComponent } from './client-health-dashboard/main-view/main-view.component';
import { CsrHomeComponent } from './csr-home/csr-home.component';
import { ClientMergeToolComponent } from './client-merge-tool/client-merge-tool.component';
import { MergeDataComponent } from './client-merge-tool/merge-data/merge-data.component';
import { UsageReportsComponent } from './usage-reports/usage-reports.component';
import { LibrarySearchComponent } from './usage-reports/library-search/library-search.component';
import { ReportComponent } from './usage-reports/report/report.component';
import { TableComponent } from './usage-reports/table/table.component';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'client-health-dashboard',
				component: ClientHealthDashboardComponent,
				children: [
					{
						path: 'user-id/:userId',
						component: MainViewComponent
					},
					{
						path: 'client-details/:clientId',
						component: ClientDetailsComponent
					}
				]
			},
			{
				path: 'csr-home/user-id/:userId',
				component: CsrHomeComponent
			}
		]),
		CommonModule,
		SharedModule,
		SystemToolsRoutingModule,
		SystemToolsCrmSharedModule
	],
	declarations: [
		ClientBrowserComponent,
		ContractRenewalsReportComponent,
		LibraryEditorComponent,
		NewsManagerComponent,
		CreateEditFeedItemComponent,
		HoldDeliveryQueueComponent,
		PlayerStatusReportComponent,
		TicketSystemComponent,
		CsrTasksComponent,
		CreateEditTaskComponent,
		ReminderEmailsComponent,
		CreateEditMailingComponent,
		EmailBlastToolComponent,
		CreateEditTemplateComponent,
		ClientHealthDashboardComponent,
		ClientDetailsComponent,
		MainViewComponent,
		CsrHomeComponent,
		ClientMergeToolComponent,
		MergeDataComponent,
		UsageReportsComponent,
		LibrarySearchComponent,
		ReportComponent,
		TableComponent
	],
	exports: [ClientHealthDashboardComponent, MainViewComponent, ClientDetailsComponent]
})
export class SystemToolsModule {}
