import { Component, Input } from '@angular/core';

import { PlayerCapacityService } from 'src/app/playlist-builder/_services';
import { ContentFileState } from 'src/app/shared/api-models/content/content-file-state.enum';
import { ContentViewStateService } from 'src/app/shared/services';
import { ContentVM } from '../../_models/content-view';
import { ContentFavoritesService, ContentViewService } from '../../_services';

@Component({
	selector: 'vc-hold-pb-card-view',
	templateUrl: './hold-pb-card-view.component.html',
	styleUrls: ['./hold-pb-card-view.component.scss']
})
export class HoldPbCardViewComponent {
	@Input() canUseInPlaylist: boolean;
	@Input() content: ContentVM;
	@Input() isLastIndex: boolean;
	@Input() viewState: 'playlist-builder' | 'content-manager';

	public showScript: boolean;
	readonly contentFileState = ContentFileState;

	constructor(
		public contentFavoritesService: ContentFavoritesService,
		public contentViewService: ContentViewService,
		public cvStateService: ContentViewStateService,
		public playerCapacityService: PlayerCapacityService
	) {}
}
