<div #topHandle class="absolute top-0 h-4 bg-gray-200 w-full select-none">
	<i
		(click)="removeHorizontalLine()"
		ngxTippy
		tippyName="{{ utilService.guid | wrapFn }}"
		[tippyProps]="{ appendTo: 'parent' }"
		data-tippy-content="Remove newest horizontal guide"
		class="fas fa-minus absolute right-6 red-text cursor-pointer text-xs"
	></i>
	<i
		(click)="addHorizontalLine()"
		ngxTippy
		tippyName="{{ utilService.guid | wrapFn }}"
		[tippyProps]="{ appendTo: 'parent' }"
		data-tippy-content="Add horizontal guide"
		class="fas fa-plus absolute right-px green-text cursor-pointer text-xs"
	></i>
	<div class="pointer-events-none">
		<div *ngFor="let marker of horizontalPixelMarkers" class="absolute h-full pointer-events-none" [style.left.px]="marker.position">
			<div class="w-px h-full bg-gray-400 pointer-events-none"></div>
			<span class="absolute text-[10px] left-1 top-0 pointer-events-none">{{ marker.value }}</span>
			<!-- Sub-markers -->
			<div *ngFor="let subMarker of marker.subMarkers" class="absolute h-1/2 top-0 pointer-events-none" [style.left.px]="subMarker - marker.position">
				<div class="w-px h-full bg-gray-300 pointer-events-none"></div>
			</div>
		</div>
	</div>
</div>
<div #leftHandle class="absolute inset-y-0 left-0 bg-gray-200 w-4 select-none">
	<i
		(click)="removeVerticalLine()"
		ngxTippy
		tippyName="{{ utilService.guid | wrapFn }}"
		[tippyProps]="{ appendTo: 'parent' }"
		data-tippy-content="Remove newest vertical guide"
		class="fas fa-minus absolute bottom-6 left-1/2 transform -translate-x-1/2 red-text cursor-pointer text-xs"
	></i>
	<i
		(click)="addVerticalLine()"
		ngxTippy
		tippyName="{{ utilService.guid | wrapFn }}"
		[tippyProps]="{ appendTo: 'parent' }"
		data-tippy-content="Add vertical guide"
		class="fas fa-plus absolute bottom-px left-1/2 transform -translate-x-1/2 green-text cursor-pointer text-xs"
	></i>
	<div class="pointer-events-none h-full relative">
		<div *ngFor="let marker of verticalPixelMarkers" class="absolute w-full pointer-events-none" [style.top.px]="marker.position">
			<div class="h-px w-full bg-gray-400 pointer-events-none"></div>
			<span class="absolute text-[8px] left-0 -top-3 pointer-events-none origin-top-left">{{ marker.value }}</span>
			<!-- Sub-markers -->
			<div *ngFor="let subMarker of marker.subMarkers" class="absolute w-1/2 pointer-events-none" [style.top.px]="subMarker - marker.position">
				<div class="h-px w-full bg-gray-300 pointer-events-none"></div>
			</div>
		</div>
	</div>
</div>
<div
	*ngFor="let line of horizontalLines; let i = index"
	[id]="'draggableLineHorizontal' + i"
	class="draggable-line w-full absolute h-px bg-[#6AA7FF] z-10 hover:cursor-ns-resize"
	[style.top.px]="line.top"
></div>
<div
	*ngFor="let line of verticalLines; let i = index"
	[id]="'draggableLineVertical' + i"
	class="draggable-line h-full absolute w-px bg-[#6AA7FF] z-10 hover:cursor-ew-resize"
	[style.left.px]="line.left"
></div>
