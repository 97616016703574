import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { mergeMap, map, switchMap, tap } from 'rxjs/operators';

import { DeliveryQueue } from 'src/app/shared/api-models/delivery';
import { environment } from '../../../environments/environment';
import { AppStateService } from '../../core/services';
import { Players, Users } from 'src/app/shared/api-models/admin';
import { SavedPlaylists } from '../../shared/view-models/content';
import { ViewState } from '../../saved-playlists/view-state';
import { ProductRoute } from 'src/app/shared/api-models/admin/product-route.enum';
import { RadioHistoryLog } from 'src/app/shared/api-models/admin/radio-history-log';
import { Play24Stations } from 'src/app/shared/api-models/content';
import { RadioHistoryLogView } from '../radio-history-log.type';

@Injectable({
	providedIn: 'root'
})
export class PlayerPlaylistHistoryService {
	public pageNumber: number = 1;
	public player: Players;
	public radioHistoryLog: RadioHistoryLogView;
	public playlists: SavedPlaylists[];
	public searchTerm: string;
	public spinnerActive: boolean;
	public totalItems: number;
	public viewState: ViewState = new ViewState(1, false, false, 'CreateDate', true);

	constructor(private appStateService: AppStateService, private httpClient: HttpClient) {}

	public getPlaylistHistory(playerId: number): Observable<any> {
		this.spinnerActive = true;
		let isSimpleSchedule: boolean = !this.appStateService.currentClient.UseAdvancedScheduling;
		return this.httpClient
			.get(`${environment.deliveryUrl}DeliveryQueue/PlayerHistory/${playerId}?pageSize=25&pageNumber=${this.pageNumber}`, { observe: 'response' })
			.pipe(
				mergeMap((res: HttpResponse<any>) => {
					let deliveryQueue: DeliveryQueue[] = res.body;
					if (deliveryQueue.length < 1) {
						return of([]);
					}
					this.totalItems = parseInt(res.headers.get('x-result-count'));
					let playlistStatus$ = deliveryQueue.map((queue) => {
						return this.httpClient.get(`${environment.deliveryUrl}DeliveryQueue/PlaylistStatus/${queue.PlaylistId}?isSimpleSchedule=${isSimpleSchedule}`);
					});

					return forkJoin(playlistStatus$).pipe(
						map((playlistStatus: string[][]) => {
							this.playlists = this.playlistView(deliveryQueue, playlistStatus);
							this.spinnerActive = false;
						})
					);
				})
			);
	}

	private playlistView(deliveryQueue: DeliveryQueue[], playlistStatus: string[][]): SavedPlaylists[] {
		return deliveryQueue.map((queue, index) => {
			let playlist: SavedPlaylists = new SavedPlaylists();
			playlist.Id = deliveryQueue[index].PlaylistId;
			playlist.Name = deliveryQueue[index].PlaylistName;
			playlist.stopDate = deliveryQueue[index].StopDate;
			playlist.startDate = deliveryQueue[index].StartDate;
			playlist['classList'] = 'white-bg t-row pd-top10 pd-bottom10';
			playlist.status = playlistStatus[index][0];
			playlist.deliverComplete = deliveryQueue[index].DeliverComplete;
			playlist['detailsBtn'] = 'Details';
			playlist.editBtn = 'Edit';
			playlist['previewBtnIcon'] = 'fas fa-play';
			return playlist;
		});
	}

	public getData(playerId: number): any {
		const playlistHistory$ = this.getPlaylistHistory(playerId);

		return forkJoin([this.getPlayer$(playerId), playlistHistory$]).pipe(
			map((res: [Players[], undefined]) => {
				this.player = res[0][0];
				this.spinnerActive = false;
				return res;
			})
		);
	}

	public getPlayer$(playerId: number): Observable<Players[]> {
		return this.httpClient.get<Players[]>(`${environment.adminUrl}CorePlayers/${playerId}`);
	}

	public getRadioHistoryLog$(playerId: number) {
		this.spinnerActive = true;
		return this.httpClient.get(`${environment.adminUrl}RadioHistoryLog/${playerId}`).pipe(
			tap((radioHistoryLog: RadioHistoryLog[]) => {
				this.totalItems = radioHistoryLog.length;
				if (this.totalItems === 0) {
					this.spinnerActive = false;
				}
			}),
			switchMap((radioHistoryLog: RadioHistoryLog[]) => {
				const observablesArray = radioHistoryLog.map((log) => {
					return forkJoin({
						station: this.httpClient.get<Play24Stations>(`${environment.contentUrl}Play24Stations/${log.RequestedPlay24StationId}`),
						user: this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${log.UserId}`)
					}).pipe(
						map(({ station, user }) => ({
							...log,
							station,
							user,
							stationName: station.Name,
							changedByUser: `${user.ContactInformation.FirstName} ${user.ContactInformation.LastName}`,
							classList: 'white-bg t-row pd-top10 pd-bottom10'
						}))
					);
				});
				return forkJoin(observablesArray);
			})
		);
	}

	public setTableHeaderColor(): string {
		const { Route } = this.appStateService.product;
		switch (Route) {
			case ProductRoute.HOLD:
				return 'blue-bg';
			case ProductRoute.RADIO:
				return 'purple-bg';
			default:
				return 'red-bg';
		}
	}
}
