import { LocationsTicketEdit } from './locations-ticket-edit';
import { TicketAttachments, Tickets, Clients, ContactInformation, CstLocationNotes } from '../../../../shared/api-models/admin';
import { Notes } from '../../_models';
import { WorkOrdersView } from './work-orders-view';

export class TicketsView extends Tickets {
    status: string;
    ticketTypeName: string;
    client: Clients;
    currentUserEmail: string;
    assignedToUsername: string;
    lastUpdateByUsername: string;
    createdByUsername: string;
    assignedLocations: LocationsTicketEdit[];
    unassignedLocations: LocationsTicketEdit[];
    attachments: TicketAttachments[];
    lastActionByUsername: string;
    csrFriendlyName: string;
    csrEmail: string;
    salesmanFriendlyName: string;
    notifyListArr: string[];
    assignedToEmail: string;
    historyNotes: Notes[];
    oldNotes: Notes[];
    activeCstEmailList: string;
    workOrders: WorkOrdersView[];
    crmContact: ContactInformation;
    cstLocationNotesByClient: CstLocationNotes[];
 }