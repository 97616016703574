import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { authGuard, isEmployeeContractorOrVoiceTalentGuard } from '../core/services/route-guards';
import { ScriptReadViewComponent } from '../content-requests/create-edit-request/clips/clip/hold-view/script-read-view/script-read-view.component';

const routes: Routes = [
	{
		path: 'system-tools/content-requests/script-read-view',
		component: ScriptReadViewComponent,
		canActivate: [authGuard, isEmployeeContractorOrVoiceTalentGuard]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ContentRequestsRoutingModule {}
