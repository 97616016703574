import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CategoriesTree, LibrariesTree } from '../_models';
import { environment } from '../../../../../environments/environment';
import { LibrariesService } from './libraries.service';
import { Events, MessageService, UtilitiesService } from '../../../../core/services';
import { LibrariesDragDrop } from 'src/app/shared/view-models/content';
import { ContentLibraries } from 'src/app/shared/api-models/content';

@Injectable({
	providedIn: 'root'
})
export class CategoriesService {
	public clickedCategory: CategoriesTree; //Store ID to cache content if clicking same category

	constructor(
		private httpClient: HttpClient,
		private librariesService: LibrariesService,
		private messageService: MessageService,
		private utilService: UtilitiesService
	) {}

	public getCategories(libraryId: number): Observable<CategoriesTree[]> {
		return this.httpClient.get<CategoriesTree[]>(`${environment.contentUrl}Contentlibraries/${libraryId}/Categories`).pipe(
			map((categories: CategoriesTree[]) => {
				categories = categories
					.map((category) => {
						if (category.ParentCategoryId) {
							category.isSubCategory = true;
						}

						//Sort sub-categories
						if (category?.InverseParentCategory.length > 0) {
							category.InverseParentCategory = this.utilService.sortItems(category.InverseParentCategory, 'CategoryName');
						}

						category.InverseParentCategory.forEach((sub) => {
							sub.isSubCategory = true;
							sub.containsFavorites = this.containsFavorites(sub);
						});
						category.containsFavorites = this.containsFavorites(category);
						return category;
					})
					.filter((category) => !category.IsDeleted);
				return this.utilService.sortItems(categories, 'CategoryName');
			})
		);
	}

	private containsFavorites(category: CategoriesTree): boolean {
		return this.librariesService.contentFavorites?.some((contentFavorite) => {
			if (contentFavorite.CategoryId === category.Id || category.InverseParentCategory.some((subCategory) => subCategory.Id === contentFavorite.CategoryId)) {
				return true;
			}
		});
	}

	public saveNewCategory$(categoryName: string, library: ContentLibraries): Observable<any> {
		this.messageService.publish(Events.savingPreloader, 1);
		return this.httpClient.post(`${environment.contentUrl}ContentLibraryCategories`, this.newCategory(categoryName, library)).pipe(
			map(() => {
				this.messageService.publish(Events.savingPreloader, 0);
			})
		);
	}

	private newCategory(categoryName: string, library: ContentLibraries): CategoriesTree {
		let category: CategoriesTree = new CategoriesTree();
		category.LibraryId = library.Id;
		category.CategoryName = categoryName;
		return category;
	}
}
