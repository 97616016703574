import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AppStateService } from 'src/app/core/services';
import { LayerDetailService } from '../../_services/layer-detail.service';
import { LayersService } from '../../../_services';

@Component({
    selector: 'ld-user-colors',
    templateUrl: './user-colors.component.html',
    styleUrls: ['./user-colors.component.scss']
})
export class UserColorsComponent implements OnInit {

    @Input() mode: 'get' | 'set';
    @Input() objectProp: 'fill' | 'stroke';
    @Output() saveClickNotify = new EventEmitter<any>();

    public userColors = ['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4'];

    constructor(
        private appStateService: AppStateService,
        private layerDetailService: LayerDetailService,
        private layersService: LayersService
    ) { }

    ngOnInit(): void {
        this.appStateService.getUserItem('create24UserColors')
            .subscribe((res) => {
                if (res) {
                    let userColors: string[] = JSON.parse(res);
                    this.userColors = userColors;
                }
            })
    }

    public onColorClick(hex: string): void {
        switch (this.mode) {
            case 'set':
                let index: number = this.userColors.findIndex(color => color === hex);
                this.userColors.splice(index, 1, this.layersService.activeLayer.canvasObj[this.objectProp]);
                break;

            case 'get':
                this.layerDetailService.updateProperty(this.objectProp, hex);
                break;
        }
    }

    public onCancelClick(): void {
        this.layerDetailService.updateProperty(this.objectProp, this.layersService.activeLayer.canvasObj[this.objectProp]);
        this.saveClickNotify.emit();
    }

    public onSaveClick(): void {
        switch (this.mode) {
            case 'set':
                this.appStateService.setUserItem('create24UserColors', JSON.stringify(this.userColors));
                break;

            case 'get':
                this.layersService.activeLayer.canvasObj[this.objectProp] = this.layerDetailService.activeObj()[this.objectProp];
                break;
        }
        this.saveClickNotify.emit();
    }
}
