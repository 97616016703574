import { Injectable } from '@angular/core';

import { IMyDateModel } from 'angular-mydatepicker';
import * as moment from 'moment';
import { CountdownLayerView } from 'src/app/user-tools/create-24/main-editor/_models';

@Injectable({
	providedIn: 'root'
})
export class DateTimeService {
	public months: { str: string; num: number }[] = [
		{ str: 'January', num: 1 },
		{ str: 'February', num: 2 },
		{ str: 'March', num: 3 },
		{ str: 'April', num: 4 },
		{ str: 'May', num: 5 },
		{ str: 'June', num: 6 },
		{ str: 'July', num: 7 },
		{ str: 'August', num: 8 },
		{ str: 'September', num: 9 },
		{ str: 'October', num: 10 },
		{ str: 'November', num: 11 },
		{ str: 'December', num: 12 }
	];
	public days: { num: number }[] = [
		{ num: 1 },
		{ num: 2 },
		{ num: 3 },
		{ num: 4 },
		{ num: 5 },
		{ num: 6 },
		{ num: 7 },
		{ num: 8 },
		{ num: 9 },
		{ num: 10 },
		{ num: 11 },
		{ num: 12 },
		{ num: 13 },
		{ num: 14 },
		{ num: 15 },
		{ num: 16 },
		{ num: 17 },
		{ num: 18 },
		{ num: 19 },
		{ num: 20 },
		{ num: 21 },
		{ num: 22 },
		{ num: 23 },
		{ num: 24 },
		{ num: 25 },
		{ num: 26 },
		{ num: 27 },
		{ num: 28 },
		{ num: 29 },
		{ num: 30 },
		{ num: 31 }
	];

	constructor() {}

	//date param is a moment
	public buildDatePickerDate(date: any): any {
		return {
			year: date.year(),
			month: date.month() + 1,
			day: date.date()
		};
	}

	public iMyDatePickerDateInit(date: string): IMyDateModel {
		return {
			isRange: false,
			singleDate: {
				date: this.buildDatePickerDate(moment.utc(new Date(date)).local()),
				jsDate: moment.utc(new Date(date)).local().toDate()
			}
		};
	}

	public dateAndTimeUTCtoLocal(date: string, italicTime?: boolean, showNoDateAsDraft?: boolean): string {
		//If date is saved with suffix "z", it denotes utc time.
		//Below method assumes no suffix "z", therefore manually
		//offsetting to local time. If "z" exists, method should
		//look like moment().format(), which will parse utc as local time
		if (!!date) {
			if (italicTime) {
				return moment.utc(date).local().format('M/D/YYYY, <i>h:mm A</i>');
			}
			return moment.utc(date).local().format('M/D/YYYY, h:mm A');
		}
		return showNoDateAsDraft ? 'Draft' : 'N/A';
	}

	public dateLocal(date: string, showNoDateAsDraft?: boolean): string {
		if (!!date) {
			return moment(date).format('M/D/YYYY');
		}
		return showNoDateAsDraft ? 'Draft' : 'N/A';
	}

	public dateUTCtoLocal(date: string, includeLineBreak?: boolean): string {
		if (!date) {
			return 'N/A';
		}
		if (includeLineBreak) {
			return moment.utc(date).format('M/D/YYYY');
		}
		return moment.utc(date).format('M/D/YYYY');
	}

	public dateAndTimeLocal(date: string, includeLineBreak?: boolean): string {
		if (!!date) {
			if (includeLineBreak) {
				return moment(date).format('M/D/YYYY, <br> h:mm A');
			}
			return moment(date).format('M/D/YYYY, h:mm A');
		}
		return 'N/A';
	}

	public isToday(date: string): boolean {
		const momentDate = moment(date);
		return momentDate.isSame(moment().clone().startOf('day'), 'd');
	}

	public todayAtMidnight(): string {
		return moment(new Date().setHours(0, 0, 0, 0)).format('YYYY-MM-DDTHH:mm:ss').toString();
	}

	public dateUtcToLocalString(date: string): string {
		return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss');
	}

	public localToUtc(date: string): string {
		return moment.utc(moment(date)).format();
	}

	public todayAtMidnightUTC(): string {
		return moment(new Date().setHours(0, 0, 0, 0)).utc().format('YYYY-MM-DDTHH:mm:ss').toString() + 'Z';
	}

	public midnightUTC(date: string): string {
		return moment(date).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss').toString() + 'Z';
	}

	public todayIsBefore(date: string): boolean {
		return moment(new Date()).isBefore(date);
	}

	public todayIsAfter(date: string): boolean {
		return moment(new Date()).isAfter(date);
	}

	public todayWithCurrentTime(): string {
		return moment.utc().format('YYYY-MM-DD[T]HH:mm:ss').toString();
	}

	public todayWithCurrentTimeUTC(): string {
		return moment.utc().format('YYYY-MM-DD[T]HH:mm:ss').toString() + 'Z';
	}

	public todayPlusOneMonth(): string {
		return moment().add(1, 'months').toISOString();
	}

	public localOffset(date: string): string {
		return moment.utc(date).toISOString();
	}

	public withinOneHour(date: string): boolean {
		const now = moment.utc();
		const diff = now.diff(moment.utc(date), 'minutes');
		return diff < 60;
	}

	public withinTwoHours(date: string): boolean {
		const now = moment.utc();
		const diff = now.diff(moment.utc(date), 'minutes');
		return diff < 120;
	}

	public within24Hours(date: string): boolean {
		const now = moment.utc();
		const diff = now.diff(moment.utc(date), 'minutes');
		return diff < 1440;
	}

	public differenceInDaysOrHours(prop: any): string {
		prop = moment.utc(prop);
		const today = moment.utc();
		if (today.diff(prop, 'hours') > 24) {
			return `${today.diff(prop, 'days')} days`;
		} else {
			return `${today.diff(prop, 'hours')} hours`;
		}
	}

	public dateDifferenceInDays(a, b): number {
		a = moment(a).startOf('day');
		b = moment(b).startOf('day');
		return b.diff(a, 'days');
	}

	//https://stackoverflow.com/questions/18623783/get-the-time-difference-between-two-datetimes
	public dateDiffHrMinSec(date: any, returnSingleValue?: 'hoursOnly' | 'minutesOnly' | 'secondsOnly'): string {
		var now = moment(new Date());
		var then = moment(date);

		var ms = moment(then, 'DD/MM/YYYY HH:mm:ss').diff(moment(now, 'DD/MM/YYYY HH:mm:ss'));
		var d = moment.duration(ms);
		var s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');

		if (!!returnSingleValue) {
			switch (returnSingleValue) {
				case 'hoursOnly':
					return Math.floor(d.asHours()).toString();
				case 'minutesOnly':
					return moment.utc(ms).format('mm');
				case 'secondsOnly':
					return moment.utc(ms).format('ss');
			}
		}
		return s;
	}

	//Full date and time
	public toUtc(date: string): string {
		return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
	}

	public convertTimeViewToUtc(time: string): string {
		return moment(moment(new Date(), 'MM/DD/YYYY').format('YYYY-MM-DD') + time, 'YYYY-MM-DDLT')
			.utc()
			.format('HH:mm:ss');
	}

	//https://stackoverflow.com/questions/32540667/moment-js-utc-to-local-time
	public convertUtcTimeToView(time: string): string {
		const utcTime = moment(moment(new Date(), 'MM/DD/YYYY').format('YYYY-MM-DD') + time, 'YYYY-MM-DDLT').format('YYYY-MM-DDTHH:mm:ss');
		return moment.utc(utcTime).local().format('LT');
	}

	//For C24
	public friendlyFormat(date: string, countdownLayerView: CountdownLayerView): string {
		switch (countdownLayerView) {
			case 'short':
				return moment(date).format('M/D/YYYY');
			case 'dayMonthDate':
				return moment(date).format('dddd, MMMM Do');
			case 'dayMonthDateYear':
				return moment(date).format('dddd, MMMM D, YYYY');
			case 'todaysDateWeekday':
				return moment(date).format('dddd');
			case 'todaysDateMonthDay':
				return moment(date).format('MMMM Do');
			case 'todaysDateMonth':
				return moment(date).format('MMMM');
			case 'todaysDateDay':
				return moment(date).format('D');
			case 'todaysDateYear':
				return moment(date).format('YYYY');
			case 'hoursMinutesAmPm':
				return moment(date).format('h:mm a');
			case 'currentTime':
				return moment(date).format('h:mm');
			case 'currentTimePeriod':
				return moment(date).format('a');
			default:
				break;
		}
	}
}
