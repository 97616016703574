<span *ngIf="savingBubblesVisible" class="absolute top10 left44 dark-text">
	<em>{{savingText}}</em>
</span>

<span *ngIf="!savingBubblesVisible" class="absolute top10 left56 dark-text">
	<i class="fa fa-check ft-size14 mgn-right8" aria-hidden="true"></i>
	<em>{{savingText}}</em>
</span>

<div *ngIf="savingBubblesVisible" class="ball-loader absolute top28 left150 in-block">
	<div class="relative">
	  <div class="ball-loader-ball ball1 dark-bg"></div>
	  <div class="ball-loader-ball ball2 dark-bg"></div>
	  <div class="ball-loader-ball ball3 dark-bg"></div>
	</div>
</div>

