import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { LibrariesView } from './libraries-view';
import { UtilitiesService } from '../../core/services/utilities.service';
import { PrintData } from './print-data';

@Injectable({
	providedIn: 'root'
})
export class LibraryEditorService {
	constructor(private httpClient: HttpClient, private utilService: UtilitiesService) {}

	public getPrintLibrary$(libraryId: number): Observable<Map<string, Map<string, PrintData[]>>> {
		return this.httpClient.get<PrintData[]>(`${environment.contentUrl}ContentLibraries/PrintLibrary/${libraryId}`).pipe(
			map((printData) => {
				const groupedData = new Map<string, Map<string, PrintData[]>>();
				printData.forEach((item) => {
					const parentCategory = item.ParentCategory;
					const categoryName = item.CategoryName;

					if (!groupedData.has(parentCategory)) {
						groupedData.set(parentCategory, new Map<string, PrintData[]>());
					}

					const parentCategoryMap = groupedData.get(parentCategory)!;

					if (!parentCategoryMap.has(categoryName)) {
						parentCategoryMap.set(categoryName, []);
					}
					parentCategoryMap.get(categoryName)!.push(item);
				});
				return this.sortGroupedData(groupedData);
			})
		);
	}

	private sortGroupedData(groupedData: Map<string, any>) {
		const sortedData = new Map<string, any>();

		groupedData.forEach((value, key) => {
			if (value instanceof Map) {
				// Sort the nested map by keys
				const sortedMap = new Map([...value.entries()].sort());
				// Recursively sort the nested map and set it in sortedData
				sortedData.set(key, this.sortGroupedData(sortedMap));
			} else if (Array.isArray(value)) {
				// Sort the array by 'Title'
				const sortedArray = value.slice().sort((a, b) => a.Title.localeCompare(b.Title));
				// Set the sorted array in sortedData
				sortedData.set(key, sortedArray);
			}
		});
		return sortedData;
	}

	public getLibraries$(): Observable<LibrariesView[]> {
		return this.httpClient.get(`${environment.contentUrl}ContentLibraries`).pipe(
			map((libraries: any[]) => {
				libraries = libraries
					.map((library) => {
						library['name'] = library.LibraryName;
						library['productTypeId'] = library.ProductTypeId;
						library['bgColor'] = this.setProductProps(library).bgColor;
						library['icon'] = this.setProductProps(library).icon;
						library['gradientColor'] = this.setProductProps(library).gradientColor;
						library['productName'] = this.setProductProps(library).productName;
						return library;
					})
					.filter((library) => !library.IsDeleted); //filter out certain types and if library is deleted
				return this.utilService.sortByProductTypeIdAndName(libraries);
			})
		);
	}

	public filterLibrariesByProduct(libraries$: Observable<LibrariesView[]>, productTypeId: number): Observable<LibrariesView[]> {
		return libraries$.pipe(
			map((libraries) => {
				return libraries.filter((library) => {
					if (productTypeId !== 8 && library.ProductTypeId === productTypeId && !this.isCreate24Library(library)) {
						return library;
					} else if (productTypeId === 8 && this.isCreate24Library(library)) {
						return library;
					}
				});
			})
		);
	}

	public updateLibrary(library: LibrariesView): Observable<any> {
		return this.httpClient.put(`${environment.contentUrl}ContentLibraries/${library.Id}`, library);
	}

	public setFilteringByProductTextColor(productTypeId: number): string {
		switch (productTypeId) {
			case 1:
				return 'blue-text';
			case 2:
				return 'purple-text';
			case 8:
				return 'orange-text';
			default:
				return 'red-text';
		}
	}

	private setProductProps(library: LibrariesView): any {
		let isCreate24Library = this.isCreate24Library(library);

		switch (library.ProductTypeId) {
			case 1:
				return { bgColor: 'blue-bg', gradientColor: 'blue-gradient', productName: 'On Hold Library', icon: 'fas fa-phone-volume' };

			case 2:
				return { bgColor: 'purple-bg', gradientColor: 'purple-gradient', productName: 'Radio Library', icon: 'fas fa-volume-up' };

			case 8:
				if (isCreate24Library) {
					return { bgColor: 'orange-bg', gradientColor: 'yellow-orange-bg-gradient', productName: 'Create24 Library', icon: 'fas fa-photo-video' };
				}
				return { bgColor: 'green-bg', gradientColor: 'green-limeGreen-bg-gradient', productName: `Other Products & Services Library`, icon: 'fas fa-book' };

			case 4:
				if (isCreate24Library) {
					return { bgColor: 'orange-bg', gradientColor: 'yellow-orange-bg-gradient', productName: 'Create24 Library', icon: 'fas fa-photo-video' };
				}
				return { bgColor: 'red-bg', gradientColor: 'red-gradient', productName: 'Poster Library', icon: 'far fa-square' };

			default:
				if (isCreate24Library) {
					return { bgColor: 'orange-bg', gradientColor: 'yellow-orange-bg-gradient', productName: 'Create24 Library', icon: 'fas fa-photo-video' };
				}
				return { bgColor: 'red-bg', gradientColor: 'red-gradient', productName: 'Lobby Video Library', icon: 'fas fa-tv' };
		}
	}

	private isCreate24Library(library: LibrariesView): boolean {
		return [5, 6, 7, 8, 9].indexOf(library.ContentLibraryTypeId) > -1;
	}
}
