<portal-header></portal-header>

<div class="container-fluid mx-4 mgn-top30">
	<!--**************************
		PAGE TITLE
	***************************-->
	<div class="row mgn-bottom30">
		<h1 class="semi-bold">
			<i class="fa fa-book mgn-right15" aria-hidden="true"></i>
			Library Editor
		</h1>
	</div>

	<div class="row white-bg shadow border-radius pd-top25 pd-bottom25 mgn-bottom15">
		<!--**************************
			PAGINATION CONTROLS
		***************************-->
		<div class="col-md-6 col-lg-3 mgn-topneg5 relative">
			<pagination-controls [maxSize]="5" (pageChange)="onPageChange($event)" class="pagination"></pagination-controls>
			<span class="hidden-lg setWidth100">Total Items: {{ (libraries$ | async)?.length }}</span>
			<span class="hidden-xs hidden-sm hidden-md absolute bottomneg22 left15 setWidth100">
				Total Items: {{ ((productFilterActive ? filteredLibraries$ : libraries$) | async)?.length }}
			</span>
		</div>

		<!--**************************
			FILTER BY NAME
		***************************-->
		<div class="col-md-6 col-lg-3 relative pd-top10">
			<input type="text" placeholder="Filter by name..." [(ngModel)]="searchTerm" />
			<button *ngIf="searchTerm?.length > 1" class="absolute top10 white-bg right15 setWidth25" (click)="searchTerm = ''; selectedLibrary = null">
				<i class="fa fa-times mgn-right8" aria-hidden="true"></i>
				Clear
			</button>
		</div>

		<!--**************************
			FILTER BY PRODUCT
		***************************-->
		<div style="display: flex; justify-content: space-between" class="col-md-12 col-lg-6 ht36 pd-top10 relative margin-top-mobile">
			<h2 *ngIf="productFilterActive" style="top: -3.75rem" class="semi-bold absolute hidden-xs hidden-sm hidden-md">
				Filtering by:
				<em class="{{ filteringByProductTextColor }}">{{ filteringByProductText }}</em>
			</h2>
			<span class="absolute topneg15">Filter by product</span>
			<div style="width: 18%" class="in-block">
				<button
					class="{{ selectedProductTypeId === 1 ? 'blue-bg-darkest' : 'blue-bg' }} border-radius white-text setWidth100"
					(click)="onProductFilterClick(1, 'On Hold')"
				>
					<i class="fa fa-phone-volume mgn-right10" aria-hidden="true"></i>
					On Hold
				</button>
			</div>
			<div style="width: 18%" class="in-block">
				<button
					class="{{ selectedProductTypeId === 3 ? 'red-bg-darkest' : 'red-bg' }} border-radius white-text setWidth100"
					(click)="onProductFilterClick(3, 'Lobby Video')"
				>
					<i class="fas fa-tv mgn-right10" aria-hidden="true"></i>
					Video
				</button>
			</div>
			<div style="width: 18%" class="in-block">
				<button
					class="{{ selectedProductTypeId === 4 ? 'red-bg-darkest' : 'red-bg' }} border-radius white-text setWidth100"
					(click)="onProductFilterClick(4, 'Poster')"
				>
					<i class="far fa-square mgn-right10" aria-hidden="true"></i>
					Poster
				</button>
			</div>
			<div style="width: 18%" class="in-block">
				<button
					class="{{ selectedProductTypeId === 2 ? 'purple-bg-darkest' : 'purple-bg' }} border-radius white-text setWidth100"
					(click)="onProductFilterClick(2, 'Radio')"
				>
					<i class="fa fa-volume-up mgn-right10" aria-hidden="true"></i>
					Radio
				</button>
			</div>
			<div style="width: 18%" class="in-block">
				<button
					class="{{ selectedProductTypeId === 8 ? 'orange-bg-darkest' : 'orange-bg' }} border-radius white-text setWidth100"
					(click)="onProductFilterClick(8, 'Create24 Libraries')"
				>
					<i class="fas fa-photo-video mgn-right10" aria-hidden="true"></i>
					C24
				</button>
			</div>
		</div>
	</div>
	<div class="row mgn-top30 mgn-bottom60 relative">
		<!--**************************
			LIBRARIES LIST
		***************************-->
		<div class="col-sm-4 pd-left0 mgn-bottom241">
			<div class="shadow border-radius white-bg pd15">
				<div
					*ngFor="
						let library of (productFilterActive ? filteredLibraries$ : libraries$)
							| async
							| searchFilter : searchTerm
							| paginate : { itemsPerPage: 25, currentPage: currentPage };
						let i = index
					"
					[attr.id]="library.Id"
					(mouseover)="onRowHover(i)"
					(mouseleave)="isHovered = false"
					(click)="onRowSelect(i, library)"
					class="{{ index === i && isHovered ? 'setOpacity' : null }} {{
						selectedLibrary?.Id === library.Id ? library.bgColor + '-darkest' : library.bgColor
					}} ft-size18 pd10 border-radius shadow mgn-bottom15 pointer"
				>
					<span class="white-text">
						<i class="{{ library.icon }} mgn-right10" aria-hidden="true"></i>
						{{ library.LibraryName }}
					</span>
				</div>
			</div>
		</div>

		<!--**************************
			DETAILS BOX
		***************************-->
		<div *ngIf="selectedLibrary?.Id" class="col-sm-8 relative">
			<div [ngStyle]="{ 'top.px': top }" style="width: 64%" id="box" class="shadow {{ detailsBoxPostion }} border-radius white-bg pd15">
				<i class="fas fa-long-arrow-alt-right absolute leftneg26 top16 ft-size20" aria-hidden="true"></i>
				<div class="row mgn-bottom15">
					<div class="col-sm-10">
						<h2 class="semi-bold in-block">
							<i class="{{ selectedLibrary.icon }} mgn-right10" aria-hidden="true"></i>
							{{ selectedLibrary.productName }} |
							<em>ID: {{ selectedLibrary.Id }}</em>
						</h2>
					</div>
					<div class="col-sm-2">
						<button class="dark-bg semi-bold white-text" (click)="onPrintClick()">
							<i class="fas fa-print mgn-right10"></i>
							Print
						</button>
					</div>
				</div>
				<div class="row {{ selectedLibrary.gradientColor }} ht3 mgn-right0 mgn-left0 mgn-bottom30 border-radius"></div>
				<div class="row">
					<div class="col-sm-6">
						<label class="semi-bold">Library Name</label>
						<input type="text" class="ft-size18" [(ngModel)]="selectedLibrary.LibraryName" />
					</div>
					<div class="col-sm-6">
						<label class="semi-bold">Root Path</label>
						<br />
						<input
							*ngIf="appStateService.currentUser.Role === enumUserRole.ADMINISTRATOR"
							type="text"
							class="ft-size18"
							[(ngModel)]="selectedLibrary.DefaultDirectory"
						/>
						<span *ngIf="appStateService.currentUser.Role !== enumUserRole.ADMINISTRATOR" class="relative top6 ft-size18">
							{{ selectedLibrary.DefaultDirectory }}
						</span>
					</div>
				</div>

				<div class="row mgn-top50">
					<div style="display: flex; justify-content: space-between" class="col-sm-12 ft-size16">
						<div class="in-block">
							<portal-checkbox
								[backgroundColor]="'lt-gray-bg'"
								[isChecked]="selectedLibrary.IsGlobal"
								[type]="'checkbox'"
								(checkboxClickNotify)="onCheckboxClick('IsGlobal')"
							></portal-checkbox>
							<span class="relative bottom6 left4 pointer" (click)="onCheckboxClick('IsGlobal')">Is Global</span>
						</div>
						<div class="in-block mgn-left30">
							<portal-checkbox
								[backgroundColor]="'lt-gray-bg'"
								[isChecked]="selectedLibrary.AllowContentRequest"
								[type]="'checkbox'"
								(checkboxClickNotify)="onCheckboxClick('AllowContentRequest')"
							></portal-checkbox>
							<span class="relative bottom6 left4 pointer" (click)="onCheckboxClick('AllowContentRequest')">Allow Content Requests</span>
						</div>
						<div class="in-block mgn-left30">
							<portal-checkbox
								[backgroundColor]="'lt-gray-bg'"
								[isChecked]="selectedLibrary.AllowCreate24"
								[type]="'checkbox'"
								(checkboxClickNotify)="onCheckboxClick('AllowCreate24')"
							></portal-checkbox>
							<span class="relative bottom6 left4 pointer" (click)="onCheckboxClick('AllowCreate24')">Allow Create24</span>
						</div>
						<div class="in-block mgn-left30">
							<portal-checkbox
								[backgroundColor]="'lt-gray-bg'"
								[isChecked]="!selectedLibrary.HideFromPortal"
								[type]="'checkbox'"
								(checkboxClickNotify)="onCheckboxClick('HideFromPortal')"
							></portal-checkbox>
							<span class="relative bottom6 left4 pointer" (click)="onCheckboxClick('HideFromPortal')">Show In Portal</span>
						</div>
						<div class="in-block">
							<portal-checkbox
								[backgroundColor]="'lt-gray-bg'"
								[isChecked]="selectedLibrary.HideFromWebPlayer"
								[type]="'checkbox'"
								(checkboxClickNotify)="onCheckboxClick('HideFromWebPlayer')"
							></portal-checkbox>
							<span class="relative bottom6 left4 pointer" (click)="onCheckboxClick('HideFromWebPlayer')">Hide From Web Players</span>
						</div>
					</div>
				</div>

				<!--**************************
					CANCEL/SAVE BUTTONS
				***************************-->
				<div class="row mgn-top60">
					<div class="col-sm-2">
						<button class="red-bg-light border-radius white-text setWidth100" (click)="onDeleteClick()">Delete</button>
					</div>
					<div class="col-sm-2 col-sm-offset-6">
						<button class="gray-bg border-radius setWidth100" (click)="selectedLibrary = null">Cancel</button>
					</div>
					<div class="col-sm-2">
						<button class="limeGreen-bg border-radius white-text setWidth100" (click)="onSaveClick()">Save</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
