<portal-header></portal-header>

<div class="container-fluid mx-4 mgn-top30 relative">
	<div class="row mgn-bottom30">
		<div class="col-sm-12">
			<div style="border-radius: 16px" class="white-bg box-shadow pd30 relative">
				<div class="row">
					<div class="col-sm-8">
						<h1>
							<i class="fas fa-home client-health-red-text mgn-right15" aria-hidden="true"></i>
							<span class="semi-bold">CSR Home |</span>
							<em class="light">{{ clientHealthDashboardService.selectedUser?.Name }}</em>
						</h1>
					</div>
					<div class="col-sm-2">
						<button id="crm-btn" (click)="onCrmClick()" class="client-health-red white-text">
							<i class="fas fa-user-circle mgn-right12"></i>
							CRM
						</button>
					</div>
					<div class="col-sm-2">
						<portal-dropdown-menu
							[backgroundColor]="'gray-bg'"
							[height]="'ht40'"
							[options]="clientHealthDashboardService.csrList"
							[key]="'Name'"
							[selectedOption]="clientHealthDashboardService.selectedUser?.Name"
							(onOptionSelect)="onCsrSelect($event)"
						></portal-dropdown-menu>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row mgn-top30">
		<div class="col-sm-4">
			<div style="border-radius: 16px" class="white-bg box-shadow scrollable pd15 ht500">
				<!--Health Status Header-->
				<div class="row mgn-bottom15">
					<div class="col-sm-6">
						<h3
							id="health-status-title"
							[routerLink]="['/client-health-dashboard/user-id/' + clientHealthDashboardService.clientHealthDashUserId()]"
							class="regular in-block pointer"
							style="text-decoration: underline"
						>
							Client Health Status
						</h3>
					</div>
					<div class="col-sm-6 text-right">
						<span class="ft-size16">{{ clientHealthDashboardService.gumballClientsAll?.length }} Clients</span>
					</div>
				</div>

				<!--Gray Divider-->
				<hr class="gray-border-darker mgn-bottom30 mgn-top0" />

				<!--Client Details Box-->
				<ng-container
					*ngIf="clientHealthViewService.hoveredClientOrPlayer && clientHealthDashboardService.isGumballClients(clientHealthViewService.hoveredClientOrPlayer)"
				>
					<div
						style="border-radius: 10px; z-index: 100"
						id="clientInfoBox"
						[ngStyle]="{ 'top.px': clientHealthViewService.darkInfoBoxTop, 'left.px': clientHealthViewService.darkInfoBoxLeft }"
						class="dark-bg white-text pd15 wdth360 fixed ft-size14"
					>
						<h3 class="semi-bold in-block mgn-right10">
							{{ clientHealthViewService.hoveredClientOrPlayer.ClientName }} |
							<em class="light">{{ clientHealthViewService.hoveredClientOrPlayer.CsrName }}</em>
							<div
								style="border-radius: 50%; height: 14px; width: 14px"
								class="in-block {{ clientHealthViewService.hoveredClientOrPlayer.gumballColorView }} relative left10 shadow"
							>
								<i
									*ngIf="clientHealthViewService.hoveredClientOrPlayer.showX"
									class="fas fa-times absolute center-unknown-height-width ft-size12 white-text"
								></i>
							</div>
						</h3>

						<div class="row mgn-top15">
							<div class="col-sm-10">
								<span>Players Not Checked In:</span>
								<br />
								<span>Players Not Installed:</span>
								<br />
								<span>Players Not Fixed:</span>
								<br />
								<span>Players Outdated:</span>
								<br />
								<span>Contracts Up For Renewal:</span>
								<br />
								<span>Open Tickets:</span>
								<br />
								<span>Oldest Open Ticket In Days:</span>
								<br />
								<span>Open Content Requests:</span>
								<br />
								<span>Oldest Open Content Request In Days:</span>
								<br />
								<span>Players Calling In Too Often:</span>
								<br />
								<br />
								<span>Rule Failure List:</span>
							</div>
							<div class="col-sm-2 text-right">
								<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.PlayerNotCheckedInCount }}</span>
								<br />
								<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.PlayerNotInstalledCount }}</span>
								<br />
								<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.PlayerNotFixedCount }}</span>
								<br />
								<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.PlayerNotOnCurrentVersionCount }}</span>
								<br />
								<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.ContractsUpForRenewalCount }}</span>
								<br />
								<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.OpenTicketCount }}</span>
								<br />
								<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.OldestOpenTicketInDays }}</span>
								<br />
								<span class="semi-bold">{{ clientHealthViewService.hoveredClientOrPlayer.OpenContentRequestCount }}</span>
								<br />
								<span class="semi-bold">
									{{ clientHealthViewService.hoveredClientOrPlayer.OldestOpenContentRequestInDays }}
								</span>
								<br />
								<span class="semi-bold">
									{{ clientHealthViewService.hoveredClientOrPlayer.PlayersCallingInTooOftenCount }}
								</span>

								<br />
								<br />
							</div>
						</div>
						<div class="row">
							<div class="col-xs-12">
								<span class="semi-bold" [innerHTML]="clientHealthViewService.hoveredClientOrPlayer.ruleFailureListView"></span>
							</div>
						</div>
					</div>
				</ng-container>

				<div
					*ngFor="let circle of clientHealthDashboardService.statusCircles"
					id="{{ circle.ClientId }}"
					(mouseover)="onClientCircleHover(circle)"
					(mouseleave)="clientHealthViewService.onCircleLeave()"
					(click)="onClientClick(circle)"
					style="border-radius: 50%; height: 16px; width: 16px"
					class="in-block {{ circle.gumballColorView }} relative shadow mgn-bottom10 mgn-right10"
				>
					<i *ngIf="circle.showX" class="fas fa-times absolute center-unknown-height-width ft-size12 white-text"></i>
				</div>
			</div>
		</div>
		<div class="col-sm-4">
			<div style="border-radius: 16px" class="white-bg box-shadow pd15 ht500 scrollable">
				<div class="row mgn-bottom10">
					<div class="col-sm-7">
						<h3 id="tasks-title" [routerLink]="['/system-tools/csr-tasks']" class="pointer regular in-block" style="text-decoration: underline">My Tasks</h3>
						<span class="ft-size18">|</span>
						<em class="light ft-size18">Due Today ({{ csrHomeService.myTasks?.length }})</em>
					</div>
					<div class="col-sm-5">
						<button (click)="onShowCompletedTasksClick()" class="ft-size12 {{ showCompletedOnly ? 'gray-bg-darkest white-text' : 'gray-bg' }}">
							Show {{ showCompletedOnly ? 'uncompleted' : 'completed' }} tasks
						</button>
					</div>
				</div>

				<!--Gray Divider-->
				<hr class="gray-border-darker mgn-bottom15 mgn-top0" />
				<p *ngIf="csrHomeService.myTasks?.length === 0" class="gray-text-darkest">No tasks here.</p>
				<table *ngIf="csrHomeService.myTasks?.length > 0" style="border-spacing: 10px !important; border-collapse: initial; width: 100%">
					<thead>
						<tr>
							<th></th>
							<th></th>
							<th style="font-weight: initial" class="gray-text-darkest">CLIENT</th>
							<th style="font-weight: initial" class="gray-text-darkest">SUBJECT</th>
							<th style="font-weight: initial" class="gray-text-darkest">TYPE</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let task of csrHomeService.myTasks">
							<td>
								<button class="gray-bg pd-right5 pd-left5" (click)="onEditTaskClick(task)"><i class="fas fa-pencil-alt"></i></button>
							</td>
							<td>
								<button id="complete-btn" (click)="onCompleteTaskClick(task)" class="client-health-green white-text pd-right5 pd-left5">
									<i class="{{ task.completeBtn }}"></i>
								</button>
							</td>
							<td>
								<p class="{{ task.Completed ? 'light' : 'regular' }} pointer" (click)="onTaskClientNameClick(task)">
									<a *ngIf="!task.Completed" style="color: initial; text-decoration: underline" id="client-name">{{ task.Client.Name }}</a>
									<a *ngIf="task.Completed" style="color: initial; text-decoration: underline" id="client-name">
										<em>{{ task.Client.Name }}</em>
									</a>
								</p>
							</td>
							<td>
								<p>
									<em>{{ task.Subject }}</em>
								</p>
							</td>
							<td>
								<p>
									{{ task.CsrTaskType.Name }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="col-sm-4">
			<div style="border-radius: 16px" class="white-bg box-shadow pd15 ht500 scrollable">
				<div class="row mgn-bottom15">
					<div class="col-sm-12">
						<h3
							id="content-requests-title"
							[routerLink]="['/system-tools/content-requests']"
							class="pointer regular in-block mgn-right8"
							style="text-decoration: underline"
						>
							My Active Content Requests
						</h3>
						<em class="light ft-size18">({{ csrHomeService.myRequests?.length }})</em>
					</div>
				</div>

				<!--Gray Divider-->
				<hr class="gray-border-darker mgn-bottom15 mgn-top0" />
				<p *ngIf="csrHomeService.myRequests?.length === 0" class="gray-text-darkest">No active requests.</p>
				<table *ngIf="csrHomeService.myRequests?.length > 0" style="border-spacing: 10px !important; border-collapse: initial; width: 100%">
					<thead>
						<tr>
							<th></th>
							<th style="font-weight: initial" class="gray-text-darkest">CLIENT</th>
							<th style="font-weight: initial" class="gray-text-darkest">TITLE</th>
							<th style="font-weight: initial" class="gray-text-darkest">STATUS</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let request of csrHomeService.myRequests">
							<td>
								<button (click)="onContentRequestClick(request)" class="{{ request.viewBtn }} white-text pd-right5 pd-left5">
									<i class="fas fa-arrow-right"></i>
								</button>
							</td>
							<td>
								<p class="regular pointer" (click)="onRequestClientNameClick(request)">
									<a style="color: initial; text-decoration: underline" id="client-name">{{ request.ClientName }}</a>
								</p>
							</td>
							<td>
								<p>
									<em>{{ request.ContentRequest.Title }}</em>
								</p>
							</td>
							<td>
								<p>
									{{ request.ContentRequest.StateName }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
