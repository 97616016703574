import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { Feeds } from '../../../shared/api-models/content';
import { FeedItemsView } from '../_models/feed-items-view';
import { mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CreateEditFeedItemService {

    constructor(
        private httpClient: HttpClient) { }

    public getFeedItem(feedId: number, feedItemId: number): Observable<FeedItemsView> {
        return this.httpClient.get(`${environment.contentUrl}FeedItems/${feedItemId}`).pipe(
            mergeMap((feedItem: FeedItemsView) => {
                return this.httpClient.get(`${environment.contentUrl}Feeds/${feedId}`).pipe(
                    map((feed: Feeds) => {
                        feedItem.feedName = feed.Name;
                        feedItem.headline = JSON.parse(feedItem.ItemContent).Title;
                        feedItem.body = JSON.parse(feedItem.ItemContent).Body;
                        feedItem.feedSourceId = feed.FeedSourceId;
                        return feedItem;
                    })
                )
            })
        )
    }

    public getFeeds(): Observable<Feeds[]> {
        return this.httpClient.get<Feeds[]>(`${environment.contentUrl}Feeds/NewsManager`);
    }

    public postFeedItem(feedItem: FeedItemsView): Observable<any> {
        return this.httpClient.post(`${environment.contentUrl}FeedItems`, feedItem);
    }

    public updateFeedItem(feedItem: FeedItemsView): Observable<any> {
        return this.httpClient.put(`${environment.contentUrl}FeedItems/${feedItem.Id}`, feedItem);
    }
}
