import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppStateService } from '../../../core/services';
import { environment } from '../../../../environments/environment';
import { Installers, PlayerHardwareTypes, PlayerInstallStates, PlayerRepairStates, PlayerOutputTypes, PlayerModels, ProductTypes, ServiceLocations, VoipFormats } from '../../../shared/api-models/admin';

@Injectable({
    providedIn: 'root'
})
export class PlayerOptionsService {

    public approvedPlayerVersions: any;
    public holdPlayerModels: PlayerModels[];
    public videoPlayerModels: PlayerModels[];
    public installStates: PlayerInstallStates[];
    public locations: ServiceLocations[];
    public outputTypes: PlayerOutputTypes[];
    public productTypes: ProductTypes[];
    public radioPlayerModels: PlayerModels[];
    public repairStates: PlayerRepairStates[];
    public voipFormats: VoipFormats[];

    constructor(
        private appStateService: AppStateService,
        private httpClient: HttpClient
    ) { }

    public getApprovedPlayerVersions(playerModelId: number): void {
        this.httpClient.get(`${environment.adminUrl}ApprovedPlayerVersions/Player/${playerModelId}`)
            .subscribe( (res) => {
                this.approvedPlayerVersions = res;
            })
    }

    public getHoldPlayerModels(): Observable<PlayerModels[]> {
        if (this.holdPlayerModels) {
            return of(this.holdPlayerModels);
        }
        return this.httpClient.get(environment.adminUrl + `CorePlayerModels`).pipe(
            map((playerModels: PlayerModels[]) => {
                return playerModels.filter((model) => this.isHoldModel(model));
            })
        )
    }

    public getPlayerHardwareTypes(): Observable<PlayerHardwareTypes[]> {
        return this.httpClient.get<PlayerHardwareTypes[]>(`${environment.adminUrl}CorePlayerHardwareTypes`);
    }

    public getVideoPlayerModels(): Observable<PlayerModels[]> {
        if (this.videoPlayerModels) {
            return of(this.videoPlayerModels);
        }
        return this.httpClient.get(environment.adminUrl + `CorePlayerModels`).pipe(
            map((playerModels: PlayerModels[]) => {
                return playerModels.filter((model) => [128, 1000000, 1000001, 1000007].some(modelId => modelId === model.Id));
            })
        )
    }

    private isHoldModel(model: PlayerModels): boolean {
        return model.PlayerModelTypeId === 1 ||
            model.PlayerModelTypeId === 2 ||
            model.PlayerModelTypeId === 3 ||
            model.PlayerModelTypeId === 4 ||
            model.PlayerModelTypeId === 1000009;
    }

    public getInstallStates(): void {
        this.httpClient.get<PlayerInstallStates[]>(`${environment.adminUrl}CorePlayerInstallStates`)
            .subscribe((installStates: PlayerInstallStates[]) => {
                this.installStates = installStates;
            })
    }

    public getRepairStates(): void {
        this.httpClient.get<PlayerRepairStates[]>(`${environment.adminUrl}CorePlayerRepairState`)
            .subscribe((repairStates: PlayerRepairStates[]) => {
                this.repairStates = repairStates;
            })
    }

    public getProductTypes(isVideo?: boolean): void {
        this.httpClient.get<ProductTypes[]>(`${environment.adminUrl}CoreProductTypes`)
            .subscribe((productTypes: ProductTypes[]) => {
                if (isVideo) {
                    this.productTypes = productTypes.filter(productType => this.onlyVideoProducts(productType));
                    return;
                }
                this.productTypes = productTypes.filter(productType => productType.Id !== 8); //Other Product or Service
            })
    }

    public getRadioPlayerModelTypes(): void {
        if (!this.radioPlayerModels) {
            this.httpClient.get(environment.adminUrl + `CorePlayerModels`)
                .subscribe((playerModels: PlayerModels[]) => {
                    this.radioPlayerModels = playerModels.filter(playerModel => playerModel.Id === 55 || playerModel.Id === 56 || playerModel.Id === 1000005 || playerModel.Id === 1000011);
                })
        }
    }

    public getServiceLocations(): void {
        if (!this.locations) {
            this.httpClient.get(environment.adminUrl + `CoreServiceLocations/Client/${this.appStateService.currentClient.Id}`)
                .subscribe((locations: ServiceLocations[]) => {
                    this.locations = locations;
                })
        }
    }

    public getVoipFormats(): Observable<VoipFormats[]> {
        if (this.voipFormats) {
            return of(this.voipFormats);
        }
        return this.httpClient.get<VoipFormats[]>(environment.adminUrl + `CoreVoipFormats`);
    }

    public onInstallerSearchKeyup(searchTerm: string): Observable<Installers[]> {
        if (searchTerm.length > 0) {
            return this.httpClient.get<Installers[]>(`${environment.adminUrl}CoreInstallers/Search/${searchTerm}?includeInactive=false`);
        }
    }

    public validateMacAddress(macAddress: string): Observable<any> {
        return this.httpClient.get(`${environment.adminUrl}CorePlayers/ByMac/${macAddress}`)
    }

    private onlyVideoProducts(productType: ProductTypes): boolean {
        return productType.Id !== 1
            && productType.Id !== 2
            && productType.Id !== 8;
    }

    public getOutputTypes(): void {
        this.httpClient.get<PlayerOutputTypes[]>(`${environment.adminUrl}CorePlayerOutputTypes`)
            .subscribe((outputTypes: PlayerOutputTypes[]) => {
                this.outputTypes = outputTypes;
            })
    }
}
