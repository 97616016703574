import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { ContentItem } from '../../shared/view-models/content';
import { environment } from '../../../environments/environment';
import { ContentLibraries } from '../../shared/api-models/content';
import { ContentSerializerService } from '../../core/services/content-serializer.service';
import { ContentService } from '../../shared/components/content-container/content/_services';
import { PlayerCapacityService } from './player-capacity.service';
import { PlaylistService } from './playlist.service';
import { UtilitiesService } from '../../core/services/utilities.service';

@Injectable({
	providedIn: 'root'
})
export class PlaylistItemService {
	private scheduleExitSource = new Subject<any>();
	scheduleExit$ = this.scheduleExitSource.asObservable();

	constructor(
		private contentSerializerService: ContentSerializerService,
		private contentService: ContentService,
		private httpClient: HttpClient,
		private playerCapacityService: PlayerCapacityService,
		private playlistService: PlaylistService,
		private utilService: UtilitiesService
	) {}

	public onScheduleExit(): void {
		this.scheduleExitSource.next();
	}

	public getContentFiles(contentItem: ContentItem): void {
		let content = this.contentSerializerService.contentItemToContent(contentItem);
		this.contentService.getContentFiles$(content).subscribe(() => {
			contentItem.contentFiles = this.contentSerializerService.contentToContentItem(content).contentFiles;
		});
	}

	//Preview button color code is based on the content's library name
	public getContentLibrary(libraryId: number): Observable<ContentLibraries> {
		return this.httpClient.get<ContentLibraries>(`${environment.contentUrl}ContentLibraries/${libraryId}`);
	}

	public onRemoveItemClick(selectedContentItem: ContentItem): void {
		let indexView = this.playlistService.playlist.contentItems.indexOf(selectedContentItem);

		this.playlistService.playlist.contentItems.splice(indexView, 1);
		this.playlistService.updatePlaylistDuration();
		this.updatePlaylistOrderOnRemove(selectedContentItem);
		this.playlistService.validatePlaylist();
		this.playerCapacityService.warningMessage = this.playerCapacityService.setWarningMessage();
	}

	public setLibraryTypeColorCode(contentItem: ContentItem): string {
		switch (true) {
			case this.utilService.includes(contentItem.libraryName, 'Custom'):
				return 'green';
			case this.utilService.includes(contentItem.libraryName, 'General'):
			case this.utilService.includes(contentItem.libraryName, 'External'):
				return 'blue-bg-darkest';
			case this.utilService.includes(contentItem.libraryName, 'Dynamic'):
			case this.utilService.includes(contentItem.libraryName, 'News'):
				return 'red';
			default:
				return 'yellow';
		}
	}

	private updatePlaylistOrderOnRemove(selectedContentItem: ContentItem): void {
		this.playlistService.orderCount--;
		selectedContentItem.order = this.playlistService.orderCount;
		let orderIndex: number = this.playlistService.orderArray.indexOf(selectedContentItem.order);

		let numbersBeforeIndex: any[] = this.playlistService.orderArray.slice(0, orderIndex);
		let numbersAfterIndex: any[] = this.playlistService.orderArray.slice(orderIndex + 1);

		let numbersAfterIndexMinus1: any[] = [];

		numbersAfterIndex.forEach((number) => {
			let newNumber = number - 1;
			numbersAfterIndexMinus1.push(newNumber);
		});

		this.playlistService.orderArray = numbersBeforeIndex.concat(numbersAfterIndexMinus1);
		this.playlistService.orderArray.forEach((number) => {
			if (number === 0) {
				this.playlistService.orderArray.splice(0, 1);
			}
		});
	}
}
