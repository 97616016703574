import { Injectable } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

import { ContentVoiceTalents } from '../../shared/api-models/content';
import { Events } from '../../core/services/events';
import { MessageService } from '../../core/services/message.service';
import { FileUploadViewState } from '../view-models';
import { SequenceStep } from '../view-models';
import { UploadData, UploadDataCounterpart } from 'src/app/shared/api-models/content';
import { ContentVM } from '../components/content-container/content/_models/content-view';

@Injectable({
	providedIn: 'root'
})
export class FileUploadStateService {
	public activeSequence: SequenceStep[];
	public counterpartProps: { contentId: number; targetLibraryId: number };
	public counterpartOrReplace: 'counterpart' | 'replace' | null;
	public file: File | any;
	public itemId: number;
	public replacementProps: { contentFileId: number; targetLibraryId: number };
	public percentComplete: number;
	public selectedContent: ContentVM; //when uploading counterpart or replacing content file
	public uploadComplete: boolean;
	public uploader: FileUploader = new FileUploader({});
	public uploadData: UploadData = new UploadData('STRETCH');
	public validationError: boolean;
	public voiceTalent: ContentVoiceTalents;
	public viewState: FileUploadViewState;

	constructor(private messageService: MessageService) {}

	public setState(
		file: any,
		counterpartOrReplace?: 'counterpart' | 'replace' | null,
		content?: ContentVM,
		counterpartProps?: { contentId: number; targetLibraryId: number },
		replacementProps?: { contentFileId: number; targetLibraryId: number },
		itemId?: number
	): void {
		this.viewState = new FileUploadViewState();
		this.uploader = new FileUploader({});

		//https://stackoverflow.com/questions/32106243/regex-to-remove-all-non-alpha-numeric-and-replace-spaces-with/32106277
		const fileName = file.name
			.split('.')
			.shift()
			.replace(/[^a-z0-9+]+/gi, '_'); //extract name only, remove illegal chars
		const ext = file.name.split('.').pop(); //extract extension only

		this.file = new File([file], `${fileName}.${ext}`, { type: file.type });
		this.itemId = itemId;
		if (counterpartOrReplace) {
			this.counterpartOrReplace = counterpartOrReplace;
		}
		if (content) {
			this.selectedContent = content;
		}
		if (counterpartProps) {
			this.counterpartProps = counterpartProps;
		}
		if (replacementProps) {
			this.replacementProps = replacementProps;
		}
	}

	public uploaderInit(): void {
		this.uploader = new FileUploader({});
	}

	public advance(itemId?: number): void {
		const currentStepIndex = this.activeSequence.findIndex((step) => step.isCurrentStep);
		this.activeSequence[currentStepIndex].isCurrentStep = false;
		this.activeSequence[currentStepIndex + 1].isCurrentStep = true;
		this.messageService.publish(Events.fileUploadAdvanceSequence, itemId);
	}

	public currentStep(): SequenceStep {
		if (this.activeSequence) {
			return this.activeSequence.find((step) => step.isCurrentStep);
		}
		return new SequenceStep();
	}

	public resetSteps(): void {
		if (this.activeSequence) {
			let currentStepIndex = this.activeSequence.findIndex((step) => step.isCurrentStep);
			this.activeSequence[currentStepIndex].isCurrentStep = false;
			this.activeSequence[0].isCurrentStep = true;
		}
		this.uploadData = new UploadData('STRETCH');
	}

	public counterpartUploadData(): any {
		const uploadData: UploadDataCounterpart = {
			ContentId: this.counterpartProps.contentId,
			VoiceTalentId: this.uploadData.VoiceTalentId
		};
		return uploadData;
	}
}
