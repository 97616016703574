import { Component, Input, OnInit } from '@angular/core';

import { ContentViewService } from '../../_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { UtilitiesService } from 'src/app/core/services';
import { ContentType } from 'src/app/shared/view-models/content/content-type.enum';
import { ContentVM } from '../../_models/content-view';

@Component({
	selector: 'vc-newsletter-view',
	templateUrl: './newsletter-view.component.html',
	styleUrls: ['./newsletter-view.component.scss']
})
export class NewsletterViewComponent implements OnInit {
	@Input() content: ContentVM;
	@Input() contentList: ContentVM[];
	@Input() isEvenIndex: boolean;
	@Input() isFirstIndex: boolean;
	@Input() isLastIndex: boolean;

	readonly contentType = ContentType;

	constructor(public contentViewService: ContentViewService, public cvStateService: ContentViewStateService, public utilService: UtilitiesService) {}

	ngOnInit(): void {}

	public onPlayClick(
		content: ContentVM,
		isLastIndex?: boolean,
		prop?: 'talentPreviewSelectorVisible' | 'talentPickerVisible' | 'playlist-details' | 'newsletter',
		contentList?: ContentVM[]
	): void {
		this.contentViewService.onPlayClick(content, isLastIndex, prop, contentList);
	}
}
