import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { AppStateService, DateTimeService, SecurityService, UtilitiesService } from '../../../../core/services';
import { ClientDetailsService } from '../../../../crm/client-details/client-details.service';
import { ClientHealthDashboardService } from 'src/app/system-tools/client-health-dashboard/client-health-dashboard.service';
import { LocationsService } from '../../../../crm/locations/locations.service';
import { ProductTypes, Users } from '../../../../shared/api-models/admin';

@Component({
	selector: 'hd-w24-employee-only-mega-menu',
	templateUrl: './w24-employee-only-mega-menu.component.html',
	styleUrls: ['./w24-employee-only-mega-menu.component.scss'],
	providers: [LocationsService]
})
export class W24EmployeeOnlyMegaMenuComponent implements OnInit {
	@Output() buttonClickNotify: EventEmitter<any> = new EventEmitter<any>();

	public contractedProducts$: Observable<ProductTypes[]>;
	public lastLoginUser$: Observable<Users>;
	public products$: Observable<any[]>;
	public showBorder: boolean;

	constructor(
		public appStateService: AppStateService,
		private clientDetailsService: ClientDetailsService,
		public clientHealthDashboardService: ClientHealthDashboardService,
		public dateTimeService: DateTimeService,
		public locationsService: LocationsService,
		private router: Router,
		public securityService: SecurityService,
		public utilService: UtilitiesService
	) {}

	ngOnInit() {
		this.locationsService.getAllLocationsForClient().subscribe();
		this.contractedProducts$ = this.clientDetailsService.getContractedProducts$();
		this.lastLoginUser$ = this.clientDetailsService.getLastLogin$();
	}

	public onButtonClick(stringId: string): void {
		switch (stringId) {
			case 'clientDetails':
				this.router.navigate(['/crm/client-details/locations']);
				break;

			case 'historyNote':
				this.router.navigate([`/crm/new-history-note`]);
				break;
			case 'createNewClient':
				this.router.navigate(['/crm/create-new-client']);
				break;
		}
		this.buttonClickNotify.emit();
	}
}
