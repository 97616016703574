<div class="border-radius shadow white-bg mgn-bottom30 relative">
	<div class="title pd15 gray-border white-bg border-radius">
		<h3 class="ellipsis semi-bold">{{ content.Title }}</h3>
	</div>
	<hr class="gray-border mgn-top0 mgn-bottom30 mgn-right15 mgn-left15" />

	<div class="row container-fluid mgn-top10 pd-bottom15">
		<div class="col-xs-7">
			<div *ngIf="!previewActive" class="in-block relative center">
				<div
					class="in-block overlay absolute setWidth100 z {{ content?.contentType === 'video/mp4' ? 'pointer' : null }} white-text"
					(click)="onPreviewClick()"
				>
					<span>
						<i class="fa {{ content.thumbIcon }} ft-size22 absolute center-unknown-height-width" aria-hidden="true"></i>
					</span>
				</div>
				<img src="{{ content.thumbnailUrl }}" class="img-responsive border-radius box-shadow" />
			</div>

			<div *ngIf="previewActive" style="width: 125%" class="relative">
				<video
					#video
					*ngIf="content.contentType === 'video/mp4'"
					src="{{ content.previewUrl }}"
					type="video/mp4"
					class="setWidth100 border-radius relative z box-shadow"
					autoplay
					controls="true"
					controlsList="nodownload"
				>
					Oops! Your browser does not support this preview.
				</video>
				<i
					(click)="previewActive = false"
					style="z-index: 100; right: -2.5rem"
					class="fas fa-times dark-text pd4 absolute topneg12 pointer ft-size26"
					aria-hidden="true"
				></i>

				<img
					*ngIf="content?.contentType === 'image/png'"
					src="{{ content.previewUrl }}"
					width="300"
					height="200"
					class="border-radius relative box-shadow z img-responsive"
				/>
			</div>
		</div>
		<div class="col-xs-5 text-right">
			<button
				(click)="showScript = !showScript"
				[disabled]="content.Text.length < 1"
				[ngClass]="{ 'gray-bg': content.Text.length > 0, 'lt-gray-bg gray-text-dark': content.Text.length < 1 }"
				class="mgn-bottom12 setWidth50 relative"
			>
				<i class="{{ showScript ? 'fas fa-times' : 'far fa-file-alt' }}"></i>
			</button>
			<br />

			<button
				*ngIf="canUseInPlaylist && !playerCapacityService.showWarning"
				(mouseup)="addToPlaylistService.onAddClick(content)"
				class="gray-bg setWidth50 mgn-bottom12 relative"
			>
				<i class="fa fa-plus" aria-hidden="true"></i>
			</button>
			<button
				*ngIf="!canUseInPlaylist || playerCapacityService.showWarning"
				class="gray-bg-darker cursor-not-allowed setWidth50 white-text mgn-bottom12 relative"
			>
				<i class="fa fa-plus" aria-hidden="true"></i>
			</button>

			<br *ngIf="cvStateService.viewState.treeSelection !== 'featured-playlist'" />

			<button
				*ngIf="cvStateService.viewState.treeSelection !== 'featured-playlist'"
				(mouseup)="contentFavoritesService.onFavoriteClick(content)"
				class="{{ content.isFavorite ? 'pink-bg' : 'white-bg' }} relative white-text setWidth50"
			>
				<i class="fas fa-heart {{ content.isFavorite ? 'white-text' : 'pink-text' }}" aria-hidden="true"></i>
				<i *ngIf="content.isFavorite" class="fa fa-check absolute ft-size10 top2 right2" aria-hidden="true"></i>
			</button>
		</div>
	</div>

	<div *ngIf="showScript" style="right: 23%" class="absolute setWidth75 top66 z">
		<div class="setWidth100 relative transparent-bg max-height124 white-text pd8 border-radius scrollable ft-size18">
			<span>
				{{ content.Text }}
			</span>
		</div>
	</div>
</div>
