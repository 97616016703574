export enum FeatureArea {
	CONTENT_MANAGER = 'content-manager',
	PLAYLIST_DETAILS = 'playlist-details',
	FEATURED_CONTENT = 'featured-content',
	PLAYLIST_BUILDER = 'playlist-builder',
	MY_PLAYERS = 'my-players',
	CREATE_24 = 'create-24',
	NEWSLETTER = 'newsletter',
	CUSTOM_DATE_FEEDS = 'custom-data-feeds',
	NONE = 'none'
}
