<portal-accordion
				[angleIconClass]="detailsVisible ? 'angle-down' : 'angle-right'"
				[backgroundColorClass]="backgroundColor"
				[itemName]="contract.name"
				[removeBtnVisible]="true"
				(removeClickNotify)="onRemoveContractNotify.emit(contract)"
				(toggleNotify)="detailsVisible = !detailsVisible">
</portal-accordion>
<div [ngClass]="detailsVisible ? '' : 'hidden'" class="mgn-top15 mgn-bottom15">
	<div class="row">
		<div class="col-sm-6 relative">
			<span class="semi-bold">Salesperson:</span><br>
			<portal-dropdown-menu
									[backgroundColor]="'gray-bg-dark'"
                                    [height]="'ht40'"
                                    [key]="'Name'"
									[options]="activeSalesmen"
									[selectedOption]="'Select a salesperson...'"
									(onOptionSelect)="onDropdownSelect($event, 'SalespersonId')">
			</portal-dropdown-menu>
			<i class="fa fa-asterisk red-text-light ft-size8 absolute right3 top20" aria-hidden="true"></i>
		</div>
		<div class="col-sm-6 relative">
			<span class="semi-bold">Locations:</span><br>
			<input type="number" class="setWidth100 ht40 gray-bg-dark" min="0"  [(ngModel)]="contract['NumLocations']" (input)="onInputChange()">
			<i class="fa fa-asterisk red-text-light ft-size8 absolute right3 top20" aria-hidden="true"></i>
		</div>
	</div>
	<div class="row mgn-top15">
		<div class="col-sm-6 relative">
			<span class="semi-bold">Sale Date:</span><br>
			<portal-date-time-selector
                    [backgroundColor]="'gray-bg-dark'"
                    [height]="'ht40'"
                    (dateChanged)="onDateChanged($event, 'SaleDate')">
            </portal-date-time-selector> 
        	<i class="fa fa-asterisk red-text-light ft-size8 absolute right3 top20" aria-hidden="true"></i>
		</div>
		<div class="col-sm-6 relative">
			<span class="semi-bold">Total Retail:</span><br>
			<input type="number" class="setWidth100 gray-bg-dark ht40" min="0"  [(ngModel)]="contract['TotalValue']" (input)="onInputChange()">
			<i class="fa fa-asterisk red-text-light ft-size8 absolute right3 top20" aria-hidden="true"></i>
		</div>
	</div>
	<div class="row mgn-top15">
		<div class="col-sm-6 relative">
			<span class="semi-bold">Term:</span><br>
			<portal-dropdown-menu
									[backgroundColor]="'gray-bg-dark'"
                                    [height]="'ht40'"
                                    [key]="'name'"
									[options]="contractTermsService.contractTerms"
									[selectedOption]="contractTermsService.contractTerms[0].name"
									(onOptionSelect)="onDropdownSelect($event, 'ContractTermId')">
			</portal-dropdown-menu>
			<i class="fa fa-asterisk red-text-light ft-size8 absolute right3 top20" aria-hidden="true"></i>
		</div>
		<div class="col-sm-6 relative">
			<span class="semi-bold">Monthly (Main Location):</span><br>
			<input type="number" class="setWidth100 ht40 gray-bg-dark" min="0" [(ngModel)]="contract['MainLocMonthly']" (input)="onInputChange()">
			<i class="fa fa-asterisk red-text-light ft-size8 absolute right3 top20" aria-hidden="true"></i>
		</div>
	</div>
	<div class="row mgn-top15">
		<div class="col-sm-6 relative">
			<span class="semi-bold">Renew Date:</span><br>
			<portal-date-time-selector
                    [backgroundColor]="'gray-bg-dark'"
                    [height]="'ht40'"
                    (dateChanged)="onDateChanged($event, 'RenewDate')">
            </portal-date-time-selector> 
        	<i class="fa fa-asterisk red-text-light ft-size8 absolute right3 top20" aria-hidden="true"></i>
		</div>
		<div class="col-sm-6 relative">
			<span class="semi-bold">Monthly (Additional Locations):</span><br>
			<input type="number" class="setWidth100 ht40 gray-bg-dark" min="0"  [(ngModel)]="contract['DupLocMonthly']" (input)="onInputChange()">
			<i class="fa fa-asterisk red-text-light ft-size8 absolute right3 top20" aria-hidden="true"></i>
		</div>
	</div>
	<div class="row mgn-top15">
		<div class="col-sm-12">
			<span class="semi-bold">Notes:</span><br>
			<textarea rows="6" [(ngModel)]="contract['Notes']"></textarea>
		</div>
	</div>
	<div class="row mgn-top15">
		<div class="col-sm-6">
			<label class="semi-bold mgn-right10 pointer" for="exempt">Exempt From Renewal Stats:</label>
			<input type="checkbox" id="exempt" [(ngModel)]="contract['IsExemptFromStats']">
		</div>
	</div>
</div>


