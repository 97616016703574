import { ContentAdvancedSchedules } from '../../api-models/content';
import { VoipFormats } from '../admin';

export class DeliveryQueue {
	Id: number;
	DeliveryQueueMessageTypeId: number;
	ProductId: number;
	ClientId: number;
	UserId: number;
	PlayerId: number;
	PlayerModelId: number;
	PlayerModelTypeId: number;
	IsExclusivePlaylist: boolean;
	PlaylistId: number;
	PlaylistName: string;
	PlaylistLastUpdate: string;
	UnitIdentifier: string;
	PlaylistJson: string;
	DeliveryJson: string;
	SavedOn: string;
	DeliveryStatusId: number;
	DeliverOn: string;
	DeliverStart: string;
	DeliverComplete?: string;
	StartDate?: string;
	StopDate?: string;
	PlaylistAdvancedSchedulesId?: number;
	StatusJson: string;
	PlaylistAdvancedSchedule: ContentAdvancedSchedules;
	IsHighPriority: boolean;
	MemoryCapacity: number;
	SecretId: string;
	BatchIdentifier: string;
	DeliverTo: string;

	startDateView?: string;
}

export class DeliveryJson {
	PlayerId: number;
	ModelTypeName: string;
	ModelTypeId: number;
	ClientName: string;
	LocationName: string;
	VoipNotify: boolean;
	PlayerName: string;
	IsLongDistance: boolean;
	RemoteAreaCode: string;
	RemotePhone: string;
	RemotePassword: string;
	HoldVolume: number;
	VoipFormatId: number;
	VoipFormat: VoipFormats;
	VoipFileModeId: number;
}
