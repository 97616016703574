export enum ContentType {
	HOLD_MUSIC = 1,
	HOLD_MESSAGE = 2,
	HOLD_CHANNEL = 3,
	IMAGE_STATIC = 4,
	VIDEO_STATIC = 6,
	VIDEO_CHANNEL = 8,
	VIDEO_CAPTURE = 9,
	IMAGE_DYNAMIC_WEATHER = 10,
	IMAGE_DYNAMIC_STOCKS = 11,
	IMAGE_DYNAMIC_NEWS = 12,
	IMAGE_DYNAMIC_COMMODITIES = 13,
	IMAGE_DYNAMIC_MARKETS = 14,
	IMAGE_DYNAMIC_TRIVIA = 15,
	IMAGE_DYNAMIC_HISTORY = 16,
	IMAGE_DYNAMIC_BIRTHDAYS = 17,
	IMAGE_DYNAMIC_QUOTES = 18,
	IMAGE_DYNAMIC_DATE = 19,
	IMAGE_DYNAMIC_COUNTDOWN = 20,
	IMAGE_DYNAMIC_FACEBOOK = 21,
	IMAGE_DYNAMIC_TWITTER = 22,
	IMAGE_DYNAMIC_INSTAGRAM = 23,
	IMAGE_DYNAMIC_SCREENFEED_MEDIA_RSS = 24,
	IMAGE_DYNAMIC_CUSTOM = 25,
	VIDEO_DYNAMIC_WEATHER = 26,
	VIDEO_DYNAMIC_STOCKS = 27,
	VIDEO_DYNAMIC_NEWS = 28,
	VIDEO_DYNAMIC_COMMODITIES = 29,
	VIDEO_DYNAMIC_MARKETS = 30,
	VIDEO_DYNAMIC_TRIVIA = 31,
	VIDEO_DYNAMIC_HISTORY = 32,
	VIDEO_DYNAMIC_BIRTHDAYS = 33,
	VIDEO_DYNAMIC_QUOTES = 34,
	VIDEO_DYNAMIC_DATE = 35,
	VIDEO_DYNAMIC_COUNTDOWN = 36,
	VIDEO_DYNAMIC_FACEBOOK = 37,
	VIDEO_DYNAMIC_TWITTER = 38,
	VIDEO_DYNAMIC_INSTAGRAM = 39,
	VIDEO_DYNAMIC_SCREENFEED = 40,
	VIDEO_DYNAMIC_CUSTOM = 41,
	CREATE24_BACKGROUND_VIDEO = 42,
	CREATE24_BACKGROUND_IMAGE = 43,
	CREATE24_IMAGE = 44,
	VIDEO_SCREEN_LAYOUT = 1000001,
	VIDEO_WIDGET_IMAGE = 1000002,
	CREATE24V4_PLACED_IMAGE = 1000005,
	CREATE24V4_IMAGE = 1000006,
	CREATE24V4_VIDEO = 1000007,
	UNKNOWN = 1000008,
	VIDEO_WIDGET_CREATE24 = 1000009
}
