import { Component, Input, ViewChild, OnInit, ElementRef } from '@angular/core';

import { CanvasService } from '../../../canvas/_services/canvas.service';
import { CountdownAndDateService } from '../../../create-layer-sidebar/countdown-and-date/countdown-and-date.service';
import { Create24Service } from 'src/app/user-tools/create-24/create-24.service';
import { CreateLayerSidebarService } from '../../../create-layer-sidebar/create-layer-sidebar.service';
import { Layer } from '../../../_models';
import { LayersService, UndoRedoService } from '../../../_services';
import { LayersTimelineWrapperService } from '../_services';
import { UtilitiesService } from 'src/app/core/services';

@Component({
	selector: 'ltw-layer',
	templateUrl: './layer.component.html',
	styleUrls: ['./layer.component.scss']
})
export class LayerComponent implements OnInit {
	@Input() layer: Layer;
	@ViewChild('nameInput') nameInput: ElementRef;

	public showLayerOptions: boolean;

	constructor(
		private canvasService: CanvasService,
		private countdownAndDateService: CountdownAndDateService,
		private create24Service: Create24Service,
		private createLayerSidebarService: CreateLayerSidebarService,
		public layersService: LayersService,
		public ltwService: LayersTimelineWrapperService,
		private undoRedoService: UndoRedoService,
		private utilService: UtilitiesService
	) {}

	ngOnInit(): void {}

	public autoCloseDropdown(event) {
		if (!this.utilService.closest(event, '.noExit')) {
			this.showLayerOptions = false;
		}
	}

	public onLayerVisibleClick(): void {
		//If not background layer
		if (this.layer.id !== 0) {
			this.layer.visible = !this.layer.visible;
			//Feed text image objects should always be hidden. Feed text image objects are hidden shadow objects
			//of a feed text layer. C24 interacts with the text object, but the player renders the image object.
			let obj = this.canvasService.canvas
				.getObjects()
				.filter((obj) => !obj.isFeedTextImage)
				.find((obj) => obj.layerId === this.layer.id);
			if (!this.layer.visible) {
				this.showHideLayer(obj, false);
			} else {
				this.showHideLayer(obj, true);
			}
			this.canvasService.canvas.renderAll();
		}
	}

	private showHideLayer(obj, visible: boolean): void {
		obj.set('visible', visible);
		obj.hasControls = visible;
		obj.hasBorders = visible;
	}

	public onLayerSelect(): void {
		this.layersService.activeLayer = this.layer;
		this.layersService.onActiveLayerSelect([this.layer.id, 'layers-panel']);
	}

	public transInIcon(): string {
		if (this.create24Service.contentType() === 'image') {
			return '';
		}
		switch (this.layer.transitionIn) {
			case 'Slide in down':
				return 'fas fa-caret-down';
			case 'Slide in left':
				return 'fas fa-caret-left';
			case 'Slide in right':
				return 'fas fa-caret-right';
			case 'Slide in up':
				return 'fas fa-caret-up';
			case 'Fade in':
				return 'fas fa-angle-double-left';
			default:
				return '';
		}
	}

	public transOutIcon(): string {
		if (this.create24Service.contentType() === 'image') {
			return '';
		}
		switch (this.layer.transitionOut) {
			case 'Slide out down':
				return 'fas fa-caret-down';
			case 'Slide out left':
				return 'fas fa-caret-left';
			case 'Slide out right':
				return 'fas fa-caret-right';
			case 'Slide out up':
				return 'fas fa-caret-up';
			case 'Fade out':
				return 'fas fa-angle-double-right';
			default:
				return '';
		}
	}

	public onOptionClick(option: string): void {
		this.showLayerOptions = false;
		switch (option) {
			case 'delete':
				//Remove deleted layer from layers array
				this.layersService.layers = this.layersService.layers.filter((l) => l.id !== this.layer.id);
				this.layersService.onLayerDelete(this.layer.id);

				this.layersService.adjustLayerIdsOnDelete(this.layer);
				this.undoRedoService.recordState('Delete layer');
				break;
			case 'rename':
				this.layer.nameState = 'edit';
				setTimeout(() => {
					this.nameInput.nativeElement.focus();
				}, 10);
				break;
			case 'edit':
				this.createLayerSidebarService.showCountdownOptionsPopup = true;
				this.countdownAndDateService.mode = 'edit';
				this.countdownAndDateService.view = this.setCountdownView();
				break;
			case 'duplicate':
				this.layersService.onCreateLayerClick(this.layersService.layerCopy(this.layer));
				this.undoRedoService.recordState('Duplicate layer');
				break;
		}
	}

	private setCountdownView(): 'countdown' | 'todaysDate' | 'currentTime' {
		switch (this.layer.type) {
			case 'Countdown':
				return 'countdown';
			case 'Current Date':
				return 'todaysDate';
			case 'Current Time':
				return 'currentTime';
		}
	}

	public onEnter(e): void {
		if (e.keyCode === 13) {
			this.setNameStateAsRead();
		}
	}

	public onCheckClick(): void {
		this.setNameStateAsRead();
	}

	public isEditable(): boolean {
		return this.layer.type === 'Countdown' || this.layer.type === 'Current Date' || this.layer.type === 'Current Time';
	}

	private setNameStateAsRead(): void {
		this.showLayerOptions = false;
		this.layer.nameState = 'read';
		this.undoRedoService.recordState('Rename layer');
	}
}
