export interface ICategories {
	categories: string[];
	subCategories: string[];
}

export enum CategoryPlaceholder {
	CATEGORY = 'Select a category...',
	SUBCATEGORY = 'Select a sub-category...',
	NONE = ''
}
