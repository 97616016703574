<div
	[ngStyle]="{ border: !ssdtService.isScheduleValid ? '1px solid #ec535a' : '1px solid #d4d4d5' }"
	class="container-fluid {{ invertColorTheme ? 'lt-gray-bg' : 'white-bg' }} white-bg border-radius pd-top15 pd-bottom15 relative"
>
	<!--*******************
		ERROR MESSAGE
	*********************-->
	<div
		*ngIf="ssdtService.errorMessageVisible"
		style="z-index: 105; width: 360px"
		class="absolute pd10 top0 right0 border-radius shadow text-center red-bg-light animated pulse white-text"
	>
		<i (mouseup)="ssdtService.errorMessageVisible = false" class="fa fa-times absolute top2 right6 pointer" aria-hidden="true"></i>
		<em>
			<i class="fa fa-exclamation-triangle mgn-right10" aria-hidden="true"></i>
			The stop date should be after the start date!
		</em>
	</div>

	<!--*************************
		DATES VALID INDICATOR
	**************************-->
	<span
		style="z-index: 100"
		ngxTippy
		tippyName="{{ utilService.guid | wrapFn }}"
		[tippyProps]="{ appendTo: 'parent', arrow: false }"
		attr.data-tippy-content="{{ ssdtService.isScheduleValid ? 'Dates are valid' : 'Dates are invalid' }}"
		class="absolute top5 right8 ft-size16"
	>
		<i class="fa {{ ssdtService.isScheduleValid ? 'fa-check lime-green-text' : 'fa-ban red-text-light' }}" aria-hidden="true"></i>
	</span>

	<div class="row">
		<!--*******************
			START DATE
		*********************-->
		<div class="{{ selectTimeHidden ? 'col-sm-5' : 'col-sm-3' }}">
			<span>Start Date</span>
			<div class="relative">
				<portal-date-picker
					[backgroundColor]="'gray-bg-dark'"
					[clearDateEnabled]="clearDateEnabled"
					[height]="'ht40'"
					[model]="ssdtService.view.startDate"
					(dateChanged)="ssdtService.onStartDateChanged($event)"
				></portal-date-picker>
			</div>
		</div>

		<!--*******************
			START TIME
		*********************-->
		<div *ngIf="!selectTimeHidden" class="col-sm-2">
			<span>Start Time</span>
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[key]="'name'"
				[options]="ssdtService.timesOfDay"
				[selectedOption]="ssdtService.view.startTime"
				(onOptionSelect)="ssdtService.onStartTimeChanged($event)"
			></portal-dropdown-menu>
		</div>

		<!--*******************
			STOP DATE TOGGLE
		*********************-->
		<div class="col-sm-2 text-center">
			<span class="hidden-sm">Stop Date</span>
			<br />
			<portal-toggle-buttons
				class="relative"
				[fontSize]="'ft-size12'"
				[isOn]="ssdtService.isStopDateSelected"
				(toggleClickNotify)="onStopDateToggleClick($event)"
			></portal-toggle-buttons>
		</div>

		<!--*******************
			STOP DATE
		*********************-->
		<div class="{{ selectTimeHidden ? 'col-sm-5' : 'col-sm-3' }}">
			<div
				*ngIf="!ssdtService.isStopDateSelected"
				[ngStyle]="{ background: invertColorTheme ? 'rgba(246,248,248,0.6' : 'rgba(255,255,255,0.6' }"
				class="stop-date-off relative z"
			></div>
			<span>Stop Date</span>
			<div class="relative">
				<portal-date-picker
					[backgroundColor]="'gray-bg-dark'"
					[clearDateEnabled]="clearDateEnabled"
					[height]="'ht40'"
					[model]="ssdtService.view.stopDate"
					(dateChanged)="ssdtService.onStopDateChanged($event)"
				></portal-date-picker>
			</div>
		</div>

		<!--*******************
			STOP TIME
		*********************-->
		<div *ngIf="!selectTimeHidden" class="col-sm-2">
			<div
				*ngIf="!ssdtService.isStopDateSelected"
				[ngStyle]="{ background: invertColorTheme ? 'rgba(246,248,248,0.6' : 'rgba(255,255,255,0.6' }"
				class="stop-date-off relative z"
			></div>
			<span>Stop Time</span>
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[key]="'name'"
				[options]="ssdtService.timesOfDay"
				[selectedOption]="ssdtService.view.stopTime"
				(onOptionSelect)="ssdtService.onStopTimeChanged($event)"
			></portal-dropdown-menu>
		</div>
	</div>
</div>
