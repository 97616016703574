import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

import { Events, MessageService } from '../../core/services';
import { Content } from '../../shared/api-models/content';
import { environment } from '../../../environments/environment';
import { ContentVM } from '../components/content-container/content/_models/content-view';

@Injectable()
export class BatchDeleteService {
	public selectAllChecked: boolean;

	constructor(private httpClient: HttpClient, private messageService: MessageService) {}

	public onSelectAllClick(contentArr: ContentVM[]): void {
		this.selectAllChecked = !this.selectAllChecked;
		contentArr.forEach((item) => (item.isSelected = this.selectAllChecked));
	}

	public onDeleteSelectedClick(contentArr: ContentVM[], str: string, urlArg: string): void {
		if (this.atLeastOneSelected(contentArr)) {
			let confirmDelete: boolean = confirm(`Delete all selected ${str}?`);
			if (confirmDelete) {
				this.selectAllChecked = false;
				this.messageService.publish(Events.savingPreloader, 1);

				let deleteObservables = contentArr
					.filter((item) => item.isSelected)
					.map((filteredItem) => {
						return this.httpClient.patch(`${environment.contentUrl}${urlArg}/${filteredItem.Id}`, this.item(str));
					});
				forkJoin(deleteObservables).subscribe(() => {
					//Remove each selected content from current contentList array
					contentArr
						.filter((item) => item.isSelected)
						.forEach((filteredItem) => {
							let index: number = contentArr.findIndex((item) => item.Id === filteredItem.Id);
							contentArr.splice(index, 1);
						});
					this.messageService.publish(Events.savingPreloader, 0);
				});
			}
		}
	}

	public atLeastOneSelected(items: any[]): boolean {
		if (items) {
			return items.some((item) => {
				return item.isSelected;
			});
		}
	}

	private item(str: string): Content {
		let item: any;
		switch (true) {
			case str === 'content':
				item = new Content();
				item.IsDeleted = true;
				break;
			case str === 'playlists':
				// item = new PlaylistsApi();
				// item.IsDeleted = true;
				break;
		}
		return item;
	}
}
