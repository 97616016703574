<div class="row purple-gradient ht2 mgn-top30 border-radius mgn-left0 mgn-right0"></div>

<form (keydown.enter)="$event.preventDefault()" #form="ngForm" novalidate (ngSubmit)="saveService.onSaveClick()">
	<div class="row mgn-top30 mgn-bottom40">
		<div class="col-sm-10 col-sm-offset-2">
			<div class="row mgn-bottom30">
				<div class="col-sm-2">
					<h2 class="semi-bold">General</h2>
				</div>
				<div class="col-sm-2">
					<h3 class="semi-bold in-block">Client ID:</h3>
					<span class="ft-size18">{{ appStateService.currentClient.Id }}</span>
				</div>
				<div class="col-sm-2">
					<h3 class="semi-bold in-block">Location ID:</h3>
					<span class="ft-size18">{{ createEditPlayerService.player.LocationId }}</span>
				</div>
				<div class="col-sm-2 text-right">
					<h3 class="semi-bold in-block">Player ID:</h3>
					<span class="ft-size18">{{ createEditPlayerService.player.Id }}</span>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Name:</span>
				</div>
				<div class="col-sm-6">
					<input
						style="border: 1px solid #d6d9db"
						(keyup)="viewService.onFormChange()"
						class="setWidth100 ht40 gray-bg-dark"
						[ngClass]="{ 'red-border-light': !playerName.valid && !playerName.pristine }"
						name="playerName"
						required
						#playerName="ngModel"
						[(ngModel)]="createEditPlayerService.player.PlayerName"
						type="text"
					/>
					<small class="red-text-light" [hidden]="playerName.valid || (playerName.pristine && !form.submitted)">Player name is required.</small>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<label for="pause" class="semi-bold">Model:</label>
				</div>
				<div class="col-sm-6">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[isDisabled]="createEditPlayerService.viewState === 'edit'"
						[key]="'Name'"
						[options]="playerOptionsService.radioPlayerModels"
						[selectedOption]="createEditPlayerService.player.PlayerModel?.Name"
						(onOptionSelect)="viewService.onPlayerModelSelect($event)"
					></portal-dropdown-menu>
				</div>
			</div>

			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Install State:</span>
				</div>
				<div class="col-sm-6 relative">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[isDisabled]="viewService.installStateDisabled"
						[height]="'ht40'"
						[key]="'Name'"
						[options]="playerOptionsService.installStates"
						[scrollbarHidden]="true"
						[selectedOption]="createEditPlayerService.player.InstallState?.Name"
						(onOptionSelect)="viewService.onInstallStateSelect($event)"
					></portal-dropdown-menu>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
				</div>
			</div>

			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Repair State:</span>
				</div>
				<div class="col-sm-6 relative">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[key]="'Name'"
						[options]="playerOptionsService.repairStates"
						[scrollbarHidden]="true"
						[selectedOption]="createEditPlayerService.player.RepairState?.Name"
						(onOptionSelect)="viewService.onRepairStateSelect($event)"
					></portal-dropdown-menu>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Location:</span>
				</div>
				<div class="col-sm-6">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[key]="'Name'"
						[options]="playerOptionsService.locations"
						[selectedOption]="createEditPlayerService.player.Location?.Name"
						(onOptionSelect)="viewService.onLocationSelect($event)"
					></portal-dropdown-menu>
					<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
				</div>
			</div>

			<div *ngIf="!playerIsHalo" class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Mac Address:</span>
				</div>
				<div class="col-sm-6 relative">
					<ng-container *ngIf="createEditPlayerService.player.PlayerModelId !== 56">
						<input
							style="border: 1px solid #d6d9db"
							(keyup)="viewService.onFormChange()"
							[ngClass]="{ 'red-border-light': (!macAddress.valid || !macValidationService.isMacAddressUnique) && !macAddress.pristine }"
							name="macAddress"
							#macAddress="ngModel"
							[ngModel]="createEditPlayerService.player.MacAddress"
							pattern=".{12}"
							maxlength="12"
							(ngModelChange)="macValidationService.onMacAddressChange($event)"
							type="text"
						/>
						<small class="red-text-light" [hidden]="(macAddress.valid && macValidationService.isMacAddressUnique) || (macAddress.pristine && !form.submitted)">
							<span *ngIf="createEditPlayerService.player.MacAddress?.length !== 12">Mac address must be 12 characters in length!</span>
							<span *ngIf="createEditPlayerService.player.MacAddress?.length === 12">Mac address must be unique!</span>
						</small>
						<div
							*ngIf="macValidationService.player?.Id && macValidationService.player?.Id !== createEditPlayerService.player.Id"
							style="right: -18.75rem"
							class="absolute top0 red-text-light"
						>
							<em>This MAC address belongs to:</em>
							<br />
							<span>
								Player:
								<span class="semi-bold">{{ macValidationService.player?.PlayerName }}</span>
								<br />
								Client:
								<a class="semi-bold red-text-light" (mouseup)="macValidationService.onClientNameClick()">{{ macValidationService.client?.Name }}</a>
							</span>
						</div>
					</ng-container>
					<ng-container *ngIf="createEditPlayerService.player.PlayerModelId === 56">
						<input
							style="border: 1px solid #d6d9db"
							[ngClass]="{ 'red-border-light': (!macAddress.valid || !macValidationService.isMacAddressUnique) && !macAddress.pristine }"
							name="macAddress"
							#macAddress="ngModel"
							[ngModel]="createEditPlayerService.player.MacAddress"
							pattern=".{12}"
							maxlength="12"
							(ngModelChange)="macValidationService.onMacAddressChange($event)"
							type="text"
						/>
						<small class="red-text-light" [hidden]="(macAddress.valid && macValidationService.isMacAddressUnique) || (macAddress.pristine && !form.submitted)">
							<span *ngIf="createEditPlayerService.player.MacAddress?.length !== 12">Mac address must be 12 characters in length!</span>
							<span *ngIf="createEditPlayerService.player.MacAddress?.length === 12">Mac address must be unique!</span>
						</small>
						<div
							*ngIf="macValidationService.player?.Id && macValidationService.player?.Id !== createEditPlayerService.player.Id"
							style="right: -18.75rem"
							class="absolute top0 red-text-light"
						>
							<em>This MAC address belongs to:</em>
							<br />
							<span>
								Player:
								<span class="semi-bold">{{ macValidationService.player?.PlayerName }}</span>
								<br />
								Client:
								<a class="semi-bold red-text-light" (mouseup)="macValidationService.onClientNameClick()">{{ macValidationService.client?.Name }}</a>
							</span>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Player Notes:</span>
				</div>
				<div class="col-sm-6">
					<textarea
						style="border: 1px solid #d6d9db"
						(keyup)="viewService.onFormChange()"
						class="setWidth100 gray-bg-dark"
						rows="4"
						[(ngModel)]="createEditPlayerService.player.PlayerNotes"
						name="playerNotes"
						type="text"
					></textarea>
				</div>
			</div>

			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<label class="semi-bold">Install Date:</label>
				</div>
				<div class="col-sm-6">
					<portal-date-time-selector
						[backgroundColor]="'gray-bg-dark'"
						[height]="'ht40'"
						[dateModel]="createEditPlayerService.player.InstallDate"
						(dateChanged)="viewService.onDateChange($event)"
					></portal-date-time-selector>
				</div>
			</div>

			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold pointer" (click)="viewService.onCheckboxClick('IsExemptFromReminders')">Exempt From Reminders:</span>
				</div>
				<div class="col-sm-6">
					<portal-checkbox
						[backgroundColor]="'white-bg'"
						[type]="'checkbox'"
						[isChecked]="createEditPlayerService.player?.IsExemptFromReminders"
						(checkboxClickNotify)="viewService.onCheckboxClick('IsExemptFromReminders')"
					></portal-checkbox>
				</div>
			</div>
			<div class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Hostname:</span>
				</div>
				<div class="col-sm-6">
					<input
						disabled="{{ viewService.hostnameDisabled }}"
						class="setWidth100 ht40 gray-bg-dark ln-height40"
						(keyup)="viewService.onFormChange()"
						name="hostname"
						#pollingIntervalSec="ngModel"
						[(ngModel)]="createEditPlayerService.player.Hostname"
						type="text"
					/>
				</div>
				<div *ngIf="viewService.hostnameDisabled" class="col-sm-2">
					<button class="red-bg-light white-text" (click)="viewService.onUnlockHostnameClick()">
						<i class="fa fa-exclamation-triangle mgn-right8" aria-hidden="true"></i>
						Unlock Hostname
					</button>
				</div>
			</div>

			<div *ngIf="createEditPlayerService.player.InstallState?.Name !== 'Installed'" class="row mgn-top20">
				<div class="col-sm-2 text-right">
					<span class="semi-bold">Activation Code:</span>
				</div>
				<div class="col-sm-6">
					<input
						class="setWidth100 ht40 gray-bg-dark ln-height40"
						maxlength="5"
						oninput="this.value = this.value.toUpperCase()"
						(keyup)="viewService.onFormChange()"
						name="activationCode"
						[(ngModel)]="createEditPlayerService.player.ActivationCode"
						type="text"
					/>
				</div>
			</div>

			<!--Music-Play24-->
			<ng-container *ngIf="createEditPlayerService.player.PlayerModelId === 56">
				<h2 class="semi-bold mgn-bottom30 mgn-top60">Technical Settings</h2>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Polling Interval (seconds):</span>
					</div>
					<div class="col-sm-6">
						<input
							class="setWidth100 ht40 gray-bg-dark ln-height40"
							(keyup)="viewService.onFormChange()"
							name="pollingIntervalSec"
							#pollingIntervalSec="ngModel"
							[(ngModel)]="createEditPlayerService.settingsJson.PollingIntervalSec"
							type="number"
						/>
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Target Version:</span>
					</div>
					<div class="col-sm-6">
						<input
							class="setWidth100 ht40 gray-bg-dark ln-height40"
							(keyup)="viewService.onFormChange()"
							name="targetVersion"
							#targetVersion="ngModel"
							[(ngModel)]="createEditPlayerService.player.playerStatus.TargetVersion"
							type="number"
						/>
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Reported Version:</span>
					</div>
					<div class="col-sm-6">
						<span>{{ createEditPlayerService.player.playerStatus.ReportedVersion }}</span>
					</div>
				</div>
			</ng-container>

			<!--Music-Halo-->
			<ng-container *ngIf="playerIsHalo">
				<h2 class="semi-bold mgn-bottom30 mgn-top60">Technical Settings</h2>
				<!--SETTINGS JSON-->
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Audio Device Name:</span>
					</div>
					<div class="col-sm-6">
						<span>{{ createEditPlayerService.settingsJson.AudioDeviceName }}</span>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Unit ID:</span>
					</div>
					<div class="col-sm-6">
						<span>{{ createEditPlayerService.settingsJson.UnitIdentifier }}</span>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Volume:</span>
					</div>
					<div class="col-sm-6">
						<input
							class="setWidth100 ht40 gray-bg-dark ln-height40"
							(keyup)="viewService.onFormChange()"
							name="volume"
							#volume="ngModel"
							[(ngModel)]="createEditPlayerService.settingsJson.Volume"
							type="number"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Polling Interval (seconds):</span>
					</div>
					<div class="col-sm-6">
						<input
							class="setWidth100 ht40 gray-bg-dark ln-height40"
							(keyup)="viewService.onFormChange()"
							name="pollingIntervalSec"
							#pollingIntervalSec="ngModel"
							[(ngModel)]="createEditPlayerService.settingsJson.PollingIntervalSec"
							type="number"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Target Version:</span>
					</div>
					<div class="col-sm-6">
						<input
							class="setWidth100 ht40 gray-bg-dark ln-height40"
							(keyup)="viewService.onFormChange()"
							name="targetVersion"
							#targetVersion="ngModel"
							[(ngModel)]="createEditPlayerService.player.playerStatus.TargetVersion"
							type="number"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Reported Version:</span>
					</div>
					<div class="col-sm-6">
						<span>{{ createEditPlayerService.player.playerStatus.ReportedVersion }}</span>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold pointer" (click)="viewService.onCheckboxClick('ForceBomgarReinstall')">Force Remote Access Reinstall:</span>
					</div>
					<div class="col-sm-6">
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[type]="'checkbox'"
							[isChecked]="createEditPlayerService.player.playerStatus.ForceBomgarReinstall"
							(checkboxClickNotify)="viewService.onCheckboxClick('ForceBomgarReinstall')"
						></portal-checkbox>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="createEditPlayerService.showIpConfigOptions()">
				<h2 class="semi-bold mgn-bottom30 mgn-top60">IP Configuration</h2>
				<div *ngIf="viewService.ipConfigDisabled" class="row mgn-top20">
					<div class="col-sm-2">
						<button class="red-bg-light white-text border-radius setWidth100" (click)="viewService.onUnlockIpConfigClick()">
							<i class="fa fa-exclamation-triangle mgn-right8" aria-hidden="true"></i>
							Unlock IP Config
						</button>
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold pointer" (mouseup)="createEditPlayerService.settingsJson.UseDhcp = !createEditPlayerService.settingsJson.UseDhcp">
							Use Dhcp:
						</span>
					</div>
					<div class="col-sm-6">
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[isDisabled]="viewService.ipConfigDisabled"
							[type]="'checkbox'"
							[isChecked]="createEditPlayerService.settingsJson.UseDhcp"
							(checkboxClickNotify)="createEditPlayerService.settingsJson.UseDhcp = !createEditPlayerService.settingsJson.UseDhcp"
						></portal-checkbox>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">IpAddress:</span>
					</div>
					<div class="col-sm-6">
						<input
							name="ipAddress"
							[disabled]="viewService.ipConfigDisabled"
							#ipAddress="ngModel"
							(keyup)="viewService.onFormChange()"
							[(ngModel)]="createEditPlayerService.settingsJson.IpAddress"
							type="text"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Net Mask:</span>
					</div>
					<div class="col-sm-6">
						<input
							name="netMask"
							[disabled]="viewService.ipConfigDisabled"
							#netMask="ngModel"
							(keyup)="viewService.onFormChange()"
							[(ngModel)]="createEditPlayerService.settingsJson.NetMask"
							type="text"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Default Gateway:</span>
					</div>
					<div class="col-sm-6">
						<input
							name="defaultGateway"
							[disabled]="viewService.ipConfigDisabled"
							#defaultGateway="ngModel"
							(keyup)="viewService.onFormChange()"
							[(ngModel)]="createEditPlayerService.settingsJson.DefaultGateway"
							type="text"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Primary DNS:</span>
					</div>
					<div class="col-sm-6">
						<input
							name="primaryDns"
							[disabled]="viewService.ipConfigDisabled"
							#primaryDns="ngModel"
							(keyup)="viewService.onFormChange()"
							[(ngModel)]="createEditPlayerService.settingsJson.PrimaryDns"
							type="text"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Secondary DNS:</span>
					</div>
					<div class="col-sm-6">
						<input
							name="secondaryDns"
							[disabled]="viewService.ipConfigDisabled"
							#secondaryDns="ngModel"
							(keyup)="viewService.onFormChange()"
							[(ngModel)]="createEditPlayerService.settingsJson.SecondaryDns"
							type="text"
						/>
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">HTTP Proxy URL:</span>
					</div>
					<div class="col-sm-6">
						<input
							name="httpProxyUrl"
							[disabled]="viewService.ipConfigDisabled"
							#httpProxyUrl="ngModel"
							(keyup)="viewService.onFormChange()"
							[(ngModel)]="createEditPlayerService.settingsJson.HttpProxyUrl"
							type="text"
						/>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="row mgn-top50 mgn-bottom50">
		<div class="col-sm-10 col-sm-offset-2">
			<div class="row">
				<div class="col-sm-8">
					<div class="row">
						<div class="col-sm-3 col-sm-offset-6">
							<button class="gray-bg" type="button" (click)="viewService.onCancelClick(location)">Cancel</button>
						</div>
						<div class="col-sm-3">
							<button [disabled]="!form.valid || saveService.savingInProgress" type="submit" class="limeGreen-bg white-text">Save</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
