<ng-container [ngSwitch]="contentService.contentView()">
	<vc-video-pb-card-view *ngSwitchCase="'videoPbCard'" [canUseInPlaylist]="canUseInPlaylist" [content]="content"></vc-video-pb-card-view>

	<vc-hold-pb-card-view
		*ngSwitchCase="'holdPbCard'"
		(window:mouseup)="autoCloseTalentBoxes($event)"
		[canUseInPlaylist]="canUseInPlaylist"
		[content]="content"
		[isLastIndex]="isLastIndex"
	></vc-hold-pb-card-view>

	<vc-video-pb-list-view
		*ngSwitchCase="'videoPbList'"
		[canUseInPlaylist]="canUseInPlaylist"
		[content]="content"
		[isEvenIndex]="isEvenIndex"
		[isFirstIndex]="isFirstIndex"
		[isLastIndex]="isLastIndex"
	></vc-video-pb-list-view>

	<vc-video-cm-list-view
		*ngSwitchCase="'videoCmList'"
		[content]="content"
		[isEvenIndex]="isEvenIndex"
		[isFirstIndex]="isFirstIndex"
		[isLastIndex]="isLastIndex"
	></vc-video-cm-list-view>

	<vc-hold-pb-list-view
		*ngSwitchCase="'holdPbList'"
		(window:mouseup)="autoCloseTalentBoxes($event)"
		class="noExit"
		[content]="content"
		[contentList]="contentList"
		[isEvenIndex]="isEvenIndex"
		[isFirstIndex]="isFirstIndex"
		[isLastIndex]="isLastIndex"
	></vc-hold-pb-list-view>

	<vc-hold-cm-list-view
		*ngSwitchCase="'holdCmList'"
		(window:mouseup)="autoCloseTalentBoxes($event)"
		class="noExit"
		[content]="content"
		[contentList]="contentList"
		[isEvenIndex]="isEvenIndex"
		[isFirstIndex]="isFirstIndex"
		[isLastIndex]="isLastIndex"
	></vc-hold-cm-list-view>

	<vc-c24-view
		*ngSwitchCase="'videoC24List'"
		[content]="content"
		[isEvenIndex]="isEvenIndex"
		[isFirstIndex]="isFirstIndex"
		[isLastIndex]="isLastIndex"
	></vc-c24-view>

	<vc-playlist-details-view
		*ngSwitchCase="'playlistDetailsList'"
		(window:mouseup)="autoCloseTalentBoxes($event)"
		[content]="content"
		[contentList]="contentList"
		[isFirstIndex]="isFirstIndex"
	></vc-playlist-details-view>

	<vc-featured-content-view
		*ngSwitchCase="'featuredContent'"
		(window:mouseup)="autoCloseTalentBoxes($event)"
		[content]="content"
		[contentList]="contentList"
		[isEvenIndex]="isEvenIndex"
		[isLastIndex]="isLastIndex"
	></vc-featured-content-view>

	<vc-newsletter-view
		*ngSwitchCase="'newsletter'"
		(window:mouseup)="autoCloseTalentBoxes($event)"
		[content]="content"
		[contentList]="contentList"
		[isEvenIndex]="isEvenIndex"
		[isLastIndex]="isLastIndex"
	></vc-newsletter-view>
</ng-container>
